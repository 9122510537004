///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/administration/bulk/bulkEditTab"/>
import * as ko from "knockout";
import {isNullOrUndefined} from 'Core/Medius.Core.Web/Scripts/lib/underscoreHelpers';

export = class BulkEditorType {
    TabName: ko.Observable<any>;
    IsDefault: any;
    IsLoading = ko.observable(false);
    cssClass= "this-tab tab-pane";
    tabId: string;
    tabHref: string;
    attributes: { id: string; "data-tab-id": string; };
    tabViewPath: any;
    tabViewModel:any;
    constructor(tabModel: { TabName: any; IsDefault: any; Id: string; RelatedType: any; ForType: any; ViewName: any; }){
        let type;
        this.TabName = ko.observable(tabModel.TabName);
        this.IsDefault = tabModel.IsDefault;
        this.tabId = "bulk-admin-tab-" + tabModel.Id;
        this.tabHref = "#" + this.tabId;
        this.attributes =  {
            "id": this.tabId,
            "data-tab-id": this.tabId
        };

        type = isNullOrUndefined(tabModel.RelatedType) ? tabModel.ForType : tabModel.RelatedType;
        this.tabViewPath = `Display/${type}/BulkAdmin${tabModel.ViewName}`;
    }
};