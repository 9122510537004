///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Entities.User/BulkAdminEditor"/>
import * as ko from "knockout";
import * as userEditorHelpers from "Core/Medius.Core.Web/Scripts/Medius/lib/utils/userEditorHelpers";
import * as _ from "underscore";

class PropertyEditor {
    Name: string;
    Key: string;
    ServiceValueProvider: any;
    Value: ko.Observable<any>;
    IsModified: ko.Observable<boolean>;
    IsDisabled: ko.Observable<boolean>;
    IsModifiedSub: any;
    toggleIsModified: () => void;
    ServiceValue: () => any;
    constructor(key:string, name:string, serviceValueProvider:(value:any) => any) {
        this.Name = name;
        this.Key = key;
        this.ServiceValueProvider = serviceValueProvider;
        this.Value = ko.observable(null);
        this.IsModified = ko.observable(false);
        this.IsDisabled = ko.observable(true);
        this.IsModifiedSub = this.IsModified.subscribe((newValue:boolean) => {
            this.IsDisabled(!newValue);
        });
        this.toggleIsModified = () => {
            this.IsModified(!this.IsModified());
        };

        this.ServiceValue = () => {
            if (this.ServiceValueProvider !== null && this.Value() !== null) {
                return this.ServiceValueProvider(this.Value());
            } else {
                return this.Value();
            }
        };
    }
}

class BulkAdminEditor {
    EntitiesIds: any;
    Editors: any[];
    TimeZonesList: ko.ObservableArray<any>;
    FormatsList: ko.ObservableArray<any>;
    LanguagesList: ko.ObservableArray<any>;
    LanguageEditor: PropertyEditor;
    TimeZoneEditor: PropertyEditor;
    DefaultCompanyEditor: PropertyEditor;
    EmailEditor: PropertyEditor;
    FormatEditor: PropertyEditor;
    IsActiveEditor: PropertyEditor;
    constructor(entityIds:any) {
        this.EntitiesIds = entityIds;
        this.Editors = [];

        this.TimeZonesList = ko.observableArray([]);
        this.FormatsList = ko.observableArray([]);
        this.LanguagesList = ko.observableArray([]);

        this.loadSelectors();
        this.loadEditors();
    }

    loadSelectors() {
        $.when(userEditorHelpers.loadAvailableLanguages())
            .done((languages:any[]) => {
                this.LanguagesList(languages);
            });

        $.when(userEditorHelpers.loadAvailableFormats())
            .done((formats:any[]) => {
                this.FormatsList(formats);
            });

        $.when(userEditorHelpers.loadAvailableTimeZones())
            .done((timeZones:any[]) => {
                this.TimeZonesList(timeZones);
            });
    }

    loadEditors() {
        this.LanguageEditor = new PropertyEditor("GeneralLanguage", "#Core/generalLanguageModification",
            (value:any) => { return { Language: value }; });
        this.TimeZoneEditor = new PropertyEditor("GeneralTimeZone", "#Core/generalTimeZoneModification",
            (value:any) => { return { TimeZone: value }; });
        this.DefaultCompanyEditor = new PropertyEditor("GeneralDefaultCompany", "#Core/generalDefaultCompanyModification",
            (value:any) => { return { CompanyId: value.Id }; });
        this.EmailEditor = new PropertyEditor("GeneralEmail", "#Core/generalEmailModification",
            (value:any) => { return { Email: value }; });
        this.FormatEditor = new PropertyEditor("GeneralFormat", "#Core/generalFormatModification",
            (value:any) => { return { Format: value }; });
        this.IsActiveEditor = new PropertyEditor("GeneralIsActive", "#Core/generalIsActiveModification",
            (value:any) => { return { IsActive: value }; });

        this.Editors.push(
            this.LanguageEditor,
            this.TimeZoneEditor,
            this.DefaultCompanyEditor,
            this.EmailEditor,
            this.FormatEditor,
            this.IsActiveEditor);
    }

    validate() {
        const missingValue = _.find(this.Editors, (editor) => {
            return (editor.IsModified() && _.isUndefined(editor.Value()));
        });

        if (!_.isUndefined(missingValue)) {
            return false;
        }
        return true;
    }

    save(modifications:any[]) {
        _.each(this.Editors, (editor) => {
            if (editor.IsModified()) {
                modifications.push({
                    Name: editor.Name,
                    Key: editor.Key,
                    Value: editor.ServiceValue()
                });
            }
        });
    }

    dispose() {
        _(this.Editors).each((editor) => {
            editor.IsModifiedSub.dispose();
        });
    }
}

export = function(entityIds:any) {
    return new BulkAdminEditor(entityIds);
};