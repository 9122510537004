///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Entities.User/Reminders/bulkRemoveGrid"/>
import * as repository from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Reminders/repository";
import * as bulkRemoveDataSource from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Entities.User/Reminders/bulkRemoveDataSource";
import { EditableGrid } from "Core/Medius.Core.Web/Scripts/Medius/components/grid/editable/grid";

class BulkRemoveGrid extends EditableGrid {
    constructor(collection: any, reminderTypes: any) {
        const options = {
            search: false,
            sort: true,
            paging: true,
            editPageSize: false,
            template: "grid-tpl-default",
            tplRow: "Core:templates/Administration/User/BulkRemoveReminderRowTpl.html"
        };
        const dataSource = bulkRemoveDataSource.create(collection, reminderTypes);

        super(dataSource, options);
    }
}

export function create(collection: any) {
    return $.when(
        repository.loadAvailableReminderTypes()
    ).pipe((reminderTypes) => {
        return new BulkRemoveGrid(collection, reminderTypes);
    });
}