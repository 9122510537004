/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Entities.Api.ClientApplication/ClientApplicationViewModel" />
import * as ko from "knockout";
import { getTenantId, toAction } from "Core/Medius.Core.Web/Scripts/Medius/lib/path";
import { getLoginApiUrl } from "Core/Medius.Core.Web/Scripts/appConfig";

const clientApplicationType = "Medius.Core.Entities.Api.ClientApplication";
const clientApplicationDtoType = "Medius.Core.Administration.Api.ClientApplicationDto";

export interface ClientApplicationResponse {
    Name: string;
    ClientId: string;
    ClientSecret: string;
    Flow: Flow;
    RedirectUri: string;
    IsActive: boolean;
    ImageUri: string;
    Description: string;
    ExternalLinkUrl: string;
    PostLogoutRedirectUri: string;
    FrontChannelLogoutUri: string;
    Id: number;
    EntityVersion: number;
    Roles: any;
    Scopes: any; 
    UseGlobalId: boolean;
}

export interface ClientApplicationRequest {
    Name: string;
    Flow: number;
    RedirectUri: string;
    IsActive: boolean;
    ImageUri: string;
    Description: string;
    ExternalLinkUrl: string;
    PostLogoutRedirectUri: string;
    FrontChannelLogoutUri: string;
    EntityVersion: number;
    Id: number;
    $type: string;
    Roles: any;
    Scopes: any;
    UseGlobalId: boolean;
}

export class ClientApplication {
    public readonly accessTokenUrl: string;
    public readonly authorizeUrl: string;
    public readonly name: ko.Observable<string>;
    public readonly clientId: ko.Observable<string>;
    public readonly clientSecret: ko.Observable<string>;
    public readonly flow: ko.Observable<Flow>;
    public readonly redirectUri: ko.Observable<string>;
    public readonly isActive: ko.Observable<boolean>;
    public readonly imageUri: ko.Observable<string>;
    public readonly description: ko.Observable<string>;
    public readonly externalLinkUrl: ko.Observable<string>;
    public readonly externalLinkUrlWithTenantIdParameter: ko.Observable<string>;
    public readonly postLogoutRedirectUri: ko.Observable<string>;
    public readonly frontChannelLogoutUri: ko.Observable<string>;
    public readonly id: ko.Observable<number>;
    public readonly $type: ko.Observable<string>;
    public readonly Roles: ko.ObservableArray<any>;
    public readonly Scopes: ko.ObservableArray<any>;
    public readonly useGlobalId: ko.Observable<boolean>;
    public readonly entityVersion: number;

    private readonly internalEntity: ClientApplicationResponse;

    constructor(entity: ClientApplicationResponse) {

        this.internalEntity = entity;
        this.entityVersion = entity.EntityVersion;

        let loginApiUrl = getLoginApiUrl();
        if (window.location.host == "localhost")
            loginApiUrl = "https://localhost";

        this.id = ko.observable(this.internalEntity.Id);
        this.name = ko.observable(this.internalEntity.Name);
        this.clientId = ko.observable(this.internalEntity.ClientId);
        this.clientSecret = ko.observable(this.internalEntity.ClientSecret);
        this.flow = ko.observable(this.internalEntity.Flow);
        this.redirectUri = ko.observable(this.internalEntity.RedirectUri);
        this.isActive = ko.observable(this.internalEntity.IsActive);
        this.imageUri = ko.observable(this.internalEntity.ImageUri);
        this.description = ko.observable(this.internalEntity.Description);
        this.externalLinkUrl = ko.observable(this.internalEntity.ExternalLinkUrl);
        this.externalLinkUrlWithTenantIdParameter = ko.observable(this.internalEntity.ExternalLinkUrl + "?tenantId=" + getTenantId());
        this.postLogoutRedirectUri = ko.observable(this.internalEntity.PostLogoutRedirectUri);
        this.frontChannelLogoutUri = ko.observable(this.internalEntity.FrontChannelLogoutUri);
        this.accessTokenUrl = loginApiUrl + toAction("token", "~/connect");
        this.authorizeUrl = loginApiUrl + toAction("authorize", "~/OAuth");
        this.$type = ko.observable(clientApplicationType);
        this.Roles = ko.observableArray(entity.Roles);
        this.Scopes = ko.observableArray(entity.Scopes);
        this.useGlobalId = ko.observable(this.internalEntity.UseGlobalId); 

        if (this.isNew()) {
            this.isActive(true);
            this.useGlobalId(true);
        }
    }

    public isNew(): boolean {
        return this.id() === 0;
    }

    public isPostLogoutRedirectUriAssigned(): boolean
    {
        return !!this.postLogoutRedirectUri();
    }

    public isFrontChannelLogoutUriAssigned(): boolean
    {
        return !!this.frontChannelLogoutUri();
    }

    public toJS() : ClientApplicationRequest {
        return {
            Name: this.name(),
            Flow: this.flow(),
            RedirectUri: this.redirectUri(),
            IsActive: this.isActive(),
            Description: this.description(),
            ExternalLinkUrl: this.externalLinkUrl(),
            PostLogoutRedirectUri: this.postLogoutRedirectUri(),
            FrontChannelLogoutUri: this.frontChannelLogoutUri(),
            Id: this.id(),
            ImageUri: this.imageUri(),
            $type: clientApplicationDtoType,
            Roles: this.Roles(),
            Scopes: this.Scopes(),
            EntityVersion: this.entityVersion,
            UseGlobalId : this.useGlobalId()       
        };
    }

}

export enum Flow {
    PasswordFlow = 0,
    AuthorizationCodeFlow = 1,
    ImplicitFlow = 3,
    ClientCredentialsFlow = 4
}


export default function editorFunc() {
    return {
        type: clientApplicationType,
        make: (entity: any, context: any) => new ClientApplication(entity),
        toJS: (viewModel: ClientApplication) => viewModel.toJS()
    };
}
