/// <amd-module name="Core/Medius.Core.Web/Scripts/Models/cashDiscountViewModelFactory"/>
import {observable} from "knockout";

export class CashDiscountViewModelFactory {
    public static create(date: Date, discount: number) {
        return {
            $type: "Medius.PurchaseToPay.DTO.CashDiscountDto, Medius.PurchaseToPay.Common",
            Date: observable<Date>(date),
            Percentage: observable({
                $type: "Medius.Core.DTOs.PercentageDto, Medius.Core.Common",
                Percent: observable(discount)
            })
        };
    }
}
