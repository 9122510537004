/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/inbox/search/document"/>

import * as _ from "underscore";
import { unwrap, observableArray } from "knockout";
import { error } from "Core/Medius.Core.Web/Scripts/Medius/lib/logger";
import * as rest from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rest";
import { handleAnyError } from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import * as registry from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/search/customFilterFieldsRegistry";
import * as $ from "jquery";
import { DocumentField } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/search/fields";
import { IDocumentContext } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/search/customFilterFieldsRegistry";
import { isInboxFilterAutocompleteEnabled } from "Core/Medius.Core.Web/Scripts/Medius/core/featureToggle";

const service = "InboxManager";

const autocompleteFields = ["Company.CompanyId", "Company.Name", "Supplier.SupplierId", "Supplier.Name"];

class Document {
    public FullName: string;
    public Name: string;
    public SearchableFields = observableArray([]);
    public customSearchableFilterFieldsCache: any[] = [];
    public configurationSearchableCache: { [key: string]: any };
    public documentContext: IDocumentContext;

    constructor(typeFullName: string, name: string) {
        if (typeof typeFullName !== 'string') {
            return null;
        }

        this.FullName = typeFullName;
        this.Name = name || null;
        this.documentContext = {CompanyId: "", CompanyName: "", DocumentType: this.FullName};
    }

    public hasCustomView(field: any) {
        const conditionName = unwrap(field.InboxFilterConditionName);
        return !!registry.getProvider(conditionName);
    }

    public getCustomView(field: any) {
        const conditionName = unwrap(field.InboxFilterConditionName);
        let cachedCustomView = _.find(this.customSearchableFilterFieldsCache, (csf: any) => csf.Field.InboxFilterConditionName === conditionName);

        if (!cachedCustomView) {
            const provider = registry.getProvider(conditionName);

            cachedCustomView = {
                Field: field,
                TemplateName: provider.TemplateName,
                Vm: provider.GetVm(field, this.documentContext)
            };

            this.customSearchableFilterFieldsCache.push(cachedCustomView);
        }

        return cachedCustomView;
    }

    public createSearchableInputs(columnConfigurations: any) {

        const fields = _.map(columnConfigurations, (config: any) => this.createFieldFromConfiguration(config));
        this.SearchableFields(fields);
        return fields;
    }

    public loadConfiguration() {
        const typeFullName = this.FullName;

        if (this.configurationSearchableCache[typeFullName]) {
            this.createSearchableInputs(this.configurationSearchableCache[typeFullName]);
        }

        return $.when(
            rest.get(service, "SearchableFilterFields/" + typeFullName)
        ).pipe(
            (configs: any) => {
                this.configurationSearchableCache[typeFullName] = configs;
                return this.createSearchableInputs(configs);
            }
        ).fail(
            (jqXhr) => handleAnyError(jqXhr)
        );
    }

    public createFieldFromConfiguration(configData: any) {

        if (_.isNull(configData) || !_.isObject(configData)) {
            return null;
        }

        if (isInboxFilterAutocompleteEnabled) {
            if (!configData.InboxFilterConditionName && autocompleteFields.includes(configData.ValueSource)) {
                configData.InboxFilterConditionName = configData.ValueSource;
            } 
        }

        const field = new DocumentField(configData.ColumnName, configData.ForType, configData.ValueSource,
            configData.Id, configData.ValueSourceType, configData.InboxFilterConditionName);

        return field;
    }

    public findDocumentField = (fieldId: any, inboxFilterConditionName: any) => {
        let field;
        const fieldComparison = !!inboxFilterConditionName
            ? (f: any) => f.InboxFilterConditionName === inboxFilterConditionName
            : (f: any) => f.Id === fieldId;

        try {
            field = _.find(this.SearchableFields(), fieldComparison);
        } catch (e) {
            error("findDocumentField(), fieldId=" + fieldId);
            error(e);
        }

        return field || null;
    };

    public dispose = () => {

        _.each(this.customSearchableFilterFieldsCache,
            (csf: any) => csf.Vm.dispose());
    };
}

Document.prototype.configurationSearchableCache = [];


export function create(typeFullName: string, name: string) {
    return new Document(typeFullName, name);
}


