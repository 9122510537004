/// <amd-module name="Core/Medius.Core.Web/Scripts/components/links/linksModal/mappers"/>

import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { Globalize } from "Core/Medius.Core.Web/Scripts/Medius/lib/globalize";
import { getCultureCode } from "Core/Medius.Core.Web/Scripts/Medius/lib/utils/user";

interface SuggestionDto {
    entityId: number
    entityType: string
    entityViewId: string
}

interface SupplierContractDocumentSuggestionDto extends SuggestionDto {
    contractNumber: string
    contractName: string
    company: string
    supplier: string
}

interface PaymentRequestSuggestionDto extends SuggestionDto {
    paymentRequestNumber: string
    company: string
    supplier: string
    paymentRequestDate: string
    voucherNumber: string
}

interface CreditMemoSuggestionDto extends SuggestionDto {
    invoiceNumber: string
    company: string
    supplier: string
    invoiceDate: string
    voucherNumber: string
}

interface SupplierInvoiceSuggestionDto extends SuggestionDto {
    invoiceNumber: string
    company: string
    supplier: string
    invoiceDate: string
    voucherNumber: string
}

interface PurchaseOrderDocumentSuggestionDto extends SuggestionDto {
    orderIdentifier: string
    requisitionNumber: string
    company: string
    supplier: string
}

interface PurchaseRequisitionSuggestionDto extends SuggestionDto {
    identifier: string
    companyName: string
    createdDate: string
}

interface DocumentApprovalSuggestionDto extends SuggestionDto {
    documentNumber: string
    company: string
    subType: string
    createdDate: string
}

interface PurchaseOrderSuggestionDto extends SuggestionDto {
    orderIdentifier: string
    company: string
    supplier: string
}


const supplierInvoiceToListItemMapper = (dto: SuggestionDto) => {
    const supplierInvoiceSuggestion = dto as SupplierInvoiceSuggestionDto;
    return {
        header: `${translate(supplierInvoiceSuggestion.entityType)} (${toLocalizedString(supplierInvoiceSuggestion.invoiceDate)})`,
        text: `${supplierInvoiceSuggestion.supplier} (${supplierInvoiceSuggestion.company})`,
        subText: supplierInvoiceSuggestion.invoiceNumber,
        value: supplierInvoiceSuggestion.entityViewId
    };
};

const documentApprovalToListItemMapper = (dto: SuggestionDto) => {
    const documentApprovalSuggestion = dto as DocumentApprovalSuggestionDto;
    return {
        header: `${translate(documentApprovalSuggestion.entityType)} (${toLocalizedString(documentApprovalSuggestion.createdDate)})`,
        text: documentApprovalSuggestion.company,
        subText: documentApprovalSuggestion.documentNumber,
        value: documentApprovalSuggestion.entityViewId
    };
};

const purchaseOrderToListItemMapper = (dto: SuggestionDto) => {
    const purchaseOrderSuggestion = dto as PurchaseOrderSuggestionDto;
    return {
        header: translate(purchaseOrderSuggestion.entityType),
        text: `${purchaseOrderSuggestion.supplier} (${purchaseOrderSuggestion.company})`,
        subText: purchaseOrderSuggestion.orderIdentifier,
        value: purchaseOrderSuggestion.entityViewId
    };
};

const purchaseRequisitionToListItemMapper = (dto: SuggestionDto) => {
    const purchaseRequisitionSuggestion = dto as PurchaseRequisitionSuggestionDto;
    return {
        header: `${translate(purchaseRequisitionSuggestion.entityType)} (${toLocalizedString(purchaseRequisitionSuggestion.createdDate)})`,
        text: purchaseRequisitionSuggestion.companyName,
        subText: purchaseRequisitionSuggestion.identifier,
        value: purchaseRequisitionSuggestion.entityViewId
    };
};

const paymentRequestToListItemMapper = (dto: SuggestionDto) => {
    const paymentRequestSuggestion = dto as PaymentRequestSuggestionDto;
    return {
        header: `${translate(paymentRequestSuggestion.entityType)} (${toLocalizedString(paymentRequestSuggestion.paymentRequestDate)})`,
        text: `${paymentRequestSuggestion.supplier} (${paymentRequestSuggestion.company})`,
        subText: paymentRequestSuggestion.paymentRequestNumber,
        value: paymentRequestSuggestion.entityViewId
    };
};

const supplierContractDocumentToListItemMapper = (dto: SuggestionDto) => {
    const supplierContractDocumentSuggestion = dto as SupplierContractDocumentSuggestionDto;
    return {
        header: translate(supplierContractDocumentSuggestion.entityType),
        text: `${supplierContractDocumentSuggestion.supplier} (${supplierContractDocumentSuggestion.company})`,
        subText: `${supplierContractDocumentSuggestion.contractName} (${supplierContractDocumentSuggestion.contractNumber})`,
        value: supplierContractDocumentSuggestion.entityViewId
    };
};

const creditMemoToListItemMapper = (dto: SuggestionDto) => {
    const creditMemoSuggestion = dto as CreditMemoSuggestionDto;
    return {
        header: `${translate(creditMemoSuggestion.entityType)} (${toLocalizedString(creditMemoSuggestion.invoiceDate)})`,
        text: `${creditMemoSuggestion.supplier} (${creditMemoSuggestion.company})`,
        subText: creditMemoSuggestion.invoiceNumber,
        value: creditMemoSuggestion.entityViewId
    };
};

const purchaseOrderDocumentToListItemMapper = (dto: SuggestionDto) => {
    const purchaseOrderDocumentSuggestion = dto as PurchaseOrderDocumentSuggestionDto;
    const requisitionNumber = purchaseOrderDocumentSuggestion?.requisitionNumber ?
        `(${purchaseOrderDocumentSuggestion.requisitionNumber})` : '';

    return {
        header: translate(purchaseOrderDocumentSuggestion.entityType),
        text: `${purchaseOrderDocumentSuggestion.supplier} (${purchaseOrderDocumentSuggestion.company})`,
        subText: `${purchaseOrderDocumentSuggestion.orderIdentifier} ${requisitionNumber}`,
        value: purchaseOrderDocumentSuggestion.entityViewId
    };
};

const toLocalizedString = (dateInJsSerializerFormat: string) => {
    const date = new Date(parseInt(dateInJsSerializerFormat.substring(6, dateInJsSerializerFormat.length - 2), 10));
    return Globalize.format(date, "d", getCultureCode());
};

const suggestionToListItemMappers = new Map([
    ["Medius.ContractbasedInvoice.Entities.ContractbasedInvoice", supplierInvoiceToListItemMapper],
    ["Medius.ExpenseInvoice.Entities.ExpenseInvoice", supplierInvoiceToListItemMapper],
    ["Medius.OrderbasedInvoice.Entities.OrderbasedInvoice", supplierInvoiceToListItemMapper],
    ["Medius.Apa.Persistence.PrepaymentInvoice.PrepaymentSupplierInvoice", supplierInvoiceToListItemMapper],
    ["Medius.Contract.Entities.SupplierContractDocument", supplierContractDocumentToListItemMapper],
    ["Medius.Procurement.Entities.PurchaseRequisition", purchaseRequisitionToListItemMapper],
    ["Medius.PurchaseToPay.Entities.PurchaseOrder.PurchaseOrder", purchaseOrderToListItemMapper],
    ["Medius.PurchaseToPay.Entities.ShortPayment.CreditMemo", creditMemoToListItemMapper],
    ["Medius.Procurement.Entities.PurchaseOrderDocument", purchaseOrderDocumentToListItemMapper],
    ["Medius.ExpenseInvoice.Entities.PaymentRequest", paymentRequestToListItemMapper],
    ["Medius.PurchaseToPay.DocumentApproval.Entities.DocumentApproval", documentApprovalToListItemMapper]
]);


const supplierInvoiceToLinkMapper = (dto: SuggestionDto) => {
    const supplierInvoiceSuggestion = dto as SupplierInvoiceSuggestionDto;

    return {
        entityType: supplierInvoiceSuggestion.entityType,
        documentNumber: supplierInvoiceSuggestion.invoiceNumber,
        company: supplierInvoiceSuggestion.company,
        supplier: supplierInvoiceSuggestion.supplier,
        sectionType: ""
    };
};

const supplierContractDocumentToLinkMapper = (dto: SuggestionDto) => {
    const supplierContractDocumentSuggestion = dto as SupplierContractDocumentSuggestionDto;

    return {
        entityType: supplierContractDocumentSuggestion.entityType,
        documentNumber: `${supplierContractDocumentSuggestion.contractName} (${supplierContractDocumentSuggestion.contractNumber})`,
        company: supplierContractDocumentSuggestion.company,
        supplier: supplierContractDocumentSuggestion.supplier,
        sectionType: ""
    };
};

const purchaseRequisitionToLinkMapper = (dto: SuggestionDto) => {
    const purchaseRequisitionSuggestion = dto as PurchaseRequisitionSuggestionDto;

    return {
        entityType: purchaseRequisitionSuggestion.entityType,
        documentNumber: purchaseRequisitionSuggestion.identifier,
        company: purchaseRequisitionSuggestion.companyName,
        supplier: "",
        sectionType: ""
    };
};

const purchaseOrderToLinkMapper = (dto: SuggestionDto) => {
    const purchaseOrderSuggestion = dto as PurchaseOrderSuggestionDto;

    return {
        entityType: purchaseOrderSuggestion.entityType,
        documentNumber: purchaseOrderSuggestion.orderIdentifier,
        company: purchaseOrderSuggestion.company,
        supplier: purchaseOrderSuggestion.supplier,
        sectionType: ""
    };
};

const creditMemoToLinkMapper = (dto: SuggestionDto) => {
    const creditMemoSuggestion = dto as CreditMemoSuggestionDto;

    return {
        entityType: creditMemoSuggestion.entityType,
        documentNumber: creditMemoSuggestion.invoiceNumber,
        company: creditMemoSuggestion.company,
        supplier: creditMemoSuggestion.supplier,
        sectionType: ""
    };
};

const purchaseOrderDocumentToLinkMapper = (dto: SuggestionDto) => {
    const purchaseOrderDocumentSuggestion = dto as PurchaseOrderDocumentSuggestionDto;
    const requisitionNumber = purchaseOrderDocumentSuggestion?.requisitionNumber ?
        `(${purchaseOrderDocumentSuggestion.requisitionNumber})` : '';
    return {
        entityType: purchaseOrderDocumentSuggestion.entityType,
        documentNumber: `${purchaseOrderDocumentSuggestion.orderIdentifier} ${requisitionNumber}`,
        company: purchaseOrderDocumentSuggestion.company,
        supplier: purchaseOrderDocumentSuggestion.supplier,
        sectionType: ""
    };
};

const paymentRequestToLinkMapper = (dto: SuggestionDto) => {
    const paymentRequestSuggestion = dto as PaymentRequestSuggestionDto;

    return {
        entityType: paymentRequestSuggestion.entityType,
        documentNumber: paymentRequestSuggestion.paymentRequestNumber,
        company: paymentRequestSuggestion.company,
        supplier: paymentRequestSuggestion.supplier,
        sectionType: ""
    };
};

const documentApprovalToLinkMapper = (dto: SuggestionDto) => {
    const documentApprovalSuggestion = dto as DocumentApprovalSuggestionDto;

    return {
        entityType: documentApprovalSuggestion.entityType,
        documentNumber: documentApprovalSuggestion.documentNumber,
        company: documentApprovalSuggestion.company,
        supplier: "",
        sectionType: ""
    };
};

const suggestionToLinkMappers = new Map([
    ["Medius.ContractbasedInvoice.Entities.ContractbasedInvoice", supplierInvoiceToLinkMapper],
    ["Medius.ExpenseInvoice.Entities.ExpenseInvoice", supplierInvoiceToLinkMapper],
    ["Medius.OrderbasedInvoice.Entities.OrderbasedInvoice", supplierInvoiceToLinkMapper],
    ["Medius.Apa.Persistence.PrepaymentInvoice.PrepaymentSupplierInvoice", supplierInvoiceToLinkMapper],
    ["Medius.Contract.Entities.SupplierContractDocument", supplierContractDocumentToLinkMapper],
    ["Medius.Procurement.Entities.PurchaseRequisition", purchaseRequisitionToLinkMapper],
    ["Medius.PurchaseToPay.Entities.PurchaseOrder.PurchaseOrder", purchaseOrderToLinkMapper],
    ["Medius.PurchaseToPay.Entities.ShortPayment.CreditMemo", creditMemoToLinkMapper],
    ["Medius.Procurement.Entities.PurchaseOrderDocument", purchaseOrderDocumentToLinkMapper],
    ["Medius.ExpenseInvoice.Entities.PaymentRequest", paymentRequestToLinkMapper],
    ["Medius.PurchaseToPay.DocumentApproval.Entities.DocumentApproval", documentApprovalToLinkMapper]
]);

export { SuggestionDto, suggestionToListItemMappers, suggestionToLinkMappers };