///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/grid/bindings/search"/>
import * as _ from "underscore";
import * as ko from "knockout";
import * as $ from "jquery";
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";

const keyIndex = "grid-column-index";

const gridSearch = {
    init: function (element:any, bindingAccessor:any) {
        let grid = bindingAccessor().grid;
        const value = bindingAccessor().value;
        let dispose:any;

        let handler = (function() {
            let oldValue = "";
            return function (newValue:any) {
                if (oldValue !== newValue) {
                    oldValue = newValue;
                    const index = koUtils.domData.get(element, keyIndex);
                    grid.searchByColumn(index, newValue);
                }
            };
        }());
        
        if (value && ko.isObservable(value)) {
            let valueSub = value.subscribe(_.debounce(function() {
                //because this function is debounced it may happen that after update
                //it will be called when binding is already disposed and handler is not avaible
                //not elegant solution but simple and working one
                if (handler) {
                    handler(value());
                }
            }, 800));

            dispose = function() {
                valueSub.dispose();
                valueSub = null;
                handler = null;
                grid = null;
            };
        }
        else {
            let $element = $(element);
            dispose = function() {
                $element.off("keyup");
                handler = null;
                grid = null;
                $element = null;
            };
            dispose();
            $element.on("keyup", _.debounce(function () {
                const newValue = $element.val();
                handler(newValue);
            }, 800));
        }

        koUtils.addDisposeCallback(element, dispose);
    },
    update: function (element:any, bindingAccessor:any) {
        const index = koUtils.unpack(bindingAccessor().index);
        koUtils.domData.set(element, keyIndex, index);
    }
};

export function register() {
    koUtils.registerBinding("grid-search", gridSearch);
}