/// <amd-module name="Core/Medius.Core.Web/Scripts/components/administrationEditor/TaskTabsTemplate"/>
import * as React from "react";
import { TabBar, Tab } from "@medius/ui-controls";

export interface TabType {
    tabName:string;
    tabTestId:string;
    tabActive:boolean;
    counter:number;
    poIndicatorVisible:boolean;
}

export interface TaskTabsProps {
    tabs: TabType[];
    activateTab: (tabName:string) => void;
}

export function TaskTabsTemplate({tabs, activateTab}:TaskTabsProps) {
    const ref = React.useRef();
    const [selectedTabState, setSelectedTabState] = React.useState(tabs.find(t => t.tabActive === true)?.tabName);

    // handle what happens on key press
    const handleKeyPress = React.useCallback((event:KeyboardEvent) => {
        if(event.altKey && event.ctrlKey) {
            selectNextTab(event.code);
        }
    }, [selectedTabState]);

    const selectNextTab = (keyCode: string) => {
        let currentTabIndex = tabs.findIndex((tab) => tab.tabName === selectedTabState);
        switch(keyCode) {
            case "KeyZ": 
                if(currentTabIndex === 0) {
                    currentTabIndex = tabs.length - 1;
                } else {
                    currentTabIndex = currentTabIndex - 1;
                }
                break;
            case "KeyX":
                if(currentTabIndex == tabs.length - 1) {
                    currentTabIndex = 0;
                } else {
                    currentTabIndex = currentTabIndex + 1;
                }
                break;
            default:
                return;
        }
        const key = tabs[currentTabIndex].tabName;
        setSelectedTabState(key);
        activateTab(key);
    };

    React.useEffect(() => {
      // attach the event listener
      document.addEventListener('keydown', handleKeyPress);

      // remove the event listener
      return () => {
        document.removeEventListener('keydown', handleKeyPress);
      };
    }, [handleKeyPress]);

    return (
        <div data-testid="task-tabs" className="tabBarContainer" ref={ref}>
            <TabBar
                layout="row"
                onSelectionChange={(key) => {
                    setSelectedTabState(key);
                    activateTab(key);
                }}
                selectedKey={selectedTabState}>
                {
                    tabs.map((tab) => {
                        let additionalInfo = tab.counter>0?tab.counter.toString():null;
                        if(tab.poIndicatorVisible) {
                            additionalInfo += " (PO)";
                        }
                        return(
                            <Tab
                                additionalInfo={additionalInfo}
                                data-testid={tab.tabTestId}
                                label = {tab.tabName}
                                key = {tab.tabName}
                                tabKey = {tab.tabName}/>
                        );
                    })
                }
            </TabBar>
        </div>
    );
}