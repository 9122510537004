/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/bindings/autoScroll"/>

import * as ko from "knockout";
import * as $ from "jquery";
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";

const autoScroll: ko.BindingHandler = {
    update: (element:any, valueAccessor:any) => {

        const $container = $(element);
        const selectedItem = koUtils.unpack(valueAccessor());

        $container
            .children()
            .filter((i, el) => ko.dataFor(el) === selectedItem)
            .each((i, item) => {
                const positionTop = $(item).position().top;
                const itemHeight = $(item).outerHeight(true);
                const scrollOffset = $container.scrollTop();
                const bottomOffset = positionTop + itemHeight - $container.height();
                const topOffset = -positionTop;

                if (bottomOffset > 0) {
                    $container.scrollTop(scrollOffset + itemHeight * Math.ceil(bottomOffset / itemHeight));
                } else if (topOffset > 0) {
                    $container.scrollTop(scrollOffset - itemHeight * Math.ceil(topOffset / itemHeight));
                }
            });
    }
};

export function register() {
    koUtils.registerBinding("autoScroll", autoScroll);
}


