///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Validation.ValidatorConfiguration/Context"/>
import * as ko from "knockout";
import * as dataGrid from "Core/Medius.Core.Web/Scripts/Medius/components/grid/default/grid";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as notification from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import * as ContextProvider from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Validation.ValidatorConfiguration/contextDataProvider";
import { ContextList as DataGridSource } from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Validation.ValidatorConfiguration/contextListDataSource";

class ContextLinksTab {
    Entity: any;
    IsNew: boolean;
    ValidatorType: any;
    Collection: any;
    ContextListGrid: dataGrid.Grid;
    SelectedContext: ko.Observable<any>;
    ContextProvider: any;
    IsAddingPossible: ko.Computed<boolean>;
    constructor(entityWithContext:any) {
        const entityModel = entityWithContext.Entity;
        this.Entity = entityModel;
        this.IsNew = this.Entity.Id > 0;
        this.ValidatorType = this.Entity.ValidatorType();
        this.Collection = this.resolveCollection(entityModel, "ContextList");
        this.ContextListGrid = this.createGrid();
        this.SelectedContext = ko.observable(null);
        this.ContextProvider = ContextProvider.create();
        this.IsAddingPossible = ko.computed(() => {
            return this.SelectedContext() !== null;
        });
    }
    addContext() {
        const newValidationContext = this.SelectedContext();

        if(this.Collection().find((item:any) => item === newValidationContext))
        {
            const msg = globalization.getLabelTranslation("#Core/chooseenValidationContextIsSelectedError");
            notification.error(msg);
            return;
        }

        this.Collection.push(newValidationContext);
        this.ContextListGrid.addRow(newValidationContext);
        this.SelectedContext(null);
    }
        
    removeContext(context:any) {
        this.Collection.remove(context);
    }
        
    createGrid() {
        const dataSource = new DataGridSource(this.Collection);

        const options = {
            tplRow: "Core:templates/Common/RemovableRowTpl.html",
            pageSize: 20,
            onRemoveRow: (row:any) => {
                this.removeContext(row);
            }
        };

            return dataGrid.create(dataSource, options);
    }
        
    resolveCollection(entityModel:any, propertyName:string) {
        const collection = entityModel[propertyName];
        if (!collection) {
            throw new Error(`Property ${propertyName} does not exist`);
        }
        return collection;
    }
    dispose() {
        this.ContextListGrid.destroy();
        this.ContextListGrid = null;
        this.IsAddingPossible.dispose();
    }
}

export = function (entityWithContext:any, companyContextId:number, tabModel:any) {
    return new ContextLinksTab(entityWithContext);
};