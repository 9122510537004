///<amd-module name="Core/Medius.Core.Web/Scripts/components/topBar/TopBarRightSide" />
import * as React from "react";
import { getBasePath } from "Core/Medius.Core.Web/Scripts/Medius/lib/path";
import { 
    IconActionDownloadRegular,
    IconSystemSettingsFill
} from "@medius/ui-controls";
import { UserMenu } from "Core/Medius.Core.Web/Scripts/components/topBar/rightSide/UserMenu";
import { HelpMenu } from "Core/Medius.Core.Web/Scripts/components/topBar/rightSide/TopBarHelpMenu";
import { getNavLinkClassName, APANavigationRoutes, isActivePage } from "Core/Medius.Core.Web/Scripts/components/topBar/topBarUtils";
import { useLocation } from "react-router-dom";

enum MenuNames {
    empty,
    userMenu,
    helpMenu
}

export interface ITopBarRightSideProps{
    toggleModal: () => void;
    toggleQAIcon: () => void;
}

export const RightSide = ({ toggleModal, toggleQAIcon }: ITopBarRightSideProps) => {
    const { pathname } = useLocation();
    const administrationUrl = `${getBasePath()}#/Administration`;
    const [visibleMenu, setVisibleMenu] = React.useState<MenuNames>(MenuNames.empty);

    const isUserMenuVisible = visibleMenu === MenuNames.userMenu;
    const isHelpMenuVisible = visibleMenu === MenuNames.helpMenu;

    const handleMenuVisibilityChange = (menuName: MenuNames) => (show: boolean) => 
        show ? setVisibleMenu(_prev => menuName) : setVisibleMenu(_prev => MenuNames.empty);

    return (
        <div className="top-bar__right_side">
            <a 
                className="top-bar-icon-holder top-bar__pending-exports"
                data-testid="open-pending-async-exports-modal"
                onClick={toggleModal}>
                <IconActionDownloadRegular size="medium" className="top-bar__icon" />
            </a>
            <a 
                href={administrationUrl}
                className={getNavLinkClassName(isActivePage(APANavigationRoutes.administrationUrl, pathname))}
                data-testid="administration-link">
                <IconSystemSettingsFill size="medium" className="top-bar__icon" />
            </a>
            <HelpMenu 
                isHelpMenuVisible={isHelpMenuVisible}
                onHelpMenuVisibilityChange={handleMenuVisibilityChange(MenuNames.helpMenu)}
                toggleQaIconVisibility={toggleQAIcon} />
            <UserMenu 
                isUserMenuVisible={isUserMenuVisible} 
                onUserMenuVisibilityChange={handleMenuVisibilityChange(MenuNames.userMenu)} 
            />
        </div>
    );
};