///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/editors/unitprice/model"/>
import * as _ from "underscore";
import * as componentFactory from "Core/Medius.Core.Web/Scripts/Medius/components/editors/financeComponent/model";
import * as settingsProvider from "Core/Medius.Core.Web/Scripts/Medius/core/settingsProvider";

class UnitPriceField extends componentFactory.FinanceComponentField {
    constructor(bindingParameters:any, viewmodel:any, context:any) {
        super(bindingParameters, viewmodel, context);
    }

    getResolution(currency:any) {
        return settingsProvider.getUnitPriceDisplayResolution(currency);
    }

    getValidationErrorMsgKey() {
        return "#Core/unitPriceFieldCannotBeEmpty";
    }
}

export function create(bindingParameters:any, viewmodel:any, context:any) {
    return new UnitPriceField(bindingParameters, viewmodel, context);
}