/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/ImportConfiguration/services"/>
import {get, put} from "Core/Medius.Core.Web/Scripts/Medius/core/fetch/rest";
import {ImportConfigurationGridDataDto, ImportConfigurationFieldDto, Company, IntegrationUpdateConfigurationData} from "./interfaces";
import * as rest from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rest";


export function getGridItems(skip: number, take: number, importInOrder: boolean, orderBy: string, orderByDir: string) {
    return get<ImportConfigurationGridDataDto>(`importConfiguration/gridEntries?skip=${skip}&take=${take}&importInOrder=${importInOrder}&orderBy=${orderBy}&orderByDir=${orderByDir}`, { });
}

export function getItemFields(id: number, companyid: number) {
    return get<Array<ImportConfigurationFieldDto>>(`importConfiguration/${id}/${companyid}/integrationUpdateConfiguration`, {  });
}

export const getCompanies = (searchPhrase: string = ''): JQueryPromise<Company[]> => {
    const encodedSearchPhrase = encodeURIComponent(searchPhrase);
    return rest.get(`importConfiguration/search`, `?searchPhrase=${encodedSearchPhrase}`);
};

export function updateConfigurationItem(importConfigurationId: number, data: IntegrationUpdateConfigurationData) {
    return put<IntegrationUpdateConfigurationData>(`Backend/Rest/importConfiguration/${importConfigurationId}`, data);
}