/// <amd-module name="Core/Medius.Core.Web/Scripts/shared/hooks/useLegacyMounted"/>
import { useEffect, useRef, RefObject } from 'react';

// used only for handling resolved non-fetch promise on dismounted react components

export function useLegacyMounted(): RefObject<boolean> {
    const mounted = useRef(false);

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, []);

    return mounted;
}
