///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/editors/helpers" />

import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as _ from "underscore";
import { formatValueWithPlaceholders } from "Core/Medius.Core.Web/Scripts/Medius/lib/stringFormat";

export function mergeDefaults(params: any, defaults: any) {
    const tempOptions = {};

    if (_.isObject(params.options)) {
        _.extend(tempOptions, defaults.options, params.options);
    }

    _.extend(defaults, params);

    if (_.isObject(params.options)) {
        _.extend(defaults.options, tempOptions);
    }

    return defaults;
}

/**
* Cheks if number is in given range
*
* @param   {value}      number  Localized number
* @param   {minValue}   number  Range begin (A -
* @param   {maxValue}   number  Range ends - B)
* @returns {Bool}   If value is beetewen true, else validation object
* e.g isValueInRange(2, 10, 15); // returns {false, "value doesn't fall into range"}
*/
export function isValueInRange(value: any, minValue: number, maxValue: number): { valid: boolean } {
    const getErrorResult = function (messageTranslationKey: any, translationArguments: any) {
        const msg = globalization.getLabelTranslation(messageTranslationKey);

        return {
            valid: false,
            validationDetails: formatValueWithPlaceholders(msg, [translationArguments[0], translationArguments[1]])
        };
    };

    if (_(value).isUndefined() ||
        _(value).isNull() || 
        isNaN(value))
        return getErrorResult("#Core/valueIsUndefined", []);

    if (!_(value).isNumber())
        return getErrorResult("#Core/valueIsNotNumber", []); 

    if (maxValue != null &&
        minValue != null &&
        (value > maxValue || value < minValue))
        return getErrorResult("#Core/valueMustBeInRange_min_max", [minValue, maxValue]);

    if (maxValue != null &&
        value > maxValue)
        return getErrorResult("#Core/valueMustBeLowerOrEqualTo_max", [maxValue]);

    if (minValue != null &&
        value < minValue)
        return getErrorResult("#Core/valueMustBeGreaterOrEqualTo_min", [minValue]);

    return {
        valid: true
    };
}

export function toFixed(number: any, precision: any) {
    const multiplier = Math.pow(10, precision);
    return Math.round(number * multiplier) / multiplier;
}

/**
* Decimal adjustment of a number, midpoint, round, floor, ceil
* @param   {String}    type    The type of adjustment.
* @param   {Number}    value   The number.
* @param   {Integer}   exp     The exponent (the 10 logarithm of the adjustment base).
* @returns {Number}            The adjusted value.
* e.g decimalAdjust('ceil', 55.51, -1); // returns 55.6
*/
export function convertDecimal(type: keyof Math, value: any, exp: any) {
    // If the exp is undefined or zero...
    if (_.isUndefined(type)) {
        return value;
    }

    if (_.isUndefined(exp) || +exp === 0) {
        return (Math as any)[type](value);
    }
    value = +value;
    exp = +exp;
    // If the value is not a number or the exp is not an integer...
    if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
        return NaN;
    }

    // Shift
    value = value.toString().split('e');
    value = (Math as any)[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
    // Shift back
    value = value.toString().split('e');
    return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
}

export function decimalAdjust(type: any, value: any, exp: any) {
    let retValue;
    //for midpoint rounding
    //if value is less than 0 than use round-floor rounding
    //use round-up as defualt for values over 0
    if (type === 'midpoint') {
        if (value > 0) {
            return convertDecimal("round", value, exp);
        } else {
            retValue = convertDecimal("round", Math.abs(value), exp);
            return -retValue;
        }
    } else {
        return convertDecimal(type, value, exp);
    }
}

///return string value "0" or "1" depend on user input in grid bool column search header
export function getBoolFromString(searchText: any) {
    const searchString = searchText.toLowerCase(),
        trueLabel = globalization.getLabelTranslation("#Core/yes"),
        falseLabel = globalization.getLabelTranslation("#Core/no");

    if (falseLabel.toLowerCase().indexOf(searchString) !== -1) {
        return "0";
    }
    if (trueLabel.toLowerCase().indexOf(searchString) !== -1) {
        return "1";
    }

    switch (searchString) {
    case "n":
    case "no":
    case "0":
        return "0";
    case "y":
    case "yes":
    case "1":
        return "1";
    default:
        return searchString;
    }
}

export function preciseRound(value: any, decPlaces: any) {
    let val = value * Math.pow(10, decPlaces);
    val = Math.round(val) / Math.pow(10, decPlaces);

    return val;
}

export function roundNumberAsString(value: any, displayResolution: any) {
    let fixedValue = value.split('.')[0];
    let fractionValue = parseFloat("." + value.split('.')[1]);
    if (isNaN(fractionValue)) {
        fractionValue = 0;
    }
    
    fractionValue = preciseRound(fractionValue, displayResolution);
    
    if (fractionValue === 1) {
        const tempFixedValue = [];
        let overflow = 1;
        for (let fixedCharNo = fixedValue.length - 1; fixedCharNo >= 0; fixedCharNo--) {
            const revFixedCahrNo = fixedValue.length - 1 - fixedCharNo;
            if (overflow === 1) {
                const charCode = fixedValue.charCodeAt(fixedCharNo);
                if (charCode === '9'.charCodeAt(0)) {
                    tempFixedValue[revFixedCahrNo] = '0';
                } else {
                    tempFixedValue[revFixedCahrNo] = String.fromCharCode(overflow + charCode);
                    overflow = 0;
                }
            } else {
                const digit = fixedValue.charAt(fixedCharNo);
                tempFixedValue[revFixedCahrNo] = digit;
            }
        }
        if (overflow === 1) {
            tempFixedValue[fixedValue.length] = '1';
        }
        fixedValue = tempFixedValue.reverse().join('');
    }

    const stringFractionValue = fractionValue.toFixed(displayResolution).split('.')[1];
    
    if (displayResolution === 0) {
        return fixedValue;
    } else {
        return `${fixedValue}.${stringFractionValue}`;
    }
}