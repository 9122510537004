///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/with/withBulkModule"/>
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as ko from "knockout";

const loadModule = function (moduleName:any, context:any) {
    const chosenAction = context.$data;
    let moduleVm = chosenAction.getViewModel();

    if (moduleVm) {
        return moduleVm;
    }

    const module = require(moduleName);
    moduleVm = module.init(chosenAction.tasksIds, chosenAction.activity, chosenAction.documentType, chosenAction.taskDescription);
    
    chosenAction.registerViewModel(moduleVm);

    return moduleVm;
};

const withBulkModule = {
    init: function (element:any, bindingParams:any, allBindingsAccessor:any, viewModel:any, context:any) {
        const module = bindingParams();

        const moduleVm = loadModule(module, context);

        const childContext = context.createChildContext(moduleVm);

        ko.applyBindingsToDescendants(childContext, element);
        return { controlsDescendantBindings: true };
    }
};

export function register(){
    koUtils.registerBinding("withBulkModule", withBulkModule);
}