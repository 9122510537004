///<amd-module name = "Core/Medius.Core.Web/Scripts/components/taskHandling/fraudPopup/service"/>
import * as rest from "Core/Medius.Core.Web/Scripts/Medius/core/fetch/rest";
import { 
    RiskFactorCode, 
    RiskFactorReasonCode, 
    RiskFactorStatusForInvoice, 
    RiskFactorsDetailsResponseDTO 
} from "Core/Medius.Core.Web/Scripts/Medius/apps/task/RiskFactor/RiskFactorComponentDtos/riskFactorsForInvoiceDto";

export const saveRiskDetails = async (
    taskId: number, 
    data: { 
        RiskFactorCode: RiskFactorCode, 
        HandlingReason: RiskFactorReasonCode
    }[]
) => {
    await rest.post(`Backend/Rest/riskFactors/invoice/${taskId}`, data);
};

export const getRiskDetails = async (taskId: number) => {
    return await rest.get<RiskFactorsDetailsResponseDTO>(`riskFactors/invoice/${taskId}`);
};

export const getRiskStatus = async (taskId: number) => {
    return await rest.get<RiskFactorStatusForInvoice>(`riskFactors/invoice/status/${taskId}`);
};

export const canHandleRisks = async (taskId: number) => {
    return await rest.get<boolean>(`riskFactors/invoice/canHandleRisks/${taskId}`);
};