/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/AdministrationFavouriteLink/Roles/RolesDataSource"/>
import {translate} from "Core/Medius.Core.Web/Scripts/lib/globalization";
import BaseDataSource = require("Core/Medius.Core.Web/Scripts/Medius/components/grid/dataSource/default");

export class RolesDataSource extends BaseDataSource {
    public loadColumns: () => any[];
    public load: (dataQuery: any) => any;

    private columns: any[];

    constructor() {
        super();
        this.columns = [
            {
                Key: "Name",
                Name: translate("#Core/role"),
                Searchable: false,
                Sortable: false,
                ValueSource: "Name",
                Width: 50
            },
            {
                Key: "Remove",
                Name: translate("#Core/remove"),
                Searchable: false,
                Sortable: false,
                ValueSource: "",
                Width: 50,
                CellTemplate: "Core:AdminPages/AdministrationFavouriteLink/Roles/RemoveRoleCellTemplate.html"
            }
        ];

        this.loadColumns = () => this.columns;

        this.load = (dataQuery: any) => {
            return dataQuery.entities();
        };
    }
}
