///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/dataObjectType/dataProvider"/>
import * as types from "Core/Medius.Core.Web/Scripts/Medius/core/type";
import { DataProvider } from "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/default/dataProvider";
import * as _ from "underscore";
import { isEmptyString } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";

export class DOTypeDataProvider extends DataProvider {
    results: any[];
    constructor() {
        super();

        this.results = _(types.cache).pairs().filter((pair) => {
            return pair[0] !== "$type" && pair[0].indexOf("System.") !== 0;
        });
    }

    createRegexSet(phrase:string) {
        let keywords = _.isString(phrase) ? phrase.replace("*", "(.*)").split(" ") : [];
        keywords = _.filter(keywords, function (keyword) {
            return !isEmptyString(keyword);
        });

        return _.map(keywords, function (keyword) {
            return new RegExp(`${keyword.toLowerCase()}`);
        });
    }

    search(phrase:string, maxResults:number, options:any) {
        const regexSet = this.createRegexSet(phrase);

        let result = this.results.filter(item => {
            const typeName = item[0];
            const type = item[1];

            if (options.customFilter && !options.customFilter(type, typeName)) {
                return false;
            }

            return true;
        });

        if (regexSet.length > 0) {
            result = result.filter(item => {
                return regexSet.some(regex => {
                    let searchResult = regex.test(item[0].toLowerCase());
                    if (options.customDescriptor) {
                        searchResult = searchResult || regex.test(options.customDescriptor(item[0]).toLowerCase());
                    }
                    return searchResult;
                });
            });
        }

        result = _.chain(result)
            .first(maxResults)
            .map(function (pair) {
                return pair[0];
            })
            .value();

        return $.Deferred().resolve({
            Results: result,
            SelectedItemIndex: null
        });
    }
}

const instance = new DOTypeDataProvider();

export function create() {
    return instance;
}