/// <amd-module name="Core/Medius.Core.Web/Scripts/lib/development/mode"/>
import * as React from "react";

// We check mode by checking if React is in production or development mode
// Based on https://stackoverflow.com/questions/52702466/detect-react-reactdom-development-production-build
function ReactIsInDevelopmentMode(){ 
    return "_self" in React.createElement("div");
}

export function isProduction() {
    return !ReactIsInDevelopmentMode();
}
