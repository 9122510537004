/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/ImportManagement/importManagementPage"/>
import * as React from "react";
import {ImportManagement} from "./importManagement";
import {registerAdministrationEntity} from "Core/Medius.Core.Web/Scripts/AdminPages/administrationServices";
import {useSelector} from "react-redux";
import {RootState} from "Core/Medius.Core.Web/Scripts/shared/store/reduxStore";
import { NoAccess } from "Core/Medius.Core.Web/Scripts/Medius/apps/noAccess/noAccess";

function ImportManagementPage() {
    const hasAccess = useSelector((state: RootState) => state.accesses.messageManagement);

    return hasAccess ? <ImportManagement /> : <NoAccess />;
}

export function registerImportManagementAdminPage() {
    registerAdministrationEntity("ImportManagement", ImportManagementPage);
}
