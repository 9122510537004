/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/dashboard/gadgetSetting"/>

import {observable} from "knockout";

class GadgetSetting {
    public Id: number;
    public Name: string;
    public InputName: string;
    public Description: string;
    public Type: string;
    public Value: ko.Observable<any>;
    public AvailableValues: Array<any>;
    public MinValue: ko.Observable<number>;
    public MaxValue: ko.Observable<number>;

    constructor() {
        this.Id = null;
        this.Name = null;
        this.InputName = null;
        this.Description = null;
        this.Type = null;
        this.Value = observable(null);
        this.AvailableValues = [];
        this.MinValue = observable(0);
        this.MaxValue = observable(0);
    }
}

export function create() {
    return new GadgetSetting();
}
