/// <amd-module name="Core/Medius.Core.Web/Scripts/components/topBar/LeftSideOuterContainer"/>
import { LocalizationProvider } from "@progress/kendo-react-intl";
import { store } from "Core/Medius.Core.Web/Scripts/shared/store/reduxStore";
import * as React from "react";
import { Provider } from "react-redux";
import { HashRouter } from "react-router-dom";
import { LeftSide } from "Core/Medius.Core.Web/Scripts/components/topBar/TopBarLeftSide";

interface IProps{
    versionMismatchData: {
        isVersionInvalid: boolean;
        isSessionExpired: boolean;
    };
    documentTypes: {
        name: string;
        url: string;
    }[];
    isQaIconVisible: boolean;
}

export const LeftSideOuterContainer = ({versionMismatchData, documentTypes, isQaIconVisible}: IProps) => {
    return <LocalizationProvider language="current-language">
        <Provider store={store}>
            <HashRouter>
                <LeftSide versionMismatchData={versionMismatchData} documentTypes={documentTypes} isQaIconVisible={isQaIconVisible} />
            </HashRouter>
        </Provider>
    </LocalizationProvider>;
};