///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/editors/date/desktop"/>
import * as ko from "knockout";
import * as _ from "underscore";
import * as dateUtils from "Core/Medius.Core.Web/Scripts/Medius/lib/utils/date";
import defaults = require("Core/Medius.Core.Web/Scripts/Medius/components/editors/date/datepickerDefaults");

function convert(dateFormat:any) {
    if (/MMMM|MMM/.test(dateFormat)) {
        dateFormat = dateFormat.replace("MMMM", "MM");
        dateFormat = dateFormat.replace("MMM", "M");
    }
    else {
        dateFormat = dateFormat.replace("MM", "mm");
        dateFormat = dateFormat.replace("M", "m");
    }

    dateFormat = dateFormat.replace("dddd", "DD");
    dateFormat = dateFormat.replace("ddd", "D");

    return dateFormat;
}

export = function (dateModel:any, element:any, $inputElement:any) {
    const dateFormat = dateModel.Localizer().getDateFormat();
    const configuration = _({}).extend(defaults, { format: convert(dateFormat) });

    $inputElement
        .bootstrapDatepicker(configuration)
        .on("changeDate", function (ev:any) {
            const date = dateUtils.fromUserLocal(ev.date);
            const localizedDate = dateModel.Localizer().toString(date);

            dateModel.InputValue(localizedDate);
        });

    ko.utils.domNodeDisposal
        .addDisposeCallback(element, function () {
            $inputElement.bootstrapDatepicker("remove");
            $inputElement.off("changeDate");
        });
};
