/// <amd-module name="Core/Medius.Core.Web/Scripts/Paging/PageIndex"/>
import * as ko from "knockout";
export class PageIndex{
    public Label:number;
    public IsActive:ko.Computed<boolean>;
    
    constructor(pageIndex:number, actualPageNumber:ko.Observable<number>){
        this.Label = pageIndex;

        this.IsActive = ko.computed(() => {
            return pageIndex === actualPageNumber();
        });
    }
}