/// <amd-module name="Core/Medius.Core.Web/Scripts/components/topBar/topBar"/>
import * as _ from "underscore";
import * as ko from "knockout";
import { QuickSearch } from "Core/Medius.Core.Web/Scripts/components/quickSearch/quickSearch";
import { getOutboxInstance } from "Core/Medius.Core.Web/Scripts/components/outbox/outbox";
import { getNotificationCenterInstance } from "Core/Medius.Core.Web/Scripts/components/notificationCenter/notificationCenter";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { observableArray, observable } from "knockout";
import { DrawerWithStore } from "./drawer/drawerWithStore";
import { open as openSecuritySettingsManager } from "Core/Medius.Core.Web/Scripts/components/securitySettingsManager/securitySettingsManager";
import { getBasePath } from "Core/Medius.Core.Web/Scripts/Medius/lib/path";
import { store } from "Core/Medius.Core.Web/Scripts/shared/store/reduxStore";
import { Module } from "../../Medius/apps/reactSpa/app";
import { invalidVersion } from "Core/Medius.Core.Web/Scripts/lib/validation/validators/versionValidator";
import { VersionMismatchDialogTemplate } from "Core/Medius.Core.Web/Scripts/components/versionMismatchDialog/versionMismatchDialogTemplate";
import { hasSessionExpired } from "Core/Medius.Core.Web/Scripts/Medius/session/extender";
import pendingAsyncExports = require("Core/Medius.Core.Web/Scripts/Medius/core/export/async/pending");
import { LeftSideOuterContainer as LeftSide } from "Core/Medius.Core.Web/Scripts/components/topBar/LeftSideOuterContainer";
import { RightSideOuterContainer as RightSide } from "Core/Medius.Core.Web/Scripts/components/topBar/RightSideOuterContainer";
import * as appConfig from "Core/Medius.Core.Web/Scripts/appConfig";

export enum MenuNames {
    documentMenu,
    userMenu,
    helpMenu
}

export class TopBar {
    public quickSearch = new QuickSearch();
    public outbox = getOutboxInstance();
    public notificationCenter = getNotificationCenterInstance();
    public documentTypes = observableArray<any>([]);
    public isQaIconVisible = observable<boolean>(appConfig.isQaEnvironmentNoteVisible());

    private documentTypesUnsubscribe = store.subscribe(() => {
        this.loadDocumentTypes();
    });

    public DrawerBindingConfig = {
        functionComponent: DrawerWithStore
    };

    public showPendingExportsModal(){
        pendingAsyncExports.showPendingExportsModal();
    }

    public toggleQAIcon = () => {
        const prevState = this.isQaIconVisible();
        this.isQaIconVisible(!prevState);
    };

    public topBarLeftSideComponentConfiguration = ko.pureComputed(() => {
        return ({
            functionComponent: LeftSide,
            props: {
                versionMismatchData: {
                    isVersionInvalid: invalidVersion(),
                    isSessionExpired: hasSessionExpired()
                },
                documentTypes: this.documentTypes(),
                isQaIconVisible: this.isQaIconVisible()
            }
        });
    });

    public topBarRightSideComponentConfiguration = ko.pureComputed(() => {
        return ({
            functionComponent: RightSide,
            props: {
                toggleModal: this.showPendingExportsModal,
                toggleQAIcon: this.toggleQAIcon
            }
        });
    });

    constructor() {
        if (!_.isEmpty(store.getState()?.documentTypes)) {
            this.loadDocumentTypes();
        }
    }

    public openChangePasswordModal() {
        openSecuritySettingsManager();
    }

    public dispose() {
        this.documentTypesUnsubscribe();
    }

    private loadDocumentTypes() {
        const pv2ModuleEnabled = store.getState().accesses?.enabledModules?.includes(Module.PV2);

        let documentTypesForCreate = store.getState().documentTypes.documentTypesForCreate;
        if (!_.isEmpty(documentTypesForCreate)) {
            if (pv2ModuleEnabled) {
                documentTypesForCreate = documentTypesForCreate.filter(documentTypes =>
                    documentTypes.type !== "Medius.Procurement.Entities.PurchaseRequisition");
            }
            this.documentTypes(documentTypesForCreate.map((documentType) => {
                return {
                    name: translate(`#${documentType.type}`),
                    url: `${getBasePath()}#/DocumentCreate/Create/${documentType.type}`
                };
            }));
        }
    }

    public VersionMismatchDialog = ko.pureComputed(() => {
        return ({
            functionComponent: VersionMismatchDialogTemplate,
            props: {
                isVersionInvalid: invalidVersion(),
                isSessionExpired: hasSessionExpired(),
            }
        });
      });
}
