///<amd-module name = "Core/Medius.Core.Web/Scripts/Models/Dtos/companyAutocompleterDto"/>
import mappings = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/mapping/collection/instance");
import {unpack} from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";

const type = "Medius.Core.DTOs.CompanyAutocompleterDto";

export function register() {
    mappings.register(type, {
        toJS: function(viewModel:any) {
            return {
                $type: "Medius.Core.DTOs.CompanyAutocompleterIncomingDto",
                Id: unpack(viewModel.Id)
            };
        }
    });
}