///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/components/copyRequisition/validationResult/validationResultsModalContent" />
import * as React from "react";
import { useState } from "react";
import { FlexLayout, Text, IconStatusErrorFill, IconStatusWarningFill, Switch } from "@medius/ui-controls";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { ValidationResultRow } from "Core/Medius.Core.Web/Scripts/Medius/components/components/copyRequisition/validationResult/validationResultRow";
import { CopyRequisitionLineDetailsDto } from "Core/Medius.Core.Web/Scripts/Medius/components/components/copyRequisition/DTOs/copyRequisitionValidationResultDto";

type ValidationResultsModalContentProps = {
    lineValidationResults: CopyRequisitionLineDetailsDto[],
    countOfCartLines: number,
    headerError: string
};

export const ValidationResultsModalContent = ({ lineValidationResults, countOfCartLines, headerError }: ValidationResultsModalContentProps) => {

    const [showErrorsOnly, setShowErrorsOnly] = useState<boolean>(false);
    const [resultsFiltered, setResultsFiltered] = useState<CopyRequisitionLineDetailsDto[]>(lineValidationResults);

    const areAllLinesValid = lineValidationResults?.some(r => r.validationErrorMessages?.length > 0) == false;
    const areAllLinesInvalid = lineValidationResults?.some(r => r.validationErrorMessages?.length == 0) == false;

    const getHeaderIcon = () => {
        return areAllLinesInvalid || headerError != ""
            ? <IconStatusErrorFill data-testid="validation-results-modal-error-icon" size="large" color="error" />
            : <IconStatusWarningFill data-testid="validation-results-modal-warning-icon" size="large" color="warning" />;
    };

    const getModalHeader = () => {
        const invalidLinesCount = lineValidationResults?.filter(r => r.validationErrorMessages?.length > 0).length;

        if (areAllLinesInvalid || headerError != "")
            return translate("#Procurement/copyRequisitionImpossible");

        if (invalidLinesCount > 0)
            return translate("#Procurement/copyRequisitionProblem");

        if (countOfCartLines > 0)
            return translate("#Procurement/cartWillBeOverwritten");
    };

    const compileMessage = () => {
        const messages: string[] = [];
        const invalidLinesCount = lineValidationResults?.filter(r => r.validationErrorMessages?.length > 0).length;

        if (headerError != "")
            return headerError;

        if (areAllLinesInvalid)
            return translate("#Procurement/copyRequisitionAllLinesInvalidMsg");

        if (invalidLinesCount > 0)
            messages.push(translate("#Procurement/copyRequisitionSomeLinesInvalidMsg"));

        if (countOfCartLines > 0)
            messages.push(translate("#Procurement/copyRequisitionRemovalOfCartMsg"));

        return messages.join(" ");
    };

    const onSwitchChangeHandler = () => {
        const newValue = !showErrorsOnly;
        setShowErrorsOnly(!showErrorsOnly);

        setResultsFiltered(lineValidationResults.filter((r) => {
            if (newValue) {
                return r.validationErrorMessages?.length > 0;
            }
            else
                return true;
        }));
    };

    const getPartNumber = (message: CopyRequisitionLineDetailsDto) => {
        if (message.product != null)
            return message.product.productDetails.partNumber;

        if (message.freeTextItem != null)
            return message.freeTextItem.freeTextLine.partNumber;

        if (message.punchOutItem != null)
            return message.punchOutItem.partNumber;

        return "";
    };
    
    const getSupplier = (message: CopyRequisitionLineDetailsDto) => {
        if (message.product != null)
            return message.product.productDetails.supplierName;

        if (message.freeTextItem != null)
            return message.freeTextItem.freeTextLine.supplier;

        if (message.punchOutItem != null)
            return message.punchOutItem.supplierName;
    };

    const getDescription = (message: CopyRequisitionLineDetailsDto) => {
        if (message.product != null)
            return message.product.productDetails.description;

        if (message.freeTextItem != null)
            return message.freeTextItem.freeTextLine.description;
    };

    return (
        <>
            <FlexLayout direction="column" horizontalAlignment="center">
                {getHeaderIcon()}
                <Text variant="heading2" data-testid="validation-results-header">{getModalHeader()}</Text>
                <Text variant="body" alignment="center" data-testid="validation-results-header-message">{compileMessage()}</Text>
            </FlexLayout>
            <FlexLayout direction="column" spacerMode="on">
                {!(areAllLinesInvalid || areAllLinesValid) && headerError == "" &&
                    <FlexLayout direction="column" horizontalAlignment="left">
                        <Switch data-testid="validation-results-switch" rightLabel={translate("#Procurement/showErrorsOnly")} onChange={onSwitchChangeHandler} />
                    </FlexLayout >
                }
                {!areAllLinesValid && headerError == "" &&
                    <div className="copy-requisition__error-container" data-testid="copy-requisition-error-container">
                        {resultsFiltered?.map((msg) => {
                            return (
                                <ValidationResultRow key={msg.lineNumber} lineNumber={msg.lineNumber} partNumber={getPartNumber(msg)} supplier={getSupplier(msg)} description={getDescription(msg)} validationErrors={msg.validationErrorMessages} />
                            );
                        })}
                    </div>
                }
            </FlexLayout>
        </>
    );
};