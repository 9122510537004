/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/inbox/bulk/steps/basicOperation"/>

import { request } from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rpc";
import * as notification from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as ko from "knockout";
import { post } from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rest";
import { Modal } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/bulk/modal";
import { Step } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/bulk/step";
import { getTasksIds } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/helpers";
import unique from "Core/Medius.Core.Web/Scripts/lib/collections/unique";

export abstract class BasicOperation extends Step {
    public tasksIds: number[];
    public activityName: string;
    public documentType: string;
    public taskDescription: string;

    public availableTaskResults = ko.observableArray([]);
    public isLoading = ko.observable(false);

    public hasTaskResults: ko.Computed<boolean>;
    public chosenTaskResult: ko.Observable<string> = ko.observable(null);
    public chosenAction: ko.Computed<any>;

    public newFinalBookingDate: ko.Observable<any>;
    public newPreferredPaymentDate: ko.Observable<any>;

    public transformTaskResults: (taskResults: any[]) => any[];

    constructor(tasks: any, chosenAction: ko.Computed<any>) {
        super(getLabelTranslation("#Core/operationDetails"),
            "Core:Medius/apps/inbox/bulk/templates/operation.html");

        this.transformTaskResults = (taskResults: any[]) => taskResults;
        this.tasksIds = getTasksIds(tasks);
        this.activityName = this.resolveActivity(tasks);
        this.documentType = this.resolveDocumentsType(tasks);
        this.taskDescription = this.resolveTaskDescription(tasks);
        this.hasTaskResults = ko.pureComputed(() => this.availableTaskResults().length > 0);
        this.chosenAction = chosenAction;

        this.newFinalBookingDate = ko.observable(null);
        this.newPreferredPaymentDate = ko.observable(null);
    }

    public loadAvailableTaskResults() {
        this.isLoading(true);
        return request(
            "TaskManager", 
            "GetTaskResults", 
            { taskId: this.tasksIds[0]}
        )
        .then((taskResults: any[]) => {
            if (taskResults.length > 0) {
                taskResults.forEach((taskResult) => {
                    taskResult.Name = getLabelTranslation(taskResult.Name);
                    taskResult.chosenTaskResult = this.chosenTaskResult;
                });
                taskResults = this.transformTaskResults(taskResults);
                this.availableTaskResults(taskResults);
            }
        })
        .always(() => {
            this.selectFirstAvailableTaskResult();
            this.isLoading(false);
        });
    }

    public selectFirstAvailableTaskResult() {
        this.chosenTaskResult(this.hasTaskResults() ? this.availableTaskResults()[0].Code : "");
    }

    private getModifications() {
        const set = [];

        if (this.newFinalBookingDate()) {
            set.push({ Key: "FinalBookingDate", Value: this.newFinalBookingDate() });
        }

        if (this.newPreferredPaymentDate()) {
            set.push({ Key: "PreferredPaymentDate", Value: this.newPreferredPaymentDate() });
        }

        return set;
    }

    public save() {
        if (!this.validate()) { return false; }

        const taskResult = this.hasTaskResults() ?
            this.availableTaskResults().find(tr => tr.Code === this.chosenTaskResult()) : null;

        if (this.chosenAction().id === "update") {
            return post("BulkOperationService", "Update", {
                DocumentType: this.documentType,
                ActivityDefinitionName: this.activityName,
                AffectedTasks: this.tasksIds,
                Modifications: this.getModifications()
            });
        }

        return request("TaskManager", "BulkCompleteTask", {
            taskIds: this.tasksIds,
            taskResult
        });
    }

    public validate() {
        if (this.chosenTaskResult() == null) {
            notification.error("Please select workflow action to perform on selected documents.");
            return false;
        }
        return true;
    }

    public onOpen(modal: Modal): void {
        modal.data.chosenAction.registerViewModel(this);
    }

    private resolveDocumentsType(tasks: any[]){
        const types = unique(tasks
            .map((task: any) => task.taskGroup.fullName), fullName => fullName);

        return types.length === 1 ? types[0] : null;
    };

    private resolveActivity(tasks: any[]){
        const activities = unique(tasks
            .map((task: any) => task.Activity), activity => activity);

        return activities.length === 1 ? activities[0] : null;
    };

    private resolveTaskDescription(tasks: any[]){
        const descriptions = unique(tasks
            .map((task: any) => task.TaskDescription), taskDescription => taskDescription);

        return descriptions.length === 1 ? getLabelTranslation(descriptions[0]) : null;
    }
}
