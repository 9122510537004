///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/task/RiskFactor/RiskDescriptions/RandomizedExtraApproval"/>
import * as React from 'react';
import { getLabelTranslation } from 'Core/Medius.Core.Web/Scripts/lib/globalization';
import { IHandledInfo, RiskFactorCode, RiskFactorReasonCode } from 'Core/Medius.Core.Web/Scripts/Medius/apps/task/RiskFactor/RiskFactorComponentDtos/riskFactorsForInvoiceDto';
import { DatePresenter } from 'Core/Medius.Core.Web/Scripts/shared/components/datePresenter';
import { getRiskFactorNameTranslation } from 'Core/Medius.Core.Web/Scripts/Medius/apps/task/RiskFactor/riskFactorUtils';

export interface IRandomizedExtraApprovalProps {
    isRiskHandled: boolean;
    handledInfo?: IHandledInfo;
    currentReasonCode: RiskFactorReasonCode;
}

export const RandomizedExtraApproval = ({ isRiskHandled, handledInfo, currentReasonCode }: IRandomizedExtraApprovalProps) => {
    return (
        <>
        <h4>{getRiskFactorNameTranslation(RiskFactorCode.RandomizedExtraApproval)}</h4>
        { isRiskHandled ? 
            <>
                <div className="risk-factor-icon-container__handlerDetails">
                    <p style={{margin: "0px"}}>{getLabelTranslation('#Core/handledBy')}</p>
                    <p>{handledInfo.handledByUser.firstName}</p>
                    <p style={{marginRight: "5px"}}>{handledInfo.handledByUser.lastName}</p>
                    <DatePresenter 
                        date={new Date(handledInfo.handledAt)} 
                        format="G"
                    />
                </div>
                <p>{resolveReasonCodeTranslation(currentReasonCode)}</p> 
            </> :
            <p>{getLabelTranslation("#Core/randomizedExtraApprovalInfoText")}</p>
        }
        </>
    );
};

function resolveReasonCodeTranslation(handlingReasonCode: RiskFactorReasonCode){
    switch (handlingReasonCode) {
        case RiskFactorReasonCode.InvoiceArchived:
            return getLabelTranslation("#Core/handlingReasonCodeInvoiceArchived");
        case RiskFactorReasonCode.InvoiceDeletedFraudAttempt:
            return getLabelTranslation("#Core/handlingCodeInvoiceDeletedFraudAttempt");
        case RiskFactorReasonCode.InvoiceDeletedOther:
            return getLabelTranslation("#Core/handlingCodeInvoiceDeletedOther");
        case RiskFactorReasonCode.InvoiceDeletedDuplicateInvoice:
            return getLabelTranslation("#Core/handlingCodeInvoiceDeletedDuplicateInvoice");
        default:
            console.warn(`[RandomizedExtraApproval] handling reason code ${handlingReasonCode} was not implemented for this risk factor`);
    }
}