/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/type"/>

import * as rest from "Core/Medius.Core.Web/Scripts/Medius/core/rest";
import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import { when } from "jquery";
import * as  _ from "underscore";
import * as logger from "Core/Medius.Core.Web/Scripts/Medius/lib/logger";

const companyRelatedTypes = [
    "Medius.Core.Entities.User"
];
const companyRelatedName = "Medius.Core.Entities.ICompanyRelated";

function parseGenericAgrs(argsString: string): any[] {
    let start = 0,
        depth = 0,
        current;

    const result: any[] = [];

    for (let i = 0; i < argsString.length; i++) {
        switch (argsString[i]) {
            case "[":
                if (depth === 0) {
                    start = i + 1;
                }
                depth += 1;
                break;
            case "]":
                if (depth === 1) {
                    current = argsString.substring(start, i - 1);
                    result.push(current);
                }
                depth -= 1;
                break;
            default:
                break;
        }
    }
    return result.length ? result : [argsString];
}

export let preloaded = false;

export let cache: any = {};

export function resolve(fullName: string, options: any) {
    return rest.get("TypeDescriptionService", "allTypes/" + fullName, options);
}

export function resolveAll(applicationName: string, applicationVersion: string, options: any) {
    return rest.get(
        "TypeDescriptionService",
        "applicationTypes/" + applicationName + "/" + applicationVersion,
        options
    );
}

export function getTypeName(fullName: string) {
    if (isNullOrUndefined(fullName)) {
        return null;
    }
    if (fullName.indexOf("`") > -1) {
        const matches = fullName.match(/^([\w\W]+`\d+)\[([\w\W]+)\]/);
        if (matches) {
            const typeName = matches[1];
            const genericArgs: any = parseGenericAgrs(matches[2]).map(getTypeName);
            if (genericArgs.length === 0) {
                return typeName;
            } else {
                return `${typeName}[${genericArgs.join("; ")}]`;
            }
        }
    }
    return fullName.split(',')[0];
}

export function getBaseType(hierarchy: any[]) {
    return hierarchy[hierarchy.length - 1];
}

export function describe(fullName: string) {
    const name = getTypeName(fullName);

    preload();

    return cache[name];
}

export function preload() {
    if (preloaded) {
        return;
    }

    const preloadDeferred = when(
        rest.get("TypeDescriptionService", "commonTypes", { async: false }),
        rest.get("TypeDescriptionService", "applicationTypes", { async: false })
    ).pipe((...args: any) => {
        _(args).each((requestForTypes) => {
            _.extend(cache, requestForTypes[0]);
        });
        preloaded = true;
    }, function () {
        logger.error("Types were loaded incorrectly");
    });
    return preloadDeferred;
}

export function clearCache() {
    preloaded = false;
    cache = {};
}

export function uncache(name: string) {
    if (_.isUndefined(cache[name])) {
        delete cache[name];
    }
}

export function isCompanyRelated(name: string): boolean {
    if (!name) {
        return false;
    }

    const typeInfo = describe(name);

    if (!typeInfo) {
        return false;
    }

    const companyRelated = _(companyRelatedTypes).contains(name) || _(typeInfo.Interfaces).contains(companyRelatedName);

    if (!companyRelated) {
        const baseType = typeInfo.Hierarchy[1];
        return isCompanyRelated(baseType);
    }

    return true;
}

export function implementsInterface(typeName: string, interfaceName: string) {
    if (!typeName || !interfaceName) {
        return false;
    }

    const typeDescription = describe(typeName);
    const shortInterfaceName = getTypeName(interfaceName);

    if (!typeDescription) {
        return false;
    }

    return _(typeDescription.Interfaces).any((iface) => shortInterfaceName === getTypeName(iface));
}

export function has(name: string) {
    const type = describe(name);
    return !!type;
}

export const getHierarchy = _.memoize((type: string) => {
    if (typeof type !== 'string') {
        return [];
    }
    const description = describe(type);

    return description ? description.Hierarchy : [];
});

export function getDerivingTypes(type: string) {
    const types = _.keys(cache);

    const derivingTypes = _.filter(types, (typeName) => {
        return _(getHierarchy(typeName)).contains(type);
    });

    return derivingTypes;
}

export function getDirectlyDerivingTypes(type: string) {
    const types = _.keys(cache);

    const derivingTypes = _.filter(types, (typeName) => {
        const hierarchy = getHierarchy(typeName) || [],
            parentType = hierarchy[1];
        return parentType === type;
    });

    return derivingTypes;
}