/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/dashboard/modals/renameDashboard"/>
import { observable } from "knockout";
import * as $ from "jquery";

class RenameDashboard {
    Name: ko.Observable<any>;
    Dashboard: any;
    rename: () => void;

    constructor(dashboard: any) {
        this.Name = observable(dashboard.name());
        this.Dashboard = dashboard;

        this.rename = () => {
            this.Dashboard.rename(this.Name());
            $("#modal-dashboard-rename").modal("hide");
        };
    }
}

export function create(dashboard: any) {
    return new RenameDashboard(dashboard);
}
