/// <amd-module name="Core/Medius.Core.Web/Scripts/Models/Percentage/percentageFactory"/>

export function create(value: any, context: any) {
    const percent = {
        $type: "Medius.Core.DTOs.PercentageDto, Medius.Core.Common",
        Percent: value,
        ViewId: ""
    };

    return context.update(percent);
}