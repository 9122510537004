/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Data.DataDefinition/Relation"/>

import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import * as typeResolver from "Core/Medius.Core.Web/Scripts/Medius/components/resolver/type";
import * as entityGrid from "Core/Medius.Core.Web/Scripts/Medius/components/grid/entity/grid";
import { EntityCollection } from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/entityCollection";
import * as _ from "underscore";

class ViewModel {
    Entity: any;
    Tab: any;
    EntitesCollection: any;
    Grid: any;
    GridSelectionSub: any;
    constructor(entityWithContext: any, companyContextId: any, tabModel: any, initialSorting?: any) {
        const entityModel = entityWithContext.Entity;
        if (isNullOrUndefined(entityModel))
            throw "medius/viewModels/administration/Medius.Data.DataDefinition/Relation: Entity model not defined";

        const propertyName = tabModel.RelatedProperty();
        const elementType = typeResolver
            .getCollectionPropertyElementType(entityModel.$type(), propertyName);

        this.Entity = entityModel;
        this.Tab = tabModel;
        this.EntitesCollection = this.resolveCollection(this.Entity, propertyName);
        this.Grid = this.createGrid(elementType, initialSorting, companyContextId, this.EntitesCollection);
        this.GridSelectionSub = this.createGridSelectionSub(this.Grid, this.EntitesCollection);
    }

    createGrid(type: any, sorting: any, companyContextId: any, relatedEntites: any) {
        return entityGrid.create(type, {
            companyContextId: companyContextId,
            initialSorting: sorting,
            multiselect: true,
            multiselectInitialPredicate: function (entityRow: any) {
                const entity = relatedEntites.inCollection(entityRow.Id);
                if (isNullOrUndefined(entity) || entity === false)
                    return false;

                return true;
            }
        });
    }

    createGridSelectionSub(grid: any, relatedEntites: any) {
        return grid.multiselection.selectedRows
            .subscribe(function (changes: any) {
                if (grid.IsLoading())
                    return;

                _(changes).each(function (change) {
                    const entity = change.value;
                    if (change.status === "added") {
                        relatedEntites.addToCollection(entity);
                    } else if (change.status === "deleted") {
                        relatedEntites.removeFromCollection(entity);
                    }
                });
            }, null, "arrayChange");
    }

    resolveCollection(entityModel: any, propertyName: any) {
        const collection = entityModel[propertyName];
        if (!collection)
            throw new Error(`Property ${propertyName} does not exist`);

        return new EntityCollection(collection);
    }

    dispose() {
        if (this.GridSelectionSub) {
            this.GridSelectionSub.dispose();
            this.GridSelectionSub = null;
        }

        this.Entity = null;
        this.Tab = null;
        this.EntitesCollection = null;
        this.Grid.destroy();
        this.Grid = null;
    }
}

export default function create(entityWithContext: any, companyContextId: any, tabModel: any, initialSorting?: any) {
    return new ViewModel(entityWithContext, companyContextId, tabModel, initialSorting);
}