/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/grid/default/pagination"/>

import * as _ from "underscore";

export function calculateTotalPages(totalRows: number, pageSize: number) {
    let totalPages = Math.ceil(totalRows / pageSize);
    totalPages = (totalPages > 0) ? totalPages : 1;

    return totalPages;
}

export function generate(totalRows: number, currentPage: number, pageSize: number) {
    const totalPages = calculateTotalPages(totalRows, pageSize),
        pages: (string | number)[] = [1];

    if (currentPage - 3 > 1) {
        pages.push("...");
    }

    if (currentPage - 2 > 1) {
        pages.push(currentPage - 2);
    }

    if (currentPage - 1 > 1) {
        pages.push(currentPage - 1);
    }

    if (currentPage !== 1) {
        pages.push(currentPage);
    }

    if (totalPages > currentPage + 1) {
        pages.push(currentPage + 1);
    }

    if (totalPages > currentPage + 2) {
        pages.push(currentPage + 2);
    }

    if (totalPages > currentPage + 3) {
        pages.push("...");
    }

    if (totalPages > 1 && !_(pages).contains(totalPages)) {
        pages.push(totalPages);
    }

    return pages;
}