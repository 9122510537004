/// <amd-module name="Core/Medius.Core.Web/Scripts/customTabs/rules/shouldRenderContractsTabRule"/>

import { store } from "Core/Medius.Core.Web/Scripts/shared/store/reduxStore";
import { Module } from "Core/Medius.Core.Web/Scripts/Medius/apps/reactSpa/app";
import { ITaskDocument } from "../interfaces";
import * as authorizationKeys from "Core/Medius.Core.Web/Scripts/Medius/core/authorizationKeys";

export const shouldRenderContractsTab = (document: ITaskDocument) => {

    const validDocumentTypes = ["Medius.ExpenseInvoice.Entities.ExpenseInvoice, Medius.ExpenseInvoice.Common", "Medius.OrderbasedInvoice.Entities.OrderbasedInvoice, Medius.OrderbasedInvoice.Common"];

    const storeState = store.getState();
    const isContractModuleEnabled = storeState.accesses.enabledModules.includes(Module.Contract);
    const isUserAuthorizedToSeeTheTab = authorizationKeys.isUserAuthorized(authorizationKeys.authorizationKeysConstants.ShowSupplierContractsForInvoice);

    return validDocumentTypes.includes(document.$type) && isContractModuleEnabled && isUserAuthorizedToSeeTheTab;
};
