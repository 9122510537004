/// <amd-module name="Core/Medius.Core.Web/Scripts/components/links/duplicatedDocuments"/>

import * as React from 'react';
import { Card, ColumnDefinitionSet, DataTable } from '@medius/ui-controls';
import { InvoiceLinkViewModel } from 'Core/Medius.Core.Web/Scripts/components/links/linkViewModel';
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";

interface DuplicateLinksProps {
    links: InvoiceLinkViewModel[];
    isHistorical?: boolean;
}

export const DuplicatedDocuments = (props: DuplicateLinksProps) => {
    const columnDefinition: ColumnDefinitionSet<InvoiceLinkViewModel> = [  
        {
            key:"HeaderName",
            dataKey: "HeaderName",
            displayName: translate('#PurchaseToPay/links/documentType'),
            columnType: "dataField",
            dataType: "text",
            minColumnSize: 2,
            overflowType: "truncate"
        },
        {
            columnType: "customContent",
            key: "InvoiceNumber",
            displayName: translate('#PurchaseToPay/links/invoiceNumber'),
            overflowType: "truncate",
            renderRowContent: (item) => {
                return (<a href={item.linkMetadata.link}>{item.InvoiceNumber}</a>);
            }
        },
        {
            key: "Company",
            dataKey: "Company",
            displayName: translate('#PurchaseToPay/links/company'),
            columnType: "dataField",
            dataType: "text",
            overflowType: "truncate"
        },
        {
            key: "Supplier",
            dataKey: "Supplier",
            displayName: translate('#PurchaseToPay/links/supplier'),
            columnType: "dataField",
            dataType: "text",
            overflowType: "truncate"
        },
    ];

    return  (
        <div className={"duplicatedLinks__mainContainer " + (props.isHistorical ? "duplicatedLinks__dataTableCard-historical" : "")}>
             <Card data-testid={'duplicated-documents-card' + (props.isHistorical ? "-historic" : "")} title={translate('#PurchaseToPay/duplicates')}>
                    <DataTable<InvoiceLinkViewModel>
                        label={translate('#PurchaseToPay/duplicates')}
                        data-testid="duplicated-documents-grid"
                        allData={props.links}
                        dataMethod="all"
                        columns={columnDefinition}
                        enableResizing={false}
                        variant="compact"
                        maxLinesPerRow={4}
                        paging={{}}
                        sorting={{
                            enableSorting: false
                        }}
                        rowSelection={{
                            allowMultiple: false,
                            onSelectedRowChanged: (item: InvoiceLinkViewModel, selected: boolean) => null,
                            isRowSelectable: (item: InvoiceLinkViewModel) => false,
                        }}
                        userPreferenceKey="duplicated-documents-settings"
                    />
            </Card>
        </div>
    );
};