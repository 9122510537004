///<amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/AdministrationFavouriteLink/Roles/Roles"/>
import { RolesDataSource } from "Core/Medius.Core.Web/Scripts/AdminPages/AdministrationFavouriteLink/Roles/RolesDataSource";
import {observable} from "knockout";
import * as grid from "Core/Medius.Core.Web/Scripts/Medius/components/grid/default/grid";

interface IRole {
    Name: ko.Observable<string>;
}

class Roles {
    public Entity: any;
    public Context: any;

    public SelectedRole = observable<IRole>(null);
    public Roles: ko.ObservableArray<IRole>;
    public RolesGrid: any;

    private rolesDataSource = new RolesDataSource();
    constructor(entityWithCtx: any) {
        this.Entity = entityWithCtx.Entity;
        this.Context = entityWithCtx.Context;

        this.Roles = this.Entity.Roles;

        this.RolesGrid = grid.create(this.rolesDataSource,
        {
            dataQuery: { entities: this.Roles }
        });
    }
 
    public removeRole(role: IRole) {
        this.Roles.remove(role);

        this.RolesGrid.refresh();
    }

    public addRole() {
        const roleToAdd = this.SelectedRole();

        if (roleToAdd === null || 
            this.Roles().filter(e => e.Name() === this.SelectedRole().Name()).length > 0) {
            return;
        }

        this.Roles.push(roleToAdd);

        this.RolesGrid.refresh();
    }
}

export default (entity: any) => {
    return new Roles(entity);
};
