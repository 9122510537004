/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/AdministrationFavouriteLink/customActions"/>
import { AdminApplication, extractCreatedId } from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/adminApp";
import { registerCustomRemoveAction, registerCustomSaveAction } from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/customActionRegistry";
import * as rest from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rest";
import * as backendErrorHandler from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { success } from "Core/Medius.Core.Web/Scripts/Medius/core/notification";

const administrationFavouriteLinkFullName = "Medius.Core.Entities.Settings.AdministrationFavouriteLink";

function onAdministrationFavouriteLinkRemove(adminApp: AdminApplication) {
    adminApp.IsLoading(true);

    rest.del("favouriteLinksAdministration", adminApp.EntityId(), null, null)
        .then(() => {
            if (adminApp.EntityId() > 0) {
                success(getLabelTranslation("#Core/entityRemovedSuccessfully"));
            }
            adminApp.ObjectBrowser.Grid().openPage(1);
            adminApp.Editor().dispose();
            adminApp.Editor(null);
            adminApp.EntityId(null);
        }, error => {
            backendErrorHandler.handleAnyError(error);
        }).always(() => { adminApp.IsLoading(false); });
}

function onAdministrationFavouriteLinkSave(adminApp: AdminApplication) {
    const entity = adminApp.Editor().EntityWithContext.Entity;
    const editorContext = adminApp.Editor().EntityWithContext.TabModels.General;

    const originTranslations = editorContext.OriginTranslations;
    const userTranslations = editorContext.ContentTranslations();

    const editedTranslations = userTranslations
        .map((x: any) => ({ value: x.DisplayName(), language: x.Language() }))
        .filter((l: any) => originTranslations.map((x: any) => x.value).indexOf(l.value) < 0);

    const saveLink = (adminApp: AdminApplication) => {
        adminApp.IsLoading(true);

        const restCall = entity.Id() === 0 ? rest.post : rest.put;

        restCall("favouriteLinksAdministration", adminApp.EntityId(), {
                id: entity.Id(),
                isActive: entity.IsActive(),
                position: entity.Position(),
                forType: entity.ForType().fullName && entity.ForType().fullName() || entity.ForType(),
                forTypeTranslations: editedTranslations,
                roleIds: entity.Roles().map((x: any) => x.Id())
            }, null)
            .then((data, textStatus, jqXhr) => {
                success(getLabelTranslation("#Core/changesSavedSuccessfully"));

                if (adminApp.EntityId() > 0) {
                    adminApp.reloadForm();
                } else {
                    const id = extractCreatedId(data, jqXhr);
                    adminApp.EntityId(id);
                }

                adminApp.ObjectBrowser.Grid().refresh();
            }, error => {
                backendErrorHandler.handleAnyError(error);
            }).always(() => { adminApp.IsLoading(false); });
    };
    
    if (editedTranslations.length > 0) {
        editorContext.openDialogAction();

        if (editorContext.actionResultSub === undefined) {
            editorContext.actionResultSub = editorContext.dialogActionResult.subscribe(
                (result: any) => {
                    if (result === true) {
                        saveLink(adminApp);
                    }
                }
            );
        }

    } else {
        saveLink(adminApp);
    }
}

export function register() {
    registerCustomSaveAction(administrationFavouriteLinkFullName, onAdministrationFavouriteLinkSave);
    registerCustomRemoveAction(administrationFavouriteLinkFullName, onAdministrationFavouriteLinkRemove);
}
