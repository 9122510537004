/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/configurationProperties/configurationPropertyBinding"/>

import * as ko from "knockout";
import { registerBinding } from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";

interface Params {
    readonly property: string;
}

interface PropertyViewModel {
    readonly IsUnlicensed?: ko.Observable<boolean>;
    readonly UnlicensedReason?: ko.Observable<string>;
    readonly BindingTemplate: ko.Observable<string>;
}

interface DataContext {
    getPropertyViewModel(propertyName: string): PropertyViewModel;
}

class ConfigurationPropertyBinding {
    public static registerBinding(): void {
        registerBinding("configurationProperty", {
            init(element: HTMLElement) {
                return { controlsDescendantBindings: true };
            },
            update(
                element: HTMLElement,
                valueAccessor: () => Params,
                bindings: any,
                viewModel: any,
                bindingContext: ko.BindingContext) {

                const dataContext: DataContext = ko.unwrap(bindingContext.$data.ConfigurationData);
                const propertyVm = dataContext.getPropertyViewModel(valueAccessor().property);

                const template = propertyVm.IsUnlicensed !== undefined
                    ? "configuration-licensed-property-wrapper"
                    : propertyVm.BindingTemplate;

                ko.renderTemplate(template, propertyVm, {}, element, "replaceChildren");
            }
        });
    }
}

ConfigurationPropertyBinding.registerBinding();
export = null;
