///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/htmlValue"/>
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as ko from "knockout";
import * as $ from "jquery";

const htmlValue = {
    init: function (element:any, valueAccessor:any, allBindingsAccessor:any) {
        const valueUpdate = valueAccessor().valueUpdate || "keyup";
        const enabledValue = koUtils.unpack(valueAccessor().enabled);

        koUtils.registerEventHandler(element, valueUpdate, function () {
            const modelValue = valueAccessor().value;
            const oneWay = valueAccessor().oneWay;
            const elementValue = element.innerHTML;
            // Do not allow any <br> tags to be added to the element
            // This is a quick fix, can probably be solved in a better way
            element.innerHTML = element.innerHTML.replace('<br>', '');

            if (ko.isWriteableObservable(modelValue) && !oneWay) {
                modelValue(elementValue);
            } else { //handle non-observable one-way binding
                const allBindings = allBindingsAccessor();
                if (allBindings._ko_property_writers && allBindings._ko_property_writers.htmlValue) {
                    allBindings._ko_property_writers.htmlValue(elementValue);
                }
            }
        });

        $(element).attr('contenteditable', enabledValue);
    },
    update: function (element:any, valueAccessor:any) {
        const value = koUtils.unpack(valueAccessor().value);
        let enabledValue = koUtils.unpack(valueAccessor().enabled);
        $(element).text(value);
        // Enable if enabled parameter not set
        if (enabledValue === null) {
            enabledValue = true;
        }
        $(element).attr('contenteditable', enabledValue);
    }
};

export function register() {
    koUtils.registerBinding("htmlValue", htmlValue);
}