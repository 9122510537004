/// <amd-module name="Core/Medius.Core.Web/Scripts/Accounting/TaxHandling/taxHandling"/>
import { TaxHandlingType } from "Core/Medius.Core.Web/Scripts/Accounting/TaxHandling/taxHandlingType";
import * as ko from "knockout";

export function addTaxHandling(viewModel: any) {
    const self = viewModel;

    switch (self.TaxHandlingType()) {
        case TaxHandlingType.LineLevelTaxCodes:
            addCodedBasedLineTaxHandling(self);
            break;
        case TaxHandlingType.HeadLevelTaxCodes:
            addCodedBasedHeadTaxHandling(self);
            break;
        case TaxHandlingType.HeadLevelNoTaxCodes:
            addNonCodeBasedHeadTaxHandling(self);
            break;
        case TaxHandlingType.LineLevelNoTaxCodes:
            addNonCodeBasedLineTaxHandling(self);
            break;
        default:
            throw new Error(`Tax type is not supported: ${self.TaxHandlingType()}.`);
    }
}

function addCodedBasedLineTaxHandling(viewModel: any) {
    const self = viewModel;

    self.PopdownOptions = () => ({});

    self.AmountHeader = () => viewModel.UseTwoTaxIndicators() ? "taxInfoTwoIndicatorsHeaderTemplate" : "taxInfoOneIndicatorHeaderTemplate";
    self.CanShowTaxLines = ko.computed(() => self.IsAnyCodeStringSelected());
    self.ShowTaxesOnHeadButton = ko.observable(false);

    self.TaxLinesTooltipTemplateName = () => "taxInfoTooltipTemplate";

    self.TaxLinesTooltipTemplateNameMultipleLines = () => "taxInfoTooltipTemplateMultipleLines";

    self.TaxLines = ko.computed(() => self.selectedCodeStrings());
}

function addCodedBasedHeadTaxHandling(viewModel: any) {
    const self = viewModel;

    self.PopdownOptions = () => ({ hideOn: 'none' });

    self.AmountHeader = () => "amountInfoHeaderTemplate";
    self.CanShowTaxLines = ko.computed(() => self.BalanceCodeString() !== null);
    self.ShowTaxesOnHeadButton = ko.observable(true);

    self.TaxLinesTooltipTemplateName = () => "Enterprise:Accounting/HeaderTax/splitHeaderTaxLinesTooltip.html";

    self.TaxLinesTooltipTemplateNameMultipleLines = () => "Enterprise:Accounting/HeaderTax/splitHeaderTaxLinesTooltip.html";

    self.TaxLines = ko.computed(() => {
        if (self.BalanceCodeString()) {
            return self.BalanceCodeString().AmountInfo();
        }
        return null;
    });
}

function addNonCodeBasedHeadTaxHandling(viewModel: any) {
    const self = viewModel;

    self.PopdownOptions = () => ({});

    self.AmountHeader = () => "amountInfoHeaderTemplate";

    self.CanShowTaxLines = ko.computed(() => self.BalanceCodeString() !== null);
    self.ShowTaxesOnHeadButton = ko.observable(true);

    self.TaxLinesTooltipTemplateName = () => "noTaxCodeTaxLinesTooltipTemplate";

    self.TaxLinesTooltipTemplateNameMultipleLines = () => "noTaxCodeTaxLinesTooltipTemplate";

    self.TaxLines = ko.computed(() => {
        if (self.BalanceCodeString()) {
            return self.BalanceCodeString().AmountInfo();
        }
        return null;
    });
}

function addNonCodeBasedLineTaxHandling(viewModel: any) {
    const self = viewModel;

    self.PopdownOptions = () => ({});

    self.AmountHeader = () => "amountInfoHeaderTemplate";

    self.CanShowTaxLines = ko.observable(false);
    self.ShowTaxesOnHeadButton = ko.observable(false);

    self.TaxLinesTooltipTemplateName = () => "";

    self.TaxLinesTooltipTemplateNameMultipleLines = () => "";

    self.TaxLines = ko.computed(() => {
        if (self.BalanceCodeString()) {
            return self.BalanceCodeString().AmountInfo();
        }
        return null;
    });
}
