/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/kendo/bindings/kendoListView"/>

import * as ko from "knockout";
import * as $ from "jquery";
import { KendoUtils } from "Core/Medius.Core.Web/Scripts/Medius/kendo/kendoUtils";
import { registerBinding, addDisposeCallback } from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";

interface KendoListViewOptions extends kendo.ui.ListViewOptions {
    itemTemplateId?: string;
}
class KendoListViewBinding {
    public static registerKnockoutBindings(): void {
        registerBinding("kendoListView", {
            init(element: HTMLElement) {
                const $element = $(element);
                addDisposeCallback(element, () => {
                    KendoUtils.destroyComponent($element, "kendoListView");
                });
            },
            update(element: HTMLElement, valueAccessor: any, allBindings: any, viewModel: any, bindingContext: any) {
                const params = valueAccessor();
                const $element = $(element);
                const options = ko.unwrap(params.options) as KendoListViewOptions;

                if (options.itemTemplateId)
                    options.template = KendoUtils.loadTemplate(options.itemTemplateId);

                options.dataBound = (event) => {
                    const itemsData = event.sender.dataSource.data();
                    for (let i = 0; i < itemsData.length; i++) {
                        const itemNode = event.sender.element[0].children[0].children[i];
                        const itemContext = bindingContext.createChildContext(itemsData[i]);
                        ko.applyBindings(itemContext, itemNode);
                    }
                };

                KendoListViewBinding.create($element, options);
            }
        });
    }

    private static create($element: JQuery, kendoOptions: kendo.ui.ListViewOptions) {
        KendoUtils.destroyComponent($element, "kendoListView");
        KendoUtils.createComponent($element, "kendoListView", kendoOptions);
    }
}

KendoListViewBinding.registerKnockoutBindings();
export = null;
