///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/modal"/>
import * as ko from "knockout";
import * as $ from "jquery";
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";

const modal = {
    init: function(element:any, valueAccessor:any) {
        const bindingParam = valueAccessor();
        const show = koUtils.unpack(bindingParam);
        let $element = $(element);
        let bindingParamSub:any;

        function toggleOnChange(value:any) {
            if (value) {
                $element.modal("show");
            } else {
                $element.modal("hide");
            }
        }

        if (ko.isWriteableObservable(bindingParam)) {
            $element.on("hidden", function() {
                bindingParam(false);
            });

            $element.on("shown", function() {
                bindingParam(true);
            });
        }

        if (ko.isSubscribable(bindingParam)) {
            bindingParamSub = bindingParam.subscribe(toggleOnChange);
        }

        koUtils.addDisposeCallback(element, function() {
            if (bindingParamSub) {
                bindingParamSub.dispose();
            }

            $element.modal("destroy");
            $element.off("shown");
            $element.off("hidden");
            $element = null;
        });

        $element.modal("hide");
        toggleOnChange(show);
    }
};

export function register() {
    koUtils.registerBinding("modal", modal);
}