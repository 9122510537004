/// <amd-module name="Core/Medius.Core.Web/Scripts/components/topBar/rightSide/UserMenu"/>
import * as React from "react";
import { useRef } from "react";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { getFullNameOld, getInitialsOld } from "Core/Medius.Core.Web/Scripts/Medius/lib/utils/user";
import * as appConfig from "Core/Medius.Core.Web/Scripts/appConfig";
import * as userSettingsModal from "Core/Medius.Core.Web/Scripts/Medius/apps/userSettings/register";
import { open as openSecuritySettingsManager } from "Core/Medius.Core.Web/Scripts/components/securitySettingsManager/securitySettingsManager";
import { useClickOutsideMenu } from "Core/Medius.Core.Web/Scripts/components/topBar/hooks/useClickOutsideMenu";

type UserMenuProps = {
    readonly isUserMenuVisible: boolean;
    readonly onUserMenuVisibilityChange: (show: boolean) => void;
};

const UserMenu = ({ isUserMenuVisible, onUserMenuVisibilityChange }: UserMenuProps) => {
    const userMenuContainerRef = useRef<HTMLDivElement>(null);

    const settingsText = translate("#Core/settings");
    const changePasswordText = translate("#Core/changePassword");
    const switchAccountText = translate("#Core/menuSwitchAccount");
    const logoutText = translate("#Core/menuLogOut");
    const logOffUrl = appConfig.getLogOffUrl();
    const switchProviderUrl = appConfig.getSwitchProviderUrl();

    const userInitials = getInitialsOld();
    const userFullName = getFullNameOld();

    const showMenu = () => {
        onUserMenuVisibilityChange(!isUserMenuVisible);
    };
    const hideMenu = () => onUserMenuVisibilityChange(false);
    const openUserSettings = () => { 
        hideMenu(); 
        userSettingsModal.open(); 
    };
    const openChangePassword = () => { 
        hideMenu(); 
        openSecuritySettingsManager(); 
    };

    const logout = () => { 
        window.location.href = window.location.origin+logOffUrl;
    };
    
    const switchProvider = () => { 
        window.location.href = window.location.origin+switchProviderUrl;
    };

    useClickOutsideMenu(isUserMenuVisible, userMenuContainerRef, hideMenu);
    
    return (
        <div
            ref={userMenuContainerRef}
            className="top-bar-menu top-bar-initials"
            data-testid="open-user-menu-button"
            title={userFullName}
        >
            <div className="top-bar-initials__circle" onClick={showMenu}>
                {userInitials}
            </div>
            {isUserMenuVisible && (
                <ul className="top-bar-menu__list top-bar-menu__list--below-initials-circle">
                    <li
                        className="top-bar-menu__list-link"
                        data-testid="open-user-settings-button"
                        onClick={openUserSettings}
                    >
                        {settingsText}
                    </li>
                    <li
                        className="top-bar-menu__list-link"
                        data-testid="change-password-button"
                        onClick={openChangePassword}
                    >
                        {changePasswordText}
                    </li>
                    <li>
                        <a onClick={switchProvider} className="top-bar-menu__list-link">
                            {switchAccountText}
                        </a>
                    </li>
                    <li className="top-bar-menu__list-divider"></li>
                    <li>
                        <a onClick={logout} className="top-bar-menu__list-link" data-testid="logout-button">
                            {logoutText}
                        </a>
                    </li>
                </ul>
            )}
        </div>
    );
};

export { UserMenu };