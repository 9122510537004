///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/administration/bindings/timepicker"/>
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as ko from "knockout";
import * as $ from "jquery";

const timePicker = {
    init: function (element: Node, valueAccessor: () => any) {
        const modelValue = valueAccessor(),
            value = ko.utils.unwrapObservable(modelValue);

        $(element).timepicker({
            minuteStep: 5,
            showInputs: false,
            disableFocus: false,
            showWidgetOnAddonClick: true,
            defaultTime: value
        }).on('changeTime.timepicker', (e: { time: { value: any; }; }) => {
            const elementValue = e.time.value;
            modelValue(elementValue);
            // ko.expressionRewriting.writeValueToProperty(modelValue, allBindingsAccessor, 'Time', elementValue);
        });
        
        koUtils.addDisposeCallback(element, () => {
            $(element).timepicker("remove");
        });
    }
};

export function register() {
    koUtils.registerBinding("timepicker", timePicker);
}
