/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/lib/task/task"/>

import * as messageLogger from "Core/Medius.Core.Web/Scripts/lib/messageLogging/messageLogger";
import * as $ from "jquery";
import { uniqueId } from "underscore";
import { Logger } from "Core/Medius.Core.Web/Scripts/Medius/lib/utils/logger";

export const states = {
    pending: "pending",
    running: "running",
    completed: "completed",
    failed: "failed",
    canceled: "canceled"
};

function prepareTaskData(params:any) {
    if (typeof params === "function") {
        params = {
            action: params
        };
    }         
    if (!params.name && params.action.name) {
        params.name = params.action.name;
    }
    const taskId = uniqueId("task_");  
    return {
        name: taskId,
        id: taskId,
        state: states.pending,
        deferred: $.Deferred(),
        keepCompleted: false,
        ...params
    };
}

export class Task extends Logger {
    constructor(params: any){
        const taskData = prepareTaskData(params);

        super({
            name: taskData.name
        });
    
        this.data = {
            ...this.data,
            ...taskData
        };

        this.info("created");
        
        if (typeof this.data.action !== "function") {
            throw new Error("Task action is required");
        }
    }

    getName() {
        return this.data.name;
    }

    getState() {
        return this.data.state;
    }

    getDeferred() {
        return this.data.deferred;
    }

    getId() {
        return this.data.id;
    }

    run() {
        if (this.data.state !== states.pending) {
            if (this.data.state !== states.canceled) {
                this.warn("attempt to run in {0} state", this.data.state);
            }
            return null;
        }

        this.data.state = states.running;
        this.info("started");

        try {
            const result = this.data.action();
            if (result && typeof result.promise === "function") {
                $.when(result.promise())
                    .then(this.data.deferred.resolve, this.data.deferred.reject);
            } else {
                this.data.deferred.resolve(result);
            }
        } catch (e) {
            messageLogger.logFromError(e);
            this.error("threw: {0}\n{1}", e.message, e.stack);
            this.data.deferred.reject(e);
        }
        this.data.deferred.done(() => {            
            this.data.state = states.completed;
            this.info("succeded");
        }).fail(() => {
            if (this.data.state === states.pending || this.data.state === states.running) {
                this.data.state = states.failed;
            }
            this.error("failed");
        }).always(() => {
            this.dispose();
        });

        return this.data.deferred.promise();
    }

    cancel() {
        const hasCancel = typeof this.data.cancel === "function";

        if (this.data.state !== states.pending && this.data.state !== states.running) {
            // task already finished so nothing to see here
            return;
        }

        if (this.data.state === states.running) {
            if (!hasCancel) {
                // this task cannot be canceled while running, ignore
                this.warn("attempt to cancel in {0} state", this.data.state);
                return;
            }

            try {
                this.data.cancel();
            } catch (e) {
                messageLogger.logFromError(e);
                this.error("canceling task has thrown an error: {0}\n{1}", e.message, e.stack);
            }
        }

        this.data.state = states.canceled;
        this.info("canceled");
        this.data.deferred.reject();
        this.dispose();
    }

    dispose() {
        this.info("disposing");
        if (typeof this.data.dispose === "function") {
            this.data.dispose();
        }
        this.data.action = null;

        // ensure dispose can only be run once
        this.dispose = function () {
        };
    }

    toString () {
        return `[object ${this.constructor.name} ${this.data.name}]`;
    }
}

export function create(params: any){
    return new Task(params);
}