/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/ImportManagement/masterDataErrors"/>
import * as React from "react";
import {useEffect, useState, useRef, useReducer} from "react";
import { Grid, GridColumn as Column, GridToolbar, GridExpandChangeEvent, GridProps } from "@progress/kendo-react-grid";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import {translate} from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { Button } from "@progress/kendo-react-buttons";
import {getMasterDataErrors, reprocessPartially} from "./services";
import { FilterDescriptor } from "@progress/kendo-data-query";
import {createPortal} from "react-dom";
import { handleError } from "../../lib/errorHandling/errorHandler";
import { masterDataErrorsReducer } from "./masterDataErrorsState";
import { ErrorItem } from "./interfaces";
import { useDebounce } from "use-debounce";

const codeMirror = (this as any).globals.CodeMirror;

interface Props {
    queueItemId: number;
    reloadImportManagement: () => void;
    errorSearchString: string;
}

export function MasterDataErrors ({queueItemId, reloadImportManagement, errorSearchString}: Props) {
    const [isLoading, setIsLoading] = useState(true);
    const [gridData, dispatch] = useReducer(masterDataErrorsReducer, { data: [], total: 0, allSelectedIds: []});
    const [dataState, setDataState] = useState<GridProps>({
        skip: 0, 
        take: 20,
        filter: {
            logic: "and",
            filters: []
        }
    });
    const filters = dataState.filter?.filters as FilterDescriptor[];
    const [debouncedParameters] = useDebounce({
        queueItemId,
        skip: dataState.skip,
        take: dataState.take,
        externalSystemIdFilter: filters?.[0]?.value ?? null
    }, 300);

    useEffect(() => {
        setIsLoading(true);
        getMasterDataErrors(
            debouncedParameters.queueItemId, 
            debouncedParameters.skip, 
            debouncedParameters.take, 
            debouncedParameters.externalSystemIdFilter,
            errorSearchString
        ).then(({masterDataErrors, total}) => {
            dispatch({type: "LOAD_DATA", masterDataErrors, total});
            setIsLoading(false);
        })
        .catch(e => {
            handleError(e);
        });
    }, [debouncedParameters.queueItemId, 
        debouncedParameters.skip, 
        debouncedParameters.take, 
        debouncedParameters.externalSystemIdFilter,
        errorSearchString
    ]);

    useEffect(() => {
        setIsLoading(true);
        setDataState({skip: 0, take: 20, filter: { logic: "and", filters: []}});
    }, [queueItemId]);

    const expandChange = (event: GridExpandChangeEvent) => {
        dispatch({itemId: event.dataItem.id, type: "EXPAND_ROW"});
    };

    const selectionChange = ({dataItem}: any) => {
        const {id} = dataItem as ErrorItem;
        dispatch({itemId: id, type: "SELECT_ROW"});
    };

    const headerSelectionChange = (event: any) => {
        const checked = event.syntheticEvent.target.checked;
        dispatch({checked, type: "SELECT_ALL"});
    };

    const reprocessItem = () => {
        const errorsIds = gridData.allSelectedIds;

        reprocessPartially(queueItemId, errorsIds)
            .then(reloadImportManagement);
    };

    return (
        <>
        {isLoading && <LoadingPanel />}
        <Grid
            {...gridData}
            {...dataState}
            pageable
            detail={DetailComponent}
            expandField="expanded"
            onExpandChange={expandChange}
            selectedField="selected"
            onSelectionChange={selectionChange}
            onHeaderSelectionChange={headerSelectionChange}
            className="importManagement-masterDataErrors"
            filterable
            filterOperators={{
                "text": [ { text: 'grid.filterContainsOperator', operator: 'contains' } ]
            }}
            onFilterChange={({filter}) => {
                setDataState((data) => ({...data, skip: 0, take: 20, filter}));
            }}
            onDataStateChange={(e) => { setDataState({...e.dataState});}}>
            <GridToolbar>
                <Button
                    id="importManagement-masterDataErrors__reprocess-selected-button"
                    title={translate("#Core/reprocessSelected")} 
                    onClick={reprocessItem}
                    disabled={!gridData.data.some(x => x.selected)}
                >{translate("#Core/reprocessSelected")}</Button>
            </GridToolbar>
            <Column
                field="selected"
                width="50px"
                headerSelectionValue={ gridData.total > 0 && gridData.data.every(dataItem => dataItem.selected) }
                filterable={false} />
            <Column field="id" title={translate("#Core/id")} filterable={false}/>
            <Column field="externalSystemId" title={translate("#Core/externalSystemId")} />
        </Grid>
        </>
        );
}

function DetailComponent({dataItem}: {dataItem: ErrorItem}) {
    const jsonEditor = useRef(null);
    useEffect(() => {
        const editor = codeMirror.fromTextArea(jsonEditor.current, {
            mode: { name: "javascript", json: true },
            lineNumbers: true
        });
        const json = JSON.parse(dataItem.serializedMasterData);
        const formattedJson = JSON.stringify(json, undefined, 2);
        editor.setValue(formattedJson);
    }, [dataItem.serializedMasterData]);
    

    return (
        <div style={{overflow: "auto"}}>
            {dataItem.integrationErrors.map((item, index) => (
                <PanelBar key={index} className="importManagement-masterDataErrors__panel-bar">
                    <PanelBarItem 
                        expanded={false} 
                        title={item.errorMessage} 
                        className="importManagement-masterDataErrors__panel-bar-item"
                        headerClassName="importManagement-masterDataErrors__panel-bar-item__header">
                            <div className="importManagement-masterDataErrors__panel-bar-item__content">
                            {copyAvailable && item.exception && <Button 
                                onClick={() => { copyToClipboard(`${item.errorMessage} ${item.exception}`);}}>
                                {translate("#Core/copyException")}
                            </Button>}
                            { item.exception && <div className="importManagement-masterDataErrors__panel-bar-item__exception">{item.exception}</div> }
                            </div>
                    </PanelBarItem>
                </PanelBar>
            ))}
            <div className="importManagement-masterDataErrors__json-header">
                <h4>{translate("#Core/rawJsonDataInInternalFormat")}</h4>
                {copyAvailable && <Button 
                    onClick={() => { copyToClipboard(dataItem.serializedMasterData);}}>
                        {translate("#Core/copyJSON")}
                </Button>}
            </div>
            <textarea className="bootstrap-legacy-textarea" id="jsonData" name="rawJsonData" ref={jsonEditor}></textarea>
        </div>
    );
}

export function LoadingPanel() {
    const loadingPanel = (
        <div className="k-loading-mas">
            <span className="k-loading-text">{translate("#Core/loading")}</span>
            <div className="k-loading-image"></div>
            <div className="k-loading-color"></div>
        </div>
    );

    const gridContent = document && document.querySelector('.importManagement-masterDataErrors');
    return gridContent ? createPortal(loadingPanel, gridContent) : loadingPanel;
}

function copyToClipboard(value: string) {
    navigator.clipboard.writeText(value);
}

const copyAvailable =  !!navigator.clipboard?.writeText;
