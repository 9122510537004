/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/default/text/component/financeComponent"/>
import {unpack} from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as typeBoundResolverFactory from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/typeBound";
import * as financialLocalizer from "Core/Medius.Core.Web/Scripts/Medius/components/localizer/type/financial";
import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";

export function create(type: any, getResolution: any) {
    return typeBoundResolverFactory.create({
        priority: 0,

        type: type,

        resolve: function (data: any) {
            let displayValue = unpack(data.DisplayValue);
            const currencyCode = unpack(data.CurrencyCode);

            displayValue = financialLocalizer.toString(displayValue, {
                decimalResolution: getResolution(data.Currencycode)
            });

            const presentation = isNullOrUndefined(currencyCode)
                ? displayValue
                : `${displayValue} ${currencyCode}`;

            return { text: presentation };
        }
    });
}
