///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/documentSearch/framework"/>
import * as $ from "jquery";
import * as ko from "knockout";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as rest from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rest";
import * as notification from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import * as serialization from "Core/Medius.Core.Web/Scripts/Medius/lib/serialization";        
import * as columnConfiguration from "Core/Medius.Core.Web/Scripts/Medius/apps/documentSearch/columnConfiguration";
import * as searchComponent from "Core/Medius.Core.Web/Scripts/Medius/apps/dataSearch/model";
import * as actionManager from "Core/Medius.Core.Web/Scripts/Medius/apps/documentSearch/actionManager";
import * as backendErrorHandler from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import * as appConfig from "Core/Medius.Core.Web/Scripts/appConfig";
import { isNullOrUndefined, isNotNullOrUndefined, isEmptyString } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import * as _ from "underscore";
import * as metadataColumns from "Core/Medius.Core.Web/Scripts/Medius/apps/documentSearch/metadataColumns";
import * as additionalEditor from "Core/Medius.Core.Web/Scripts/Medius/apps/documentSearch/filtering/additionalFilterEditor";
import * as savedQueriesPanel from "Core/Medius.Core.Web/Scripts/Medius/apps/documentSearch/savedQueriesPanel"; 
import tabs = require("Core/Medius.Core.Web/Scripts/Medius/apps/documentSearch/tabs");
import * as operatorsProvider from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/provider/operators";
import * as queryEditor from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/editor";
import pendingAsyncExports = require("Core/Medius.Core.Web/Scripts/Medius/core/export/async/pending");

const USER = "Medius.Core.Entities.User",
      SUPPLIER = "Medius.Enterprise.Entities.Supplier",
      DOCUMENT = "Medius.Data.Document";

class Framework {
    columnConfigModel: any;
    searchModel: any;
    savedQueriesModel: any;
    additionalFilterModel: any;
    actionManagerModel: any;
    isLoading = ko.observable(false);
    isQueryValid = ko.observable(false);
    isColumnConfigButtonVisible = ko.observable(true);
    isQueryEditorVisible = ko.observable(true);
    advancedSearchDocUrl: string;
    queryTree = ko.observable();
    documentType = ko.observable();
    resultGroups = ko.observableArray([]);
    queryEditor: any;
    searchHasResults: ko.Computed<boolean>;
    searchHasNoResults = ko.observable(false);
    isSavePossible: ko.Computed<boolean>;
    isSaveAsNewPossible: ko.Computed<boolean>;
    currentQueryName = ko.observable("");
    newQueryName = ko.observable(null);
    isNewQueryNameValid: ko.Computed<boolean>;
    isNamePopdownVisible = ko.observable(false);
    SelectedQueryNameSub: any;
    SavedQuerySub: any;
    documentTypeSub: ko.Subscription;
    exportToExcel: () => any;
    isAnyDocumentSelected: ko.Computed<boolean>;
    SelectionResultSet: any;
    rowsCount: ko.Computed<any>;
    AdditionalFilterSub: any;
    removeUnsavedQuery: any;
    constructor() {
        metadataColumns.loadAvailableMetadataColumns();
        this.columnConfigModel = columnConfiguration.create();
        this.searchModel = searchComponent.create();
        this.savedQueriesModel = savedQueriesPanel.create();
        this.additionalFilterModel = additionalEditor.create();
        this.actionManagerModel = actionManager.create();
        this.advancedSearchDocUrl = appConfig.getAdvancedSearchOperatorsDocumentationUrl();
        this.queryEditor = queryEditor.create({
            value: this.queryTree,
            type: this.documentType,
            isValid: this.isQueryValid,
            onEnterPressed: () => {
                this.runQuery();
            }
        });
        this.queryTree(this.queryEditor.toTree(""));
        //columnConfiguration
        this.searchHasResults = ko.computed(() => {
            return !isNullOrUndefined(this.searchModel.resultGroup());
        });
        this.isSavePossible = ko.computed(() => {
            return this.isQueryValid() && isNotNullOrUndefined(this.searchModel.selectedQuery()) && this.searchModel.selectedQuery().Id !== -1;
        });
        this.isSaveAsNewPossible = ko.computed(() => {
        return this.isQueryValid();
        });
        this.isNewQueryNameValid = ko.computed(() => {
            return !isEmptyString(this.newQueryName());
        });
        this.SelectedQueryNameSub = this.savedQueriesModel.SelectedQueryName.subscribe((selectedQueryName: string) => {
            this.currentQueryName(selectedQueryName);
            this.newQueryName(selectedQueryName);
        });
        // reusing
        this.SavedQuerySub = this.savedQueriesModel.SelectedQuery.subscribe((selectedQuery: { Name: any; IsQueryEditorVisible: any; IsColumnConfigEnabled: any; }) => {
            if (isNullOrUndefined(selectedQuery)) {
                this.searchModel.selectedQuery(null);
                return;
            }
            if (!this.loadSavedQuery(selectedQuery)) {
                return;
            }
            const queryName = isNotNullOrUndefined(selectedQuery) ? selectedQuery.Name : "";
            this.newQueryName(queryName);
            this.currentQueryName(queryName);
            this.additionalFilterModel.reset();
            $.when(this.runQuery()).done(() => {
                this.isQueryEditorVisible(_.isUndefined(selectedQuery.IsQueryEditorVisible) ? true : selectedQuery.IsQueryEditorVisible);
                this.isColumnConfigButtonVisible(_.isUndefined(selectedQuery.IsColumnConfigEnabled) ? true : selectedQuery.IsColumnConfigEnabled);
            });
        });
        this.documentTypeSub = this.documentType.subscribe(() => {
            this.searchModel.ColumnConfiguration(null);
            this.additionalFilterModel.reset();
        });
        this.exportToExcel = () => {
            this.isLoading(true);
            const jsonQueryObject = serialization.toJSON(this.searchModel.FinalQueryObject());
            return (pendingAsyncExports as any).wrapAsyncExportTask(rest.post(
                "DataSearchManager", `exports?exportTo=excel&queryName=${encodeURIComponent(this.currentQueryName())}`,
                jsonQueryObject
            )).always(() => {
                this.isLoading(false);
            });
        };
        this.rowsCount = ko.computed(() => {
            const resultGroup = this.searchModel.resultGroup();
            if (isNullOrUndefined(resultGroup)) {
                return 0;
            }
            return resultGroup.dataSource.totalRows;
        });
        //notification
        this.isAnyDocumentSelected = ko.computed(() => {
            const resultSet = this.searchModel.resultGroup();
            return isNotNullOrUndefined(resultSet) ? resultSet.grid().SelectedIds().length > 0 : false;
        });
        this.SelectionResultSet = ko.computed(() => {
            const resultGroup = this.searchModel.resultGroup();
            let resultSet:any = {};
            if (isNotNullOrUndefined(resultGroup)){
                resultSet = {
                    type: resultGroup.type,
                    rowsCount: resultGroup.totalRows,
                    documentIds: []
                };
                resultSet.documentIds = isNotNullOrUndefined(resultGroup.grid()) ? resultGroup.grid().SelectedIds() : [];
            }
            return resultSet;
        });
        //bind apply to this view model
        this.applyColumnConfig = this.applyColumnConfig.bind(this);
        this.saveNewDocumentSearchQuery = this.saveNewDocumentSearchQuery.bind(this);
        this.saveQuery = this.saveQuery.bind(this);
    }

    runQuery() {
        let queryTree, extendedQuery, andOperator, orderByOperator, orderByOffset;
        const additionalFilterQuery = this.additionalFilterModel.AdditionalFilterQuery();
        const inputQuery = this.queryEditor.inputQuery().trim();

        if (!this.isQueryValid()) {
            notification.error(globalization.getLabelTranslation("#Core/documentSearchInvalidCombinedQuery"));
            return $.Deferred().reject();
        }

        if (!isEmptyString(additionalFilterQuery)) {

            if (!this.queryEditor.validate(additionalFilterQuery)) {
                notification.error(globalization.getLabelTranslation("#Core/documentSearchInvalidAddFiltering"));
                return $.Deferred().reject();
            }

            extendedQuery = additionalFilterQuery;
            if (!isEmptyString(inputQuery)) {
                andOperator = operatorsProvider.getLogicalConjunctionOperator();
                orderByOperator = operatorsProvider.getOrderByOperator();
                orderByOffset = inputQuery.indexOf(orderByOperator);
                if (orderByOffset === 0) {
                    extendedQuery = extendedQuery + " " + inputQuery;
                } else if (orderByOffset !== -1) {
                    extendedQuery = "(" + inputQuery.slice(0, orderByOffset).trim() + ") "
                        + andOperator + " (" + extendedQuery + ") " + inputQuery.slice(orderByOffset);
                } else {
                    extendedQuery = "(" + inputQuery + ") " + andOperator + " (" + extendedQuery + ")";
                }
            }

            if (!this.queryEditor.validate(extendedQuery)) {
                notification.error(globalization.getLabelTranslation("#Core/documentSearchInvalidCombinedQuery"));
                return $.Deferred().reject();
            }

            queryTree = this.queryEditor.toTree(extendedQuery);
        }
        else {
            queryTree = this.queryTree();
        }

        this.isLoading(true);
        this.isQueryEditorVisible(this.isQueryEditorVisible());
        this.isColumnConfigButtonVisible(this.isColumnConfigButtonVisible());

        return this.searchModel.fetchResults(queryTree)
            .always(() => {
                this.isLoading(false);
            })
            .done(() => {
                const dataSourceColumns = this.searchModel.resultGroup() ? this.searchModel.resultGroup().dataSource.columns : [];

                this.searchHasNoResults(!this.searchHasResults());
                this.columnConfigModel.init(this.documentType(), this.searchModel.ColumnConfiguration());

                if (this.documentType() === USER || this.documentType() === SUPPLIER) {
                    this.isColumnConfigButtonVisible(false);
                }

                if (isEmptyString(additionalFilterQuery)) {
                    this.additionalFilterModel.reloadFields(this.documentType(), this.searchModel.ColumnConfiguration(), dataSourceColumns);
                }
            }).fail(() => {
                this.searchModel.ColumnConfiguration([]);
                this.columnConfigModel.clearColumnConfig();
            });
    }

    applyFiltering() {
        const additionalQuery = this.additionalFilterModel.parseColumnFieldsToQuery();

        this.additionalFilterModel.AdditionalFilterQuery(additionalQuery);
        this.runQuery();
    }

    removeFiltering() {
        this.additionalFilterModel.reset();
        this.runQuery();
    }

    applyColumnConfig() {
        const pickedColumns = this.columnConfigModel.DisplayedColumns();

        this.searchModel.ColumnConfiguration(pickedColumns);
        this.isLoading(true);

        return this.runQuery()
            .done(() => {
                this.additionalFilterModel.reloadFields(this.documentType(), pickedColumns);
            })
            .always(() => {
                this.isLoading(false);
            });
    }

    saveQuery() {
        if (!this.isQueryValid()) {
            notification.error("Query can't be saved due to it's not valid.");
            return;
        }

        this.isLoading(true);

        return this.searchModel.saveQueryChanges(this.queryTree())
            .done(() => {
                this.savedQueriesModel.getSavedQueries();
                this.currentQueryName(this.newQueryName());
                notification.success(globalization.getLabelTranslation("#Core/documentSearchQuerySaved"));
            })
            .fail((xhr: any) => {
                backendErrorHandler.handleAnyError(xhr, null, "#Core/filterSavedUnsuccessfully");
            })
            .always(() => {
                this.isLoading(false);
            });
    }

    saveNewDocumentSearchQuery() {
        if (!this.isQueryValid()) {
            notification.error("Query can't be saved due to it's not valid.");
            return;
        }

        if (!this.isNewQueryNameValid()) {
            notification.error(globalization.getLabelTranslation("#Core/documentSearchQueryNameIsEmptyError"));
            return;
        }

        this.isLoading(true);
        return this.searchModel.saveAsNewQuery(this.queryTree(), this.newQueryName())
            .done((newQueryId: any) => {
                $.when(this.savedQueriesModel.getSavedQueries()).done(() => {
                    this.savedQueriesModel.updateQueryPanel(newQueryId);
                });
                this.isNamePopdownVisible(false);
                notification.success(globalization.getLabelTranslation("#Core/documentSearchQuerySaved"));
            }).always(() => {
                this.isLoading(false);
            });
    }

    loadSavedQuery(queryItem: { Name?: any; IsQueryEditorVisible?: any; IsColumnConfigEnabled?: any; Query?: any; }) {
        let queryTree;
        const selectedQuery = this.searchModel.selectedQuery;

        if (selectedQuery() && selectedQuery().Id === -1) {
            this.removeUnsavedQuery();
            selectedQuery(null);
        }

        if (!queryItem) {
            return false;
        }

        if (typeof queryItem.Query === "string") {
            try {
                queryTree = JSON.parse(queryItem.Query); //if query is shared all its data is not in .Query but just in object
            } catch(e) {
                notification.error("Query parsing error: " + e.message);
                return false;
            }
        } else {
            queryTree = queryItem.Query;
        }

        selectedQuery(queryItem);
        this.queryTree(queryTree.Query || queryTree);
        this.queryEditor.parse(this.queryTree());
        const columns = queryTree.Columns || "";
        this.searchModel.ColumnConfiguration(columns.split("|"));

        return true;
    }

    newQuery() {
        //clear document type
        this.queryEditor.type(DOCUMENT);

        //clear query
        this.queryEditor.inputQuery("");
        this.searchModel.selectedQuery(null);
        this.currentQueryName("");
        this.newQueryName("");
        this.isQueryEditorVisible(true);

        //clear columns
        this.searchModel.ColumnConfiguration(null);
        this.additionalFilterModel.reset();

        //clear results
        this.searchModel.resultGroup(null);
        this.isColumnConfigButtonVisible(true);
    }

    toggleNewQuery() {
        this.isNamePopdownVisible(!this.isNamePopdownVisible());
    }

    destroy() {
        this.additionalFilterModel.destroy();
        this.SavedQuerySub.dispose();
        this.AdditionalFilterSub.dispose();
        this.documentTypeSub.dispose();
        this.SelectedQueryNameSub.dispose();
        this.queryEditor.destroy();
    }
}

export function register() {
    tabs.register(new Framework(), "Core:templates/DocumentSearch/Main.html", "#Core/documentSearch", "AdvancedSearch");
}