/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/AuthorizationGroups/service"/>
import * as rest from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rest";
import * as rpc from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rpc";
import { AuthorizationGroup, Company, EntityId, Supplier, DocumentType, Dimension, AuthorizationGroupsHeaders, Optional, Currency } from "./authorizationGroup";
import * as fetchrest from "Core/Medius.Core.Web/Scripts/Medius/core/fetch/rest";

const baseUrl = "authorizationGroup";
export interface AuthorizationGroupsQueryData {
    skip: Optional<number>;
    take: Optional<number>;
    sort: Optional<Sort> | null;
    filter: Filters;
}
export interface Filters {
    Name: string;
    CompanyName: string;
    CurrencyCode: string;
    IsActive?: boolean;
}
export interface Sort {
    Field: Optional<string>;
    Dir: Optional<string>; // asc, desc
}

export const getPage = (queryData: AuthorizationGroupsQueryData): JQueryPromise<AuthorizationGroupsHeaders> =>
    rest.post(baseUrl, "query", queryData);

export const get = (id?: number): JQueryPromise<AuthorizationGroup> =>
    rest.get(baseUrl, id ? id : undefined);

export const save = (newAuthorizationGroup?: AuthorizationGroup | null): JQueryPromise<EntityId> =>
    rest.post(baseUrl, "", newAuthorizationGroup);

export const remove = (id?: number) =>
    rest.del(baseUrl, id ? id : undefined);

export const getCompanies = (searchPhrase: string = ''): JQueryPromise<Company[]> => {
    const encodedSearchPhrase = encodeURIComponent(searchPhrase);
    return rest.get(`AuthorizationGroupCompany/search`, `?searchPhrase=${encodedSearchPhrase}`);
};

export const getSuppliers = (companyId: number, skip: number, take: number, searchPhrase: string = ''): JQueryPromise<{ Suppliers: Supplier[], Total: number }> => {
    const encodedSearchPhrase = encodeURIComponent(searchPhrase);
    return rest.get(`AuthorizationGroupSupplier/search`, `?companyId=${companyId}&skip=${skip}&take=${take}&searchPhrase=${encodedSearchPhrase}`);
};

export async function getCurrencies(): Promise<{ Currencies: Currency[], Total: number }> {
    return fetchrest.get(`AuthorizationGroupCurrency`, ``);
}

export const hasAddAccess = () =>
    rpc.request("SettingsManager", "UserAccessControlForType", { typeName: entityType });

export const getDimensions = (companyId?: number): JQueryPromise<Dimension[]> =>
    rest.get(baseUrl, `dimensions/?companyId=${companyId}`);

export const getDocumentTypes = (): JQueryPromise<{documentTypeList:DocumentType[],defaultDocumentTypeId:number}> =>
    rest.get(baseUrl, "documentTypes");

export const entityType = "Medius.Enterprise.Entities.Authorization.Configuration.AuthorizationGroup";
