/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/inbox/bulk/actions/action"/>
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as ko from "knockout";
import * as $ from "jquery";
import unique from "Core/Medius.Core.Web/Scripts/lib/collections/unique";

import { NoModificationError } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/bulk/noModification";

export class Action {
    public id: string;
    public label: string;

    public isAvailable: ko.Observable<boolean>;
    public isReady: ko.Observable<boolean>;
    public isLoading: ko.Observable<boolean>;

    public activity: any;
    public viewModel: any;
    public tasksIds: any;
    public documentType: any;
    public taskDescription: any;

    public send: () => any;
    public validate: () => any;
    public getViewModel: () => void;
    public registerViewModel: (viewModel: any) => void;
    public dispose: () => any;
    public viewPath: string;

    constructor(tasks: any[]) {
        this.viewModel = null;

        this.tasksIds = tasks.map(task => task.id);
        const documentTypes = unique(tasks.map(task => task.taskGroup.fullName), fullName => fullName);
        this.documentType = documentTypes.length === 1 ? documentTypes[0] : null;
        const activities = unique(tasks.map(task => task.Activity), activity => activity);
        this.activity = activities.length === 1 ? activities[0] : null;
        const taskDescriptions = unique(tasks.map(task => task.taskDescription), taskDescription => taskDescription);
        this.taskDescription = taskDescriptions.length > 1 ? null : globalization.getLabelTranslation(taskDescriptions[0]);
        this.isReady = ko.observable(true);

        this.send = () => {
            if (!this.viewModel)
                return $.Deferred().reject(new NoModificationError());

            return this.viewModel.save();
        }

        this.validate = () => {
            if (!this.viewModel) 
                return true;

            return this.viewModel.validate();
        }

        this.registerViewModel = (viewModel) => {
            this.viewModel = viewModel;
        };

        this.getViewModel = () => {
            return this.viewModel;
        };

        this.dispose = () => {
            if (this.viewModel && this.viewModel.dispose) {
                this.viewModel.dispose();
            }
            this.viewModel = null;
        }
    }
}
