/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Data.DataDefinition/SimpleRelationGridFactory"/>
import * as defaultGrid from "Core/Medius.Core.Web/Scripts/Medius/components/grid/default/grid";
import {SimpleRelationDataSource} from "Core/Medius.Core.Web/Scripts/Medius/components/grid/dataSource/simpleRelation";
import * as entityGridDefaults from "Core/Medius.Core.Web/Scripts/Medius/components/grid/entity/defaults";
import * as _ from "underscore";

export function createGrid(relatedEntities: any) {
    const dataSource = new SimpleRelationDataSource();

    const options = _.extend(entityGridDefaults.get(), {
        tplRow: "Core:templates/Administration/Relation/SimpleRowTpl.html",
        dataQuery: { entities: relatedEntities }
    });

    const grid = defaultGrid.create(dataSource, options);

    const relatedEntitiesSub = relatedEntities.subscribe(function (changes: any) {
        _(changes).each(function (change) {
            if (change.status === "added") {
                grid.addRow(change.value);
            } else if (change.status === "deleted") {
                grid.removeRow(change.value);
            }
        });
    }, null, 'arrayChange');

    grid.dispose = function () {
        relatedEntitiesSub.dispose();
    };

    return grid;
}