/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/labels/labelsRepository"/>
import { observableArray } from "knockout";
import * as _ from "underscore";
import * as rest from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rest";
import { ICompanyLabel } from "Core/Medius.Core.Web/Scripts/typings/interfaces/ICompanyLabel";
import {getLabelTextColor} from "Core/Medius.Core.Web/Scripts/lib/labelHelper";

const allLabelsObservableArray = observableArray<ICompanyLabel>([]);
const idLabelCacheLookup: any = {};

class LabelsRepository {
    private isWaitingForResponse = false;
    private promise: JQueryPromise<void>;

    public setRepositoryData(labels: ICompanyLabel[]) {
        const sortedList = _(labels)
            .sortBy(label => label.description)
            .filter((label, index, self) => _.findIndex(self, l => l.description === label.description) === index);

        allLabelsObservableArray(sortedList);

        _(labels).forEach((label: ICompanyLabel) => {
            idLabelCacheLookup[label.id] = label;
        });
    }

    public reloadLabels(): JQueryPromise<void> {
        if (this.isWaitingForResponse) {
            return this.promise;
        }

        this.isWaitingForResponse = true;

        const promise = rest.get("labels", "", null, null)
            .then((labelsDto: ICompanyLabel[]) => {
                const labels = labelsDto.map(l => {
                    return {
                        ...l,
                        textColor: getLabelTextColor(l.colorCode)
                    };
                });
                this.setRepositoryData(labels);
            }).always(() => {
                this.isWaitingForResponse = false;
            });

        return promise;
    }

    public getLabelsByCompany(companyId: number): JQueryPromise<ICompanyLabel[]> {
        const promise = rest.get("labels/company", companyId.toString(), null, null);

        return promise;
    }

    public getLabelsByIds(labelsIds: number[]): ko.ObservableArray<ICompanyLabel> {
        const labelsObservableArray = observableArray<ICompanyLabel>([]);
        const labels: ICompanyLabel[] = [];
        const idsOfMissingLabels: number[] = [];

        let label: ICompanyLabel;

        // try to get all labels from cache
        _(labelsIds).forEach((labelId: number) => {
            label = idLabelCacheLookup[labelId];
            if (!_.isUndefined(label)) {
                labels.push(label);
            } else {
                idsOfMissingLabels.push(labelId);
            }
        });
        labelsObservableArray(labels);

        // if do not have all labels in cache, try to reload
        if (idsOfMissingLabels.length > 0) {
            this.reloadLabels().done(() => {
                _(idsOfMissingLabels).forEach((labelId: number) => {
                    label = idLabelCacheLookup[labelId];
                    labelsObservableArray.push(label);
                });
            });
        }

        return labelsObservableArray;
    }
}

export function getLabelsByCompany(companyId: number): JQueryPromise<ICompanyLabel[]> {
    const labelsRepository = new LabelsRepository();
    return labelsRepository.getLabelsByCompany(companyId);
}

export function getAllLabelsObservableArray(): ko.ObservableArray<ICompanyLabel> {
    return allLabelsObservableArray;
}

export function getLabelsByIds(labelsIds: number[]) {
    const labelsRepository = new LabelsRepository();
    return labelsRepository.getLabelsByIds(labelsIds);
}

export function init() {
    const labelsRepository = new LabelsRepository();
    return labelsRepository.reloadLabels();
}
