/// <amd-module name="Core/Medius.Core.Web/Scripts/components/links/linksModal/linksModal"/>

import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import * as _ from "underscore";
import { AutoComplete, Button, ColumnDefinitionSet, DataTable, DropDown, InputControlEvent, ListItem, ModalDialog, Text, Notification } from "@medius/ui-controls";
import { IconActionDeleteRegular } from '@medius/ui-controls';
import { get, post } from "Core/Medius.Core.Web/Scripts/Medius/core/fetch/rest";
import { SuggestionDto, suggestionToLinkMappers, suggestionToListItemMappers } from 'Core/Medius.Core.Web/Scripts/components/links/linksModal/mappers';
import { translate } from 'Core/Medius.Core.Web/Scripts/lib/globalization';
import { handleError } from "Core/Medius.Core.Web/Scripts/lib/errorHandling/errorHandler";

interface Link {
    entityType: string,
    entityViewId: string,
    entityId: number,
    documentNumber: string,
    company: string,
    supplier: string,
    sectionType: string,
    uniqueId: number
}

export const LinksModal = ({ sourceEntityType, sourceEntityViewId, modalVisible, onModalClose }: { sourceEntityType: string, sourceEntityViewId: string, modalVisible: boolean, onModalClose: () => void }) => {

    const [searchResult, setSearchResult] = useState<SuggestionDto[]>([]);
    const [selectedLinks, setSelectedLinks] = useState<Link[]>([]);
    const [missingTypeError, setMissingTypeError] = useState(false);

    const validate = useCallback(() => {
        const hasMissingTypes = _.any(selectedLinks, (link: Link) => !link.sectionType);
        setMissingTypeError(hasMissingTypes);

        return !hasMissingTypes;
    }, [selectedLinks]);

    useEffect(() => {
        if (missingTypeError) {
            validate();
        }
    }, [missingTypeError, selectedLinks, validate]);

    const columnDefinition: ColumnDefinitionSet<Link> = [
        {
            columnType: "customContent",
            key: "entityType",
            displayName: translate("#PurchaseToPay/linksGrid/documentType"),
            renderRowContent: (link: Link) => {
                return <span>{translate(link.entityType)}</span>;
            }
        },
        {
            key: "documentNumber",
            dataKey: "documentNumber",
            displayName: translate("#PurchaseToPay/linksGrid/documentNumber"),
            columnType: "dataField",
            dataType: "text",
            enableSorting: false
        },
        {
            key: "company",
            dataKey: "company",
            displayName: translate("#Core/company"),
            columnType: "dataField",
            dataType: "text",
            enableSorting: false
        },
        {
            key: "supplier",
            dataKey: "supplier",
            displayName: translate("#Enterprise/supplier"),
            columnType: "dataField",
            dataType: "text",
            enableSorting: false
        },
        {
            columnType: "customContent",
            key: "sectionType",
            displayName: translate("#PurchaseToPay/linksGrid/sectionType"),
            renderRowContent: (link: Link) => {
                return <DropDown
                    dataMethodType="allItems"
                    data-testid="link-selection-type-list"
                    placeholder={translate("#PurchaseToPay/linksGrid/linkTypePlaceholder")}
                    useEmptyItem
                    useStringValue
                    invalid={missingTypeError && !link.sectionType}
                    defaultValue={link.sectionType}
                    onChange={(value: InputControlEvent<string>) => onTypeChange(value.value, link)}
                    allItems={availableTypes(link, sourceEntityType)} />;
            }
        },
        {
            columnType: "actions",
            key: "editAction",
            displayName: "edit",
            maxColumnSize: "content",
            renderRowActions: (link: Link) => <Button data-testid="delete-selected-link-button" label={translate("#Core/remove")} hideLabel icon={<IconActionDeleteRegular />} variant="inline" onClick={() => onDelete(link)} />
        }
    ];

    const setSearchResultWrapper = (suggestions: SuggestionDto[]) => {
        const oldSuggestions = [...searchResult];
        const newSuggestions = suggestions.filter(suggestion => !_.any(oldSuggestions, x => x.entityViewId == suggestion.entityViewId));

        const result = oldSuggestions.concat(newSuggestions);
        setSearchResult(result);
    };

    const onGetItemData = async (search: string | null) => {
        const suggestions = await loadEntities(search);

        setSearchResultWrapper(suggestions);

        return {
            items: suggestions.map<ListItem>(mapSuggestionToListItem),
            totalAvailableItemsCount: suggestions.length
        };
    };

    const loadEntities = async (search: string) => {
        let suggestions: SuggestionDto[] = [];

        try {
            const endpoint = search ? `entities-to-link/search?searchTerm=${search}` : "entities-to-link/recentlyViewed";
            const response = await get(endpoint);
            suggestions = search ? (response.kind === "success" ? response.results.suggestions : []) : response.suggestions;
        } catch (err) {
            handleError(err);
        }

        return suggestions;
    };

    const mapSuggestionToListItem = (dto: SuggestionDto) => {
        return suggestionToListItemMappers.get(dto.entityType)(dto);
    };

    const mapSuggestionToLink = (dto: SuggestionDto) => {
        const link = suggestionToLinkMappers.get(dto.entityType)(dto) as Link;
        link.entityViewId = dto.entityViewId;
        link.entityId = dto.entityId;
        link.uniqueId = Number(new Date());

        return link;
    };

    const onSelect = async ({ value }: InputControlEvent<ListItem>) => {
        if (!value) {
            return;
        }

        const { value: entityViewId } = value;

        const selectedItem = searchResult.find(x => x.entityViewId === entityViewId);

        setSelectedLinks([...selectedLinks, mapSuggestionToLink(selectedItem)]);
    };

    const onDone = async () => {

        const mapLinkToDto = (sourceEntityType: string, sourceEntityViewId: string, link: Link) => {
            return {
                sourceEntityType: sourceEntityType,
                sourceEntityViewId: sourceEntityViewId,
                targetEntityType: link.entityType,
                targetEntityViewId: link.entityViewId,
                targetEntityId: link.entityId,
                entityLinkType: link.sectionType === "Other" ? "" : link.sectionType
            };
        };

        if (validate() && selectedLinks.length) {
            const data = selectedLinks.map(link => mapLinkToDto(sourceEntityType, sourceEntityViewId, link));
            await post("Backend/Rest/links", data)
                .then(onModalClose)
                .catch(err => handleError(err))
                .finally(() => {
                    setSelectedLinks([]);
                });
        }
    };

    const availableTypes = (link: Link, sourceType: string) => {
        const types: ListItem[] = [{ text: "", value: "" }, { text: translate("#PurchaseToPay/linksGrid/otherLinks"), value: "Other" }, { text: translate("#PurchaseToPay/linksGrid/referencedDocuments"), value: "ReferencedDocument" }];

        return types.filter(({ value }: ListItem) => {
            return value === "ReferencedDocument" ? isDocumentReferencable(sourceType, link.entityType) : true;
        });
    };

    const isDocumentReferencable = (sourceType: string, targetType: string) => {
        const referenceableList = ["Medius.ContractbasedInvoice.Entities.ContractbasedInvoice", "Medius.ExpenseInvoice.Entities.ExpenseInvoice", "Medius.OrderbasedInvoice.Entities.OrderbasedInvoice"];
        return referenceableList.includes(sourceType) && referenceableList.includes(targetType);
    };

    const onTypeChange = (sectionType: string, link: Link) => {
        setSelectedLinks((prevSelectedLinks) => {
            const newState = [...prevSelectedLinks];
            const index = newState.findIndex(x => x.uniqueId === link.uniqueId);
            newState[index].sectionType = sectionType;
            return newState;
        });
    };

    const onDelete = (link: Link) => {
        setSelectedLinks((currentSelectedLinks) => currentSelectedLinks.filter(x => x.uniqueId !== link.uniqueId));
    };

    return (
        <ModalDialog
            data-testid="links-creation-modal"
            title={translate("#PurchaseToPay/linksModal/header")}
            width="large"
            isOpen={modalVisible}
            onRequestClose={onModalClose}
            renderContent={() => {
                return (<>
                    <div style={{ marginBottom: 24 }}>
                        <Text id="search" variant="body" spacerMode="off">{translate("#PurchaseToPay/linksModal/documentSearchLabel")}</Text>
                        <AutoComplete
                            width="large"
                            onChange={onSelect}
                            disabled={false}
                            data-testid='link-selector'
                            dataMethodType="pagedItems"
                            getItemData={onGetItemData}
                            value={null}
                        />
                    </div>
                    <div style={{ marginBottom: 24 }}>
                        {
                            selectedLinks.length ? <DataTable<Link>
                                label="links"
                                key={`datatable-${missingTypeError}`} 
                                data-testid="link-selected-list"
                                allData={selectedLinks}
                                dataMethod="all"
                                columns={columnDefinition}
                                maxLinesPerRow={2}
                                paging={{}}
                            /> : null
                        }
                    </div>
                    {missingTypeError && <Notification data-testid="links-warning-notification" feedbackType="error" text={translate("#PurchaseToPay/linksModal/warningSubtext")} title={translate("#PurchaseToPay/linksModal/warningText")} />}
                </>);
            }}
            renderActions={() => {
                return (<>
                    <Button data-testid="cancel-adding-links-button" label={translate("#Core/cancel")} variant="secondary" onClick={onModalClose} />
                    <Button data-testid="confirm-adding-links-button" label={translate("#Enterprise/done")} onClick={onDone} />
                </>);
            }}
        />
    );
};