/// <amd-module name="Core/Medius.Core.Web/Scripts/components/customTopBarProvider/customTopBarProvider"/>

import { TopBarContext } from "Core/Medius.Core.Web/Scripts/components/topBar/topBarContextState";

type CustomViewModelTopBarProvider = () => any;

const topBarProviders: CustomTopBarModel[] = [];

export function register(model: CustomTopBarModel) {
    topBarProviders.push(model);
}

export function get(contextName: string): CustomTopBarModel {
    
    const matchingProviders = topBarProviders.filter(x => x.context === contextName);
    
    if (matchingProviders.length === 0) {
        return null;
    }
    if (matchingProviders.length > 1) {
        throw new Error("Multiple TopBarViewModelProviders matching for context: " + contextName);
    }
    return matchingProviders[0];
}

export interface CustomTopBarModel {
    templateName: string;
    context: TopBarContext;
    provider: CustomViewModelTopBarProvider;
}