/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Data.DataDefinition/Editor"/>

import { observableArray, isWriteableObservable } from "knockout";
import * as _ from "underscore";
import { getPropertyTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { getTypeName } from "Core/Medius.Core.Web/Scripts/Medius/core/type";
import { getPropertyType, getPropertyAttribute, hasAttribute, isEntityModelProperty, isObsolete } from "Core/Medius.Core.Web/Scripts/Medius/components/resolver/type";
import { unpack } from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
const REQUIRED_ATTRIBUTE = "System.ComponentModel.DataAnnotations.RequiredAttribute";
const STRINGLENGTH_ATTRIBUTE = "System.ComponentModel.DataAnnotations.StringLengthAttribute";
const TRANSLATE_ATTRIBUTE = "Medius.Globalization.TranslateAttribute";
const VISIBILITY_ATTRIBUTE = "Medius.ViewAttributes.VisibilityAttribute";

let notConfigurableProperties = [
    "Id",
    "$type",
    "SystemGenerated",
    "EntityVersion",
    "IsDeleted",
    "ReferencedFilesCount",
    "ViewId",
    "CreatedTimestamp"
];

const readOnlyPropertiesTemplates: Record<string, any> = {
    'ImportedTimestamp': 'Core:templates/Administration/ReadOnlyControls/DateTime.html'
};

class ConfigurableProperty {
    Label: any;
    Name: string;
    ControlId: string;
    Value: any;
    PropertyType: any;
    IsRequired: any;
    IsTranslatable: any;
    MaxLength: any;
    Context: any;
    FieldTemplate: any;
    EntityType: string;
    EntityId: any;
    EntityViewId: string;
    constructor(entityType: string, entityId: any, entityViewId: string, name: string, value: any, context: any, template: any) {
        const propertyType = getPropertyType(entityType, name);
        const stringLengthAttr = getPropertyAttribute(entityType, name, STRINGLENGTH_ATTRIBUTE);

        this.Label = getPropertyTranslation(`#${entityType}/${name}`) || name;
        this.Name = name;
        this.ControlId = ["control", name].join("-");
        this.Value = value;
        this.PropertyType = propertyType;
        this.IsRequired = hasAttribute(entityType, name, REQUIRED_ATTRIBUTE);
        this.IsTranslatable = hasAttribute(entityType, name, TRANSLATE_ATTRIBUTE);
        this.MaxLength = stringLengthAttr ? stringLengthAttr.ConstructorArguments[0] : null;
        this.Context = context;
        this.FieldTemplate = template;

        if (this.IsTranslatable) {
            this.EntityType = entityType;
            this.EntityId = entityId;
            this.EntityViewId = entityViewId;
        }
    }
}

class ViewModel {
    Entity: any;
    Tab: any;
    CompanyContextId: any;
    ConfigurableProperties = observableArray([]);
    EntityType: any;
    EntityId: any;
    EntityViewId: any;
    Context: any;
    constructor(entityWithContext: any, companyContextId: any, tabModel: any, excludedProperties?: any) {
        this.Entity = entityWithContext.Entity;
        this.Tab = tabModel;
        this.CompanyContextId = companyContextId;
        this.EntityType = getTypeName(unpack(this.Entity.$type));
        this.EntityId = unpack(this.Entity.Id);
        this.EntityViewId = unpack(this.Entity.ViewId);
        notConfigurableProperties = _.union(notConfigurableProperties, excludedProperties);
        this.Context = entityWithContext.Context;
    
        this.init();
    }

    init() {
        const t = _.chain(_.keys(this.Entity))
            .filter(this.verifyProperty.bind(this))
            .map((name: any) => {
                return this.createConfigurableProperty(name, this.Entity[name]);
            })
            .value();

        this.ConfigurableProperties(t);
    }

    verifyProperty(name: any) {
        const property = this.Entity[name],
            isInNotConfigurableProperties = _.contains(notConfigurableProperties, name),
            isWriteable = isWriteableObservable(property),
            isArray = _.isArray(unpack(property));

        return (isWriteable &&
            !isArray &&
            !isInNotConfigurableProperties &&
            isEntityModelProperty(this.EntityType, name) === true &&
            !isObsolete(this.EntityType, name) &&
            !hasAttribute(this.EntityType, name, VISIBILITY_ATTRIBUTE));
    }

    createConfigurableProperty(name: any, data: any) {
        const template = this.resolveTemplateForProperty(name),
            confProperty = new ConfigurableProperty(this.EntityType, this.EntityId, this.EntityViewId, name, data, this.Context, template);

        return confProperty;
    }

    resolveTemplateForProperty(propertyName: any) {
        if (readOnlyPropertiesTemplates[propertyName]) {
            return readOnlyPropertiesTemplates[propertyName];
        }
        return 'Core:templates/Administration/Control.html';
    }

    dispose() {
        this.Entity = null;
        this.Tab = null;
        this.CompanyContextId = null;
        this.ConfigurableProperties([]);
        this.ConfigurableProperties = null;
    }
}

export = function (entityWithContext: any, companyContextId: any, tabModel: any, excludedProperties?: any) {
    return new ViewModel(entityWithContext, companyContextId, tabModel, excludedProperties);
};