///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/stateMachine" />

import * as lexemeIterator from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/lexeme/iterator";
import * as lexemeParser from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/parser/lexeme";
import * as helpers from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/lexeme/helpers";
import * as ko from "knockout";    
import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";

export class StateMachine {
    entityType: any;
    initialState: any;
    message: ko.Observable;

    constructor(entityType: any, initialState: any){
        this.entityType = entityType;
        this.initialState = initialState;
        this.message = ko.observable(null);
    }

    getHints(lexemes: any, queryEndsWithWhitespace: any) {        
        if (isNullOrUndefined(this.entityType)) {
            return [];
        }
        
        const iterator = lexemeIterator.create(lexemes);
        
        return this.initialState.getHints(iterator, {
            endsWithWhitespace: queryEndsWithWhitespace,
            entityType: this.entityType,
            openedBrackets: 0
        });
    }
    
    validate(query: any) {
        this.message(null);
            
        if (isNullOrUndefined(this.entityType)) {
            return { valid: false };
        }
        
        if (!helpers.validateQuotes(query)) {
            return { valid: false };
        }

        const lexemes = lexemeParser.process(query);
        const iterator = lexemeIterator.create(lexemes);
        
        const result = this.initialState.validate(iterator, {
            endsWithWhitespace: helpers.endsWithWhitespace(query),
            entityType: this.entityType,
            openedBrackets: 0
        });
                    
        if (result.message) {
            this.message(result.message);
        }

        return result;
    }
}

export function create(entityType: any, initialState: any) {
    return new StateMachine(entityType, initialState);
}