///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/editors/amount/model" />
import {FinanceComponentField} from "Core/Medius.Core.Web/Scripts/Medius/components/editors/financeComponent/model";
import { getAmountDisplayResolution } from "Core/Medius.Core.Web/Scripts/Medius/core/settingsProvider";

class AmountField extends FinanceComponentField {
    constructor(bindingParameters: any, viewmodel: any, context: any){
        super(bindingParameters, viewmodel, context);
    }

    getResolution(currency: any) {
        return getAmountDisplayResolution(currency);
    }

    getValidationErrorMsgKey(): string {
        return "#Core/editorForValidation_amountFieldCannotBeEmpty";
    }
}


export function create(bindingParameters: any, viewmodel: any, context: any) {
    return new AmountField(bindingParameters, viewmodel, context);
}
