/// <amd-module name="Core/Medius.Core.Web/Scripts/components/staticDashboard/staticDashboard"/>
import * as ko from "knockout";
import { IStaticDashboard } from "Core/Medius.Core.Web/Scripts/components/staticDashboard/staticDashboardCollection";

export abstract class StaticDashboard implements IStaticDashboard {
    public readonly id: string;
    public readonly tabId: string;
    public readonly containerId: string;
    public readonly hrefContainerId: string;
    public readonly isOwned: boolean = false;

    public readonly name: ko.Observable<string>;
    public readonly template: ko.Observable<string>;
    public readonly isVisible: ko.Observable<boolean> = ko.observable(true);
    public abstract isAccessible(): Promise<boolean>;

    private hasRendered: boolean;

    public setActive() {
        if (!this.hasRendered) {
            this.init();
            return;
        }
    }

    protected init() {
        this.hasRendered = true;
    }

    protected constructor(id: string, name: string, template: string) {
        this.id = id;
        this.tabId = `${id}-tab`;
        this.containerId = id;
        this.hrefContainerId = `#${this.containerId}`;
        this.name = ko.observable(name);
        this.template = ko.observable(template);
        this.hasRendered = false;
    }
}
