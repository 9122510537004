/// <amd-module name="Core/Medius.Core.Web/Scripts/components/administration/autocompleters/company/promiseBasedCompanyAutocompleterContainer"/>
import * as React from "react";
import { ComboBoxChangeEvent, ComboBoxFilterChangeEvent } from "@progress/kendo-react-dropdowns";
import { CompanyAutocompleter } from "./companyAutocompleter";
import { debounce } from "underscore";
import { useState } from "react";

export interface IPromiseBasedCompanyAutocompleterContainerProps<T> {
    selectedItem: T;
    textFieldSelector: string;
    onCompanyChange: (event: ComboBoxChangeEvent) => void;
    getCompaniesPromise: (searchPhrase?: string) => JQueryPromise<T[]>;
    disabled: boolean;
    defaultLabel?: string;
}

export function PromiseBasedCompanyAutocompleterContainer<T>(props: IPromiseBasedCompanyAutocompleterContainerProps<T>) {
    const { textFieldSelector, onCompanyChange, selectedItem, getCompaniesPromise, disabled } = props;

    const [companyDropdownState, setCompanyDropdownState] = useState({
        loadedCompanies: [] as T[],
    });

    const handleCompanyFilterChange = (event: ComboBoxFilterChangeEvent) => {
        const filter = event.filter.value;
        debouncedQueryCompanies(filter);
    };

    const handleCompanyChange = (event: ComboBoxChangeEvent) =>
        onCompanyChange(event);

    const queryCompanies = (filter: string = '') =>
        getCompaniesPromise(filter)
            .then((response) => {
                setCompanyDropdownState((x) => ({ ...x, loadedCompanies: response }));
            });

    const debouncedQueryCompanies = debounce(queryCompanies, 300);

    return (
        <CompanyAutocompleter
            required={true}
            label={props.defaultLabel}
            selectedItem={selectedItem}
            textFieldSelector={textFieldSelector}
            onCompanyChange={handleCompanyChange}
            onFilterChange={handleCompanyFilterChange}
            onDropdownOpen={() => debouncedQueryCompanies()}
            loadedCompanies={companyDropdownState.loadedCompanies}
            disabled={disabled}
        />
    );
}
