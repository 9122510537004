/// <amd-module name="Core/Medius.Core.Web/Scripts/loginPage/ForgotPasswordPage"/>
import * as React from "react";
import {useState} from "react";
import {ajax} from "jquery";
import {getTenantId} from "Core/Medius.Core.Web/Scripts/Medius/lib/path";
import * as antiForgeryTokenUtils from "Core/Medius.Core.Web/Scripts/Medius/core/antiForgeryToken";
import Card from "Core/Medius.Core.Web/Scripts/components/card/Card";

export default function ForgotPasswordPage(props: IForgotPasswordPageProps) {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    
    function handleUserNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        setUsername(event.target.value);
    }

    function handleEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
        setEmail(event.target.value);
    }

    const resetPassword = () => {
        setLoading(true);
        const trimmedUserName = username.trim();
        const trimmedEmail = email.trim();
        if (!trimmedUserName || !trimmedEmail) {
            setError("Both username and email has to be provided");
            setLoading(false);
            return;
        }

        const ajaxOptions = {
            url: `/${getTenantId()}/Backend/Rest/localUsers/${trimmedUserName}/resetPassword`,
            data: JSON.stringify(trimmedEmail),
            type: 'POST',
            contentType: "application/json"
        };

        antiForgeryTokenUtils.addToHeaders(ajaxOptions);

        // unfortunately jQuery as we need statusCode
        ajax(ajaxOptions)
        .done(() => {
            props.goToForgotPasswordResultPage();
        })
        .fail((jqXhr: { status: number }) => {
            if (jqXhr.status === 400) {
                setError("Provided username or email is not correct");
            } else {
                setError("An error occured");
            }            
        })
        .always(() => setLoading(false));
    };

    return (
        <Card loading={loading} loaderMessage={"Please wait..."}>
            <div className="forgot-password-page">
                <div className="forgot-password-page__text">Please fill your username and your email address</div>
                <label className="card-label" htmlFor="username">Username</label>
                <input className="card-input" type="text" name="username" id="username" value={username} onChange={handleUserNameChange} autoFocus />

                <label className="card-label" htmlFor="email">Email address</label>
                <input className="card-input" type="text" name="email" id="email" value={email} onChange={handleEmailChange}/>
                <button className="card-button" onClick={resetPassword} data-testid="reset-password-button">Reset password</button>
                { error ? (<div className="forgot-password-page__errors" data-testid="forgot-password-error">{error}</div>) : null }
            </div>
            <div className="card-text-container">
                <a className="card-text" onClick={props.goToLoginPage} data-testid="back-to-login-page-link">Back</a>
            </div>
        </Card>
    );
}

interface IForgotPasswordPageProps {
    goToForgotPasswordResultPage: () => void;
    goToLoginPage: () => void;
}
