/// <amd-module name="Core/Medius.Core.Web/Scripts/components/procurement/validatorConfiguration/useIsValidatorActive"/>

import { useAppSelector } from "Core/Medius.Core.Web/Scripts/shared/store/hooks";
import { validatorConfigurationSelector } from "./validatorConfigurationSlice";

export function useIsValidatorActive(validator: string) {
    const validatorConfiguration = useAppSelector(validatorConfigurationSelector);

    return validatorConfiguration.some(vc => vc === validator);
}
