///<amd-module name = "Core/Medius.Core.Web/Scripts/Models/Medius.Core.Entities.AsyncTask.AsyncTaskInformation"/>
import * as ko from "knockout";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as date from "Core/Medius.Core.Web/Scripts/Medius/lib/utils/date";
import mappings = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/mapping/collection/instance");

const ELAPSED_TIME_UPDATE_INTERVAL = 1000;

function make00StringFormat(number:number) {
    if (number > 9) {
        return "" + number;
    } else if (number > 0) {
        return "0" + number;
    } else {
        return "00";
    }
}

function getHours(secondsTillCompletion:number) {
    const hours = Math.floor(secondsTillCompletion / (60 * 60));
    return make00StringFormat(hours);
}

function getMinutes(secondsTillCompletion:number) {
    secondsTillCompletion %= 60 * 60;
    const minutes = Math.floor(secondsTillCompletion / 60);
    return make00StringFormat(minutes);
}

function getSeconds(secondsTillCompletion:number) {
    secondsTillCompletion %= 60;
    const seconds = Math.floor(secondsTillCompletion);
    return make00StringFormat(seconds);
}

function formatRemainingTimeInfo(secondsTillCompletion:number) {
    const remainingTime = `${getHours(secondsTillCompletion)}:${getMinutes(secondsTillCompletion)}:${getSeconds(secondsTillCompletion)}`;
    const remainingTimeInfo = globalization.getLabelTranslation("#Core/elapsedTimeInfo_elapsedTime", remainingTime);
    return remainingTimeInfo;
}

function scheduleTimerUpdate(vm:any) {
    if (vm.TaskState() !== "InProgress") {
        return;
    }

    setTimeout(function () {
        const currentDate: any = date.utcNow();
        vm.CurrentOperationTime(currentDate - vm.TaskStartDateTime());
        scheduleTimerUpdate(vm);
    }, 1000);
}

export function register() {
    mappings.register("Medius.Core.Entities.AsyncTask.AsyncTaskInformation", function (vm:any) {
        let lastElapsedTimeUpdate:any, lastElapsedTimeValue:any;

        vm.DisplayedProgress = ko.computed(function() {
            return Math.min(vm.Progress() * 100.0, 100.0);
        });

        vm.QueueLengthInformation = ko.computed(function() {
            const queueLengthInformation = globalization.getLabelTranslation(
                "#Core/asyncTaskWaitingInQueueInfo_positionInQueue",
                vm.PositionInQueue()
            );

            return queueLengthInformation;
        });

        vm.getNextQuarterStopInterval = function () {
            const nextHalfStop = (100.0 - vm.DisplayedProgress()) / 4;
            const nextHalfStopInterval = nextHalfStop / vm.DisplayProgressSpeed();
            return nextHalfStopInterval;
        };

        const currentDate:any = date.utcNow();
        vm.CurrentOperationTime = ko.observable(currentDate - vm.TaskStartDateTime());

        vm.ExpectedOperationTime = ko.computed(function () {
            if (this.Progress() === 0) {
                return;
            }

            const currentDate:any = date.utcNow();
            const elapsedTime = currentDate - this.TaskStartDateTime();
            const expectedTime = elapsedTime / this.Progress();

            return expectedTime;
        }, vm);

        vm.TimeRemainingInformation = ko.computed(function () {
            const currentOperationTime = this.CurrentOperationTime();
            const expectedOperationTime = this.ExpectedOperationTime();

            const currentDate:any = date.utcNow();
            if (lastElapsedTimeUpdate && currentDate - lastElapsedTimeUpdate < ELAPSED_TIME_UPDATE_INTERVAL) {
                return lastElapsedTimeValue;
            }

            if (!expectedOperationTime) {
                return globalization.getLabelTranslation("#Core/calculatingElapsedTimeInfo");
            }

            const secondsTillCompletion = (expectedOperationTime - currentOperationTime) / 1000;
            const remainingTimeInfo = formatRemainingTimeInfo(secondsTillCompletion + 10);

            lastElapsedTimeValue = remainingTimeInfo;
            lastElapsedTimeUpdate = date.utcNow();
            return remainingTimeInfo;
        }, vm);

        vm.DisplayProgressSpeed = ko.computed(function () {
            const expectedOperationTime = this.ExpectedOperationTime();
            const currentOperationTime = this.CurrentOperationTime.peek();

            const remainingDisplayedProgress = 100.0 - vm.DisplayedProgress.peek();
            return remainingDisplayedProgress / (expectedOperationTime - currentOperationTime);
        }, vm);

        vm.dispose = function () {
            vm.QueueLengthInformation.dispose();
            vm.DisplayProgressSpeed.dispose();
            vm.ExpectedOperationTime.dispose();
            vm.TimeRemainingInformation.dispose();
        };

        scheduleTimerUpdate(vm);
    });
}