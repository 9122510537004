/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/ImportManagement/importManagamentState"/>
import {QueueItem, QueueItemsDto, QueueItemDto, QueueItemState} from "./interfaces";
import {getPropertyTranslation} from "Core/Medius.Core.Web/Scripts/lib/globalization";

const LOAD_DATA = 'LOAD_DATA';
const SELECT_ROW = 'SELECT_ROW';

interface LoadDataAction {
    type: typeof LOAD_DATA;
    data: QueueItemsDto;
}

interface SelectRowAction {
    type: typeof SELECT_ROW;
    selectedRowId: number;
}

export function importManagementReducer(state: {data: QueueItem[]; total: number}, action: LoadDataAction | SelectRowAction) {
    switch (action.type) {
        case LOAD_DATA:
            return {
                data: action.data.queueItems.map(createQueueItem),
                total: action.data.totalCount
            };
        case SELECT_ROW:
            return {
                data: state.data.map(x => ({
                    ...x,
                    selected: x.id === action.selectedRowId
                })),
                total: state.total
            };
        default:
          throw new Error();
      }
}

export function selectRow(selectedRowId: number) {
    return {type: SELECT_ROW, selectedRowId} as const;
}

export function loadData(data: QueueItemsDto) {
    return { type: LOAD_DATA, data} as const;
}

function createQueueItem(item: QueueItemDto): QueueItem {
    return {
        ...item,
        itemType: getPropertyTranslation(item.itemType),
        createdTimestamp: new Date(item.createdTimestamp),
        selected: false,
        successfullyProcessed: item.state === QueueItemState.Processed && item.isSuccessful
    };
}
