/// <amd-module name="Core/Medius.Core.Web/Scripts/components/invoice/reasonCodes/components/section"/>
import * as React from "react";
import { useState, useEffect } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as notification from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import { ReasonCode, AddedReasonCode } from "../types";
import { SelectAvailableReasonCodes } from "./selectAvailableReasonCodes";
import { AddedReasonCodesList } from "./addedReasonCodesList";
import * as reasonCodesService from "../service";
import { useAbortController } from "Core/Medius.Core.Web/Scripts/shared/hooks/useAbortController";
import { aborted } from "Core/Medius.Core.Web/Scripts/Medius/core/fetch/rest";

export type ReasonCodesSectionProps = {
    readonly documentViewId: string;
    readonly documentCompanyId?: number;
    readonly availableReasonCodes: ReasonCode[];
};

type AddReasonCodeButtonProps = {
    readonly documentViewId: string;
    readonly documentCompanyId?: number;
    readonly reasonCodeId: number;
    readonly onClick: (reasonCode: AddedReasonCode) => void;
};

export function ReasonCodesSection(props: ReasonCodesSectionProps) {
    const { documentViewId, documentCompanyId, availableReasonCodes } = props;
    const [selectedReasonCodeId, setSelectedReasonCodeId] = useState<number>();
    const [canAdd, setCanAdd] = useState<boolean>(false);
    const [added, setAdded] = useState<AddedReasonCode[]>([]);
    const [areAddedLoading, setAreAddedLoading] = useState(true);
    const abortController = useAbortController();

    useEffect(() => {
        reasonCodesService
            .getFor(documentViewId, documentCompanyId, abortController.signal)
            .then(state => {
                setCanAdd(state.canAdd);
                setAdded(sortByCreateDate(state.added));
                setAreAddedLoading(false);
            })
            .catch(err => {
                if (aborted(err))
                    return;

                notifyError();
                setAreAddedLoading(false);
            });
    }, [documentViewId, documentCompanyId, setCanAdd, setAdded, abortController.signal]);

    const addReasonCode = (reasonCode: AddedReasonCode) =>
        setAdded(added => [reasonCode, ...added]);

    const removeReasonCode = (reasonCode: AddedReasonCode) =>
        setAdded(added => added.filter(code => code.id !== reasonCode.id));

    const sortByCreateDate = (added: AddedReasonCode[]) =>
        added.sort((a, b) => b.created.localeCompare(a.created));

    const notifyError = () =>
        notification.error(translate(
            "#PurchaseToPay/documentReasonCodes/document/addedReasonCodes/error"));

    return (
        <>
            {canAdd && documentCompanyId &&
                <div className="row-fluid reason-codes-section__available">
                    <div className="span6">
                        <SelectAvailableReasonCodes
                            values={availableReasonCodes}
                            onSelected={setSelectedReasonCodeId} />
                    </div>
                    <div className="span3">
                        <AddReasonCodeButton
                            reasonCodeId={selectedReasonCodeId}
                            onClick={addReasonCode}
                            {...props} />
                    </div>
                </div>}
            <div className="row-fluid">
                <div className="span12">
                    <AddedReasonCodesList
                        values={added}
                        isLoading={areAddedLoading}
                        canAdd={canAdd}
                        onRemove={removeReasonCode}
                        {...props} />
                </div>
            </div>
        </>
    );
}

export function AddReasonCodeButton(props: AddReasonCodeButtonProps) {
    const { documentViewId, documentCompanyId, reasonCodeId, onClick: onAdd } = props;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const abortController = useAbortController();

    const notifyError = () => notification.error(translate(
        "#PurchaseToPay/documentReasonCodes/document/addReasonCode/error"));

    const label = translate(
        "#PurchaseToPay/documentReasonCodes/document/addReasonCode/label");

    const disabled = isLoading || reasonCodeId == null;

    const add = async () => {
        setIsLoading(true);
        return reasonCodesService
            .addTo(documentViewId, reasonCodeId, documentCompanyId, abortController.signal)
            .then(result => {
                onAdd(result);
                setIsLoading(false);
            })
            .catch(err => {
                if (aborted(err))
                    return;

                notifyError();
                setIsLoading(false);
            });
    };

    return (
        <Button onClick={add} icon="plus" disabled={disabled} data-testid="add-reason-code-button">
            {label}
        </Button>
    );
}
