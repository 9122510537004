/// <amd-module name="Core/Medius.Core.Web/Scripts/components/slidingModal/slidingModal"/>
import { PropsWithChildren, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useClosableElement } from './useClosableElement';
import * as React from "react";

type SlidingModalProps = PropsWithChildren<{
    show: boolean;
    width: number;
    position: 'left' | 'right';
    onClose: () => void;
    onClosed?: () => void;
    disableFocusTrap?: boolean;
    initialFocus?: { initialFocus: string };
}>;

export const SlidingModal = ({
    children,
    show,
    width,
    position,
    onClose,
    onClosed,
    disableFocusTrap = false,
    initialFocus = { initialFocus: '' },
}: SlidingModalProps) => {
    const modalClassName = `sv-sliding-modal--${position}`;

    return (
        <>
            <CSSTransition
                in={show}
                timeout={300}
                classNames={modalClassName}
                onExited={onClosed}
                unmountOnExit>
                <aside
                    className={`sv-sliding-modal ${modalClassName}`}
                    style={{ width }}>
                    <SlidingModalDialog
                        onClose={onClose}
                        disableFocusTrap={disableFocusTrap}
                        initialFocus={initialFocus}>
                        {children}
                    </SlidingModalDialog>
                </aside>
            </CSSTransition>
            <CSSTransition
                in={show}
                timeout={300}
                classNames='sv-sliding-modal__overlay'
                unmountOnExit>
                <div className='sv-sliding-modal__overlay'></div>
            </CSSTransition>
        </>
    );
};

const SlidingModalDialog = ({
    onClose,
    children
}: {
    onClose: () => void;
    children: PropsWithChildren<any>;
    disableFocusTrap: boolean;
    initialFocus: { initialFocus: string };
}) => {
    const contentRef = useRef<HTMLDivElement>(null);
    useClosableElement({ onClose, contentRef });

    return (
        <div className='sv-sliding-modal__dialog' ref={contentRef}>
            {children}
        </div>
    );
};

export const SlidingModalCloseButton = ({
    onClick,
}: {
    onClick: () => void;
}) => {
    return (
        <div className="sv-sliding-modal-close-btn-wrapper">
            <button className='sv-sliding-modal-close-btn' onClick={onClick}>
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                    <path d="M0 0h24v24H0z" fill="none"/>
                    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
                </svg>       
            </button>
        </div>
    );
};