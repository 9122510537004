///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/presenters/number/model"/>
import * as _ from "underscore";
import * as ko from "knockout";
import { Basic } from "Core/Medius.Core.Web/Scripts/Medius/components/presenters/basic";
import * as localizer from "Core/Medius.Core.Web/Scripts/Medius/components/editors/number/localizer";
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as helpers from "Core/Medius.Core.Web/Scripts/Medius/components/editors/helpers";
import * as numberDefaults from "Core/Medius.Core.Web/Scripts/Medius/components/presenters/number/defaults";

class NumberModel extends Basic {
    numberLocalizer: ko.Computed<localizer.LocalizerAdapter>;
    constructor(bindingParameters:any) {
        const defaults = helpers.mergeDefaults(bindingParameters || {}, numberDefaults.get());
        const numberLocalizer = ko.computed(() => {
            return localizer.create(
                koUtils.unpack(defaults.resolution),
                defaults.trimZeros,
                defaults.roundDecimal,
                defaults.notLocalized
            );
        }); 

        super(defaults, numberLocalizer);

        this.numberLocalizer = numberLocalizer;
    }

    dispose() {
        this.numberLocalizer.dispose();
        this.numberLocalizer = null;
        super.dispose();
    }
}

export const derive = NumberModel;

export function create(bindingParameters:any) {
    return new NumberModel(bindingParameters);
}

export function extend(obj:any, bindingParameters:any) {
    _.extend(obj, create(bindingParameters));
}