/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/administrationServices"/>
import {get} from "Core/Medius.Core.Web/Scripts/Medius/core/fetch/rest";
import {ComponentType} from "react";
import {RegisterPage} from "Core/Medius.Core.Web/Scripts/Medius/apps/reactSpa/routes";

interface AdministrationEntry {
    type: string;
    link: string;
    component: ComponentType;
}

let sidebarData: Promise<SidebarDto> | null = null;
const newAdministrationEntities: AdministrationEntry[] = [];

export function registerAdministrationEntity(type: string, component: ComponentType, link?: string, route?: string) {
    RegisterPage(route ? `/configuration${route}` : `/configuration/${type}`, component);
    newAdministrationEntities.push({type,
        component,
        link: link ? `/configuration${link}` : `/configuration/${type}`});
}

export function registerInAdministrationHost(type: string, component: ComponentType, link: string, route?: string) {
    RegisterPage(route ? `/configuration${route}` : `/configuration/${type}`, component);
    newAdministrationEntities.push({type,
        component,
        link});
}

export function getAdministrationUrlPrefix(forType: string) {
    const newAdministrationEntity = newAdministrationEntities.filter(x => x.type === forType)[0];
    return newAdministrationEntity ? newAdministrationEntity.link : `/Administration/${forType}`;
}

export function getSidebarData() {
    if (!sidebarData) {
        sidebarData = get<SidebarDto>("sidebar/sidebarData");
    }
    return sidebarData;
}

export interface AdditionalLinkDto {
    displayName: string;
    viewPath: string;
}

export interface QuickLinksDto {
    displayName: string;
    forType: string;
}

export interface ConfigurableTypeDto {
    displayName: string;
    fullName: string;
}

export interface SidebarDto {
    quickLinks: QuickLinksDto[];
    additionalLinks: AdditionalLinkDto[];
    configurableTypes: ConfigurableTypeDto[];
}
