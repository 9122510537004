/// <amd-module name="Core/Medius.Core.Web/Scripts/customTabs/definitions/contracts/customFilterCells"/>

import * as React from "react";
import { FC } from "react";
import { GridFilterCellProps } from "@progress/kendo-react-grid";
import { DatePicker, DatePickerChangeEvent, MultiViewCalendar, MultiViewCalendarProps } from "@progress/kendo-react-dateinputs";
import { Button } from "@progress/kendo-react-buttons";
import { ICompanyDto, ISupplierFilterDto, ICompanyFilterDto, getCompaniesAsync } from "Core/Medius.Core.Web/Scripts/customTabs/definitions/contracts/service";
import { PromiseBasedCompanyAutocompleterContainer } from "Core/Medius.Core.Web/Scripts/customTabs/definitions/contracts/companyAutocompleter/promiseBasedCompanyAutocompleterContainer";
import { SupplierAutocompleter } from "Core/Medius.Core.Web/Scripts/customTabs/definitions/contracts/supplierAutocompleter/supplierAutocompleter";
import { Input, InputChangeEvent } from "@progress/kendo-react-inputs";

interface DateFilterCellProps {
    wrappedProps: GridFilterCellProps;
    operator: 'gte' | 'lte' | 'eq';
}

interface CompanyFilterCellProperties {
    selected: ICompanyFilterDto;
    invoiceCompanyId: number;
    handleFilterChanged: (company: ICompanyDto) => void;
}

interface InputFilterCellProps extends GridFilterCellProps{
    dataTestId: string;
}

export const dateFilterCellFactory = (props: DateFilterCellProps): any => {
    return (<DateFilterCell wrappedProps={props.wrappedProps} operator={props.operator} />);
};

export const inputFilterCellFactory = (props: InputFilterCellProps): any => {
    return (<InputFilterCell {...props} />);
};

export const DateFilterCell: FC<DateFilterCellProps> = (props: DateFilterCellProps) => {
    const onChange = (event: DatePickerChangeEvent) => {
        props.wrappedProps.onChange({
            value: event.target.value,
            operator: props.operator,
            syntheticEvent: event.syntheticEvent
        });
    };

    return (
        <div className="dateTimeCell">
            <DatePicker
                calendar={(props: React.JSX.IntrinsicAttributes & MultiViewCalendarProps<any> & React.RefAttributes<any>) => <MultiViewCalendar {...props} weekNumber={true} views={1}/>}
                className="supplierContract__input-filter-cell"
                onChange={onChange}
                value={props.wrappedProps.value}
            />
            {props.wrappedProps.value && <Button data-testid='dateTimeCell-clear_filter' icon="filter-clear" onClick={(event) => {
                props.wrappedProps.onChange({
                    value: "",
                    operator: "",
                    syntheticEvent: event
                });
            }}></Button>}
        </div>
    );
};

export const companyFilterCell: any = (props: CompanyFilterCellProperties) => {
    return <PromiseBasedCompanyAutocompleterContainer
        onCompanyChange={props.handleFilterChanged}
        selectedItem={props.selected}
        invoiceCompanyId={props.invoiceCompanyId}
        disabled={false}
        getCompaniesPromise={getCompaniesAsync}
        textFieldSelector="nameWithIdAndIdentifiers" />;
};

export const supplierFilterPagingComponent = (params: { supplier: ISupplierFilterDto, invoiceSupplierId: number, company: ICompanyFilterDto, onChange: (newSupplier: ISupplierFilterDto) => void }) => {
    return (
        <SupplierAutocompleter company={params?.company} supplier={params.supplier} invoiceSupplierId={params.invoiceSupplierId} onChange={params.onChange} pageSize={10} />
    );
};

export const InputFilterCell = (props: InputFilterCellProps) => {
    const onChange = (event: InputChangeEvent) => {
        props.onChange({
            value: event.target.value,
            operator: 'contains',
            syntheticEvent: event.syntheticEvent
        });
    };

    return (
        <div className="k-filtercell">
            <Input
                data-testid={props.dataTestId}
                style={{ width: "100%" }}
                onChange={onChange}
                value={props.value}
            />
        </div>
    );
};
