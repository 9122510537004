/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/resolver/type" />

import * as _ from "underscore";
import * as type from "Core/Medius.Core.Web/Scripts/Medius/core/type";
import { isNullOrUndefined, isEmptyString } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
const ENTITY_FULLNAME = "Medius.Data.Entity";
const ICOMPONENT_FULLNAME = "Medius.Data.IComponent";
const IGNORE_ATTRIBUTE = "Medius.SqlAttributes.IgnoreAttribute";
const OBSOLETE_ATTRIBUTE = "System.ObsoleteAttribute";

function getPropertyDescription(entityType: string, property: string) {
    if (property.startsWith(entityType)) {
        // + 1 for the dot part of property path (Entity.Property)
        property = property.substring(entityType.length + 1, property.length);
    }
    const entityDescription = type.describe(entityType);
    if (!entityDescription) {
        return null;
    }
    
    return entityDescription.Properties[property];
}


export function getPropertyType(entityType: string, property: string) {
    try {
        const propertyDescription = getPropertyDescription(entityType, property);
        
        if (!propertyDescription) {
            return null;
        }

        if (propertyDescription.Type.indexOf('System.Nullable`1') > -1 ||
            propertyDescription.Type.indexOf('System.Lazy`1') > -1) {
            return propertyDescription.GenericTypeArguments[0];
        }
        else if (propertyDescription.IsPrimitive &&
            propertyDescription.Type.indexOf("Medius.") === 0) {
            return "System.Enum";
        }
        else {
            return propertyDescription.Type;
        }
    } catch (e) {
        return null;
    }
}

export function getCollectionPropertyElementType(entityType: any, property: string) {
    try {
        const propertyDescription = getPropertyDescription(entityType, property);
        
        if (!propertyDescription) {
            return null;
        }

        if (propertyDescription.Type.indexOf("System.Collections.Generic.IList`1") > -1 ||
            propertyDescription.Type.indexOf("System.Collections.Generic.IEnumerable`1") > -1) {
            return propertyDescription.GenericTypeArguments[0];
        }
        else {
            return propertyDescription.Type;
        }
    } catch (e) {
        return null;
    }
}

export function getPropertyAttributes(entityType: any, property: string) {
    try {
        return getPropertyDescription(entityType, property).Attributes;
    } catch (e) {
        return [];
    }
}

export function getPropertyAttribute(entityType: any, property: string, attributeName: any) {
    const attributes = getPropertyAttributes(entityType, property);
    return _(attributes).find(function (attr) {
        return attr.Type.FullName === attributeName;
    });
}

export function getInterfaces(entityType: any) {
    const entityDescription = type.describe(entityType);

    if (!entityDescription) {
        return [];
    }
    
    return entityDescription.Interfaces || [];
}

export function getProperties(entityType: any, includeEntityFields?: any, onlyPrimitives?: any): Record<string, any> {
    let entityFields: any = null;

    onlyPrimitives = (_.isUndefined(onlyPrimitives)) ? false : onlyPrimitives;
    includeEntityFields = (_.isUndefined(includeEntityFields)) ? true : includeEntityFields;

    let properties: any[] = [];
    const hierarchy = type.getHierarchy(entityType);

    _.each(hierarchy, function(t) {
        const typeDescription = type.describe(t);
        
        if (_(typeDescription).isNull()) {
            return;
        }
        
        const tp = (onlyPrimitives) ?
            _.filter(typeDescription.Properties, function (p) {
                return p.IsPrimitive === true;
            }) :
            typeDescription.Properties; 

        properties.push(_(_.keys(tp)).without('$type'));
    });
    
    properties = _.uniq(_.flatten(properties));
    
    if (!includeEntityFields) {
        entityFields = _.keys(type.describe(ENTITY_FULLNAME).Properties);
        properties = _.reject(properties, function(p) {
            return _.contains(entityFields, p);
        });
    }
    
    if (isEntity(entityType)) {
        properties.push("Id");
        properties.push("CreatedTimestamp");
    }

    return properties;
}

export function getPrimitiveProperties(entityType: any, includeEntityFields: any) {
    return getProperties(entityType, includeEntityFields, true);
}

export function isEntityModelProperty(entityType: any, property: string) {
    return !isNullOrUndefined(getPropertyDescription(entityType, property));
}

export function hasAttributeOnPath(entityType: any, property: string, attributeFullName: any): any {
    let remainingPath: any = null;
    let propertyType: any = null;

    if (isEmptyString(entityType) || isEmptyString(property) || isEmptyString(attributeFullName)) {
        return false;
    }

    const path = property.split(".");
    const propertyDescription = getPropertyDescription(entityType, path[0]);
    
    if (!propertyDescription) {
        return false;
    }

    const isInCurrentStep = _.any(propertyDescription.Attributes, function (attr) {
        return attr.Type.FullName === attributeFullName;
    });

    if (isInCurrentStep) {
        return true;
    }
    
    if (path.length > 1) {
        propertyType = getPropertyType(entityType, path[0]);
        if (!propertyType) {
            return false;
        }

        if (isCollection(propertyType)) {
            propertyType = getCollectionPropertyElementType(entityType, path[0]);
        }

        remainingPath = _.rest(path, 1).join(".");
        return hasAttributeOnPath(propertyType, remainingPath, attributeFullName);
    }
    
    return false;
}

export function hasAttribute(entityType: any, property: string, attributeFullName: any): any {
    let remainingPath: any = null;
    let propertyType: any = null;

    if (isEmptyString(entityType) || isEmptyString(property) || isEmptyString(attributeFullName)) {
        return false;
    }

    const path = property.split(".");
    if (path.length > 1) {
        propertyType = getPropertyType(entityType, path[0]);
        
        if (!propertyType) {
            return false;
        }
        
        if (isCollection(propertyType)) {
            propertyType = getCollectionPropertyElementType(entityType, path[0]);
        }
        
        remainingPath = _.rest(path, 1).join(".");
        return hasAttribute(propertyType, remainingPath, attributeFullName);
    }

    const propertyDescription = getPropertyDescription(entityType, property);
    
    if (!propertyDescription) {
        return false;
    }
    
    return _.any(propertyDescription.Attributes, function (attr) {
        return attr.Type.FullName === attributeFullName;
    });
}

export function isIgnored (entityType: any, property: string) {
    return hasAttributeOnPath(entityType, property, IGNORE_ATTRIBUTE);
}

export function isObsolete (entityType: any, property: string) {
    return hasAttribute(entityType, property, OBSOLETE_ATTRIBUTE);
}

export function isSubclass (className: any, baseClassName: any) {
    const typeDescription = type.describe(className);

    if (isNullOrUndefined(typeDescription)) {
        return false;
    }
    return _.contains(_.rest(typeDescription.Hierarchy), baseClassName);
}
export function isSameClassOrSubclass(className: any, baseClassName: any) {
    return (className === baseClassName || isSubclass(className, baseClassName));
}

export function isCollection(fullName: any) {
    if (!fullName) {
        return false;
    }

    return fullName.indexOf("System.Collections") > -1;
}

export function isEntity(fullName: any) {
    if (!fullName) {
        return false;
    }
    
    return isSubclass(fullName, ENTITY_FULLNAME);
}

export function implementsInterface(fullName: any, interfaceName: any, lookInParent?: any) {
    if (!fullName) {
        return false;
    }
    
    const entityDescription = type.describe(fullName);

    if (!entityDescription) {
        return false;
    }
    
    lookInParent = lookInParent === undefined ? true : false;
    const hierarchy = (lookInParent) ? entityDescription.Hierarchy : [fullName];
    
    const result = _.chain(hierarchy)
        .map(t => {
            return _.values(getInterfaces(t));
        })
        .flatten()
        .uniq()
        .contains(interfaceName)
        .value();
    
    return result;
}

export function isComplexType(fullName: string) {
    return isEntity(fullName) || implementsInterface(fullName, ICOMPONENT_FULLNAME);
}

export function containsCollection(fullName: any, path?: any) {
    if (isEmptyString(fullName) || isEmptyString(path)) {
        return false;
    }

    const parts = path.split(".");

    return _.any(parts, function(part) {
        const fullNameType = getPropertyType(fullName, part);
        return isCollection(fullNameType);
    });
}