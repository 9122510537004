///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/models/userSettings/tabs/dataSearchConfiguration"/>

import * as rest from "Core/Medius.Core.Web/Scripts/Medius/core/rest";
import * as sync from "Core/Medius.Core.Web/Scripts/Medius/core/sync";
import * as user from "Core/Medius.Core.Web/Scripts/Medius/lib/utils/user";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import userSettings = require("Core/Medius.Core.Web/Scripts/Medius/models/userSettings/userSettings");
import contextFactory = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/context");
const dataSearchUserConfigurationType = "Medius.Core.Entities.DataSearch.DataSearchUserConfiguration";

import { create } from "Core/Medius.Core.Web/Scripts/Medius/apps/userSettings/tab";

export function register(){
    create({
        name: globalization.getLabelTranslation("#Core/documentSearch"),
        template: "userSettings-dataSearchConfiguration-tpl",
        
        model: userSettings.DataSearchUserConfiguration,
        originalModel: null,
        
        init: function () {
            const userId = parseInt(user.getEntity().Id, 10);
    
            this.loadDataSearchSettings(userId).pipe((dataSearchUserConfiguration: any) => {
                this.model(dataSearchUserConfiguration);
            });
        },
        
        loadDataSearchSettings: function (userId: string) {
            return rest.get("DataSearchManager", "dataSearchUserConfiguration/" + userId, { cache: false })
                .pipe((data: any) => {              
                    const entity = (data) ? data : sync.getNew(dataSearchUserConfigurationType);
                    this.originalModel = entity;
                    const context = contextFactory();
                    return context.create(entity);
                });
        },
        
        onSave: function(modifications: any[]) {
            const model = this.model();
            
            if (model.SendNotificationsInSummary() !== this.originalModel.SendNotificationsInSummary) {
                modifications.push({
                    Key: "DataSearchUserConfiguration",
                    Value: { SendNotificationsInSummary: model.SendNotificationsInSummary() }
                });
            }
        }
    });
}