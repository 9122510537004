/// <amd-module name="Core/Medius.Core.Web/Scripts/components/overconnectionBanner/overconnectionBanner"/>
import * as React from "react";
import { Notification } from "@medius/ui-controls";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";

export function OverconnectionBanner(props: {onOverconnectionFilterChanged: (value: boolean) => void, isFilterEnabled: boolean, isFilterVisible: boolean}) {
    return (
        <div data-testid="overconnection-banner">
            <Notification feedbackType="info" text={translate("#OrderbasedInvoice/overlyConnectedBannerDetail")} 
             actionLabel={props.isFilterVisible ? props.isFilterEnabled ? translate("#OrderbasedInvoice/hideOverlyConnectedLines") : translate("#OrderbasedInvoice/showOverlyConnectedLines") : null} 
             title={translate("#OrderbasedInvoice/overlyConnectedBannerTitle")} onAction={() =>{
                const filterNewValue = !props.isFilterEnabled;
                props.onOverconnectionFilterChanged(filterNewValue);
             }} />
        </div>
    );
}
