/// <amd-module name="Core/Medius.Core.Web/Scripts/components/links/referencedDocuments"/>

import * as React from 'react';
import { Card, ColumnDefinitionSet, DataTable, Button, IconUserUserRegular, IconSystemSettingsFill, IconActionDeleteRegular } from '@medius/ui-controls';
import { InvoiceLinkViewModel, LinkViewModelBase } from 'Core/Medius.Core.Web/Scripts/components/links/linkViewModel';
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { DatePresenter } from "Core/Medius.Core.Web/Scripts/shared/components/datePresenter";

interface ReferencedLinksProps {
    links: InvoiceLinkViewModel[];
    isHistorical?: boolean;
    onItemDelete?: (item: LinkViewModelBase) => void;
}

export const ReferencedDocuments = (props: ReferencedLinksProps) => {
    const columnDefinition: ColumnDefinitionSet<InvoiceLinkViewModel> = [  
        {
            key: "HeaderName",
            dataKey: "HeaderName",
            displayName: translate('#PurchaseToPay/links/documentType'),
            columnType: "dataField",
            dataType: "text",
            minColumnSize: 2,
            overflowType: "truncate"
        },
        {
            columnType: "customContent",
            key: "IsSystemGenerated",
            displayName: "",
            renderRowContent: (item) => {
                return item.IsSystemGenerated ? <IconSystemSettingsFill data-testid="icon-settings" /> : <IconUserUserRegular data-testid="icon-contact" />;
            },
            maxColumnSize: 'content',
            horizontalAlignment : 'left'
        },
        {
            columnType: "customContent",
            key: "InvoiceNumber",
            displayName: translate('#PurchaseToPay/links/invoiceNumber'),
            overflowType: "truncate",
            renderRowContent: (item) => {
                return (<a href={item.linkMetadata.link}>{item.InvoiceNumber}</a>);
            },
            horizontalAlignment : 'left'
        },
        {
            columnType: "customContent",
            key: "Company",
            displayName: translate('#PurchaseToPay/links/company'),
            renderRowContent: (item) => {
                return (<div className="referencedLinks__companyColumn">{item.Company}</div>);
            },
            maxColumnSize: 'content',
            horizontalAlignment : 'left',
            overflowType: "truncate"
        },
        {
            columnType: "customContent",
            key: "Supplier",
            displayName: translate('#PurchaseToPay/links/supplier'),
            renderRowContent: (item) => {
                return (<div className="referencedLinks__supplierColumn">{item.Supplier}</div>);
            },
            maxColumnSize: 'content',
            horizontalAlignment : 'left',
            overflowType: "truncate"
        },
        {
            columnType: "customContent",
            key: "InvoiceDate",
            displayName: translate('#PurchaseToPay/links/invoiceDate'),
            renderRowContent: (item) => {
                return (<DatePresenter date={new Date(item.InvoiceDate)} format="d"></DatePresenter>);
            },
            maxColumnSize: 'content',
            horizontalAlignment : 'left',
            overflowType: "truncate"
        },
        {
            key: "PreliminaryVoucherNumber",
            dataKey: "PreliminaryVoucherNumber",
            displayName: translate('#PurchaseToPay/links/preliminaryVoucherNumber'),
            columnType: "dataField",
            dataType: "text",
            overflowType: "truncate"
        },
        {
            key: "FinalVoucherNumber",
            dataKey: "FinalVoucherNumber",
            displayName: translate('#PurchaseToPay/links/finalVoucherNumber'),
            columnType: "dataField",
            dataType: "text",
            overflowType: "truncate"
        },
        {
            columnType: "customContent",
            key: "PreliminaryPostingDate",
            displayName: translate('#PurchaseToPay/links/preliminaryPostingDate'),
            renderRowContent: (item) => {
                return  (item.PreliminaryPostingDate ? <DatePresenter date={new Date(item.PreliminaryPostingDate)} format="d"></DatePresenter> : <></>);
            },
            maxColumnSize: 'content',
            horizontalAlignment : 'left',
            overflowType: "truncate"
        },
        {
            columnType: "customContent",
            key: "FinalPostingDate",
            displayName: translate('#PurchaseToPay/links/finalPostingDate'),
            renderRowContent: (item) => {
                return (item.FinalPostingDate ? <DatePresenter date={new Date(item.FinalPostingDate)} format="d"></DatePresenter>: <></>);
            },
            maxColumnSize: 'content',
            horizontalAlignment : 'left',
            overflowType: "truncate"
        },
        {
            columnType: "actions",
            key: "iconDelete",
            displayName: "",
            renderRowActions: (item) => item.AllowDelete ? <Button data-testid='delete-button' onClick={() => props?.onItemDelete(item)}  label={translate('#PurchaseToPay/reporting/delete')} hideLabel icon={<IconActionDeleteRegular />} variant="inline" /> : <></>,
            maxColumnSize: 'content'
        }
    ];
    return  (
        <div className={props.isHistorical ? "linksWithSections__historicalCard" : ""}>
            <div className={"referencedLinks__mainContainer " + (props.isHistorical ? "referencedLinks__dataTableCard-historical" : "")}>
                <Card data-testid={'referenced-documents-card' + (props.isHistorical ? "-historic" : "")}
                      title={translate('#PurchaseToPay/referencedDocuments')}>
                        <DataTable<InvoiceLinkViewModel>
                            data-testid="referenced-documents-grid"
                            label={translate('#PurchaseToPay/referencedDocuments')}
                            allData={props.links}
                            dataMethod="all"
                            columns={columnDefinition}
                            enableResizing={false}
                            variant="compact"
                            maxLinesPerRow={10}
                            paging={{}}
                            sorting={{
                                enableSorting: false
                            }}
                            rowSelection={{
                                allowMultiple: false,
                                onSelectedRowChanged: (item: InvoiceLinkViewModel, selected: boolean) => null,
                                isRowSelectable: (item: InvoiceLinkViewModel) => false,
                            }}
                            userPreferenceKey="referenced-documents-settings"
                        />
                </Card>
            </div>
        </div>
    );
};