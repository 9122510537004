/// <amd-module name="Core/Medius.Core.Web/Scripts/lib/collections/unique"/>
export default function unique<T>(collection: T[], getKey: (item: T) => any): T[] {
    const uniqueKeys: any = {};
    const distinctItems: T[] = [];
    for (let i = 0; i < collection.length; ++i) {
        if (!uniqueKeys[getKey(collection[i])]) {
            distinctItems.push(collection[i]);
        }
        uniqueKeys[getKey(collection[i])] = 1;
    }
    return distinctItems;
}
