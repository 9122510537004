/// <amd-module name="Core/Medius.Core.Web/Scripts/components/inboxSearch/inboxSearch"/>
import * as ko from "knockout";
import * as _ from "underscore";
import { error, success } from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import { lightApi } from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rpc";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { Advanced, create as advancedSearch } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/search/advanced";

interface ISearchCritera {
    Keywords: string[];
    WithLabels: boolean;
    Labels: any[];
    SerializedQuery: string;
}

export default class InboxSearch {
    public inbox: any;
    public advanced: Advanced;
    public isFilterApplied: ko.Computed<boolean>;
    public keywords = ko.observable<string>(null);
    public currentCriteria = ko.observable(null);
    public currentSavedFilter = ko.observable(null);
    public isFilterActive = ko.observable(false);
    public isAdvancedSearchVisible = ko.observable(false);
    public savedFilters = ko.observableArray([]);
    public triggerAdvancedSearch: () => void;
    public clear: () => void;
    public saveFilterAsNew: () => any;
    public saveFilter: () => void;
    public toggleAdvancedSearch: () => void;

    constructor(inbox: any) {
        this.inbox = inbox;
        this.advanced = advancedSearch();
        this.isFilterApplied = ko.computed(() => {
          return this.isFilterActive()
            || this.advanced.isAdvancedFilterActive()
            || !!this.currentSavedFilter();
        });

        this.triggerAdvancedSearch = () => {
            if (this.keywords()) {
                this.isFilterActive(true);
            }
            this.advanced.isAdvancedFilterActive(true);
            this.triggerSearch();
        };

        this.clear = () => {
            this.isFilterActive(false);
            this.keywords(null);
            this.currentCriteria(null);
            this.currentSavedFilter(null);
            this.inbox.filterTasks();
            this.advanced.reset();
        };

        this.saveFilterAsNew = () => {
            if (!this.validateFilter()) {
                return false;
            }

            const data = {
                searchToSave: {
                    SerializedQuery: this.advanced.serializeQuery(),
                    Name: this.advanced.Name().trim()
                }
            };

            this.inbox.isLoading(true);

            return lightApi("InboxService", "SaveFilter", data)
                .done(() => {
                    success(translate("#Core/searchFilterSaved"));
                    this.loadSavedFilters();
                })
                .always(() => {
                    this.inbox.isLoading(false);
                });
        };

        this.saveFilter = () => {

            if (!this.currentSavedFilter()) {
                this.saveFilterAsNew();
            } else {
                this.updateCurrentSavedFilter();
            }
        };

        this.toggleAdvancedSearch = () => {
            this.isAdvancedSearchVisible(!this.isAdvancedSearchVisible());
            if (this.isAdvancedSearchVisible()) {
                this.advanced.loadTaskDescriptions();
            }
        };
    }

    clearKeywords() {
        const currentCriteria = this.currentCriteria();
        if (currentCriteria === null) return;

        this.isFilterActive(false);
        currentCriteria.Keywords = null;
        this.keywords(null);
        this.updateCurrentCriteria(currentCriteria);

        if (this.isFilterApplied())
            this.inbox.filterTasks(this.currentCriteria());
        else {
            this.clear();
        }     
    }

    loadSavedFilters() {
        return lightApi("InboxService", "GetSavedFilters")
                .done(filters => {
                    this.savedFilters(filters);
                });
    }

    parseKeywords(keywords: string | any) {
        if(!_.isString(keywords)) {
            return [];
        }

        const splitted = _.escape(keywords).trim()
            .split(" ")
            .filter(keyword => keyword.trim() !== "");

        return splitted.slice(0, 100);
    }

    createSearchCriteria(query: string) {
        const keywords = this.parseKeywords(this.keywords());
        const labels = this.advanced.serializeLabels();
        const withLabels = this.advanced.WithLabels();

        return {
            Keywords: keywords,
            WithLabels: withLabels,
            Labels: labels,
            SerializedQuery: query
        } as ISearchCritera;
    }

    updateCurrentCriteria(criteria: any) {
        const currentCriteria = this.currentCriteria();
        if (!!currentCriteria) {
            currentCriteria.Keywords = criteria.Keywords;
            currentCriteria.Labels = criteria.Labels;
            currentCriteria.SerializedQuery = criteria.SerializedQuery;
            this.currentCriteria(currentCriteria);
        } else {
            this.currentCriteria(criteria);
        }
    }

    triggerSearch() {
        const query = this.advanced.serializeQuery();
        const criteria = this.createSearchCriteria(query);
        this.updateCurrentCriteria(criteria);
        this.currentSavedFilter(null);
        this.inbox.filterTasks(criteria);
        this.isAdvancedSearchVisible(false);
    }

    triggerInputSearch() {
        if (this.keywords()) {
            this.isFilterActive(true);
            this.triggerSearch();
        } else {
            this.clearKeywords();
        }
    }

    applySavedFilter(savedFilter: any) {

        if (!savedFilter || !savedFilter.SerializedQuery) {
            return;
        }

        this.advanced.parseQuery(savedFilter).then(() => {
            const criteria = this.createSearchCriteria(savedFilter.SerializedQuery);

            this.updateCurrentCriteria(criteria);
            this.currentSavedFilter(savedFilter);

            this.inbox.filterTasks(criteria);
            this.isAdvancedSearchVisible(false);
        });
    }

    validateFilter() {
        const name = this.advanced.Name();
        const serializedQuery = this.advanced.serializeQuery();

        if (!name) {
            error(translate("#Core/searchFilterCantBeSavedWithoutName"));
            return false;
        }

        if (!serializedQuery) {
            error(translate("#Core/searchFilterCantBeSavedWithoutConditions"));
            return false;
        }

        return true;
    }

    updateCurrentSavedFilter() {
        if (!this.validateFilter()) {
            return false;
        }

        const data = {
            searchToSave: {
                Id: this.currentSavedFilter().Id,
                SerializedQuery: this.advanced.serializeQuery(),
                Name: this.advanced.Name().trim()
            }
        };

        this.inbox.isLoading(true);

        return lightApi("InboxService", "SaveFilter", data)
            .done(() => {
                success(translate("#Core/searchFilterSaved"));
                this.loadSavedFilters();
            })
            .always(() => {
                this.inbox.isLoading(false);
            });
    }

    removeSavedFilter(savedFilter: any) {
        if (!savedFilter || !savedFilter.Id) {
            return false;
        }

        const data = {
            filterId: savedFilter.Id
        };

        this.inbox.isLoading(true);
        return lightApi("InboxService", "RemoveFilter", data)
            .done(() => {
                success(translate("#Core/searchFilterDeleted"));
                this.loadSavedFilters();
            })
            .always(() => {
                this.inbox.isLoading(false);
            });
    }

    dispose() {
        this.isFilterApplied.dispose();
    }
}
