/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/PasswordManagement/passwordManagement"/>
import * as React from "react";
import {useState} from "react";
import {registerAdministrationEntity} from "Core/Medius.Core.Web/Scripts/AdminPages/administrationServices";
import {SidebarNavigation} from "Core/Medius.Core.Web/Scripts/AdminPages/sidebarNavigation";
import {translate} from "Core/Medius.Core.Web/Scripts/lib/globalization";
import {RootState} from "Core/Medius.Core.Web/Scripts/shared/store/reduxStore";
import {useSelector} from "react-redux";
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import {Button} from "@progress/kendo-react-buttons";
import {post} from "Core/Medius.Core.Web/Scripts/Medius/core/fetch/rest";
import {success} from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import {handleAnyError} from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import {NoAccess} from "Core/Medius.Core.Web/Scripts/Medius/apps/noAccess/noAccess";

export function PasswordManagement() {
    const hasAccess = useSelector((state: RootState) => state.accesses.passwordManagement);
    const [dialogVisible, setDialogVisible] = useState(false);
    const toggleDialog = () => { setDialogVisible(!dialogVisible); };
    const onResetAll = async () => {
        toggleDialog();
        try {
            await post("Backend/Rest/localUserAdministration/forcePasswordChange");
            success(translate("#Security/actionSuccessfullyCompleted"));
        }
        catch(e) {
            handleAnyError(e);
        }
    };

    if(!hasAccess) {
        return(<NoAccess/>);
    }
    return (
    <>
        <SidebarNavigation/>
        <div className="passwordManagement">
            <h2 className="administration-page-header">{translate("#Security/passwordManagement")}</h2>
            <div className="passwordManagement__content">
                <div>{translate("#Security/forcePasswordChangeAll")}</div>
                <Button
                    themeColor="primary"
                    onClick={toggleDialog}>{translate("#Security/force")}
                </Button>
            </div>
        </div>
        {dialogVisible && <Dialog title={translate("#Security/passwordManagement")} onClose={toggleDialog}>
            <p className="passwordManagement__dialog-content">
                {translate("#Security/forcePasswordChangeAllInfo")}
                <br/>
                {translate("#Security/areYouSureToPerformAction")}
            </p>
            <DialogActionsBar>
                <Button onClick={toggleDialog}>{translate("#Security/cancel")}</Button>
                <Button themeColor="primary" onClick={onResetAll}>{translate("#Security/resetAll")}</Button>
            </DialogActionsBar>
        </Dialog>}
    </>);
}

export function registerPasswordManagementAdminPage() {
    registerAdministrationEntity("PasswordManagement", PasswordManagement);
}
