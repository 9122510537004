///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/core/comments/model"/>
import * as ko from "knockout";
import * as _ from "underscore";
import * as $ from "jquery";
import * as backendErrorHandler from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import * as type from "Core/Medius.Core.Web/Scripts/Medius/core/type";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as rest from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rest";
import { Comment } from "Core/Medius.Core.Web/Scripts/Medius/core/comment/comment";
import { MentionEditorFactory } from "Core/Medius.Core.Web/Scripts/components/mention/editor/mentionEditorFactory";

const mentionEditorFactoryInstance = MentionEditorFactory.getInstance();

export class Comments {
    entityViewId: any;
    entityType: string;
    defaultNumberOfComments: number;
    allowRemoveComment: ko.Observable<boolean>;
    documentId: any;
    showAll: ko.Observable<boolean>;
    comments: ko.ObservableArray<any>;
    visibleComments: ko.Computed<any>;
    IsLoading: ko.Observable<boolean>;
    newComment: ko.Observable<string>;
    mentionEditor: any;
    canSend: ko.Computed<boolean>;
    totalComments: ko.Computed<number>;
    totalHiddenComments: ko.Computed<number>;
    showMoreLabel: ko.Computed<any>;
    headerComments: ko.Computed<any>;
    deleteComment: (hash: string) => void;
    isShowMoreLinkVisible: ko.Computed<boolean>;
    
    constructor(entityViewId:any, entityType:string, documentId:any, isLongTermArchived:boolean, isWrapped:boolean) {
        this.entityViewId = entityViewId;
        this.entityType = type.getTypeName(entityType);
        this.defaultNumberOfComments = 3;
        this.allowRemoveComment = ko.observable(false);
        this.documentId = ko.unwrap(documentId);
    
        this.showAll = ko.observable(false);
        this.comments = ko.observableArray();
    
        this.visibleComments = ko.computed(() => {
            const localComments = this.comments();
    
            return (this.showAll()) ? localComments : localComments.slice(0, this.defaultNumberOfComments);
        });
    
        this.IsLoading = ko.observable(false);
        this.newComment = ko.observable("");
    
        this.mentionEditor = mentionEditorFactoryInstance.create(getMentionsOptions(this, isLongTermArchived, isWrapped));
    
        this.canSend = ko.computed(() => {
            return this.newComment().trim().length > 0;
        });
    
        this.totalComments = ko.computed(() => {
            return this.comments().length;
        });
    
        this.totalHiddenComments = ko.computed(() => {
            const result = this.totalComments() - this.defaultNumberOfComments;
            return Math.max(0, result);
        });
    
        this.showMoreLabel = ko.computed(() => {
            return globalization.getFormattedLabelTranslation("#Core/showMore_number", [this.totalHiddenComments().toString()]);
        });
    
        this.headerComments = ko.computed(() => {
            return globalization.getFormattedLabelTranslation("#Core/comments_total", [this.totalComments().toString()]);
        });
    
        this.deleteComment = (hash:string) => {
            this.comments.remove((comment:any) => {
                return comment.hash === hash;
            });
    
            rest.del("comments", hash)
                .fail(function(jqXhr) {
                    backendErrorHandler.handleAnyError(jqXhr);
                });
        };
    
        this.isShowMoreLinkVisible = ko.computed(() => {
            return !this.showAll() && this.totalHiddenComments() > 0;
        });
    
        this.loadComments();
    }

    reset() {
        this.newComment("");
    }

    addComment() {
        this.IsLoading(true);
        const comment = $.trim(this.newComment());

        rest.post("comments", "", {
            comment: comment,
            entityViewId: this.entityViewId,
            entityType: this.entityType
        }).done((savedCommentDto) => {
            const newComment = new Comment(savedCommentDto, this.allowRemoveComment, this.deleteComment);
            this.comments.unshift(newComment);
            this.reset();
        }).fail((jqXhr) => {
            backendErrorHandler.handleAnyError(jqXhr);
        }).always(() => {
            this.IsLoading(false);
        });
    }

    loadComments() {
        this.IsLoading(true);

        rest.get("comments", "", {
            entityViewId: this.entityViewId,
            entityType: this.entityType
        }, {cache: false}).done((comments) => {
            const commentsModels = comments.map((comment:any) => {
                return new Comment(comment, this.allowRemoveComment, this.deleteComment);
                })
                .sort((a:any, b:any) => {
                    return (a.createdDate - b.createdDate < 0) ? 1 : -1;
                });

            markFirstCarriedOverComment(commentsModels);
            this.comments(commentsModels);
        }).fail((jqXhr) => {
            backendErrorHandler.handleAnyError(jqXhr);
        }).always(() => {
            this.IsLoading(false);
        });
    }

    showMoreComments() {
        this.showAll(true);
    }

    dispose() {
        this.headerComments.dispose();
        this.showMoreLabel.dispose();
        this.totalHiddenComments.dispose();
        this.totalComments.dispose();
        this.visibleComments.dispose();
        this.canSend.dispose();
        this.isShowMoreLinkVisible.dispose();
        this.mentionEditor.dispose();
    }
}

function markFirstCarriedOverComment(comments:any) {
    let wasFirstMarked = false;
    comments.forEach(
        function(comment:any) {
            if (!wasFirstMarked && comment.isCarriedOver) {
                comment.isFirstCarriedOver = true;
                wasFirstMarked = true;
            }
        });
}

function getMentionsOptions(comments: Comments, isLongTermArchived:boolean, isWrapped:boolean) {
    let mentionsDisableDueToTranlsationKey = "";
    let autocompleterDisablementReasonDocumentType = "";
    let mentionsEnabled = true;


    if (isLongTermArchived) {
        mentionsDisableDueToTranlsationKey = "#Core/mentionDisabledArchived";
        mentionsEnabled = false;
    }
    else if (!comments.documentId || isWrapped) {
        mentionsDisableDueToTranlsationKey = "#Core/mentionDisabledDuringCreation";
        mentionsEnabled = false;
    }
    else if (!type.isCompanyRelated(comments.entityType)) {
        mentionsDisableDueToTranlsationKey = "#Core/mentionDisabledNonCompanyRelated";
        autocompleterDisablementReasonDocumentType = comments.entityType;
        mentionsEnabled = false;
    }

    const placeholder = mentionsEnabled
        ? globalization.getLabelTranslation("#Core/writeACommentMentionsEnabled")
        : globalization.getLabelTranslation("#Core/writeAComment");


    return {
        mentionsEnabled: mentionsEnabled,
        value: comments.newComment,
        documentId: comments.documentId,
        options: {
            placeholder: placeholder,
            rows: 4,
            autocompleterDisablementReasonTranslationKey: mentionsDisableDueToTranlsationKey,
            autocompleterDisablementReasonDocumentType: autocompleterDisablementReasonDocumentType
        }
    };
}