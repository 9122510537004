/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/grid/default/gridHeadCell"/>

import * as React from "react";
import { Text } from "@medius/ui-controls";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";

interface Props {
    Name?: string;
    Icon?: React.FC;
}

export function GridHeadCell({ Name, Icon }: Props) {
    return Icon ? <Icon /> : <Text variant="heading3">{translate(Name)}</Text>;
}