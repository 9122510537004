/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/image/model"/>
import { filter } from "underscore";
import { pureComputed } from "knockout";
import { unpack, pack } from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import { createUrl } from "Core/Medius.Core.Web/Scripts/Medius/core/rest";

class ImageViewModel {
    hashFiles: ko.Observable<any>;
    public hashFile = () => this.hashFiles().length > 0 ? this.hashFiles()[0] : null;
    public pageUrl = () => {
        if (this.hashFile()) {
            const page = 1;
            const hash = unpack(this.hashFile().Hash || this.hashFile().hash);
            const format = "png";
            const tag = unpack(this.hashFile().Type || this.hashFile().type);
            return createUrl("MediaService", `image/${hash}/${format}/pages/${page}?tag=${tag}`);
        } else {
            return "";
        }
    };
    public currentImage = pureComputed(() => this.pageUrl());

    constructor(hashFiles: any) {
        this.hashFiles = pack(filter(hashFiles, (file) => (unpack(file.Type || file.type) !== "EDIDocumentContent")));
    }
}

export function imageViewModel(hashFiles: any) {
    return new ImageViewModel(hashFiles);
}

