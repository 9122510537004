/// <amd-module name="Core/Medius.Core.Web/Scripts/components/administration/autocompleters/company/companyAutocompleter"/>
import { ComboBox, ComboBoxChangeEvent, ComboBoxFilterChangeEvent, ComboBoxOpenEvent } from "@progress/kendo-react-dropdowns";
import * as React from "react";

export interface ICompanyAutocompleterProps<T> {
    required: boolean;
    label: string;
    textFieldSelector: string;
    selectedItem: T;
    loadedCompanies: T[];
    onCompanyChange: (event: ComboBoxChangeEvent) => void;
    onFilterChange: (event: ComboBoxFilterChangeEvent) => void;
    onDropdownOpen: (event: ComboBoxOpenEvent) => void;
    disabled: boolean;
}

export function CompanyAutocompleter<T>(props: ICompanyAutocompleterProps<T>) {
    const { required, label, textFieldSelector, selectedItem, loadedCompanies, onCompanyChange, onFilterChange, onDropdownOpen, disabled } = props;

    const handleCompanyChange = (event: ComboBoxChangeEvent) =>
        onCompanyChange(event);

    const handleFilterChange = (event: ComboBoxFilterChangeEvent) =>
        onFilterChange(event);

    const handleCompanyDropdownOpen = (event: ComboBoxOpenEvent) =>
        onDropdownOpen(event);

    return (
        <ComboBox
            data-testid="company-autocompleter"
            label={label}
            filterable
            required={required}
            data={loadedCompanies}
            value={selectedItem}
            textField={textFieldSelector}
            disabled={disabled}
            style={{ width: "250px", marginBottom: "10px" }}
            onFilterChange={handleFilterChange}
            onChange={handleCompanyChange}
            onOpen={handleCompanyDropdownOpen}
        />
    );
}
