/// <amd-module name="Core/Medius.Core.Web/Scripts/lib/development/noMSDatesValidator"/>
import * as mode from "./mode";
import * as notification from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import * as logger from "../../Medius/lib/logger";

export function isValid(json: any) {
    const visitedObjects = new Set();
    const hasNoMSDates = isValidRecursively(json, visitedObjects);
    return hasNoMSDates;
}

function isValidRecursively(json: any, visitedObjects: Set<any>): boolean {
    if (typeof json === "string") {
        if (RegExp(/^Date\(\d{13}\)$/).test(json)) {
            return false;
        }
    }

    if (isArray(json)) {
        return isValidArrayRecursively(json, visitedObjects);
    } else if ((typeof json === "object") && (json !== null)) {

        // Naive protection from circular & shared dependencies 
        // Shared dependencies could be supported if needed by rewriting this validation algorithm to DFS based one (which discovers cycles)
        if (visitedObjects.has(json)) {
            return false;
        }

        visitedObjects.add(json);

        return isValidObjectRecursively(json, visitedObjects);
    } else {
        return true;
    }
}

function isValidArrayRecursively(arr: any[], visitedObjects: Set<any>) {
    return arr.every(x => isValidRecursively(x, visitedObjects));
}

function isValidObjectRecursively(obj: any, visitedObjects: Set<any>) {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            if (!isValidRecursively(obj[key], visitedObjects)) {
                return false;
            }
        }
    }
    return true;
}

function isArray(o: any) {
    return Object.prototype.toString.call(o) === "[object Array]";
}

export function assertNoMicrosoftFormatDates(json: any) {
    if (!mode.isProduction()) {
        if (!isValid(json)) {
            const title = "Incorrect date format";
            const message = `Legacy Microsoft Date format "/Date(0000000000000)/" returned, return dates in ISO format instead (use Web API controller)`;
            notification.error(message, title);
            logger.error(`[${title}] ${message}`);
            return true;
        }
    }
    return false;
}
