/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/lib/utils"/>
import * as _ from "underscore";
import * as $ from "jquery";

function bindFunction(func: any, context: any): any {
    return _.bind(func, context);
}

export function bind(source: any, context: any): any {
    if (typeof source === "function") {
        return bindFunction(source, context);
    } else if (source && typeof source === "object") {
        _(source).each(function (v, k) {
            if (typeof v === "function") {
                context[k] = _.bind(v, context);
            }
        });
        return context;
    }
    throw new Error("Invalid input parameters");
}

export function fireBlurOnActiveInputElement(): void {
    if ($(document.activeElement).is('input')) {
        (document.activeElement as HTMLInputElement).blur();
    }
}