/// <amd-module name="Core/Medius.Core.Web/Scripts/customTabs/definitions/contracts/supplierAutocompleter/supplierAutocompleter"/>

import * as React from "react";
import { useEffect, useState } from "react";
import { ISupplierFilterDto, ICompanyFilterDto } from "Core/Medius.Core.Web/Scripts/customTabs/definitions/contracts/service";
import { useDebounce } from "use-debounce";
import { useAbortController } from "Core/Medius.Core.Web/Scripts/shared/hooks/useAbortController";
import { getSuppliersWithPagingAsync } from "Core/Medius.Core.Web/Scripts/customTabs/definitions/contracts/service";
import { ComboBox, ComboBoxChangeEvent, ComboBoxPageChangeEvent, ComboBoxCloseEvent } from "@progress/kendo-react-dropdowns";
import { aborted } from "Core/Medius.Core.Web/Scripts/Medius/core/fetch/rest";
import * as notification from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import dataLoadingComponentFactory from "Core/Medius.Core.Web/Scripts/customTabs/definitions/contracts/dataLoadingComponentFactory";
import { isEnterEvent, isArrowEvent} from "Core/Medius.Core.Web/Scripts/customTabs/definitions/contracts/eventActions";

type SupplierAutocompleterProps = {
    readonly supplier: ISupplierFilterDto;
    readonly invoiceSupplierId: number;
    readonly onChange: (newSupplier: ISupplierFilterDto) => void;
    readonly pageSize: number;
    readonly company: ICompanyFilterDto;
};

type Optional<T> = T | undefined;
type Filter = {
    searchPhrase: Optional<string>,
    skip: number,
    take: number
};

export function SupplierAutocompleter({ supplier, invoiceSupplierId, onChange, pageSize, company }: SupplierAutocompleterProps) {
    const abortController = useAbortController();
    const [internalSelectedItem, setInternalSelectedItem] = React.useState(supplier);
    const [suppliers, setSuppliers] = useState<ISupplierFilterDto[]>([]);
    const [isInitialized, setIsInitialized ] = useState(false);
    const [filter, setFilter] = useState<Filter>({ searchPhrase: undefined, skip: 0, take: pageSize });
    const [total, setTotal] = useState<number>(0);
    const [debouncedFilter] = useDebounce(filter, 300);
    const [isDataLoading, setIsDataLoading] = useState(false);

    useEffect(() => {    
            if (!isInitialized) {
                setIsInitialized(true);
                return;
            }    

            setIsDataLoading(true);   
            getSuppliersWithPagingAsync(invoiceSupplierId, company?.id, filter.skip, filter.take, filter.searchPhrase, abortController.signal)
            .then(data => {
                setSuppliers(data ? data.suppliers : []);
                setTotal(data ? data.total : 0);
                setIsDataLoading(false);
            })
            .catch((err: any) => {
                setIsDataLoading(false);
                if (!aborted(err)) {
                    notification.error(translate("#Contract/contractsForInvoices/supplierFilter/error"));
                }
            });
    }, [debouncedFilter, abortController.signal]);

    const onPageChange = (ev:ComboBoxPageChangeEvent) => setFilter(f => ({ ...f, skip: ev.page.skip, take: ev.page.take }));

    const handleCompanyDropdownClose = (event: ComboBoxCloseEvent) => {
        if (isEnterEvent(event.nativeEvent)) {
            onChange(internalSelectedItem);
        }
    };

    const handleSupplierChanged = (event: ComboBoxChangeEvent) => {
        setInternalSelectedItem(event.value);
        if (!isArrowEvent(event.nativeEvent)) {
            onChange(event.value);
        }
    };

    const handleFirstElementFocus = () => 0;

    return (
        <div data-testid="supplier-autocompleter">
            <ComboBox
                virtual={{
                    pageSize: pageSize,
                    skip: filter.skip,
                    total: total
                }}
                focusedItemIndex={handleFirstElementFocus}
                data={suppliers}
                label={undefined}
                textField="nameWithIdAndIdentifiers"
                filterable
                onFilterChange={ev => {
                    if (filter.searchPhrase !== ev.filter.value) {
                        setFilter(f => ({...f, searchPhrase: ev.filter.value, skip: 0}));
                    }
                }}
                defaultValue={internalSelectedItem}
                onOpen={_ => {
                    if (filter.searchPhrase !== "") {
                        setFilter(f => ({ ...f, searchPhrase: "" }));
                    }
                }}
                onClose={handleCompanyDropdownClose}
                onChange={handleSupplierChanged}
                onPageChange={onPageChange}
                popupSettings={{ width: '480px', popupClass: "supplier-autocompleter for-selenium_supplier-autocompleter-list" }}
                listNoDataRender={dataLoadingComponentFactory(isDataLoading)}
            />
        </div>
    );
}