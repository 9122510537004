/// <amd-module name="Core/Medius.Core.Web/Scripts/components/links/genericLinks"/>

import * as React from 'react';
import { Card, ColumnDefinitionSet, Button, DataTable, IconUserUserRegular, IconSystemSettingsFill, IconActionDeleteRegular } from '@medius/ui-controls';
import { LinkViewModelBase, EntityLinkViewModel } from 'Core/Medius.Core.Web/Scripts/components/links/linkViewModel';
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";

interface GenericLinksProps {
    links: EntityLinkViewModel[];
    header: string;
    isHistorical?: boolean;
    onItemDelete?: (item: LinkViewModelBase) => void;
}

export const GenericLinks = (props: GenericLinksProps) => {
    const columnDefinition: ColumnDefinitionSet<EntityLinkViewModel> = [
        {
            key: "HeaderName",
            dataKey: "HeaderName",
            displayName: translate('#PurchaseToPay/links/type'),
            columnType: "dataField",
            dataType: "text",
            minColumnSize: 2,
            overflowType: "truncate"
        },
        {
            columnType: "customContent",
            key: "IsSystemGenerated",
            displayName: "",
            renderRowContent: (item) => {
                return item.IsSystemGenerated ? <IconSystemSettingsFill data-testid='icon-settings' /> : <IconUserUserRegular data-testid='icon-contact' />;
            },
            maxColumnSize: 'content',
            horizontalAlignment : 'left',
            overflowType: "truncate"
        },
        {
            columnType: "customContent",
            key: "Label",
            displayName: "",
            overflowType: "truncate",
            renderRowContent: (item) => {
                return (<a href={item.linkMetadata.link}>{item.Label}</a>);
            }
        },
        {
            columnType: "actions",
            key: "iconDelete",
            displayName: "",
            renderRowActions: (item) => item.AllowDelete ? <Button data-testid='delete-button' onClick={() => props?.onItemDelete(item)} label={translate('#PurchaseToPay/reporting/delete')} hideLabel icon={<IconActionDeleteRegular />} variant="inline" /> : <></>,
            maxColumnSize: 'content'
        }
    ];

    return  (
        <div data-testid="generic-documents-main-section" className={"genericLinks__mainContainer " + (props.isHistorical ? "genericLinks__dataTableCard-historical" : "")}>
            <Card data-testid={'generic-documents-card' + (props.isHistorical ? "-historic" : "")} title={props.header}>
                <DataTable<EntityLinkViewModel>
                            data-testid="generic-documents-grid"
                            label={props.header}
                            allData={props.links}
                            dataMethod="all"
                            columns={columnDefinition}
                            variant="compact"
                            enableResizing={false}
                            maxLinesPerRow={4}
                            paging={{}}
                            sorting={{
                                enableSorting: false
                            }}
                            rowSelection={{
                                allowMultiple: false,
                                onSelectedRowChanged: () => null,
                                isRowSelectable: () => false,
                            }}
                            userPreferenceKey="generic-links-settings"
                        />
            </Card>
        </div>);
};