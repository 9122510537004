///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/parser/condition"/>
import * as iterator from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/lexeme/iterator";
import * as operatorsProvider from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/provider/operators";
import * as translationsProvider from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/provider/translations";
import { removeQuotes } from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/lexeme/helpers";
import * as _ from "underscore";

class ConditionParser {
    fullTypeName: string;
    lexemeIterator: any;
    constructor(entityType:string, lexemeIterator:any) {
        this.fullTypeName = entityType;
        this.lexemeIterator = lexemeIterator;
        
        if (!this.fullTypeName) {
            this.fullTypeName = lexemeIterator.next();
        }
    }

    parse() {
        const logicalOperators = operatorsProvider.getLogicalOperatorsWithoutNegation();

        let conditionsTree:any = this.parseCondition();

        while (this.hasConditionsLeft(logicalOperators)) {
            const relationOperator = this.lexemeIterator.next();
            const secondCondition = this.parseCondition();
            const relationName = operatorsProvider.getOperatorName(relationOperator);
            conditionsTree = this.combineConditions(relationName, conditionsTree, secondCondition);                
        }

        return conditionsTree;
    }

    combineConditions(relationName:string, conditionA:any, conditionB:any) {
        const values = [conditionA, conditionB];

        const conditionsTree = {
            Operator: relationName,
            Values: values
        };
        
        return conditionsTree;
    }

    hasConditionsLeft(logicalOperators:any[]) {
        const next = this.lexemeIterator.next();
        this.lexemeIterator.prev();
        
        return _.contains(logicalOperators, next) && !_.contains(operatorsProvider.getSeparators(), next);
    }

    parseCondition() {
        const firstLexeme = this.lexemeIterator.next();

        if (firstLexeme === '(') {
            return this.parseConditionInBrackets();
        } else if (firstLexeme === operatorsProvider.getLogicalNegationOperator()) {
            return this.parseConditionWithNegation();
        } else {
            this.lexemeIterator.prev();
            return this.parseStatement();
        }
    }
    
    parseConditionInBrackets() {
        const inBrackets = this.lexemeIterator.getLexemesInBrackets() || [];

        if (inBrackets.length === 0) {
            return null;
        }

        const innerConditionParser = new ConditionParser(this.fullTypeName, iterator.create(inBrackets));
        return innerConditionParser.parse();
    }
    
    parseConditionWithNegation() {
        const statement: {Operator:string, Values:any[]} = {
            Operator: 'not',
            Values: [this.parseCondition()]
        };
        
        return statement;
    }
    
    parseStatement() {
        const lexemes = [];
        let statement: any = null;

        while (this.lexemeIterator.hasNext() && !statement) {
            const current = this.lexemeIterator.next();
            lexemes.push((current === '(') ? this.parseValuesInBrackets() : current);
            statement = this.matchStatement(lexemes);
        }
        
        return statement;
    }
    
    matchStatement(lexemes:any) {
        const statements = operatorsProvider.getOperators();

        const correctStatement = _.find(statements, (statement) => {
            return this.isProperStatement(statement, lexemes);
        });
        
        if (!correctStatement) {
            return null;
        }

        return this.createStatement(correctStatement, lexemes);
    }
    
    isProperStatement(statementDefinition:any, lexemes:any) {
        const arity = statementDefinition.Arity;
        const operatorTranslation = statementDefinition.Translation;
        const operatorType = statementDefinition.OpType;
        const totalLexemes = lexemes.length;

        if (operatorType === 'Binary' && totalLexemes === 3) {
            return removeQuotes(lexemes[1]) === operatorTranslation;
        }

        if (operatorType === 'Nullary' &&
            (arity === 0 && totalLexemes === 2)) {
            return removeQuotes(lexemes[1]) === operatorTranslation;
        }
        
        if (operatorType === 'Unary' &&
            ((arity === -1 && totalLexemes > 2) ||
             (arity === 0 && totalLexemes === 1) ||
             (arity === 1 && totalLexemes === 2) ||
             (arity === 2 && totalLexemes === 3))) {
            return removeQuotes(lexemes[0]) === operatorTranslation;
        }
        
        return false;
    }
    
    createStatement(statementDefinition:any, lexemes:any) {
        const statement: {Operator: any, Property:any, Values:any} = {
            Operator: statementDefinition.Operator,
            Property: null,
            Values: null
        };
        
        if (statementDefinition.OpType === 'Binary') {
            statement.Property = translationsProvider.resolvePropertyFromTranslation(this.fullTypeName, lexemes[0]);
            statement.Values = _.isArray(lexemes[2]) ? lexemes[2] : [lexemes[2]];
        }
        else if (statementDefinition.OpType === 'Nullary') {
            statement.Property = translationsProvider.resolvePropertyFromTranslation(this.fullTypeName, lexemes[0]);
            return statement;
        }
        else {
            statement.Values = _.isArray(lexemes[1]) ? lexemes[1] : [lexemes[1]];
        }

        statement.Values = statement.Values.map(function (value:any) {
            return removeQuotes(value);
        });

        return statement;
    }
    
    parseValuesInBrackets() {
        const inBrackets = this.lexemeIterator.getLexemesInBrackets();

        return _.filter(inBrackets, function(lex) {
            return !lex.match(/[,;]/);
        });
    }
}

export function create(entityType:string, lexemeIterator:any) {
    return new ConditionParser(entityType, lexemeIterator);
}