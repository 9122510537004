/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/ImportConfiguration/importConfigurationPage"/>
import * as React from "react";
import {ImportConfigurationAdminPage} from "./importConfiguration";
import {registerAdministrationEntity} from "Core/Medius.Core.Web/Scripts/AdminPages/administrationServices";
import {useSelector} from "react-redux";
import {RootState} from "Core/Medius.Core.Web/Scripts/shared/store/reduxStore";
import { NoAccess } from "Core/Medius.Core.Web/Scripts/Medius/apps/noAccess/noAccess";

function ImportConfigurationPage() {
    const hasAccess = useSelector((state: RootState) => state.accesses.importConfiguration);

    return hasAccess ? <ImportConfigurationAdminPage /> : <NoAccess />;
}

export function registerImportConfigurationAdminPage() {
    registerAdministrationEntity("ImportConfiguration", ImportConfigurationPage);
}
