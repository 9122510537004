/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/inbox/taskGroup/gridConfig" />

import * as userLocalStore from "Core/Medius.Core.Web/Scripts/Medius/core/store/user/local";

import { columnWidthStore } from "Core/Medius.Core.Web/Scripts/Medius/core/store/inbox/columnWidth";

export function get() {
    return {
        paging: false,
        search: false,
        resizable: true,
        sort: true,
        multiselect: true,
        pageSize: 20,
        defaultColumnWidth: 75,
        tplRows: "Core:Medius/apps/inbox/taskGroup/templates/Rows.html",
        tplFoot: "Core:Medius/apps/inbox/taskGroup/templates/Foot.html",
        tplFooter: "Core:Medius/apps/inbox/taskGroup/templates/Footer.html",
        tplHeader: "Core:Medius/apps/inbox/taskGroup/templates/Header.html",
        template: "Core:Medius/apps/inbox/taskGroup/templates/Grid.html",
        measureUxCategory: "Inbox refresh",
        onClickRow: function (clickedTask: any) {
            clickedTask.clicked();
        },
        showBulkCheckboxes: true,
        columnWidthStore: columnWidthStore,
        nativeScroll: true,
        selectColumns: true,
        columnVisibleStore: userLocalStore.getFor("Core:inbox-columns-visibility"),
        columnSortingStore: userLocalStore.getFor("Core:inbox-columns-sorting"),
        pageSizeStore: userLocalStore.getFor("Core:inbox-page-size")
    };
}