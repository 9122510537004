/// <amd-module name="Core/Medius.Core.Web/Scripts/components/procurement/edit/hasUnsavedChangesHelpers"/>
import { Address, CartCodingLine, CartFreeTextFormField, CartItem, CartLine } from "../cart/cart";
import { compareAddresses } from "../addressUtils";
import { isEqual } from "underscore";
import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";

const hasAddressNoChanges = (address: Address, addressSnapshot: Address) => {
    return address === addressSnapshot ||
        (address && addressSnapshot && compareAddresses(address, addressSnapshot));
};

const compareId = (x: number | null | undefined, y: number | null | undefined) => {
    if(x === y) return true;

    if(x === 0 && y === null || y === undefined) return true;
    if(y === 0 && x === null || x === undefined) return true;

    return false;
};

const compareCartCodingLine = (x: CartCodingLine, y: CartCodingLine) => {
    const xFiltered = x.dimensionValues.filter(dv => dv.id);
    const yFiltered = y.dimensionValues.filter(dv => dv.id);
    return x.amount === y.amount &&
        xFiltered.length === yFiltered.length &&
        xFiltered.every((dv, idx) => dv.id === yFiltered[idx].id);
};

const compareFreeTextForm = (x: CartItem, y: CartItem) => {
    if (isNullOrUndefined(x.freeTextFormId) && isNullOrUndefined(y.freeTextFormId)) 
        return true;

    if (isNullOrUndefined(x.freeTextFormId) || isNullOrUndefined(y.freeTextFormId)) 
        return false;

    if (x.freeTextFormId !== y.freeTextFormId) return false;
    
    const hasXFormSelected = x.freeTextFormId > 0;
    const hasYFormSelected = y.freeTextFormId > 0;

    if (!hasXFormSelected && !hasYFormSelected) return true;

    return hasXFormSelected && hasYFormSelected && 
        compareFreeTextFormFields(x.freeTextFormFields, y.freeTextFormFields);
};

const compareFreeTextFormFields = (x: CartFreeTextFormField[], y: CartFreeTextFormField[]) => {
    if (isNullOrUndefined(x) && isNullOrUndefined(y)) return true;
    if (isNullOrUndefined(x) || isNullOrUndefined(y)) return false;
    if (x.length !== y.length) return false;

    return x.every((ftf, idx) => isEqual(ftf, y.at(idx)));
};

const compareCartLine = (x: CartLine, y: CartLine) => {
    const hasFreeTextFormNoChanges = compareFreeTextForm(x.item, y.item);

    return x.item.category?.id === y.item.category?.id &&
        x.item.contract?.id === y.item.contract?.id &&
        x.item.unit?.id === y.item.unit?.id &&
        x.item.unitPrice?.currencyId === y.item.unitPrice?.currencyId &&
        x.item.unitPrice?.value === y.item.unitPrice?.value &&
        x.item.supplier?.id === y.item.supplier?.id &&
        x.item.freeTextFormId === y.item.freeTextFormId &&
        hasFreeTextFormNoChanges &&
        x.additionalInformation === y.additionalInformation &&
        x.deliveryDate === y.deliveryDate &&
        x.taxCodes.length === y.taxCodes.length &&
        x.taxCodes.every((taxCode, idx) => compareId(taxCode.id, y.taxCodes[idx].id)) &&
        x.codingLines.length === y.codingLines.length &&
        x.codingLines.every((line, idx) => compareCartCodingLine(line, y.codingLines[idx])) &&
        hasAddressNoChanges(x.deliveryAddress, y.deliveryAddress);
};

export { compareId, compareCartLine, hasAddressNoChanges };