///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/widgets/SourcesWidget"/>
import { FlexContent, LinkButton, Text, IconActionExternalRegular } from "@medius/ui-controls";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as React from "react";
import { ISourceUrl } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/agentInterfaces";


export const SourcesWidget = ({sources}: {sources: ISourceUrl[]}) => {
    return <div className="mediusAgent__widgetCard apaCopilot__learnMore" data-testid="apa-copilot-learn-more">
    <div className="mediusAgent__widgetCard__iconAndContentContainer">
        <FlexContent>
            <Text variant="heading3">{getLabelTranslation("#PurchaseToPay/learnMore")}</Text>
            {sources.map((source, index) => 
                <div key={index}>
                    <LinkButton icon={<IconActionExternalRegular/>} iconAlign="right" isTrustedUrl label={source.label} url={source.url} useNewWindow/>
                </div>
            )}
        </FlexContent>
    </div>
</div>;
};