///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Reminders/grid"/>
import * as repository from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Reminders/repository";
import * as ko from "knockout";
import * as _ from "underscore";
import * as reminderConfigurationHandler from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Reminders/reminderConfigurationHandler";
import * as remindersDataSource from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Reminders/remindersDataSource";
import { EditableGrid } from "Core/Medius.Core.Web/Scripts/Medius/components/grid/editable/grid";
import * as entityBackup from "Core/Medius.Core.Web/Scripts/Medius/lib/entityBackup/entity";
class RemindersGrid extends EditableGrid {
    reminderConfigurationHandler: any;
    IsValid: ko.Computed<boolean>;
    constructor(reminders: any, options: any, reminderTypes: any, messageTemplates: any) {
        options = {
            ...options,
            search: false,
            sort: true,
            paging: true,
            editPageSize: true,
            tplRow: "Core:templates/Administration/User/ReminderRowTpl.html",
            hideSummary: false,
            hideWhen: false
        };

        const reminderConfigurationHandlerVar: any = reminderConfigurationHandler.create(reminders, reminderTypes, options);

        const dataSource = remindersDataSource.create(reminders, reminderTypes, messageTemplates, options.hideSummary, options.hideWhen, reminderConfigurationHandlerVar);

        super(dataSource, options);
        dataSource.grid = this;
        this.reminderConfigurationHandler = reminderConfigurationHandlerVar;

        this.IsValid = ko.computed(() => {
            return !this.isAnyRowInEdit();
        });
    }

    addEmptyRow() {
        if (this.isAnyRowInEdit()) {
            return;
        }

        const reminderConfig = this.DataSource.getFirstRowReminderConfiguration() ||
            this.reminderConfigurationHandler.createReminderConfiguration();

        if (reminderConfig === null) {
            return;
        }

        this.isAnyRowInEdit(true);

        const documentType = this.DataSource.getFirstRowDocumentType();
        const messageTemplate = _(this.DataSource.availableMessageTemplates).first();

        const newReminderConfigRow = this.reminderConfigurationHandler.addReminderRow(reminderConfig, documentType, messageTemplate);
        newReminderConfigRow.isEditable = ko.observable(true);
        newReminderConfigRow.justAdded = true;

        this.openPage(1);
    }

    editRow(row: any) {
        super.editRow(row, entityBackup);
    }

    saveRow(row: any) {
        const wasJustAdded = row.justAdded === true;
        super.saveRow(row);
        this.reminderConfigurationHandler.onReminderTypeChanged(row);

        if (wasJustAdded) {
            this.openPage(1);
        }
    }

    removeRow(row: any) {
        row.disposeWrapper();
        this.reminderConfigurationHandler.removeRow(row);
        super.removeRow(row);
    }

    validateInput() {
        return this.IsValid();
    }

    destroy() {
        this.IsValid.dispose();
        repository.invalidateReminderCache();
        super.destroy();
    }
}

export function create(reminders: any, options?: any) {
    return $.when(
        repository.loadAvailableReminderTypes(),
        repository.loadAvailableMessageTemplates()
    ).then((reminderTypes: any, messageTemplates: any) => {
        return new RemindersGrid(reminders, options, reminderTypes, messageTemplates);
    });
}