/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/sox/savedSoxReports"/>
import {observableArray, computed} from "knockout";
import * as _ from "underscore";
import * as restJson from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rest";
import * as backendErrorHandler from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";

class SavedSoxReports {
    constructor(public SoxFormModel: any) {}

    public AllSoxReports = observableArray([]);
    public DefaultSoxReports = computed(() => _.filter(this.AllSoxReports(), (item: any) => item.IsDefault));

    public getSavedSoxReports(){
        return restJson.get("SoxReportManager", "savedSoxReports")
            .done((soxSavedReports) => {
                this.AllSoxReports(soxSavedReports);
            })
            .fail((xhr) => {
                backendErrorHandler.handleAnyError(xhr, null, "#Core/soxReportSidebarGettingSavedFailed");
            });

    }

    public pickSavedReport(pickedReport: any) {
        this.SoxFormModel.loadData(pickedReport);
    }
}

export function create(soxFormModel: any) {
    const reportsPanel = new SavedSoxReports(soxFormModel);
    reportsPanel.getSavedSoxReports();
    return reportsPanel;
}
