///<amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/Role/Reports/reportsConfiguration"/>
class ReportsConfiguration {
    public Entity: any;
    public Context: any;

    constructor(entityWithCtx: any) {
        this.Entity = entityWithCtx.Entity;
        this.Context = entityWithCtx.Context;
    }
}

export default (entity: any) => {
    return new ReportsConfiguration(entity);
};