/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/generator"/>

import * as metadataFactory from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/metadata";
import * as _ from "underscore";
import { getFor } from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/collection";

class MetadataGenerator {
    constructor(private overrideType: any) { }

    public getMetadata = (data: any): any => {
        const metadata = metadataFactory.create(data);
        const resolvers = getFor(data, this.overrideType);

        _(resolvers).each((r) => {

            const resolvedMetadata = r.resolve(data) || {};
            const newAttributes = resolvedMetadata.attributes;
            const newData = _.omit(resolvedMetadata, "attributes");

            _(metadata).extend(newData);
            _(metadata.attributes).extend(newAttributes);
        });

        return metadata;
    };
}

export function create(overrideType?: any) {
    return new MetadataGenerator(overrideType);
}

export function instanceOf(generator: any) {
    return generator instanceof MetadataGenerator;
}