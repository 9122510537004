///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/grid/columnSelector/propertiesLoader"/>
import * as _ from "underscore";
import { isEmptyString, isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import * as $ from "jquery";
import * as ko from "knockout";
import * as translationsProvider from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/provider/translations";
import * as propertyTypeHelper from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/helpers/propertyType";
import * as typeResolver from "Core/Medius.Core.Web/Scripts/Medius/components/resolver/type";
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";

class PropertiesLoader {
    loadProperties(typeFullName:string, selectedCollection:any, limitCollectionAccess:boolean, absolutePath?:string, entityType?:any) {
        const properties = translationsProvider.getTranslatedProperties(typeFullName, false);
        const parentCollection = typeResolver.containsCollection(entityType, absolutePath);
        const unpackedSelectedCollection = koUtils.unpack(selectedCollection);
        const isSelectedCollectionEmpty = isEmptyString(unpackedSelectedCollection);

        limitCollectionAccess = koUtils.unpack(limitCollectionAccess);
        limitCollectionAccess = isNullOrUndefined(limitCollectionAccess) ? true : limitCollectionAccess;
        
        const propertiesItems = properties.map((p:any) => {
            const path = p.property;
            const directPath = absolutePath ? absolutePath.concat(".", path) : path;
            const type = propertyTypeHelper.getPropertyTypeForHints(typeFullName, path);
            const isComplexObject = typeResolver.isComplexType(type);
            const isCollection = typeResolver.isCollection(propertyTypeHelper.getPropertyType(typeFullName, path));
            let canBeChosenNestingCollections, canBeChosenSelectedOtherCollection;
        
            if (limitCollectionAccess) {
                canBeChosenNestingCollections = !(parentCollection && isCollection);
                canBeChosenSelectedOtherCollection = isCollection ?
                    (isSelectedCollectionEmpty || directPath.indexOf(unpackedSelectedCollection) === 0) : true;
            } else {
                canBeChosenNestingCollections = true;
                canBeChosenSelectedOtherCollection = true;
            }

            return {
                Translation: p.translation,
                Property: path,
                Type: type,
                isCollection: isCollection,
                hasSubMenu: isCollection || isComplexObject,
                showProperties: ko.observable(false),
                absolutePath: directPath,
                entityType: entityType ? entityType : typeFullName,
                canBeChosenNestingCollections: canBeChosenNestingCollections,
                canBeChosenSelectedOtherCollection: canBeChosenSelectedOtherCollection,
                selectedCollection: selectedCollection,
                limitCollectionAccess: limitCollectionAccess,
                propertiesList: ko.observableArray([])
            };
        }).sort((item:any) => {
            if (isEmptyString(item.Translation)) {
                return item.Property;
            } else {
                return item.Translation;
            }
        });

        return $.Deferred().resolve(propertiesItems);
    }
    
    destroy() {
    }
}

export function create() {
    return new PropertiesLoader();
}