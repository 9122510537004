export type UploadProgress = number | "loading";
export type AttachmentAccess = "internal" | "external";
export type Attachment = Readonly<{
    name: string,
    size: number,
    link: string,
    hash: string,
    uploadedBy: string,
    uploadedAt: string,
    description: string,
    access: AttachmentAccess,
    progress?: UploadProgress,
    errorMessage?: string,
}>;

export type AttachmentUploaded = Readonly<{
    attachment: Attachment,
    temporaryHash: string,
}>;