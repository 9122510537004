///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/configurationProperties/collectionPropertyDataSource"/>
import DataSource = require("Core/Medius.Core.Web/Scripts/Medius/components/grid/dataSource/default");
import * as _ from "underscore";
import * as $ from "jquery";

export = class GridDataSource extends DataSource {
    Columns: any;
    Collection: any;
    constructor(collection: any, columns: any) {
        super();

        this.Columns = columns;
        this.Collection = collection;
    }

    load(dataQuery: { page: number; pageSize: number; }) {

        const startIndx = (dataQuery.page - 1) * dataQuery.pageSize;
        const endIndx = dataQuery.page * dataQuery.pageSize;

        const sortedCollection = _(this.Collection()).sortBy((item) => {
            return !item.Metadata.IsOverriden;
        });
        const collectionSlice = sortedCollection.slice(startIndx, endIndx);
        return $.Deferred().resolve(collectionSlice);
    }

    loadColumns() {
        return $.Deferred().resolve(this.Columns);
    }

    getTotalRows() {
        return this.Collection().length;
    }

    destroy() {
        this.Collection = null;
        DataSource.prototype.destroy.call(this);
    }
};
