/// <amd-module name="Core/Medius.Core.Web/Scripts/Models/Medius.Core.Entities.CustomFields.CustomFieldsContainer"/>
import * as ko from "knockout";
import repository = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/mapping/collection/instance");

const typeName = "Medius.Core.Entities.CustomFields.CustomFieldsContainer, Medius.Core.Common";

class CustomFieldWithConfigurationViewModel {
    public CustomField: any;
    public Configuration: any;
    public Type:string;
    public isActive: () => boolean;
    public dispose: () => void;
    
    constructor(CustomField: any, Configuration: any, Type:string) {
        this.CustomField = ko.isObservable(CustomField) ? CustomField : ko.observable(CustomField);
        this.Configuration = ko.isObservable(Configuration) ? Configuration : ko.observable(Configuration);
        this.Type = Type;

        this.isActive = () => {
            const config = ko.unwrap(this.Configuration);
            
            if (!config) {
                return false;
            }
            return ko.unwrap(config.IsActive);
        };
        
        this.dispose = () => {
            this.CustomField = null;
            this.Configuration = null;
        };
    };
}

function disposeCollection(collection:any) {
    collection.forEach(tryDispose);
}

function tryDispose(item:any) {
    if (item && item.dispose) {
        item.dispose();
    }
}

function getCustomField(container:any, index:number) {
    const customFieldContainer = ko.unwrap(container);

    if (!customFieldContainer || !customFieldContainer[index]) {
        return null;
    }

    return ko.unwrap(customFieldContainer[index].CustomField);
}

export class CustomFieldsContainer {
    public Perspective: any;
    public ActiveCustomFields: ko.ObservableArray<CustomFieldWithConfigurationViewModel>;
    public AreAnyFieldsActive: ko.Computed<boolean>;

    public NumericCustomField1WithConfiguration: CustomFieldWithConfigurationViewModel;
    public NumericCustomField2WithConfiguration: CustomFieldWithConfigurationViewModel;
    public NumericCustomField3WithConfiguration: CustomFieldWithConfigurationViewModel;
    public NumericCustomField4WithConfiguration: CustomFieldWithConfigurationViewModel;
    public NumericCustomField5WithConfiguration: CustomFieldWithConfigurationViewModel;
    public NumericCustomFieldsWithConfiguration:ko.Computed<CustomFieldWithConfigurationViewModel[]>;
    public ActiveNumericCustomFieldsWithConfiguration: () => any[];
    public AreAnyNumericFieldsActive: ko.Computed<boolean>;

    public TextCustomField1WithConfiguration: CustomFieldWithConfigurationViewModel;
    public TextCustomField2WithConfiguration: CustomFieldWithConfigurationViewModel;
    public TextCustomField3WithConfiguration: CustomFieldWithConfigurationViewModel;
    public TextCustomField4WithConfiguration: CustomFieldWithConfigurationViewModel;
    public TextCustomField5WithConfiguration: CustomFieldWithConfigurationViewModel;
    public TextCustomFieldsWithConfiguration:ko.Computed<CustomFieldWithConfigurationViewModel[]>;
    public ActiveTextCustomFieldsWithConfiguration: () => any[];
    public AreAnyTextFieldsActive: ko.Computed<boolean>;

    public BooleanCustomField1WithConfiguration: CustomFieldWithConfigurationViewModel;
    public BooleanCustomField2WithConfiguration: CustomFieldWithConfigurationViewModel;
    public BooleanCustomField3WithConfiguration: CustomFieldWithConfigurationViewModel;
    public BooleanCustomField4WithConfiguration: CustomFieldWithConfigurationViewModel;
    public BooleanCustomField5WithConfiguration: CustomFieldWithConfigurationViewModel;
    public BooleanCustomFieldsWithConfiguration:ko.Computed<CustomFieldWithConfigurationViewModel[]>;
    public ActiveBooleanCustomFieldsWithConfiguration: () => any[];
    public AreAnyBooleanFieldsActive: ko.Computed<boolean>;

    public DateTimeCustomField1WithConfiguration: CustomFieldWithConfigurationViewModel;
    public DateTimeCustomField2WithConfiguration: CustomFieldWithConfigurationViewModel;
    public DateTimeCustomField3WithConfiguration: CustomFieldWithConfigurationViewModel;
    public DateTimeCustomField4WithConfiguration: CustomFieldWithConfigurationViewModel;
    public DateTimeCustomField5WithConfiguration: CustomFieldWithConfigurationViewModel;
    public DatetimeCustomFieldsWithConfiguration:ko.Computed<CustomFieldWithConfigurationViewModel[]>;
    public ActiveDatetimeCustomFieldsWithConfiguration: () => any[];
    public AreAnyDatetimeFieldsActive: ko.Computed<boolean>;

    public ActiveCustomFieldsSync: ko.Computed<void>;
    public perspectiveSub: any
    public dispose: () => void;
    public toJS: () => any;


    constructor(data:any) {
        this.Perspective = ko.isObservable(data.Perspective) ? data.Perspective : ko.observable(data.Perspective);
        if (this.Perspective()) {
            this.Perspective().Configuration = ko.isObservable(this.Perspective().Configuration)
            ? this.Perspective().Configuration
            : ko.observable(this.Perspective().Configuration);
        }

        this.ActiveCustomFields = ko.observableArray([]);

        this.AreAnyFieldsActive = ko.pureComputed(() => !this.Perspective() ? false : this.Perspective().AreAnyFieldsActive);

        this.NumericCustomFieldsWithConfiguration = ko.computed(() => {
            const configuration = !!this.Perspective() ? this.Perspective().Configuration() : null;

            this.NumericCustomField1WithConfiguration = new CustomFieldWithConfigurationViewModel(data.NumericCustomField1, !!configuration ? configuration.NumericCustomField1 : null, 'n');
            this.NumericCustomField2WithConfiguration = new CustomFieldWithConfigurationViewModel(data.NumericCustomField2, !!configuration ? configuration.NumericCustomField2 : null, 'n');
            this.NumericCustomField3WithConfiguration = new CustomFieldWithConfigurationViewModel(data.NumericCustomField3, !!configuration ? configuration.NumericCustomField3 : null, 'n');
            this.NumericCustomField4WithConfiguration = new CustomFieldWithConfigurationViewModel(data.NumericCustomField4, !!configuration ? configuration.NumericCustomField4 : null, 'n');
            this.NumericCustomField5WithConfiguration = new CustomFieldWithConfigurationViewModel(data.NumericCustomField5, !!configuration ? configuration.NumericCustomField5 : null, 'n');

            return [
                this.NumericCustomField1WithConfiguration,
                this.NumericCustomField2WithConfiguration,
                this.NumericCustomField3WithConfiguration,
                this.NumericCustomField4WithConfiguration,
                this.NumericCustomField5WithConfiguration
            ];
        });

        this.ActiveNumericCustomFieldsWithConfiguration = () => {
            return ko.utils.arrayFilter(this.NumericCustomFieldsWithConfiguration(), function(item:any) {
                return item.isActive();
            });
        };
    
        this.AreAnyNumericFieldsActive = ko.pureComputed(() => {
            return this.ActiveNumericCustomFieldsWithConfiguration().length > 0;
        });


        this.TextCustomFieldsWithConfiguration = ko.computed(() => {
            const configuration = !!this.Perspective() ? this.Perspective().Configuration() : null;

            this.TextCustomField1WithConfiguration = new CustomFieldWithConfigurationViewModel(data.TextCustomField1, !!configuration ? configuration.TextCustomField1 : null, 't');
            this.TextCustomField2WithConfiguration = new CustomFieldWithConfigurationViewModel(data.TextCustomField2, !!configuration ? configuration.TextCustomField2 : null, 't');
            this.TextCustomField3WithConfiguration = new CustomFieldWithConfigurationViewModel(data.TextCustomField3, !!configuration ? configuration.TextCustomField3 : null, 't');
            this.TextCustomField4WithConfiguration = new CustomFieldWithConfigurationViewModel(data.TextCustomField4, !!configuration ? configuration.TextCustomField4 : null, 't');
            this.TextCustomField5WithConfiguration = new CustomFieldWithConfigurationViewModel(data.TextCustomField5, !!configuration ? configuration.TextCustomField5 : null, 't');

            return [
                this.TextCustomField1WithConfiguration,
                this.TextCustomField2WithConfiguration,
                this.TextCustomField3WithConfiguration,
                this.TextCustomField4WithConfiguration,
                this.TextCustomField5WithConfiguration
            ];
        });

        this.ActiveTextCustomFieldsWithConfiguration = () => {
            return ko.utils.arrayFilter(this.TextCustomFieldsWithConfiguration(), function(item:any) {
                return item.isActive();
            });
        };

        this.AreAnyTextFieldsActive = ko.pureComputed(() => {
            return this.ActiveTextCustomFieldsWithConfiguration().length > 0;
        });

        this.BooleanCustomFieldsWithConfiguration = ko.computed(() => {
            const configuration = !!this.Perspective() ? this.Perspective().Configuration() : null;

            this.BooleanCustomField1WithConfiguration = new CustomFieldWithConfigurationViewModel(data.BooleanCustomField1, !!configuration ? configuration.BooleanCustomField1 : null, 'b');
            this.BooleanCustomField2WithConfiguration = new CustomFieldWithConfigurationViewModel(data.BooleanCustomField2, !!configuration ? configuration.BooleanCustomField2 : null, 'b');
            this.BooleanCustomField3WithConfiguration = new CustomFieldWithConfigurationViewModel(data.BooleanCustomField3, !!configuration ? configuration.BooleanCustomField3 : null, 'b');
            this.BooleanCustomField4WithConfiguration = new CustomFieldWithConfigurationViewModel(data.BooleanCustomField4, !!configuration ? configuration.BooleanCustomField4 : null, 'b');
            this.BooleanCustomField5WithConfiguration = new CustomFieldWithConfigurationViewModel(data.BooleanCustomField5, !!configuration ? configuration.BooleanCustomField5 : null, 'b');

            return [
                this.BooleanCustomField1WithConfiguration,
                this.BooleanCustomField2WithConfiguration,
                this.BooleanCustomField3WithConfiguration,
                this.BooleanCustomField4WithConfiguration,
                this.BooleanCustomField5WithConfiguration
            ];
        });

        this.ActiveBooleanCustomFieldsWithConfiguration = () => {
            return ko.utils.arrayFilter(this.BooleanCustomFieldsWithConfiguration(), function(item:any) {
                return item.isActive();
            });
        };

        this.AreAnyBooleanFieldsActive = ko.pureComputed(() => {
            return this.ActiveBooleanCustomFieldsWithConfiguration().length > 0;
        });

        this.DatetimeCustomFieldsWithConfiguration = ko.computed(() => {
            const configuration = !!this.Perspective() ? this.Perspective().Configuration() : null;

            this.DateTimeCustomField1WithConfiguration = new CustomFieldWithConfigurationViewModel(data.DateTimeCustomField1, !!configuration ? configuration.DateTimeCustomField1 : null, 'd');
            this.DateTimeCustomField2WithConfiguration = new CustomFieldWithConfigurationViewModel(data.DateTimeCustomField2, !!configuration ? configuration.DateTimeCustomField2 : null, 'd');
            this.DateTimeCustomField3WithConfiguration = new CustomFieldWithConfigurationViewModel(data.DateTimeCustomField3, !!configuration ? configuration.DateTimeCustomField3 : null, 'd');
            this.DateTimeCustomField4WithConfiguration = new CustomFieldWithConfigurationViewModel(data.DateTimeCustomField4, !!configuration ? configuration.DateTimeCustomField4 : null, 'd');
            this.DateTimeCustomField5WithConfiguration = new CustomFieldWithConfigurationViewModel(data.DateTimeCustomField5, !!configuration ? configuration.DateTimeCustomField5 : null, 'd');

            return [
                this.DateTimeCustomField1WithConfiguration,
                this.DateTimeCustomField2WithConfiguration,
                this.DateTimeCustomField3WithConfiguration,
                this.DateTimeCustomField4WithConfiguration,
                this.DateTimeCustomField5WithConfiguration
            ];
        });

        this.ActiveDatetimeCustomFieldsWithConfiguration = () => {
            return ko.utils.arrayFilter(this.DatetimeCustomFieldsWithConfiguration(), function(item:any) {
                return item.isActive();
            });
        };

        this.AreAnyDatetimeFieldsActive = ko.pureComputed(() => {
            return this.ActiveDatetimeCustomFieldsWithConfiguration().length > 0;
        });

        this.ActiveCustomFieldsSync = ko.computed(() => {
            this.ActiveCustomFields.removeAll();

            if (!this.Perspective() || !this.TextCustomFieldsWithConfiguration() || !this.NumericCustomFieldsWithConfiguration() ||
                !this.BooleanCustomFieldsWithConfiguration() || !this.DatetimeCustomFieldsWithConfiguration()) {
                return;
            }

            ko.utils.arrayPushAll(this.ActiveCustomFields(), this.ActiveNumericCustomFieldsWithConfiguration());
            ko.utils.arrayPushAll(this.ActiveCustomFields(), this.ActiveTextCustomFieldsWithConfiguration());
            ko.utils.arrayPushAll(this.ActiveCustomFields(), this.ActiveBooleanCustomFieldsWithConfiguration());
            ko.utils.arrayPushAll(this.ActiveCustomFields(), this.ActiveDatetimeCustomFieldsWithConfiguration());
            this.ActiveCustomFields.valueHasMutated();
        });

        this.perspectiveSub = this.Perspective.subscribe(() => {
            this.ActiveCustomFieldsSync();
        });

        this.dispose = () => {
            this.perspectiveSub.dispose();
            this.perspectiveSub = null;
            this.ActiveCustomFieldsSync.dispose();
            this.ActiveCustomFieldsSync = null;

            this.AreAnyDatetimeFieldsActive.dispose();
            this.DatetimeCustomFieldsWithConfiguration.dispose();
            this.DatetimeCustomFieldsWithConfiguration = null;

            this.AreAnyBooleanFieldsActive.dispose();
            this.BooleanCustomFieldsWithConfiguration.dispose();
            this.BooleanCustomFieldsWithConfiguration = null;

            this.AreAnyTextFieldsActive.dispose();
            this.TextCustomFieldsWithConfiguration.dispose();
            this.TextCustomFieldsWithConfiguration = null;

            this.NumericCustomFieldsWithConfiguration.dispose();
            this.NumericCustomFieldsWithConfiguration = null;

            disposeCollection(this.ActiveCustomFields.peek());
            this.AreAnyFieldsActive.dispose();
            this.ActiveCustomFields = null;
        }

        this.toJS = () => {
            return {
                $type: typeName,
                TextCustomField1: getCustomField(this.TextCustomFieldsWithConfiguration, 0),
                TextCustomField2: getCustomField(this.TextCustomFieldsWithConfiguration, 1),
                TextCustomField3: getCustomField(this.TextCustomFieldsWithConfiguration, 2),
                TextCustomField4: getCustomField(this.TextCustomFieldsWithConfiguration, 3),
                TextCustomField5: getCustomField(this.TextCustomFieldsWithConfiguration, 4),
                NumericCustomField1: getCustomField(this.NumericCustomFieldsWithConfiguration, 0),
                NumericCustomField2: getCustomField(this.NumericCustomFieldsWithConfiguration, 1),
                NumericCustomField3: getCustomField(this.NumericCustomFieldsWithConfiguration, 2),
                NumericCustomField4: getCustomField(this.NumericCustomFieldsWithConfiguration, 3),
                NumericCustomField5: getCustomField(this.NumericCustomFieldsWithConfiguration, 4),
                BooleanCustomField1: getCustomField(this.BooleanCustomFieldsWithConfiguration, 0),
                BooleanCustomField2: getCustomField(this.BooleanCustomFieldsWithConfiguration, 1),
                BooleanCustomField3: getCustomField(this.BooleanCustomFieldsWithConfiguration, 2),
                BooleanCustomField4: getCustomField(this.BooleanCustomFieldsWithConfiguration, 3),
                BooleanCustomField5: getCustomField(this.BooleanCustomFieldsWithConfiguration, 4),
                DateTimeCustomField1: getCustomField(this.DatetimeCustomFieldsWithConfiguration, 0),
                DateTimeCustomField2: getCustomField(this.DatetimeCustomFieldsWithConfiguration, 1),
                DateTimeCustomField3: getCustomField(this.DatetimeCustomFieldsWithConfiguration, 2),
                DateTimeCustomField4: getCustomField(this.DatetimeCustomFieldsWithConfiguration, 3),
                DateTimeCustomField5: getCustomField(this.DatetimeCustomFieldsWithConfiguration, 4)
            };
        }
    }
}

export function registerMapping() {
    repository.register(typeName, function() {return true;}, {
        make: function (data:any, context:any) {
            return new CustomFieldsContainer(data)
        },
        toJS: function(viewModel:any) {
            if(viewModel instanceof CustomFieldsContainer) return viewModel.toJS();
        }
    });
};