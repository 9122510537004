///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/context/cache"/>
import { mapFactory } from "Core/Medius.Core.Web/Scripts/Medius/lib/map";
const CONTEXT_CACHE_KEY = "viewmodel-context-cache";

export = function cacheDecorator(context:any) {
    let cache = context.ensure(CONTEXT_CACHE_KEY, mapFactory());

    context.disposal.register(function() {
        cache.dispose(true);
        cache = null;
        context.cache = null;
    });

    context.cache = cache;
};