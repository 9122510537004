///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/context/decorators"/>
import disposal = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/context/disposal");
import finalize = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/context/finalize");
import cache = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/context/cache");
import queue = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/context/queue");
import events = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/context/events");
import oldData = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/context/oldData");
import mode = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/context/mode");

const decorators = [disposal, finalize, cache, queue, events, oldData, mode];

export function getDecorators() {
    return decorators;
}