/// <amd-module name="Core/Medius.Core.Web/Scripts/MasterData/masterDataService"/>
const rpc = require("Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rpc");

export function getAddresses(companyId: number, addressTypes: string[]): JQueryPromise<any> {
    return rpc.request("MasterDataService", "GetAddresses", {
        addressTypes,
        companyId
    });
}

export interface CompanyConfigurationDto {
    UseTaxCodes: boolean;
    UseTwoTaxIndicators: boolean;
    TaxIndicator1Label: string;
    TaxIndicator2Label: string;
}

export function getCompanyConfigurationDto(companyId: number): JQueryPromise<CompanyConfigurationDto> {
    return rpc.request("MasterDataService", "GetCompanyConfigurationDto", {
        companyId
    });
}
