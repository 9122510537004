/// <amd-module name="Core/Medius.Core.Web/Scripts/customTabs/definitions/contracts/sendWrapper"/>
import * as EventNames from "Core/Medius.Core.Web/Scripts/events/eventNames";
import { processingState } from "Core/Medius.Core.Web/Scripts/Models/userTask";
import * as eventBus from "Core/Medius.Core.Web/Scripts/eventBus";
import { getOutboxInstance } from "Core/Medius.Core.Web/Scripts/components/outbox/outbox";
import { startHandleAsyncMeasurement, startHandleMeasurement } from "Core/Medius.Core.Web/Scripts/Medius/performance/loggers/task";
import { executeAfterBlockingOperationsEnd } from "Core/Medius.Core.Web/Scripts/lib/workflowBlockingOperation";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import store = require("Core/Medius.Core.Web/Scripts/Medius/core/store/handling");

export interface IParams {
    task: {
        taskId: number,
        workflowStep: string,
        documentType: string
    };
    stepContext: any;
    documentId: number;
    CommentsModel: any;
}

export const send = (params: IParams, handleTaskAction: () => Promise<any>) => {
    const { CommentsModel, documentId, stepContext, task } = params;

    executeAfterBlockingOperationsEnd(() => {

        if (CommentsModel.newComment()) {
            CommentsModel.addComment();
        }

        processingState(true);

        const measurement = startHandleMeasurement(task);
        const asyncMeasurement = startHandleAsyncMeasurement(task);
        const outboxItem = getOutboxInstance().enqueue(task.taskId, documentId);

        stepContext.disposeContext = () => { };

        Promise.all([store.setItem(task.taskId.toString(), stepContext), handleTaskAction()])
            .then((results: any) => {
                const response = results[1];
                if (response.ok) {
                    store.removeItem(task.taskId.toString());
                    stepContext.disposeContext();
                    outboxItem.done();
                    return;
                }

                return response.status === 400 ? response.text() : `{"message": "${translate('#Core/errorOccurredUnknown')}"}`;
            })
            .then((data: string) => {
                return data ? JSON.parse(data).message : "";
            })
            .then((message: string) => {
                if (!message) return;

                const validationMessages = message.split(/\r?\n/);
                if (validationMessages && validationMessages.length > 0)
                    outboxItem.fail(validationMessages);
            })
            .catch((err: any) => {
                console.log(err);
            })
            .finally(() => {
                measurement.stop();
                processingState(false);
            });

        eventBus.trigger(EventNames.taskHandled, task.taskId);
        asyncMeasurement.stop();
    });
};