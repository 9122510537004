/// <amd-module name="Core/Medius.Core.Web/Scripts/lib/reactIntegration/kendoIntl"/>
import { load as kendoIntlLoad } from "@progress/kendo-react-intl";

const currentCulture = {
    name: ""
};

export function load(numbers: any, currencies: any, calendar: any, dateFields: any, timeZoneNames: any, weekData: any) {
    /* 
    culture name is set like that due to structure of localization files
    we get culture from there to treat this as single source of truth

    example of object structure

    {
        "main": {
            "en" : {
                ...localizationData
            }
        }
    }
    */
    currentCulture.name = Object.keys(numbers.main)[0];
    kendoIntlLoad(numbers, currencies, calendar, dateFields, timeZoneNames, weekData);
}

export function getCurrentCulture() {
    return currentCulture.name;
}
