///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/task/RiskFactor/RiskDescriptions/FromEmailAddressChanged"/>
import * as React from 'react';
import { getLabelTranslation } from 'Core/Medius.Core.Web/Scripts/lib/globalization';
import { AdditionalProperties, IHandledInfo, RiskFactorCode, RiskFactorReasonCode } from 'Core/Medius.Core.Web/Scripts/Medius/apps/task/RiskFactor/RiskFactorComponentDtos/riskFactorsForInvoiceDto';
import { DatePresenter } from 'Core/Medius.Core.Web/Scripts/shared/components/datePresenter';
import { getRiskFactorNameTranslation } from 'Core/Medius.Core.Web/Scripts/Medius/apps/task/RiskFactor/riskFactorUtils';

export interface IEmailAddressChangedProps {
    isRiskHandled: boolean;
    additionalProperties: AdditionalProperties;
    handledInfo: IHandledInfo;
    currentReasonCode: RiskFactorReasonCode;
}

export const FromEmailAddressChanged = ({ 
    isRiskHandled, 
    additionalProperties,
    handledInfo,
    currentReasonCode
}: IEmailAddressChangedProps) => (
    <>
        <h4>{getRiskFactorNameTranslation(RiskFactorCode.FromEmailAddressChanged)}</h4>
        { 
            isRiskHandled ? (
                <>
                    <div className="risk-factor-icon-container__handlerDetails">
                        <p style={{margin: "0px"}}>{getLabelTranslation('#Core/handledBy')}</p>
                        <p>{handledInfo.handledByUser.firstName}</p>
                        <p style={{marginRight: "5px"}}>{handledInfo.handledByUser.lastName}</p>
                        <DatePresenter 
                            date={new Date(handledInfo.handledAt)} 
                            format="G" 
                        />
                    </div>
                    <p>{resolveReasonCodeTranslation(currentReasonCode)}</p>
                </>
            ) : (
                <>
                    <p>{getLabelTranslation("#Core/riskFactorEmailAddressUnhandledDescription")}</p>
                    <a href={`mailto:${additionalProperties.newEmailAddress}`}>
                        {additionalProperties.newEmailAddress}
                    </a>
                </>
            )
        }
    </>  
);

function resolveReasonCodeTranslation(handlingReasonCode: RiskFactorReasonCode){
    switch (handlingReasonCode) {    
        case RiskFactorReasonCode.SupplierChanged:
            return getLabelTranslation("#Core/handlingReasonCodeSupplierChanged");
        case RiskFactorReasonCode.NewEmailAddressAdded:
            return getLabelTranslation("#Core/handlingReasonCodeNewEmailAddressAdded");
        case RiskFactorReasonCode.InvoiceArchived:
            return getLabelTranslation("#Core/handlingReasonCodeInvoiceArchived");
        case RiskFactorReasonCode.InvoiceDeletedDuplicateInvoice:
            return getLabelTranslation("#Core/handlingCodeInvoiceDeletedDuplicateInvoice");
        case RiskFactorReasonCode.InvoiceDeletedFraudAttempt:
            return getLabelTranslation("#Core/handlingCodeInvoiceDeletedFraudAttempt");
        case RiskFactorReasonCode.InvoiceDeletedOther:
            return getLabelTranslation("#Core/handlingCodeInvoiceDeletedOther");
        default:
            console.warn(`[FromEmailAddressChanged] handling reason code ${handlingReasonCode} was not implemented for this risk factor`);
            break;
    }
}