///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Entities.User/Reminders/externalRemindersDataSource"/>
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import  DataSource = require("Core/Medius.Core.Web/Scripts/Medius/components/grid/dataSource/default");
import * as notification from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import * as reminderConfigurationRowWrapper from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Reminders/reminderConfigurationRowWrapper";
import * as repository from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Reminders/repository";
import * as _ from "underscore";

export class ExternalRemindersDataSource extends DataSource {
    availableReminders: any;
    availableRemindersNamesArray: any;
    availableMessageTemplates: any;
    currentReminders: any;
    inheritedReminders: any;
    isInherited: boolean;
    reminders: any;
    rows: any[];
    grid: any;
    constructor(currentReminders:any, inheritedReminders:any, isInherited:boolean, availableReminders:any, availableMessageTemplates:any, private hideSummary: boolean, private hideWhen: boolean, private reminderConfigurationHandler: any) {
        super();

        this.availableReminders = availableReminders;
        this.availableRemindersNamesArray = availableReminders.getAvailableRemindersNamesArray();
        this.availableMessageTemplates = availableMessageTemplates;

        this.currentReminders = currentReminders;
        this.inheritedReminders = inheritedReminders;
        this.isInherited = isInherited;

        if (this.isInherited) {
            this.reminders = this.inheritedReminders;
        } else {
            this.reminders = this.currentReminders;
        }

        this.rows = null;
    }

    setInherited(isInherited:boolean) {
        this.isInherited = isInherited;
        if (this.isInherited) {
            this.reminders = this.inheritedReminders;
        } else {
            this.reminders = this.currentReminders;
        }
    }
    loadColumns() {
        const columns = [
            {
                Sortable: true,
                Searchable: false,
                Resizable: true,
                Stretch: true,
                Name: getLabelTranslation("#Core/typeOfReminderLabel"),
                ValueSource: "ReminderTypeName",
                Width: 100
            },
            {
                Sortable: true,
                Searchable: false,
                Resizable: true,
                Stretch: true,
                Name: getLabelTranslation("#Core/documentTypeHeader"),
                ValueSource: "DocumentTypeName",
                Width: 100
            },
            {
                Sortable: false,
                Searchable: false,
                Resizable: false,
                Stretch: false,
                Visible: !this.hideSummary,
                Name: getLabelTranslation("#Core/sendInSummaryHeader"),
                ValueSource: "SendSummary",
                Width: 50
            },
            {
                Sortable: false,
                Searchable: false,
                Resizable: false,
                Stretch: false,
                Name: getLabelTranslation("#Core/enabledHeader"),
                ValueSource: "Enabled",
                Width: 50
            },
            {
                Sortable: false,
                Searchable: false,
                Resizable: false,
                Stretch: false,
                Name: getLabelTranslation("#Core/reminderJob"),
                Visible: !this.hideWhen,
                ValueSource: "",
                Key: "When",
                Width: 90
            },
            {
                Sortable: false,
                Searchable: false,
                Resizable: true,
                Stretch: true,
                Name: getLabelTranslation("#Core/detailsHeader"),
                ValueSource: "Details",
                Width: 70
            },
            {
                Sortable: false,
                Searchable: false,
                Resizable: true,
                Stretch: true,
                Name: getLabelTranslation("#Core/messageTemplateHeader"),
                ValueSource: "Template",
                Width: 100
            },
            {
                Sortable: false,
                Searchable: false,
                Resizable: false,
                Stretch: false,
                Width: 90,
                Name: "",
                ValueSource: ""
            }
        ];

        return $.Deferred().resolve(columns);
    }

    load(dataQuery:any) {
        let rows = _.chain(this.reminders())
             .map((reminderConfiguration) => {
                const rowsForCompatibleDocuments = this.getValidRows(reminderConfiguration);

                return _(rowsForCompatibleDocuments).map((reminderConfigurationRow) => {
                    return reminderConfigurationRowWrapper.create(this, reminderConfiguration, reminderConfigurationRow);
                });
             })
            .flatten()
            .value();

        rows = super.sort(dataQuery, rows);
        this.rows = rows;
        rows = super.page(dataQuery, rows);

        return $.Deferred().resolve(rows);
    }

    getValidRows(reminderConfiguration:any) {
        const availableDocuments = this.availableReminders.getCompatibleDocumentTypes(reminderConfiguration.ReminderType());
        return _(reminderConfiguration.Rows()).filter((row) => {
                    return row.justAdded === true || _(availableDocuments).any((doc) =>{
                      return row.EntityFullTypeName() === doc.Value;  
                    });
                });
    }

    getTotalRows() {
        const total = _.chain(this.reminders())
            .map((reminderConfiguration) => {
                return this.getValidRows(reminderConfiguration).length;
            }).reduce((sum, value) => {
                return sum + value;
            }, 0).value();

        return $.Deferred<number>().resolve(total);
    }

    getFirstRowReminderConfiguration() {
        const firstRow = _(this.rows).first() || _(this.reminders()).first();

        if (!firstRow) {
            return null;
        }

        const firstRowReminderType = firstRow.ReminderType();

        return _(this.reminders()).find((reminderConfiguration) => {
            return reminderConfiguration.ReminderType() === firstRowReminderType;
        });
    }

    getFirstRowDocumentType() {
        const firstRow = _(this.rows).first();

        if (!firstRow) {
            return null;
        }

        return firstRow.EntityFullTypeName();
    }

    validateRow(row:any) {
        if (!this.reminderConfigurationHandler.isUnique(row)) {
            notification.error(`${getLabelTranslation("#Core/reminderAlreadyExists")} (${row.ReminderTypeName()}, ${row.DocumentTypeName()}).`);
            return false;
        }

        return true;
    }

    destroy() {
        _(this.rows).each((row) => {
            if (row.disposeWrapper) {
                row.disposeWrapper();
            }
        });

        this.availableReminders.dispose();
        repository.invalidateExternalReminderCache();

        this.rows = null;
        this.availableMessageTemplates = null;
        this.availableReminders = null;
        this.reminders = null;
        this.currentReminders = null;
        this.inheritedReminders = null;
        super.destroy();
    }
}
