/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/EdiDocumentMapper/general"/>
import { IEntityWithContext } from "Core/Medius.Core.Web/Scripts/AdminPages/IEntityWithContext";


class EdiDocumentMapper {
    public Entity: any;

    constructor(entity: any) {
        this.Entity = entity;
    }

}

export default function(entityWithCtx: IEntityWithContext) {
    return new EdiDocumentMapper(entityWithCtx.Entity);
}
