/**
 * Represents the props of the *ActionButtons component*.
 * You cand find more info [here](https://mfxistorybook.z5.web.core.windows.net/?path=/docs/components-administration-action-buttons).
 */
export interface ActionButtonsProps {
    /**
     * Action to execute when save button is clicked.
     */
    onSave: () => void;

    /**
     * Defines save button label.
     */
    saveButtonLabel: string;

    /**
     * Defines whether save button will be shown to the user. Defaults to false.
     */
    saveVisible?: boolean;

    /**
     * Defines whether save button will be disabled. Defaults to false.
     */
    saveDisabled?: boolean;

    /**
     * Defines save button HTML title attribute. Defaults to undefined.
     */
    saveTitle?: string;

    /**
     * Action to execute when delete button is clicked.
     */
    onDelete: () => void;

    /**
     * Defines delete button label.
     */
    deleteButtonLabel: string;

    /**
     * Defines whether delete button will be shown to the user. Defaults to false.
     */
    deleteVisible?: boolean;

    /**
     * Defines whether save button will be disabled. Defaults to false.
     */
    deleteDisabled?: boolean;

    /**
     * Defines delete button HTML title attribute. Defaults to undefined.
     */
    deleteTitle?: string;

    /**
     * Action to execute when clone button is clicked.
     */
    onClone?: () => void;

    /**
     * Defines delete button label. Defaults to empty string.
     */
    cloneButtonLabel?: string;

    /**
     * Defines whether clone button will be shown to the user. Defaults to false.
     */
    cloneVisible?: boolean;

    /**
     * Defines whether clone button will be disabled. Defaults to false.
     */
    cloneDisabled?: boolean;

    /**
     * Defines clone button HTML title attribute. Defaults to undefined.
     */
    cloneTitle?: string;
}