/// <amd-module name="Core/Medius.Core.Web/Scripts/components/customDocumentModel/customDocumentModelProvider"/>

type Condition = (task: any) => boolean;
type CustomDocumentModelProvider = (taskDto: any, queue?: any, isFullScreenEnabled?: ko.Observable<boolean>) => any;
const modelProviders: { condition: Condition; provider: CustomDocumentModelProvider }[] = [];
export function register(condition: Condition, provider: CustomDocumentModelProvider) {
    modelProviders.push({ condition, provider });
}
export function get(task: any): CustomDocumentModelProvider {
    const matchingProviders = modelProviders.filter(x => x.condition(task));
    if (matchingProviders.length === 0) {
        return null;
    }
    if (matchingProviders.length > 1) {
        throw new Error("multiple matching providers");
    }
    return matchingProviders[0].provider;
}
