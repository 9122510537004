///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/editors/number/model"/>
import * as _ from "underscore";
import * as ko from "knockout";
import * as basic from "Core/Medius.Core.Web/Scripts/Medius/components/editors/basic";
import * as localizer from "Core/Medius.Core.Web/Scripts/Medius/components/editors/number/localizer";
import * as helpers from "Core/Medius.Core.Web/Scripts/Medius/components/editors/helpers";
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import { isNotNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";

class NumberField {
    MaxValue: any;
    MinValue: any;
    Resolution: any;
    IsInteger: any;
    UseSpin: any;
    Step: any;
    TrimZeros: any;
    RoundDecimal: any;
    NotLocalized: any;
    params: any;
    dispose: () => void;
    constructor(bindingParameters:any, viewmodel:any, context:any, params:any = {}) {
        const customLocalizer = params.localizer;
        let numberLocalizer:any;
        const selectedTemplate = "editors-number-text";
        const defaults:any = {
            template: selectedTemplate,
            align: 'left',
            maxValue: null,
            minValue: null,
            resolution: 2,
            isInteger: true,
            trimZeros: false,
            roundDecimal: 'midpoint', //round, floor, ceil
            useSpin: false,
            step: 1,
            notLocalized: false,
            options: {
                defaultName: "editor-number",
                pattern: null, //numbers and periods
                css: null
            }
        };

        helpers.mergeDefaults(bindingParameters, defaults);
        const validator = (number:number) => {
            if (_.isUndefined(number)) {
                return false;
            }
            if (_.isNull(number)) {
                return true;
            }
            else {
                return helpers.isValueInRange(
                    number,
                    koUtils.unpack(defaults.minValue),
                    koUtils.unpack(defaults.maxValue)
                );
            }
        };

        if (defaults.resolution === 0) {
            defaults.resolution = defaults.isInteger ? 0 : 2;
        }

        if (isNotNullOrUndefined(customLocalizer)) {
            numberLocalizer = customLocalizer;
        }
        else {
            numberLocalizer = ko.computed(function () {
                const resolution = koUtils.unpack(defaults.resolution);
                return localizer.create(
                    resolution,
                    defaults.trimZeros,
                    defaults.roundDecimal,
                    defaults.notLocalized
                );
            });
        }
        _(this).extend(basic.create(defaults, context, {
            localizer: numberLocalizer,
            validator: validator
        }));

        this.MaxValue = this.params.maxValue;
        this.MinValue = this.params.minValue;
        this.Resolution = this.params.resolution;
        this.IsInteger = this.params.isInteger;
        this.UseSpin = this.params.useSpin;
        this.Step = this.params.step;
        this.TrimZeros = this.params.trimZeros;
        this.RoundDecimal = this.params.roundDecimal;
        this.NotLocalized = this.params.notLozalized;

        this.dispose = function () {
            numberLocalizer.dispose();
            numberLocalizer = null;
            basic.derive.prototype.dispose.call(this);
        };
    } 
}

export function create(bindingParameters:any, viewmodel:any, context:any, params:any) {
    return new NumberField(bindingParameters, viewmodel, context, params);
}