/// <amd-module name="Core/Medius.Core.Web/Scripts/components/links/linkViewModel"/>
import { EntityLinkMetadataResolver } from 'Core/Medius.Core.Web/Scripts/components/links/linkMetadataProvider';
import { sortBy } from "underscore";

type LinkType = LinkTypeEnum | null;
enum LinkTypeEnum
{
    Duplicate = 'Duplicate',
    Reclassify = 'Reclassify',
    ReferencedDocument = 'ReferencedDocument'
}

class LinkViewModelBase {
    public ViewId: string;
    public $type: string;
    public Label: string;
    public HeaderName: string;
    public IsDisabled: boolean;
    public EntityId: number;
    public AllowDelete: boolean;
    public IsCarriedOver: boolean;
    public Description: string;
    public LinkType: LinkType;
    public IsSystemGenerated: boolean;
    public RawId: number;
    private entityLinkMetadataResolver: EntityLinkMetadataResolver;

    public get Id() {
        return this.EntityId;
    }

    get linkMetadata() {
        return this.entityLinkMetadataResolver.getMetadata(this);
    }

    constructor(dto: BaseLinkDto) {
        this.ViewId = dto.entityViewId;
        this.$type = dto.entityType;
        this.HeaderName = dto.typeName;
        this.IsDisabled = dto.isDisabled;
        this.EntityId = dto.entityId;
        this.RawId = dto.id;
        this.AllowDelete = dto.allowDelete;
        this.IsCarriedOver = dto.isCarriedOver;
        this.LinkType = dto.linkType;
        this.IsSystemGenerated = dto.isSystemGenerated;
        this.entityLinkMetadataResolver = new EntityLinkMetadataResolver();
    }
}

class InvoiceLinkViewModel extends LinkViewModelBase {
    public Company: string;
    public Supplier: string;
    public ReferencedDocuments: string[];
    public InvoiceDate: Date;
    public PreliminaryVoucherNumber: string | null;
    public InvoiceNumber: string;
    public FinalVoucherNumber: string | null;
    public FinalPostingDate: Date | null;
    public PreliminaryPostingDate: Date | null;

    get referencedDocumentsView(): string {
        if (!this.ReferencedDocuments || this.ReferencedDocuments.length === 0) {
            return "";
        }

        return this.ReferencedDocuments.join(", ");
    }

    constructor(dto: InvoiceLinkDto) {
        super(dto);
        this.Company = dto.company;
        this.Supplier = dto.supplier;
        this.ReferencedDocuments = dto.referencedDocuments;
        this.InvoiceDate = dto.invoiceDate;
        this.PreliminaryVoucherNumber = dto.preliminaryVoucherNumber; 
        this.InvoiceNumber = dto.invoiceNumber;
        this.FinalVoucherNumber = dto.finalVoucherNumber;
        this.FinalPostingDate = dto.finalPostingDate;
        this.PreliminaryPostingDate = dto.preliminaryPostingDate;
    }
}

class EntityLinkViewModel extends LinkViewModelBase {
    public Label: string;

    constructor(dto: EntityLinkDto) {
        super(dto);
        this.Label = dto.label;
    }
}

interface BaseLinkDto {
    entityType: string;
    typeName: string;
    entityViewId: string;
    entityId: number;
    allowDelete: boolean;
    isCarriedOver: boolean;
    isSystemGenerated: boolean;
    linkType: LinkType;
    isDisabled: boolean;
    id: number;
}

interface InvoiceLinkDto extends BaseLinkDto {
    company: string;
    supplier: string;
    referencedDocuments: string[];
    invoiceDate: Date;
    preliminaryVoucherNumber: string | null;
    invoiceNumber: string;
    finalVoucherNumber: string | null;
    finalPostingDate: Date | null;
    preliminaryPostingDate: Date | null;
}

interface EntityLinkDto extends BaseLinkDto {
    label: string;
}

interface LinksWithCategoriesDto
{
    duplicated: InvoiceLinkDto[];
    referencedDocuments: InvoiceLinkDto[];
    custom: EntityLinkDto[];
    reclasiffied: EntityLinkDto[];
}

class LinksWithCategoriesViewModel
{
    private Duplicated: InvoiceLinkViewModel[];
    private ReferencedDocuments: InvoiceLinkViewModel[];
    private Custom: EntityLinkViewModel[];
    private Reclasiffied: EntityLinkViewModel[];

    get linksCnt(): number {
        return this.Duplicated.length
            + this.Custom.length
            + this.Reclasiffied.length
            + this.ReferencedDocuments.length;
    }

    get isThereAnyActiveLink(): boolean {
        return (this.Custom && this.Custom.filter(x => !x.IsCarriedOver).length > 0)
            || (this.Duplicated && this.Duplicated.filter(x => !x.IsCarriedOver).length > 0)
            || (this.Reclasiffied && this.Reclasiffied.filter(x => !x.IsCarriedOver).length > 0)
            || (this.ReferencedDocuments && this.ReferencedDocuments.filter(x => !x.IsCarriedOver).length > 0);
    }

    get isThereAnyHistoricalLink(): boolean {
        return (this.Custom && this.Custom.filter(x => x.IsCarriedOver).length > 0)
            || (this.Duplicated && this.Duplicated.filter(x => x.IsCarriedOver).length > 0)
            || (this.Reclasiffied && this.Reclasiffied.filter(x => x.IsCarriedOver).length > 0)
            || (this.ReferencedDocuments && this.ReferencedDocuments.filter(x => x.IsCarriedOver).length > 0);
    }

    getDuplicatedLinks(isHistorical: boolean): InvoiceLinkViewModel[] {
        const links = this.Duplicated.filter(x => x.IsCarriedOver === isHistorical);
        return sortBy(links, link => [
            link.HeaderName,
            !link.IsSystemGenerated,
            link.InvoiceNumber
        ]);
    }

    getReferencedDocumentsLinks(isHistorical: boolean): InvoiceLinkViewModel[] {
        const links = this.ReferencedDocuments.filter(x => x.IsCarriedOver === isHistorical);
        return sortBy(links, link => [
            link.HeaderName,
            !link.IsSystemGenerated,
            link.InvoiceNumber
        ]);
    }

    getCustomLinks(isHistorical: boolean): EntityLinkViewModel[] {
        const links = this.Custom.filter(x => x.IsCarriedOver === isHistorical);
        return sortBy(links, link => [
            link.HeaderName,
            !link.IsSystemGenerated,
            link.Label
        ]);
    }

    getReclasiffiedLinks(isHistorical: boolean): EntityLinkViewModel[] {
        const links = this.Reclasiffied.filter(x => x.IsCarriedOver === isHistorical);
        return sortBy(links, link => [
            link.HeaderName,
            !link.IsSystemGenerated,
            link.Label
        ]);
    }

    constructor(linksDto: LinksWithCategoriesDto) {
        this.Duplicated = linksDto.duplicated.map(x => new InvoiceLinkViewModel(x));
        this.Custom = linksDto.custom.map(x => new EntityLinkViewModel(x));
        this.Reclasiffied = linksDto.reclasiffied.map(x => new EntityLinkViewModel(x));
        this.ReferencedDocuments = linksDto.referencedDocuments.map(x => new InvoiceLinkViewModel(x));
    }
}

export {LinksWithCategoriesViewModel,
    LinksWithCategoriesDto,
    EntityLinkDto,
    InvoiceLinkDto,
    BaseLinkDto,
    EntityLinkViewModel,
    InvoiceLinkViewModel,
    LinkViewModelBase,
    LinkTypeEnum,
    LinkType};