///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/documentSearch/filtering/additionalFilterEditor"/>
import * as helpers from "Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/helpers";
import * as metadataColumns from "Core/Medius.Core.Web/Scripts/Medius/apps/documentSearch/metadataColumns";
import * as ko from "knockout";
import { isEmptyString, isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import * as _ from "underscore";
import * as searchFields from "Core/Medius.Core.Web/Scripts/Medius/apps/documentSearch/filtering/fields";
import * as typeResolver from "Core/Medius.Core.Web/Scripts/Medius/components/resolver/type";
import { getTranslation } from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/provider/translations";
import { getPropertyType } from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/helpers/propertyType";
import * as operatorsProvider from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/provider/operators";
import * as dateLocalizer  from "Core/Medius.Core.Web/Scripts/Medius/components/editors/date/localizer";

const BinaryField = searchFields.BinaryField;
const emptyOperator = {
    Translation: ko.observable(" "),
    Arity: ko.observable(0)
};

class AdditionalFilterEditor {
    typeFullName: any = null;
    DataSourceColumns: any = null;
    ColumnFields = ko.observableArray([]);
    AdditionalFilterQuery = ko.observable(null);
    isFilterEnable = ko.computed(() => {
        return !isEmptyString(this.AdditionalFilterQuery());
    });
    hasFields = ko.computed(() => {
        return this.ColumnFields().length > 0;
    });
    isFilterVisible = ko.computed(() => {
        return this.hasFields();
    });
    isFilterShown = ko.observable(false);

    parseColumnFieldsToQuery() {
        const queries: Array<any> = [];

        _.each(this.ColumnFields(), (field) => {
            const propPath = field.PropertyPath,
                isValuePickedAndSet = field.operator() !== emptyOperator;

            if (isValuePickedAndSet) {

                const values = _.map(field.components(), function (item) {
                    const editor = item(),
                        value = editor.value();

                    if (isEmptyString(value)) {
                        return "";
                    }

                    if (editor.Type === "Medius.Core.Entities.Quantity") {
                        return "'" + value.DisplayValue() + " " + value.UnitDescription + "'";
                    }

                    //if editor is for entity combine query with entity.ID
                    if (typeResolver.isEntity(editor.Type) && value) {
                        return value.Id();
                    }

                    //if editor is for date combine query with FORMATTED date
                    if (helpers.isDate(value)) {
                        const localizer = dateLocalizer.create("d");
                        const dateString = localizer.toString(value);
                        return dateString;
                    }

                    //if editor is for primitive type, combine simple srting query
                    if (_.isString(value) && value.indexOf(' ') >= 0) {
                        return "'" + value + "'";
                    }

                    return value;
                });

                let concatedValues = values.join(",");

                if (!isEmptyString(concatedValues)) {
                    concatedValues = (values.length > 1) ? `(${concatedValues})` : concatedValues;

                    const query = `${propPath} ${field.operator().Translation()} ${concatedValues}`;
                    queries.push(query);
                }
            }
        });

        const andOperator = operatorsProvider.getLogicalConjunctionOperator();
        return queries.join(` ${andOperator} `);
    }

    resetFiltering() {
        _.each(this.ColumnFields(), (docField) => {
            docField.resetField();
        });
    }

    createColumnField(valueSourcePath: any, id: any) {
        const sourceColumn = _(this.DataSourceColumns).find((column) => {
                return column.ValueSource === valueSourcePath;
            });

        const columnLabel = isNullOrUndefined(sourceColumn)
            ? getTranslation(this.typeFullName, valueSourcePath)
            : sourceColumn.ColumnName;

        const columnType = getPropertyType(this.typeFullName, valueSourcePath);

        //TODO: ug: for now get only hints which are arity <= 2 ('in' not included)
        const hintsObj = operatorsProvider.getBinaryOperatorsWithArity(columnType, [0, 1, 2]);

        if (hintsObj.length > 0) { //make it empty when only empty operator is in
            hintsObj.unshift(emptyOperator);
        }

        const field = new BinaryField(columnLabel, this.typeFullName, valueSourcePath, id, columnType, hintsObj, emptyOperator);
        return field;
    }

    loadFields(columnConfiguration: any) {
        const columns = _.chain(columnConfiguration)
            .reject((column) => {
                return _.any(metadataColumns.availableMetadataColumns(), (item) => {
                    return item.Key === column;
                });
            })
            .reject((column) => {
                return isEmptyString(column) || typeResolver.isIgnored(this.typeFullName, column);
            })
            .map((config, index) => {
                return this.createColumnField(config, index);
            })
            .value();

        this.ColumnFields(columns);
    }

    reloadFields(type: any, columnConfiguration: any, dataSourceColumns: any) {
        this.typeFullName = type;
        this.DataSourceColumns = dataSourceColumns;
        this.loadFields(columnConfiguration);
    }

    resetFields() {
        this.reloadFields(this.typeFullName, [], undefined);
    }

    reset() {
        this.isFilterShown(false);
        this.resetFields();
        this.AdditionalFilterQuery(null);
    }

    toggleFilterShown() {
        this.isFilterShown(!this.isFilterShown());
    }

    destroy() {
        this.reset();
        this.isFilterEnable.dispose();
        this.isFilterVisible.dispose();
        this.hasFields.dispose();
    }
}

export function create() {
    return new AdditionalFilterEditor();
}