/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/administration/bindings/withEntityCtxTypeScriptModule"/>
import { uniqueId } from "underscore";
import { registerBinding } from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as ko from "knockout";
const registeredModules: any = {};

const loadModule = function (moduleName: string, key: string, context: any) {
    let moduleVm: any;

    if (!context.$data.modules) {
        context.$data.modules = {};
    }

    // load from cache by key
    if (context.$data.modules[key]) {
        moduleVm = context.$data.modules[key];
    }
    // create a new instance and cache it
    else if(registeredModules[moduleName]) {
        const defaultViewModel = context.$data;
        const tabModel = context.$parent;

        const module = registeredModules[moduleName];
        moduleVm = module(defaultViewModel.EntityWithContext, defaultViewModel.CompanyContextId, tabModel);

        context.$data.modules[key] = moduleVm;
    }
    else {
        const defaultViewModel = context.$data;
        const tabModel = context.$parent;

        let module = require(moduleName);
        if (module.default)
            module = module.default;
        moduleVm = module(defaultViewModel.EntityWithContext, defaultViewModel.CompanyContextId, tabModel);

        context.$data.modules[key] = moduleVm;
    }

    return moduleVm;
};

const withEntityCtxTypeScriptModule = {
    init(element: HTMLElement, bindingParams: any, allBindingsAccessor: any, viewModel: any, context: any) {
        const module = bindingParams().module;
        const key = bindingParams().key || uniqueId('module_');

        // load module from cache by key or initialize a new instance
        const moduleVm = loadModule(module, key, context);

        if (moduleVm && moduleVm.dispose) {
            ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
                if (moduleVm) {
                    moduleVm.dispose();
                }
                context.$data.modules[key] = null;
            });
        }

        const newContext = context.createChildContext(moduleVm);

        ko.applyBindingsToDescendants(newContext, element);

        return { controlsDescendantBindings: true };
    }
};

export function registerModule(name: string, module: any) {
    registeredModules[name] = module;
}

export function registerWithEntityCtxTypeScriptBinding() {
    registerBinding("withEntityCtxTypeScriptModule", withEntityCtxTypeScriptModule);
}