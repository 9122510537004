///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/task/RiskFactor/RiskDescriptions/DuplicatedInvoice"/>
import { getLabelTranslation } from 'Core/Medius.Core.Web/Scripts/lib/globalization';
import * as React from 'react';
import { IHandledInfo, RiskFactorCode, RiskFactorReasonCode } from 'Core/Medius.Core.Web/Scripts/Medius/apps/task/RiskFactor/RiskFactorComponentDtos/riskFactorsForInvoiceDto';
import { DatePresenter } from 'Core/Medius.Core.Web/Scripts/shared/components/datePresenter';
import { getRiskFactorNameTranslation } from 'Core/Medius.Core.Web/Scripts/Medius/apps/task/RiskFactor/riskFactorUtils';

export interface IDuplicatedInvoiceProps {
    isRiskHandled: boolean;
    currentReasonCode: RiskFactorReasonCode;
    handledInfo: IHandledInfo;
}

export const DuplicatedInvoice = ({ 
    isRiskHandled, 
    currentReasonCode,
    handledInfo
}: IDuplicatedInvoiceProps) => {
    return <>
        <h4>{getRiskFactorNameTranslation(RiskFactorCode.DuplicatedInvoice)}</h4>
        {
            isRiskHandled ? (
                <div>
                    <div className="risk-factor-icon-container__handlerDetails">
                        <p style={{margin: "0px"}}>{getLabelTranslation('#Core/handledBy')}</p>
                        <p>{handledInfo.handledByUser.firstName}</p>
                        <p style={{marginRight: "5px"}}>{handledInfo.handledByUser.lastName}</p>
                        <DatePresenter 
                            date={new Date(handledInfo.handledAt)} 
                            format="G"
                        />
                    </div>
                    <p>{resolveReasonCodeTranslation(currentReasonCode)}</p>
                </div>
            ) : (
                <div>
                    <p>
                        {getLabelTranslation("#Core/duplicateInvoiceInfoText")}
                    </p>
                </div>
            )
        }
    </>;
};

function resolveReasonCodeTranslation(handlingReasonCode: RiskFactorReasonCode){
    switch (handlingReasonCode) {
        case RiskFactorReasonCode.ValueChanged:
            return getLabelTranslation("#Core/handlingReasonCodeValueChanged");
        case RiskFactorReasonCode.OriginalInvoiceInvalidatedOrEdited:
            return getLabelTranslation("#Core/handlingCodeOriginalInvoiceInvalidatedOrEdited");
        case RiskFactorReasonCode.SupplierChanged:
            return getLabelTranslation("#Core/handlingReasonCodeSupplierChanged");
        case RiskFactorReasonCode.InvoiceDeletedDuplicateInvoice:
            return getLabelTranslation("#Core/handlingCodeInvoiceDeletedDuplicateInvoice");
        case RiskFactorReasonCode.InvoiceDeletedFraudAttempt:
            return getLabelTranslation("#Core/handlingCodeInvoiceDeletedFraudAttempt");
        case RiskFactorReasonCode.InvoiceDeletedOther:
            return getLabelTranslation("#Core/handlingCodeInvoiceDeletedOther");
        case RiskFactorReasonCode.InvoiceArchived:
            return getLabelTranslation("#Core/handlingReasonCodeInvoiceArchived");
        default:
            console.warn(`[DuplicatedInvoice] handling reason code ${handlingReasonCode} was not implemented for this risk factor`);
            break;
    }
}