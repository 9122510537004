/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/EdiDocumentMapper/documentSource"/>
import { IEntityWithContext } from "Core/Medius.Core.Web/Scripts/AdminPages/IEntityWithContext";
import { isDocumentFormatMappingEnabled } from "Core/Medius.Core.Web/Scripts/Medius/core/featureToggle";
import * as ko from "knockout";
import {translateDocumentSource} from "Core/Medius.Core.Web/Scripts/Medius/apps/document/documentTranslator";

class DocumentSource {
    public Entity: any;
    public DocumetSourceEnum: { Id: number; Value: string; }[];

    public DocumentSourceMappings: any;
    public IsDocumentFormatMappingEnabled:ko.Observable<boolean>;

    constructor(entity: any) {
        this.Entity = entity;
        this.IsDocumentFormatMappingEnabled = ko.observable(isDocumentFormatMappingEnabled());

        this.DocumentSourceMappings = ko.observableArray(entity.DocumentSourceMappings());

        this.DocumetSourceEnum = [
            { Id: 0, Value: translateDocumentSource(0) },
            { Id: 1, Value: translateDocumentSource(1) },
            { Id: 2, Value: translateDocumentSource(2) },
            { Id: 3, Value: translateDocumentSource(3) }
        ];
    }

    public addRow = () => {
        this.DocumentSourceMappings.push({ FromDocumentSource: '', TargetDocumentSource: '' });
    };

    public removeRow = (row : any) => {
        this.DocumentSourceMappings.remove(row);
    };
}

export default function(entityWithCtx: IEntityWithContext) {
    return new DocumentSource(entityWithCtx.Entity);
}
