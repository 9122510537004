/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/kendo/bindings/kendoDialog"/>

import * as ko from "knockout";
import * as $ from "jquery";
import { registerBinding, addDisposeCallback } from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";

function escapeKendoTemplates(input: string): string {
    return input.replace("#", "\\#");
}

type OptionsEnhancer = (params: any, options: KendoDialogOptions) => void;

class KendoDialogBinding {
    public static registerKnockoutBindings(): void {
        registerBinding("kendoDialog",
            KendoDialogBinding.makeKnockoutBinding(
                KendoDialogBinding.titleOptionsEnhancer));

        registerBinding("kendoConfirm",
            KendoDialogBinding.makeKnockoutBinding(
                KendoDialogBinding.confirmOptionsEnhancer));
    }

    private static makeKnockoutBinding(optionsEnhancer: OptionsEnhancer): any {
        return {
            init(element: HTMLElement) {
                const $sourceElement = $(element);

                // We have to wrap binding's source element with something,
                // because Kendo will move it into theirs DIV which is directly
                // under HTML > BODY. Without this wrapper (which will stay
                // where dialog is defined), when whole Knockout's template
                // is being disposing, the dialog is not destroyed.
                const $wrapper = $sourceElement
                    .wrap("<div></div>")
                    .parent()
                    .hide();

                addDisposeCallback($wrapper.get(0), () => {
                    KendoDialogBinding.destroyWidget($sourceElement);
                });
            },

            update(element: HTMLElement, valueAccessor: any) {
                const $sourceElement = $(element);
                KendoDialogBinding.destroyWidget($sourceElement);

                const params = valueAccessor();
                const options = ko.unwrap(params.options) as KendoDialogOptions;

                if (options) {
                    optionsEnhancer(params, options);

                    const dialog = $sourceElement
                        .kendoDialog(options)
                        .data("kendoDialog");

                    options.showDialog = () => {
                        dialog.open();
                        const focused = dialog.element.find("[autofocus]");
                        if (focused.length > 0)
                            focused.first().focus();
                    };

                    options.setTitle = (title: string) => {
                        dialog.title(title);
                    };
                }
            }
        };
    }

    private static destroyWidget($element: JQuery): void {
        const widget = $element
            .data("kendoDialog");

        if (widget) {
            widget.destroy();
            $element.empty();
        }
    }

    private static titleOptionsEnhancer(params: any, options: KendoDialogOptions): void {
        const title = ko.unwrap(params.title) as string;
        options.title = escapeKendoTemplates(
            getLabelTranslation(title));
    }

    private static confirmOptionsEnhancer(params: any, options: KendoDialogOptions): void {
        KendoDialogBinding.titleOptionsEnhancer(params, options);

        const confirmOptions = options as KendoConfirmOptions;

        const contentText = escapeKendoTemplates(
            getLabelTranslation(params.message));

        const cancelText = escapeKendoTemplates(
            getLabelTranslation(
                params.cancelButton || "#Core/cancel"));

        const confirmText = escapeKendoTemplates(
            getLabelTranslation(
                params.confirmButton || "#Core/confirm"));

        confirmOptions.modal = true;
        confirmOptions.closable = false;
        confirmOptions.content = `<div data-testid="kendo-confirmDialog">${contentText}</div>`;
        confirmOptions.actions = [
            { text: cancelText, action: confirmOptions.onCancel },
            { text: confirmText, action: confirmOptions.onConfirm, primary: true }
        ];
    }
}

export function register() {
    KendoDialogBinding.registerKnockoutBindings();
}
