/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/grid/dataSource/simpleRelation"/>

import DataSource = require("Core/Medius.Core.Web/Scripts/Medius/components/grid/dataSource/default");
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as $ from "jquery";

export class SimpleRelationDataSource extends DataSource {
    columnName: string;
    constructor(columnName?: string) {
        super();
        this.columnName = columnName;
    }

    load(dataQuery: any) {
        return dataQuery.entities();
    }

    loadColumns() {
        const columns = [
            {
                Name: this.columnName,
                Searchable: false,
                Sortable: false,
                Width: 50,
                ValueSource: ""
            },
            {
                Name: globalization.getLabelTranslation("#Core/remove"),
                Searchable: false,
                Sortable: false,
                Width: 50,
                ValueSource: ""
            }
        ];

        return $.Deferred().resolve(columns);
    }
}


