/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/generic/model" />

import * as helpers from "Core/Medius.Core.Web/Scripts/Medius/components/editors/helpers";
import * as propertyResolver from "Core/Medius.Core.Web/Scripts/Medius/components/resolver/property";
import { resolve } from "Core/Medius.Core.Web/Scripts/Medius/components/resolver/component";

import { isUndefined } from "underscore";
import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";

class Generic{
    Template: any;
    Params: any;
    ViewModel: any;
    Type: any;
    Component: any;

    constructor(bindingParameters: any, viewModel: any){
        if (isNullOrUndefined(bindingParameters) || isUndefined(bindingParameters.value)) {
            throw new Error("Generic component - OriginalValue not provided");
        }

        const params = {
            tpl: ""
        };

        helpers.mergeDefaults(bindingParameters, params);
        this.Template = params.tpl;
        this.Params = bindingParameters;
        this.ViewModel = viewModel;
        this.Type = null;
        this.Component = null;

        this.resolveComponent();
    }

    resolveComponent() {
        if (this.Params.type) {
            const component = resolve(this.Params.type);

            if (component) {
                this.Type = this.Params.type;
                this.Component = component;
                return;
            }
        }

        this.Type = propertyResolver.resolvePropertyType(this.Params.value, this.ViewModel);
        this.Component = resolve(this.Type);
    }

    dispose() {
        this.Params = null;
        this.ViewModel = null;
    }

}
    
export function create(bindingParameters: any, viewModel: any) {
    return new Generic(bindingParameters, viewModel);
}