/*global _ */
(function (global) {
    "use strict";
    global.medius = global.medius || {};
    var _ = require("underscore");

    /* This is very crucial for system to work */
    (function() {
        var running;

        function startNyanMode() {
            if (running) {
                return;
            }
            running = true;

            $('<style>' +
                'input, button, div, span, body { ' +
                '   font-family: Comic Sans, Comic Sans MS !important;' +
                '   color: magenta !important;' +
                '} ' +

                '#medius-logo img, #zoidberg { ' +
                '   height: 40px;' +
                '}' +

                '#nyan {' +
                '   position: fixed;' +
                '   left: 0px;' +
                '   height: 80px;' +
                '   transition: left 15s;' +
                '   -webkit-transition: left 15s;' +
                '   -ms-transition: left 15s;' +
                '   -moz-transition: left 15s;' +
                '}' +

                '#nyan.start-nyan {' +
                '   left: 4096px;' +
                '}' +

                '::-webkit-input-placeholder,' +
                '::-moz-placeholder,' +
                ':-ms-input-placeholder {' +
                '  color: cyan !important;' +
                '  font-family: Comic Sans, Comic Sans MS !important;' +
                '}' +
                '</style>').appendTo("body");

            $('<li><img id="zoidberg" src="Content/images/medius_md.gif" /></li>').appendTo("ul#nav-links");
            
            $('<audio autoplay="autoplay">' +
                '<source src="Content/images/nc/nc.mp3" type="audio/mpeg" />' +
                '<source src="Content/images/nc/nc.ogg" type="audio/ogg" />' +
              '</audio>').appendTo("body");

            $("a#medius-logo img").attr({
                src: "Content/images/mediusflow_doge.gif"
            });
        }

        function nyanize() {
            var cat, top;

            top = Math.round(50 + Math.random() * 950);

            cat = $('<img id="nyan" style="top: '+ top +'px;" src="Content/images/medius_nc.gif" /></li>').appendTo("body");

            global.setTimeout(function () { cat.addClass("start-nyan"); }, 0);
            global.setTimeout(nyanize, 1000 + Math.random() * 3000);
        }

        if (!global.jwerty) {
            return;
        }

        global.jwerty.key('↑,↑,↓,↓,←,→,←,→,b,a', function() {
            startNyanMode();
            nyanize();
        });
    }());

} .call(this, this));