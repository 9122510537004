/// <amd-module name="Core/Medius.Core.Web/Scripts/Models/CreateInvoiceAmountsViewModel"/>
import { observable, observableArray } from "knockout";
import { isEmpty } from "underscore";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { Amount, zero as zeroAmount, createFromValues } from "Core/Medius.Core.Web/Scripts/Models/amount";
import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import * as ko from "knockout";
import { get } from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rest";
import { TaxFieldsConfiguration } from "Core/Medius.Core.Web/Scripts/typings/dto/taxFieldsConfiguration";
import * as rpc from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rpc";
import * as utils from "Core/Medius.Core.Web/Scripts/Medius/mediusUtils";

interface CurrencyDto {
    id: number;
    code: string;
}

class ImportTimeCustomTaxField {
    public $type = observable<string>("Medius.Enterprise.Entities.ImportTimeCustomTaxField, Medius.Enterprise.Common");
    public Name: ko.Observable<string>;
    public Amount: ko.Observable<number>;

    constructor(name: string, amount: number) {
        this.Name = observable<string>(name);
        this.Amount = observable<number>(amount);
    }
}

export class CreateInvoiceAmountsViewModel {
    public SupplierId: number;
    public TaxFieldsConfiguration = observable<TaxFieldsConfiguration>();

    public AvailableCurrencies = observableArray<any>([]);
    public SelectedCurrency = observable<any>(null);
    public TaxConfigurationIsLoaded = observable<boolean>(false);
    public AreAmountsDisabled = observable<boolean>(true);
    public TaxTotalAmount = observable<Amount>();
    public OptionsCaptionText: string = translate("#Core/LBL_CHOOSE");
    public CompanyNotSetText = observable<string>(translate("#Enterprise/createPageCompanyNotSelectedLabel"));
    public CurrencyNotSetText = observable<string>(translate("#Enterprise/createPageCurrencyNotSelectedLabel"));
    private isUpdating: boolean;

    private isCurrencyRateImportedSub: ko.Subscription;
    private amountSub: ko.Subscription;
    private roundingSub: ko.Subscription;
    private tax1Sub: ko.Subscription;
    private tax2Sub: ko.Subscription;
    private tax3Sub: ko.Subscription;
    private tax4Sub: ko.Subscription;
    private tax5Sub: ko.Subscription;

    public invoiceViewModel = observable<any>(null);
    private context: any;

    constructor(invoiceViewModel: any, context: any) {
        this.context = context;
        this.invoiceViewModel(invoiceViewModel);

        if (!invoiceViewModel.Amount()) {
            this.invoiceViewModel().Amount(zeroAmount(""));
        }

        if (!invoiceViewModel.Rounding()) this.invoiceViewModel().Rounding(zeroAmount(""));
        if (!invoiceViewModel.Gross()) this.invoiceViewModel().Gross(zeroAmount(""));

        if (!invoiceViewModel.Tax1()) this.invoiceViewModel().Tax1(zeroAmount(""));
        if (!invoiceViewModel.Tax2()) this.invoiceViewModel().Tax2(zeroAmount(""));
        if (!invoiceViewModel.Tax3()) this.invoiceViewModel().Tax3(zeroAmount(""));
        if (!invoiceViewModel.Tax4()) this.invoiceViewModel().Tax4(zeroAmount(""));
        if (!invoiceViewModel.Tax5()) this.invoiceViewModel().Tax5(zeroAmount(""));

        this.TaxTotalAmount(zeroAmount(""));

        
        this.invoiceViewModel().CurrencyRateResolution = observable(10);

        this.isUpdating = false;

        this.isCurrencyRateImportedSub = this.invoiceViewModel().IsCurrencyRateImported.subscribe((newValue: any) => {
            if (!newValue) {
                this.invoiceViewModel().CurrencyRate(0);
            }
        });

        this.amountSub = this.invoiceViewModel().Amount().DisplayValue.subscribe(this.amountChangeSubscription);
        this.roundingSub = this.invoiceViewModel().Rounding().DisplayValue.subscribe(this.amountChangeSubscription);
    }

    public isOnlyTax1Configured() {
        const taxFieldsConfiguration = this.TaxFieldsConfiguration();
        if (isNullOrUndefined(taxFieldsConfiguration)) return false;

        return taxFieldsConfiguration.TaxField1Configuration.Active
            && !taxFieldsConfiguration.TaxField2Configuration.Active
            && !taxFieldsConfiguration.TaxField3Configuration.Active
            && !taxFieldsConfiguration.TaxField4Configuration.Active
            && !taxFieldsConfiguration.TaxField5Configuration.Active;
    }

    public updateCompanyDependentData(taxFieldsConfiguration: TaxFieldsConfiguration) {
        const oldTaxConfiguration = this.TaxFieldsConfiguration();
        const invoiceViewModel = this.invoiceViewModel();
        const selectedCurrency = this.getSelectedCurrency();
        const zero = createFromValues(0, selectedCurrency);

        if (!!oldTaxConfiguration) {
            if (!areStringsEqual(oldTaxConfiguration.TaxField1Configuration.Name, taxFieldsConfiguration.TaxField1Configuration.Name) || !taxFieldsConfiguration.TaxField1Configuration.Active) {
                this.updateTax(invoiceViewModel.Tax1, zero);
            }

            if (!areStringsEqual(oldTaxConfiguration.TaxField2Configuration.Name, taxFieldsConfiguration.TaxField2Configuration.Name) || !taxFieldsConfiguration.TaxField2Configuration.Active) {
                this.updateTax(invoiceViewModel.Tax2, zero);
            }

            if (!areStringsEqual(oldTaxConfiguration.TaxField3Configuration.Name, taxFieldsConfiguration.TaxField3Configuration.Name) || !taxFieldsConfiguration.TaxField3Configuration.Active) {
                this.updateTax(invoiceViewModel.Tax3, zero);
            }

            if (!areStringsEqual(oldTaxConfiguration.TaxField4Configuration.Name, taxFieldsConfiguration.TaxField4Configuration.Name) || !taxFieldsConfiguration.TaxField4Configuration.Active) {
                this.updateTax(invoiceViewModel.Tax4, zero);
            }

            if (!areStringsEqual(oldTaxConfiguration.TaxField5Configuration.Name, taxFieldsConfiguration.TaxField5Configuration.Name) || !taxFieldsConfiguration.TaxField5Configuration.Active) {
                this.updateTax(invoiceViewModel.Tax5, zero);
            }
        }

        this.TaxFieldsConfiguration(taxFieldsConfiguration);
        this.TaxConfigurationIsLoaded(true);

        if (!oldTaxConfiguration) {
            this.initializeTaxFromImportTimeCustomTaxFields();
            this.updateTotalTax();
            this.tax1Sub = this.invoiceViewModel().Tax1().DisplayValue.subscribe(this.updateTaxDependentData);
            this.tax2Sub = this.invoiceViewModel().Tax2().DisplayValue.subscribe(this.updateTaxDependentData);
            this.tax3Sub = this.invoiceViewModel().Tax3().DisplayValue.subscribe(this.updateTaxDependentData);
            this.tax4Sub = this.invoiceViewModel().Tax4().DisplayValue.subscribe(this.updateTaxDependentData);
            this.tax5Sub = this.invoiceViewModel().Tax5().DisplayValue.subscribe(this.updateTaxDependentData);
        }

        this.updateImportTimeCustomTaxFields();

       return this.fetchCurrencies().done(() => this.updateCurrencyOnSupplierChange(invoiceViewModel.Supplier));
    }

    public updateCurrencyOnSupplierChange(supplier: any) {
        if (!this.SelectedCurrency()) {
            if (supplier() && supplier().Id()) {
                const currencyParams = {
                    supplierId: supplier().Id()
                };

                const currencySuccessCallback = (supplierCurrency: any) => {
                    const selectedCurrency = ko.utils.arrayFirst(this.AvailableCurrencies(), (currency: any) => {
                        return currency.Code() === supplierCurrency.Code;
                    });
                    this.SelectedCurrency(selectedCurrency);
                };

                utils.ajax("GetCurrencyForSupplier", "MasterDataService", currencyParams, currencySuccessCallback);
            }
        }
    }

    private getValueIfExists(tax: ko.Observable<Amount>) {
        if (isNullOrUndefined(tax) || isNullOrUndefined(tax()) || isNullOrUndefined(tax().DisplayValue()))
            return 0;
        return tax().DisplayValue();
    }

    private amountChangeSubscription = () => {
        if (!this.isUpdating) {
            this.updateGross(this.TaxTotalAmount(), this.invoiceViewModel().Amount(), this.invoiceViewModel().Rounding());
        }
    };

    public updateAmounts() {
        if (!this.SelectedCurrency())
            return;

        this.isUpdating = true;

        this.AreAmountsDisabled(false);
        const newCurrencyCode = this.SelectedCurrency().Code();
        utils.updateAmountCurrencyCode(this.invoiceViewModel().Amount, newCurrencyCode);
        utils.updateAmountCurrencyCode(this.invoiceViewModel().Gross, newCurrencyCode);
        utils.updateAmountCurrencyCode(this.invoiceViewModel().Rounding, newCurrencyCode);

        utils.updateAmountCurrencyCode(this.invoiceViewModel().Tax1, newCurrencyCode);
        utils.updateAmountCurrencyCode(this.invoiceViewModel().Tax2, newCurrencyCode);
        utils.updateAmountCurrencyCode(this.invoiceViewModel().Tax3, newCurrencyCode);
        utils.updateAmountCurrencyCode(this.invoiceViewModel().Tax4, newCurrencyCode);
        utils.updateAmountCurrencyCode(this.invoiceViewModel().Tax5, newCurrencyCode);
        utils.updateAmountCurrencyCode(this.TaxTotalAmount, newCurrencyCode);

        this.updateCurrencyRateResolution();

        this.isUpdating = false;
    }

    private updateGross(tax: Amount, amount: Amount, rounding: Amount) {
        if (amount
            && !isEmpty(amount.CurrencyCode())
            && tax
            && !isEmpty(tax.CurrencyCode())
            && rounding
            && !isEmpty(rounding.CurrencyCode())) {
            const params = {
                      amount: amount.toJS(),
                      tax: tax.toJS(),
                      rounding: rounding.toJS()
                  },
                  successCallback = (gross: any) => {
                      this.invoiceViewModel().Gross().update(gross);
                  };

            this.context.enqueue(() => {
                return utils.ajax("CalculateGross", "MasterDataService", params, successCallback);
            });
        }
    }

    private updateCurrencyRateResolution() {
        if (this.invoiceViewModel().Company()
            && this.invoiceViewModel().Company().Id() > 0
            && this.invoiceViewModel().InvoiceDate()
            && this.SelectedCurrency()) {

            rpc.lightApi("CurrencyRateService", "GetRateForCompany", {
                currencyId: this.SelectedCurrency().Id(),
                companyId: this.invoiceViewModel().Company().Id(),
                date: this.invoiceViewModel().InvoiceDate()
            }).done((data: any) => {
                if (data !== null && data !== undefined) {
                    this.invoiceViewModel().CurrencyRateResolution(data.Resolution);
                    if (!this.invoiceViewModel().IsCurrencyRateImported()) {
                        this.invoiceViewModel().CurrencyRate(0);
                    }
                }
            });
        }
    }

    private preselectCurrency() {
        const amount = this.invoiceViewModel().Amount();
        if (amount && amount.CurrencyCode() !== null) {
            const selectedCurrency = ko.utils.arrayFirst(this.AvailableCurrencies(), (currency: any) => {
                return currency.Code() === amount.CurrencyCode();
            });
            this.SelectedCurrency(selectedCurrency);
        }
    }

    private onGetCurrenciesSuccess = (data: CurrencyDto[]) => {
        this.AvailableCurrencies(this.context.update(data));
        this.AvailableCurrencies.sort((left, right) => {
            return left.Code() === right.Code()
                ? 0
                : (left.Code() < right.Code()
                    ? -1
                    : 1);
        });
        this.preselectCurrency();
    };

    private fetchCurrencies() {
        if(this.invoiceViewModel().Company()
            && this.invoiceViewModel().Company().Id() > 0)
            {
                return get("currency", `forcompany?companyId=${this.invoiceViewModel().Company().Id()}`).then((currencies: CurrencyDto[]) => {
                    this.onGetCurrenciesSuccess(currencies);
                });
            }
    }

    private initializeTaxFromImportTimeCustomTaxFields() {
        const taxConfiguration = this.TaxFieldsConfiguration();
        const invoiceViewModel = this.invoiceViewModel();
        const currencyCode = this.getSelectedCurrency();
        const customTaxFields: Array<ImportTimeCustomTaxField> = invoiceViewModel.ImportTimeCustomTaxFields();

        if (taxConfiguration.TaxField1Configuration.Active) {
            if (invoiceViewModel.ImportTimeTax() !== null) {
                this.updateTax(invoiceViewModel.Tax1, createFromValues(invoiceViewModel.ImportTimeTax(), currencyCode));
            }
        }

        if (taxConfiguration.TaxField2Configuration.Active) {
            const tax = customTaxFields.find(t => areStringsEqual(t.Name(), taxConfiguration.TaxField2Configuration.Name));
            if (!!tax) this.updateTax(invoiceViewModel.Tax2, createFromValues(tax.Amount(), currencyCode));
        }

        if (taxConfiguration.TaxField3Configuration.Active) {
            const tax = customTaxFields.find(t => areStringsEqual(t.Name(), taxConfiguration.TaxField3Configuration.Name));
            if (!!tax) this.updateTax(invoiceViewModel.Tax3, createFromValues(tax.Amount(), currencyCode));
        }

        if (taxConfiguration.TaxField4Configuration.Active) {
            const tax = customTaxFields.find(t => areStringsEqual(t.Name(), taxConfiguration.TaxField4Configuration.Name));
            if (!!tax) this.updateTax(invoiceViewModel.Tax4, createFromValues(tax.Amount(), currencyCode));
        }

        if (taxConfiguration.TaxField5Configuration.Active) {
            const tax = customTaxFields.find(t => areStringsEqual(t.Name(), taxConfiguration.TaxField5Configuration.Name));
            if (!!tax) this.updateTax(invoiceViewModel.Tax5, createFromValues(tax.Amount(), currencyCode));
        }
    }

    private updateTax(taxToUpdate: ko.Observable<any>, sourceTax: Amount) {
        taxToUpdate().DisplayValue(sourceTax.DisplayValue());
        taxToUpdate().CurrencyCode(sourceTax.CurrencyCode());
    }

    private updateTaxDependentData = () => {
        this.updateTotalTax();
        this.amountChangeSubscription();
        this.updateImportTimeCustomTaxFields();
    };

    private updateTotalTax = () => {
        const sumOfTaxes = this.getValueIfExists(this.invoiceViewModel().Tax1)
            + this.getValueIfExists(this.invoiceViewModel().Tax2)
            + this.getValueIfExists(this.invoiceViewModel().Tax3)
            + this.getValueIfExists(this.invoiceViewModel().Tax4)
            + this.getValueIfExists(this.invoiceViewModel().Tax5);

        const amount = createFromValues(sumOfTaxes, this.getSelectedCurrency());
        this.updateTax(this.TaxTotalAmount, amount);
    };

    private updateImportTimeCustomTaxFields = () => {
        const taxConfiguration = this.TaxFieldsConfiguration();
        const invoiceViewModel = this.invoiceViewModel();

        const customTaxFields: Array<ImportTimeCustomTaxField> = [];

        if (taxConfiguration.TaxField1Configuration.Active) {
            invoiceViewModel.ImportTimeTax((invoiceViewModel.Tax1() as Amount).DisplayValue());
        } else {
            invoiceViewModel.ImportTimeTax(null);
        }

        if (taxConfiguration.TaxField2Configuration.Active) {
            customTaxFields.push(new ImportTimeCustomTaxField(taxConfiguration.TaxField2Configuration.Name, (invoiceViewModel.Tax2() as Amount).DisplayValue()));
        }

        if (taxConfiguration.TaxField3Configuration.Active) {
            customTaxFields.push(new ImportTimeCustomTaxField(taxConfiguration.TaxField3Configuration.Name, (invoiceViewModel.Tax3() as Amount).DisplayValue()));
        }

        if (taxConfiguration.TaxField4Configuration.Active) {
            customTaxFields.push(new ImportTimeCustomTaxField(taxConfiguration.TaxField4Configuration.Name, (invoiceViewModel.Tax4() as Amount).DisplayValue()));
        }

        if (taxConfiguration.TaxField5Configuration.Active) {
            customTaxFields.push(new ImportTimeCustomTaxField(taxConfiguration.TaxField5Configuration.Name, (invoiceViewModel.Tax5() as Amount).DisplayValue()));
        }

        invoiceViewModel.ImportTimeCustomTaxFields(customTaxFields);
    };

    private getSelectedCurrency(): string {
        return isNullOrUndefined(this.SelectedCurrency()) ? "" : this.SelectedCurrency().Code();
    }

    public dispose() {
        this.isCurrencyRateImportedSub.dispose();
        this.amountSub.dispose();
        this.roundingSub.dispose();

        this.tax1Sub && this.tax1Sub.dispose();
        this.tax2Sub && this.tax2Sub.dispose();
        this.tax3Sub && this.tax3Sub.dispose();
        this.tax4Sub && this.tax4Sub.dispose();
        this.tax5Sub && this.tax5Sub.dispose();
    }
}

function areStringsEqual(left: string, right: string) {
    if (left === undefined || right === undefined) {
        throw new Error("Cannot compare strings which are undefined.");
    }

    if (left === null && right === null) {
        return true;
    }

    if (left === null || right === null || left.length !== right.length) {
        return false;
    }

    return left.toLowerCase() === right.toLowerCase();
}
