/// <amd-module name="Core/Medius.Core.Web/Scripts/Models/fullScreenUxLog"/>
import {logEvent} from "Core/Medius.Core.Web/Scripts/lib/metricsLogging/metricLogger";
import * as settings from "Core/Medius.Core.Web/Scripts/Medius/apps/task/settings";

const fullScreen = "Open in fullscreen";
const splitView = "Open in split - view";
const screenMode = "screenMode";

export function logScreenMode(viewmodelContext: any, stepName: string) {

    const getContextData = viewmodelContext.getContextData || viewmodelContext.get;
    const taskHandleData = getContextData(settings.context);

    if (taskHandleData.isFullscreenEnabled() === false) {
        logEvent(`${screenMode}.${extractDocuentTypeName(taskHandleData.documentType)}`, `${stepName}.${splitView}`);
    } else {
        logEvent(`${screenMode}.${extractDocuentTypeName(taskHandleData.documentType)}`, `${stepName}.${fullScreen}`);
    }
}

function extractDocuentTypeName(documentType: any) {
    const documentTypeArray = documentType.split(".");
    return getLastArrayValue(documentTypeArray);
}

function getLastArrayValue(documentTypeArray: any) {
    return documentTypeArray.slice(-1)[0];
}
