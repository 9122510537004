///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/states/states"/>
import * as _ from "underscore";
import * as propertyHelper from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/helpers/property";
import * as propertyTypeHelper from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/helpers/propertyType";
import * as operatorsProvider from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/provider/operators";
import * as hintsProvider from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/provider/hints";
import { resolvePropertyFromTranslation }  from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/provider/translations";
import { isEmptyString, isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import * as filterHelper from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/helpers/filter";
import * as lexemeValidator from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/lexeme/validator";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as translationsProvider from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/provider/translations";
import { getOrderByHints }from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/provider/hints"; 
import { getTranslatedSortingOrders } from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/provider/translations";
import * as typesProvider from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/provider/types";

export const ConditionState = {
    isTransitionAllowed: function(lexeme: string, lexemeIterator: any, parsingData: any) {
        const isCorrectProperty = propertyHelper.isCorrectProperty(parsingData.entityType, lexeme);
        const isCorrectOperator = operatorsProvider.isCorrectUnaryOperator(parsingData.entityType, lexeme);

        return isCorrectProperty || isCorrectOperator || lexeme === "(" || lexeme === operatorsProvider.getLogicalNegationOperator();
    },
    getHints: function(lexemeIterator: any, parsingData: any):any {
        const lexeme = lexemeIterator.next();
        const hintsResult = ConditionState.hints(lexeme, parsingData);

        if (isEmptyString(lexeme)) {
            return hintsResult;
        }

        if (ConditionState.isTransitionAllowed(lexeme, lexemeIterator, parsingData)) {
            return ConditionState.transition(lexeme, parsingData).getHints(lexemeIterator, parsingData);
        }

        return hintsResult;
    },
    validate: function(lexemeIterator: any, parsingData: any): any{
        const lexeme = lexemeIterator.next();

        if (ConditionState.isTransitionAllowed(lexeme, lexemeIterator, parsingData)) {
            return ConditionState.transition(lexeme, parsingData).validate(lexemeIterator, parsingData);
        }

        return { valid: false };
    },
    transition: function(currentLexeme: string, parsingData: any) {
        const entityType = parsingData.entityType;
        const property = resolvePropertyFromTranslation(entityType, currentLexeme);
        const propertyType = propertyTypeHelper.getPropertyType(entityType, property);

        parsingData.property = property;
        parsingData.propertyTranslation = currentLexeme;
        parsingData.propertyType = propertyType;

        if (!isEmptyString(propertyType)) {
            return PropertyOperatorsState;
        }

        if (operatorsProvider.isCorrectUnaryOperator(entityType, currentLexeme)) {
            const operatorData = operatorsProvider.getOperatorDataByTranslation(currentLexeme);
            parsingData.operator = operatorData;

            if (operatorData.Arity === 0) {
                return ConditionFinishedState;
            }

            return OperatorValuesState;
        }

        if (currentLexeme === operatorsProvider.getLogicalNegationOperator()) {
            return ConditionState;
        }

        if (!parsingData.openedBrackets) {
            parsingData.openedBrackets = 0;
        }
        parsingData.openedBrackets++;

        return ConditionState;
    },
    hints: function(currentLexeme: string, parsingData: any) {
        const hints = [];

        if (currentLexeme) {
            currentLexeme = currentLexeme.trim();
        }

        if (isEmptyString(currentLexeme)) {
            hints.push('(');
        }

        return hints.concat(hintsProvider.getHints(parsingData.entityType, currentLexeme));
    }
};

export const ConditionFinishedState = {
    stateType: "conditionFinished",
    getHints: function(lexemeIterator: any, parsingData: any) {
        const lexeme = lexemeIterator.next();

        if (isEmptyString(lexeme) && !parsingData.endsWithWhitespace) {
            return [];
        }

        const hintsResult = ConditionFinishedState.hints(lexeme, parsingData);

        if (lexemeIterator.hasNext() || (_(hintsResult).contains(lexeme) && parsingData.endsWithWhitespace)) {
            return ConditionFinishedState.transition(lexeme, parsingData).getHints(lexemeIterator, parsingData);
        }

        return hintsResult;
    },
    validate: function(lexemeIterator: any, parsingData: any) {
        const lexeme = lexemeIterator.next();

        if (isEmptyString(lexeme) && parsingData.openedBrackets > 0) {
            return { valid: false, message: "not enough closing brackets" };
        }

        if (lexeme === ')' && parsingData.openedBrackets === 0) {
            return { valid: false, message: "too much closing brackets" };
        }

        if (isEmptyString(lexeme) && !lexemeIterator.hasNext()) {
            return { valid: true };
        }

        const hintsResult = ConditionFinishedState.hints(lexeme, parsingData);

        if (!_(hintsResult).contains(lexeme)) {
            return { valid: false, message: "unrecognized lexeme: " + lexeme };
        }

        const modifiedInstance = ConditionFinishedState.transition(lexeme, parsingData);
        return modifiedInstance.validate(lexemeIterator, parsingData);
    },
    transition: function(currentLexeme: string, parsingData: any): any {
        if (currentLexeme === ')') {
            parsingData.openedBrackets--;
            return ConditionFinishedState;
        }

        if (currentLexeme === operatorsProvider.getOrderByOperator()) {
            return OrderingPropertyState;
        } else { // and, or
            return ConditionState;
        }
    },
    hints: function(currentLexeme: string, parsingData: any) {
        let hints = [operatorsProvider.getOrderByOperator()];

        hints = hints.concat(operatorsProvider.getLogicalOperatorsWithoutNegation());

        if (parsingData.openedBrackets > 0) {
            hints.push(')');
        }

        return filterHelper.filterOut(hints, currentLexeme);
    }
};

const incorrectValues = ['\'', '"', '\\', '/', '(', ')'];

export const OperatorValuesState = {
    getHints: function(lexemeIterator: any, parsingData: any) {
        const lexeme = lexemeIterator.next();

        if (_(incorrectValues).contains(lexeme) && !lexemeIterator.hasNext()) {
            return [];
        }

        if (isEmptyString(lexeme) || (!lexemeIterator.hasNext() && !parsingData.endsWithWhitespace)) {
            return OperatorValuesState.hints(lexeme, parsingData);
        }

        return OperatorValuesState.transition(lexeme, parsingData).getHints(lexemeIterator, parsingData);
    },
    validate: function(lexemeIterator: any, parsingData: any) {
        const lexeme = lexemeIterator.next();
        const expectedValueType = parsingData.propertyType;
        const operatorArity = parsingData.operator.Arity;

        if ((operatorArity === 0 && !isEmptyString(lexeme)) ||
            (operatorArity !== 0 && isEmptyString(lexeme)) ||
            (_(incorrectValues).contains(lexeme) && !lexemeIterator.hasNext())) {
            return { valid: false };
        }

        if (isEmptyString(expectedValueType) || lexeme === "(") {
            return OperatorValuesState.transition(lexeme, parsingData).validate(lexemeIterator, parsingData);
        }

        if (!isEmptyString(expectedValueType) && !lexemeValidator.validate(lexeme, parsingData)) {
            return { valid: false };
        }

        return OperatorValuesState.transition(lexeme, parsingData).validate(lexemeIterator, parsingData);
    },
    transition:function(currentLexeme: string, parsingData: any) {

        if (currentLexeme === '(') {
            parsingData.openedBrackets = parsingData.openedBrackets || 0;
            parsingData.openedBrackets++;
            return ValuesInBracketsState;
        }

        return ConditionFinishedState;
    },
    hints: function(lexeme:string, parsingData:any) {
        const operatorData = parsingData.operator;

        if (!operatorData) {
            return [];
        }

        const operator = operatorData.Operator;
        const arity = operatorData.Arity;
        const canProvideCustomSuggestion = operatorData.CanProvideCustomSuggestion;

        if (isNullOrUndefined(arity) || arity === -1) {
            return ['('];
        }

        if (canProvideCustomSuggestion) {
            return hintsProvider.getCustomSuggestions(operator, lexeme);
        }

        if (parsingData.property) {
            return hintsProvider.getValueHints(parsingData.entityType, operator,
                parsingData.property, parsingData.propertyType, lexeme);
        }

        return [];
    },
    localizer: null as any
};

const SKIP_LEXEMES = [",", ";"];
const BRACKETS = ["(", ")"];

export const ValuesInBracketsState = {
    getHints:function(lexemeIterator:any, parsingData:any):string[] {
        const currentLexeme = lexemeIterator.next();

        if (currentLexeme !== ")" && parsingData.openedBrackets > 0 && !lexemeIterator.hasNext()) {
            return [ ')' ];
        }
                
        return ValuesInBracketsState.transition(currentLexeme, parsingData).getHints(lexemeIterator, parsingData);
    },
    validate:function(lexemeIterator:any, parsingData:any): {valid:boolean, message?:string} {
        const expectedValueType = parsingData.propertyType;
        const currentLexeme = lexemeIterator.next();
        let nextLexeme: string; 
        if (_(SKIP_LEXEMES).contains(currentLexeme)) {
            nextLexeme = lexemeIterator.next();
            lexemeIterator.prev();

            if (nextLexeme === ')') {
                return { valid: false };
            } else {
                return ValuesInBracketsState.transition(currentLexeme, parsingData).validate(lexemeIterator, parsingData);
            }
        }

        if (!_(BRACKETS).contains(currentLexeme) &&
            !isEmptyString(expectedValueType) &&
            !lexemeValidator.validate(currentLexeme, parsingData)) {
            return { valid: false };
        }
                
        if (currentLexeme !== ")" && parsingData.openedBrackets > 0 && !lexemeIterator.hasNext()) {
            return { valid: false};
        }
                
        return ValuesInBracketsState.transition(currentLexeme, parsingData).validate(lexemeIterator, parsingData);
    },
    transition:function(currentLexeme:string, parsingData:any) {
                
        if (isNullOrUndefined(parsingData.openedBrackets)) {
            parsingData.openedBrackets = 0;
        }

        if (currentLexeme === "(") {
            parsingData.openedBrackets += 1;
        }

        if (currentLexeme === ")") {
            parsingData.openedBrackets -= 1;
        }
                
        if (parsingData.openedBrackets < 1) {
            return ConditionFinishedState;
        }
                
        return ValuesInBracketsState;
    }
};


export const PropertyOperatorsState = {
    getHints: function(lexemeIterator: any, parsingData: any):string[] {
        const currentLexeme = lexemeIterator.next();
        const nullaryOperators = operatorsProvider.getNullaryOperators(parsingData.propertyType);

        if ((isEmptyString(currentLexeme) || !lexemeIterator.hasNext()) && !parsingData.endsWithWhitespace) {
            return [];
        }

        const hintsResult = PropertyOperatorsState.hints(currentLexeme, parsingData);

        if (_.isUndefined(currentLexeme)) {
            const binaryOperators = operatorsProvider.getBinaryOperators(parsingData.propertyType);
            return binaryOperators.concat(nullaryOperators);
        }

        //go to last one if nullary
        if (_(nullaryOperators).contains(currentLexeme)) {
            return PropertyOperatorsState.finalTransition(currentLexeme, parsingData).getHints(lexemeIterator, parsingData);
        }

        if (hintsResult.length === 0) {
            return [];
        }

        if (!_(hintsResult).contains(currentLexeme)) {
            return hintsResult;
        }

        return PropertyOperatorsState.transition(currentLexeme, parsingData).getHints(lexemeIterator, parsingData);
    },
    validate: function(lexemeIterator: any, parsingData: any): {valid:boolean, message?:string} {
        const currentLexeme = lexemeIterator.next();
        const hintsResult = PropertyOperatorsState.hints(currentLexeme, parsingData);
        const nullaryOperators = operatorsProvider.getNullaryOperators(parsingData.propertyType);

        if (isEmptyString(currentLexeme)) {
            return { valid: false };
        }

        if (!_(hintsResult).contains(currentLexeme)) {
            return { valid: false, message: globalization.getLabelTranslation("#Core/dataSearchUnknownOperator") };
        }

        const operatorData = operatorsProvider.getOperatorDataByTranslationAndType(currentLexeme, parsingData.propertyType) ||
            operatorsProvider.getOperatorDataByTranslation(currentLexeme);

        //go to last one if nullary
        if (_(nullaryOperators).contains(operatorData.Operator)) {
            return PropertyOperatorsState.finalTransition(currentLexeme, parsingData).validate(lexemeIterator, parsingData);
        }

        if ((!lexemeIterator.hasNext() || !operatorData) && operatorData.Arity !== 0) {
            return { valid: false };
        }

        return PropertyOperatorsState.transition(currentLexeme, parsingData).validate(lexemeIterator, parsingData);
    },
    transition: function transition(currentLexeme: string, parsingData: any) {
        const operatorData = operatorsProvider.getOperatorDataByTranslationAndType(currentLexeme, parsingData.propertyType) ||
            operatorsProvider.getOperatorDataByTranslation(currentLexeme);

        parsingData.operator = operatorData;
        return OperatorValuesState;
    },
    finalTransition: function(currentLexeme: string, parsingData: any) {
        const operatorData = operatorsProvider.getOperatorDataByTranslationAndType(currentLexeme, parsingData.propertyType) ||
            operatorsProvider.getOperatorDataByTranslation(currentLexeme);

        parsingData.operator = operatorData;
        return ConditionFinishedState;
    },
    hints: function hints(lexeme: string, parsingData: any) {
        const binaryOperators = operatorsProvider.getBinaryOperators(parsingData.propertyType),
            nullaryOperators = operatorsProvider.getNullaryOperators(parsingData.propertyType);
        return filterHelper.filterOut(binaryOperators.concat(nullaryOperators), lexeme);
    }
};


export const OrderingPropertyState = {
    getHints:function(lexemeIterator: any, parsingData: any):string[] {
        const currentLexeme = lexemeIterator.next();
        
        if (!OrderingPropertyState.isCorrectProperty(parsingData.entityType, currentLexeme)) {
            return getOrderByHints(parsingData.entityType, currentLexeme);
        }

        return OrderingPropertyState.transition().getHints(lexemeIterator, parsingData);
    },
    isCorrectProperty:function(entityType: any, currentLexeme: any) {
        const resolvedProperty = translationsProvider.resolvePropertyFromTranslation(entityType, currentLexeme);
        return propertyHelper.isCorrectOrderingProperty(entityType, resolvedProperty);
    },
    validate:function(lexemeIterator: any, parsingData: any) : {valid:boolean, message?:string}{
        const currentLexeme = lexemeIterator.next();
        
        if (OrderingPropertyState.isCorrectProperty(parsingData.entityType, currentLexeme)) {
            return OrderingPropertyState.transition().validate(lexemeIterator);
        }

    return { valid: false };
    },
    transition:function(){
        return SortingOrderState;
    }
};


export const SortingOrderState = {
    getHints: function(lexemeIterator: any, parsingData: any):string[] {
        const lexeme = lexemeIterator.next();
        const hints = SortingOrderState.hints(lexeme, parsingData);

        if (_(hints).contains(lexeme)) {
            return [];
        }

        return hints;
    },
    validate: function(lexemeIterator: any): {valid:boolean, message?:string} {
        const currentLexeme = lexemeIterator.next();
        const orders = SortingOrderState.hints(currentLexeme);

        //empty "Asc" or "Desc" value after "orderBy" is correct - it will be set by default (Asc)
        if (!_(orders).contains(currentLexeme) && !isEmptyString(currentLexeme)) {
            return { valid: false, message: "Invalid order" };
        }
        
        if (lexemeIterator.hasNext()) {
            return { valid: false };
        }

        return { valid: true };
    },
    hints: function(currentLexeme: any, parsingData?: any) {
        const orders = getTranslatedSortingOrders();

        if (_.isUndefined(currentLexeme)) {
            return orders;
        }

        currentLexeme = currentLexeme.toLowerCase();
        return _.filter(orders, function (op) {
            return op.toLowerCase().includes(currentLexeme);
        });
    },
    transition: function(/*currentLexeme, parsingData*/) {
    return {};
    }
};

export const AfterEntityState = {
    validate: function(lexemeIterator:any, parsingData:any): {valid:boolean, message?:string} {
        const lexeme = lexemeIterator.next();

        if (lexeme === ":") {
            if (_.isUndefined(lexemeIterator.hasNext())) {
                return { valid: false };
            }
            return AfterEntityState.transition(lexeme, parsingData).validate(lexemeIterator, parsingData);
        }

        if (_(operatorsProvider.getSeparators()).contains(lexeme)) {
            if (_.isUndefined(lexemeIterator.current())) {
                return { valid: true };
            }
            return AfterEntityState.transition(lexeme, parsingData).validate(lexemeIterator, parsingData);
        }

        return { valid: false, message: "expected conditions or separator" };
    },
    transition:function (currentLexeme:string, parsingData:any) {
        return (currentLexeme === ":") ?
            ConditionState :
            EntityTypeState;
    },
    hints: function () {
        return [":"];
    },
    getHints: function(lexemeIterator:any, parsingData:any): string[] {
        return [];
    }
};


export const EntityTypeState = {
    validate: function (lexemeIterator:any, parsingData:any): {valid:boolean, message?:string} {
        const currentLexeme = lexemeIterator.next();
        const types = EntityTypeState.hints();
                
        if (_.isUndefined(currentLexeme)) {
            return { valid: false };
        }
                
        const isTypeSubstring = _(types).any(function (type) {
            return type.indexOf(currentLexeme) !== -1;
        });

        if (_(types).contains(currentLexeme)) {
            if (_.isUndefined(lexemeIterator.current())) {
                return { valid: true };
            }
            return EntityTypeState.transition(currentLexeme, parsingData).validate(lexemeIterator, parsingData);
        }
        else if (_.isUndefined(lexemeIterator.current()) && isTypeSubstring) {
            return { valid: false };
        }

        return { valid: false, message: "unknown entity type: " + currentLexeme };
    },

    transition: function (currentLexeme:string, parsingData:any) {
        if (isNullOrUndefined(parsingData.entityType)) {
            parsingData.entityType = typesProvider.getFullTypeName(currentLexeme);
        }
                
        return AfterEntityState;
    },

    hints: function () {
        return typesProvider.getTypesTranslations();
    },
    getHints(lexemeIterator:any, parsingData:any): string[] {
        return [];
    }
};

const SeparatorState = {
    getHints: function (lexemeIterator:any, parsingData:any): string[] {
        const currentLexeme = lexemeIterator.next();

        if (!_.isUndefined(lexemeIterator.current()) || currentLexeme === ";") {
            return SeparatorState.transition(currentLexeme, parsingData).getHints(lexemeIterator, parsingData);
        }
        return [";"];
    },

    validate: function (lexemeIterator:any, parsingData:any): {valid:boolean, message?:string} {
        const currentLexeme = lexemeIterator.next();

        if (currentLexeme === ";") {
            if (_.isUndefined(lexemeIterator.current())) {
                return { valid: true };
            }
            return SeparatorState.transition(currentLexeme, parsingData).validate(lexemeIterator, parsingData);
        }

        return { valid: false, message: "Expected separator after orderBy clause" };
    },

    transition: function (currentLexeme:string, parsingData:any) {
        return EntityTypeState;
    }
};

const orderByOperator = operatorsProvider.getOrderByOperator();

export const EmptyQueryState = {
    getHints: function (lexemeIterator:any, parsingData:any):string[] {
        const lexeme = lexemeIterator.next();

        if (orderByOperator === lexeme) {
            return OrderingPropertyState.getHints(lexemeIterator, parsingData);
        }
                
        lexemeIterator.prev();
                
        if (orderByOperator.indexOf(lexeme) > -1 || isEmptyString(lexeme)) {
            return [].concat(ConditionState.getHints(lexemeIterator, parsingData), [orderByOperator]);
        }
                
        return ConditionState.getHints(lexemeIterator, parsingData);
    },
            
    validate: function (lexemeIterator:any, parsingData:any):{valid:boolean, message?:string} {
        const lexeme = lexemeIterator.next();
                
        if (orderByOperator === lexeme) {
            return OrderingPropertyState.validate(lexemeIterator, parsingData);
        }
                
        lexemeIterator.prev();
        return ConditionState.validate(lexemeIterator, parsingData);
    },
    transition: function() {
        return {};
    }
};