/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/inbox/taskGroup/dataSource" />

import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as _ from "underscore";
import * as ko from "knockout";
import DefaultDataSource = require("Core/Medius.Core.Web/Scripts/Medius/components/grid/dataSource/default");
import { TaskGroup } from "./group";
import { IconToggleSparkleFill } from "@medius/ui-controls";
import { GridHeadCell } from "Core/Medius.Core.Web/Scripts/Medius/components/grid/default/gridHeadCell";

class TaskGroupDataSource extends DefaultDataSource {
    taskGroup: TaskGroup;
    initLoad: boolean;

    constructor(taskGroup: TaskGroup) {
        super();

        this.initLoad = false;
        this.taskGroup = taskGroup;
    }

    loadColumns() {
        const columns = [];

        columns.push({
            Sortable: false,
            Searchable: false,
            Resizable: false,
            Stretch: false,
            Selectable: false,
            ValueSource: "",
            Width: 32,
            GridHeadCell: this.createGridHeadCellComponent()
        });

        if (this.taskGroup.showLabelColumn) {
            columns.push({
                Sortable: false,
                Searchable: false,
                Resizable: false,
                Stretch: false,
                Name: getLabelTranslation("#Core/labels"),
                ValueSource: "Labels",
                VisibleStorageId: this.getStorageId(this.taskGroup.storageId, "Labels", "visibility"),
                Width: 85,
                GridHeadCell: this.createGridHeadCellComponent({
                    Name: getLabelTranslation("#Core/labels")
                })
            });
        }

        if (this.taskGroup.showIndicatorsColumn) {
            columns.push({
                Sortable: false,
                Searchable: false,
                Resizable: true,
                Stretch: false,
                Name: getLabelTranslation("#Core/inboxIndicatorsColumnName"),
                ValueSource: "Indicators",
                VisibleStorageId: this.getStorageId(this.taskGroup.storageId, "Indicators", "visibility"),
                Width: 80,
                GridHeadCell: this.createGridHeadCellComponent({
                    Name: getLabelTranslation("#Core/inboxIndicatorsColumnName")
                })
            });
        }

        if (this.taskGroup.showCodingSuggestionInvoiceConfidenceColumn) {

            columns.push({
                Sortable: true,
                Searchable: false,
                Resizable: true,
                Stretch: false,
                Name: getLabelTranslation("#Core/codingSuggestionConfidence"),
                Icon: IconToggleSparkleFill,
                ValueSource: "Document.CodingSuggestionConfidence",
                OrderBySource: "Document.CodingSuggestionConfidence",
                VisibleStorageId: this.getStorageId(this.taskGroup.storageId, "Document.CodingSuggestionConfidence", "visibility"),
                Width: 40,
                GridHeadCell: this.createGridHeadCellComponent({
                    Name: getLabelTranslation("#Core/codingSuggestionConfidence"),
                    Icon: IconToggleSparkleFill
                })
            });
        }

        columns.push({
            Sortable: true,
            Searchable: false,
            Name: getLabelTranslation("#Core/task"),
            ValueSource: "TaskDescription",
            OrderBySource: "TaskDescription",
            VisibleStorageId: this.getStorageId(this.taskGroup.storageId, "TaskDescription", "visibility"),
            WidthStorageId: this.getStorageId(this.taskGroup.storageId, "TaskDescription", "width"),
            GridHeadCell: this.createGridHeadCellComponent({
                Name: getLabelTranslation("#Core/task")
            })
        });

        _(this.taskGroup.columns().filter((column: any) => {
            return column.SourceKey !== "CodingSuggestionConfidence";
        })).each((column: any) => {
            let orderByPath = null;
            const valueSource = ["Document", column.SourceKey].join("."),
                visibleStorageId = this.getStorageId(this.taskGroup.storageId, valueSource, "visibility"),
                widthStorageId = this.getStorageId(this.taskGroup.storageId, valueSource, "width");

            if (column.OrderByKey) {
                orderByPath = ["Document", column.OrderByKey].join(".");
            }

            columns.push({
                Sortable: column.Sortable,
                Searchable: false,
                Name: column.Name,
                ValueSource: valueSource,
                OrderBySource: orderByPath,
                VisibleStorageId: visibleStorageId,
                WidthStorageId: widthStorageId,
                GridHeadCell: this.createGridHeadCellComponent({
                    Name: column.Name
                })
            });
        });
        return $.Deferred().resolve(columns);
    }

    private getStorageId(...parts: string[]) {
        return parts.join(":");
    }

    private createGridHeadCellComponent(props?: { Name?: string; Icon?: React.FC }) {
        return ko.pureComputed(() =>
            ({
                functionComponent: GridHeadCell,
                props: props
            })
        );
    }

    load(dataQuery: any) {
        const deferred = $.Deferred();

        if (!this.initLoad) {
            this.initLoad = true;
            return deferred.resolve(this.taskGroup.tasks());
        }

        this.taskGroup.loadTasks(dataQuery).done(() => {
            deferred.resolve(this.taskGroup.tasks());
        });

        return deferred;
    }

    getTotalRows() {
        return $.Deferred<number>().resolve(this.taskGroup.totalTasks());
    }

    isDocumentIdColumn(column: any) {
        return column.ValueSource === 'Document.Id';
    }

    isTaskDescriptionColumn(column: any) {
        return column.ValueSource === 'TaskDescription';
    }

    isRegularColumn(column: any) {
        return !this.isDocumentIdColumn(column) && !this.isTaskDescriptionColumn(column);
    }
}

export function create(taskGroup: TaskGroup) {
    return new TaskGroupDataSource(taskGroup);
}
