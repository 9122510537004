///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Entities.Dashboard.Gadget/AccessControlTab"/>
import * as _ from "underscore";
import * as ko from "knockout";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as notification from "Core/Medius.Core.Web/Scripts/Medius/core/notification";

class AccessControlTab {
    Role: ko.Observable<any>;
    User: ko.Observable<any>;
    Gadget: ko.Observable<any>;
    isAccessRestrictedSub: any;
    constructor(entityWithContext:any) {
        this.Role = ko.observable();
        this.User = ko.observable();

        this.Gadget = ko.observable(entityWithContext.Entity);

        this.isAccessRestrictedSub = this.Gadget().IsAccessRestricted.subscribe((value:boolean) => {
            if (value === false) {
                this.Gadget().Users([]);
                this.Gadget().Roles([]);
            }
        });
    }
    removeUser(userToRemove:any) {
        this.Gadget().Users.remove(userToRemove);
    }
    removeRole(roleToRemove:any) {
        this.Gadget().Roles.remove(roleToRemove);
    }
    addRow(collectionToUpdate:ko.ObservableArray<any>, itemToAdd:any, errorMessage:any) {
        if (!_(collectionToUpdate()).find((u) => {
            return ko.utils.unwrapObservable(u.Id) === ko.utils.unwrapObservable(itemToAdd().Id);
        })) {
            collectionToUpdate.push(itemToAdd());
        } else {
            notification.error(globalization.getLabelTranslation(errorMessage));
        }
        itemToAdd(null);
    }
    addUser() {
        this.addRow(this.Gadget().Users, this.User, "#Core/notifySelectedUserIsAlreadyAdded");
    }
    addRole() {
        this.addRow(this.Gadget().Roles, this.Role, "#Core/notifySelectedRoleIsAlreadyAdded");
    }
    dispose() {
        this.Role(null);
        this.User(null);
        this.Gadget(null);
        this.isAccessRestrictedSub.dispose();
    }
}

export = function (entityWithContext:any) {
    return new AccessControlTab(entityWithContext);
};