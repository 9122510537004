///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/core/das"/>
import * as $logger from "Core/Medius.Core.Web/Scripts/Medius/lib/logger";
import * as type from "Core/Medius.Core.Web/Scripts/Medius/core/type";
import * as rest from "Core/Medius.Core.Web/Scripts/Medius/core/rest";
import * as _ from "underscore";
import { isNullOrUndefined } from "../../lib/underscoreHelpers";

export const levels = {
    Edit: "Edit",
    Display: "Display",
    Hide: "Hide"
};

let schemas:any = null;

export const preload = () => {
    rest.get("DataAccessSchemaManager", "schemas", { async: false })
        .done((data:any) => {
            schemas = data;
        })
        .fail(() => {
            $logger.error("medius.das: Error occurred during schemas preload" +
                          " operation.");
        });
};

export const resolveAccess = (path: string, entityType: string, activityId: string | number, companyContextId: string | number) => {
    if (!path || !entityType) {
         return null;
    }

    activityId = activityId || 0;
    companyContextId = companyContextId || 0;
    entityType = type.getTypeName(entityType);
    const key = entityType + "," + activityId + "," + companyContextId;
    const defaultKey = entityType + ",0," + companyContextId;
    let access:any;

    try {
        path = path.split(".")[0];
        access = null;

        if (!isNullOrUndefined(schemas)) {
            if (!_.isUndefined(schemas[key])) {
                access = schemas[key][path];
            } else if (!_.isUndefined(schemas[defaultKey])) {
                access = schemas[defaultKey][path];
            }
        }

        if (isNullOrUndefined(access)) {
            access = setToDefault(path, entityType, activityId, companyContextId);
        }
    }
    catch (e) {
        access = setToDefault(path, entityType, activityId, companyContextId);
    }

    return access;
};

const setToDefault = (path: any, entityType: any, activityId: any, companyContextId: any) => {
    const message = `medius.das: access setting for property '${path}' in ${entityType} ` +
    `does not exist in schema regarding activityId: ${activityId}, ` +
    `companyId: ${companyContextId}`;
    $logger.warn(message);

    return levels.Edit;
};