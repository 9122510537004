/// <amd-module name="Core/Medius.Core.Web/Scripts/Models/FinanceComponentDto/quantityFactory"/>
import { subscribable, observable } from "knockout";
import * as settingsProvider from "Core/Medius.Core.Web/Scripts/Medius/core/settingsProvider";

const quantityType = "Medius.Core.DTOs.QuantityDto, Medius.Core.Common";

function setDefaultUnitIn(quantity: any) {
    const unit = settingsProvider.getDefaultUnit();
    quantity.UnitDescription = unit.Description;
    quantity.UnitId = unit.Id;
}

class Quantity {
    private _subscribable: ko.Subscribable<unknown>;
    $type = quantityType;
    DisplayValue: ko.Observable<number>;
    UnitDescription: any;
    UnitId: any;
    constructor(data: any) {
        if (data.DisplayValue === undefined || data.DisplayValue === null)
            throw new Error("DisplayValue is required.");

        this._subscribable = new subscribable();

        // TODO : There is plan to remove observable from DisplayValue...
        this.DisplayValue = observable(parseFloat(data.DisplayValue));
        this.UnitDescription = data.UnitDescription;
        // TODO : There is plan to remove UnitId...
        this.UnitId = data.UnitId;

        if (!this.UnitDescription)
            setDefaultUnitIn(this);
    }

    toJS() {

        return {
            $type: this.$type,
            DisplayValue: this.DisplayValue() && this.DisplayValue().toString(),
            UnitDescription: this.UnitDescription,
            UnitId: this.UnitId
        };
    }

    update(data: any) {
        let updated: boolean, amountValue: any;

        if (data.DisplayValue !== undefined) {
            if (data.DisplayValue !== null) {
                amountValue = parseFloat(data.DisplayValue);
            }

            if (this.DisplayValue() !== amountValue) {
                this.DisplayValue(amountValue);
                updated = true;
            }
        }

        if (data.UnitDescription !== undefined) {
            if (this.UnitDescription !== data.UnitDescription) {
                this.UnitDescription = data.UnitDescription;
                this.UnitId = data.UnitId;

                if (!this.UnitDescription)
                    setDefaultUnitIn(this);

                updated = true;
            }
        }

        if (updated)
            this._subscribable.notifySubscribers({ value: this });

        return updated;
    }

    onUpdated(callback: any) {
        return this._subscribable.subscribe(callback);
    }
}

export function create(data: any) {
    return new Quantity(data);
}

export function creatingType() {
    return quantityType;
}

export function zero() {
    return new Quantity({
        DisplayValue: 0
    });
}

export function instanceOf(vm: any) {
    return vm instanceof Quantity;
}
