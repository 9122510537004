///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/localizer/type/date" />

import { Globalize } from "Core/Medius.Core.Web/Scripts/Medius/lib/globalize";
import * as $ from "jquery";
import { getCode } from "Core/Medius.Core.Web/Scripts/Medius/lib/culture";
import * as dateUtils from "Core/Medius.Core.Web/Scripts/Medius/lib/utils/date";

export function fromString(date: string, options: any) {
    const format = options.dateFormat;

    if (!format)
        return null;

    if (typeof date !== "string")
        return date; 

    date = $.trim(date);
    if (!date.length)
        return null;

    const dateParsed = Globalize.parseDate(date, format, getCode());
    if (dateParsed === null) {
        // The undefined is returned to indicate that data cannot be parsed.
        // The null value is accepted by date validator, undefined is not.
        return undefined;
    }

    return dateUtils.setLocal(dateParsed);
}

export function toString(value: any, options: any) {
    const format = options.dateFormat || "d";
    let date = value;

    if (!(date instanceof Date))
        date = fromString(date, { dateFormat: format });

    if (!date)
        return null;

    return Globalize.format(date, format, getCode());
}