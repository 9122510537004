///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/mapping/resolver"/>
import * as ko from "knockout";
import mappings = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/mapping/collection/instance");
import * as defaultMapping from "Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/mapping/default";

export function resolve(data:any, context:any) {
    let currentMapping = {};
    const type = data && ko.utils.unwrapObservable(data.$type);

    if (type) {
        currentMapping = mappings.resolve(type, context, data);
        if (currentMapping) {
            return {...defaultMapping, ...currentMapping};
        }
    }
    return defaultMapping;
}