/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/dashboard/spa"/>
import { once } from "underscore";
import { applications } from "Core/Medius.Core.Web/Scripts/Medius/apps/spaManager/applications";
import { renderTemplate } from "Core/Medius.Core.Web/Scripts/Medius/apps/utils";
import * as route from "Core/Medius.Core.Web/Scripts/Medius/apps/spaManager/route";
import * as framework from "./framework";

class DashboardApp {
    public containerId: string;
    public app: any;
    public routes: any[];
    public initialize: () => void;

    public name = applications.dashboard;

    constructor(spaManager: any, containerId: string) {

        this.containerId = containerId;

        this.app = framework.create({ containerId: "dashboard-framework" }, spaManager);
        const routeHandlers = {
            routeHandler: () => {
                spaManager.openApp(this);
                this.app.onOpenDashboard();
            },

            routeHandlerTask: (taskId: string, fromApp?: string, fromRoute?: string) => {
                spaManager.switchApp(this);
                this.app.onOpenDashboardWithTaskContext(taskId, fromApp, fromRoute);
            },

            routeHandlerDashboard: (dashboardId: string) => {
                spaManager.openApp(this);
                this.app.onOpenDashboard(dashboardId);
            }
        };

        this.routes = [
            route.create("Dashboard", routeHandlers.routeHandler),
            route.create("Dashboard/", routeHandlers.routeHandler),
            route.create("Dashboard/:id", routeHandlers.routeHandlerDashboard),
            route.create("Dashboard/Task/:id", routeHandlers.routeHandlerTask),
            route.create("Dashboard/Task/:id/", routeHandlers.routeHandlerTask),
            route.create("Dashboard/Task/:id/:fromApp/:fromRoute", routeHandlers.routeHandlerTask),
            route.create("Dashboard/Task/:id/:fromApp/:fromRoute/", routeHandlers.routeHandlerTask)
        ];

        this.initialize = once(() => {
        });

    }

}

export function create(spaManager: any, containerId: string) {
    return new DashboardApp(spaManager, containerId);
}

export function getView() {
    return renderTemplate("Core:Medius/apps/dashboard/templates/Dashboard.html");
}
