///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/grid/bindings/entityGrid"/>
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as grid from "Core/Medius.Core.Web/Scripts/Medius/components/grid/entity/grid";
import * as ko from "knockout";

const gridElementKey = "medius-grid";

function parseBinding(bindingAccessor:any) {
    const binding = bindingAccessor(); 

    return {
        type: koUtils.unpack(binding.type) || null,
        options: koUtils.unpack(binding.options) || {}
    };
}

const entityGrid = {
    init: function (element:any, bindingAccessor:any, allBindingsAccessor:any, viewModel:any, bindingContext:any) {
        const binding = parseBinding(bindingAccessor);
        const options = binding.options;
        const type = binding.type;
        const current = koUtils.domData.get(element, gridElementKey) || {};

        if (current.type) {
            current.disposal();
            ko.utils.domNodeDisposal.removeDisposeCallback(current.disposal, undefined);
        }

        if (!type) {
            throw new Error("entityGrid: Can't initialize EntityGrid, no entity type provided.");
        }

        let instance = grid.create(type, options);
        let newContext = bindingContext.createChildContext(instance);
        newContext.$grid = instance;
        newContext.$viewModel = viewModel;
        
        instance.preload().done(function () {
            ko.renderTemplate(instance.options.template, newContext, {}, element, "replaceChildren");
        });

        let disposal = function () {
            koUtils.domData.set(element, gridElementKey, null);
            instance.destroy();
            instance = null;
            bindingContext.$root.draggedGrid = null;
            newContext.$grid = null;
            newContext.$viewModel = null;
            newContext = null;
            disposal = null;
        };
        ko.utils.domNodeDisposal.addDisposeCallback(element, disposal);

        koUtils.domData.set(element, gridElementKey, {
            type: type,
            disposal: disposal
        });

        return { controlsDescendantBindings: true };
    },
    update: function (element:any, bindingAccessor:any) {
        const binding = parseBinding(bindingAccessor),
            type = binding.type,
            current = koUtils.domData.get(element, gridElementKey) || {};

        if (type && type !== current.type) {
            ko.bindingHandlers.entityGrid.init(element, bindingAccessor, undefined, undefined, undefined);
        }
    }
};

export function register() {
    koUtils.registerBinding("entityGrid", entityGrid);
}