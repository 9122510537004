/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/sox/soxReportManager"/>
import {observable, computed} from "knockout";
import * as restJson from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rest";
import * as notification from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import * as backendErrorHandler from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import {SoxReport} from "./soxReport";
import * as savedReports from "./savedSoxReports";
import pendingExports = require("Core/Medius.Core.Web/Scripts/Medius/core/export/async/pending");

class SoxReportManager {
    public soxReportEnabled = observable(true);
    public CurrentReportModel = new SoxReport();
    public SavedReportsModel = savedReports.create(this.CurrentReportModel);
    public IsFormValid = computed(() => this.CurrentReportModel.SelectedReportDescription() !== null);

    public isTackedFilter(dataObject: any) {
        return dataObject.IsTracked;
    }

    public generateSoxReport() {
        const dto = this.CurrentReportModel.getSoxReportRequestData();

        if (this.CurrentReportModel.validate()) {
            this.soxReportEnabled = observable(false);

            (pendingExports as any).wrapAsyncExportTask(
                restJson.post("SoxReportManager", "soxReport", dto), (xhr: any) => {
                    if (xhr.status >= 400 && xhr.status < 500) {
                        notification.error(JSON.parse(xhr.responseText).Message);
                    } else {
                        backendErrorHandler.handleAnyError(xhr);
                    }
            }).always(() => {
                this.soxReportEnabled(true);
            });
        }
    }

    public dispose() {
        this.IsFormValid.dispose();
    }
}

export function create() {
    return new SoxReportManager();
}
