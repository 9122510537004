/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/kendo/bindings/kendoNumericTextBox" />

import { KendoUtils } from "Core/Medius.Core.Web/Scripts/Medius/kendo/kendoUtils";
import { registerBinding } from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";

interface IKendoNumericTextBoxOptions {

    value: ko.Observable<number>;
    validator: ko.Observable<KendoValidator>;
}

class KendoNumericTextBox {
    private static readonly widgetName = "kendoNumericTextBox";

    public static registerKnockoutBinding(): void {
        registerBinding(
            KendoNumericTextBox.widgetName, {
                init(element: Element, valueAccessor: any) {
                    const $element = $(element);

                    KendoUtils.ensureValidElement(KendoNumericTextBox.widgetName, "input", $element);
                    KendoNumericTextBox.create($element, valueAccessor());
                    KendoUtils.registerDisposeFor($element, KendoNumericTextBox.widgetName);
                },
                update(element: Element, valueAccessor: any) {
                    const options = valueAccessor() as IKendoNumericTextBoxOptions;
                    const $element = $(element);
                    const selectedValue = options.value();
                    const validator = options.validator();

                    KendoNumericTextBox.validate($element, selectedValue, validator);
                    $element.data(KendoNumericTextBox.widgetName)
                        .value(selectedValue);
                }
            });
    }

    private static create($element: JQuery, params: IKendoNumericTextBoxOptions) {
        if (params.validator)
            params.validator({ validate: () => KendoNumericTextBox.validate($element, params.value(), params.validator()) });

        const options: kendo.ui.NumericTextBoxOptions = {
            change: (e: kendo.ui.NumericTextBoxChangeEvent) => {
                const selectedValue = e.sender.value();
                KendoNumericTextBox.validate($element, selectedValue, params.validator());
                params.value(selectedValue);
            },
            decimals: 0,
            format: "0"
        };

        KendoUtils.dispose($element, KendoNumericTextBox.widgetName);
        $element.kendoNumericTextBox(options);
    }

    private static validate(element: JQuery, value: number, validator: KendoValidator): boolean {
        if (!validator)
            return true;

        const numericTextBox = KendoNumericTextBox.get(element);
        if (value) {
            numericTextBox.removeClass("invalid");
            return true;
        }
        else {
            numericTextBox.addClass("invalid");
            return false;
        }
    }

    private static get(element: JQuery): JQuery {
        const numericTextBox = element.parent();
        if (numericTextBox.length === 0)
            throw new Error("Could not find Kendo numeric text box.");
        return numericTextBox;
    }
}

export function register() {
    KendoNumericTextBox.registerKnockoutBinding();
}
