/// <amd-module name="Core/Medius.Core.Web/Scripts/lib/labelHelper"/>
function hexToRgb(hex: string) {
    const result = getHexRegex(hex).exec(hex);
    return result ? { 
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16) 
    } : null;
}

export function getLabelTextColor(hex: string) {
    if (!hex) {
        return "#000000";
    } 
    const rgb = hexToRgb(hex);
    const  luminance = (0.2126 * rgb.r + 0.7152 * rgb.g + 0.0722 * rgb.b);
    return (luminance < 140) ? "var(--md-color-text-light)" : "var(--md-color-text-primary)";
}

function getHexRegex(hex: string) {
    if(hex.length === 4) {
        return /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    }
    if(hex.length === 7) {
        return /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;
    }
    throw new Error("Hex needs to be of shape either #fff or #ffffff");
}
