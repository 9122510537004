///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/configurationProperties/baseCollection"/>
import base = require("Core/Medius.Core.Web/Scripts/Medius/components/configurationProperties/base");
import * as grid from "Core/Medius.Core.Web/Scripts/Medius/components/grid/default/grid";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as _ from "underscore";
import CollectionDataSource = require("Core/Medius.Core.Web/Scripts/Medius/components/configurationProperties/collectionPropertyDataSource");
import * as accessLevels from "Core/Medius.Core.Web/Scripts/Medius/components/components/accessControl/values";
import * as ko from "knockout";
export = class ConfigurationProperty extends base{
    Value: ko.Computed<any>;
    collectionGrid: any;
    
    constructor(params:any) {
        super(params);

        this.Value = ko.computed(this.getValue, this);
        this.disposables.push(this.Value);
    }

    addItem(item: any) {
        if (!item) {
            return;
        }
        const wrappedItem = this.wrapCurrentItemWithMetadata(item);
        wrappedItem.IsNew = true;
        this.CurrentValue.unshift(item);
        if (this.collectionGrid) {
            this.collectionGrid.addRow(wrappedItem);
        }
        this.changed();
    }

    checkIfIsInherited() {
        return _.isEmpty(this.CurrentValue()) === false;
    }

    createGrid() {
        const options = this.getGridOptions(),
            dataSource = new CollectionDataSource(this.Value, this.getColumns());

        return grid.create(dataSource, options);
    }

    createViewModel(data: any) {
        return _.map(data, function (item) {
            return this.Context.create(item);
        }, this);
    }

    getCurrentItemsWithMetadata() {
        return _.map(this.CurrentValue(), this.wrapCurrentItemWithMetadata, this);
    }

    getInheritedItemsWithMetadata() {
        return _.map(this.InheritedValue(), this.wrapInheritedItemWithMetadata, this);
    }

    getEmptyValue(): any[] {
        return [];
    }

    getGridOptions():any {
        return {
            tplRow: this.getRowTemplate(),
            pageSize: 20,
            onRemoveRow: this.removeItem.bind(this)
        };
    }

    isDefinedInCurrentCompany() {
        const currentValue = this.CurrentValue();
        return currentValue && currentValue.length > 0;
    }

    removeItem(item: { Data: any; }) {
        this.CurrentValue.remove(item.Data);

        this.changed();
    }

    wrapInheritedValue(data: unknown[]) {
        return ko.observableArray(data);
    }

    wrapCurrentItemWithMetadata(item: any) {
        return {
            Data: item,
            Metadata: {
                IsOverriden: true,
                OriginCompanyName: globalization.getLabelTranslation("#Core/currentConfigurationProperty")
            },
            AccessLevel: function () {
                return accessLevels.edit;
            },
            Disabled: false,
            IsNew: undefined as any
        };
    }

    wrapInheritedItemWithMetadata(item: any, index: string | number) {
        return {
            Data: item,
            Metadata: this.Metadata.ItemsDescription[index],
            AccessLevel: function () {
                return accessLevels.view;
            },
            Disabled: true
        };
    }

    getValue() {
        throw new Error("Please define getValue in " + this.constructor.name + " prototype");
    }

    getColumns() {
        throw new Error("Please define getColumns in " + this.constructor.name + " prototype");
    }

    getRowTemplate() {
        throw new Error("Please define getRowTemplate in " + this.constructor.name + " prototype");
    }
};