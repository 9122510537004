/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/ImportManagement/masterDataErrorsState"/>
import { MasterDataErrorDto, ErrorItem } from "./interfaces";
const LOAD_DATA = 'LOAD_DATA';
const SELECT_ROW = 'SELECT_ROW';
const SELECT_ALL = 'SELECT_ALL';
const EXPAND_ROW = 'EXPAND_ROW';

interface LoadDataAction {
    type: typeof LOAD_DATA;
    masterDataErrors: MasterDataErrorDto[];
    total: number;
}

interface SelectRowAction {
    type: typeof SELECT_ROW;
    itemId: number;
}

interface SelectAllAction {
    type: typeof SELECT_ALL;
    checked: boolean;
}

interface ExpandRowAction {
    type: typeof EXPAND_ROW;
    itemId: number;
}

type MasterDataErrorAction = LoadDataAction | SelectAllAction | SelectRowAction| ExpandRowAction;

function getErrorItem(errorDto: MasterDataErrorDto, selectedIds: number[]): ErrorItem {
    return { ...errorDto, selected: selectedIds.includes(errorDto.id), expanded: false };
}

export interface MasterDataErrorState {
    data: ErrorItem[];
    total: number;
    allSelectedIds: number[];
}

export function masterDataErrorsReducer(state: MasterDataErrorState, action: MasterDataErrorAction) {
    switch(action.type) {
        case LOAD_DATA: {
            return {
                data: action.masterDataErrors.map(x => getErrorItem(x, state.allSelectedIds)),
                total: action.total,
                allSelectedIds: state.allSelectedIds
            };
        }
        case SELECT_ALL: {
            const notCurrentPageSelectedIds = state.allSelectedIds.filter(x => !state.data.map(item => item.id).includes(x));
            const allSelectedIds = action.checked ? [...notCurrentPageSelectedIds, ...state.data.map(item => item.id)] : [...notCurrentPageSelectedIds];
            return {
                ...state,
                data: state.data.map(item => ({...item, selected: action.checked})),
                allSelectedIds
            };
        }
        case SELECT_ROW: {
            const data = state.data.map(item => ({...item, selected: item.id === action.itemId ? !item.selected : item.selected }));
            const {selected, id} = data.filter(x => x.id === action.itemId)[0];
            return {
                ...state,
                data,
                allSelectedIds: selected ? [...state.allSelectedIds, id] : state.allSelectedIds.filter(x => x !== id)
            };
        }
        case EXPAND_ROW: {
            return {
                ...state,
                data: state.data.map(item => ({...item, expanded: item.id === action.itemId ? !item.expanded : item.expanded }))
            };
        }
        default: 
            throw new Error();
    }
}
