///<amd-module name = "Core/Medius.Core.Web/Scripts/Models/Entities/Customfields/CustomFieldsPerspective"/>
import * as ko from "knockout";

export const create = (): {Configuration:ko.Observable<any>, AreAnyFieldsActive:ko.Observable<boolean>, ValidationErrors:any} => {
    return {
        "Configuration": ko.observable({
            "EntityType": null,
            "TextCustomField1": createTextCustomFieldConfiguration(),
            "TextCustomField2": createTextCustomFieldConfiguration(),
            "TextCustomField3": createTextCustomFieldConfiguration(),
            "TextCustomField4": createTextCustomFieldConfiguration(),
            "TextCustomField5": createTextCustomFieldConfiguration(),
            "NumericCustomField1": createCustomFieldConfiguration(),
            "NumericCustomField2": createCustomFieldConfiguration(),
            "NumericCustomField3": createCustomFieldConfiguration(),
            "NumericCustomField4": createCustomFieldConfiguration(),
            "NumericCustomField5": createCustomFieldConfiguration(),
            "BooleanCustomField1": createCustomFieldConfiguration(),
            "BooleanCustomField2": createCustomFieldConfiguration(),
            "BooleanCustomField3": createCustomFieldConfiguration(),
            "BooleanCustomField4": createCustomFieldConfiguration(),
            "BooleanCustomField5": createCustomFieldConfiguration(),
            "DateTimeCustomField1": createCustomFieldConfiguration(),
            "DateTimeCustomField2": createCustomFieldConfiguration(),
            "DateTimeCustomField3": createCustomFieldConfiguration(),
            "DateTimeCustomField4": createCustomFieldConfiguration(),
            "DateTimeCustomField5": createCustomFieldConfiguration(),
        }),
        "AreAnyFieldsActive": ko.observable(false),
        "ValidationErrors": null
    };
};

function createCustomFieldConfiguration() {
    return {
        "Mandatory": ko.observable(false),
        "IsActive": ko.observable(false),
        "DisableIntegrationUpdates": ko.observable(false),
        "Name": ko.observable("")
    };
}

function createTextCustomFieldConfiguration() {
    const config:any = createCustomFieldConfiguration();
    config["MaximumLength"] = null;
    return ko.observable(config);
}