///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/transformers/serialize"/>
import * as ko from "knockout";
import * as helpers from "Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/helpers";
import arraySerializer = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/transformers/serialize/array");
import objectSerializer = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/transformers/serialize/object");
import toJSserializer = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/transformers/serialize/toJS");
import viewmodelSerializer = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/transformers/serialize/viewmodel");

function serializeViewmodelFactory() {
    let currentSerializer:any;
    return {
        topDown: function (args:any) {
            const data = args.data;
            const context = args.context;

            currentSerializer = arraySerializer(data) ||
                toJSserializer(data, context) ||
                viewmodelSerializer(data) ||
                objectSerializer(data);

            if (!currentSerializer.shouldDescend) {
                args.stopDescend();
            }
        },
        beforeDescend: function(args:any) {
            if (!currentSerializer.shouldDescendProperty(args.propertyName)) {
                args.stopPropertyDescend();
            }
        },
        afterDescend: function (args:any) {
            const value = ko.utils.unwrapObservable(args.propertyValue);
            currentSerializer.mapProperty(args.propertyName, value);
        },
        bottomUp: function (args:any) {
            const value = currentSerializer.finalize();
            args.data = value;
        }
    };
}

function skipTransformerFactory() {
    return {
        topDown: function(args:any) {
            args.data = undefined;
            args.stopDescend();
        }
    };
}

function unwrapTransformerFactory() {
    return {
        topDown: function(args:any) {
            args.data = args.accessor ? ko.utils.unwrapObservable(args.accessor) : args.data;
        }
    };
}

export = function serializeTransformerFactory() {
    const unwrap = unwrapTransformerFactory();
    const skip = skipTransformerFactory();
    const serializer = serializeViewmodelFactory();
    
    interface Transformer {
        topDown?: (args: any) => void;
        bottomUp?: (args: any) => void;
        beforeDescend?: (args: any) => void;
        afterDescend?: (args: any) => void;
    }

    function getTransformer(args:any):Transformer {
        const data = ko.utils.unwrapObservable(args.accessor) || args.data;

        switch (typeof(data)) {
            case "number":
            case "boolean":
            case "string":
                return unwrap;

            case "object":
                if (data === null || helpers.isDate(data)) {
                    return unwrap;
                }
                return serializer;
            default:
                return skip;
        }
    }

    function invokeCurrentTransformer(method: keyof Transformer, args:any) {
        const currentTransformer = getTransformer(args);

        if (currentTransformer[method]) {
            currentTransformer[method](args);
        }
    }

    return {
        topDown: function (args:any) {
            invokeCurrentTransformer("topDown", args);
        },
        beforeDescend: function (args:any) {
            if (args.propertyName === "__metadata__") {
                args.stopPropertyDescend();
            }
            invokeCurrentTransformer("beforeDescend", args);
        },
        afterDescend: function (args:any) {
            invokeCurrentTransformer("afterDescend", args);
        },
        bottomUp: function (args:any) {
            invokeCurrentTransformer("bottomUp", args);
        }
    };

};