///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/parser/lexeme"/>
import * as lexemeFilter from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/lexeme/filter";
import { isEmptyString } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import * as _ from "underscore";

export function process(input:string) {
    let lexemes = this.lex(input);
    lexemes = lexemeFilter.filter(lexemes);
    return lexemes;
}

export function lex(input:string) {
    let lexemes: string[] = [];
    let currentIndex = 0;
    let quoteIndex:number;
    let splittingEnd:number;
    let toSplitting:string;
    let quotedStatement:string;
    
    if (isEmptyString(input)) {
        return [];
    }
    do {
        quoteIndex = this.getNextQuoteIndex(input, currentIndex);
        splittingEnd = quoteIndex !== -1 ? quoteIndex : input.length;
        toSplitting = input.substring(currentIndex, splittingEnd);
        lexemes = lexemes.concat(this.splitIgnoringEmptyLexemes(toSplitting));
        if (quoteIndex !== -1) {
            quotedStatement = this.getStatementInQuotes(input, quoteIndex);
            lexemes.push(quotedStatement);
            currentIndex = quoteIndex + quotedStatement.length;
        } else {
            currentIndex = input.length;
        }
        
    } while (currentIndex < input.length);
    return lexemes;
}

export function getNextQuoteIndex(input:string, currentIndex:number) {
    const singleQuoteIndex = input.indexOf('\'', currentIndex);
    const doubleQuoteIndex = input.indexOf('"', currentIndex);
    let quoteIndex:number;
    
    if (singleQuoteIndex > -1 && doubleQuoteIndex > -1) {
        quoteIndex = Math.min(singleQuoteIndex, doubleQuoteIndex);
    } else {
        quoteIndex = Math.max(singleQuoteIndex, doubleQuoteIndex);
    }
    return quoteIndex;
}

export function getStatementInQuotes(input:string, openingQuoteIndex:number) {
    const closingQuoteIndex = this.matchQuotes(input, openingQuoteIndex);
    if (closingQuoteIndex === -1) {
        return input.substring(openingQuoteIndex);
    }
    return input.substring(openingQuoteIndex, closingQuoteIndex + 1);
}

export function matchQuotes(input:string, openingQuotationIndex:number) {
    const quotationSign = input[openingQuotationIndex];
    const index = openingQuotationIndex + 1;
    const matchedIndex = input.indexOf(quotationSign, index);
    return matchedIndex;
}

export function splitIgnoringEmptyLexemes(input:string) {
    const formattedInput = this.formatInput(input);
    const lexemes = _.chain(formattedInput.split(/ +/))
        .reject(function (lexeme) {
            return isEmptyString(lexeme);
        })
        .value();
    return lexemes;
}

export function formatInput(input:string) {
    input = input.replace(/\(/g, " ( ");
    input = input.replace(/\)/g, " ) ");
    input = input.replace(/<=/g, " <= ");
    input = input.replace(/>=/g, " >= ");
    input = input.replace(/!=/g, " != ");
    input = input.replace("==", " == ");
    input = input.replace(/([^!<>=])=/g, "$1 = ");
    input = input.replace(/,/g, " , ");
    input = input.replace(/;/g, " ; ");
    input = input.replace(/:/g, " : ");
    input = input.replace(/\./g, " . ");
    return input;
}