///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/knockout/disposal"/>
export function tryDispose(o:any) {
    switch (typeof o) {
        case "function":
            return o();
        case "object":
            if (o && o.dispose) {
                return o.dispose();
            }
            break;
        default:
            break;
    }
    return null;
}