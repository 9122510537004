/// <amd-module name="Core/Medius.Core.Web/Scripts/components/procurement/resolutionConfiguration/resolutionConfigurationSlice"/>
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "Core/Medius.Core.Web/Scripts/shared/store/reduxStore";
import { ResolutionConfiguration, ResolutionConfigurationDto } from "./resolutionConfiguration";

const initialState: ResolutionConfiguration = {
    resolutionConfigurations: []
};

export const resolutionConfigurationSlice = createSlice({
    name: 'resolutionConfiguration',
    initialState,
    reducers: {
      set: (state, action: PayloadAction<ResolutionConfigurationDto[]>) => {
        state.resolutionConfigurations = action.payload;
      }, 
    }
  });
  
  export const { set } = resolutionConfigurationSlice.actions;
  
  export function resolutionConfigurationSelector(s: RootState) { return s.procurement.resolutionConfiguration.resolutionConfigurations; }

