/*global window, global */
(function () {
    'use strict';

    var globals = typeof window !== 'undefined' ? window : global;

    define("require", function () { return globals.require; });

    define("knockout", function () { return globals.ko; });
    define("jquery", function () { return globals.jQuery; });
    define("codeMirror", function () { return globals.CodeMirror; });
    define("jwerty", function () { return globals.jwerty; });
    define("moment", function () { return globals.moment; });
    define("jwerty", function () { return globals.jwerty; });
    define("backbone", function () { return globals.Backbone; });

    define("window", function () { return window; });
})();
