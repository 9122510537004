/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/shortcuts/definitions"/>
import { defineShortcut } from "Core/Medius.Core.Web/Scripts/lib/shortcutManager";
import { applications} from "Core/Medius.Core.Web/Scripts/Medius/apps/spaManager/applications";

export function registerDefinitions() {
    /***** NAVIGATION *****/
    defineShortcut({
        shortcutName: "ToggleShortcutModal",
        shortcut: "alt+k",
        description: "#Core/shortcutToggleShortcutModal",
        groupName: "#Core/navigation"
    });
    
    defineShortcut({
        shortcutName: "FocusOnQuickSearch",
        shortcut: "ctrl+alt+f",
        description: "#Core/shortcutFocusOnQuickSearch",
        groupName: "#Core/navigation"
    });

    defineShortcut({
        shortcutName: "SwitchToNextTab",
        shortcut: "ctrl+alt+x",
        description: "#Core/switchToNextTab",
        groupName: "#Core/navigation",
        viewContext: applications.inbox
    });

    defineShortcut({
        shortcutName: "SwitchToPreviousTab",
        shortcut: "ctrl+alt+z",
        description: "#Core/switchToPreviousTab",
        groupName: "#Core/navigation",
        viewContext: applications.inbox
    });

    // Due to fact that we have to have two different definitions for shortcut when used in different viewContext we duplicate definitions with small adjustments and skip in help
    // because for user there should be no difference and only one definition in help is enough
    defineShortcut({
        shortcutName: "SwitchToNextTabInCreate",
        shortcut: "ctrl+alt+x",
        description: "#Core/switchToNextTab",
        groupName: "#Core/navigation",
        skipInHelp: true,
        viewContext: applications.createDocument
    });

    defineShortcut({
        shortcutName: "SwitchToPreviousTabInCreate",
        shortcut: "ctrl+alt+z",
        description: "#Core/switchToPreviousTab",
        groupName: "#Core/navigation",
        skipInHelp: true,
        viewContext: applications.createDocument
    });

    /***** GRID *****/
    defineShortcut({
        shortcutName: "GridSelectAll",
        shortcut: "ctrl+a",
        description: "#Core/shortcutGridSelectAllRows",
        groupName: "#Core/grid"
    });
    
    defineShortcut({
        shortcutName: "GridRowUp",
        shortcut: "↑",
        description: "#Core/shortcutGridRowUp",
        groupName: "#Core/grid"
    });

    defineShortcut({
        shortcutName: "GridRowDown",
        shortcut: "↓",
        description: "#Core/shortcutGridRowDown",
        groupName: "#Core/grid"
    });
    
    /***** INBOX *****/
    defineShortcut({
        shortcutName: "ToggleFullscreen",
        shortcut: "F2",
        description: "#Core/shortcutTurnOnOffFullscreen",
        groupName: "#Core/inbox",
        viewContext: applications.inbox
    });

    defineShortcut({
        shortcutName: "MainActionInTaskShortcut",
        shortcut: "F4",
        description: "#Core/shortcutMainActionInTask",
        groupName: "#Core/inbox",
        viewContext: applications.inbox
    });

    defineShortcut({
        shortcutName: "MainActionInTaskShortcutInCreate",
        shortcut: "F4",
        description: "#Core/shortcutMainActionInTask",
        groupName: "#Core/inbox",
        viewContext: applications.createDocument
    });

    defineShortcut({
        shortcutName: "OpenPreviousTask",
        shortcut: "alt+w",
        description: "#Core/shortcutOpenPreviousTask",
        groupName: "#Core/inbox",
        viewContext: applications.inbox
    });

    defineShortcut({
        shortcutName: "OpenNextTask",
        shortcut: "alt+s",
        description: "#Core/shortcutOpenNextTask",
        groupName: "#Core/inbox",
        viewContext: applications.inbox
    });

    defineShortcut({
        shortcutName: "OpenDashboardInTaskContext",
        shortcut: "alt+g",
        description: "#Core/shortcutOpenControlCenter",
        groupName: "#Core/inbox",
        viewContext: applications.inbox
    });
    
    defineShortcut({
        shortcutName: "RefreshInbox",
        shortcut: "alt+r",
        description: "#Core/refreshInbox",
        groupName: "#Core/inbox",
        viewContext: applications.inbox
    });

    defineShortcut({
        shortcutName: "NavigateFromDashboardToInbox",
        shortcut: "alt+g",
        description: "#Core/shortcutOpenControlCenter",
        groupName: "#Core/inbox",
        viewContext: applications.dashboard,
        skipInHelp: true
    });

    defineShortcut({
        shortcutName: "AddCodingLine",
        shortcut: 'alt+1',
        description: '#Enterprise/shortcutAddCodingLine',
        groupName: '#Enterprise/coding',
        viewContext: applications.inbox
    });

    defineShortcut({
        shortcutName: "CloneCodingLine",
        shortcut: 'alt+2',
        description: '#Enterprise/shortcutCloneCodingLine',
        groupName: '#Enterprise/coding',
        viewContext: applications.inbox
    });
    
    defineShortcut({
        shortcutName: "RemoveCodingLine",
        shortcut: 'alt+3',
        description: '#Enterprise/shortcutRemoveCodingLine',
        groupName: '#Enterprise/coding',
        viewContext: applications.inbox
    });
    
    defineShortcut({
        shortcutName: "AuthorizeAllMyLines",
        shortcut: 'alt+4',
        description: '#Enterprise/authorizeAll',
        groupName: '#Enterprise/coding',
        viewContext: applications.inbox
    });
    
    defineShortcut({
        shortcutName: "ApplyCodingTemplate",
        shortcut: 'alt+5',
        description: '#Enterprise/selectTemplate',
        groupName: '#Enterprise/coding',
        viewContext: applications.inbox
    });

    defineShortcut({
        shortcutName: "ApplyLatestCodingTemplate",
        shortcut: 'alt+shift+5',
        description: '#Enterprise/applyLatestCoding',
        groupName: '#Enterprise/coding',
        viewContext: applications.inbox
    });
    
    defineShortcut({
        shortcutName: "ApplyAccrual",
        shortcut: 'alt+6',
        description: '#Enterprise/selectLinesToApplyAccrual',
        managerDescription: '#Enterprise/applyAccrual',
        groupName: '#Enterprise/coding',
        viewContext: applications.inbox
    });
    
    defineShortcut({
        shortcutName: "ChangeAuthorizer",
        shortcut: 'alt+7',
        description: '#Enterprise/changeAuthorizer',
        groupName: '#Enterprise/coding',
        viewContext: applications.inbox
    });
    
    defineShortcut({
        shortcutName: "RejectAllMyLines",
        shortcut: 'alt+8',
        description: '#Enterprise/rejectAllMyLines',
        groupName: '#Enterprise/coding',
        viewContext: applications.inbox
    });
    
    defineShortcut({
        shortcutName: "SaveCodingAsTemplate",
        shortcut: 'alt+9',
        description: '#Enterprise/saveAsTemplate',
        groupName: '#Enterprise/coding',
        viewContext: applications.inbox
    });
    
    defineShortcut({
        shortcutName: "RemoveAccural",
        shortcut: 'alt+0',
        description: '#Enterprise/selectLinesToRemoveAccrual',
        managerDescription: '#Enterprise/removeAccrual',
        groupName: '#Enterprise/coding',
        viewContext: applications.inbox
    });
    
    defineShortcut({
        shortcutName: "FetchAuthorizer",
        shortcut: 'alt+a', //"ctrl+alt" = "right alt", do not use "ctrl+alt+[e,o,a,s,l,z,x,c,n]" as it blocks writing Polish special characters
        description: '#ExpenseInvoice/LBL_FETCH_RESPONSIBLE_USERS',
        groupName: '#Enterprise/coding',
        viewContext: applications.inbox
    });
}
