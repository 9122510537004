/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/default/text/component/percentage"/>
import {unpack} from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as typeBoundResolverFactory from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/typeBound";

export const resolver = typeBoundResolverFactory.create({
    priority: 0,

    type: "Medius.Core.DTOs.PercentageDto",

    resolve: function (data: any) {
        const percent = unpack(data.Percent);
        const presentation = `${percent}%`;

        return { text: presentation };
    }
});
