/// <amd-module name="Core/Medius.Core.Web/Scripts/appInsights"/>
import { isEmptyString } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { getTenantId } from 'Core/Medius.Core.Web/Scripts/Medius/lib/path';
import { getId } from 'Core/Medius.Core.Web/Scripts/Medius/lib/utils/user';
import * as config from "Core/Medius.Core.Web/Scripts/appConfig";

let appInsights: ApplicationInsights = null;

function isEnabled() {
    return !isEmptyString(config.getApplicationInsightsInstrumentationKey());
}

const excludedPatterns = [
    new RegExp('.+/documentLock/\\d+/acquire'),
    new RegExp('.+/SpaApp/Ping'),
    new RegExp('.+/statsd/.+')
];

function filteringFunction(envelope: ITelemetryItem) {
    const dependencyName = envelope.baseData.name;
    let result = false;
    excludedPatterns.forEach(element => {
        result ||= element.test(dependencyName);
    });
    return !result;
}

function traceTechnicalData(envelope: ITelemetryItem) {
    envelope.tags["ai.cloud.role"] = `${config.getDeploymentName()}_UI`;
    envelope.data["Deployment"] = config.getDeploymentName();
    envelope.data["TenantId"] = getTenantId();
    envelope.data["Version"] = config.getCurrentAppDisplayVersion();
}

export function initializeApplicationInsights() {
    if (appInsights !== null || !isEnabled())
        return;

    let samplingPercentage = parseFloat(config.getApplicationInsightsSamplingPercentage());
    if (isNaN(samplingPercentage)
        || samplingPercentage <= 0
        || samplingPercentage > 100)
        samplingPercentage = 100;

    appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: config.getApplicationInsightsInstrumentationKey(),
            samplingPercentage,
            maxAjaxCallsPerView: -1,
            disableAjaxTracking: false,
            disableFetchTracking: false,
            enableAutoRouteTracking: true
        }
    });

    appInsights.loadAppInsights();

    appInsights.trackPageView();
    appInsights.trackEvent({
        name: "DesktopWebUserData",
        properties: {
            screenWidth: window.screen.width,
            screenHeight: window.screen.height,
            tenant: getTenantId()
        }
    });

    appInsights.addTelemetryInitializer(filteringFunction);
    appInsights.addTelemetryInitializer(traceTechnicalData);

    const userId = getId();
    if (userId)
        appInsights.setAuthenticatedUserContext(`${getTenantId()}-${userId}`, null, false);
}

export function getAppInsights() {
    if (!appInsights)
        initializeApplicationInsights();

    return appInsights;
}

export function trackEvent(...args: Parameters<typeof appInsights.trackEvent>) {
    getAppInsights()?.trackEvent(...args);
}
