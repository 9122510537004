/// <amd-module name="Core/Medius.Core.Web/Scripts/loginPage/LocalLoginPageRoot"/>
import * as React from "react";
import {useState} from "react";
import LoginPage from "./LoginPage";
import ForgotPasswordPage from "./ForgotPasswordPage";
import ForgotPasswordResultPage from "./ForgotPasswordResultPage";

export enum PageType {
    LoginForm,
    ForgotPassword,
    ForgotPasswordResult,
}

export default function LocalLoginPage() {
    const [currentPage, setCurrentPage] = useState(PageType.LoginForm);

    let page = null;
    switch (currentPage) {
        case PageType.LoginForm:
            page = <LoginPage 
                goToForgotPasswordPage={() => setCurrentPage(PageType.ForgotPassword)} />;
            break;
        case PageType.ForgotPassword:
            page = <ForgotPasswordPage 
                goToLoginPage={() => setCurrentPage(PageType.LoginForm)}
                goToForgotPasswordResultPage={() => setCurrentPage(PageType.ForgotPasswordResult)}/>;
            break;
        case PageType.ForgotPasswordResult:
            page = <ForgotPasswordResultPage
                goToLoginPage={() => setCurrentPage(PageType.LoginForm)} />;
            break;
        default:
          return null;
    }

    return (
        <>       
            {page}
        </>
    );
}
