///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Data.DataDefinition/CustomFields"/>
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as type from "Core/Medius.Core.Web/Scripts/Medius/core/type";
import * as rpc from "Core/Medius.Core.Web/Scripts/Medius/core/rpc";
import * as serialization from "Core/Medius.Core.Web/Scripts/Medius/lib/serialization";
import * as ko from "knockout";
import { unpack } from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as backendErrorHandler from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";

const CompanyType = "Medius.Core.Entities.Company";
const UserType = "Medius.Core.Entities.User";

class ViewModel {
    Entity: any;
    Context: any;
    CompanyContext: ko.Observable<any>;
    CompanyFilter: any;
    ShowCompanyContext: any;
    CanPresentData: ko.Computed<boolean>;
    MissingPermission: ko.Observable<boolean>;
    MissingPermissionMessage: ko.Computed<string>;
    constructor(entityWithContext:any) {
        this.Entity = entityWithContext.Entity;
        this.Context = entityWithContext.Context;

        this.CompanyContext = ko.observable(this.getDefaultCompany());
        this.CompanyFilter = this.getCompanyFilter();
        this.ShowCompanyContext = this.showCompanyContext();
        this.CanPresentData = ko.computed(() => {
            return this.ShowCompanyContext === false || this.CompanyContext() !== null;
        });

        this.MissingPermission = ko.observable(false);
        this.MissingPermissionMessage = ko.computed(() => {
            const company = unpack(this.CompanyContext);
            const companyName = company && unpack(company.Name);

            return globalization.getFormattedLabelTranslation("#Core/missingPermissionToViewCompany", [companyName]);
        }, this);
        this.CompanyContext.subscribe(this.refresh.bind(this));
        this.refresh();
    }
    getCompanyFilter() {
        if (this.ShowCompanyContext === false) {
            return null;
        }

        if (this.getEntityType() === UserType) {
            return null;
        }

        if (this.isCompanyRelated()) {
            return this.Entity.Company();
        }

        return null;
    }

    getDefaultCompany() {
        if (this.ShowCompanyContext === false) {
            return null;
        }

        if (this.getEntityType() === UserType) {
            return this.Entity.DefaultCompany();
        }

        if (this.isCompanyRelated()) {
            return this.Entity.Company();
        }

        return null;
    }

    isCompanyRelated() {
        return type.isCompanyRelated(unpack(this.Entity.$type));
    }

    getEntityType() {
        return type.getTypeName(unpack(this.Entity.$type));
    }

    showCompanyContext() {
        return this.getEntityType() !== CompanyType;
    }

    getCustomFieldsPerspective() {
        const methodArgs = {
            dataType: this.getEntityType(),
            contextCompanyId: 0
        };

        if (this.showCompanyContext()) {
            methodArgs.contextCompanyId = this.CompanyContext().Id();
        } else if (this.getEntityType() === CompanyType) {
            methodArgs.contextCompanyId = this.Entity.Id();
        }

        rpc.ajax("CustomFieldsManager", "GetCustomFieldsPerspective", {
            data: serialization.toJSON(methodArgs)
        }).done((data:any) => {
            const perspectiveVm = this.Context.create(data);
            this.Entity.CustomFields().Perspective(perspectiveVm);
        })
        .fail((error:any) => {
            if (error.status === 403) {
                this.MissingPermission(true);
            } else {
                backendErrorHandler.handleAnyError(error);
        }});
    }

    refresh() {
        this.MissingPermission(false);
        if (this.CanPresentData()) {
            this.getCustomFieldsPerspective();
        }
    }
}

export = function(entityWithContext:any) {
    return new ViewModel(entityWithContext);
};