///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/transformers/create/makeBuilder"/>
import * as defaults from "Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/transformers/create/defaults";
import * as utils from "Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/transformers/create/utils";

export = function makeViewmodelBuild(input:any, mapping:any, context:any) {
    let viewmodel:any = null;

    if (mapping.make) {
        viewmodel = mapping.make(input, context);
    }

    if (!viewmodel) {
        return null;
    }

    return {
        ...defaults,
        shouldDescend: false,
        finalize: function () {
            utils.finalizeViewmodel(viewmodel, input, mapping, context, undefined);
            return viewmodel;
        }
    };
};