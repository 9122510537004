///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/models/mergedConfiguration/mergedCompanyConfigurationTab"/>
import BaseFactory = require("Core/Medius.Core.Web/Scripts/Medius/models/mergedConfiguration/mergedEntityConfigurationTab");
import { EntityWithContext } from "./mergedConfigurationTab";

export class MergedCompanyConfigurationTab extends BaseFactory{
    constructor(entityWithContext: EntityWithContext){
        super(entityWithContext);
    }

    getCompanyFilter(): any {
        return null;
    }
    
    getDefaultCompanyContext() {
        return this.entityWithContext.Entity;
    }
    
    showCompanyContext() {
        return false;
    }
}