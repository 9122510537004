///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/export/async/pending" />

import * as _ from "underscore";
import * as $ from "jquery";
import * as ko from "knockout";
import * as backendErrorHandler from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import * as notification from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as storage from "Core/Medius.Core.Web/Scripts/Medius/lib/store/async";
import * as rest from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rest";
import * as path from "Core/Medius.Core.Web/Scripts/Medius/lib/path";
import contextFactory = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/context");

const shouldDisplayNotificationsKey = 'should-diplay-async-export-notifications';

class PendingAsyncExports{
    context: any;
    IsPendingExportsModalShown: ko.Observable;
    ShouldDisplayNotifications: ko.Observable;
    toogleNotificationsSubscription: ko.Subscription;
    IsLoading: ko.Computed;
    PendingExports: ko.ObservableArray;
    delayedPendingExportsRequest: any;
    pendingExportsRequest: JQueryPromise<any>;
    deleteExportRequest: JQueryPromise<any>;
    timeouts: any[] = [];
    isInitInProgress: ko.Observable;
    isDeleteInProgress: ko.Observable;
    isDelayedRefreshInProgress: ko.Observable;

    constructor(){
        const storageInstance = storage.createLocalStore("Core:pending-tasks");
    
        this.context = contextFactory();
    
        this.isInitInProgress = ko.observable(false);
        this.isDeleteInProgress = ko.observable(false);
        this.isDelayedRefreshInProgress = ko.observable(false);
    
        if (!storageInstance.hasItem(shouldDisplayNotificationsKey)) {
            storageInstance.setItem(shouldDisplayNotificationsKey, true);
        }
    
        this.IsPendingExportsModalShown = ko.observable(false);
        this.ShouldDisplayNotifications = ko.observable(storageInstance.getItem(shouldDisplayNotificationsKey));
        this.toogleNotificationsSubscription = this.ShouldDisplayNotifications.subscribe(() => {
            storageInstance.setItem(shouldDisplayNotificationsKey, this.ShouldDisplayNotifications());
        });
    
        this.IsLoading = ko.computed(() => {
            return this.isInitInProgress() || this.isDeleteInProgress() || this.isDelayedRefreshInProgress();
        });
        this.PendingExports = ko.observableArray();
    }

    showPendingExportsModal(){
        this.IsPendingExportsModalShown(true);
    }

    scheduleUpdateEvent() {
        const unfinishedExport = _(this.PendingExports()).find((pendingExport) => {
            return pendingExport.TaskState() !== 'Completed' && pendingExport.TaskState() !== 'Failed';
        });

        if (!unfinishedExport) {
            return null;
        }

        const updateInterval = 2000;

        return setTimeout(() => {
            this.refresh();
        }, updateInterval);
    }

    onExportCompleted(completedExport: any) {
        if (!this.ShouldDisplayNotifications()) {
            return;
        }

        if (completedExport.TaskState() === 'Completed') {
            notification.success(
                globalization.getFormattedLabelTranslation("#Core/completedAsyncExportTaskInfo_filename", [completedExport.FileName()])
            );
        } else if (completedExport.TaskState() === 'Failed') {
            notification.error(
                globalization.getFormattedLabelTranslation("#Core/failedAsyncExportTaskInfo_filename", [completedExport.FileName()])
            );
        }
    }

    updatePendingExports(updatedPendingExports: any) {
        const newPendingExports: any[] = [];

        _(updatedPendingExports).each((updatedPendingExport) => {
            let existingExport = _(this.PendingExports()).find((pendingExport) => {
                return pendingExport.Id() === updatedPendingExport.Id;
            });

            if (!existingExport) {
                existingExport = this.context.create(updatedPendingExport);
            }
            newPendingExports.push(existingExport);

            if (existingExport.TaskState() === 'Failed' || existingExport.TaskState() === 'Completed') {
                return;
            }

            existingExport.TaskState(updatedPendingExport.TaskState);
            existingExport.Progress(updatedPendingExport.Progress);
            existingExport.ErrorMessage(updatedPendingExport.ErrorMessage);
            existingExport.TaskStartDateTime(updatedPendingExport.TaskStartDateTime);
            existingExport.PositionInQueue(updatedPendingExport.PositionInQueue);

            if (updatedPendingExport.TaskState !== 'InProgress') {
                this.onExportCompleted(existingExport);
                return;
            }
            if (updatedPendingExport.TaskState === 'Queued') {
                return;
            }               
        });

        _(this.PendingExports()).each((pendingExport) => {
            const updatedExport = _(updatedPendingExports).find((updatedPendingExport) => {
                return pendingExport.Id() === updatedPendingExport.Id;
            });

            if (!updatedExport) {
                pendingExport.dispose();
            }
        });

        this.PendingExports(_(newPendingExports).sortBy((pendingExport) => {
            return pendingExport.CreatedTimestamp();
        }).reverse());
    }

    clearAllTimeouts() {
        _(this.timeouts).each((timerId) => {
            clearTimeout(timerId);
        });
        this.timeouts = [];
    }

    delayedRefresh() {
        if (this.delayedPendingExportsRequest) {
            return this.delayedPendingExportsRequest;
        }

        this.isDelayedRefreshInProgress(true);
        this.delayedPendingExportsRequest = $.Deferred();
        this.delayedPendingExportsRequest.pipe(() => {
            return this.refresh();
        }).always(() => {
            this.delayedPendingExportsRequest = null;
            this.isDelayedRefreshInProgress(false);
        });

        setTimeout(() => {
            this.delayedPendingExportsRequest.resolve();
        }, 2000);

        return this.delayedPendingExportsRequest;
    }

    wrapAsyncExportTask(asyncExportTaskRequest: any, onExportError?: any) {
        let asyncExportTaskTmp: any;

        return asyncExportTaskRequest.pipe((asyncExportTask: any) => {
            const title = globalization.getLabelTranslation("#Core/asyncExportStartedInfoTitle");
            const message = globalization.getLabelTranslation(
                "#Core/asyncExportStartedInfoMessage_filename",
                asyncExportTask.FileName
            );

            notification.info(message, title);
            this.IsPendingExportsModalShown(true);
            asyncExportTaskTmp = asyncExportTask;

            return this.refresh();
        }).pipe(() => {
            this.delayedRefresh();
            return asyncExportTaskTmp;
        }).fail((xhr: any) => {
            if (onExportError)
                onExportError(xhr);
            else
                backendErrorHandler.handleAnyError(xhr);
        });
    }

    refresh() {
        if (this.pendingExportsRequest) {
            return this.pendingExportsRequest;
        }

        this.clearAllTimeouts();

        this.pendingExportsRequest = rest.get("AsyncExportManager", "exports")
            .pipe((pendingExports) => {
                this.updatePendingExports(pendingExports);
                const schedulEventTimer = this.scheduleUpdateEvent();
                if (schedulEventTimer !== null) {
                    this.timeouts.push(schedulEventTimer);
                }
                return pendingExports;
            }).fail((xhr) => {
                backendErrorHandler.handleAnyError(xhr);
            }).always(() => {
                this.pendingExportsRequest = null;
            });

        return this.pendingExportsRequest;
    }

    init() {
        this.isInitInProgress(true);
        return this.refresh().always(() => {
            this.isInitInProgress(false);
        });
    }

    downloadExportFile(pendingExport: any){
        const queryString = `?asyncExportTaskId=${pendingExport.Id()}`;
        const url = path.toAction("AsyncExport", "~/AsyncExport") + queryString;
        window.location.href = url;
    }

    onSuccessfulDelete(pendingExport: any) {
        this.PendingExports(_(this.PendingExports()).without(pendingExport));
        pendingExport.dispose();
    }

    removePendingExport(pendingExport: any) {
        if (this.deleteExportRequest) {
            return this.deleteExportRequest;
        }
        this.isDeleteInProgress(true);

        this.deleteExportRequest = $.when(this.pendingExportsRequest).pipe(() => {
            return rest.del(
                "AsyncExportManager", `exports/${pendingExport.Id()}`
            ).pipe(() => {
                this.onSuccessfulDelete(pendingExport);
            }).fail((xhr) => {
                if (xhr.status === 404) {
                    this.onSuccessfulDelete(pendingExport);
                } else {
                    backendErrorHandler.handleAnyError(xhr);
                }
            }).always(() => {
                this.deleteExportRequest = null;
                this.isDeleteInProgress(false);
            });
        });

        return this.deleteExportRequest;
    }

    destroy() {
        this.IsLoading.dispose();
        this.PendingExports([]);
        this.toogleNotificationsSubscription.dispose();
        this.context.dispose();
    }
}

const pendingAsyncExportsInstance = new PendingAsyncExports();
export = pendingAsyncExportsInstance;