///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/grid/default/description"/>
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import { formatValueWithPlaceholders } from "../../../lib/stringFormat";

const filteringDescription = globalization.getLabelTranslation("#Core/gridDataInformationFilteringDescription_total_form");
const selectionDescription = globalization.getLabelTranslation("#Core/gridDataInformationSelectionDescription_selected_form");
const quantityFrom = globalization.getLabelTranslation("#Core/gridDataInformationBasicDescription_from_form");
const quantityFromAll = globalization.getLabelTranslation("#Core/gridDataInformationBasicDescription_from_all_form");
const quantityFromToAll = globalization.getLabelTranslation("#Core/gridDataInformationBasicDescription_from_to_all_form");
const quantityZero = globalization.getLabelTranslation("#Core/gridDataInformationBasicDescriptionZero_form");
const waitLabel = globalization.getLabelTranslation("#Core/waitforDataToLoad");

export function generate(params:any) {
    let description = "";
        
    params = params || {};            
        
    if (isNullOrUndefined(params.totalNonfilteredRows)) {
        return waitLabel;
    }
    
    description += buildShowingDescription(params.totalRows, params.totalVisibleRows, params.page,
        params.pageSize, params.singularForm, params.pluralForm, params.limit);

    if (params.isFiltered) {
        description += formatValueWithPlaceholders(filteringDescription, [toString(params.totalNonfilteredRows, params.limit), params.pluralForm]);
    }
    if (params.isMultiselect && params.totalSelectedRows > 0) {
        description += formatValueWithPlaceholders(selectionDescription,[params.totalSelectedRows, params.pluralForm]);
    }

    return description;
}

function buildShowingDescription(totalRows:number, totalVisibleRows:number, page:number, pageSize:number, singularForm:string, pluralForm:string, limit:number) {
    
    if (totalRows === 0) {
        return formatValueWithPlaceholders(quantityZero, [pluralForm]);
    }

    const from = (page - 1) * pageSize + 1;
    const to = from + totalVisibleRows - 1;
    
    if (totalRows === 1) {
        return formatValueWithPlaceholders(quantityFrom, [from, singularForm]);
    }

    const totalRowsString = toString(totalRows, limit);

    if (from === to) {
        return formatValueWithPlaceholders(quantityFromAll, [from, totalRowsString, pluralForm]);
    }
    
    return formatValueWithPlaceholders(quantityFromToAll, [from, to, totalRowsString, pluralForm]);
}

function toString(totalRows:number, limit:number) {
    if (limit && totalRows >= limit) {
        return totalRows.toString() + "+";
    } else if (totalRows) {
        return totalRows.toString();
    }
}