///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/configurationProperties/simpleObject"/>
import {ConfigurationProperty} from "Core/Medius.Core.Web/Scripts/Medius/components/configurationProperties/baseObject";

class SimpleProperty extends ConfigurationProperty{
    BindingTemplate: string;
    constructor(params: any){
        super(params);
        this.BindingTemplate = "configuration-simple-property";
    }
    
    createViewModel(data: any) {
        return data;
    }
}

export const derive = SimpleProperty;
export function create(params: any) {
    return new SimpleProperty(params);
}