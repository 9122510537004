/// <amd-module name="Core/Medius.Core.Web/Scripts/components/administration/page/service"/>
import * as rest from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rest";
import * as rpc from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rpc";
import * as administration from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/server";
import { AdministrationEntity, IAdministrationEntity } from "./types";
import { create as createDataSearchQueryBuilder } from "Core/Medius.Core.Web/Scripts/Medius/components/grid/dataSource/dataSearchQueryBuilder";

export class AdministrationService<TEntity extends IAdministrationEntity=any> {
    constructor(private entityType: string) {
        this.entityType = entityType;
    }
    
    public getPage(page: number, limit: number, keywords: Record<string, unknown>, columns: string[]) { 
        return rest.get("DataSearchManager", this.getReasonCodesUrl(page, limit, keywords, columns));
    }
    
    public get(id: number): JQueryPromise<AdministrationEntity<TEntity>> {
        return administration.get(`${this.entityType}/${id}`);
    }
    
    public add(entityData: any) {
        return administration.post(this.entityType, entityData);
    }
    
    public update(id: number, entityData: any) {
        return administration.put(`${this.entityType}/${id}`, entityData);
    }
    
    public remove(id: number) {
        return administration.del(`${this.entityType}/${id}`);
    }
    
    public hasAddAccess() {
        return rpc.request("SettingsManager", "UserAccessControlForType", { typeName: this.entityType });
    }

    private getReasonCodesUrl(page: number, limit: number, keywords: Record<string, unknown>, columns: string[]) {
        const dataSearchQueryBuilder = createDataSearchQueryBuilder({
            entityType: this.entityType,
            columns,
            keywords
        });
    
        const query = dataSearchQueryBuilder
            .buildColumns()
            .buildSearchQuery()
            .getQuery();
    
        return `searchPagedEntities?query=${query}&page=${page}&limit=${limit}`;
    }
}
