///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/editors/bindings/triggerValidation"/>
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as $ from "jquery";

const keyInitFlag = "triggerValidationFlag";

const triggerValidation = {
    init: function (element:any, bindingAccessor:() => string, allAccessor:any, viewModel:any) {
        const eventType = bindingAccessor() || "change";
        let $element = $(element);
        let handler = viewModel.triggerValidation.bind(viewModel);

        $element.on(eventType, handler);

        if (eventType === "keyup") {
            $element.on("blur", handler);
        }

        koUtils.addDisposeCallback(element, function () {
            $element.off(eventType, handler);
            $element.off("blur", handler);
            handler = null;
            $element = null;
        });

        koUtils.domData.set(element, keyInitFlag, true);
    },
    update: function (element:any, ...args:any[]) {
        const flag = koUtils.domData.get(element, keyInitFlag);

        args = [element, ...args];
        if (!flag) {
            triggerValidation.init.apply(this, args);
        }
    }
};

koUtils.registerBinding("triggerValidation", triggerValidation);