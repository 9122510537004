/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/contextResource"/>
import contextFactory = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/context");

export = function contextResourceFactory() {
    let context:any = null;
    return {
        newContext: () => {
            if (context) {
                context.dispose();
            }
            context = contextFactory();
            return context;
        },
        enqueue: (action:any) => {
            if (context) {
                return context.enqueue(action).getDeferred();
            }
            return null;
        },
        dispose: () => {
            if (context) {
                context.dispose();
                context = null;
            }
        }
    };
}