///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/codeMirrorPlugin"/>
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as $ from "jquery";
import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";

const codeMirror = (window as any).CodeMirror;

const codeMirrorPlugin = {
    init: function (element:any, valueAccessor:any) {
        const value = valueAccessor();

        const editor = codeMirror.fromTextArea(element, {
            lineNumbers: true,
            matchBrackets: true,
            mode: "text/xml"
        });

        function refresh() {
            editor.refresh();
        }

        if (isNullOrUndefined(value())) {
            value("");
        }

        editor.setValue(value());

        editor.on('change', function () {
            value(editor.getValue());
        });

        refresh();

        $(window).on("shown", refresh);


        const dispose = function () {
            $(window).off("shown", refresh);
            editor.off();
        };

        koUtils.addDisposeCallback(element, dispose);
    }
};

export function register() {
    koUtils.registerBinding("codeMirrorPlugin", codeMirrorPlugin);
}