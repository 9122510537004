/// <amd-module name="Core/Medius.Core.Web/Scripts/components/sidebar/sidebarGridState"/>
const initialState: SidebarGridState = { 
    take: 20, 
    skip: 0, 
    filter: {
        logic: "and",
        filters: []
    }
};

export const SetSidebarStateAction = "set-sidebar-state";

export function sidebarGridStateReducer(state = initialState, action: {type: string; payload: any}): SidebarGridState {
    switch (action.type) {
        case SetSidebarStateAction:
            return {...state, ...action.payload };
        default:
            return state;
    }
}

interface SidebarGridState {
    take: number;
    skip: number;
    filter: {
        logic: "and";
        filters: {value: string; field: string}[];
    };
}
