/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/mediusUtils"/>
import * as _ from "underscore";
import * as ko from "knockout";
import * as amountDtoFactory from "Core/Medius.Core.Web/Scripts/Models/amount";
import * as rest from "Core/Medius.Core.Web/Scripts/Medius/core/rest";
import * as logger from "Core/Medius.Core.Web/Scripts/Medius/lib/logger";
import * as backendErrorHandler from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import * as rpc from "Core/Medius.Core.Web/Scripts/Medius/core/rpc";
import * as rpcJson from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rpc";
import * as workflowNames from "Core/Medius.Core.Web/Scripts/Medius/workflownames";
import * as unitPriceDtoFactory from "Core/Medius.Core.Web/Scripts/Models/FinanceComponentDto/unitpriceFactory";
import * as quantityDtoFactory from "Core/Medius.Core.Web/Scripts/Models/FinanceComponentDto/quantityFactory";
import * as settings from "Core/Medius.Core.Web/Scripts/Medius/apps/task/settings";
import * as utilsfn from "Core/Medius.Core.Web/Scripts/lib/fn";

let sessionId = 0;

export function cleanupParameters(object: any) {
    const newObject: any = {};

    _.each(object, function(v, k) {
        v = ko.utils.unwrapObservable(v);
        if (_.isArray(v)) {
            newObject[k] = _.map(v, toJs);
        } else {
            newObject[k] = toJs(v);
        }
    });

    return newObject;
}

export function parseObjectToQueryString(params: any): string {
    const data = Object.entries(params).map(([key, value]) => {
        if (Array.isArray(value)) {
            return value.map((item, i) => 
                Object.entries(item).map(([subKey, subValue]) => 
                    `&${key}[${i}].${subKey}=${subValue}`
                ).join('')
            ).join('');
        } else {
            return `&${key}=${value}`;
        }
    }).join('');
    return '?' + data.substring(1);
}

function createdUsingRepository(value: any) {
    return value._ctx && value['__ko_mapping__'] === undefined;
}

function createdUsingKo(value: any) {
    return value['__ko_mapping__'] !== undefined;
}

function isPrimitive(value: any) {
    return _.isString(value) || _.isBoolean(value) || _.isNumber(value) || _.isDate(value);
}

function toJs(value: any) {
    value = ko.utils.unwrapObservable(value);

    if (!value) {
        return value;
    } else if (createdUsingRepository(value)) {
        return value._ctx.toJS(value);
    } else if (createdUsingKo(value)) {
        return ko.toJS(value);
    } else if (amountDtoFactory.instanceOf(value) || unitPriceDtoFactory.instanceOf(value) || quantityDtoFactory.instanceOf(value)) {
        return value.toJS();
    } else if (isPrimitive(value)) {
        return value;
    }

    return cleanupParameters(value);
}

function performAjaxCall(ajaxCall: any, startLoading: any, successCallback: any) {
    const callSessionId = sessionId;

    if (_.isFunction(startLoading)) {
        startLoading();
    }

    logger.log("ajax call with sessionId={0}", callSessionId);
    return ajaxCall().fail(function (error: any) {
            backendErrorHandler.handleAnyError(error);
        })
        .done(function(data: any) {
            logger.log("returned ajax call with callSessionId={0}, sessionId now is {1}", callSessionId, sessionId);
            if (sessionId === callSessionId && _.isFunction(successCallback)) {
                successCallback(data);
            }
        });
}

function updateFinanceComponentCurrencyCode(componentObservable: any, newCurrencyCode: any, financeComponentFactory: any) {
    let newComponent: any;

    if (componentObservable()) {
        componentObservable().CurrencyCode(newCurrencyCode);
    } else {
        newComponent = financeComponentFactory.zero(newCurrencyCode);
        componentObservable(newComponent);
    }
}

export function resetAjaxCalls() {
    sessionId = sessionId + 1;
}

export function restPost(resourceName: string, serviceName: string, params: any, successCallback: any, startLoading: any, stopLoading: any, async?: boolean) {
    const newParams = cleanupParameters(params || {});

    function ajaxCall() {
        return rest.post(serviceName,
            resourceName,
            {
                data: JSON.stringify(newParams),
                complete: stopLoading ? stopLoading : function () { },
                async: async
            });
    }

    return performAjaxCall(ajaxCall, startLoading, successCallback);
}

export function ajax(methodName: string, serviceName: string, params?: any, successCallback?: any, startLoading?: any, stopLoading?: any, async?: boolean) {
    const newParams = cleanupParameters(params || {});

    function ajaxCall() {
        return rpc.ajax(serviceName,
            methodName,
            {
                data: JSON.stringify(newParams),
                complete: stopLoading ? stopLoading : function() {},
                async: async
            });
    }

    return performAjaxCall(ajaxCall, startLoading, successCallback);
}

export function syncAjax(methodName: string, serviceName: string, params: any, successCallback?: any, startLoading?: any, stopLoading?: any) {
    return ajax(methodName, serviceName, params, successCallback, startLoading, stopLoading, false);
}

export function lightJsonAjax(methodName: string, serviceName: string, params: any, successCallback: any, startLoading: any, stopLoading: any, async: boolean) {
    function ajaxCall() {
        return rpcJson.lightApi(serviceName,
            methodName,
            params,
            {
                complete: stopLoading ? stopLoading : function() {},
                async: async
            });
    }

    return performAjaxCall(ajaxCall, startLoading, successCallback);
}

export function concatenateLabels(string1: string, string2: string, maxLength: number) {
    let concatenatedStrings: string;
    if (string1 === null || string1.length === 0) {
        concatenatedStrings = string2;
    } else {
        concatenatedStrings = string2 !== null && string2.length !== 0 ? string1 + " - " + string2 : string1;
    }
    if (concatenatedStrings === null) {
        return concatenatedStrings;
    }
    if (maxLength !== null && concatenatedStrings.length > maxLength) {
        return concatenatedStrings.substring(0, maxLength - 4) + "...";
    } else {
        return concatenatedStrings;
    }
}

export function getDocumentContext(ctx: any) {
    const getContextData = ctx.getContextData || ctx.get;
    const topLevelDocumentData = getContextData(settings.topLevelDocument);
    const taskHandleData = getContextData(settings.context);

    return {
        WorkflowStepName: (taskHandleData) ? taskHandleData.workflowStep : undefined,
        DocumentTypeName: (topLevelDocumentData) ? topLevelDocumentData.getDocumentType() : undefined,
        DocumentId: (topLevelDocumentData) ? topLevelDocumentData.getId() : undefined,
        IsReadOnly: (taskHandleData) ? taskHandleData.isReadOnly: undefined,
        ReasonCodeName: (taskHandleData) ? taskHandleData.reasonCodeName : undefined,
        GetDocumentDto: function () {
            return (topLevelDocumentData) ? topLevelDocumentData.getDocumentDto() : undefined;
        },
        IsInTaskHandling: function () {
            return !this.IsDraft() &&
                   !this.IsInEDI() &&
                   !this.IsInCreate() &&
                   !this.IsInArchive() &&
                   !this.IsInError() &&
                   !this.IsInvalidated() &&
                   !this.IsInEdit() &&
                   !this.IsInCancelPostMessage();
        },
        IsDraft: function () {
            return topLevelDocumentData && topLevelDocumentData.isWrapping() &&
                topLevelDocumentData.getType() === "Medius.Core.Entities.DocumentRegistration.Draft";
        },
        IsInEDI: function () {
            return topLevelDocumentData && topLevelDocumentData.isWrapping() &&
                topLevelDocumentData.getType() === "Medius.Core.Entities.Integration.EDIDocumentImport";
        },
        IsInCreate: function () {
            return taskHandleData && taskHandleData.workflowStep === workflowNames.Create;
        },
        IsInEdit: function () {
            return taskHandleData && taskHandleData.workflowStep === workflowNames.Edit;
        },
        IsInArchive: function () {
            const archiveStepNames = ["Archive", "Archived"];
            return taskHandleData && _.contains(archiveStepNames, taskHandleData.workflowStep);
        },
        IsInError: function () {
            return taskHandleData && taskHandleData.workflowStep === workflowNames.Error;
        },
        IsInvalidated: function () {
            return taskHandleData && taskHandleData.workflowStep === workflowNames.EndTask;
        },
        IsInDistribute: function () {
            return taskHandleData && taskHandleData.workflowStep === workflowNames.DistributeDocument;
        },
        IsInWorkflowStep: function (workflowStepName: string) {
            return taskHandleData && taskHandleData.workflowStep === workflowStepName;
        },
        IsInCancelPostMessage: function () {
            const str = "Cancel post";
            return taskHandleData && taskHandleData.workflowStep.slice(0, str.length) === str;
        },
        IsInAuthorizeStep: function () {
            return taskHandleData && taskHandleData.workflowStep === workflowNames.ApproveDocument;
        },
        IsPaymentRequestCreating: function () {
            return this.DocumentTypeName == "Medius.ExpenseInvoice.Entities.PaymentRequest" && (this.IsInEDI() || this.IsDraft());
        },
        IsInSplitHeaderTaxStep: function () {
            return taskHandleData && taskHandleData.workflowStep === workflowNames.SplitHeaderTax;
        },
        IsInSplitTaxStep: function () {
            return taskHandleData && taskHandleData.workflowStep === workflowNames.SplitTax;
        },
        IsInConfirmStep: function () {
            return taskHandleData && taskHandleData.workflowStep === workflowNames.Confirm;
        }
    };
}

export function updateAmountCurrencyCode(amountObservable: any, newCurrencyCode: any) {
    updateFinanceComponentCurrencyCode(amountObservable, newCurrencyCode, amountDtoFactory);
}

export function updateUnitPriceCurrencyCode(unitPriceObservable: any, newCurrencyCode: any) {
    updateFinanceComponentCurrencyCode(unitPriceObservable, newCurrencyCode, unitPriceDtoFactory);
}

export function IsDraft(context: any) {
    const topLevelDocumentData = context.get(settings.topLevelDocument);
    return topLevelDocumentData 
        && topLevelDocumentData.isWrapping() 
        && topLevelDocumentData.getType() === "Medius.Core.Entities.DocumentRegistration.Draft";
}

export function isInCreateStep(context: any) {
    const taskHandlingContext = context.get('task-handling-context');
    return taskHandlingContext
        && taskHandlingContext.workflowStep === workflowNames.Create;
}

export function IsInEDIStep(context: any) {
    const topLevelDocumentData = context.get(settings.topLevelDocument);
    return topLevelDocumentData 
        && topLevelDocumentData.isWrapping() 
        && topLevelDocumentData.getType() === "Medius.Core.Entities.Integration.EDIDocumentImport";
}

export const isBeingCreated = utilsfn.any(isInCreateStep, IsInEDIStep, IsDraft);

export function isInManualConnectContractbasedStep(context: any) {
    const taskHandlingContext = context.get('task-handling-context');
    return taskHandlingContext
        && taskHandlingContext.workflowStep === workflowNames.ManualConnectContractbasedInvoice;
}

export function isInPostControlStep(context: any) {
    const taskHandlingContext = context.get('task-handling-context');
    return taskHandlingContext
        && (taskHandlingContext.workflowStep === workflowNames.ManualPostControl || 
            taskHandlingContext.workflowStep === workflowNames.ExpenseManualPostControl);
}

export function isInAuthorizeInvoiceAmountStep(context: any) {
    const taskHandlingContext = context.get('task-handling-context');
    return taskHandlingContext 
        && taskHandlingContext.workflowStep === workflowNames.AuthorizeInvoiceAmount;
}

export function isInDistributeStep(context: any) {
    const taskHandlingContext = context.get('task-handling-context');
    return taskHandlingContext 
        && taskHandlingContext.workflowStep === workflowNames.DistributeDocument;
}

export function isInManualConnectDocument(context: any) {
    const taskHandlingContext = context.get('task-handling-context');
    return taskHandlingContext
        && taskHandlingContext.workflowStep === workflowNames.ManualConnectDocument;
}

export function isInAnalyzeDocument(context: any) {
    const taskHandlingContext = context.get('task-handling-context');
    return taskHandlingContext
        && taskHandlingContext.workflowStep === workflowNames.AnalyzeDocument;
}

export function isInAuthorizeDeviationsStep(context: any) {
    const taskHandlingContext = context.get('task-handling-context');
    return taskHandlingContext
        && taskHandlingContext.workflowStep === workflowNames.AuthorizeDeviations;
}

export function isInWorkflow(context: any) {
    return !!context.get('task-handling-context');
}

export function isInEndTaskStep(context: any) {
    const taskHandlingContext = context.get('task-handling-context');
    return taskHandlingContext
        && taskHandlingContext.workflowStep === workflowNames.EndTask;
}

export function isInAuthorizeStep(context: any) {
    const taskHandlingContext = context.get('task-handling-context');
    return taskHandlingContext
        && taskHandlingContext.workflowStep === workflowNames.ApproveDocument;
}

export function isInRiskFactor(context: any) {
    const taskHandlingContext = context.get('task-handling-context');
    return taskHandlingContext
        && taskHandlingContext.workflowStep === workflowNames.RiskFactor;
}

export function isInIntegrationInvalidate(context: any) {
    const taskHandlingContext = context.get('task-handling-context');
    return taskHandlingContext
        && taskHandlingContext.workflowStep === workflowNames.OrderbasedIntegrationInvalidate;
}

export function isInAnalyzeStep(context:any) {
    return context.get('task-handling-context').workflowStep === "AnalyzeDocument";
}