/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/lib/task/queue"/>
import * as _ from "underscore";
import * as $ from "jquery";
import { create as taskCreate, states as taskStates} from "Core/Medius.Core.Web/Scripts/Medius/lib/task/task";
import { create as loggerFactoryCreate } from "Core/Medius.Core.Web/Scripts/Medius/lib/utils/logger";

const states = {
    executing: "executing",
    waiting: "waiting"
};

export function create(params?: any) {    
    const data = _({
        name: _.uniqueId("queue_"),
        tasks: [],
        async: false,
        state: states.waiting,
        currentTask: null
    }).extend(params),
        logger = loggerFactoryCreate({ name: data.name });

    function getState() {
        if (data.currentTask) {
            return states.executing;
        }
        return states.waiting;
    }

    function cleanup() {
        _(data.tasks).each(function (t) {
            t.cancel();
        });
        data.tasks = [];
        executeNext();
    }

    function executeNextSync() {
       const successHandler = () => {
            data.currentTask = null;
            executeNext();
        };

        function failureHandler() {
            data.currentTask = null;
            logger.log("Execution of task failed");
        }

        if (data.tasks.length === 0 || getState() === states.executing) {
            return;
        }

        const nextTask = data.tasks.shift();

        if (nextTask.getState() === taskStates.pending) {
            data.currentTask = nextTask;
            const result = nextTask.run();

            $.when(result)
                .then(successHandler, failureHandler);
        }
    }

    function executeNextAsync() {
        return setTimeout(function () {
            executeNextSync();
        }, 0);
    }

    function executeNext() {
        if (data.async) {
            return executeNextAsync();
        }
        return executeNextSync();
    }

    function cancelTasksById(insertedTask: any) {
        const newTaskId = insertedTask.getId(),
            newTasks = [];

        if (data.currentTask && newTaskId === data.currentTask.getId()) {
            data.currentTask.cancel();
        }

        for (let i = 0; i < data.tasks.length; i++) {
            const t = data.tasks[i];

            if (t.getId() === newTaskId) {
                t.cancel();
            } else {
                newTasks.push(t);
            }
        }
        data.tasks = newTasks;
    }

    return {
        enqueue: (taskData: any) => {
            const task = taskCreate(taskData);

            cancelTasksById(task);

            data.tasks.push(task);
            if (getState() === states.waiting) {
                executeNext();
            }
            return _(task).pick("getName", "getState", "getDeferred", "cancel", "data");
        },

        dispose: () => {
            cleanup();
        },

        toString: function () {
            return `[object Queue ${data.name}]`;
        }
    };
}