/// <amd-module name="Core/Medius.Core.Web/Scripts/components/pdfViewer/pdfViewerLangMapper"/>
const map: { [index: string]: string } = {
        "da" : "da",
        "en-US" : "en-US",
        "fr" : "fr",
        "sv" : "sv-SE",
        "nl" : "nl",
        "no" : "nb-NO"
    };

export function mapXiToPdfjs(code: string): string {
    return map[code] || code;
}
