/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/Unit/General/general"/>
import {IEntityWithContext} from "Core/Medius.Core.Web/Scripts/AdminPages/IEntityWithContext";

const defaultExternalSystemId = "-";

class Unit {
    public Entity: any;
    public isDefaultUnit: boolean;

    constructor(entityWithContext: IEntityWithContext, _companyContextId: any, _tabModel: any) {
        entityWithContext.TabModels.General = this;

        this.Entity = entityWithContext.Entity;

        this.isDefaultUnit = this.Entity.ExternalSystemId() === defaultExternalSystemId;

    }
}

export default function create(entityWithContext: IEntityWithContext, companyContextId: number, tabModel: any) {
    return new Unit(entityWithContext, companyContextId, tabModel);
}
