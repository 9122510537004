/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/editors/date/model"/>

import {mergeDefaults} from "Core/Medius.Core.Web/Scripts/Medius/components/editors/helpers";
import {getTranslation} from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { create as createDateLocalizer } from "Core/Medius.Core.Web/Scripts/Medius/components/editors/date/localizer";
import { Basic } from "Core/Medius.Core.Web/Scripts/Medius/components/editors/basic";
import { formatValueWithPlaceholders } from "Core/Medius.Core.Web/Scripts/Medius/lib/stringFormat";

export class DateEditor extends Basic {

    ClearTriggerId: string;
    Options: any;
    OriginalValue: any;    

    constructor(bindingParameters: any, context: any, params: any) {
        const defaults = {
            template: "editors-date-default",
            format: "d",
            options: {
                defaultName: "editor-date",
                css: "input-medium",
                placeholder: (null as any),
            },
            emptyCheck: (value: any) => value === null,
            value: (null as any),
            updateIfInvalid: (null as any),
        };

        const validationMethod = (value?: any) => {
            const details = formatValueWithPlaceholders(getTranslation("#Core/incorrectFormatOfDate_format"), [defaults.options.placeholder]); 

            if (value === undefined) {
                return {
                    valid: false,
                    validationDetails: details, 
                    updateIfInvalid: defaults.updateIfInvalid,
                    value: (null as any)
                };
            }
            return true;
        };

        // only D and d is available
        mergeDefaults(bindingParameters, defaults);
        const dateLocalizer = createDateLocalizer(defaults.format);
        params = {...params, validator: validationMethod, localizer: dateLocalizer };

        defaults.options.placeholder = defaults.options.placeholder || dateLocalizer.getDateFormat();
        const originalValue = defaults.value();

        if (originalValue instanceof Date && originalValue.getFullYear() < 1900)
            defaults.value(null);
        
        super(defaults, context, params);

        this.ClearTriggerId = (this.Options.id)
            ? [this.Options.id, "clear"].join("-")
            : null;
    }   

    clear() {
        this.OriginalValue(null);
    }
}

export function create(bindingParameters: any, context: any, params: any){
    return new DateEditor(bindingParameters, context, params);
}

export const derive = DateEditor;
