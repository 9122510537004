/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/default/text/indexedFields"/>
import { unpack } from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as typeBoundResolverFactory from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/typeBound";
import { describe } from "Core/Medius.Core.Web/Scripts/Medius/core/type";
import * as _ from "underscore";
import { getPropertyAttributes } from "Core/Medius.Core.Web/Scripts/Medius/components/resolver/type";

const labelAttribute = "Medius.IndexSearch.FieldAttribute";

export const resolver = typeBoundResolverFactory.create({
    priority: typeBoundResolverFactory.priority.lowest + 500,
    type: "Medius.Data.Entity",
    resolve: function (data: any) {
        const entityType = unpack(data.$type),
            properties = _.without(_.keys(describe(entityType).Properties), "$type"),
            labelProperties = _.filter(properties, function (property) {
                const attributes = getPropertyAttributes(entityType, property);
                if (_.some(attributes, function (attribute) { return attribute.Type.FullName === labelAttribute; })) {
                    return true;
                }
                else {
                    return false;
                }
            });

        if (!labelProperties.length) {
            return null;
        }

        return {
            text: _.map(labelProperties, function (labelProperty) {
                return unpack(data[labelProperty]);
            }).join(", ")
        };
    }
});

