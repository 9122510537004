/// <amd-module name="Core/Medius.Core.Web/Scripts/components/administrationEditor/editorTab"/>
import {observable, pureComputed, unwrap} from "knockout";
import { hasTemplate, getTemplate } from "Core/Medius.Core.Web/Scripts/AdminPages/tabTemplateProvider";
import {EditorTabDto} from "./EditorTabDto";

export default class EditorTab {
    public ForType: ko.Observable<string>;
    public Id: ko.Observable<string>;
    public ViewName: ko.Observable<string>;
    public Position: ko.Observable<number>;
    public TabName: ko.Observable<string>;
    public RelatedType: ko.Observable<string>;
    public IsActive: ko.Observable<boolean>;
    public IsDefault: ko.Observable<boolean>;
    public RelatedProperty: ko.Observable<string>;
    public RequiresEntitySave: ko.Observable<boolean>;
    public ExtraAuthorizationType: ko.Observable<string>;
    public tabViewPath: ko.Computed<string>;

    public IsLoading = observable(false);
    public isDropdown = observable(false);
    public tabViewModel: ko.Observable<any>;
    public tabId: ko.Computed<string>;
    public tabHref: ko.Computed<string>;
    public attributes: ko.Computed<any>;

    constructor(tabData: EditorTabDto, defaultViewModel: any) {
        this.Id = observable(tabData.Id); 
        this.ForType = observable(tabData.ForType); 
        this.ViewName = observable(tabData.ViewName);
        this.Position = observable(tabData.Position);
        this.TabName = observable(tabData.TabName); 
        this.RelatedType = observable(tabData.RelatedType); 
        this.IsActive = observable(tabData.IsActive); 
        this.IsDefault = observable(tabData.IsDefault); 
        this.RelatedProperty = observable(tabData.RelatedProperty); 
        this.RequiresEntitySave = observable(tabData.RequiresEntitySave); 
        this.ExtraAuthorizationType = observable(tabData.ExtraAuthorizationType);
        this.tabId = pureComputed(() => `task-tab-${this.Id()}`); 
        this.tabHref = pureComputed(() => `#${this.tabId()}`);
        this.tabViewModel = observable(defaultViewModel);
        this.tabViewPath = pureComputed(() =>
            this.tabViewModel() && unwrap(this.tabViewModel().EntityWithContext.Entity.Id) === 0 && this.RequiresEntitySave() ?
                 "Display/SaveToEditView" :
                 `Display/${this.RelatedType() || this.ForType() || "Medius.Data.Entity"}/${this.ViewName()}`
        );
        this.attributes = pureComputed(() => {
            return {
                "id": this.tabId(),
                "data-tab-id": this.tabId()
            };
        });
    }

    public hasPureHtmlTemplate() {
        return hasTemplate(this.ForType(), this.Id());
    } 

    public getTemplate() {
        return getTemplate(this.ForType(), this.Id());
    } 
}
