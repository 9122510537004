/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/kendo/bindings/kendoConnectedLists"/>
import * as $ from "jquery";
import * as ko from "knockout";
import { addDisposeCallback, registerBinding } from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";

class KendoConnectedLists {
    public static bind($div: JQuery, bindingParams: any): void {
        const bindingOptions = ko.unwrap(bindingParams.options) as IKendoConnectedListsBindingOptions;

        const leftListBindingOptions = ko.unwrap(bindingOptions.left) as IKendoConnectedListsListBindingOptions;
        const rightListBindingOptions = ko.unwrap(bindingOptions.right) as IKendoConnectedListsListBindingOptions;

        const leftListId: string = kendo.guid();
        const rightListId: string = kendo.guid();

        const leftListOptions: kendo.ui.ListBoxOptions = {
            toolbar: {
                tools: ["transferTo", "transferFrom"]
            },
            draggable: true,
            connectWith: rightListId,
            dropSources: [rightListId],
            selectable: "multiple",
            dataTextField: bindingOptions.displayValueField,
            dataValueField: bindingOptions.dataValueField,
            reorder: e => e.preventDefault(),
            dataSource: leftListBindingOptions.data,
        };

        const rightListOptions: kendo.ui.ListBoxOptions = {
            draggable: true,
            connectWith: leftListId,
            dropSources: [leftListId],
            selectable: "multiple",
            dataTextField: bindingOptions.displayValueField,
            dataValueField: bindingOptions.dataValueField,
            reorder: e => e.preventDefault(),
            dataSource: rightListBindingOptions.data,
        };

        $div.addClass("k-connected-lists");

        const leftListElement = $(
            `<section class="k-connected-lists-left">
                        <header>${leftListBindingOptions.header}</header>
                        <select id="${leftListId}"></select>
                    </section>`).appendTo($div);

        const rightListElement = $(
            `<section class="k-connected-lists-right">
                        <header>${rightListBindingOptions.header}</header>
                        <select id="${rightListId}"></select>
                    </section>`).appendTo($div);

        const leftList = leftListElement.find(`#${leftListId}`).kendoListBox(leftListOptions).data("kendoListBox");
        const rightList = rightListElement.find(`#${rightListId}`).kendoListBox(rightListOptions).data("kendoListBox");

        this.registerDisposeFor($div, "kendoListBox", leftListId, rightListId);

        if (bindingOptions.keepSorted) {
            KendoConnectedLists.keepSorted(leftList);
            KendoConnectedLists.keepSorted(rightList);
        }
    }

    public static registerDisposeFor($element: JQuery, widgetName: string, leftListId: string, rightListId: string): void {
        const htmlNode = $element[0];
        addDisposeCallback(htmlNode, () => {
            const leftList = $($element.find(`#${leftListId}`)[0]).data(widgetName);
            if (leftList)
                leftList.destroy();

            const rightList = $($element.find(`#${rightListId}`)[0]).data(widgetName);
            if (rightList)
                rightList.destroy();

            $element.empty();
        });
    }

    public static keepSorted(listBox: kendo.ui.ListBox): void {
        const handler = (e: kendo.data.DataSourceChangeEvent) => {
            if (e.action === "add") {
                listBox.dataSource.unbind("change", handler);
                listBox.dataSource.sort({ field: listBox.options.dataTextField, dir: "asc" });
                listBox.refresh();
                listBox.dataSource.bind("change", handler);
            }
        };

        listBox.dataSource.bind("change", handler);
    }

    public static registerKnockoutBinding() {
        registerBinding("kendoConnectedLists", {
            init(element: HTMLElement, valueAccessor: any) {
                const $element = $(element);

                if (!$element.is("div"))
                    throw new Error("Binding 'kendoConnectedLists' can be applied only on a DIV");

                KendoConnectedLists.bind($element, valueAccessor());
            }
        });
    }
}

KendoConnectedLists.registerKnockoutBinding();

export = null;