/// <amd-module name="Core/Medius.Core.Web/Scripts/lib/date/date" />

import * as backendErrorHandler from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import {get} from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rest";
import { fromUserLocal } from "Core/Medius.Core.Web/Scripts/Medius/lib/utils/date";
import * as moment from "moment";

let userTimeProvider: UserTimeProvider|null = null;

export function getUserTimeProvider(): UserTimeProvider|null {
    return userTimeProvider;
}

function setUserTimeProvider() {
    get("users", "current/time-zone")
        .done((data: ITimeZone) => {
            userTimeProvider = new UserTimeProvider(data);
        })
        .fail((xhr: any) => backendErrorHandler.handleAnyError(xhr));

}

class UserTimeProvider {
    private readonly timeZone: ITimeZone;

    constructor(timeZone: ITimeZone) {
        this.timeZone = timeZone;
    }

    public toUserTimeZone(utcDate: Date): Date|null {
        if (utcDate === null || utcDate === undefined)
            return null;

        const momentDate = moment.utc(utcDate.getTime()).utcOffset(this.timeZone.offsetInMinutes);
        return new Date(Date.UTC(momentDate.year(),
            momentDate.month(),
            momentDate.date(),
            momentDate.hour(),
            momentDate.minute(),
            momentDate.second(),
            momentDate.millisecond()));
    }

    public toUserTimeZoneFromBrowserDate(browserDate: Date): Date|null {
        const utcDate = fromUserLocal(browserDate);
        return this.toUserTimeZone(utcDate);
    }

    public toUserTimeZoneFromMediusDateFormat(mediusDateFormat: Date) : Date|null{
        const date = mediusDateFormat.toJSON();
        const year = parseInt(date.substring(0,4));
        const month = parseInt(date.substring(5,7));
        const day = parseInt(date.substring(8,10));
        const hour = parseInt(date.substring(11,13));
        const minute = parseInt(date.substring(14,16));
        const second = parseInt(date.substring(17,19));

        return this.toUserTimeZone(new Date(year, month-1, day, hour, minute, second));
    }
}

interface ITimeZone {
    id: string;
    offsetInMinutes: number;
}

setUserTimeProvider();