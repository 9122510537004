/// <amd-module name="Core/Medius.Core.Web/Scripts/Models/FinanceComponentDto/register"/>
import mappings = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/mapping/collection/instance");
import * as unitPriceFactory from "Core/Medius.Core.Web/Scripts/Models/FinanceComponentDto/unitpriceFactory";
import * as quantityFactory from "Core/Medius.Core.Web/Scripts/Models/FinanceComponentDto/quantityFactory";

export function register() {
    const factories = [unitPriceFactory, quantityFactory];

    factories.forEach(function (factory) {
        mappings.register(
            factory.creatingType(),
            { make: factory.create });
    });
}
