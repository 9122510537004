/// <amd-module name="Core/Medius.Core.Web/Scripts/customTabs/definitions/contracts/companyAutocompleter/companyAutocompleter"/>

import * as React from "react";
import { ComboBox, ComboBoxChangeEvent, ComboBoxFilterChangeEvent, ComboBoxOpenEvent, ComboBoxCloseEvent } from "@progress/kendo-react-dropdowns";
import dataLoadingComponentFactory from "Core/Medius.Core.Web/Scripts/customTabs/definitions/contracts/dataLoadingComponentFactory";
import { isEnterEvent, isArrowEvent, isFilterEvent} from "Core/Medius.Core.Web/Scripts/customTabs/definitions/contracts/eventActions";

export interface ICompanyAutocompleterProps<T> {
    required: boolean;
    disabled?: boolean;
    label: string;
    textFieldSelector: string;
    selectedItem: T;
    loadedCompanies: T[];
    isDataLoading: boolean;
    onCompanyChange: (company: T) => void;
    onFilterChange: (filter: string) => void;
    onDropdownOpen: (event: ComboBoxOpenEvent) => void;
}

export function CompanyAutocompleter<T>(props: ICompanyAutocompleterProps<T>) {
    const { required, label, textFieldSelector, selectedItem, loadedCompanies, onCompanyChange, onFilterChange, onDropdownOpen, isDataLoading, disabled = false } = props;
    const [internalSelectedItem, setInternalSelectedItem] = React.useState(selectedItem);
    const handleCompanyChange = (event: ComboBoxChangeEvent) => {
        setInternalSelectedItem(event.value);
        if (!isArrowEvent(event.nativeEvent)) {
            onCompanyChange(event.value);
        }
    };

    const handleFilterChange = (event: ComboBoxFilterChangeEvent) => {
        if (!isFilterEvent(event.nativeEvent)) {
            return;
        }

        onFilterChange(event.filter.value);
    };
    const handleCompanyDropdownOpen = (event: ComboBoxOpenEvent) => onDropdownOpen(event);
    const handleCompanyDropdownClose = (event: ComboBoxCloseEvent) => {
        if (isEnterEvent(event.nativeEvent)) {
            onCompanyChange(internalSelectedItem);
        }
    };
    const handleFirstElementFocus = () => 0;

    return (
        <div data-testid="company-autocompleter">
            <ComboBox
                focusedItemIndex={handleFirstElementFocus}
                label={label}
                filterable
                required={required}
                data={loadedCompanies}
                value={internalSelectedItem}
                textField={textFieldSelector}
                disabled={disabled}
                popupSettings={{ width: '480px', popupClass: "for-selenium_company-autocompleter-list"}}
                onFilterChange={handleFilterChange}
                onChange={handleCompanyChange}
                onClose={handleCompanyDropdownClose}
                onOpen={handleCompanyDropdownOpen}
                listNoDataRender={dataLoadingComponentFactory(isDataLoading)}
            />
        </div>
    );
}