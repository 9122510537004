/// <amd-module name="Core/Medius.Core.Web/Scripts/components/card/Loader"/>
import * as React from "react";

export default function Loader(props: ILoaderProps) {
    return (
        <div className="loader-component">
            <div className="loader-component__text">{props.message ? props.message : "Please wait..."}</div>
            <div className="loader-component__spinner">
                <div className="loader_rectangle1"></div>
                <div className="loader_rectangle2"></div>
                <div className="loader_rectangle3"></div>
                <div className="loader_rectangle4"></div>
                <div className="loader_rectangle5"></div>
            </div>
        </div>
    );
}

interface ILoaderProps {
    message: string;    
}
