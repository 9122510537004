///<amd-module name = "Core/Medius.Core.Web/Scripts/Models/Medius.Core.Entities.Workflow.TaskResult.TaskResult"/>
import * as ko from "knockout";
import * as type from "Core/Medius.Core.Web/Scripts/Medius/core/type";
import mappings = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/mapping/collection/instance");
import * as customHandlersProvider from "Core/Medius.Core.Web/Scripts/Medius/core/customTaskHandlers/customTaskHandlersProvider";

export function extender(vm:any) {
    vm.getCustomHandler = function (task:any) {
        return customHandlersProvider.getHandler(vm, task);
    };

    vm.resultName = ko.computed(function () {
        return type.getTypeName(vm.$type());
    });
}

export function register() {
    mappings.register("Medius.Core.Entities.Workflow.TaskResult.TaskResult", extender);
}