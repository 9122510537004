/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/authorizationKeys"/>
import { ajax } from "Core/Medius.Core.Web/Scripts/Medius/core/rpc";
import * as serialization from "Core/Medius.Core.Web/Scripts/Medius/lib/serialization";

let authorizationKeys: string[] = [];
let request: any;

export function isUserAuthorized(key: string) { return authorizationKeys.indexOf(key) !== -1; }

export function isUserAuthorizedPromise(key: string): JQueryPromise<boolean> {
    return request.then(() => {
        return authorizationKeys.indexOf(key) !== -1;
    });
}

export function isUserAuthorizedInCompany(key: string, companyId: number) {
    return ajax("SettingsManager", "HasUserAuthorizationKeyInCompany", { data: serialization.toJSON({ key, companyId }) });
}
    
export function init() {
    request = ajax("SettingsManager", "GetAuthorizationKeys", null)
        .then((authKeys: any) => { authorizationKeys = authKeys; });

    return request;
}

export const authorizationKeysConstants = {
    AnalyzeDeviationsInBulk: "Medius.OrderbasedInvoice.AuthorizationKeys.AnalyzeDeviationsInBulk",
    AuthorizeDeviationsInBulk: "Medius.OrderbasedInvoice.AuthorizationKeys.AuthorizeDeviationsInBulk",
    CanManageEscalations: "Medius.Enterprise.AuthorizationKeys.Administration.CanManageEscalations",
    PutOnHold: "Medius.Core.AuthorizationKeys.PutOnHold",
    CopyCodingFromExcel: "Medius.OrderbasedInvoice.AuthorizationKeys.CopyCodingFromExcel",
    CanPurgeInvoices: "Medius.Core.AuthorizationKeys.CanPurgeInvoices",
    ShowSupplierContractsForInvoice: "Medius.Apa.Infrastructure.ContractsForInvoices.AuthorizationKey.ShowSupplierContractsForInvoice",
    ExpenseInvoiceInvalidateInvoice: "Medius.ExpenseInvoice.AuthorizationKeys.InvalidateInvoice",
    OrderbasedInvoiceInvalidateInvoice: "Medius.OrderbasedInvoice.AuthorizationKeys.InvalidateInvoice"
};
