/// <amd-module name="Core/Medius.Core.Web/Scripts/bindings/counter/attach"/>
import {unwrap, isWriteableObservable, isSubscribable, utils} from "knockout";
import {registerBinding} from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import { warn } from "Core/Medius.Core.Web/Scripts/Medius/lib/logger";

export function register() {
    const counterAttach = {
        init(element: HTMLElement, valueAccessor: ko.Observable<any>, allBindingsAccessor: unknown, viewModel: unknown, bindingContext: any) {
            const data = valueAccessor() || {},
                name = unwrap(data.name) || "default",
                source = data.source;
    
            if (!bindingContext.counters || !bindingContext.counters[name]) {
                warn(`Counter "${name}" is not defined`);
                return;
            }
    
            const counterModel = bindingContext.counters[name];
            counterModel.aquire();
            const counter = counterModel.getCounter();
            counterModel.counterTemplate(data.counterTemplate ?? "Core:bindings/counter/defaultTemplate.html");
            counterModel.counterData(data.counterData);
    
            if (!source || !isSubscribable(source)) {
                throw new Error("Source must be subscribable");
            }
    
            counter((source as ko.Observable<any>)());
    
           const readSourceSubscription = source.subscribe((newValue) =>{
                counter(newValue);
            });
    
            let readCounterSubscription: ko.Subscription;
            if (isWriteableObservable(source)) {
                readCounterSubscription = counter.subscribe((newValue: any) => {
                    source(newValue);
                });
            }
    
    
            utils.domNodeDisposal.addDisposeCallback(element, () => {
                if (readSourceSubscription) {
                    readSourceSubscription.dispose();  
                }
                if (readCounterSubscription) {
                    readCounterSubscription.dispose();
                }
                counterModel.release();
            });
        }
    };

    registerBinding("counterAttach", counterAttach);
}
