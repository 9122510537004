/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/customTaskHandlers/customTaskHandlersProvider" />
import { find } from "underscore";

type canHandlePredicate = (result: any, task: any) => boolean;
type Handler = {
    canHandle: canHandlePredicate;
    handle: (task: any, result: any, data: any) => any;
};

export const handlers: Handler[] = [];
export const frontendHandlers: canHandlePredicate[]  = [];

export function addHandler(handler: Handler, willHandleAlsoFrontend?: boolean) {
    handlers.push(handler);
    if(willHandleAlsoFrontend) {
        frontendHandlers.push(handler.canHandle);
    }
}

export function getHandler(result: any, task: any, data?: any) {
    const handler = find(handlers, (handler) => handler.canHandle(result, task));
    return handler && handler.handle(task, result, data);
}

export function hasCustomizedInboxHandling(result: any, task: any) {
    return frontendHandlers.some(x => x(result,task));
}