///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/mapping/collection/implementation" />
import * as typeModule from "Core/Medius.Core.Web/Scripts/Medius/core/type";
import { mapFactory } from "Core/Medius.Core.Web/Scripts/Medius/lib/map";
import * as _ from "underscore";

function defaultFallback() {
    return "default";
}

export function dataTransferMappingCollectionFactory() {
    let map = mapFactory();
    const exports: any = {
        reset: function() {
            map = mapFactory();
        },
        register: function(type: any, condition: any, mapping: any) {
            if (condition && !mapping) {
                mapping = condition;
                condition = defaultFallback;
            }

            switch (typeof mapping) {
                case "object":
                    break;
                case "function":
                    mapping = {
                        construct: mapping
                    };
                    break;
                default:
                    throw new Error("Unexpected mapping type, should be either object or function");
            }

            const shortName = typeModule.getTypeName(type);
            const typeMapping = map.ensure(shortName, []);

            typeMapping.push({
                condition: condition,
                mapping: mapping
            });
        },
        unregister: function(type: any, condition: any) {
            const shortName = typeModule.getTypeName(type);
            const typeMapping = map.ensure(shortName, []);
            map.set(shortName, _(typeMapping).reject(function(mapping) {
                return mapping.condition === condition;
            }));
        },
        resolve: function(type: any, context: any, data: any) {
            return tryResolve(type, context, type, data);
        }
    };

    function getParentMapping(type: any, context: any, topType: any): any {
        const hierarchy = typeModule.getHierarchy(type);
        if (hierarchy.length > 1) {
            return tryResolve(hierarchy[1], context, topType, null);
        }
        return null;
    }

    function tryResolve(type: any, context: any, topType: any, data: any) {
        const shortName = typeModule.getTypeName(type);
        let mappings = map.get(shortName);

        if (!mappings) {
            return getParentMapping(type, context, topType);
        }

        mappings = _.map(mappings, v => {
            return { mapping: v.mapping, condition: v.condition(context, data) };
        });

        const availableMappings = _.filter(mappings, v => {
            return v.condition === true;
        });

        const defaultMapping = _.filter(mappings, function (v) {
            return v.condition === defaultFallback();
        });

        switch (availableMappings.length) {
            case 0:
                if (defaultMapping.length) {
                    return defaultMapping[0].mapping;
                } else {
                    return getParentMapping(type, context, topType);
                }
            case 1:
                return availableMappings[0].mapping;
            default:
                throw new Error("More than one mapping match current condition");
        }
    }

    return exports;
}