/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/grid/ui/userConfigRepository" /> 

import * as $ from "jquery";
import * as _ from "underscore";
import { isEmptyString } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
const keyWidth = "WidthStorageId";
const keyVisible = "VisibleStorageId";
const keyPageSize = "PageSizeStorageId";

function loadFromStore(store: any, columns: any, key: any, onRead: any) {        
    if (!store) {
        return $.when();
    }
    
    const deferred = $.Deferred();

    _.chain(columns)
        .reject(function (column) {
            return isEmptyString(column[key]);
        })
        .map(function (column) {
            return $.when(store.getItem(column[key])).pipe(function (storedValue) {
                onRead(column, storedValue);
                return storedValue;
            });
        })
        .each((readOperation: JQueryPromise<any>) => {
            deferred.pipe(readOperation as any);
        });

    return deferred.resolve();
}

export function loadPageSize(store: any, grid: any) {
    if (!store) {
        return $.when();
    }

    return $.when(store.getItem(grid["PageSizeStorageId"])).pipe(function (storedValue) {
        applyPageSize(grid, storedValue);
        return storedValue;
    });
}

function saveToStore(store: any, column: any, value: any, key: any) {
    if (store && !isEmptyString(column[key])) {
        store.setItem(column[key], value);
    }
}

function applyColumnWidth(column: any, storedValue: any) {
    const storedColumnWidth = parseInt(storedValue, 10);

    if (!(_.isNaN(storedColumnWidth) || _.isUndefined(storedColumnWidth))) {
        column.Width = storedColumnWidth;
    }
}

function applyPageSize(grid: any, storedValue: any) {
    const storedPageSize = parseInt(storedValue, 10);

    if (!(_.isNaN(storedPageSize) || _.isUndefined(storedPageSize))) {
        grid.ChosenPageSize(storedPageSize);
    }
}

function applyColumnVisible(column: any, storedValue: any) {
    if (isEmptyString(storedValue)) {
        return;
    }

    const storedColumnVisibleState = JSON.parse(storedValue);
    column.Visible(storedColumnVisibleState);
}

export function loadColumnsWidth(store: any, columns: any) {
    return loadFromStore(store, columns, keyWidth, applyColumnWidth);
}

export function saveColumnWidth(store: any, column: any, value: any) {
    saveToStore(store, column, value, keyWidth);
}

export function loadColumnsVisible(store: any, columns: any) {
    return loadFromStore(store, columns, keyVisible, applyColumnVisible);
}

export function saveColumnVisible (store: any, column: any, value: any) {
    saveToStore(store, column, value, keyVisible);
}

export function savePageSize(store: any, grid: any, value: any) {
    saveToStore(store, grid, value, keyPageSize);
}