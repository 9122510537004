/// <amd-module name="Core/Medius.Core.Web/Scripts/bindings/counter/init"/>
import {registerBinding} from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";


export function register() {
    const countersInit = {
        init(element: HTMLElement, valueAccessor: unknown, allBindingsAccessor: unknown, viewModel: unknown, bindingContext: any) {
            if (!bindingContext.counters) {
                bindingContext.counters = {};
            }
        }
    };
    
    registerBinding("countersInit", countersInit);
}
