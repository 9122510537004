///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/editors/bindingContinuation"/>
import * as ko from "knockout";

export = function (model:any, params:any, element:any, bindingAccessor:any, allBindingsAccessor:any, viewModel:any, bindingContext:any) {
    ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
        if (bindingContext.validationGroup) {
            bindingContext.validationGroup.unregisterEditorComponent(model);
        }
    });

    if (bindingContext.validationGroup) {
        bindingContext.validationGroup.registerEditorComponent(model);
    }
};