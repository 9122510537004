///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Entities.Role/viewModelExtensions/roleDataObjectAutohorization"/>
import mappings = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/mapping/collection/instance");
import { unpack } from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";

function condition(context:any) {
    const specificContext = context.get('administration-context');
    return specificContext && specificContext.entityType === "Medius.Core.Entities.Role";
}

function mapViewModelPropertyToReference(viewModel:any) {
    viewModel = unpack(viewModel);
    
    if (!viewModel) {
        return null;
    }
    
    return {
        $type: unpack(viewModel.$type),
        Id: unpack(viewModel.Id),
        ViewId: unpack(viewModel.ViewId),
        EntityVersion: unpack(viewModel.EntityVersion)
    };
}

export function register() {
    mappings.register("Medius.Core.Entities.Security.RoleDataObjectAuthorization", condition, {
        toJS: function (viewModel:any/*, context*/) {
            return {
                $type: unpack(viewModel.$type),
                Id: unpack(viewModel.Id),
                ViewId: unpack(viewModel.ViewId),
                EntityVersion: unpack(viewModel.EntityVersion),
                AccessControlGroup: mapViewModelPropertyToReference(viewModel.AccessControlGroup),
                DataObject: mapViewModelPropertyToReference(viewModel.DataObject),
                IsConfigurable: unpack(viewModel.IsConfigurable),
                IsDeleted: unpack(viewModel.IsDeleted),
                Role: mapViewModelPropertyToReference(viewModel.Role)
            };
        }
    });
}