///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/grid/columnSelector/columnSelector"/>
import * as _ from "underscore";
import * as ko from "knockout";
import * as helpers from "Core/Medius.Core.Web/Scripts/Medius/components/editors/helpers";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization"
import * as defaults from "Core/Medius.Core.Web/Scripts/Medius/components/grid/columnSelector/defaults";
import * as propertiesLoader from "Core/Medius.Core.Web/Scripts/Medius/components/grid/columnSelector/propertiesLoader";

const propertiesLoaderInstance = propertiesLoader.create();

class ColumnSelector {
    options: any;
    Type: any;
    DocumentProperties: any;
    ButtonLabel: any;
    selectedCollection: any;
    limitCollectionAccess: any;
    pickColumn: (property: any) => void;
    constructor(bindingParameters:any) {
        this.options = defaults.get();
        helpers.mergeDefaults(bindingParameters, this.options);
    
        const onColumnPicked = (_.isFunction(this.options.onColumnPicked)) ? this.options.onColumnPicked : function() {};
        this.Type = this.options.type || null;
        this.DocumentProperties = ko.observableArray([]);
        this.ButtonLabel = globalization.getLabelTranslation(this.options.buttonLabel);
        this.selectedCollection = this.options.selectedCollection || null;
        this.limitCollectionAccess = this.options.limitCollectionAccess;
        
        // pick should be called on ColumnSelector object (not on single property)
        this.pickColumn = function (property) {
            onColumnPicked(property.absolutePath);
        };
    }

    open() {
        return propertiesLoaderInstance.loadProperties(this.Type, this.selectedCollection, this.limitCollectionAccess)
            .pipe((properties:any) => {
                this.DocumentProperties(properties);
                return properties;
            });
    }

    destroy() {
        this.DocumentProperties([]);
    }
}

export function create(bindingParameters:any) {
    return new ColumnSelector(bindingParameters);
}