///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/grid/dataSource/dataSearch" />

import * as _ from "underscore";
import * as rpc from "Core/Medius.Core.Web/Scripts/Medius/core/rpc";
import * as rest from "Core/Medius.Core.Web/Scripts/Medius/core/rest";
import * as dataSearchQueryBuilder from "Core/Medius.Core.Web/Scripts/Medius/components/grid/dataSource/dataSearchQueryBuilder";
import * as restCommunication from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rest";
import DefaultDataSource = require("Core/Medius.Core.Web/Scripts/Medius/components/grid/dataSource/default");
import * as propertyTypeHelper from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/helpers/propertyType";
import pendingAsyncExports = require("Core/Medius.Core.Web/Scripts/Medius/core/export/async/pending");

export class DataSearchSource extends DefaultDataSource{
    constructor(){
        super();
    }

    loadColumns(entityDataQuery: any){
        return rpc.ajax("SettingsManager", "GetColumnConfigurations", {
            data: JSON.stringify({
                entityFullName: entityDataQuery.entityType
            })
        }).pipe((columns) => {
            if (columns.length === 0) {
                columns.push({ Name: "Id", ValueSource: "Id" });
            }

            _.each(columns, function (col) {
                col.ValueSourceType = propertyTypeHelper.getPropertyType(entityDataQuery.entityType, col.ValueSource);
            });

            return columns;
        });
    }

    load(entityDataQuery: any) {
        let companyId: string;

        if (!entityDataQuery) {
            throw new Error("load(): EntityDataQuery not defined.");
        }

        const query = encodeURIComponent(this.buildQuery(entityDataQuery));
        let method = "searchPagedEntities";
        if (entityDataQuery.mapToDto) {
            method = "searchPaged";
        }

        let resourceUrl = `${method}?query=${query}&page=${entityDataQuery.page}&limit=${entityDataQuery.pageSize}`;

        if (entityDataQuery.isCompanyRelated && entityDataQuery.companyContextId) {
            companyId = entityDataQuery.companyContextId;
            resourceUrl += "&companyId=" + companyId;
        }
        
        return rest.get("DataSearchManager", resourceUrl);
    }

    exportData(entityDataQuery: any/*, properties*/) {
        const query = this.buildQuery(entityDataQuery);
        let parameters = "exports?exportTo=excel";
        let companyId: string;

        if (entityDataQuery.isCompanyRelated && entityDataQuery.companyContextId) {
            companyId = entityDataQuery.companyContextId;
            parameters += "&companyId=" + companyId;
        }

        return (pendingAsyncExports as any).wrapAsyncExportTask(restCommunication.post(
            "DataSearchManager", parameters, query
        ));
    }

    downloadImagesToPdf(entityDataQuery: any, selectedIds: any) {
        entityDataQuery.keywords.Id = selectedIds;
        const query = encodeURIComponent(this.buildQuery(entityDataQuery));

        window.location.href = rest.createUrl("DataSearchManager", "exportImages?query=" + query);
    }
    
    buildQuery(entityDataQuery: any) {
        const queryBuilder = dataSearchQueryBuilder.create(entityDataQuery)
                    .buildColumns()
                    .buildSearchQuery()
                    .buildCreatedDateFilter()
                    .buildChangedDateFilter()
                    .buildSortQuery();
        return queryBuilder.getQuery();
    }
}