/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/DocumentCacheManagement/documentCacheManagamentState"/>
import {DocumentCache, DocumentCachesDto, DocumentCacheDto} from "./interfaces";
import {getPropertyTranslation} from "Core/Medius.Core.Web/Scripts/lib/globalization";

const LOAD_DATA = 'LOAD_DATA';
const SELECT_ROW = 'SELECT_ROW';

interface LoadDataAction {
    type: typeof LOAD_DATA;
    data: DocumentCachesDto;
}

interface SelectRowAction {
    type: typeof SELECT_ROW;
    selectedRowId: number;
}

export function documentCacheManagementReducer(state: {data: DocumentCache[]; total: number}, action: LoadDataAction | SelectRowAction) {
    switch (action.type) {
        case LOAD_DATA:
            return {
                data: action.data.documents.map(createDocumentCache),
                total: action.data.totalCount,
                lastUpdated: action.data.lastUpdated ? new Date(action.data.lastUpdated) : null
            };
        default:
          throw new Error();
      }
}

export function selectRow(selectedRowId: number) {
    return {type: SELECT_ROW, selectedRowId} as const;
}

export function loadData(data: DocumentCachesDto) {
    return { type: LOAD_DATA, data} as const;
}

function createDocumentCache(item: DocumentCacheDto): DocumentCache {
    return {
        ...item,
        documentCreatedTimestamp: item.documentCreatedTimestamp ? new Date(item.documentCreatedTimestamp) : null,
        documentType: getPropertyTranslation(item.type)
    };
}
