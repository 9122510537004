/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/inbox/documentOrder"/>
const documentOrder: {[key: string]: number} = {
    "Medius.ExpenseInvoice.Entities.PaymentRequest": 1,
    "Medius.Core.Platform.Inbox.Draft.DraftInboxEntity": 2,
    "Medius.PurchaseToPay.Platform.CCF.Inbox.CaptureDocumentInboxEntity": 3,
    "Medius.PurchaseToPay.Platform.EdiTaskHandling.Inbox.EdiDocumentImportInboxEntity": 4,
    "Medius.ExpenseInvoice.Entities.ExpenseInvoice": 5,
    "Medius.OrderbasedInvoice.Entities.OrderbasedInvoice": 6,
    "Medius.Apa.Persistence.PrepaymentInvoice.PrepaymentSupplierInvoice": 7,
    "Medius.ContractbasedInvoice.Entities.ContractbasedInvoice": 8,
    "Medius.Procurement.Entities.PurchaseRequisition": 9,
    "Medius.Procurement.Entities.PurchaseOrderDocument": 10,
    "Medius.PurchaseToPay.Entities.AdjustmentRequest.AdjustmentRequest": 11,
    "Medius.PurchaseToPay.DocumentApproval.Entities.DocumentApproval": 12,
    "Medius.PurchaseToPay.Entities.ShortPayment.CreditMemo": 13,
    "Medius.Procurement.Platform.Inbox.PurchaseRequisitionInboxEntity": 14,
    "Medius.Contract.Entities.SupplierContractDocument": 15,
    "Medius.PurchaseToPay.Entities.SupplierInvoice": 16,
    "Medius.Core.QA.Entities.SeleniumData.CompanyRelatedQaDocument": 17,
    "Medius.Core.QA.Entities.BuisnessRepositoryData.CompanyRelatedDocument": 18,
    "Medius.Core.QA.Entities.DocumentWithFetchOnDemand.FetchOnDemandQaDocument": 19,
    "Medius.Core.QA.Entities.SeleniumData.QaDocumentWithAmount": 20,
    "Medius.Core.QA.Entities.SeleniumData.ReclassifiableDocumentA": 21,
    "Medius.Core.QA.Entities.SeleniumData.ReclassifiableDocumentB": 22,
    "Medius.Core.Entities.QA.EntityMappingTests.SomeDocument": 23
};

export function getDocumentOrderForType(type: string) {
    // for non standard document types (e.g. from Selenium tests) value which will place document in the end
    return documentOrder[type] ? documentOrder[type] : 24;
}

export function getAllDocumentTypes() {
    return Object.keys(documentOrder);
}
