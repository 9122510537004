/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/customTaskHandlers/abortImportHandler"/>
import {addHandler} from "Core/Medius.Core.Web/Scripts/Medius/core/customTaskHandlers/customTaskHandlersProvider";
import {request} from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rpc";

export function register() {
    addHandler({
        canHandle: function (vm: any, _task: any) {
            return vm.Code && vm.Code() === "abortImport";
        },
        handle: function (task: any, taskResult: any) {
            return function () {
                return request("TaskManager", "CompleteTaskById", {
                    taskId: task.Id(),
                    taskResult: taskResult
                });
            };
        }
    });
}
