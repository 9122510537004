///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/task/RiskFactor/riskFactorUtils"/>

import { RiskFactorCode } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/RiskFactor/RiskFactorComponentDtos/riskFactorsForInvoiceDto";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";

export function getRiskFactorNameTranslation(RFCode: RiskFactorCode) {
    switch (RFCode) {
        case RiskFactorCode.FirstTimeInvoice:
            return getLabelTranslation("#Core/riskFactorNameFirstTimeInvoice");
        case RiskFactorCode.BankAccountValidationMasterData:
            return getLabelTranslation("#Core/riskFactorNameBankAccountValidationMasterData");
        case RiskFactorCode.DuplicatedInvoice:
            return getLabelTranslation("#Core/riskFactorNameDuplicatedInvoice");
        case RiskFactorCode.FromEmailAddressChanged:
            return getLabelTranslation("#Core/riskFactorNameFromEmailAddressChanged");
        case RiskFactorCode.NewBankAccountDetected:
            return getLabelTranslation("#Core/riskFactorNameNewBankAccountDetected");
        case RiskFactorCode.NewOrganizationNumberDetected:
            return getLabelTranslation("#Core/riskFactorNameNewOrganizationNumberDetected");
        case RiskFactorCode.OrganizationNumberValidationMasterData:
            return getLabelTranslation("#Core/riskFactorNameOrganizationNumberValidationMasterData");
        case RiskFactorCode.RandomizedExtraApproval:
            return getLabelTranslation("#Core/riskFactorNameRandomizedExtraApproval");
    }
}