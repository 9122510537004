/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/inbox/bulk/step"/>
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as ko from "knockout";

import { Modal } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/bulk/modal";

export interface IStep {
    name: string;
    template: string;
    buttonNextLabel: string;
    isButtonYesVisible: ko.Observable<boolean>;
    isNextStepEnable: ko.Observable<boolean>;
    active: ko.Observable<boolean>;
    done: ko.Observable<boolean>;
    text: string;

    onOpen(modal: Modal): any;
    onOpenBack(modal: Modal): any;
    validate(modal: Modal): boolean;
    dispose(modal: Modal): any;
}

export class Step implements IStep {
    public name: string;
    public template: string;
    public buttonNextLabel: string;
    public isButtonYesVisible: ko.Observable<boolean>;
    public isNextStepEnable: ko.Observable<boolean>;
    public currentStepIndex: ko.Observable<number>;
    public active: ko.Observable<boolean>;
    public done: ko.Observable<boolean>;
    public text: string;

    public constructor(name: string, template: string) {
        this.name = name;
        this.template = template;
        this.buttonNextLabel = getLabelTranslation("#Core/next");
        this.isButtonYesVisible = ko.observable(false);
        this.isNextStepEnable = ko.observable(true);
        this.active = ko.observable(false);
        this.done = ko.observable(false);
    }

    public onOpen(modal: Modal) {}

    public onOpenBack(modal: Modal) {}

    public validate(modal: Modal): boolean {
        return true;
    }

    public dispose(modal: Modal) {}
}
