///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/transformers/create/cacheBuilder"/>
import * as defaults from "Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/transformers/create/defaults";

export = function cacheViewmodelBuild(input:any, mapping:any, context:any) {
    let key:any = null,
        viewmodel:any = null;
    if (mapping.key) {
        key = mapping.key(input, context);
    }

    if (key && context.cache) {
        viewmodel = context.cache.get(key);
    }

    if (!viewmodel) {
        return null;
    }

    return {
        ...defaults,
        shouldDescend: false,
        finalize: function() {
            return viewmodel;
        }
    };
};