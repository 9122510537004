///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/resolver/property"/>
import { getPropertyType } from "Core/Medius.Core.Web/Scripts/Medius/components/resolver/type";
import { unpack } from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import { error, warn } from "Core/Medius.Core.Web/Scripts/Medius/lib/logger";
import { getTypeName } from "Core/Medius.Core.Web/Scripts/Medius/core/type";
import * as _ from "underscore";

export function resolvePropertyNameFromValue(currentValue: any, viewModel: any) {
    let value: any;
    let key: any;
    for (key in viewModel) {
        value = viewModel[key];
        if (Object.keys(viewModel).includes(key) && currentValue === value) {
            return key;
        }
    }
    return null;
}

export function resolvePropertyName(currentValue: any, viewModel: any) {
    const name = resolvePropertyNameFromValue(currentValue, viewModel);

    if (_.isNull(name)) {
        throw new Error("Unable to resolve property name");
    }
    return name;
}

export function resolveEntityType(viewModel: any) {
    return unpack(viewModel.$type) || null;
}

export function resolvePropertyTypeFromValue(value: any) {
    value = unpack(value);

    if (_.isNull(value)) {
        return null;
    }

    switch (typeof (value)) {
        case "boolean":
            return "System.Boolean";
        case "number":
            return "System.Decimal";
        case "string":
            return "System.String";
        case "object": {
            if (value instanceof Date) {
                return "System.DateTime";
            }

            const objectType = getTypeName(unpack(value.$type));
            if (objectType) {
                return objectType;
            }
            break;
        }

        default:
            error("Unknown type of property.resolvePropertyTypeFromValue value argument: " +
                typeof value);
            break;
    }

    return null;
}

export function resolvePropertyTypeFromEntityModel(currentValue: any, viewModel: any) {
    const name = resolvePropertyName(currentValue, viewModel);
    const entityType = resolveEntityType(viewModel);

    if (name && entityType) {
        return getPropertyType(entityType, name);
    }

    warn(`
        Core/Medius.Core.Web/Scripts/Medius/components/resolver/property: 
        property from entity model ${name} (value: ${unpack(currentValue)}, entity: ${entityType}). 
        'System.String' returned instead.
    `);

    return null;
}

export function resolvePropertyType(currentValue: any, viewModel: any) {

    return (resolvePropertyTypeFromValue(currentValue) ||
        resolvePropertyTypeFromEntityModel(currentValue, viewModel) ||
        "System.String");

}
