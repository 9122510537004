///<amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/User/IdentityProvider/identityProviderDataSource"/>
import * as $ from "jquery";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import DataSource = require("Core/Medius.Core.Web/Scripts/Medius/components/grid/dataSource/default");

export default class IdentityProviderDataSource extends DataSource {
    public EntityCollection: any;

    constructor(entityCollection: any) {
        super();
        this.EntityCollection = entityCollection;
    }

    public load(dataQuery: any) {
        const startIndx = (dataQuery.page - 1) * dataQuery.pageSize;
        const endIndx = dataQuery.page * dataQuery.pageSize;
        const entities = this.EntityCollection.slice(startIndx, endIndx);

        return $.Deferred().resolve(entities);
    }

    public loadColumns() {
        const columns = [
            {
                ColumnName: globalization.getLabelTranslation("#Core/identityProviderNameColumnName"),
                ValueSource: "IdentityProviderName.Name",
                ValueSourceType: "System.String",
                Searchable: false,
                Sortable: false,
                Width: 300
            },
            {
                ColumnName: globalization.getLabelTranslation("#Core/identityProviderUserIdentifierColumnName"),
                ValueSource: "NameIdentifier",
                ValueSourceType: "System.String",
                Searchable: false,
                Sortable: false,
                Width: 300
            },
            {
                ColumnName: "",
                ValueSource: null as any,
                Searchable: false,
                Sortable: false,
                Width: 80
            }
        ];
        return $.Deferred().resolve(columns);
    }

    public getTotalRows() {
        return this.EntityCollection.length;
    }

    public destroy() {
        this.EntityCollection = null;
        super.destroy();
    }
}
