/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/kendo/bindings/kendoAutoComplete" />

import { KendoUtils } from "Core/Medius.Core.Web/Scripts/Medius/kendo/kendoUtils";
import * as ko from "knockout";
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";

interface IBindingValue {
    disabled?: ko.Observable<boolean> | boolean | undefined;
    options: ko.Observable<AutoCompleteOptions> | AutoCompleteOptions;
}

class KendoAutoComplete {
    private static readonly widgetName = "kendoAutoComplete";

    public static registerKnockoutBinding(): void {
        koUtils.registerBinding(
            KendoAutoComplete.widgetName,
            {
                init(element: HTMLElement, valueAccessor: any) {
                    const $element = $(element);
                    KendoUtils.ensureValidElement(KendoAutoComplete.widgetName, "input", $element);

                    const value = valueAccessor() as IBindingValue;
                    KendoAutoComplete.initializeBinding($element, ko.unwrap(value.disabled), ko.unwrap(value.options));
                },

                update(element: HTMLElement, valueAccessor: any) {
                    const value = valueAccessor() as IBindingValue;
                    const widget = KendoAutoComplete.getWidget($(element));
                    KendoAutoComplete.updateBinding(widget, ko.unwrap(value.disabled), ko.unwrap(value.options));
                }
            });
    }

    private static getWidget($element: JQuery): kendo.ui.AutoComplete {
        return $element.data(KendoAutoComplete.widgetName);
    }

    private static initializeBinding($element: JQuery, disabled: boolean, options: AutoCompleteOptions): void {
        const required = ko.unwrap(options.required);
        const placeholder = ko.unwrap(options.placeholder);
        const selectedItem = options.selectedItem;

        options.clearValidationErrors = () => KendoAutoComplete.clearValidationErrors(KendoAutoComplete.getWidget($element));
        options.validate = () => KendoAutoComplete.validateWidget(KendoAutoComplete.getWidget($element), required);

        const dataSourceOptions: kendo.data.DataSourceOptions = {
            transport: KendoAutoComplete.createTransport(options),
            pageSize: 24,
            serverPaging: true,
            serverFiltering: true
        };

        const kendoOptions: kendo.ui.AutoCompleteOptions = {
            dataSource: kendo.data.DataSource.create(dataSourceOptions),
            dataTextField: "displayValue",
            height: 300,
            select: KendoAutoComplete.eventHandlerForSelect(required, selectedItem),
            change: KendoAutoComplete.eventHandlerForChange(required, selectedItem),
            close: KendoAutoComplete.eventHandlerForChange(required, selectedItem),
            placeholder: globalization.getLabelTranslation(placeholder)
        };

        const currentAutoComplete = KendoAutoComplete.getWidget($element);
        if (currentAutoComplete)
            currentAutoComplete.destroy();

        $element.empty();
        $element.kendoAutoComplete(kendoOptions);
        const newAutoComplete = KendoAutoComplete.getWidget($element);
        KendoAutoComplete.setFocusHandler(newAutoComplete);
        newAutoComplete.enable(!disabled);

        KendoUtils.registerDisposeFor($element, KendoAutoComplete.widgetName);
    }

    private static updateBinding(widget: kendo.ui.AutoComplete, disabled: boolean, options: AutoCompleteOptions): void {
        const boundedItem = options.selectedItem();
        if (boundedItem) {
            widget.value(boundedItem.displayValue);
            if (!KendoAutoComplete.isSuggestedItem(widget, boundedItem))
                widget.dataSource.add(boundedItem);
        } else {
            widget.value("");
        }

        widget.enable(!disabled);
        widget.trigger("changed");
    }

    private static createTransport(options: AutoCompleteOptions): kendo.data.DataSourceTransport {
        return {
            read: (dataSourceTransportOptions: kendo.data.DataSourceTransportOptions) => {
                const data = dataSourceTransportOptions.data;

                let searchQuery = "";
                if (data.filter && data.filter.filters && data.filter.filters[0]) {
                    searchQuery = data.filter.filters[0].value.trim();
                }

                const request: AutoCompleteRequest = {
                    query: searchQuery,
                    page: data.page as number,
                    pageSize: data.pageSize as number,
                    skip: data.skip as number,
                    take: data.take as number
                };

                options.read(request)
                    .then(items => dataSourceTransportOptions.success(items))
                    .catch(error => dataSourceTransportOptions.error(error));
            }
        };
    }

    private static setFocusHandler(widget: kendo.ui.AutoComplete): void {
        widget.element.focus((e: JQueryEventObject) => {
            const currentWidget = KendoAutoComplete
                .getWidget($(e.target));

            currentWidget.search(
                currentWidget.value());
        });
    }

    private static eventHandlerForSelect(required: boolean, selectedItem: ko.Observable<AutoCompleteItem>): (e: kendo.ui.AutoCompleteSelectEvent) => void {
        return (e: kendo.ui.AutoCompleteSelectEvent) => {
            const widgetItem: AutoCompleteItem = {
                displayValue: e.dataItem.displayValue as string,
                value: e.dataItem.value
            };

            KendoAutoComplete.clearValidationErrors(e.sender);
            KendoAutoComplete.updateSelectedItem(widgetItem, required, selectedItem);
        };
    }

    private static eventHandlerForChange(required: boolean, selectedItem: ko.Observable<AutoCompleteItem>): (e: kendo.ui.AutoCompleteEvent) => void {
        return (e: kendo.ui.AutoCompleteEvent) => {
            const widget = e.sender;
            const widgetItem = KendoAutoComplete.getSelectedItem(widget);
            KendoAutoComplete.updateSelectedItem(widgetItem, required, selectedItem);

            if (widgetItem)
                widget.value(widgetItem.displayValue);

            KendoAutoComplete.validateWidget(widget, required);
        };
    }

    private static updateSelectedItem(widgetItem: AutoCompleteItem, required: boolean, selectedItem: ko.Observable<AutoCompleteItem>): void {
        if (KendoAutoComplete.alreadySelected(widgetItem, selectedItem))
            return;

        if (widgetItem)
            selectedItem(widgetItem);
        else if (!required)
            selectedItem(null);
    }

    private static getSelectedItem(widget: kendo.ui.AutoComplete): AutoCompleteItem {
        if (KendoAutoComplete.hasEmptyInput(widget))
            return null;

        return KendoAutoComplete.getExactlyMatchedItem(widget)
            || KendoAutoComplete.getSinglePartiallyMatchedItem(widget);
    }

    private static hasEmptyInput(widget: kendo.ui.AutoComplete): boolean {
        const inputText = widget.value();
        if (!inputText)
            return true;

        return inputText.trim() === "";
    }

    private static getExactlyMatchedItem(widget: kendo.ui.AutoComplete): AutoCompleteItem {
        const value = widget.value().toLowerCase();
        const matchedItems = widget.dataSource.data()
            .filter((item: AutoCompleteItem) => item.displayValue.toLowerCase() === value);

        return matchedItems[0] || null;
    }

    private static getSinglePartiallyMatchedItem(widget: kendo.ui.AutoComplete): AutoCompleteItem {
        const value = widget.value().toLowerCase();
        const partiallyMatchedItems = widget.dataSource.data()
            .filter((item: AutoCompleteItem) => item.displayValue.toLowerCase().indexOf(value) > -1);

        return partiallyMatchedItems.length === 1
            ? partiallyMatchedItems[0]
            : null;
    }

    private static isSuggestedItem(widget: kendo.ui.AutoComplete, suggestedItem: AutoCompleteItem): boolean {
        const knownMatchedItems = widget.dataSource.data()
            .filter((item: AutoCompleteItem) => item.value === suggestedItem.value);

        return knownMatchedItems.length !== 0;
    }

    private static alreadySelected(widgetItem: AutoCompleteItem, selectedItem: ko.Observable<AutoCompleteItem>): boolean {
        const boundedItem = selectedItem();
        if (widgetItem === boundedItem)
            return true;

        return widgetItem
            && boundedItem
            && widgetItem.value === boundedItem.value;
    }

    private static validateWidget(widget: kendo.ui.AutoComplete, required: boolean): boolean {
        if (!required)
            return true;

        if (KendoAutoComplete.getSelectedItem(widget)) {
            KendoAutoComplete.clearValidationErrors(widget);
            return true;
        } else {
            KendoAutoComplete.setValidationErrors(widget);
            return false;
        }
    }

    private static setValidationErrors(widget: kendo.ui.AutoComplete): void {
        widget.element.addClass("invalid");
    }

    private static clearValidationErrors(widget: kendo.ui.AutoComplete): void {
        widget.element.removeClass("invalid");
    }
}

export function register() {
    KendoAutoComplete.registerKnockoutBinding();
}
