///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Entities.Role/Services"/>
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as sync from "Core/Medius.Core.Web/Scripts/Medius/core/sync";
import * as dataGrid from "Core/Medius.Core.Web/Scripts/Medius/components/grid/default/grid";
import * as ServicesDataSource from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Entities.Role/servicesDataSource";
import * as ko from "knockout";
import * as backendErrorHandler from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import { EntityCollection } from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/entityCollection";
import { formatValueWithPlaceholders } from "Core/Medius.Core.Web/Scripts/Medius/lib/stringFormat";

class ServicesTab {
    Entity: any;
    Tab: any;
    CompanyContextId: number;
    CollectionHandler: any;
    ServicesGrid: any;
    ServicesCollection: ko.ObservableArray<any>;
    SelectedService: ko.Observable<any>;
    FilteredServicesCollection: ko.ObservableArray<any>;
    isAddPaneEnabled: ko.Computed<boolean>;
    subscriptions: any[];
    constructor(entityWithContext:any, companyContextId:number, tabModel:any) {
        const entityModel = entityWithContext.Entity;
        this.Entity = entityModel;
        this.Tab = tabModel;
        this.CompanyContextId = companyContextId;
        this.CollectionHandler = this.resolveCollection(this.Entity, "Services");
        this.ServicesGrid = this.createGrid();
        this.ServicesCollection = ko.observableArray([]);
        this.SelectedService = ko.observable();
        this.FilteredServicesCollection = ko.observableArray([]);
        this.isAddPaneEnabled = ko.computed(() => {
            return this.FilteredServicesCollection().length !== 0;
        });

        this.initializeAvailableServices();

        this.subscriptions = [];
        this.subscriptions.push(this.CollectionHandler.Collection.subscribe(() => {
            this.refreshServiceCollection();
        }));
        this.subscriptions.push(this.ServicesCollection.subscribe(() => {
            this.refreshServiceCollection();
        }));
    }
    createGrid() {
        const dataSource = new ServicesDataSource.DataServices(this.CollectionHandler);

        const options = {
            tplRow: "Core:templates/Common/RemovableRowTpl.html",
            pageSize: 20,
            onRemoveRow: (row:any) => {
                this.removeService(row);
            }
        };

        return dataGrid.create(dataSource, options);
    }

    resolveCollection(entityModel:any, propertyName:any) {
        const collection = entityModel[propertyName];
        if (!collection) {
            throw new Error(`Property ${propertyName} does not exist`);
        }
        return new EntityCollection(collection);
    }

    initializeAvailableServices() {
        sync.load("Medius.Data.Configuration.ServiceConfigurationBase")
        .done((data) => {
            this.ServicesCollection(data);
        }).fail((xhr) => {
            const title = globalization.getLabelTranslation("#Core/cantLoadDataObject_type",["Medius.Data.Configuration.ServiceConfigurationBase"]);
            backendErrorHandler.handleAnyError(xhr, null, title);
        });
    }

    removeService(service:any){
        this.CollectionHandler.removeFromCollection(service);
    }

    addService() {
        const service = this.SelectedService();

        this.CollectionHandler.addToCollection(service);
        this.ServicesGrid.addRow(service);
    }

    refreshServiceCollection() {
        const entityCollection = new EntityCollection(this.CollectionHandler.Collection);
        const temp:any = [];
        this.ServicesCollection().forEach((dataEntity) => {
            if (!entityCollection.inCollection(dataEntity.Id)) {
                temp.push(dataEntity);
            }
        });
        this.FilteredServicesCollection(temp);
    }

    dispose() {
        this.subscriptions.forEach((subscription) => {
            subscription.dispose();
        });
        this.ServicesCollection([]);
        this.SelectedService(null);
        this.isAddPaneEnabled.dispose();
        this.FilteredServicesCollection([]);
        this.subscriptions = null;
        this.Entity = null;
        this.Tab = null;
        this.CompanyContextId = null;
        this.ServicesGrid.destroy();
        this.ServicesGrid = null;
        this.CollectionHandler = null;
    }
}

export = function (entitityWithContext:any, companyContextId:any, tabModel:any) {
    return new ServicesTab(entitityWithContext, companyContextId, tabModel);
};