///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/readonlyView"/>
import * as ko from "knockout";
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";

const readonlyView = {
    init: function (element:any, valueAccessor:any, allValues:any, viewModel:any, bindingContext:any) {
        const source = valueAccessor();

        const contextObservable = ko.computed({
            read: function () { return koUtils.unpack(source); },
            write: ko.isWriteableObservable(source) ?
                function (v) { source(v); } :
                function () { },
            deferEvaluation: true
        });

        const childBinding = bindingContext.extend({
            $isReadOnlyView: contextObservable
        });

        koUtils.addDisposeCallback(element, function() {
            contextObservable.dispose();
        });

        ko.applyBindingsToDescendants(childBinding, element);
        return { controlsDescendantBindings: true };
    }
};

export function register() {
    koUtils.registerBinding("readonlyView", readonlyView);
}