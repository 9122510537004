///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/grid/dataSource/dataSearchQueryBuilder" />

import * as _ from "underscore";
import * as moment from "moment";
import * as serialization from "Core/Medius.Core.Web/Scripts/Medius/lib/serialization";
import { isEmptyString, isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";

interface IQueryObject {
    Columns: string,
    Query: {
        EntityType: any,
        Order: string,
        OrderBy: string,
        Root: any
    }
}

class DataSearchQueryBuilder {
    queryObject: IQueryObject;
    queryConditions: any[];
    entityDataQuery: any;

    constructor(entityDataQuery: any){
        const entityType = entityDataQuery.entityType;
        this.queryConditions = [];
        this.entityDataQuery = entityDataQuery;
        
        this.queryObject = {
            Columns: "Id",
            Query: {
                EntityType: entityType,
                Order: "ASC",
                OrderBy: "Id",
                Root: {}
            }
        };
    }

    buildColumns() {
        const columns = this.entityDataQuery.columns;
        
        if (!columns || !_.isArray(columns)) {
            return this;
        }

        this.queryObject.Columns = columns.join("|");

        return this;
    }
    
    buildSortQuery() {
        const sortKey = _(_.keys(this.entityDataQuery.sorting)).last(),
            v = this.entityDataQuery.sorting[sortKey];

        if (isEmptyString(v)) {
            return this;
        }
        
        this.queryObject.Query.OrderBy = sortKey;
        this.queryObject.Query.Order = v.toUpperCase();
        
        return this;
    }
    
    buildCondition(key: any, keywords: any) {
        const obj = {
            Property: key,
            Operator: null as any,
            Values: [] as any[]
        };

        if (typeof keywords === "boolean") {
            obj.Operator = "=";
            obj.Values.push(keywords);
            return obj;
        }

        if (typeof keywords === "string") {
            
            if (keywords.includes(' null')) {
                obj.Operator = "isEmpty";
                return obj;
            }
            
            obj.Operator = "like";
            obj.Values.push(keywords.toLowerCase().trim());
            return obj;
        }

        if (keywords instanceof Date) {
            const startValue = keywords.toJSON();
            const endValue = moment(keywords).add(1, "day").subtract(1, "millisecond").toJSON();

            obj.Operator = "between";
            obj.Values.push(startValue);
            obj.Values.push(endValue);
            return obj;
        }

        if (typeof keywords === "number") {
            obj.Operator = "=";
            obj.Values.push(keywords);
            return obj;
        }

        if (_.isArray(keywords)) {
            obj.Operator = "in";
            obj.Values = [].concat(keywords);
            return obj;
        }

        if (typeof keywords === "object") {
            keywords = (keywords.toJSON) ? keywords.toJSON() : JSON.stringify(keywords);
        }

        obj.Operator = "like";
        obj.Values.push(keywords);
        return obj;
    }

    buildSearchQuery() {
        const searchKeys = _.keys(this.entityDataQuery.keywords);

        if (_.isEmpty(searchKeys)) {
            return this;
        }

        // search
        const conditions = _.chain(searchKeys)
            .reject(k => {
                const keywords = this.entityDataQuery.keywords[k];
                return isNullOrUndefined(keywords);
            })
            .map(k => {
                return this.buildCondition(k, this.entityDataQuery.keywords[k]);
            })
            .value();

        this.queryConditions = this.queryConditions.concat(conditions);

        return this;
    }

    appendWhereCondition(property: any, operator: any, value: any) {
        if (isEmptyString(property) || isEmptyString(operator) || isEmptyString(value)) {
            return this;
        }
        
        const condition = {
            Operator: operator,
            Property: property,
            Values: [value]
        };
        this.queryConditions.push(condition);

        return this;
    }

    buildCreatedDateFilter() {
        if (!this.entityDataQuery.createdDateFilter) {
            return this;
        }

        return this.appendWhereCondition("CreatedTimestamp", ">=", this.entityDataQuery.createdDateFilter.value);
    }

    buildChangedDateFilter() {
        if (!this.entityDataQuery.changedDateFilter) {
            return this;
        }

        return this.appendWhereCondition("ChangedTimestamp", ">=", this.entityDataQuery.changedDateFilter.value);
    }
    
    getQuery() {
        let rootCondition = null;
        
        if (this.queryConditions.length === 1) {
            rootCondition = this.queryConditions[0];
        } else if (this.queryConditions.length > 1) {
            rootCondition = {
                Operator: "and",
                Values: this.queryConditions
            };
        }

        this.queryObject.Query.Root = rootCondition;
        
        const serialized = serialization.toJSON(this.queryObject);
        return serialized;
    }
}

export function create(entityDataQuery: any){
    return new DataSearchQueryBuilder(entityDataQuery);
}