///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/presenters/basic"/>
import { pack, unpack } from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as helpers from "Core/Medius.Core.Web/Scripts/Medius/components/editors/helpers";
import * as defaultLocalizer from "Core/Medius.Core.Web/Scripts/Medius/components/editors/localizer";
import * as ko from "knockout";
import * as $ from "jquery";

export class Basic{
    params = {
        template: "",
        options: {
            id: (null as any),
            css: (null as any),
            tabindex: 0
        },
        value: (null as any),
        multiline: (null as any)
    };

    LocalizedValueSub: any;
    Template: any;
    Options: any;
    Localizer: any;
    OriginalValue: any;
    LocalizedValue = ko.observable();

    constructor(bindingParameters: any, localizer?: any) {
        if (!bindingParameters) {
            throw new Error("Basic presenter component - bindingParameters not provided");
        }

        helpers.mergeDefaults(bindingParameters, this.params);
        
        this.Template = this.params.template;
        this.Options = this.params.options;
        this.Localizer = pack(
            (localizer) ? localizer : defaultLocalizer.create()
        );
        this.OriginalValue = this.params.value;
        this.LocalizedValue = ko.observable();
        
        if (ko.isObservable(this.OriginalValue)) {
            this.LocalizedValueSub = ko.computed(() => {
                this.localize(this.OriginalValue(), this.LocalizedValue); 
            });
        }
        else {
            this.localize(this.OriginalValue, this.LocalizedValue);
        }
    }

    dispose() {    
        if (this.LocalizedValueSub) {
            this.LocalizedValueSub.dispose();
        }
    
        if (this.Localizer && this.Localizer.dispose) {
            this.Localizer.dispose();
        }
    
        this.OriginalValue = null;
        this.Localizer = null;
    }

    localize(value: any, target: any) {
        const localized = this.Localizer().toString(unpack(value));
    
        if (typeof localized === "string") {
            target(localized);
        } else {
            $.when(localized).done((result) => {
                target(result);
            });
        }
    }
}

export function create(bindingParameters: any, localizer: any){
    return new Basic(bindingParameters, localizer);
}

export const derive = Basic;