///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Validation.ValidatorConfiguration/contextListDataSource"/>
import * as $ from "jquery";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import DataSource = require("Core/Medius.Core.Web/Scripts/Medius/components/grid/dataSource/default");

export class ContextList extends DataSource {
    EntityCollection: any;
    constructor(entityCollection:any) {
        super();
        this.EntityCollection = entityCollection;
    }
    load(dataQuery:any) {
        const entities = this.page(dataQuery, this.EntityCollection);

        return $.Deferred().resolve(entities);
    }
    loadColumns() {
        const columns = [
            {
                ColumnName: globalization.getLabelTranslation("#Core/id"),
                ValueSource: "Id",
                Searchable: false,
                Sortable: false,
                Width: 40
            },
            {
                ColumnName: globalization.getLabelTranslation("#Core/type"),
                ValueSource: "$type",
                Searchable: false,
                Sortable: false,
                Width: 300
            },
            {
                ColumnName: globalization.getLabelTranslation("#Core/validationContextColumnName"),
                ValueSource: "Code",
                ValueSourceType: "System.String",
                Searchable: false,
                Sortable: false,
                Width: 100
            },
            {
                ColumnName: "",
                ValueSource: null,
                Searchable: false,
                Sortable: false,
                Width: 40
            }
        ];
        return $.Deferred().resolve(columns);
    }

    getTotalRows() {
        return this.EntityCollection.length;
    }

    destroy() {
        this.EntityCollection = null;
        super.destroy();
    }
}