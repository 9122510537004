/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/inbox/indicators/IndicatorsWrapper"/>

import * as React from "react";
import { 
    IconFileDocumentValidRegular, 
    IconFileXRegular, 
    IconCommunicationChatFill, 
    IconEditingPaperclipRegular, 
    IconEditingSortAscendingRegular, 
    IconActionCloseRegular, 
    IconCommerceMoneyRegular, 
    IconFileAutomaticCreationIndicatorRegular,
    getSvgPropsForIcon, 
    IconProps, 
    IconToggleRiskFactorRegular,
    IconToggleRiskFactorHandledRegular
} from '@medius/ui-controls';
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as helpers from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/helpers";
import { store } from "Core/Medius.Core.Web/Scripts/shared/store/reduxStore";
import { Module } from "Core/Medius.Core.Web/Scripts/Medius/apps/reactSpa/app";

interface IndicatorProps {
    Type: string;
    Value: string;
}

interface IndicatorsProps {
    Indicators: IndicatorProps[];
    Activity: string;
}

interface ContractsCount {
    activeContractsCount: number;
    inactiveContractsCount: number;
}

interface IndicatorViewProps {
    icon: (arg: string) => JSX.Element;
    translation: (params: ContractsCount|string) => string;
    isAvailable: boolean;
}

interface SupportedIndicators {
    ActiveContracts: IndicatorViewProps;
    ActiveAndInactiveContracts: IndicatorViewProps;
    InactiveContracts: IndicatorViewProps;
    [key: string]: IndicatorViewProps;
}

enum RiskStatus {
    NoRiskFactorsDetected, //Nothing in inbox
    AtLeastOneRiskFactorIsActive, //Show flame in inbox
    AllRiskFactorsAreHandled //Show Extinguish flame in inbox
}

export function IndicatorsWrapper(params: IndicatorsProps) {
    const indicatorIE11ClassName: any = undefined;

    const supportedIndicators: SupportedIndicators = {
        RiskStatus: {
            translation: (value: string) => {
                if(value === undefined)
                    throw new Error("Value can not be undefined");
                return getLabelTranslation("#Core/risks", value);
            },
            icon: (parsedValue: any) => {
                const isFraudAndRiskProtectionEnabled = store.getState().accesses?.enabledModules.includes(Module.FraudAndRiskProtection);
                if(!isFraudAndRiskProtectionEnabled || parsedValue === RiskStatus.NoRiskFactorsDetected){
                    return <></>;
                }
                else if(parsedValue === RiskStatus.AtLeastOneRiskFactorIsActive){
                    return(
                        <span title={getLabelTranslation("#Core/AtLeastOneRiskFactorIsActive")}>
                            <IconToggleRiskFactorRegular color="error" />
                        </span>
                    );
                }
                else if(parsedValue === RiskStatus.AllRiskFactorsAreHandled){
                    return(
                        <span title={getLabelTranslation("#Core/AllRiskFactorsAreHandled")}>
                            <IconToggleRiskFactorHandledRegular color="container"/>
                        </span>
                    );
                }
            },
            isAvailable: true
        },
        ActiveContracts: {
            translation: (value: ContractsCount) => {
                if(value === undefined) throw new Error("Value can not be undefined");
                return getLabelTranslation('#Core/indicatorActiveContracts', value.activeContractsCount);
            },
            icon: () => <IconFileDocumentValidRegular />,
            isAvailable: true
        },
        ActiveAndInactiveContracts: {
            translation: (value: ContractsCount) => {
                if(value === undefined) throw new Error("Value can not be undefined");
                return getLabelTranslation('#Core/indicatorActiveAndInactiveContracts', value.activeContractsCount, value.inactiveContractsCount);
            },
            icon: () => <IconFileDocumentValidRegular />,
            isAvailable: true
        },
        InactiveContracts: {
            translation: (value: ContractsCount) => {
                if(value === undefined) throw new Error("Value can not be undefined");
                return getLabelTranslation('#Core/indicatorInactiveContracts', value.inactiveContractsCount);
            },
            icon: () => <IconFileXRegular />,
            isAvailable: true
        },
        Comments: {
            translation: (value: string) => {
                if(value === undefined) throw new Error("Value can not be undefined");
                return getLabelTranslation("#Core/indicatorComments", value);
            },
            icon: () => <IconCommunicationChatFill />,
            isAvailable: true
        },
        Attachments: {
            translation: (value:string) => {
                if(value === undefined) throw new Error("Value can not be undefined");
                return getLabelTranslation("#Core/indicatorAttachments", value);
            },
            icon: () => <IconEditingPaperclipRegular />,
            isAvailable: true
        },
        FastTrack: {
            translation: () => getLabelTranslation("#ExpenseInvoice/paymentRequest/type/fastTrack"),
            icon: () => <IconEditingSortAscendingRegular />,
            isAvailable: true
        },
        MissingGr: {
            translation: () => getLabelTranslation("#OrderbasedInvoice/indicatorMissingGrsToolTip"),
            icon: () => <IconMissingGr />,
            isAvailable: true
        },
        HasRejectedLines: {
            translation: () => getLabelTranslation("#Core/indicatorHasRejectedLines"),
            icon: () => <IconActionCloseRegular  color="error"/>,
            isAvailable: helpers.isActivityAvailableForHasRejectedLines(params.Activity)
        },
        HasRejectedLinesWithShortPayment: {
            translation: () => getLabelTranslation("#Core/indicatorHasRejectedLinesWithShortPayment"),
            icon: () => <IconCommerceMoneyRegular />,
            isAvailable: helpers.isActivityAvailableForHasRejectedLinesWithShortPayment(params.Activity)
        },
        HasCashDiscountOpportunity: {
            translation: () => getLabelTranslation("#Core/indicatorHasCashDiscountOpportunity"),
            icon: () => <IconCashDiscount />,
            isAvailable: true
        },
        TwoWayMatchable: {
            translation: () => getLabelTranslation("#OrderbasedInvoice/indicatorTwoWayMatching"),
            icon: () => <IconTwoWayMatching />,
            isAvailable: true
        },
        AutomaticCreation: {
            translation: () => getLabelTranslation("#Core/documentSource_enum_100"),
            icon: () => <IconFileAutomaticCreationIndicatorRegular />,
            isAvailable: true
        }
    };

    const parseValue = (value: string | undefined) => {
        return value? JSON.parse(value): undefined;
    };

    const filteredIndicators: IndicatorProps[] = params.Indicators
        .filter((x:IndicatorProps) => {
            return supportedIndicators[x.Type] !== undefined && supportedIndicators[x.Type].isAvailable;
        });
        
    return (
    <>
        {
            filteredIndicators.map((x: IndicatorProps) => {
                const indicatorValue: IndicatorViewProps = supportedIndicators[x.Type];
                const parsedValue = parseValue(x.Value); 
                return (
                    <i 
                        key={x.Type} 
                        className={indicatorIE11ClassName} 
                        data-testid="inbox-indicator" 
                        title={indicatorValue.translation(parsedValue)}>{indicatorValue.icon(parsedValue)}
                    </i>
                );
            })
        }        
    </>
    );
}

function SvgTwoWayMatching(props:IconProps) {
    const svgProps = getSvgPropsForIcon(props);

    return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
        <path fillRule="evenodd" clipRule="evenodd" d="M4 6C4.87703 6 5.62221 5.43549 5.892 4.65H8.59459C9.63964 4.65 10.5315 5.02583 11.2703 5.7775C12.009 6.52917 12.3784 7.43667 12.3784 8.5C12.3784 9.56333 12.009 10.4708 11.2703 11.2225C10.5315 11.9742 9.63964 12.35 8.59459 12.35H5.892C5.62221 11.5645 4.87703 11 4 11C2.89543 11 2 11.8954 2 13C2 14.1046 2.89543 15 4 15C4.74028 15 5.38663 14.5978 5.73244 14H8.59459C10.0901 14 11.3649 13.4638 12.4189 12.3913C13.473 11.3188 14 10.0217 14 8.5C14 6.97833 13.473 5.68125 12.4189 4.60875C11.3649 3.53625 10.0901 3 8.59459 3H5.73244C5.38663 2.4022 4.74028 2 4 2C2.89543 2 2 2.89543 2 4C2 5.10457 2.89543 6 4 6Z" fill="black"/>
    </svg>;
}
const IconTwoWayMatching = React.memo(SvgTwoWayMatching);

function SvgMissingGr(props:IconProps) {
    const svgProps = getSvgPropsForIcon(props);

    return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
    <path fillRule="evenodd" clipRule="evenodd" d="M4.5 5L1 11H8L4.5 5ZM4.18185 9.42107H4.81821V10.0526H4.18185V9.42107ZM4.18185 7.52633H4.81821V8.78949H4.18185V7.52633Z" fill="black"/>
    <path d="M10.19 10.072C9.834 10.072 9.51 9.99599 9.218 9.84399C8.93 9.69199 8.7 9.46799 8.528 9.17199C8.36 8.87199 8.276 8.50399 8.276 8.06799C8.276 7.63599 8.362 7.26799 8.534 6.96399C8.71 6.65599 8.942 6.42199 9.23 6.26199C9.518 6.09799 9.836 6.01599 10.184 6.01599C10.468 6.01599 10.712 6.06799 10.916 6.17199C11.12 6.27599 11.286 6.39399 11.414 6.52599L10.952 7.08999C10.852 7.00199 10.746 6.92799 10.634 6.86799C10.522 6.80799 10.382 6.77799 10.214 6.77799C9.91 6.77799 9.662 6.88999 9.47 7.11399C9.278 7.33799 9.182 7.64599 9.182 8.03799C9.182 8.43799 9.274 8.74999 9.458 8.97399C9.642 9.19799 9.916 9.30999 10.28 9.30999C10.36 9.30999 10.438 9.29999 10.514 9.27999C10.59 9.25999 10.652 9.22999 10.7 9.18999V8.51799H10.052V7.79799H11.48V9.59799C11.344 9.72999 11.162 9.84199 10.934 9.93399C10.71 10.026 10.462 10.072 10.19 10.072Z" fill="black"/>
    <path d="M12.2882 9.99999V6.08799H13.6982C13.9742 6.08799 14.2262 6.12599 14.4542 6.20199C14.6822 6.27399 14.8642 6.39799 15.0002 6.57399C15.1362 6.74999 15.2042 6.99399 15.2042 7.30599C15.2042 7.59399 15.1402 7.83199 15.0122 8.01999C14.8882 8.20399 14.7202 8.34199 14.5082 8.43399L15.3962 9.99999H14.4062L13.6562 8.58999H13.1702V9.99999H12.2882ZM13.1702 7.88799H13.6262C13.8582 7.88799 14.0342 7.83999 14.1542 7.74399C14.2782 7.64399 14.3402 7.49799 14.3402 7.30599C14.3402 7.11399 14.2782 6.97999 14.1542 6.90399C14.0342 6.82799 13.8582 6.78999 13.6262 6.78999H13.1702V7.88799Z" fill="black"/>
    </svg>;
}

const IconMissingGr = React.memo(SvgMissingGr);

function SvgCashDiscount(props:IconProps) {
    const svgProps = getSvgPropsForIcon(props);

    return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
    <circle cx="5.5" cy="5.5" r="1.75" stroke="#333333" strokeWidth="1.5"/>
    <circle cx="10.5" cy="10.5" r="1.75" stroke="#333333" strokeWidth="1.5"/>
    <path d="M12 4L4 12" stroke="#333333" strokeWidth="1.5"/>
    </svg>;
}

const IconCashDiscount = React.memo(SvgCashDiscount);