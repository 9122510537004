/// <amd-module name="Core/Medius.Core.Web/Scripts/shared/prepayment/paymentTranslation"/>
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { PaymentStatus } from "./types";

export class PaymentTranslation {
    public static ofNotProvided(): string {
        return getLabelTranslation("#Core/informationNotProvided");
    }

    public static ofStatus(status: PaymentStatus): string {
        function key() {
            switch (status) {
            case PaymentStatus.Open:
                return "#PurchaseToPay/paymentInformation/paymentStatus/open";

            case PaymentStatus.PartiallyPaid:
                return "#PurchaseToPay/paymentInformation/paymentStatus/partiallyPaid";

            case PaymentStatus.Paid:
                return "#PurchaseToPay/paymentInformation/paymentStatus/paid";

            case PaymentStatus.Cancelled:
                return "#PurchaseToPay/paymentInformation/paymentStatus/cancelled";

            case PaymentStatus.Issued:
                return "#PurchaseToPay/paymentInformation/paymentStatus/issued";

            case PaymentStatus.Failed:
                    return "#PurchaseToPay/paymentInformation/paymentStatus/failed";

            case PaymentStatus.Expired:
                return "#PurchaseToPay/paymentInformation/paymentStatus/expired";

            case PaymentStatus.Voided:
                return "#PurchaseToPay/paymentInformation/paymentStatus/voided";
            
            default:
                throw PaymentTranslation.errorUnknownEnumValue(status, "PaymentStatus");
            }
        }

        return getLabelTranslation(key());
    }

    private static errorUnknownEnumValue(value: any, enumName: string): Error {
        return new Error(`Unknown ${enumName} enum's value: '${status}' - cannot find translation key for it.`);
    }
}
