/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/administration/leftSideGridRegistry"/>
const gridRegistry: {[key: string]: (entityId: ko.Computed<any>) => any} = {};

export function registerGrid(entityName: string, creator: (entityId: ko.Computed<any>) => any) {
    gridRegistry[entityName] = creator;
}

export function getGridForEntity(entityName: string) {
    return gridRegistry[entityName];
}
