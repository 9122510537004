/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/localizer/type/numeric" />

import {Globalize} from "Core/Medius.Core.Web/Scripts/Medius/lib/globalize";
import * as $ from "jquery";
import * as _ from "underscore";
import * as culture from "Core/Medius.Core.Web/Scripts/Medius/lib/culture";
import * as helpers from "Core/Medius.Core.Web/Scripts/Medius/components/editors/helpers";
import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";

function getLastChar(str: string) {    
    return str.charAt(str.length - 1);
}

function removeLastChar(str: string) {
    return str.substring(0, str.length - 1);
}

function adjustNumber(number: number, options: any) {
    return helpers.decimalAdjust(options.roundDecimal, number, -options.decimalResolution);
}

export function fromString(number: string, options: any) {
    let retNumber = number;
    let decNumber;

    number = $.trim(number);

    if (isNullOrUndefined(number) || 0 === number.length) //input cleared
        return null;

    if (typeof number === "string") {
        decNumber = Globalize.parseFloat(number, culture.getCode());

        if (_.isUndefined(options.decimalResolution)) {
            options.decimalResolution = 2;
        }

        retNumber = adjustNumber(decNumber, options);
    }
    return retNumber;
}

export function toString(value: any, options: any) {
    const cultureCode = culture.getCode();
    let number = value;
    let strNumber: string;

    if (isNullOrUndefined(number))
        return number;

    if (typeof number === "string")
        number = Globalize.parseFloat($.trim(number), culture.getCodeForInvariantCulture());

    if (_.isNaN(number))
        return null;

    if (_.isUndefined(options.decimalResolution))
        options.decimalResolution = 2;

    number = adjustNumber(number, options);

    if (options.notLocalized)
        return number.toString();

    if (!options.trimZeros)
        return Globalize.format(number, "n" + options.decimalResolution, cultureCode);

    if ((!number as any) % 1 !== 0)
        return Globalize.format(number, "n0", cultureCode);
    strNumber = Globalize.format(number, "n" + options.decimalResolution, cultureCode);

    // remove last character from string if a 0
    while (getLastChar(strNumber) === "0")
        strNumber = removeLastChar(strNumber);

    if (_.contains([",", "."], getLastChar(strNumber)))
        strNumber = removeLastChar(strNumber);

    return strNumber;    
}