/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/localizer/localizer"/>

import * as defaults from "Core/Medius.Core.Web/Scripts/Medius/components/localizer/defaults";

export const mappings: { [key: string]: any } = {};

export function register(type: string, localizer: any) {
    mappings[type] = localizer;
}

export function copy(sourceType: string, targetType: string) {
    mappings[targetType] = mappings[sourceType];
}

export function resolve(type: string){
    return mappings[type];
}

defaults.register(
    {register, copy, resolve, mappings}
    );
