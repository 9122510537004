/// <amd-module name="Core/Medius.Core.Web/Scripts/lib/shortcutManager"/>
import {isUndefined, clone} from "underscore";
import {isInputElementActive} from "Core/Medius.Core.Web/Scripts/lib/htmlHelper";
import {logEvent} from "Core/Medius.Core.Web/Scripts/lib/metricsLogging/metricLogger";

const jwerty = require("jwerty");

const definitions: any = {};
const activeEvents: any = {};
const suspendedEvents: any = {};
let viewContext: any;

export function bindShortcut(shortcutName: string, callback: any) {        
    const shortcutDefinition = definitions[shortcutName];
    const isAlreadyBound = activeEvents[shortcutName] !== undefined;

    if (isUndefined(shortcutDefinition)) {
        throw new Error(`Cannot bind shortcut: ${shortcutName} - it's undefined. ` +
            "Please define shortcut first by using defineShortcut method.");
    }

    if (isAlreadyBound) {
        unbindShortcut(shortcutName);
    }

    const event = jwerty.event(shortcutDefinition.combo, callback);
    
    if (shortcutDefinition.isActiveIn(viewContext)) {
        bind(event);
        activeEvents[shortcutName] = event;
    } else {
        suspendedEvents[shortcutName] = event;
    }
}

export function bindShortcutWithLogging(shortcutName: string, callback: () => any) {
    const shortcutDefinition = definitions[shortcutName];

    const callbackWithLogging = function() {
        logEvent(`shortcut-${shortcutName}-${shortcutDefinition.combo}`);
        callback();
    };
    bindShortcut(shortcutName, callbackWithLogging);
}

export function nonInputShortcutCallback(callback: any) {
    return function() {
        if (isInputElementActive()) {
            return true;
        }
        return callback();
    };
}

export function bindShortcutBlockingDefaultAction(shortcutName: any, callback: any) {
    const wrappedCallback = function(e: Event) {
        e.preventDefault();
        callback();
    };

    bindShortcut(shortcutName, wrappedCallback);
}

export function defineShortcut(params: any) {
    const shortcutName = params.shortcutName;
    
    if (definitions[shortcutName]) {
        throw new Error(`Shortcut with name ${shortcutName} is already defined.`);
    }
        
    const definition = new ShortcutDefintion(params);
    definitions[params.shortcutName] = definition;
}

export function getAllDefinedShortcuts() {
    return clone(definitions);
}

export function getDefinition(shortcutName: string) {
    return definitions[shortcutName];
}

export function setViewContext(viewCtx: any) {
    viewContext = viewCtx;
    suspendBindingsNotMatchingViewContext();
    resumeBindingsMatchingViewContext();
}

export function resumeBindingsMatchingViewContext() {
    Object.keys(suspendedEvents).forEach(shortcutName => {
        if (definitions[shortcutName].isActiveIn(viewContext)) {
            resumeShortcut(shortcutName);
        }
    });
}

export function suspendBindingsNotMatchingViewContext() {
    Object.keys(activeEvents).forEach(shortcutName => {
        if (definitions[shortcutName].isActiveIn(viewContext) === false) {
            suspendShortcut(shortcutName);
        }
    });
}

export function resumeShortcut(shortcutName: string) {
    const suspendedEvent = suspendedEvents[shortcutName];
    const isAlreadyResumed = isUndefined(activeEvents[shortcutName]) === false;

    if (isAlreadyResumed) {
        return;
    }
    
    if (suspendedEvent) {
        bind(suspendedEvent);
        delete suspendedEvents[shortcutName];
    }

    activeEvents[shortcutName] = suspendedEvent;
}

export function suspendShortcut(shortcutName: string) {
    const activeEvent = activeEvents[shortcutName];
    const isAlreadySuspended = isUndefined(suspendedEvents[shortcutName]) === false;
    
    if (isAlreadySuspended) {
        return;
    }

    if (activeEvent) {
        unbind(activeEvent);
        delete activeEvents[shortcutName];
    }

    suspendedEvents[shortcutName] = activeEvent;
}

export function unbindShortcut(shortcutName: string) {
    const activeEvent = activeEvents[shortcutName];
    const suspendedEvent = suspendedEvents[shortcutName];
    
    if (activeEvent) {
        unbind(activeEvent);
        delete activeEvents[shortcutName];
    }

    if (suspendedEvent) {
        delete suspendedEvents[shortcutName];
    }
}
export function unbindAllShortcuts() {
    Object.keys(activeEvents).forEach(b => {
        unbindShortcut(b);
    });
}

export function dispose() {
    unbindAllShortcuts();
}

function getBindingElement() {
    return $(document.body);
}

function bind(event: any) {
    const bindingElement = getBindingElement();
    bindingElement.bind("keydown", event);
}

function unbind(event: any) {
    const bindingElement = getBindingElement();
    bindingElement.unbind("keydown", event);
}

class ShortcutDefintion {
    public name: string;
    public combo: string;
    public keyBlocks: any;
    public groupName: string;
    public description: string;
    public viewContext: string;
    public skipInHelp: boolean;
    public managerDescription: string;
    constructor(params: any) {
        this.name = params.shortcutName;
        this.combo = params.shortcut;
        this.keyBlocks = params.shortcut.split("+");
        this.groupName = params.groupName;
        this.description = params.description;
        this.viewContext = params.viewContext;
        this.skipInHelp = params.skipInHelp;
        this.managerDescription = params.managerDescription;
    }

    public isActiveIn(viewCtx: any) {
        return isUndefined(this.viewContext) || this.viewContext === viewCtx;
    }
}
