///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/editors/textTranslation/model"/>
import * as _ from "underscore";
import * as basic from "Core/Medius.Core.Web/Scripts/Medius/components/editors/basic";
import * as helpers from "Core/Medius.Core.Web/Scripts/Medius/components/editors/helpers";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as notification from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import * as restJson from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rest";
import * as user from "Core/Medius.Core.Web/Scripts/Medius/lib/utils/user";
import defaultParams = require("Core/Medius.Core.Web/Scripts/Medius/components/editors/textTranslation/defaults");
import * as backendErrorHandler from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import * as ko from "knockout";
import { isNullOrUndefined, isNotNullOrUndefined, isEmptyString } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import * as userEditorHelpers from "Core/Medius.Core.Web/Scripts/Medius/lib/utils/userEditorHelpers";

const textServiceName = "TextTranslationService";

class TextTranslation {
    ComponentPrefix: string;
    LanguageValuePairs: ko.ObservableArray<any>;
    LanguageValueChanges: ko.ObservableArray<any>;
    SelectedTranslation: ko.Observable<any>;
    IsEdited: ko.Observable<boolean>;
    IsSaving: ko.Observable<boolean>;
    IsAnyValueInvalid: ko.Observable<boolean>;
    InvalidValuesInfo: ko.Observable<any>;
    EntityType: ko.Observable<any>;
    PropertyName: ko.Observable<any>;
    EntityViewId: ko.Observable<any>;
    IsReadonly: ko.Computed<any>;
    IsSavingDisabled: ko.Computed<any>;
    IsTipVisible: ko.Computed<any>;
    IsComponentInvalid: ko.Computed<any>;
    ShowErrorIndicator: any;
    selectLanguage: (selected: any) => void;
    OriginalValue: any;
    constructor(bindingParameters: any, viewmodel: any, context: any, params: any) {
        const defaults = defaultParams.get();

        this.ComponentPrefix = "#TranslatedProperty";
        this.LanguageValuePairs = ko.observableArray([]);
        this.LanguageValueChanges = ko.observableArray([]);
        this.SelectedTranslation = ko.observable(null);
        this.IsEdited = ko.observable(false);
        this.IsSaving = ko.observable(false);
        this.IsAnyValueInvalid = ko.observable(false);
        this.InvalidValuesInfo = ko.observable(false);

        this.EntityType = ko.observable(bindingParameters.entityType);
        this.PropertyName = ko.observable(bindingParameters.propertyName);
        this.EntityViewId = ko.observable(bindingParameters.entityViewId);

        this.IsReadonly = ko.computed(() => { return this.IsAnyValueInvalid(); });
        this.IsSavingDisabled = ko.computed(() => { return this.IsReadonly() || this.IsSaving(); });
        this.IsTipVisible = ko.computed(() => { return this.IsEdited(); });

        helpers.mergeDefaults(bindingParameters, defaults);

        if (defaults.options && defaults.options.placeholder) {
            defaults.options.placeholder = globalization.getLabelTranslation(defaults.options.placeholder);
        }

        _(this).extend(basic.create(defaults, context, params));

        this.IsComponentInvalid = ko.computed(() => {
            return this.ShowErrorIndicator() || this.IsAnyValueInvalid();
        });

        this.loadLanguages();

        this.selectLanguage = (selected: any) => {
            this.SelectedTranslation(selected);
        };
    }

    loadLanguages() {
        userEditorHelpers.loadAvailableLanguages().then((languages: any) => {
            let list = _.map(languages,
                (item) => {
                    return {
                        Language: item,
                        Translation: ko.observable(null)
                    };
                });

            list = list.filter(item => item.Language.Value !== 'en-legacy');
            this.LanguageValuePairs(list);
            const userLanguage = this.getValueInUserLanguage(list);
            this.SelectedTranslation(userLanguage);

            this.setObservationOnValues();
            this.loadExistingTranslations();
        });
    }

    isOrginalValueSet() {
        return isNotNullOrUndefined(this.OriginalValue()) && !isEmptyString(this.OriginalValue());
    }

    getValueInUserLanguage(collection: any) {
        const language = user.getLanguageCode();
        return _.find(collection, (item) => {
            return item.Language.Value === language;
        });
    }

    validateValues() {
        const invalidValue = _.find(this.LanguageValuePairs(),
                (item) => {
                    return isNullOrUndefined(item.Translation()) ? false : item.Translation().indexOf('|') > -1;
                });

        this.IsAnyValueInvalid(false);
        if (isNotNullOrUndefined(invalidValue)) {
            this.IsAnyValueInvalid(true);
            this.InvalidValuesInfo(globalization.getLabelTranslation("#Core/textTranslationInvalidValues"));
        }
    }

    setObservationOnValues() {
        _.each(this.LanguageValuePairs(), (pair) => {
            const sub = pair.Translation.subscribe(() => {
                this.validateValues();
                this.IsEdited(true);
            });
            this.LanguageValueChanges.push(sub);
        });
    }

    loadExistingTranslations() {
        //value is new
        if (!this.isOrginalValueSet()) {
            return;
        }

        //value is not translated yet or has non-component-tag value
        this.getExistingTranslationByTag();
    }

    fillSelectionWithExisitingTranslations(entriesDto: any) {
        //no existing tags found
        if (isNullOrUndefined(entriesDto) || isNullOrUndefined(entriesDto.TranslationTag) || _.isEmpty(entriesDto.TranslationEntries)) {
            this.setObservationOnValues();

            if (this.isOrginalValueSet()) {
                //set just current value
                const currentEntry = this.getValueInUserLanguage(this.LanguageValuePairs());
                if (isNotNullOrUndefined(currentEntry)) {
                    currentEntry.Translation(this.OriginalValue());
                }
            }

            return;
        }

        //dto is back with some entries
        this.OriginalValue(entriesDto.TranslationTag);
        _.each(entriesDto.TranslationEntries, (entry) => {
            const listItem = _.find(this.LanguageValuePairs(), (pair) => {
                return pair.Language.Value === entry.LanguageCode;
            });

            if (isNotNullOrUndefined(listItem)) {
                listItem.Translation(entry.TranslationValue);
            }
        });
        this.setObservationOnValues();
        return;
    }

    getExistingTranslationByTag() {
        const url = `getTranslationEntryForValue?translationKey=${encodeURIComponent(this.getTranslationTag())}`;

        restJson.get(textServiceName, url)
            .done((entriesDto) => {
                this.fillSelectionWithExisitingTranslations(entriesDto);
            })
            .fail((jqXhr) => {
                backendErrorHandler.handleAnyError(jqXhr);
            });
    }

    getTranslationTag() {
        return `${this.ComponentPrefix}/${this.EntityType()}${this.PropertyName()}${this.EntityViewId()}`;
    }

    setNewTranslationTag() {
        const tagValue = this.getTranslationTag();
        this.OriginalValue(tagValue);
    }

    mapValuesToDto(valuesCollection: any) {
        const entriesCollection = _.map(valuesCollection, (item) => {
                return {
                    LanguageCode: item.Language.Value,
                    TranslationValue: item.Translation()
                };
            }),
            dto = {
                TranslationTag: this.OriginalValue(),
                TranslationEntries: entriesCollection
            };

        return dto;
    }

    addTranslationToGlobalization(dto: any) {
        const language = user.getLanguageCode();
        const currentItem = _.find(dto.TranslationEntries, (item) => {
            return item.LanguageCode === language;
        });

        globalization.addOrUpdateLabelTranslation(dto.TranslationTag, currentItem.TranslationValue);
    }

    createNewTagForNewOrNotEditedProperty() {
        if (isNullOrUndefined(this.OriginalValue()) || this.OriginalValue().indexOf(this.ComponentPrefix) === -1) {
            this.setNewTranslationTag();
        }
    }

    saveTranslations() {
        this.createNewTagForNewOrNotEditedProperty();

        const dto = this.mapValuesToDto(this.LanguageValuePairs());
        this.IsSaving(true);

        restJson.post(textServiceName, "saveTranslationEntries", dto)
            .done(() => {
                const msg = globalization.getLabelTranslation("#Core/textTranslationSavingSuccess_tag", this.OriginalValue());
                this.addTranslationToGlobalization(dto);
                notification.success(msg);
                this.IsEdited(false);
            })
            .fail((jqXhr) => {
                backendErrorHandler.handleAnyError(jqXhr);
            })
            .always(() => {
                this.IsSaving(false);
            });
    }

    dispose() {
        _.each(this.LanguageValueChanges, (sub: any) => {
            sub.dispose();
        });

        this.IsReadonly.dispose();
        this.IsSavingDisabled.dispose();
        this.IsTipVisible.dispose();
        this.IsComponentInvalid.dispose();
        basic.derive.prototype.dispose.call(this);
    }
}

export function create(bindingParameters: any, viewmodel: any, context: any, params: any) {
    return new TextTranslation(bindingParameters, viewmodel, context, params);
}
