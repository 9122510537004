/// <amd-module name="Core/Medius.Core.Web/Scripts/components/availableTasksModal/availableTasksModal"/>
import {pureComputed, observableArray} from "knockout";

type OpenTaskListener = (taskId: number) => void;

class AvailableTasksModal {
    private readonly emptyOpenTaskListener: OpenTaskListener = _id => { };
    private openTaskListener: OpenTaskListener;

    public readonly availableTasksForDocument: ko.ObservableArray<any> = observableArray([]);
    public readonly isVisible = pureComputed(() => this.availableTasksForDocument().length !== 0);

    constructor() {}

    public show(tasks: any[], openTaskListener: OpenTaskListener) {
        this.openTaskListener = openTaskListener;
        this.availableTasksForDocument(tasks);
    }

    public close() {
        this.openTaskListener = this.emptyOpenTaskListener;
        this.availableTasksForDocument([]);
    }

    public openTask(taskId: number) {
        if (this.openTaskListener) {
            this.openTaskListener(taskId);
        }
        this.close();
    }
}

let availableTasksModal: AvailableTasksModal;

export function getInstance(): AvailableTasksModal {
    if (!availableTasksModal) {
        availableTasksModal = new AvailableTasksModal();
    }
    return availableTasksModal;
}
