/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/context"/>
import * as ko from "knockout";
import * as _ from "underscore";
import createTransformerFactory = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/transformers/create");
import { mapFactory } from "Core/Medius.Core.Web/Scripts/Medius/lib/map";
import transformation = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/transformation");
import copyTransformation = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/copyTransformation");
import serializeTransformerFactory = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/transformers/serialize");
import updateTransformerFactory = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/transformers/update");
import { getDecorators } from "Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/context/decorators";
class GlobalViewmodelContext{
    public create:any;
    public mode:any;
    public finalize:any;
    public toJS:any;
    public update:any;
    public set:any;
    public getData:any;
    constructor(extensions:any){
        let i;
        let extension;
        const map = mapFactory();
        _(this).extend(map);
    
        for (i = 0; extensions && i < extensions.length; i++) {
            extension = extensions[i];
            extension(this);
        }
    }
}

export = function contextFactory() {
    let roots = 0;
    const decorators = getDecorators();

    const context = new GlobalViewmodelContext(decorators);

    function newRootName() {
        return ["<root", roots++, ">"].join("");
    }

    context.create = function (data:any, options:any) {
        const rootname = options?.rootname || newRootName();

        context.mode.create();
        const rootvm = transformation(data, createTransformerFactory, {
            rootname: rootname,
            context: context
        });

        context.finalize.run();

        return ko.utils.unwrapObservable(rootvm);
    };

    context.toJS = function (data:any, options:any) {
        const rootname = options?.rootname || newRootName();

        context.mode.serialize();
        const rootdata = transformation(data, serializeTransformerFactory, {
            rootname: rootname,
            context: context
        });

        context.finalize.run();

        return rootdata;

    };

    context.update = function (data:any, options?:any) {
        const rootname = options?.rootname || newRootName();

        context.mode.update();
        const rootvm = copyTransformation(data, options?.target, updateTransformerFactory, {
            rootname: rootname,
            context: context
        });

        context.finalize.run();

        return ko.utils.unwrapObservable(rootvm);
    };

    return context;
};
