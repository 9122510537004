///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/administration/bulk/steps/confirm"/>
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import {WizardStep} from "Core/Medius.Core.Web/Scripts/Medius/components/wizard/steps/wizardStep";
import * as rest from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rest";
import * as backendErrorHandler from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import * as _ from "underscore";
import * as ko from "knockout";

const continueTranslationSelected = globalization.getLabelTranslation("#Core/areYouSureToPerformOnSelectedUsers"),
continueTranslationFiltered = globalization.getLabelTranslation("#Core/areYouSureToPerformOnFilteredUsers"),
continueTranslationAll = globalization.getLabelTranslation("#Core/areYouSureToPerformOnAllUsers"),
addModificationTranslation = globalization.getLabelTranslation("#Core/noModificationsDefinedInBulkWizard");

class BulkAdminConfirmEntityWizardStep extends WizardStep<any>{
    question = ko.observable("");
    nrOfEntities = ko.observable(0);
    modificationsNames = ko.observableArray();
    constructor() {
        super(globalization.getLabelTranslation("#Core/confirm"), "Core:templates/Administration/Bulk/Confirm.html");
        this.isButtonYesVisible(true);
        this.isNextStepEnable(true);
    }
    
    onOpen(editWizard:any) {
        editWizard.DefaultViewModel.ModificationsContainer = [];
        editWizard.steps[0].save(editWizard.DefaultViewModel.ModificationsContainer);
        if (editWizard.DefaultViewModel.ModificationsContainer.length === 0) {
            this.question(addModificationTranslation);
            this.isButtonYesVisible(false);
            this.isNextStepEnable(false);
        } else {
            if (editWizard.DefaultViewModel.EntitiesIds.length === 0) {
                if (editWizard.DefaultViewModel.ShouldApplyOnAll) {
                    this.question(continueTranslationAll);
                } else {
                    this.question(continueTranslationFiltered);
                }
                rest.post(
                    "BulkOperationService", "bulkOperationFilteredCount", {
                        EntityFilter: editWizard.DefaultViewModel.EntityFilter,
                        CompanyId: editWizard.DefaultViewModel.CompanyId
                    }).done(
                    (count) => {
                        this.nrOfEntities(count);
                    }).fail(
                    (xhr) => {
                        backendErrorHandler.handleAnyError(xhr);
                    });
            } else {
                this.question(continueTranslationSelected);
                this.nrOfEntities(editWizard.DefaultViewModel.EntitiesIds.length);
            }
            
            this.modificationsNames(_.map(editWizard.DefaultViewModel.ModificationsContainer, (modification) => {
                return isNullOrUndefined(modification.Name) ? modification.Key : modification.Name;
            }));
            this.isButtonYesVisible(true);
            this.isNextStepEnable(true);
        }
    }
    
    validate(editWizard:any) {
        return editWizard.DefaultViewModel.ModificationsContainer.length !== 0;
    }

    dispose() {
    }
}

export function create(baseWizardStep: any) {
    return new BulkAdminConfirmEntityWizardStep();
}