/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/inbox/spa"/>
import { applications } from "Core/Medius.Core.Web/Scripts/Medius/apps/spaManager/applications";
import { once } from "underscore";
import { initialize } from "Core/Medius.Core.Web/Scripts/Medius/lockManager";
import { InboxInstance as inbox } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/inbox";
import { trigger } from "Core/Medius.Core.Web/Scripts/eventBus";
import * as EventNames from "Core/Medius.Core.Web/Scripts/events/eventNames";
import * as route from "Core/Medius.Core.Web/Scripts/Medius/apps/spaManager/route";
import { renderTemplate } from "Core/Medius.Core.Web/Scripts/Medius/apps/utils";
import { scrollToSelectedGridRow } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/helpers";

const taskAction = "Tasks";
const documentAction = "Documents";
const documentTaskAction = "DocumentTasks";

class InboxApp {
    public app: any = inbox;
    public name = applications.inbox;
    public routes: any[];

    private documentPreviouslyOpened = false;
    constructor(private spaManager: any, public containerId: string) {
        inbox.registerSpaApp(this);
        initialize();

        const open = () => {
            if (this.documentPreviouslyOpened) {
                this.documentPreviouslyOpened = false;
                this.app.resetAndOpenFirstTask();
            } else {
                this.app.turnOffFullscreen();
                this.app.selectedTasksHandler.isInDocumentMode(false);
                this.app.originPlace(null);
                this.onInitialOpen();
            }

            spaManager.openApp(this);
        };

        const openTask = (taskId: number) => {
            this.refreshIfDocumentPreviouslyOpened();
            this.app.openTask(taskId);
            this.spaManager.openApp(this);
            scrollToSelectedGridRow();
        };

        const openDocument = (documentId: number) => {
            this.setDocumentMode();
            this.app.openDocument(documentId);
            this.spaManager.openApp(this);
        };

        const openTaskInDocumentMode = (taskId: number) => {
            this.setDocumentMode();
            this.app.openTaskInDocumentMode(taskId);
            this.spaManager.openApp(this);
        };

        const openTypeGroup = (typeName: string) => {
            this.refreshIfDocumentPreviouslyOpened();
            this.app.currentTypeGroup(typeName);
            this.app.openTypeGroup();
            this.spaManager.openApp(this);
            scrollToSelectedGridRow();
        };

        this.routes = [
            route.create(taskAction, open),
            route.create(taskAction + "/", open),
            route.create(taskAction + "/:taskId", openTask),
            route.create(taskAction + "/:taskId/", openTask),
            route.create(taskAction + "/TypeGroups/:typeName", openTypeGroup),
            route.create(taskAction + "/TypeGroups/:typeName/", openTypeGroup),
            route.create(documentAction + "/:documentId", openDocument),
            route.create(documentAction + "/:documentId/", openDocument),
            route.create(documentTaskAction + "/:taskId", openTaskInDocumentMode),
            route.create(documentTaskAction + "/:taskId/", openTaskInDocumentMode),
            // TODO: remove when old Inbox removed
            route.create(taskAction + "/ShowDocument/:documentId", openDocument),
            route.create(taskAction + "/ShowDocument/:documentId/", openDocument)
        ];
    }

    public initialize = once(() => {
        this.app.init();
    });

    public onTaskIdChanged() {
        this.openInbox();
    }

    public onInitialOpen() {
        this.openInbox(false, true);
    }

    private openInbox(withTrigger?: boolean, withReplace?: boolean) {

        const taskId = this.app.currentTaskId();
        const segments: (string | number)[] = [taskAction];

        if (typeof taskId === "number") {
            segments.push(taskId);
        }

        this.spaManager.router.updateUrl(segments, withTrigger, withReplace);
        trigger(EventNames.urlHashChanged);
        this.openInboxApp();
    }

    public openInboxApp() {
        this.spaManager.openApp(this, false);
    }

    private setDocumentMode() {
        this.app.currentTypeGroup(null);
        this.documentPreviouslyOpened = true;
    }

    private refreshIfDocumentPreviouslyOpened() {
        if (this.documentPreviouslyOpened) {
            this.documentPreviouslyOpened = false;
            this.app.resetAndRefresh();
        }
    }
}

export function create(spaManager: any, containerId: string) {
    return new InboxApp(spaManager, containerId);
}

export function getView() {
    return renderTemplate("inbox-template");
}
