///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Entities.DataSearch.SharedDataSearch/Editor"/>
import * as _ from "underscore";
import * as ko from "knockout";
import * as serialization from "Core/Medius.Core.Web/Scripts/Medius/lib/serialization";
import * as user from "Core/Medius.Core.Web/Scripts/Medius/lib/utils/user";
import * as columnConfiguration from "Core/Medius.Core.Web/Scripts/Medius/apps/documentSearch/columnConfiguration";
import * as notification from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import * as logger from "Core/Medius.Core.Web/Scripts/Medius/lib/logger";
import { isNotNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import * as queryEditor from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/editor";

class Editor {
    columnConfigModel: any;
    SearchQueryValue: ko.Observable<any>;
    documentType: ko.Observable<any>;
    queryTree: ko.Observable<any>;
    ColumnConfiguration: ko.ObservableArray<any>;
    queryEditor: any;
    Entity: any;
    Name: ko.Observable<any>;
    UserName: ko.Observable<any>;
    documentTypeSub: any;
    columnConfigSub: any;
    queryValueSub: any;
    constructor(entityWithContext:any) {
        this.columnConfigModel = columnConfiguration.create();
        let loadColumns:any; 
        let loadDocType:any;
        const entityModel = entityWithContext.Entity;
        if (_.isNull(entityModel) || _.isUndefined(entityModel)) {
            throw "medius/viewModels/administration/Medius.Core.Entities.DataSearch/Editor: Entity model not defined";
        }
        this.SearchQueryValue = ko.observable();
        this.documentType = ko.observable();
        this.queryTree = ko.observable();
        this.ColumnConfiguration = ko.observableArray([]);

        this.queryEditor = queryEditor.create({
            value: this.SearchQueryValue,
            type: this.documentType
        });

        this.Entity = entityModel;
        this.Name = ko.observable(entityModel.Name());
        this.UserName = ko.observable();
        
        if (this.Entity.Id() > 0) {
            this.UserName(this.Entity.User().UserName());
        } else {
            this.UserName(user.getCurrent().UserName);
            this.Entity.User(user.getEntity());
        }

        if (isNotNullOrUndefined(entityModel.Query())) {
            try {
                const parsedQueryString = JSON.parse(entityModel.Query());
                this.queryTree(parsedQueryString);
                this.queryEditor.parse(this.queryTree().Query);
                this.documentType(this.queryTree().Query.EntityType);

                loadColumns = this.queryTree().Columns || "";
                loadDocType = this.documentType();
                this.ColumnConfiguration(loadColumns.split("|"));
                this.columnConfigModel.init(this.documentType(), this.ColumnConfiguration());
                if (this.ColumnConfiguration().length === 0) {
                    this.columnConfigModel.reset();
                }
            } catch(e) {
                notification.error("Query parsing error.");
                logger.error(e);
            }
        } else {
            this.setDefaultQuery();
        }
        
        entityWithContext.Entity.Name = ko.computed(() => {
            return this.Name();
        });

        this.documentTypeSub = this.documentType.subscribe(() => {
            if (isNotNullOrUndefined(this.documentType())) {
                if (loadDocType !== this.documentType()) {
                   this.columnConfigModel.init(this.documentType(), this.ColumnConfiguration());
                   this.columnConfigModel.reset();
                } else { 
                    this.columnConfigModel.init(loadDocType, loadColumns.split("|"));
                }
            }
        });

        this.columnConfigSub = this.columnConfigModel.DisplayedColumns.subscribe(() => {
            this.ColumnConfiguration(this.columnConfigModel.DisplayedColumns());
            this.serializeQuery();
        });

        this.queryValueSub = this.SearchQueryValue.subscribe(() => {
            this.serializeQuery();
        });
    }
    setDefaultQuery() {
        this.Entity.Query(this.prepareQuery(this.queryEditor.toTree()));
    }
        
    serializeQuery() {
        if (!this.queryEditor.isValid()) {
            this.Entity.Query(null);
            return;
        }
            
        this.Entity.Query(this.prepareQuery(this.SearchQueryValue()));
    }

    prepareQuery(queryTree:any) {
        let columnConfig = "";

        if (isNotNullOrUndefined(this.ColumnConfiguration())) {
            columnConfig = this.ColumnConfiguration().join("|");
        }

        const savedQueryObject = {
            Query: queryTree,
            Columns: columnConfig
        };

        return serialization.toJSON(savedQueryObject);
    }

    dispose() {
        this.Entity = null;
        this.Name = null;
        this.UserName = null;
        this.SearchQueryValue = null;
        this.queryValueSub.dispose();
        this.columnConfigSub.dispose();
        this.documentTypeSub.dispose();
    }
}

export = function(entityWithContext:any) {
    return new Editor(entityWithContext);
};