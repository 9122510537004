/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/default/text/entity/currency"/>
import {unpack} from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as typeBoundResolverFactory from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/typeBound";

export const resolver = typeBoundResolverFactory.create({
    priority: 0,
    type: "Medius.Core.Entities.Currency",
    resolve: function (data: any) {
        return { text: unpack(data.Code) };
    }
});
