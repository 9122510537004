/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/lib/detector"/>

import * as _ from "underscore";

const cache: Record<string, any> = {
    Android: null,
    WindowsPhone: null,
    iPad: null,
    iPhone: null,
    mobile: null,
    desktop: null,
    browserInternetExplorer: null,
    browserFirefox: null,
    browserChrome: null,
    browserEdge: null,
    browserSafari: null,
    browserOther: null
};

let userAgent = navigator.userAgent;

export function isBrowserChrome() {
    if (_.isNull(cache.browserChrome)) {
        test();
    }
    return cache.browserChrome && cache.browserSafari && !cache.browserEdge;
}
    
export function  isBrowserFirefox() {
    if (_.isNull(cache.browserFirefox)) {
        test();
    }
    return cache.browserFirefox;
}
    
export function isBrowserMsie() {
    if (_.isNull(cache.browserInternetExplorer)) {
        test();
    }
    return cache.browserInternetExplorer;
}

export function isBrowserEdge() {
    if (_.isNull(cache.browserEdge)) {
        test();
    }
    return cache.browserEdge;
}

export function isBrowserSafari() {
    if (_.isNull(cache.browserSafari)) {
        test();
    }
    return cache.browserSafari && !cache.browserChrome && !cache.browserEdge;
}

export function isBrowserOther(){
    if (!isBrowserChrome() && !isBrowserFirefox() && !isBrowserEdge() && !isBrowserMsie()) {
        return true;
    }
}

export function test() {
    cache.Android = /Android/i.test(userAgent);
    cache.WindowsPhone = /IEMobile/i.test(userAgent);
    cache.iPad = /iPad/i.test(userAgent);
    cache.iPhone = /iPhone/i.test(userAgent);
    cache.mobile = (cache.Android || cache.iPad || cache.iPhone || cache.WindowsPhone);
    cache.desktop = !cache.mobile;

    //Browser test
    cache.browserChrome = (/chrome|crios|crmo/i).test(userAgent);
    cache.browserFirefox = (/Firefox/i).test(userAgent);
    cache.browserInternetExplorer = (/msie|trident/i).test(userAgent);
    cache.browserEdge = (/edge/i).test(userAgent);
    cache.browserSafari = (/Safari/i).test(userAgent);
}

export function setUserAgent(uA: string) {
    userAgent = uA;
}

export function getBrowserName() {
    if (isBrowserChrome()) {
        return "chrome";
    } else if (isBrowserMsie()) {
        return "internetexplorer";
    } else if (isBrowserFirefox()) {
        return "firefox";
    } else if (isBrowserEdge()) {
        return "edge";
    } else if (isBrowserSafari()) {
        return "safari";
    } else {
        return "other";
    }
}