/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/inbox/bulk/steps/operation"/>
import { BasicOperation } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/bulk/steps/basicOperation";

export class Operation extends BasicOperation{
    constructor(tasks: any[], chosenAction: ko.Computed<any>){
        super(tasks, chosenAction);
        
        this.loadAvailableTaskResults();
    }
}
