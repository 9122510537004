///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/grid/columnSelector/defaults"/>
export function get(): {
    onColumnPicked: () => any,
    template: string,
    buttonLabel: string,
    selectedCollection: any,
    limitCollectionAccess:boolean
} {
    return {
        onColumnPicked: function (/*property*/) {  
        },
        template: "column-picker-default",
        buttonLabel: '#Core/columnPickerPlaceholder',
        selectedCollection: null,
        limitCollectionAccess: true
    };
}