///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/Components/StreamErrorMessage" />
import { 
    Button, 
    FlexLayout, 
    IconArrowsClockwiseFill, 
    Text 
} from "@medius/ui-controls";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as React from "react";

interface IProps{
    retryCallback: () => void;
    showRetryButton: boolean;
}

export const StreamErrorMessage = ({ retryCallback, showRetryButton }: IProps) => {
    return (
        <FlexLayout
            direction="column"
            width="fill"
            className="mediusAgent__streamErrorMessageContainer"
        >
            <Text variant="body">
                {getLabelTranslation("#Core/mediusCopilotHasEncounteredSomeIssue")}
                <a href="http://support.medius.com/" className="mediusAgent__streamErrorMessageContainer__link">{getLabelTranslation("#Core/mediusSupport")}</a>
            </Text>
            {showRetryButton && <Button
                icon={<IconArrowsClockwiseFill />}
                label={getLabelTranslation("#Core/agentTryAgain")}
                variant="tertiary"
                width="fill"
                onClick={retryCallback}
            />}
        </FlexLayout>
    );
};