/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/AuthorizationGroups/Tabs/documentTypes"/>
import * as React from "react";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { DropDownList, ListItemProps } from "@progress/kendo-react-dropdowns";
import { DocumentType } from "../authorizationGroup";
import { Button } from "@progress/kendo-react-buttons";
import { getDocumentOrderForType } from "../documentOrder";

export interface IDocumentTypesProps {
    selectedDocumentTypes: DocumentType[];
    documentTypesList: DocumentType[];
    onChange: (documentTypes: DocumentType[]) => void;
}

export function DocumentTypes(props: IDocumentTypesProps) {

    const { selectedDocumentTypes, documentTypesList, onChange } = props;

    const isMoreTypesAvailable = selectedDocumentTypes.length < documentTypesList.length;

    const values = documentTypesList
    .sort((a, b) => getDocumentOrderForType(a.Name) - getDocumentOrderForType(b.Name))
    .map(type => {
        return { Name: translate(type.Name), Id: type.Id };
    });

    const addNewDocumentType = () => {
        onChange(selectedDocumentTypes
            .concat(documentTypesList
                .find(el => selectedDocumentTypes
                    .findIndex(document => document.Id === el.Id) === -1) ?? []));
    };

    const deleteDocumentType = (documentTypeId: number) => {
        onChange(selectedDocumentTypes.filter(x => x.Id !== documentTypeId));
    };

    const onChangeDropDown = (event: any, elementIndex: number) => {
        const selectedDocumentId = event.target.value.Id;
        if (selectedDocumentTypes.findIndex(x => x.Id == selectedDocumentId) > -1)
            return;

        onChange(selectedDocumentTypes.filter(x => x.Id !== selectedDocumentTypes[elementIndex].Id)
            .concat(documentTypesList.find(x => x.Id === selectedDocumentId) ?? []));
    };
    
    const documentDropdowns = selectedDocumentTypes
    .map((value, index) =>
        <div className="documentTypeDropdown"
            data-testid="authorization-group-document-types-list"
            key={value.Id} >
            <DropDownList
                data={values}
                value={{ Name: translate(value.Name), Id: value.Id }}
                dataItemKey="Id"
                textField="Name"
                onChange={(event) => onChangeDropDown(event, index)}
                itemRender={(li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
                    const itemChildren = selectedDocumentTypes.findIndex(x => x.Id == itemProps.dataItem.Id) > -1 ?
                        <span style={{ color: "#ccc" }}><>{li.props.children}</></span> :
                        <span><>{li.props.children}</></span>;
                    return React.cloneElement(li, li.props, itemChildren);
                }}
            />
            {selectedDocumentTypes.length > 1 &&
                <Button
                    icon="delete"
                    className="documentTypeDeleteButton"
                    data-testid="document-type-delete-button"
                    onClick={() => deleteDocumentType(value.Id)} />
            }

        </div>
    );

    return (
        <div className="authorizationGroups__configuration-property">
            <h4 className="section-header">{translate('#Enterprise/AuthorizationGroupDocumentTypesTabName')}</h4>
            <div>
                {documentDropdowns}
            </div>
            {isMoreTypesAvailable &&
                <div>
                    <a data-testid="document-type-add-button"
                        style={{ cursor: "pointer" }}
                        onClick={addNewDocumentType}>+{translate('#Enterprise/authorizationGroupDocumentTypes_addNewDocumentType')}</a>
                </div>}
        </div>
    );
}
