/// <amd-module name="Core/Medius.Core.Web/Scripts/Helpers/migrationApisAvailableEndpointsList"/>

export function getAvailableMigratedWebApiEndpointsList(): string[] {
    return [
        "pv2/units",
        "pv2/units/default",
        "pv2/resolutionConfiguration",
        "goodsReceiptNote/grnForDeliveryLines",
        "authorizationGroupCurrency",
        "app",
        "CreditMemo/lines/\\d+",
        "CodingTemplate/\\d+/defaultSuppliers",
        "cfoDashboard/companies",
        "reports/schedule/receiver/autocomplete",
        "reports/role/autocomplete",
        "reports/filter/payment-term/autocomplete",
        "reports/filter/user/autocomplete",
        "reports/share/receiver/autocomplete",
        "reports/documentReasonCode/autocomplete",
        "payment-request/type/\\d+/\\d+/custom-fields",
        "payment-request/types/\\d+",
        "payment-request/references",
        "taxIndicatorRules/documentTypes",
        "followupGroupUser",
        "bulkInbox/availableDocuments",
        "followupGroupCompany",
        "administration/customlist/companies",
        "pv2/userEmail",
        "pv2/userEmail/getByUserId/\\d+",
        "cfoDashboard/paymentDateIntegrationEnabled",
        "cfoDashboard/cashflow/isAccessible",
        "cfoDashboard/apProcess/isAccessible",
        "invoiceimageusers/\\d+",
        "pv2/supplierConfiguration/company/\\d+/supplier",
        "reports/filter/label/autocomplete",
        "currency/forcompany",
        "pv2/freeTextItemContracts/\\d+",
        "pv2/freeTextItemContracts/getContract/\\d+",
        "pv2/freeTextItemUnits",
        "pv2/freeTextItemUnits/default",
        "pv2/freeTextItemUnits/getUnit/\\d+",
        "pv2/freeTextItemCurrencies/\\d+",
        "pv2/freeTextItemCurrencies/getCurrency/\\d+",
        "pv2/freeTextItemCurrencies/isSupplierCurrencyInList/\\d+/\\d+",
        "pv2/company/\\d+/suppliers",
        "pv2/company/\\d+/contracts",
        "HashFilesService/GetImageHashFilesWithNames",
        "contractsforinvoices/companyfilter",
        "contractsforinvoices/companyfilter/company",
        "authorizationGroupSupplier/search",
        "authorizationGroupCompany/search",
        "RefreshRemainingAmount",
        "categoryadministration/coding",
        "reports/connectionStatusType/autocomplete",
        "MentionSuggestions",
        "followupGroupSupplier",
        "catalogItemsAutocompleter",
        "temporaryDelegationsGadget/grid",
        "administration/purgeInvoices/configurations",
        "administration/purgeInvoices/configuration/\\d+",
        "administration/purgeInvoices/configuration",
        "administration/purgeInvoices/companies",
        "connectionStatus/GetVisible",
        "purchaseOrder/administration/gridEntries",
        "administration/login-options",
        "sendForReview",
        "pv2/company-role/\\d+/[^/]+/users",
        "pv2/company-role/\\d+/[^/]+/distributable-roles",
        "companyLabelAdministration",
        "companyLabelAdministration/\\d+",
        "companyLabelAdministration/companies",
        "administration/foureyes/companies/search",
        "permissionGroupsAdministration/companies",
        "permissionGroupsAdministration/companies/\\d+/permissionGroups",
        "purchase-order-document/payment-terms",
        "purchase-order-document/\\d+/payment-terms",
        "payment-request/\\d+/payment-terms",
        "orderbased-invoice/\\d+/payment-terms",
        "supplier-contract/\\d+/payment-terms",
        "supplier-contract/payment-terms",
        "supplier-contract/payment-term",
        "reports/adminCompany/autocomplete",
        "administration/customlist/suppliers",
        "resolutionConfigurationAdministration/resolutionconfigurations",
        "pv2/freeTextItemSuppliers/\\d+",
        "pv2/freeTextItemSuppliers/getSupplier/\\d+",
        "reports/filter/past-handler/autocomplete",
        "real-currencies",
        "pv2/company/\\d+/header",
        "pv2/companies",
        "pv2/companies/autocompleter",
        "pv2/companies/single",
        "pv2/company/\\d+/addresses",
        "administration/jobs/sendscheduledreportsjob",
        "document-approval-company-configuration/\\d+/response-message-simulation-allowance",
        "document-approval-company-configuration/\\d+/document-template-link",
        "document-approval-company-configuration/\\d+/main-attachment-mandatory-info/\\d+",
        "document-approval-company-configuration/\\d+/voucher-field-visibility/\\d+",
        "document-approval-company-configuration/\\d+/sub-type",
        "contractsforinvoices/contracts",
        "validate-payment-details-confirmation/[0-9a-z-]+/is-confirmed",
        "validate-payment-details-confirmation/[0-9a-z-]+/\\d+/confirm",
        "procurement/locations/IsLocationIdDuplicated",
        "procurement/locations/IsLocationNameDuplicatedWithinCompany",
        "procurement/locations/\\d+",
        "expense-invoice/\\d+/payment-terms",
        "links",
        "purchasing-system-final-integration/configuration/company/\\d+/enabled",
        "pv2/purchaseRequisition/supplierAutocompleter/company/\\d+/suppliers",
        "contractsforinvoices/supplierfilter",
        "contractsforinvoices/supplierfilter/supplier",
        "documentSourceManagement/documentProviders",
		"documentSourceManagement/documentFormats",
        "accessManagement",
        "accessManagement/history",
        "accessManagement/users",
        "accessManagement/authorization",
        "notifications/latest",
        "notifications/(mention|release)",
        "notifications/\\d+/read",
        "notifications/read/(mention|release)",
        "notifications",
        "inactiveUsersGadget/search",
        "inactiveUsersGadget/searchReasons",
        "applicationData"
    ];
}
