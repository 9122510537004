///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/userSettings/register"/>
import {template} from "Core/Medius.Core.Web/Scripts/Medius/apps/userSettings/defaults";
import * as app from "Core/Medius.Core.Web/Scripts/Medius/apps/userSettings/app";
import * as $ from "jquery";
import * as ko from "knockout";

function onClick() {
    const instance = app.getInstance(),
        element = window.document.createElement("div");

    window.document.body.appendChild(element);
    ko.renderTemplate(template, instance, {}, element);

    const $modal = $(element).find(".modal-container");

    $modal.on("hidden", () => {
        document.body.removeChild(element);
        instance.dispose();
    });

    $modal.modal("show");
}

export function open() {
    onClick();
}

export function register() {
    $("#open-user-settings").click(onClick);
}