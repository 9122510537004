///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Entities.NewInbox.Folder/Editor"/>
import * as ko from "knockout";

class FolderType {
    Value: any;
    DisplayValue: any;
    constructor(displayValue:any, value:any) {
        this.DisplayValue = displayValue;
        this.Value = value;
    }
}

class Editor {
    Entity: any;
    documentType: ko.Observable<any>;
    AvailableFolderTypes: ko.Observable<FolderType[]>;
    constructor(entityWithContext:any) {
        this.Entity = entityWithContext.Entity;
        this.documentType = ko.observable();

        const availableFolderTypes = [
            new FolderType("My tasks", "MyTasks"),
            new FolderType("In progress", "InProgress")
        ];

        this.AvailableFolderTypes = ko.observable(availableFolderTypes);
    }
}

export = function(entityWithContext:any) {
    return new Editor(entityWithContext);
};