///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/documentSearch/filtering/fields"/>
import * as ko from "knockout";
import { zero } from "Core/Medius.Core.Web/Scripts/Models/FinanceComponentDto/quantityFactory";
import { getTimeValues } from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/provider/timeOperators";

const idPrefix = "document-search-filtering";

function createId(args: any[]) {
    return args.join("-");
}


function resolveComponentsFields(operator: { Translation: () => string; Arity: () => any; }, type: string, id: number) {
    if (type === "Medius.Core.Entities.Amount") {
        return [ko.observable(new ComponentField("System.Int32", createId([id, "1"]), "Amount"))];
    }

    if (type === "System.DateTime" && operator.Translation() === "is") {
        return [ko.observable(new DateField("System.DateTime", createId([id, "1"])))];
    }

    if (type === "Medius.Core.Entities.Quantity") {
        return [ko.observable(new QuantityField(createId([id, "1"])))];
    }

    const componentType = (type === "System.DateTime") ? "Date" : "Component";
    switch (operator.Arity()) {
        case 1:
            return [ko.observable(new ComponentField(type, createId([id, "1"]), componentType))];
        case 2:
            return [
                ko.observable(new ComponentField(type, createId([id, "1"]), componentType)),
                ko.observable(new ComponentField(type, createId([id, "2"]), componentType))
            ];
        default:
            return [];
    }
}

export class ComponentField {
    CustomEditorType: any;
    Id: any;
    Placeholder: any = null;
    value = ko.observable(null);
    Type: any;
    constructor(type: string, id: any, component: string)
    {
        this.CustomEditorType = component || "Component";
        this.Id = id || null;
        this.Type = type || null;
    }
}

class QuantityField extends ComponentField {
    constructor(id: any)
    {
        super("Medius.Core.Entities.Quantity", id, null);
        this.value(zero());
    }
}

class DateField {
    CustomEditorType = "DateTime_is";
    Id: any;
    Placeholder: any = null;
    value = ko.observable(null);
    Type: any;
    TimeOptions = ko.observableArray(getTimeValues());
    constructor(type: string, id: string)
    {
        this.Id = id || null;
        this.Type = type || null;
    }
}

export class BinaryField {
    Id: number;
    ClearId: string;
    Label: any;
    Type: any;
    Hints: any;
    value = ko.observable(null);
    PropertyPath: any;
    emptyOperator: any;
    operator: ko.Observable<any>;
    selectedOperator: ko.Observable<any>;
    emptyOperTranslation: ko.Observable<any>;
    components = ko.observableArray([]);
    constructor(label: any, entityType: any, property: any, configId: number, type: any, hints: any, emptyOperator: any)
    {
        if (typeof configId !== 'number') {
            return;
        }
    
        this.Id = configId;
        const prefix = createId([idPrefix, entityType, property]);
        this.ClearId = createId([prefix, "clear"]) || null;
    
        this.Label = label || null;
        this.Type = type || null;
        this.Hints = hints || null;
        this.PropertyPath = property;
    
        this.emptyOperator = emptyOperator;
        this.operator = ko.observable(emptyOperator || null);
        this.selectedOperator = ko.observable(emptyOperator.Translation() || null);
        this.emptyOperTranslation = ko.observable(emptyOperator.Translation());
    
        this.selectOperator = this.selectOperator.bind(this);
        this.resetField = this.resetField.bind(this);
    }

    insertValues(values:any, components:any) {

        for (let i = 0; i < components().length; i++) {
            const valueItem = values[i];
            if (components()[i]().CustomEditorType === valueItem.editorType) {
                components()[i]().value(valueItem.value);
            }
        }
    }

    selectOperator(newOperator: { Translation: any; Arity: any; }) {
        const prevValues = this.components().map((item) => {
            return {
                value: item().value(),
                editorType: item().CustomEditorType
            };
        });

        this.components([]);
        this.selectedOperator(newOperator.Translation());

        if (newOperator !== this.emptyOperator) {
            const componentFields = resolveComponentsFields(newOperator, this.Type, this.Id);
            this.components(componentFields);

            if (newOperator.Arity() === this.operator().Arity()) {
                this.insertValues(prevValues, this.components);
            }
        }
        this.operator(newOperator);
    }

    resetField() {
        this.components([]);
        this.selectedOperator(this.emptyOperator.Translation());
        this.value(null);
        this.operator(this.emptyOperator);
    }
}
