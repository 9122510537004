///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Entities.Integration.EDIDocumentMapper/Editor"/>
import * as ko from "knockout";
import * as type from "Core/Medius.Core.Web/Scripts/Medius/core/type";
import * as documentTranslator from "Core/Medius.Core.Web/Scripts/Medius/apps/document/documentTranslator";

class Editor {
    Entity: any;
    DocumentSourceMappings: ko.ObservableArray<any>;
    Tab: any;
    CompanyContextId: number;
    ConfigurableProperties: ko.ObservableArray<any>;
    EntityType: string;
    EntityId: any;
    EntityViewId: any;
    Context: any;
    DocumetSourceEnum: { Id: number; Value: string & { format?: any; }; }[];
    constructor(entityWithContext:any, companyContextId:number, tabModel:any) {
        this.Entity = entityWithContext.Entity;
        this.DocumentSourceMappings = ko.observableArray(this.Entity.DocumentSourceMappings());
        this.Tab = tabModel;
        this.CompanyContextId = companyContextId;
        this.ConfigurableProperties = ko.observableArray([]);
        this.EntityType = type.getTypeName(this.Entity.$type());
        this.EntityId = this.Entity.Id();
        this.EntityViewId = this.Entity.ViewId();
        this.Context = entityWithContext.Context;

        this.DocumetSourceEnum = [
            { Id: 0, Value: documentTranslator.translateDocumentSource(0) },
            { Id: 1, Value: documentTranslator.translateDocumentSource(1) },
            { Id: 2, Value: documentTranslator.translateDocumentSource(2) },
            { Id: 3, Value: documentTranslator.translateDocumentSource(3) }
        ];
    }
    addRow() {
        this.DocumentSourceMappings.push({ FromDocumentSource: '', TargetDocumentSource: '' });
    }

    removeRow(row:any) {
        this.DocumentSourceMappings.remove(row);
    }

    dispose() {
        this.Entity = null;
        this.Tab = null;
        this.CompanyContextId = null;
        this.Context = null;
    }
}

export = function(entityWithContext:any, companyContextId:number, tabModel:any) {
    return new Editor(entityWithContext, companyContextId, tabModel);
};