/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/PaymentTerm/extensionRightSide"/>
import { BackendController } from "./backendController";
import { PaymentTerm } from "./paymentTerm";
import { LegacyCustomFieldsEditorViewModel } from "./legacyCustomFieldsEditorViewModel";
import { LegacyCompanyViewModel } from "./legacyCompanyViewModel";
const globalization = require("Core/Medius.Core.Web/Scripts/lib/globalization");

export class ExtensionRightSide implements AdminPagesRightSide {
    public readonly template = "Core:AdminPages/PaymentTerm/extensionRightSide.html";
    public readonly canDelete = true;
    public readonly canUpdate = true;

    public generalTabName: string = globalization.getLabelTranslation("#Core/general");
    public general: PaymentTerm;

    public customFieldsTabName: string = globalization.getLabelTranslation("#Core/customFields");
    public customFields: LegacyCustomFieldsEditorViewModel;

    public load(entityId: number): JQueryGenericPromise<any> {
        return BackendController.get(entityId).then(
            response => {
                const companyViewModel = LegacyCompanyViewModel.create(response.company);

                const customFieldsViewModel = LegacyCustomFieldsEditorViewModel.create({
                    customFields: response.customFields,
                    entityType: "Medius.Enterprise.Entities.PaymentTerm, Medius.Enterprise.Common",
                    entityId: response.id,
                    company: companyViewModel
                });

                this.general = new PaymentTerm(response, companyViewModel);
                this.customFields = customFieldsViewModel;
            });
    }

    public save(): JQueryGenericPromise<EntityId> {
        const general = this.general.toRequest();
        const customFields = this.customFields.toRequest();
        return BackendController.save({
            ...general,
            customFields: customFields
        });
    }
}
