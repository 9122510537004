/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/lib/utils/date"/>

export type MediusDateWrapper = Date & {
    isLocal: boolean;
};

export function setLocal(date: any): MediusDateWrapper {
    date.isLocal = true;
    date.toJSON = function () {
        return toUserLocal(this).toJSON();
    };
    return date;
}

export function toUserLocal(date: Date) {
    const year = date.getFullYear(),
        month = date.getMonth(),
        day = date.getDate(),
        hours = date.getHours(),
        minutes = date.getMinutes(),
        seconds = date.getSeconds(),
        ms = date.getMilliseconds(),
        userLocalDate = new Date(Date.UTC(year, month, day, hours, minutes, seconds, ms));

    if (year > 0 && year < 100) {
        userLocalDate.setFullYear((userLocalDate as any).getYear());
    }

    return userLocalDate;
}

export function toUnknownKindIsoString(date: Date) {
    return date.toISOString().slice(0, -1);
}

export function fromUserLocal(date: Date) {
    const year = date.getUTCFullYear(),
        month = date.getUTCMonth(),
        day = date.getUTCDate(),
        hours = date.getUTCHours(),
        minutes = date.getUTCMinutes(),
        seconds = date.getUTCSeconds(),
        ms = date.getUTCMilliseconds(),
        localDate = new Date(year, month, day, hours, minutes, seconds, ms);

    if (year > 0 && year < 100) {
        localDate.setFullYear((localDate as any).getYear());
    }

    return setLocal(localDate);
}

export function now() {
    return setLocal(new Date());
}

export function utcNow() {
    return fromUserLocal(new Date());
}

export function today() {
    const date = now();
    date.setHours(0, 0, 0, 0);
    return date;
}

export function isMicrosoftDate(input: string) {
    return /^\s*\/Date\([-+0-9]+\)\/\s*$/.test(input);
}

export function getDefault() {
    // default(DateTime) used for required field validator on backend
    return new Date('0001-01-01');
}

export function tryRevive(s: any) {
    if (isMicrosoftDate(s)) {
        const strTimestamp = s.match(/-?[0-9]+/);

        if (strTimestamp && strTimestamp[0]) {
            const timestamp = parseInt(strTimestamp[0], 10);
            return fromUserLocal(new Date(timestamp));
        }
        const currentDate = eval("new " + s.replace(/\//g, "").replace(/(\+|\-)[0-9]+\)/g, ")"));
        return fromUserLocal(currentDate);
    }
    return s;
}

export function tryWithAbsoluteDate(s: any) {
    if (isMicrosoftDate(s)) {
        const strTimestamp = s.match(/-?[0-9]+/),
            isContainingTimeZone = s.indexOf("-") > -1 || s.indexOf("+") > -1,
            timestamp = parseInt(strTimestamp[0], 10);

        return isContainingTimeZone ? new Date(timestamp) : fromUserLocal(new Date(timestamp));
    }
    return s;
}