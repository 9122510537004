/// <amd-module name="Core/Medius.Core.Web/Scripts/components/links/linkSelector/linkModelProviders"/>
import { SuggestionDto } from "Core/Medius.Core.Web/Scripts/components/links/linkSelector/linkSelectorDtos";
import LinkSuggestion from "Core/Medius.Core.Web/Scripts/components/links/linkSelector/suggestion/suggestion";

type SuggestionModelProvider = (suggestionDto: SuggestionDto) => LinkSuggestion;

const modelProviders: { [index: string]: SuggestionModelProvider } = {};

export function register(entityType: string, provider: SuggestionModelProvider): void {
    modelProviders[entityType] = provider;
}

export function get(entityType: string): SuggestionModelProvider {
    return modelProviders[entityType] || null;
}
