/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/moment/culture"/>
import { getCultureCode } from "Core/Medius.Core.Web/Scripts/Medius/lib/utils/user";
import { log, warn } from "Core/Medius.Core.Web/Scripts/Medius/lib/logger";
import * as culture from "Core/Medius.Core.Web/Scripts/Medius/lib/culture";
import * as moment from "moment";

const trySetCulture = (cultureCode: string): boolean => {
    moment.locale(cultureCode);
    if (moment.locale() === cultureCode) {
        return true;
    }
    return false;
};

const usercultureCode = getCultureCode();

if (trySetCulture(usercultureCode))
    log(`Moment - culture set to: '${usercultureCode}'.`);
else if(trySetCulture(culture.baseCodeFor(usercultureCode)))
    warn(`Moment - there is no culture: '${usercultureCode}'. A '${culture.baseCodeFor(usercultureCode)}' will be used.`);
else if (trySetCulture(culture.getCodeForInvariantCulture()))
    warn(`Moment - there is no culture: '${usercultureCode}'. A '${culture.getCodeForInvariantCulture()}' will be used.`);

