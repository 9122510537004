// this path was not changed due to reference in Core/Medius.Core.Web/Scripts/Medius/components/presenters/utils.js
/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/presenters/quantity/model"/>

import * as ko from "knockout"; 
import * as settingsProvider from "Core/Medius.Core.Web/Scripts/Medius/core/settingsProvider";
import * as helpers from "Core/Medius.Core.Web/Scripts/Medius/components/editors/helpers";
import * as localizerFactory from "Core/Medius.Core.Web/Scripts/Medius/components/editors/number/localizer";

type QunatityParams = {
    value: ko.Observable<number>;
    showUnit: boolean;
    };

class Quantity{
    parametersValueBinding: any;
    synchronizedQuantityBinding: any;
    LocalizedValue: any;
    Options: any;
    Template: string;
    UnitDescription: any;

    constructor(bindingParameters: QunatityParams){
        let currentUnitDescription: any, currentNumberLocalizer: any;
        this.parametersValueBinding = null;
        this.synchronizedQuantityBinding = null;
        
        const bindTo = (value: any) => {
            let actualValue = value;

            if (ko.isObservable(value)) {
                this.parametersValueBinding = value
                    .subscribe(newQuantity);
    
                actualValue = value();
            }
            newQuantity(actualValue);
        };

        const newQuantity = (newValue: any) => {
            if (this.synchronizedQuantityBinding)
            this.synchronizedQuantityBinding.dispose();

            if (!newValue) {
                displayEmptyQuantity();
                return;
            }

            // And source (newValue) could be anything...
            // With: _.DisplayValue, _.UnitDescription.
            if (newValue.onUpdated) {
                // But only for object from: Quantity factory,
                // updates will be noticable in this presenter.
                this.synchronizedQuantityBinding = newValue
                    .onUpdated(function (data: any) {
                        quantityUpdated(data.value);
                    });
            }

            quantityUpdated(newValue);
        };

        const quantityUpdated = (quantity: any) => {
            if (currentUnitDescription !== quantity.UnitDescription) {
                currentUnitDescription = quantity.UnitDescription;
                currentNumberLocalizer = createLocalizerFor(currentUnitDescription);

                if (this.UnitDescription)
                    this.UnitDescription(currentUnitDescription);
            }

            const localizedDisplayValue = currentNumberLocalizer
                .toString(ko.unwrap(quantity.DisplayValue));

            this.LocalizedValue(localizedDisplayValue);
        };

        const displayEmptyQuantity = () => {
            currentUnitDescription = null;
            currentNumberLocalizer = null;

            if (this.UnitDescription)
                this.UnitDescription("");

            this.LocalizedValue("");
        };

        const createLocalizerFor = (unitDescription: any) =>
        {
            const quantityResolution = settingsProvider
            .getQuantityDisplayResolution(unitDescription);

        return localizerFactory.create(
            quantityResolution,
            configuration.trimZeros,
            configuration.roundDecimal,
            configuration.notLocalized);
        };

        const configuration = helpers.mergeDefaults(bindingParameters, {
            showUnit: true,
            options: {
                id: null,
                css: null
            }
        });

        this.LocalizedValue = ko.observable();
        this.Options = configuration.options;

        if (configuration.showUnit) {
            this.Template = "presenters-quantity-with-unit";
            this.UnitDescription = ko.observable();
        }
        else {
            this.Template = "presenters-quantity-without-unit";
        }

        bindTo(configuration.value);
    }

    dispose() {
        if (this.parametersValueBinding) {
            this.parametersValueBinding.dispose();
            this.parametersValueBinding = null;
        }

        if (this.synchronizedQuantityBinding) {
            this.synchronizedQuantityBinding.dispose();
            this.synchronizedQuantityBinding = null;
        }
    }
}

export function create (bindingParameters: QunatityParams) {
    return new Quantity(bindingParameters);
}
