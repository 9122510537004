/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/AdministrationFavouriteLink/General/general"/>
import { IEntityWithContext } from "Core/Medius.Core.Web/Scripts/AdminPages/IEntityWithContext";
import * as rest from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rest";
import * as _ from "underscore";
import { observable, observableArray } from "knockout";
import { getPropertyTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as user from "Core/Medius.Core.Web/Scripts/Medius/lib/utils/user";
import  DataDefinitionEditor = require("Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Data.DataDefinition/Editor");

class AdministrationFavouriteLink {
    public ViewModel: any;
    public Entity: any;
    public readonly isNew: ko.Observable<boolean>;
    public readonly hasForType: ko.Observable<boolean>;
    public readonly canUpdateTranslation: ko.Observable<boolean>;
    public ContentTranslations: ko.ObservableArray<string>;
    public SelectedTranslation: ko.Observable<any>;
    public subscriptionForType: ko.Subscription;
    public actionResultSub: ko.Subscription;
    public OriginTranslations: Array<any>;
    public readonly favouriteLinkTypeProvider: FavouriteLinkTypeProvider;
    public openDialogNotifier = observable(false).extend({ notify: 'always' });
    public dialogActionResult: ko.Observable<boolean | null> = observable(null);

    constructor(entityWithContext: IEntityWithContext, companyContextId: any, tabModel: any) {

        entityWithContext.TabModels.General = this;
        this.Entity = entityWithContext.Entity;
        this.isNew = observable(this.Entity.Id() === 0);
        this.hasForType = observable(false);
        this.favouriteLinkTypeProvider = new FavouriteLinkTypeProvider(this.Entity.ForType());
        this.canUpdateTranslation = observable(false);

        this.ContentTranslations = observableArray([]);
        this.OriginTranslations = [];
        this.SelectedTranslation = observable({ Language: observable(""), DisplayName: observable(""), IsDisabled: false });
        
        this.subscriptionForType = this.Entity.ForType.subscribe(() => this.loadLanguages());

        this.ViewModel = DataDefinitionEditor.call(this, entityWithContext, companyContextId, tabModel);

        rest.get("favouriteLinksAdministration", `canupdatetranslation/${this.Entity.Id()}`).then((canUpdate: boolean) => {
            if (canUpdate) {
                this.loadLanguages();
            }
        });
    }

    public openDialogAction() {
        this.openDialogNotifier(true);
    }

    public dispose() {
        if (this.actionResultSub !== undefined) {
            this.actionResultSub.dispose();
        }
        this.subscriptionForType.dispose();
    }

    public getForType() {
        return this.Entity.ForType() && this.Entity.ForType().fullName && this.Entity.ForType().fullName() || this.Entity.ForType();
    }

    private loadLanguages() {
        let list;

        const forType = this.getForType();
        if (forType === null) {
            this.hasForType(false);
            return;
        }

        rest.get("favouriteLinksAdministration", `translations/${forType}/${this.Entity.Id()}`).then((translations: any) => {
            list = translations.map((item: any) => {
                const displayName = observable(item.value);
                return {
                    Language: observable(item.language),
                    DisplayName: displayName,
                    selectLanguage: (selected: any) => {
                        this.SelectedTranslation(selected);
                    }
                };
            });

            this.OriginTranslations = translations;
            this.ContentTranslations(list);
            const userLanguage = this.getValueInUserLanguage(this.ContentTranslations());
            this.SelectedTranslation(userLanguage);
            this.hasForType(true);
            this.canUpdateTranslation(true);
        });
    }

    private getValueInUserLanguage(collection: any) {
        return _.find(collection, (item: any) => item.Language() === user.getLanguageCode());
    }
}

class FavouriteLinkTypeProvider {
    private current: string;

    constructor(current: string) {
        this.current = this.getItemDescription(current);
    }

    public search(phrase: string, limit: number) {
        return rest.post("favouriteLinksAdministration", "favouriteLink/autocomplete", { phrase: phrase, limit: limit, current: this.current });
    }

    public getItemValue(item: any) {
        if (item == null) {
            return "";
        }
        if (item.displayName == undefined) {
            return getPropertyTranslation(item);
        }
        return item.displayName();
    }

    public getItemDescription(item: any) {
        if (item == null) {
            return "";
        }
        if (item.fullName == undefined) {
            return getPropertyTranslation(item);
        }
        return getPropertyTranslation(item.fullName);
    }

    public createDummy(): any {
        return null;
    }

    public exact(searchTerm: string, maxResults: number) {
        return this.search(searchTerm, maxResults);
    }
}

export default function create(entityWithContext: IEntityWithContext,
    companyContextId: number,
    tabModel: any) {
    return new AdministrationFavouriteLink(entityWithContext, companyContextId, tabModel);
}

// save this one for custom save
export type AdministrationAdministrationFavouriteLink = AdministrationFavouriteLink;
