/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/reactSpa/spa"/>
import { setProcurementContext } from "Core/Medius.Core.Web/Scripts/components/topBar/topBarContextState";
import { App } from "Core/Medius.Core.Web/Scripts/Medius/apps/reactSpa/app";
import { observe, store } from "Core/Medius.Core.Web/Scripts/shared/store/reduxStore";
import * as appsUtils from "Core/Medius.Core.Web/Scripts/Medius/apps/utils";
import * as route from "Core/Medius.Core.Web/Scripts/Medius/apps/spaManager/route";

class ReactSpa {
    public viewContextName = "ReactSpa";
    public routes: any[];
    public componentConfig = {
        functionComponent: App
    };
    public app: ReactSpa;
    public ownsEntireViewPort = false;

    constructor(spaManager: any, public containerId: string) {
        const ownEntireViewPort = () => { this.ownsEntireViewPort = true; };
        const defaultViewPort = () => { this.ownsEntireViewPort = false; };
        this.routes = [
            route.create("demoManager", () => { defaultViewPort(); spaManager.openApp(this); }),
            route.create("configuration", () => { defaultViewPort(); spaManager.openApp(this); }),
            route.create("configuration/:optional", () => { defaultViewPort(); spaManager.openApp(this); }),
            route.create("configuration/:optional/:optional2", () => { defaultViewPort(); spaManager.openApp(this); }),
            route.create("procurement(/*optional)", () => {
                ownEntireViewPort();
                const unsubscribe = observe(s => s.accesses, (accesses) => {
                    if (!accesses) return;

                    if (!accesses.procurementV2) {
                        spaManager.router.navigate('/Tasks', { trigger: true, replace: true });
                        return;
                    }

                    if (accesses.procurementV2 &&
                        store.getState().topBarContext.context !== "Procurement") {
                        store.dispatch(setProcurementContext());
                    }

                    spaManager.openApp(this);
                    if (unsubscribe) {
                        unsubscribe();
                    }
                });
            }),
            route.create("Home", () => { spaManager.openApp(this); }),
            route.create("administrationv2/:optional", () => { defaultViewPort(); spaManager.openApp(this); })
        ];
        this.app = this;
    }
}

export function create(spaManager: any, containerId: string) {
    return new ReactSpa(spaManager, containerId);
}

export function getView() {
    return appsUtils.renderTemplate("Core:Medius/apps/reactSpa/spa.html");
}
