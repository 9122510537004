/// <amd-module name="Core/Medius.Core.Web/Scripts/components/procurement/addressUtils"/>
import { Address } from "./cart/cart";

export const compareAddresses = (x: Address, y: Address) => {
    return x.addressLine === y.addressLine &&
        x.addressLine2 === y.addressLine2 &&
        x.building === y.building &&
        x.city === y.city &&
        x.country === y.country &&
        x.division === y.division &&
        x.email === y.email &&
        x.fax === y.fax &&
        x.homepage === y.homepage &&
        x.location === y.location &&
        x.name === y.name &&
        x.phone === y.phone &&
        x.recipient === y.recipient &&
        x.state === y.state &&
        x.zip === y.zip;
};

export const compareAddressesWithNullCheck = (x: Address, y: Address) => {
    if(x === y) return true;
    if(x == null && y == null) return true;
    if(x == null || y == null) return false;

    return x.addressLine === y.addressLine &&
        x.addressLine2 === y.addressLine2 &&
        x.building === y.building &&
        x.city === y.city &&
        x.country === y.country &&
        x.division === y.division &&
        x.email === y.email &&
        x.fax === y.fax &&
        x.homepage === y.homepage &&
        x.location === y.location &&
        x.name === y.name &&
        x.phone === y.phone &&
        x.recipient === y.recipient &&
        x.state === y.state &&
        x.zip === y.zip;
};