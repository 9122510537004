///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/redistributionItem/dataProvider"/>
import { DataProvider } from "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/default/dataProvider";
import * as backendErrorHandler from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import * as rpc from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rpc";

class Provider extends DataProvider {
    constructor() {
        super();
    }

    callServer(phrase:string, limit:number, documentId:any, onlyPersonal:any) {
        const params = {
            searchText: phrase,
            documentId: documentId,
            maxResults: limit,
            onlyPersonal: onlyPersonal
        };

        return rpc.request("TaskManager", "GetRedistributionItems", params)
            .fail(function(data) {
                backendErrorHandler.handleAnyError(data);
            });
    }

    search(phrase:string, limit:number, options:any) {
        return this.callServer(phrase, limit, options.documentId, options.onlyPersonal);
    }
}

export function create() {
    return new Provider();
}