/// <amd-module name="Core/Medius.Core.Web/Scripts/lib/development/noISODatesValidator"/>
import * as mode from "./mode";
import * as notification from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import * as logger from "../../Medius/lib/logger";

export function isValid(json: any) {
    const visitedObjects = new Set();
    const hasNoISODates = isValidRecursively(json, visitedObjects);
    return hasNoISODates;
}

function isValidRecursively(json: any, visitedObjects: Set<any>): boolean {
    if (typeof json === "string") {
        // customly written, due to fact that we are not want to validate all ISO dates format, e.g. 1111 is valida ISO date format however
        // it's not case for us
        if (RegExp(/(^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$)|(^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$)|(^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}[+-]\d{4}$)/).test(json)) {
            return false;
        }
    }

    if (isArray(json)) {
        return isValidArrayRecursively(json, visitedObjects);
    } else if ((typeof json === "object") && (json !== null)) {

        // Naive protection from circular & shared dependencies 
        // Shared dependencies could be supported if needed by rewriting this validation algorithm to DFS based one (which discovers cycles)
        if (visitedObjects.has(json)) {
            return false;
        }

        visitedObjects.add(json);

        return isValidObjectRecursively(json, visitedObjects);
    } else {
        return true;
    }
}

function isValidArrayRecursively(arr: any[], visitedObjects: Set<any>) {
    return arr.every(x => isValidRecursively(x, visitedObjects));
}

function isValidObjectRecursively(obj: any, visitedObjects: Set<any>) {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            if (!isValidRecursively(obj[key], visitedObjects)) {
                return false;
            }
        }
    }
    return true;
}

function isArray(o: any) {
    return Object.prototype.toString.call(o) === "[object Array]";
}

export function assertNoISOFormatDates(json: any) {
    if (!mode.isProduction()) {
        if (!isValid(json)) {
            const title = "Incorrect date format";
            const message = `ISO date format returned, to handle ISO dates please use fetch/rest.ts module or add LegacyMicrosoftDateFormatSerializer attribute for Web API controller`;
            try {
                // this may fail for request which are done before page is fully loaded and DOM is not fully created yet
                notification.error(message, title);
            } catch (e) {}
            logger.error(`[${title}] ${message}`);
            return true;
        }
    }
    return false;
}
