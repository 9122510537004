/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/inbox/folder" />

import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import { observable, observableArray, computed } from "knockout";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { removeOrderCriteria, shallowCopySearchCriteria } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/helpers";
import * as notifications from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import { getDocumentOrderForType, getAllDocumentTypes } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/documentOrder";
import { defer, isFunction } from "underscore";
import { lightApi } from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rpc";
import { scrollToSelectedGridRow } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/helpers";
import { getFor } from "Core/Medius.Core.Web/Scripts/Medius/core/store/user/local";
import { create as taskGroupCreate } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/taskGroup/group";
import { startCountMeasurement } from "Core/Medius.Core.Web/Scripts/Medius/performance/loggers/folder";
import { IStoreStorage } from "Core/Medius.Core.Web/Scripts/Medius/lib/store/async";
import { Inbox } from "./inbox";

export class Folder {
    public inbox: Inbox;
    public isSearchFilterApplied: ko.Observable<boolean>;
    public id: any;
    public name: any;
    public visible = observable(true);
    public typeGroups = observableArray<any>([]);
    public noEntriesFoundVisible = observable(false);
    public localizedName: string;
    public counts: any[] = [];
    public columnSortingStore: IStoreStorage;

    public reloadCount = () => {
        if (this.inbox.isLoading() === true || this.inbox.isCountLoading() === true)
            return;
        this.typeGroups()
            .forEach((group) => {
                const count = this.counts[group.fullName];
                group.totalTasks(count);
            });
    };

    public totalFolderTasks: ko.Computed<number>;
    public totalFolderTasksSub: ko.Subscription;

    constructor(inbox: Inbox, folderDto: any, isSearchFilterApplied: ko.Observable<boolean>) {
        if (isNullOrUndefined(inbox)) {
            throw new Error("Can't initialize without providing Inbox instance");
        }
        this.inbox = inbox;
        this.isSearchFilterApplied = isSearchFilterApplied;
        this.id = folderDto.Id;
        this.name = folderDto.Name;
        this.localizedName = translate(this.name);
        this.columnSortingStore = getFor("Core:inbox-columns-sorting");
        this.totalFolderTasks = computed(() => {
            let total = 0;

            this.typeGroups().forEach((group) => {
                total += group.visibleTasks().length;
            });

            return total;
        });
        this.totalFolderTasksSub = this.totalFolderTasks.subscribe((value) => {
            this.noEntriesFoundVisible(value === 0);
            this.tryOpenTask(value);
        });
    }

    public init() {
        if (this.visible()) {
            return this.columnSortingStore.getItem(this.id)
                .then(() => this.loadTasks())
                .fail(() => this.loadTasks());
        }
    }

    public loadTasks() {
        if (!this.visible()) {
            return null;
        }

        return this.columnSortingStore.getItem(this.id)
            .then((criteriaJson: string) => JSON.parse(criteriaJson))
            .fail(() => null as any)
            .then((savedOrderCriteria: any) => {
                let searchCriteria = shallowCopySearchCriteria(this.inbox.search.currentCriteria());

                if (searchCriteria && searchCriteria.OrderCriteria) {
                    const hasCriteriaForCorrectFolder = searchCriteria.OrderCriteria.some((oc: any) => oc.FolderId === this.id);

                    if (!hasCriteriaForCorrectFolder) {
                        searchCriteria.OrderCriteria = null;
                    }
                }
                if (savedOrderCriteria) {
                    if (searchCriteria === null) {
                        searchCriteria = {
                            OrderCriteria: savedOrderCriteria
                        };
                    } else {
                        searchCriteria.OrderCriteria = savedOrderCriteria;
                    }
                }
                return searchCriteria;
            }).then((searchCriteria: any) => {
                this.inbox.isLoading(true);
                return this.makeRequest(searchCriteria)
                    .then((documentTasksWrappers: any[]) => {
                        const groups = documentTasksWrappers
                            .sort((a, b) => getDocumentOrderForType(a.FullName) - getDocumentOrderForType(b.FullName))
                            .map((wrapper) => taskGroupCreate(this, wrapper, searchCriteria ? searchCriteria.OrderCriteria : null));
                        this.disposeGroups();
                        this.typeGroups(groups);
                        this.noEntriesFoundVisible(groups.length === 0);
                        this.inbox.generateDocumentTypes();

                        if (this.inbox.currentTypeGroup())
                            this.inbox.openTypeGroup();
                    }).fail(() => {
                        this.columnSortingStore.removeItem(this.id).then(() => {
                            notifications.error(translate("#Core/pleaseRefreshThePageAndTryAgain"), translate("#Core/errorOccuredDuringLoadingInbox"));
                        });
                    });
            })
            .always(() => {
                this.inbox.isLoading(false);
                this.reloadCount();
                scrollToSelectedGridRow();
            });
    }

    public countTasks() {
        const searchCriteria = this.inbox.search.currentCriteria();
        const measurement = startCountMeasurement();

        if (!this.visible()) {
            return null;
        }

        this.inbox.isCountLoading(true);

        //information about sorting is not needed for count
        return this.makeCountRequest(removeOrderCriteria(searchCriteria))
            .done((counts) => {
                this.counts = counts;
            })
            .always(() => {
                this.inbox.isCountLoading(false);
                this.reloadCount();
                measurement.stop();
            });
    }

    public tryOpenTask(totalAvailableTasks: number) {
        const openFirstTask = () => {
            if (totalAvailableTasks === 1 && !this.inbox.currentTaskId()) {
                this.inbox.openFirstTask();
            }
        };
        defer(openFirstTask); // required to be sure all computes about task state were updated before attempt to open first task
    }

    public dispose() {
        this.totalFolderTasksSub.dispose();
        this.totalFolderTasks.dispose();
        this.disposeGroups();
        this.typeGroups([]);
    }

    public disposeGroups() {
        this.typeGroups().forEach((group) => {
            if (group && isFunction(group.dispose)) {
                group.dispose();
            }
        });
    }

    public makeRequest(searchCriteria: any) {
        return lightApi("InboxService", "GetTasksLimitedPerDocumentType", {
            folderId: this.id,
            filter: searchCriteria,
            start: 0,
            count: this.getLastPageSizes()
        });
    }

    public makeCountRequest(searchCriteria: any) {
        return lightApi("InboxService", "GetTasksCount", {
            folderId: this.id,
            filter: searchCriteria
        });
    }

    getLastPageSizes() {
        const docTypes = getAllDocumentTypes();
        const pageSizes: { [key: string]: number } = {};
        docTypes.forEach(t => {
            const pageSize = localStorage.getItem(`${getFor("Core:inbox-page-size").prefix}:folder(${this.id}):${t}`);
            if (pageSize != null) {
                pageSizes[t] = JSON.parse(pageSize);
            }
            else {
                pageSizes[t] = 20;
            }
        });
        return pageSizes;
    }
}

export function create(inbox: Inbox, folderDto: any, isSearchFilterApplied: ko.Observable<boolean>) {
    return new Folder(inbox, folderDto, isSearchFilterApplied);
}