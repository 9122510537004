/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/wizard/steps/result" />

import * as ko from "knockout";
import { WizardStep } from "Core/Medius.Core.Web/Scripts/Medius/components/wizard/steps/wizardStep";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
export class ResultWizardStep<TData> extends WizardStep<TData> {
    public result: ko.Observable<any>;

    constructor() {
        super(globalization.getLabelTranslation("#Core/result"), "Core:Medius/components/wizard/steps/result.html");
        this.result = ko.observable();
    }
}