/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/administration/entityCollection"/>

import {isNull, isUndefined, isEmpty, find} from "underscore";
import * as ko from "knockout";

export class EntityCollection<T extends {Id: any}> {
    constructor(public Collection: ko.ObservableArray<T>) {}

    public addToCollection(entity?: T) {
        if (isNull(entity) || isUndefined(entity)) {
            return;
        }

        if (!this.inCollection(entity.Id)) {
            this.Collection.push(entity);
        }
    }

    public removeFromCollection(entity?: T) {
        const unwrappedEntity = ko.unwrap(entity);

        if (isNull(unwrappedEntity) || isUndefined(unwrappedEntity) || isEmpty(this.Collection())) {
            return;
        }

        const id = ko.unwrap(unwrappedEntity.Id);

        if (this.inCollection(id)) {
            this.Collection.remove((item) => ko.unwrap(item.Id) === id);
        }
    }

    public inCollection(id: any) {
        if (isEmpty(this.Collection())) {
            return false;
        }

        id = ko.unwrap(id);

        const found = find(this.Collection(), (element) => id === ko.unwrap(element.Id));

        return found || null;
    }
}
