/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/LoginOption/General/general"/>
import { IEntityWithContext } from "Core/Medius.Core.Web/Scripts/AdminPages/IEntityWithContext";
import * as backendErrorHandler from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import { observable, pureComputed } from "knockout";
import { info } from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import { lightApi } from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rpc";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import {isNotAStringOrEmpty} from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import { getProtocolTypesWithLabels, ProtocolType, ProtocolTypeWithLabel } from "Core/Medius.Core.Web/Scripts/AdminPages/LoginOption/General/protocolType";
import { getTenantId } from "Core/Medius.Core.Web/Scripts/Medius/lib/path";
const FileReader = (this as any).globals.FileReader;
const megabyte = 1024 * 1024;

class LoginOption {
    public entity: any;
    public wizard = observable<any>();
    public isLoading = observable(false);
    public isDragOver = observable(false);
    public metadataContent = observable<any>();
    public refreshUsingUrl: () => void;
    public refreshUsingMetadata: (text: string) => void;
    public refreshUsingTextContent: () => void;
    public updateEntityData: (data: any) => void;
    public isRefreshEnabled: ko.Computed<boolean>;
    public handleDrop: (t: any, e: any) => void;
    public handleDragEnter: () => void;
    public handleDragLeave: () => void;
    public isDragAndDropSupported = !!FileReader;
    public shouldDisplayCliendIdAndSecret = observable(false);
    public shouldDisplayAllOpenIdFields = observable(false);
    public setShouldDisplayCliendIdAndSecret: () => void;
    public isProtocolTypeGoogle: () => boolean;
    public isProtocolTypeOpenIdConnect: () => boolean;
    public availableProtocolTypes = observable<ProtocolTypeWithLabel[]>(getProtocolTypesWithLabels());
    private oidcIdentityProviderIssuer = "../signin-oidc";
    private oidcRedirectUrlSuffix = "signin-oidc";
    private getRedirectUrl: () => string;

    constructor(entity: any) {
        this.entity = entity;
        this.refreshUsingUrl = () => {
            this.isLoading(true);

            lightApi("IdentityProviderConfigurationService", "GetDefaultConfigurationFromUrl", {
                url: this.entity.MetadataUrl()
            }).done(this.updateEntityData).fail((e: any) => {
                backendErrorHandler.handleAnyError(e);
            }).always(() => {
                this.isLoading(false);
            });
        };

        this.refreshUsingMetadata = (text: string) => {
            this.isLoading(true);

            lightApi("IdentityProviderConfigurationService", "GetDefaultConfigurationFromMetadata", {
                federationMetadata: text
            }).done(this.updateEntityData)
                .fail((e: any) => {
                    backendErrorHandler.handleAnyError(e);
                }).always(() => {
                    this.isLoading(false);
                });
        };

        this.refreshUsingTextContent = () => {
            this.refreshUsingMetadata(this.metadataContent());
        };

        this.updateEntityData = (data: any) => {
            this.entity.Thumbprint(data.CertificateThumbprint);
            this.entity.Issuer(data.IssuerUrl);
            this.entity.ProtocolType(data.ProtocolType);
        };

        this.setShouldDisplayCliendIdAndSecret = () => {
            const isProtocolTypeGoogle = this.isProtocolTypeGoogle();
            const isProtocolTypeOpenIdConnect = this.isProtocolTypeOpenIdConnect();

            this.shouldDisplayCliendIdAndSecret(isProtocolTypeGoogle || isProtocolTypeOpenIdConnect);
            this.shouldDisplayAllOpenIdFields(isProtocolTypeOpenIdConnect);

            if(!(isProtocolTypeGoogle || isProtocolTypeOpenIdConnect)){
                this.entity.ClientId(null);
                this.entity.ClientSecret(null);
                this.entity.Issuer(null);
                this.entity.Scopes(null);
                this.entity.MetadataAddress(null);
            }
            else{
                this.entity.Issuer(this.oidcIdentityProviderIssuer);
            }
        };

        this.isProtocolTypeGoogle = () => {
            return this.entity.ProtocolType() == ProtocolType.Google;
        };

        this.isProtocolTypeOpenIdConnect = () => {
            return this.entity.ProtocolType() == ProtocolType.OpenIdConnect;
        };

        this.getRedirectUrl = () => {
            return window.location.protocol + "//" + window.location.host + "/" + getTenantId() + "/" + this.oidcRedirectUrlSuffix;
        };

        this.isRefreshEnabled = pureComputed(() => isNotAStringOrEmpty(this.entity.MetadataUrl()) === false);

        this.handleDrop = (t: any, e: any) => {
            e.stopPropagation();
            e.preventDefault();
            this.isDragOver(false);

            const reader = new FileReader();
            reader.onload = (theFile: any) => {
                if(theFile.lengthComputable && theFile.total <= megabyte) {
                    this.refreshUsingMetadata(theFile.target.result);
                }
                else {
                    info(translate('#Core/xmlExceedsLimit'), translate('#Core/xmlFileTooBig'));
                }
            };
            reader.readAsText(e.originalEvent.dataTransfer.files[0]);
        };

        this.handleDragEnter = () => {
            this.isDragOver(true);
        };

        this.handleDragLeave = () => {
            this.isDragOver(false);
        };

        this.shouldDisplayCliendIdAndSecret(this.isProtocolTypeGoogle() || this.isProtocolTypeOpenIdConnect());
        this.shouldDisplayAllOpenIdFields(this.isProtocolTypeOpenIdConnect());
    }

    handleDrag(t: any, evt: Event) {
        evt.stopPropagation();
        evt.preventDefault();
    }
}

export default function(entityWithCtx: IEntityWithContext) {
    return new LoginOption(entityWithCtx.Entity);
}
