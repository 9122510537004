///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/AgentIconComponent"/>
import * as React from "react";
import {
    SidebarOverlay,
    LoaderIcon,
    IconCommunicationPaperPlaneFill,
    IconActionCloseRegular,
    LocalizationContext,
    StringMap,
    EmptyState,
    ImageMessagessConversation,
    ImageLogoMediusCopilot,
    IconAppsAppCopilotRegular
} from "@medius/ui-controls";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { AiThinkingIcon } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/Components/AiThinkingIcon";
import { useClickOutsideReference } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/hooks/useClickOutsideReference";
import * as services from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/services/agentServices";
import { IMessage, IPredefinedQuestion, IStreamCallOriginDetails, Role, StreamCallScenario } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/agentInterfaces";
import { IAgentIconComponentProps } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/agentInterfaces";
import { ChatMessage } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/Components/ChatMessage";
import { focusOnElement, scrollToBottom } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/utils";
import { newGuid } from "Core/Medius.Core.Web/Scripts/lib/uniqueGuid";
import { PredefinedQuestions } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/Components/PredefinedQuestionButtons";
import { handleError } from "Core/Medius.Core.Web/Scripts/lib/errorHandling/errorHandler";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
import { getCurrentCulture } from "Core/Medius.Core.Web/Scripts/lib/reactIntegration/kendoIntl";
import { mapFromNorthstarToApa } from "Core/Medius.Core.Web/Scripts/lib/northstarLocalization";
import { getCultureCode } from "Core/Medius.Core.Web/Scripts/Medius/lib/utils/user";
import { LegalQuestionsContextMenu } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/Components/LegalQuestionsContextMenu";
import { WelcomeWidget } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/widgets/WelcomeWidget";
import { NonOperationalWidget } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/widgets/NonOperationalWidget";
import { FeedbackModal } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/Components/FeedbackModal";
import { isCopilotPredictedNextApproversEnabled } from "Core/Medius.Core.Web/Scripts/Medius/core/featureToggle";

export interface IAgentProps extends IAgentIconComponentProps {
    isInFullscreen: boolean;
}

export default function AgentIconComponent({
    documentId,
    isOperational,
    isInFullscreen,
    workflowStepName,
    invoiceType,
    getDelegationMessage,
    getEscalationMessage
}: IAgentProps) {
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
    const [messages, setMessages] = React.useState<IMessage[]>([]);
    const [prompt, setPrompt] = React.useState("");
    const [isStreamActive, setIsStreamActive] = React.useState(false);
    const [isLoadingMessages, setIsLoadingMessages] = React.useState(true);
    const [processedMessage, setProcessedMessage] = React.useState<IMessage>();
    const [conversationId, setConversationId] = React.useState<string>();
    const [abortController, setAbortController] = React.useState<AbortController>();
    const [requestTimeout, setRequestTimeout] = React.useState<NodeJS.Timeout>();
    const [isAgentAvailable, setIsAgentAvailable] = React.useState(true);
    const [predefinedQuestions, setPredefinedQuestions] = React.useState<IPredefinedQuestion[]>([]);
    const [isFeedbackModalOpen, setIsFeedbackModalOpen] = React.useState(false);
    const [predictedNextApprovers, setPredictedNextApprovers] = React.useState<string[]>();
    const [invoiceInsightsMessageIndex, setInvoiceInsightsMessageIndex] = React.useState<number>();
    const [werePredictedNextApproversUpdated, setWerePredictedNextApproversUpdated] = React.useState(false);
    const [lastStreamCallDetails, setLastStreamCallDetails] = React.useState<IStreamCallOriginDetails | null>(null);

    const streamCallScenarios: { predefined: StreamCallScenario, freetext: StreamCallScenario } = {
        predefined: "predefined",
        freetext: "freetext"
    };

    const iconRef = React.useRef(null);

    useClickOutsideReference(iconRef, () => {
        setIsSidebarOpen(false);
    });

    const lastMessageRef = React.useRef(null);
    const promptInputRef = React.useRef(null);

    const getPredictedNextApprovers = async () => {
        try {
            if(isCopilotPredictedNextApproversEnabled() && invoiceType === "Medius.ExpenseInvoice.Entities.ExpenseInvoice") {
                const data = await services.getPredictedNextApprovers(documentId);
                setPredictedNextApprovers(data);
            }
            else {
                setPredictedNextApprovers([]);
            }
        }
        catch(error) {
            handleError(error);
            setIsAgentAvailable(false);
        }
    };

    React.useEffect(() => {
        const fetchData = async () => {
            setIsAgentAvailable(true);
            if (isSidebarOpen && messages.length === 0) {
                try {
                    const previousMessages = await services.getMessages();

                    if (isOperational) {
                        getPredictedNextApprovers();
                        const invoiceContextMessage = await services.getInvoiceContext(documentId);
                        if (invoiceContextMessage) {
                            previousMessages.push(invoiceContextMessage);
                            setInvoiceInsightsMessageIndex(previousMessages.length - 1);
                        }

                        const predefinedQuestionsFromService = await services.getPredefinedQuestions();
                        if(predefinedQuestionsFromService.length > 0) {
                            setPredefinedQuestions(predefinedQuestionsFromService);
                        } else {
                            throw new Error("Could not load predefined questions");
                        }
                    }
                    else {
                        const predefinedQuestionsFromService = await services.getLegalPredefinedQuestions();
                        if(predefinedQuestionsFromService.length > 0) {
                            setPredefinedQuestions(predefinedQuestionsFromService);
                        } else {
                            throw new Error("Could not load predefined questions");
                        }
                    }
                    setMessages(previousMessages);
                    //generate new conversation guid
                    setConversationId(newGuid());
                }
                catch (error) {
                    handleError(error);
                    setIsAgentAvailable(false);
                }
                finally {
                    setIsLoadingMessages(false);                
                }
            }
        };

        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSidebarOpen]);

    React.useEffect(() => {
        if(!werePredictedNextApproversUpdated && predictedNextApprovers && messages.length > 0 && invoiceInsightsMessageIndex != undefined) {
            setWerePredictedNextApproversUpdated(true);
            setMessages(messages.map((message, index) => {
                if(invoiceInsightsMessageIndex == index) {
                    return {
                        ...message, 
                        invoiceContextDto: {
                            ...message.invoiceContextDto, 
                            predictedNextApprovers: predictedNextApprovers
                        }
                    };
                }
                return message;
            }));
        }
    }, [invoiceInsightsMessageIndex, predictedNextApprovers, messages, werePredictedNextApproversUpdated]);

    React.useEffect(() => {
        scrollToBottom(lastMessageRef);
        focusOnElement(promptInputRef);
    }, [messages]);

    React.useEffect(() => {
        if (processedMessage) {
            if(isStreamActive) {
                scrollToBottom(lastMessageRef);
                return;
            }
            if(messageHasAnythingToDisplay(processedMessage)) {
                setMessages(prevMessages => [...prevMessages, processedMessage]);
            }
            setProcessedMessage(null);
        }
    }, [processedMessage, isStreamActive]);
    
    const handleSubmitPrompt = () => {
        if (prompt.length === 0 || isStreamActive)
            return;
        const oldMessages = [...messages];
        const truncatedPrompt = prompt.substring(0, 2000);
        setMessages(prevMessages => [...prevMessages, {
            content: truncatedPrompt,
            role: Role.user,
            conversationId: conversationId,
            documentId: documentId,
            workflowStepName: workflowStepName,
            invoiceType: invoiceType,
            date: new Date().toISOString(),
            sources: []
        }]);
        setPrompt("");
        sendPrompt(truncatedPrompt, conversationId, oldMessages);
    };

    const handleStreamEnd = () => {
        setIsStreamActive(false);
    };

    const handleAbort = () => {
        if (isStreamActive) {
            abortController.abort();
            clearTimeout(requestTimeout);
            handleStreamEnd();
        }
    };

    const messageHasAnythingToDisplay = (message: IMessage) => {
        return message?.content || 
        message?.invoiceContextDto ||
        message?.supplierWidgetDto || 
        message?.isError;
    };

    const handleMessageChunk = (message: IMessage) => {
        setProcessedMessage({
            content: getLabelTranslation(message.content),
            role: Role.assistant,
            supplierWidgetDto: message.supplierWidgetDto,
            sources: message.sources,
            conversationId: conversationId,
            documentId: documentId,
            date: message.date,
            isError: message.isError
        });
    };

    const bypassBackendCallWithLocalMessage = (localMessage: string) => {
        setTimeout(() => {
            handleMessageChunk({
                content: localMessage,
                role: Role.user,
                conversationId: conversationId,
                documentId: documentId,
                date: new Date().toISOString(),
                sources: []
            });
            handleStreamEnd();
        }, 500);
    };

    const predefinedQuestionClickCallback = async (predefinedQuestion: string, isRetry: boolean = false) => {
        if (isStreamActive)
            return;

        setLastStreamCallDetails(
            {
                origin: streamCallScenarios.predefined, 
                predefinedArguments: { predefinedQuestion: predefinedQuestion },
                freetextArguments: null
            }
        );

        const translatedQuestion = getLabelTranslation(predefinedQuestion);
        const oldMessages = [...messages];
        if(!isRetry){
            setMessages(prevMessages => [...prevMessages, {
                content: translatedQuestion,
                role: Role.user,
                conversationId: conversationId,
                documentId: documentId,
                workflowStepName: workflowStepName,
                invoiceType: invoiceType,
                date: new Date().toISOString(),
                sources: []
            }]);
        }

        setIsStreamActive(true);

        const delegationMessage = getDelegationMessage();
        const escalationMessage = getEscalationMessage();

        if (delegationMessage !== "") {
            bypassBackendCallWithLocalMessage(delegationMessage);
            return;
        }

        if (escalationMessage !== "") {
            bypassBackendCallWithLocalMessage(escalationMessage);
            return;
        }

        const categorizationId = predefinedQuestions.find(question => question.value === predefinedQuestion).categorizationId;

        const abortControllerInstance = new AbortController();
        setAbortController(abortControllerInstance);

        const timeout = setTimeout(() => {
            abortControllerInstance.abort();
            handleStreamEnd();
            handleError(new Error(getLabelTranslation("#Core/timeoutException")));
        }, 60000);

        setRequestTimeout(timeout);

        try {
            await services.streamResponse(
                documentId,
                workflowStepName,
                invoiceType,
                "", //temporaryDelegationMessage
                "", //escalation message         -- both are handled before this function call, no need to send anything here.
                isOperational,
                translatedQuestion,
                conversationId,
                oldMessages,
                handleMessageChunk,
                handleStreamEnd,
                abortControllerInstance.signal,
                timeout,
                categorizationId
            );
        } catch (error) {
            setMessages(prev => [...prev, 
                {
                    isError: true,
                    content: "",
                    role: Role.user,
                    conversationId: conversationId,
                    documentId: documentId,
                    workflowStepName: workflowStepName,
                    invoiceType: invoiceType,
                    date: new Date().toISOString(),
                    sources: []
                }
            ]);
        }

    };

    const sendPrompt = async (prompt: string, conversationId: string, oldMessages: IMessage[]) => {
        setLastStreamCallDetails(
            { 
                origin: streamCallScenarios.freetext, 
                freetextArguments: { prompt, conversationId, oldMessages },
                predefinedArguments: null
            }
        );
        setIsStreamActive(true);
        const handleMessageChunk = (message: IMessage) => {
            setProcessedMessage({
                content: getLabelTranslation(message.content),
                role: Role.assistant,
                supplierWidgetDto: message.supplierWidgetDto,
                sources: message.sources,
                invoiceContextDto: message.invoiceContextDto,
                conversationId: conversationId,
                documentId: documentId,
                date: message.date,
                isError: message.isError
            });
        };

        const abortControllerInstance = new AbortController();
        setAbortController(abortControllerInstance);

        const timeout = setTimeout(() => {
            abortControllerInstance.abort();
            handleStreamEnd();
            handleError(new Error(getLabelTranslation("#Core/timeoutException")));
        }, 60000);

        setRequestTimeout(timeout);
        try {
            await services.streamResponse(
                documentId,
                workflowStepName,
                invoiceType,
                getDelegationMessage(),
                getEscalationMessage(),
                isOperational,
                prompt,
                conversationId,
                oldMessages,
                handleMessageChunk,
                handleStreamEnd,
                abortControllerInstance.signal,
                timeout
            );
        } catch (error) {
            setMessages(prev => [...prev, 
                {
                    isError: true,
                    content: "",
                    role: Role.user,
                    conversationId: conversationId,
                    documentId: documentId,
                    workflowStepName: workflowStepName,
                    invoiceType: invoiceType,
                    date: new Date().toISOString(),
                    sources: []
                }
            ]);
        }
    };

    function updateAreaHeight(areaRef: React.RefObject<HTMLTextAreaElement>, prompt: string) {
        if (!areaRef.current) return;
        if(prompt != ""){
            const area = areaRef.current;
            area.style.height = '';
            const scrollHeight = Math.ceil(area.scrollHeight);
            area.style.height = `${scrollHeight-8}px`;
        } else {
            const area = areaRef.current;
            area.style.height = '16px';
        }
    }

    const handlePromptInputChange = (e: any) => {
        setPrompt(e.target.value);
        updateAreaHeight(promptInputRef, e.target.value);
    };

    const handlePromptInputKeyDown = (e: any) => {
        if (e.key === "Enter") {
            e.preventDefault();
            handleSubmitPrompt();
        }
    };

    React.useEffect(() => {
        updateAreaHeight(promptInputRef, prompt);
    }, [prompt]);

    const isPromptInputDisabled = () => {
        if (workflowStepName != "EndTask" && workflowStepName != "Archive"){
            return isStreamActive;
        }
        return true;
    };

    const handleStreamRetry = () => {
        setMessages(prev => {
            const newMessages = [...prev];
            newMessages.pop();
            return newMessages;
        });
        if(!lastStreamCallDetails)
            return;
        if(lastStreamCallDetails.origin === streamCallScenarios.freetext){
            if(!lastStreamCallDetails.freetextArguments)
                return;
            sendPrompt(
                lastStreamCallDetails.freetextArguments.prompt,
                lastStreamCallDetails.freetextArguments.conversationId,
                lastStreamCallDetails.freetextArguments.oldMessages
            );
            return;
        }
        if(lastStreamCallDetails.origin === streamCallScenarios.predefined){
            if(!lastStreamCallDetails.predefinedArguments)
                return;
            predefinedQuestionClickCallback(lastStreamCallDetails.predefinedArguments.predefinedQuestion, true);
            return;
        }
    };

    return (
        <LocalizationContext.Provider value={{
            translate: (key: string, _, defaultValue?: string, args?: StringMap) => mapFromNorthstarToApa(key, defaultValue, args),
            getLocale: () => getCultureCode()
        }} >
            <IntlProvider locale={getCurrentCulture()}>
                <LocalizationProvider language="current-language">
                    <div className="mediusAgent">
                        <div className="mediusAgent__iconContainer" onClick={() => setIsSidebarOpen(true)} data-testid="apaAgentIconButton">
                            <IconAppsAppCopilotRegular size="large" className="mediusAgent__icon" />
                        </div>
                        <div
                            ref={iconRef}
                            style={
                                    isInFullscreen ?
                                        { position: "absolute", top: "-20px", right: "-44px" } :
                                        { position: "absolute", top: "-10px", right: "-24px" }
                            }
                            data-testid="apaAgentAnchor"
                        ></div>
                        <SidebarOverlay
                            icon={<ImageLogoMediusCopilot className="mediusAgent__titleImage" />}
                            contentBackground="light"
                            title={"Medius Copilot"}
                            alignment="right"
                            isOpen={isSidebarOpen}
                            targetRef={iconRef}
                            onClose={() => setIsSidebarOpen(false)}
                            data-testid="apa-agent"
                        >
                            <div className="mediusAgent__innerContainer">
                                {!isAgentAvailable ?
                                    <EmptyState
                                        explanation={getLabelTranslation("#Core/agentUnavailableExplanation")}
                                        size="large"
                                        title={getLabelTranslation("#Core/agentUnavailableTitle")}
                                        image={<ImageMessagessConversation/>}
                                        centerInContainer={true}
                                        contentAlign="center"
                                    />
                                    : (
                                        <>
                                            {isLoadingMessages ?
                                                <div className="mediusAgent__innerContainer__loaderIconContainer">
                                                    <LoaderIcon />
                                                </div>
                                                : (<>
                                                    <div className="mediusAgent__messagesContainer" data-testid="mediusAgent__messagesContainer">
                                                        { isOperational ? 
                                                            <WelcomeWidget /> :
                                                            <NonOperationalWidget
                                                                onOpenFeedbackModal={() => setIsFeedbackModalOpen(true)} />
                                                            }
                                                        <>
                                                        {messages.map((el, index) => (
                                                            <ChatMessage
                                                                message={el} 
                                                                key={index} 
                                                                invoiceType={invoiceType} 
                                                                workflowStepName={workflowStepName}
                                                                retryCallback={handleStreamRetry}
                                                                showRetryButton={index === messages.length-1}
                                                            />
                                                        ))}
                                                        </>
                                                        { isStreamActive && !processedMessage?.content && 
                                                            <AiThinkingIcon/>
                                                        }
                                                        { messageHasAnythingToDisplay(processedMessage) &&
                                                            <ChatMessage
                                                                message={processedMessage} 
                                                                key={messages.length} 
                                                                invoiceType={invoiceType} 
                                                                workflowStepName={workflowStepName}
                                                                retryCallback={handleStreamRetry}
                                                                showRetryButton={false}
                                                            />
                                                        }
                                                        <div className="mediusAgent__lastMessageAnchor" ref={lastMessageRef} />
                                                    </div>
                                                    <div className="mediusAgent__innerContainer__bottom">
                                                        {isOperational &&
                                                            <PredefinedQuestions
                                                                predefinedQuestionsList={predefinedQuestions}
                                                                disabled={isStreamActive}
                                                                onPredefinedQuestionClick={predefinedQuestionClickCallback}
                                                            />
                                                        }
                                                        <div className="mediusAgent__promptInput">
                                                            <LegalQuestionsContextMenu 
                                                                buttonClickCb={predefinedQuestionClickCallback} 
                                                                onOpenFeedbackModal={() => setIsFeedbackModalOpen(true)}
                                                                isFeedbackModalOpen={isFeedbackModalOpen}
                                                            />
                                                            <textarea
                                                                className="mediusAgent__promptInput__textarea"
                                                                value={prompt}
                                                                placeholder={getLabelTranslation("#Core/askMeAnythingAboutThisInvoice")}
                                                                ref={promptInputRef}
                                                                onChange={handlePromptInputChange}
                                                                onKeyDown={handlePromptInputKeyDown}
                                                                disabled={isPromptInputDisabled()}
                                                                data-testid="apaAgentPromptInput"
                                                            />
                                                            {isStreamActive ?
                                                                <div
                                                                    className="mediusAgent__promptInput__chatIcon"
                                                                    onClick={handleAbort}
                                                                    data-testid="apaAgentCancelRequestButton"
                                                                >
                                                                    <IconActionCloseRegular size="medium" color="brand" />
                                                                </div>
                                                                : <div
                                                                    className="mediusAgent__promptInput__chatIcon"
                                                                    onClick={handleSubmitPrompt}
                                                                    data-testid="apaAgentSendMessageButton"
                                                                >
                                                                    <IconCommunicationPaperPlaneFill size="medium" color="brand" />
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </>)}
                                        </>
                                    )
                                }
                            </div>
                        </SidebarOverlay>
                        <FeedbackModal 
                            isOpen={isFeedbackModalOpen} 
                            onClose={() => setIsFeedbackModalOpen(false)} 
                            documentId={documentId} 
                            conversationId={conversationId} 
                            invoiceType={invoiceType} 
                            workflowStepName={workflowStepName}
                        />
                    </div>
                </LocalizationProvider>
            </IntlProvider>
        </LocalizationContext.Provider>
    );
}