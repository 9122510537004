///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/core/errors/register/knockout"/>
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import { Errors } from "Core/Medius.Core.Web/Scripts/Medius/core/errors/model";
import * as ko from "knockout";

const errorKey = "medius-errors";

const binding: ko.BindingHandler = {
    init: function (element, bindingAccessor, allAccessor, viewModel, context) {
        const id = ko.utils.unwrapObservable(bindingAccessor().id) || null,
            type = ko.utils.unwrapObservable(bindingAccessor().type) || null,
            tabId = $(element).parent().data("tabId"),
            currentError = koUtils.domData.get(element, errorKey);

        if (!currentError) {
            const errors = new Errors(element, {
                tabId: tabId,
                entityViewId: id,
                entityType: type
            });

            const newContext = context.createChildContext(errors);
            ko.applyBindingsToDescendants(newContext, element);
            koUtils.domData.set(element, errorKey, context);
        }

        return { controlsDescendantBindings: true };
    },
    update: function (element, ...args:any[]) {
        const currentErrors = koUtils.domData.get(element, errorKey);

        args = [element, ...args];
        if (!currentErrors) {
            binding.init.apply(this, args);
        }

        return { controlsDescendantBindings: true };
    }
};

export function register() {
    koUtils.registerBinding("errors", binding);
}