///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/administration/bulk/deleteModal"/>
import * as ko from "knockout";
import * as rest from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rest";
import * as bulkAdminResult from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/bulk/bulkAdminResult";
import * as json from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/helpers";

class BulkDeleteModal {
    EntityType: any;
    EntitiesIds: any;
    IsVisible: any;
    isDeleteSend: ko.Observable<boolean>;
    result: ko.Observable<any>;
    constructor(isVisible: any, entitieIds: any, entityName:any){
        this.EntityType = entityName();
        this.EntitiesIds = entitieIds;
        this.IsVisible = isVisible;

        this.isDeleteSend = ko.observable(false);
        this.result = ko.observable(null);
    }

    deleteConfirm() {
        this.isDeleteSend(true);


        rest.del("bulkAdministration", "", json.toJSON({
                EntityType: this.EntityType,
                AffectedEntities: this.EntitiesIds
            }), null)
            .then((result) => {
                this.result(bulkAdminResult.createFromActionSuccess(result));
            }, (result) => {
                this.result(bulkAdminResult.createFromActionFailure(result));
            });
    };
};

export = function (isVisible: any, entitieIds: any, entityKey: any) {
    return new BulkDeleteModal(isVisible, entitieIds, entityKey);
};