/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Entities.Role/Authorization"/>

import { ObservableArray, Observable, Computed, observableArray, observable, computed } from "knockout";
import * as _ from "underscore";
import { getFormattedLabelTranslation, getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { error } from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import { getNew, load } from "Core/Medius.Core.Web/Scripts/Medius/core/sync";
import { toJS } from "Core/Medius.Core.Web/Scripts/Medius/lib/serialization";
import * as grid from "Core/Medius.Core.Web/Scripts/Medius/components/grid/default/grid";
import { handleAnyError } from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import * as authorizationTabGridDataSource from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Entities.Role/authorizationDataSource";
import { isSameClassOrSubclass } from "Core/Medius.Core.Web/Scripts/Medius/components/resolver/type";
import * as DataObjectDataProvider from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Entities.Role/DataObjectDataProvider";
import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";

const ROLE_DATA_OBJECT_AUTHORIZATION_FULLNAME = "Medius.Core.Entities.Security.RoleDataObjectAuthorization";

const accessLevels = {
    Deny: 0,
    Personal: 1,
    Creator: 2,
    Allow: 3
};

class AuthorizationTab {
    Entity: any;
    Context: any;
    RelatedEntities: any;
    Tab: any;
    DataObjectCollection: ObservableArray<any>;
    DataObjectNames: ObservableArray<any>;
    SelectedDataObjectTypeName: Observable<any>;
    AuthorizationGroupCollection: ObservableArray<any>;
    DataObjectProvider: any;
    IsRoleSaved: boolean;
    grid: Observable<any>;
    authorizationGroupNames: Computed<any[]>;
    nonPersonalAuthorizationGroupNames: Computed<any[]>;
    constructor(entityWithContext: any, companyContextId: any, tabModel: any) {
        this.Entity = entityWithContext.Entity;
        this.Context = entityWithContext.Context;

        this.RelatedEntities = entityWithContext.RelatedObjects[ROLE_DATA_OBJECT_AUTHORIZATION_FULLNAME] ?
            entityWithContext.RelatedObjects[ROLE_DATA_OBJECT_AUTHORIZATION_FULLNAME].RelatedEntities :
            observableArray([]);
        this.Tab = tabModel;
        this.DataObjectCollection = observableArray([]);
        this.DataObjectNames = observableArray([]);
        this.SelectedDataObjectTypeName = observable();
        this.AuthorizationGroupCollection = observableArray([]);
        this.DataObjectProvider = DataObjectDataProvider.create();
        this.IsRoleSaved = this.Entity.Id() !== 0; //authorization can be modify only if entity is already saved
        this.grid = observable();
        this.authorizationGroupNames = computed(() => {
            return _(this.AuthorizationGroupCollection()).map((item) => item.Name);
        });

        this.nonPersonalAuthorizationGroupNames = computed(() => {
            return _(this.AuthorizationGroupCollection()).filter((authGroup) => {
                return _([authGroup.CreatePermission, authGroup.UpdatePermission, authGroup.ReadPermission, authGroup.DeletePermission])
                    .contains(accessLevels.Personal) === false;
            }).map((item) => item.Name);
        });

        if (this.IsRoleSaved) {
            this.initializeAvailableDataObjects();
        }
        this.initializeAvailableAccessGroups();
    }

    initGrid() {
        const authorizationDataSource = authorizationTabGridDataSource.create(this.RelatedEntities, this.AuthorizationGroupCollection);
        const authorizationGrid = grid.create(authorizationDataSource,
            {
                tplRow: "tpl-administration/Medius.Core.Entities.Role/Authorization/row"
            }
        );

        this.grid(authorizationGrid);
    }

    addNewAuthorizationRow() {
       //if row with current data object name already exists don't create the new one
        const exists = _.any(this.RelatedEntities(), (item) => {
            return (item.DataObject().Name() === this.SelectedDataObjectTypeName());
        });

        if (exists) {
            error(getLabelTranslation("#Core/authorizationConfigurationExistsError"));
            return;
        }

        //create new RoleDataObjectAuthorization
        let response = getNew("Medius.Core.Entities.Security.RoleDataObjectAuthorization");
        response.DataObject = _.find(this.DataObjectCollection(), (item) => {
            return item.Name === this.SelectedDataObjectTypeName();
        });

        if (isNullOrUndefined(response.DataObject)) {
            error(getFormattedLabelTranslation("#Core/cantLoadDataObject_dataObjectName", [this.SelectedDataObjectTypeName()]));
            return;
        }

        response.Role = toJS(this.Entity);
        response.AccessControlGroup = this.AuthorizationGroupCollection()[0];
        response = this.Context.create(response);

        //add a row to view and related entities
        this.RelatedEntities.unshift(response);
        this.grid().refresh();
    }

    removeAuthorizationRow(row: any) {
        const index = this.RelatedEntities().indexOf(row.RelatedEntity);
        this.RelatedEntities().splice(index, 1);

        if (row.dispose) {
            row.dispose();
        }

        this.grid().refresh();
    }

    // gets data objects to fullfill listBox
    initializeAvailableDataObjects() {
        return load("Medius.Core.Entities.DataObject").done((data: any) => {
            this.DataObjectCollection(data);

            //takes only those data objects which are not used alredy
            const possibleDataObjectsNames = _.chain(data)
                .filter((item) => {
                    return !_.any(this.RelatedEntities(), (row) => {
                        return (row.DataObject().Name() === item.Name);
                    });
                }).map((item) => item.Name)
                .value();
            this.DataObjectProvider.fillResults(possibleDataObjectsNames);
        }).fail((xhr: any) => {
            handleAnyError(xhr);
        });
    }


    getAuthorizationGroupNames(data: any) {
        const relatedEntityType = data.RelatedEntity.DataObject().Name();

        if (!isSameClassOrSubclass(relatedEntityType, "Medius.Data.Document") && relatedEntityType != "Medius.Core.Entities.User") {
            return this.nonPersonalAuthorizationGroupNames;
        }
        return this.authorizationGroupNames;
    }

    // inits the list box with access group, it will be the same for each row
    initializeAvailableAccessGroups() {
        return load("Medius.Core.Entities.Security.AccessControlGroup").done((data: any) => {
            this.AuthorizationGroupCollection(data);
            this.initGrid();
        }).fail((xhr: any) => {
            handleAnyError(xhr);
        });
    }

    // invoked when cleaning Editor
    // should remove all references to external objects
    dispose() {
        this.authorizationGroupNames.dispose();
        this.Entity = null;
        this.Tab = null;
        this.DataObjectCollection = null;
        this.SelectedDataObjectTypeName = null;
        this.AuthorizationGroupCollection = null;
        this.IsRoleSaved = null;
    }
}


export default function create(entityWithContext: any, companyContextId: any, tabModel: any) {
    return new AuthorizationTab(entityWithContext, companyContextId, tabModel);
}