///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/validation/attributes"/>
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { formatValueWithPlaceholders } from "../../lib/stringFormat";

export const attributeRules: {
    "RequiredAttribute": () => {rule:RegExp, errorMessage:string}[],
    "StringLengthAttribute": (attributeData:any) => {rule:RegExp, errorMessage:string}[],
    "CreditCardAttribute": {rule:RegExp, errorMessage:string}[],
    "CuitAttribute": {rule:RegExp, errorMessage:string}[],
    "DateAttribute":{rule:RegExp, errorMessage:string}[],
    "DigitsAttribute": {rule:RegExp, errorMessage:string}[],
    "EmailAttribute": {rule:RegExp, errorMessage:string}[],
    "EqualToAttribute": {rule:RegExp, errorMessage:string}[],
    "FileExtensionsAttribute": {rule:RegExp, errorMessage:string}[],
    "IntegerAttribute": {rule:RegExp, errorMessage:string}[],
    "MaxAttribute": {rule:RegExp, errorMessage:string}[],
    "MinAttribute": {rule:RegExp, errorMessage:string}[],
    "NumericAttribute": {rule:RegExp, errorMessage:string}[],
    "UrlAttribute": {rule:RegExp, errorMessage:string}[],
    "RangeAttribute": {rule:RegExp, errorMessage:string}[],
    "RegularExpressionAttribute": {rule:RegExp, errorMessage:string}[],
    "YearAttribute": {rule:RegExp, errorMessage:string}[],

} = {
    "RequiredAttribute": function () {
        return [
            {
                rule: /[^\s]+/,
                errorMessage: getLabelTranslation("#Core/defaultValidation_REQUIREDATTRIBUTE")
            }
        ];
    },
    "StringLengthAttribute": function (attributeData) {
        const minLength = 1;
        const maxLength = attributeData.ConstructorArguments[0];
        return [
            {
                rule: new RegExp("^.{" + minLength + "," + maxLength + "}$"),
                errorMessage:
                    getLabelTranslation("#Core/editorForValidation_stringLengthNotValid",[minLength, maxLength])
            }
        ];
    },
    "CreditCardAttribute": [],
    "CuitAttribute": [
        {
            rule: new RegExp("'^[0-9]{2}-?[0-9]{8}-?[0-9]$"),
            errorMessage: "Examples of valid values {20245597151, 20-24559715-1}."
        }
    ],
    "DateAttribute": [],
    "DigitsAttribute": [
        {
            rule: /\d*/,
            errorMessage:
                getLabelTranslation("#Core/defaultValidation_DIGITSATTRIBUTE")
        }
    ],
    "EmailAttribute": [
        {
            rule: new RegExp("^\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\\.[A-Z]{2,4}\b$"),
            errorMessage:
                getLabelTranslation("#Core/defaultValidation_EMAILATTRIBUTE")
        }
    ],
    "EqualToAttribute": [],
    "FileExtensionsAttribute": [],
    "IntegerAttribute": [
        {
            rule: /[\-]?\d*/,
            errorMessage:
                getLabelTranslation("#Core/defaultValidation_INTEGERATTRIBUTE")
        }
    ],

    "MaxAttribute": [],
    "MinAttribute": [],
    "NumericAttribute": [],
    "UrlAttribute": [],
    "RangeAttribute": [],
    "RegularExpressionAttribute": [],
    "YearAttribute": [
        {
            rule: new RegExp("^[0-9]{4}$"),
            errorMessage:
                getLabelTranslation("#Core/defaultValidation_YEARATTRIBUTE")
        }
    ]
};