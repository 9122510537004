/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/DocumentCacheManagement/loadingPanel"/>
import * as React from "react";
import {createPortal} from "react-dom";
import {translate} from "Core/Medius.Core.Web/Scripts/lib/globalization";

export function LoadingPanel() {
    const loadingPanel = (
        <div className="k-loading-mask documentCacheManagement-loadingPanel">
            <span className="k-loading-text">{translate("#Core/loading")}</span>
            <div className="k-loading-image"></div>
            <div className="k-loading-color"></div>
        </div>
    );

    const gridContent = document && document.querySelector('.documentCacheManagement__documentsGrid');
    return gridContent ? createPortal(loadingPanel, gridContent) : loadingPanel;
}
