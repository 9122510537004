///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Entities.CompanyIdentifier/IdentifiersTab"/>
import * as sync from "Core/Medius.Core.Web/Scripts/Medius/core/sync";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as notification from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import * as ko from "knockout";
import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";

const externalSystemIdentifier = "ExternalSystemIdentifier";
const companyNameIdentifier = "CompanyNameIdentifier";
const companyIdIdentifier = "CompanyIdIdentifier";

class IdentifiersTab {
    entity: any;
    AvailableIdentifierTypes: ko.ObservableArray<any>;
    CompanyIdentifierType: ko.Observable<any>;
    CompanyIdentifier: ko.Observable<any>;
    constructor(entityWithContext:any) {
        this.entity = entityWithContext.Entity;

        this.AvailableIdentifierTypes = ko.observableArray([]);

        this.CompanyIdentifierType = ko.observable();
        
        this.CompanyIdentifier = ko.observable();
        
        sync.load("Medius.Core.Entities.IdentifierType")
            .done((list) => {
                list = list.filter((identifier:{Type:string}) => {
                    return !(identifier.Type === externalSystemIdentifier || identifier.Type === companyNameIdentifier || identifier.Type === companyIdIdentifier);
                });
                this.AvailableIdentifierTypes(list);
            });
    }

    removeRow(rowToRemove:any) {
        this.entity.CompanyIdentifiers.remove(rowToRemove);
    }

    isRowEditable(row:any) {
        return !(ko.unwrap(row.Type().Type) === externalSystemIdentifier || ko.unwrap(row.Type().Type) === companyNameIdentifier || ko.unwrap(row.Type().Type) === companyIdIdentifier);
    }

    addRow() {
        let alreadyAddedIdentifier = false;
        let errorMessage:string;
        if (isNullOrUndefined(this.CompanyIdentifier())) {
            errorMessage = globalization.getLabelTranslation("#Core/notifyCompanyIdentifierMustBeFilled");
            notification.error(errorMessage);
            return;
        }

        this.entity.CompanyIdentifiers().forEach((id: {Identifier:string, Type:() => {Type:ko.Observable<string>} }) => {
            if (ko.unwrap(id.Identifier) === this.CompanyIdentifier() && ko.unwrap(id.Type().Type) === this.CompanyIdentifierType().Type)
            {
                alreadyAddedIdentifier = true;
            }
        });
        
        if (alreadyAddedIdentifier) {
            errorMessage = globalization.getLabelTranslation("#Core/notifySelectedCompanyIdentifierAlreadyAdded");
            notification.error(errorMessage);
            return;
        }

        const newIdentifier = sync.getNew("Medius.Core.Entities.CompanyIdentifier");

        newIdentifier.Type = ko.observable(this.CompanyIdentifierType());
        newIdentifier.Identifier = ko.observable(this.CompanyIdentifier());

        this.entity.CompanyIdentifiers.push(newIdentifier);
        
        this.CompanyIdentifier(null);
    }
    dispose() {
        this.CompanyIdentifierType(null);
        this.CompanyIdentifier(null);
    }
}

export = function (entityWithContext:any) {
    return new IdentifiersTab(entityWithContext);
};