/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/inbox/taskGroup/grid" />

import { Grid } from "Core/Medius.Core.Web/Scripts/Medius/components/grid/default/grid";
import * as config from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/taskGroup/gridConfig";
import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import * as _ from "underscore";
import * as taskGroupDataSource from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/taskGroup/dataSource";
import { sortByColumn } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/taskGroup/gridOperations";
import * as ko from "knockout";
import { ShowMoreButtonGroupTemplate } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/taskGroup/showMoreButtonGroupTemplate";

import * as helpers from "Core/Medius.Core.Web/Scripts/Medius/components/editors/helpers";
import { savePageSize } from "Core/Medius.Core.Web/Scripts/Medius/components/grid/ui/userConfigRepository";
import { TaskGroup } from "./group";

export class TaskGrid extends Grid {
    public isShowMoreVisible: ko.Computed<boolean>;
    public IsLabelColumnVisible: ko.Computed<boolean>;
    public IsIndicatorsColumnVisible: ko.Computed<boolean>;
    public IsCodingSuggestionInvoiceConfidenceColumnVisible: ko.Computed<boolean>;
    public FirstNonCustomColumnIndex: ko.Computed<number>;
    public taskGroup: any;

    public ShowMoreButtonGroup = ko.pureComputed(() => {
        return ({
            functionComponent: ShowMoreButtonGroupTemplate,
            props: {
                loadMoreTasksAction: (tasksAmount: number) => { this.loadMoreTasks(tasksAmount); },
                resetPageSizeAction: () => { this.resetPageSize(); }
            }
        });
    });

    constructor(taskGroup: TaskGroup, options: any) {
        const configOptions = config.get();
        if (!isNullOrUndefined(options)) {
            helpers.mergeDefaults(options, configOptions);
        }
        const dataSource = taskGroupDataSource.create(taskGroup);

        super(dataSource, configOptions, false);

        this.taskGroup = taskGroup;
        this.TotalLoadedRows.dispose();
        this.TotalLoadedRows = ko.computed(() => {
            return taskGroup.visibleTasks().length;
        });

        this.isShowMoreVisible = ko.computed(() => {
            return true;
        });

        if (!this.options.showBulkCheckboxes) {
            this.AllColumns.dispose();
            this.AllColumns = ko.computed(() => {
                return this.Columns();
            });
        }

        this.IsLabelColumnVisible = ko.computed(() => {
            return _(this.VisibleColumns()).find((column: any) => {
                return column.ValueSource === "Labels";
            });
        });

        this.IsIndicatorsColumnVisible = ko.computed(() => {
            return _(this.VisibleColumns()).find((column: any) => {
                return column.ValueSource === "Indicators";
            });
        });

        this.IsCodingSuggestionInvoiceConfidenceColumnVisible = ko.computed(() => {
            return _(this.VisibleColumns()).find((column: any) => {
                return column.ValueSource === "Document.CodingSuggestionConfidence";
            });
        });

        /* First columns are custom and have their own html markup in RowsTpl.cshtml file:
            - View (show full screen mode, always visible - that's why offset is at least 1)
            - Labels
            - Indicators
            - CodingSuggestion
        */
        this.FirstNonCustomColumnIndex = ko.computed(() => {
            let index = 1;
            if (this.IsLabelColumnVisible()) {
                index++;
            }
            if (this.IsIndicatorsColumnVisible()) {
                index++;
            }
            if (this.IsCodingSuggestionInvoiceConfidenceColumnVisible()) {
                index++;
            }
            return index;
        });
    }

    destroy() {
        _(this).each((value: any, key: any) => {
            if (Object.prototype.hasOwnProperty.call(this, key)) {
                if (value && ko.isObservable(value) && _.isFunction(value.prototype.dispose)) {
                    value.prototype.dispose();
                }
            }
        });
        this.DataSource.destroy();
    }

    loadMoreTasks(limit: any) {
        this.DataQuery.pageSize += parseInt(limit, 10);
        savePageSize(this.options.pageSizeStore, this, this.DataQuery.pageSize);
        this.refresh();
    }

    resetPageSize() {
        this.DataQuery.pageSize = 20;
        savePageSize(this.options.pageSizeStore, this, 20);
        this.refresh();
    }

    sortByColumn(column: any, order: any) {
        return sortByColumn(this, column, order);
    }
}

export function create(taskGroup: TaskGroup, options: any) {
    return new TaskGrid(taskGroup, options);
}
