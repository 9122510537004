/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/default/text/entity/user"/>
import {unpack} from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as typeBoundResolverFactory from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/typeBound";

export const resolver = typeBoundResolverFactory.create({
    priority: 0,
    type: "Medius.Core.Entities.User",
    resolve: function (data: any) {
        const lastName = unpack(data.LastName);
        let description = lastName ? `${unpack(data.FirstName)} ${lastName}` : `${unpack(data.FirstName)}`;
        const userName = unpack(data.UserName);

        if (userName) {
            // attach username only if it is present
            description = `${description} (${userName})`;
        }

        return {
            text: description
        };
    }
});
