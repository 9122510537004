///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/administration/bulk/steps/result"/>
import * as _ from "underscore";
import { WizardStep } from "Core/Medius.Core.Web/Scripts/Medius/components/wizard/steps/wizardStep";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as rest from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rest";
import * as backendErrorHandler from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import * as ko from "knockout";
import {isNullOrUndefined} from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import * as $ from "jquery";
import { ModificationsMapping, create  as createModificationsMapping} from "Core/Medius.Core.Web/Scripts/Medius/models/bulk/modificationsMapping";

class BulkAdminResultEntityWizardStep extends WizardStep<any> {
    result = ko.observable(null);
    modificationsNames = ko.observableArray();
    navigation:any = null;
    
    constructor(){
        super(globalization.getLabelTranslation("#Core/result"), "Core:templates/Administration/Bulk/Result.html");
    }

    navigate(parent: { close: { apply: (arg0: any) => void; }; }) {
        if (this.navigation !== null) {
            parent.close.apply(parent);
            this.navigation.call();
        }
    }

    onOpen(editWizard: any) {
        $.when(createModificationsMapping().prepareHandlerMapping())
            .done((mapping: ModificationsMapping) => {
                const entityType = editWizard.DefaultViewModel.EntityType,
                    modificationsContainer = editWizard.DefaultViewModel.ModificationsContainer;

                this.navigation = editWizard.DefaultViewModel.NavigationMethod;

                this.modificationsNames(_.map(modificationsContainer, (modification) => {
                    return isNullOrUndefined(modification.Name) ? modification.Key : modification.Name;
                }));

                rest.put("bulkAdministration", "", {
                        EntityType: entityType,
                        AffectedEntities: editWizard.DefaultViewModel.EntitiesIds,
                        Modifications: mapping.injectMapping(entityType, modificationsContainer),
                        EntityFilter: editWizard.DefaultViewModel.EntityFilter,
                        CompanyId: editWizard.DefaultViewModel.CompanyId
                }, null)
                    .then(() => {},
                    (error) => {
                        backendErrorHandler.handleAnyError(error);
                    });
            });
    }

    dispose() {
    }
}

export function create() {
    return new BulkAdminResultEntityWizardStep();
}