/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/administration/extensions/registry/clientApplication" />

import mapping = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/mapping/collection/instance");
import viewModel from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Entities.Api.ClientApplication/ClientApplicationViewModel";

function typeCondition(context: any) {
    return true;
}

export function registerClientApplicationMapping() {
    mapping.register(viewModel().type, typeCondition, viewModel());
}
