/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/inbox/helpers"/>
import * as _ from "underscore";

export function findCurrentTask(array: any[]) {
    return _(array).find((task: any) => task.isCurrent());
}

export function getTasksIds(array: any[]) {
    return array.map(task => task.id);
}

export function resolveSortingOrder(dataQuery: any) {
    const columnKeys = _(dataQuery.sorting).keys();
    let key = _(columnKeys).first();
    const order = dataQuery.sorting[key] || "";

    if (key) {
        key = key.split("_").join(".");
    }

    return { order, key };
}

export function mapPersistedOrderCriteria({ DocumentType, Path, Type }: any) {
    return {
        DocumentType, Path, Type
    };
}

export function removeOrderCriteria(searchCriteria: any) {
    return {
        ...searchCriteria,
        OrderCriteria: null
    };
}

export function shallowCopySearchCriteria(searchCriteria: any) {
    return {
        ...searchCriteria
    };
}

export function isActivityAvailableForHasRejectedLines(activity: string) {
    return activity === "ExpenseManualPostControl" || activity === "ManualPostControl";
}

export function isActivityAvailableForHasRejectedLinesWithShortPayment(activity: string) {
    return activity === "ManualPostControl";
}

export function scrollToSelectedGridRow() {        
    const inboxScroll = document.getElementById("inbox-scroll");
    
    if (!inboxScroll)
        return;

    const selectedTaskRow = inboxScroll.querySelector(".grid-row.selected");

    if (!selectedTaskRow)
        return;

    selectedTaskRow.scrollIntoView(false);

    if (inboxScroll.scrollTop > 0)
        inboxScroll.scrollBy(0, 200);
}