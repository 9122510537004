/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/inbox/bulk/actions/workflow"/>
const globalization = require("Core/Medius.Core.Web/Scripts/lib/globalization");

import { Action } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/bulk/actions/action";
import * as ko from "knockout";

export class Workflow extends Action {
    constructor(tasks: any) {
        super(tasks);
        this.id = "workflow";
        this.label = globalization.getLabelTranslation("#Core/bulkWorkflowAction");
        this.taskDescription = this.taskDescription;

        this.isAvailable = ko.observable(!!this.activity);
    }
}
