///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/etGoLive/model"/>
import * as rpc from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rpc";
import * as backendErrorHandler from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import * as notification from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as ko from "knockout";
import * as _ from "underscore";

function showSuccessMessage (translationKey:string) {
    notification.success(
        globalization.getLabelTranslation(
            translationKey));
}

class GoLiveModel {
    canStartGoLive = ko.observable();
    startDisabledMessage = ko.observable("");
    reindexingCompanies = ko.observableArray([]);
    checkProgressInterval:ko.Observable<any> = ko.observable();
    asyncTaskId = ko.observable();

    isWorking = ko.observable(false);
    progressPercentage:ko.Observable<string> = ko.observable("0");

    setStartDisabledMessage = (translationKey:string) => {
        this.startDisabledMessage(
            globalization.getLabelTranslation(
                translationKey));
    };

    setInProgressMessage = (progress:number) => {
        this.startDisabledMessage(
            globalization.getLabelTranslation(
                "#Core/goLiveInProgressMessage"));

        this.progressPercentage(progress + "%");
    };

    showMessage = ko.computed(() => {
        return this.startDisabledMessage().length > 0
            || this.isWorking();
    });

    checkProgress = () => {
        const request = rpc.lightApi("EtGoLiveService", "GetTaskStatus", { asyncTaskId: this.asyncTaskId })
            .done((value) => {
                switch (value.Status) {
                    case "HasRun":
                        this.isWorking(false);
                        this.setStartDisabledMessage("#Core/goLiveHasRunMessage");
                        clearInterval(this.checkProgressInterval());
                        break;

                    case "InProgress":
                        this.setInProgressMessage(value.Progress);
                        break;

                    case "Faulted":
                        this.isWorking(false);
                        this.setStartDisabledMessage("#Core/goLiveFaultedMessage");
                        clearInterval(this.checkProgressInterval());
                }
            });
        return request;
    };

    fetchIsEnabledSetting = () => {
        const request = rpc.lightApi("EtGoLiveService", "GetState")
            .fail(backendErrorHandler.handleAnyError)
            .done((value) => {
                switch (value.Status) {
                    case "Enabled":
                        this.isWorking(false);
                        this.canStartGoLive(true);
                        break;

                    case "Disabled":
                        this.isWorking(false);
                        this.canStartGoLive(false);
                        this.setStartDisabledMessage("#Core/goLiveDisabledMessage");
                        break;

                    case "HasRun":
                        this.isWorking(false);
                        this.canStartGoLive(false);
                        this.setStartDisabledMessage("#Core/goLiveHasRunMessage");
                        break;

                    case "InProgress":
                        this.isWorking(true);
                        this.canStartGoLive(false);
                        this.setInProgressMessage(value.Progress);
                        this.asyncTaskId(value.AsyncTaskId);
                        this.checkProgressInterval(setInterval(this.checkProgress, 1000));
                }
            });
        return request;
    };

    fetchReindexingCompanies = () => {
        rpc.lightApi("EtGoLiveService", "LoadCompanies")
            .fail(backendErrorHandler.handleAnyError)
            .done((value) => {
                this.reindexingCompanies(value);
            });
    };

    runGoLive = () => {
        const companiesForReindexing = _.filter(this.reindexingCompanies(), (item) => {
            return (item.NewCompanyId && 0 !== item.NewCompanyId.length);
        });

        return rpc.lightApi("EtGoLiveService", "Start", {
                reindexing: companiesForReindexing
            })
            .fail(backendErrorHandler.handleAnyError)
            .done((value) => {
                this.isWorking(true);
                this.canStartGoLive(false);
                showSuccessMessage("#Core/GoLivePerformedOk");
                showSuccessMessage("#Core/GoLiveStarted");
                this.asyncTaskId(value);
                this.setInProgressMessage(0);
                this.checkProgressInterval = ko.observable(setInterval(this.checkProgress, 2000));
            });
    };

    constructor() {
        this.fetchIsEnabledSetting();
        this.fetchReindexingCompanies();
    }
}

export = function() {
    return new GoLiveModel();
};