/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/attachments/attachments" />

import { observable, observableArray, pureComputed } from "knockout";
import * as _ from "underscore";
import * as $ from "jquery";
import { FileAttachment, isFileSupported } from "Core/Medius.Core.Web/Scripts/Medius/core/attachments/fileAttachment";
import { getTypeName } from "Core/Medius.Core.Web/Scripts/Medius/core/type";
import { addAttachmentRequest, getAttachments, deleteAttachment, getAttachmentMaxSize, showMaxFileSizeExceededError, deleteAttachmentAddedByOther } from "Core/Medius.Core.Web/Scripts/Medius/core/attachments/shared/attachmentsShared";

export class Attachments {
    public tabCounter: ko.Computed<number>;
    public uploadImmediately: boolean;
    public MainAttachmentMode: boolean;
    public IsReadOnly: boolean;
    public MaxFileSize: number | null = null;
    public FileDescription = observable<string>(null);
    public IsLoading = observable(false);
    public CarriedOverAttachments = observableArray<FileAttachment>([]);
    public Attachments = observableArray<FileAttachment>([]);
    public settings: any;
    public deleteFileAddedByOthers: (attachment: FileAttachment) => void;
    public fileInput: any;
    private target: HTMLElement;
    private options: any;

    constructor(target: HTMLElement, options: any) {
        this.settings = _.extend({
            entityViewId: null,
            entityType: null,
            tabId: null,
            formTarget: null,
            input: null
        }, options);

        this.target = target;
        this.options = options;
        this.getFileInputAndFormTargetElements();

        this.IsReadOnly = options.readOnly;
        this.MainAttachmentMode = options.mainAttachmentMode;
        this.uploadImmediately = options.uploadImmediately;

        this.tabCounter = pureComputed(() => this.Attachments().length + this.CarriedOverAttachments().length);

        this.getAttachmentMaxSize();
        this.loadAttachments();

        this.deleteFileAddedByOthers = (attachment: FileAttachment) => {
            this.IsLoading(true);
            deleteAttachmentAddedByOther(attachment.Hash)
                .then(() => this.Attachments.remove(attachment))
                .finally(() => this.IsLoading(false));
        };
    }

    public getFileInputAndFormTargetElements = () => {
        this.settings.formTarget = $(this.target).find("#fileupload");          
        this.settings.input = $(this.target).find("input[type=file]");

        this.fileInput = this.settings.input[0];

        if(this.options.uploadImmediately){
            this.settings.formTarget["0"].addEventListener("dragover", (e: any) => {
                e.preventDefault();
            });
    
            this.settings.formTarget["0"].addEventListener("drop", (e: any) => {
                e.preventDefault();
                this.addAttachment(e.dataTransfer.files[0]);
            });
            
            this.fileInput.addEventListener("change", () => this.addAttachment(this));
        }
    }

    public addAttachment(incomingData?: any){
        let file: File = null;
        if(incomingData?.fileInput){
            file = this.fileInput.files[0];
        }
        else{
            file = incomingData;
        }

        if(!file) return;

        if(!(this.validateFileSize({ files: [file] }) && isFileSupported({ files: [file] }))) return;       

        this.IsLoading(true);
        addAttachmentRequest(this.FileDescription() || "", 
            this.settings.entityViewId,
            getTypeName(this.settings.entityType),
            this.MainAttachmentMode,
            file).then(attachments => {
                this.Attachments.unshift(...attachments.map(attachment => new FileAttachment(attachment)));
                this.FileDescription(null);
                this.settings.formTarget.get(0).reset();
            })
            .finally(() => {
                this.IsLoading(false);
            });
    }

    public loadAttachments() {
        this.IsLoading(true);
        getAttachments({
            entityViewId: this.settings.entityViewId,
            entityType: getTypeName(this.settings.entityType)}, this.MainAttachmentMode)
            .then(result => {
                this.Attachments(result.attachments);
                this.CarriedOverAttachments(result.carriedOverAttachments);
            })
            .finally(() =>{
                this.IsLoading(false);
            });
    }

    public deleteFile(attachment: FileAttachment) {
        this.IsLoading(true);
        deleteAttachment(attachment.Hash)
            .then(() => {
                this.Attachments.remove(attachment);
                this.CarriedOverAttachments.remove(attachment);
            }).
            finally(() => this.IsLoading(false));
    }

    public getAttachmentMaxSize() {
        this.IsLoading(true);
        getAttachmentMaxSize()
            .then(maxSize => this.MaxFileSize = maxSize)
            .finally(() => this.IsLoading(false));
    }

    public validateFileSize(data: any) {
        if (this.MaxFileSize && data.files && data.files[0].size > this.MaxFileSize) {
            showMaxFileSizeExceededError(this.MaxFileSize);
            return false;
        }
        return true;
    }
    
    public destroy(attachment: FileAttachment) {
        this.Attachments.remove(attachment);
    }
}