/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/LoginOption/grid"/>
import { registerGrid } from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/leftSideGridRegistry";
import { pageRows, sortRows} from "Core/Medius.Core.Web/Scripts/Medius/components/grid/dataSource/dataSourceOperations";
import { GridDataSource } from "Core/Medius.Core.Web/Scripts/Medius/components/grid/dataSource/IDataSource";
import { Deferred } from "jquery";
import { get } from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rest";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as grid from "Core/Medius.Core.Web/Scripts/Medius/components/grid/default/grid";

const entityName = "Medius.Core.Entities.Security.IdentityProvider";

export function registerIdentityProviderGrid() {
    registerGrid(entityName, (entityId: ko.Computed<any>) => { 
        const options = {
            editPageSize: true,
            onClickRow: (selectedRow: any) => { entityId(selectedRow.Id); }
        };
        return grid.create(new IdentityProviderGridDataSource(), options); 
    });
}


interface GridDataDto {
    rows: any[],
    count: number,
}

class IdentityProviderGridDataSource implements GridDataSource {
    public columns: any[];
    public load: (dataQuery: any) => JQueryPromise<any>;
    public sort: <T>(dataQuery: any, rows: T[]) => T[];
    public page: <T>(dataQuery: any, rows: T[]) => T[];
    public totalRows: JQueryDeferred<number | null> = Deferred();

    constructor() {
        this.columns = [
            {
                Key: "IdentityProviderName",
                Name: translate("#Core/identityProviderNameColumnName"),
                Searchable: true,
                Sortable: true,
                Resizable: true,
                Stretch: true,
                Width: 100,
                ValueSource: "identityProviderName"
            }
        ];

        this.load = (dataQuery: any) => {
            const messages = Deferred();
            const sortingProperty = Object.keys(dataQuery.sorting).shift();
            this.totalRows = Deferred();
            get("administration/login-options", "", {
                ...dataQuery.keywords,
                page: dataQuery.page,
                pageSize: dataQuery.pageSize,
                orderBy: sortingProperty,
                orderByDirection: !sortingProperty ? null : dataQuery.sorting[sortingProperty]
            }).then((gridData: GridDataDto) => {
                this.totalRows.resolve(gridData.count);
                //needed for handling grid mechanism which relies on Id
                //starting with capital letter
                const rowsWithCapitalizedId = gridData.rows.map(r => {
                    return {
                        ...r,
                        Id: r.id,
                    };
                });
                messages.resolve(rowsWithCapitalizedId);
            });
            return messages.promise(); 
        };

        this.sort = sortRows;
        this.page = pageRows;
    }

    getTotalRows() {
        return this.totalRows.promise();
    }

    loadColumns() {
        const columnsDeffered = Deferred();
        setTimeout(() => {
            columnsDeffered.resolve(this.columns);
        }, 0);
        return columnsDeffered.promise();
    }

    exportData() {}

    asyncExportData() {}

    getSortValue(row: any, sortingProperty: string) {
        return row[sortingProperty];
    }

    destroy() {}
}