///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/grid/editable/grid"/>
import * as ko from "knockout";
import * as _ from "underscore";
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import { Grid } from "Core/Medius.Core.Web/Scripts/Medius/components/grid/default/grid";
import * as objectBackup from "Core/Medius.Core.Web/Scripts/Medius/lib/entityBackup/backup";

export class EditableGrid extends Grid {
    isAnyRowInEdit = ko.observable(false);
    constructor(dataSource: any, options: any) {
        options = {
            ...options,
            template: "grid-editable-tpl"
        };

        super(dataSource, options);
    }

    addEmptyRow() {

        if (this.isAnyRowInEdit()) {
            return;
        }

        const row = this.DataSource.getNewRow();
        row.isEditable = ko.observable(true);
        row.justAdded = true;

        if (_.isFunction(this.DataSource.addRow)) {
            this.DataSource.addRow(row);
        }

        super.addRow(row);
        this.SelectedRow(null);

        this.turnOnEditMode(row);
    }

    turnOnEditMode(row: any) {
        row.isEditable(true);
        this.isAnyRowInEdit(true);
    }

    turnOffEditMode(row: any) {
        row.isEditable(false);
        this.isAnyRowInEdit(false);
    }

    cancelRow(row: any) {
        if (koUtils.unpack(row.justAdded)) {
            this.removeRow(row);
        } else {
            row.backup.revert();
            row.backup = null;
        }

        this.turnOffEditMode(row);
    }

    editRow(row: any, backupModule: any) {
        if (this.isAnyRowInEdit()) {
            return;
        }

        if (_.isUndefined(backupModule)) {
            backupModule = objectBackup;
        }

        row.backup = backupModule.create(row);

        this.turnOnEditMode(row);
    }
    
    saveRow(row:any): any {
        if (_.isFunction(this.DataSource.validateRow)) {
            const validationResult = this.DataSource.validateRow(row);
            if (validationResult === false) {
                return false;
            }
        }

        if (_.isFunction(this.DataSource.saveRow)) {
            this.DataSource.saveRow(row);
        }

        row.justAdded = false;
        this.turnOffEditMode(row);
    }

    removeRow(row: any) {
        if (_.isFunction(this.DataSource.removeRow)) {
            this.DataSource.removeRow(row);
        }
        super.removeRow(row);
    }
}

export function create(dataSource: any, options: any) {
    return new EditableGrid(dataSource, options);
}