///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/typeahead"/>
import * as _ from "underscore";
import * as $ from "jquery";
import * as lexemeHelpers from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/lexeme/helpers";
import * as lexemeParser from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/parser/lexeme";
import { isEmptyString } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";

export class QueryTypeahead {
    hints: any[];
    lexemes: string[];
    stateMachine: any;
    items: number;
    source: (query: any, process: any) => void;
    matcher: (value:string) => boolean;
    highlighter: (item: any) => any;
    updater: (item: any) => any;
    query:string;
    constructor(stateMachine:any) {
        this.hints = [];
        this.lexemes = [];
        this.stateMachine = stateMachine;

        this.items = 20;
        
        this.source = (query, process) => {
            updateLexemes(query);
            updateHints(query).done((hints:any) => {
                process(hints);
            });
        };

        this.matcher = () => {
            return true;
        };

        this.highlighter = (item) => {
            const last = lexemeHelpers.removeQuotes(_.last(this.lexemes));
            return this.getHighlightedItem(item, last);
        };

        const getFromStyledHintAccessor = (item:any) => {
            return this.getFromStyledHint(item);
        };

        this.updater = function (item) {
            const query = this.query;

            item = getFromStyledHintAccessor(item);

            if (item.indexOf(query) !== -1) {
                return item;
            }
            
            const chars = query.split('').reverse();
            const charsTotal = chars.length;
            let done = false;
            let i = 0;
            
            if (item === ")") {
                done = true;
            }
            
            if (!done) {
                //if the matched item contains dot or space, find length of overlapping string
                if (item.indexOf('.') !== -1 || item.indexOf(' ') !== -1) {
                    i = getLengthOverlappingString(item.toLowerCase(), query[query.length - 1], query.toLowerCase());
                }
                if (i === 0) {
                    while (!done && i < charsTotal) {
                        if (chars[i] === "." || chars[i] === " ") {
                            done = true;
                        } else {
                            i += 1;
                        }
                    }
                }
                    
            }


            item = lexemeHelpers.wrapWithQuotes(item);
            
            return query.substr(0, query.length - i) + item;
        };

        function getLengthOverlappingString(item:string, lastWrittenChar:string, query:string): number {
            //start seach from the last letter in the item that matches the last letter written in query
            const lastIndexOfLastWrittenChar = item.lastIndexOf(lastWrittenChar);
            if (lastIndexOfLastWrittenChar !== -1) {
                //change item so it can match what's been written
                const shortenedItem = item.substring(0, lastIndexOfLastWrittenChar + 1);
                const foundAtIndex = query.lastIndexOf(shortenedItem);
                //if partial item was found at end of query, return length to 
                if (foundAtIndex + shortenedItem.length === query.length) {
                    if (query[foundAtIndex - 1] === '\'') {
                        return shortenedItem.length + 1;
                    } else {
                        return shortenedItem.length;
                    }
                } else {
                    return getLengthOverlappingString(shortenedItem.substring(0, shortenedItem.length - 1), lastWrittenChar, query);
                }
            } else {
                return 0;
            }
        }


        const updateLexemes = (query:string) => {
            this.lexemes = (isEmptyString(query)) ? [] : lexemeParser.process(query);
        };

        const updateHints = (query:string) => {
            const hints = this.stateMachine.getHints(this.lexemes, lexemeHelpers.endsWithWhitespace(query));
            
            if (_.isArray(hints)) {
                return $.Deferred().resolve(hints);
            }
            
            return hints;
        };
    }

    getHighlightedItem(item:string, lexeme:string) {
        if (isEmptyString(item)) {
            return "";
        }
        
        if (lexeme === ")" || lexeme === "(") {
            return item;
        }

        const styledRegex = />([a-zA-Z0-9]*)</,
            replaceRegex = new RegExp('(' + lexeme + ')', 'gi');

        if (styledRegex.test(item)) {
            let inTags = item.match(styledRegex)[1];
            inTags = inTags.replace(replaceRegex, "<strong>$1</strong>");

            return item.replace(styledRegex, ">" + inTags + "<");
        }
        return item.replace(replaceRegex, "<strong>$1</strong>");
    }
    
    isStyledHint(hint:string) {
        const styledHintRegex = />([a-zA-Z0-9]*)</;
        return styledHintRegex.test(hint);
    }

    getFromStyledHint(hint:string) {
        if (!this.isStyledHint(hint)) {
            return hint;
        }

        const styledRegex = />([a-zA-Z0-9]*)</,
            nestedPropertyWithoutTranslationRegex = /([a-zA-Z0-9'\. ]*)\.</,
            nestedPropertyWithTranslationRegex = /([a-zA-Z0-9'\. ]*)\.[a-zA-Z0-9' ]*</;

        let prefix = '';
    
        if (nestedPropertyWithoutTranslationRegex.test(hint)) {
            prefix = hint.match(nestedPropertyWithoutTranslationRegex)[1] + '.';
        } else if (nestedPropertyWithTranslationRegex.test(hint)) {
            prefix = hint.match(nestedPropertyWithTranslationRegex)[1] + '.';
        }
    
        return prefix + hint.match(styledRegex)[1];
    }
}

export function create (stateMachine:any) {
    return new QueryTypeahead(stateMachine);
}