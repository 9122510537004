///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/context/queue"/>
import * as queueFactory from "Core/Medius.Core.Web/Scripts/Medius/lib/task/queue";

const CONTEXT_QUEUE_KEY = "viewmodel-context-queue";

export = function queueDecorator(context:any) {
    context.queue = context.ensure(CONTEXT_QUEUE_KEY, queueFactory.create());
    context.enqueue = function(...args:any[]) {
        return context.queue.enqueue.apply(this, args);
    };

    context.disposal.register(function() {
        context.queue.dispose();
        context.queue = null;
    });
};