///<amd-module name="Core/Medius.Core.Web/Scripts/Paging/PageableCollection"/>
import { PagingHelpers } from "Core/Medius.Core.Web/Scripts/Paging/PagingHelpers";
import { PageIndex } from "Core/Medius.Core.Web/Scripts/Paging/PageIndex";
import * as _ from "underscore";
import * as ko from "knockout";

const NEIGHBOUR_PAGES_IN_MENU = 2;
export = class PageableCollection{
    
    public Options: any;
    public Paging: PagingHelpers;
    public focusChannel: ko.Subscribable<any>;
    public ItemsCount: ko.Observable<number>;
    public UnfilteredTotalCount: any;
    public AnyLinesAreFiltered: ko.Computed<boolean>;
    public MaxPage: ko.Observable<number>;
    public PreviousItemsCount: number;
    public AvailablePageSizes: Array<number>;
    public PageSize: number;
    public ActualPageNumber: ko.Observable<number>;
    public PageItemStartIndex: ko.Observable<number>;
    public PageItemLastIndex: ko.Observable<number>;
    public SummaryTemplateName: string;
    public PageItemsCollection: ko.ObservableArray<any>;
    public UpdatePagingItems: () => void;
    public DisableNavigationOnce: boolean;
    public updateFieldsAfterCollectionChange: () => void;
    public updateFieldsAfterPageChange: () => void;
    public collectionSub: any;
    public ActualPageNumberSub: any;
    public FirstPageDisplayedInMenu: ko.Computed<number>;
    public LastPageDisplayedInMenu: ko.Computed<number>;
    public PagesArray: ko.Computed<Array<any>>;
    public NavigateFirstPage: () => void;
    public NavigateLastPage: () => void;
    public NavigatePage:(page:any) => void;
    public NavigatePageWithNumber: (pageNumber:number) => void;
    public NavigatePreviousPage: () => void;
    public NavigateNextPage: () => void;
    public PreviousPageAvailable: ko.Computed<boolean>;
    public NextPageAvailable: ko.Computed<boolean>;
    public filteringIsEnabled:boolean;

    public constructor(collection: any, options?:any) {
        this.Options = _.extend({
            readonly: false,
            summaryTemplateName: 'pagination-default-summary-template'
        }, options || {});

        this.Paging = new PagingHelpers();
        
        this.focusChannel = new ko.subscribable();

        this.ItemsCount = ko.observable(0);

        this.UnfilteredTotalCount = this.Options.unfilteredTotalCount;

        this.filteringIsEnabled = this.Options.unfilteredTotalCount !== undefined;

        this.AnyLinesAreFiltered = ko.computed(() => {
            return this.filteringIsEnabled && this.UnfilteredTotalCount() !== this.ItemsCount();
        });

        this.MaxPage = ko.observable(0);

        this.PreviousItemsCount = this.ItemsCount();

        this.AvailablePageSizes = [5, 10, 25, 50, 100];

        this.PageSize = this.AvailablePageSizes[1];
        
        this.ActualPageNumber = ko.observable(this.MaxPage());

        this.PageItemStartIndex = ko.observable(0);

        this.PageItemLastIndex = ko.observable(0);

        this.SummaryTemplateName = this.Options.summaryTemplateName;

        this.PageItemsCollection = ko.observableArray();

        this.UpdatePagingItems = () =>{
            const newPageItemStartIndex = this.Paging.GetPageItemStartIndex(this.ActualPageNumber(), this.PageSize, this.ItemsCount());
            this.PageItemStartIndex(newPageItemStartIndex);

            const newPageItemLastIndex = this.Paging.GetPageItemLastIndex(newPageItemStartIndex, this.PageSize, this.ItemsCount());
            this.PageItemLastIndex(newPageItemLastIndex);

            const newPageItemsCollection = this.Paging.GetPageItemsCollection(collection, newPageItemStartIndex, newPageItemLastIndex);
            this.PageItemsCollection(newPageItemsCollection);
        };

        const newLineWasAdded = () => {
            return this.ItemsCount() === (this.PreviousItemsCount + 1);
        };

        this.updateFieldsAfterCollectionChange = () => {
            const newItemsCount = ko.utils.unwrapObservable(collection).length;
            this.ItemsCount(newItemsCount);

            const newMaxPage = Math.max(1, Math.ceil(this.ItemsCount() / this.PageSize));
            this.MaxPage(newMaxPage);

            if(!this.DisableNavigationOnce && !this.Options.readonly && newLineWasAdded()){
                this.NavigateLastPage();
            } else {
                this.ActualPageNumber(Math.max(1, Math.min(this.ActualPageNumber(), newMaxPage)));
            }
            this.UpdatePagingItems();
            this.PreviousItemsCount = this.ItemsCount();
            this.DisableNavigationOnce = false;
        };

        this.updateFieldsAfterPageChange = () => {
            this.UpdatePagingItems();
        };

        this.collectionSub = collection.subscribe(this.updateFieldsAfterCollectionChange);
        this.ActualPageNumberSub = this.ActualPageNumber.subscribe(this.updateFieldsAfterPageChange);
        
        this.FirstPageDisplayedInMenu = ko.computed(() => {
            const numberOfPagesVisibleBeforeActualPage = NEIGHBOUR_PAGES_IN_MENU;
            const numberOfPagesAfterActualPage = this.MaxPage() - this.ActualPageNumber();
            const additionalPagesVisibleBeforeActualPage = Math.max(0, NEIGHBOUR_PAGES_IN_MENU - numberOfPagesAfterActualPage);
            const menuFirstIndex = Math.max(1, this.ActualPageNumber() - numberOfPagesVisibleBeforeActualPage - additionalPagesVisibleBeforeActualPage);
            return menuFirstIndex;
        }, this);

        this.LastPageDisplayedInMenu = ko.computed(() => {
            const numberOfPagesVisibleAfterActualPage = NEIGHBOUR_PAGES_IN_MENU;
            const numberOfPagesBeforeActualPage = this.ActualPageNumber() - 1;
            const additionalPagesVisibleAfterActualPage = Math.max(0, NEIGHBOUR_PAGES_IN_MENU - numberOfPagesBeforeActualPage);
            const menuLastIndex = Math.min(this.MaxPage(), this.ActualPageNumber() + numberOfPagesVisibleAfterActualPage + additionalPagesVisibleAfterActualPage);
            return menuLastIndex;
        }, this);

        this.PagesArray = ko.computed(() => {
            const pagesArray = [];
            for(let i = this.FirstPageDisplayedInMenu(); i <= this.LastPageDisplayedInMenu(); i++) {
                pagesArray.push(new PageIndex(i, this.ActualPageNumber));
            }
            return pagesArray;
        }, this);

        this.NavigateFirstPage = () => {
            if(this.ActualPageNumber() !== 1) {
                this.ActualPageNumber(1);
            }
        };

        this.NavigateLastPage = () => {
            if(this.ActualPageNumber() !== this.MaxPage()) {
                this.ActualPageNumber(this.MaxPage());
            }
        };

        this.NavigatePage = (page) => {
            this.ActualPageNumber(page.Label);
            this.focusChannel.notifySubscribers({}, "focusFirstLine");
        };

        this.NavigatePageWithNumber = (pageNumber) => {
            if(pageNumber > this.MaxPage()){
                pageNumber = this.MaxPage();
            }
            this.ActualPageNumber(pageNumber);
        };

        this.NavigatePreviousPage = () => {
            const actualPageNumber = this.ActualPageNumber();
            if(actualPageNumber > 1) {
                this.ActualPageNumber(actualPageNumber - 1);
                this.focusChannel.notifySubscribers({}, "focusFirstLine");
            }
        };

        this.NavigateNextPage = () => {
            const actualPageNumber = this.ActualPageNumber();
            if (actualPageNumber < this.MaxPage()) {
                this.ActualPageNumber(actualPageNumber + 1);
                this.focusChannel.notifySubscribers({}, "focusFirstLine");
            }
        };

        this.PreviousPageAvailable = ko.computed(()=>{
            return this.ActualPageNumber() > 1;
        }, this);

        this.NextPageAvailable = ko.computed(()=>{
            return this.ActualPageNumber() < this.MaxPage();
        }, this);

        this.updateFieldsAfterCollectionChange();
    }

    public dispose = () => {
        this.NextPageAvailable.dispose();
        this.PreviousPageAvailable.dispose();
        this.PagesArray.dispose();
        this.LastPageDisplayedInMenu.dispose();
        this.FirstPageDisplayedInMenu.dispose();
        this.ActualPageNumberSub.dispose();
        this.collectionSub.dispose();
        this.AnyLinesAreFiltered.dispose();
    };
};