///<amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/User/SuperTabs/tabs"/>

import * as React from "react";
import { Tab, TabBar, Tag, Text } from "@medius/ui-controls";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { useSelector } from "react-redux";
import { RootState } from "Core/Medius.Core.Web/Scripts/shared/store/reduxStore";
import { Module } from "Core/Medius.Core.Web/Scripts/Medius/apps/reactSpa/app";
import { Provider } from "react-redux";
import { store } from "Core/Medius.Core.Web/Scripts/shared/store/reduxStore";
import { IconUserUserRegular } from '@medius/ui-controls';
interface Props {
  setActiveTab: (arg0: string) => void;
  userName: string;
  firstName: string;
  lastName: string;
  isActive: boolean;
}

function TabsWithContext({setActiveTab, userName, firstName, lastName, isActive}:Props) {
  const enabledModules = useSelector((state: RootState) => state.accesses?.enabledModules);
  const hasAccess = !!enabledModules?.find(x => x === Module.Pay);
  const [selectedKey, setSelectedKey] = React.useState('General');
  const _color = isActive ? "green" : "red";

  React.useEffect(() => {
    setActiveTab(selectedKey);
  }, [selectedKey, setActiveTab]);

  if (!hasAccess) {
    return null;
  }

  return (
    <>
      <div className="user-superTabs-container">
        <IconUserUserRegular/>
        <div className="user-superTabs-children" >
          <Text variant="title"> {firstName} {lastName}</Text>
          <span className="user-superTabs-userName"> ({userName})</span>
        </div>
        <Tag
          color={_color}
          label="Active"
          maxLines={0}
        />
       </div>
      <TabBar onSelectionChange={(newKey) => setSelectedKey(newKey)} selectedKey={selectedKey}>
        <Tab tabKey="General" label={translate("#Core/general")} />
        <Tab tabKey="Step-up Authentication" label={translate("#Core/stepUpAuthentication")} />
      </TabBar>
    </>
  );
}

export function Tabs(Props: any) {
  return (
    <Provider store={store}>
      <TabsWithContext setActiveTab={Props.setActiveTab} userName={Props.userName} firstName={Props.firstName} lastName={Props.lastName} isActive={Props.isActive}/>
    </Provider>);
}