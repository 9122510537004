/// <amd-module name="Core/Medius.Core.Web/Scripts/lib/reactIntegration/propsValidator"/>
import * as ko from "knockout";

export function isValid(props: any) {
    const visitedObjects = new Set();
    const hasNoSubscribableObject = isValidRecursively(props, visitedObjects);
    return hasNoSubscribableObject;
}

function isValidRecursively(props: any, visitedObjects: Set<any>): boolean {
    if (ko.isSubscribable(props)) {
        return false;
    }

    if (isArray(props)) {
        return isValidArrayRecursively(props, visitedObjects)
    } else if ((typeof props === "object") && (props !== null)) {

        // Naive protection from circular & shared dependencies 
        // Shared dependencies could be supported if needed by rewriting this validation algorithm to DFS based one (which discovers cycles)
        if (visitedObjects.has(props)) {
            return false;
        }

        visitedObjects.add(props);

        return isValidObjectRecursively(props, visitedObjects);
    } else {
        return true;
    }
}

function isValidArrayRecursively(arr: any[], visitedObjects: Set<any>) {
    return arr.every(x => isValidRecursively(x, visitedObjects));
}

function isValidObjectRecursively(obj: any, visitedObjects: Set<any>) {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            if (!isValidRecursively(obj[key], visitedObjects)) {
                return false;
            }
        }
    }
    return true;
}

function isArray(o: any) {
    return Object.prototype.toString.call(o) === "[object Array]";
}
