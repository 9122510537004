/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/Company/Addresses/newAddressManagementPageInfoDialog"/>
import { AlertDialog, LinkButton, Text } from "@medius/ui-controls";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { getBasePath } from "Core/Medius.Core.Web/Scripts/Medius/lib/path";
import * as React from "react";

type NewAddressManagementPageInfoDialogProps = {
    isOpen: boolean;
    closeDialog: () => void;
};

export const NewAddressManagementPageInfoDialog = ({ isOpen, closeDialog }: NewAddressManagementPageInfoDialogProps) => {
    const deprecationInfoParts = translate("#Procurement/address/oldAddressViewDepreciationInfo").split("{0}");
    return <AlertDialog isOpen={isOpen} onClose={closeDialog}>
      <Text variant="paragraph">{deprecationInfoParts[0]}<LinkButton
            isTrustedUrl
            isBold
            label={translate("#Procurement/address/oldAddressViewDepreciationInfoFeatureName")}
            url={`${getBasePath()}#/configuration/AddressManagement`}
            useNewWindow={false}
            onClick={closeDialog}
        />{deprecationInfoParts[1]}</Text>
    </AlertDialog>;
};