/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/default/factory" />

import * as utils from "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/utils";
import * as model from "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/default/model";
import * as defaultsFactory from "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/default/defaults";
import * as dataProviderFactory from "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/default/dataProvider";

export function create(bindingParams: any) {
    const optionsExtension = {
        autofocus: bindingParams.autofocus,
        disabled: bindingParams.disabled,
        required: bindingParams.required
    };
    const providerOptionsExtension = {},
    params = utils.createParameters(bindingParams, optionsExtension, providerOptionsExtension, defaultsFactory, dataProviderFactory);

    return model.create(params);
}