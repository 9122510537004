/// <amd-module name="Core/Medius.Core.Web/Scripts/components/sidebar/additionalLinks"/>
import * as React from "react";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";

interface Props {
    links: {displayName: string; viewPath: string}[];
}

export function AdditionalLinks({links}: Props) {
    if (links.length === 0) {
        return null;
    }
    return (
    <>
        <h6>{translate("#Core/administrationAdditionalLinks")}</h6>
        <ul>
            {links.map(({displayName, viewPath}) => (
            <li key={displayName}>
                <a href={viewPath}>{translate(displayName)}</a>  
            </li>))}
        </ul>   
    </>);
}
