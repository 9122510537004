/// <amd-module name="Core/Medius.Core.Web/Scripts/components/quickSearch/quickSearch"/>
import * as ko from "knockout";
import { SuggestionDto } from "Core/Medius.Core.Web/Scripts/components/quickSearch/quickSearchDtos";
import * as quickSearchService from "Core/Medius.Core.Web/Scripts/components/quickSearch/services/quickSearchService";
import * as suggestionModelProviders from "Core/Medius.Core.Web/Scripts/components/quickSearch/suggestionModelProviders";
import Suggestion from "Core/Medius.Core.Web/Scripts/components/quickSearch/suggestion/suggestion";
import * as backendErrorHandler from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import {translate} from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as _ from "underscore";

const jwerty = require("jwerty");

export type SuggestionMapper = (
    dto: SuggestionDto,
    searchTerm: string,
    suggestionSelectedHandler: (suggestion: Suggestion) => void) => Suggestion;

const suggestionMapper: SuggestionMapper = (
    dto: SuggestionDto,
    searchTerm: string,
    suggestionSelectedHandler: (suggestion: Suggestion) => void) => {
    try {
        const suggestion = suggestionModelProviders.get(dto.entityType)(dto);
        suggestion.onSuggestionSelected = suggestionSelectedHandler;
        suggestion.searchTerm = searchTerm;
        return suggestion;
    } catch (e) {
        throw new Error(`Parsing suggestion failed for ${dto.entityType} and entityId=${dto.entityId}`);
    }
};

export class QuickSearch {
    private debounceWaitTimeInMilliSeconds = 500;
    private searchTextSub: ko.Subscription;
    private isActiveSub: ko.Subscription;
    private minSearchTextLength = 3;
    private registeredSuggestionModelProviderTypes = suggestionModelProviders.getRegisteredTypes();

    public static readonly maxSearchTextLength = 450;
    public tooltipText = translate("#Core/searchShortcut");

    public isActive = ko.observable(false);
    public isLoading = ko.observable(false);
    public searchTerm = ko.observable("");
    public suggestions: ko.ObservableArray<Suggestion> = ko.observableArray([]);
    public areSuggestionsLoaded = ko.observable(false);
    public isTimeoutInfoDisplayed = ko.observable(false);

    public isNoSuggestionsInfoDisplayed: ko.Computed<boolean>;
    public selectedSuggestion: ko.Computed<Suggestion>;
    public header: ko.Computed<string>;
    public searchIcon = `Content/images/search.png`;
    public suggestionMapper: SuggestionMapper;

    constructor() {
        this.suggestionMapper = suggestionMapper;
        this.searchTextSub = this.searchTerm.subscribe(searchTerm => {
            this.isTimeoutInfoDisplayed(false);
            if (this.isSearchTermInvalid(searchTerm)) {
                this.suggestions([]);
                this.areSuggestionsLoaded(false);
                return;
            }

            if (searchTerm) {
                this.refreshSuggestions();
                return;
            }

            this.loadRecentlyViewedDocuments();
        });

        this.isActiveSub = this.isActive.subscribe(isActive => {
            if (!isActive) {
                this.deactivate();
            } else {
                this.activate();
            }
        });

        this.isNoSuggestionsInfoDisplayed = ko.computed(() => !this.isLoading() && this.areSuggestionsLoaded() && this.suggestions().length === 0);

        this.selectedSuggestion = ko.computed(() =>
            this.getSelectedSuggestionIndex() >= 0
                ? this.suggestions()[this.getSelectedSuggestionIndex()]
                : null);

        this.header = ko.computed(() =>
            this.searchTerm()
                ? translate("#Core/quickSearchResults")
                : translate("#Core/quickSearchRecentlyViewed"));

        this.onBlur = this.onBlur.bind(this);        
    }

    private isSearchTermInvalid(searchTerm: string) {
        return searchTerm && (searchTerm.length < this.minSearchTextLength || searchTerm.trim().length < this.minSearchTextLength);
    }

    public activate = () => {
        if (this.searchTerm()) {
            this.loadSearchResults();
        } else {
            this.loadRecentlyViewedDocuments();
        }
        this.isActive(true);
    };

    public deactivate() {
        this.isActive(false);
        this.areSuggestionsLoaded(false);
        this.isTimeoutInfoDisplayed(false);
        this.suggestions([]);
    }

    public preventSubmit() { }

    public keydown(viewModel: any, event: JQueryKeyEventObject) {
        if (jwerty.is("↑", event)) {
            this.selectPreviousSuggestion();
            event.preventDefault();
        } else if (jwerty.is("↓", event)) {
            this.selectNextSuggestion();
            event.preventDefault();
        } else if (jwerty.is("esc", event)) {
            this.deactivate();
        } else if (jwerty.is("enter", event)) {
            this.tryOpenSelectedSuggestion();
        }
        return true;
    }

    public onBlur() {
        const USAGE_KEY = "searchFieldUsageTimes";
        const TEN_MINUTES = 10 * 60 * 1000;
        const currentTime = new Date().getTime();

        let usageTimes = JSON.parse(localStorage.getItem(USAGE_KEY)) || [];
        usageTimes.push(currentTime);

        // Keep only the last three entries
        usageTimes = usageTimes.slice(-3);

        localStorage.setItem(USAGE_KEY, JSON.stringify(usageTimes));

        if (usageTimes.length == 3 && (usageTimes[2] - usageTimes[0] <= TEN_MINUTES)) {
            localStorage.setItem(USAGE_KEY, JSON.stringify([]));
            const candu = (window as any).Candu;
            candu?.getEventing().track('SC2024.SearchBarPin');
            candu?.triggerHotspotGroup({ slug: 'sc1-search-hotspot' });
        }
    }

    public dispose() {
        this.searchTextSub.dispose();
        this.isNoSuggestionsInfoDisplayed.dispose();
        this.isActiveSub.dispose();
        this.selectedSuggestion.dispose();
        this.header.dispose();
    }

    private tryOpenSelectedSuggestion() {
        if (this.selectedSuggestion()) {
            this.selectedSuggestion().suggestionSelected();
        }
    }

    private selectPreviousSuggestion() {
        if (this.suggestions().length > 1) {
            const previousSelectedSuggestionIndex = (this.suggestions().length + this.getSelectedSuggestionIndex() - 1) % this.suggestions().length;
            this.selectedSuggestion().isSelected(false);
            this.suggestions()[previousSelectedSuggestionIndex].isSelected(true);
        }
    }

    private selectNextSuggestion() {
        if (this.suggestions().length > 1) {
            const nextSelectedSuggestionIndex = (this.getSelectedSuggestionIndex() + 1) % this.suggestions().length;
            this.selectedSuggestion().isSelected(false);
            this.suggestions()[nextSelectedSuggestionIndex].isSelected(true);
        }
    }

    private getSelectedSuggestionIndex() {
        return this.findIndex(this.suggestions(), suggestion => suggestion.isSelected());
    }

    private refreshSuggestions = _.debounce(() => {
        if (this.searchTerm()) {
            this.loadSearchResults();
        }
    }, this.debounceWaitTimeInMilliSeconds);

    private loadSearchResults() {
        const actualSearchTerm = this.searchTerm();

        if (this.isSearchTermInvalid(actualSearchTerm)) {
            return;
        }

        this.isLoading(true);

        const hasModelProvider = (dto: SuggestionDto): boolean => 
            this.registeredSuggestionModelProviderTypes.includes(dto.entityType);

        quickSearchService
            .search(actualSearchTerm.substring(0, QuickSearch.maxSearchTextLength))
            .done(searchResult => {
                if (searchResult.kind === "success") {
                    const suggestionsCollectionDto = searchResult.results;
                    if ((suggestionsCollectionDto.searchTermUsed || "") === this.searchTerm()) {
                        this.suggestions(suggestionsCollectionDto.suggestions.filter(hasModelProvider).map(this.toSuggestion));
                        this.areSuggestionsLoaded(true);
                        this.selectFirstSuggestion();
                    }
                } else {
                    this.isTimeoutInfoDisplayed(true);
                }
            })
            .fail(xhr => backendErrorHandler.handleAnyError(xhr))
            .always(() => this.isLoading(false));
    }

    private loadRecentlyViewedDocuments() {
        this.isLoading(true);

        const hasModelProvider = (dto: SuggestionDto): boolean => 
            this.registeredSuggestionModelProviderTypes.includes(dto.entityType);
        
        quickSearchService
            .recentlyViewed()
            .done(suggestionsCollectionDto => {
                if (!this.searchTerm()) {
                    this.suggestions(suggestionsCollectionDto.suggestions.filter(hasModelProvider).map(dto =>
                        this.suggestionMapper(dto, this.searchTerm(), this.onSuggestionSelected)));
                    this.areSuggestionsLoaded(true);
                    this.selectFirstSuggestion();
                }
            })
            .fail(xhr => backendErrorHandler.handleAnyError(xhr))
            .always(() => this.isLoading(false));
    }

    private onSuggestionSelected = () => {
        this.deactivate();
    };

    private selectFirstSuggestion() {
        if (this.suggestions().length > 0) {
            this.suggestions()[0].isSelected(true);
        }
    }

    private toSuggestion = (dto: SuggestionDto) => {
        try {
            const suggestion = suggestionModelProviders.get(dto.entityType)(dto);
            suggestion.onSuggestionSelected = this.onSuggestionSelected;
            suggestion.searchTerm = this.searchTerm();
            return suggestion;
        } catch (e) {
            throw new Error(`Parsing suggestion failed for ${dto.entityType} and entityId=${dto.entityId}`);
        }
    };

    private findIndex<T>(items: T[], condition: (item: T) => boolean): number {
        let index = -1;
        items.some((el, i) => {
            if (condition(el)) {
                index = i;
                return true;
            }
            return false;
        });
        return index;
    }
}
