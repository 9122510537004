/// <amd-module name="Core/Medius.Core.Web/Scripts/shared/components/textIconCallout"/>
import * as React from "react";
import {useRef, useState} from "react";
import { Callout, Button, FlexLayout, Text, IconStatusInfoRegular } from "@medius/ui-controls";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";

export function TextIconCallout(props: {referencedDocuments: string} ) {

    const [columnSelectOpen, setColumnSelectOpen] = useState(false);
    const iconButtonRef = useRef<HTMLButtonElement>(null);

    return (
        <>
        <div className="field-value-container-text-overflow">
            <span className="field-value-text-overflow" data-testid="referenced-documents-text-overflow">{props.referencedDocuments}</span>
            {props.referencedDocuments ? (
            <span>
                <Button
                    ref={iconButtonRef}
                    data-testid="referenced-documents-callout-button"
                    label={translate("#Core/showMore")}
                    hideLabel
                    variant="inline"
                    height="small"
                    icon={<IconStatusInfoRegular />}
                    onClick={() => setColumnSelectOpen(true)}
                />
                <Callout
                    displayTargetRef={iconButtonRef}
                    data-testid="referenced-documents-callout"
                    isOpen={columnSelectOpen}
                    position="bottom-end"
                    onCloseRequested={() => setColumnSelectOpen(false)}>
                    <FlexLayout direction="column">
                        <Text variant="paragraph">{props.referencedDocuments}</Text>
                    </FlexLayout>
                </Callout>
            </span>
          ) : null}
        </div>
        </>
      );
}
