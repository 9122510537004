///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/presenters/currencyRate/model"/>
import * as settingsProvider from "Core/Medius.Core.Web/Scripts/Medius/core/settingsProvider";
import { FinanceComponent } from "Core/Medius.Core.Web/Scripts/Medius/components/presenters/financeComponent/model";

class CurrencyRatePresenter extends FinanceComponent{
    create: () => CurrencyRatePresenter;
    constructor(bindingParameters:any) {
        super(bindingParameters);
    }
    getResolution(currencyCode:string) {
        return settingsProvider.getCurrencyRateDisplayResolution(currencyCode);
    }
}

export function create(bindingParameters:any) {
    return new CurrencyRatePresenter(bindingParameters).create();
}