///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/autofocus"/>
import * as $ from "jquery";
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";

function setAutofocus(element:any) {
    // Normal code:
    ($(element)[0].setAttribute as any)("autofocus", true);
    ($(element)[0] as any).select();
    
    // IE9 & Firefox code:
    $(element)[0].focus();
}

function delaySetAutofocus(element:any) {
    setTimeout(function() { setAutofocus(element); }, 0);
}

const autofocus = {
    init: function(element:any, bindingAccessor:any) {
        const enableAutofocus = koUtils.unpack(bindingAccessor());

        if (!enableAutofocus) {
            return;
        }

        delaySetAutofocus(element);
    }
};

export function register() {
    koUtils.registerBinding("autofocus", autofocus);
}