/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/reactSpa/routes"/>
import * as React from "react";
import {ComponentType} from "react";
import {useSelector} from "react-redux";
import {DemoManager} from "Core/Medius.Core.Web/Scripts/Medius/apps/demoManager/demoManager";
import { NoAccess } from "Core/Medius.Core.Web/Scripts/Medius/apps/noAccess/noAccess";
import {RootState} from "Core/Medius.Core.Web/Scripts/shared/store/reduxStore";
import { Routes, Route } from "react-router-dom";

const pages: JSX.Element[] = [];

export function RegisterPage(path: string, Component: ComponentType, props: any = null) {
    pages.push(<Route path={path} key={path} element={<Component {...props}/>}/>);
}

export function AppRoutes() {
    const accesses = useSelector((x: RootState) => x.accesses);
    return (<Routes>
        <Route path="/demoManager" element={accesses.demoManager ? <DemoManager /> : <NoAccess />}/>
        {pages}
        <Route path="*" element={<div></div>}/>
    </Routes>);
}
