///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/task/RiskFactor/riskFactorIconComponent"/>
import * as React from "react";
import { Callout, Notification, IconActionCloseRegular, IconToggleRiskFactorRegular, IconToggleRiskFactorHandledRegular } from "@medius/ui-controls";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { Loader } from "@progress/kendo-react-indicators";
import * as services from "Core/Medius.Core.Web/Scripts/Medius/apps/task/RiskFactor/riskFactorIconComponentServices";
import * as appConfig from "Core/Medius.Core.Web/Scripts/appConfig";
import { RiskFactor, RiskFactorStatusForInvoice, RiskFactorCode, RiskFactorReasonCode } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/RiskFactor/RiskFactorComponentDtos/riskFactorsForInvoiceDto";
import { RiskFactorCard } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/RiskFactor/RiskFactorCard";
import { LocalizationContext, StringMap } from '@medius/ui-controls';
import { getCultureCode } from "Core/Medius.Core.Web/Scripts/Medius/lib/utils/user";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
import { getCurrentCulture } from "Core/Medius.Core.Web/Scripts/lib/reactIntegration/kendoIntl";
import { mapFromNorthstarToApa } from "Core/Medius.Core.Web/Scripts/lib/northstarLocalization";

interface IProps{
    taskId: number;
}

export default function RiskFactorIconComponent({ taskId }: IProps){
    const [generalRiskFactorStatus, setGeneralRiskFactorStatus] = React.useState<RiskFactorStatusForInvoice>();
    const [isLoadingStatus, setIsLoadingStatus] = React.useState(true);
    const [hideComponent, setHideComponent] = React.useState(true);

    const [isLoading, setIsLoading] = React.useState(true);
    const [isRiskFactorCalloutOpened, setIsRiskFactorCalloutOpened] = React.useState(false);
    const [unhandledRisks, setUnhandledRisks] = React.useState<RiskFactor[]>([]);
    const [handledRisks, setHandledRisks] = React.useState<RiskFactor[]>([]);
    const [currentReasonCodes, setCurrentReasonCodes] = React.useState<{reasonCode: RiskFactorReasonCode, riskFactorCode: RiskFactorCode}[]>([]);
    const [canEdit, setCanEdit] = React.useState<boolean>(false);

    React.useEffect(() => {
        services.getRiskFactorStatus(taskId).then(resp => {
            if(!resp){
                setHideComponent(true);
                return;
            }
            setGeneralRiskFactorStatus(resp);
            setIsLoadingStatus(false);

            if(!resp.hasAnyRiskFactors){
                setHideComponent(true);
            } else {
                setHideComponent(false);
            }

        }).catch(() => {
            setHideComponent(true);
        });
    }, [taskId]);

    const openCallout = () => {
        setIsLoading(true);
        setIsRiskFactorCalloutOpened(true);

        services.getRiskFactors(taskId).then(response => {
            if(!response){
                setHideComponent(true);
                return;
            }

            const invoiceDetails = response.invoiceDetails;
            setIsLoading(false);
            setHideComponent(false);

            const filteredHandledRisks = invoiceDetails.riskFactors.filter(item => item.isHandled);
            const filteredUnhandledRisks = invoiceDetails.riskFactors.filter(item => !item.isHandled);

            setHandledRisks(filteredHandledRisks);
            setUnhandledRisks(filteredUnhandledRisks);
            setCanEdit(response.canEdit);

            //filling in existing reason codes selected by the user earlier (only risks with handledInfo, which will be 
            //converted to handled risks and sent to CRFS)
            invoiceDetails.riskFactors.forEach(element => {
                if(element.handledInfo){
                    setCurrentReasonCodes(prev => [...prev, { reasonCode: element.handledInfo.reasonCode, riskFactorCode: element.code }]);
                }
            });

        }).catch(() => {
            setHideComponent(true);
        });
    };

    const closeCallout = () => {
        setCurrentReasonCodes([]);
        setIsRiskFactorCalloutOpened(false);
    };

    const refreshDetailsCall = () => {
        setIsLoading(true);
        services.getRiskFactors(taskId).then(response => {
            const invoiceDetails = response.invoiceDetails;
            setIsLoading(false);
            const filteredHandledRisks = invoiceDetails.riskFactors.filter(item => item.isHandled);
            const filteredUnhandledRisks = invoiceDetails.riskFactors.filter(item => !item.isHandled);
            
            setHandledRisks(filteredHandledRisks);
            setUnhandledRisks(filteredUnhandledRisks);
            setCanEdit(response.canEdit);
        }).catch(() => {
            setHideComponent(true);
        });
    };

    const riskFactorContainerRef = React.useRef<HTMLDivElement>(null);
    const riskFactorAnchorRef = React.useRef<HTMLDivElement>(null);

    if(isLoadingStatus)
        return <></>;

    return(
        <LocalizationContext.Provider value={{
            translate: (key: string, _, defaultValue?: string, args?: StringMap) => mapFromNorthstarToApa(key, defaultValue, args),
            getLocale: () => getCultureCode()
        }} >
            <IntlProvider locale={getCurrentCulture()}>
                <LocalizationProvider language="current-language">
                {
                    !hideComponent && 
                    <div className="risk-factor-icon-container"
                        data-testid="sidebar-outer-container"
                        style={generalRiskFactorStatus.hasActiveRiskFactors ? { borderColor: "red", padding: "5px 12px 5px 11px" } : null}
                        ref={riskFactorContainerRef}
                    >
                        <div
                            ref={riskFactorAnchorRef}
                            data-testid={generalRiskFactorStatus.hasActiveRiskFactors ? "risk-factor-icon-unhandled" : "risk-factor-icon-handled" }
                        >
                            {
                                generalRiskFactorStatus.hasActiveRiskFactors ? (
                                    <div className="risk-factor-icon-container__moreThanOneContainer" onClick={openCallout} data-testid="open-callout-button">
                                        <IconToggleRiskFactorRegular color="error" size="small"/>
                                        { generalRiskFactorStatus.activeRiskFactorsCount > 0 ? <p data-testid="active-riskFactors-count">{generalRiskFactorStatus.activeRiskFactorsCount}</p> : null}
                                    </div>
                                ) : <div onClick={openCallout}><IconToggleRiskFactorHandledRegular color="container" size="small"/></div>
                            }
                            <Callout
                                displayTargetRef={riskFactorAnchorRef}
                                renderContainerRef={riskFactorContainerRef}
                                onCloseRequested={ () => closeCallout() }
                                isOpen={isRiskFactorCalloutOpened}
                                position="bottom-end"
                                className="risk-factor-icon-container__kendoCalloutStyle"
                            >
                                <div className="risk-factor-icon-container__calloutContainer" data-testid="callout-container">
                                    <div className="risk-factor-icon-container__bannerContainer">
                                        <h2>{getLabelTranslation("#Core/risks")}</h2>
                                        <div 
                                            className="risk-factor-icon-container__closeIconContainer" 
                                            onClick={() => closeCallout()}
                                            data-testid="close-callout-button"
                                        >
                                            <IconActionCloseRegular />
                                        </div>
                                    </div>
                                    <hr className="risk-factor-icon-container__calloutContainer__bannerSeparator" />
                                    {
                                        isLoading ? <Loader size="large" type="converging-spinner" /> : (
                                            <>
                                            {
                                                generalRiskFactorStatus.isInconclusive && 
                                                <div className="risk-factor-icon-container__notificationWrapper" style={{marginBottom: "10px"}}>
                                                    <Notification
                                                        data-testid="inconclusive-risks-notification" 
                                                        feedbackType="warning"
                                                        title={getLabelTranslation("#Core/couldNotCalculateAllRiskFactors")}
                                                        text={getLabelTranslation("#Core/crfsInconclusiveInvoiceCanStillBeProcessed")}
                                                        actionLabel="Refresh"
                                                        onAction={refreshDetailsCall}
                                                    />
                                                </div>
                                            }
                                            <div className="risk-factor-icon-container__notificationWrapper">
                                                <Notification 
                                                    isTrustedUrl
                                                    feedbackType="info"
                                                    text={getLabelTranslation("#Core/whyIsThisImportant")+"?"}
                                                    actionLabel="Success Portal"
                                                    url={`${appConfig.getDocumentationUrl()}_fraud_and_risk_protection`}
                                                />
                                            </div>
                                            <div className="risk-factor-icon-container__cardWrapper" style={generalRiskFactorStatus.isInconclusive ? {border: 'none'} : null}>
                                                {
                                                    unhandledRisks.length > 0 && 
                                                        <RiskFactorCard
                                                            canEdit={canEdit}
                                                            risks={unhandledRisks} 
                                                            areHandled={false} 
                                                            currentReasonCodes={currentReasonCodes}
                                                            taskId={taskId}
                                                        />
                                                }
                                                {(!generalRiskFactorStatus.isInconclusive && handledRisks.length > 0 && unhandledRisks.length > 0 ) && <hr style={{margin: "2px"}} />}
                                                {
                                                    handledRisks.length > 0 && 
                                                    <RiskFactorCard
                                                        canEdit={false}
                                                        risks={handledRisks} 
                                                        areHandled 
                                                        currentReasonCodes={currentReasonCodes}
                                                        taskId={taskId}
                                                    />
                                                }
                                            </div>
                                            </>
                                        )
                                    }
                                </div>
                            </Callout>
                        </div>
                    </div>
                }
                </LocalizationProvider>
            </IntlProvider>
        </LocalizationContext.Provider>
    );
}