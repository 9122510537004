/// <amd-module name="Core/Medius.Core.Web/Scripts/components/pdfViewer/imageSelector/imageSelector"/>
import * as ko from "knockout";
import * as backendErrorHandler from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import * as _ from "underscore";
import { getTypeName } from "Core/Medius.Core.Web/Scripts/Medius/core/type";

const rest = require("Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rest");

export class ImageSelector {
    public images = ko.observableArray([]);
    public selectedImage = ko.observable(null);
    private isLoading = false;
    private attemptedSelectImage = "";

    private isMissingFileName(hashFile: any): boolean {
        const unwrappedHashFileName = ko.unwrap(hashFile.Name);
        return unwrappedHashFileName === undefined
            || unwrappedHashFileName === ""
            || unwrappedHashFileName === null;
    }

    public loadImages(documentId: number, documentType: string, imageArray: any[]) {

        if (imageArray.length > 0 && this.isMissingFileName(imageArray[0])) {
            this.loadImagesFromBackend(documentId, documentType);
        } else {
            this.images(imageArray.map((img: any) => ({ Name: img.Name(), Hash: img.Hash() })));
        }
    }

    private loadImagesFromBackend(documentId: any, documentType: any) {
        this.isLoading = true;
        return rest.get("HashFilesService",
                "GetImageHashFilesWithNames",
                {
                    entityId: documentId,
                    entityType: getTypeName(documentType)
                })
            .pipe((files: any) => {
                this.images(files);
                if (this.attemptedSelectImage === "" && this.images().length > 0) {
                    this.selectImage(this.images()[0].Hash);
                } else {
                    this.selectImage(this.attemptedSelectImage);
                }
                this.attemptedSelectImage = "";
            })
            .fail((err: any) => {
                backendErrorHandler.handleAnyError(err);
            })
            .always(() => {
                this.isLoading = false;
            });
    }

    public selectImage(hash: any) {
        if (this.selectedImage() === null || hash !== this.selectedImage().Hash) {
            const image = _.find(this.images(), (img: any) => img.Hash === hash);
            if (image) {
                this.selectedImage(image);
            } else if (this.isLoading) {
                this.attemptedSelectImage = hash;
            }
        }
    }

    public showImageSelector = ko.computed(() => {
        return this.images().length > 1;
    });

    public dispose() {
        this.showImageSelector.dispose();
    }

}
