///<amd-module name = "Core/Medius.Core.Web/Scripts/components/taskHandling/fraudPopup/FinalApprovalPopupTemplate"/>
import * as React from "react";
import { ConfirmDialog, Text } from "@medius/ui-controls";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { RiskFactor } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/RiskFactor/RiskFactorComponentDtos/riskFactorsForInvoiceDto";
import { getRiskFactorNameTranslation } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/RiskFactor/riskFactorUtils";

interface FinalApprovalTemplateInterface {
    showPopup: boolean,
    onConfirm: () => void,
    onCancel: () => void,
    unhandledRiskFactors: RiskFactor[]
}

export const FinalApprovalPopupTemplate = ({showPopup, onConfirm, onCancel, unhandledRiskFactors}:FinalApprovalTemplateInterface) => {
    return (<>
        <ConfirmDialog
            width="content"
            isOpen={showPopup}
            data-testid="finalApprovalPopup"
            cancelLabel={getLabelTranslation("#Core/cancel")}
            confirmLabel={getLabelTranslation("#Core/finalApprovalConfirmation")}
            onCancel={onCancel}
            onConfirm={onConfirm}>
            <Text data-testid="finalApprovalPopup-title" variant="heading2">{getLabelTranslation("#Core/finalApprovalPopupTitle")}</Text>
            <Text data-testid="finalApprovalPopup-text" variant="body">{getLabelTranslation("#Core/finalApprovalPopupBody")}</Text>  
            <ul data-testid="finalApprovalPopup-riskFactorList">
            {unhandledRiskFactors.map((r, index) => 
                <li key={index} data-testid={`finalApprovalPopup-riskFactor-${r.code}`}>
                    <Text variant="body">{getRiskFactorNameTranslation(r.code)}</Text>
                </li>)}    
            </ul>
        </ConfirmDialog>
    </>);
};