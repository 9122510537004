///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/provider/types" />
import * as $ from "jquery";
import * as _ from "underscore";
import * as rest from "Core/Medius.Core.Web/Scripts/Medius/core/rest";
import * as logger from "Core/Medius.Core.Web/Scripts/Medius/lib/logger";

let types: any[] = [];
let typesTranslations: any[] = [];
let deferred: any = null;
    
export function load(){    
    if (deferred) {
        return deferred;
    }
    
    if (types.length > 0) {
        return $.Deferred().resolve(types);
    }
    
    deferred = rest.get("DataSearchManager", "types").pipe(function(typesResult: any) {
        setTypes(typesResult);
        return types;
    })
    .fail(logger.error)
    .always(function () {
        deferred = null;
    });

    return deferred;
}

export function setTypes(data: any){
    types = _.chain(data).map(function (typeDef) {
        if (!typeDef.Translation) {
            return typeDef;
        }

        typeDef.Translation = typeDef.Translation.trim();

        if (typeDef.Translation.indexOf('#') === 0) {
            typeDef.Translation = typeDef.Translation.split(".").pop();
        }
        
        return typeDef;
    }).value();

    typesTranslations = _.map(types, function(typeDef) {
        return typeDef.Translation;
    });
}

export function getTypes(){
    return types;
}

export function getTypesTranslations(){
    return typesTranslations;
}

export function getFullTypeName(typeNameTranslation: any){
    const typeDef = _.find(types, function (def) {
        return def.Translation === typeNameTranslation;
    });
    
    if (_.isUndefined(typeDef)) {
        return typeNameTranslation;
    }
    return typeDef.TypeFullName;
}