/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/withSpaApp"/>
import { applyBindingsToDescendants } from "knockout";
import { registerBinding } from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";

const binding = {
    init: function (element: any, valueAccessor: () => any) {
        const spaApp = valueAccessor();
        
        applyBindingsToDescendants(spaApp.app, element);
        
        return { controlsDescendantBindings: true };
    }
};

export function register() {
    registerBinding("withSpaApp", binding);
}