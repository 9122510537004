/// <amd-module name="Core/Medius.Core.Web/Scripts/errorPages/ClientApplicationAuthenticationError"/>
import * as React from "react";
import Card from "Core/Medius.Core.Web/Scripts/components/card/Card";
import {toAction} from "Core/Medius.Core.Web/Scripts/Medius/lib/path";
import {translate} from "Core/Medius.Core.Web/Scripts/lib/globalization";

export default function ClientApplicationAuthenticationErrorPage() {
    return (
        <Card>
            <div className="card-content">
                <div className="card-message">
                    {translate("#Core/clientAppAuthFail")}
                </div>
                    
                <form action={toAction('SwitchProvider', '~/Account')}>
                    <button className="card-button card-button" type="submit">{translate("#Core/logOut")}</button>
                </form>
            </div>
        </Card>
    );
}
