///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/userSettings/tab"/>
import * as _ from "underscore";
import * as tabManager from "Core/Medius.Core.Web/Scripts/Medius/apps/userSettings/tabManager";

class UserSettingsTab {
    name = "";
    template = "test-tpl";
    href = _.uniqueId("userSettings-tab-");
    isDefault = false;

    init() {
    }

    onSave(/*modifications*/) {
    }
    
    dispose() {
    }
}

export function create(o:any) {
    const tab = new UserSettingsTab();
    _.extend(tab, o);

    tabManager.register(tab);
}