/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/DocumentManagement/documentManagement"/>
import * as React from "react";
import { useState, useEffect, useCallback } from "react";
import { Grid, GridColumn, GridToolbar, GridProps, GridDataStateChangeEvent } from "@progress/kendo-react-grid";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { get, post, del } from "Core/Medius.Core.Web/Scripts/Medius/core/fetch/rest";
import { Button } from "@progress/kendo-react-buttons";
import { success } from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { handleError } from "Core/Medius.Core.Web/Scripts/lib/errorHandling/errorHandler";
import { registerAdministrationEntity } from "Core/Medius.Core.Web/Scripts/AdminPages/administrationServices";
import { SidebarNavigation } from "Core/Medius.Core.Web/Scripts/AdminPages/sidebarNavigation";
import { useSelector } from "react-redux";
import { RootState } from "Core/Medius.Core.Web/Scripts/shared/store/reduxStore";
import { NoAccess } from "Core/Medius.Core.Web/Scripts/Medius/apps/noAccess/noAccess";
import { TextArea } from "@progress/kendo-react-inputs";
import { Input } from "@progress/kendo-react-inputs";
import { useDebounce } from "use-debounce";

interface DocumentDto {
    id: number;
    type: string | null;
    source: string;
    createdTimestamp: string;
    selected: boolean;
    latestTaskDescription: string;
    canArchiveOrInvalidate: boolean;
    finalVoucherNumber: string;
}

export function DocumentManagement() {
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const [archiveDialogVisible, setArchiveDialogVisible] = useState(false);
    const [invalidateDialogVisible, setInvalidateDialogVisible] = useState(false);
    const [archiveComment, setArchiveComment] = useState<string>("");
    const [archiveVoucher, setArchiveVoucher] = useState<string>("");
    const [invalidateComment, setInvalidateComment] = useState<string>("");
    const [selectedDocument, setSelectedDocument] = useState<DocumentDto>(null);
    const [hasInvalidateAndArchivePermission, setInvalidateAndArchivePermission] = useState<boolean>(false);
    const [dataState, setDataState] = useState<GridProps>({
        take: 20,
        skip: 0,
        sort: [{ field: "id", dir: "asc" }],
        filter: {
            logic: "and",
            filters: []
        }
    });

    const [documents, setDocuments] = useState({ data: [] as DocumentDto[], total: 0 });
    const [loading, setLoading] = useState(false);
    const sortValues = dataState.sort[0] as { field?: string; dir?: string };
    const idValue = dataState.filter && dataState.filter.filters && dataState.filter.filters[0] ? (dataState.filter.filters[0] as any).value : null;
    const { field, dir } = { ...sortValues };

    const [debouncedIdValue] = useDebounce(idValue, 200);

    const reload = useCallback(() => {
        const fetchData = async () => {
            try {
                const result = await get("documentManagement/documents", { skip: dataState.skip, take: dataState.take, sortField: field, sortDir: dir, idValue: debouncedIdValue });
                const data = result.data.map((x: DocumentDto) => ({
                    ...x,
                    latestTaskDescription: translate(x.latestTaskDescription),
                    createdTimestamp: new Date(x.createdTimestamp)
                }));
                setDocuments({ ...result, data });
            } catch (e) {
                handleError(e);
            }

            setLoading(false);
        };
        setLoading(true);
        fetchData();
    }, [dataState.skip, dataState.take, field, dir, debouncedIdValue]);

    const loadPermissions = useCallback(() => {
        const fetchPermissions = async () => {
            try {
                const result = await get("documentManagement/has-invalidate-and-archive-permission");
                setInvalidateAndArchivePermission(result);
            } catch (e) {
                handleError(e);
            }
        };
        fetchPermissions();
    }, [setInvalidateAndArchivePermission]);

    useEffect(() => {
        reload();
        loadPermissions();
    }, [reload, loadPermissions]);

    const hasAccess = useSelector((state: RootState) => state.accesses.documentManagement);
    const dataStateChanged = (e: GridDataStateChangeEvent) => {
        setDataState({
            ...e.dataState
        });
    };

    const customFilterOperators = {
        text: [
            { text: "grid.filterEqOperator", operator: "eq" }
        ]
    };

    function rejectDelete() {
        setDeleteDialogVisible(false);
    }

    function rejectArchive() {
        setArchiveDialogVisible(false);
        setArchiveComment(null);
        setArchiveVoucher(null);
    }

    function rejectInvalidate() {
        setInvalidateDialogVisible(false);
        setInvalidateComment(null);
    }

    async function confirmDelete() {
        setDeleteDialogVisible(false);
        try {
            await del(`Backend/Rest/documentManagement/${selectedDocument.id}`);
            success(translate("#Core/documentDeleted"));
            reload();
        } catch (e) {
            handleError(e);
        }
    }

    async function confirmArchive() {
        setArchiveDialogVisible(false);
        try {
            await post(`Backend/Rest/documentManagement/${selectedDocument.id}/archive`, { comment: archiveComment, voucher: archiveVoucher });
            success(translate("#Core/documentManagementArchiveTopic"));
            reload();
        } catch (e) {
            handleError(e);
        } finally {
            setArchiveComment(null);
            setArchiveVoucher(null);
        }
    }

    async function confirmInvalidate() {
        setInvalidateDialogVisible(false);
        try {
            await post(`Backend/Rest/documentManagement/${selectedDocument.id}/invalidate`, { comment: invalidateComment });
            success(translate("#Core/documentManagementInvalidateTopic"));
            reload();
        } catch (e) {
            handleError(e);
        } finally {
            setInvalidateComment(null);
        }
    }

    if (!hasAccess) {
        return (<NoAccess />);
    }
    return (
        <>
            <SidebarNavigation />
            <div className="document-management">
                <h2 className="administration-page-header">{translate("#Core/documentManagement")}</h2>
                {loading && <LoadingPanel />}
                <Grid
                    total={documents.total}
                    data={documents.data.map(entry => ({
                        ...entry,
                        selected: selectedDocument?.id === entry.id
                    }))}
                    {...dataState}
                    sortable={true}
                    pageable={true}
                    filterable={true}
                    filterOperators={customFilterOperators}
                    onDataStateChange={dataStateChanged}
                    scrollable="scrollable"
                    style={{ flexGrow: 1, height: "0" }}
                    selectedField="selected"
                    onRowClick={({ dataItem }: { dataItem: DocumentDto }) => {
                        setSelectedDocument(dataItem);
                    }}>
                    <GridToolbar>
                        <Button
                            id="importManagement__refresh-button"
                            title={translate("#Core/refresh")}
                            icon="refresh"
                            onClick={reload}>{translate("#Core/refresh")}</Button>
                        <Button className="document-management__action-cell__button"
                            onClick={() => { setDeleteDialogVisible(true); }}
                            icon="delete"
                            disabled={!selectedDocument?.id}
                            data-testid="dm-delete-button"
                        >{translate("#Core/documentManagementDelete")}</Button>
                        {hasInvalidateAndArchivePermission && <Button className="document-management__action-cell__button"
                            onClick={() => { setInvalidateDialogVisible(true); }}
                            disabled={!selectedDocument?.canArchiveOrInvalidate}
                            data-testid="dm-invalidate-button"
                        >{translate("#Core/documentManagementInvalidate")}</Button>}
                        {hasInvalidateAndArchivePermission && <Button className="document-management__action-cell__button"
                            onClick={() => { 
                                setArchiveVoucher(selectedDocument.finalVoucherNumber); 
                                setArchiveDialogVisible(true); 
                            }}
                            disabled={!selectedDocument?.canArchiveOrInvalidate}
                            data-testid="dm-archive-button"
                        >{translate("#Core/documentManagementArchive")}</Button>} 
                    </GridToolbar>
                    <GridColumn field="id" title={translate("#Core/idColumnName")} width="400" format="{0:n}" filter="text" />
                    <GridColumn field="type" title={translate("#Core/type")} filterable={false} />
                    <GridColumn field="source" title={translate("#Core/documentSource")} filterable={false} />
                    <GridColumn field="createdTimestamp" title={translate("#Core/createdDate")} filterable={false} format="{0:d}" />
                    <GridColumn field="latestTaskDescription" title={translate("#Enterprise/status")} filterable={false} sortable={false} />
                </Grid>
                {deleteDialogVisible && <Dialog title={translate("#Core/confirmWindowHeader")} onClose={rejectDelete}>
                    <p className="document-management__dialog__message__delete">{translate("#Core/deleteThisDocumentConfirmationMessage")}</p>
                    <DialogActionsBar>
                        <button className="k-button" onClick={rejectDelete}>{translate("#Core/no")}</button>
                        <button className="k-button" data-testid="dm-confirmationDialog-yes" onClick={confirmDelete}>{translate("#Core/yes")}</button>
                    </DialogActionsBar>
                </Dialog>}
                {archiveDialogVisible &&
                    <ArchivePopup rejectArchive={rejectArchive} setArchiveComment={setArchiveComment} confirmArchive={confirmArchive} archiveComment={archiveComment} archiveVoucher={archiveVoucher} setArchiveVoucher={setArchiveVoucher}></ArchivePopup>}
                {invalidateDialogVisible &&
                    <InvalidatePopup rejectInvalidate={rejectInvalidate} setInvalidateComment={setInvalidateComment} confirmInvalidate={confirmInvalidate} invalidateComment={invalidateComment} ></InvalidatePopup>}
            </div>
        </>
    );
}

export function registerDocumentManagementAdminPage() {
    registerAdministrationEntity("DocumentManagement", DocumentManagement);
}

function LoadingPanel() {
    return (
        <div className="k-loading-mask">
            <span className="k-loading-text">{translate("#Core/loading")}</span>
            <div className="k-loading-image"></div>
            <div className="k-loading-color"></div>
        </div>
    );
}

function InvalidatePopup({ rejectInvalidate, setInvalidateComment, confirmInvalidate, invalidateComment }: {
    rejectInvalidate: () => void;
    setInvalidateComment: React.Dispatch<React.SetStateAction<string>>; confirmInvalidate: () => void; invalidateComment: string
}) {

    return (<Dialog title={translate("#Core/confirmWindowHeader")} onClose={rejectInvalidate}>
        <span className="document-management__dialog__message">
            <p><b>{translate("#Core/invalidateThisDocumentConfirmationMessage_1")}</b></p>
            <p>{translate("#Core/invalidateThisDocumentConfirmationMessage_2")}</p>
            <p>{translate("#Core/invalidateThisDocumentConfirmationMessage_3")}</p>
            <p className="document-management__dialog__message__orderbased">{translate("#Core/invalidateThisDocumentConfirmationMessage_4")}</p>
            <p>{translate("#Core/invalidateThisDocumentConfirmationMessage_5")}</p>
            <h5>{translate("#Core/comment")} *</h5>
            <TextArea data-testid="dm-invalidate-confirmationDialog-comment" style={{ width: "100%" }} value={invalidateComment} onChange={e => setInvalidateComment(e.value.toString())} required={true} rows={5}></TextArea>
        </span>
        <DialogActionsBar>
            <button className="k-button" onClick={rejectInvalidate}>{translate("#Core/documentManagementCancel")}</button>
            <button className="k-button" data-testid="dm-invalidate-confirmationDialog-yes" onClick={confirmInvalidate} disabled={!(invalidateComment?.length > 0)}>{translate("#Core/documentManagementPermanentlyInvalidate")}</button>
        </DialogActionsBar>
    </Dialog>
    );
}

function ArchivePopup({ rejectArchive, setArchiveComment, confirmArchive, setArchiveVoucher, archiveVoucher, archiveComment }: {
    rejectArchive: () => void;
    setArchiveComment: React.Dispatch<React.SetStateAction<string>>; confirmArchive: () => void; setArchiveVoucher: React.Dispatch<React.SetStateAction<string>>; archiveVoucher: string, archiveComment: string
}) {

    return (<Dialog title={translate("#Core/confirmWindowHeader")} onClose={rejectArchive}>
        <span className="document-management__dialog__message">
            <p><b>{translate("#Core/archiveThisDocumentConfirmationMessage_1")}</b></p>
            <p>{translate("#Core/archiveThisDocumentConfirmationMessage_2")}</p>
            <p>{translate("#Core/archiveThisDocumentConfirmationMessage_3")}</p>
            <p className="document-management__dialog__message__orderbased">{translate("#Core/archiveThisDocumentConfirmationMessage_4")}</p>
            <p>{translate("#Core/archiveThisDocumentConfirmationMessage_5")}</p>
            <h5>{translate("#Enterprise/LBL_FINAL_VOUCHER_NUMBER")} *</h5>
            <Input data-testid="dm-archive-confirmationDialog-voucher" value={archiveVoucher} onChange={e => setArchiveVoucher(e.target.value as string)} required={true}></Input>
            <h5>{translate("#Core/comment")} *</h5>
            <TextArea data-testid="dm-archive-confirmationDialog-comment" style={{ width: "100%" }} value={archiveComment} onChange={e => setArchiveComment(e.value.toString())} required={true} rows={5}></TextArea>
        </span>
        <DialogActionsBar>
            <button className="k-button" onClick={rejectArchive}>{translate("#Core/documentManagementCancel")}</button>
            <button className="k-button" data-testid="dm-archive-confirmationDialog-yes" onClick={confirmArchive} disabled={!(archiveComment?.length > 0 && archiveVoucher?.length > 0)}>{translate("#Core/documentManagementPermanentlyArchive")}</button>
        </DialogActionsBar>
    </Dialog>
    );
}
