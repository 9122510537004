/// <amd-module name="Core/Medius.Core.Web/Scripts/customTabs/definitions/contracts/service"/>
import * as rest from "Core/Medius.Core.Web/Scripts/Medius/core/fetch/rest";

const supplierFilterBasePath = `contractsforinvoices/supplierfilter`;
const companyFilterBasePath = `contractsforinvoices/companyfilter`;

export interface ICompanyDto {
    name: string;
}

export interface ISupplierDto {
    name: string;
}

export interface ICompanyFilterDto {
    id: number;
    name: string;
    nameWithIdAndIdentifiers: string;
}

export interface ISupplierFilterDto {
    id: number,
    name: string,
    nameWithIdAndIdentifiers: string;
}

export interface IContractDto {
    id: number,
    number: string,
    name: string,
    description: string,
    owner: string,
    category: string,
    active: boolean,
    validFrom: Date,
    validTo?: Date | null,
    companyDto: ICompanyDto,
    supplierDto: ISupplierDto,
    isAccessible: boolean
}

export const getContracts = async (companyId: number, supplierId: number, invoiceDate: Date, abortSignal?: AbortSignal): Promise<IContractDto[]> => {
    let requestParams: {companyId?: number, supplierId?: number, invoiceDate: string} = { invoiceDate: invoiceDate.toJSON()};
    if (companyId) {
        requestParams = {...requestParams, companyId };
    }

    if (supplierId) {
        requestParams = {...requestParams, supplierId };
    }

    return rest.get<IContractDto[]>("contractsforinvoices/contracts", {...requestParams, signal : abortSignal})
    .then((value:IContractDto[]) => {
        return value.map(x => {
            x.validFrom = resetTime(new Date(x.validFrom));
            if (x.validTo) {
                x.validTo = resetTime(new Date(x.validTo));
            } else {
                x.validTo = null;
            }
            
            return x;
        });
    });

};

export const getSuppliersWithPagingAsync = (invoiceSupplierId:number, companyId: number, skip: number, take: number, searchPhrase: string = '', abortSignal?: AbortSignal): Promise<{ suppliers: ISupplierFilterDto[], total: number }> => {
    const encodedSearchPhrase = encodeURIComponent(searchPhrase);
    return rest.get(`${supplierFilterBasePath}?invoiceSupplierId=${invoiceSupplierId}&companyId=${companyId ? companyId : ''}&skip=${skip}&take=${take}&searchPhrase=${encodedSearchPhrase}`, { signal: abortSignal });
};

export const getCompaniesAsync = (invoiceCompanyId: number, searchPhrase: string, abortSignal?: AbortSignal): Promise<ICompanyFilterDto[]> => {
    const encodedSearchPhrase = encodeURIComponent(searchPhrase);
    return rest.get(`${companyFilterBasePath}?invoiceCompanyId=${invoiceCompanyId}&searchPhrase=${encodedSearchPhrase}`, { signal: abortSignal });
};

export const getCompanyAsync = (companyId:  number, abortSignal?: AbortSignal): Promise<ICompanyFilterDto> => {
    return rest.get<ICompanyFilterDto>(`${companyFilterBasePath}/company`, { companyId, signal: abortSignal });
};

export const getSupplierAsync = (supplierId:  number, abortSignal?: AbortSignal): Promise<ISupplierFilterDto> => {
    return rest.get<ISupplierFilterDto>(`${supplierFilterBasePath}/supplier`, { supplierId, signal: abortSignal });
};

export const reclassify = (taskId: number, contractId: number) => {
    return rest.post("Backend/Rest/contractsforinvoices/reclassification", { taskId, contractId }, false);
};

function resetTime(dateTime: Date): Date {
    dateTime.setHours(0);
    dateTime.setMinutes(0);
    dateTime.setSeconds(0);
    dateTime.setMilliseconds(0);
    return dateTime;
}
