///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/editors/percentage/model"/>
import * as ko from "knockout";
import * as _ from "underscore";
import * as numbers from "Core/Medius.Core.Web/Scripts/Medius/components/editors/number/model";
import * as helpers from "Core/Medius.Core.Web/Scripts/Medius/components/editors/helpers";
import * as koSync from "Core/Medius.Core.Web/Scripts/Medius/knockout/observables/synchronization";
import * as koDisposal from "Core/Medius.Core.Web/Scripts/Medius/knockout/disposal";
import * as defaultsHolder from "Core/Medius.Core.Web/Scripts/Medius/components/editors/percentage/defaults";
import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";

class Percentage {
    dispose: () => void;
    Resolution: any;
    InputValue: any;
    params: any;
    constructor(bindingParameters:any, viewmodel:any, context:any) {
        const percent = ko.observable();
        let percentSync:any;
        let percentageUnwrapped:any;
        let percentageSubscription:any;

        function percentageSubscriptionsUpdate(percentageObject:any) {
            koDisposal.tryDispose(percentSync);

            if (!percentageObject) {
                return;
            }

            percentSync = koSync.twoWay(percentageObject.Percent, percent);
        }

        function synchronizePercentage(percentageObject:any) {
            if (percentageObject === percentageUnwrapped && !_.isUndefined(percentageObject)) {
                return;
            }

            percentageUnwrapped = percentageObject;
            percentageSubscriptionsUpdate(percentageObject);
        }

        const defaults:any = defaultsHolder.get();
        helpers.mergeDefaults(bindingParameters, defaults);
        
        const percentage = defaults.value;
        defaults.value = percent;

        if (ko.isObservable(percentage) && !_.isNull(percentage())) {
            percentageSubscription = percentage.subscribe(synchronizePercentage);
            synchronizePercentage(percentage());
        }
        else {
            defaults.disabled = true;
        }

        const numberModel = numbers.create(defaults, viewmodel, context, undefined);
        _(this).extend(numberModel);

        
        this.dispose = function () {
            koDisposal.tryDispose(percentSync);
            koDisposal.tryDispose(percentageSubscription);
            numberModel.dispose();
        };

        this.Resolution = this.params.resolution;

        this.InputValue.subscribe((value:number) => {
            if (isNullOrUndefined(value)) {
                percentage(value);
            }
            else if (_.isUndefined(percentage())) {
                percentage({
                    $type: "Medius.Core.DTOs.PercentageDto",
                    Percent: ko.observable(value)
                });
            }
        });
    }
}

export function create(bindingParameters:any, viewmodel:any, context:any) {
    return new Percentage(bindingParameters, viewmodel, context);
}