///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/documentSearch/actions/notification/steps/form"/>
import * as _ from "underscore";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { WizardStep } from "Core/Medius.Core.Web/Scripts/Medius/components/wizard/steps/wizardStep";
import { isEmptyString } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import * as ko from "knockout";

class FormNotificationWizardStep extends WizardStep<Notification> {
    buttonNextLabel = globalization.getLabelTranslation("#Core/sendEmail");
    documentNbTypeInfo: ko.Observable<any>;
    textMessage = ko.observable(globalization.getLabelTranslation("#Core/notificationDefaultMessage"));
    textSubject: ko.Observable<any>;
    textLinksInfo: ko.Observable<any>;
    constructor(wizard: { documentTypeName: any; documentCount: any; }) {
        super(globalization.getLabelTranslation("#Core/notificationEditSendStep"),
        "Core:templates/DocumentSearch/Notification/Form.html");
        const documentTypeName = wizard.documentTypeName;
        const documentCount = wizard.documentCount;

        this.documentNbTypeInfo = ko.observable(globalization.getFormattedLabelTranslation("#Core/notificationDocumentsInfo_type",
            [documentCount, documentTypeName]));
        this.textSubject = ko.observable(globalization.getFormattedLabelTranslation("#Core/notificationDefaultSubject_docTypeName",
            [documentTypeName]));
        this.textLinksInfo = ko.observable(globalization.getFormattedLabelTranslation("#Core/notificationDocumentsListSection_docType",
            [documentTypeName]));
    }

    validate(wizard: { data: any; }) {
        const message = this.textMessage();
        const subject = this.textSubject();
        
        if (isEmptyString(message)) {
            return false;
        }

        _.extend(wizard.data, {
            message: message,
            subject: subject
        });

        return true;
    }

}

export function create(wizard: { documentTypeName: any; documentCount: any; }) {
    return new FormNotificationWizardStep(wizard);
}