/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/AuthorizationGroups/Tabs/hooks/useClickOutside"/>
import { useEffect, useRef, MutableRefObject } from "react";

type CallbackWithSideEffects = (e: any) => void;
export function useClickOutside(elRef: any, callback: CallbackWithSideEffects) {
    const callbackRef = useRef<CallbackWithSideEffects>(null);
    (callbackRef as MutableRefObject<CallbackWithSideEffects>).current = callback;

    useEffect(() => {
        const handleClickOutside = (e:any) => {
            if (!elRef?.current?.contains(e.target) && callbackRef.current) {
                callbackRef.current(e);
            }
        };

        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, [callbackRef, elRef]);
}
