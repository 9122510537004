/// <amd-module name="Core/Medius.Core.Web/Scripts/components/controls/inputs"/>
import * as React from "react";
import { translate } from "../../lib/globalization";
import { Switch, SwitchChangeEvent } from "@progress/kendo-react-inputs";

type InputProps = {
    readonly value: any;
    readonly onChange: (e: SwitchChangeEvent) => void;
};

export const ActiveSwitch = ({ value, onChange }: InputProps) =>
    (<>
        <div>{translate("#Core/active")}</div>
        <Switch
            onLabel={translate("#Core/yes")}
            offLabel={translate("#Core/no")}
            checked={value}
            onChange={onChange}/>
    </>);