/// <amd-module name="Core/Medius.Core.Web/Scripts/lib/workflowBlockingOperation"/>
import * as ko from "knockout";

const pendingOperationsSemaphore = ko.observable(0);

export const isWorkflowBlockingOperationInProgress = ko.pureComputed(() => pendingOperationsSemaphore() !== 0);

export function workflowBlockingOperation<T>(action: any): any {    
    pendingOperationsSemaphore(pendingOperationsSemaphore() + 1);
    if (action.always) {
        // Support for legacy jQuery promises
        return action.always(() => {
            pendingOperationsSemaphore(pendingOperationsSemaphore() - 1);
        });
    } else {
        // ES6 promises
        return action.then(
            () => pendingOperationsSemaphore(pendingOperationsSemaphore() - 1),
            () => pendingOperationsSemaphore(pendingOperationsSemaphore() - 1));
    }
}

export function executeAfterBlockingOperationsEnd(action: () => void): void {
    if (isWorkflowBlockingOperationInProgress()) {
        const isWorkflowBlockingOpInProgressSub = isWorkflowBlockingOperationInProgress.subscribe(value => {
            if (value === false) {
                action();
                isWorkflowBlockingOpInProgressSub.dispose();
            }
        });
    } else {
        action();
    }
}
