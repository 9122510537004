/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/models/userSettings/userSettings"/>
import {observable, observableArray} from "knockout";

class UserSettings {
    public CurrentUser = observable<any>();
    public isReadOnly = { readonly: "" };
    public currentPassword = observable<string>();
    public newPassword = observable<string>();
    public retypedPassword = observable<string>();
    public TimeZonesList = observableArray<any>([]);
    public FormatsList = observableArray<any>([]);
    public LanguagesList = observableArray<any>([]);

    public DataSearchUserConfiguration = observable<any>();
    public sampleText: string;

    constructor() {}

    public reset() {        
        this.CurrentUser(null);
        this.currentPassword(null);
        this.newPassword(null);
        this.retypedPassword(null);
    }
}

export = new UserSettings();
