/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/extensionPointsRegistry"/>
const extensionPointsRegistry: { [entityType: string]: AdminPagesExtensionPoint } = {};

export function register(entityType: string, customizations: AdminPagesExtensionPoint) {
    extensionPointsRegistry[entityType] = customizations;
}

export function hasCustomRightSide(entityType: string): boolean {
    const extension = extensionPointsRegistry[entityType];
    return extension && typeof extension.rightSide === "function";
}

export function getCustomRightSide(entityType: string): AdminPagesRightSide {
    return extensionPointsRegistry[entityType].rightSide();
}
