/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rpc"/>
import * as _ from "underscore";
import { ajax } from "Core/Medius.Core.Web/Scripts/Medius/core/rpc";
import { getDefaultOptions, toJSON, deserializeResponse } from "./helpers";
import { pipeApply } from "Core/Medius.Core.Web/Scripts/Medius/lib/deferred";

export function request<T = any>(
    service: string,
    resource: string,
    data?: any,
    options?: any
): JQueryPromise<T> {
    options = _(getDefaultOptions()).extend(options || {});

    if (data) {
        options.data = toJSON(data);
    }

    const requestResult = ajax(service, resource, options).pipe(
        deserializeResponse
    );

    return pipeApply(requestResult, { includeFail: true });
}

export function lightApi(
    service: string,
    resource: string,
    data?: any,
    options?: any
) {
    return request(`lightApi/${service}`, resource, data, options);
}
