/// <amd-module name="Core/Medius.Core.Web/Scripts/components/procurement/currentCompany/useCurrentCompany"/>

import { useAppSelector } from "Core/Medius.Core.Web/Scripts/shared/store/hooks";
import { currentCompanySelector, currentCompanyConfigurationSelector, currentProcurementConfigurationSelector } from "Core/Medius.Core.Web/Scripts/components/procurement/currentCompany/currentCompanySlice";
import { useMemo } from "react";

export function useCurrentCompany() {
    const currentCompany = useAppSelector(currentCompanySelector);
    const currentCompanyId = currentCompany?.id;
    const currentCompanyConfiguration = useAppSelector(currentCompanyConfigurationSelector);    
    const currentProcurementConfiguration = useAppSelector(currentProcurementConfigurationSelector);
    return useMemo(() => ({
        currentCompany,
        currentCompanyId,
        currentCompanyConfiguration,
        currentProcurementConfiguration
    }), [currentCompany, currentCompanyId, currentCompanyConfiguration, currentProcurementConfiguration]);
}
