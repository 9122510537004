/// <amd-module name="Core/Medius.Core.Web/Scripts/Models/FinanceComponentDto/unitpriceFactory"/>
import { observable } from "knockout";

const unitPriceType = "Medius.Core.DTOs.UnitPriceDto, Medius.Core.Common";

class UnitPrice {
    public $type = unitPriceType;

    CurrencyCode: ko.Observable<any>;
    DisplayValue: ko.Observable<number>;
    constructor(data: { CurrencyCode: any; DisplayValue: any; }) {
        this.CurrencyCode = observable(data.CurrencyCode);
        this.DisplayValue = observable(parseFloat(data.DisplayValue));
    }

    toJS() {
        const value = this.DisplayValue();

        return {
            $type: this.$type,
            CurrencyCode: this.CurrencyCode(),
            DisplayValue: value && value.toString()
        };
    }

    update(data: { CurrencyCode: any; DisplayValue: string; }) {
        if (this.CurrencyCode() !== data.CurrencyCode)
            this.CurrencyCode(data.CurrencyCode);

        const unitPriceValue = parseFloat(data.DisplayValue);

        if (this.DisplayValue() !== unitPriceValue)
            this.DisplayValue(unitPriceValue);
    }
}

export function create(data: { CurrencyCode: any; DisplayValue: any; }) {
    return new UnitPrice(data);
}

export function creatingType() {
    return unitPriceType;
}

export function zero(currencyCode: any) {
    if (currencyCode === null || currencyCode === undefined) {
        throw new Error("Currency code is required.");
    }
    return new UnitPrice({
        CurrencyCode: currencyCode,
        DisplayValue: 0
    });
}

export function instanceOf(vm: any) {
    return vm instanceof UnitPrice;
}
