///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/administration/bulk/bulkAdminResult"/>
import * as ko from "knockout";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as _ from "underscore";
import { formatValueWithPlaceholders } from "../../../lib/stringFormat";

class BulkAdminResult{
    successes: any;
    failures: any;
    hasFailures: boolean;
    successTitle: ko.Observable<any>;
    failTitle: ko.Observable<any>;
    successesExpanded: ko.Observable<boolean>;
    failuresExpanded: ko.Observable<boolean>;
    toggleSuccesses: () => void;
    toggleFailures: () => void;

    constructor(successes: any, failures: string | any[]){
        this.successes = successes;
        this.failures = failures;
        this.hasFailures = failures.length > 0;
        
        this.successTitle = ko.observable(globalization.getLabelTranslation("#Core/successfullyHandledBulkUpdate"));
        this.failTitle = ko.observable(globalization.getLabelTranslation("#Core/failedBulkUpdate"));

        this.successesExpanded = ko.observable(false);
        this.failuresExpanded = ko.observable(this.hasFailures);

        this.toggleSuccesses = function () {
            const expanded = this.successesExpanded();
            this.successesExpanded(!expanded);
        };

        this.toggleFailures = function () {                 
            const expanded = this.failuresExpanded();
            this.failuresExpanded(!expanded);
        };
    }
}

export function createFromActionSuccess(bulkOperationResult: { results: any; }) {
    let successes: any[] = [],
        failures: any[] = [];

            _(bulkOperationResult.results).each((result) => {
                let messages;

                if (result.result === 0) {
                    if (result.validationResults) {
                        messages = _(result.validationResults).map((m) => {
                            let message, params;
                            message = m.validationMessage.message;
                            params = m.validationMessage.messageParams;
                            return formatValueWithPlaceholders(message, [params]);
                        });
                    } else {
                        messages = result.detailsMsg;
                    }
                    failures.push({
                        linkText: result.details.presentation,
                        validationMessages: [messages]
                    });
                } else {
                    successes.push({
                        linkText: result.details.presentation
                    });
                }
            });

            return new BulkAdminResult(successes, failures);
};

export function createFromActionFailure(bulkOperationResult:any) {
    let failures = [];
    
    failures.push({
        linkText: globalization.getLabelTranslation("#Core/serviceOperationFailed"),
        validationMessages: [bulkOperationResult.statusText]
    });
    
    return new BulkAdminResult([], failures);
};