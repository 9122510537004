/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/AuthorizationGroups/limit"/>
import { Checkbox, CheckboxChangeEvent, NumericTextBox, NumericTextBoxChangeEvent, NumericTextBoxHandle } from "@progress/kendo-react-inputs";
import { Popup } from "@progress/kendo-react-popup";
import * as React from "react";
import { useRef, useState } from "react";
import { translate } from "../../lib/globalization";
import { AuthorizationLimit, LimitType } from "./authorizationGroup";

export const enum ValidationType { Positive, Negative }

export type LimitInputProps = {
    readonly label?: string;
    readonly unlimitedLabel: string;
    readonly limit: AuthorizationLimit;
    readonly type: ValidationType;
    readonly allowUnlimited?: boolean;
    readonly allowLimit?: boolean;
    readonly validationMessage?: string;
    readonly onChange: (limit: AuthorizationLimit) => void;
};

type Tooltip = { open: boolean, message: string };

export const Limit = ({ label = "", unlimitedLabel, limit, type, allowUnlimited = true, allowLimit = true, validationMessage = undefined, onChange }: LimitInputProps) => {
    const anchor = useRef<HTMLSpanElement>(null);
    const popupContainer = useRef<HTMLDivElement>(null);
    const [tooltip, setTooltip] = useState<Tooltip>({ open: false, message: "" });

    const getLimitType = (value?: number | null) => {
        return value != null
            ? LimitType.Limited
            : LimitType.Undefined;
    };

    const handleLimitChange = (e: NumericTextBoxChangeEvent) => {
        const newLimit = { 
            Type: getLimitType(e.value), 
            Value: { ...limit.Value, DisplayValue: e.value } 
        } as AuthorizationLimit;
        onChange(newLimit);
    };
    const handleUnlimitedChange = (e: CheckboxChangeEvent) => {
        const newLimit = { ...limit, Type: e.value == true ? LimitType.Unlimited : getLimitType(limit.Value?.DisplayValue) };
        onChange(newLimit);
    };

    const defaultValidationMessage = type === ValidationType.Positive 
        ? translate("#Core/valueMustBeNonNegative") 
        : translate("#Core/valueMustBeNonPositive");

    const isUnlimited = limit?.Type === LimitType.Unlimited;
    
    const shouldBePositive = type === ValidationType.Positive;
    const isValid = (value: string) => isUnlimited 
        ? true
        : shouldBePositive 
            ? Number(value) === 0 || value.charAt(0) !== '-' 
            : Number(value) === 0 || value.charAt(0) === '-';

    const numericTextBoxRef = (handle: NumericTextBoxHandle) => {
        if(!handle?.element) return;
        
        (anchor as React.MutableRefObject<HTMLSpanElement>).current = handle.element;
        const element = handle.element;
        const rawValue = element.value as string;
        const shouldDisplay = rawValue && rawValue.length > 0 
            ? (limit?.Type === LimitType.Limited && !isValid(rawValue))
            : false;

        setTooltip(prev => prev.open === shouldDisplay
            ? prev 
            : { 
                open: shouldDisplay, 
                message: shouldDisplay ? (validationMessage ?? defaultValidationMessage) : ""
            });
    };    

    return (<>
        <div ref={popupContainer} className="limitContainer">
            {allowLimit &&
                <div className="limitValueContainer">
                    <NumericTextBox
                        ref={numericTextBoxRef}
                        name="limitInput"
                        label={label}
                        format="n2"
                        spinners={false}
                        min={type == ValidationType.Positive ? 0 : undefined}
                        max={type == ValidationType.Negative ? 0 : undefined}
                        value={isUnlimited ? undefined : limit.Value?.DisplayValue}
                        disabled={isUnlimited}
                        onChange={handleLimitChange} />
                    <Popup anchor={anchor.current} show={tooltip.open} popupClass="limitTooltip"
                        anchorAlign={{ horizontal: "center", vertical: "bottom" }}
                        popupAlign={{ horizontal: "center", vertical: "top" }}
                        appendTo={popupContainer.current}>
                        <span className="tooltipText">{tooltip.message}</span>
                    </Popup>
                </div>}
            {allowUnlimited &&
                <div className="checkBoxContainter">
                    <Checkbox
                        label={unlimitedLabel}
                        value={isUnlimited}                        
                        onChange={handleUnlimitedChange}
                        data-testid="unlimited-checkbox"
                    />
                </div>}
        </div>
    </>);
};
