///<amd-module name = "Core/Medius.Core.Web/Scripts/Models/Medius.Core.Entities.ReminderDetails"/>
import * as ko from "knockout";
import * as _ from "underscore";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import mappings = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/mapping/collection/instance");
import * as type from "Core/Medius.Core.Web/Scripts/Medius/core/type";
import * as typeResolver from "Core/Medius.Core.Web/Scripts/Medius/components/resolver/type";


const excludedProperties = [
    "Id",
    "$type",
    "SystemGenerated",
    "EntityVersion",
    "IsDeleted",
    "ReferencedFilesCount",
    "ViewId",
    "CreatedTimestamp"
];

class ConfigurableProperty {
    Label: any;
    Name: string;
    ControlId: string;
    Value: any;
    PropertyType: any;
    constructor(entityType:any, name:string, value:any){
        const propertyType = typeResolver.getPropertyType(entityType, name);

        this.Label = globalization.getPropertyTranslation(`#${entityType}/${name}`) || name;
        this.Name = name;
        this.ControlId = ["control", name].join("-");
        this.Value = value;
        this.PropertyType = propertyType;
    }
}

export function extender(vm:any/*, data, context*/) {
    const propertiesList: ConfigurableProperty[] = [];

    vm.EntityType = type.getTypeName(vm.$type());
    vm.ConfigurableProperties = ko.observableArray([]);

    const properties = _.keys(vm);

    _.each(properties, function (propertyName) {
        const property = vm[propertyName];

        if (ko.isWriteableObservable(property) && !_.contains(excludedProperties, propertyName) &&
                !_.isArray(property())) {
            propertiesList.push(new ConfigurableProperty(vm.EntityType, propertyName, property));
        }

    });

    vm.ConfigurableProperties(propertiesList);
    vm.getShortDescription = ko.observable("?");
}

export function register() {
    mappings.register("Medius.Core.Entities.ReminderDetails", extender);
}