///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/inbox/bulk/defaultModal"/>
import { BulkModalFactory } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/bulk/bulkModalFactory";
import { Modal } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/bulk/modal";
import { IStep} from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/bulk/step";

import { Choose } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/bulk/steps/choose";
import { Operation } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/bulk/steps/operation";
import { Confirm } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/bulk/steps/confirm";
import { Result } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/bulk/steps/result";

export class DefaultModal extends Modal{
    constructor(tasks: any[], options: any) {
        const template = "Core:Medius/apps/inbox/bulk/templates/defaultModal.html";

        let chosenAction: ko.Computed<any>;
        const chooseAction = (action: any) => chosenAction = action;

        const steps = [
            new Choose(tasks, chooseAction, options.selectedDocumentsWithRiskFactorsCount),
            new Operation(tasks, chosenAction),
            new Confirm(),
            new Result()
        ] as IStep[];

        super(tasks, options, steps, template);
    }
}

export function register() {
    BulkModalFactory.getInstance().registerDefault(DefaultModal);    
}
