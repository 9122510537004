/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/spaManager/applicationsRegister" />
import * as spaInbox from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/spa";
import * as reactSpa from "Core/Medius.Core.Web/Scripts/Medius/apps/reactSpa/spa";
import * as spaAdministration from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/spa";
import * as spaNewDocument from "Core/Medius.Core.Web/Scripts/Medius/apps/document/spa";
import * as spaDashboard from "Core/Medius.Core.Web/Scripts/Medius/apps/dashboard/spa";
import * as spaDocumentSearch from "Core/Medius.Core.Web/Scripts/Medius/apps/documentSearch/spa";

const applicationsCollection: any[] = [
    spaAdministration,
    spaInbox,
    spaDashboard,
    spaDocumentSearch,
    spaNewDocument,
    reactSpa
];

export function registerApp(application: any) {
    applicationsCollection.push(application);
}

export function getApps() {
    return applicationsCollection.slice();
}

