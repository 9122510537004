/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/metadata"/>

class Metadata {
    public attributes: any = {};
    constructor(public data: any) {}
}

export function create(data: any) {
    return new Metadata(data);
}

export function instanceOf(metadata: any) {
    return metadata instanceof Metadata;
}