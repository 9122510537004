/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/kendo/bindings/kendoDatePicker" />

import { KendoUtils } from "Core/Medius.Core.Web/Scripts/Medius/kendo/kendoUtils";
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";

interface IKendoDatePickerOptions {

    value: ko.Observable<Date>;
    validator: ko.Observable<KendoValidator>;
}

class KendoDatePicker {
    private static readonly widgetName = "kendoDatePicker";

    public static registerKnockoutBinding(): void {
        koUtils.registerBinding(
            KendoDatePicker.widgetName, {
                init(element: Element, valueAccessor: any) {
                    const $element = $(element);

                    KendoUtils.ensureValidElement(KendoDatePicker.widgetName, "input", $element);
                    KendoDatePicker.create($element, valueAccessor());
                    KendoUtils.registerDisposeFor($element, KendoDatePicker.widgetName);
                },
                update(element: Element, valueAccessor: any) {
                    const options = valueAccessor() as IKendoDatePickerOptions;
                    const $element = $(element); 

                    const selectedValue = options.value();

                    let validator: KendoValidator;
                    if (valueAccessor().validator) {
                        validator = options.validator() as KendoValidator;
                    }

                    KendoDatePicker.validate($element, selectedValue, validator);
                    $element.data(KendoDatePicker.widgetName)
                        .value(selectedValue);
                }
            });
    }

    private static create($element: JQuery, params: IKendoDatePickerOptions) {
        if (params.validator) {
            params.validator({ validate: () => KendoDatePicker.validate($element, params.value(), params.validator()) });
        }

        const options: kendo.ui.TimePickerOptions = {
            change: (e: kendo.ui.TimePickerChangeEvent) => {
                const selectedValue = e.sender.value();
                KendoDatePicker.validate($element, selectedValue, params.validator());
                params.value(selectedValue);
            }
        };
        
        KendoUtils.dispose($element, KendoDatePicker.widgetName);
        $element.kendoDatePicker(options);
    }

    private static validate(element: JQuery, value: Date, validator: KendoValidator): boolean {
        if (!validator) {
            return true;
        }
            
        const datePicker = KendoDatePicker.getDatePickerElement(element);
        if (value) {
            datePicker.removeClass("invalid");
            return true;
        } else {
            datePicker.addClass("invalid");
            return false;
        }
    }

    private static getDatePickerElement(element: JQuery): JQuery {
        const datePicker = element.parent();
        if (datePicker.length === 0) {
            throw new Error("Could not find Kendo date picker.");
        }
        return datePicker;
    }
}

export function register() {
    KendoDatePicker.registerKnockoutBinding();
}
