/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/inbox/bulk/steps/confirm"/>
const globalization = require("Core/Medius.Core.Web/Scripts/lib/globalization");

import { Step } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/bulk/step";

export class Confirm extends Step {
    public question: string;

    constructor() {
        super(globalization.getLabelTranslation("#Core/confirm"),
            "Core:Medius/apps/inbox/bulk/templates/confirm.html");
            
        this.question = globalization.getLabelTranslation("#Core/areYouSureToPerformOnSelectedDocuments");
        this.isButtonYesVisible(true);
        this.isNextStepEnable(true);
    }
}
