///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/documentSearch/metadataColumns"/>
import * as ko from "knockout";
import * as rest from "Core/Medius.Core.Web/Scripts/Medius/core/rest";
import * as _ from "underscore";
import * as typeResolver from "Core/Medius.Core.Web/Scripts/Medius/components/resolver/type"; 

export const availableMetadataColumns = ko.observableArray([]);

export function loadAvailableMetadataColumns() {
    return rest.get("DataSearchManager", "availableMetadata").done((columns: any[]) => {
        availableMetadataColumns(columns);
    });
}

export function getMetadataColumnsForType(entityType: any) {
    if (!entityType || availableMetadataColumns().length === 0) {
        return [];
    }

    return _(availableMetadataColumns()).filter((metadataColumnInfo) => {
        return _.any(metadataColumnInfo.SupportedTypes, (supportedType) => {
            return typeResolver.isSameClassOrSubclass(entityType, supportedType);
        });
    });
}