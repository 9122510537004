/// <amd-module name="Core/Medius.Core.Web/Scripts/components/invoice/reasonCodes/components/selectAvailableReasonCodes"/>
import * as React from "react";
import { useState } from "react";
import { filterBy } from "@progress/kendo-data-query";
import { ComboBox, ComboBoxFilterChangeEvent } from "@progress/kendo-react-dropdowns";
import { ReasonCode } from "../types";
import { LocalizationProvider } from "@progress/kendo-react-intl";

type AvailableReasonCodesSelectProps = {
    readonly values: ReasonCode[];
    readonly onSelected: (reasonCodeId: number) => void;
};

type AvailableReasonCode = {
    readonly id: number;
    readonly displayValue: string;
};

export function SelectAvailableReasonCodes(props: AvailableReasonCodesSelectProps) {
    const {values, onSelected} = props;

    const [reasonCodes] = useState<AvailableReasonCode[]>(values
        .sort((a, b) => a.displayValue.localeCompare(b.displayValue))
    );

    const [state, setState] = useState<{selected: AvailableReasonCode | null, available: AvailableReasonCode[]}>({
        selected: null,
        available: reasonCodes
    });

    const onChange = ({value: reasonCode}: {value: AvailableReasonCode}) => {
        setState(x => ({
            ...x, selected: reasonCode
        }));
        onSelected(reasonCode?.id);
    };

    const onFilterChange = (event: ComboBoxFilterChangeEvent) =>
        setState(x => ({
            ...x, available: filterBy(reasonCodes.slice(), event.filter)
        }));

    return (
        <span data-testid="select-available-reason-codes">
            <LocalizationProvider
                language="current-language">
                <ComboBox
                    data={state.available}
                    value={state.selected}
                    onChange={onChange}
                    dataItemKey="id"
                    textField="displayValue"
                    filterable
                    onFilterChange={onFilterChange}
                    suggest
                    style={{ width: "99%" }} />
            </LocalizationProvider>
        </span>
    );
}
