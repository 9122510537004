///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/transformers/create/objectBuilder"/>
import * as defaults from "Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/transformers/create/defaults";
import * as utils from "Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/transformers/create/utils";
import { isArray } from "Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/helpers";

export = function objectViewmodelBuilder(input:any, mapping:any, context:any) {
    let viewmodel:any = null;
    const properties:any[] = [];
    let prototype:any;

    if (mapping.prototype) {
        prototype = mapping.prototype(input, context);
    }

    if (mapping.newObject) {
        viewmodel = mapping.newObject(input, prototype, context);
    }

    if (!viewmodel) {
        viewmodel = isArray(input) ? [] : {};
    }
    
    if (mapping.initializeContext) {
        mapping.initializeContext(context, input);
    }

    return {
        ...defaults,
        shouldDescend: true,
        construct: function () {
            if (mapping.construct) {
                mapping.construct(viewmodel, input, context);
            }
        },
        mapProperty: function (name:string, value:any) {
            viewmodel[name] = value;
            properties.push(name);
        },
        finalize: function () {
            utils.finalizeViewmodel(viewmodel, input, mapping, context, properties);
            return viewmodel;
        }  
    };
};