/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/collection" />
import * as _ from "underscore";

type Resolver = any;

let resolvers: Resolver[] = [];

export function register(resolver: Resolver) {
    resolvers = resolvers.concat([resolver]).sort((leftResolver, rightResolver) => {
        // this is in if form to be able to handle near-overflow values, since
        // some priorities are INT_MAX / INT_MIN values and adding to or removing
        // from them causes overflow
        const l = leftResolver.priority,
            r = rightResolver.priority;
        return (l < r) ?
            -1 :
            l > r ?
                1 :
                0;
    });
}

export function getFor(data: any, typeOverride: any) {
    return _(resolvers).filter((r) => r.condition(data, typeOverride));
}