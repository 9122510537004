/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/default/longText/entity/document"/>
import {unpack} from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as typeBoundResolverFactory from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/typeBound";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";

export const resolver = typeBoundResolverFactory.create({
    priority: 0,

    type: "Medius.Data.Document",

    resolve: function (data: any) {
        const id = unpack(data.Id);
        const type = unpack(data.$type).split(',')[0];
        const translatedType = globalization.getPropertyTranslation(`#${type}`);

        return {
            longText: `${translatedType} ${id}`
        };
    }
});
