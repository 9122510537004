/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/localizer/cultureLocalizer"/>
import { computed, isObservable } from "knockout";
import * as localizer from "Core/Medius.Core.Web/Scripts/Medius/components/localizer/localizer";

export class CultureLocalizer {
    getLocalizedObservable(observable: any, typeLocalizer?: any, options?: any) {

        // We want to track the updates of original observable,
        // therefore we need to return a computed depending on it.
        return computed(() => {
            return this.getLocalizedBaseValue(observable(), typeLocalizer, options);
        });
    }

    getLocalizedBaseValue(value: any, typeLocalizer?: any, options?: any) {
        if (typeLocalizer) {
            switch (typeof typeLocalizer) {
                case "object":
                    return typeLocalizer.toString(value, options);

                case "function":
                    return typeLocalizer(value, options);
            }
        }

        throw new Error("Unknown type of 'typeLocalizer': " + typeof typeLocalizer);
    }

    getLocalizedValue(value: any, type?: any, options?: any) {
        const typeLocalizer = localizer.resolve(type);

        if (!typeLocalizer)
            return value;

        options = options || {};
        return (isObservable(value))
            ? this.getLocalizedObservable(value, typeLocalizer, options)
            : this.getLocalizedBaseValue(value, typeLocalizer, options);
    }
}
