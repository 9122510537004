/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/helpers/property"/>

import * as typeResolver from "Core/Medius.Core.Web/Scripts/Medius/components/resolver/type";
import * as translationsProvider from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/provider/translations";
import { isEmptyString } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import { getPropertyType } from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/helpers/propertyType";


export function isCorrectProperty(entityType: string, lexeme: string) {
    
    const property = translationsProvider.resolvePropertyFromTranslation(entityType, lexeme);
    const propertyType = getPropertyType(entityType, property);

    if (isEmptyString(propertyType)) {
        return false;
    }

    return true;
}

export function isCorrectOrderingProperty(entityType: string, property: string) {
    return isCorrectProperty(entityType, property) && !typeResolver.containsCollection(entityType, property);
}
