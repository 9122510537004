/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/default/link/dataDefinition"/>
import {unpack} from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as type from "Core/Medius.Core.Web/Scripts/Medius/core/type";
import * as typeBoundResolverFactory from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/typeBound";
import * as pathLib from "Core/Medius.Core.Web/Scripts/Medius/lib/path";

export const resolver = typeBoundResolverFactory.create({
    priority: typeBoundResolverFactory.priority.lowest,
    type: "Medius.Data.DataDefinition",
    resolve: function(data: any) {
        const entityType = type.getTypeName(unpack(data.$type)),
            entityId = unpack(data.Id);

        return {
            link: pathLib.getBasePath() + ["#Administration", entityType, entityId, "entity-link"].join("/")
        };
    }
});
