///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/lib/entityBackup/entity"/>

import * as objectBackup from "Core/Medius.Core.Web/Scripts/Medius/lib/entityBackup/backup";

const propertiesToSkip = [
    "Id",
    "ViewId",
    "EntityVersion",
    "CreatedTimestamp",
    "HashFiles",
    "IsDeleted",
    "ReferencedFilesCount",
    "$type"
];

export function create(entity: any) {
    return objectBackup.create(entity, propertiesToSkip);
}
