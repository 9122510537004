/// <amd-module name="Core/Medius.Core.Web/Scripts/components/topBar/documentTypesState"/>

export interface DocumentType {
    readonly type: string;
    readonly nameTranslationKey?: string;
}

export interface DocumentTypes {
    readonly documentTypesForCreate: DocumentType[],
    readonly documentTypesForRead: DocumentType[]
}

export const DocumentTypesLoadedAction = "DOCUMENT_TYPES_LOADED";

export function documentTypesReducer(state: DocumentTypes = {} as DocumentTypes, action: { type: string; payload: any }): DocumentTypes {
    switch (action.type) {
        case DocumentTypesLoadedAction:
            return { ...action.payload };
        default:
            return state;
    }
}

export function setDocumentTypes(documentTypes: DocumentTypes) {
    return {
        type: DocumentTypesLoadedAction,
        payload: documentTypes
    };
}
