/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/inbox/search/fields" />

import * as ko from "knockout";
import { isEmptyString } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import { load } from "Core/Medius.Core.Web/Scripts/Medius/core/sync";
import * as typeResolver from "Core/Medius.Core.Web/Scripts/Medius/components/resolver/type";
const idPrefix = "inbox-advanced-search";
const DATETIME = "System.DateTime";

function createId(args: any) {
    return args.join("-");
}

export class Input {
    Id: any;
    ClearId: any;
    Placeholder: any;
    Value: any;
    Label: any;

    constructor(name: any, placeholder: any, value: any, id?: any) {
        this.Id = id || null;
        this.ClearId = createId([id, "clear"]) || null;
        this.Placeholder = placeholder || null;
        this.Value = ko.observable(value || null);
        this.Label = name || null;
    }
}

export class Field {
    Label: any;
    Property: any;
    Type: any;
    Values: any;
    Value: any;

    constructor (label: any = null, entityType: any, property: any = null, type: any = null, fieldFrom?: any, fieldTo?: any) {
        this.Label = label;
        this.Property = property;
        this.Type = type;

        const prefix = createId([idPrefix, entityType, this.Property]);
        const fields = this.resolveFields(this.Type, prefix, fieldFrom, fieldTo, this.Label);
        this.Values = fields;
    }

    updateField(fromValue: any, toValue: any = null) {        
        const fieldFrom = this.Values[0];
        const fieldTo = this.Values[1];

        this.updateFieldInput(fieldFrom, fromValue);
        this.updateFieldInput(fieldTo, toValue);
    }

    updateFieldInput(fieldInput: any, value: any) {
        if (!fieldInput || isEmptyString(value)) {
            return;
        }

        if (typeResolver.isEntity(this.Type)) {
            load(this.Type, value).done(function (entity: any) {
                fieldInput.Value(entity);
            });
        }
        else if (this.Type === DATETIME) {
            fieldInput.Value(new Date(value));
        }
        else {
            fieldInput.Value(value);
        }
    }
    
    resolveFields(type: any, prefixId: any, from: any, to: any, labelFiled: any) {
        if (type === DATETIME) {
            const labelFrom = labelFiled + "From";
            const labelTo = labelFiled + "To";

            const fieldFrom = from || new Input(labelFrom, "From", null, createId([prefixId, "From"]));
            const fieldTo = to || new Input(labelTo, "To", null, createId([prefixId, "To"]));
            return [fieldFrom, fieldTo];
        }
        return [
            from || new Input(labelFiled, null, null, createId([prefixId]))
        ];
    }

    dispose() {
        if(!!this.Value) this.Value.dispose();
    }
}

export class DocumentField extends Field {
    Id: any;
    InboxFilterConditionName: any; //possibly string

    constructor (label: any, entityType: any, property: any, configId: number, type: any, conditionFieldName: any, fieldFrom?: any, fieldTo?: any) {        
        super(label, entityType, property, type, fieldFrom, fieldTo);
        
        if (!!conditionFieldName) this.InboxFilterConditionName = conditionFieldName;
        this.Id = configId;
    }
}