///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Entities.Settings.AdministrationFavouriteLink/EditorForType"/>
import * as _ from "underscore";
function isMasterData(type:any) {
    return _(type.Hierarchy).contains("Medius.Data.DataDefinition");
}
export = function(entityWithContext:any, companyContextId:number, tabModel:any) {
    return {
        ForType: tabModel.EntityWithContext.Entity.ForType,
        isMasterData: isMasterData
    };
};