/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/lib/store/local"/>
import { isNotNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";

class LocalStorage {
    public setItem(key: string, value: any) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    public getItem(key: string) {
        try {
            return JSON.parse(localStorage.getItem(key));
        } catch (e) {
            return null;
        }
    }

    public hasItem(key: string) {
        return isNotNullOrUndefined(localStorage.getItem(key));
    }

    public removeItem(key: string) {
        localStorage.removeItem(key);
    }
}

export const localStorageInstance = new LocalStorage();

export type ILocalStorage = InstanceType<typeof LocalStorage>;