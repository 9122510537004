/// <amd-module name="Core/Medius.Core.Web/Scripts/lib/formatting"/>
import { unpack, unpackAll } from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as ko from "knockout";

export function formatItem(name: ko.Observable<string> | string, description: ko.Observable<string> | string) {
    name = unpack(name);
    description = unpack(description);

    if(!description) {
        return name;
    }
    return name + " (" + description + ")";
}

export function getItemName(line:any) {
    const idInfoStartIndex = line.lastIndexOf("(");
    if (idInfoStartIndex >= 0) {
        return line.substring(0, idInfoStartIndex).trim();
    } else {
        return line.trim();
    }
}

export function formatUser(userEntityVm : any) {
    const user = unpackAll(userEntityVm);
    return formatItem(user.FirstName + ' ' + user.LastName, user.UserName);
}
