///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Entities.Dashboard.UserDashboard/userDashboardTab"/>
import { observable, Observable, unwrap } from "knockout";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { isNotNullOrUndefined, isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import * as _ from "underscore";
import { error } from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import { getNew } from "Core/Medius.Core.Web/Scripts/Medius/core/sync";

class UserDashboardTab {
    User = observable();
    UserDashboards: any;
    Dashboard: Observable<any>;
    constructor(entityWithContext: any) {
        const userDashboards = entityWithContext.RelatedObjects["Medius.Core.Entities.Dashboard.UserDashboard"].RelatedEntities;

        // Bindings
        this.UserDashboards = userDashboards;
        this.Dashboard = observable(entityWithContext.Entity);
    }

    removeRow(rowToRemove: any) {
        this.UserDashboards.remove(rowToRemove);
    }

    addRow() {
        if (isNullOrUndefined(this.User())) {
            const errorMessage = getLabelTranslation("#Core/notifyUserMustBeSelected");
            error(errorMessage);
            return;
        }

        const alreadyAddedUserDashboard = _(this.UserDashboards()).find((ud) => unwrap(ud.User().Id) === unwrap(this.User().Id));
        if (isNotNullOrUndefined(alreadyAddedUserDashboard)) {
            const errorMessage = getLabelTranslation("#Core/notifySelectedUserIsAlreadyAdded");
            error(errorMessage);
            return;
        }

        const userDashboard = getNew("Medius.Core.Entities.Dashboard.UserDashboard");
        userDashboard.User = observable(this.User());
        userDashboard.Dashboard = this.Dashboard;
        this.UserDashboards.push(userDashboard);
    }

    dispose() {
        this.UserDashboards(null);
        this.User(null);
        this.Dashboard(null);
    }
}

export default function create(entityWithContext: any) {
    return new UserDashboardTab(entityWithContext);
}