/// <amd-module name="Core/Medius.Core.Web/Scripts/components/topBar/rightSide/TopBarHelpMenu"/>
import * as React from "react";
import { useRef } from "react";
import * as appConfig from "Core/Medius.Core.Web/Scripts/appConfig";
import { useAppSelector } from "Core/Medius.Core.Web/Scripts/shared/store/hooks";
import * as userVoice from "Core/Medius.Core.Web/Scripts/Medius/core/userVoice";
import { useClickOutsideMenu } from "Core/Medius.Core.Web/Scripts/components/topBar/hooks/useClickOutsideMenu";
import { Link } from "react-router-dom";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";

export type HelpMenuProps = {
    readonly isHelpMenuVisible: boolean;
    readonly onHelpMenuVisibilityChange: (show: boolean) => void;
    readonly toggleQaIconVisibility: () => void;
};

export const HelpMenu = ({
    isHelpMenuVisible,
    onHelpMenuVisibilityChange,
    toggleQaIconVisibility,
}: HelpMenuProps) => {
    const isUserVoiceEnabled = appConfig.isUserVoiceEnabled();
    const benchmarksUrl = appConfig.getBenchmarksUrl();
    const documentationUrl = appConfig.getDocumentationUrl();
    const supportPortalUrl = appConfig.getSupportPortalUrl();
    const privacyPolicyUrl = appConfig.getPrivacyPolicyUrl();
    const appDisplayVersion = `${translate("#Core/version")} ${appConfig.getCurrentAppDisplayVersion()}`;
    const isSupportPortalEnabled = useAppSelector((store) => store.accesses?.supportPortal);
    const helpMenuContainerRef = useRef<HTMLDivElement>(null);

    const showMenu = () => {
        onHelpMenuVisibilityChange(!isHelpMenuVisible);
    };
    const hideMenu = () => onHelpMenuVisibilityChange(false);
    const openUserVoice = () => {
        hideMenu();
        userVoice.open();
    };
    const toggleQaIconAndHideMenu = () => {
        hideMenu();
        toggleQaIconVisibility();
    };

    useClickOutsideMenu(isHelpMenuVisible, helpMenuContainerRef, hideMenu);

    return (
        <div ref={helpMenuContainerRef} className="top-bar-menu top-bar-icon-holder" title={translate("#Core/help")}>
            <svg
                id="glyphicons-basic"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
                onClick={showMenu}
                data-testid="top-bar-help-menu-icon"
                className={isHelpMenuVisible ? "top-bar-icon-holder--extended top-bar__icon" : "top-bar__icon"}
            >
                <path
                    id="circle-question"
                    d="M16,4A12,12,0,1,0,28,16,12.01312,12.01312,0,0,0,16,4Zm2,18a1,1,0,0,1-1,1H15a1,1,0,0,1-1-1V20a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1Zm2.75-8.88574c0,1.37915-.86694,2.08215-1.95569,2.82495a3.5176,3.5176,0,0,0-1.47369,1.721A.49149.49149,0,0,1,16.84937,18h-2.062a.50188.50188,0,0,1-.49414-.5802,3.26859,3.26859,0,0,1,.65955-1.60095,10.46885,10.46885,0,0,1,2.32751-1.85645,1.0162,1.0162,0,0,0,.46973-.84814V13a1.0013,1.0013,0,0,0-1-1h-1.5a1,1,0,0,0-1,1,1,1,0,0,1-1,1h-1a1,1,0,0,1-1-1,3.99992,3.99992,0,0,1,4-4h1.5c2.28278,0,4,1.16394,4,4Z"
                />
            </svg>
            {isHelpMenuVisible && (
                <ul className="top-bar-menu__list top-bar-menu__list--right-aligned" title="">
                    <li>
                        <a href="#keyboardShortcutsModal" data-toggle="modal"
                            onClick={hideMenu} className="top-bar-menu__list-link">
                            {translate("#Core/keyboardShortcutsMenu")}
                        </a>
                    </li>
                    <li className="top-bar-menu__list-divider"></li>
                    <li>
                        <div className="beamerTrigger top-bar-menu__list-link">
                            {translate("#Core/whatsNew")}
                        </div>
                    </li>
                    <li>
                        <Link className="top-bar-menu__list-link" onClick={hideMenu}
                            rel='noreferrer' target="_blank" to={documentationUrl}>
                            {translate("#Core/onlineDocumentation")}
                        </Link>
                    </li>
                    {isSupportPortalEnabled && (
                        <li>
                            <Link className="top-bar-menu__list-link" onClick={hideMenu}
                                rel='noreferrer' target="_blank" to={supportPortalUrl}>
                                {translate("#Core/supportPortalLink")}
                            </Link>
                        </li>
                    )}
                    <li>
                        <Link className="top-bar-menu__list-link" onClick={hideMenu}
                            rel='noreferrer' target="_blank" to={privacyPolicyUrl}>
                            {translate("#Core/privacyPolicy")}
                        </Link>
                    </li>
                    <li>
                        <Link className="top-bar-menu__list-link" onClick={hideMenu}
                            rel='noreferrer' target="_blank" to={benchmarksUrl}>
                            {translate("#Core/benchmarks")}
                        </Link>
                    </li>
                    {isUserVoiceEnabled && (
                        <li className="top-bar-menu__list-link" onClick={openUserVoice}>
                            {translate("#Core/userVoiceLink")}
                        </li>
                    )}
                    {appConfig.isQaEnvironmentNoteVisible() && (
                        <>
                            <li className="top-bar-menu__list-divider"></li>
                            <li className="top-bar-menu__list-link" onClick={toggleQaIconAndHideMenu} data-testid="top-bar-help-menu-qa-note">
                                {translate("#Core/toggleQaIcon")}
                            </li>
                        </>
                    )}
                    <li className="top-bar-menu__list-divider"></li>
                    <li className="text-center" data-testid="top-bar-help-menu-appDisplayVersion">{appDisplayVersion}</li>
                </ul>
            )}
        </div>
    );
};