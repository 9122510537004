/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/history/binding/workflowHistory" />

import * as ko from "knockout";
import {WorkflowHistory} from "Core/Medius.Core.Web/Scripts/Medius/core/history/history";
import { domData, registerBinding} from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
    
const historyKey = "medius-history";

interface IParams {
    viewId: string | ko.Observable<string>;
    type: string | ko.Observable<string>; // e.g. 'Medius.Core.Entities.Help.HelpRequest'
}

const binding = {
    init(element: HTMLElement, bindingAccessor: any, allAccessor: any, viewModel: any, context: any) {
        const params: IParams = bindingAccessor();

        const viewId = ko.utils.unwrapObservable(params.viewId) || null;
        const type = ko.utils.unwrapObservable(params.type) || null;
        const currentHistory = domData.get(element, historyKey);

        if (!currentHistory) {
            const workflowHistory = new WorkflowHistory(viewId, type);
            const newContext = context.createChildContext(workflowHistory);

            ko.applyBindingsToDescendants(newContext, element);
            domData.set(element, historyKey, context);
        }

        return { controlsDescendantBindings: true };
    },
    update(element: HTMLElement, ...args:any) {
        const currentHistory = domData.get(element, historyKey);
        if (!currentHistory) {
            return (binding.init as any).apply(this, [element, ...args]);
        }
        return { controlsDescendantBindings: true };
    }
};

export function register() {
    registerBinding("workflowHistory", binding);
}