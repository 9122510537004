/// <amd-module name="Core/Medius.Core.Web/Scripts/components/links/linkMetadataProvider"/>
import * as ko from "knockout";
import { getTypeName } from "Core/Medius.Core.Web/Scripts/Medius/core/type";
import * as defaultMetadataGeneratorFactory from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/generator";
import { getBasePath } from 'Core/Medius.Core.Web/Scripts/Medius/lib/path';

let defaultMetadataGenerator: any;

const getDefaultMetadataGeneratorInstance = () => {
    if (!defaultMetadataGenerator) {
        defaultMetadataGenerator = defaultMetadataGeneratorFactory.create();
    }
    return defaultMetadataGenerator;
};

class EntityLinkMetadataResolver {
    public getMetadata = (link: any) => {
        if (!link) {
            return {};
        }

        if (link.$type === "Medius.Archive.Core.Domain.Entities.Invoice")
        {
            return {
                text: link.Label || link.Data,
                link: `${getBasePath()}/#/Documents/${link.Id}`
            };
        }

        return {
            text: link.Label || link.Data,
            link: getDefaultMetadataGeneratorInstance().getMetadata(link).link
        };
    };
}

function getDocumentType(documentViewModel: DocumentViewModel, baseType: string): string {
    if (documentViewModel.Invoice !== undefined) {
        return getTypeName(documentViewModel.Invoice().$type());
    }

    if (baseType === "Medius.Core.Entities.DocumentRegistration.Draft, Medius.Core.Common") {
        return getTypeName(documentViewModel.Document().Entity().$type());
    }

    return getTypeName(documentViewModel.$type());
}

function getDocumentViewId(documentViewModel: DocumentViewModel, baseType: string): string {
    if (documentViewModel.Invoice !== undefined) {
        return getTypeName(documentViewModel.Invoice().ViewId());
    }

    if (baseType === "Medius.Core.Entities.DocumentRegistration.Draft, Medius.Core.Common") {
        return documentViewModel.Document().Entity().ViewId();
    }

    return documentViewModel.ViewId();
}

interface DocumentViewModel {
    ViewId: ko.Observable<string>;
    $type: ko.Observable<string>;
    Document?: ko.Observable<any>; // filled in draft mode
    MappedContent?: ko.Observable<any>;
    Invoice?: ko.Observable<any>; // filled in archive mode
}

export { getDocumentType, getDocumentViewId, DocumentViewModel, EntityLinkMetadataResolver };