///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/components/basic" />

import * as ko from 'knockout';
import * as _ from 'underscore';
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as helpers from "Core/Medius.Core.Web/Scripts/Medius/components/editors/helpers";
import * as accessControlValues from "Core/Medius.Core.Web/Scripts/Medius/components/components/accessControl/values";

interface paramsObject {
    value: any;
    template: any;
    options: any;
    params: any;
    accessLevel?: any;
    defaultAccessLevel?: any;
}

export class Basic {
    params: paramsObject = {
        value: null,
        template: null,
        options: {},
        params: {},
    };
    
    Template: any;
    Value: any;
    Context: any;
    Params: any;
    Options: any;

    AccessLevel: any;
    IsEditable: any;
    IsViewable: any;
    IsHidden: any;

    constructor(bindingParameters: { value: any; accessLevel: any; }, viewmodel: any, context: any = {}){
        
        if (!bindingParameters || !bindingParameters.value) {
            throw new Error("value is required");
        }

        if (typeof bindingParameters.accessLevel !== "function") {
            throw new Error("access level must be a function");
        }

        helpers.mergeDefaults(bindingParameters, this.params);
        this.Options = this.params.options;
        
        this.Template = this.params.template;
        this.Value = this.params.value;
        this.Context = context;

        this.Params = _.extend({
            value: this.Value
        }, this.params.params);

        this.AccessLevel = ko.computed(() => {
            if (koUtils.unpack(this.Context.isReadOnlyView)) {
                return accessControlValues.view;
            }
            return this.params.accessLevel() || this.params.defaultAccessLevel || accessControlValues.hide;
        });
    
        this.IsEditable = ko.computed(() => {
            return this.AccessLevel() === accessControlValues.edit;
        });
    
        this.IsViewable = ko.computed(() => {
            return this.AccessLevel() === accessControlValues.view;
        });
    
        this.IsHidden = ko.computed(() => {
            return this.AccessLevel() === accessControlValues.hide;
        });
    }

    dispose(){
        this.IsHidden.dispose();
        this.IsViewable.dispose();
        this.IsEditable.dispose();
        this.AccessLevel.dispose();
        this.Value = null;
        this.Params = null;
    }
}

export function create(bindingParameters: { value: any; accessLevel: any; }, viewModel?: any, context?: any){
    return new Basic(bindingParameters, viewModel, context);
} 