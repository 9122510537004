/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/lib/store/memory"/>
const data: { [key: string]: any} = {};

class MemoryStore {
    public setItem(key: string, value: any) {
        data[key] = value;
    }

    public getItem(key: string) {
        return data[key];
    }

    public hasItem(key: string) {
        return data.hasOwnProperty(key);
    }

    public removeItem(key: string) {
        if (this.hasItem(key)) {
            delete data[key];
        }
    }
}

export const create = () => new MemoryStore();
