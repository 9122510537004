///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/chosenPlugin"/>
import { registerBinding } from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as $ from "jquery";

const chosenPlugin = {
    init: function (element:any) {
        $(element).chosen();
    },
    update: function (element:any, valueAccessor:any) {
        const val = valueAccessor();

        if (val() === null) {
            $(element).val("");
        }

        $(element).trigger("chosen:updated.chosen");
    },
    after: ['translateAttr']
};

export function register() {
    registerBinding("chosenPlugin", chosenPlugin);
}