/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/AuthorizationGroups/Tabs/limitTab"/>
import * as React from "react";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { Limit, ValidationType } from "../limit";
import { AuthorizationLimit, GeneralPermission } from "../authorizationGroup";

type LimitTabProps = {
    permission: GeneralPermission
    onChange: (permission: GeneralPermission) => void;
};

export function LimitTab(props: LimitTabProps) {

    const { permission, onChange } = props;

    const onDebitLimitChange = (limit: AuthorizationLimit) => {
        onChange({ ...permission, DebitLimit: limit });
    };

    const onCreditLimitChange = (limit: AuthorizationLimit) => {
        onChange({ ...permission, CreditLimit: limit });
    };

    return (
        <div>
            <h4 className="section-header">{translate('#Enterprise/DebitLimit')}</h4>
            <div className="limitTab__debitLimit section-margin" data-testid="limitTab-debitLimit">
                <Limit
                    unlimitedLabel={translate("#Enterprise/unlimited")}
                    limit={permission.DebitLimit}
                    type={ValidationType.Positive}
                    onChange={onDebitLimitChange} />
            </div>

            <h4 className="section-header">{translate('#Enterprise/CreditLimit')}</h4>
            <div className="limitTab__creditLimit" data-testid="limitTab-creditLimit">
                <Limit
                    unlimitedLabel={translate("#Enterprise/unlimited")}
                    limit={permission.CreditLimit}
                    type={ValidationType.Negative}
                    onChange={onCreditLimitChange} />
            </div>
        </div>
    );
}
