/// <amd-module name="Core/Medius.Core.Web/Scripts/components/administration/title"/>
import * as React from "react";

interface TitleProps {
    label: string;
}

export function Title({ label }: TitleProps) {
    return (
        <h3 style={{color: "black", marginLeft: "50px", fontSize: "24.5px"}}>{label}</h3>
    );
}
