///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/newTooltip/newPopdownSkin"/>

export const showOn = 'click';
export const hideOn = 'clickOutside';
export const position = {
    my: 'right+15 top',
    at: 'center bottom',
    collision: 'flipfit flipfit'
};
export const css = {
    color: 'black',
    background: 'white',
    cssClass: 'newPopdown'
};
export const arrowCss = {
    'border-color': 'white'
};