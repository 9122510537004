///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/binding" />
import * as ko from "knockout";
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as editor from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/editor";
import * as _ from "underscore";

const ENTER = 13;

(function fixTypeaheadKeysRecognition() {
    // fix of known bootstrap issue : https://github.com/twbs/bootstrap/issues/6633
    const moveMethod = $.fn.typeahead.Constructor.prototype.move;
    $.fn.typeahead.Constructor.prototype.move = function (e: KeyboardEvent) {
        if (!this.shown || e.keyCode !== 40 || e.type !== 'keypress') {
            moveMethod.call(this, e);
        }
    };
}());

const binding: ko.BindingHandler = {
    init: function (element, bindingAccessor, allBindingsAccessor, viewModel, bindingContext) {
        const params = koUtils.unpack(bindingAccessor());

        const model = (params.validate) ? params : editor.create(params);
        ko.renderTemplate(model.options.template, model, {}, element, "replaceChildren");

        const $input = $(element).find("input[type=text]");
        $input.typeahead(model.typeahead);
           
        function handler(e: any) {
            if (!e.isPropagationStopped() && e.keyCode === ENTER) {
                model.options.onEnterPressed(e);
            }
        }
        
        if (typeof model.options.onEnterPressed === "function") {
            $input.on("keyup", _.debounce(handler, 300));
        }
        
        if (bindingContext.validationGroup) {
            bindingContext.validationGroup.registerEditorComponent(model);
        }
        
        koUtils.addDisposeCallback(element, function () {
            if (bindingContext.validationGroup) {
                bindingContext.validationGroup.unregisterEditorComponent(model);
            }

            $input.off("keyup");
            model.dispose();
        });
        
        return { controlsDescendantBindings: true };
    }
};

export function registerEditDocumentSearchQueryBinding() {
    koUtils.registerBinding("edit-documentSearch-query", binding);
}
