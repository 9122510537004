/// <amd-module name="Core/Medius.Core.Web/Scripts/components/administration/buttons/addButton"/>
import * as React from "react";
import { Button } from "@progress/kendo-react-buttons";

interface AddButtonProps {
    disabled?: boolean;
    onClick: () => void;
    canAdd: boolean;
    label: string;
}

export function AddButton({ disabled = false, onClick, label, canAdd }: AddButtonProps) {
    return <>
        {canAdd && <Button
            data-testid="administration-action-buttons-add-button"
            className="administration-action-buttons__add-button"
            icon="plus"
            disabled={disabled}
            onClick={onClick}>
            {label}
        </Button>}
    </>;
}
