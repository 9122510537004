/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/inbox/InboxOptionsDropdownTemplate"/>
import * as React from "react";
import { Button, Switch, Checkbox, Text, ContentArea, FlexLayout, FlexSpacer, CheckboxGroup, ContentDivider, IconActionDeleteRegular } from "@medius/ui-controls";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";

export interface InboxOptionsDropdownProps {
    folders: Array<{id:number, visible:boolean, localizedName:string}>;
    savedFilters:Array<{Name:string}>;
    documentTypes:Array<{visible:boolean, typeName:string}>;
    toggleFolder: (id:number) => void;
    toggleDocumentType: (typeName:string) => void;
    allFoldersVisible: boolean;
    allDocumentsVisible: boolean;
    toggleAllFoldersAction: (visibility:boolean) => void,
    toggleAllDocumentTypesAction: (visibility:boolean) => void,
    deleteFilter: (filter:any) => void,
    applyFilter: (filter:any) => void
}

export function InboxOptionsDropdownTemplate({folders, savedFilters, documentTypes, toggleFolder, toggleDocumentType, allFoldersVisible, allDocumentsVisible, toggleAllFoldersAction, toggleAllDocumentTypesAction, deleteFilter, applyFilter }:InboxOptionsDropdownProps) {
    return (
        <div className="optionsDropdown">
            <ContentArea padSides={false} spacerMode="off" addBottomSpacer>
                <ContentArea spacerMode="off" padSides={false}>
                    <Text variant="heading3">
                        {getLabelTranslation("#Core/folders")}
                    </Text>
                </ContentArea>
                <ContentArea spacerMode="on" padSides={false}>
                    <Switch
                        width="fill"
                        leftLabel={getLabelTranslation("#Core/all")}
                        value={allFoldersVisible}
                        onChange={(e) => toggleAllFoldersAction(e.target.value)}/>
                </ContentArea>
                <div className="optionsDropdown__content__body">
                    <ContentArea spacerMode="on" padSides={false}>
                        <CheckboxGroup layout="column">
                            {folders.map((folder, key) => <Checkbox key={key} checked={folder.visible} label={folder.localizedName} onChange={() => toggleFolder(folder.id)}/>)}
                        </CheckboxGroup>
                    </ContentArea>
                </div>
                <ContentDivider/>
            </ContentArea>
                {savedFilters.length > 0 ?
                <ContentArea padSides={false} spacerMode="on">
                    <ContentArea padSides={false}>
                        <Text variant="heading3">
                            {getLabelTranslation("#Core/savedSearches")}
                        </Text>
                    </ContentArea>
                    <ContentArea padSides={false}>
                    {savedFilters.map((filter, key) => {
                        return(
                            <FlexLayout
                                spacerSize="vsmall" key = {key}>
                                <div className="saved-filter" data-testid="saved-filter">
                                <Button 
                                    data-testid={`saved-filter-select-${filter.Name}`}
                                    label = {filter.Name}
                                    variant = "inline"
                                    height="small"
                                    description={getLabelTranslation("#Core/searchFor", filter.Name)}
                                    onClick = {() => applyFilter(filter)}
                                    />
                                </div>
                                <FlexSpacer/>
                                <Button data-testid={`saved-filter-remove-${filter.Name}`}
                                    label = {getLabelTranslation("#Core/clickToDeleteFilter")}
                                    icon = {<IconActionDeleteRegular />}
                                    variant = "inline"
                                    height="small"
                                    hideLabel
                                    onClick={() => deleteFilter(filter)}/>
                            </FlexLayout>
                        );
                    })}
                    </ContentArea>
                    <ContentDivider/>
                </ContentArea>:<></>}
                {documentTypes.length > 0 ?
                <ContentArea spacerMode="on">
                    <ContentArea padSides={false}>
                    <Text variant="heading3">
                        {getLabelTranslation("#Core/documents")}
                    </Text>
                    </ContentArea>
                    <ContentArea spacerMode="on" padSides={false}>
                        <Switch
                            width="fill"
                            leftLabel={getLabelTranslation("#Core/all")}
                            value={allDocumentsVisible}
                            onChange={(e) => toggleAllDocumentTypesAction(e.target.value)}/>
                    </ContentArea>
                    <div className="optionsDropdown__content__body">
                        <ContentArea spacerMode="on" padSides={false}>
                            <CheckboxGroup layout="column">
                                {documentTypes.map((documentType, key) => <Checkbox key={key} checked={documentType.visible} label={documentType.typeName} onChange={() => toggleDocumentType(documentType.typeName)}/>)}
                            </CheckboxGroup>
                        </ContentArea>
                    </div>
                </ContentArea>:<></>}
        </div>
    );
}