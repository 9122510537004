/// <amd-module name="Core/Medius.Core.Web/Scripts/lib/metricsLogging/metricLogger"/>

import { event, output } from "Core/Medius.Core.Web/Scripts/Medius/lib/performance/output/all";

const category = "UX";

export function logEvent(action: string, label?: string, value?: number) {
    event(category, action, label, value);
}

export function logScenario(scenario?: string, scenarioDetails?: any, label?: string, time?: any) {
    if (!scenario) {
        return;
    }

    output(category, scenario, time, {
        scenarioDetails, label
    });
}
