/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/AuthorizationGroups/contexts/companyContext"/>
import { handleAnyError } from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import * as React from "react";
import { createContext, useEffect, useMemo, useState } from "react";
import { Dimension } from "../authorizationGroup";
import { getDimensions } from "../service";

export const CompanyContext = createContext({ companyId : 0, dimensions: [] as Dimension[] });

export const CompanyProvider = ({ companyId, children } : any) => {
    const [dimensions, setDimensions] = useState<Dimension[]>([]);
    const value = useMemo(() =>({ companyId, dimensions }), [companyId, dimensions]);

    useEffect(() => {
        getDimensions(companyId)
            .then(dimensions => {
                setDimensions(dimensions ?? []);
            })
            .fail(handleAnyError);
    }, [companyId]);

    return (
        <CompanyContext.Provider value={value}>
            {children}
        </CompanyContext.Provider>
    );
};
