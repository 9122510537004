///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/presenters/utils"/>
import * as componentUtils from "Core/Medius.Core.Web/Scripts/Medius/components/utils";
import * as _ from "underscore";

export function getConfiguration(name:string, params:any) {
    const configuration = _({
        factory: `medius/components/presenters/${params.module || name}/model`
    }).extend(params);
    return componentUtils.getConfiguration(name, configuration);
}

export const emitBinding = componentUtils.emitBinding.bind(componentUtils, "display");