/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/wizard/defaultWizardOptions"/>

import { IWizardOptions } from "Core/Medius.Core.Web/Scripts/Medius/components/wizard/IWizardOptions";

export class DefaultWizardOptions implements IWizardOptions {
    private onCloseHandler: () => void;
    private onCancelHandler: () => void;

    public readonly template = "Core:Medius/components/wizard/wizard.html";

    constructor(onCloseHandler: () => void, onCancelHandler: () => void) {
        this.onCloseHandler = onCloseHandler;
        this.onCancelHandler = onCancelHandler;
    }

    public onClose() {
        this.onCloseHandler();
    }
    public onCancel() {
        this.onCancelHandler();
    }
}
