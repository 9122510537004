/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Data.DataDefinition/SimpleRelationEditor"/>

import { unpack } from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import {Provider} from "Core/Medius.Core.Web/Scripts/Medius/apps/autocompleter/dataProvider/companyRelated";
import * as typeResolver from "Core/Medius.Core.Web/Scripts/Medius/components/resolver/type";
import * as _ from "underscore";
import * as ko from "knockout";
import * as simpleRelationGridFactory from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Data.DataDefinition/SimpleRelationGridFactory";
import * as simplePresenter from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/simplePresenter";
import {EntityCollection} from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/entityCollection";

class EntityDataProvider extends Provider {
    entityType: any;
    constructor(context: any, companyId: string, entityType: any) {
        super(context, "GetActiveMasterDataForAutoCompleter", "MasterDataService", companyId);
    
        this.entityType = entityType;
    
        this.getParameters = (phrase, limit) => {
            const companyId = unpack(this.companyId) || this.context.getData().companyId;
            const parameters =  {
                searchText: phrase,
                maxResults: limit,
                companyId: companyId || 0,
                type: this.entityType
            };
    
            return parameters;
        };
    }
}

class SimpleRelationEdior {
    Entity: any;
    CollectionHandler: any;
    RelatedEntities: any;
    EntityToAdd: ko.Observable<any>;
    EntityDataProvider: EntityDataProvider;
    Grid: import("Core/Medius.Core.Web/Scripts/Medius/components/grid/default/grid").Grid;
    constructor(entityWithContext: any, companyContextId: any, tabModel: any) {
        let companyId: any;
    
        const entityModel = entityWithContext.Entity;
    
        if (_.isNull(entityModel) || _.isUndefined(entityModel)) {
            throw "medius/viewModels/administration/Medius.Data.DataDefinition/Relation: Entity model not defined";
        }
    
        const propertyName = tabModel.RelatedProperty();
        const elementType = typeResolver.getCollectionPropertyElementType(entityModel.$type(), propertyName);
    
        if (entityModel.Company && entityModel.Company()) {
            companyId = entityModel.Company().Id();
        } else {
            companyId = companyContextId;
        }
    
        this.Entity = entityModel;
        this.CollectionHandler = this.resolveCollection(this.Entity, propertyName);
        this.RelatedEntities = entityModel[propertyName];
        this.EntityToAdd = ko.observable();
        this.EntityDataProvider = new EntityDataProvider(entityWithContext.Context, companyId, elementType);
    
        this.Grid = simpleRelationGridFactory.createGrid(this.RelatedEntities);
    
    }

    remove(entityToRemove: any) {
        this.RelatedEntities.remove(entityToRemove);
    }

    add() {
        if (!this.EntityToAdd()) {
            return;
        }

        const entityToAdd = _(this.RelatedEntities()).find((entity) => {
            return entity.Id() === this.EntityToAdd().Id();
        });
        if (!entityToAdd) {
            this.RelatedEntities.push(this.EntityToAdd());
        }
    }

    // TODO: Workaround. Should be removed when MFP-7509 is fixed. Then display component can be used in SimpleRowTpl.cshtml instead
    createLabel(entity: any) {
        return simplePresenter.presentMetadata(entity).text;
    }

    resolveCollection(entityModel: any, propertyName: any) {
        const collection = entityModel[propertyName];

        if (!collection) {
            throw new Error(`Property ${propertyName} does not exist`);
        }

        return new EntityCollection(collection);
    }

    dispose() {
        this.Entity = null;
        this.Grid.dispose();
        this.Grid.destroy();
        this.Grid = null;
        this.CollectionHandler = null;
    }
}

export default function create(entityWithContext: any, companyContextId: any, tabModel: any) {
    return new SimpleRelationEdior(entityWithContext, companyContextId, tabModel);
}