/// <amd-module name="Core/Medius.Core.Web/Scripts/components/prepaymentInvoices/appliedPrepaymentsModal"/>
import * as React from "react";
import {
    useState,
    useCallback,
    useEffect,
    useRef,
} from "react";
import { getBasePath } from "Core/Medius.Core.Web/Scripts/Medius/lib/path";
import {
    IconSystemSettingsFill,
    LoaderIcon,
    IconUserUserFill,
    TableIcon,
    NumberBox,
    IconActionDeleteRegular,
    ModalDialog,
    Button,
    ColumnDefinitionSet,
    DataTable,
    Notification,
    Text,
    ListItem,
    InputControlEvent,
    MultiSelectAutoComplete,
    IconSecurityLockFill
} from "@medius/ui-controls";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { handleError } from "Core/Medius.Core.Web/Scripts/lib/errorHandling/errorHandler";
import * as settingsProvider from "Core/Medius.Core.Web/Scripts/Medius/core/settingsProvider";
import { get, post } from "Core/Medius.Core.Web/Scripts/Medius/core/fetch/rest";
import {
    PrepaymentInformation,
    OriginInvoiceData,
    localizeDate,
    localizeAmountForBig,
    convertBigToNumber,
    convertBigToAmountDto
} from "Core/Medius.Core.Web/Scripts/components/prepaymentInvoices/appliedPrepaymentsCommon";
import { getCultureCode } from "../../Medius/lib/utils/user";
import * as _ from "underscore";
import Big from "big.js";

const NotANumber = "NaN";
type NaNType = "NaN";

interface ApplyPrepaymentsModalProps {
    prepaymentInformations: PrepaymentInformation[];
    originInvoiceData: OriginInvoiceData;
    modalVisible: boolean;
    onModalClose: (isApply: boolean, result: PrepaymentInformation[]) => void
}

interface ApplicablePrepaymentsQueryParams {
    SearchPhrase: string,
    Skip: number,
    Take: number,
    InvoiceId: number,
    AppliedPrepayments: number[],
    SelectedPrepayments: number[]
}

export const ApplyPrepaymentsModal = ({prepaymentInformations, originInvoiceData, modalVisible, onModalClose }: ApplyPrepaymentsModalProps) => {
    //HACK: because of NS bug we need to set some value here
    //if there will be more prepayments only first 20 will be visible
    const maxNumberOfPrepaymentsAllowedByUI = 20;
    const resolution = settingsProvider.getAmountDisplayResolution(originInvoiceData.Gross.CurrencyCode);
    const currency = originInvoiceData.Gross.CurrencyCode;
    const [isLoaderVisible, setIsLoaderVisible] = useState(false);
    const [allData, setAllData] = useState<PrepaymentApplicationViewModel[]>(mapPrepaymentInformationsToViewModel(prepaymentInformations, currency));
    const [isTotalChanged, setIsTotalChanged] = useState(false);
    const [searchResult, setSearchResult] = useState<ApplicablePrepaymentViewModel[]>([]);
    const [cacheDependencyKey, setCacheDependencyKey] = useState<number>(0);

    // without allDataRef is no possible to observe actual amounts in renderAppliedAmountColumn
    const allDataRef = useRef(allData);

    useEffect(() => {
        setAllData(mapPrepaymentInformationsToViewModel(prepaymentInformations, currency));
    }, [modalVisible]);

    useEffect(() => {
        allDataRef.current = allData;
        setIsTotalChanged(!isTotalChanged);
    }, [allData]);

    const renderAppliedAmountColumn = useCallback((item: PrepaymentApplicationViewModel) => {
        if (isSummaryRow(item)) {
            const isTotalAmountIsMoreThanOriginInvoiceTotal = PrepaymentApplicationValidation.isTotalAmountIsMoreThanOriginInvoiceTotal(filteredPrepaymentsWithoutSummary(allDataRef.current), originInvoiceData.Gross);
            const filteredPrepaymentApplicationsWithoutSummary = filteredPrepaymentsWithoutSummary(allDataRef.current);
            
            if (PrepaymentApplicationValidation.hasWrongValue(filteredPrepaymentApplicationsWithoutSummary)) {
                return <></>;
            }
            const sumOfApplications = filteredPrepaymentApplicationsWithoutSummary.reduce((a,b)=> a.add(b.AppliedAmountValue), Big(0));
            return <div data-testid="total-applied-amount" className={isTotalAmountIsMoreThanOriginInvoiceTotal ? "applied_prepayments_modal__total-validation-failed" : ""}>{localizeAmountForBig(sumOfApplications, item.Currency)}</div>;
        }

        const currentAppliedAmount = allDataRef.current.filter(x => x.InvoiceId === item.InvoiceId)[0].AppliedAmountValue;
        const isInvalidValue = currentAppliedAmount === NotANumber;
        let isLessThanOrEqualZero = false;
        let isGreaterThanRemainig = false;
        if (!isInvalidValue) {
            isLessThanOrEqualZero = (currentAppliedAmount as Big).lte(0);
            isGreaterThanRemainig = (currentAppliedAmount as Big).gt(item.RemainingAmountValue);
        }

        return <NumberBox data-testid="applied-amount" invalid={ isLessThanOrEqualZero || isGreaterThanRemainig || isInvalidValue} decimalPlaces={resolution} unit={currency} value={convertBigToNumber(currentAppliedAmount as Big)} onChange={(event) => {
            allDataRef.current.filter(x => x.InvoiceId === item.InvoiceId)[0].AppliedAmountValue = (event.value === null || Number.isNaN(event.value)) ? NotANumber:  Big(event.value);
            setAllData([...allDataRef.current]);
        }}></NumberBox>;
    }, [modalVisible, isTotalChanged]);

    //we need to useCallbacks to not loose focuse on input when editing data
    const renderRemainingColumn = useCallback((item: PrepaymentApplicationViewModel) => {
        if (isSummaryRow(item)) {
             return <><b className="applied_prepayments_modal__custom-cell-alignment">{translate('#PurchaseToPay/total')}</b></>;
        }
        return <div className="applied_prepayments_modal__custom-cell-alignment">{item.RemainingAmountValue === NotANumber ? "" : localizeAmountForBig(item.RemainingAmountValue, item.Currency)}</div>;
    }, [modalVisible]);

    const invoiceNumberColumn = useCallback((item: PrepaymentApplicationViewModel) => {
        if (isSummaryRow(item)) return <></>;
        return (<div className="linkCell">
                {
                    item.CanAccessInvoice ? <a data-testid="apply-prepayment-invoice-number" href={`${getBasePath()}#/Tasks/ShowDocument/${item.InvoiceId}`}>{item.InvoiceNumber}</a> : <span className="linkCell_text">{item.InvoiceNumber}</span>
                }
                {
                    item.CanAccessInvoice ? undefined : <div title={translate('#PurchaseToPay/insufficientPermissionsToOpenTheInvoice')} className="linkCell_locker"><IconSecurityLockFill /></div>
                }
                </div>);
    }, [modalVisible]);

    const isManualColumn = useCallback((item: PrepaymentApplicationViewModel) => {
        if (isSummaryRow(item)) return <></>;
        return item.IsManual ? <TableIcon data-testid="prepayment-manual-icon" icon={<IconUserUserFill data-testid="icon-contact" />} label={translate("#PurchaseToPay/prepayments/connectionCreatedByUser")} />
        : <TableIcon data-testid="prepayment-auto-icon" icon={<IconSystemSettingsFill data-testid="icon-settings" />} label={translate("#PurchaseToPay/prepayments/connectionCreatedBySystem")} />;
    }, [modalVisible]);

    const invoiceDateColumn = useCallback((item: PrepaymentApplicationViewModel) => {
        if (isSummaryRow(item)) return <></>;
        return localizeDate(item.InvoiceDate);
    }, [modalVisible]);

    const removeRowActionColumn = useCallback((item: PrepaymentApplicationViewModel) => {
            if (isSummaryRow(item)) return <></>;
            return <>
                <div>
                    <Button data-testid='delete-button' onClick={() => {
                        const allDataToSet = filteredPrepaymentsWithoutSummary(allDataRef.current).filter(x => x.InvoiceId !== item.InvoiceId);
                        const totalValue = filterApplicationsWithoutIncorrectAmount(allDataToSet).map(x => x.AppliedAmountValue as Big).reduce((a, b) => a.add(b), Big(0));
                        const totalValueViewModel = new PrepaymentApplicationSummaryViewModel(totalValue, currency);
                        setAllData([...allDataToSet, totalValueViewModel]);
                        setCacheDependencyKey(prevState => prevState + 1);
                    }} label={translate('#PurchaseToPay/reporting/delete')} hideLabel icon={<IconActionDeleteRegular />} variant="inline" />
                </div>
            </>;
    }, [modalVisible]);

    const columnDefinition: ColumnDefinitionSet<PrepaymentApplicationViewModel> = [
        {
            columnType: "customContent",
            key: "InvoiceNumber",
            displayName: translate("#Enterprise/LBL_INVOICE_NUMBER"),
            overflowType: "truncate",
            renderRowContent: invoiceNumberColumn,
            minColumnSize: 1
        },
        {
            columnType: "customContent",
            key: "IsManual",
            displayName: String(),
            renderRowContent: isManualColumn,
            overflowType: "truncate",
            horizontalAlignment : 'left',
            minColumnSize: 1,
            maxColumnSize: "content"
        },
        {
            columnType: "customContent",
            key: "InvoiceDate",
            displayName: translate("#Enterprise/LBL_INVOICE_DATE"),
            overflowType: "truncate",
            renderRowContent: invoiceDateColumn,
            minColumnSize: 1
        },
        {
            key: "VoucherNumber",
            dataKey: "VoucherNumber",
            displayName: translate('#Enterprise/LBL_FINAL_VOUCHER_NUMBER'),
            columnType: "dataField",
            dataType: "text",
            enableSorting: false,
            overflowType: "truncate",
            minColumnSize: 1
        },
        {
            key: "PurchaseOrderNumber",
            dataKey: "PurchaseOrderNumber",
            displayName: translate("#Enterprise/orderNumber"),
            columnType: "dataField",
            dataType: "text",
            enableSorting: false,
            overflowType: "truncate",
            minColumnSize: 1
        },
        {
            columnType: "customContent",
            key: "PrepaymentRemaining",
            displayName: translate("#PurchaseToPay/prepaymentRemaining"),
            renderRowContent: renderRemainingColumn,
            overflowType: "truncate",
            minColumnSize: 1
        },
        {
            columnType: "customContent",
            key: "AppliedAmount",
            displayName: translate("#PurchaseToPay/appliedAmount"),
            renderRowContent: renderAppliedAmountColumn,
            overflowType: "truncate",
            minColumnSize: 1
        },
        {
            columnType: "actions",
            key: "actionIcons",
            displayName: String(),
            maxColumnSize: 1,
            renderRowActions: removeRowActionColumn
        },
    ];

    const mapPrepaymentToListItem = (prepayment: ApplicablePrepaymentViewModel): ListItem => {
        const header = !prepayment.purchaseOrderNumber ? undefined : `${prepayment.purchaseOrderNumber}`;
        return {
            header: header,
            text: `${prepayment.invoiceNumber} (${(new Date(prepayment.invoiceDate)).toLocaleDateString(getCultureCode())})`,
            subText: `${localizeAmountForBig(prepayment.remainingAmountValue, prepayment.currencyCode)}`,
            value: `${prepayment.invoiceId}`
        };
    };

    const mapPrepaymentApplicationViewModelToListItem = (prepayment: PrepaymentApplicationViewModel): ListItem => {
        const header = !prepayment.PurchaseOrderNumber ? undefined : `${prepayment.PurchaseOrderNumber}`;
        return {
            header: header,
            text: `${prepayment.InvoiceNumber} (${(new Date(prepayment.InvoiceDate)).toLocaleDateString(getCultureCode())})`,
            subText: `${localizeAmountForBig(prepayment.RemainingAmountValue as Big, prepayment.Currency)}`,
            value: `${prepayment.InvoiceId}`
        };
    };

    const setSearchResultWrapper = (prepayments: ApplicablePrepaymentViewModel[]) => {
        const oldPrepayments = [...searchResult];
        const newPrepayments = prepayments.filter(prepayment => !_.any(oldPrepayments, x => x.invoiceId == prepayment.invoiceId));

        const result = oldPrepayments.concat(newPrepayments);
        setSearchResult(result);
    };

    const onGetItemData = async (search: string | null, skip: number, take: number) => {

        const params = {
            SearchPhrase: encodeURIComponent(search || ""),
            Skip: skip,
            Take: take,
            InvoiceId: originInvoiceData.InvoiceId,
            AppliedPrepayments: prepaymentInformations.map(x => x.InvoiceId),
            SelectedPrepayments: filteredPrepaymentsWithoutSummary(allData).map(x => x.InvoiceId),
        } as ApplicablePrepaymentsQueryParams;

        const data = await get<ApplicablePrepaymentsDto>("prepaymentInvoice/applicationToInvoice/getApplicablePrepayments", params);
        const recordsFound = data.records.map((applicablePrepayment: ApplicablePrepaymentDto) => {
            const appliedPrepayment = prepaymentInformations.find(prepaymentInformation => prepaymentInformation.InvoiceId === applicablePrepayment.invoiceId);
            let remainingAmountValue: Big = Big(applicablePrepayment.remainingAmount.displayValue);
            if (appliedPrepayment) {
                remainingAmountValue = Big(appliedPrepayment.RemainingAmount.DisplayValue);
            }

            return {
                remainingAmountValue: remainingAmountValue,
                currencyCode: applicablePrepayment.remainingAmount.currencyCode,
                ...applicablePrepayment
            } as ApplicablePrepaymentViewModel;
            
        });

        setSearchResultWrapper(recordsFound);
        return {
            items: recordsFound.map<ListItem>(mapPrepaymentToListItem),
            morePagesAvailable: data.hasMore
        };
    };

    const onSelect = async (value: ListItem[]) => {
        if (!value) {
            return;
        }

        const selectedPrepaymentId = value[value.length - 1].value;

        const selectedPrepayment = searchResult.find(x => x.invoiceId.toString() === selectedPrepaymentId);
        const existingData = allDataRef.current;

        const newElement = mapPrepaymentDtoToViewModel(selectedPrepayment, currency, Big(originInvoiceData.Gross.DisplayValue), calculateTotalAppliedAmount(filteredPrepaymentsWithoutSummary(existingData)));
        const allDataToSet = filteredPrepaymentsWithoutSummary(existingData.concat(newElement));

        const totalValue = calculateTotalAppliedAmount(allDataToSet);
        const totalValueViewModel = new PrepaymentApplicationSummaryViewModel(totalValue, currency);

        allDataRef.current = [...allDataToSet, totalValueViewModel];
        setAllData([...allDataToSet, totalValueViewModel]);
        setCacheDependencyKey(prevState => prevState + 1);
    };

    const calculateTotalAppliedAmount = (prepaymentApplicationViewModels: PrepaymentApplicationViewModel[]) => {
        return filterApplicationsWithoutIncorrectAmount(prepaymentApplicationViewModels).map(x => x.AppliedAmountValue as Big).reduce((a, b) => a.add(b), Big(0));
    };

    return (
        <ModalDialog
            data-testid="prepayment-applications-modal"
            title={translate("#PurchaseToPay/applyPrepayment")}
            width="custom"
            isOpen={modalVisible}
            onRequestClose={() => onModalClose(false, undefined)}
            renderContent={() => {
                return (<>
                    <div>
                        {isLoaderVisible && <div className="prepayment-applications-modal__loader-overlay">
                            <LoaderIcon delayDisplay={false} />
                        </div>}
                        <div>
                            <Text id="search" variant="body" spacerMode="off">Select prepayment invoice</Text>
                            <MultiSelectAutoComplete
                                width="large"
                                onChange={({ value: listItems }: InputControlEvent<ListItem[]>) => onSelect(listItems)}
                                dataMethodType="pagedItems"
                                getItemData={onGetItemData}
                                useExternalSelectionDisplay={true}
                                cacheDependencyKey={cacheDependencyKey.toString()}
                                value={filteredPrepaymentsWithoutSummary(allDataRef.current).map(x => mapPrepaymentApplicationViewModelToListItem(x))}
                                pageSize={5}
                                data-testid='prepayments-search_box'
                            />
                        </div>

                        <div className="prepayment-applications-modal__prepayment_data_table">
                            {
                                <DataTable<PrepaymentApplicationViewModel>
                                    label={translate('#PurchaseToPay/prepayments/modal/gridLabel')}
                                    data-testid="prepayment-applications"
                                    allData={allData}
                                    enableResizing={false}
                                    dataMethod="all"
                                    columns={columnDefinition}
                                    maxLinesPerRow={2}
                                    paging={{
                                        enablePaging: true,
                                        initialPageSize: maxNumberOfPrepaymentsAllowedByUI,
                                        availablePageSizes: [maxNumberOfPrepaymentsAllowedByUI]
                                    }}
                                />
                            }                      
                        </div>
                        <div>
                            {PrepaymentApplicationValidation.isAnyAppliedAmountIsHigherThanRemaining(filteredPrepaymentsWithoutSummary(allData)) && <div data-testid="validation-applied-is-higher-than-remaining" className="applied_prepayments_modal__validation-notification"><Notification feedbackType="error" text="" title={translate('#PurchaseToPay/prepayments/modal/appliedAmountIsHigherThanRemaining')}/></div> }
                            {PrepaymentApplicationValidation.isTotalAmountIsMoreThanOriginInvoiceTotal(filteredPrepaymentsWithoutSummary(allData), originInvoiceData.Gross) && <div data-testid="validation-total-is-higher-than-invoice-gross" className="applied_prepayments_modal__validation-notification"><Notification feedbackType="error" text="" title={translate('#PurchaseToPay/prepayments/modal/totalAppliedIsHigherThanInvoiceTotal')}/></div> }
                            {PrepaymentApplicationValidation.isAnyAppliedAmountIsZero(filteredPrepaymentsWithoutSummary(allData)) && <div data-testid="validation-applied-amount-is-zero" className="applied_prepayments_modal__validation-notification"><Notification feedbackType="error" text="" title={translate('#PurchaseToPay/prepayments/modal/appliedAmountMustBeGreaterThan0')}/></div>}
                            {PrepaymentApplicationValidation.hasWrongValue(filteredPrepaymentsWithoutSummary(allData)) && <div data-testid="validation-applied-has-wrong-value" className="applied_prepayments_modal__validation-notification"><Notification feedbackType="error" text="" title={translate('#PurchaseToPay/prepayments/modal/appliedAmountHasWrongValue')}/></div>}  
                        </div>
                    </div>
                    
                </>);
            }}
            renderActions={() => {
                return (<>
                    <Button data-testid="cancel-adding-links-button" label={translate("#Core/cancel")} variant="secondary" onClick={() => onModalClose(false, undefined)} />
                    <Button data-testid="confirm-adding-links-button" label={translate("#PurchaseToPay/prepayments/modal/save")} onClick={async () => {
                        
                        try {
                            setIsLoaderVisible(true);
                            const appliedWithoutSummary = filteredPrepaymentsWithoutSummary(allData);
                            if (!PrepaymentApplicationValidation.isValid(appliedWithoutSummary, originInvoiceData.Gross)) {
                                return;
                            }

                            const applicationsDto: PrepaymentApplicationDto[] = appliedWithoutSummary.map(x => {
                                let isManual;
                                const previousApplication = prepaymentInformations.find(y => x.InvoiceId === y.InvoiceId);
                                if (previousApplication) {
                                    const appliedHasChanged = Big(previousApplication.AppliedAmount.DisplayValue) !== x.AppliedAmountValue;
                                    isManual = appliedHasChanged ? true : previousApplication.IsManual;
                                }

                                return {
                                    PrepaymentId: x.InvoiceId,
                                    Amount: {
                                        CurrencyCode: currency,
                                        DisplayValue: (x.AppliedAmountValue as Big).toString(),
                                    },
                                    IsManual: isManual ? isManual : x.IsManual
                                };
                            });

                            const prepaymentApplicationsDto: PrepaymentApplicationsStateDto = {
                                InvoiceId: originInvoiceData.InvoiceId,
                                InvoiceType: originInvoiceData.Type === "PoInvoice" ? FinalInvoiceType.PoInvoice : FinalInvoiceType.NonPoInvoice,
                                Applications: applicationsDto
                            };

                            await post(`Backend/Rest/prepaymentInvoice/applicationToInvoice/save`, prepaymentApplicationsDto);
                            onModalClose(true, appliedWithoutSummary.map(x => toPrepaymentInformation(x, currency)));
                        } catch(e) {
                            handleError(e);
                        } finally {
                            setIsLoaderVisible(false);
                        }
                        }} />
                </>);
            }}
        />
    );
};

enum FinalInvoiceType {
    PoInvoice,
    NonPoInvoice
}

interface PrepaymentApplicationDto {
    PrepaymentId: number;
    Amount: AmountDto;
    IsManual: boolean;
}

interface PrepaymentApplicationsStateDto {
    InvoiceId: number;
    InvoiceType: FinalInvoiceType;
    Applications: PrepaymentApplicationDto[];
}

export interface ApplicablePrepaymentsDto {
    hasMore: boolean,
    records: ApplicablePrepaymentDto[]
}

export interface ApplicablePrepaymentDto {
    invoiceNumber: string,
    invoiceDate: Date,
    invoiceId: number,
    remainingAmount: {
        displayValue: string;
        currencyCode: string;
    },
    voucherNumber: string,
    purchaseOrderNumber: string
}

interface ApplicablePrepaymentViewModel {
    invoiceNumber: string,
    invoiceDate: Date,
    invoiceId: number,
    remainingAmountValue: Big,
    currencyCode: string,
    voucherNumber: string,
    purchaseOrderNumber: string
}

function isSummaryRow(item: any) {
    const result = item &&
    (item.Total || item.Total === null);
    return result;
}

function toPrepaymentInformation(viewModel: PrepaymentApplicationViewModel, currencyCode: string): PrepaymentInformation {
    return {
        InvoiceId: viewModel.InvoiceId,
        InvoiceDate: viewModel.InvoiceDate,
        InvoiceNumber: viewModel.InvoiceNumber,
        IsManual: viewModel.IsManual,
        PurchaseOrderNumber: viewModel.PurchaseOrderNumber,
        VoucherNumber: viewModel.VoucherNumber,
        RemainingAmount: convertBigToAmountDto(viewModel.RemainingAmountValue as Big, currencyCode),
        AppliedAmount: convertBigToAmountDto(viewModel.AppliedAmountValue as Big, currencyCode),
        CanAccessInvoice: viewModel.CanAccessInvoice
    };
}

function toPrepaymentApplicationViewModel(connection: PrepaymentInformation): PrepaymentApplicationViewModel {
    return {
        InvoiceNumber: connection.InvoiceNumber,
        IsManual: connection.IsManual,
        InvoiceDate: connection.InvoiceDate,
        VoucherNumber: connection.VoucherNumber,
        PurchaseOrderNumber: connection.PurchaseOrderNumber,
        AppliedAmountValue: Big(connection.AppliedAmount.DisplayValue),
        Currency: connection.AppliedAmount.CurrencyCode,
        InvoiceId: connection.InvoiceId,
        RemainingAmountValue: Big(connection.RemainingAmount.DisplayValue),
        CanAccessInvoice: connection.CanAccessInvoice
    };
}

function mapPrepaymentInformationsToViewModel(prepaymentApplications: PrepaymentInformation[], currencyCode: string) {
    if (!prepaymentApplications || prepaymentApplications.length === 0){
        return [new PrepaymentApplicationSummaryViewModel(new Big(0), currencyCode)];
}

    return [...prepaymentApplications.map(x => toPrepaymentApplicationViewModel(x)),
            new PrepaymentApplicationSummaryViewModel(prepaymentApplications.map(x => {
                return !x.AppliedAmount ? Big(0) : (Big(x.AppliedAmount.DisplayValue));
    }).reduce((a,b) => a.add(b)), currencyCode)];
}


function max(a: Big, b: Big): Big {
    if(b.gt(a)) return b;
    return a;
}

function min(a: Big, b: Big): Big {
    if (b.lt(a)) return b;
    return a;
}

function mapPrepaymentDtoToViewModel(prepayment: ApplicablePrepaymentViewModel, currency: string, invoiceAmount: Big, appliedAmount: Big) {
    return {
        InvoiceNumber: prepayment.invoiceNumber,
        IsManual: true,
        InvoiceDate: new Date(prepayment.invoiceDate),
        VoucherNumber: prepayment.voucherNumber,
        PurchaseOrderNumber: prepayment.purchaseOrderNumber,
        AppliedAmountValue: max(Big(0), min(prepayment.remainingAmountValue, invoiceAmount.minus(appliedAmount))),
        InvoiceId: prepayment.invoiceId,
        Currency: currency,
        RemainingAmountValue: Big(prepayment.remainingAmountValue),
        CanAccessInvoice: true
    } as PrepaymentApplicationViewModel;
}

function filterApplicationsWithoutIncorrectAmount(applications: PrepaymentApplicationViewModel[]) {
    return applications.filter(x => x.AppliedAmountValue !== NotANumber);
}

class PrepaymentApplicationValidation {
    static isAnyAppliedAmountIsZero(prepaymentApplications: PrepaymentApplicationViewModel[]) {
        return filterApplicationsWithoutIncorrectAmount(prepaymentApplications).filter(x => (x.AppliedAmountValue as Big).lte(0)).length > 0;
    }

    static isAnyAppliedAmountIsHigherThanRemaining (prepaymentApplications: PrepaymentApplicationViewModel[]) {
        const rowsWithHigherValue = filterApplicationsWithoutIncorrectAmount(prepaymentApplications).filter(x => x.RemainingAmountValue !== NotANumber).filter(prepaymentApplication => (prepaymentApplication.AppliedAmountValue as Big).gt(prepaymentApplication.RemainingAmountValue));
        return rowsWithHigherValue.length > 0;
    }

    static isTotalAmountIsMoreThanOriginInvoiceTotal (prepaymentApplications: PrepaymentApplicationViewModel[], originInvoiceGross: AmountDto) {
        const appliedAmounts = filterApplicationsWithoutIncorrectAmount(prepaymentApplications).map(x => x.AppliedAmountValue as Big).reduce((a, b) => a.add(b), Big(0));
        return appliedAmounts.gt(Big(originInvoiceGross.DisplayValue));
    }

    static hasWrongValue (prepaymentApplications: PrepaymentApplicationViewModel[]) {
        return prepaymentApplications.filter(x => x.AppliedAmountValue === NotANumber).length > 0;
    }

    static isValid(data: PrepaymentApplicationViewModel[], originInvoiceGross: AmountDto): boolean {
        return !PrepaymentApplicationValidation.hasWrongValue(data) 
        && !PrepaymentApplicationValidation.isAnyAppliedAmountIsZero(data) 
        && !PrepaymentApplicationValidation.isAnyAppliedAmountIsHigherThanRemaining(data)
        && !PrepaymentApplicationValidation.isTotalAmountIsMoreThanOriginInvoiceTotal(data, originInvoiceGross);
    }
}

function filteredPrepaymentsWithoutSummary(prepaymentApplications: PrepaymentApplicationViewModel[]): PrepaymentApplicationViewModel[] {
    return prepaymentApplications.filter(application => !isSummaryRow(application));
}


class PrepaymentApplicationViewModel {
    InvoiceNumber: string;
    IsManual: boolean;
    InvoiceDate: Date;
    VoucherNumber: string;
    PurchaseOrderNumber: string;
    AppliedAmountValue: Big | NaNType;
    InvoiceId: number;
    Currency: string;
    RemainingAmountValue: Big | NaNType;
    CanAccessInvoice: boolean;
}


class PrepaymentApplicationSummaryViewModel extends PrepaymentApplicationViewModel {
    constructor(public Total: Big, public Currency: string) {
        super();
    }
}
