/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/AuthorizationGroups/Tabs/components/gridCells"/>
import * as React from "react";
import { Limit, ValidationType } from "../../limit";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { AuthorizationLimit, Dimension, Supplier } from "../../authorizationGroup";
import { SupplierAutocompleter } from "./supplierAutocompleter";
import { SvgIcon } from "@progress/kendo-react-common";
import { trashIcon } from "@progress/kendo-svg-icons";

type ChangeEvent<T> = (x: T) => void;
const passTargetValueTo = (onChange: any) => (event: any) => onChange(event.target.value);
const passValueTo = (onChange: any) => (event: any) => onChange(event.value);

export const LockedDropDownCell = (data: Dimension[], value: Dimension, onChange: ChangeEvent<string>, style?: any, dataQa?: string) =>
    <td data-testid={dataQa} style={style} className="k-grid-content-sticky gridContainer__grid__lockedColumnCell">
        <DropDownList data={data} textField="DisplayName" value={value} onChange={passTargetValueTo(onChange)} />
    </td>;

export const InputCell = (value: string, onChange: ChangeEvent<string>, dataQa?: string) =>
    <td data-testid={dataQa}>
        <Input value={value} onChange={passTargetValueTo(onChange)} />
    </td>;

export const CheckboxCell = (checkboxValue: boolean, onChange: ChangeEvent<boolean>, dataQa?: string) =>
    <td data-testid={dataQa} className="gridContainer__grid__centerCell">
        <Checkbox
            value={checkboxValue}
            onChange={passValueTo(onChange)}
            key={+checkboxValue} />
    </td>;

export const LimitValueCell = (limit: AuthorizationLimit, validationType: ValidationType, onChange: ChangeEvent<AuthorizationLimit>, dataQa?: string) => {
    return (
        <div data-testid={dataQa}>
            <Limit
                allowUnlimited={false}
                label={""}
                unlimitedLabel={translate("#Enterprise/unlimited")}
                limit={limit}
                type={validationType}
                onChange={onChange} />
        </div>
    );
};

export const LimitTypeCell = (limit: AuthorizationLimit, validationType: ValidationType, onChange: ChangeEvent<AuthorizationLimit>, dataQa?: string) => {
    return (
        <td data-testid={dataQa} className="gridContainer__grid__centerCell">
            <Limit
                allowLimit={false}
                label={""}
                unlimitedLabel={""}
                limit={limit}
                type={validationType}
                key={limit.Type}
                onChange={onChange} />
        </td>
    );
};

export const ButtonCell = (onClick: (() => any), dataQa?: string) => {
    return (
        <td className="gridContainer__grid__centerCell">
            <button className="k-button k-button-icon" onClick={onClick} data-testid={dataQa}>
                <SvgIcon icon={trashIcon}/>
            </button>
        </td>);
};

export const LockedSupplierAutocompleterCell = (supplier: Supplier, onChange: (newSupplier: Supplier) => void, style?: any, dataQa?: string) => {
    return (
        <td style={style} className="k-grid-content-sticky gridContainer__grid__lockedColumnCell" data-testid={dataQa}>
            <SupplierAutocompleter supplier={supplier} onChange={onChange} pageSize={50} width='400px' />
        </td>
    );
};
