/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/comment/comment"/>

export interface CommentDto {
    id: number;
    tokenizedText: Token[];
    author: string;
    createdDate: Date;
    hash: string;
    isCarriedOver: boolean;
}

interface Token {
    tokenType: string;
    tokenValue: TokenValue;
}

interface TokenValue {
    title: string;
    description: string;
    value: string;
}

export class Comment {
    public id: number;
    public tokenizedText: Token[];
    public author: string;
    public createdDate: Date;
    public isCarriedOver: boolean;
    public isFirstCarriedOver = false;
    public hash: string;
    public onDelete: (hash: string) => void;
    public canBeDeleted: ko.Observable<boolean>;

    constructor(commentDto: CommentDto, canBeDeleted: ko.Observable<boolean>, onDelete: (hash: string) => void) {
        this.id = commentDto.id;
        this.tokenizedText = commentDto.tokenizedText;
        this.author = commentDto.author;
        this.createdDate = commentDto.createdDate;
        this.isCarriedOver = commentDto.isCarriedOver;
        this.hash = commentDto.hash;
        this.onDelete = onDelete;
        this.canBeDeleted = canBeDeleted;
    }

    public deleteRequested(): void {
        this.onDelete(this.hash);
    }
}
