///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/task/RiskFactor/RiskDescriptions/FirstTimeInvoice"/>
import * as React from 'react';
import { getLabelTranslation } from 'Core/Medius.Core.Web/Scripts/lib/globalization';
import { IHandledInfo, RiskFactorCode, RiskFactorReasonCode } from 'Core/Medius.Core.Web/Scripts/Medius/apps/task/RiskFactor/RiskFactorComponentDtos/riskFactorsForInvoiceDto';
import { DatePresenter } from 'Core/Medius.Core.Web/Scripts/shared/components/datePresenter';
import { getRiskFactorNameTranslation } from 'Core/Medius.Core.Web/Scripts/Medius/apps/task/RiskFactor/riskFactorUtils';

export interface IFirstTimeInvoiceProps {
    isRiskHandled: boolean;
    handledInfo?: IHandledInfo;
    currentReasonCode: RiskFactorReasonCode;
}

export const FirstTimeInvoice = ({ isRiskHandled, handledInfo, currentReasonCode }: IFirstTimeInvoiceProps) => {
    return (
        <>
        <h4>{getRiskFactorNameTranslation(RiskFactorCode.FirstTimeInvoice)}</h4>
        { isRiskHandled ? <>
            <div className="risk-factor-icon-container__handlerDetails">
                <p style={{margin: "0px"}}>{getLabelTranslation('#Core/handledBy')}</p>
                <p>{handledInfo.handledByUser.firstName}</p>
                <p style={{marginRight: "5px"}}>{handledInfo.handledByUser.lastName}</p>
                <DatePresenter 
                    date={new Date(handledInfo.handledAt)} 
                    format="G"
                />
            </div>
            <p>{resolveReasonCodeTranslation(currentReasonCode)}</p> </> :
            <p>{getLabelTranslation("#Core/firstTimeInvoiceInfoText")}</p>
        }
        </>
    );
};

function resolveReasonCodeTranslation(handlingReasonCode: RiskFactorReasonCode){
    switch (handlingReasonCode) {
        case RiskFactorReasonCode.SupplierChanged:
            return getLabelTranslation("#Core/handlingReasonCodeSupplierChanged");
        case RiskFactorReasonCode.InvoiceArchived:
            return getLabelTranslation("#Core/handlingReasonCodeInvoiceArchived");
        case RiskFactorReasonCode.InvoiceDeletedDuplicateInvoice:
            return getLabelTranslation("#Core/handlingCodeInvoiceDeletedDuplicateInvoice");
        case RiskFactorReasonCode.InvoiceDeletedFraudAttempt:
            return getLabelTranslation("#Core/handlingCodeInvoiceDeletedFraudAttempt");
        case RiskFactorReasonCode.InvoiceDeletedOther:
            return getLabelTranslation("#Core/handlingCodeInvoiceDeletedOther");
        default:
            console.warn(`[FirstTimeInvoice] handling reason code ${handlingReasonCode} was not implemented for this risk factor`);
            break;
    }
}