/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/helpers"/>
import * as ko from "knockout";

function constructorIs(type:any) {
    return function(data:any) {
        data = ko.utils.unwrapObservable(data);
        return data && data.constructor === type;
    };
}

export const isArray = constructorIs(Array);

export const isDate = constructorIs(Date);

export function tryUnwrap(o:any) {
    let accessor = o;
    let data = o;

    if (!ko.isObservable(data)) {
        accessor = null;
    } else {
        data = ko.utils.unwrapObservable(accessor);
    }

    return {
        accessor: accessor,
        data: data
    };
}

export const unwrap = ko.utils.unwrapObservable;