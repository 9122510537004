/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/provider/translations"/>
import { isEmptyString } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import * as _ from "underscore";
import { getPrimitiveProperties, getProperties, isIgnored, isObsolete } from "Core/Medius.Core.Web/Scripts/Medius/components/resolver/type";
import { getPropertyTranslation, getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { removeQuotes } from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/lexeme/helpers";
import { getPropertyTypeForHints } from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/helpers/propertyType";

let cachedTranslatedProperties: Record<string, any> = {};
    
function prepareKeyForPropertyTranslation(type: string, path: string) {
    return ('#' + type + '/' + path);
}

function getPropertiesNames(type: any, onlyNotIgnored: any, onlyPrimitives: any) {
    let properties = (onlyPrimitives) ?
            getPrimitiveProperties(type, false) :
            getProperties(type, false);

    if (onlyNotIgnored) {
        properties = _.reject(properties, function (prop) {
            return isIgnored(type, prop);
        });
    }

    // remove Obsolete properties
    properties = _.reject(properties, function (prop) {
        return isObsolete(type, prop);
    });

    return properties;
}

export function getTranslatedProperties(type: string, onlyNotIgnored = false, onlyPrimitives = false) {
   if(isEmptyString(type)) {
        return [];
    }

    const cachePath = [type, onlyNotIgnored, onlyPrimitives].join("_");
    
    if (cachedTranslatedProperties[cachePath]) {
        return cachedTranslatedProperties[cachePath];
    }
    
    const properties = getPropertiesNames(type, onlyNotIgnored, onlyPrimitives);
    
    const map = _.map(properties, function (property) {
        let translation = getPropertyTranslation(prepareKeyForPropertyTranslation(type, property));
        
        if (translation && translation.indexOf('#') === 0) {
            translation = null;
        }
        
        if (translation) {
            translation = translation.trim();
        }

        return {
            property: property,
            translation: translation
        };
    });
    
    const sorted = _(map).sortBy(function (f) {
        return f.translation;
    });

    cachedTranslatedProperties[cachePath] = sorted;

    return sorted;
}

export function getTranslationPath(type: string, property: string) {
   if (isEmptyString(property) || isEmptyString(type)) {
        return null;
    }

    const path = property.split(".");

    const translations = _.map(path, function (p) {
        const propertyType = getPropertyTypeForHints(type, p);

        if (!propertyType) {
            return '';
        }

        const typeProperties = _(getTranslatedProperties(type)).where({ property: p });
        type = propertyType;
        
        if (typeProperties.length === 1 && !!_.first(typeProperties).translation) {
            return _.first(typeProperties).translation;
        }
        return p;
    });

    return translations;
}

export function getTranslation(type: string, property: string) {
    const path = getTranslationPath(type, property);

    if (!path) {
        return "";
    }

    const quotedPath = _.map(path,
        function(element) {
            if (element.indexOf(" ") === -1) {
                return element;
            }
            return "'" + element + "'";
        }
    );

    return quotedPath.join(".");
}

export function resolvePropertyFromTranslation(entityType: string, translationPath: string) {
    if (isEmptyString(translationPath) || isEmptyString(entityType)) {
        return null;
    }

    const path = translationPath.split(".");

    const properties = _.map(path, function (t) {
        const noQuotesTranslation = removeQuotes(t),
            typeProperties = getTranslatedProperties(entityType, true),
            matchingProperties = _(typeProperties).where({ translation: noQuotesTranslation });

        if (matchingProperties.length !== 1) {
            return t;
        }

        const propertyName = _.first(matchingProperties).property;

        if (isEmptyString(propertyName)) {
            return t;
        }

        entityType = getPropertyTypeForHints(entityType, propertyName);
        return propertyName;
    });

    return properties.join(".");
}

export function getTranslatedSortingOrders() {
    const orders = ['Asc', 'Desc'];

    return _.map(orders, function (el) {
        return getLabelTranslation("#Core/dataSearch" + el);
    });
}

export function resolveSortingOrderFromTranslation(translation: string) {
    if (getLabelTranslation("#Core/dataSearchDesc") === translation) {
        return "DESC";
    }

    return "ASC";
}

export function resolveSortingOrderTranslation(order: string) {
    if (isEmptyString(order) || typeof order !== "string") {
        return null;
    }

    order = order.toLowerCase();
    order = order.charAt(0).toUpperCase() + order.slice(1);
    
    return getLabelTranslation("#Core/dataSearch" + order);
}

export function resetCache() {
    cachedTranslatedProperties = {};
}