/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/customfields/listValue/factory" />
import * as model from "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/default/model";
import * as utils from "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/utils";

const dataProviderFactory = require("Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/customfields/listValue/dataProvider"),
defaultsFactory = require("Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/default/defaults");

export function create(bindingParameters: any){
    const optionsExtension = {
        autofocus: bindingParameters.autofocus,
        disabled: bindingParameters.disabled,
        required: bindingParameters.required
    },
    providerOptionsExtension = {
        list: bindingParameters.list
    },
    params = utils.createParameters(bindingParameters, optionsExtension, providerOptionsExtension, defaultsFactory,
        dataProviderFactory);

    params.resultItemTemplate = "Core:templates/customFieldAutocompleterItem.html";

    return model.create(params);
}