///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/customfields/list/dataProvider"/>
import { Provider as base } from "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/entity/dataProvider";
import * as backendErrorHandler from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import * as rpc from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rpc";

export class Provider extends base {
    constructor() {
        super();
    }

    search(phrase:string, limit:number, options:any) {
        const params = {
            searchText: phrase,
            contextCompanyId: this.getId(options.company),
            maxResults: limit,
            documentType: options.documentType
        };

        const result = rpc.lightApi("CustomFieldsService", "GetCustomLists", params)
            .fail(function (data) {
            backendErrorHandler.handleAnyError(data);
        });

        return result;
    }
}

export function create() {
    return new Provider();
}