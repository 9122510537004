///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/ReadOnlyRelation/relation"/>
import * as entityGrid from "Core/Medius.Core.Web/Scripts/Medius/components/grid/entity/grid";
import { DataSource } from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/ReadOnlyRelation/dataSource";
import * as typeResolver from "Core/Medius.Core.Web/Scripts/Medius/components/resolver/type";

class ErrorsTab {
    Grid: any;
    constructor(entityWithContext:any, companyContextId:number, tabModel:any) {
        const entity = entityWithContext.Entity;
        const propertyName = tabModel.RelatedProperty();
        const collection = entity[propertyName];
        const elementType = typeResolver.getCollectionPropertyElementType(entity.$type(), propertyName);

        this.Grid = this.createGrid(elementType, collection);
    }

    createGrid(elementType:string, collection:ko.ObservableArray<any>) {
        const options = {
            dataSource: new DataSource(collection)
        };

        return entityGrid.create(elementType, options);
    }
}

export = function(entityWithContext:any, companyContextId:number, tabModel:any) {
    return new ErrorsTab(entityWithContext, companyContextId, tabModel);
};