/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/lib/culture"/>
import * as logger from "Core/Medius.Core.Web/Scripts/Medius/lib/logger";
import {Globalize} from "Core/Medius.Core.Web/Scripts/Medius/lib/globalize";


let currentCode = "";
const defaultInvariantCultureCode = "en-US";
const globalizeDefaultCulture = "default";

export function getCode() {
    if (!currentCode)
        throw Error("Not initialized yet! Use: 'setCurrent' first.");

    return currentCode;
}

export function getCodeForInvariantCulture() {
    return defaultInvariantCultureCode;
}

export function getDateFormat(patternKey: string) {
    return Globalize.cultures[getCode()]
        .calendars.standard.patterns[patternKey];
}

export function getNumberFormatDefinition() {
    return Globalize.cultures[getCode()]
        .numberFormat;
}

export function baseCodeFor(code: string) {
    return code.split("-")[0];
}

function setCode(code: string) {
    let definition = Globalize.cultures[code];

    if (!definition) {
        definition = Globalize.cultures[baseCodeFor(code)]
            || Globalize.cultures[getCodeForInvariantCulture()]
            || Globalize.cultures[globalizeDefaultCulture];

        logger.warn(`Globalize does not have a culture: '${code}'. A '${definition.name}' will be used.`);
    }

    currentCode = definition.name;
}

export function setCurrent(cultureCode?: string) {
    setCode(cultureCode || defaultInvariantCultureCode);
    logger.log(`Current culture: '${getCode()}'.`);
}

