///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/utils"/>
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";

//this function returns the calculated bool based on medius copilot requirements R1.3 and R1.4
export function calculateOperational(documentType: string, taskName: string){
    if( 
        (documentType === "Medius.ExpenseInvoice.Entities.ExpenseInvoice, Medius.ExpenseInvoice.Common" && taskName === getLabelTranslation("#ExpenseInvoice/CodeAndAuthorizeTaskGroupDescription") ) ||
        (documentType === "Medius.OrderbasedInvoice.Entities.OrderbasedInvoice, Medius.OrderbasedInvoice.Common" && 
            ( taskName === getLabelTranslation("#OrderbasedInvoice/AnalyzeDeviationsTaskGroupDescription") ) ||   
            ( taskName === getLabelTranslation("#OrderbasedInvoice/authorizeDeviationsTaskGroupDescription") ) ||  
            ( taskName === getLabelTranslation("#OrderbasedInvoice/authorizeInvoiceAmountTaskGroupDescription") )
        )
    ){
        return true;
    }

    return false;
}

export function scrollToBottom(lastMessageRef: React.MutableRefObject<any>){
    if(lastMessageRef.current){
        lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
}

export function getDelegatedMessage(messages: string[]){
    const filteredDelegationMessage = messages.filter(message => {
        const comparisonString = message;

        const originalString = getLabelTranslation("#Core/documentHasBeenDelegatedFrom_user");
        const regexString = originalString.replace("{0}", '(.*)');
        const regex = new RegExp(regexString);
        const matchFromUser = regex.test(comparisonString);

        const originalString2 = getLabelTranslation("#Core/documentHasBeenDelegatedFrom_role_user");
        const regexString2 = originalString2.replace("{0}", '(.*)').replace("{1}", '(.*)');
        const regex2 = new RegExp(regexString2);
        const matchFromRoleUser = regex2.test(comparisonString);

        return (matchFromUser || matchFromRoleUser);
    });
    
    return filteredDelegationMessage.length > 0 
        ? filteredDelegationMessage[0] + `. ${getLabelTranslation("#Core/temporaryDelegationShortDescription")}.` 
        : "";
}

export function getEscalationMessage(messages: string[]){
    const personalRoleEscalationMessage = getLabelTranslation("#Core/escalationMessage_personalRole");
    const roleEscalationMessage = getLabelTranslation("#Core/escalationMessage_role");

    let foundPersonalEscalationMessage = false;
    let foundRoleEscalationMessage = false;

    for(const message of messages){
        if(message === personalRoleEscalationMessage){
           foundPersonalEscalationMessage = true;
           break;
        }
    
        const regexRoleEscalation = roleEscalationMessage.replace("{0}", "(.*)");
        const regex = new RegExp(regexRoleEscalation);
    
        const matchFromRoleEscalation = regex.test(message);
        if(matchFromRoleEscalation){
            foundRoleEscalationMessage = true;
            break;
        }
    }
    
    if(foundPersonalEscalationMessage || foundRoleEscalationMessage){
        return getLabelTranslation("#Core/escalationShortDescription");
    }

    return "";
}

export function focusOnElement(elementRef: React.MutableRefObject<any>){
    elementRef.current && elementRef.current.focus();    
}