/// <amd-module name="Core/Medius.Core.Web/Scripts/components/topBar/uxMetrics"/>

import { logEvent } from "../../lib/metricsLogging/metricLogger";

export function log(scenario: string, label?: string): void {
    logEvent(`HomePage - ${scenario}`, label);
}

export function logHomeClicked(componentName: string): void {
    log("HomePage button clicked", componentName);
}
