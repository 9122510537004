///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Validation.ValidatorConfiguration/contextDataProvider"/>
import { Provider } from "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/entity/dataProvider";

class ContextDataProvider extends Provider {
    constructor() {
        super();
    }
    filterPhrase(contextData:any, phrase:string) {
        const regex = new RegExp(`${phrase.replace("*", "(.*)").toLowerCase()}`);

        const returnRes = this.filter(contextData, (context:any) => {
            return regex.test(context.$type.toLowerCase());
        });

        return returnRes;
    }

    search(phrase:string, limit:number, options:any) {
        return super.search("", limit, options)
            .pipe((data) => {
                return this.filterPhrase(data, phrase);
        });
    }
}

export function create() {
    return new ContextDataProvider();
}