/// <amd-module name="Core/Medius.Core.Web/Scripts/shared/components/invoiceSummary"/>
import * as React from "react";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { IconCommerceSupplierRegular, IconTimeCalendarBlankRegular, IconCommerceMoneyRegular, IconCommunicationHashRegular } from '@medius/ui-controls';

interface InvoiceSummaryProp {
    iconCode: string;
    keys: string[];
    values: string[];
}

interface InvoiceSummaryProps {
    identifiers: InvoiceSummaryProp[];
}

const iconMapping: {[key: string]: JSX.Element} = {
    "supplier": <IconCommerceSupplierRegular size="small" />,
    "money": <IconCommerceMoneyRegular size="small" />,
    "calendar": <IconTimeCalendarBlankRegular size="small" />,
    "hash": <IconCommunicationHashRegular size="small" />
};

export function InvoiceSummary({ identifiers }: InvoiceSummaryProps) {
    return <>
        {identifiers.map((identifier, i) => {
            return (<div key={i} className="document-summary__section">
                {<div className="document-summary__icon">
                {iconMapping[identifier.iconCode]}
                </div>}
            <div className="document-summary__section__identifiers">
                <div className="document-summary__column">
                {
                    identifier.keys.map((k,i) => {
                        return (<div key={i} className="document-summary__key">{translate(k)}</div>);
                    })
                }
                </div>
                <div className="document-summary__column document-summary__column--wrapped">
                {
                    identifier.values.map((v,i) => {
                        return (<div key={i} className="document-summary__value">{v}</div>);
                    })
                }
                </div>
            </div>
        </div>);
        })}
    </>;
}