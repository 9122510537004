/// <amd-module name="Core/Medius.Core.Web/Scripts/shared/bindings/intermediateCheckbox"/>

import * as _ from "underscore";
import * as ko from "knockout";
import * as utils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";

const intermediateCheckbox = {
    init(element: HTMLInputElement, valueAccessor: any) {
        const value = ko.unwrap(valueAccessor());
        const checked = value.checked as ko.ObservableArray<string>;
        const all = value.all as ko.ObservableArray<string>;
        const subset = !!value.subset ? ko.observableArray(value.subset as string[]) : all;
        
        function updateModel() {
            checked.removeAll(subset());

            if (element.checked) {
                checked([...checked(), ...subset()]);
            }
        };

        function updateElement() {
            element.checked = _.intersection(checked(), subset()).length === subset().length;
            element.indeterminate = _.intersection(checked(), subset()).length > 0 && !element.checked;
        };

        element.addEventListener("click", updateModel);
        ko.computed(updateElement, null, { disposeWhenNodeIsRemoved: element });
    }
};

utils.registerBinding("intermediateCheckbox", intermediateCheckbox);
