/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/editors/number/localizer"/>

import { resolve as localizerResolve } from "Core/Medius.Core.Web/Scripts/Medius/components/localizer/localizer";

const decimalResolutionDefault = 2;
const trimZerosDefault = false;
const roundDecimalDefault = "midpoint";
const notLocalizedDefault = false;

export class LocalizerAdapter {

    options: {
        decimalResolution: number, 
        trimZeros: boolean, 
        roundDecimal: string,
        notLocalized: boolean
    };
    decimalLocalizer: any;

    constructor(decimalResolution = decimalResolutionDefault, 
                trimZeros = trimZerosDefault, 
                roundDecimal = roundDecimalDefault, 
                notLocalized = notLocalizedDefault) {

        this.decimalLocalizer = localizerResolve("System.Decimal");
        this.options = {
            decimalResolution: decimalResolution,
            trimZeros: trimZeros,
            roundDecimal: roundDecimal,
            notLocalized: notLocalized
        };
    }

    fromString = (value: string) => {
        return this.decimalLocalizer.fromString(value, this.options);
    };

    toString = (value: any) => {
        return this.decimalLocalizer.toString(value, this.options);
    };
}

export function create(
    decimalResolution = decimalResolutionDefault, 
    trimZeros = trimZerosDefault, 
    roundDecimal = roundDecimalDefault, 
    notLocalized = notLocalizedDefault
    ){
    return new LocalizerAdapter(decimalResolution, trimZeros, roundDecimal, notLocalized);
}