/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/task/models/taskHandlingContext" />

import {context} from "Core/Medius.Core.Web/Scripts/Medius/apps/task/settings";
import type = require("Core/Medius.Core.Web/Scripts/Medius/core/type");

export function extendViewmodelContextFromTaskData(vmContext: any, task: any, fullscreenEnabled: boolean = false) {
    const taskContextData = {
        taskId: task.Id,
        documentType: task.Document && type.getTypeName(task.Document.$type),
        workflowStep: task && task.ActivityContext && task.ActivityContext.Name && task.ActivityContext.Name,
        isReadOnly: task && task.Perspective && task.Perspective.ReadOnly,
        isFullscreenEnabled: fullscreenEnabled,
        reasonCodeName: task.ReasonCodeName
    };
    vmContext.set(context, taskContextData);
}

export function extendViewmodelContextFromCreateDocument(vmContext: any, documentType: string) {
    const taskContextData = {
        taskId: null as number,
        documentType: documentType,
        workflowStep: "Create",
        isReadOnly: false,
        isFullscreenEnabled: false
    };
    vmContext.set(context, taskContextData);
}