/// <amd-module name="Core/Medius.Core.Web/Scripts/Models/Medius.Core.Entities.Workflow.EndTask"/>
import { observable, observableArray, computed } from "knockout";
import { getBasePath } from "Core/Medius.Core.Web/Scripts/Medius/lib/path";
import { handleAnyError } from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import { getTypeName } from "Core/Medius.Core.Web/Scripts/Medius/core/type";
import { success } from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import { getOutboxInstance } from "Core/Medius.Core.Web/Scripts/components/outbox/outbox";
import {InboxInstance} from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/inbox";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as labelsHandlerFactory from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/task/labelsHandlerFactory";
import { request } from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rpc";
import store = require("Core/Medius.Core.Web/Scripts/Medius/core/store/handling");
import newRepository = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/mapping/collection/instance");

export class EndTask {

    constructor(viewModel: any, data: any, context: any) {
        const self = viewModel;
        self._ctx = context;

        self.labelsHandler = observable(null);

        // Remove after Viewmodel Repository migration
        const requestOngoing = observable(false);

        self.disableButtons = computed(function () {
            return requestOngoing() || getOutboxInstance().isInProgessOrDone(self.Id());
        });

        self.disableRedistribution = observable(false);
        self.AvailableRedistribution = observableArray();
        self.ChoosenRedistribution = observableArray();
        self.active = computed(function () {
            return false;
        });

        self.handleRedistribution = function (_ev: any) { };

        self.handleTaskHandler = function (_resultCode: any, _ev: any) { };

        self.getLabelsHandler = function () {
            return labelsHandlerFactory.create(self.Document().Id())
                .done(function (labelsHandler: any) {
                    self.labelsHandler(labelsHandler);
                });
        };

        self.allowSaveDocument = computed(function () {
            return self.Perspective && !self.Perspective().ReadOnly();
        });

        self.saveTaskDocument = function () {
            let task = null;

            const defaultSaveHandler = {
                saveTask: function (viewModelDto: any) {
                    return request("TaskManager", "SaveTaskDocument", {
                        task: viewModelDto
                    });
                }
            };

            function saveHandler() {
                const handler = self.Document().customSaveTaskDocument || defaultSaveHandler;
                return handler.saveTask(self.toDto()).done(function () {
                    self.Document().EditMode(false);
                    success(getLabelTranslation("#Core/documentHasBeenSaved"));
                    store.removeItem(self.Id());
                    InboxInstance.reloadCurrentTask();
                })
                    .fail((xhr: any) => handleAnyError(xhr));
            }

            requestOngoing(true);
            task = context.queue.enqueue({
                action: saveHandler,
                name: 'save-document'
            });

            task.getDeferred().always(function () {
                requestOngoing(false);
            });
        };

        self.typeName = computed(function () {
            return getTypeName(self.$type());
        });

        self.actionsTemplate = "Core:templates/Task/HandleEndTask.html";
        self.messagesTemplate = "Core:components/taskMessages/messages.html";
        self.dashboardContextLink = computed(function () {
            const link = ["#/Dashboard", "Task", self.Id()].join("/");
            return getBasePath() + link;
        });

        self.disableRedistribution(true);
        self.toDto = context.toJS.bind(context, self);
        self.errors = observableArray(getOutboxInstance().getErrorsForTask(self.Id()));
        self.errorsVisible = observable(true);
        self.shouldRecalculate = observable(true).extend({ notify: 'always' });
        self.hideErrors = function () { };
    }
}

export function register() {
    newRepository.register("Medius.Core.Entities.Workflow.EndTask, Medius.Core.Common", EndTask);
}