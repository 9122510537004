///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/document/models/Creator"/>
import * as ko from "knockout";
import * as type from "Core/Medius.Core.Web/Scripts/Medius/core/type";
import * as koutils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as $ from "jquery";
import * as events from "Core/Medius.Core.Web/Scripts/Medius/apps/document/events";

class EditorViewModel {
    documentViewModel: any;
    docType: any;
    viewPath: ko.Observable<string>;
    containerElement: JQuery;
    constructor(documentViewModel: any, containerElement: any) {
        this.documentViewModel = documentViewModel;
        this.docType = type.getTypeName(koutils.unpack(documentViewModel.$type));
        
        this.viewPath = ko.observable(`Display/${this.docType}/Create`);

        this.containerElement = $(containerElement);
        this.registerEventHandlers();
    }

    registerEventHandlers() {
        this.containerElement.on(events.modelReset, () => {
            this.viewPath(null);
        });
    }
}

export = function (documentViewModel: any, containerElement: any) {
    return new EditorViewModel(documentViewModel, containerElement);
};
