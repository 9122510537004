///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/components/copyRequisition/DTOs/copyRequisitionValidationResultDto" />
import { Address } from "Core/Medius.Core.Web/Scripts/components/procurement/cart/cart";
import { ProductDto } from "Core/Medius.Core.Web/Scripts/components/procurement/cart/productDto";
import { FreeTextItem } from "Core/Medius.Core.Web/Scripts/components/procurement/cart/freeTextItem";
import { CodingAndTax } from "Core/Medius.Core.Web/Scripts/components/procurement/cart/coding";


export type CopyRequisitionResultDto = {
    headerDetails: CopyRequisitionHeaderDetailsDto,
    lineDetails: CopyRequisitionLineDetailsDto[]
};

export type CopyRequisitionHeaderDetailsDto = {
    headerError: string,
    invoiceAddress: Address,
    deliveryAddress: Address,
    buyerId?: number,
    shippingMarks: string,
    orderTypeId?: number,
    goodsReceiptedById?: number,
    additionalInformation: string,
    scrubbedHeaderFields: string[]
};

export type CopyRequisitionLineDetailsDto = {
    lineNumber: number,
    validationErrorMessages: string[],
    product: ProductLineDetailsDto,
    freeTextItem: FreeTextLineDetailsDto,
    punchOutItem: PunchOutLineDetailsDto,
    lineDeliveryAddress: Address,
    lineAdditionalInformation: string
};

export type CodingAndTaxValidationResultDto = {
    codingInfo: CodingInformation[],
};

type FreeTextLineDetailsDto = {
    freeTextLine: FreeTextItem,
    quantity: number
};

type ProductLineDetailsDto = {
    productDetails: ProductDto,
    quantity: number
};

type PunchOutLineDetailsDto = {
    partNumber: string,
    supplierName: string
};

type CodingInformation = {
    lineNumber: number,
    codingAndTax: CodingAndTax,
    isCodingValid: boolean,
    isCategoryValid: boolean
};