///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/transformers/serialize/defaults"/>

function noop() {
}

export const shouldDescend = true;
export function shouldDescendProperty(name:string) {
    return name !== "__metadata__";
}
export const mapProperty = noop;
export const finalize = noop;