///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/presenters/timespan/model"/>
import * as textPresenter from "Core/Medius.Core.Web/Scripts/Medius/components/presenters/text/model";
import * as _ from "underscore";

class TimeSpan {
    constructor(binding:any) {
        const defaults = {
            options: {
                placeholder: 'd.HH:mm:ss'
            }
        };
        
        binding = _({}).extend(defaults, binding);
        _(this).extend(textPresenter.create(binding));
    }
}

export function create(binding:any) {
    return new TimeSpan(binding);
}