///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/longExportConfirmer"/>
import * as $ from "jquery";
import * as ko from "knockout";
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import { create as asyncExportStarterFactory } from "Core/Medius.Core.Web/Scripts/Medius/core/export/async/starter";
import * as exportLimits from "Core/Medius.Core.Web/Scripts/Medius/core/export/async/limits";

const binding = {
    init: function(element:any, valueAccessor:any, allBindingsAccessor:any, viewModel:any, bindingContext:any) {
        const options = valueAccessor();

        let asyncExportStarter = asyncExportStarterFactory(options.action, options.rowsCount);
        const asyncExportStarterBindingContext = bindingContext.createChildContext(asyncExportStarter);
        let asyncExportStarterModalParentElement = document.createElement("div");
        document.body.appendChild(asyncExportStarterModalParentElement);
        ko.renderTemplate(
            "Core:templates/AsyncExportToExcelModalTemplate.html", asyncExportStarterBindingContext,
            {}, asyncExportStarterModalParentElement
        );
        let asyncExportStarterModalElement = $(asyncExportStarterModalParentElement).children()[0];

        function clickHandler() {
            if (exportLimits.isQuickExportLimitMet(options.rowsCount())) {
                options.action();
            } else {
                $(asyncExportStarterModalElement).modal('show');
            }
        }
        $(element).on("click", clickHandler);

        koUtils.addDisposeCallback(element, function() {
            $(element).off("click", clickHandler);
            asyncExportStarter.destroy();
            $(asyncExportStarterModalParentElement).remove();
            asyncExportStarterModalParentElement = null;
            asyncExportStarterModalElement = null;
            asyncExportStarter = null;
        });
    }
};

export function register() {
    koUtils.registerBinding("longExportConfirmer", binding);
}