/// <amd-module name="Core/Medius.Core.Web/Scripts/components/administration/autocompleters/company/companySearchServiceAutocompleter"/>
import * as React from "react";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { PromiseBasedCompanyAutocompleterContainer } from "Core/Medius.Core.Web/Scripts/components/administration/autocompleters/company/promiseBasedCompanyAutocompleterContainer";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as rpc from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rpc";
import { nameOf } from "Core/Medius.Core.Web/Scripts/Medius/core/extensions";

interface Company {
    Name: string
}

interface CompanyAutocompleterProps {
    readonly value: Company;
    readonly onChange: (event: ComboBoxChangeEvent) => void;
}

export const CompanyAutocompleter = ({
    value,
    onChange
}: CompanyAutocompleterProps) => {

    const getCompanies = (filter: string) =>
        rpc.request("CompanySearchService", "SearchCompanyByPhrase", { phrase: filter })
            .then(response => response.Results);

    return (
        <PromiseBasedCompanyAutocompleterContainer<Company>
            defaultLabel={translate("#Core/company")}
            getCompaniesPromise={getCompanies}
            selectedItem={value}
            textFieldSelector={nameOf<Company>("Name")}
            onCompanyChange={onChange}
            disabled={false} />
    );
};