///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Entities.DataSearch.DataSearchUserConfiguration/AdvancedSearch"/>

class AdvancedSearchTab {
    UserConfiguration: any;
    constructor(entityWithContext:any) {
        const configurationType = "Medius.Core.Entities.DataSearch.DataSearchUserConfiguration"; 

        this.UserConfiguration = entityWithContext.RelatedObjects[configurationType].RelatedEntities()[0];
    }
}
export = function (entityWithContext:any) {
    return new AdvancedSearchTab(entityWithContext);
};