///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Entities.User/Reminders/viewModel"/>
import * as ko from "knockout";
import * as featureToggles from "Core/Medius.Core.Web/Scripts/Medius/core/featureToggle";
import * as remindersGrid from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Reminders/grid";

class Reminders{
    Entity: any;
    grid: ko.Observable<any>;
    isActionableEmailsToggleVisible: ko.Observable<boolean>;
    constructor(entityWithContext:any) {
        this.Entity = entityWithContext.Entity;
        this.grid = ko.observable();
        this.isActionableEmailsToggleVisible = ko.observable(featureToggles.isActionableEmailsToggleVisible());

        this.createGrid();
    }

    createGrid() {
        remindersGrid.create(this.Entity.ReminderConfigurations).done((grid:any) => {
            this.grid(grid);
        });
    }

    dispose() {
        this.Entity = null;
    }
}

export = function(entityWithContext:any) {
    return new Reminders(entityWithContext/*, companyContextId, tabModel*/);
};