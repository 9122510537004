/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/performance/loggers/task"/>

import { last } from "underscore";
import { unwrap } from "knockout";
import { when } from "jquery";
import { trackEvent } from "Core/Medius.Core.Web/Scripts/appInsights";
import { start } from "./base";

import type = require("Core/Medius.Core.Web/Scripts/Medius/core/type");

let activeLoadMeasurement: any;

function getReadableDocumentType(documentTypeName: string) {
    return last(documentTypeName.split('.'));
}

function startHandle(scenario: string, documentType: string, workflowStep: string) {
    return start(scenario, documentType, workflowStep);
}

function startHandleMeasurementInner(task: any, scenario: string) {
    if (task.workflowStep && task.documentType) {
        return startHandle(scenario, task.documentType, task.workflowStep);
    } else {
        const documentType = getReadableDocumentType(type.getTypeName(unwrap(unwrap(task.Document).$type)));
        const workflowStep = unwrap(unwrap(task.ActivityContext).Name);

        return startHandle(scenario, documentType, workflowStep);
    }
}

export function startHandleMeasurement(task: any) {
    return startHandleMeasurementInner(task, "Handle document");
}

export function startHandleAsyncMeasurement(task: any) {
    return startHandleMeasurementInner(task, "Handle document without waiting on server response");
}

export function startLoadMeasurement() {
    activeLoadMeasurement = start();
    return activeLoadMeasurement;
}

export function handle(bindingContext: any, context: any) {
    stopLoadMeasurement(bindingContext, context);
}

export function stopLoadMeasurement(bindingContext: any, context: any) {
    const taskContext = context.get('task-handling-context');
    const stepName = !!taskContext ? taskContext.workflowStep : 'no-context';
    const documentType = !!taskContext ? getReadableDocumentType(taskContext.documentType) : 'no-context';
    const taskId = !!taskContext ? taskContext.taskId : 0;
    const measurement = activeLoadMeasurement;

    when(bindingContext.$loadComplete).then(() => {
        if (measurement) {
            trackEvent({
                name: "Load document",
                properties: {
                    documentType: documentType,
                    stepName: stepName,
                    taskId: taskId,
                    time: measurement.getElapsedTime(),
                }
            });
        }
    });


    activeLoadMeasurement = null;
}