///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Schedulers.ReportStuckIntegrationMessagesJobContext/Editor"/>
import mappings = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/mapping/collection/instance");
import * as _ from "underscore";

import { viewModelFactory as base } from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Schedulers.ScheduledJobContext/Editor";

function viewModelFactory(viewModel:any) {
    base(viewModel);

    viewModel.Template = 'Core:templates/Administration/ReportStuckIntegrationMessagesJobContext/EditorTemplate.html';
}

export function register() {
    mappings.register("Medius.Core.Schedulers.ReportStuckIntegrationMessagesJobContext", _.constant(true), viewModelFactory);
}