///<amd-module name = "Core/Medius.Core.Web/Scripts/Models/Medius.Core.Entities.NewInbox.TaskTab"/>
import mappings = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/mapping/collection/instance");
import * as ko from "knockout";

export function register() {
    mappings.register("Medius.Core.Entities.NewInbox.TaskTab", function (vm:any) {

        vm.wasActivated = ko.observable(false).extend({ notify: 'always' });

        vm.tabActivationRequested = function () {
            vm.wasActivated(true);
        };

        vm.isDefaultActive = vm.IsDefault();

        vm.IsTabActive = ko.observable(vm.isDefaultActive);

        vm.IsLoading = ko.observable(false);

        vm.cssClass = "this-tab tab-pane";

        vm.tabId = ko.computed(function () {
            return "task-tab-" + vm.Id();
        });

        vm.tabHref = ko.computed(function () {
            return "#" + vm.tabId();
        });

        vm.attributes = ko.computed(function () {
            return {
                "id": vm.tabId(),
                "data-tab-id": vm.tabId(),
                "data-separate": vm.IsSeparateInFullscreen()
            };
        });

        vm.tabViewPath = ko.computed(function () {
            return ["Display", vm.ForType() || "Medius.Data.Entity", vm.ViewName()].join("/");
        });

        vm.isOfCustomType = function() {
            return vm.tabViewPath().includes("CustomTabs");
        };

        vm.active = ko.observable(vm.isDefaultActive);

        vm.isDropdown = ko.observable(false);

        vm.resetActive = function () {
            vm.wasActivated(vm.isDefaultActive);
            vm.active(vm.isDefaultActive);
        };

        vm.activate = function () {
            vm.wasActivated(true);
            vm.active(true);
        };

        vm.deactivate = function () {
            vm.wasActivated(false);
            vm.active(false);
        };

        vm.dispose = function() {
            vm.tabViewPath.dispose();
            vm.attributes.dispose();
            vm.tabHref.dispose();
            vm.tabId.dispose();
        };
    });
}
