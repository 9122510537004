// this path was not changed due to being referenced in Core/Medius.Core.Web/Scripts/Medius/components/presenters/utils.js
/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/presenters/amount/model"/>

import * as settingsProvider from "Core/Medius.Core.Web/Scripts/Medius/core/settingsProvider";
import { FinanceComponent } from "Core/Medius.Core.Web/Scripts/Medius/components/presenters/financeComponent/model";

type AmountParams = {
    value: ko.Observable<number>;
};

class Amount extends FinanceComponent{
    create: () => any;
    constructor(bindingParameters: AmountParams){
        super(bindingParameters);
    }

    getResolution(currencyCode: string) {
        return settingsProvider.getAmountDisplayResolution(currencyCode);
    }
}

export function create (bindingParameters: AmountParams) {
    return new Amount(bindingParameters).create();
}