/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/wizard/wizard"/>
import * as ko from "knockout";
import * as _ from "underscore";

const defaults = {
    template: "Core:Medius/components/wizard/wizard.html",
    onClose: function () {
    },
    onCancel: function () {
    }
};

export class AbstractWizard {
    skipNextStep: boolean;
    currentStep: ko.Computed<any>;
    currentStepIndex: ko.Observable<number>;
    instanceObservable: any;
    maxStepIndex: number;
    options: any;
    isVisible: ko.Observable<boolean>;
    currentStepName: ko.Computed<any>;
    data: any;
    steps: any[];
    isVisibleSub: ko.Subscription;

    init() {
        this.openNext();
    }

    openNext() {
        const hasNotBeenOpenedYet = !this.currentStep();
        if (hasNotBeenOpenedYet || this.currentStep().validate(this)) {
            if (!hasNotBeenOpenedYet) {
                this.currentStep().beforeNext(this);
            }

            this.openStep(this.skipNextStep ? 2 : 1);
            this.currentStep().onOpen(this);

            return true;
        }

        return false;
    }

    openPrev() {
        const hasNotBeenOpenedYet = !this.currentStep();

        if (!hasNotBeenOpenedYet) {
            this.currentStep().beforePrev(this);
        }

        if (this.openStep(this.skipNextStep ? -2 : -1)) {
            this.currentStep().onOpenBack(this);

            return true;
        }

        return false;
    }

    openStep(diff: any) {
        const nextStep = this.currentStepIndex() + parseInt(diff, 10);

        if (nextStep >= 0 && nextStep <= this.maxStepIndex) {
            this.currentStepIndex(nextStep);
            this.skipNextStep = false;

            return true;
        }

        return false;
    }

    closeWizard() {
        this.isVisible(false);

        if (this.instanceObservable && ko.isObservable(this.instanceObservable)) {
            this.instanceObservable(null);
        }
    }

    cancel() {
        if (typeof this.options.onCancel === "function") {
            this.options.onCancel();
        }

        this.closeWizard();
    }

    close() {
        if (typeof this.options.onClose === "function") {
            this.options.onClose();
        }

        this.closeWizard();
    }

    reset() {
        this.data = {};
        this.currentStepIndex(0);
    }

    dispose() {
        _(this.steps).each(function (step) {
            step.dispose();
        });

        this.isVisibleSub.dispose();
        this.currentStepName.dispose();
        this.currentStep.dispose();
        this.reset();
    }
}

/**
 *  This is legacy component. Use typedWizard.ts instead
 */
export class Wizard extends AbstractWizard{
    skipNextStep: boolean;
    isCurrentStepFullyCustom: ko.Observable<boolean>;
    isLoading: ko.Observable<boolean>;
    totalStepsCss: string;
    
    constructor(steps: any, options: any) {
        if (!_.isArray(steps) || steps.length === 0) {
            throw new Error("Cannot initialize wizard without providing wizard steps");
        }
        super();
        this.skipNextStep = false;
        this.options = _.extend({}, defaults, options);
        this.isCurrentStepFullyCustom = ko.observable(false);
        this.isLoading = ko.observable(false);
        this.isVisible = ko.observable(true);
        this.steps = steps;
        this.totalStepsCss = "steps-" + steps.length;
        this.maxStepIndex = steps.length - 1;
        this.currentStepIndex = ko.observable(-1);
        this.currentStep = ko.computed(() => {
            if (this.currentStepIndex() === -1)
                return null;
            return steps[this.currentStepIndex()];
        });

        this.currentStepName = ko.computed(() => {
            return (this.currentStep()) ? this.currentStep().name : null;
        });
        this.data = {};
        this.instanceObservable = null;

        this.isVisibleSub = this.isVisible.subscribe((newValue) => {
            if (newValue === false) {
                this.cancel();
            }
        });

        this.init();
    }
}

export function create(steps: any, options: any) {
    return new Wizard(steps, options);
}

export function derive() {
    return Wizard;
}
export const type = Wizard;