/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/inbox/bulk/modal"/>
import * as ko from "knockout";

import { IStep } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/bulk/step";
import unique from "Core/Medius.Core.Web/Scripts/lib/collections/unique";
import {translate} from "Core/Medius.Core.Web/Scripts/lib/globalization";
import {error} from "Core/Medius.Core.Web/Scripts/Medius/core/notification";

export class Modal {
    public isLoading: ko.Observable<boolean>;
    public isVisible: ko.Observable<boolean>;

    public steps: IStep[];
    public tasks: any[];
    public tasksIds: number[];
    public documentType: string;
    public activity: string;
    public taskDescription: string;

    public data: any;
    public options: any;

    public totalStepsCss: string;
    public maxStepIndex: number;

    public instanceObservable: ko.Observable<any>;
    public currentStepIndex: ko.Observable<number>;
    public currentStep: ko.Computed<any>;
    public currentStepName: ko.Computed<string>;
    public isLastStep: ko.Computed<boolean>;
    public isIntermediateStep: ko.Computed<boolean>;

    public isVisibleSub: ko.Subscription;

    constructor(tasks: any[], options: any, steps: IStep[], template: string) {
        if (tasks.length === 0)
            throw "Argument 'tasks' must contain at least one task.";

        this.isVisible = ko.observable(true);
        this.isVisibleSub = this.isVisible.subscribe(newValue => {
            if (newValue === false)
                this.close();
        });

        this.isLoading = ko.observable(false);
        this.instanceObservable = null;

        this.steps = steps;
        steps.forEach((step, i) => {
            step.text = (i + 1).toString();
        });
        this.totalStepsCss = "steps-" + this.steps.length;
        this.maxStepIndex = this.steps.length - 1;
        
        this.currentStepIndex = ko.observable(-1);
        this.currentStep = ko.pureComputed(() => {
            if (this.currentStepIndex() === -1)
                return null;
            return this.steps[this.currentStepIndex()];
        });
        this.currentStepName = ko.pureComputed(() => (this.currentStep()) ? this.currentStep().name : null);

        this.isIntermediateStep = ko.pureComputed(() => this.currentStepIndex() > 0 && this.currentStepIndex() < this.maxStepIndex);
        this.isLastStep = ko.pureComputed(() => this.currentStepIndex() === this.maxStepIndex);

        this.options = {
            template: template,
            ...options
        };

        this.tasks = tasks;
        this.tasksIds = tasks.map(task => task.id);
        this.documentType = this.resolveDocumentsType(tasks);
        if (!this.documentType) {
            error(translate("#Core/bulkOperationCannotBePerformedOnDifferentDocuments"));
        }

        this.activity = this.resolveActivity(tasks);
        this.taskDescription = this.resolveTaskDescription(tasks);

        this.openNext();
    }

    public openStep(diff: number) {
        const currentStep = this.steps[this.currentStepIndex()];
        if (currentStep) {
            currentStep.active(false);
            currentStep.done(diff > 0);
        }

        const nextStepIndex = this.currentStepIndex() + diff;
        if (nextStepIndex >= 0 && nextStepIndex <= this.maxStepIndex) {
            const nextStep = this.steps[nextStepIndex];
            nextStep.active(true);
            nextStep.done(false);

            this.currentStepIndex(nextStepIndex);
            return true;
        }

        return false;
    }

    public openNext() {
        if ((!this.currentStep() || this.currentStep().validate(this)) && this.openStep(1)) {
            this.currentStep().onOpen(this);
            return true;
        }
        return false;
    }

    public openPrev() {
        if (this.openStep(-1)) {
            this.currentStep().onOpenBack(this);
            return true;
        }
        return false;
    }

    private closeModal() {
        this.isVisible(false);

        if (this.instanceObservable && ko.isObservable(this.instanceObservable)) {
            this.instanceObservable(null);
        }
    }
        
    public cancel() {
        this.closeModal();
    }

    public close() {
        if (this.options.onClose) {
            this.options.onClose();
        }

        this.closeModal();
    }
        
    public dispose() {
        this.steps.forEach(step => step.dispose(this));
        this.currentStep.dispose();

        this.currentStepIndex(0);
    }

    private resolveDocumentsType(tasks: any[]) {
        const types = unique(tasks.map(task => task.taskGroup.fullName), (fullName => fullName));
        return types.length === 1 ? types[0] : null;
    }

    private resolveActivity(tasks: any[]) {
        const activities = unique(tasks.map(task => task.Activity), (activity => activity));
        return activities.length === 1 ? activities[0] : null;
    }

    private resolveTaskDescription(tasks: any[]) {
        const taskDescriptions = unique(tasks.map(task => task.TaskDescription), (taskDescription => taskDescription));
        return taskDescriptions.length <= 1 ? translate(taskDescriptions[0]) : null;
    }
}
