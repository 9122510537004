/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/default/text/entity/number"/>
import { unpack } from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as typeBoundResolverFactory from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/typeBound";
import * as numberLocalizer from "Core/Medius.Core.Web/Scripts/Medius/components/editors/number/localizer";

export const resolver = typeBoundResolverFactory.create({
    priority: 0,

    type: "Medius.Core.Entities.Number",

    resolve: function (numberEntity: any) {
        let displayValue = unpack(numberEntity.Value);
        const displayResolution = unpack(numberEntity.Resolution);

        const localizer = numberLocalizer.create(
            displayResolution,
            true
        );

        displayValue = localizer.toString(displayValue);

        return { text: displayValue };
    }
});
