/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/inbox/bulk/steps/choose"/>
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { observableArray, observable, pureComputed } from "knockout";
import { Modal } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/bulk/modal";
import { Step } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/bulk/step";
import { Action } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/bulk/actions/action";
import { Workflow } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/bulk/actions/workflow";
import { Update } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/bulk/actions/update";
import { BulkOperationOnDocumentWithRisksWarning } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/bulkOperationOnDocumentWithRisksWarning";

export class Choose extends Step {
    public availableActions: ko.ObservableArray<Action>;

    public chosenActionId: ko.Observable<string>;
    public chosenAction: ko.Computed<any>;
    public areSelectedDocumentsWithRiskFactors: ko.Observable<boolean>;
    public bulkOperationOnDocumentWithRisksWarning = pureComputed(() => {
        return ({
            functionComponent: BulkOperationOnDocumentWithRisksWarning
        });
    });

    private isLoading: ko.Computed<boolean>;
    private isLoadingSub: ko.Subscription;

    constructor(tasks: any, chooseAction: (chosenAction: any) => void, selectedDocumentsWithRiskFactorsCount: number) {
        super(getLabelTranslation("#Core/chooseOperation"),
            "Core:Medius/apps/inbox/bulk/templates/choose.html");

        this.areSelectedDocumentsWithRiskFactors = observable(selectedDocumentsWithRiskFactorsCount > 0);
        this.availableActions = observableArray<Action>();
        this.chosenActionId = observable<string>();
        this.chosenAction = pureComputed(() => {
            return this.availableActions().find((action) => action.id === this.chosenActionId());
        });
        chooseAction(this.chosenAction);

        this.availableActions([
            new Workflow(tasks),
            new Update(tasks)
        ]);

        this.isLoading = pureComputed(() => {
            const unreadyAction = this.availableActions().find(action => !action.isReady());
            return !!unreadyAction;
        });

        this.isLoadingSub = this.isLoading.subscribe((isLoading) => {
            if (!isLoading) {
                this.isLoadingSub.dispose();
                const availableAction = this.availableActions().find(action => action.isAvailable());
                if (availableAction) 
                    this.chosenActionId(availableAction.id);
            }
        });
    }

    public dispose(modal: Modal): void {
        this.isLoadingSub.dispose();
    }

    public validate(modal: Modal) {
        if (this.chosenAction()) {
            modal.data = modal.data || {};
            modal.data.chosenAction = this.chosenAction();
            return true;
        }

        return false;
    }
}
