///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/workflow/management/instance"/>
import * as entityGrid from "Core/Medius.Core.Web/Scripts/Medius/components/grid/entity/grid";
import * as ko from "knockout";

import WorkflowManagmentDataSource = require("Core/Medius.Core.Web/Scripts/Medius/apps/workflow/management/workflowManagmentDataSource");
        
class Instance {
    WorkflowManagement:any;
    FavoritesLinks = ko.observableArray([]);
    Grid:any;
    IsSelected = ko.observable(false);
    disableButtons = ko.observable(false);  
    constructor(WorkflowManagement:any) {
        this.WorkflowManagement = WorkflowManagement;
        this.Grid = this.createGrid();
    }

    createGrid() {
        const options = {
            dataSource: new WorkflowManagmentDataSource(),
            pageSize: 20,
            onClickRow: function () {}
        };

        return entityGrid.create("Medius.Tenant.WorkflowComponent.InstanceInfo", options);
    }
}

export = function(app:any) {
    return new Instance(app);
};

