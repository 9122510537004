/// <amd-module name="Core/Medius.Core.Web/Scripts/components/topBar/topBarUtils"/>

import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";

const getNavLinkClassName = (isActive: boolean) =>
    isActive ? "top-bar-icon-holder top-bar-icon-holder--active" : "top-bar-icon-holder";

const isActivePage = (pagename: string, pathname:string) => {
    const truncatedPathname = pathname.split("/").filter(i => i!=="")[0]?.toLowerCase();
    const truncatedPagename = pagename.split("/").filter(i => i!=="")[0]?.toLowerCase();
    if(isNullOrUndefined(truncatedPagename) || isNullOrUndefined(truncatedPathname))
        return false;

    return truncatedPathname === truncatedPagename;
};

const ProcurementRoute = "/procurement";
const ProcurementCheckoutRoute = `${ProcurementRoute}/checkout`;

enum APANavigationRoutes {
    homeUrl = "/Home",
    inboxUrl = "/Tasks",
    dashboardUrl = "/Dashboard",
    advancedSearchUrl = "/AdvancedSearch",
    reportsUrl = "/Reports",
    createUrl = "/DocumentCreate",
    administrationUrl = "/Administration",
}

enum MenuNames {
    empty,
    userMenu,
    helpMenu
}

export { 
    getNavLinkClassName, 
    ProcurementRoute, 
    ProcurementCheckoutRoute, 
    MenuNames, 
    isActivePage,
    APANavigationRoutes
};