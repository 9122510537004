/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/inbox/InboxHeaderTemplate"/>
import * as React from "react";
import { Button,
    MenuButton,  
    TextBox, 
    Callout, 
    FlexLayout, 
    FlexSpacer, 
    ButtonGroup, 
    IconActionFilterFill, 
    IconActionSearchRegular, 
    IconActionRefreshRegular, 
    IconSystemSettingsFill,
    IconArrowsExpandFullRegular,
    IconControlsCheckboxSelected,
    IconActionDeleteRegular,
    IconArrowsChevronDownRegular
 } from "@medius/ui-controls";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import {InboxOptionsDropdownTemplate, InboxOptionsDropdownProps} from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/InboxOptionsDropdownTemplate";

interface InboxHeaderProps {
    refreshAction: () => void,
    fullscreenAction: () => void,
    handleOneByOneAction: () => void,
    searchAction: (keywords:string) => void,
    bulkWizardAction: () => void,
    hasTasks: boolean,
    toggleDropdownAction: (visibility:boolean) => void,
    isDropdownVisible: boolean,
    filterButtonAction: () => void,
    isFilterApplied: boolean,
    resetFilter: () => void,
    savedFilterName: string,
    dropdownProps: InboxOptionsDropdownProps
}

export function InboxHeaderTemplate({refreshAction, fullscreenAction, handleOneByOneAction, searchAction, filterButtonAction, bulkWizardAction, hasTasks, toggleDropdownAction, isDropdownVisible, isFilterApplied, resetFilter, savedFilterName, dropdownProps}: InboxHeaderProps) {
    const searchBoxRef = React.useRef<HTMLInputElement>(null);
    const optionsButtonRef = React.useRef<HTMLButtonElement>(null);
    const [filterNameState, setFilterNameState] = React.useState(""); 

    function doSearch() {
        const searchText = searchBoxRef.current.value;
        searchAction(searchText);
    }

    React.useEffect(() => {
      savedFilterName?setFilterNameState(" " + savedFilterName):setFilterNameState("");
    }, [savedFilterName]);
    
    return (<>
                <FlexLayout>
                    <ButtonGroup layout="row">
                        <Button
                            data-testid="btn-show-inbox-options"
                            ref = {optionsButtonRef}
                            label={getLabelTranslation("#Core/customizeInbox")}
                            hideLabel
                            icon={<IconSystemSettingsFill/>}
                            variant="secondary"
                            height="small"
                            onClick={() => toggleDropdownAction(true)}
                            />
                        <Button
                            data-testid="trigger-refresh-btn"
                            label={getLabelTranslation("#Core/refresh")}
                            hideLabel
                            icon={<IconActionRefreshRegular/>}
                            variant="secondary"
                            height="small"
                            onClick={() => refreshAction()}
                            />
                        <Button
                            data-testid="advanced-search-btn"
                            label={getLabelTranslation("#Core/filter")}
                            icon={<IconActionFilterFill />}
                            variant = "secondary"
                            height="small"
                            onClick={() => filterButtonAction()}
                        />
                    </ButtonGroup>
                    <div>
                        <form className="searchForm"
                            onSubmit={(e) => {
                            e.preventDefault();
                            doSearch();
                        }}>
                        <TextBox data-testid="simple-search-input" ref = {searchBoxRef}
                            placeholder={getLabelTranslation("#Core/search")} 
                            height="small"/>
                        <Button
                            data-testid = "trigger-search-btn"
                            label = {getLabelTranslation("#Core/search")}
                            height= "small"
                            onClick={doSearch}
                            variant = "secondary"
                            icon={<IconActionSearchRegular/>}
                            hideLabel
                            />
                        </form>
                    </div>
                    {isFilterApplied?
                    <Button 
                            data-testid="adv-search-reset-filter"
                            label={getLabelTranslation("#Core/resetFilter") + filterNameState}
                            icon={<IconActionDeleteRegular/>}
                            variant="inline"
                            height="small"
                            onClick={() => resetFilter()}/>:<></>
                    }
                    <FlexSpacer/>
                    <ButtonGroup layout="row">
                        <Button
                            data-testid="btn-bulk-operation"
                            label={getLabelTranslation("#Core/bulkOperation")}
                            icon={<IconControlsCheckboxSelected/>}
                            variant="secondary"
                            height="small"
                            onClick={() => bulkWizardAction()}
                            disabled={!hasTasks}
                            />
                        <Button
                            data-testid="btn-open-task-in-fullscreen"
                            label={getLabelTranslation("#Core/openInFullscreen") + " (F2)"}
                            hideLabel
                            icon={<IconArrowsExpandFullRegular/>}
                            variant="secondary"
                            height="small"
                            onClick={() => fullscreenAction()}
                            />
                        <MenuButton
                            icon={<IconArrowsChevronDownRegular/>}
                            items={[
                                {
                                    key:"handleOneByOne",
                                    label:getLabelTranslation("#Core/handleOneByOne")
                                }
                            ]}
                            variant="secondary"
                            height="small"
                            label={getLabelTranslation("#Core/handleOneByOne")}
                            hideLabel
                            menuSize="compact"
                            onItemSelected={() => handleOneByOneAction()}
                            disabled={!hasTasks}
                        />
                    </ButtonGroup>
                </FlexLayout>
                <Callout
                    className="optionsDropdownContainer" 
                    displayTargetRef={optionsButtonRef}
                    isOpen={isDropdownVisible}
                    position="bottom-end"
                    onCloseRequested={() => toggleDropdownAction(false)}>
                    <InboxOptionsDropdownTemplate 
                        folders = {dropdownProps.folders}
                        savedFilters = {dropdownProps.savedFilters}
                        documentTypes = {dropdownProps.documentTypes}
                        toggleDocumentType = {dropdownProps.toggleDocumentType}
                        toggleFolder = {dropdownProps.toggleFolder}
                        allFoldersVisible = {dropdownProps.allFoldersVisible}
                        allDocumentsVisible = {dropdownProps.allDocumentsVisible}
                        toggleAllFoldersAction = {dropdownProps.toggleAllFoldersAction}
                        toggleAllDocumentTypesAction = {dropdownProps.toggleAllDocumentTypesAction}
                        deleteFilter = {dropdownProps.deleteFilter}
                        applyFilter = {dropdownProps.applyFilter}/>
                </Callout>
            </>
    );
}