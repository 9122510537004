///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/transformers/update/utils"/>
import * as createUtils from "Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/transformers/create/utils";
import * as mappingResolver from "Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/mapping/resolver";

export function getCurrentViewmodel(input:any, context:any) {
    const mapping = mappingResolver.resolve(input, undefined);
    let key:any;

    if (mapping && mapping.key) {
        key = mapping.key(input);
    }

    if (key) {
        return context.cache.get(key);
    }

    return undefined;
}

export const getMetadata = createUtils.getMetadata;
export const hasProperty = createUtils.hasProperty;