///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/redistributionItem/model"/>
import * as baseModel from "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/default/model";
import * as defaultsFactory from "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/default/defaults";
import * as dataProviderFactory from "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/redistributionItem/dataProvider";
import * as utils from "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/utils";

export function create(bindingParams:any) {
    const optionsExtension = {
            disabled: bindingParams.disabled,
            required: bindingParams.required
        },
        providerOptionsExtension = {
            documentId: bindingParams.documentId,
            onlyPersonal: bindingParams.onlyPersonal
        },
        params = utils.createParameters(bindingParams, optionsExtension, providerOptionsExtension, defaultsFactory, dataProviderFactory);

    return baseModel.create(params);
}