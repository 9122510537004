///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Entities.Reminders.CompanyReminderConfiguration/ReminderConfigurationsProperty"/>
import {OverridenCollectionProperty} from "Core/Medius.Core.Web/Scripts/Medius/components/configurationProperties/overridenCollection";
import * as roleDataProviderFactory from "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/role/roleWithDummy";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as sync from "Core/Medius.Core.Web/Scripts/Medius/core/sync";
import * as utils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as ko from "knockout";
import * as _ from "underscore";
import * as remindersGrid from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Reminders/grid";

class ReminderConfigurationsProperty extends OverridenCollectionProperty {
    BindingTemplate: string;
    roleDataProvider: any;
    Company: any;
    grid: ko.Observable<any>;
    autocompleterRoleValue: ko.Observable<any>;
    disposables: any;
    setInheritedValueAsCurrentValue: () => void;
    Context: any;
    Value: any;
    IsInherited: any;
    constructor(params:any) {
        const allRolesText = "-- " + globalization.getLabelTranslation("#Core/allRoles") + " --";

        params.WithoutCollectionGrid = true;
        super(params);
        this.BindingTemplate = "Core:templates/Administration/ReminderConfiguration/Configuration.html";
        this.roleDataProvider = roleDataProviderFactory.create(this.Context, this.Company.Id, allRolesText);

        this.grid = ko.observable();
        this.autocompleterRoleValue = ko.observable();

        this.disposables.push(
            this.autocompleterRoleValue
                .subscribe((selectedRole) => {
                    if (!selectedRole) {
                        return;
                    }

                    let roleReminderConfiguration = _.find(this.Value(), (config) => {
                        const configRole = utils.unpack(config.Data.Role),
                            selectedRoleId = selectedRole.Id();

                        if (!configRole && selectedRoleId === 0) { // all roles
                            return true;
                        }

                        return configRole && utils.unpack(configRole.Id) === selectedRoleId;
                    });

                    if (!roleReminderConfiguration) {
                        if (this.IsInherited()) {
                            // Just empty reminder configuration so that empty grid is rendered
                            roleReminderConfiguration = {
                                Data: { ReminderConfigurations: ko.observable([]) }
                            };
                        } else {
                            roleReminderConfiguration = this.addItem();
                        }
                    }

                    remindersGrid.create(roleReminderConfiguration.Data.ReminderConfigurations)
                        .done((grid:any) => {
                            grid.IsInherited = this.IsInherited;
                            this.setGrid(grid);
                        });
                }));

        this.disposables.push({ dispose: () => { this.setGrid(null); } });

        this.autocompleterRoleValue({ Name: allRolesText, Id: ko.observable(0) });

        this.setInheritedValueAsCurrentValue = () => {
            super.setInheritedValueAsCurrentValue();
            this.autocompleterRoleValue.valueHasMutated();
        };
    }

    setGrid(newGrid:any) {
        const oldGrid = this.grid();
        this.grid(newGrid);

        if (oldGrid)
            oldGrid.destroy();
    }

    addItem() {
        let newRoleReminderConfiguration = sync.getNew("Medius.Core.Entities.Reminders.RoleReminderConfiguration");
        newRoleReminderConfiguration.Role = {
            $type: "Medius.Core.Entities.Role, Medius.Core.Common",
            Id: this.autocompleterRoleValue().Id()
        };
        newRoleReminderConfiguration = this.Context.create(newRoleReminderConfiguration);
        super.addItem(newRoleReminderConfiguration);
        return this.Value()[0];
    }

    getCurrency():any {
        return null;
    }

    getRowTemplate():any {
        return null;
    }

    getColumns():any {
        return null;
    }
}

export function create(params:any) {
    return new ReminderConfigurationsProperty(params);
}