///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/task/RiskFactor/RiskDescriptions/NewBankAccountDetected"/>
import { getLabelTranslation } from 'Core/Medius.Core.Web/Scripts/lib/globalization';
import * as React from 'react';
import { AdditionalProperties, IHandledInfo, RiskFactorCode, RiskFactorReasonCode } from 'Core/Medius.Core.Web/Scripts/Medius/apps/task/RiskFactor/RiskFactorComponentDtos/riskFactorsForInvoiceDto';
import { DropDown, ListItem } from '@medius/ui-controls';
import { getRiskFactorNameTranslation } from 'Core/Medius.Core.Web/Scripts/Medius/apps/task/RiskFactor/riskFactorUtils';
import { DatePresenter } from 'Core/Medius.Core.Web/Scripts/shared/components/datePresenter';
import { isNullOrUndefined } from 'Core/Medius.Core.Web/Scripts/lib/underscoreHelpers';
import { sendOneHandlingReasonForTask } from 'Core/Medius.Core.Web/Scripts/Medius/apps/task/RiskFactor/riskFactorIconComponentServices';

export interface INewBankAccountDetectedProps {
    additionalProperties: AdditionalProperties;
    canEdit: boolean;
    isRiskHandled: boolean;
    handledInfo?: IHandledInfo;
    currentReasonCode: RiskFactorReasonCode;
    taskId: number;
}

export const NewBankAccountDetected = ({
    additionalProperties,
    canEdit,
    isRiskHandled,
    handledInfo,
    currentReasonCode,
    taskId
}: INewBankAccountDetectedProps) => {
    const dropdownItems: ListItem[] = [
        { text: getLabelTranslation("#Core/handlingCodeNoReason"), value: RiskFactorReasonCode.NoReason }, 
        { text: getLabelTranslation("#Core/handlingCodeValueHasBeenConfirmedWithSupplier"), value: RiskFactorReasonCode.ValueHasBeenConfirmedWithSupplier }
    ];

    const [selectedReasonCode, setSelectedReasonCode] = React.useState<ListItem>(
        dropdownItems.filter(el => el.value === currentReasonCode)[0] || dropdownItems[0]
    );

    //This code is necessary due to a bug inside Northstar, can probably be removed when NS is upgraded to version 1.3.0
    //After removal remember to check if MFD-36854 bug does not reproduce
    const [dropdownItem, setDropdownItem] = React.useState<ListItem>(
        {value: selectedReasonCode.value, text:selectedReasonCode.text}
    );
    
    const allFieldsHiddenOrEmpty = !isNullOrUndefined(
        additionalProperties.allFieldsHiddenOrEmpty) && 
        JSON.parse(additionalProperties.allFieldsHiddenOrEmpty
    );

    const handleReasonCodeSelection = (dropdownEvent: any) => {
        setSelectedReasonCode(dropdownEvent.value);
        sendOneHandlingReasonForTask(taskId, { 
            handlingReason: dropdownEvent.value.value, 
            riskFactorCode: RiskFactorCode.NewBankAccountDetected
        });
    };
    
    React.useEffect(() => {
        setDropdownItem({value: selectedReasonCode.value, text:selectedReasonCode.text});
    }, [selectedReasonCode]);

    return (
        <>
            <h4>{getRiskFactorNameTranslation(RiskFactorCode.NewBankAccountDetected)}</h4>
            { isRiskHandled ?
                <>
                    <div className="risk-factor-icon-container__handlerDetails">
                        <p style={{margin: "0px"}}>{getLabelTranslation('#Core/handledBy')}</p>
                        <p>{handledInfo.handledByUser.firstName}</p>
                        <p style={{marginRight: "5px"}}>{handledInfo.handledByUser.lastName}</p>
                        <DatePresenter 
                            date={new Date(handledInfo.handledAt)} 
                            format="G"
                        />
                    </div>
                    <p>{resolveReasonCodeTranslation(currentReasonCode)}</p>
                </> :
                <>
                    {allFieldsHiddenOrEmpty?
                    <p>{getLabelTranslation("#Core/noBankAccountValuesOnTheInvoice")}</p>:
                    <>
                        <p>{getLabelTranslation("#Core/newBankAccountDetectedInfoText")}</p>
                        <div>
                            <p>{getLabelTranslation("#Core/currentValues")}:</p>
                            {additionalProperties.newBankGiroValue && <p className="risk-factor-icon-container__numberLabel">{additionalProperties.newBankGiroValue}</p>}
                            {additionalProperties.newPlusGiroValue && <p className="risk-factor-icon-container__numberLabel">{additionalProperties.newPlusGiroValue}</p>}
                            {additionalProperties.newBbanValue && <p className="risk-factor-icon-container__numberLabel">{additionalProperties.newBbanValue}</p>}
                            {additionalProperties.newIbanValue && <p className="risk-factor-icon-container__numberLabel">{additionalProperties.newIbanValue}</p>}
                        </div>
                    </>
                    }
                    { canEdit && <>
                        <p>{getLabelTranslation("#Core/setRiskAsHandledSelectOption")}</p>
                        <DropDown 
                            data-testid={"risk-input-NewBankAccountDetected"}
                            allItems={dropdownItems} 
                            value={dropdownItem}
                            onChange={handleReasonCodeSelection}
                        />
                    </> }
                </>
            }
        </>
    );
};

function resolveReasonCodeTranslation(handlingReasonCode: RiskFactorReasonCode){
    switch (handlingReasonCode) {
        case RiskFactorReasonCode.ValueHasBeenConfirmedWithSupplier:
            return getLabelTranslation("#Core/handlingCodeValueHasBeenConfirmedWithSupplier");
        case RiskFactorReasonCode.SupplierChanged:
            return getLabelTranslation("#Core/handlingReasonCodeSupplierChanged");
        case RiskFactorReasonCode.ValueChanged:
            return getLabelTranslation("#Core/handlingReasonCodeValueChanged");
        case RiskFactorReasonCode.InvoiceArchived:
            return getLabelTranslation("#Core/handlingReasonCodeInvoiceArchived");
        case RiskFactorReasonCode.ValueConfirmed:
            return getLabelTranslation("#Core/handlingReasonCodeValueConfirmed");
        case RiskFactorReasonCode.NewValueAdded:
            return getLabelTranslation("#Core/handlingReasonCodeNewValueAdded");
        case RiskFactorReasonCode.InvoiceDeletedDuplicateInvoice:
            return getLabelTranslation("#Core/handlingCodeInvoiceDeletedDuplicateInvoice");
        case RiskFactorReasonCode.InvoiceDeletedFraudAttempt:
            return getLabelTranslation("#Core/handlingCodeInvoiceDeletedFraudAttempt");
        case RiskFactorReasonCode.InvoiceDeletedOther:
            return getLabelTranslation("#Core/handlingCodeInvoiceDeletedOther");
        default:
            console.warn(`[NewBankAccountDetected] handling reason code ${handlingReasonCode} was not implemented for this risk factor`);
            break;
    }
}