/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/wizard/typedWizard"/>

import { IWizardStep } from "Core/Medius.Core.Web/Scripts/Medius/components/wizard/steps/IWizardStep";
import { IWizardOptions } from "Core/Medius.Core.Web/Scripts/Medius/components/wizard/IWizardOptions";
import { observable, pureComputed, isObservable } from "knockout";

export class TypedWizard<TData> {
    public isLoading = observable(false);
    public isVisible = observable(true);
    public data: TData;

    public options: IWizardOptions;
    public steps: IWizardStep<TData>[];
    public totalStepsCss: string;
    public maxStepIndex: number;
    public currentStepIndex: ko.Observable<number>;
    public currentStep: ko.Computed<IWizardStep<TData>>;
    public isCurrentStepFullyCustom: ko.Computed<boolean>;
    public currentStepName: ko.Computed<string>;
    public instanceObservable: any;

    public isVisibleSub: ko.Subscription;

    constructor(steps: IWizardStep<TData>[], options?: IWizardOptions) {
        this.options = {
            template: "Core:Medius/components/wizard/wizard.html",
            onClose: () => { },
            onCancel: () => { },
            ...options
        };

        this.steps = steps;
        this.totalStepsCss = "steps-" + steps.length;
        this.maxStepIndex = steps.length - 1;
        this.currentStepIndex = observable(-1);

        this.currentStep = pureComputed(() => {
            if (this.currentStepIndex() === -1) {
                return null;
            }
            return steps[this.currentStepIndex()];
        });

        this.isCurrentStepFullyCustom = pureComputed(() => {
            const currentStep = this.currentStep();
            if (currentStep && currentStep.isFullyCustom) {
                return currentStep.isFullyCustom();
            }
            return false;
        });

        this.currentStepName = pureComputed(() => this.currentStep() ? this.currentStep().name : null);

        this.data = {} as any;
        this.instanceObservable = null;

        this.isVisibleSub = this.isVisible.subscribe(newValue => {
            if (newValue === false) {
                this.cancel();
            }
        });
    }

    public init() {
        this.openNext();
    }

    public openNext() {
        if (!this.currentStep() || this.currentStep().validate(this)) {
            this.openStep(1);
            this.currentStep().onOpen(this);
            return true;
        }
        return false;
    }

    public openPrev() {
        if (this.openStep(-1)) {
            this.currentStep().onOpenBack(this);
            return true;
        }
        return false;
    }

    public openStep(diff: number) {
        const nextStep = this.currentStepIndex() + diff;

        if (nextStep >= 0 && nextStep <= this.maxStepIndex) {
            this.currentStepIndex(nextStep);
            return true;
        }
        return false;
    }

    public closeWizard() {
        this.isVisible(false);

        if (this.instanceObservable && isObservable(this.instanceObservable)) {
            this.instanceObservable(null);
        }
    }

    public cancel() {
        if (this.options.onCancel) {
            this.options.onCancel();
        }

        this.closeWizard();
    }

    public close() {
        if (this.options.onClose) {
            this.options.onClose();
        }
        this.closeWizard();
    }

    public reset() {
        this.data = {} as any;
        this.currentStepIndex(0);
    }

    public dispose() {
        this.steps.forEach(step => step.dispose(this));
        this.isVisibleSub.dispose();
        this.currentStepName.dispose();
        this.currentStep.dispose();
        this.reset();
    }
}
