/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/dblclick"/>
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as $ from "jquery";

const registerBinding = {
    update(element: HTMLElement, valueAccessor: any) {
        $(element).dblclick(() => {
            valueAccessor();
        });
    }
};

export function register() {
    koUtils.registerBinding("dblclick", registerBinding);
}