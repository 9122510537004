///<amd-module name = "Core/Medius.Core.Web/Scripts/Models/Medius.Core.Entities.DocumentRegistration.Draft"/>
import { DocumentViewmodel } from "Core/Medius.Core.Web/Scripts/Models/document";
import mapping = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/mapping/collection/instance");
import * as ko from "knockout";

export function register() {
    mapping.register("Medius.Core.Entities.DocumentRegistration.Draft, Medius.Core.Common", function (vm:any, data:any, context:any) {
        DocumentViewmodel(vm, data, context);
        
        vm.EntityViewModel = ko.computed(function () {
            if (vm.Document()) {
                return vm.Document().Entity();
            }
            return null;
        });
    });
}