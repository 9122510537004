/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/lib/utils/logger" />

import * as logger from "Core/Medius.Core.Web/Scripts/Medius/lib/logger";
import { formatValueWithPlaceholders } from "Core/Medius.Core.Web/Scripts/Medius/lib/stringFormat";   

export class Logger {
    data: any;

    constructor(params: string | any){
        if (typeof params === "string") {
            params = {
                name: params
            };
        }
    
        this.data = {
            ...params,
            isActive: function () {
                return true;
            }
        };
    
        if (!this.data.name) {
            throw new Error("Name is required");
        }  
    }

    log(message: string, ...params: any[]) {
        this.output("log", message, params);
    }

    warn(message: string, ...params: any[]) {
        this.output("warn", message, params);
    }

    info(message: string, ...params: any[]) {
        this.output("info", message, params);
    }

    error(message: string, ...params: any[]) {
        this.output("error", message, params);
    }

    debug(message: string, ...params: any[]) {
        this.output("debug", message, params);
    }

    private output(level: keyof Logger, message: string, params: any[]) {
        if (!this.data.isActive()) {
            return;
        }

        if (typeof message !== "string") {
            throw new Error("Message is required");
        }

        let msg = formatValueWithPlaceholders(message, [params]);
        msg = `${this.data.name} - ${msg}`;

        switch (level) {
            case "log":
               logger.log(msg);
               break;
            case "warn":
                logger.warn(msg);
                break;
            case "info":
                logger.info(msg);
                break;
            case "debug":
                logger.debug(msg);
                break;
            case "error":
                logger.error(msg);        
                break;
            default:
                throw new Error(`No method ${level} in Logger`);
                break;
        }
    }           
}

export function create(params: any){
    return new Logger(params); 
}