/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Reminders/reminderConfigurationRowWrapper"/>

import * as ko from "knockout";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as _ from "underscore";
import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as sync from "Core/Medius.Core.Web/Scripts/Medius/core/sync";
import * as type from "Core/Medius.Core.Web/Scripts/Medius/core/type";
import contextFactory = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/context");

function wrapReminderConfigurationRow(dataSource: any, reminderConfiguration: any, reminderConfigurationRow: any) {
    const self: any = reminderConfigurationRow;
    const details = self.Details();

    if (self.wrapped) {
        return self;
    }

    self.context = contextFactory();
    self.isNew = self.Id() === 0;
    self.ReminderType = ko.observable(reminderConfiguration.ReminderType());
    self.CurrentReminderType = self.ReminderType();
    self.ReminderTypeName = ko.observable();
    self.ReminderDescription = ko.observable();
    self.ReminderCompatibleTypes = ko.observableArray([]);
    self.IsSendInSummarySupported = ko.observable();
    self.IsValid = ko.observable(true);

    self.CanEdit = ko.computed(function () {
        if (details && details.hasOwnProperty('CanEdit')) {
            return details.CanEdit();
        }

        return true;
    });

    self.IsSendInSummaryDisabled = ko.computed(function () {
        return !self.IsSendInSummarySupported();
    });

    if (details) {
        details.CurrentReminderType = self.CurrentReminderType;
        details.IsValid = self.IsValid;
    }

    self.showSummary = _.find(dataSource.grid.Columns(), function (c) { return c.Key === 'SendSummary'; }).Visible;
    self.showWhen = _.find(dataSource.grid.Columns(), function (c) { return c.Key === 'When'; }).Visible;

    self.reminderInfo = ko.computed(function () {
        return dataSource.availableReminders.getAvailableReminder(self.ReminderType(), self.EntityFullTypeName());
    });

    function getReminderInfoForType(reminderType: any) {
        const nonMatchingDocumentTypeReminderInfo = dataSource.availableReminders.getAvailableReminder(reminderType);

        if (!nonMatchingDocumentTypeReminderInfo) return null;

        const firstCompatibleType = _.first(nonMatchingDocumentTypeReminderInfo.ReminderCompatibleTypes);
        return dataSource.availableReminders.getAvailableReminder(self.ReminderType(), firstCompatibleType.Value);
    }

    function refreshReminder(useDefaultSendInSummary: any) {
        let reminderInfo = self.reminderInfo();

        if (!reminderInfo) {
            reminderInfo = getReminderInfoForType(self.ReminderType());
        }

        if (useDefaultSendInSummary) {
            self.SendSummary(reminderInfo.SendInSummaryByDefault);
        }

        if (!!reminderInfo) {
            self.ReminderTypeName(reminderInfo.NameTranslated);
            self.ReminderDescription(reminderInfo.Description);
            const compatibleTypes = dataSource.availableReminders.getCompatibleDocumentTypes(reminderInfo.ReminderType);
            self.ReminderCompatibleTypes(compatibleTypes);
            self.IsSendInSummarySupported(reminderInfo.IsSendInSummarySupported);
        }
    }

    self.DocumentTypeName = ko.computed(function () {
        const newEntityFullTypeName = self.EntityFullTypeName();
        return globalization.getPropertyTranslation(newEntityFullTypeName);
    });

    self.reminderTypeSub = self.ReminderType.subscribe(function () {
        refreshReminder(true);
    });

    self.ReminderJobTimeFrame = ko.computed(function () {
        const reminder = dataSource.availableReminders.getAvailableReminder(self.ReminderType(), self.EntityFullTypeName());

        if (!reminder || reminder.RepeatTime === null) {
            return null;
        }

        return _.map(reminder.RepeatTime, function (timeSpan) {

            const msg = [globalization.getLabelTranslation("#Core/timeEach")];

            const parts = timeSpan.split(":");
            const parts2 = parts[0].split(".");

            const map = {
                days: parseInt((parts2.length === 2) ? parts2[0] : 0, 10),
                hours: parseInt(parts2[1] || parts2[0], 10),
                minutes: parseInt(parts[1], 10),
                seconds: parseInt(parts[2], 10)
            };

            if (map.days > 0) {
                msg.push(map.days + "d");
            }

            if (map.hours > 0) {
                msg.push(map.hours + "h");
            }

            if (map.minutes > 0) {
                msg.push(map.minutes + "min");
            }

            if (map.seconds > 0) {
                msg.push(map.seconds + "s");
            }

            return msg.join(" ");
        });
    });

    self.showNever = ko.computed(function () {
        return self.ReminderJobTimeFrame() === null;
    });
    self.showInstant = ko.computed(function () {
        return !self.SendSummary() && self.ReminderJobTimeFrame() !== null;
    });
    self.showSchedule = ko.computed(function () {
        return self.SendSummary() && self.ReminderJobTimeFrame() !== null;
    });

    self.messageTemplateId = ko.observable(self.Template() ? koUtils.unpack(self.Template().Id) : 0);

    self.messageTemplateIdSub = self.messageTemplateId.subscribe(function (id: any) {
        const template = _.find(dataSource.availableMessageTemplates, function (tpl) {
            return id === tpl.Id;
        });
        self.Template(template);
    });

    self.availableMessageTemplates = ko.computed(function () {
        const reminderType = self.ReminderType();
        const compatibleEntityTypes = type.getHierarchy(self.EntityFullTypeName());
        return _.filter(dataSource.availableMessageTemplates, function (template) {
            return (isNullOrUndefined(template.ReminderType) || template.ReminderType === reminderType) &&
                (isNullOrUndefined(template.EntityType) || _.contains(compatibleEntityTypes, template.EntityType));
        });
    });

    const savedDetails = self.Details();
    const savedDetailsType = (!isNullOrUndefined(savedDetails)) ?
        type.getTypeName(koUtils.unpack(savedDetails.$type)) :
        null;

    self.reminderDetailsType = ko.computed(function () {
        const reminderInfo = self.reminderInfo();
        return (reminderInfo) ? reminderInfo.ReminderDetailsType : null;
    });

    self.reminderDetailsType.extend({ notify: 'always' });

    self.ViewPath = ko.computed(function () {
        return self.reminderDetailsType() ? ["Display", self.reminderDetailsType(), "ReminderDetails"].join('/') : null;
    });

    self.reminderDetailsTypeSub = self.reminderDetailsType.subscribe(function (newReminderDetailsType: any) {
        if (!!savedDetails && savedDetailsType === newReminderDetailsType) {
            savedDetails.CurrentReminderType = self.ReminderType();
            self.Details(savedDetails);
            savedDetails.validate && savedDetails.validate();
            return;
        }

        if (!!newReminderDetailsType) {
            const newDetails = self.context.create(sync.getNew(newReminderDetailsType));
            newDetails.CurrentReminderType = self.ReminderType();
            newDetails.IsValid = self.IsValid;
            self.Details(newDetails);
            return;
        }

        self.Details(null);
    });

    refreshReminder(self.justAdded);

    self.disposeWrapper = function () {
        self.reminderInfo.dispose();
        self.DocumentTypeName.dispose();
        self.reminderTypeSub.dispose();
        self.ReminderJobTimeFrame.dispose();
        self.messageTemplateIdSub.dispose();
        self.ViewPath.dispose();
        self.reminderDetailsTypeSub.dispose();
        self.reminderDetailsType.dispose();
        self.context.dispose();
        self.availableMessageTemplates.dispose();
        self.showNever.dispose();
        self.showInstant.dispose();
        self.showSchedule.dispose();
        self.IsSendInSummaryDisabled.dispose();

        if (self.Details() && self.Details().dispose) {
            self.Details().dispose();
        }
    };

    self.wrapped = true;

    return self;
}

export const create = wrapReminderConfigurationRow;