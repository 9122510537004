/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/AuthorizationGroups/authorizationGroup"/>
export type Optional<T> = T | undefined;

export interface AuthorizationGroup {
    Id: number;
    IsActive: boolean;
    Name: Optional<string>;
    Company: Optional<Company> | null;
    Currency: Optional<Currency> | null;
    GeneralPermission: GeneralPermission;
    DeviationPermission: GeneralPermission;
    SupplierPermissions: SupplierPermission[];
    DimensionValuePermissions: DimensionValuePermission[];
    DocumentTypes: DocumentType[];
    CanUserDelete: boolean;
    CanUserUpdate: boolean;
    ExternalSystemId: string;
    ImportedTimestamp: Optional<Date>;
}

export interface AuthorizationGroupsHeaders {
    Total: Optional<number>;
    Data: AuthorizationGroupHeader[]
}

export interface AuthorizationGroupHeader {
    Id: number;
    Name: string;
    CompanyName: string;
    CurrencyCode: string;
    IsActive: boolean;
    ImportedTimestamp: Optional<Date>;
}

export interface Company {
    Id: number;
    Name: string;
    NameWithCompanyIdAndIdentifiers: string;
    AccountingCurrency: {
        Code: string;
        Id: number;
    }
}

export interface Currency {
    Id: number;
    Code: string;
}

export interface Supplier {
    Id: number;
    Name: string;
    NameWithSupplierIdAndIdentifiers: Optional<string>;
}

export interface GeneralPermission {
    Id: number;
    DebitLimit: AuthorizationLimit;
    CreditLimit: AuthorizationLimit;
}

export interface DimensionValueExceptionPermission {
    Id: number;
    Value: DimensionValueExpression;
    Description: DimensionValueExpression;
}

export interface DimensionValueExpression {
    Id: number;
    Filter: string;
}

export interface DimensionValuePermission {
    Id: number;
    Value: DimensionValueExpression;
    Description: DimensionValueExpression;
    Dimension: Dimension;
    Exceptions: DimensionValueExceptionPermission[];
    DebitLimit: AuthorizationLimit;
    CreditLimit: AuthorizationLimit;
    HasAuthorizationRight: boolean;
    HasCodingRight: boolean;
}

export interface SupplierPermission {
    Id: number;
    Supplier: Supplier;
    DebitLimit: AuthorizationLimit;
    CreditLimit: AuthorizationLimit;
}

export interface Dimension {
    Id: number;
    DisplayName: string;
}

export interface DocumentType {
    Id: number;
    Name: string;
}

export interface AuthorizationLimit {
    Value: Amount | null;
    Type: LimitType;
}

interface Amount {
    CurrencyCode: Optional<string>;
    DisplayValue: Optional<number> | null;
}

export const enum LimitType {
    Undefined = 0,
    Limited = 1,
    Unlimited = 2
}
export interface EntityId {
    readonly Id: number;
}

export function GetEmpty(defaultDocumentTypeId:number) {
    return {
        Id: 0,
        Name: "",
        IsActive: true,
        Company: null,
        Currency: null,
        GeneralPermission: {
            Id: 0,
            DebitLimit: {
                Value: { DisplayValue: null, CurrencyCode: undefined },
                Type: LimitType.Undefined
            },
            CreditLimit: {
                Value: { DisplayValue: null, CurrencyCode: undefined },
                Type: LimitType.Undefined
            }
        },
        DeviationPermission: {
            Id: 0,
            DebitLimit: {
                Value: { DisplayValue: null, CurrencyCode: undefined },
                Type: LimitType.Undefined
            },
            CreditLimit: {
                Value: { DisplayValue: null, CurrencyCode: undefined },
                Type: LimitType.Undefined
            }
        },
        DocumentTypes: [
            { Id: defaultDocumentTypeId, Name: "Medius.Data.Document" }
        ],
        DimensionValuePermissions: [],
        SupplierPermissions: [],
        CanUserDelete: false,
        CanUserUpdate: false
    } as AuthorizationGroup;
}

export function GetEmptyDimensionValuePermission(dimension: Dimension) {
    return {
        Id: 0,
        Dimension: { Id: dimension.Id, DisplayName: dimension.DisplayName },
        Value: {
            Id: 0,
            Filter: "*"
        },
        Description: {
            Id: 0,
            Filter: "*"
        },
        DebitLimit: {
            Value: { Id: 0, DisplayValue: null, CurrencyCode: undefined },
            Type: LimitType.Undefined
        },
        CreditLimit: {
            Value: { Id: 0, DisplayValue: null, CurrencyCode: undefined },
            Type: LimitType.Undefined
        },
        Exceptions: [],
        HasAuthorizationRight: false,
        HasCodingRight: false
    } as DimensionValuePermission;
}

export function GetEmptySupplierPermission() {
    return {
        Id: 0,
        Supplier: {
            Id: 0,
            Name: "",
            NameWithSupplierIdAndIdentifiers: undefined
        },
        DebitLimit: {
            Value: { DisplayValue: null, CurrencyCode: undefined },
            Type: LimitType.Undefined
        },
        CreditLimit: {
            Value: { DisplayValue: null, CurrencyCode: undefined },
            Type: LimitType.Undefined
        },
    } as SupplierPermission;
}

export function mapToHeaderData(item: AuthorizationGroup) {
    return {
        Id: item.Id,
        Name: item.Name,
        CompanyName: item.Company?.Name,
        CurrencyCode: item.Currency?.Code,
        IsActive: item.IsActive,
        ImportedTimestamp: item.ImportedTimestamp
    } as AuthorizationGroupHeader;
}

export function IsNewAuthorizationGroup(item?: AuthorizationGroup | null) {
    return item?.Id == 0;
}

function DeepClone(value: any) {
    return JSON.parse(JSON.stringify(value));
}

export function Clone(authorizationGroup?: AuthorizationGroup | null) {
    const clonedItem = DeepClone(authorizationGroup) as AuthorizationGroup;
    clonedItem.Id = 0;
    clonedItem.Name = "";
    clonedItem.ExternalSystemId = "";
    clonedItem.Currency = authorizationGroup?.Currency;
    clonedItem.GeneralPermission.Id = 0;
    clonedItem.DeviationPermission.Id = 0;
    clonedItem.DimensionValuePermissions.forEach(dvp => {
        dvp.Id = 0;
        dvp.Value.Id = 0;
        dvp.Description.Id = 0;
        dvp.Exceptions.forEach(ex => {
            ex.Id = 0;
            ex.Value.Id = 0;
        });
    });
    clonedItem.SupplierPermissions.forEach(sp => sp.Id = 0);
    return clonedItem;
}
