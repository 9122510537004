/// <amd-module name="Core/Medius.Core.Web/Scripts/shared/components/boolToggle"/>
import * as React from "react";
import { useState, MouseEventHandler, ChangeEvent, SyntheticEvent, PropsWithChildren, useCallback } from "react";
import { Button, ButtonGroup, ButtonProps } from "@progress/kendo-react-buttons";
import { useEffect } from "react";

export { BoolToggle, BoolToggleProps, BoolToggleElement };
export { SwitchToggle, SwitchToggleProps, SwitchToggleElement, Either };

type BoolToggleElement = HTMLElement & {
    name: string;
    value: boolean | null;
};

type BoolToggleProps = Omit<SwitchToggleProps, "leftLabel" | "rightLabel" | "value" | "onToggle"> & {
    readonly trueLabel: string;
    readonly falseLabel: string;
    readonly value?: boolean | null;
    readonly onToggle?: (event: ChangeEvent<BoolToggleElement>) => void;
};

const BoolToggle = (props: BoolToggleProps) =>
    <SwitchToggle
        {...props}
        leftLabel={props.trueLabel}
        rightLabel={props.falseLabel}
        value={props?.value == null ? null : props.value ? Either.Left : Either.Right}
        onToggle={e => {
            if (props?.onToggle) {
                const event = e as any;
                event.target = {
                    ...e.target,
                    name: e.target.name,
                    value: e.target?.value === null ? null : e.target.value == Either.Left
                };
                props.onToggle(event as ChangeEvent<BoolToggleElement>);
            }
        }} />;

type SwitchToggleProps = PropsWithChildren<{
    readonly leftLabel: string;
    readonly rightLabel: string;
    readonly name?: string;
    readonly value?: Either | null;
    readonly required?: boolean;
    readonly disabled?: boolean;
    readonly onToggle?: (event: ChangeEvent<SwitchToggleElement>) => void;
}>;

type SwitchToggleElement = HTMLElement & {
    name: string;
    value: Either | null;
};

enum Either {
    Left,
    Right
}

const SwitchToggle = <TElement extends SwitchToggleElement>(props: SwitchToggleProps) => {
    const { value, required, onToggle } = props;

    const leftSelected = useCallback(() =>
        required ? value !== Either.Right : false,
    [required, value]);
    
    const rightSelected = useCallback(() =>
        value === Either.Right,
    [value]);

    const [isLeftSelected, setIsLeftSelected] = useState<boolean>(leftSelected());
    const [isRightSelected, setIsRightSelected] = useState<boolean>(rightSelected());

    const toggleLeft = (event: SyntheticEvent) => {
        const selected = toggle(isLeftSelected);
        setIsLeftSelected(selected);
        setIsRightSelected(false);
        onToggleChange(event, true, selected);
    };

    const toggleRight = (event: SyntheticEvent) => {
        const selected = toggle(isRightSelected);
        setIsRightSelected(selected);
        setIsLeftSelected(false);
        onToggleChange(event, false, selected);
    };

    const toggle = (value: boolean) =>
        required ? true : !value;

    const onToggleChange = (event: SyntheticEvent, leftSelected: boolean, selected: boolean) => {
        if (onToggle) {
            const value = leftSelected && selected
                ? Either.Left
                : !leftSelected && selected
                    ? Either.Right
                    : null;

            onToggle(changeEvent(event, value));
        }
    };

    const changeEvent = (event: SyntheticEvent, value: Either | null) => {
        const changeEvent = event as ChangeEvent<TElement>;
        changeEvent.target = {
            ...changeEvent.target,
            name: props.name,
            value: value
        };
        return changeEvent;
    };

    const BoolButton = (
        props: PropsWithChildren<Omit<ButtonProps, "primary" | "togglable"> & {
            selected: boolean;
            onClick: MouseEventHandler;
        }>
    ) =>
        <Button
            themeColor={props.selected ? "primary" : "base"}
            togglable
            {...props}>
            {props.children}
        </Button>;

    useEffect(() => {
        setIsLeftSelected(leftSelected());
        setIsRightSelected(rightSelected());
    }, [leftSelected, rightSelected]);

    return (
        <ButtonGroup>
            <BoolButton
                selected={isLeftSelected}
                onClick={toggleLeft}
                disabled={props.disabled}>
                {props.leftLabel}
            </BoolButton>
            <BoolButton
                selected={isRightSelected}
                onClick={toggleRight}
                disabled={props.disabled}>
                {props.rightLabel}
            </BoolButton>
            <>{props.children}</>
        </ButtonGroup>
    );
};
