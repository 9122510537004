/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/default/defaults"/>

export function create () {
    return {
        template: 'editor-autocompleter-component-template',
        resultsTemplate: 'editor-autocompleter-component-results-template',
        resultItemTemplate: 'editor-autocompleter-component-results-item-template',
        throttle: 500,
        maxResults: 20,
        options: {
            useViewModels: true,
            rightAlign: false,
            preselectFirstValue: true
        },
        dataProviderOptions: {}
    };
}