/// <amd-module name="Core/Medius.Core.Web/Scripts/lib/globalization"/>
import { get } from "Core/Medius.Core.Web/Scripts/Medius/core/rest";
import { loadKendoTranslations } from "Core/Medius.Core.Web/Scripts/lib/reactIntegration/kendoTranslations";
import * as messageLogger from "Core/Medius.Core.Web/Scripts/lib/messageLogging/messageLogger";
import * as _ from "underscore";
import * as user from "Core/Medius.Core.Web/Scripts/Medius/lib/utils/user";
import * as logger from "Core/Medius.Core.Web/Scripts/Medius/lib/logger";
import * as simplePresenter from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/simplePresenter";
import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import { formatValueWithPlaceholders } from "../Medius/lib/stringFormat";

const TranslationOption = {
    ShortText: { value: 0, name: "Short" },
    LongText: { value: 1, name: "Long" }
};

export const labels: any = {};
export const properties: any = {};
export const dictionaryInstance = { labels, properties };

function _getTranslationFromDictionary(dict: any, key: any, translationOption: any) {
    let translationValue = null;

    // add the leading '#' to the key if missing
    const searchKey = typeof (key) === "string" && key.indexOf("#") !== 0 ? "#" + key : key;
    const translation = (dictionaryInstance as any)[dict][searchKey] as any;

    if (!translation) {
        return key;
    }

    switch (translationOption) {
        case TranslationOption.ShortText:
            translationValue = _getShortText(translation);
            break;
        case TranslationOption.LongText:
            translationValue = _getLongText(translation);
            break;
        default: {
            const message = `Invalid translationOption ${translationOption}. Please report a bug.`;
            logger.error(message);
            messageLogger.logErrorMessage(message);
            break;
        }
    }

    const fallbackKey = dict == "properties" ? key : searchKey;

    return isNullOrUndefined(translationValue) ? fallbackKey : translationValue;
}

function _getShortText(translation: any) {
    if (translation.DefaultShortText !== null) {
        return translation.DefaultShortText.Value;
    }
    return null;
}

function _getLongText(translation: any) {
    if (translation.LongDescription !== null) {
        return translation.LongDescription.Value;
    }
    return null;
}

export function getLabelTranslation(labelKey?: string | number | null, ...parameters: any[]): string {
    const translation = _getTranslationFromDictionary("labels", labelKey, TranslationOption.ShortText);

    if (parameters.length > 0) {
        const objectPresentations = simplePresenter.present(parameters);
        return formatValueWithPlaceholders(translation, objectPresentations);
    } else {
        return translation;
    }
}

export function getFormattedLabelTranslation(labelKey: string, parameters: string[]): string {
    const text = getLabelTranslation(labelKey);
    const translatedList = parameters?.map(x => getLabelTranslation(x)) ?? [];

    return formatValueWithPlaceholders(text, translatedList);
}

export function getTranslation(key?: string | null): string {
    const labelTranslation = getLabelTranslation(key);

    if (labelTranslation !== key) {
        return labelTranslation;
    }

    return getPropertyTranslation(key);
}

export function translate(key?: string | null): string {
    return getTranslation(key);
}

export function dispose() {
    // removing all properties of object without removing reference, because object is exported and reused
    Object.keys(labels).forEach(key => { delete labels[key]; });
    Object.keys(properties).forEach(key => { delete properties[key]; });
}

function getLanguageCode() {
    // if user is unauthenticated fetch default translations
    if (user.getCurrent()) {
        return `/${user.getLanguageCode()}`;
    }
    return "";
}

export function init() {
    const languageCode = getLanguageCode();

    return get("DefaultTranslationService", "translations" + languageCode, { async: false })
        .done((data: any) => {
            if (data.Labels) {
                _.extend(dictionaryInstance.labels, data.Labels);
            }
            if (data.Properties) {
                _.extend(dictionaryInstance.properties, data.Properties);
            }
            loadKendoTranslations(translate);

        }).fail(() => {
            logger.warn("Translations loaded incorrectly");
        });
}

export function getLabelLongTranslation(labelKey: string) {
    return _getTranslationFromDictionary("labels", labelKey, TranslationOption.LongText);
}

export function getPropertyTranslation(propKey?: string | null) {
    return _getTranslationFromDictionary("properties", propKey, TranslationOption.ShortText);
}

export function getPropertyLongTranslation(propKey: string) {
    return _getTranslationFromDictionary("properties", propKey, TranslationOption.LongText);
}

export function getDummyEntry(value: string) {
    return {
        LongDescription: null as any,
        InflectedShortText: null as any,
        DefaultShortText: {
            Value: value
        }
    };
}

export function addOrUpdateLabelTranslation(transTag: string, transValue: string) {
    if (dictionaryInstance.labels[transTag]) {
        dictionaryInstance.labels[transTag].DefaultShortText.Value = transValue;
    } else {
        dictionaryInstance.labels[transTag] = getDummyEntry(transValue);
    }
}
