/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/register" />

import * as collection from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/collection";
import * as linkDocument from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/default/link/document";
import * as dataDefinition from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/default/link/dataDefinition";
import * as documentTask from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/default/link/documentTask";
import * as task from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/default/link/task";
import * as entityLongTextDocument from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/default/longText/entity/document";
import * as errorsBadRequest from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/default/longText/errors/badRequest";
import * as errorsDataObjectAuthorizationResult from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/default/longText/errors/dataObjectAuthorizationResult";
import * as errorsDataValidationResultCollection from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/default/longText/errors/dataValidationResultCollection";
import * as errorsServiceUnexpectedOperationExceptionDetail from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/default/longText/errors/serviceUnexpectedOperationExceptionDetail";
import * as componentTextAmount from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/default/text/component/amount";
import * as unitPriceTextAmount from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/default/text/component/unitprice";
import * as percentageTextAmount from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/default/text/component/percentage";
import * as textEntityCompany from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/default/text/entity/company";
import * as textEntityCurrency from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/default/text/entity/currency";
import * as textEntityCurrencyDto from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/default/text/entity/currencyDto";
import * as textEntityCurrencyRate from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/default/text/entity/currencyRate";
import * as textEntityListCustomFieldValue from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/default/text/entity/listCustomFieldValue";
import * as textEntityListCustomFieldValueDto from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/default/text/entity/listCustomFieldValueDto";
import * as textEntityNumber from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/default/text/entity/number";
import * as textEntityRole from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/default/text/entity/role";
import * as textEntityRoleCompany from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/default/text/entity/roleCompany";
import * as textEntityRoleDto from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/default/text/entity/roleDto";
import * as textEntityUnit from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/default/text/entity/unit";
import * as textEntityUser from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/default/text/entity/user";
import * as textEntityUserDto from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/default/text/entity/userDto";
import * as textEntityValidationContextConfiguration from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/default/text/entity/validationContextConfiguration";
import * as textEntityxslTemplate from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/default/text/entity/xslTemplate";
import * as textBasicDto from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/default/text/basicDto";
import * as textBasicEntity from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/default/text/basicEntity";
import * as textIndexedFields from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/default/text/indexedFields";
import * as textNameField from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/default/text/nameField";
import * as textSearchItem from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/default/text/searchItem";

export function register() {
    collection.register(linkDocument.resolver);
    collection.register(dataDefinition.resolver);
    collection.register(documentTask.resolver);
    collection.register(task.resolver);
    collection.register(entityLongTextDocument.resolver);
    collection.register(errorsBadRequest.resolver);
    collection.register(errorsDataObjectAuthorizationResult.resolver);
    collection.register(errorsDataValidationResultCollection.resolver);
    collection.register(errorsServiceUnexpectedOperationExceptionDetail.resolver);
    collection.register(componentTextAmount.resolver);
    collection.register(unitPriceTextAmount.resolver);
    collection.register(percentageTextAmount.resolver);
    collection.register(textEntityCompany.resolver);
    collection.register(textEntityCurrency.resolver);
    collection.register(textEntityCurrencyDto.resolver);
    collection.register(textEntityCurrencyRate.resolver);
    collection.register(textEntityListCustomFieldValue.resolver);
    collection.register(textEntityListCustomFieldValueDto.resolver);
    collection.register(textEntityNumber.resolver);
    collection.register(textEntityRole.resolver);
    collection.register(textEntityRoleCompany.resolver);
    collection.register(textEntityRoleDto.resolver);
    collection.register(textEntityUnit.resolver);
    collection.register(textEntityUser.resolver);
    collection.register(textEntityUserDto.resolver);
    collection.register(textEntityValidationContextConfiguration.resolver);
    collection.register(textEntityxslTemplate.resolver);
    collection.register(textBasicDto.resolver);
    collection.register(textBasicEntity.resolver);
    collection.register(textIndexedFields.resolver);
    collection.register(textNameField.resolver);
    collection.register(textSearchItem.resolver);
}

