/// <amd-module name="Core/Medius.Core.Web/Scripts/customTabs/definitions/contracts/contractsGrid"/>

import * as React from "react";
import { FC, useEffect, useState, useCallback } from "react";
import { State } from "@progress/kendo-data-query";
import { Grid, GridColumn, GridDataStateChangeEvent, GridCellProps } from "@progress/kendo-react-grid";
import { IContractDto, getContracts, ISupplierFilterDto, ICompanyFilterDto, getCompanyAsync, getSupplierAsync, reclassify } from "Core/Medius.Core.Web/Scripts/customTabs/definitions/contracts/service";
import { DateCell, LinkCell, IsActiveCell, PlainTestIdCell } from "Core/Medius.Core.Web/Scripts/customTabs/definitions/contracts/customCells";
import { CompositeFilterDescriptor, process, DataResult } from "@progress/kendo-data-query";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { getter } from "@progress/kendo-react-common";
import { GridFilterCellProps, getSelectedState, GridSelectionChangeEvent } from "@progress/kendo-react-grid";
import { companyFilterCell, dateFilterCellFactory, supplierFilterPagingComponent, InputFilterCell, inputFilterCellFactory } from "Core/Medius.Core.Web/Scripts/customTabs/definitions/contracts/customFilterCells";
import { handleError } from "Core/Medius.Core.Web/Scripts/lib/errorHandling/errorHandler";
import { useAbortController } from "Core/Medius.Core.Web/Scripts/shared/hooks/useAbortController";
import { MaskingKendoReactGridLoadingPanel } from "Core/Medius.Core.Web/Scripts/shared/components/loadingPanel";
import { aborted } from "Core/Medius.Core.Web/Scripts/Medius/core/fetch/rest";
import { IntlProvider } from "@progress/kendo-react-intl";
import { getCurrentCulture } from "Core/Medius.Core.Web/Scripts/lib/reactIntegration/kendoIntl";
import { Button } from "@progress/kendo-react-buttons";
import { RequestReclassifyDialog, DialogAction } from "Core/Medius.Core.Web/Scripts/customTabs/definitions/contracts/requestReclassifyDialog";

const DATA_ITEM_KEY = "id";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);
const initialFilter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [
        {
            field: 'active',
            operator: 'eq',
            value: true
        }
    ]
};

type ContractsGridProps = {
    taskId: number,
    companyId: number,
    supplierId: number,
    invoiceDate: Date,
    onReclassify?: (handleTaskAction: any) => void,
    showReclassifyButton: boolean,
    activeContractsHandler?: (activeContractsCnt: number) => void;
};

function getSelectedItem(selectedState: { [id: string]: boolean | number[]; }, contracts: IContractDto[]): IContractDto | null {
    const selectedIds = Object.keys(selectedState);
    if (!selectedIds || !contracts || selectedIds.length === 0) {
        return null;
    }

    const selectedIdparsed = parseInt(selectedIds[0]);
    return contracts.find(x => x.id === selectedIdparsed);
}
export const ContractsGrid: FC<ContractsGridProps> = ({ taskId, companyId, supplierId, invoiceDate, showReclassifyButton, onReclassify, activeContractsHandler }: ContractsGridProps) => {
    const [selectedState, setSelectedState] = React.useState<{
        [id: string]: boolean | number[];
    }>({});

    const onSelectionChange = (event: GridSelectionChangeEvent) => {
        const newSelectedState = getSelectedState({
            event,
            selectedState: selectedState,
            dataItemKey: DATA_ITEM_KEY,
        });

        setSelectedState(newSelectedState);
        const localSelectedItem = dataResult.data.find(x => x[SELECTED_FIELD]);
        !localSelectedItem || (localSelectedItem[SELECTED_FIELD] = undefined);
        const selectedItem = getSelectedItem(newSelectedState, dataResult.data);
        if (selectedItem) {
            (selectedItem as any)[SELECTED_FIELD] = true;
        }

        setDataResult({ ...dataResult });
    };

    const [dataResult, setDataResult] = useState<DataResult>();
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);
    const [isActiveFilterChecked, setIsActiveFilterChecked] = useState<boolean>(false);
    const [contracts, setContracts] = useState<IContractDto[]>([]);
    const initialDataState: State = { skip: 0, take: 20, filter: initialFilter };
    const [dataState, setDataState] = React.useState<State>(initialDataState);
    const [supplier, setSupplier] = useState<ISupplierFilterDto>(null);
    const [company, setCompany] = useState<ISupplierFilterDto>(null);
    const [reclassifyDialogVisible, setReclassifyDialogVisible] = useState(false);
    const abortController = useAbortController();

    const contractsWithSelectedState = (contracts: IContractDto[], selectedState: { [id: string]: boolean | number[]; }) => {
        return contracts.map(item => ({
            ...item,
            [SELECTED_FIELD]: selectedState[idGetter(item)],
        }));
    };
    const fetchData = useCallback(async () => {
        try {
            setIsDataLoading(true);
            const [selectedCompany, selectedSupplier] = await Promise.all(
                [getCompanyAsync(companyId, abortController.signal),
                getSupplierAsync(supplierId, abortController.signal)]);
            setSupplier(selectedSupplier);
            setCompany(selectedCompany);
            const data = await getContracts(selectedCompany?.id, selectedSupplier?.id, invoiceDate, abortController.signal);
            setContracts(data);
            setDataResult(process(contractsWithSelectedState(data, selectedState), dataState));
            activeContractsHandler(data?.length);
        } catch (e) {
            if (aborted(e)) {
                return;
            }

            handleError(e);
        } finally {
            setIsDataLoading(false);
            setIsInitialized(true);
        }
    }, [abortController.signal]);

    useEffect(() => {
        if (!isInitialized) {
            return;
        }

        async function fetchContracts() {
            try {
                setIsDataLoading(true);
                const data = await getContracts(company?.id, supplier?.id, invoiceDate, abortController.signal);
                setContracts(data);
                setDataResult(process(contractsWithSelectedState(data, selectedState), dataState));
            } catch (e) {
                handleError(e);
            } finally {
                setIsDataLoading(false);
            }
        }

        fetchContracts();
    }, [company, supplier, abortController.signal]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleDataStateChange = (e: GridDataStateChangeEvent) => {
        setDataState(e.dataState);
        setDataResult(process(contractsWithSelectedState(contracts, selectedState), e.dataState));
    };

    const onIncludeInactiveChange = (e: any) => {
        setIsActiveFilterChecked(e.target.checked);
        let newFilters: any;
        if (e.target.checked) {
            newFilters = {
                logic: 'and',
                filters: [...dataState.filter.filters.filter((x: any) => x.field !== 'active')]
            };
        } else {
            newFilters = {
                logic: 'and',
                filters: [...dataState.filter.filters, {
                    field: 'active',
                    operator: 'eq',
                    value: true
                }]
            };
        }

        const newDataState = {
            ...dataState,
            filter: newFilters
        };

        setDataResult(process(contractsWithSelectedState(contracts, selectedState), newDataState));
        setDataState(newDataState);
    };

    const handleSupplierFilterChanged = async (event: ISupplierFilterDto) => setSupplier(event);
    const handleCompanyFilterChanged = async (event: ICompanyFilterDto) => setCompany(event);
    const companyFilterCellWithProps = useCallback(() => companyFilterCell({ selected: company, invoiceCompanyId: companyId, handleFilterChanged: handleCompanyFilterChanged }), [company]);
    const supplierFilterPagingComponentWithProps = useCallback(
        () => supplierFilterPagingComponent({ supplier: supplier, invoiceSupplierId: supplierId, company: company, onChange: handleSupplierFilterChanged }),
        [company, supplier]);
    const validFromFilterCell = useCallback((props: GridFilterCellProps) => dateFilterCellFactory({ wrappedProps: props, operator: 'gte' }), []);
    const validToFilterCell = useCallback((props: GridFilterCellProps) => dateFilterCellFactory({ wrappedProps: props, operator: 'lte' }), []);
    const contractNumberFilterCell = useCallback((props: GridFilterCellProps) => inputFilterCellFactory({ ...props, dataTestId: "contract-number-filter" }), []);
    const contractNameFilterCell = useCallback((props: GridFilterCellProps) => inputFilterCellFactory({ ...props, dataTestId: "contract-name-filter" }), []);
    const contractNameCell = useCallback((props: GridCellProps) => PlainTestIdCell({ ...props, dataTestId: "contract-name-cell" }), []);

    return (
        <div className="supplierContract__mainContainer">
            {isDataLoading && <MaskingKendoReactGridLoadingPanel gridSelector=".supplierContract__grid" />}
            <div className="supplierContract__mainContainer__inactiveFilter">
                <input type="checkbox" id="isActiveFilter" onChange={onIncludeInactiveChange} checked={isActiveFilterChecked} data-testid="contracts-tab-include-inactive-checkbox"/>
                <label htmlFor="isActiveFilter">{translate("#PurchaseToPay/contractsGrid/isActiveFilter")}</label>
            </div>

            <IntlProvider locale={getCurrentCulture()}>
                <div data-testid="contracts-tab-grid">
                    <Grid
                        data={dataResult}
                        {...dataState}
                        pageable={true}
                        onDataStateChange={handleDataStateChange}
                        className="supplierContract__grid"
                        filterable={true}
                        sortable={true}
                        resizable
                        dataItemKey={DATA_ITEM_KEY}
                        selectedField={SELECTED_FIELD}
                        onSelectionChange={onSelectionChange}
                        selectable={{
                            enabled: true,
                            drag: false,
                            cell: false,
                            mode: 'single',
                        }}>
                        <GridColumn field="number" title={translate("#PurchaseToPay/contractsGrid/contractNumber")} cell={LinkCell} filterCell={contractNumberFilterCell} filterable={true} />
                        <GridColumn field="name" title={translate("#PurchaseToPay/contractsGrid/contractName")} cell={contractNameCell} filterCell={contractNameFilterCell} filterable={true} />
                        <GridColumn field="description" title={translate("#PurchaseToPay/contractsGrid/description")} filterCell={InputFilterCell} filterable={true} />
                        <GridColumn field="owner" title={translate("#PurchaseToPay/contractsGrid/owner")} filterCell={InputFilterCell} filterable={true} />
                        <GridColumn field="category" title={translate("#PurchaseToPay/contractsGrid/category")} filterCell={InputFilterCell} filterable={true} />
                        <GridColumn field="validFrom" title={translate("#PurchaseToPay/contractsGrid/validFrom")} filter="date"
                            format="{0:d}" cell={DateCell} filterCell={validFromFilterCell} filterable={true} />
                        <GridColumn field="validTo" title={translate("#PurchaseToPay/contractsGrid/validTo")}
                            format="{0:d}" filter="date" cell={DateCell} filterable={true} filterCell={validToFilterCell} />
                        <GridColumn field="active" title={translate("#PurchaseToPay/contractsGrid/active")} filterable={false} cell={IsActiveCell} />
                        <GridColumn field="companyDto.name" title={translate("#PurchaseToPay/contractsGrid/company")}
                            filterable={true}
                            filterCell={companyFilterCellWithProps}
                        />
                        <GridColumn field="supplierDto.name" title={translate("#PurchaseToPay/contractsGrid/supplier")}
                            filterable={true}
                            filterCell={supplierFilterPagingComponentWithProps}
                        />
                    </Grid>
                </div>
            </IntlProvider>
            {showReclassifyButton && <Button className="supplierContract__reclassifyContainer" disabled={!getSelectedItem(selectedState, dataResult?.data)} data-testid="contracts-tab-reclassify-button" onClick={() => setReclassifyDialogVisible(true)}>
                {translate("#PurchaseToPay/reclassifyDialog/reclassify")}
            </Button>}
            {reclassifyDialogVisible && <RequestReclassifyDialog
                selectedContract={getSelectedItem(selectedState, dataResult?.data)}
                onClose={async (action: DialogAction, contract: IContractDto) => {
                    if (action === DialogAction.Ok) {
                        onReclassify(() => reclassify(taskId, contract.id));
                    }
                    setReclassifyDialogVisible(false);
                }} />}
        </div>
    );
};
