/// <amd-module name="Core/Medius.Core.Web/Scripts/components/mention/editor/editor"/>
// Mobile version of this file is: mfxi\Mediusflow\Frontend\Web\mobile\src\core\components\comments\newComment\newComment.ts
// Consider changing the other one while doing changes in this one.

import * as ko from "knockout";
import { debounce } from "underscore";
import { parseText } from "Core/Medius.Core.Web/Scripts/components/mention/editor/mentionEditorParser";
import { EditorBase, SuggestionPresentationMode } from "Core/Medius.Core.Web/Scripts/components/mention/editor/editorBase";
import { IMentionDefaultEditorOptions, ISuggestion, ISuggestionsProvider } from "Core/Medius.Core.Web/Scripts/components/mention/editor/IEditorOptions";

export class Editor extends EditorBase {
    private readonly suggestionsProvider: ISuggestionsProvider;
    private readonly debouncedFetch: ((searchTerm: string) => void) & _.Cancelable;
    private canFetchNextPart = false;
    private readonly autocompleterLimit: number;

    public readonly searchTerm = ko.observable<string>();
    public readonly suggestions: ko.ObservableArray<ISuggestion> = ko.observableArray<ISuggestion>(null);
    public readonly selectedSuggestion: ko.Observable<ISuggestion> = ko.observable<ISuggestion>();

    private readonly suggestionsSubscription: ko.Subscription;

    constructor(value: ko.Observable<string>, options: IMentionDefaultEditorOptions) {
        super(value, options);

        this.suggestionsProvider = options.suggestionsProvider;
        this.debouncedFetch = debounce(this.fetchResults, options.autocompleterRequestDelay);
        this.autocompleterLimit = options.autocompleterLimit;

        this.suggestionsSubscription = this.suggestions.subscribe(suggestions => {
            this.onSuggestionsChanged(suggestions);
        });
    }

    // Event handlers

    public onScroll(viewModel: EditorBase, event: JQueryEventObject) {
        const element = event.target as any;
        const isAtTheBottom = element.scrollTop + element.offsetHeight > element.scrollHeight;
        const limit = this.autocompleterLimit;

        if (isAtTheBottom && this.canFetchNextPart) {
            const promise = this.suggestionsProvider.fetchSuggestionsPart(this.searchTerm(), this.suggestions().length, limit);

            promise.done(suggestions => {
                if (suggestions.length < limit) this.canFetchNextPart = false;
                this.suggestions.push(...suggestions);
            });
        }
    }

    public dropDownItemMouseDown(selectedSuggestion: ISuggestion) {
        this.selectedSuggestion(selectedSuggestion);
        this.completeWithSelectedSuggestion();
    }

    public onBlur() {
        this.debouncedFetch.cancel();
        this.suggestionsProvider.abortRequest();
        super.onBlur();
    }

    // Dispose

    public dispose() {
        this.debouncedFetch.cancel();
        this.suggestionsSubscription.dispose();
        super.dispose();
    }

    // Private methods
    
    protected onValidMentionFound(searchTerm: string) {
        this.debouncedFetch(searchTerm);

        this.suggestionsPresentationMode(SuggestionPresentationMode.ShowFetching);
        this.searchTerm(searchTerm);
        this.canFetchNextPart = true;
    }

    protected onInvalidMentionFound(searchTerm: string) {
        this.debouncedFetch.cancel();
        this.suggestionsProvider.abortRequest();

        this.suggestionsPresentationMode(SuggestionPresentationMode.ShowHint);
        this.searchTerm(searchTerm);
        this.canFetchNextPart = true;
    }

    protected onMentionNotFound() {
        this.debouncedFetch.cancel();
        this.suggestionsProvider.abortRequest();

        this.suggestionsPresentationMode(SuggestionPresentationMode.ShowNothing);
        this.suggestions(null);
    }

    protected onArrowUp() {
        // select next suggestion
        this.changeSelectedSuggestion(-1);
        return false;
    }

    protected onArrowDown() {
        // select previous suggestion
        this.changeSelectedSuggestion(+1);
        return false;
    }

    protected onEnter() {
        if (this.selectedSuggestion()) {
            this.completeWithSelectedSuggestion();
            return false;
        }
    }

    private onSuggestionsChanged(suggestions: Array<ISuggestion>) {
        let newSuggestion = SuggestionPresentationMode.ShowNothing;
        let newSelectedSuggestion: ISuggestion = null;

        if (suggestions && suggestions.length) {
            newSuggestion = SuggestionPresentationMode.ShowResults;
            newSelectedSuggestion = suggestions.indexOf(this.selectedSuggestion()) >= 0 ? this.selectedSuggestion() : suggestions[0];
        }
        else if (this.latestMentionSeekResult.mentionFound) {
            newSuggestion = SuggestionPresentationMode.ShowNoResults;
        }

        this.suggestionsPresentationMode(newSuggestion);

        if (this.selectedSuggestion() !== newSelectedSuggestion) {
             this.selectedSuggestion(newSelectedSuggestion); // Ugly workaround for obscure scroll binding behavior
        }
    }
   
    private changeSelectedSuggestion(offset: any) {
        if (this.selectedSuggestion()) {
            const index = this.suggestions().indexOf(this.selectedSuggestion());
            this.selectedSuggestion(this.suggestions()[(this.suggestions().length + (index + offset)) % this.suggestions().length]);
        }
        else {
            this.selectedSuggestion(this.suggestions()[0]);
        }
    }

    private completeWithSelectedSuggestion() {
        const identifier = this.selectedSuggestion().identifier;
        const parseResult = parseText(this.value(), this.latestMentionSeekResult, identifier);

        this.value(parseResult.parsedText);
        this.cursorPosition(parseResult.cursorPosition);
    }

    private fetchResults(searchTerm: string) {
        this.suggestionsProvider.fetchSuggestions(searchTerm)
            .done(suggestions => this.suggestions(suggestions))
            .fail(failResult => {
                if (failResult && failResult.aborted)
                    return;

                this.suggestions(null);
            });
    }
}
