///<amd-module name = "Core/Medius.Core.Web/Scripts/components/taskHandling/fraudPopup/InvalidatePopupTemplate"/>
import * as React from "react";
import { ConfirmDialog, Text, RadioButtonGroup } from "@medius/ui-controls";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { handleError } from "Core/Medius.Core.Web/Scripts/lib/errorHandling/errorHandler";
import { RiskFactorsForInvoice, RiskFactorReasonCode } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/RiskFactor/RiskFactorComponentDtos/riskFactorsForInvoiceDto";
import { saveRiskDetails } from "Core/Medius.Core.Web/Scripts/components/taskHandling/fraudPopup/service";

interface InvalidatePopupTemplateInterface {
    showPopup: boolean,
    onConfirm: () => void,
    onCancel: () => void,
    riskDetails: RiskFactorsForInvoice
    taskId: number
}

export const InvalidatePopupTemplate = ({showPopup, onConfirm, onCancel, riskDetails, taskId}:InvalidatePopupTemplateInterface) => {
    const [radioButtonState, setRadioButtonState] = React.useState("other");

    const handleConfirm = () => {
        let reason:RiskFactorReasonCode;
        switch(radioButtonState) {
            case "fraudAttempt":
                reason = RiskFactorReasonCode.InvoiceDeletedFraudAttempt;
                break;
            case "other":
                reason = RiskFactorReasonCode.InvoiceDeletedOther;
                break;
            default:
                return;
        }

        riskDetails.riskFactors = riskDetails.riskFactors.map(rf => ({...rf, 
            handledInfo: {
                ...rf.handledInfo,
                reasonCode: reason,
            }}));
        
        const riskDetailsDto = riskDetails.riskFactors.map(rf => (
            {   
                RiskFactorCode: rf.code,
                HandlingReason: reason
            }
        ));

        saveRiskDetails(taskId, riskDetailsDto).then(() => {
            onConfirm();
        })
        .catch(error => {
            handleError(error);
        });
    };
    return (<>
        <ConfirmDialog 
            isOpen={showPopup}
            cancelLabel={getLabelTranslation("#Core/cancel")}
            confirmLabel={getLabelTranslation("#Core/invalidateConfirmation")}
            data-testid="invalidatePopup"
            onCancel={onCancel}
            onConfirm={handleConfirm}>
            <Text data-testid="invalidatePopup-title" variant="heading2">{getLabelTranslation("#Core/invalidatePopupTitle")}</Text>
            <Text data-testid="invalidatePopup-text" variant="body">{getLabelTranslation("#Core/invalidatePopupBody")}</Text>  
            <RadioButtonGroup
                data-testid="invalidatePopup-radioButtons"
                layout="column"
                options={[
                  {
                    label: getLabelTranslation("#Core/fraudAttempt"),
                    value: 'fraudAttempt',
                    "data-testid":"invalidatePopup-radioButton-fraudAttempt"
                  },
                  {
                    label: getLabelTranslation("#Core/identifierTypeOther"),
                    value: 'other',
                    "data-testid":"invalidatePopup-radioButton-other"
                  }
                ]}
                onChange={({value}: any) => setRadioButtonState(value)}
                value={radioButtonState}
                variant="radio"
            />
        </ConfirmDialog>
    </>);
};