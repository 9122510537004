/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/lib/deferred"/>

import * as _ from "underscore";
import * as $ from "jquery";

function pipedHandler(continuation: any) {
    return function() {
        if (arguments.length !== 1 || !(arguments[0] instanceof Array)) {
            continuation.apply(this, arguments);
        }

        continuation.apply(this, arguments[0]);
    };
}

export function pipeApply(source: any, options: any): any{
    options = _({
        excludeDone: false,
        includeFail: false
    }).extend(options);

    if (!source || typeof source !== "object") {
        throw new Error("Source must be a deferred object");
    }

    const newDeferred = $.Deferred();

    if (!options.excludeDone) {
        source.done(pipedHandler(newDeferred.resolve));
    }
    if (options.includeFail) {
        source.fail(pipedHandler(newDeferred.reject));
    }

    return newDeferred;
}

export function when(promises: any): any{
    if (arguments.length > 1 || !(promises instanceof Array)) {
        return $.when.apply($, arguments);
    }
    return $.when.apply($, promises);
}