/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/PaymentTerm/legacyCustomFieldsEditorViewModel"/>
import { CustomFields } from "./customFields";
import { LegacyCompanyViewModel } from "./legacyCompanyViewModel";
import {observable} from "knockout";

import contextFactory = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/context");

interface Parameters {
    entityType: string;
    entityId: number;
    company: LegacyCompanyViewModel;
    customFields: CustomFields;
}

export class LegacyCustomFieldsEditorViewModel {
    public EntityWithContext: any;

    constructor(entityWithContext: any) {
        this.EntityWithContext = entityWithContext;
    }

    public toRequest(): CustomFields {
        const legacyDto = this.EntityWithContext.Entity.CustomFields().toJS();

        const dto: {[key: string]: any} = {};
        Object.keys(legacyDto).forEach(key => dto[key[0].toLowerCase() + key.substr(1)] = legacyDto[key]);
        return dto as CustomFields;
    }

    public static create(parameters: Parameters): LegacyCustomFieldsEditorViewModel {
        const context = contextFactory();

        const legacyCustomFieldsDto: {[key: string]: any} = {
            $type: "Medius.Core.Entities.CustomFields.CustomFieldsContainer, Medius.Core.Common"
        };

        Object.keys(parameters.customFields).forEach(key => legacyCustomFieldsDto[key[0].toUpperCase() + key.substr(1)] = (parameters.customFields as any)[key]);

        const customFieldsViewModel = contextFactory().create(legacyCustomFieldsDto);

        return new LegacyCustomFieldsEditorViewModel({
            Entity: {
                $type: parameters.entityType,
                Id: observable(parameters.entityId),
                Company: observable(parameters.company),
                CustomFields: observable(customFieldsViewModel)
            },
            Context: context
        });
    }
}
