/// <amd-module name="Core/Medius.Core.Web/Scripts/components/quickSearch/suggestion/suggestion"/>
import * as ko from "knockout";
import { SuggestionDto } from "Core/Medius.Core.Web/Scripts/components/quickSearch/quickSearchDtos";
import * as inboxManagerService from "Core/Medius.Core.Web/Scripts/components/quickSearch/services/inboxManagerService";
import * as availableTasksModal from "Core/Medius.Core.Web/Scripts/components/availableTasksModal/availableTasksModal";
import * as backendErrorHandler from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import { getBasePath } from "Core/Medius.Core.Web/Scripts/Medius/lib/path";
import { navigateTo } from "../services/windowService";

type SuggestionSelectedHandler = (suggestion: Suggestion) => void;

export default class Suggestion {
    public readonly entityId: number;
    public isSelected: ko.Observable<boolean> = ko.observable(false);
    public templateName: string;
    public searchTerm: string;
    public suggestionUrl: string;

    constructor(suggestionDto: SuggestionDto) {
        this.entityId = suggestionDto.entityId;
        this.suggestionUrl = `${getBasePath()}#Tasks/ShowDocument/${this.entityId}`;
    }

    public onSuggestionSelected: SuggestionSelectedHandler = () => {};

    public openFromEntityId = () => {
        navigateTo(this.suggestionUrl);
    }

    public openFromTaskId = (taskId: number) => {
        navigateTo(`${getBasePath()}#DocumentTasks/${taskId}`);
    }

    public suggestionSelected() {
        inboxManagerService
            .tasksAssignmentsFor(this.entityId)
            .done((taskAssignments: any) => {
                if (taskAssignments.length === 1) {
                    this.openFromEntityId();
                } else {
                    availableTasksModal.getInstance().show(taskAssignments, this.openFromTaskId);
                }
                this.onSuggestionSelected(this);
            })
            .fail(xhr => backendErrorHandler.handleAnyError(xhr));
    }

    public handleMouseDown() {
        return false;
    }
}
