/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/metadata/entity/templates/link"/>
import {unpack} from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import { getBasePath } from "Core/Medius.Core.Web/Scripts/Medius/lib/path";

const concatUrlSegments = function (segments: string[]) {
    return segments.join("/").replace("//", "/");
};

export const linkTemplates = {
    "Medius.Data.DataDefinition": function (entity: any, entityType: string) {
        return concatUrlSegments([getBasePath(), "#Administration", entityType, unpack(entity.Id)]);
    },
    "Medius.Data.Document": function (entity: any) {
        return concatUrlSegments([getBasePath(), "#Tasks", "ShowDocument", unpack(entity.Id)]);
    },
    "Medius.Data.Task": function(entity: any) {
        return concatUrlSegments([getBasePath(), "#Tasks", unpack(entity.Id)]);
    }
};
