/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/lib/logger"/>

let logger: Pick<Console, "debug" | "trace" | "error" | "warn" | "info" | "log" | "assert" | "clear" | "dir" | "profile" | "profileEnd">;

const emptyHandler = function () { };

const names = ["debug", "trace", "error", "warn", "info", "log", "assert", "clear", "dir", "profile", "profileEnd"];

// Set up the real console if it is avaliable.
// https://stackoverflow.com/a/52921595/6322395
if (!window.console || Object.keys(window.console).length === 0) {
    logger = {} as any;
    names.forEach((name) => {
        (logger as any)[name] = emptyHandler;
    });
} else {
    names.forEach((name) => {
        const windowConsole: any = window.console;
        if (!windowConsole[name]) {
            windowConsole[name] = window.console.log;
        }
    });
    logger = window.console;
}

export const debug = logger.debug;
export const trace = logger.trace;
export const error = logger.error;
export const warn = logger.warn;
export const info = logger.info;
export const log = logger.log;
export const assert = logger.assert;
export const clear = logger.clear;
export const dir = logger.dir;
export const profile = logger.profile;
export const profileEnd = logger.profileEnd;