///<amd-module name = "Core/Medius.Core.Web/Scripts/components/taskHandling/fraudPopup/DeletePopupTemplate"/>
import * as React from "react";
import { ConfirmDialog, Text, RadioButtonGroup } from "@medius/ui-controls";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { handleError } from "Core/Medius.Core.Web/Scripts/lib/errorHandling/errorHandler";
import { RiskFactorsForInvoice, RiskFactorReasonCode, RiskFactorCode } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/RiskFactor/RiskFactorComponentDtos/riskFactorsForInvoiceDto";
import { saveRiskDetails } from "Core/Medius.Core.Web/Scripts/components/taskHandling/fraudPopup/service";

interface DeletePopupTemplateInterface {
    showPopup: boolean,
    onConfirm: () => void,
    onCancel: () => void,
    riskDetails: RiskFactorsForInvoice
    taskId: number
}

const baseRadioOptions = [
    {
        label: getLabelTranslation("#Core/fraudAttempt"),
        value: 'fraudAttempt',
        "data-testid":"deletePopup-radioButton-fraudAttempt"
    },
    {
        label: getLabelTranslation("#Core/identifierTypeOther"),
        value: 'other',
        "data-testid":"deletePopup-radioButton-other"
    }
];

export const DeletePopupTemplate = ({showPopup, onConfirm, onCancel, riskDetails, taskId}:DeletePopupTemplateInterface) => {
    const [radioButtonState, setRadioButtonState] = React.useState<"other" | "fraudAttempt" | "duplicateInvoice">("other");
    const [hasDuplicateInvoiceRiskFactor, setHasDuplicateInvoiceRiskFactor] = React.useState(false);
    const [radioButtonOptions, setRadioButtonOptions] = React.useState<{label:string, value:string, "data-testid":string}[]>(baseRadioOptions);

    React.useEffect(() => {
        const duplicateRiskFactorPresent = riskDetails?.riskFactors?.some(rf => rf.code === RiskFactorCode.DuplicatedInvoice);
        setHasDuplicateInvoiceRiskFactor(duplicateRiskFactorPresent);
    }, [riskDetails]);

    React.useEffect(() => {
        if(hasDuplicateInvoiceRiskFactor) {
            setRadioButtonOptions(
                [
                    {
                        label:getLabelTranslation("#Core/duplicateInvoice"),
                        value: 'duplicateInvoice',
                        "data-testid":"deletePopup-radioButton-duplicateInvoice"
                    },
                    ...baseRadioOptions
                ]
            );
        }
        else {
            setRadioButtonOptions(baseRadioOptions);
        }
    }, [hasDuplicateInvoiceRiskFactor]);

    const handleConfirm = () => {
        let reason:RiskFactorReasonCode;
        switch(radioButtonState) {
            case "duplicateInvoice":
                if(!hasDuplicateInvoiceRiskFactor) {
                    return;
                }
                reason = RiskFactorReasonCode.InvoiceDeletedDuplicateInvoice;
                break;
            case "fraudAttempt":
                reason = RiskFactorReasonCode.InvoiceDeletedFraudAttempt;
                break;
            case "other":
                reason = RiskFactorReasonCode.InvoiceDeletedOther;
                break;
            default:
                return;
        }
        riskDetails.riskFactors = riskDetails.riskFactors.map(rf => ({...rf, 
            handledInfo: {
                ...rf.handledInfo,
                reasonCode: reason,
            }}));
        
        const riskDetailsDto = riskDetails.riskFactors.map(rf => (
            {   
                RiskFactorCode: rf.code,
                HandlingReason: reason
            }
        ));

        saveRiskDetails(taskId, riskDetailsDto).then(() => {
            onConfirm();
        })
        .catch(error => {
            handleError(error);
        });
    };
    return (<>
        <ConfirmDialog 
            isOpen={showPopup}
            cancelLabel={getLabelTranslation("#Core/cancel")}
            confirmLabel={getLabelTranslation("#Core/deleteConfirmation")}
            data-testid="deletePopup"
            onCancel={onCancel}
            onConfirm={handleConfirm}>
            <Text data-testid="deletePopup-title" variant="heading2">{getLabelTranslation("#Core/deletePopupTitle")}</Text>
            <Text data-testid="deletePopup-text" variant="body">{getLabelTranslation("#Core/deletePopupBody")}</Text>  
            <RadioButtonGroup
                data-testid="deletePopup-radioButtons"
                layout="column"
                options={radioButtonOptions}
                onChange={({value}: any) => setRadioButtonState(value)}
                value={radioButtonState}
                variant="radio"
            />
        </ConfirmDialog>
    </>);
};