/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/dashboard/gadget"/>

import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import * as _ from "underscore";
import { extend } from "underscore";
import * as ko from "knockout";
import { ajax } from "Core/Medius.Core.Web/Scripts/Medius/core/rpc";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { logFromError } from "Core/Medius.Core.Web/Scripts/lib/messageLogging/messageLogger";
import { handleAnyError } from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import { error, log } from "Core/Medius.Core.Web/Scripts/Medius/lib/logger";
import { toJSON } from "Core/Medius.Core.Web/Scripts/Medius/lib/serialization";
import * as gadgetSetting from "Core/Medius.Core.Web/Scripts/Medius/apps/dashboard/gadgetSetting";
import { getGadgetDefinition } from "Core/Medius.Core.Web/Scripts/Medius/apps/dashboard/gadgetDefinitions";
import { DeleteGadgetConfirmationDialog } from "Core/Medius.Core.Web/Scripts/Medius/apps/dashboard/deleteGadgetConfirmationDialog";

class Gadget {
    params: any;
    Dashboard: any;
    GadgetDashboard: any;
    Settings: ko.ObservableArray<any>;
    ContainerId: string;
    Title: ko.Observable<any>;
    IsContentVisible: ko.Observable<boolean>;
    IsAvailable: any;
    IsSettingsPageOpen: ko.Observable<boolean>;
    IsLoading: ko.Observable<boolean>;
    GadgetTemplate: ko.Observable<string>;
    Context: any;
    SpecificGadget: ko.Observable<any>;
    FileContent: any;
    Position: any;
    OpenDeleteConfirmationPopup: () => void;
    IsPopupOpened: ko.Observable<boolean>;
    ConfirmationPopupTemplate: ko.Computed<any>;

    constructor(dashboard: any, gadgetDashboard: any, options: any) {
        this.params = extend({
            title: null,
            settings: null,
            testMode: false,
            available: true
        }, options);

        if (dashboard === null || isNullOrUndefined(gadgetDashboard)) {
            throw new Error("Gadget init error");
        }

        // Properties
        this.Dashboard = dashboard; // Handler to Dashboard that produced the gadget
        this.GadgetDashboard = gadgetDashboard; // GadgetDashboard entity contains plenty of useful information
        this.Settings = ko.observableArray([]);
        this.ContainerId = "gadget-" + this.GadgetDashboard.Id;
        this.Title = ko.observable(this.params.title);
        this.IsContentVisible = ko.observable(true);
        this.IsAvailable = this.params.available;
        this.IsSettingsPageOpen = ko.observable(false);
        this.IsLoading = ko.observable(false);
        this.GadgetTemplate = ko.observable("");
        this.Context = this.Dashboard.Framework.Context;
        this.SpecificGadget = ko.observable();
        this.FileContent = this.GadgetDashboard.Gadget.FileContent;
        this.Position = this.GadgetDashboard.ColumnIndex;

        this.init();

        this.OpenDeleteConfirmationPopup = () => {
            this.IsPopupOpened(true);
        };

        this.IsPopupOpened = ko.observable(false);
        this.ConfirmationPopupTemplate =  ko.computed(() => {
            return ({
                functionComponent: DeleteGadgetConfirmationDialog,
                props: {
                    IsDialogOpen: this.IsPopupOpened(),
                    OnConfirm: () => {
                        this.IsPopupOpened(false);
                        this.Dashboard.closeGadget(this);
                    },
                    OnCancel: () => {
                        this.IsPopupOpened(false);
                    }
                }
            });
        });
    }

    init() {
        // Map settings
        this.mapSettings(this.params.settings);

        // Load code
        return this;
    }

    render() {
        const gadgetDefinition = getGadgetDefinition(this.FileContent) ?? require(this.FileContent);

        const specificGadget = gadgetDefinition(this);
        this.SpecificGadget(specificGadget);

        return specificGadget.render(true);
    }

    /**
    * Updates gadget's setting
    * from persitence layer
    * @param defaults default settings
    * @ignore
    */
    mapSettings(defaults: any) {
        let ar: any[];

        // Settings from persistence layer have been loaded
        if (!isNullOrUndefined(defaults) && !isNullOrUndefined(this.Settings())) {
            ar = [];

            _(defaults).each((d) => {
                let value = d.Value,
                    id = d.Id;

                _(this.Settings()).each((s) => {
                    if (d.Name === s.Name) {
                        id = s.Id;

                        if (s.Value() !== null) {
                            value = s.Value();

                            if (d.Type === "checkbox") {
                                value = (parseInt(value, 10) === 1) ? true : false;
                            }
                        }
                    }
                });

                const setting = gadgetSetting.create();
                setting.Id = id;
                setting.Name = d.Name;
                setting.InputName = "gs-" + this.GadgetDashboard.Id + "-" + d.Name;
                setting.Description = d.Description;
                setting.Value(value);
                setting.AvailableValues = d.AvailableValues;
                setting.Type = d.Type;
                setting.MinValue = d.MinValue;
                setting.MaxValue = d.MaxValue;

                ar.push(setting);
            });

            this.Settings(ar);
        }
    }

    /**
    * Saves settings
    * @ignore
    */
    saveSettings() {
        const settingsToSend: { Id: any; Name: any; Value: any; }[] = [];

        _(this.Settings()).each((e) => {
            let value = e.Value();
            if (e.Type === "checkbox") {
                value = (value === false || value === "false" ||
                    value === "False") ? 0 : 1;
            }

            settingsToSend.push({ Id: e.Id, Name: e.Name, Value: value });
        });

        // Save settings
        if (settingsToSend.length > 0) {
            ajax("CoreGadgetService", "SaveGadgetSettings", {
                data: toJSON({
                    gadgetDashboardId: this.GadgetDashboard.Id,
                    gadgetSettings: settingsToSend
                })
            }).done(() => {
                // Rerender gadget with new settings
                this.rerenderGadget();
            }).fail((xhr: any) => {
                // Display error message
                this.errorHandler(xhr, getLabelTranslation("#Core/gadgetsSettingsNotSavedError"));
            });
        }

        // Close settings page
        this.IsSettingsPageOpen(false);
    }

    /**
    * Rerenders gadget's content using method render() that must be implemented
    * in the specific gadget
    * @ignore
    */
    rerenderGadget() {
        // Force gadget that is loaded to rerender itself
        try {
            if (!isNullOrUndefined(this.SpecificGadget())) {
                this.SpecificGadget().render();
            } else {
                throw new Error("specificGadget is null");
            }
        } catch (e) {
            logFromError(e);
            error("Exception inside gadget.renderGadget function: " + e);
        }
    }

    disposeGadget() {
        if (isNullOrUndefined(this.SpecificGadget()) || !this.SpecificGadget().dispose || typeof this.SpecificGadget().dispose !== 'function') {
            return;
        }

        this.SpecificGadget().dispose();
    }

    /**
    * @ignore
    */
    printSettings(title: string) {
        let str = title + ": ";

        _(this.Settings()).each((item) => {
            str += "{Id=" + item.Id + ", Name=" + item.Name +
                ", Value=" + item.Value() + "} , ";
        });
        log(str);
    }

    errorHandler(jqXhr: any, exceptionDetail: any) {
        handleAnyError(jqXhr, null, exceptionDetail);
    }

    toggleSettings() {
        const state = this.IsSettingsPageOpen();

        this.IsSettingsPageOpen(!state);
    }

    toggleMinimize() {
        const state = this.IsContentVisible();

        this.IsContentVisible(!state);
    }
}

export function create(dashboard: any, gadgetDashboard: any, options: any) {
    return new Gadget(dashboard, gadgetDashboard, options);
}

export const init = Gadget.prototype.init;
export const render = Gadget.prototype.render;
