///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/document/models/HashFiles/hashFilesDataSource"/>

import DataSource = require("Core/Medius.Core.Web/Scripts/Medius/components/grid/dataSource/default");
import * as $ from "jquery";

class HashFilesDataSource extends DataSource {
    EntityCollection: any;
    constructor(entityCollection: any) {
        super();
        this.EntityCollection = entityCollection;
    }

    load(dataQuery: any) {
        const startIndx = (dataQuery.page - 1) * dataQuery.pageSize;
        const endIndx = dataQuery.page * dataQuery.pageSize;
        const entities = this.EntityCollection().slice(startIndx, endIndx);
        return $.Deferred().resolve(entities);
    }

    loadColumns() {

        const columns = [
            {
                ColumnName: "Name",
                ValueSource: "Name",
                ValueSourceType: "System.String",
                Searchable: false,
                Sortable: false,
                Width: 500
            },
            {
                ColumnName: "",
                ValueSource: null as string,
                Searchable: false,
                Sortable: false,
                Width: 50
            }
        ];
        return $.Deferred().resolve(columns);
    }

    getTotalRows() {
        return this.EntityCollection().length;
    }

    destroy() {
        this.EntityCollection = null;
        super.destroy();
    }
}

export function create(collection: any) {
    return new HashFilesDataSource(collection);
}
