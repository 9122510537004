/// <amd-module name="Core/Medius.Core.Web/Scripts/components/grid/isActiveCell"/>
import * as React from "react";
import { ChangeEvent } from "react";
import { GridCellProps, GridFilterCellProps } from "@progress/kendo-react-grid";
import { BoolToggle, BoolToggleElement } from "Core/Medius.Core.Web/Scripts/shared/components/boolToggle";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";


export const IsActiveCell = ({ dataItem }: GridCellProps) => (
    <td>
        {dataItem.IsActive ? translate("#Core/yes") : translate("#Core/no")}
</td>
);

export const IsActiveFilterCell = ({ onChange }: GridFilterCellProps) => {
    const onToggle = (syntheticEvent: ChangeEvent<BoolToggleElement>) => {
        const value = syntheticEvent.target.value;
        onChange({
            value,
            operator: value !== null ? "eq" : "",
            syntheticEvent
        });
    };

    return <BoolToggle
        onToggle={onToggle}
        trueLabel={translate("#Core/yes")}
        falseLabel={translate("#Core/no")} />;
};
