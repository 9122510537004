///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/grid/bindings/droppableRows"/>
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as _ from "underscore";

const dropRowsZone = {
    init: function (element:any, bindingAccessor:any, allAccessor:any, viewModel:any, bindingContext:any) {
        let $element = $(element);
        let onDrop = bindingAccessor().onDrop;
        let action = bindingAccessor().action;
        let dropEnabled = bindingAccessor().dropEnabled;
        let draggedRows:any;

        const dropOptions = {
            accept: ".grid-row",
            tolerance: "pointer",
            drop: function (/*ev, ui*/) {
                const grid = bindingContext.$root.draggedGrid;

                if (!grid) {
                    return;
                }

                if (onDrop) {
                    onDrop();
                }

                if (grid.MultiselectEnabled) {
                    draggedRows = grid.getSelectedRows();

                    _(draggedRows).each(function (row) {
                        grid.markRow(koUtils.unpack(row.Id), false);
                    });
                } else {
                    draggedRows = [grid.SelectedRow()];
                }

                grid.SelectedRow(null);
                bindingContext.$root.draggedGrid = null;

                if (typeof action === 'function') {
                    action(draggedRows);

                    $element.removeClass("drop-enabled");
                    $element.removeClass("drop-disabled");
                }
            },
            over: function (/*ev, ui*/) {
                const grid = bindingContext.$root.draggedGrid;
                let draggedRows:any;

                if (!grid) {
                    return;
                }

                if (grid.MultiselectEnabled) {
                    draggedRows = grid.getSelectedRows();
                }
                else {
                    draggedRows = [grid.SelectedRow()];
                }

                if (typeof dropEnabled === 'function') {
                    const isDropEnabled = dropEnabled(draggedRows);

                    $element.addClass(isDropEnabled ? "drop-enabled" : "drop-disabled");
                }
            },
            out: function (/*ev, ui*/) {
                $element.removeClass("drop-enabled");
                $element.removeClass("drop-disabled");
            }
        };

        $element.droppable(dropOptions);

        const dispose = function () {
            onDrop = null;
            action = null;
            dropEnabled = null;
            $element = null;
        };

        koUtils.addDisposeCallback(element, dispose);
    }
};

export function register() {
    koUtils.registerBinding("grid-droppableRows", dropRowsZone);
}