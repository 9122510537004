/// <amd-module name="Core/Medius.Core.Web/Scripts/components/pdfViewer/pdfViewerUxLog"/>
import {logEvent} from "Core/Medius.Core.Web/Scripts/lib/metricsLogging/metricLogger";

export function logImagePrint(documentType: string) {
    const scenario = "Image viewer - Print";
    log(scenario, documentType);
}

export function logImageDownload(documentType: string) {
    const scenario = "Image viewer - Download";
    log(scenario, documentType);
}

export function logImageSendByEmail(documentType: string) {
    const scenario = "Image viewer - Send by email";
    log(scenario, documentType);
}

function log(scenario: string, documentType: string) {
    if (documentType) {
        const s = documentType.split(".");
        documentType = documentType.split(".")[s.length - 1];
    }

    logEvent(scenario, documentType);
}
