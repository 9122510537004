/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/document/spa"/>
import { applications } from "Core/Medius.Core.Web/Scripts/Medius/apps/spaManager/applications";
import * as route from "Core/Medius.Core.Web/Scripts/Medius/apps/spaManager/route";
import * as $ from "jquery";
import * as documentEvents from "Core/Medius.Core.Web/Scripts/Medius/apps/document/events";
import * as eventBus from "Core/Medius.Core.Web/Scripts/eventBus";
import * as eventNames from "Core/Medius.Core.Web/Scripts/events/eventNames";
import {once} from "underscore";
import * as appsUtils from "Core/Medius.Core.Web/Scripts/Medius/apps/utils";
import * as documentHandling from "Core/Medius.Core.Web/Scripts/Medius/apps/document/documentHandling";
import * as Backbone from "backbone";

class DocumentApp {
    public containerId: any;
    public app: any;
    public history: any[] = [];
    public name = applications.createDocument;
    public routes: ReturnType<typeof route.create>[];
    public registerEvents: () => void;
    public initialize: () => void;
 
    constructor(spaManager: any, containerId: any) {
        this.containerId = containerId;
        this.app = documentHandling.create($("#document-container").get(0));

        const create = (type: any) => {
            $(document).trigger(documentEvents.documentCreateRequest, type);
            spaManager.openApp(this);
        };

        const openReclassifyTypeSelector = (task: any) => {
            $(document).trigger(documentEvents.reclassifySelectorOpened, [task]);
            spaManager.router.navigate("/DocumentCreate/Create/Reclassify");
            eventBus.trigger(eventNames.urlHashChanged);
            spaManager.openApp(this);
        };

        this.routes = [
            route.create("DocumentCreate/Create/:type", create),
            route.create("DocumentCreate/Create/:type/", create)
        ];

        this.registerEvents = () => {
            $(window)
                .off(documentEvents.documentCreateRequest)
                .on(documentEvents.documentCreateRequest, (e, docType) => {
                    if ((Backbone.history as any).fragment.indexOf("DocumentCreate/Create") === -1) {
                        this.history.push((Backbone.history as any).fragment);
                    }
                    spaManager.router.navigate("DocumentCreate/Create/" + docType, { trigger: true });
                })
                .off(documentEvents.documentReclassificationRequest)
                .on(documentEvents.documentReclassificationRequest, (e, task) => {
                    openReclassifyTypeSelector(task);
                });
        };

        this.initialize = once(() => {

            $(window).on(documentEvents.cancel, () => {

                if (this.history.length > 0) {
                    spaManager.router.navigate(
                        this.history[this.history.length - 1], { trigger: true });
                } else {
                    spaManager.router.navigate("Tasks", { trigger: true });
                }
            });
        });
    }
}

export function create(spaManager: any, containerId: any) {
    const documentApp = new DocumentApp(spaManager, containerId);
    documentApp.registerEvents();
    return documentApp;
}

export function getView() {
    return appsUtils.renderTemplate("document-template");
}
