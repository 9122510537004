///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/presenters/bindings"/>
import * as componentUtils from "Core/Medius.Core.Web/Scripts/Medius/components/utils";
import * as presentersUtils from "Core/Medius.Core.Web/Scripts/Medius/components/presenters/utils";

export const configuration = {
    "bool": {
        factory: "Core/Medius.Core.Web/Scripts/Medius/components/presenters/bool/model"
    },
    "date": {
        factory: "Core/Medius.Core.Web/Scripts/Medius/components/presenters/date/model"
    },
    "number": {
        factory: "Core/Medius.Core.Web/Scripts/Medius/components/presenters/number/model"
    },
    "amount": {
        factory: "Core/Medius.Core.Web/Scripts/Medius/components/presenters/amount/model"
    },
    "unitprice": {
        factory: "Core/Medius.Core.Web/Scripts/Medius/components/presenters/unitprice/model"
    },
    "quantity": {
        factory: "Core/Medius.Core.Web/Scripts/Medius/components/presenters/quantity/model"
    },
    "percentage": {
        factory: "Core/Medius.Core.Web/Scripts/Medius/components/presenters/percentage/model"
    },
    "text": {
        factory: "Core/Medius.Core.Web/Scripts/Medius/components/presenters/text/model"
    },
    "entity-link": {
        factory: "Core/Medius.Core.Web/Scripts/Medius/components/presenters/link/entity/model"
    },
    "labels": {
        factory: "Core/Medius.Core.Web/Scripts/Medius/components/presenters/labels/model"
    },
    "currencyRate": {
        factory: "Core/Medius.Core.Web/Scripts/Medius/components/presenters/currencyRate/model"
    }
};

export function register() {
    componentUtils.registerBindings(configuration, presentersUtils);
}