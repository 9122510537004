/// <amd-module name="Core/Medius.Core.Web/Scripts/components/procurement/inboxSlice"/>

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "Core/Medius.Core.Web/Scripts/shared/store/reduxStore";

interface Inbox {
    searchFilter: string,
    viewType: string,
    gridSkip: number,
    gridTake: number,
    gridSortField: string,
    gridSortDir: string,
}

const initialState: Inbox = { 
    searchFilter: null,
    viewType: null,
    gridSkip: null,
    gridTake: null,
    gridSortField: null,
    gridSortDir: null,
};

export const inboxSlice = createSlice({
    name: 'inbox',
    initialState,
    reducers: {
        setSearchFilter: (state, action: PayloadAction<string>) => {
            state.searchFilter = action.payload;
        },
        setViewType: (state, action: PayloadAction<string>) => {
            state.viewType = action.payload;
        },
        setGridSkip: (state, action: PayloadAction<number>) => {
            state.gridSkip = action.payload;
        },
        setGridTake: (state, action: PayloadAction<number>) => {
            state.gridTake = action.payload;
        },
        setGridSortField: (state, action: PayloadAction<string>) => {
            state.gridSortField = action.payload;
        },
        setGridSortDir: (state, action: PayloadAction<string>) => {
            state.gridSortDir = action.payload;
        },
        clear: () => initialState
    }
});

export const { setSearchFilter, setViewType, setGridSkip, setGridTake, setGridSortField, setGridSortDir, clear } = inboxSlice.actions;

export function inboxSelector(s: RootState) { return s.procurement.inbox; }
