/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/lib/path"/>

import {isNullOrUndefined} from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import * as logger from "Core/Medius.Core.Web/Scripts/Medius/lib/logger";

let basePath: string;
let tenantId: string;
const pathSeparator = "/";

function setBasePath (p: string) {
    basePath = pathSeparator + p.replace(/^\//g, "").replace(/\/$/, "");
    if (basePath !== pathSeparator) {
        basePath += pathSeparator;
    }
}

export function appBase(path: string, aTenantId: string) {
    logger.info(`Setting up application path as ${path}.`);
    logger.info(`Setting up application tenantID as ${aTenantId}.`);

    setBasePath(path);
    tenantId = aTenantId;
}

export function getBasePath() {
    if (isNullOrUndefined(basePath)) {
        throw "Invalid Core/Medius.Core.Web/Scripts/Medius/lib/path usage. Do not invoke getBasePath before module is initialized.";
    }
    return basePath;
}

export function getTenantId() {
    return tenantId;
}

export function toAction(action: string, controllerPath: string | undefined | null = null) {
    let path;
    let n;

    if (typeof controllerPath === 'undefined' || controllerPath === null) {
        path = action;
    } else {
        n = toResource(controllerPath);
        path = [n, action].join(pathSeparator);
    }
    return path;
}

export function toResource(path: string) {
    return path.replace("~/", basePath);
}

export function toAppResource(module: string, path: string) {
    return toResource(
        ["~/AppResource", module, path].join(pathSeparator)
    );
}

export function stripBasePath(path: string) {
    return path.replace(basePath, "");
}

export function getUrlWithBase(action: string) {
    return basePath + action;
}
