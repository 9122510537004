///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Entities.User/BulkAdminRoles"/>
import * as ko from "knockout";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as notification from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import * as sync from "Core/Medius.Core.Web/Scripts/Medius/core/sync";
import { isNotNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import * as _ from "underscore";

class BulkAdminEditor {
    EntitiesIds: any;
    RoleCompanyAdd: ko.ObservableArray<any>;
    RoleCompanyRemove: ko.ObservableArray<any>;
    selectedCompany: ko.Observable<any>;
    selectedRole: ko.Observable<any>;
    IsModified: ko.Observable<boolean>;
    selectionIsDone: ko.Computed<any>;
    isCompanyNotSelected: ko.Computed<boolean>;
    constructor(entityIds:any) {
        this.EntitiesIds = entityIds;
        this.RoleCompanyAdd = ko.observableArray([]);
        this.RoleCompanyRemove = ko.observableArray([]);
        this.selectedCompany = ko.observable(null);
        this.selectedRole = ko.observable(null);
        this.IsModified = ko.observable(false);
    
        this.selectionIsDone = ko.computed(() => {
            return isNotNullOrUndefined(this.selectedCompany()) && isNotNullOrUndefined(this.selectedRole());
        });
    
        this.isCompanyNotSelected = ko.computed(() => {
            return !isNotNullOrUndefined(this.selectedCompany());
        });
    }

    removeItemFromCollection(collection:any, companyId:any, roleId:any) {
        return _.reject(collection(), (item) => {
            return item.Company.Id() === companyId && item.Role.Id() === roleId;
        });
    }

    removeFromAddCollection(companyId:any, roleId:any) {
        this.RoleCompanyAdd(this.removeItemFromCollection(this.RoleCompanyAdd, companyId, roleId));
    }

    removeFromRemoveCollection(companyId:any, roleId:any) {
        this.RoleCompanyRemove(this.removeItemFromCollection(this.RoleCompanyRemove, companyId, roleId));
    }

    getEntryFromCollection(collection:any) {
        if (_.isUndefined(this.selectedRole()) || _.isUndefined(this.selectedCompany())) {
            return false;
        }

        const existingOne = _.find(collection(), (item) => {
            return item.Company.Id === this.selectedCompany().Id && item.Role.Id === this.selectedRole().Id;
        });

        if (!_.isUndefined(existingOne)) {
            notification.info(getLabelTranslation("#Core/roleCompanyIsAlreadyInCollection"));
        }

        return existingOne;
    }

    removeSelectedFromCollection(collection:any) {
        const clearedCollection = _.reject(collection(), (item) => {
                return item.Company.Id === this.selectedCompany().Id && item.Role.Id === this.selectedRole().Id;
            });
        return clearedCollection;
    }

    addEntry() {
        const existingOne = this.getEntryFromCollection(this.RoleCompanyAdd);
        if (!existingOne && this.selectionIsDone()) {
            const clearRemoveCollection = this.removeSelectedFromCollection(this.RoleCompanyRemove);
            this.RoleCompanyRemove(clearRemoveCollection);

            const newRoleCompany = sync.getNew("Medius.Core.Entities.RoleCompany");
            newRoleCompany.Role = this.selectedRole();
            newRoleCompany.Company = this.selectedCompany();
            this.RoleCompanyAdd.push(newRoleCompany);
        }
        if (!this.selectionIsDone()) {
            notification.info(getLabelTranslation("#Core/companyAndRoleHaveToBeSelected"));
        }
    }

    removeEntry() {
        const existingOne = this.getEntryFromCollection(this.RoleCompanyRemove);
        if (!existingOne && this.selectionIsDone()) {
            const clearAddCollection = this.removeSelectedFromCollection(this.RoleCompanyAdd);
            this.RoleCompanyAdd(clearAddCollection);

            this.RoleCompanyRemove.push({
                Company: this.selectedCompany(),
                Role: this.selectedRole()
            });
        }
        if (!this.selectionIsDone()) {
            notification.info(getLabelTranslation("#Core/companyAndRoleHaveToBeSelected"));
        }
    }

    validate() {
        return true;
    }

    save(modifications:any[]) {
        if (this.RoleCompanyAdd().length > 0) {
            modifications.push({
                Name: "#Core/addRoleCompanyConnectionModification",
                Key: 'AddRoleCompanyConnection',
                Value: _.map(this.RoleCompanyAdd(), (rc) => {
                    return {
                        CompanyId: rc.Company.Id(),
                        RoleId: rc.Role.Id()
                    };
                })
            });
        }
        if (this.RoleCompanyRemove().length > 0) {
            modifications.push({
                Name: "#Core/deleteRoleCompanyConnectionModification",
                Key: 'DeleteRoleCompanyConnection',
                Value: _.map(this.RoleCompanyRemove(), (rc) => {
                    return {
                        CompanyId: rc.Company.Id(),
                        RoleId: rc.Role.Id()
                    };
                })
            });
        }
    }

    dispose() {
    }
}

export = function(entityIds:any) {
    return new BulkAdminEditor(entityIds);
};