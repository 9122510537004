/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/lib/utils/DefaultCompanyDataProvider"/>

import { CompanyDataProvider } from "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/company/dataProvider";
import * as _ from "underscore";
import * as rpc from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rpc";
import * as backendErrorHandler from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";

export class Provider extends CompanyDataProvider {
    availableCompaniesIds: any = null;

    filterByAvailableCompanies(data: any) {
        return this.filter(data, (company: any) => {
            return _.contains(this.availableCompaniesIds, company.Id);
        });
    }

    getResults(userId: number) {
        return rpc.request('UserService', 'GetAvailableDefaultCompaniesIds', {
            UserId: userId
        }).done((data) => {
            this.availableCompaniesIds = data;
        }).fail((data) => {
            backendErrorHandler.handleAnyError(data);
        });
    }

    search(searchText: string, maxResults: number, options: any) {
        const resultHandler = super.search(searchText, maxResults, options)
            .pipe((data) => {
                return this.filterByAvailableCompanies(data);
            });

        return resultHandler;
    }
}