/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/inbox/OpenInFullscreenButtonTemplate"/>
import * as React from "react";
import { Button, IconArrowsExpandFullRegular } from "@medius/ui-controls";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization"; 

interface OpenInFullscreenButtonTemplateProps {
    openInFullscreenAction: () => void
}

export function OpenInFullscreenButtonTemplate({openInFullscreenAction}:OpenInFullscreenButtonTemplateProps) {
    return (
        <Button
        data-testid="btn-open-task-in-fullscreen"
        label={getLabelTranslation("#Core/openInFullscreen")}
        hideLabel
        icon={<IconArrowsExpandFullRegular/>}
        height="small"
        variant="tertiary"
        onClick={() => openInFullscreenAction()}/>
    );
}