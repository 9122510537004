/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/lib/serialization"/>

import * as ko from "knockout";
import * as dateUtils from "Core/Medius.Core.Web/Scripts/Medius/lib/utils/date";
import {fromJS as internalFromJS} from "Core/Medius.Core.Web/Scripts/Medius/lib/knockoutMapping";
import * as _ from "underscore";


const defaults = {
    stripNulls: true
};
const dateRegex = /\/Date\([-+0-9]+\)\//;

function visitDate(value: any) {
    if (!value.isLocal) {
        value = dateUtils.toUserLocal(value);
    }
    return value;
}

function visitObject(value: any, options: any) {
    if (options.stripNulls && _.isNull(value)) {
        // strips nulls
        return undefined;
    }

    _(value).each(function (subvalue, subkey) {
        value[subkey] = process(subvalue, options);
    });
    return value;
}

function process(value: any, options: any) {
    if (typeof value === "object") {
        if (value instanceof Date) {
            return visitDate(value);
        }
        return visitObject(value, options);
    }
    return value;
}

 function tryReviveDate (s: any) {
    /*jshint evil:true */
    if (dateRegex.test(s)) {
        return eval('new ' + s.replace(/\//g, '').replace(/(\+|\-)[0-9]+\)/g, ')'));
    }
    return s;
}

function reviveObject (o: any) {
    for (const k in o) {
        o[k] = revive(o[k]);
    }
    return o;
}

export function toJSON(viewModel: any, options?: any) {
    options = options || defaults;
    const serialize = ko.toJS(viewModel);
    const stripped = process(serialize, options);
    return JSON.stringify(stripped);
}

export function revive(o: any) {
    switch (typeof o) {
        case 'string':
            return tryReviveDate(o);
        case 'object':
            return reviveObject(o);
        default:
            return o;
    }
}

export const toJS = ko.toJS;
export const fromJS = internalFromJS;
export const fromJSON = function(jsonString: any) {
    const parsed = ko.utils.parseJson(jsonString);
    arguments[0] = parsed;
    return fromJS.apply(this, arguments);
};