/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/withProperties"/>
import { utils, applyBindingsToDescendants } from "knockout";
import { registerBinding } from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";

const binding: ko.BindingHandler = {
    init: function (element:any, valueAccessor:any, allBindingsAccessor:any, viewModel:any, bindingContext:any) {
        // Make a modified binding context, with a extra properties, and apply it to descendant elements
        const newProperties = valueAccessor(),
        childBindingContext = bindingContext.createChildContext(viewModel);
        utils.extend(childBindingContext, newProperties);
        applyBindingsToDescendants(childBindingContext, element);
        // Also tell KO *not* to bind the descendants itself, otherwise they will be bound twice
        return { controlsDescendantBindings: true };
    }
};

export function register() {
    registerBinding("withProperties", binding);
}