///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/editors/email/bindingContinuation"/>
import * as $ from "jquery";
import originalContinuation = require("Core/Medius.Core.Web/Scripts/Medius/components/editors/bindingContinuation");

export = function(model:any, params:any, element:any, ...args:any[]) {
    args = [model, params, element, ...args];
    
    const $input = $(element).find("input").get(0);
    model.setInputElement($input);
    originalContinuation.apply(this, args);
};