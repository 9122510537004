///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/role/dataProvider"/>
import { Provider as base } from "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/entity/dataProvider";
import * as backendErrorHandler from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import * as rpc from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rpc";

export class Provider extends base {
    constructor() {
        super();
    }

    search(phrase:string, limit:number, options:any) {
        const params = {
            phrase: phrase,
            detachMode: 'Autocompleter',
            contextCompanyId: this.getId(options.company),
            limit: limit,
            onlyActive: options.onlyActive,
            withPersonal: options.withPersonal,
            onlyPersonal: options.onlyPersonal,
            onlyDistributionAllowed: options.onlyDistributionAllowed
        };

        const result = rpc.request("RoleSearchService", "SearchRoleByPhrase", params)
            .fail(function (data) {
            backendErrorHandler.handleAnyError(data);
        });

        return result;
    }
}

export function create() {
    return new Provider();
}