///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Schedulers.ImportJobContext/Editor"/>
import * as _ from "underscore";
import Mappings = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/mapping/collection/instance");

function Editor(viewModel: any) {
    viewModel.Template = 'Core:templates/Administration/ImportJobContext/EditorTemplate.html';
}

Mappings.register("Medius.Core.Schedulers.ImportJobContext", _.constant(true), Editor);

export = null;