/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/lockManager"/>
import {post} from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rest";
import {getLockManagerRepeatTime, getLockManagerStartingAquiresNumber} from "Core/Medius.Core.Web/Scripts/appConfig";
import * as sessionExtender from "Core/Medius.Core.Web/Scripts/Medius/session/extender";

let lockManager: LockManager = null;
let lockManagerInterval: any = null;

const repeatTimeInMiliseconds = getLockManagerRepeatTime();
const startingNumberOfLockAcquires = getLockManagerStartingAquiresNumber();

export function initialize() {
    lockManager = new LockManager();
    lockManagerInterval = setInterval(lockInterval, repeatTimeInMiliseconds);
}

export function destroy() {
    clearInterval(lockManagerInterval);
    lockManager = null;
}

export function setLockIntervalForDocument(documentId: number) {
    lockManager.SetLockInterval(documentId);
}

export function releaseLockIntervalForDocument() {
    lockManager.ReleaseLockInterval();
}

function logLockManagerMessage(message: string) {
// tslint:disable-next-line: no-console
    console.log(`%c Lock manager logging: ${message}`, "color: green");
}

function lockInterval() {
    logLockManagerMessage("Lock interval loop");
    lockManager.ExecuteLockAction();
}

class LockManager {
    private isActive = false;
    private ticksLeft: number;
    private id: number;
    private extender: sessionExtender.SessionExtender;

    constructor() {
        this.extender = sessionExtender.create();
    }

    public ExecuteLockAction() {
        if (!isInDocumentView() || !this.isActive) {
            return;
        }
        post("documentLock", `${this.id}/acquire`).done(this.keepSessionAlive.bind(this));
        logLockManagerMessage(`lock action for document id: ${this.id}, ticks left: ${this.ticksLeft}`);
        this.ticksLeft = this.ticksLeft - 1;
        if (this.ticksLeft <= 0) {
            this.isActive = false;
        }
    }

    public SetLockInterval(id: number) {
        logLockManagerMessage(`new lock for document requested, documentId: ${id}`);
        this.ticksLeft = startingNumberOfLockAcquires;
        this.id = id;
        this.isActive = true;
    }

    public ReleaseLockInterval() {
        logLockManagerMessage(`release lock for document requested, currently locked documentId: ${this.id}`);
        this.isActive = false;
        this.id = null;
        this.ticksLeft = 0;
    }

    private keepSessionAlive() {
        this.extender.extendSession();
    }
}

function isInDocumentView() {
    if (window.location.hash.indexOf("#DocumentCreate") !== -1) {
        return false;
    }

    if (window.location.hash.indexOf("#Reports") !== -1) {
        return false;
    }

    if (window.location.hash.indexOf("#Administration") !== -1) {
        return false;
    }

    if (window.location.hash.indexOf("#AdvancedSearch") !== -1) {
        return false;
    }

    if (window.location.hash.indexOf("#Dashboard") !== -1) {
        return false;
    }

    if (window.location.hash.indexOf("#configuration") !== -1) {
        return false;
    }

    if (window.location.hash.indexOf("#Home") !== -1) {
        return false;
    }
    return true;
}
