/// <amd-module name="Core/Medius.Core.Web/Scripts/components/procurement/hooks/useSaveRequisition"/>
import { Address, Comment, NoteToSupplier, OurReferenceRole } from "Core/Medius.Core.Web/Scripts/components/procurement/cart/cart";
import { cart, selectCartLines } from "Core/Medius.Core.Web/Scripts/components/procurement/cart/cartSlice";
import { useAppSelector } from "Core/Medius.Core.Web/Scripts/shared/store/hooks";
import { mapToCartLineDto, RequisitionRequestLineDto } from "./cartTypesForRequisitionRequest";
import * as rest from "Core/Medius.Core.Web/Scripts/Medius/core/fetch/rest";

type SaveDto = {
    readonly invoiceAddress: Address;
    readonly deliveryAddress: Address;
    readonly noteToSupplier: NoteToSupplier;
    readonly deliveryDate: string;
    readonly shippingMarks: string;
    readonly orderTypeId: number;
    readonly buyerRoleId: number;
    readonly ourReferenceRole: OurReferenceRole;
    readonly goodsReceiptByRoleId: number;
    readonly comments : Comment[];
    readonly cartLines: RequisitionRequestLineDto[]
};

const savePurchaseRequisition = (id: string, dto: SaveDto) => rest.post(`Backend/Rest/pv2/edit/save/${id}`, dto);

const useSaveRequisition = (id: string) => {
    const cartState = useAppSelector(cart);
    const cartLines = useAppSelector(selectCartLines);

    const save = async () => {
        const details = {
            ...cartState.details,
            cartLines: cartLines.map(cartLine => mapToCartLineDto(cartLine)),
            comments: cartState.details.comments?.filter(x => !(x.id > 0))};
        await savePurchaseRequisition(id, details);
    };

    return [save] as const;
};

export { useSaveRequisition };