/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/lib/utils/userEditorHelpers"/>
import { ajax } from "Core/Medius.Core.Web/Scripts/Medius/core/rpc";
import { Deferred } from "jquery";
import { handleAnyError } from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";

let availableFormats: any[] = [];
let availableLanguages: any[] = [];
let availableTimeZones: any[] = [];


export function loadAvailableFormats() {
    const dfd = Deferred();

    if (availableFormats.length > 0) {
        dfd.resolve(availableFormats);
    }
    else {
        ajax('UserService', 'GetAvailableFormats')
            .pipe(function (formats: any[]) {
                const temp = formats.map(function (format: any) {
                    return { Value: format.Name, Name: format.DisplayName };
                });
                return temp;
            }).done(function (result: any) {
                availableFormats = result;
                dfd.resolve(result);
            });
    }

    return dfd.promise();
}

export function loadAvailableLanguages() {
    const dfd = Deferred();

    if (availableLanguages.length > 0) {
        dfd.resolve(availableLanguages);
    }
    else {
        ajax('UserService', 'GetAvailableLanguages').pipe(function (cultures: any) {
            const temp = cultures.map(function (culture: any) {
                return { Value: culture.Name, Name: culture.DisplayName };
            });
            return temp;
        }).done(function (result: any) {
            availableLanguages = result;
            dfd.resolve(result);
        }).fail(function (error: any) {
            handleAnyError(error);
        });
    }

    return dfd.promise();
}

export function loadAvailableTimeZones() {
    const dfd = Deferred();

    if (availableTimeZones.length > 0) {
        dfd.resolve(availableTimeZones);
    }
    else {
        ajax('UserService', 'GetAvailableTimeZones').pipe(function (timeZones: any) {
            const temp = timeZones.map(function (timeZone: any) {
                return { Value: timeZone.Id, Name: timeZone.DisplayName };
            });

            return temp;
        }).done(function (result: any) {
            availableTimeZones = result;
            dfd.resolve(result);
        });
    }

    return dfd.promise();
}