/// <amd-module name="Core/Medius.Core.Web/Scripts/components/links/links"/>
import * as ko from "knockout";
import { LinksWithSections } from 'Core/Medius.Core.Web/Scripts/components/links/linksWithSections';
import { DocumentViewModel, getDocumentType, getDocumentViewId } from 'Core/Medius.Core.Web/Scripts/components/links/linkMetadataProvider';

export default class Links {
    public linksCount: ko.Observable<number>;
    public linkedEntity = ko.observable(null);
    public linksWithSections: any;
    public entityType: string;
    public entityViewId: string;

    constructor(documentViewModel: DocumentViewModel) {
        this.linksCount = ko.observable(0);
        this.entityType = getDocumentType(documentViewModel, documentViewModel.$type());
        this.entityViewId = getDocumentViewId(documentViewModel, documentViewModel.$type());
        this.linksWithSections = ko.pureComputed(() => {
            return ({
                functionComponent: LinksWithSections,
                props: { 
                    entityViewId: this.entityViewId,
                    entityType: this.entityType,
                    onLinksCountChanged:  (value: number) => this.linksCount(value)
                }
            });
        });
    }
}