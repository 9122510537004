///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/workflow/studio/workflowDataProvider"/>
import * as rpc from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rpc";
import * as types from "Core/Medius.Core.Web/Scripts/Medius/core/type";
import * as _ from "underscore";
import { isNullOrUndefined, isNotNullOrUndefined, isEmptyString } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import * as $ from "jquery";
import * as defaultDataProvider from "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/default/dataProvider";

class WorkflowDataProvider extends defaultDataProvider.DataProvider{
    
    DataObjectTypesKeys: any = [];
    results: any = [];
    customFilter: any;
    contextApplicationName: any;
    contextApplicationVersion: any;

    constructor(contextApplicationName: any, contextApplicationVersion: any, customFilter: any) {
        super();
        this.customFilter = customFilter;
        this.contextApplicationName = contextApplicationName;
        this.contextApplicationVersion = contextApplicationVersion;

        this.initializeAvailableDataObjects().then(_.bind(this.init, this));
    }

    init() {
        this.results = _.reject(this.DataObjectTypesKeys, (key) => {
            return isNullOrUndefined(key) || key === "$type" ||
                key.indexOf("System.") === 0 || (isNotNullOrUndefined(this.customFilter) && !this.customFilter(types.cache[key]));
        });
    }

    initializeAvailableDataObjects() {
        return rpc.request("DataObjectManager", "GetDataObjectsNamesForApplication", {
            applicationName: this.contextApplicationName,
            applicationVersion: this.contextApplicationVersion
        }).done((data) => {
            this.DataObjectTypesKeys = data;
        });
    }

    search(phrase: string): JQueryDeferred<unknown> {
        let result; 
        const regexSet: any[] = [];

        let keywords = _.isString(phrase) ? phrase.replace("*", "(.*)").split(" ") : [];
        keywords = _.filter(keywords, (keyword) => {
            return !isEmptyString(keyword);
        });

        _.each(keywords, (keyword) => {
            regexSet.push(new RegExp(`${keyword.toLowerCase()}`));
        });

        if (regexSet.length === 0) {
            result = this.results;
        } else {
            result = _.filter(this.results, (item) => {
                return _.some(regexSet, (regex) => {
                    return regex.test(item.toLowerCase());
                });
            });
        }

        return $.Deferred().resolve(result);
    }
}

export function create(contextApplicationName: any, contextApplicationVersion: any, customFilter: any) {
    return new WorkflowDataProvider(contextApplicationName, contextApplicationVersion, customFilter);
}

export const derive = WorkflowDataProvider;