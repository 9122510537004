///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/components/copyRequisition/copyPurchaseRequisitionButton" />
import { AsyncButton } from "@medius/ui-controls";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as React from "react";
import { get } from "Core/Medius.Core.Web/Scripts/Medius/core/fetch/rest";
import { ValidationResultsModal } from "Core/Medius.Core.Web/Scripts/Medius/components/components/copyRequisition/validationResult/validationResultsModal";
import { Company } from "Core/Medius.Core.Web/Scripts/components/procurement/currentCompany/configuration";
import { CopyRequisitionResultDto } from "Core/Medius.Core.Web/Scripts/Medius/components/components/copyRequisition/DTOs/copyRequisitionValidationResultDto";
import { useCopyRequisition } from "Core/Medius.Core.Web/Scripts/Medius/components/components/copyRequisition/Hooks/useCopyRequisition";

interface CopyRequisitionButtonProps {
    documentId: number,
    documentIdentifier: string,
    company: Company
}

export const CopyRequisitionButton = ({ documentId, documentIdentifier, company }: CopyRequisitionButtonProps) => {

    const useCopyRequisitionData = useCopyRequisition(company, documentId, documentIdentifier);
    const { modalVisible, resolveValidationResults, setModalVisible, lineValidationResults, copyRequisitionToBasket, countOfCartLines, headerError } = useCopyRequisitionData;

    const validate = async () => {
        const validationResult = await get<CopyRequisitionResultDto>(`pv2/requisition/${documentId}/checkIfCanBeCopied`);

        resolveValidationResults(validationResult);
    };

    return (
        <>
            <AsyncButton label={translate("#Procurement/copyToCart")} data-testid="copy-requisition-button" onClick={() => validate()} />
            <ValidationResultsModal
                isModalVisible={modalVisible}
                setIsModalVisible={setModalVisible}
                lineValidationResults={lineValidationResults}
                copyRequisitionToBasket={copyRequisitionToBasket}
                countOfCartLines={countOfCartLines}
                headerError={headerError}
            />
        </>
    );
};