///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/editors/timespan/model"/>
import * as textComponent from "Core/Medius.Core.Web/Scripts/Medius/components/editors/text/model";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as _ from "underscore";

function validationMethod(value:any) {
    const reg = new RegExp(/^(\d+\.)?(0\d|1\d|2[0-3])(:[0-5]\d){2,}$/);

    if (reg.test(value)) {
        return true;
    }
    return {
        valid: false,
        validationDetails: globalization.getLabelTranslation("#Core/incorrectFormatOfTimeSpan")
    };
}

class TimeSpan {
    constructor(binding:any, viewmodel:any, context:any) {
        const defaults = {
            validator: validationMethod,
            options: {
                placeholder: 'd.HH:mm:ss'
            }
        };
        
        binding = _({}).extend(defaults, binding);
        _(this).extend(textComponent.create(binding, viewmodel, context));
    }
}

export function create(binding:any, viewmodel:any, context:any) {
    return new TimeSpan(binding, viewmodel, context);
}