/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Entities.Role/authorizationDataSource"/>
import DataSource = require("Core/Medius.Core.Web/Scripts/Medius/components/grid/dataSource/default");
import { getLabelTranslation, getPropertyTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as _ from "underscore";
import { isEmptyString } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import { ObservableArray, observable, unwrap } from "knockout";
import * as $ from "jquery";

class AuthorizationTabDataSource extends DataSource {
    relatedEntities: ObservableArray<any>;
    authorizationGroups: any;
    constructor(relatedEntities: any, authorizationGroups: any) {
        super();
        this.relatedEntities = relatedEntities;
        this.authorizationGroups = authorizationGroups;
    }

    loadColumns() {
        const columns = [
            {
                Sortable: false,
                Searchable: true,
                Resizable: true,
                Name: getLabelTranslation("#Core/dataObject"),
                ValueSource: "DataObject.Name"
            },
            {
                Sortable: false,
                Searchable: false,
                Resizable: false,
                Stretch: false,
                Width: 170,
                Name: getLabelTranslation("#Core/accessControlGroup"),
                ValueSource: ""
            },
            {
                Sortable: false,
                Searchable: false,
                Resizable: false,
                Stretch: false,
                Width: 100,
                Name: getLabelTranslation("#Core/configurable"),
                ValueSource: ""
            },
            {
                Sortable: false,
                Searchable: false,
                Resizable: false,
                Stretch: false,
                Width: 70,
                Name: "",
                ValueSource: ""
            }
        ];

        return $.Deferred().resolve(columns);
    }

    filterRowsByDataObjectName(dataQuery: any) {
        let resultRows = this.relatedEntities() || [],
            nameTranslation;

        if (isEmptyString(dataQuery.keywords["DataObjec t.Name"])) {
            return resultRows;
        }

        _.each(dataQuery.keywords, (keyword) => {
            keyword = keyword.toLowerCase().trim();
            resultRows = _.filter(resultRows, (row) => {
                nameTranslation = getPropertyTranslation(row.DataObject().Name());
                return nameTranslation.toLowerCase().indexOf(keyword) > -1;
            });
        });

        return resultRows;
    }

    load(dataQuery: any) {
        const deferred = $.Deferred();
        let allRows = this.relatedEntities() || [],
            index, i,
            rows = [];

        if (allRows.length === 0) {
            return $.Deferred().resolve([]);
        }

        // search
        allRows = this.filterRowsByDataObjectName(dataQuery);

        // paging
        index = (dataQuery.page - 1) * dataQuery.pageSize;
        for (i = 0; i < dataQuery.pageSize; i++) {
            if (allRows[index]) {
                rows.push(allRows[index]);
            }
            index++;
        }

        rows = _.map(rows, (row) => {
            return this.createAuthorizationPairViewModel(row);
        });

        deferred.resolve(rows);

        return deferred;
    }

    getTotalRows(dataQuery: any) {
        const rows = this.filterRowsByDataObjectName(dataQuery);
        return $.Deferred<number>().resolve(rows.length);
    }

    createAuthorizationPairViewModel(pairVM: any) {
        const row: Record<string, any> = {};

        const accessControlGroup = unwrap(pairVM.AccessControlGroup);
        const accessControlGroupName = (accessControlGroup) ? unwrap(accessControlGroup.Name) : undefined;

        row.RelatedEntity = pairVM;
        row.AccessControlGroupName = observable(accessControlGroupName);

        row.subscribedName = row.AccessControlGroupName.subscribe((newValue: any) => {
            row.RelatedEntity.AccessControlGroup = _.find(this.authorizationGroups(), (item) => {
                return item.Name === newValue;
            });
        });

        row.dispose = function () {
            this.subscribedName.dispose();
        };

        return row;
    }

    dispose() {
        this.relatedEntities = null;
        this.authorizationGroups = null;
    }
}

export function create(relatedEntities: any, authorizationGroups: any) {
    return new AuthorizationTabDataSource(relatedEntities, authorizationGroups);
}