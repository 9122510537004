/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/document/uiDocumentManager"/>
import * as rpc from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rpc";

class UiDocumentManagerRegistry {
    private registry: { [documentType: string]: UiDocumentManagerInterface} = {};

    public register(uiDocumentManager: UiDocumentManagerInterface, documentType: string) {
        this.registry[documentType] = uiDocumentManager;
    }

    public get(documentType: string) {
        return this.registry[documentType] ?? new UiDocumentManager();
    }
}

export const uiDocumentManagerRegistry = new UiDocumentManagerRegistry();

export interface UiDocumentManagerInterface {
    registerDocument(document: any): JQueryPromise<any>;
}

class UiDocumentManager implements UiDocumentManagerInterface {
    public registerDocument(document: any) {
        return genericRegisterDocument(document.toDto());
    }
}

export function genericRegisterDocument(dto: any) 
{
    return rpc.request("UiDocumentManager", "RegisterDocumentWithTransaction", {document: dto});
}
