///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/editors/bindings"/>
import * as componentUtils from "Core/Medius.Core.Web/Scripts/Medius/components/utils";
import * as editorUtils from "Core/Medius.Core.Web/Scripts/Medius/components/editors/utils";

import "Core/Medius.Core.Web/Scripts/Medius/components/editors/bindings/triggerValidation";
import "Core/Medius.Core.Web/Scripts/Medius/components/editors/bindings/clickOutside";
import "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/bindings/positionResults";

const configuration = {
    "amount": {
        factory: "Core/Medius.Core.Web/Scripts/Medius/components/editors/amount/model"
    },
    "unitprice": {},
    "quantity": {},
    "percentage": {},
    "bool": {},
    "timespan": {},
    "bool-checkbox": {
        module: "bool",
        defaults: {
            template: "editors-bool-checkbox"
        }
    },
    "bool-toggle": {
        module: "bool",
        defaults: {
            template: "editors-bool-toggle"
        }
    },
    "bool-nullable": {
        module: "bool",
        defaults: {
            nullable: true
        }
    },
    "date": {
        continuation: "Core/Medius.Core.Web/Scripts/Medius/components/editors/date/bindingContinuation"
    },
    "number": {},
    "text": {},
    "text-translation": {
        module: "textTranslation"
    },
    "email": {
        continuation: "Core/Medius.Core.Web/Scripts/Medius/components/editors/email/bindingContinuation"
    },
    "autocompleter": {
        factory: "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/default/factory"
    },
    "autocompleter-entity": {
        factory: "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/entity/factory"
    },
    "autocompleter-dataObjectType": {
        module: "autocompleter/dataObjectType"
    },
    "autocompleter-company": {
        module: "autocompleter/company/factory"
    },
    "autocompleter-role": {
        module: "autocompleter/role"
    },
    "autocompleter-redistributionItem": {
        module: "autocompleter/redistributionItem"
    },
    "autocompleter-approvalGroup": {
        module: "autocompleter/approvalGroup"
    },
    "autocompleter-customfieldvalue": {
        factory: "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/customfields/listValue/factory"
    }
};

componentUtils.registerBindings(configuration, editorUtils);