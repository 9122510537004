///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/preloader"/>
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as $ from "jquery";

/* @example
    <div data-bind="preloader: [observableBool]">...</div>*/
const preloader = {
    init: function (element:any, bindingAccessor:any) {
        const value = koUtils.unpack(bindingAccessor()),
            el = $(element);

        el.find(".preloader").remove();

        if (value) {
            const html = '<div class="preloader">' +
                '<div class="preloader-inner">' +
                '<div class="preloader-overlay"></div>' +
                '<div class="preloader-image"></div>' +
                '</div></div>';
            el.append(html);
        }
    },
    update: function (element:any, bindingAccessor:any) {
        preloader.init(element, bindingAccessor);
    }
};

export function register() {
    koUtils.registerBinding("preloader", preloader);
}