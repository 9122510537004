/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/kendo/bindings/kendoDropDownList" />

import * as $ from "jquery";
import * as ko from "knockout";

import { KendoUtils } from "Core/Medius.Core.Web/Scripts/Medius/kendo/kendoUtils";
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";

interface IOptions {
    data: any | kendo.data.DataSource;
    selected?: ko.Observable<any>;
    value?: ko.Observable<any>;
    dataTextField?: string;
    dataValueField?: string;
    filter?: string;
    height?: number;
}

class KendoDropDownList {
    private static readonly widgetName = "kendoDropDownList";

    public static registerKnockoutBindings(): void {
        koUtils.registerBinding(this.widgetName, {
            init(element: HTMLElement) {
                const $element = $(element);
                koUtils.addDisposeCallback(element, () => {
                    KendoUtils.destroyComponent($element, KendoDropDownList.widgetName);
                });
            },
            update(element: HTMLElement, valueAccessor: any) {
                const $element = $(element);
                const options = ko.unwrap(valueAccessor()) as IOptions;

                const dataSource = ko.unwrap(options.data);
                const dataValueField = options.dataValueField || "value";
                const kendoOptions: kendo.ui.DropDownListOptions = {
                    dataSource: dataSource,
                    dataTextField: options.dataTextField || "text",
                    dataValueField: dataValueField,
                    filter: options.filter || "none",
                    height: options.height || 400,
                    fixedGroupTemplate: null,
                    change: e => {
                        const selectedValue = e.sender.value();
                        let data: any;
                        if (dataSource.data !== undefined)
                            data = dataSource.data();
                        else
                            data = dataSource;

                        const selectedItem = KendoDropDownList.findItem(data, dataValueField, selectedValue);

                        if (options.selected)
                            options.selected(selectedItem);

                        if (options.value)
                            options.value(selectedValue);
                    }
                };

                const initialValue = KendoDropDownList.getInitialValue(options, dataValueField);
                KendoDropDownList.createKendoDropDownList($element, kendoOptions, initialValue);
            }
        });
    }

    private static findItem(data: any[], dataValueField: string, value: string): any {
        let result: any;
        data.some((item) => {
            if (item.items) 
                result = KendoDropDownList.findItem(item.items, dataValueField, value);
            else if (item[dataValueField] === value)
                result = item;
            return result;
        });
        return result;
    }

    private static getInitialValue(options: IOptions, valueField: string): any {
        const initialValue = ko.unwrap(options.value);
        if (initialValue != undefined)
            return initialValue;

        const initialSelected = ko.unwrap(options.selected);
        if (initialSelected != undefined)
            return initialSelected[valueField];

        return undefined;
    }

    private static createKendoDropDownList($element: JQuery, kendoOptions: kendo.ui.DropDownListOptions, initialValue: any) {
        KendoUtils.destroyComponent($element, KendoDropDownList.widgetName);
        const $list = KendoUtils.createComponent($element, KendoDropDownList.widgetName, kendoOptions);
        if (initialValue != undefined) {
            const valueField = kendoOptions.dataValueField;
            $list.data(KendoDropDownList.widgetName)
                .select((item: any) => item[valueField] === initialValue);
        }
    }
}

export function register() {
    KendoDropDownList.registerKnockoutBindings();
}
