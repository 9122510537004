///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/task/models/documentContext"/>

import * as type from "Core/Medius.Core.Web/Scripts/Medius/core/type";
import * as taskHandlingConfiguration from "Core/Medius.Core.Web/Scripts/Medius/apps/task/settings";
import * as _ from "underscore";

class DocumentContext{
    data: any;

    getType = () => {
        return this.data.documentTypeName;
    };

    getDocumentType = () => {
        return this.data.wrappedDocumentTypeName || this.data.documentTypeName;
    };

    isWrapping = () => {
        return this.data.isWrappingDocument;
    };

    getId = () => {
        return this.data.documentId;
    };

    getDocumentDto = () => {
        return this.data.documentDto;
    };

    constructor(data: any) {
        data = _({
            isWrappingDocument: false,
            documentId: 0,
            wrappedDocumentTypeName: null
        }).extend(data);
    
        if (!data.documentTypeName) {
            throw new Error("Document type name has not been specified");
        }
    
        data.documentTypeName = type.getTypeName(data.documentTypeName);

        this.data = data;
    }
}

export function extendViewModelContextFromDocument(vmContext: any, document: any) {
    const documentType = document.$type;
    let isWrappingDocument = false;
    let wrappedDocumentTypeName = null;

    if (type.implementsInterface(documentType, "Medius.Core.Entities.IWrappingDocument") && document.WrappedDocumentType) {
        isWrappingDocument = true;
        wrappedDocumentTypeName = type.getTypeName(document.WrappedDocumentType);
    }

    const documentContextData = new DocumentContext({
        documentTypeName: documentType,
        documentId: document.Id,
        documentDto: document,
        isWrappingDocument: isWrappingDocument,
        wrappedDocumentTypeName: wrappedDocumentTypeName
    });
    vmContext.set(taskHandlingConfiguration.topLevelDocument, documentContextData);
}

export function extendViewmodelContextFromCreateDocument(vmContext: any, documentType: any) {
    const documentContextData = new DocumentContext({
        documentTypeName: documentType
    });
    vmContext.set(taskHandlingConfiguration.topLevelDocument, documentContextData);
}