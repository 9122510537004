/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/document/events" />

export const initialized ="documentRegistration:initialized";
export const modelBound = "documentRegistration:model-bound";
export const saveSuccess = "documentRegistration:save-success";
export const reclassificationRequestSent = "documentRegistration:reclassification-sent";
export const saveError = "documentRegistration:save-error";
export const saveForbidden = "documentRegistration:save-forbidden";
export const cancel = "documentRegistration:cancel";
export const validationError = "documentRegistration:validation-error";
export const modelReset = "documentRegistration:model-unbound";
export const reclassifySelectorOpened = "documentRegistration:reclassify-opened";
export const documentCreateRequest = "documentRegistration:document-create-request";
export const documentReclassificationRequest = "documentRegistration:document-reclassification-request";
export const documentCopyCancel = "documentRegistration:document-copy-cancel";
export const modelLoadError = "documentRegistration:model-load-error";