///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/lexeme/filter" />
import * as _ from "underscore";

export function filter(lexemes: string[]) {
    return joinNestedProperties(lexemes);
}

export function joinNestedProperties(lexemes: string[]) {
    let resultId = 0;
    const result = _.isEmpty(lexemes) ? [] : [lexemes[0]];
    const lexemesLength = lexemes.length;
    
    for (let lexemeId = 1; lexemeId < lexemesLength; lexemeId++) {
        if (lexemes[lexemeId] === '.') {
            result[resultId] += '.';
            
            if (lexemeId + 1 < lexemesLength) {
                result[resultId] += lexemes[lexemeId + 1];
                lexemeId++;
            }
        }
        else {
            result.push(lexemes[lexemeId]);
            resultId++;
        }
    }
    
    return result;
}