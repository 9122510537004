/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/default/text/basicDto"/>
import { unpack } from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import { getTypeName } from "Core/Medius.Core.Web/Scripts/Medius/core/type";
import * as conditionBoundResolverFactory from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/conditionBound";

export const resolver = conditionBoundResolverFactory.create({
    priority: conditionBoundResolverFactory.priority.lowest,
    condition: function (data: any) {
        return data && typeof data === "object" && data.$type;
    },
    resolve: function (data: any) {
        return {
            text: getTypeName(unpack(data.$type))
        };
    }
});
