///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/grid/dataQuery/default"/>
import * as _ from "underscore";

export class DataQuery{
    keywords: any;
    page = 1;
    pageSize: any;
    sorting: any;
    limit: any;

    constructor(pageSize: any, keywords: any = {}, sorting: any = {}, limit?: any){
        this.keywords = keywords;
        this.pageSize = pageSize;
        this.sorting = sorting;
        this.limit = limit;
    }

    addFilter(propertyName: string, propertyValue: any) {
        const filter: any = {};
        filter[propertyName] = propertyValue;

        _.extend(this.keywords, filter);
    }

    removeFilter(propertyName:string) {
        this.keywords = _.omit(this.keywords, propertyName);
    }
}