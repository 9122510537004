/// <amd-module name="Core/Medius.Core.Web/Scripts/lib/validation/validators/notExistingValueValidator"/>
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";

export class NotExistingValueValidator {
    constructor(validationMessage: string) {
        this.validationMessage = validationMessage;
    }

    public validationMessage: string;

    public isValid(value: { isValueMissing: boolean }) {
        if (value && value.isValueMissing) {
            return false;
        }
        return true;
    }
}

export function valueExists(validationMessage?: string) {
    return new NotExistingValueValidator(validationMessage || translate("#Core/couldNotFindValue"));
}
