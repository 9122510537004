/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/dashboard/bindings/sortableGadgets"/>
import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import { addDisposeCallback, registerBinding } from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as $ from "jquery";

const sortableGadgets = {
    init(element: Node, bindingParams: () => any) {
        const dashboard = bindingParams();

        if (isNullOrUndefined(dashboard) || !dashboard.isOwned) {
            return;
        }

        $(element).sortable({
            connectWith: ".column",
            cursor: "move",
            stop: function () {
                dashboard.saveLayout();
            },
            handle: ".gadget-header"
        });

        addDisposeCallback(element, function () {
            $(element).sortable("destroy");
        });
    }
};

export function register() {
    registerBinding("sortableGadgets", sortableGadgets);
}