/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/AuthorizationGroups/Tabs/components/supplierLimitGrid"/>
import * as React from "react";
import { useCallback, useRef, useState } from "react";
import { Grid, GridCellProps, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { ButtonCell, LimitTypeCell, LimitValueCell, LockedSupplierAutocompleterCell } from "./gridCells";
import { GetEmptySupplierPermission, SupplierPermission } from "../../authorizationGroup";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { ValidationType } from "../../limit";
import { TooltipProps } from "./headerCells";
import { SvgIcon } from "@progress/kendo-react-common";
import { plusIcon } from "@progress/kendo-svg-icons";

type SupplierLimitGridProps = {
    readonly data: SupplierPermission[];
    readonly onChange: (newPermissions: SupplierPermission[]) => void;
};

const Supplier = "Supplier";
const DebitLimit = "DebitLimit";
const CreditLimit = "CreditLimit";

const SupplierDataQa = "supplier-limit-supplier";
const DebitLimitValueDataQa = "supplier-limit-debitLimit-value";
const CreditLimitValueDataQa = "supplier-limit-creditLimit-value";
const DebitLimitTypeDataQa = "supplier-limit-debitLimit-type";
const CreditLimitTypeDataQa = "supplier-limit-creditLimit-type";

type Property =
    "Supplier" | "DebitLimit" | "CreditLimit";

enum ActionType { Add, Edit, Delete }

type AddAction = { type: ActionType.Add };
type EditAction = {
    type: ActionType.Edit
    property: Property
    index: number
    newValue: any
};
type DeleteAction = { type: ActionType.Delete; index: number };
type DispatchAction = AddAction | EditAction | DeleteAction;

const toPerm = (dataItem: any) => dataItem as SupplierPermission;

const supplierLimitGridReducer = (state: SupplierPermission[], action: DispatchAction, onChange: (newPermissions: SupplierPermission[]) => void) => {
    switch (action.type) {
        case ActionType.Edit:
            onChange(state.map((x, idx) => idx === action.index ? { ...x, [action.property]: action.newValue } : x));
            break;
        case ActionType.Add:
            onChange(state.concat(GetEmptySupplierPermission() as SupplierPermission));
            break;
        case ActionType.Delete:
            onChange(state.filter((_, idx) => idx !== action.index));
            break;
    }
};

const SupplierLimitGrid = ({ data, onChange }: SupplierLimitGridProps) => {

    const refData = useRef(data);
    refData.current = data;

    const dispatch = (action: DispatchAction): void => {
        supplierLimitGridReducer(refData.current, action, onChange);
    };

    const edit = (property: Property, index: number) => (newValue: any) =>
        dispatch({ type: ActionType.Edit, property, index, newValue });

    const SupplierCell = useCallback(({ style, dataItem, dataIndex }: GridCellProps) =>
        LockedSupplierAutocompleterCell(toPerm(dataItem).Supplier, edit(Supplier, dataIndex), style, SupplierDataQa), []);
    const DebitLimitValueCell = useCallback(({ dataItem, dataIndex }: GridCellProps) =>
        LimitValueCell(toPerm(dataItem).DebitLimit, ValidationType.Positive, edit(DebitLimit, dataIndex), DebitLimitValueDataQa), []);
    const DebitLimitTypeCell = useCallback(({ dataItem, dataIndex }: GridCellProps) =>
        LimitTypeCell(toPerm(dataItem).DebitLimit, ValidationType.Positive, edit(DebitLimit, dataIndex), DebitLimitTypeDataQa), []);
    const CreditLimitValueCell = useCallback(({ dataItem, dataIndex }: GridCellProps) =>
        LimitValueCell(toPerm(dataItem).CreditLimit, ValidationType.Negative, edit(CreditLimit, dataIndex), CreditLimitValueDataQa), []);
    const CreditLimitTypeCell = useCallback(({ dataItem, dataIndex }: GridCellProps) =>
        LimitTypeCell(toPerm(dataItem).CreditLimit, ValidationType.Negative, edit(CreditLimit, dataIndex), CreditLimitTypeDataQa), []);
    const DeleteItemCell = useCallback(({ dataIndex }: GridCellProps) =>
        ButtonCell(() => dispatch({ type: ActionType.Delete, index: dataIndex }), "delete-supplier-limit"), []);

    const [skipRows, setSkipRows] = useState<number>(0);
    const handlePageChange = (event: any) => {
        setSkipRows(isNaN(event.page.skip) ? 0 : event.page.skip);
    };

    return (
        <div data-testid="supplier-limits-grid" className="gridContainer">
            <Grid
                style={{ height: '99%' }}
                rowHeight={25}
                data={refData.current.slice(skipRows, skipRows + 20)}
                className="gridContainer__grid"
                pageSize={20}
                total={refData.current.length}
                skip={skipRows}
                scrollable={'virtual'}
                onPageChange={handlePageChange}
            >
                <GridToolbar>
                    <button className="k-button" onClick={() => dispatch({ type: ActionType.Add })} data-testid="add-supplier-limit">
                        <SvgIcon icon={plusIcon}/>{translate("#Enterprise/LBL_ADD")}
                    </button>
                </GridToolbar>
                <GridColumn field={Supplier} title={translate("#Enterprise/supplier")} width={'200px'}
                    locked={true} cell={SupplierCell} {...TooltipProps} />
                <GridColumn title={translate("#Enterprise/DebitLimit")} {...TooltipProps}>
                    <GridColumn field={DebitLimit} title={translate("#Enterprise/LBL_LIMIT")} width={'200px'}
                        cell={DebitLimitValueCell} {...TooltipProps} />
                    <GridColumn field={DebitLimit} title={translate("#Enterprise/unlimited")} width={'80px'}
                        cell={DebitLimitTypeCell} {...TooltipProps} />
                </GridColumn>
                <GridColumn title={translate("#Enterprise/CreditLimit")} {...TooltipProps}>
                    <GridColumn field={CreditLimit} title={translate("#Enterprise/LBL_LIMIT")} width={'200px'}
                        cell={CreditLimitValueCell} {...TooltipProps} />
                    <GridColumn field={CreditLimit} title={translate("#Enterprise/unlimited")} width={'80px'}
                        cell={CreditLimitTypeCell} {...TooltipProps} />
                </GridColumn>
                <GridColumn cell={DeleteItemCell} width={'60px'} />
            </Grid>
        </div>
    );
};

export default SupplierLimitGrid;
