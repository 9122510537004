/// <amd-module name="Core/Medius.Core.Web/Scripts/shared/components/datePresenter"/>
import * as React from "react";
import { useInternationalization } from "@progress/kendo-react-intl";
import { DateFormatOptions } from "@progress/kendo-react-intl";

export const DatePresenter = (props: { date: Date; format?: string|DateFormatOptions }) => {
    const internationalization = useInternationalization();
    const { date, format } = props;

    return <>{internationalization.formatDate(date, format)}</>;
};