/// <amd-module name="Core/Medius.Core.Web/Scripts/components/pdfViewer/bindings/imageViewerContainer"/>
import * as ko from "knockout";

let cachedImageViewerIframe: HTMLIFrameElement = null;

const binding = {
    init: function (element: HTMLElement, valueAccessor: ko.Observable<ko.Computed<string>>) {
        if (!document.body.contains(element)) {
            // Fixing case when two async templates containing image viewer was were applying bindings 
            // and when last one was the one detached from the DOM (sometimes we're fetching DocumentImageTab twice
            // - for full screen tab and for image tab and then removing second one from the DOM after it's loaded)
            return;
        }

        /*
        IE 11 has known problems related to memory leaks when using iframes:
         - https://stackoverflow.com/questions/8407946/is-it-possible-to-use-iframes-in-ie-without-memory-leaks
         - https://connect.microsoft.com/IE/Feedback/Details/1742276 (Won't fix)

        This custom binding implements some strategies that reduce leaking on IE:
         - setting src to about:blank before disposing/switching to next image,
         - reusing the same iframe instead of deleting/adding it each time DOM for image tab is recreated,
         - cleaning after previous document using built in pdf.js cleanup methods.

        Following methods failed (and are not implemented):
         - frame.contentWindow.document.write('') before disposing,
         - frame.contentWindow.document.innerHTML = '' before disposing,
         - contentWindow.PDFViewerApplication = null; and for other globals,
         - removing iframe manually from the DOM.
        */

        const imageViewerIframe = cachedImageViewerIframe
            || (cachedImageViewerIframe = document.getElementById("image-viewer-iframe") as HTMLIFrameElement);

        const hiddenFrameContainer = document.getElementById("hidden-image-viewer-iframe-container");

        element.appendChild(imageViewerIframe);

        const url = valueAccessor();
        imageViewerIframe.src = url();

        let urlSub = url.subscribe(newUrl => {
            cleanAfterPreviousImage(imageViewerIframe);
            imageViewerIframe.src = newUrl;
        });

        ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
            cleanAfterPreviousImage(imageViewerIframe);
            hideImageViewerIframe();

            urlSub.dispose();
            urlSub = null;
        });

        function hideImageViewerIframe() {
            hiddenFrameContainer.appendChild(imageViewerIframe);
        }

        function cleanAfterPreviousImage(iframe: HTMLIFrameElement) {
            const contentWindow = iframe.contentWindow as any;

            if (contentWindow && contentWindow.PDFViewerApplication) {
                contentWindow.PDFViewerApplication.close(); /* returns promise but we don't wait for it */
                contentWindow.PDFViewerApplication._cleanup();
            } else {
                // TODO: Log this after frontend exception logging is implemented
                console.warn("Possible memory leak: PDF viewer image not cleaned properly");
            }
            iframe.src = "about:blank";
        }

        return { controlsDescendantBindings: true };
    },

    update(_element: HTMLElement, _valueAccessor: ko.Observable<ko.Computed<string>>) {
        return { controlsDescendantBindings: true };
    },
};

export function register() {
    
    ko.bindingHandlers["image-viewer-container"] = binding;
}
