/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/rpc"/>
import { toAction, getUrlWithBase } from "../lib/path";
import { ajax as ajaxRawCall } from "../lib/ajax";
import { isMigratedRpcEndpoint } from "Core/Medius.Core.Web/Scripts/Helpers/migrationApisHelper";

function createMediusFlowUrl(service: string, resource: string) {
  return `${toAction("Rpc", "~/Backend")}/${service}/${resource}`;
}
function createRemediumUrl(service: string, resource: string) {
  return `${toAction("remedium-api")}/${service}/${resource}`;
}

export function ajax(
  service: string,
  resource: any,
  options: JQueryAjaxSettings = {},
  signal?: AbortSignal
) {
  let uri = createMediusFlowUrl(service, resource);

  service = service.replace("lightApi/", "");
  const endpoint = `${service}/${resource}`;
  if (isMigratedRpcEndpoint(endpoint)) {
    uri = createRemediumUrl(service, resource);
  }

  options = { ...options, type: "POST" };
  return ajaxRawCall(uri, options, signal);
}

export function ping(action: string) {
  const url = getUrlWithBase(action);
  return ajaxRawCall(url, { type: "POST" });
}
