/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/AuthorizationGroups/Tabs/generalTab"/>
import { Input, Switch } from "@progress/kendo-react-inputs";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as React from "react";
import { Company, DocumentType, Optional, Currency } from "../authorizationGroup";
import { PromiseBasedCompanyAutocompleterContainer } from "./companyAutocompleter/promiseBasedCompanyAutocompleterContainer";
import { getCompanies } from "../service";
import { DocumentTypes } from "./documentTypes";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { CurrencyAutocompleter } from "./components/currencyAutocompleter";
import { Text } from "@medius/ui-controls";

export interface IGeneralTabProps {
    authGroupId: number;
    authGroupName: Optional<string>;
    company: Optional<Company> | null;
    isActive: boolean;
    companyDisabled: boolean;
    currencyDisabled: boolean;
    currency: Optional<Currency> | null;
    selectedDocumentTypes: DocumentType[];
    documentTypesList: DocumentType[];
    importedTimestamp: Date;
    externalSystemId: string;
    onNameChange: (name: string) => void;
    onCompanyChange: (company: Company) => void;
    onCurrencyChange: (currency: Currency) => void;
    onActiveChange: (isActive: boolean) => void;
    onDocumentTypeListChange: (documentTypes: DocumentType[]) => void;
}

type ActiveSwitchProps = {
    readonly value: boolean;
    readonly onChange: (newValue: boolean) => void;
};

export const ActiveSwitch = ({ value, onChange }: ActiveSwitchProps) => {
    return (<>
        <div>{translate("#Core/active")}</div>
        <Switch
            onLabel={translate("#Core/yes")}
            offLabel={translate("#Core/no")}
            checked={value}
            onChange={e => onChange(e.value)}
            data-testid='active-switch'
        />
    </>);
};

export function GeneralTab(props: IGeneralTabProps) {
    const { authGroupName, company, isActive, companyDisabled, currency, importedTimestamp, selectedDocumentTypes, documentTypesList, onNameChange, onCompanyChange, onCurrencyChange, onActiveChange, onDocumentTypeListChange,
        currencyDisabled, externalSystemId } = props;

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        onNameChange(e.target.value);

    const handleCompanyChange = (company: Company) =>
        onCompanyChange(company);

    const handleCurrencyChange = (currency: Currency) =>
        onCurrencyChange(currency);

    const handleDocumentTypeListChange = (documentTypes: DocumentType[]) => {
        onDocumentTypeListChange(documentTypes);
    };

    return (
    <div className="general-tab-wrapper">
        <h4 className="section-header">{translate('#Enterprise/authorizationGroup_configurationHeader')}</h4>
        <div className="authorizationGroups__configuration-property" data-testid='authorization-group-active-switch'>
            <ActiveSwitch
                value={isActive}
                onChange={onActiveChange} />
        </div>
        <div className="authorizationGroups__configuration-property__text required" data-testid='authorization-group-name'>
            <Input
                label={translate("#Enterprise/authorizationGroup")}
                value={authGroupName}
                required
                onChange={(e) => {
                    handleNameChange(e.nativeEvent);
                }}>
            </Input>
        </div>
        <div className="authorizationGroups__configuration-property__text required" data-testid='authorization-group-company'>
            <PromiseBasedCompanyAutocompleterContainer
                onCompanyChange={handleCompanyChange}
                selectedItem={company}
                disabled={companyDisabled}
                getCompaniesPromise={getCompanies}
                textFieldSelector="NameWithCompanyIdAndIdentifiers"
            />
        </div>
        <div className="authorizationGroups__configuration-property required" data-testid='authorization-group-currency-code'>
            <CurrencyAutocompleter className={"authorizationGroups__configuration-property__currency"} currency={currency} onChange={handleCurrencyChange} label={translate("#Core/currency")} disabled={currencyDisabled} />
        </div>
        <Tooltip anchorElement="target" position="top">
            <div className="authorizationGroups__configuration-property__text" data-testid='authorization-group-external-system-id' title={externalSystemId}>
                <Input
                    label={translate("#Core/externalSystemId")}
                    value={externalSystemId}
                    disabled>
                </Input>
            </div>
        </Tooltip>
        <div className="authorizationGroups__configuration-property__text" data-testid='authorization-group-imported-timestamp'>
            <Text variant="paragraph">
                {translate("#PurchaseToPay/reporting/columns/ImportedTimestamp")}
            </Text>
            <Text variant="paragraph">
                {importedTimestamp ? importedTimestamp.toLocaleString('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                    }) : " "}
            </Text>
        </div>
        <div className="authorizationGroups__configuration-property__text" data-testid='authorization-group-document-types'>
            <DocumentTypes
                selectedDocumentTypes={selectedDocumentTypes}
                documentTypesList={documentTypesList}
                onChange={handleDocumentTypeListChange} />
        </div>
    </div>);
}
