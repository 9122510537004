/// <amd-module name="Core/Medius.Core.Web/Scripts/components/procurement/freeTextForm"/>
import { FreeTextFormFieldType } from "Core/Medius.Core.Web/Scripts/components/procurement/cart/freeTextItem";

export type FreeTextForm = {
    id: number,
    identifier: string,
    name: string,
    isServiceBased: boolean,
    serviceBasedIsEditable: boolean,
    isActive: boolean,
    description: string,
    categoryId: number,
    categoryCode: string,
    categoryDescription: string,
    companyId: number,
    companyName: string,
    defaultSupplierId: number,
    defaultSupplierName: string,
    defaultContractId: number,
    defaultContractTitle: string,
    defaultUnitId: number,
    defaultUnitDescription: string,
    fields: FreeTextFormField[]
};

export const InitialFreeTextForm: FreeTextForm = {
    id: 0,
    identifier: "",
    name: "",
    isServiceBased: false,
    serviceBasedIsEditable: false,
    isActive: true,
    description: "",
    companyId: null,
    companyName: "",
    categoryId: null,
    categoryCode: "",
    categoryDescription: null,
    defaultSupplierId: null,
    defaultSupplierName: "",
    defaultContractId: null,
    defaultContractTitle: "",
    defaultUnitId: null,
    defaultUnitDescription: "",
    fields: []
};

export const DefaultFormBeforeFTFChanges: FreeTextForm = {
    id: 0,
    identifier: "DefaultForm",
    name: "Default",
    isServiceBased: false,
    serviceBasedIsEditable: false,
    isActive: true,
    description: "",
    companyId: null,
    companyName: "",
    categoryId: null,
    categoryCode: "",
    categoryDescription: null,
    defaultSupplierId: null,
    defaultSupplierName: "",
    defaultContractId: null,
    defaultContractTitle: "",
    defaultUnitId: null,
    defaultUnitDescription: "",
    fields: []
};

export type FreeTextFormField = {
    id: number;
    order: number;
    name: string;
    fieldType: FreeTextFormFieldType;
    isActive: boolean;
    isMandatory: boolean;
    showOnPoAndPdf: boolean;
    availableForRouting: boolean;
    specific?: FreeTextFormFieldSpecifics;
};

export type FreeTextFormFieldSpecifics = {
    label: FreeTextFormFieldLabelSpecific;
    list: FreeTextFormFieldListSpecific;
};

export type FreeTextFormFieldSpecificsAllowedTypes = FreeTextFormFieldLabelSpecific | FreeTextFormFieldListSpecific;

export type FreeTextFormFieldListSpecific = {
    customListId: number;
};

export type FreeTextFormFieldLabelSpecific = {
    labelText: string;
};


