///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Entities.User/rolesDataSource"/>
import * as _ from "underscore";
import * as $ from "jquery";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import DataSource = require("Core/Medius.Core.Web/Scripts/Medius/components/grid/dataSource/default");

export class RolesDataSource extends DataSource {
    Collection: any;
    constructor(collection:any) {
        super();
        this.Collection = collection;
    }

    load(dataQuery:any) {
        const slicedCollection= this.page(dataQuery, this.Collection());
        return $.Deferred().resolve(slicedCollection);
    }

    loadColumns() {
        const columns: any[] = [
              {
                  ColumnName: getLabelTranslation("#Core/companyName"),
                  ValueSource: "Company.Name",
                  ValueSourceType: "System.String",
                  Searchable: true,
                  Sortable: true,
                  Width: 100
              },
              {
                  ColumnName: getLabelTranslation("#Core/roleName"),
                  ValueSource: "Role.Name",
                  ValueSourceType: "System.String",
                  Searchable: true,
                  Sortable: true,
                  Width: 100
              },
              {
                  ColumnName: getLabelTranslation("#Core/IsActive"),
                  ValueSource: "Role.IsActive",
                  ValueSourceType: "System.Boolean",
                  Searchable: true,
                  Sortable: true,
                  Width: 20
              },
              {
                  Sortable: false,
                  Searchable: false,
                  Resizable: false,
                  Stretch: false,
                  Width: 90,
                  Name: "",
                  ValueSource: null
              }
        ];
        return $.Deferred().resolve(columns);
    }

    getTotalRows() {
        return this.Collection().length;
    }
    
    buildExportQuery() {
        const rolesIds = _(this.Collection()).map(function(role) {
            return koUtils.unpack(role.Id);
        }).join(",");
        
        const query = `from Medius.Core.Entities.RoleCompany t where t.Id in (${rolesIds}) order by t.Id asc`;

        return query;
    }

    destroy() {
        this.Collection = null;
    }
}