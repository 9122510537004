///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/task/bindings/taskContext"/>
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as ko from "knockout";
import * as $ from "jquery";
import * as _ from "underscore";

const taskDomKey = "medius-taskId";

const taskContext = {
    init: function() {
        return { controlsDescendantBindings: true};
    },
    update: function (element:any, bindingAccessor:any, allAccessor:any, viewModel:any, context:any) {
        const currentId = koUtils.domData.get(element, taskDomKey);
        let $element = $(element);
        let task = bindingAccessor();
        
        if( task && task instanceof Object && task.Id() !== currentId) {
            const newContext = context.createChildContext(task);
            _(newContext).extend({
                "$task": task,
                "$loadComplete": []
            });

            ko.applyBindingsToDescendants(newContext, element);
            koUtils.domData.set(element, taskDomKey, task.Id());
            ko.utils.domNodeDisposal.addDisposeCallback(element, function() {
                newContext.$task = null;
                newContext.$loadComplete = null;
                $element = null;
                task = null;
            });
            $element.show();
        } else {
            $element.hide();
        }
    }
};

export function register() {
    koUtils.registerBinding("taskContext", taskContext);
}