/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/administration/spa"/>
import {create as createAdministration} from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/adminApp";
import {isNull, once} from "underscore";
import * as route from "Core/Medius.Core.Web/Scripts/Medius/apps/spaManager/route";
import { renderTemplate } from "Core/Medius.Core.Web/Scripts/Medius/apps/utils";

class AdministrationApp {
    public app = createAdministration();
    public viewContextName = "Administration";
    public entityKeySub: ko.Subscription;
    public routes: any[];
    public initialize: () => void;

    constructor(spaManager: any, public containerId: string) {
        const routeHandlers = {
            open: () => {
                spaManager.openApp(this);
                this.app.setEntityKey(null, null);
            },
            browse: (entityType: string) => {
                spaManager.openApp(this);
                this.app.setEntityKey(entityType, null);
            },
            edit: (entityType: string, id: string) => {
                spaManager.openApp(this);
                this.app.setEntityKey(entityType, parseInt(id, 10));
            },
            editFromLink: (entityType: string, id: string) => {
                spaManager.openApp(this);
                this.app.setEntityKey(entityType, parseInt(id, 10), true);
            }
        };

        this.entityKeySub = this.app.EntityKey.subscribe((key) => {
            const t = ["Administration"];

            if (!isNull(key.Name)) {
                t.push(key.Name);
            }

            if (!isNull(key.Id)) {
                t.push(key.Id.toString());
            }

            if (window.location.href.includes("/entity-link")) {
                spaManager.router.updateUrl(t, undefined, true);
            } else {
                spaManager.router.updateUrl(t);
            }
                
        });

        this.routes = [
            route.create("Administration", routeHandlers.open),
            route.create("Administration/", routeHandlers.open),
            route.create("Administration/:entityType", routeHandlers.browse),
            route.create("Administration/:entityType/", routeHandlers.browse),
            route.create("Administration/:entityType/:id", routeHandlers.edit),
            route.create("Administration/:entityType/:id/", routeHandlers.edit),
            route.create("Administration/:entityType/:id/entity-link", routeHandlers.editFromLink)
        ];

        this.initialize = once(() => {
            this.app.init();
        });
    }
}

export function create(spaManager: any, containerId: string) {
    return new AdministrationApp(spaManager, containerId);
}

export function getView() {
    return renderTemplate("Core:templates/Administration/Main.html");
}
