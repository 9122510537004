///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Entities.DataSearch.RoleDataSearch/roleDataSearchTab"/>
import * as sync from "Core/Medius.Core.Web/Scripts/Medius/core/sync";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as notification from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import * as _ from "underscore";
import * as ko from "knockout";
import { isNullOrUndefined, isNotNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
class RoleDataSearchTab {
    RoleDataSearches: any;
    Role: ko.Observable<any>;
    DataSearch: ko.Observable<any>;
    constructor(entityWithContext:any) {
        const roleDataSearchs = entityWithContext.RelatedObjects["Medius.Core.Entities.DataSearch.RoleDataSearch"].RelatedEntities;

        this.RoleDataSearches = roleDataSearchs;
        this.Role = ko.observable();
        this.DataSearch = ko.observable(entityWithContext.Entity);
    }
    removeRow(rowToRemove:any) {
        this.RoleDataSearches.remove(rowToRemove);
    }

    addRow() {
        let errorMessage:string;

        if (isNullOrUndefined(this.Role())) {
            errorMessage = globalization.getLabelTranslation("#Core/notifyRoleMustBeSelected");
            notification.error(errorMessage);
            return;
        }

        if (isNotNullOrUndefined(
            _(this.RoleDataSearches()).find((rd) => {
                return ko.utils.unwrapObservable(rd.Role().Id) === ko.utils.unwrapObservable(this.Role().Id);
            })
        )) {
            errorMessage = globalization.getLabelTranslation("#Core/notifySelectedRoleIsAlreadyAdded");
            notification.error(errorMessage);
            return;
        }

        const roleDataSearch = sync.getNew("Medius.Core.Entities.DataSearch.RoleDataSearch");
        roleDataSearch.Role = ko.observable(this.Role());
        roleDataSearch.SharedDataSearch = this.DataSearch;
        this.RoleDataSearches.push(roleDataSearch);
    }

    dispose() {
        this.RoleDataSearches(null);
        this.Role(null);
        this.DataSearch(null);
    }
}

export = function(entityWithContext:any) {
    return new RoleDataSearchTab(entityWithContext);
};