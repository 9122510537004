///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/configurationProperties/baseObject"/>
import base = require("Core/Medius.Core.Web/Scripts/Medius/components/configurationProperties/base");
import * as ko from "knockout";
export class ConfigurationProperty extends base{
    Value: ko.Computed<any>;
    disposables: any;
    IsOverriden: any;
    resetCurrentValue: any;
    setInheritedValueAsCurrentValue: any;
    CurrentValue: any;
    InheritedValue: any;
    constructor(params: any) {
        super(params);
        this.Value = ko.computed({
            read: () => {
                if (this.IsOverriden()) {
                    return this.CurrentValue();
                }
                return this.InheritedValue();
            },
            write: (value) => {
                this.CurrentValue(value);
            }
        });

        this.disposables.push(
            this.IsOverriden
                .subscribe((isOverriden: any) => {
                    if (isOverriden) {
                        this.setInheritedValueAsCurrentValue();
                    } else {
                        this.resetCurrentValue();
                    }
                }));

        this.disposables.push(this.Value);
    }

    isDefinedInCurrentCompany() {
        return this.CurrentValue() !== null;
    }

    getEmptyValue(): any {
        return null;
    }
}

export const derive = ConfigurationProperty;
export function create(params: any) {
    return new ConfigurationProperty(params);
}
