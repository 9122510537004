/// <amd-module name="Core/Medius.Core.Web/Scripts/loginPage/ForgotPasswordResultPage"/>
import * as React from "react";
import Card from "Core/Medius.Core.Web/Scripts/components/card/Card";

export default function ForgotPasswordResultPage(props: IForgotPasswordResultPageProps) {       
    return (
        <Card>
            <div className="forgot-password-result-page__email-sent" data-testid="forgot-password-email-sent">
                Email with reset password link has been sent on provided email address
            </div>

            <div className="card-text-container">
                <a onClick={props.goToLoginPage} className="card-text" data-testid="back-to-login-page-link">Back</a>
            </div>
        </Card>
    );
}

interface IForgotPasswordResultPageProps {
    goToLoginPage: () => void;
}
