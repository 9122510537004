/// <amd-module name="Core/Medius.Core.Web/Scripts/eventBus"/>
import * as Backbone from "backbone";
import * as _ from "underscore";
import {log} from "Core/Medius.Core.Web/Scripts/Medius/lib/logger";

let eventBus: Backbone.Events = null;

export { history as backboneHistory} from "backbone";

export function start() {
    eventBus = _.extend({}, Backbone.Events);    
}

export function stop() {
    eventBus.stopListening();
    eventBus = null;
}

export function listenTo(event: string, callback: any) {
    const listener: Backbone.Events = _.extend({}, Backbone.Events);
    listener.listenTo(eventBus, event, callback);
    return listener;
}

export function trigger(eventName: string, eventData?: any) {
    log("Event triggered: " + eventName);
    eventBus.trigger(eventName, eventData);
}
