///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/delayedClick"/>
import * as fn from "Core/Medius.Core.Web/Scripts/Medius/lib/functions";
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as ko from "knockout";
import * as _ from "underscore";

const defaultDelay = 120;
const bindingName = "delayedClick";

/*
* Parses incoming parameters to parametes object.
*
* Returned object should look at least as follws: {
*   click: <click handler to attach>,
*   delay: <observable/value of milliseconds of delay>
* }
*/
function parseParams(bindlingValue:any) {
    switch (typeof bindlingValue) {
        case "function":
            return {
                click: bindlingValue,
                delay: defaultDelay
            };
        case "object":
            if (!bindlingValue || typeof bindlingValue.click !== "function") {
                throw new Error(`Parameter click for ${bindingName} binding is required`);
            }
            return _({ delay: defaultDelay }).extend(bindlingValue);
        default:
            throw new Error(`Unsupported parameter type for ${bindingName} binding`);
    }
}

const delayedClick = {
    init: function (element:any, valueAccessor:any, allValues:any, viewModel:any, bindingContext:any) {
        const bindingValues = valueAccessor();
        const params = getParams(bindingValues);
        const handler = fn.delayed(params.click, params.delay, undefined);
        const handlerAccessor = ko.observable(handler);

        return ko.bindingHandlers.click.init.call(this, element, handlerAccessor, allValues, viewModel, bindingContext);
    }
};


function getParams(bindingValue:any) {
    const params = parseParams(bindingValue);
    return koUtils.unpackAll(params);
}

export function register() {
    koUtils.registerBinding(bindingName, delayedClick);
}