///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/grid/bindings/clickableRows"/>
import * as ko from "knockout";
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as _ from "underscore";

const clickableRows = {
    init: function(element:HTMLElement, bindingAccessor:any, allBindingsAccessor:any, viewModel:any, bindingContext:any) {
        let grid = bindingContext.$grid;
        
        if (!grid.RowsClickable || !_.isFunction(grid.options.onClickRow)) {
            return;
        }

        element.classList.add("clickable");

        let clickHandler = function(target:any, event:any) {
            let preventClikableRowAction:boolean;
            if(target.dataset.preventClickableRowAction !== undefined) {
                preventClikableRowAction = target.dataset.preventClickableRowAction === "true";
            }
            
            const preventClickRow = preventClikableRowAction === undefined ? false : preventClikableRowAction;

            if (!preventClickRow) {
                const clickedRow = ko.dataFor(target.parentElement);
                grid.SelectedRow(clickedRow);
                grid.options.onClickRow(clickedRow, event, grid);
            }
        };

        const listener = function(event:Event) {
            let t:any = event.target;
            while (t && t !== this) {
                if (t.matches(".grid-cell")) {
                    clickHandler(t, event);
                }
                t = t.parentNode;
            }
        };

        element.addEventListener("click", listener);

        koUtils.addDisposeCallback(element, function() {
            element.removeEventListener("click", listener);
            grid = null;
            clickHandler = null;
        });
    }
};

export function register() {
    koUtils.registerBinding("grid-clickableRows", clickableRows);
}