///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/editors/textTranslation/defaults"/>
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";

interface ReturnedObject {
    template: string,
    align: string,
    options: {
        defaultName: string,
        placeholder: string,
        pattern: any,
        css: any
    }
}

export = {
    get: function (): ReturnedObject {
        return {
            template: "editors-text-translation",           
            align: 'left',
            options: {
                defaultName: "editor-text-translation",
                placeholder: globalization.getLabelTranslation("#Core/textTranslationPlaceholder"),
                pattern: null,
                css: null
            }
        };
    }
};