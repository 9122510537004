/// <amd-module name="Core/Medius.Core.Web/Scripts/components/prepaymentInvoices/appliedPrepaymentsCommon"/>
import Big from "big.js";
import * as dateLocalizer from "Core/Medius.Core.Web/Scripts/Medius/components/editors/date/localizer";
import * as simplePresenter from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/simplePresenter";
import { createFromValues, Amount } from "Core/Medius.Core.Web/Scripts/Models/amount";

interface PrepaymentInformation {
    InvoiceNumber: string;
    IsManual: boolean;
    InvoiceDate: Date;
    VoucherNumber: string;
    PurchaseOrderNumber: string;
    AppliedAmount: AmountDto;
    InvoiceId: number;
    RemainingAmount: AmountDto;
    CanAccessInvoice: boolean;
}

type InvoiceType = "PoInvoice" | "NonPoInvoice";

interface OriginInvoiceData { 
    Gross: AmountDto;
    InvoiceId: number;
    Type: InvoiceType
}

const localizeAmount = (obj: { DisplayValue: string | number, CurrencyCode: string }) => {
    return simplePresenter.present(createFromValues(Number(obj.DisplayValue), obj.CurrencyCode));
};

const localizeAmountForBig = (amount: Big, currencyCode: string) => {
    return simplePresenter.present(new Amount({DisplayValue: amount.toString(), CurrencyCode: currencyCode}));
};

const localizeDate = (obj: Date) => {
    return dateLocalizer.create("d").toString(obj);
};

const convertBigToNumber = (value: Big) => {
    return Number(value);
};

const convertBigToAmountDto = (value: Big, currencyCode: string) => {
    return {
        DisplayValue: value.toString(),
        CurrencyCode: currencyCode
    } as AmountDto;
};

export { PrepaymentInformation, OriginInvoiceData, InvoiceType, localizeDate, localizeAmountForBig, convertBigToNumber, convertBigToAmountDto, localizeAmount };