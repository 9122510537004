/// <amd-module name="Core/Medius.Core.Web/Scripts/components/sidebar/sidebar"/>
import * as React from "react";
import { useEffect, useState } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { AdditionalLinks } from "./additionalLinks";
import { AdditionalLinkDto, QuickLinksDto, ConfigurableTypeDto, getSidebarData, getAdministrationUrlPrefix } from "Core/Medius.Core.Web/Scripts/AdminPages/administrationServices";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { useSelector, useDispatch } from "react-redux";
import { DisplayNameFilter, filterByDisplayName } from "./filters";
import { RootState } from "Core/Medius.Core.Web/Scripts/shared/store/reduxStore";
import { SetSidebarStateAction } from "./sidebarGridState";
import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { getBasePath } from "Core/Medius.Core.Web/Scripts/Medius/lib/path";

function createLink(entityName: string) {
    return `${getBasePath()}#${getAdministrationUrlPrefix(entityName)}`;
}

function filterConfigurableTypes(types: ConfigurableTypeDto[], filtersContainer?: { filters: { field: string; value: string }[] }) {
    if (!filtersContainer) {
        return types;
    }
    const filters = filtersContainer.filters;
    const typeFilter = filters.filter(f => f.field === "displayName")[0];
    if (typeFilter) {
        types = filterByDisplayName(types, typeFilter.value);
    }
    return types;
}

const selectSidebarGridState = (state: RootState) => state.sidebarGridState;

export function Sidebar({ onBackAction }: { onBackAction: () => void }) {
    const [gridData, setGridData] = useState<ConfigurableTypeDto[]>([]);
    const [quickLinksData, setQuickDataLinks] = useState<QuickLinksDto[]>([]);
    const [additionalLinksData, setAdditionalDataLinks] = useState<AdditionalLinkDto[]>([]);
    const gridState = useSelector(selectSidebarGridState);
    const dispatch = useDispatch();

    useEffect(() => {
        fetchData();
        async function fetchData() {
            const { configurableTypes, quickLinks, additionalLinks } = await getSidebarData();
            setGridData(configurableTypes);
            setQuickDataLinks(quickLinks);
            setAdditionalDataLinks(additionalLinks);
        }
    }, []);

    return (<>
        <div className="sidebar__header">
            <h6>{translate("#Core/adminstrationQuickLink")}</h6>
            <button id="toggle-sidebar-visibility"
                className="btn btn-mini"
                onClick={onBackAction}
                data-testid="Sidebar-back-button">
                <i className="icon-backward"></i>
            </button>
        </div>
        <ul className="nav nav-pills" data-testid="favoritesLinks">
            {quickLinksData.map(({ forType, displayName }) => (
                <li key={forType}>
                    <a href={createLink(forType)}>{translate(displayName)}</a>
                </li>))}
        </ul>
        <Grid
            filterable
            pageable
            scrollable="none"
            total={filterConfigurableTypes(gridData, gridState.filter).length}
            data={filterConfigurableTypes(gridData, gridState.filter).slice(gridState.skip, gridState.skip + gridState.take)}
            skip={gridState.skip}
            take={gridState.take}
            filter={gridState.filter as CompositeFilterDescriptor}
            onDataStateChange={e => { dispatch({ type: SetSidebarStateAction, payload: e.dataState }); }}
            onFilterChange={e => { dispatch({ type: SetSidebarStateAction, payload: { skip: 0, take: 20, filter: e.filter } }); }}
            onRowClick={e => { window.location.hash = getAdministrationUrlPrefix(e.dataItem.fullName); }}
            className="sidebar__grid"
        >
            <GridColumn field="displayName" title={translate("#Core/name")} filterCell={DisplayNameFilter} />
        </Grid>
        <AdditionalLinks links={additionalLinksData} />
    </>);
}
