/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/PaymentTerm/paymentTerm"/>
import { PaymentTermResponse, PaymentTermGeneralRequest, PaymentTermStartDateDto, PaymentTermLineDto } from "./backendController";
import { LegacyCompanyViewModel } from "./legacyCompanyViewModel";
import {observable, observableArray, pureComputed} from "knockout";

const linesMinCount = 1;
const linesMaxCount = 3;

export interface MediusPercentage extends ko.Observable<{
    readonly Percent: ko.Observable<number>;
}> {
}

export class PaymentTerm {
    public readonly paymentTermId: ko.Observable<string>;
    public readonly name: ko.Observable<string>;
    public readonly company: ko.Observable<any>;
    public readonly description: ko.Observable<string>;
    public readonly active: ko.Observable<boolean>;
    public readonly externalSystemId: ko.Observable<string>;
    public readonly startDate: ko.Observable<PaymentTermStartDate>;
    public readonly importedTimestamp: Date;
    public readonly erpSourceId: string;

    public readonly lines: ko.ObservableArray<PaymentTermLine>;
    public readonly canAddLines: ko.Computed<boolean>;
    public readonly canRemoveLines: ko.Computed<boolean>;
    public readonly addNewLine: () => void;

    private readonly entityId: number;

    public constructor(paymentTerm: PaymentTermResponse, companyViewModel: LegacyCompanyViewModel) {
        this.paymentTermId = observable(paymentTerm.paymentTermId);
        this.name = observable(paymentTerm.name);
        this.company = observable(companyViewModel);
        this.description = observable(paymentTerm.description);
        this.active = observable(paymentTerm.active);
        this.externalSystemId = observable(paymentTerm.externalSystemId);
        this.startDate = observable(new PaymentTermStartDate(paymentTerm.startDate));
        this.importedTimestamp = paymentTerm.importedTimestamp;
        this.erpSourceId = paymentTerm.erpSourceId;

        this.lines = observableArray(paymentTerm.lines.map(line => new PaymentTermLine(this, line)));
        this.canAddLines = pureComputed(() => this.lines().length < linesMaxCount);
        this.canRemoveLines = pureComputed(() => this.lines().length > linesMinCount);

        this.addNewLine = () => {
            const defaults: PaymentTermLineDto = {
                numberOfDays: 0,
                numberOfMonths: 0,
                fixedDay: null,
                endOfMonth: false,
                cashDiscount: 0
            };

            this.lines.push(new PaymentTermLine(this, defaults));
        };

        this.entityId = paymentTerm.id;
    }

    public toRequest(): PaymentTermGeneralRequest {
        return {
            id: this.entityId,
            paymentTermId: this.paymentTermId(),
            name: this.name(),
            description: this.description(),
            externalSystemId: this.externalSystemId(),
            company_id: this.company().Id(),
            active: this.active(),
            lines: this.lines()
                .map(line => line.toRequest())
        };
    }
}

export class PaymentTermStartDate {
    public readonly type: ko.Observable<string>;
    public readonly fixedDay: ko.Observable<number>;
    public readonly numberOfMonths: ko.Observable<number>;

    public constructor(startDateDto: PaymentTermStartDateDto) {
        this.type = observable(startDateDto.type);
        this.fixedDay = observable(startDateDto.fixedDay);
        this.numberOfMonths = observable(startDateDto.numberOfMonths);
    }
}

export class PaymentTermLine {
    public readonly numberOfDays: ko.Observable<number>;
    public readonly numberOfMonths: ko.Observable<number>;
    public readonly fixedDay: ko.Observable<number>;
    public readonly endOfMonth: ko.Observable<boolean>;
    public readonly cashDiscount: MediusPercentage;

    public readonly canRemoveIt: ko.Computed<boolean>;
    public readonly removeIt: () => void;

    public constructor(container: PaymentTerm, line: PaymentTermLineDto) {
        this.numberOfDays = observable(line.numberOfDays);
        this.numberOfMonths = observable(line.numberOfMonths);
        this.fixedDay = observable(line.fixedDay);
        this.endOfMonth = observable(line.endOfMonth);
        this.cashDiscount = observable({
            Percent: observable(line.cashDiscount)
        });

        this.canRemoveIt = pureComputed(() => container.canRemoveLines());
        this.removeIt = () => container.lines.remove(this);
    }

    public toRequest(): PaymentTermLineDto {
        return {
            numberOfDays: this.numberOfDays(),
            numberOfMonths: this.numberOfMonths(),
            fixedDay: this.fixedDay(),
            endOfMonth: this.endOfMonth(),
            cashDiscount: this.cashDiscount().Percent()
        };
    }
}
