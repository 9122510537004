/// <amd-module name="Core/Medius.Core.Web/Scripts/components/messageDialog/messageDialog"/>
import { translate } from 'Core/Medius.Core.Web/Scripts/lib/globalization';
import * as React from "react";
import { AlertDialog, IconActionCloseRegular, IconStatusInfoRegular } from '@medius/ui-controls';
import { DialogMessage } from "Core/Medius.Core.Web/Scripts/shared/Dtos/dialogMessage";


export interface IMessageDialogProps {
    onClose: () => void;
    headerMessage: string;
    severity: MessageSeverity;
    messages: DialogMessage[];
    dataTestId: string;
}

export enum MessageSeverity {
    Information,
    Warning,
    Error
}

export const MessageDialog = (props: IMessageDialogProps) => {
    const { onClose, headerMessage, severity, messages, dataTestId } = props;

    return (<>
        <AlertDialog isOpen={messages.length > 0} width='medium' onClose={() => onClose()} data-testid={dataTestId}>
            <div className="message-dialog__header">
                <span className="heading4">{translate(headerMessage)}</span>

                <div className="message-dialog__header-close-button" onClick={onClose}>
                    <IconActionCloseRegular data-testid="close-dialog" />
                </div>
            </div>

            <div className="message-dialog__img">
                {severity === MessageSeverity.Error &&
                    <IconStatusInfoRegular size="large" color="disabled" />
                }
            </div>

            <div className="message-dialog__messages">
                <ul>

                    {messages.map((msg, ix) => (
                        <li key={ix}>{msg.messageHeader}
                            {msg.messageBodyText != undefined && msg.messageBodyText.length > 0 &&
                                <span>
                                    <br /> {msg.messageBodyText}
                                </span>
                            }

                            {msg.messageBodyList != undefined && msg.messageBodyList.length > 0 &&
                                <span>
                                    <br /><br />
                                    <ul>
                                        {msg.messageBodyList.map((msgBL, idx) => (
                                            <li key={idx}>{msgBL}</li>
                                        ))}
                                    </ul>
                                </span>
                            }
                            {msg.messageFooter != undefined && msg.messageFooter.length > 0 &&
                                <span>
                                    <br /> {msg.messageFooter}
                                </span>
                            }

                        </li>


                    ))}
                </ul>
            </div>

        </AlertDialog>
    </>
    );
};