/// <amd-module name="Core/Medius.Core.Web/Scripts/Models/document"/>
import {observable, pureComputed } from "knockout";
import Links from "Core/Medius.Core.Web/Scripts/components/links/links";
import {PdfViewer} from "Core/Medius.Core.Web/Scripts/components/pdfViewer/pdfViewer";
import {SendEmailContainer} from "Core/Medius.Core.Web/Scripts/components/sendEmail/sendEmailContainer";
import {TextIconCallout} from "Core/Medius.Core.Web/Scripts/shared/components/textIconCallout";

import {translateDocumentSource, 
    translateDocumentProvider,
    translateDocumentFormat,
    translateInvalidatedAndEditedIconTooltipFromImport, 
    translateInvalidatedAndEditedIconTooltipWithId} from "Core/Medius.Core.Web/Scripts/Medius/apps/document/documentTranslator";

import mapping = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/mapping/collection/instance");

export function DocumentViewmodel(vm: any, data: any, viewmodelContext: any) {
    vm.links = new Links(vm);

    if (vm.Id() !== 0) {
        let companyId: number | null = null;
        if (vm.Company && vm.Company() && vm.Company().Id && vm.Company().Id()) {
            companyId = vm.Company().Id();
        }
        vm.pdfViewer = new PdfViewer(vm.Id(), vm.$type(), vm.HashFiles(), companyId);
        vm.sendEmailContainer = new SendEmailContainer(vm.pdfViewer, companyId, vm.$type());
    }

    vm.toDto = () => viewmodelContext.toJS(vm);

    vm.dispose = () => {
        if (vm.pdfViewer) {
            vm.pdfViewer.dispose();
        }
        if (vm.sendEmailContainer) {
            vm.sendEmailContainer.dispose();
        }
        vm.pdfViewer = null;
    };

    vm.isRedistributable = false;

    vm.DocumentSourceLocalized = observable(translateDocumentSource(vm.DocumentSource()));
    vm.DocumentProviderLocalized = observable(translateDocumentProvider(vm.DocumentProvider())); 
    vm.DocumentFormatLocalized = observable(translateDocumentFormat(vm.DocumentFormat())); 
    const referencedDocuments = (vm.ReferencedDocuments && vm.ReferencedDocuments() !== null) ? observable(vm.ReferencedDocuments().map((rf: { Value: () => any; }) => rf.Value()).join("; ")) : observable([]);

    vm.TextIconCallout = pureComputed(() => { return ({
         functionComponent: TextIconCallout,
         props: { referencedDocuments: referencedDocuments() }
        }); });

    const tooltipTranslated = vm.Perspective && vm.Perspective() !== null && vm.Perspective().IsInvalidatedFromEdi && vm.Perspective().IsInvalidatedFromEdi() ? translateInvalidatedAndEditedIconTooltipFromImport :
        translateInvalidatedAndEditedIconTooltipWithId(vm.InvalidatedDocumentId());

    vm.InvalidateAndEditIconTooltip = observable(tooltipTranslated);
}

export function registerMapping() {
    const DOCUMENT_TYPE_NAME = "Medius.Data.Document, Medius.Common";
    mapping.register(DOCUMENT_TYPE_NAME, DocumentViewmodel);
}
