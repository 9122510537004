/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/editors/validator" />

import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as logger from "Core/Medius.Core.Web/Scripts/Medius/lib/logger";
import * as helpers from "Core/Medius.Core.Web/Scripts/Medius/components/editors/helpers";
import * as State from "Core/Medius.Core.Web/Scripts/Medius/components/editors/state";
import * as $ from "jquery";
import * as ko from "knockout";
import * as _ from "underscore";

export class Validator {

    params: any;
    IsRequired: ko.Observable;
    Validity: ko.Observable;
    IsValidating = ko.observable(false);
    IsValid: ko.Computed;
    IsInvalid: ko.Computed;
    ValidationDetails = ko.observable(null);
    ValidationRequest: any = null;

    constructor(params: any = {}) {
        this.params = {
            required: false,
            updateIfInvalid: false,
            businessValidator: (newValue: any) => newValue !== undefined,            
            inputValidator: null,
            emptyCheck: null
        };
        helpers.mergeDefaults(params, this.params);

        this.IsRequired = (ko.isObservable(this.params.required)) ? this.params.required :
            ko.observable(this.params.required);
        this.Validity = ko.observable(State.Valid);
        this.IsValid = ko.computed(() => {
            return !this.IsValidating() && this.Validity() === State.Valid;
        });
        this.IsInvalid = ko.computed(() => {
            return !this.IsValidating() && this.Validity() === State.InValid;
        });
    }

    validate = (newValue: any) => {
        let inputValidationResult: any;

        if (this.ValidationRequest) {
            if (this.ValidationRequest.state() !== "resolved" && this.ValidationRequest.abort) {
                this.ValidationRequest.abort();
            }
            this.ValidationRequest = null;
        }

        try {

            // reset validation details
            this.ValidationDetails(null);
            this.IsValidating(true);

            // run 'required' validation
            if (!this.runRequiredValidation(newValue)) {
                this.setInvalid(getLabelTranslation("#Core/defaultValidation_REQUIREDATTRIBUTE"));
                return {
                    valid: false,
                    updateIfInvalid: this.params.updateIfInvalid,
                    value: newValue,
                    validationDetails: null
                };
            }

            // run input validation
            inputValidationResult = this.runInputValidation(newValue);
            if (!inputValidationResult || !inputValidationResult.valid) {
                this.setInvalid(inputValidationResult.validationDetails);
                return {
                    valid: false,
                    updateIfInvalid: this.params.updateIfInvalid,
                    value: newValue,
                    validationDetails: null
                };
            }
            // run business validation
            this.ValidationRequest = this.runBusinessValidation(newValue);
            return this.ValidationRequest;

        } catch (e) {
            logger.error(e);
            this.setInvalid(e.message);
        }
    };

    setInvalid = (message: string) => {
        this.ValidationDetails(message);
        this.Validity(State.InValid);
        this.IsValidating(false);
    };

    resetValidity = () => {
        this.ValidationDetails(null);
        this.Validity(State.Valid);
        this.IsValidating(false);
    };

    runRequiredValidation = (newValue: any) => {
        let valid = true;
        const emptyCheck = this.params.emptyCheck;

        if (!this.IsRequired()) {
            return true;
        }

        if (!_.isNull(emptyCheck)) {
            switch (typeof emptyCheck) {
                case "string":
                    if (emptyCheck === newValue) {
                        valid = false;
                    }
                    break;

                case "function":
                    if (emptyCheck(newValue)) {
                        valid = false;
                    }
                    break;

                default:
                    logger.error("validator.js: unknown type of params.emptyCheck property: " + typeof emptyCheck);
                    break;
            }
        }
        else if (!/[^\s]+/.test(newValue) || _.isNull(newValue) || _.isUndefined(newValue)) {
            valid = false;
        }

        return valid;
    };

    runInputValidation = (newValue?: any) => {
        let result;
        let isValid;
        const validator = this.params.inputValidator;

        result = {
            valid: true,
            validationDetails: null
        };

        if (!_.isNull(validator) && _.isFunction(validator)) {
            result = validator(newValue);

            if (_.isBoolean(result)) {
                isValid = result;
                result = {
                    valid: isValid,
                    validationDetails: null
                };
            }
        }

        return result;
    };

    runBusinessValidation = (newValue: any) => {
        let isValid;
        let businessValidationResult;
        const validator = this.params.businessValidator;

        if (!_.isNull(validator) && _.isFunction(validator)) {
            businessValidationResult = validator(newValue);

            if (_.isBoolean(businessValidationResult)) {
                isValid = businessValidationResult;
                businessValidationResult = {
                    valid: isValid,
                    updateIfInvalid: this.params.updateIfInvalid,
                    value: newValue,
                    validationDetails: null
                };
            }
        } else {
            businessValidationResult = {
                valid: true,
                updateIfInvalid: true,
                value: newValue,
                validationDetails: null
            };
        }

        return $.when(businessValidationResult)
            .done((result) => {
                this.onBusinessValidationCompleted(result);
            })
            .fail(() => {
                this.onBusinessValidationFailed();
            })
            .always((result) => {
                this.IsValidating(false);

                if (result.validationDetails) {
                    this.ValidationDetails(result.validationDetails);
                }
            });
    };

    onBusinessValidationCompleted = (result: any) => {
        // update validity status
        this.Validity((result.valid) ? State.Valid : State.InValid);
    };

    onBusinessValidationFailed = () => {
        this.Validity(State.InValid);
    };

    dispose = () => {
        this.IsInvalid.dispose();
        this.IsValid.dispose();
        this.Validity(null);
        this.ValidationDetails(null);

        //PSD-1824
        //This params disposal has gone through couple changes
        //To make sure that emptyCheck is set to null to pass validation without reference error
        //and drop current reference to object we creating new with only one property
        //in this way we are sure that if in other places some part of code is dependent on undefined
        //behavior will be still correct
        this.params = {
            emptyCheck: null
        };

    };
}