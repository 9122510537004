/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/editors/bool/model"/>
import {Basic} from "Core/Medius.Core.Web/Scripts/Medius/components/editors/basic";
import * as ko from "knockout";
import * as logger from "Core/Medius.Core.Web/Scripts/Medius/lib/logger";
import * as _ from "underscore";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";

function wrapTextObservable(model: any, propertyName: any) {
    const propertyValue = model[propertyName];

    switch (typeof propertyValue) {
        case "string":
            return ko.observable(propertyValue);
        case "function":
            if (ko.isObservable(propertyValue)) {
                return propertyValue;
            }
            break;
        default:
            logger.error("Unknown editor/bool/model.wrapTextObservable model[propertyName] type for propertyName: " + propertyName);
            break;
    }
    throw new Error("Value not supported");
}

const defaults = {
    trueLabel: "#Core/yes",
    falseLabel: "#Core/no",
    template: "editors-bool-default",
    nullable: false
};

class Bool {
    LocalizedTrue: ko.Computed<any>;
    LocalizedFalse: ko.Computed<any>;
    IsTrue: ko.Computed<boolean>;
    IsFalse: ko.Computed<boolean>;
    toggle: () => void;
    setTrue: () => void;
    setFalse: () => void;
    ActiveLabel: ko.Computed<any>;
    OriginalValue: ko.Observable<any>;
    params: any;
    dispose: () => void;
    constructor(binding: any, viewmodel: any, context: any, params: any) {
        binding = _({}).extend(defaults, binding);
        _(this).extend(new Basic(binding, context, params));

        const trueLabel = wrapTextObservable(binding, "trueLabel");
        const falseLabel = wrapTextObservable(binding, "falseLabel");

        this.LocalizedTrue = ko.computed(() => {
            const key = trueLabel();
            return globalization.getLabelTranslation(key);
        });

        this.LocalizedFalse = ko.computed(() => {
            const key = falseLabel();
            return globalization.getLabelTranslation(key);
        });

        this.IsTrue = ko.computed(() => this.OriginalValue() === true);

        this.IsFalse = ko.computed(() => this.OriginalValue() === false);

        this.toggle = () => {
            const currentValue = this.OriginalValue();
            this.OriginalValue(!currentValue);
        };

        this.setTrue = () => {
            const newValue = (this.IsTrue() && this.params.nullable) ? null : true;
            this.OriginalValue(newValue);
        };

        this.setFalse = () => {
            const newValue = (this.IsFalse() && this.params.nullable) ? null : false;
            this.OriginalValue(newValue);
        };

        this.ActiveLabel = ko.computed(() => {
            if (this.IsTrue()) {
                return this.LocalizedTrue();
            }
            if (this.IsFalse()) {
                return this.LocalizedFalse();
            }
            return null;
        });

        this.dispose = () => {
            this.ActiveLabel.dispose();
            this.IsFalse.dispose();
            this.IsTrue.dispose();
            this.LocalizedFalse.dispose();
            this.LocalizedTrue.dispose();
            Basic.prototype.dispose.call(this);
        };
    }
}

export function create(binding: any, viewmodel: any, context: any, params: any) {
    return new Bool(binding, viewmodel, context, params);
}
