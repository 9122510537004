///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/components/accessControl/das" />
import * as ko from "knockout";
import * as _ from "underscore";
import * as baseFactory from "Core/Medius.Core.Web/Scripts/Medius/components/components/basic";
import * as propertyResolver from "Core/Medius.Core.Web/Scripts/Medius/components/resolver/property";
import * as accessLevelValues from "Core/Medius.Core.Web/Scripts/Medius/components/components/accessControl/values";
import * as utils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import { isNullOrUndefined } from "../../../../lib/underscoreHelpers";
import * as das from "Core/Medius.Core.Web/Scripts/Medius/core/das";

function DasResolutionError(...args: any) {
    Error.apply(this, args);
}
_(DasResolutionError.prototype).extend(Error.prototype);

_(DasComponent.prototype).extend({
    dasToComponent: function (dasLevel: any) {
        switch (dasLevel) {
            case das.levels.Edit:
                return accessLevelValues.edit;
            case das.levels.Display:
                return accessLevelValues.view;
            case null:
                return accessLevelValues.view;
            case das.levels.Hide:
                return accessLevelValues.hide;
            default:
                throw DasResolutionError("Unsupported DAS level: " + dasLevel);
        }
    }
});

function DasComponent(bindingParameters: any, viewModel: any, ...args: any) {
    if (isNullOrUndefined(viewModel)) {
        throw DasResolutionError("View model is required for DAS resolution");
    }

    const context = bindingParameters.accessCheckContext || {};
    const companyId = utils.pack(context.companyId || null);
    const activityId = utils.pack(context.activityId || null);
    const entityType = utils.unpack(viewModel.$type) || viewModel.constructor.name;
    const propertyName = propertyResolver.resolvePropertyName(bindingParameters.value, viewModel);

    bindingParameters.accessLevel = ko.computed(() => {
        const dasLevel = das.resolveAccess(propertyName, entityType, activityId(), companyId());
        return this.dasToComponent(dasLevel);
    }, this);

    _(this).extend(baseFactory.create.apply(this, [bindingParameters, viewModel, ...args]));
}

export function create(bindingParameters: any, viewModel: any, context: any, options: any){
    return new (DasComponent as any)(bindingParameters, viewModel, context, options);
}

export function Error(){
    return DasResolutionError;
}