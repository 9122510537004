/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/dashboard/modals/library"/>
import { observable, observableArray } from "knockout";
import * as $ from "jquery";

class GadgetsLibrary {
    Dashboard: any = null;
    Name = observable("");
    Gadgets = observableArray([]);
    
    installGadget = (gadget: any) => {
        this.Dashboard.installGadget(gadget);
        $("#modal-dashboard-library").modal("hide");
    };
}

export function create(_frameworkInstance: any) {
    return new GadgetsLibrary();
}
