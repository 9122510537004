///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Entities.User/BulkAdminReminders"/>
import * as ko from "knockout";
import * as featureToggles from "Core/Medius.Core.Web/Scripts/Medius/core/featureToggle";
import * as _ from "underscore";
import * as bulkRemoveRemindersGrid from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Entities.User/Reminders/bulkRemoveGrid";
import * as remindersGrid from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Reminders/grid";

class BulkAdminReminders {
    isStatusModified: ko.Observable<boolean>;
    isStatusDisabled: ko.Observable<boolean>;
    hasActionableEmailsConfigured: ko.Observable<any>;
    remindersToAdd: ko.ObservableArray<any>;
    remindersToDelete: ko.ObservableArray<any>;
    newRemindersGrid: ko.Observable<any>;
    toDeleteRemindersGrid: ko.Observable<any>;
    isActionableEmailsToggleVisible: ko.Observable<boolean>;
    isStatusModifiedSub: any;
    constructor() {
        this.isStatusModified = ko.observable(false);
        this.isStatusDisabled = ko.observable(true);
        this.hasActionableEmailsConfigured = ko.observable(null);
        this.remindersToAdd = ko.observableArray([]);
        this.remindersToDelete = ko.observableArray([]);
        this.newRemindersGrid = ko.observable();
        this.toDeleteRemindersGrid = ko.observable();
        this.isActionableEmailsToggleVisible = ko.observable(featureToggles.isActionableEmailsToggleVisible());

        this.isStatusModifiedSub = this.isStatusModified.subscribe((value:boolean) => {
            this.isStatusDisabled(!value);

            if (!value)
                this.hasActionableEmailsConfigured(null);
        });

        this.createGrids();
    }

    onOpenBack() {
        this.createGrids();
    }

    createGrids() {
        remindersGrid.create(this.remindersToAdd, { template: 'grid-tpl-default', editPageSize: false }).done((grid:any) => {
            this.newRemindersGrid(grid);
        });

        bulkRemoveRemindersGrid.create(this.remindersToDelete).done((grid:any) => {
            this.toDeleteRemindersGrid(grid);
        });
    }

    validate() {
        return true;
    }

    save(modifications:any[]) {
        if (this.hasActionableEmailsConfigured() !== null){
            modifications.push({
                Name: "#Core/configureActionableEmails",
                Key: "HasActionableEmailsConfigured",
                Value: {
                    'HasActionableEmailsConfigured': this.hasActionableEmailsConfigured()
                }
            });
        }

        if (this.remindersToAdd().length > 0) {
            modifications.push({
                Name: "#Core/addRemindersModification",
                Key: "UserRemindersAdd",
                Value: _.map(this.remindersToAdd(), (mod) => {
                    return this.toAddServiceValue(mod);
                })
            });
        }

        if (this.remindersToDelete().length > 0) {
            modifications.push({
                Name: "#Core/deleteRemindersModification",
                Key: "UserRemindersDelete",
                Value: this.remindersToDelete()
            });
        }
    }

    toAddServiceValue(reminderConfig:any) {
        return reminderConfig;
    }

    dispose() {
    }
}

export = function() {
    return new BulkAdminReminders();
};