/// <amd-module name="Core/Medius.Core.Web/Scripts/lib/identifiersResolver"/>
import {createFromValues} from "Core/Medius.Core.Web/Scripts/Models/amount";
import * as simplePresenter from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/simplePresenter";
import * as dateLocalizer from "Core/Medius.Core.Web/Scripts/Medius/components/editors/date/localizer";
import * as localizer from "Core/Medius.Core.Web/Scripts/Medius/components/editors/number/localizer";

export function resolveIdentifiers(identifiers: any[]) {
    return identifiers.map((section) => {
        const values = section.values.map((value: any) => {
                switch(value.type) {
                case "string":
                    return value.value;
                case "amount":
                    return simplePresenter.present(createFromValues(value.value.displayValue, value.value.currencyCode));
                case "date":
                    return dateLocalizer.create("d").toString(value.value);
                case "currencyrate":
                    return localizer.create(value.value.displayResolution, true).toString(value.value.displayValue);
            }
        });
        return {iconCode: section.iconCode, keys: section.keys, values: values};
    });
}
