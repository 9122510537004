/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/AuthorizationGroups/Tabs/components/currencyAutocompleter"/>
import { handleAnyError } from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import * as React from "react";
import { useState, useContext } from "react";
import {  Currency } from "../../authorizationGroup";
import { CompanyContext } from "../../contexts/companyContext";
import { getCurrencies } from "../../service";
import { useEffect } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";

type CurrencyAutocompleterProps = {
    readonly currency: Currency;
    readonly onChange: (newCurrency: Currency) => void;
    disabled?: boolean;
    label: string;
    className: string;
};


export function CurrencyAutocompleter({ currency, onChange, disabled = false, label, className }: CurrencyAutocompleterProps) {
    const companyId = useContext(CompanyContext).companyId;
    const [currencies, setCurrencies] = useState<Currency[]>([]);
    
    useEffect(() => {
        async function load() {
            try {
                const data = await getCurrencies();
                setCurrencies(data ? data.Currencies : []);
            }
            catch (error) {
                handleAnyError(error);
            }
        }      

        load();
       
    }, [companyId]);

    return (
        <DropDownList
            className={className}
            data-testid="currency-autocompleter"
            data={currencies}
            required={true}
            disabled={disabled}
            label={label}
            textField="Code"
            dataItemKey="Id"
            defaultValue={currency}
            value={currency}
            onChange= {e => onChange(e.target.value)}
        />
    );
}