/// <amd-module name="Core/Medius.Core.Web/Scripts/components/invoice/reasonCodes/components/addedReasonCodesList"/>
import * as React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { ListView } from "@progress/kendo-react-listview";
import { LoadingPanel } from "Core/Medius.Core.Web/Scripts/shared/components/loadingPanel";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { AddedReasonCode } from "../types";
import * as reasonCodesService from "../service";
import * as notification from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import { useState } from "react";

type AddedReasonCodeListProps = {
    readonly documentViewId: string;
    readonly documentCompanyId?: number;
    readonly values: AddedReasonCode[];
    readonly isLoading: boolean;
    readonly canAdd: boolean;
    readonly onRemove: (reasonCode: AddedReasonCode) => void;
};

type RemoveReasonCodeButtonProps = {
    documentViewId: string;
    documentCompanyId?: number;
    reasonCode: AddedReasonCode;
    onRemove: (reasonCode: AddedReasonCode) => void;
};

export function AddedReasonCodesList(props: AddedReasonCodeListProps) {
    const {documentCompanyId, values, isLoading, canAdd} = props;

    const empty = translate(
        "#PurchaseToPay/documentReasonCodes/document/addedReasonCodes/empty");

    function AddedReasonCodeItem({dataItem: reasonCode}: {dataItem: AddedReasonCode}) {
        return (
            <div className="added-reason-code__list__item" data-testid="added-reason-codes-item">
                <AddedReasonCodeLine
                    reasonCode={reasonCode} />
                {reasonCode.canRemove && documentCompanyId &&
                <RemoveReasonCodeButton
                    reasonCode={reasonCode}
                    {...props} />}
            </div>
        );
    }

    return isLoading
        ? <LoadingPanel />
        : !canAdd && values.length == 0
            ? <span>{empty}</span>
            : <div data-testid="added-reason-codes">
                <ListView
                data={values}
                item={AddedReasonCodeItem}
                className="added-reason-code__list" />
              </div>;
}

export function AddedReasonCodeLine({reasonCode: {reasonCode, creator}}: {reasonCode: AddedReasonCode}) {
    const addedBy = translate(
        "#PurchaseToPay/documentReasonCodes/document/reasonCodeAddedBy");

    return (
        <>
            <span className="added-reason-code__code">{reasonCode}</span>
            <span>{` ${addedBy} `}</span>
            <span className="added-reason-code__creator">{creator}</span>
        </>
    );
}

export function RemoveReasonCodeButton(props: RemoveReasonCodeButtonProps) {
    const {documentViewId, documentCompanyId, reasonCode, onRemove} = props;
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const notifyError = () => notification.error(translate(
        "#PurchaseToPay/documentReasonCodes/document/removeReasonCode/error"));

    const remove = () => {
        setIsLoading(true);
        return reasonCodesService
            .removeFrom(documentViewId, reasonCode.id, documentCompanyId)
            .then(() => onRemove(reasonCode), notifyError);
    };

    return <Button onClick={remove} icon="delete" fillMode="flat" disabled={isLoading} />;
}
