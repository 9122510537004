///<amd-module name = "Core/Medius.Core.Web/Scripts/Models/Medius.Core.Entities.Workflow.StartTask"/>
import * as ko from "knockout";
import * as path from "Core/Medius.Core.Web/Scripts/Medius/lib/path";
import * as type from "Core/Medius.Core.Web/Scripts/Medius/core/type";
import mappings = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/mapping/collection/instance");
import * as outbox from "Core/Medius.Core.Web/Scripts/components/outbox/outbox";

const startTaskTypeName = "Medius.Core.Entities.Workflow.StartTask";

function StartTaskViewmodel (vm:any, data:any, viewmodelContext:any) {
    vm.disableButtons = ko.observable(false);
    vm.disableRedistribution = ko.observable(false);
    vm.AvailableRedistribution = ko.observableArray();
    vm.ChoosenRedistribution = ko.observableArray();

    vm.active = ko.computed(function () {
        return false;
    });

    vm.handleRedistribution = function() {
    };

    vm.handleTaskHandler = function () {

    };

    vm.allowSaveDocument = ko.computed(function () {
        return false;
    });

    vm.saveTaskDocument = function () {

    };

    vm.typeName = ko.computed(function () {
        return type.getTypeName(vm.$type());
    });

    vm.actionsTemplate = "Core:templates/Task/HandleStartTask.html";

    vm.messagesTemplate = "Core:components/taskMessages/messages.html";

    vm.dashboardContextLink = ko.computed(function () {
        const link = ["#/Dashboard", "Task", vm.Id()].join("/");
        return path.getBasePath() + link;
    });

    vm.CurrentState = ko.computed(function () {
        return "Complete";
    });

    vm.disableRedistribution(true);

    vm.disposeContext = viewmodelContext.dispose.bind(viewmodelContext);
    vm.toDto = viewmodelContext.toJS.bind(viewmodelContext, vm);

    vm.errors = ko.observableArray(outbox.getOutboxInstance().getErrorsForTask(vm.Id()));
    vm.errorsVisible = ko.observable(true);
    vm.shouldRecalculate = ko.observable(true).extend({ notify: 'always'});

    vm.hideErrors = function() {
    };
}

export function register() {
    mappings.register(startTaskTypeName, {
        construct: StartTaskViewmodel
    });
}