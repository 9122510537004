/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/spaManager/manager" />
import { applyBindings, observable } from "knockout";
import * as _ from "underscore";
import * as Backbone from "backbone";
import * as window from "window";
import * as eventBus from "Core/Medius.Core.Web/Scripts/eventBus";
import * as EventNames from "Core/Medius.Core.Web/Scripts/events/eventNames";
import { setViewContext } from "Core/Medius.Core.Web/Scripts/lib/shortcutManager";
import { getApps } from "Core/Medius.Core.Web/Scripts/Medius/apps/spaManager/applicationsRegister";
import { setRouter } from "Core/Medius.Core.Web/Scripts/Medius/apps/spaManager/routerProvider";
import { UIHandler } from "Core/Medius.Core.Web/Scripts/Medius/apps/spaManager/uiHandler";
import * as featureToggle from "Core/Medius.Core.Web/Scripts/Medius/core/featureToggle";
import * as router from "Core/Medius.Core.Web/Scripts/Medius/apps/spaManager/router";

function generateAppContainerId() {
    return _.uniqueId("app");
}

function getBasePath() {
    return (window.location.pathname + "/").replace("//", "/");
}

class AppManager {
    public isVisible = observable(true);

    private isTransitionInProgress = false;
    private Apps: any;
    private ActiveApp: any = null;
    private DefaultRoute: any;
    private UIHandler: any;
    private router: any;

    constructor(containerId: any, appsContainerId: any, apps: any, defaultRoute: any) {
        this.Apps = this.initApps(apps, appsContainerId);
        this.DefaultRoute = defaultRoute || [];
        this.UIHandler = new UIHandler(this, containerId, appsContainerId);
        this.router = router.create(this);
        setRouter(this.router);
    }

    public initApps(appModules: any[], appsContainerId: string) {
        const apps: any[] = [];
        const $appsContainer = $("#" + appsContainerId);

        appModules.forEach((appModule, i) => {
            const appId = generateAppContainerId();
            const view = '<article id="' + appId + '" data-bind="withSpaApp: Apps[' + i + ']"></article>';
            $appsContainer
                .append(view)
                .children("#" + appId)
                .append(appModule.getView());

            const app = appModule.create(this, appId);

            apps.push(app);
        });

        return apps;
    }

    public start() {
        this.UIHandler.init();

        const routingResult = Backbone.history.start({
            root: getBasePath()
        });

        Backbone.history.on("all", function (route: any, router: any) {
            eventBus.trigger(EventNames.urlHashChanged);
        });

        if (!routingResult && this.DefaultRoute.length > 0) {
            this.router.navigateTo(this.DefaultRoute);
        }
    }

    public findApp(appToOpen: any) {
        return this.Apps.find((app: any) => app === appToOpen);
    }

    public openApp(appToOpen: any, invokeInitialize = true) {
        appToOpen = this.findApp(appToOpen);

        if (!appToOpen) {
            return false;
        }

        if (appToOpen.initialize && invokeInitialize === true) {
            appToOpen.initialize();
        }

        this.UIHandler.openApp(appToOpen);
        this.ActiveApp = appToOpen;
        setViewContext(appToOpen.name);
    }

    public switchApp(appToOpen: any) {
        if (!this.ActiveApp) {
            this.openApp(appToOpen);
        }

        // app already opened
        if (this.ActiveApp === appToOpen) {
            return false;
        }

        const app = this.findApp(appToOpen);

        if (!app) {
            return false;
        }

        this.isTransitionInProgress = true;
        const transition = this.UIHandler.makeTransition(this.ActiveApp, app);
        $.when(transition).always(() => {
            this.ActiveApp = app;
            this.isTransitionInProgress = false;
            setViewContext(appToOpen.name);
        });

        return transition;
    }
}

export function create(containerId: any, appsContainerId: any) {
    const defaultRoute = featureToggle.isHomePageEnabled()
        ? ["Home"] : ["Tasks"];

    return new AppManager(containerId, appsContainerId, getApps(), defaultRoute);
}

export function init(containerId: any, appsContainerId: any) {
    const appManager = create(containerId, appsContainerId);
    applyBindings(appManager, document.getElementById(containerId));
    appManager.start();
}
