/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/components/utils" />
import * as componentUtils from "Core/Medius.Core.Web/Scripts/Medius/components/utils";
import * as _ from "underscore";

export function emitBinding(bindingCondifuration: any) {
    return componentUtils.emitBinding("component", bindingCondifuration);
}

export function getConfiguration(name: any, params: any) {
    const configuration = _({
        factory: "Core/Medius.Core.Web/Scripts/Medius/components/components/default"
    }).extend(params);
    return componentUtils.getConfiguration(name, configuration);
}