///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/parser/query"/>
import * as iterator from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/lexeme/iterator";
import * as condParser from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/parser/condition";
import * as typesProvider from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/provider/types";
import * as operatorsProvider from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/provider/operators";
import * as translationsProvider from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/provider/translations";
import * as lexemeParser from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/parser/lexeme";
import * as _ from "underscore";

export function getQueryForAllEntities() {
    return { "Operator": ">", "Property": "Id", "PropertyType": "System.Int32", "Values": ["0"] };
}

export function parse(entityType:string, query:string) {
    const lexemes = lexemeParser.process(query);
    return parseDocumentQuery(entityType, lexemes);
}

export function parseDocumentQuery(entityType:string, lexemes:string[]) {
    const fullTypeName = typesProvider.getFullTypeName(entityType);
    
    if (!fullTypeName) {
        return {};
    }
    
    const resultObject: {EntityType:string, Root:any, OrderBy:string, Order:"DESC"|"ASC"} = {
        EntityType: fullTypeName,
        Root: null,
        OrderBy: null,
        Order: null
    };
   
    if (lexemes.length === 0) {
        resultObject.Root = getQueryForAllEntities();
        return resultObject;
    }
    const orderByOperator = operatorsProvider.getOrderByOperator();
    const orderByIndex = _.indexOf(lexemes, orderByOperator);
    
    if (orderByIndex === -1) {
        resultObject.Root = parseConditions(entityType, lexemes);
        return resultObject;
    }
    
    const orderByLexemes = _.rest(lexemes, orderByIndex+1);
    const conditionsLexemes = _.first(lexemes, orderByIndex);
        
    resultObject.Root = orderByIndex === 0 ?
        getQueryForAllEntities() :
        parseConditions(entityType, conditionsLexemes);
    resultObject.OrderBy = translationsProvider.resolvePropertyFromTranslation(fullTypeName, orderByLexemes[0]);
    resultObject.Order = translationsProvider.resolveSortingOrderFromTranslation(orderByLexemes[1]);
    
    return resultObject;
}

export function parseConditions(entityType:string, lexemes:string[]) {
    const lexemeIterator = iterator.create(lexemes);
    const conditionParser = condParser.create(entityType, lexemeIterator);
    
    return conditionParser.parse();
}