/// <amd-module name="Core/Medius.Core.Web/Scripts/components/mention/editor/userMentionSuggestionsProvider"/>
// The same file exists in mobile app: mfxi\mediusflow\frontend\web\mobile\src\core\components\comments\usermentionsuggestionsprovider.ts
// Consider changing the other one while doing changes in this one.

import { ISuggestion, ISuggestionsProvider } from "Core/Medius.Core.Web/Scripts/components/mention/editor/IEditorOptions";
import { defaults } from "Core/Medius.Core.Web/Scripts/components/mention/editor/defaults";
import * as rest from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rest";

export class UserMentionSuggestionsProvider implements ISuggestionsProvider {
    private readonly documentId: number;
    private currentRequest: any;

    public constructor(documentId: number) {
        this.documentId = documentId;
    }

    public fetchSuggestions(searchTerm: string): JQueryPromise<Array<ISuggestion>> {
        const limit = defaults.autocompleterLimit;
        return this.fetchSuggestionsPart(searchTerm, 0, limit);
    }

    public fetchSuggestionsPart(searchTerm: string, offset: number, limit: number): JQueryPromise<Array<ISuggestion>> {

        this.abortRequest();
        this.currentRequest = rest.get("MentionSuggestions", "",
        {
            searchTerm: searchTerm,
            documentId: this.documentId,
            offset: offset,
            limit: limit
        });

        this.currentRequest.always((): any => this.currentRequest = null);

        return this.currentRequest.promise();
    }

    public abortRequest() {
        if (this.currentRequest)
            this.currentRequest.reject({ aborted: true });
    }
}
