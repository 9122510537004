///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/components/copyRequisition/validationResult/validationResultsModal" />
import * as React from "react";
import { ModalDialog, Button, AsyncButton } from "@medius/ui-controls";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { ValidationResultsModalContent } from "Core/Medius.Core.Web/Scripts/Medius/components/components/copyRequisition/validationResult/validationResultsModalContent";
import { CopyRequisitionLineDetailsDto } from "Core/Medius.Core.Web/Scripts/Medius/components/components/copyRequisition/DTOs/copyRequisitionValidationResultDto";

type ValidationResultsModalProps = {
    isModalVisible: boolean,
    setIsModalVisible: (isModalVisible: boolean) => void,
    lineValidationResults: CopyRequisitionLineDetailsDto[],
    copyRequisitionToBasket: () => void,
    countOfCartLines: number,
    headerError: string
};

export const ValidationResultsModal = ({ isModalVisible, setIsModalVisible, lineValidationResults, copyRequisitionToBasket, countOfCartLines, headerError }: ValidationResultsModalProps) => {

    const isContinueButtonVisible = lineValidationResults?.filter(r => r.validationErrorMessages.length == 0).length > 0;

    return (
        <ModalDialog
            title={translate("#Procurement/copyRequisition")}
            isOpen={isModalVisible}
            width={"medium"}
            onRequestClose={() => setIsModalVisible(false)}
            renderContent={() => {
                return (
                    <ValidationResultsModalContent
                        lineValidationResults={lineValidationResults}
                        countOfCartLines={countOfCartLines}
                        headerError={headerError}
                    />
                );
            }}
            renderActions={() => {
                return (
                    <>
                        <Button
                            label={translate("#Procurement/cancel")}
                            onClick={() => setIsModalVisible(false)}
                            variant="inline" />
                        {isContinueButtonVisible &&
                            <AsyncButton
                                label={translate("#Procurement/continue")}
                                onClick={async () => { copyRequisitionToBasket(); }}
                                variant="primary"
                                data-testid="validation-results-continue-button"
                            />
                        }
                    </>);
            }}
        />
    );
};
