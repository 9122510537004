/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/default/collectionBasedDataProvider"/>
export class CollectionBasedDataProvider<T>{
    private collection: T[];
    private readonly displayValue: (item: T) => string;

    public static createFromCollection<TItem>(collection: TItem[], displayValue: (item: TItem) => string) {
        return new CollectionBasedDataProvider(collection, displayValue);
    }

    public static createFromRequest<TItem>(promise: JQueryPromise<TItem[]>, displayValue: (item: TItem) => string) {
        const dataProvider = new CollectionBasedDataProvider([], displayValue);
        promise.done(data => dataProvider.setCollection(data));
        return dataProvider;
    }

    private constructor(collection: T[], displayValue: (item: T) => string) {
        this.displayValue = displayValue;
        this.setCollection(collection);
    }

    public search(phrase: string): JQueryDeferred<any> {
        const regex = new RegExp(phrase.replace("*", "(.*)").toLowerCase());
        const results = this.collection.filter((item: T) => regex.test(this.displayValue(item).toLowerCase()));
        return $.Deferred().resolve(results);
    }

    public exact(phrase: string) {
        return this.search(phrase);
    }

    public createDummy(): any {
        return null;
    }

    private setCollection(collection: T[]) {
        this.collection = collection.sort((item1, item2) => this.displayValue(item1) > this.displayValue(item2) ? 1 : -1);
    }
}
