///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/presenters/financeComponent/model"/>
import * as _ from "underscore";
import * as ko from "knockout";
import * as basic from "Core/Medius.Core.Web/Scripts/Medius/components/presenters/basic";
import * as helpers from "Core/Medius.Core.Web/Scripts/Medius/components/editors/helpers";
import * as localizer from "Core/Medius.Core.Web/Scripts/Medius/components/editors/financeComponent/localizer";
import * as koDisposal from "Core/Medius.Core.Web/Scripts/Medius/knockout/disposal";
import * as koSync from "Core/Medius.Core.Web/Scripts/Medius/knockout/observables/synchronization";
import * as financeComponentDefaults from "Core/Medius.Core.Web/Scripts/Medius/components/presenters/financeComponent/defaults";
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";

export class FinanceComponent {
    ShowCurrency: boolean;
    CurrencyCode: ko.Observable<string>;
    updateResolution: () => void;
    create: () => void;
    dispose: () => void;

    constructor(bindingParameters: any) {
        
        let displayValueSync: { dispose: any };
        let currencyCodeSync: { dispose: any };
        const displayValue = ko.observable();
        let valueSubscription: any;
        let currencyCodeSub: any;
        let financialLocalizer: any;
        let displayResolutionSync: any;

        const synchronize = (dto: any) => {
            koDisposal.tryDispose(displayValueSync);
            koDisposal.tryDispose(currencyCodeSync);

            displayValueSync = koSync.oneWay(dto.DisplayValue, displayValue);
            currencyCodeSync = koSync.oneWay(dto.CurrencyCode, this.CurrencyCode);

            currencyCodeSub = dto && dto.CurrencyCode.subscribe(() => {
                this.updateResolution();
            });
        };

        const defaults = helpers.mergeDefaults(bindingParameters, financeComponentDefaults.get());
        defaults.resolution = ko.isObservable(defaults.resolution) ? defaults.resolution : ko.observable(defaults.resolution);

        this.ShowCurrency = defaults.showCurrency;
        this.CurrencyCode = ko.observable();

        this.updateResolution = () => {
            const currencyCode = koUtils.unpack(this.CurrencyCode);
            const resolution = this.getResolution(currencyCode);
            defaults.resolution(resolution);
        };

        if (!isNullOrUndefined(koUtils.unpack(defaults.value))) {
            if (ko.isObservable(defaults.value)) {
                synchronize(defaults.value());
                valueSubscription = defaults.value.subscribe(synchronize);
            }
            else {
                displayValue(koUtils.unpack(defaults.value.DisplayValue));
                this.CurrencyCode(koUtils.unpack(defaults.value.CurrencyCode));
                this.updateResolution();
            }
        }
        else {
            displayValue(null);
            this.CurrencyCode(null);
        }

        defaults.value = displayValue;

        this.create = () => {

            this.updateResolution();

            financialLocalizer = ko.computed(() => {
                const resolution = koUtils.unpack(defaults.resolution);
                return localizer.create(
                    resolution,
                    defaults.trimZeros,
                    defaults.roundDecimal,
                    defaults.notLocalized
                );
            });

            _(this).extend(basic.create(defaults, financialLocalizer));

            this.dispose = () => {
                if (financialLocalizer) {
                    financialLocalizer.dispose();
                    financialLocalizer = null;
                }

                if (valueSubscription) {
                    valueSubscription.dispose();
                    valueSubscription = null;
                }

                if (currencyCodeSub) {
                    currencyCodeSub.dispose();
                    currencyCodeSub = null;
                }

                koDisposal.tryDispose(displayResolutionSync);
                koDisposal.tryDispose(currencyCodeSync);
                koDisposal.tryDispose(displayValueSync);

                basic.derive.prototype.dispose.call(self);
            };

            return this;
        };
    }

    getResolution(currencyCode:any) {
        throw new Error("Function getResolution is not implemented in " + this.constructor.name);
    }
}