/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/inbox/task/labelsHandlerFactory"/>
import * as rest from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rest";
import * as backendErrorHandler from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import * as labelsHandler from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/task/labelsHandler";
import * as labelsRepository from "Core/Medius.Core.Web/Scripts/Medius/core/labels/labelsRepository";

export function create(documentId: number) {
    return rest.get(`labels/document/${documentId}`).then(
        labelsAssignedToDocument => createWithAssignedLabelsProvided(documentId, labelsAssignedToDocument, true), error => {
            backendErrorHandler.handleAnyError(error);
        });
}

export function createWithAssignedLabelsProvided(documentId: number, assignedLabelsIds: number[], isInFullScreen = false) {
    const assignedLabelsObservableArray = labelsRepository.getLabelsByIds(assignedLabelsIds);
    const handler = labelsHandler.create(documentId, assignedLabelsObservableArray, isInFullScreen);
    return handler;
}
