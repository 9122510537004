/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/kendo/bindings/kendoMultiSelect"/>

import { KendoUtils } from "Core/Medius.Core.Web/Scripts/Medius/kendo/kendoUtils";
import * as ko from "knockout";
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";

class KendoMultiSelect {
    public static registerKnockoutBindings(): void {
        koUtils.registerBinding("kendoMultiSelect", {
            init(element: HTMLElement, valueAccessor: any) {
                const $element = $(element);
                const required = valueAccessor().required as boolean;
                const maxSelectedItems = valueAccessor().maxSelectedItems as number;
                const placeholder = valueAccessor().placeholder as string;
                const bindingParams = valueAccessor().options as MultiSelectParams;

                bindingParams.validate = () => KendoMultiSelect.validate($element, required, bindingParams);
                bindingParams.clear = () => KendoMultiSelect.clear($element, required, bindingParams);

                const dataSourceOptions: kendo.data.DataSourceOptions = {
                    transport: KendoMultiSelect.createTransport(bindingParams),
                    pageSize: 24,
                    serverPaging: true,
                    serverFiltering: true
                };

                const kendoOptions: kendo.ui.MultiSelectOptions = {
                    dataSource: kendo.data.DataSource.create(dataSourceOptions),
                    dataTextField: "displayValue",
                    dataValueField: "value",
                    height: 300,
                    select: KendoMultiSelect.createSelectEvent(bindingParams),
                    deselect: KendoMultiSelect.createDeselectEvent(bindingParams),
                    change: () => KendoMultiSelect.validate($element, required, bindingParams),
                    maxSelectedItems: maxSelectedItems,
                    placeholder: getLabelTranslation(placeholder),
                };

                if (bindingParams.open)
                    kendoOptions.open = bindingParams.open;

                if (bindingParams.itemTemplateId)
                    kendoOptions.itemTemplate = KendoUtils.loadTemplate(bindingParams.itemTemplateId);

                KendoMultiSelect.createMultiSelect($element, kendoOptions);

                KendoMultiSelect.disableOpenOnScrollClick($element);

                $element.find("input").first().prop("autofocus", true);

                koUtils.addDisposeCallback(element, () => {
                    KendoUtils.destroyComponent($element, "kendoMultiSelect");
                });
            },

            update(element: HTMLElement, valueAccessor: any) {
                const $element = $(element);
                const enable = ko.unwrap(valueAccessor().enable) !== undefined ? ko.unwrap(valueAccessor().enable) : true as boolean;
                const values = (valueAccessor().options as MultiSelectParams).values();

                const kendoMultiSelect = KendoUtils.getComponent($element, "kendoMultiSelect") as kendo.ui.MultiSelect;

                kendoMultiSelect.enable(enable);
                kendoMultiSelect.value(values.map(v => v.value));
            }
        });
    }

    private static createTransport(multiSelectParams: MultiSelectParams): kendo.data.DataSourceTransport {
        return {
            read: (options: kendo.data.DataSourceTransportOptions) => {
                const data = options.data;

                let searchQuery = "";
                if (data.filter && data.filter.filters && data.filter.filters[0]) {
                    searchQuery = data.filter.filters[0].value.trim();
                }

                const request: MultiSelectRequest = {
                    query: searchQuery,
                    page: data.page as number,
                    pageSize: data.pageSize as number,
                    skip: data.skip as number,
                    take: data.take as number
                };

                multiSelectParams.read(request)
                    .then(items => {
                        KendoMultiSelect.appendExistingValueOnEmptyQuery(searchQuery, multiSelectParams, items);
                        options.success(items);
                    })
                    .catch(error => options.error(error));
            }
        };
    }

    private static appendExistingValueOnEmptyQuery(searchQuery: string, multiSelectParams: MultiSelectParams, items: MultiSelectItem[]) {
        if (searchQuery !== "")
            return;

        for (const existingItem of multiSelectParams.values()) {
            if (items.filter(item => item.value === existingItem.value).length === 0) {
                items.push(existingItem);
            }
        }
    }

    private static createSelectEvent(multiSelectParams: MultiSelectParams): (e: kendo.ui.MultiSelectSelectEvent) => void {
        return (e: kendo.ui.MultiSelectSelectEvent) => {
            multiSelectParams.values.push(e.dataItem);
        };
    }

    private static createDeselectEvent(multiSelectParams: MultiSelectParams): (e: kendo.ui.MultiSelectDeselectEvent) => void {
        return (e: kendo.ui.MultiSelectDeselectEvent) => {
            multiSelectParams.values
                .remove((item: any) => item.value === e.dataItem.value);
        };
    }

    private static disableOpenOnScrollClick(element: JQuery): void {
        const multiselect = KendoUtils.getComponent(element, "kendoMultiSelect");
        const multiselectwrap = multiselect.element.closest(".k-widget.k-multiselect").find(".k-multiselect-wrap");

        multiselectwrap.mousedown((e: JQueryEventObject) => {
            e.stopPropagation();
        });

        multiselectwrap.click((e: JQueryEventObject) => {
            if (!$(e.target).hasClass("k-i-close")) {
                multiselect.focus();
                multiselect.open();
            }
        });
    }

    private static clear(element: JQuery, required: boolean, multiSelectParams: MultiSelectParams): void {
        const multiselect = KendoMultiSelect.getMultiselectElement(element);
        multiselect.removeClass("invalid");
    }

    private static validate(element: JQuery, required: boolean, multiSelectParams: MultiSelectParams): boolean {
        if (!required)
            return true;

        const multiselect = KendoMultiSelect.getMultiselectElement(element);
        const items = multiSelectParams.values();
        if (items.length > 0) {
            multiselect.removeClass("invalid");
            return true;
        } else {
            multiselect.addClass("invalid");
            return false;
        }
    }

    private static getMultiselectElement(element: JQuery): JQuery {
        const multiselect = element.children("span.k-multiselect");
        if (multiselect.length === 0)
            throw new Error("Could not find Kendo multiselect.");
        return multiselect;
    }

    private static createMultiSelect($element: JQuery, kendoOptions: kendo.ui.MultiSelectOptions): void {
        KendoUtils.destroyComponent($element, "kendoMultiSelect");
        KendoUtils.createComponent($element, "kendoMultiSelect", kendoOptions);
    }
}

KendoMultiSelect.registerKnockoutBindings();
export = null;
