///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/task/RiskFactor/RiskDescriptions/BankAccountValidationMasterData"/>
import * as React from 'react';
import { AdditionalProperties, IHandledInfo, RiskFactorCode, RiskFactorReasonCode } from 'Core/Medius.Core.Web/Scripts/Medius/apps/task/RiskFactor/RiskFactorComponentDtos/riskFactorsForInvoiceDto';
import { getLabelTranslation } from 'Core/Medius.Core.Web/Scripts/lib/globalization';
import { DropDown, ListItem } from '@medius/ui-controls';
import { getRiskFactorNameTranslation } from 'Core/Medius.Core.Web/Scripts/Medius/apps/task/RiskFactor/riskFactorUtils';
import { isNullOrUndefined } from 'Core/Medius.Core.Web/Scripts/lib/underscoreHelpers';
import { DatePresenter } from 'Core/Medius.Core.Web/Scripts/shared/components/datePresenter';
import { sendOneHandlingReasonForTask } from 'Core/Medius.Core.Web/Scripts/Medius/apps/task/RiskFactor/riskFactorIconComponentServices';

export interface IBankAccountValidationMasterDataProps {
    isRiskHandled: boolean;
    additionalProperties: AdditionalProperties;
    canEdit: boolean;
    handledInfo: IHandledInfo;
    currentReasonCode?: RiskFactorReasonCode;
    taskId: number;
}

export const BankAccountValidationMasterData = ({
    isRiskHandled,
    additionalProperties,
    canEdit,
    handledInfo,
    currentReasonCode,
    taskId
}: IBankAccountValidationMasterDataProps) => {
    const dropdownItems: ListItem[] = [
        { text: getLabelTranslation("#Core/handlingCodeNoReason"), value: RiskFactorReasonCode.NoReason }, 
        { text: getLabelTranslation("#Core/handlingCodeMasterDataNotUpdated"), value: RiskFactorReasonCode.MasterDataNotUpdated }, 
        { text: getLabelTranslation("#Core/handlingCodeMasterDataUpdated"), value: RiskFactorReasonCode.MasterDataUpdated },
        { text: getLabelTranslation("#Core/handlingCodeMasterDataUpdatedAndConfirmed"), value: RiskFactorReasonCode.MasterDataUpdatedAndConfirmed }
    ];

    const [selectedReasonCode, setSelectedReasonCode] = React.useState<ListItem>(
        dropdownItems.filter(el => el.value === currentReasonCode)[0] || dropdownItems[0]
    );

    //This code is necessary due to a bug inside Northstar, can probably be removed when NS is upgraded to version 1.3.0
    //After removal remember to check if MFD-36854 bug does not reproduce
    const [dropdownItem, setDropdownItem] = React.useState<ListItem>(
        {value: selectedReasonCode.value, text:selectedReasonCode.text}
    );

    const allFieldsHiddenOrEmpty = !isNullOrUndefined(
        additionalProperties.allFieldsHiddenOrEmpty) && 
        JSON.parse(additionalProperties.allFieldsHiddenOrEmpty
    );

    const handleReasonCodeSelection = (dropdownEvent: any) => {
        setSelectedReasonCode(dropdownEvent.value);
        sendOneHandlingReasonForTask(taskId, { 
            handlingReason: dropdownEvent.value.value, 
            riskFactorCode: RiskFactorCode.BankAccountValidationMasterData
        });
    };

    React.useEffect(() => {
        setDropdownItem({value: selectedReasonCode.value, text:selectedReasonCode.text});
    }, [selectedReasonCode]);

    return (
        <>
            <h4>{getRiskFactorNameTranslation(RiskFactorCode.BankAccountValidationMasterData)}</h4>
            {
                isRiskHandled ? <>
                    <div className="risk-factor-icon-container__handlerDetails">
                        <p style={{margin: "0px"}}>{getLabelTranslation('#Core/handledBy')}</p>
                        <p>{handledInfo.handledByUser.firstName}</p>
                        <p style={{marginRight: "5px"}}>{handledInfo.handledByUser.lastName}</p>
                        <DatePresenter 
                            date={new Date(handledInfo.handledAt)} 
                            format="G"
                        />
                    </div>
                    <p>{resolveReasonCodeTranslation(currentReasonCode)}</p>
                </> : <>
                    { allFieldsHiddenOrEmpty ? <p>{getLabelTranslation("#Core/noBankAccountValuesToMatchTheMasterData")}</p> : <>
                        <div>
                            <p>{getLabelTranslation("#Core/bankAccountValidationInfoText")}</p>
                            <p>{getLabelTranslation("#Core/currentValues")}:</p>
                            {additionalProperties.newBankGiroValue && <p className="risk-factor-icon-container__numberLabel">{additionalProperties.newBankGiroValue}</p>}
                            {additionalProperties.newPlusGiroValue && <p className="risk-factor-icon-container__numberLabel">{additionalProperties.newPlusGiroValue}</p>}
                            {additionalProperties.newBbanValue && <p className="risk-factor-icon-container__numberLabel">{additionalProperties.newBbanValue}</p>}
                            {additionalProperties.newIbanValue && <p className="risk-factor-icon-container__numberLabel">{additionalProperties.newIbanValue}</p>}
                        </div>
                    </> }
                    { canEdit && <>
                        <p>{getLabelTranslation("#Core/setRiskAsHandledSelectOption")}</p>
                        <DropDown 
                            data-testid={"risk-input-BankAccountValidationMasterData"}
                            allItems={dropdownItems} 
                            value={dropdownItem}
                            onChange={handleReasonCodeSelection}
                        />
                    </> }
                </>
            }                    
        </>
    );
};

function resolveReasonCodeTranslation(handlingReasonCode: RiskFactorReasonCode){
    switch (handlingReasonCode) {
        case RiskFactorReasonCode.MasterDataUpdatedAndConfirmed:
            return getLabelTranslation("#Core/handlingCodeMasterDataUpdatedAndConfirmed");
        case RiskFactorReasonCode.MasterDataNotUpdated:
            return getLabelTranslation("#Core/handlingCodeMasterDataNotUpdated");
        case RiskFactorReasonCode.MasterDataUpdated:
            return getLabelTranslation("#Core/handlingCodeMasterDataUpdated");
        case RiskFactorReasonCode.InvoiceArchived:
            return getLabelTranslation("#Core/handlingReasonCodeInvoiceArchived");
        case RiskFactorReasonCode.InvoiceDeletedDuplicateInvoice:
            return getLabelTranslation("#Core/handlingCodeInvoiceDeletedDuplicateInvoice");
        case RiskFactorReasonCode.InvoiceDeletedFraudAttempt:
            return getLabelTranslation("#Core/handlingCodeInvoiceDeletedFraudAttempt");
        case RiskFactorReasonCode.InvoiceDeletedOther:
            return getLabelTranslation("#Core/handlingCodeInvoiceDeletedOther");
        case RiskFactorReasonCode.SupplierChanged:
            return getLabelTranslation("#Core/handlingReasonCodeSupplierChanged");
        case RiskFactorReasonCode.ValueChanged:
            return getLabelTranslation("#Core/handlingReasonCodeValueChanged");
        default:
            console.warn(`[BankAccountValidationMasterData] handling reason code ${handlingReasonCode} was not implemented for this risk factor`);
            break;
    }
}