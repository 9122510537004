/// <amd-module name="Core/Medius.Core.Web/Scripts/components/taskHandling/shared/buttons/invalidateAndEditButton"/>
import * as ko from "knockout";
import * as notification from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { InvalidateAndEditButtonBase, IInvalidateAndEditHandler } from "../buttons/invalidateAndEditButtonBase";

export class InvalidateAndEditButton extends InvalidateAndEditButtonBase {
    private invalidateTaskResult = "Invalidate";
    private invalidateAndEditTaskResult = "InvalidateAndEdit";
    private invalidateAndEditErrorLabelKey = "#Enterprise/InvalidateAndEditTaskResultUnavailable";

    constructor(handler: IInvalidateAndEditHandler, isDisabled: ko.Observable<boolean>, isInvaldiateAndEditHidden: ko.Observable<boolean> = ko.observable(false)) {
        super(handler, isDisabled, isInvaldiateAndEditHidden);
    }

    public invalidateAndEdit = () => {
        const task = this.invalidateAndEditHandler.getTask();

        //comments model taken from Document is new approach, taking it from step is legacy
        const commentsModel = task.Document().CommentsModel ?? task.CommentsModel;

        if (commentsModel.newComment()) {
            commentsModel.addComment();
        }

        const userTaskDto = this.invalidateAndEditHandler.getUserTaskDto();

        if (userTaskDto === undefined) {
            this.reclassifyEndTask(task);
        }
        else {
            const invalidateGroupResults = userTaskDto.AvailableGroupResults
                .find((gr: { Code: string }) => gr.Code === this.invalidateTaskResult);
            if (invalidateGroupResults === undefined) {
                notification.error(globalization.getLabelTranslation(this.invalidateAndEditErrorLabelKey));
                return;
            }
            const invalidateAndEditResultDto = invalidateGroupResults.AvailableCopyResults
                .find((cr: { Code: string }) => cr.Code === this.invalidateAndEditTaskResult);
            if (invalidateAndEditResultDto === undefined) {
                notification.error(globalization.getLabelTranslation(this.invalidateAndEditErrorLabelKey));
                return;
            }
            this.reclassify(task, invalidateAndEditResultDto);
        }

        this.isInvalidateModalVisible(false);
    };
}