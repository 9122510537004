///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/editors/financeComponent/localizer"/>
import * as _ from "underscore";
import * as financialLocalizer from "Core/Medius.Core.Web/Scripts/Medius/components/localizer/type/financial";

class LocalizerAdapter {
    fromString: (value: string) => any;
    toString: (value:any) => string;

    constructor(decimalResolution:any, trimZeros = false, roundDecimal = "midpoint", notLocalized = false) {
        const options = {
            decimalResolution: _.isUndefined(decimalResolution) ? 2 : decimalResolution,
            trimZeros: trimZeros,
            roundDecimal: roundDecimal,
            notLocalized: notLocalized
        };
        this.fromString = function(value:string) {
            return financialLocalizer.fromString(value, options);
        };
        this.toString = function (value:any) {
            return financialLocalizer.toString(value, options);
        };
    }
}

export function create(decimalResolution:any, trimZeros?:any, roundDecimal?:any, notLocalized?:any) {
    return new LocalizerAdapter(decimalResolution, trimZeros, roundDecimal, notLocalized);
}