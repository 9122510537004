/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/currency/select-currency"/>
import * as ko from "knockout";
import CurrencyDataProvider, { CurrencyDto } from "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/currency/dataProvider";
import * as defaults from "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/default/defaults";
import {AutocompleterViewModel} from "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/default/model";

interface IParams {
    value: any;
    options: {
        css: string;
    };
}

interface CurrencyViewModel {
    Id: ko.Observable<number>;
    Code: ko.Observable<string>;
}

function createViewModel(params: IParams) {
    return new AutocompleterViewModel({
        ...defaults.create(),
        dataProvider: new CurrencyDataProvider(params),
        ...params,
        options: {            
            customGetItemTextValue: (x?: CurrencyDto | CurrencyViewModel) => x ? ko.unwrap(x.Code) : "",
            customGetItemDescription: (x?: CurrencyDto | CurrencyViewModel) => x ? ko.unwrap(x.Code) : "",
            useViewModels: false,
            rightAlign: false,
            preselectFirstValue: true,
            ...params.options
        }
    });
}

export function register() {
    ko.components.register("select-currency", {
        viewModel: createViewModel,
        template: { element: "editor-autocompleter-component-template" },
        synchronous: true
    });
}
