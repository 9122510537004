/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/translate"/>
import {unwrap} from "knockout";
import {translate, getFormattedLabelTranslation} from "Core/Medius.Core.Web/Scripts/lib/globalization";
import {registerBinding} from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";

const translateBinding = {
    update(element: HTMLElement, valueAccessor: ko.Observable<string | ko.Observable<string>>) {
        const key = unwrap(valueAccessor());
        element.textContent = translate(key);
    }
};

interface TranslateAttrParams {
    attr: string;
    key: string;
}

const translateAttrBinding = {
    update: (element: HTMLElement, valueAccessor: ko.Observable<TranslateAttrParams | ko.Observable<TranslateAttrParams>>) => {
        const {attr, key} = unwrap(valueAccessor());
        element.setAttribute(attr, translate(key));
    }
};

interface TranslateAttrParamsParams {
    attr: string;
    key: string;
    params: string[];
}

const translateAttrParamsBinding = {
    update: (element: HTMLElement, valueAccessor: ko.Observable<TranslateAttrParamsParams | ko.Observable<TranslateAttrParamsParams>>) => {
        const accessor = unwrap(valueAccessor());
        const translationParams = Array.isArray(accessor.params) ? accessor.params : [accessor.params];
        const translatedKey = getFormattedLabelTranslation(accessor.key, translationParams);

        element.setAttribute(accessor.attr, translatedKey);
    }
};

interface TranslateParamsParams {
    params: string[];
    key: string;
}

const translateParamsBinding = {
    update: (element: HTMLElement, valueAccessor: ko.Observable<TranslateParamsParams | ko.Observable<TranslateParamsParams>>) => {
        const accessor = unwrap(valueAccessor());
        const params = Array.isArray(accessor.params) ? accessor.params : [accessor.params];
        element.textContent = getFormattedLabelTranslation(accessor.key, params);
    }
};

export function register() {
    registerBinding("translate", translateBinding);
    registerBinding("translateAttr", translateAttrBinding);
    registerBinding("translateAttrParams", translateAttrParamsBinding);
    registerBinding("translateParams", translateParamsBinding);
}
