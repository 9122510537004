/// <amd-module name="Core/Medius.Core.Web/Scripts/components/quickSearch/suggestionModelProviders"/>
import { SuggestionDto } from "Core/Medius.Core.Web/Scripts/components/quickSearch/quickSearchDtos";
import Suggestion from "Core/Medius.Core.Web/Scripts/components/quickSearch/suggestion/suggestion";

type SuggestionModelProvider = (suggestionDto: SuggestionDto) => Suggestion;

const modelProviders: { [index: string]: SuggestionModelProvider } = {};

export function register(entityType: string, provider: SuggestionModelProvider): void {
    modelProviders[entityType] = provider;
}

export function get(entityType: string): SuggestionModelProvider {
    return modelProviders[entityType] || null;
}

export function getRegisteredTypes(): string[] {
    return Object.keys(modelProviders);
}
