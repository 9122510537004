/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/clickOutsideElem" />

import * as $ from "jquery";
import * as ko from "knockout";

interface BindingOptions {
    actionOut: (e: JQueryEventObject) => void;
    boundaryElementClass?: string;
    ignoredElementId?: string;
}

interface BoundryChecker {
    contains(element: Element): boolean;
}

function makeBoundary(sourceElement: Element, options: BindingOptions): BoundryChecker {
    function findTop(): Element {
        if (!options.boundaryElementClass)
            return sourceElement;

        return $(sourceElement)
            .closest(`.${options.boundaryElementClass}`)
            .get(0);
    }

    return {
        contains(element: Element) {
            const currentTop = findTop();
            return currentTop === element
                || $.contains(currentTop, element);
        }
    };
}

export function register() {
    ko.bindingHandlers["clickOutsideElem"] = {
        init(element: Element, bindingAccessor: () => BindingOptions | ko.Observable<BindingOptions>): void {
            const options = ko.utils.unwrapObservable(bindingAccessor());
            if (!options.actionOut)
                throw new Error("Options 'actionOut' is required.");

            const boundary = makeBoundary(element, options);
            const ignoredElementId = options.ignoredElementId;


            const handler = (e: JQueryEventObject) => {
                if (boundary.contains(e.target))
                    return;

                if (ignoredElementId && (ignoredElementId === e.target.id || $.contains(document.getElementById(ignoredElementId), e.target)))
                    return;

                options.actionOut(e);
            };

            $("html").on("click", handler);

            ko.utils.domNodeDisposal.addDisposeCallback(
                element,
                () => $("html").off("click", handler));
        }
    };
}
