///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Reminders/remindersDataSource"/>
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as notification from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import * as reminderConfigurationRowWrapper from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Reminders/reminderConfigurationRowWrapper";
import DataSource = require("Core/Medius.Core.Web/Scripts/Medius/components/grid/dataSource/default");

class RemindersDataSource extends DataSource {
    availableReminders: any;
    availableRemindersNamesArray: any;
    availableMessageTemplates: any;
    reminders: any;
    rows: any[];
    grid: any;
    constructor(reminders: any, availableReminders: any, availableMessageTemplates: any, private hideSummary: boolean, private hideWhen: boolean, private reminderConfigurationHandler: any) {
        super();
        this.availableReminders = availableReminders;
        this.availableRemindersNamesArray = availableReminders.getAvailableRemindersNamesArray();
        this.availableMessageTemplates = availableMessageTemplates;

        this.reminders = reminders;

        this.rows = null;
    }

    loadColumns() {
        const columns = [
            {
                Sortable: true,
                Searchable: false,
                Resizable: true,
                Stretch: true,
                Name: getLabelTranslation("#Core/typeOfReminderLabel"),
                ValueSource: "ReminderTypeName",
                Width: 100
            },
            {
                Sortable: true,
                Searchable: false,
                Resizable: true,
                Stretch: true,
                Name: getLabelTranslation("#Core/documentTypeHeader"),
                ValueSource: "DocumentTypeName",
                Width: 100
            },
            {
                Sortable: false,
                Searchable: false,
                Resizable: true,
                Stretch: false,
                Visible: !this.hideSummary,
                Name: getLabelTranslation("#Core/sendInSummaryHeader"),
                ValueSource: "SendSummary",
                Width: 150
            },
            {
                Sortable: false,
                Searchable: false,
                Resizable: false,
                Stretch: false,
                Name: getLabelTranslation("#Core/enabledHeader"),
                ValueSource: "Enabled",
                Width: 50
            },
            {
                Sortable: false,
                Searchable: false,
                Resizable: false,
                Stretch: false,
                Name: getLabelTranslation("#Core/reminderJob"),
                Visible: !this.hideWhen,
                ValueSource: "",
                Key: "When",
                Width: 90
            },
            {
                Sortable: false,
                Searchable: false,
                Resizable: true,
                Stretch: true,
                Name: getLabelTranslation("#Core/detailsHeader"),
                ValueSource: "Details",
                Width: 70
            },
            {
                Sortable: false,
                Searchable: false,
                Resizable: true,
                Stretch: true,
                Name: getLabelTranslation("#Core/messageTemplateHeader"),
                ValueSource: "Template",
                Width: 100
            },
            {
                Sortable: false,
                Searchable: false,
                Resizable: false,
                Stretch: false,
                Width: 90,
                Name: "",
                ValueSource: ""
            }
        ];

        return $.Deferred().resolve(columns);
    }

    getValidRows(reminderConfiguration: any) {
        const availableDocuments = this.availableReminders.getCompatibleDocumentTypes(reminderConfiguration.ReminderType());
        return reminderConfiguration.Rows().filter((row: any) => {
            return row.justAdded === true || availableDocuments.some((doc: any) => {
                return row.EntityFullTypeName() === doc.Value;
            });
        });
    }

    load(dataQuery: any) {
        let rows = this.reminders().map((reminderConfiguration: any) => {
            const rowsForCompatibleDocuments = this.getValidRows(reminderConfiguration);

            return rowsForCompatibleDocuments.map((reminderConfigurationRow: any) => {
                return reminderConfigurationRowWrapper.create(this, reminderConfiguration, reminderConfigurationRow);
            });
        }).flat(Infinity);

        rows = super.sort(dataQuery, rows);
        this.rows = rows;
        rows = super.page(dataQuery, rows);

        return $.Deferred().resolve(rows);
    }

    getTotalRows(): JQueryDeferred<number> {
        const total = this.reminders()
            .map((reminderConfiguration: any) => this.getValidRows(reminderConfiguration).length)
            .reduce((sum: number, value: number) => sum + value, 0);

        return $.Deferred<number>().resolve(total);
    }

    getFirstRowReminderConfiguration() {
        const firstRow = this.rows[0] || this.reminders()[0];

        if (!firstRow) {
            return null;
        }

        const firstRowReminderType = firstRow.ReminderType();

        return this.reminders().find((reminderConfiguration: any) => {
            return reminderConfiguration.ReminderType() === firstRowReminderType;
        });
    }

    getFirstRowDocumentType() {
        const firstRow = this.rows[0];

        if (!firstRow) {
            return null;
        }

        return firstRow.EntityFullTypeName();
    }

    validateRow(row: any) {
        if (!this.reminderConfigurationHandler.isUnique(row)) {
            notification.error(`${getLabelTranslation("#Core/reminderAlreadyExists")} (${row.ReminderTypeName()}, ${row.DocumentTypeName()}).`);
            return false;
        }

        return true;
    }

    destroy() {
        this.rows.forEach((row: any) => {
            if (row.disposeWrapper) {
                row.disposeWrapper();
            }
        });

        this.rows = null;
        this.availableMessageTemplates = null;
        this.availableReminders = null;
        this.reminders = null;
        this.grid = null;
        super.destroy();
    }
}

export function create(reminders: any, availableReminders: any, availableMessageTemplates: any, hideSummary: boolean, hideWhen: boolean, reminderConfigurationHandler: any) {
    return new RemindersDataSource(reminders, availableReminders, availableMessageTemplates, hideSummary, hideWhen, reminderConfigurationHandler);
}