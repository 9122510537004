/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/transformation"/>
import * as ko from "knockout";

function tryUnwrap(o:any):any {
    let accessor = o;
    let data = o;

    if (!ko.isObservable(data)) {
        accessor = null;
    } else {
        data = ko.utils.unwrapObservable(accessor);
    }

    return {
        accessor: accessor,
        data: data
    };
}

export = function objectTransformation(tree:any, transformerFactory:any, options:any) {
    options = options || {};

    const rootname = options.rootname || null;
    const context = options.context;
    const treeData = tryUnwrap(tree);

    function continueTransformation(currentPath:any, initialData:any, parent:any) {
        let k,
            currentData:any,
            currentAccessor:any,
            currentPropertyValue:any,
            currentPropertyPath,
            descend = true,
            descendProperty,
            replaceProperty,
            unwrap,
            transformer:any = {};

        if (transformerFactory) {
            transformer = transformerFactory(context);
        }

        unwrap = tryUnwrap(initialData);

        currentData = unwrap.data;
        currentAccessor = unwrap.accessor;

        function stopDescend() {
            descend = false;
        }

        function stopPropertyDescend() {
            descendProperty = false;
        }

        function stopPropertyReplace() {
            replaceProperty = false;
        }

        if (transformer.topDown) {
            transformer.topDown({
                set data(v) {
                    currentData = v;
                    currentAccessor = null;
                },
                get data() {
                    return currentData;
                },
                set accessor(v) {
                    const unwrapped = tryUnwrap(v);
                    currentAccessor = unwrapped.accessor;
                    currentData = unwrapped.data;
                },
                get accessor() {
                    return currentAccessor;
                },
                path: currentPath,
                parent: parent,
                context: context,
                stopDescend: stopDescend
            });
        }

        if (descend && typeof currentData === "object") {
            for (k in currentData) {
                if (currentData.hasOwnProperty(k)) {
                    descendProperty = true;
                    replaceProperty = true;
                    currentPropertyValue = currentData[k];
                    currentPropertyPath = [currentPath, k].join(".");

                    if (transformer.beforeDescend) {
                        transformer.beforeDescend({
                            propertyName: k,
                            set propertyValue(v) {
                                currentPropertyValue = v;
                            },
                            get propertyValue() {
                                return currentPropertyValue;
                            },
                            propertyPath: currentPropertyPath,
                            get data() {
                                return currentData;
                            },
                            path: currentPath,
                            context: context,
                            stopPropertyDescend: stopPropertyDescend,
                            stopPropertyReplace: stopPropertyReplace
                        });
                    }

                    if (descendProperty) {
                        currentPropertyValue = continueTransformation(currentPropertyPath, currentPropertyValue, currentData);
                    }

                    if (descendProperty && transformer.afterDescend) {
                        transformer.afterDescend({
                            propertyName: k,
                            set propertyValue(v) {
                                currentPropertyValue = v;
                            },
                            get propertyValue() {
                                return currentPropertyValue;
                            },
                            propertyPath: currentPropertyPath,
                            get data() {
                                return currentData;
                            },
                            path: currentPath,
                            context: context,
                            stopPropertyReplace: stopPropertyReplace
                        });
                    }
                }
            }
        }

        if (transformer.bottomUp) {
            transformer.bottomUp({
                set data(v) {
                    currentData = v;
                    currentAccessor = null;
                },
                get data() {
                    return currentData;
                },
                set accessor(v) {
                    const unwrapped = tryUnwrap(v);
                    currentAccessor = unwrapped.accessor;
                    currentData = unwrapped.data;
                },
                get accessor() {
                    return currentAccessor;
                },
                path: currentPath,
                parent: parent,
                context: context
            });
        }

        return currentAccessor || currentData;
    }

    const result = continueTransformation(rootname, treeData.data, null);

    return result;
}