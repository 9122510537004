/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/communication/platform"/>
import { addToHeaders } from "Core/Medius.Core.Web/Scripts/Medius/core/antiForgeryToken";
import { isNotNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import { validateVersionFromHeaderFetch } from "Core/Medius.Core.Web/Scripts/lib/validation/validators/versionValidator";
import _ from "underscore";
 
export type Data = any & {
    signal?: AbortSignal;
};

export async function post<T = any>(url: string, data?: Data): Promise<any> {
    const options = {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        method: "POST",
        credentials: "same-origin" as RequestCredentials,
        body: JSON.stringify(data)
    };

    addToHeaders(options);
    addAbortSignal(options, data);
    const requestDate = new Date();

    return fetch(url, options)
        .then((response) => handleVersionValidator(response, requestDate)) as Promise<T>;
}

export async function put<T = any>(url: string, data?: Data) {
    const options = {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json; charset=utf-8",
        },
        dataType: "text",
        method: "PUT",
        credentials: "same-origin" as RequestCredentials,
        body: JSON.stringify(data)
    };

    addToHeaders(options);
    addAbortSignal(options, data);
    const requestDate = new Date();

    return fetch(url, options)
        .then((response) => handleVersionValidator(response, requestDate)) as Promise<T>;
}

export async function del<T = any>(url: string, data?: Data) {
    const options = {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        method: "DELETE",
        credentials: "same-origin" as RequestCredentials,
        body: JSON.stringify(data)
    };

    addToHeaders(options);
    addAbortSignal(options, data);
    const requestDate = new Date();

    return fetch(url, options)
        .then((response) => handleVersionValidator(response, requestDate)) as Promise<T>;
}

export async function get<T = any>(url: string, data: Data = {}) {
    const options = {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        method: "GET",
        credentials: "same-origin" as RequestCredentials
    } as any;
    
    addAbortSignal(options, data);

    if (!_.isEmpty(data)) {
        url += "?" + Object.entries(data)
            .filter(([_, value]) => Array.isArray(value) 
                ? value.length > 0 
                : value !== null)
            .map(([key, value]) => Array.isArray(value) 
                ? value.map(val => `${key}=${val}`).join("&") 
                : `${key}=${value}`)
            .join("&");
    }

    const requestDate = new Date();

    return fetch(url, options)
        .then((response) => handleVersionValidator(response, requestDate)) as Promise<T>;
}

export function aborted(onRejectedReason: any) {
    return onRejectedReason.name == "AbortError";
}

function addAbortSignal(options: any, data?: Data, signal?: AbortSignal) {
    if (data?.signal) {
        options.signal = data.signal;
        delete data.signal;
    }
    else if (isNotNullOrUndefined(signal)) {
        options.signal = signal;
    }
}

function handleVersionValidator(response: Response, requestDate: Date) {
    validateVersionFromHeaderFetch(response.headers, response.url, requestDate);
    return response;
}
