///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/newTooltip/defaultWhiteSkin"/>
export const showOn = 'mouseenter';
export const hideOn = 'mouseleave';
export const position = {
    my: 'center bottom',
    at: 'center top',
    collision: 'flipfit flipfit'
};
export const css = {
    color: 'black',
    background: 'white',
    border: '1.5px solid #ccc',
    'box-shadow': 'rgb(204, 204, 204) 1px 2px 3px 0.5px',
    'border-radius': '6px',
    'max-width': 'none'
};
export const arrowCss = {
    'border-color': '#ffffff'
};