/// <amd-module name="Core/Medius.Core.Web/Scripts/components/procurement/hooks/cartTypesForRequisitionRequest"/>
import { Address, Cart, CartCodingLine, CartDetails, CartFreeTextFormField, CartLine, CartLineType, CartTaxCode } from "Core/Medius.Core.Web/Scripts/components/procurement/cart/cart";

export type RequisitionRequestDto = Readonly<{
    cartLines: RequisitionRequestLineDto[];
    details: CartDetails;
}>;

export type CopySourceDto = Readonly<{
    sourceDocumentIdentifier: string;
    copyingUserId: number;
    copiedTimestamp: string
}>;

export type RequisitionRequestItemDto = Readonly<{
    quantityBasedProduct: RequisitionRequestQuantityBasedProductItemDto;
    valueBasedProduct: RequisitionRequestValueBasedProductItemDto;
    freeText: RequisitionRequestFreeTextItemDto;
    freeTextForm: RequisitionRequestFreeTextFormItemDto;
    punchout: RequisitionRequestPunchoutItemDto;
}>;

export type RequisitionRequestLineDto = Readonly<{
    lineType: CartLineType;
    item: RequisitionRequestItemDto;
    quantity: number;
    codingLines: CartCodingLine[];
    taxCodes: CartTaxCode[];
    additionalInformation: string;
    deliveryDate: string;
    deliveryAddress: Address;
    databaseId?: number;
    dimensionApprovalObjectId?: number;
}>;

export type RequisitionRequestQuantityBasedProductItemDto = Readonly<{
    productId: number;
    categoryId?: number;
    unitPrice: number;
}>;

export type RequisitionRequestValueBasedProductItemDto = Readonly<{
    productId: number;
    categoryId?: number;
    price: number;
}>;

export type RequisitionRequestFreeTextItemDto = Readonly<{
    description: string;
    categoryId: number;
    contractId?: number;
    supplierId: number;
    currencyId: number;
    price: number;
    isQuantityBased: boolean;
    unitId: number;
    partNumber: string;
}>;

export type RequisitionRequestFreeTextFormItemDto = Readonly<{
    description: string,
    categoryId: number;
    contractId?: number;
    supplierId: number;
    currencyId: number;
    price: number;
    isQuantityBased: boolean;
    unitId: number;
    partNumber: string;
    freeTextFormId: number;
    fields: RequisitionRequestFreeTextFormItemFieldDto[];
}>;

export type RequisitionRequestFreeTextFormItemFieldDto = Readonly<{
    databaseId?: number,
    fieldId: number,
    textValue: string,
    numericValue: number,
    booleanValue: boolean,
    dateValue: string,
    listValueId: number,
    textAreaValue: string,
}>;

export type RequisitionRequestPunchoutItemDto = Readonly<{
    description: string;
    categoryId: number;
    supplierId: number;
    currencyId: number;
    price: number;
    isQuantityBased: boolean;
    partNumber: string;
    unitId: number;
}>;

export function mapToCartDto({ cartLines, details }: Cart): RequisitionRequestDto {
    return {
        cartLines: cartLines.map(cartLine => mapToCartLineDto(cartLine)),
        details
    };
}

export function mapCopySource({ details }: Cart): CopySourceDto {
    if (details.copySource == null) return null;

    return {
        sourceDocumentIdentifier: details.copySource.sourceDocumentIdentifier,
        copyingUserId: details.copySource.copyingUserId,
        copiedTimestamp: details.copySource.copiedTimestamp
    };
}

export function mapToCartLineDto(cartLine: CartLine): RequisitionRequestLineDto {
    if (!cartLine)
        return null;

    const {
        item: { lineType, isQuantityBased },
        quantity: { value: quantity },
        codingLines,
        taxCodes
    } = cartLine;

    return {
        lineType,
        item: {
            quantityBasedProduct: lineType == CartLineType.Catalog && isQuantityBased ? mapQuanityBasedProductLineToDto(cartLine) : null,
            valueBasedProduct: lineType == CartLineType.Catalog && !isQuantityBased ? mapValueBasedProductLineToDto(cartLine) : null,
            freeText: lineType == CartLineType.FreeText ? mapFreeTextLineToDto(cartLine) : null,
            punchout: lineType == CartLineType.PunchOut ? mapPunchoutLineToDto(cartLine) : null,
            freeTextForm: lineType == CartLineType.FreeTextForm ? mapFreeTextFormLineToDto(cartLine) : null,
        },
        quantity,
        codingLines,
        taxCodes,
        additionalInformation: cartLine.additionalInformation,
        deliveryDate: cartLine.deliveryDate,
        deliveryAddress: cartLine.deliveryAddress,
        databaseId: cartLine.databaseId,
        dimensionApprovalObjectId: cartLine.dimensionApprovalObjectId
    };
}

function mapQuanityBasedProductLineToDto(
    { item: { productId, category, unitPrice } }: CartLine
): RequisitionRequestQuantityBasedProductItemDto {
    return {
        productId,
        categoryId: category?.id,
        unitPrice: unitPrice.value
    };
}

function mapValueBasedProductLineToDto(
    { item: { productId, category, unitPrice } }: CartLine
): RequisitionRequestValueBasedProductItemDto {
    return {
        productId,
        categoryId: category?.id,
        price: unitPrice.value
    };
}

const mapFreeTextFormLineToDto = (cartLine: CartLine): RequisitionRequestFreeTextFormItemDto => {
    const mapField = (field: CartFreeTextFormField): RequisitionRequestFreeTextFormItemFieldDto => {
        return {
            databaseId: field.databaseId,
            fieldId: field.fieldId,
            textValue: field.fieldType == "text" ? field.fieldValue : null,
            numericValue: field.fieldType == "numeric" ? field.fieldValue : null,
            booleanValue: field.fieldType == "boolean" ? field.fieldValue : null,
            dateValue: field.fieldType == "date" ? field.fieldValue : null,
            listValueId: field.fieldType == "list" ? field.fieldValue : null,
            textAreaValue: field.fieldType == "textArea" ? field.fieldValue : null,
        };
    };

    return {
        ...mapFreeTextLineToDto(cartLine),
        freeTextFormId: cartLine.item.freeTextFormId,
        fields: cartLine.item.freeTextFormFields?.map(mapField),
    };
};

function mapFreeTextLineToDto({ item }: CartLine): RequisitionRequestFreeTextItemDto {
    return {
        description: item.description,
        supplierId: item.supplier?.id,
        categoryId: item.category?.id,
        unitId: item.unit.id,
        isQuantityBased: item.isQuantityBased,
        currencyId: item.unitPrice.currencyId,
        price: item.unitPrice.value,
        partNumber: item.partNumber,
        contractId: item.contract?.id,
    };
}

function mapPunchoutLineToDto({ item }: CartLine): RequisitionRequestPunchoutItemDto {
    return {
        categoryId: item.category?.id,
        currencyId: item.unitPrice.currencyId,
        description: item.description,
        isQuantityBased: item.isQuantityBased,
        price: item.unitPrice.value,
        supplierId: item.supplier.id,
        partNumber: item.partNumber,
        unitId: item.unit.id,
    };
}