///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/documentSearch/spa"/>
import * as appsUtils from "Core/Medius.Core.Web/Scripts/Medius/apps/utils";
import * as route from "Core/Medius.Core.Web/Scripts/Medius/apps/spaManager/route";
import { applications } from "Core/Medius.Core.Web/Scripts/Medius/apps/spaManager/applications";
import * as _ from "underscore";

import tabs = require("Core/Medius.Core.Web/Scripts/Medius/apps/documentSearch/tabs");

class DocumentSearchApp {
    containerId: any;
    app: any;
    name: string;
    routes: any[];
    updateUrl: (elements: any) => boolean;
    initialize: () => void;
    constructor(spaManager: any, containerId: any) {
        this.containerId = containerId;
        this.app = tabs;
        this.name = applications.advancedSearch;
    
        const routeHandlers = {
            open: () => {
                spaManager.openApp(this);
            },
            archiveSearch: () => {
                spaManager.openApp(this);
                this.app.setActiveTab("ArchiveSearch");
            }
        };
    
        this.routes = [
            route.create("AdvancedSearch", routeHandlers.open),
            route.create("AdvancedSearch/ArchiveSearch", routeHandlers.archiveSearch)
        ];
    
        this.updateUrl = (elements) => {
            if (!_.isArray(elements)) {
                return false;
            }

            spaManager.router.navigate(elements.join("/"), {
                trigger: true,
                replace: true
            });

            return true;
        };
    
        this.initialize = _.once(() => {
        });
    }
}

export function create(spaManager: any, containerId: any) {
    return new DocumentSearchApp(spaManager, containerId);
}

export function getView() {
    return appsUtils.renderTemplate("Core:templates/DocumentSearch/Tabs.html");
}