/// <amd-module name="Core/Medius.Core.Web/Scripts/events/eventNames"/>
const EventNames = {
    reportingToolChanged: "reportingToolChanged",
    urlHashChanged: "urlHashChanged",
    documentHistoryChanged: "documentHistoryChanged",
    documentDataChanged: "documentDataChanged",
    taskHandled: "taskHandled",
    taskSentToReview: "taskSentToReview",
    taskSentToReviewInPV2: "taskSentToReviewInPV2",
    taskWithdrawnFromReview: "taskWithdrawnFromReview",
    taskWithdrawnFromReviewInPV2: "taskWithdrawnFromReviewInPV2",
    taskReloadRequested: "taskReloadRequested",
    taskSaved: "taskSaved",
    gadgetDrilldownClicked: "gadgetDrilldownClicked",
    handleDocumentsOneByOneRequested: "handleDocumentsOneByOneRequested",
    administrationEntityChanged: "administrationEntityChanged"
} as const;

export = EventNames;
