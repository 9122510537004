///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/grid/bindings/clickOutside"/>
import * as $ from "jquery";
import * as ko from "knockout";
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as shortcutManager from "Core/Medius.Core.Web/Scripts/lib/shortcutManager";
import * as _ from "underscore";

const ENTER = 13;
const SPACE = 32;

function rowUp(viewModel:any, action:any) {
    if (viewModel.IsActive()) {
        const index = _.indexOf(viewModel.Rows(), viewModel.SelectedRow());
        if (index > 0) {
            switch(action) {
                case "SelectedRow":
                    viewModel.SelectedRow(viewModel.Rows()[index - 1]);
                    break;
                case "clickRow":
                    viewModel.clickRow(viewModel.Rows()[index - 1]);
                    break;
            }
        }
    }

    //After admin page is loaded disposal function is called, which unbinds shortcuts. If this disposal was caused
    //by using said shortcut we need to rebind shortcuts for them to still work. 
    shortcutManager.bindShortcutBlockingDefaultAction(viewModel.RowDownShortcutName, function(){rowDown(viewModel, action);});
    shortcutManager.bindShortcutBlockingDefaultAction(viewModel.RowUpShortcutName, function(){rowUp(viewModel, action);});
}

function rowDown(viewModel:any, action:any) {
    if (viewModel.IsActive()) {
        const index = _.indexOf(viewModel.Rows(), viewModel.SelectedRow());

        if (index < viewModel.Rows().length - 1) {
            switch(action) {
                case "SelectedRow":
                    viewModel.SelectedRow(viewModel.Rows()[index + 1]);
                    break;
                case "clickRow":
                    viewModel.clickRow(viewModel.Rows()[index + 1]);
                    break;
            }
        }
    }

    //After admin page is loaded disposal function is called, which unbinds shortcuts. If this disposal was caused
    //by using said shortcut we need to rebind shortcuts for them to still work.
    shortcutManager.bindShortcutBlockingDefaultAction(viewModel.RowDownShortcutName, function(){rowDown(viewModel, action);});
    shortcutManager.bindShortcutBlockingDefaultAction(viewModel.RowUpShortcutName, function(){rowUp(viewModel, action);});
}

const gridClickOutside = {
    init: function (element:any, bindingAccessor:any, allAccessor:any, viewModel:any) {
        let $html = $("html");
        let $element = $(element);
        let lastEvent:any;

        let clickGrid = (event:any) => {
            //clickGrid needs to be executed after clickOutside thats why setTimeout(0) is used
            //The issue is with first selection (selecting element when no element has been selected before)
            //Withput delay what happens is that:
            // - firstly we register shortcuts (from clickGrid)
            // - secondly we deregister shortcuts (from clickOutside)
            setTimeout(function () {
                if (!viewModel.IsActive()) {
                    if (viewModel.MultiselectEnabled) {

                        if ($html) {
                            $html.on("keyup", keyHandler);
                        }                            

                        shortcutManager.bindShortcutBlockingDefaultAction(
                            viewModel.SelectAllShortcutName, function() {
                                if (viewModel.IsActive()) {
                                    viewModel.SelectAll(true);
                                }
                            }
                        );
                        //add navigation (selection change) on arrows 
                        shortcutManager.bindShortcutBlockingDefaultAction(
                            viewModel.RowUpShortcutName, function() {rowUp(viewModel, "SelectedRow");}
                        );
                        shortcutManager.bindShortcutBlockingDefaultAction(
                            viewModel.RowDownShortcutName, function() {rowDown(viewModel, "SelectedRow");}
                        );
                    } else {
                        shortcutManager.bindShortcutBlockingDefaultAction(
                            viewModel.RowUpShortcutName, function() {rowUp(viewModel, "clickRow");}
                        );
                        shortcutManager.bindShortcutBlockingDefaultAction(
                            viewModel.RowDownShortcutName, function() {rowDown(viewModel, "clickRow");}
                        );
                    }
                }

                viewModel.IsActive(true);
                lastEvent = event.timeStamp;

                return true;
            }, 0);
        };

        let clickOutside = (event:any) => {
            if (lastEvent === event.timeStamp) {
                return true;
            }
            
            if (viewModel.IsActive()) {
                if (viewModel.MultiselectEnabled) {
                    shortcutManager.unbindShortcut(viewModel.SelectAllShortcutName);
                    $html.off("keyup", keyHandler);
                }
                
                shortcutManager.unbindShortcut(viewModel.RowUpShortcutName);
                shortcutManager.unbindShortcut(viewModel.RowDownShortcutName);
                
            }

            viewModel.IsActive(false);

            return true;
        };

        let keyHandler = (e:any) => {
            const index = _.indexOf(viewModel.Rows(), viewModel.SelectedRow());
            
            if (!e.isPropagationStopped() && e.keyCode === ENTER) {
                    if (viewModel.IsActive()) {
                        if (index >= 0) {
                            viewModel.clickRow(viewModel.Rows()[index]);
                        }
                    }
                }
            if (!e.isPropagationStopped() && e.keyCode === SPACE) {
                if (viewModel.IsActive()) {
                    if (index >= 0) {
                        viewModel.multiselection.toggleRow(viewModel.Rows()[index].Id);
                    }
                }
            }
        };
        
        function disposal() {
            $element.off("click", clickGrid);
            $html.off("click", clickOutside);
            $html.off("focusin", clickOutside);
            $html.off("keyup", keyHandler);
            
            shortcutManager.unbindShortcut(viewModel.SelectAllShortcutName);
            shortcutManager.unbindShortcut(viewModel.RowUpShortcutName);
            shortcutManager.unbindShortcut(viewModel.RowDownShortcutName);

            $html = null;
            $element = null;
            clickGrid = null;
            clickOutside = null;
            keyHandler = null;
        }

        $element.on("click", clickGrid);
        $html.on("click", clickOutside);
        $html.on("focusin", clickOutside);
        
        ko.utils.domNodeDisposal.addDisposeCallback(element, disposal);
    },

    update: function () {
    }
};

export function register() {
    koUtils.registerBinding("gridClickOutside", gridClickOutside);
}