/// <amd-module name="Core/Medius.Core.Web/Scripts/components/administration/buttons/actionButtons"/>
import * as React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { ActionButtonsProps } from "./actionButtonsProps";

/**
 * Represents the *ActionButtons component*.
 * You can find more info [here](https://mfxistorybook.z5.web.core.windows.net/?path=/docs/components-administration-action-buttons).
 */
export function ActionButtons(props: ActionButtonsProps) {
        const { onSave, onDelete, onClone = () => {} } = props;
        const { saveVisible = false, deleteVisible = false, cloneVisible = false } = props;
        const { saveButtonLabel, deleteButtonLabel, cloneButtonLabel = "" } = props;
        const { saveDisabled = false, cloneDisabled = false, deleteDisabled = false } = props;
        const { saveTitle = undefined, cloneTitle = undefined, deleteTitle = undefined } = props;

        // utility to get the button style
        // need to force pointer-events:auto to make tooltip work in disabled mode (only!)
        const determineButtonClass = (buttonName:string, title:string|undefined, disabled: boolean) => {
            let cls = `administration-action-buttons__${buttonName}`;
            if (title && disabled && title.length > 0)
                cls += " disabledWithTitle";
            return cls;
            };

    return (
        <div className="administration-action-buttons">
            {saveVisible && <Button
                data-testid="administration-action-buttons-save-button"
                className={determineButtonClass("save-button", saveTitle, saveDisabled)}
                themeColor="primary"
                onClick={onSave}
                icon="save"
                disabled={saveDisabled}
                title={saveTitle}
                >
                {saveButtonLabel}
            </Button>}
            {cloneVisible && <Button
                data-testid="administration-action-buttons-clone-button"
                className={determineButtonClass("clone-button", cloneTitle, cloneDisabled)}
                onClick={onClone}
                icon="copy"
                disabled={cloneDisabled}
                title = {cloneTitle}
                >
                {cloneButtonLabel}
            </Button>}
            {deleteVisible && 
                <Button
                data-testid="administration-action-buttons-delete-button"
                className={determineButtonClass("delete-button", deleteTitle, deleteDisabled)}
                onClick={onDelete}
                icon="delete"
                disabled={deleteDisabled}
                title={deleteTitle}
                >
                {deleteButtonLabel}
            </Button>}
        </div>);
}

