///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/task/RiskFactor/RiskFactorCard"/>

import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as React from "react";
import { RiskFactorCode, RiskFactor, RiskFactorReasonCode } from "./RiskFactorComponentDtos/riskFactorsForInvoiceDto";
import { Card, IconToggleRiskFactorRegular, IconToggleRiskFactorHandledRegular } from "@medius/ui-controls";
import { FromEmailAddressChanged } from "./RiskDescriptions/FromEmailAddressChanged";
import { BankAccountValidationMasterData } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/RiskFactor/RiskDescriptions/BankAccountValidationMasterData";
import { OrganizationNumberValidationMasterData } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/RiskFactor/RiskDescriptions/OrganizationNumberValidationMasterData";
import { FirstTimeInvoice } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/RiskFactor/RiskDescriptions/FirstTimeInvoice";
import { DuplicatedInvoice } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/RiskFactor/RiskDescriptions/DuplicatedInvoice";
import { NewBankAccountDetected } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/RiskFactor/RiskDescriptions/NewBankAccountDetected";
import { NewOrganizationNumberDetected } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/RiskFactor/RiskDescriptions/NewOrganizationNumberDetected";
import { RandomizedExtraApproval } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/RiskFactor/RiskDescriptions/RandomizedExtraApproval";

export interface IProps{
    risks: RiskFactor[], 
    areHandled: boolean,
    taskId: number,
    currentReasonCodes: {
        reasonCode: RiskFactorReasonCode;
        riskFactorCode: RiskFactorCode;
    }[],
    canEdit: boolean,
}

export function RiskFactorCard({ risks, areHandled, currentReasonCodes, canEdit, taskId }: IProps){
    const getRiskFactor = (el: RiskFactor, handled: boolean) => {
        let content = <></>;
        switch (el.code) {
            case RiskFactorCode.RandomizedExtraApproval:
                content = (
                    <RandomizedExtraApproval
                        isRiskHandled={handled}
                        handledInfo={el.handledInfo}
                        currentReasonCode={currentReasonCodes.filter(elem => elem.riskFactorCode === el.code)[0]?.reasonCode}
                    />
                ); break;
            case RiskFactorCode.FromEmailAddressChanged:
                content = (
                    <FromEmailAddressChanged
                        additionalProperties={el.additionalProperties} 
                        isRiskHandled={handled}
                        handledInfo={el.handledInfo}
                        currentReasonCode={currentReasonCodes.filter(elem => elem.riskFactorCode === el.code)[0]?.reasonCode}
                    />
                ); break;
            case RiskFactorCode.BankAccountValidationMasterData:
                content = (
                    <BankAccountValidationMasterData 
                        additionalProperties={el.additionalProperties}
                        canEdit={canEdit}
                        isRiskHandled={handled}
                        handledInfo={el.handledInfo}
                        currentReasonCode={currentReasonCodes.filter(elem => elem.riskFactorCode === el.code)[0]?.reasonCode}
                        taskId={taskId}
                    />
                ); break;
            case RiskFactorCode.OrganizationNumberValidationMasterData:
                content = (
                    <OrganizationNumberValidationMasterData 
                        additionalProperties={el.additionalProperties}
                        canEdit={canEdit}
                        isRiskHandled={handled}
                        handledInfo={el.handledInfo}
                        currentReasonCode={currentReasonCodes.filter(elem => elem.riskFactorCode === el.code)[0]?.reasonCode}
                        taskId={taskId}
                    />
                ); break;
            case RiskFactorCode.FirstTimeInvoice:
                content = (
                    <FirstTimeInvoice 
                        isRiskHandled={handled}
                        handledInfo={el.handledInfo}
                        currentReasonCode={currentReasonCodes.filter(elem => elem.riskFactorCode === el.code)[0]?.reasonCode}
                    />
                ); break;
            case RiskFactorCode.DuplicatedInvoice:
                content = (
                    <DuplicatedInvoice 
                        isRiskHandled={handled}
                        handledInfo={el.handledInfo}
                        currentReasonCode={currentReasonCodes.filter(elem => elem.riskFactorCode === el.code)[0]?.reasonCode}
                    />
                ); break;
            case RiskFactorCode.NewBankAccountDetected:
                content = (
                    <NewBankAccountDetected
                        additionalProperties={el.additionalProperties}
                        canEdit={canEdit}
                        isRiskHandled={handled}
                        handledInfo={el.handledInfo}
                        currentReasonCode={currentReasonCodes.filter(elem => elem.riskFactorCode === el.code)[0]?.reasonCode}
                        taskId={taskId}
                    />
                ); break;
            case RiskFactorCode.NewOrganizationNumberDetected:
                content = (
                    <NewOrganizationNumberDetected 
                        additionalProperties={el.additionalProperties}
                        canEdit={canEdit}
                        isRiskHandled={handled}
                        handledInfo={el.handledInfo}
                        currentReasonCode={currentReasonCodes.filter(elem => elem.riskFactorCode === el.code)[0]?.reasonCode}
                        taskId={taskId}
                    />
                ); break;
        }

        return content;
    };

    const filteredRisks = risks.filter(r => !r.isHandled).map(r => r.code);
    const showDropdownRisksInfoText = (
        filteredRisks.includes(RiskFactorCode.NewBankAccountDetected) ||
        filteredRisks.includes(RiskFactorCode.NewOrganizationNumberDetected) ||
        filteredRisks.includes(RiskFactorCode.OrganizationNumberValidationMasterData) ||
        filteredRisks.includes(RiskFactorCode.BankAccountValidationMasterData)
    ) && canEdit;

    return (
        <>
        {
            areHandled ? (
                <Card
                    data-testid={"risks-handled"}
                    isCollapsible
                    renderAdditionalHeaderContent={() => CardHeaderContent(true)}
                >
                    { risks.map((el, i) => (
                        <div key={i} data-testid={"risk-container-"+el.code} className="risk-factor-icon-container__cardInternalContainer">
                            { getRiskFactor(el, true) }
                            { i !== risks.length-1 && <hr />}
                        </div>
                    ))}
                </Card>
            ) : (
                <Card
                    data-testid={"risks-unhandled"}
                    isCollapsible
                    renderAdditionalHeaderContent={() => CardHeaderContent(false)}
                >
                    {showDropdownRisksInfoText && <>
                        <p>{getLabelTranslation("#Core/specifyHowToHandleActiveRisks")}</p>
                        <hr />
                    </>}
                    { risks.map((el, i) => (
                        <div key={i} data-testid={"risk-container-"+el.code} className="risk-factor-icon-container__cardInternalContainer">
                            { getRiskFactor(el, false) }
                            { i !== risks.length-1 && <hr />}
                        </div>
                    ))}
                </Card>
            )
        }
        </>
    );
}

function CardHeaderContent(isRiskHandled: boolean): JSX.Element {
    let content = (
        <>
            <IconToggleRiskFactorRegular color="error" size="small"/>
            <h2 className="risk-factor-icon-container__cardH2">{getLabelTranslation("#Core/activeRisks")}</h2>
        </>
    );

    if(isRiskHandled){
        content = (
            <>
            <IconToggleRiskFactorHandledRegular color="container" size="small"/>
            <h2 className="risk-factor-icon-container__cardH2">{getLabelTranslation("#Core/handledRisks")}</h2>
            </>
        );
    }

    return content;
}