///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/transformers/serialize/object"/>
import * as ko from "knockout";
import * as defaults from "Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/transformers/serialize/defaults";

export = function objectBuilder(input:any/*, context*/) {
    const data:any = {};

    return {
        ...defaults,
        shouldDescend: true,
        shouldDescendProperty: function (name: any) {
            const property = input[name];
            switch(typeof property) {
                case "string":
                case "number":
                case "boolean":
                    return true;

                case "function":
                    return !ko.isComputed(property);

                case "object":
                    return true;
            }
        },
        mapProperty: function (name:string, value:any) {
            if (value !== undefined) {
                data[name] = ko.utils.unwrapObservable(value);
            }
        },
        finalize: function () {
            return data;
        }
    };
};