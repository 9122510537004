/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/AuthorizationGroups/Tabs/components/headerCells"/>
import * as React from "react";
import { GridColumnProps, GridHeaderCellProps } from "@progress/kendo-react-grid";
import { Checkbox } from "@progress/kendo-react-inputs";
import { SvgIcon } from "@progress/kendo-react-common";
import { infoCircleIcon } from "@progress/kendo-svg-icons";

const tooltipHeaderCell = (headerProps: GridHeaderCellProps) => {
    return (
        <a className="k-link" onClick={headerProps.onClick}>
            <span title={headerProps.title}>{headerProps.title}</span>
        </a>
    );
};

export const TooltipProps: GridColumnProps = {
    headerCell: tooltipHeaderCell
};

export type CheckboxHeaderCellProps = {
    readonly isChecked: boolean;
    readonly info?: string;
    readonly onChange: (checked: boolean, property: string) => void;
}  & GridHeaderCellProps;

const passValueTo = (onChange: any, property?: string) => (event: any) => onChange(event.value, property);

export const CheckboxHeaderCell = (props: CheckboxHeaderCellProps) => {
    return (
        <div>
            <Checkbox onChange={passValueTo(props.onChange, props.field)} value={props.isChecked} />
            {!props.info &&
                <span className="checkboxHeaderCell">
                    {props.title}
                </span>
            }
            {props.info &&
                <span title={props.info} className="checkboxHeaderCell">
                    {props.title}
                    <span className="headerCellInfo" title={props.info}>
                        <SvgIcon icon={infoCircleIcon}/>
                    </span>
                </span>
            }
        </div>
    );
};
