/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/copyTransformation"/>
import { unwrap } from "Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/helpers"

export = function copyTransformation(sourceTree:any, targetTree:any, transformerFactory:any, options:any) {
    options = options || {};

    const rootname = options.rootname || null;
    const context = options.context;

    function continueTransformation(currentPath:any, sourceData:any, targetData:any, parentSoure:any, parentTarget:any) {
        /*jshint onevar:false */
        let source:any,
            sourceUnwrapped,
            target:any,
            targetUnwrapped,
            sourceProperty:any,
            targetProperty:any,
            currentPropertyPath,
            visitorArgs,
            propertyVisitorArgs,
            descend = true,
            descendProperty,
            transformer:any = {},
            currentPropertyName:any;

        if (transformerFactory) {
            transformer = transformerFactory(context);
        }

        source = sourceData;
        sourceUnwrapped = unwrap(source);

        target = targetData;
        targetUnwrapped = unwrap(target);

        function getSourceData() {
            return source;
        }

        function getTargetData() {
            return target;
        }

        function setTargetData(v:any) {
            target = v;
            targetUnwrapped = unwrap(target);
        }

        function stopDescend() {
            descend = false;
        }

        visitorArgs = {
            getSourceData: getSourceData,
            getTargetData: getTargetData,
            setTargetData: setTargetData,
            parent: {
                source: parentSoure,
                target: parentTarget
            },
            path: currentPath,
            context: context,
            stopDescend: stopDescend
        };

        function getSourceProperty() {
            return sourceProperty;
        }

        function getTargetProperty() {
            return targetProperty;
        }

        function setTargetProperty(v:any, skipTargetUpdate:any) {
            targetProperty = v;
            if (!skipTargetUpdate) {
                targetUnwrapped[currentPropertyName] = v;
            }
        }

        function stopPropertyDescend() {
            descendProperty = false;
        }

        propertyVisitorArgs = {
            getSourceProperty: getSourceProperty,
            getTargetProperty: getTargetProperty,
            setTargetProperty: setTargetProperty,
            getSourceData: getSourceData,
            getTargetData: getTargetData,
            parentPath: currentPath,
            path: null,
            propertyName: null,
            propertyPath: null,
            stopPropertyDescend: stopPropertyDescend
        };

        if (transformer.topDown) {
            transformer.topDown(visitorArgs);
        }

        if (descend && sourceUnwrapped && typeof sourceUnwrapped === "object") {
            for (currentPropertyName in sourceUnwrapped) {
                if (sourceUnwrapped.hasOwnProperty(currentPropertyName)) {
                    descendProperty = true;
                    currentPropertyPath = [currentPath, currentPropertyName].join(".");

                    propertyVisitorArgs.propertyName = currentPropertyName;
                    propertyVisitorArgs.propertyPath = currentPropertyPath;

                    targetProperty = undefined;
                    sourceProperty = undefined;

                    if (targetUnwrapped && typeof targetUnwrapped === "object" && targetUnwrapped.hasOwnProperty(currentPropertyName)) {
                        targetProperty = targetUnwrapped[currentPropertyName];
                    }

                    sourceProperty = sourceUnwrapped[currentPropertyName];

                    if (transformer.beforeDescend) {
                        transformer.beforeDescend(propertyVisitorArgs);
                    }

                    if (descendProperty) {
                        targetProperty = continueTransformation(currentPropertyPath, sourceProperty, targetProperty,
                            sourceUnwrapped, targetUnwrapped);
                    }

                    if (transformer.afterDescend) {
                        transformer.afterDescend(propertyVisitorArgs);
                    }
                }
            }
        }

        if (transformer.bottomUp) {
            transformer.bottomUp(visitorArgs);
        }

        return target;
    }

    const result = continueTransformation(rootname, sourceTree, targetTree, null, null);

    return result;
}