///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/jscroll"/>
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";

const jscroll = {
    init: function (element:any) {
        $(element).addClass("scrollable");
    }
};

export function register() {
    koUtils.registerBinding("jscroll", jscroll);
}