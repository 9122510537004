/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/customfields/listValue/dataProvider"/>
import {unwrap} from "knockout";
import * as base from "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/default/dataProvider";
import { handleAnyError } from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import { lightApi } from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rpc";

class Provider extends base.DataProvider {
    constructor(){
        super();
    }

    search(searchText: string, maxResults: number, options: any) {
        const optionsList = unwrap(options.list);
        if(Array.isArray(optionsList)) {
            const params = {
                customListIds: optionsList.map(list => list.Id),
                searchText: searchText,
                maxResults: maxResults
            };

            return lightApi("CustomFieldsService", "GetCombinedCustomListsValues", params).fail((data: any) => {
                handleAnyError(data);
            });
        } else {
            const params = {
                customListId: optionsList?.Id,
                searchText: searchText,
                maxResults: maxResults
            };

            return lightApi("CustomFieldsService", "GetCustomListValues", params).fail((data: any) => {
                handleAnyError(data);
            });
        }
    }
}

export function create(){
    return new Provider();
}

export const derive = Provider;
