/// <amd-module name="Core/Medius.Core.Web/Scripts/components/outbox/outboxItem"/>
import {pureComputed, computed, observable, observableArray} from "knockout";
import {getFormattedLabelTranslation} from "Core/Medius.Core.Web/Scripts/lib/globalization";

const states = {
    /* Task is being processed by the backend */
    pending: "pending",
    /* Task has been processed by the backend and has suceded */
    done: "done",
    /* task has been processed by the backend, but error occurred */
    failed: "failed"
};

const labels = {
    document0isBeingHandled: "#Core/document0isBeingHandled",
    document0hasFailed: "#Core/document0hasFailed",
    document0hasCompleted: "#Core/document0hasCompleted"
};

export class OutboxItem {
    private metadataGenerator: any;

    public taskId: number;
    public state = observable(states.pending);
    public validationMessages = observableArray<any>([]);

    public isPending = pureComputed(() => this.state() === states.pending);
    public isDone = pureComputed(() => this.state() === states.done);
    public isFailed = pureComputed(() => this.state() === states.failed);
    public linkText: ko.Computed<string>;
    public linkTarget: ko.Computed<any>;

    constructor(taskId: number, documentId: any, metadataGenerator: any) {
        this.metadataGenerator = metadataGenerator;
        this.taskId = taskId;

        this.linkText = computed(() => {
            const message = this.isDone() ?
                labels.document0hasCompleted :
                this.isFailed() ?
                    labels.document0hasFailed :
                    labels.document0isBeingHandled;

            return getFormattedLabelTranslation(message, [documentId]);
        });

        this.linkTarget = computed(() => {
            return this.metadataGenerator.getMetadata({
                $type: "Medius.Data.Task",
                Id: taskId
            }).link;
        });
    }

    public done() {
        this.state(states.done);
    }

    public fail(messages: any[]) {
        this.validationMessages(messages.map(message => (getFormattedLabelTranslation as any)(message)));
        this.state(states.failed);
    }

    public dispose() {
        this.isPending.dispose();
        this.isDone.dispose();
        this.isFailed.dispose();
        this.linkTarget.dispose();
        this.linkText.dispose();
    }
}
