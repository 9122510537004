/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/document/documentHandling"/>
import * as $ from "jquery";
import { observable, observableArray, pureComputed, unwrap } from "knockout";
import * as documentManager from "Core/Medius.Core.Web/Scripts/Medius/apps/document/documentManager";
import * as window from "window";
import { delay } from "underscore";
import * as tabManager from "Core/Medius.Core.Web/Scripts/Medius/apps/document/tabManager";
import * as backendErrorHandler from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import { translate, getPropertyTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as notification from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import { InboxInstance as inbox } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/inbox";
import * as rpc from "Core/Medius.Core.Web/Scripts/Medius/core/rpc";
import type = require("Core/Medius.Core.Web/Scripts/Medius/core/type");
import * as editor from "./editor";
import * as events from "Core/Medius.Core.Web/Scripts/Medius/apps/document/events";
import contextResourceFactory = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/contextResource");

class DocumentHandlingViewModel {
    public validation = observable();
    public viewError = observable(false);
    public copyMode = observable(false);
    public isLoading = observable(false);
    public documentViewModel = observable<any>();
    public Editor: any;
    public DocumentManager: any;
    public targetElement: JQuery;
    public EntityViewModel: ko.Observable<any>;
    public TabManager: any;
    public draftName = observable();
    public draftModalVisible = observable(false);
    public reclassifyTypeSelectVisible = observable(false);
    public possibleReclassifyTypeList = observableArray();
    public selectedReclassifyType = observable();
    public sourceTask = observable<any>();
    public targetDocumentType = observable();
    public SetCommentsModel: (commentsModel: any) => void;
    public DisposeCommentsModel: () => void;
    public editedDocumentType: ko.Computed<string>;
    public allowRemoveComment = observable(false);
    public copyTask: any;
    public isDraftNameValid: any;

    public registerDocument = () => {
        this.isLoading(true);
        this.DocumentManager.registerDocument(this.documentViewModel());
    };

    public performDocumentReclassification = () => {
        this.isLoading(true);
        this.DocumentManager.performDocumentReclassification(this.documentViewModel(), this.copyTask);
    };

    constructor(targetElement: HTMLElement) {
        const contextResource = contextResourceFactory();
        this.Editor = editor.create(targetElement, contextResource);
        this.DocumentManager = documentManager.create(targetElement, contextResource);

        this.targetElement = $(targetElement);

        this.EntityViewModel = this.documentViewModel;

        this.TabManager = tabManager.create();

        this.SetCommentsModel = (commentsModel: any) => {
            this.DocumentManager.CommentsModel = commentsModel;
        };

        this.DisposeCommentsModel = () => {
            this.DocumentManager.CommentsModel = null;
        };

        this.editedDocumentType = pureComputed(() => {
            if (this.documentViewModel()) {
                const typeName = type.getTypeName(unwrap(this.documentViewModel().$type));
                return this.translateDocumentType(typeName);
            } else {
                return "";
            }

        });

        $(document)
            .off(events.documentCreateRequest)
            .on(events.documentCreateRequest, (e, docType) => {
                this.createNewDocument(docType);
            })
            .off(events.reclassifySelectorOpened)
            .on(events.reclassifySelectorOpened, (e, task) => {
                return rpc.ajax("UiDocumentManager", "GetDocumentReclassificationTypes")
                    .done((data: any) => {
                        this.chooseReclassifyType(data, task);
                    });
            });
    }

    public initTabs(documentModel: any) {
        this.isLoading(true);
        $.when(this.TabManager.loadTabs(documentModel)).done(() => {
            this.isLoading(false);
        });
    }

    public reset() {
        this.viewError(false);
        this.validation(null);
        this.copyMode(false);
        this.documentViewModel(null);
    }

    public createNewDocument(docType: any) {
        this.reset();
        this.Editor.createNewDocument(docType);
    }

    public initModel(model: any) {
        this.documentViewModel(null);
        this.initTabs(model);
        this.documentViewModel(model);
    }

    public saveAsDraft() {
        $.when(this.DocumentManager.saveAsDraft(this.documentViewModel(),
            this.draftName()))
            .done(() => { this.onSaveDraftSuccess(); })
            .fail((data: any) => { this.onSaveDraftFailure(data); });
    }

    public showSaveDraftModal() {
        this.draftModalVisible(true);
    }

    public hideSaveDraftModal() {
        this.draftModalVisible(false);
    }

    public chooseReclassifyType(data: any, task: any) {
        this.sourceTask(task);
        const targetDocument = getDocumentType(task.Document());

        if(task.reclasifyToSameDocumentType){
            data.push(targetDocument);
            this.possibleReclassifyTypeList(data);
            this.targetDocumentType(targetDocument);
            this.reclassifyDocument();
        }
        else {
            this.possibleReclassifyTypeList(data);
            this.targetDocumentType(targetDocument);
            this.reclassifyTypeSelectVisible(true);
        }
    }

    public translateDocumentType(docType: string) {
        return getPropertyTranslation("#" + docType);
    }

    public reclassifyDocument() {
        this.reclassifyTypeSelectVisible(false);

        this.Editor.initWithExistingDocument(this.sourceTask().Document().Id(), this.targetDocumentType());
        this.copyMode(true);
        this.copyTask = this.sourceTask();
        this.allowRemoveComment(true);
    }

    public showReclassifyModal() {
        this.reclassifyTypeSelectVisible(true);
    }

    public hideReclassifyModal() {
        this.reclassifyTypeSelectVisible(false);
    }

    public onSaveDraftSuccess() {
        this.draftName("");
        this.draftModalVisible(false);
        notification.success(
            translate("#Core/draftSaved") + translate("#Core/signExclamation")
        );
        $(window).trigger(events.cancel);
        delay(() => { inbox.refresh(); }, 1000);
    }

    public onSaveDraftFailure(data: any) {
        backendErrorHandler.handleAnyError(data);
    }

    public dispose() {
        this.isDraftNameValid.dispose();
    }
}

function getDocumentType(document: any): string {
    return document.$type().split(",")[0];
}

function registerEventHandlers(viewModel: any, parentElement: any) {

    parentElement.on(events.modelLoadError, function (ev: any, xhr: any) {
        ev.stopPropagation();
        backendErrorHandler.handleAnyError(xhr);
    });

    parentElement.on(events.saveSuccess, function (ev: any) {
        ev.stopPropagation();

        viewModel.isLoading(false);
        notification.success(
            translate("#Core/documentRegistered") +
            translate("#Core/signExclamation")
        );
        viewModel.reset();
        $(window).trigger(events.cancel);
    });

    parentElement.on(events.reclassificationRequestSent, function (ev: any, taskId: any) {
        ev.stopPropagation();

        viewModel.isLoading(false);
        viewModel.reset();
        $(window).trigger(events.cancel);

        inbox.onTaskHandled(taskId);
    });

    parentElement.on(events.validationError, function (ev: any, xhr: any) {
        ev.stopPropagation();
        viewModel.isLoading(false);
        backendErrorHandler.handleAnyError(xhr);
    });

    parentElement.on(events.saveError, function (ev: any, xhr: any) {
        ev.stopPropagation();
        viewModel.isLoading(false);
        backendErrorHandler.handleAnyError(xhr);
    });

    parentElement.on(events.saveForbidden, function (ev: any) {
        notification.info(translate("#Core/waitUntilDocumentRegistered"));
    });

    parentElement.on(events.modelBound, function (ev: any, model: any) {
        ev.stopPropagation();
        viewModel.initModel(model);
    });

    parentElement.on(events.modelReset, function () {
        viewModel.reset();
    });
}

export function create(targetElement: HTMLElement) {
    const vm = new DocumentHandlingViewModel(targetElement);
    registerEventHandlers(vm, $(targetElement));
    return vm;
}
