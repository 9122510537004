///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/documentSearch/queryItem"/>
import * as notification from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as rest from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rest";
import * as ko from "knockout";
import * as backendErrorHandler from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import { formatValueWithPlaceholders } from "../../lib/stringFormat";

class QueryItem {
    Query: any;
    QueryId: any;
    QueryName: ko.Observable<any>;
    IsNameEditorVisible = ko.observable(false);
    QueryItemTooltip: ko.Computed<any>;
    refreshItemsSection: any;
    constructor(savedQuery: any) {
        this.Query = savedQuery;
        this.QueryId = savedQuery.Id;
        this.Query.isFavorite = ko.observable(savedQuery.IsFavorite || false);
        this.Query.isShared = ko.observable(savedQuery.IsShared || false);
        this.Query.OwnerName = ko.observable(savedQuery.OwnerName || "");
        this.QueryName = ko.observable(savedQuery.Name || null);

        this.QueryItemTooltip = ko.computed(() => {
            return `${this.QueryName}, ${globalization.getLabelTranslation("#Core/sharedQueryCreator")}: ${this.Query.OwnerName}`;
        });
    }

    removeQueryItem() {
        const queryId = this.Query.Id;

        return rest.del("DataSearchManager", 'deleteSavedQuery?id=' + queryId)
            .done(() => {
                notification.success(globalization.getLabelTranslation("#Core/deleteQueryItemSuccess_name", this.Query.Name ));
            })
            .fail((xhr) => {
                backendErrorHandler.handleAnyError(
                    xhr, null, (globalization.getLabelTranslation("#Core/deleteQueryItemFail_name", this.Query.Name))
                );
            });
    }

    toggleQueryItemNameEditor() {
        const currentValue = this.IsNameEditorVisible();

        if (!currentValue) {
            this.QueryName(this.Query.Name);
        }

        this.IsNameEditorVisible(!currentValue);
    }

    updateQueryItemName() {
        const oldName = this.Query.Name,
            newName = this.QueryName(),
            queryId = this.Query.Id;

        return rest.put("DataSearchManager", 'updateQueryName/' + queryId, newName)
          .done(() => {
              this.Query.Name = newName;
              this.toggleQueryItemNameEditor();
              notification.success(
                  globalization.getLabelTranslation("#Core/updateQueryItemNameSuccess_oldName_newName", [... oldName, newName])
              );
          })
          .fail((xhr) => {
              backendErrorHandler.handleAnyError(xhr, null, globalization.getLabelTranslation("#Core/updateQueryItemNameFail_oldName_newName", [... oldName, newName]));
          });
    }

    toggleQueryItemFavorite(queryItem: any) {
        const queryId = queryItem.Query.Id,
            newValue = !queryItem.Query.isFavorite();

        rest.post("DataSearchManager", 'updateFavoriteQuery?queryId=' + queryId + "&isFavorite=" + newValue)
           .done(() => {
               queryItem.Query.isFavorite(newValue);
               this.refreshItemsSection();
               notification.success(globalization.getLabelTranslation("#Core/updateItemFavoriteStateSuccess_name", queryItem.Query.Name));
           })
           .fail((xhr) => {
               backendErrorHandler.handleAnyError(xhr, null, globalization.getLabelTranslation("#Core/updateItemFavoriteStateFail_name", queryItem.Query.Name));
           });
    }

    toggleChangeNameEditor() {
        this.IsNameEditorVisible(!this.IsNameEditorVisible());
    }
}

export function create(savedQuery: any) {
    return new QueryItem(savedQuery);
}