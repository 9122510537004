/// <amd-module name="Core/Medius.Core.Web/Scripts/components/topBar/RightSideOuterContainer"/>
import { LocalizationProvider } from "@progress/kendo-react-intl";
import { store } from "Core/Medius.Core.Web/Scripts/shared/store/reduxStore";
import * as React from "react";
import { Provider } from "react-redux";
import { HashRouter } from "react-router-dom";
import { RightSide } from "Core/Medius.Core.Web/Scripts/components/topBar/TopBarRightSide";

interface IProps{
    toggleModal: () => void;
    toggleQAIcon: () => void;
}

export const RightSideOuterContainer = ({ toggleModal, toggleQAIcon }: IProps) => {
    return <LocalizationProvider language="current-language">
        <Provider store={store}>
            <HashRouter>
                <RightSide toggleModal={toggleModal} toggleQAIcon={toggleQAIcon} />
            </HashRouter>
        </Provider>
    </LocalizationProvider>;
};