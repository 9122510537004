/*
* Binding that allows to add shortcut to given element.
The action will be called in context of view model for given element.
We expect the options:
 name - friendly name od shortcut, needs to be unique
 action - the method which is called when key will be called
 keyCode - the key which shortcut responds to, it is string, if more then one ocurrs it should be joined by "+", e.g. 'F2' , 'Alt+S'
 keyCodePattern - should meet jwertyCodes (https://github.com/keithamus/jwerty/blob/master/jwerty.js)
 toolTip (optional) - tool tip is added to element which is bind, if not given, default one is combined from name and keyCode: "name (keyCode)"
 blockDefault (optional) - it prevents default shortcut behaviour, false by default
 disable (optional) - disable shortcut, e.g. when the button is disabled, false by default
 fireOnce (optional) - if true, assigned action will be triggered only once

 Sample of use, fullscrean on/off, toggleFullscreen and toggleFullscreenKeyCode are defined in TaskHandling viev model:
 shortcut:{
    name: 'ToggleFullscreen',
    action: $root.toggleFullscreen,
    keyCode: $root.toggleFullscreenKeyCode,
    toolTip: '@Medius.TranslateForJs("#Core/fullscreenShortcut")',
    blockDefault: true}
*/

///<amd-module name ="Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/shortcut"/>
import * as _ from "underscore";
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as shortcutManager from "Core/Medius.Core.Web/Scripts/lib/shortcutManager";
import * as metricLogger from "Core/Medius.Core.Web/Scripts/lib/metricsLogging/metricLogger";
import { isNotNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";

const wrapKey = "medius-shortcut";

function addShortcutsTipToElement(domElement:any, shortcutName:any, tooltip:any) {
    const shortcutDefintion = shortcutManager.getDefinition(shortcutName);
    const formattedTooltip = `${globalization.getLabelTranslation(tooltip)} (${shortcutDefintion.combo})`;

    if (isNotNullOrUndefined(_(formattedTooltip))) {
        domElement.attr('title', formattedTooltip);
    }
}

function addDefaultTipToElement(domElement:any, shortcutName:any) {
    const shortcutDefintion = shortcutManager.getDefinition(shortcutName);
    const translatedDescrition = globalization.getLabelTranslation(shortcutDefintion.description);

    addShortcutsTipToElement(domElement, shortcutName, translatedDescrition);
}

const shortcut = {
    init: function (element:any, bindingAccessor:any) {
        const options = bindingAccessor();
        let $element = $(element);

        const shortcutName = koUtils.unpack(options.name);
        let action = options.action;
        const toolTip = koUtils.unpack(options.toolTip);
        const blockDefault = options.blockDefault || false;
        const fireOnce = options.fireOnce || false;
        const logUsage = options.logUsage === undefined ? true : options.logUsage;

        if (!shortcutName) {
            return;
        }

        if (fireOnce) {
            action = _.once(action);
        }
        let actionToBind = action;
        if(logUsage) {
            const shortcutDefintion = shortcutManager.getDefinition(shortcutName);
            actionToBind = function() {
                metricLogger.logEvent("shortcut-" + shortcutName + "-" + shortcutDefintion.combo);
                action();
            };
        }

        if (blockDefault) {
            shortcutManager.bindShortcutBlockingDefaultAction(shortcutName, actionToBind);
        } else {
            shortcutManager.bindShortcut(shortcutName, actionToBind);
        }

        if (toolTip) {
            addShortcutsTipToElement($element, shortcutName, toolTip);
        } else {
            addDefaultTipToElement($element, shortcutName);
        }

        koUtils.addDisposeCallback(element, function () {
            shortcutManager.unbindShortcut(shortcutName);
            action = null;
            $element = null;
        });
        koUtils.domData.set(element, wrapKey, true);
    },

    update: function (element:any, bindingAccessor:any, ...args:any[]) {
        const options = bindingAccessor();
        const enable = koUtils.unpack(options.enable);
        const shortcutName = koUtils.unpack(options.name);

        const initialized = koUtils.domData.get(element, wrapKey);
        if (!initialized) {
            shortcut.init.apply(this, [element, bindingAccessor, ...args]);
        }

        if (enable === true) {
            shortcutManager.resumeShortcut(shortcutName);
        }
        else if (enable === false) {
            shortcutManager.suspendShortcut(shortcutName);
        }
    }
};

export function register() {
    koUtils.registerBinding("shortcut", shortcut);
}