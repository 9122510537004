/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/kendo/bindings/kendoTreeView" />

import { KendoUtils } from "Core/Medius.Core.Web/Scripts/Medius/kendo/kendoUtils";
import * as ko from "knockout";
import { addDisposeCallback, registerBinding } from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";

class KendoTreeView {
    private static readonly widgetName = "kendoTreeView";

    private static rebindTreeView($div: JQuery, params: any): void {
        const options = ko.unwrap(params.options) as KendoTreeViewOptions;

        const currentTreeView = $div.data(KendoTreeView.widgetName);
        if (currentTreeView)
            currentTreeView.destroy();

        $div.empty();

        if (options) {
            KendoTreeView.setupCheck(options);
            KendoTreeView.setupClick(options);

            const treeView = $div.kendoTreeView(options).data(KendoTreeView.widgetName);
            if (options.expandOnStart)
                treeView.expand(".k-treeview-item");
            KendoTreeView.registerDisposeFor($div, KendoTreeView.widgetName);
            KendoTreeView.setupFilterMethod(options, treeView);
            KendoTreeView.setupCheckingMethods(options, treeView);
        }
    }

    private static setupClick(options: KendoTreeViewOptions) {
        if (!options.onClick && (!options.onCheck || !options.checkOnSelect))
            return;

        options.select = e => {
            const dataNode = e.sender.dataItem(e.node);

            if (options.nonClickableRoots && dataNode.level() === 0) {
                e.preventDefault();
                return;
            }

            if (options.checkOnSelect) {
                e.preventDefault();
                options.check(e);
                return;
            }

            options.onClick(dataNode);
        };
    }

    private static setupCheck(options: KendoTreeViewOptions) {
        if (!options.onCheck)
            return;

        options.check = e => {
            const treeView = e.sender as kendo.ui.TreeView;
            const dataNode = treeView.dataItem(e.node);

            if (dataNode.get("checked") && treeView.element.find(".k-treeview-item.checked").length <= options.minimumCheckedNodes) {
                e.preventDefault();
                return;
            }

            const checked = dataNode.get("checked");
            dataNode.set("checked", !checked);

            KendoTreeView.check(dataNode, $(e.node), options);
        };
    }

    private static setupFilterMethod(options: KendoTreeViewOptions, treeView: kendo.ui.TreeView) {
        function contains(str: string, substr: string) {
            return str.toLowerCase().indexOf(substr.toLowerCase()) !== -1;
        }

        options.filter = (text: string) => {
            if (text !== "") {
                treeView.element.find(".k-treeview-group .k-treeview-group .k-treeview-leaf").closest("li").hide();

                if (!options.nonFilterableRoots)
                    treeView.element.find(".k-treeview-group").closest("li").hide();

                treeView.element.find(".k-treeview-group .k-treeview-group .k-treeview-leaf").each(
                    (i, el) => {
                        if (contains($(el).text(), text)) {
                            $(el).parents("ul, li").each(
                                (ii, ele) => {
                                    treeView.expand($(ele).parents("li"));
                                    $(ele).show();
                                });
                        }

                    });

                if (!options.nonFilterableRoots)
                    treeView.element.find(".k-treeview-group .k-treeview-leaf").each(
                        (i, el) => {
                            if (contains($(el).text(), text)) {
                                $(el).parents("ul, li").each(
                                    (ii, ele) => {
                                        $(ele).show();
                                    });
                            }
                        });
            }
            else {
                treeView.element.find(".k-treeview-group").find("li").show();
                const nodes = treeView.element.find("> .k-treeview-group > li");

                $.each(
                    nodes,
                    i => {
                        if (nodes[i].getAttribute("data-expanded") == null) {
                            $(nodes[i]).find("li").hide();
                        }
                    });
            }
        };
    }

    private static setupCheckingMethods(options: KendoTreeViewOptions, treeView: kendo.ui.TreeView) {
        options.markAsChecked = (nodeId: string) => {
            const node = treeView.findByUid(treeView.dataSource.get(nodeId).uid);
            const dataNode = treeView.dataItem(node);

            const checked = dataNode.get("checked");
            if (checked)
                return;

            dataNode.set("checked", true);
            KendoTreeView.check(dataNode, node, options, false);
        };

        options.markAsUnchecked = (nodeId: string) => {
            const node = treeView.findByUid(treeView.dataSource.get(nodeId).uid);
            const dataNode = treeView.dataItem(node);

            const checked = dataNode.get("checked");
            if (!checked)
                return;

            dataNode.set("checked", false);
            KendoTreeView.check(dataNode, node, options, false);
        };
    }

    private static check(node: kendo.data.Node, element: JQuery, options: KendoTreeViewOptions, invokeOnCheck: boolean = true) {
        const checked = node.get("checked");

        if (checked)
            element.addClass("checked");
        else
            element.removeClass("checked");

        if (invokeOnCheck)
            options.onCheck(node);
    }

    private static registerDisposeFor($element: JQuery, widgetName: string): void {
        const htmlNode = $element[0];
        addDisposeCallback(
            htmlNode, () => {
                const widgetInstance = $element.data(widgetName) as kendo.ui.TreeView;

                if (widgetInstance) {
                    const sub = (widgetInstance.options as KendoTreeViewOptions).filterSubscription;
                    if (sub)
                        sub.dispose();

                    widgetInstance.destroy();
                    $element.empty();
                }
            });
    }

    public static registerKnockoutBinding(): void {
        registerBinding(
            KendoTreeView.widgetName, {
                update(element: HTMLElement, valueAccessor: any) {
                    const $element = $(element);

                    KendoUtils.ensureValidElement(KendoTreeView.widgetName, "div", $element);
                    KendoTreeView.rebindTreeView($element, valueAccessor());
                }
            });
    }
}

export function register() {
    KendoTreeView.registerKnockoutBinding();
}
