/// <amd-module name="Core/Medius.Core.Web/Scripts/components/topBar/drawer/Drawer"/>
import * as React from "react";
import { useState, useRef, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { Popup } from '@progress/kendo-react-popup';
import DrawerApp from "./DrawerApp";
import { get } from "Core/Medius.Core.Web/Scripts/Medius/core/fetch/rest";
import { useAbortController } from "Core/Medius.Core.Web/Scripts/shared/hooks/useAbortController";
import { aborted } from "Core/Medius.Core.Web/Scripts/Medius/core/fetch/rest";
import { handleError } from "Core/Medius.Core.Web/Scripts/lib/errorHandling/errorHandler";
import { getBasePath } from "Core/Medius.Core.Web/Scripts/Medius/lib/path";
import { getTopBarContextStoreActionByNameIfExists } from "../topBarContextState";
import { store } from "Core/Medius.Core.Web/Scripts/shared/store/reduxStore";
import { setApps, selectApps } from "./appState";
import { IconSystemDotsNineBold } from "@medius/ui-controls";

type ClickResult = "AllowNavigation" | "PreventNavigation";

type DrawerProps = {
    readonly onAppClicked?: (appName: string, appPath: string) => ClickResult;
};

export default function Drawer({ onAppClicked }: DrawerProps) {
    const [drawerState, setDrawerState] = useState({ expanded: false, mouseOnDrawer: false });
    const inputEl = useRef(null);
    const apps = useSelector(selectApps);
    const abortController = useAbortController();

    const LogosDirectory = `${getBasePath()}Content/images/`;
    const isVisible = apps?.length > 0;

    const handleDrawerClick = () => {
        setDrawerState(prevState => ({ expanded: !prevState.expanded, mouseOnDrawer: true }));
    };

    const handleOnAppClick = (e: React.MouseEvent<HTMLAnchorElement>, appName: string) => {
        setDrawerState(prevState => ({ expanded: !prevState.expanded, mouseOnDrawer: prevState.mouseOnDrawer }));

        if (onAppClicked) {
            const appPath = apps?.find(x => x.name === appName)?.path;
            const result = onAppClicked(appName, appPath);

            if (result === "PreventNavigation") {
                e.preventDefault();
                return;
            }
        }

        const topBarContext = getTopBarContextStoreActionByNameIfExists(appName);
        if (topBarContext)
            store.dispatch(topBarContext);
    };

    const clickEventListener = () => {
        setDrawerState(prevState => {
            if (!prevState.mouseOnDrawer) {
                return { expanded: false, mouseOnDrawer: prevState.mouseOnDrawer };
            }
            else {
                return prevState;
            }
        });
    };

    function onPopupClose() {
        setDrawerState({ expanded: false, mouseOnDrawer: false });
    }

    function handleHoverOff() {
        setDrawerState(prevState => ({ expanded: prevState.expanded, mouseOnDrawer: false }));
    }

    function handleHoverOn() {
        setDrawerState(prevState => ({ expanded: prevState.expanded, mouseOnDrawer: true }));
    }

    const fetchApps = useCallback(async () => {
        try {
            const fetchedApps: Array<AppDto> = await get("app", { signal: abortController.signal });

            if (fetchedApps === null)
                return;

            store.dispatch(setApps(fetchedApps));
        } catch (err) {
            if (aborted(err))
                return;

            handleError(err);
        }
    }, [abortController.signal]);

    useEffect(() => {
        document.addEventListener('click', clickEventListener, false);
        fetchApps();

        return () =>
            document.removeEventListener('click', clickEventListener, false);
    }, [fetchApps]);

    return (
        isVisible &&
        <div className="drawer-icon-backgroud">
            <div onMouseEnter={handleHoverOn} onMouseLeave={handleHoverOff}>
                <div ref={inputEl} className="top-bar-menu" onClick={handleDrawerClick} data-testid={'drawer-apps'}>
                    <div className={drawerState.expanded ? "top-bar__icon_padding" : "top-bar__icon_padding top-bar__icon_padding_closed"}>
                        <div
                            className="top-bar__icon_container"
                            style={drawerState.expanded ?
                                {"filter": "invert(1)", "backgroundColor": "#E2D7CC"} :
                                {"filter": "invert(0)", "backgroundColor": "#FFF"}
                            }
                        >
                            <IconSystemDotsNineBold size="large" />
                        </div>
                    </div>
                </div>
                <Popup
                    anchor={inputEl.current}
                    show={drawerState.expanded}
                    className={'drawer-wrapper'}
                    popupClass={'popup-content'}
                    onClose={onPopupClose} >
                    <div className="drawer-apps">
                        {apps.map((app) =>
                            <DrawerApp
                                key={app.name}
                                path={app.path}
                                LogoUrl={LogosDirectory + app.logoFileName}
                                name={app.name}
                                type={app.type}
                                onClick={handleOnAppClick} />)
                        }
                    </div>
                </Popup>
            </div>
        </div>
    );
}
