/* eslint-disable react/no-unknown-property */
///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/Components/FeedbackModal"/>

import { ModalDialog, TextArea, Text, Button } from "@medius/ui-controls";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { sendFeedback } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/services/agentServices";
import { success } from "Core/Medius.Core.Web/Scripts/Medius/core/notification";

import * as React from "react";

interface FeedbackModalProps {
    isOpen: boolean,
    onClose: () => void,
    documentId: number;
    conversationId: string;
    invoiceType: string;
    workflowStepName: string;
    onConfirm?: () => void;
}

export function FeedbackModal({isOpen, onClose, documentId, conversationId, invoiceType, workflowStepName, onConfirm}: FeedbackModalProps) {
    const [customFeedback, setCustomFeedback] = React.useState("");

    const submitFeedback = (feedback: string) => {
        sendFeedback(conversationId, documentId, feedback, workflowStepName, invoiceType).then(() => {
                success(
                    getLabelTranslation("#Core/agentThankYouForLettingUsImproveMediusAgent"), 
                    getLabelTranslation("#Core/agentFeedbackSent")
                );
                setCustomFeedback("");
                onClose();
                if(onConfirm) {
                    onConfirm();
                }
            }
        );
    };

    return <ModalDialog
        close-ai-agent-modal="false"
        isOpen={isOpen} 
        onRequestClose={() => onClose()} 
        title={getLabelTranslation("#Core/submitFeedbackToMedius")}
        width={"small"}
        renderContent={() => <>{
            <div close-ai-agent-modal="false">
                <TextArea 
                    value={customFeedback} 
                    data-testid="agent-feedback-more-text" 
                    maxLength={4000} 
                    onChange={event => setCustomFeedback(event.value)} 
                    placeholder={getLabelTranslation("#Core/agentGiveAsMuchDetails")} 
                    width="fill" 
                />
                <Text variant="paragraph">
                    {getLabelTranslation("#Core/agentCustomFeedback")}
                </Text>
            </div>
        }</>}
        renderActions={() => {
            return <>
                    <Button 
                        label={getLabelTranslation("#Core/cancel")} 
                        data-testid="agent-feedback-more-cancel" 
                        variant="secondary" 
                        close-ai-agent-modal="false" 
                        onClick={() => onClose()} 
                    />
                    <Button 
                        label={getLabelTranslation("#Core/send")} 
                        data-testid="agent-feedback-more-send" 
                        close-ai-agent-modal="false" 
                        onClick={() => submitFeedback(customFeedback)} 
                    />
                </>;
            }
        }
        actionsAlignment="center" 
        showActionsDivider={true}
    />;
}