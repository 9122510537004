/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/document/tabManager"/>
import {observable, pureComputed} from "knockout";
import {get} from "Core/Medius.Core.Web/Scripts/Medius/core/rest";
import {extend} from "underscore";
import {bindShortcutWithLogging, nonInputShortcutCallback} from "Core/Medius.Core.Web/Scripts/lib/shortcutManager";
import type = require("Core/Medius.Core.Web/Scripts/Medius/core/type");
import { getCustomDraftTabs, isDraftTabVisible } from "Core/Medius.Core.Web/Scripts/customTabs/customTabs";
import { prepaymentType } from "Core/Medius.Core.Web/Scripts/shared/prepaymentType";

class TabManager {
    public Tabs = observable({
        Left: [],
        Right: []
    });
    public LeftVisible = pureComputed(() => this.Tabs().Left.length > 0);
    public RightVisible = pureComputed(() => this.Tabs().Right.length > 0);
    public TabsSub: ko.Subscription;
    public activateNextTab: () => void;
    public activatePreviousTab: () => void;
    constructor() {
        this.TabsSub = this.Tabs.subscribe(tabs => {
            cleanupActive(tabs.Left);
            cleanupActive(tabs.Right);
        });

        this.activateNextTab = () => {
            const tabsEl = getTabsDOMElements(this.Tabs().Right);
            const activeEl = getActiveTab(tabsEl);
            const nextElIndex = (tabsEl.indexOf(activeEl) + 1) % tabsEl.length;

            tabsEl[nextElIndex].children().tab("show");
        };

        this.activatePreviousTab = () => {
            const tabsEl = getTabsDOMElements(this.Tabs().Right);
            const activeEl = getActiveTab(tabsEl);
            const nextElIndex = (tabsEl.indexOf(activeEl) - 1 + tabsEl.length) % tabsEl.length;

            tabsEl[nextElIndex].children().tab("show");
        };
    }

    private getDocumentType(documentModel: any) {
        return type.getTypeName(documentModel.$type());
    }

    public loadCustomDraftTabs(documentModel: any) {
        let tabsVms = getCustomDraftTabs()
            .filter((x: any) => x.ForType === this.getDocumentType(documentModel))
            .map((tab: any) => {
                return extend({}, tab,
                    {
                        documentViewModel: documentModel,
                        tabViewPath: ["Display", tab.ForType, tab.ViewName].join("/"),
                        tabId: "document-tab" + tab.Id
                    });
            });

        tabsVms = tabsVms.filter((tab: any) => isDraftTabVisible(tab.tabViewPath, documentModel)).sort();
        
        this.Tabs({
            Left: tabsVms.filter((tab: any) => tab.LeftSide),
            Right: tabsVms.filter((tab: any) => !tab.LeftSide)
        });

        bindShortcutWithLogging("SwitchToNextTabInCreate", nonInputShortcutCallback(this.activateNextTab));
        bindShortcutWithLogging("SwitchToPreviousTabInCreate", nonInputShortcutCallback(this.activatePreviousTab));
    }

    private useCustomTabs(documentModel: any) {
        const documentTypesUsingCustomTabs = [prepaymentType];

        return !!documentTypesUsingCustomTabs.find(x => x === this.getDocumentType(documentModel));
    }

    public loadTabs(documentModel: any) {
        if (this.useCustomTabs(documentModel)) {
            this.loadCustomDraftTabs(documentModel);
            return $.Deferred().resolve();
        }

        const docType = type.getTypeName(documentModel.$type());
        return get("DocumentReferenceDataService", `tabs/${docType}`).then((tabs: any) => {
            const tabsVms = tabs.map((tab: any) => {
                return extend({}, tab,
                    {
                        documentViewModel: documentModel,
                        tabViewPath: ["Display", tab.ForType, tab.ViewName].join("/"),
                        tabId: "document-tab" + tab.Id
                    });
            });

            this.Tabs({
                Left: tabsVms.filter((tab: any) => tab.LeftSide),
                Right: tabsVms.filter((tab: any) => !tab.LeftSide)
            });

            bindShortcutWithLogging("SwitchToNextTabInCreate", nonInputShortcutCallback(this.activateNextTab));
            bindShortcutWithLogging("SwitchToPreviousTabInCreate", nonInputShortcutCallback(this.activatePreviousTab));
        });
    }

    public dispose() {
        this.LeftVisible.dispose();
        this.RightVisible.dispose();
        this.TabsSub.dispose();
    }
}

function cleanupActive(tabsCollection: any) {
    /*
     * This is workaround for Bootstrap limitation, where multiple initial active tabs
     * or tab collections with no active tab are displayed incorrectly.
     *
     * Here, we just keep only single (first found) active tab on each side actually active, others
     * are set as inactive so the displaying doesn't break.
     */
    let hasActive = false;

    tabsCollection.forEach((tab: any) => {
        if (hasActive) {
            tab.IsDefault = false;
        } else if (tab.IsDefault) {
            hasActive = true;
        }
    });

    if (!hasActive && tabsCollection[0]) {
        tabsCollection[0].IsDefault = true;
    }
}

export function create() {
    return new TabManager();
}

/* These are DOM helper methods, global side effects so use carefully */
function getTabsDOMElements(tabs: any[]) {
    return tabs.map(tab => $('a[href="#' + tab.tabId + '"]').parent());
}

function getActiveTab(tabsEl: JQuery[]) {
    return tabsEl.find(tabEl => tabEl.hasClass("active"));
}
