///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/context/mode"/>
const modes = {
    update: "update",
    create: "create",
    serialize: "serialize",
    none: "none"
};

export = function modeDecorator(context:any) {
    const currentModeStack = [modes.none];

    context.mode = {
        update: function() {
            currentModeStack.push(modes.update);
        },
        create: function() {
            currentModeStack.push(modes.create);
        },
        serialize: function() {
            currentModeStack.push(modes.serialize);
        },
        reset: function() {
            currentModeStack.pop();
        },
        get: function() {
            return currentModeStack[currentModeStack.length - 1];
        },
        getFullStack: function() {
            return currentModeStack;
        }
    };

    context.isUpdating = function() {
        return context.mode.get() === modes.update;
    };

    context.isSerializing = function () {
        return context.mode.get() === modes.serialize;
    };

    context.isCreating = function() {
        return context.mode.get() === modes.create;
    };

    context.isIdle = function() {
        return context.mode.get() === modes.none;
    };

    context.finalize.add(context.mode.reset);
};