/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/autocompleter/dataProvider/roleProvider"/>
import * as ko from "knockout";
import { Provider } from "Core/Medius.Core.Web/Scripts/Medius/apps/autocompleter/dataProvider/companyRelated";

class RoleProvider extends Provider {
    public roleName: string;

    constructor(context: any, companyId: ko.Computed<number>, buildViewModel: boolean, roleName: string) {
        super(context, "GetRolesAvailableForDistribution", "lightApi/RoleAutocompletersService", companyId, buildViewModel);
        this.roleName = roleName;
    }

    public getParameters(phrase: string, limit: number) {
        return {
            searchText: phrase,
            maxResults: limit,
            companyId: ko.unwrap(this.companyId) || this.context.getData().companyId,
            roleName: this.roleName
        };
    }
}

export function create(context: any, companyId: ko.Computed<number>, buildViewModel: boolean = true, roleName: string) {
    return new RoleProvider(context, companyId, buildViewModel, roleName);
}
