/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/default/longText/errors/badRequest"/>
import * as typeBoundResolverFactory from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/typeBound";

export const resolver = typeBoundResolverFactory.create({
    priority: 0,

    type: "Medius.Services.FaultDetails.BadRequest",

    resolve: function (response: any) {
        if (!response.Message) {
            return {};
        }

        return {
            longText: response.Message
        };
    }
});
