/// <amd-module name="Core/Medius.Core.Web/Scripts/shared/bindings/importantVisible"/>
import {bindingHandlers, utils} from "knockout";

// This binding is a bit hacky, only use case for it is when native display binding is not working due to !important modifier in css classes which we
// don't have control of. Originaly introduced for kendo classes
const binding = {
    update(element: HTMLElement, valueAccessor: any) {
        const show = utils.unwrapObservable(valueAccessor());
        if (!show) {
            element.style.setProperty("display", "none", "important");
        } else {
            element.style.display = "";
        }   
      } 
};

export function registerBinding() {
    bindingHandlers.importantVisible = binding;
}
