/// <amd-module name="Core/Medius.Core.Web/Scripts/shared/bindings/highlightedText"/>
import * as $ from "jquery";
import Suggestion from "Core/Medius.Core.Web/Scripts/components/quickSearch/suggestion/suggestion";

const koUtils = require("Core/Medius.Core.Web/Scripts/Medius/knockout/utils");

const highlightedText = {
    update(element: HTMLTextAreaElement, valueAccessor: any, allBindings: any, viewModel: any, bindingContext: any) {
        const css = "quick-search-suggestion__highlighted";
        const suggestion: Suggestion = bindingContext.$data;
        let text: string = valueAccessor();
        let searchPhrase: string = suggestion.searchTerm;        

        text = $("<div/>").text(text).html(); // escape html tags

        if (searchPhrase.trim().length === 0) {
            element.innerHTML = text;
            return;
        }

        searchPhrase = searchPhrase.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // escape regex special characters
        const replacement = (match: any) => `<span class="${css}">${match}</span>`;

        element.innerHTML = text.replace(new RegExp(searchPhrase, "gi"), replacement);
    }
};

koUtils.registerBinding("highlightedText", highlightedText);
