/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/autocompleter/dataProvider/paymentTermSearchController"/>
import * as rest from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rest";
import resolve from "Core/Medius.Core.Web/Scripts/Medius/apps/metadata/resolvers/paymentTerm";
import { DataProvider } from "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/default/dataProvider";

class PaymentTermSearchController extends DataProvider {
    public context: any;
    private documentId: number;

    constructor(context: any, documentId: number, private readonly controllerName: string) {
        super();
        this.context = context;
        this.documentId = documentId;
    }

    public get(phrase: string, limit: number) {
        const params = {
            phrase: phrase,
            limit: limit
        };
        return rest.get(this.controllerName, `${this.documentId}/payment-terms`, params)
            .then((paymentTerms: IPaymentTerm[]) => {
                const results = addDisplayNames(paymentTerms);
                return {
                    Results: results
                };
            });
    }

    public search(phrase: string, limit: number) {
        return this.get(phrase, limit);
    }
}

export function addDisplayNames(paymentTerms: IPaymentTerm[]) {
    return paymentTerms.map(addDisplayName);
}

export function addDisplayName(paymentTerm: IPaymentTerm) {
    if (!paymentTerm)
        return null;
    paymentTerm.DisplayName = resolve(paymentTerm).text;
    return paymentTerm;
}

export interface IPaymentTerm {
    Id: number;
    Name: string;
    Type: string;
    PaymentTermId: string;
    DisplayName: string;
}

export function create(context: any, documentId: number, controllerName: string) {
    return new PaymentTermSearchController(context, documentId, controllerName);
}
