/// <amd-module name="Core/Medius.Core.Web/Scripts/components/topBar/services/documentService"/>
import * as rpc from "Core/Medius.Core.Web/Scripts/Medius/core/rpc";

export interface DocumentTypeDto {
    readonly Type: string;
    readonly NameTranslationKey?: string;
}

export function getDocumentTypes(): JQueryPromise<DocumentTypeDto[]> {
    return rpc.ajax("UiDocumentManager", "GetDocumentTypesForCreate");
}
