///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/approvalGroup/model"/>
import * as baseModel from "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/default/model";
import * as dataProviderFactory from "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/approvalGroup/dataProvider";
import * as baseDefaultsFactory from "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/default/defaults";
import { createParameters } from "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/utils";

export function create(bindingParams: any) {
    const providerOptionsExtension = {
        company: bindingParams.company
    },
        optionsExtension = {
            disabled: bindingParams.disabled,
            required: bindingParams.required
        },
        params = createParameters(bindingParams, optionsExtension, providerOptionsExtension,
            baseDefaultsFactory, dataProviderFactory);

    return baseModel.create(params);
}