///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/newTooltip/bindings"/>
import * as tooltipFactory from "Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/newTooltip/model";
import * as skins from "Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/newTooltip/skins";
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as ko from "knockout";
import * as _ from "underscore";

function getInnerBindingParams (element:any, valueAccessor:any, allBindingsAccessor:any, viewModel:any, bindingContext:any) {
    const template = valueAccessor().template || 'default-tooltip-template';
    const data = ko.utils.unwrapObservable(valueAccessor().data || valueAccessor());
    const innerBindingContext = bindingContext.createChildContext(data);

    function innerValueAccesor () {
        return {
            name: template,
            data: data
        };
    }

    return [element, innerValueAccesor, allBindingsAccessor, data, innerBindingContext];
}

function getExtendedAccessors(valueAccessors:any) {
    const skinName = "newPopdown";
    const options = _(valueAccessors().options || {}).extend(skins.resolve(skinName), {
                isNewPopdown: true
            }, valueAccessors().options);
    const extendedAccessors = function () {
            const accessors = _({
                headerTemplate: 'default-popdown-header-template',
                bodyTemplate: 'default-popdown-body-template',
                footerTemplate: 'default-popdown-footer-template',
                template: "default-popdown-template",
                headerData: {},
                bodyData: {},
                footerData: {}
            }).extend(valueAccessors(), {
                options: options
            });

            return accessors;
        };
    return extendedAccessors;
}

const genericTooltip = {
    init: function (element:any, valueAccessor:any, allBindingsAccessor:any, viewModel:any, bindingContext:any) {
        const options = $.extend(true, {}, valueAccessor().options);

        $.extend(options, {
            setContent: function () {
                if (valueAccessor().enabled !== undefined) {
                    this.enabled = ko.utils.unwrapObservable(valueAccessor().enabled);
                }
                this.tooltipElement.attr("data-bind", "notBindChilds: true");
                const params = getInnerBindingParams(this.tooltipContent[0], valueAccessor, allBindingsAccessor, viewModel, bindingContext);
                ko.bindingHandlers.template.update.apply(null, params);
            }
        });

        const tooltip = tooltipFactory.create(element, options);
        koUtils.addDisposeCallback(element, function() {
            tooltip.destroy();
        });
    },

    update: function (element:any, valueAccessor:any, allBindingsAccessor:any, viewModel:any, bindingContext:any) {
        const tooltipId = $(element).attr('tooltip-id');
        let enabled = true;

        if (valueAccessor().enabled !== undefined) {
            enabled = ko.utils.unwrapObservable(valueAccessor().enabled);
        }
        
        /* Make the tooltip update when refreshOn observable changes */
        if (valueAccessor().refreshOn !== undefined) {
            valueAccessor().refreshOn();
        }

        if (tooltipId) {
            const tooltip = $('#' + tooltipId).data('tooltip');
            tooltip.enabled = enabled;
            const params = getInnerBindingParams(tooltip.tooltipContent[0], valueAccessor, allBindingsAccessor, viewModel, bindingContext);
            ko.bindingHandlers.template.update.apply(null, params);
            if (enabled) {
                tooltip.updatePositionIfVisible();
            } else {
                tooltip.hide();
            }
        }
    }
};

const newTooltip = {
    init: function (element:any, valueAccessors:any, allBindingsAccessor:any, viewModel:any, bindingContext:any) {
        const skinName = valueAccessors().skin;
        const options = _({}).extend(skins.resolve(skinName), {
                showOn: "mouseenter",
                hideOn: "mouseleave"
            });
        const extendedAccessors = function () { return _({}).extend(valueAccessors(), { options: options }); };
        
        return ko.bindingHandlers.genericTooltip.init(element, extendedAccessors, allBindingsAccessor, viewModel, bindingContext);
    },

    update: function (element:any, valueAccessors:any, allBindingsAccessor:any, viewModel:any, bindingContext:any) {
        return ko.bindingHandlers.genericTooltip.update(element, valueAccessors, allBindingsAccessor, viewModel, bindingContext);
    }
};

const newPopdown = {
    init: function (element:any, valueAccessors:any, allBindingsAccessor:any, viewModel:any, bindingContext:any) {
        return ko.bindingHandlers.genericTooltip.init(element,
            getExtendedAccessors(valueAccessors),
            allBindingsAccessor,
            viewModel,
            bindingContext);
    },

    update: function (element:any, valueAccessors:any, allBindingsAccessor:any, viewModel:any, bindingContext:any) {
        return ko.bindingHandlers.genericTooltip.update(element,
            getExtendedAccessors(valueAccessors),
            allBindingsAccessor,
            viewModel,
            bindingContext);
    }
};

const infoTooltip = {
    init: function(element:any, valueAccessors:any, allBindingsAccessor:any, viewModel:any, bindingContext:any) {
        const skinName = valueAccessors().skin;
        const options = _({}).extend(skins.resolve(skinName), {
                showOn: "mouseenter",
                hideOn: "mouseleave",
                position: {
                    my: 'left bottom',
                    at: 'right center',
                    collision: 'flipfit flipfit'
                }
            });
        const extendedAccessors = function () { return _({}).extend(valueAccessors(), { options: options }); };
        
        return ko.bindingHandlers.genericTooltip.init(element, extendedAccessors, allBindingsAccessor, viewModel, bindingContext);
    },

    update: function(element:any, valueAccessors:any, allBindingsAccessor:any, viewModel:any, bindingContext:any) {
        return ko.bindingHandlers.genericTooltip.update(element, valueAccessors, allBindingsAccessor, viewModel, bindingContext);
    }
};

const focusTooltip = {
    init: function (element:any, valueAccessors:any, allBindingsAccessor:any, viewModel:any, bindingContext:any) {
        const skinName = valueAccessors().skin;
        const options = _({}).extend(skins.resolve(skinName), {
                showOn: "focus",
                hideOn: "blur"
            }); 
        const extendedAccessors = function () { return _({}).extend(valueAccessors(), { options: options }); };

        return ko.bindingHandlers.genericTooltip.init(element, extendedAccessors, allBindingsAccessor, viewModel, bindingContext);
    },

    update: function (element:any, valueAccessors:any, allBindingsAccessor:any, viewModel:any, bindingContext:any) {
        if(valueAccessors().resultsVisible) {
            const resultsVisible = valueAccessors().resultsVisible();
            const tooltipId = $(element).attr('tooltip-id');
            if(tooltipId && resultsVisible) {
                $('#' + tooltipId).addClass('tooltip-results-visible');
            }
            if(tooltipId && !resultsVisible) {
                $('#' + tooltipId).removeClass('tooltip-results-visible');
            }
        }
        return ko.bindingHandlers.genericTooltip.update(element, valueAccessors, allBindingsAccessor, viewModel, bindingContext);
    }
};

export function register() {
    koUtils.registerBinding("genericTooltip", genericTooltip);
    koUtils.registerBinding("newTooltip", newTooltip);
    koUtils.registerBinding("focusTooltip", focusTooltip);
    koUtils.registerBinding("infoTooltip", infoTooltip);
    koUtils.registerBinding("newPopdown", newPopdown);
}