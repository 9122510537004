/// <amd-module name="Core/Medius.Core.Web/Scripts/Models/supplierInvoiceInEdi"/>
import { observable } from "knockout";
import { DocumentViewmodel } from "Core/Medius.Core.Web/Scripts/Models/document";
import { log } from "Core/Medius.Core.Web/Scripts/Medius/lib/logger";
import { each } from "underscore";
import { topLevelDocument as documentDescriptor } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/settings";
import * as utils from "Core/Medius.Core.Web/Scripts/Medius/mediusUtils";
import mappings = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/mapping/collection/instance");
import * as customFieldsPerspective from "Core/Medius.Core.Web/Scripts/Models/Entities/CustomFields/CustomFieldsPerspective";

export function viewModelFactory(viewModel: any, initialData: any, context: any) {
    const topLevelDocumentData = context.get(documentDescriptor);
    viewModel.IsInvoiceLinesLoaded = observable(false);

    DocumentViewmodel(viewModel, initialData, context);

    viewModel._ctx = context;
    viewModel.LinesCustomFieldsPerspective = observable(customFieldsPerspective.create());

    context.getData().documentId = viewModel.Id();

    viewModel.unload = function () {
        log("Document unload called, InvoiceNumber: {0} ", viewModel.InvoiceNumber());
        utils.resetAjaxCalls();
    };

    function loadInvoiceLines() {
        utils.ajax("GetInvoiceLinesForSupplierInvoice", "WrappingDocumentService", {
            wrappingDocumentId: viewModel.WrappingDocumentId
        }).done((data: any) => {
            each(data, (line: any) => {
                line.CustomFields.Perspective = viewModel.LinesCustomFieldsPerspective;
            });
            viewModel.Lines(context.create(data));

            viewModel.IsInvoiceLinesLoaded(true);
        });
    }

    function loadCodingLines() {
        if (viewModel.Accounting() == null) {
            return;
        }

        utils.ajax("GetCodingLinesForSupplierInvoice", "WrappingDocumentService", {
            wrappingDocumentId: viewModel.WrappingDocumentId
        }).done((data: any) => {
            viewModel.Accounting().DimensionStrings(context.create(data));
        });
    }

    if (topLevelDocumentData && topLevelDocumentData.isWrapping()) {
        viewModel.WrappingDocumentId = topLevelDocumentData.getId();
        loadInvoiceLines();
        loadCodingLines();
    }
}

export function registerMapping() {
    mappings.register("Medius.PurchaseToPay.Entities.SupplierInvoice", utils.IsInEDIStep, viewModelFactory);
}
