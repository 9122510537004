/// <amd-module name="Core/Medius.Core.Web/Scripts/customTabs/definitions/contracts/customCells"/>

import * as React from "react";
import { GridCellProps, GRID_COL_INDEX_ATTRIBUTE } from "@progress/kendo-react-grid";
import { getCultureCode } from "Core/Medius.Core.Web/Scripts/Medius/lib/utils/user";
import { getBasePath } from "Core/Medius.Core.Web/Scripts/Medius/lib/path";
import { IContractDto } from "./service";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { IconSecurityLockFill  } from '@medius/ui-controls';
import { logContractOpen } from "Core/Medius.Core.Web/Scripts/customTabs/definitions/contracts/metricLogger";

export const DateCell = (props: GridCellProps) => {
    return (
        <td data-testid="dateCell"
            className={props.isSelected ? 'k-state-selected' : ''}
            colSpan={props.colSpan}
            role={'gridcell'}
            aria-selected={props.isSelected}
            {...{ [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex }}>
            {toLocaleDate(props.dataItem[props.field])}
        </td>
    );
};

const toLocaleDate = (date: string) => {
    const parsedDate = Date.parse(date);
    if (!isFinite(parsedDate))
        return "";

    return new Date(parsedDate).toLocaleDateString(getCultureCode());
};

export const LinkCell = (props: GridCellProps) => {
    const element = props.dataItem as IContractDto;
    return (
        <td data-testid="contract-link-cell"
            className={props.isSelected ? 'k-state-selected' : ''}
            colSpan={props.colSpan}
            role={'gridcell'}
            aria-selected={props.isSelected}
            {...{ [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex }}>
                <div className="linkCell">
                    {
                        element.isAccessible ?
                        <a className="linkCell_link" onClick={logContractOpen} href={`${getBasePath()}#Tasks/ShowDocument/${element.id}`} target="_blank" rel="noreferrer">{element.number}</a>
                        : <span className="linkCell_text">{element.number}</span>
                    }
                    {
                        element.isAccessible || <div title={translate('#PurchaseToPay/contractsGrid/notAllowedContractTitle')}><IconSecurityLockFill  className="linkCell_locker"/></div>
                    }
                </div>
        </td>
    );
};

export const IsActiveCell = (props: GridCellProps) => {
    const element = props.dataItem as IContractDto;
    return (
        <td data-testid="isActiveCell"
            className={props.isSelected ? 'k-state-selected' : ''}
            colSpan={props.colSpan}
            role={'gridcell'}
            aria-selected={props.isSelected}
            {...{ [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex }}>
            {element.active ? translate('#Core/yes') : translate('#Core/no')}
        </td>
    );
};

export const PlainTestIdCell = (propsWithTestId: TestIdCellProps) => {
    const field = propsWithTestId.field || "";
    return (<td data-testid={propsWithTestId.dataTestId}
        className={propsWithTestId.isSelected ? 'k-state-selected' : ''}
        aria-selected={propsWithTestId.isSelected}
        {...{ [GRID_COL_INDEX_ATTRIBUTE]: propsWithTestId.columnIndex }}> 
            {propsWithTestId.dataItem[field].toString()}
    </td>);
};

export interface TestIdCellProps extends GridCellProps {
    dataTestId: string;
}