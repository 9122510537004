/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/provider/timeOperators"/>
import * as _ from "underscore";

const rangeOperators = ["lastMonth", "thisMonth", "nextMonth", "lastYear", "thisYear", "nextYear"],
    dayOperators = ["yesterday", "today", "tomorrow"];
        
export function getTimeValues() {
    return dayOperators.concat(rangeOperators);
}

export function getDateTimeValues(operator: string) {
    const values = dayOperators, acceptableOperators = ['!=', '<', '>', '<=', '>=', '=', 'is'];

    if (!_(acceptableOperators).contains(operator)) {
        return [];
    }

    if (operator === "is") {
        return values.concat(rangeOperators);
    }

    return values.sort();
}