///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Entities.Reminders.CompanyReminderConfiguration/CompanyReminderConfigurationMergedTab"/>

import { MergedCompanyConfigurationTab } from "Core/Medius.Core.Web/Scripts/Medius/models/mergedConfiguration/mergedCompanyConfigurationTab";
import * as reminderConfigurationsProperty from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Entities.Reminders.CompanyReminderConfiguration/ReminderConfigurationsProperty";

class CompanyDefaultReminderConfigurationTab extends MergedCompanyConfigurationTab {
    constructor(entityWithContext:any) {
        super(entityWithContext);
    }

    getConfigurationTypeName() {
        return "Medius.Core.Entities.Reminders.CompanyReminderConfiguration";
    }
    
    getTemplateName() {
        return "Core:templates/Administration/ReminderConfiguration/MergedConfiguration.html";
    }
    
    intializeConfigurationProperties(company:any, configurationData:any) {
        configurationData.addCustomProperty('ReminderConfigurations', "#Core/defaultNotifications", reminderConfigurationsProperty);
        configurationData.addSimpleProperty('IsNotificationsForMasterDataEnabled', "#Core/isNotificationsForMasterDataEnabled");

        configurationData.isRootCompany = (configurationData.Company.CompanyId() === "root");
    }
}

export = function(entityWithContext:any) {
    return new CompanyDefaultReminderConfigurationTab(entityWithContext);
};