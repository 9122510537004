///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/presenters/labels/model"/>
import * as ko from "knockout";
import * as _ from "underscore";
import * as helpers from "Core/Medius.Core.Web/Scripts/Medius/components/editors/helpers";
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as defaults from "Core/Medius.Core.Web/Scripts/Medius/components/presenters/labels/defaults";

class LabelsPresenter {
    params: { template: string; options?:any };
    Template: string;
    Options: any;
    labels: ko.Computed<any[]>;
    totalLabels: ko.Computed<number>;
    labelsDescriptions: ko.Computed<string>;
    constructor(bindingParameters:any) {
        this.params = defaults.get();
        helpers.mergeDefaults(bindingParameters, this.params);

        let labelsObservableArrayFromParameters = bindingParameters.value;

        if (!_.isArray(koUtils.unpack(bindingParameters.value))) {
            throw "medius/components/presenters/labels: value is not an array";
        }

        this.Template = this.params.template;
        this.Options = this.params.options;

        this.labels = ko.computed(() => {
            if(typeof labelsObservableArrayFromParameters === 'function') {
                labelsObservableArrayFromParameters = labelsObservableArrayFromParameters();
            }
            return _(labelsObservableArrayFromParameters).filter((label) => {
                return !!label && label.description;
            });
        });

        this.totalLabels = ko.computed(() => {
            return this.labels().length;
        });

        this.labelsDescriptions = ko.computed(() => {
            return this.labels()
                .map((label) => {
                    return label.longDescription;
                })
                .join(", ");
        });
    }

    dispose() {
        this.labels.dispose();
        this.totalLabels.dispose();
        this.labelsDescriptions.dispose();
    }
}

export function create(bindingParameters:any) {
    return new LabelsPresenter(bindingParameters);
}