/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/default/link/document"/>
import * as pathLib from "Core/Medius.Core.Web/Scripts/Medius/lib/path";
import {unpack} from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as typeBoundResolverFactory from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/typeBound";

export const resolver = typeBoundResolverFactory.create({
    priority: typeBoundResolverFactory.priority.lowest,
    type: "Medius.Data.Document",
    resolve: function (data: any) {
        const entityId = unpack(data.Id);

        return {
            link: pathLib.getBasePath() + ["#Tasks", "ShowDocument", entityId].join("/")
        };
    }
});