///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/widgets/InvoiceInsightsWidget/InvoiceInsightsWidget"/>
import * as React from "react";
import { InvoiceApprovalStatus, IInvoiceContextDto } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/agentInterfaces";
import { 
    IconWithTooltip, 
    FlexContent, 
    Text, 
    IconCommunicationChatFill, 
    IconAppsAppContractManagementRegular, 
    LinkButton, 
    IconStatusCheckCircleRegular, 
    IconStatusXCircleRegular, 
    IconStatusDisabledRegular, 
    IconSecurityLockFill,
} from "@medius/ui-controls";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { getUrlWithBase } from "Core/Medius.Core.Web/Scripts/Medius/lib/path";
import { present } from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/simplePresenter";
import { create as dateLocalizerCreate } from "Core/Medius.Core.Web/Scripts/Medius/components/editors/date/localizer";
import { createFromValues } from "Core/Medius.Core.Web/Scripts/Models/amount";
import { invoiceInsightsCommentsLimit } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/AgentConsts";
import { DatePresenter } from "Core/Medius.Core.Web/Scripts/shared/components/datePresenter";
import { getUserTimeProvider } from "Core/Medius.Core.Web/Scripts/lib/date/date";
import { WhoIsApprovingThisInvoice } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/widgets/InvoiceInsightsWidget/WhoIsApprovingThisInvoice";
interface InvoiceInsightsWidgetProps {
    invoiceContextDto: IInvoiceContextDto,
}

export function InvoiceInsightsWidget({invoiceContextDto}: InvoiceInsightsWidgetProps) {
    const timeProvider = getUserTimeProvider();

    const getPreviousInvoiceApprovalStatusIcon = (approvalStatus: InvoiceApprovalStatus) => {
        switch(approvalStatus) {
            case InvoiceApprovalStatus.Approved:
                return <IconWithTooltip 
                    text={getLabelTranslation("#Core/approved")} 
                    icon={<IconStatusCheckCircleRegular color="ok"/>}
                />;
            case InvoiceApprovalStatus.Rejected:
                return <IconWithTooltip 
                    text={getLabelTranslation("#Core/rejected")} 
                    icon={<IconStatusXCircleRegular color="error"/>}
                />;
            case InvoiceApprovalStatus.PartiallyApproved:
                return <IconWithTooltip 
                    text={getLabelTranslation("#Core/partiallyApproved")} 
                    icon={<IconStatusDisabledRegular color="warning"/>}
                />;
            case InvoiceApprovalStatus.Invalidated:
                return <IconWithTooltip 
                text={getLabelTranslation("#Core/invalidated")} 
                icon={<IconStatusXCircleRegular color="error"/>}
            />;
        }
    };


    return(
        <div data-testid="apa-copilot-invoice-insights">
            <Text variant="paragraph">
                {getLabelTranslation("#PurchaseToPay/apaCopilotInvoiceInsightsText")}
            </Text>
            <WhoIsApprovingThisInvoice predictedApprovers={invoiceContextDto.predictedNextApprovers} previousApproversPerStep={invoiceContextDto.authorizationHistoryUsersPerStep}/>
            <div className="mediusAgent__widgetCard">
                <FlexContent>
                    <Text variant="heading3">{getLabelTranslation("#PurchaseToPay/apaCopilotInvoiceInsightsAmountToApprove")}</Text>
                    <Text spacerMode="off" variant="heading1">{present(createFromValues(invoiceContextDto.amountToApprove.value, invoiceContextDto.amountToApprove.currency))}</Text>
                </FlexContent>
            </div>
            {invoiceContextDto.latestComments.length > 0 &&
                <div className="mediusAgent__widgetCard" data-testid="apa-copilot-latestcomments">
                    <div className="mediusAgent__widgetCard__iconAndContentContainer">
                        <div className="mediusAgent__widgetCard__iconAndContentContainer__icon"><IconCommunicationChatFill color="brand" /></div>
                        <FlexContent>
                            <Text variant="heading3">{getLabelTranslation("#PurchaseToPay/apaCopilotInvoiceInsightsLast3Comments")}</Text>
                            {invoiceContextDto.latestComments.slice(0, invoiceInsightsCommentsLimit).map((value, index) => (<div key={index}>
                                <Text variant="small"><b>{value.createdBy.firstName + value.createdBy.lastName + " "}</b>{"("}<DatePresenter date={timeProvider.toUserTimeZoneFromBrowserDate(new Date(value.createdDate))} format="g"/>{")"}</Text>
                                <Text spacerMode="off" variant="paragraph">{value.value}</Text>
                                </div>
                            ))}
                        </FlexContent>
                    </div>
                </div>
            }
            {invoiceContextDto.previousInvoices.length > 0 && 
                <div className="mediusAgent__widgetCard" data-testid="apa-copilot-previousinvoices">
                    <div className="mediusAgent__widgetCard__iconAndContentContainer">
                        <div className="mediusAgent__widgetCard__iconAndContentContainer__icon"><IconAppsAppContractManagementRegular color="brand" /></div>
                        <FlexContent>
                            <Text variant="heading3">
                                {getLabelTranslation(
                                    "#PurchaseToPay/apaCopilotInvoiceInsightsPreviousInvoicesFromSupplier", 
                                    invoiceContextDto.previousInvoices.length, 
                                    invoiceContextDto.allPreviousInvoicesCount === 1001 ? "1000+" : invoiceContextDto.allPreviousInvoicesCount
                                )}
                            </Text>
                            <table className="mediusAgent__previousInvoicesTable">
                                <thead>
                                    <tr>
                                        <th><Text variant="small">{getLabelTranslation("#PurchaseToPay/invoiceNumber")}</Text></th>
                                        <th><Text variant="small">{getLabelTranslation("#Enterprise/invoiceDate")}</Text></th>
                                        <th><Text variant="small">{getLabelTranslation("#Enterprise/totalAmount")}</Text></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {invoiceContextDto.previousInvoices.map((value, index) => (
                                        <tr key={index}>
                                            <td>
                                                { value.hasUserAccess ?
                                                    <LinkButton 
                                                        isTrustedUrl 
                                                        label={value.invoiceNumber} 
                                                        url={getUrlWithBase("#/Tasks/ShowDocument/")+value.id} 
                                                    /> : <div className="mediusAgent__previousInvoiceDisabledReflinkContainer">
                                                        <p>{value.invoiceNumber}</p>
                                                        <IconWithTooltip 
                                                            icon={<IconSecurityLockFill />} 
                                                            text={getLabelTranslation("#Core/insufficientPermissionsToOpenTheInvoice")} 
                                                        />
                                                    </div>
                                                }
                                            </td>
                                            <td>{dateLocalizerCreate("d").toString(new Date(value.invoiceDate))}</td>
                                            <td>{present(createFromValues(value.totalAmount.value, value.totalAmount.currency))}</td>
                                            <td>{getPreviousInvoiceApprovalStatusIcon(value.approvalStatus)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </FlexContent>
                    </div>
                </div>
            }
        </div>
    );
}