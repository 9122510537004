/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/metadata/entity/generator"/>
import * as type from "Core/Medius.Core.Web/Scripts/Medius/core/type";
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import { linkTemplates } from "Core/Medius.Core.Web/Scripts/Medius/components/metadata/entity/templates/link";
import { labelTemplates } from "Core/Medius.Core.Web/Scripts/Medius/components/metadata/entity/templates/label";
import * as _ from "underscore";
import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";

class EntityUrlCreator {
    defaultLink: any;
    defaultLabel: any;
    constructor(defaultLink: any, defaultLabel: any) {
        this.defaultLink = defaultLink;
        this.defaultLabel = defaultLabel;
    }

    resolveType(entity: any) {
        if (isNullOrUndefined(entity)) {
            return null;
        }

        const resolvedType = koUtils.unpack(entity.$type);

        if (isNullOrUndefined(resolvedType)) {
            return null;
        }

        return type.getTypeName(resolvedType);
    }

    resolveHierarchyType(entityType: string) {
        if (isNullOrUndefined(entityType)) {
            return [];
        }

        const dot = type.describe(entityType);

        if (!_.isObject(dot)) {
            return [];
        }

        return dot.Hierarchy;
    }

    resolveCreator(hierarchy: any, templateCollection: any) {
        if (_.isEmpty(hierarchy) || _.isEmpty(templateCollection)) {
            return null;
        }

        const key = _(hierarchy).find(function (typeName) {
            return (_.isFunction(templateCollection[typeName])) ? true : false;
        });

        if (isNullOrUndefined(key)) {
            return null;
        }

        return templateCollection[key];
    }

    createMetadata(defaultValue: any, entity: any, templateCollection: any) {
        if (isNullOrUndefined(entity)) {
            return defaultValue;
        }

        entity = koUtils.unpack(entity);
        const entityType = this.resolveType(entity);
        const typeHierarchy = this.resolveHierarchyType(entityType);
        const creator = this.resolveCreator(typeHierarchy, templateCollection);

        return (_.isNull(creator)) ? defaultValue : creator(entity, entityType);
    }

    createLabel(entity: any) {
        return this.createMetadata(this.defaultLabel, entity, labelTemplates);
    }

    createLabelFromProperties(entity: any, fields: any) {
        fields = koUtils.unpack(fields);

        if (isNullOrUndefined(fields) || _.isEmpty(fields)) {
            return this.createLabel(entity);
        }

        const parts: any[] = [];

        _(fields).each(function (property) {
            const value = koUtils.unpack(entity[property]);

            if (isNullOrUndefined(property) || isNullOrUndefined(value)) {
                return;
            }

            parts.push(value);
        });

        if (_.isEmpty(parts)) {
            return this.defaultLabel;
        }

        return parts.join(", ");
    }

    createUrl(entity: any) {
        return this.createMetadata(this.defaultLink, entity, linkTemplates);
    }
}

export const linkGenerator = new EntityUrlCreator("#", null);
