///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/validation/group/binding"/>
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as model from "Core/Medius.Core.Web/Scripts/Medius/components/validation/group/model";
import * as ko from "knockout";

const group: ko.BindingHandler = {
    init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
        const attachToParent = valueAccessor().attachToParent;
        let parentValidationGroup = bindingContext.validationGroup;
        const name = ko.unwrap(valueAccessor().name);

        let instance = model.create(attachToParent, parentValidationGroup, name);

        const components = valueAccessor().components || [];
        components.map((component:any) => {
            instance.registerEditorComponent(component);
        });

        const innerBindingContext = bindingContext.extend({ validationGroup: instance });

        koUtils.addDisposeCallback(element, () => {
            instance.dispose();
            instance = null;
            parentValidationGroup = null;
            delete innerBindingContext.validationGroup;
        });

        ko.applyBindingsToDescendants(innerBindingContext, element);

        return { controlsDescendantBindings: true };
    }
};

export function register() {
    koUtils.registerBinding("validation-group", group);
}