///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/export/async/starter" />

import * as exportLimits from "Core/Medius.Core.Web/Scripts/Medius/core/export/async/limits";
import * as localizer from "Core/Medius.Core.Web/Scripts/Medius/components/editors/number/localizer";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as ko from "knockout";
import { formatValueWithPlaceholders } from "../../../lib/stringFormat";

class AsyncExportStarter{
    performExport: () => void;
    CanPerformExport: ko.Computed;
    RowCountInformation: ko.Computed;

    constructor(asyncExportOperation: any, rowsCount: any) {
        const localizerAdapter = localizer.create(0);

        function formatResultsCount(message: any) {
            const totalRows = rowsCount();
            return formatValueWithPlaceholders(message, [localizerAdapter.toString(totalRows)]);
        }

        this.performExport = () => {
            asyncExportOperation();
        };
    
        this.CanPerformExport = ko.computed(function () {
            return exportLimits.isSlowExportLimitMet(rowsCount());
        });
    
        this.RowCountInformation = ko.computed(() => {    
            const translation = this.CanPerformExport()
                ? globalization.getLabelTranslation("#Core/asyncExportToExcelModalInformationPart1_resultsCount")
                : globalization.getLabelTranslation("#Core/disabledAsyncExportToExcelModalInformationPart1_resultsCount");
    
            const message = formatResultsCount(translation);
            return message;
        });
    }

    destroy(){
        this.CanPerformExport.dispose();
        this.RowCountInformation.dispose();
    }
}

export function create(asyncExportOperation: any, rowsCount: any) {
    return new AsyncExportStarter(asyncExportOperation, rowsCount);
}