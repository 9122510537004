/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/inbox/taskGroup/group" />
import * as $ from "jquery";
import * as ko from "knockout";
import * as taskGroupGrid from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/taskGroup/grid";
import * as task from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/task";
import * as helpers from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/helpers";
import * as rpc from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rpc";
import * as _ from "underscore";
import { Folder } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/folder";
import { isEmptyString, isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";

interface IOptions { 
    gridTitle: any;
    folderId: any;
    groupFullName: any;
    pageSizeStorageId: any;
    sortingOrder: any;
    sortingColumn: any;
}

export class TaskGroup { 
    options: IOptions = {} as IOptions;
    folder: Folder;
    fullName: any;
    storageId: string;
    displayName: any;
    showLabelColumn: any;
    showIndicatorsColumn: any;
    showCodingSuggestionInvoiceConfidenceColumn: any;
    availableLabels: ko.ObservableArray<any>;
    visible: ko.Observable<boolean>;
    columns: ko.ObservableArray<any>;
    totalTasks: ko.Observable<number>;
    tasks: ko.ObservableArray<any>;
    displayTasks: ko.Observable<boolean>;
    isTaskCountLoading: ko.Observable<boolean>;
    visibleTasks: ko.Computed<any>;
    totalNotProcessedTasks: ko.Computed<any>;
    totalTasksString: ko.Computed<any>;
    visibility: ko.Computed<boolean>;
    isCountLoading: ko.Computed<any>;
    grid: ko.Observable<taskGroupGrid.TaskGrid>;
    visibilitySub: ko.Subscription;
    displayTasksSub: ko.Subscription;
    fullscreenEnabledSub: any;

    constructor(folder: Folder, documentTasksWrapper: any, orderCriterias: any) {
        this.folder = folder;
        this.fullName = documentTasksWrapper.FullName;
        this.storageId = `folder(${this.folder.id}):${this.fullName}`;
        this.displayName = documentTasksWrapper.DisplayName;
        this.showLabelColumn = _.isUndefined(documentTasksWrapper.IsLabelColumnVisible) ? true : documentTasksWrapper.IsLabelColumnVisible;
        this.showIndicatorsColumn = _.isUndefined(documentTasksWrapper.IsIndicatorsColumnVisible) ? true : documentTasksWrapper.IsIndicatorsColumnVisible;
        this.showCodingSuggestionInvoiceConfidenceColumn = _.isUndefined(documentTasksWrapper.IsCodingSuggestionInvoiceConfidenceColumnVisible) ? true : documentTasksWrapper.IsCodingSuggestionInvoiceConfidenceColumnVisible;
        this.availableLabels = ko.observableArray(documentTasksWrapper.Labels || []);
        this.visible = ko.observable(true);
        this.columns = ko.observableArray(documentTasksWrapper.Columns);
        this.totalTasks = ko.observable(0);
        this.tasks = ko.observableArray(this.parseTasks(documentTasksWrapper.Tasks));
        this.displayTasks = ko.observable(true);
        this.isTaskCountLoading = ko.observable(false);
        
        this.visibleTasks = ko.computed(() => {
            return this.tasks().filter(t => t.visible());
        });

        this.totalNotProcessedTasks = ko.computed(() => {
            return this.totalTasks() + (this.visibleTasks().length - this.tasks().length);
        });

        this.totalTasksString = ko.computed(() => {
            return this.totalNotProcessedTasks();
        });

        this.visibility = ko.computed(() => {
            return this.visible() && this.visibleTasks().length > 0;
        });

        this.options.gridTitle = this.displayName;
        this.options.folderId = this.folder.id;
        this.options.groupFullName = this.fullName;
        this.options.pageSizeStorageId = this.storageId;

        this.isCountLoading = ko.computed(() => {
            return this.folder.inbox.isCountLoading() || this.isTaskCountLoading();
        });

        if (orderCriterias) {
            const orderCriteria = orderCriterias.filter((criteria: any) => {
                return criteria.DocumentType === this.fullName;
            })[0];
            if (orderCriteria) {
                this.options.sortingOrder = orderCriteria.Type;
                this.options.sortingColumn = orderCriteria.Path;
            }
        }
        
        this.grid = ko.observable(taskGroupGrid.create(this, this.options));

        const displayGrid = (visible: any) => {
            const grid = (visible) ? taskGroupGrid.create(this, this.options) : null;
            this.grid(grid);

            if (grid) {
                this.folder.inbox.selectedTasksHandler.tasks().forEach((selectedTask: any) => {
                    grid.markRow(selectedTask.id, true);
                });
            }
        };

        this.visibilitySub = this.visibility.subscribe((newValue) => {
            if (!!this.grid() && newValue) {
                return;
            }

            if (!this.folder.inbox.fullscreenEnabled()) {
                displayGrid(newValue);
            }
        });

        this.displayTasksSub = this.displayTasks.subscribe((newValue) => {
            displayGrid(newValue && this.visibility());
        });

        this.fullscreenEnabledSub = this.folder.inbox.fullscreenEnabled.subscribe((enabled: any) => {
            displayGrid(!enabled && this.displayTasks() && this.visibility());
        });
    }

    loadTasks(dataQuery: any): any {
        this.loadTotalTasks();
        return $.when(
            this.makeLoadTasksRequest(dataQuery)
        ).then((taskDtos) => {
            const newTasks = this.parseTasks(taskDtos);
            this.disposeTasks();
            this.tasks(newTasks);
        });
    }

    makeLoadTasksRequest(dataQuery: any): any {
        let filter = this.folder.inbox.search.currentCriteria();
        const sorting = helpers.resolveSortingOrder(dataQuery);
        const isSortingApplied = (!isEmptyString(sorting.key) && !isEmptyString(sorting.order)); //sprawdzic

        if (isNullOrUndefined(filter) && !isSortingApplied) {
            return rpc.lightApi("InboxService", "GetTasksForDocumentType", {
                documentType: this.fullName,
                folderId: this.folder.id,
                start: 0,
                count: dataQuery.pageSize
            });
        }

        if (isSortingApplied) {
            filter = filter || {};
            if (!filter.OrderCriteria) {
                filter.OrderCriteria = [];
            }
            filter.OrderCriteria = filter.OrderCriteria.filter((crit: any) => {
                return crit.DocumentType !== this.fullName && crit.FolderId !== this.folder.id;
            });
            filter.OrderCriteria.push({ DocumentType: this.fullName, Path: sorting.key, Type: sorting.order, FolderId: this.folder.id });
            this.folder.inbox.search.currentCriteria(filter);
        }

        const params = {
            documentType: this.fullName,
            folderId: this.folder.id,
            start: 0,
            filter: helpers.shallowCopySearchCriteria(filter),
            count: dataQuery.pageSize
        };

        if (params.filter && params.filter.OrderCriteria) {
            params.filter.OrderCriteria = params.filter.OrderCriteria.filter((crit: any) => {
                return crit.FolderId === this.folder.id;
            }).map(helpers.mapPersistedOrderCriteria);
        }

        return rpc.lightApi("InboxService", "GetTasksForDocumentType", params);
    }

    loadTotalTasks() {
        this.isTaskCountLoading(true);

        return this.makeLoadTotalTasksRequest().done((total) => {
            this.totalTasks(total);
        })
        .always(() => {
            this.isTaskCountLoading(false);
        });
    }

    makeLoadTotalTasksRequest() {
        const filter = helpers 
            .shallowCopySearchCriteria(this.folder.inbox.search.currentCriteria());
        const params = {
            documentType: this.fullName,
            folderId: this.folder.id,
            filter: filter
        };

        if (params.filter && params.filter.OrderCriteria) {
            params.filter.OrderCriteria = params.filter.OrderCriteria.filter((crit: any) => {
                return crit.FolderId === this.folder.id;
            }).map(helpers.mapPersistedOrderCriteria);
        }

        return rpc.lightApi("InboxService", "GetTasksCountForDocumentType", params);
    }

    parseTasks(taskDtos: any) {
        return taskDtos.map((taskDto: any) => {
            return task.create(this, taskDto);
        });
    }

    toggleDisplayTasks() {
        this.displayTasks(!this.displayTasks());
    }

    disposeTasks() {
        this.tasks().forEach((task) => {
            if (task && _.isFunction(task.dispose)) {
                task.dispose();
            }
        });
    }

    dispose() {
        this.disposeTasks();
        this.fullscreenEnabledSub.dispose();
        this.visibilitySub.dispose();
        this.displayTasksSub.dispose();
        this.visibleTasks.dispose();
        this.visibility.dispose();
        this.isCountLoading.dispose();
        this.totalTasksString.dispose();
        this.totalNotProcessedTasks.dispose();
    }
}

export function create(folder: Folder, documentTasksWrapper: any, orderCriterias: any) {
    return new TaskGroup(folder, documentTasksWrapper, orderCriterias);
}
