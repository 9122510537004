/*global window, global */
; (function () {
    'use strict';

    function Mock() {
        this.mock = true;
        this.moduleName = name;
    }

    var globals = typeof window !== 'undefined' ? window : global,
        modules = {},
        cache = {},
        has,
        expand,
        dirname,
        localRequire,
        initModule,
        require,
        redefine;

    if (typeof globals.require === 'function') {
        return;
    }

    has = function (object, name) {
        return ({}).hasOwnProperty.call(object, name);
    };

    expand = function (root, name) {
        var i, length, results = [], parts, part;
        if (/^\.\.?(\/|$)/.test(name)) {
            parts = [root, name].join('/').split('/');
        } else {
            parts = name.split('/');
        }
        for (i = 0, length = parts.length; i < length; i++) {
            part = parts[i];
            if (part === '..') {
                results.pop();
            } else if (part !== '.' && part !== '') {
                results.push(part);
            }
        }
        return results.join('/');
    };

    dirname = function (path) {
        return path.split('/').slice(0, -1).join('/');
    };

    localRequire = function (path) {
        return function (name) {
            var dir = dirname(path),
                absolute = expand(dir, name);

            return globals.require(absolute);
        };
    };

    initModule = function (name, definition) {
        var exports, module = { id: name, exports: {} };
        definition(module.exports, localRequire(name), module);
        exports = cache[name] = module.exports;

        return exports;
    };

    require = function (name) {
        var dirIndex, path = expand(name, '.');

        if (has(cache, path)) {
            return cache[path];
        }
        if (has(modules, path)) {
            return initModule(path, modules[path]);
        }

        dirIndex = expand(path, './index');
        if (has(cache, dirIndex)) {
            return cache[dirIndex];
        }
        if (has(modules, dirIndex)) {
            return initModule(dirIndex, modules[dirIndex]);
        }

        throw new Error('Cannot find module "' + name + '"');
    };

    redefine = function (bundle) {
        for (var key in bundle) {
            if (bundle.hasOwnProperty(key)) {
                if (has(bundle, key)) {
                    if (!globals.require.patchMode && modules[key]) {
                        throw new Error("Module " + key + " is being redeclared.");
                    }

                    modules[key] = bundle[key];
                    delete cache[key];
                }
            }
        }
    };

    globals.define = function (name, dependencies, moduleBody) {

        if (typeof dependencies === "function") {
            globals.define(name, [], dependencies);
            return;
        }

        var module = {},
            resolver = localRequire(name),
            wrapper = function (exports, require, module) {
                var dependentModules = [],
                    context = {
                        module: module,
                        require: require,
                        exports: exports,
                        globals: globals
                    },
                    exportsModule = {};

                for (var dependencyName in dependencies) {
                    if (dependencies.hasOwnProperty(dependencyName)) {
                        if (dependencies[dependencyName] === "exports") {
                            dependentModules[dependencyName] = exportsModule;
                        } else {
                            dependentModules[dependencyName] = resolver(dependencies[dependencyName]);
                        }
                    }
                }

                module.exports = _.extend((moduleBody.apply(context, dependentModules) || {}), exportsModule);
            };

        module[name] = wrapper;
        globals.require.redefine(module);
    };

    globals.require = require;
    globals.require.redefine = redefine;
    globals.require.patchMode = true;
    globals.require.brunch = true;
})();

