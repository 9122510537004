/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/entity/model" />

import * as defaultsFactory from "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/default/defaults";
import * as baseModel from "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/default/model";
const dataProviderFactory = require("Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/entity/dataProvider");
import * as utils from "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/utils";

export function create(bindingParams: any) {
    const optionsExtension = {
        disabled: bindingParams.disabled,
        required: bindingParams.required
    };
    const providerOptionsExtension = {
        entityType: bindingParams.type,
        company: bindingParams.company,
        fields: bindingParams.fields
    };
    const params = utils.createParameters(bindingParams, optionsExtension, providerOptionsExtension, defaultsFactory, dataProviderFactory);

    return baseModel.create(params);
}