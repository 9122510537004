///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/editors/quantity/model" />
import * as _ from "underscore";
import * as ko from "knockout";

import * as settingsProvider from "Core/Medius.Core.Web/Scripts/Medius/core/settingsProvider";
import * as numberLocalizerFactory from "Core/Medius.Core.Web/Scripts/Medius/components/editors/number/localizer";
import * as numberEditor from "Core/Medius.Core.Web/Scripts/Medius/components/editors/number/model";
import * as editorHelpers from "Core/Medius.Core.Web/Scripts/Medius/components/editors/helpers";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
const contextFactory = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/context");

const templateWithUnitName = "edit-quantity-default-with-unit";
const templateWithoutUnitName = "edit-quantity-default";

class QuantityEditor{
    UnitDescription: ko.Computed;
    ShowUnit: boolean;
    EnableUnit: boolean;
    AvaliableUnits: any;
    MinValue: any;
    MaxValue: any;
    Template: string;
    Resolution: ko.Observable;
    IsInteger: boolean;
    
    ChangeUnit: () => void;    
    dispose: () => void;

    constructor(bindingParameters: any, viewmodel: any, context: any, params: any){
        const vmContext = resolveContext(context);
        let parametersValueBinding: any;
        let synchronizedQuantity: any;
        let synchronizedQuantityBinding: any;
        let isDisplayValueUpdating: boolean;
        let editorDisplayValueBinding: any;
        const quantityDisplayValue = ko.observable();
        const quantityResolution = ko.observable();

        const quantityUnitDescription = ko.observable();
        function resolveContext(sourceContext: any) {
            sourceContext = sourceContext || contextFactory();
    
            // Workaround for nested ViewModelContext inside bindingContext.
            // See: "medius/components/bindings/componentsContext".
            // "componentsContext" binding is used in TaskTabs and Fullscreen views.
            return sourceContext?.context || sourceContext;
        }

        const showUnit = bindingParameters.options?.showUnit?? true;

        const configuration = _({
            align: "right",
            maxValue: null,
            minValue: null,
            isInteger: false,
            trimZeros: false,
            roundDecimal: "midpoint",
            notLocalized: false,
            resolution: 2,
            showUnit: showUnit,
            enableUnit: true,
            options: { defaultName: "editor-quantity" }
        }).extend(bindingParameters);

        configuration.template = !configuration.showUnit
            ? templateWithoutUnitName
            : templateWithUnitName;
        
        let numberLocalizer = ko.computed(() => {
            return numberLocalizerFactory.create(
                quantityResolution(),
                configuration.trimZeros,
                configuration.roundDecimal,
                configuration.notLocalized
            );
        });    
        
        
        function bindTo(value: any){
            let actualValue = value;
    
            if (ko.isObservable(value)) {
                // Here is small problem... When 'value' is initially observable with 'null'
                // inside, then Editor will be disabled forever, even observable changes its state
                // to 'not null' (a Quantity).
                //
                // For opposite case, when initially 'value' has some Quantity in it, and then it
                // changes into 'null', we will have "operable" control without any synchronization
                // to that observable 'value' (because we can't "talk" to NULL or UNDEFINED).
                //
                // But I think this isn't any valid usecase. This behaviour we can observe
                // at '/ComponentLibrary/Bindings#edit-quantity-examples' in a tab: Change.
                // There a button: 'Switch quantity' will produce NULL for 1/3 times.
    
                parametersValueBinding = value.subscribe(newQuantity);
                actualValue = value();
            }
    
            newQuantity(actualValue);
            return !!actualValue;
        }
    
        function validateNumber(value: any){
            if (configuration.disabled)
                return { valid: true };
    
            if (value === null || value == undefined){
                return {
                    valid: false,
                    validationDetails: getLabelTranslation("#Core/editorForValidation_amountFieldCannotBeEmpty")
                };
            }
    
            return editorHelpers
                .isValueInRange(value, configuration.minValue, configuration.maxValue);
        }

        function quantityUpdated(quantity: any){
            const resolution = settingsProvider.getQuantityDisplayResolution(quantity.UnitDescription);
    
            isDisplayValueUpdating = true;
    
            quantityResolution(resolution);
            quantityDisplayValue(quantity.DisplayValue());
            quantityUnitDescription(quantity.UnitDescription);
    
            isDisplayValueUpdating = false;
        }

        function displayEmptyQuantity(){
            isDisplayValueUpdating = true;
    
            quantityResolution(0);
            quantityDisplayValue("");
            quantityUnitDescription("");
    
            isDisplayValueUpdating = false;
        }

        function newQuantity(newValue: any){            
            if (synchronizedQuantityBinding)
                synchronizedQuantityBinding.dispose();
    
            if(newValue) {
                synchronizedQuantityBinding = newValue.onUpdated((data: any) => {
                    quantityUpdated(data.value);
                });
    
                synchronizedQuantity = newValue;
                quantityUpdated(newValue);
            }
            else {
                synchronizedQuantity = null;
                displayEmptyQuantity();
            }
        }

        function createNumberEditorIn(vm: any, bindingParams: any, outerParams: any){
            let localParams = _.clone(outerParams) || {};
            localParams = _(localParams).extend({ localizer: numberLocalizer });
    
            const baseEditor = numberEditor.create(bindingParams, viewmodel, context, localParams);
    
            _(vm).extend(baseEditor);
            return baseEditor;
        }

        if (!bindTo(configuration.value))
            configuration.disabled = true;
    
        configuration.value = quantityDisplayValue;
        configuration.resolution = quantityResolution;
        configuration.validator = validateNumber;
        
        let baseEditorInstance = createNumberEditorIn(this, configuration, params);

        this.ShowUnit = configuration.showUnit;
        this.EnableUnit = configuration.enableUnit && !configuration.disabled;
    
        this.UnitDescription = ko.computed(() => {
            return quantityUnitDescription() || "";
        });
    
        this.AvaliableUnits = settingsProvider.getAvaliableUnits();
    
        editorDisplayValueBinding = quantityDisplayValue
            .subscribe((value: any) => {
                if (!synchronizedQuantity)
                    return;
    
                if (!isDisplayValueUpdating)
                    synchronizedQuantity.update({
                        DisplayValue: value
                    });
            });
        
        this.ChangeUnit = function () {
            if (!synchronizedQuantity)
                return;
    
            const selectedUnit = vmContext.create(this);
            synchronizedQuantity.update({
                UnitDescription: selectedUnit.Description(),
                UnitId: selectedUnit.Id()
            });
        };
        
        this.dispose = () => {
            if (parametersValueBinding) {
                parametersValueBinding.dispose();
                parametersValueBinding = null;
            }
    
            if (synchronizedQuantityBinding) {
                synchronizedQuantityBinding.dispose();
                synchronizedQuantityBinding = null;
            }
    
            this.UnitDescription.dispose();
    
            if (editorDisplayValueBinding) {
                editorDisplayValueBinding.dispose();
                editorDisplayValueBinding = null;
            }
    
            if (numberLocalizer) {
                numberLocalizer.dispose();
                numberLocalizer = null;
            }
    
            if (baseEditorInstance) {
                baseEditorInstance.dispose();
                baseEditorInstance = null;
            }
        };
    }
}

export function create(bindingParameters: any, viewmodel: any, context: any, params: any) {
    return new QuantityEditor(bindingParameters, viewmodel, context, params);
}