/// <amd-module name="Core/Medius.Core.Web/Scripts/components/shortcutsModal/shortcutsModal"/>
import * as $ from "jquery";
import {bindShortcutBlockingDefaultAction} from "Core/Medius.Core.Web/Scripts/lib/shortcutManager";
import * as shortcutViewModel from "Core/Medius.Core.Web/Scripts/Medius/models/options/shortcuts";

export function register() {
    const manager = shortcutViewModel.bind($('div#keyboardShortcutsModal'), $('div#keyboardShortcutsModal'));

    $('a#present-keyboard-shortcut').click(manager.open);

    bindShortcutBlockingDefaultAction("ToggleShortcutModal", manager.toggleVisibility);
}
