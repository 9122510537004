///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/company/defaults"/>
import * as baseDefaultsFactory from "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/default/defaults";

export function create() {
    const baseDefaults = baseDefaultsFactory.create();

    return {
        ...baseDefaults,
        dataProviderOptions: {
            includeVirtual: true,
            includeReal: true,
            includeInactive: false
        },
        options: {
            useViewModels: true,
            useDefault: false,
            preselectFirstValue: true
        }
    };
}