/// <amd-module name="Core/Medius.Core.Web/Scripts/customTabs/definitions/contracts/requestReclassifyDialog"/>

import * as React from 'react';
import { useState } from 'react';
import { FC } from 'react';
import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { IContractDto } from "Core/Medius.Core.Web/Scripts/customTabs/definitions/contracts/service";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { LoadingPanel } from "Core/Medius.Core.Web/Scripts/shared/components/loadingPanel";

interface RequestReclassifyDialogProps {
    selectedContract: IContractDto;
    onClose: (action: DialogAction, item?: IContractDto) => Promise<void>;
}

enum DialogAction {
    Ok,
    Cancel
}

const RequestReclassifyDialog: FC<RequestReclassifyDialogProps> = (props: RequestReclassifyDialogProps) => {
    const { onClose, selectedContract } = props;
    const [isDataLoading, setIsDataLoading] = useState(false);
    const cancelClickHandler = async () => {
        setIsDataLoading(true);
        await onClose(DialogAction.Cancel);
    };

    const reclassifyClickHandler = async () => {
        setIsDataLoading(true);
        await onClose(DialogAction.Ok, selectedContract);
    };

    return (
        <>
            <Dialog width={300} autoFocus={true}>
                <div className="request-reclassify-dialog__container" data-testid="request-reclassify-dialog">
                    <div className="request-reclassify-dialog__header">
                        <header data-testid="request-reclassify-dialog-header">
                        {translate("#PurchaseToPay/reclassifyDialog/createRecurringInvoice")}
                        </header>

                        <div className="request-reclassify-dialog__header-close-button">
                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={cancelClickHandler}  data-testid="close-dialog">
                                <path d="M9.66659 1.27337L8.72659 0.333374L4.99992 4.06004L1.27325 0.333374L0.333252 1.27337L4.05992 5.00004L0.333252 8.72671L1.27325 9.66671L4.99992 5.94004L8.72659 9.66671L9.66659 8.72671L5.93992 5.00004L9.66659 1.27337Z" fill="#333333" />
                            </svg>
                        </div>
                    </div>
                    <div className="request-reclassify-dialog__heading-line-divider">
                        <div className="request-reclassify-dialog__line-divider" />
                    </div>
                

                    <div className="request-reclassify-dialog__content" data-testid="request-reclassify-dialog-content">
                        <div>
                            {translate("#PurchaseToPay/reclassifyDialog/documentWillBeReclassified")}
                        </div>

                        <div className="request-reclassify-dialog__content-details">
                            <div>
                                {translate("#PurchaseToPay/edidocumentimport/inbox/column/company")}: <b data-testid="request-reclassify-dialog-company">{selectedContract?.companyDto.name}</b>
                            </div>
                            <div>
                                {translate("#PurchaseToPay/edidocumentimport/inbox/column/supplier")}: <b data-testid="request-reclassify-dialog-supplier">{selectedContract?.supplierDto.name}</b>
                            </div>
                        </div>
                    </div>

                    <div className="request-reclassify-dialog__buttons-container">
                        <div><Button className="request-reclassify-dialog__cancelButton" data-testid="request-reclassify-dialog-cancel-button" onClick={cancelClickHandler}>{translate("#PurchaseToPay/reporting/cancel")}</Button>{isDataLoading && <LoadingPanel />}</div>
                        <Button className="reclassify-confirmation" data-testid="request-reclassify-dialog-reclassify-button" onClick={reclassifyClickHandler}>{translate("#PurchaseToPay/reclassifyDialog/reclassify")}</Button>
                    </div>
                </div>
            </Dialog>
        </>
    );
};

export { DialogAction, RequestReclassifyDialog};