///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/core/gadget/data"/>
import * as serialization from "Core/Medius.Core.Web/Scripts/Medius/lib/serialization";
import * as rpc from "Core/Medius.Core.Web/Scripts/Medius/core/rpc";

export class Data {
    QueryDocumentAgregatedForGadget: (gadgetDashboardId: any, queryKey: any) => any;
    getDataAsync: any;
    GetConfigurationObject: (contextCompanyId: any, entityTypeName: any, entityId: any, configurationObjectTypeName: any) => any;
    constructor() {
        this.getDataAsync = function(serviceName:string, functionName:string, params:any) {
            const request = rpc.ajax(serviceName, functionName, {
                    data: serialization.toJSON(params)
                }
            );

            return request;
        };

        this.GetConfigurationObject = (contextCompanyId:any, entityTypeName:any, entityId:any, configurationObjectTypeName:any) => {
            const params:any = {};
            params.contextCompanyId = contextCompanyId;
            params.entityTypeName = entityTypeName;
            params.entityId = entityId;
            params.configurationObjectTypeName = configurationObjectTypeName;

            return this.getDataAsync("ConfigurationService", "GetConfigurationEntity", params);
            };

            this.QueryDocumentAgregatedForGadget = (gadgetDashboardId:any, queryKey:any) => {
                const params = { gadgetDashboardId: gadgetDashboardId, queryKey: queryKey },
                request = this.getDataAsync("CoreGadgetService", "GetAgregatedDataForGadget", params);

            return request;
        };
    }
}