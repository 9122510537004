///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/workflow/management/workflowManagmentDataSource"/>
import * as $ from "jquery";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { DataSearchSource as DataSearchDataSource } from "Core/Medius.Core.Web/Scripts/Medius/components/grid/dataSource/dataSearch";


const typeFullName = "Medius.Tenant.WorkflowComponent.InstanceInfo";
const workflowFullName = "Medius.Tenant.WorkflowComponent.WorkflowInfoDescription";
        
export = class WorkflowManagementDataSource extends DataSearchDataSource {
    loadColumns() {
        const columns = [
            {
                ColumnName: globalization.getPropertyTranslation(`#${workflowFullName}/Name`),
                ValueSource: "WorkflowInfo.Name",
                ValueSourceType: "System.String",
                Searchable: true,
                Sortable: true,
                Width: 100
            },
            {
                ColumnName: globalization.getPropertyTranslation(`#${workflowFullName}/ApplicationName`),
                ValueSource: "WorkflowInfo.ApplicationName",
                ValueSourceType: "System.String",
                Searchable: true,
                Sortable: true,
                Width: 100
            },                       
            {
                ColumnName: globalization.getPropertyTranslation(`#${typeFullName}/Guid`),
                ValueSource: "Guid",
                ValueSourceType: "System.String",
                Searchable: true,
                Sortable: true,
                Width: 100
            },
            {
                ColumnName: globalization.getPropertyTranslation(`#${typeFullName}/State`),
                ValueSource: "State",
                ValueSourceType: "System.String",
                Searchable: true,
                Sortable: true,
                Width: 100
            }
        ];
        return $.Deferred().resolve(columns);
    }
};
