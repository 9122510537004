/// <amd-module name="Core/Medius.Core.Web/Scripts/core/attachments/binding/attachments"/>

import * as ko from "knockout";
import { Attachments } from "Core/Medius.Core.Web/Scripts/Medius/core/attachments/attachments";
import { domData, registerBinding } from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as $ from "jquery";

const attachmentsKey = "medius-attachments";

/**
* Attachments binding - loads attachments for given entity
*
* @example 
* <div data-bind="attachments: { 'id': 1, 'type': 'Medius.Core.Entities.Help.HelpRequest' }">
*/
const binding = {
    init(element: HTMLElement, bindingAccessor: any, allAccessor: any, viewModel: any, context: any) {
        let id: any = ko.utils.unwrapObservable(bindingAccessor().id) || null;
        let type: any = ko.utils.unwrapObservable(bindingAccessor().type) || null;
        const readOnly: any = ko.utils.unwrapObservable(bindingAccessor().readOnly) || false;
        const mainAttachmentMode: any = ko.utils.unwrapObservable(bindingAccessor().mainAttachmentMode) || false;
        const uploadImmediately: any = bindingAccessor().uploadImmediately || false;
        const tabId: any = $(element).parent().data("tabId");
        const currentAttachments: any = domData.get(element, attachmentsKey);
        let attachments: any, newContext: any;

        function setWrappedEntity() {
            if (type === "Medius.Core.Entities.DocumentRegistration.Draft, Medius.Core.Common") {
                type = viewModel.EntityViewModel().$type();
                id = viewModel.EntityViewModel().ViewId();
            }

            if (type === "Medius.Core.Entities.Integration.EDIDocumentImport, Medius.Core.Common" && ko.unwrap(viewModel.MappedContent)) {
                type = viewModel.MappedContent().Entity().$type();
                id = viewModel.MappedContent().Entity().ViewId();
            }
        }

        setWrappedEntity();

        if (!currentAttachments) {
            attachments = new Attachments(element, {
                tabId: tabId,
                entityViewId: id,
                entityType: type,
                readOnly: readOnly,
                mainAttachmentMode: mainAttachmentMode,
                uploadImmediately: uploadImmediately
            });

            newContext = context.createChildContext(attachments);
            ko.applyBindingsToDescendants(newContext, element);
            domData.set(element, attachmentsKey, context);
        }

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            attachments.destroy();
        });

        return { controlsDescendantBindings: true };
    },
    update(element: HTMLElement, ...args:any) {
        const currentAttachments = domData.get(element, attachmentsKey);

        if (!currentAttachments) {
            return (binding.init as any).apply(this, [element, ...args]);
        }
        return { controlsDescendantBindings: true };
    }
};

export function register() {
    registerBinding("attachments", binding);
}