/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/AuthorizationGroups/authorizationGroups"/>
import * as React from "react";
import { useState, useEffect, useCallback, useRef } from "react";
import { registerAdministrationEntity } from "Core/Medius.Core.Web/Scripts/AdminPages/administrationServices";
import { useLocalStorage } from "Core/Medius.Core.Web/Scripts/shared/hooks/useLocalStorage";
import { entityType, getPage, get, save, remove, hasAddAccess, getDocumentTypes, AuthorizationGroupsQueryData } from "./service";
import { SidebarNavigation } from "Core/Medius.Core.Web/Scripts/AdminPages/sidebarNavigation";
import { Splitter, SplitterPaneProps, TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { LoadingPanel } from "Core/Medius.Core.Web/Scripts/shared/components/loadingPanel";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { throttle } from "underscore";
import { success } from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import { AddButton } from "Core/Medius.Core.Web/Scripts/components/administration/buttons/addButton";
import { Title } from "Core/Medius.Core.Web/Scripts/components/administration/title";
import { AuthorizationGroupsGrid } from "./grid";
import { handleAnyError } from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import { AuthorizationGroup, AuthorizationGroupsHeaders, Clone, Company, DocumentType, GetEmpty, IsNewAuthorizationGroup, Optional } from "./authorizationGroup";
import { GeneralTab } from "./Tabs/generalTab";
import { LimitTab } from "./Tabs/limitTab";
import { ActionButtons } from "../../components/administration/buttons/actionButtons";
import { DimensionLimitsTab } from "./Tabs/dimensionLimitTab";
import { AuthorizationGroupDataHeader } from "./authorizationGroupDataHeader";
import { SupplierLimitsTab } from "./Tabs/supplierLimitsTab";
import { CompanyProvider } from "./contexts/companyContext";
import { GetClonedGridRowData, GetInitialGridState, GetNewGridRowData, IsNewRow } from "./gridState";
import { SortDescriptor } from "@progress/kendo-data-query";
import { GridProps } from "@progress/kendo-react-grid";
import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { useDebounce } from "use-debounce";

enum Tab {
    General = 0,
    GeneralLimit = 1,
    SupplierLimits = 2,
    DimensionLimits = 3,
    DeviationLimit = 4
}

export const AuthorizationGroups = () => {

    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => {
             mounted.current = false;
        };
    },[]);

    const defaultPaneSettings: SplitterPaneProps[] = [
        { size: '50%', min: '380px' },
        { min: '20px' }
    ];

    const [isLoading, setIsLoading] = useState<boolean>(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const [panesState, setPanesState] = useLocalStorage("authorizationGroups_panes", defaultPaneSettings);
    const [gridData, setGridData] = useState<Optional<AuthorizationGroupsHeaders> | null>(null);
    const [gridDataState, setGridDataState] = useState<GridProps>(GetInitialGridState());
    const [selectedItem, setSelectedItem] = useState<AuthorizationGroup | null>(null);
    const [selectedTab, setSelectedTab] = useState(Tab.General);
    const [canAdd, setCanAdd] = useState<boolean>(false);
    const [canEdit, setCanEdit] = useState<boolean>(false);
    const [canDelete, setCanDelete] = useState<boolean>(false);
    const [documentTypesList, setDocumentTypes] = useState<DocumentType[]>([]);
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [dimensionLimitsSort, setDimensionLimitsSort] = useState<SortDescriptor[]>([]);
    const [defaultDocumentTypeId, setDefaultDocumentTypeId] = useState<number>();

    const [debouncedFilter] = useDebounce(gridDataState.filter, 300);
    const setIsLoadingCallback = useCallback((loading:boolean) => {
        if(mounted.current)
        setIsLoading(loading);
    }, []);

    const onError = useCallback((response: JQueryXHR) => {
        handleAnyError(response);
    }, []);

    const onGridStateChange = (dataState: GridProps) => {
        setGridDataState(dataState);
    };

    const onGridDataChange = (data?: AuthorizationGroupsHeaders | null) => {
        setGridData(data);
    };

    const loadData = () => {
        const mapGridDataStateToQueryData = (): AuthorizationGroupsQueryData => {
            return {
                take: gridDataState.take,
                skip: gridDataState.skip,
                sort: gridDataState.sort && gridDataState.sort.length > 0
                    ? { Field: gridDataState.sort[0].field, Dir: gridDataState.sort[0].dir }
                    : null,
                filter: mapFilterToQueryData(gridDataState.filter)
            };
        };

        const mapFilterToQueryData = (filter?: CompositeFilterDescriptor) => {
            const filters: any[] = filter?.filters ?? [];
            const newFilterKeywords = {} as any;
            for (const columnFilter of filters) {
                // eslint-disable-next-line no-prototype-builtins
                if (!newFilterKeywords.hasOwnProperty(String(columnFilter.field)))
                    newFilterKeywords[String(columnFilter.field)] = columnFilter.value;
            }
            return newFilterKeywords;
        };

        return getPage(mapGridDataStateToQueryData())
            .then(headersData => {
                if(mounted.current) {
                    onGridDataChange(headersData);
                }
            })
            .fail(onError)
            .always(() => setIsLoadingCallback(false));
    };

    const onRowClick = (id: number) => {
        getItem(id);
    };

    const changeSelectedItemProperty = (propertyName: string) =>
        (newValue: any) => {
            setIsDirty(true);
            setSelectedItem(prev => prev ? { ...prev, [propertyName]: newValue } : null);
        };

    const addEmptyItem = () => {
        setCanEdit(true);
        let newItem = gridData?.Data.find(IsNewRow);

        if (!newItem) {
            newItem = GetNewGridRowData();
            onGridDataChange(gridData ? { ...gridData, Data: [newItem, ...gridData.Data] } : null);
        }

        setSelectedTab(Tab.General);
        setSelectedItem(GetEmpty(defaultDocumentTypeId));
    };

    const getItem = (id?: number, isNewlyCreated = false) => {
        if (selectedItem && selectedItem.Id === 0) {
            onGridDataChange(gridData ? { ...gridData, Data: [...gridData.Data.filter(gd => gd.Id !== 0)] } : null);
        }
        setIsLoadingCallback(true);
        get(id).then(item => {
            if(mounted.current){
                setSelectedItem(item ? item : null);
                if (isNewlyCreated) {
                    setSelectedTab(Tab.General);
                }
                setCanEdit(item?.CanUserUpdate ?? false);
                setCanDelete(item?.CanUserDelete ?? false);
                setIsDirty(false);
            }
        }).always(() => setIsLoadingCallback(false));
    };

    const saveItem = () => {
        const newlyCreatedItem = IsNewAuthorizationGroup(selectedItem);

        setIsLoadingCallback(true);
        save(selectedItem)
            .then(entity => {
                success(translate("#Core/changesSavedSuccessfully"));
                loadData();
                getItem(entity?.Id, newlyCreatedItem);
            })
            .fail(onError)
            .always(() => setIsLoadingCallback(false));
    };

    const cloneItem = () => {
        setCanEdit(true);
        const newItem = GetClonedGridRowData();
        onGridDataChange(gridData ? { ...gridData, Data: [newItem, ...gridData.Data] } : null);
        setSelectedTab(Tab.General);
        setSelectedItem(Clone(selectedItem));
    };

    const removeItem = () => {
        if(!selectedItem) return;

        setIsLoadingCallback(true);
        return remove(selectedItem.Id)
            .then(() => {
                success(translate("#Core/entityRemovedSuccessfully"));
                loadData();
                if(mounted.current) {
                    setSelectedItem(null);
                }
            })
            .fail(onError)
            .always(() => setIsLoadingCallback(false));
    };

    const companyDropdownDisabled = () => {
        return selectedItem?.Id !== 0;
    };

    const currencyDropdownDisabled = () => {
        return selectedItem?.Id !== 0;
    };

    const onCompanyChange = (newValue: Company) => {
        setIsDirty(true);
        setSelectedItem(prev => prev ? { ...prev, 
            ["Company"]: newValue, 
            ["Currency"]: newValue?.AccountingCurrency } : null);
    };

    useEffect(() => {
        hasAddAccess().then(({ Create }) => {
            if(mounted.current) {
                setCanAdd(Create);
            }});
    }, []);

    useEffect(() => {{
        if (selectedItem
            && selectedItem.Name !== ""
            && selectedItem.Company && selectedItem.Currency
            && !selectedItem.SupplierPermissions.some(x => x.Supplier === null)) {
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    }}, [selectedItem]);

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedFilter, gridDataState.take, gridDataState.skip, gridDataState.sort, gridDataState.pageSize]);

    useEffect(() => {
        getDocumentTypes()
            .then(documentTypes => {
                if(mounted.current) {
                    setDocumentTypes(documentTypes.documentTypeList ?? []);
                    setDefaultDocumentTypeId(documentTypes.defaultDocumentTypeId);
                }
            })
            .fail(handleAnyError);
    }, []);


    const resizeEventThrottled = useCallback(
        throttle(() =>
        resizeEvent(), 400),
        []);

    const resizeEvent = () => {
        const event = document.createEvent('Event');
        event.initEvent('resize', true, true);
        document.dispatchEvent(event);
    };

    return (<>
        <SidebarNavigation />
        <div className="authorizationGroups">
            <Splitter
                panes={panesState}
                onChange={(e) => {
                    setPanesState(e.newState);
                    resizeEventThrottled();
                }}>
                <div className="authorizationGroups__left-side">
                    {isLoading && <LoadingPanel />}
                    <div className="authorizationGroups__left-side__title-bar">
                        <Title label={translate("#Enterprise/authorizationGroup")} />
                        <AddButton
                            canAdd={canAdd}
                            label={translate("#Core/add")}
                            onClick={addEmptyItem}
                            disabled={isLoading}
                        />
                    </div>
                    <AuthorizationGroupsGrid
                        gridData={gridData}
                        gridDataState={gridDataState}
                        selectedItem={selectedItem}
                        onGridDataStateChanged={onGridStateChange}
                        onRowClick={onRowClick}
                    />
                </div>
                <div className="authorizationGroups__right-side">
                    {isLoading && <LoadingPanel />}
                    {selectedItem &&
                        <div className="authorizationGroups__contentPanel">
                            <ActionButtons
                                saveButtonLabel={translate("#Core/save")}
                                deleteButtonLabel={translate("#Core/delete")}
                                cloneButtonLabel={translate("#Core/clone")}
                                onSave={saveItem}
                                onDelete={removeItem}
                                onClone={cloneItem}
                                saveDisabled={isLoading || !isDirty || !isValid}
                                deleteDisabled={isLoading}
                                cloneDisabled={isLoading}
                                deleteVisible={canDelete && !IsNewAuthorizationGroup(selectedItem)}
                                saveVisible={canEdit}
                                cloneVisible={canEdit && !IsNewAuthorizationGroup(selectedItem) && !isDirty}
                            />
                            <AuthorizationGroupDataHeader
                                authorizationGroupName={selectedItem.Name} />
                            <CompanyProvider companyId={selectedItem?.Company?.Id}>
                                <TabStrip
                                    selected={selectedTab}
                                    onSelect={(e) => { setSelectedTab(e.selected); }}
                                    className="authorizationGroups__contentPanel__panels"
                                >
                                    <TabStripTab title={translate('#Enterprise/generalTabName')} contentClassName="authorizationGroups__contentPanel__panel">
                                        <GeneralTab
                                            authGroupId={selectedItem.Id}
                                            authGroupName={selectedItem.Name}
                                            company={selectedItem.Company}
                                            isActive={selectedItem.IsActive}
                                            companyDisabled={companyDropdownDisabled()}
                                            currency={selectedItem.Currency}
                                            selectedDocumentTypes={selectedItem.DocumentTypes}
                                            documentTypesList={documentTypesList}
                                            importedTimestamp={selectedItem.ImportedTimestamp}
                                            currencyDisabled={currencyDropdownDisabled()}
                                            externalSystemId={selectedItem.ExternalSystemId}
                                            onNameChange={changeSelectedItemProperty("Name")}
                                            onCompanyChange={onCompanyChange}
                                            onCurrencyChange={changeSelectedItemProperty("Currency")}
                                            onActiveChange={changeSelectedItemProperty("IsActive")}
                                            onDocumentTypeListChange={changeSelectedItemProperty("DocumentTypes")}
                                        />

                                    </TabStripTab>
                                    <TabStripTab title={translate('#Enterprise/AuthorizationGroupGeneralLimitTabName')} contentClassName="authorizationGroups__contentPanel__panel" >
                                        <LimitTab
                                            key={selectedItem.Id}
                                            permission={selectedItem.GeneralPermission}
                                            onChange={changeSelectedItemProperty("GeneralPermission")} />
                                    </TabStripTab>
                                    <TabStripTab title={translate('#Enterprise/AuthorizationGroupSupplierLimitsTabName')} contentClassName="authorizationGroups__contentPanel__panel">
                                        <SupplierLimitsTab
                                            key={selectedItem.Id}
                                            data={selectedItem.SupplierPermissions}
                                            onChange={changeSelectedItemProperty("SupplierPermissions")} />
                                    </TabStripTab>
                                    <TabStripTab title={translate('#Enterprise/AuthorizationGroupDimensionLimitsTabName')} contentClassName="authorizationGroups__contentPanel__panel">
                                        <DimensionLimitsTab
                                            key={selectedItem.Id}
                                            data={selectedItem.DimensionValuePermissions}
                                            onChange={changeSelectedItemProperty("DimensionValuePermissions")}
                                            sort={dimensionLimitsSort}
                                            onSortingChange={setDimensionLimitsSort} />
                                    </TabStripTab>
                                    <TabStripTab title={translate('#Enterprise/lblDeviationLimit')} contentClassName="authorizationGroups__contentPanel__panel" >
                                        <LimitTab
                                            key={selectedItem.Id}
                                            permission={selectedItem.DeviationPermission}
                                            onChange={changeSelectedItemProperty("DeviationPermission")} />
                                    </TabStripTab>
                                </TabStrip>
                            </CompanyProvider>
                        </div>}
                </div>
            </Splitter>
        </div>
    </>
    );
};

export function registerAuthorizationGroupsAdminPage(): void {
    registerAdministrationEntity(entityType, AuthorizationGroups);
}
