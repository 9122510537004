/// <amd-module name="Core/Medius.Core.Web/Scripts/components/links/linkSelector/linkSelector"/>
import * as ko from "knockout";
import * as _ from "underscore";
import { SuggestionDto } from "Core/Medius.Core.Web/Scripts/components/links/linkSelector/linkSelectorDtos";
import * as linkService from "Core/Medius.Core.Web/Scripts/components/links/linkSelector/services/linkService";
import * as suggestionModelProviders from "Core/Medius.Core.Web/Scripts/components/links/linkSelector/linkModelProviders";
import Suggestion from "Core/Medius.Core.Web/Scripts/components/links/linkSelector/suggestion/suggestion";
import * as backendErrorHandler from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";

import { getFormattedLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";

type SuggestionSelectedHandler = (suggestion: Suggestion) => void;

export class LinkSelector {
    private debounceWaitTimeInMilliSeconds = 500;
    private searchTextSub: ko.Subscription;
    private isActiveSub: ko.Subscription;
    private minSearchTextLength = 3;

    public static readonly maxSearchTextLength = 450;
    public tooltipText: string = getFormattedLabelTranslation("#Core/searchShortcut", []);

    public isActive: ko.Observable<boolean> = ko.observable(false);
    public isLoading: ko.Observable<boolean> = ko.observable(false);
    public searchTerm: ko.Observable<string> = ko.observable("");
    public suggestions: ko.ObservableArray<Suggestion> = ko.observableArray([]);
    public areSuggestionsLoaded: ko.Observable<boolean> = ko.observable(false);
    public isTimeoutInfoDisplayed: ko.Observable<boolean> = ko.observable(false);

    public isNoSuggestionsInfoDisplayed: ko.Computed<boolean>;
    public selectedSuggestion: ko.Computed<Suggestion>;
    public header: ko.Computed<string>;

    public onSuggestionSelected: SuggestionSelectedHandler = () => { };

    constructor() {

        this.searchTextSub = this.searchTerm.subscribe((searchTerm) => {
            this.isTimeoutInfoDisplayed(false);
            if (this.isSearchTermInvalid(searchTerm)) {
                this.suggestions([]);
                this.areSuggestionsLoaded(false);
                return;
            }

            if (searchTerm) {
                this.refreshSuggestions();
                return;
            }

            this.loadRecentlyViewedDocuments();
        });

        this.isActiveSub = this.isActive.subscribe(isActive => {
            if (!isActive) {
                this.deactivate();
            } else {
                this.activate();
            }
        });

        this.isNoSuggestionsInfoDisplayed = ko.computed(() => !this.isLoading() && this.areSuggestionsLoaded() && this.suggestions().length === 0);

        this.selectedSuggestion = ko.computed(() =>
            this.getSelectedSuggestionIndex() >= 0
                ? this.suggestions()[this.getSelectedSuggestionIndex()]
                : null);

        this.header = ko.computed(() =>
            this.searchTerm()
                ? getFormattedLabelTranslation("#Core/quickSearchResults", [])
                : getFormattedLabelTranslation("#Core/quickSearchRecentlyViewed", []));
    }

    private isSearchTermInvalid(searchTerm: string) {
        return searchTerm && (searchTerm.length < this.minSearchTextLength || searchTerm.trim().length < this.minSearchTextLength);
    }

    public activate = () => {
        if (this.searchTerm()) {
            this.loadSearchResults();
        } else {
            this.loadRecentlyViewedDocuments();
        }
        this.isActive(true);
    };

    public deactivate() {
        this.isActive(false);
        this.areSuggestionsLoaded(false);
        this.isTimeoutInfoDisplayed(false);
        this.suggestions([]);
    }

    public preventSubmit() {}

    public dispose() {
        this.searchTextSub.dispose();
        this.isNoSuggestionsInfoDisplayed.dispose();
        this.isActiveSub.dispose();
        this.selectedSuggestion.dispose();
        this.header.dispose();
    }

    private getSelectedSuggestionIndex() {
        return this.findIndex(this.suggestions(), suggestion => suggestion.isSelected());
    }

    private refreshSuggestions = _.debounce(() => {
        if (this.searchTerm()) {
            this.loadSearchResults();
        }
    }, this.debounceWaitTimeInMilliSeconds);

    private loadSearchResults() {
        const actualSearchTerm = this.searchTerm();

        if (this.isSearchTermInvalid(actualSearchTerm)) {
            return;
        }

        this.isLoading(true);

        linkService
            .search(actualSearchTerm.substring(0, LinkSelector.maxSearchTextLength))
            .done(searchResult => {
                if (searchResult.kind == "success") {
                    const suggestionsCollectionDto = searchResult.results;
                    if ((suggestionsCollectionDto.searchTermUsed || "") === this.searchTerm()) {
                        this.suggestions(suggestionsCollectionDto.suggestions.map(this.toSuggestion));
                        this.areSuggestionsLoaded(true);
                        this.selectFirstSuggestion();
                    }
                }
                else {
                    this.isTimeoutInfoDisplayed(true);
                }
            })
            .fail(xhr => backendErrorHandler.handleAnyError(xhr))
            .always(() => this.isLoading(false));
    }

    private loadRecentlyViewedDocuments() {
        this.isLoading(true);

        linkService
            .recentlyViewed()
            .done(suggestionsCollectionDto => {
                if (!this.searchTerm()) {
                    this.suggestions(suggestionsCollectionDto.suggestions.map(this.toSuggestion));
                    this.areSuggestionsLoaded(true);
                    this.selectFirstSuggestion();
                }
            })
            .fail(xhr => backendErrorHandler.handleAnyError(xhr))
            .always(() => this.isLoading(false));
    }

    private selectFirstSuggestion() {
        if (this.suggestions().length > 0) {
            this.suggestions()[0].isSelected(true);
        }
    }

    private toSuggestion = (dto: SuggestionDto) => {
        const suggestion = suggestionModelProviders.get(dto.entityType)(dto);
        suggestion.onSuggestionSelected = this.onSuggestionSelected;
        suggestion.searchTerm = this.searchTerm();
        return suggestion;
    };

    private findIndex<T>(items: T[], condition: (item: T) => boolean): any {
        let index = -1;
        items.some((el, i) => {
            if (condition(el)) {
                index = i;
                return true;
            }
            return undefined;
        });
        return index;
    }
}
