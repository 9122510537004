/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/administration/server"/>
import * as administration from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rest";

export function put(url: string, data: any) {
    return administration.put("ExtendableSynchronization", url, data);
}

export function post(url: string, data: any) {
    return administration.post("ExtendableSynchronization", url, data);
}

export function get(url: string) {
    return administration.get("ExtendableSynchronization", url);
}

export function del(url: string) {
    return administration.del("ExtendableSynchronization", url);
}
