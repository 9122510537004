/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/task/loaders/task"/>
import { isObservable, observable, computed } from "knockout";
import { handleAnyError } from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import { markAsRecentlyViewed } from "Core/Medius.Core.Web/Scripts/components/quickSearch/services/quickSearchService";
import { setLockIntervalForDocument } from "Core/Medius.Core.Web/Scripts/Medius/lockManager";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { error } from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import { when } from "jquery";
import * as communication from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rpc";
import { startLoadMeasurement } from "Core/Medius.Core.Web/Scripts/Medius/performance/loggers/task";
import type = require("Core/Medius.Core.Web/Scripts/Medius/core/type");
import store = require("Core/Medius.Core.Web/Scripts/Medius/core/store/handling");
import { getCustomTabs } from "Core/Medius.Core.Web/Scripts/customTabs/customTabs";
import * as taskContextFactory from "Core/Medius.Core.Web/Scripts/Medius/apps/task/models/taskContext";

/*
    * Abstracts the logic of loading a task entity togather with its context data (tabs),
    * and creating appropriate viewmodel.
    *
    * Instance watches the idSource observable for any changes made to it. If current id
    * is different than id of currently loaded task, the following behaviour is exposed:
    *
    *   [idSource changes] --> <data loading and initialization> --> [TaskContext is changed]
    *
    * Currently the following ordered operations are triggered:
    *   1. Loading of task model with new Id,
    *   2. Loading of task tabs for given task and document types,
    *   3. Cration of context object from given task model and task tabs,
    *   4. Update of the TaskContext property with newly created context
    *
    * In addition, the options parameter is stored and passed to all the created contexts, to
    * provide them with additional data they may require to operate.
    */

export class TaskLoader {
    public Loading = observable(false);
    public TaskContext = observable<any>(null);
    public isInSwitchMode = observable(true);
    public Task = computed(() => this.TaskContext() ? this.TaskContext().Task : null);
    public hasCustomDocumentViewModel = computed(() => this.TaskContext() && this.TaskContext().CustomDocumentModel);
    public Tabs = computed(() => this.TaskContext() ? this.TaskContext().Tabs : null);
    public loadHandlers: any[];
    public addLoadHandler: (handler: any) => void;
    public loadFromServer: (taskId: number) => any;

    private idSourceSub: ko.Subscription;
    public createTaskContextFromLocalTask: (localTask: any) => any;
    public loadTaskTabsForTaskId: (taskId: number) => any;
    public loadTaskTabs: (taskDto: any) => any;
    public updateTaskContext: (taskContext: any) => any;

    constructor(idSource: ko.Observable<any>, public options: any) {
        if (!isObservable(idSource)) {
            throw new Error("Task id source must be an observable");
        }

        this.loadHandlers = [
            {
                loadTask: (newTaskId: number) => {
                    return store.getItem(newTaskId.toString())
                        .pipe((task: any) => {
                            if (task) {
                                task.isLocal = true;
                                return this.createTaskContextFromLocalTask(task);
                            }
                            return when(this.loadFromServer(newTaskId), this.loadTaskTabsForTaskId(newTaskId)).then((incomingTask, defaultTabs) => {
                                return taskContextFactory.createFromDtos(incomingTask[0], sortTabs(defaultTabs[0], getCustomTabs()), this.options);
                            });
                        });
                },

                canHandle: (newTaskId: number) => newTaskId > 0
            }
        ];

        const sortTabs = (...tabs: any[]) => {
            return tabs.flat().sort((a, b) => a.Position - b.Position);
        };

        this.addLoadHandler = (handler: any) => {
            this.loadHandlers.push(handler);
        };

        this.loadFromServer = (taskId: number) => {
            return communication.lightApi("InboxTaskService", "GetTask", { taskId }, {
                context: this
            }).fail((result: any) => {
                handleAnyError(result);
            });
        };

        this.startLoadTask = this.startLoadTask.bind(this);
        this.idSourceSub = idSource.subscribe(this.startLoadTask);

        this.createTaskContextFromLocalTask = (localTask: any) => {
            const task = localTask.toDto();

            if (!localTask) {
                return null;
            }

            return this.loadTaskTabs(task).pipe((tabs: any) => {
                return taskContextFactory.createFromTaskViewmodel(task, localTask, sortTabs(tabs, getCustomTabs()), this.options);
            });
        };

        this.loadTaskTabsForTaskId = (taskId: number) => {
            return communication.request("TabManager", "GetTaskTabsForId", { taskId }, { context: this });
        };

        this.loadTaskTabs = (taskDto: any) => {
            const params = {
                documentFullName: taskDto.Document && type.getTypeName(taskDto.Document.$type),
                activityId: taskDto.ActivityContext && taskDto.ActivityContext.Id,
                workflowId: taskDto.Perspective && taskDto.Perspective.WorkflowId,
                documentId: taskDto.Document && taskDto.Document.Id
            };
            return communication.request("TabManager", "GetTaskTabs", params, { context: this });
        };

        this.updateTaskContext = (taskContext: any) => {
            const currentContext = this.TaskContext();
            if (currentContext) {
                currentContext.dispose();
            }

            if (!taskContext) {
                this.TaskContext(null);
                return null;
            }

            this.TaskContext(taskContext);
            return taskContext;
        };

    }

    public loadFromStore(newTaskId: number) {
        return store.getItem(newTaskId.toString());
    }

    public startLoadTask(newTaskId: number) {
        const taskContext = this.TaskContext;

        if (isNullOrUndefined(newTaskId) || newTaskId < 0) {
            if (taskContext() != null) {
                taskContext().dispose();
                taskContext(null);
            }
            return;
        }

        startLoadMeasurement();
        this.Loading(true);
        this.isInSwitchMode(true);

        const loader = this.loadHandlers.find((handler) => handler.canHandle(newTaskId));

        loader.loadTask(newTaskId, this.options)
            .pipe((taskCtx: any) => {
                return this.updateTaskContext(taskCtx);
            })
            .done((taskCtx: any) => {
                if (taskCtx.Task && taskCtx.Task.Document) {
                    const documentId = taskCtx.Task.Document().Id();
                    const documentType = taskCtx.Task.Document().$type();
                    markAsRecentlyViewed(documentId, documentType);
                    // Unfortunately not all tasks has IsReadonly property, so check needs to be done this way
                    if (taskCtx.Task.IsReadonly && taskCtx.Task.IsReadonly()) {
                        return;
                    }
                    if (taskCtx.Task.Perspective && taskCtx.Task.Perspective() &&
                        taskCtx.Task.Perspective().ShouldAcquireDocumentLock && !taskCtx.Task.Perspective().ShouldAcquireDocumentLock()) {
                        return;
                    }
                    setLockIntervalForDocument(documentId);
                } else if (taskCtx.CustomDocumentModel) {
                    if (taskCtx.CustomDocumentModel.documentId && taskCtx.CustomDocumentModel.documentType) {
                        markAsRecentlyViewed(taskCtx.CustomDocumentModel.documentId, taskCtx.CustomDocumentModel.documentType);
                    }
                    if (taskCtx.CustomDocumentModel.isActive) {
                        setLockIntervalForDocument(taskCtx.CustomDocumentModel.documentId);
                    }
                }
            })
            .fail((xhr: any) => {
                if (!xhr || !!(xhr.status) || xhr.status !== 0) {
                    error(translate("#Core/documentDoesNotExistOrYouHaveInsufficientPermissions"), translate("#Core/documentCannotBeLoaded"));
                }
            })
            .always(() => {
                this.Loading(false);
                this.isInSwitchMode(false);
            });
    }

    public dispose() {
        this.options = null;
        this.loadHandlers = null;
        this.Loading = null;
        this.TaskContext = null;
        this.isInSwitchMode = null;
        this.idSourceSub.dispose();
        this.Tabs.dispose();
        this.Task.dispose();
    }
}
