/// <amd-module name="Core/Medius.Core.Web/Scripts/customTabs/definitions/contracts/companyAutocompleter/promiseBasedCompanyAutocompleterContainer"/>

import * as React from "react";
import { useState } from "react";
import { debounce } from "underscore";
import { CompanyAutocompleter } from "./companyAutocompleter";
import { useAbortController } from "Core/Medius.Core.Web/Scripts/shared/hooks/useAbortController";
import { aborted } from "Core/Medius.Core.Web/Scripts/Medius/core/fetch/rest";
import * as notification from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";

export interface IPromiseBasedCompanyAutocompleterContainerProps<T> {
    selectedItem: T;
    textFieldSelector: string;
    invoiceCompanyId: number;
    disabled?: boolean;    
    onCompanyChange: (company: T) => void;
    getCompaniesPromise: (invoiceCompanyId:number, searchPhrase?: string, abortSignal?: AbortSignal) => Promise<T[]>
}



export function PromiseBasedCompanyAutocompleterContainer<T>(props: IPromiseBasedCompanyAutocompleterContainerProps<T>) {
    const { textFieldSelector, onCompanyChange, selectedItem, getCompaniesPromise, invoiceCompanyId, disabled = false } = props;
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [prevFilter, setPrevFilter ] = useState<string | undefined>(undefined);
    const abortController = useAbortController();
    const [companyDropdownState, setCompanyDropdownState] = useState({
        loadedCompanies: [] as T[],
    });

    const handleCompanyFilterChange = (filter: string) => debouncedQueryCompanies(filter);
    const handleCompanyChange = (company: T) => onCompanyChange(company);
    const queryCompanies = (filter: string = '') => {
            if (prevFilter === filter) {
                return;
            }

            setIsDataLoading(true);
            getCompaniesPromise(invoiceCompanyId, filter, abortController.signal)
            .then((response) => {
                setPrevFilter(filter);
                setCompanyDropdownState((x) => ({ ...x, loadedCompanies: response ?? [] }));
            })
            .catch((err: any) => {
                if (aborted(err))
                    return;

                notification.error(translate("#Contract/contractsForInvoices/companyFilter/error"));
            })
            .finally(() => {
                setIsDataLoading(false);
            });
        
    };

    const debouncedQueryCompanies = debounce(queryCompanies, 300);

    return (
        <CompanyAutocompleter
            required={false}
            disabled={disabled}
            label={undefined}
            selectedItem={selectedItem}
            textFieldSelector={textFieldSelector}
            onCompanyChange={handleCompanyChange}
            onFilterChange={handleCompanyFilterChange}
            onDropdownOpen={() => debouncedQueryCompanies()}
            loadedCompanies={companyDropdownState.loadedCompanies}
            isDataLoading={isDataLoading}
        />
    );
}