/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/lib/functions"/>

import * as _ from "underscore";

/* returns delayed version of the function */
export function delayed(func: any, delayTime: number, context: any):any {
    delayTime = delayTime || 0;

    return function () {
        const args = arguments,
            target = context || this;

        return _.delay(function () {
            func.apply(target, args);
        }, delayTime);
    };
}

export function deferred(func: any, context: any):any {
    return delayed(func, 0, context);
}