/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/scrollDown" />

import * as ko from "knockout";

export function register() {
    ko.bindingHandlers["scrollDown"] = {
        update(element: Element, valueAccessor: () => boolean | ko.Observable<boolean>) {
            if (ko.utils.unwrapObservable(valueAccessor()))
                element.scrollTop = element.scrollHeight;
        }
    };
}
