/// <amd-module name="Core/Medius.Core.Web/Scripts/components/topBar/topBarContextState"/>

export type TopBarContext = "AP Automation" | "Procurement";

type topBarContextAction = "set-topbar-context";
interface TopBarContextState {
    context: TopBarContext
}
const initialState: TopBarContextState = {
    context: "AP Automation"
};

type topBarContextDispatchAction = {
    type: topBarContextAction;
    payload: TopBarContext
};

export const availableTopBarContexts: TopBarContextState[] = [{ context: "AP Automation" }, { context: "Procurement" }];

export function topBarContextReducer(state = initialState, action: topBarContextDispatchAction): TopBarContextState {
    switch (action.type) {
        case "set-topbar-context":
            return { ...state, context: action.payload };
        default:
            return state;
    }
}

/**
 * call these from code using:
 *         store.dispatch(setProcurementContext());
 * as if called directly there may be circular references
 */

export function setAPAutomationContext(): topBarContextDispatchAction {
    return { type: "set-topbar-context", payload: "AP Automation" };
}
export function setProcurementContext(): topBarContextDispatchAction {
    return { type: "set-topbar-context", payload: "Procurement" };
}

export function getTopBarContextStoreActionByNameIfExists(name: string): topBarContextDispatchAction | undefined {
    const match = availableTopBarContexts.find(c => c.context == name);
    if (match) {
        return { type: "set-topbar-context", payload: match.context };
    }
    return undefined;
}
