/// <amd-module name="Core/Medius.Core.Web/Scripts/components/taskHandling/shared/header/header"/>
import * as ko from "knockout";
import { getOutboxInstance } from "Core/Medius.Core.Web/Scripts/components/outbox/outbox";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";


export class Header {
    public readonly messages: string[];
    public readonly title: string;
    public readonly anyMessages: boolean;
    public readonly isActive: boolean;
    private readonly taskId: number;
    public readonly errors: ko.Computed<string[]>;
    public readonly errorsHeader: string;

    constructor(title: string, messages: string[], taskId: number, isActive: boolean) {
        this.title = title;
        this.messages = messages;
        this.isActive = isActive;
        this.anyMessages = this.messages && this.messages.length > 0;
        this.taskId = taskId;

        this.errorsHeader = getLabelTranslation("#Core/documentErrors");

        this.errors = ko.pureComputed(() => {
            return getOutboxInstance().getErrorsForTask(this.taskId);
        });        
    }

    public hideErrors = () => { };
}