///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/grid/bindings/columnSelector"/>
import * as ko from "knockout";
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as _ from "underscore";
import { isNotNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import * as columnSelectorModel from "Core/Medius.Core.Web/Scripts/Medius/components/grid/columnSelector/columnSelector";

const selectorElementKey = "medius-selector";

function parseBinding(bindingAccessor:any) {
    const binding = bindingAccessor(),
        label = koUtils.unpack(binding.buttonLabel);
    
    binding.type = koUtils.unpack(koUtils.unpack(binding.type)) || null;

    if (isNotNullOrUndefined(label)) {
        _.extend(binding, { buttonLabel: label });
    }

    return binding;
}

const columnSelector = {
    init: function (element:Element, bindingAccessor:any) {
        const bindingParams = parseBinding(bindingAccessor);
        const type = bindingParams.type;
        const current = koUtils.domData.get(element, selectorElementKey) || {};
        
        if (current.type) {
            current.disposal();
            ko.utils.domNodeDisposal.removeDisposeCallback(current.disposal, undefined);
        }

        if (!type) {
            throw new Error("columnSelector: Can't initialize columnSelector, no entity type provided.");
        }

        const instance = columnSelectorModel.create(bindingParams);
        ko.renderTemplate(instance.options.template, instance, {}, element, "replaceChildren");

        let buttonOff = element.querySelectorAll(".dropdown:not(.open) *[data-toggle='dropdown']");
        let buttonOn = element.querySelectorAll(".dropdown.open *[data-toggle='dropdown']");

        buttonOff.forEach(button => {
            button.addEventListener("click", () => {
                instance.open();
            });
        });
        
        buttonOn.forEach(button => {
            button.addEventListener("click", () => {
                instance.destroy();
            });
        });

        const disposal = function() {
            instance.destroy();
            buttonOn.forEach(button => {
                button.removeEventListener("click", () => {
                    instance.destroy();
                });
            });
            buttonOn = null;
            buttonOff.forEach(button => {
                button.removeEventListener("click", () => {
                    instance.open();
                });
            });
            
            buttonOff = null;
            koUtils.domData.set(element, selectorElementKey, null);
        };
        ko.utils.domNodeDisposal.addDisposeCallback(element, disposal);

        koUtils.domData.set(element, selectorElementKey, {
            type: type,
            disposal: disposal
        });
        
        return { controlsDescendantBindings: true };
    },
    update: function (element:any, bindingAccessor:any) {
        const binding = parseBinding(bindingAccessor);
        const type = binding.type;
        const current = koUtils.domData.get(element, selectorElementKey) || {};

        if (type && type !== current.type) {
            columnSelector.init(element, bindingAccessor);
        }
    }
};

export function register() {
    koUtils.registerBinding("columnSelector", columnSelector);
}