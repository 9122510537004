/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/Company/General/general"/>
import {observable, observableArray, pureComputed} from "knockout";
import {when} from "jquery";
import {IEntityWithContext} from "Core/Medius.Core.Web/Scripts/AdminPages/IEntityWithContext";
import {translate} from "Core/Medius.Core.Web/Scripts/lib/globalization";
import DataDefinitionEditor = require("Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Data.DataDefinition/Editor");
import * as userEditorHelpers from "Core/Medius.Core.Web/Scripts/Medius/lib/utils/userEditorHelpers";
import { CountryCodes } from "Core/Medius.Core.Web/Scripts/lib/countryCodes";
import { get } from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rest";
import { handleAnyError } from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import { Module } from "Core/Medius.Core.Web/Scripts/Medius/apps/reactSpa/app";

class Company {
    public ViewModel: any;
    public LanguagesList: ko.ObservableArray<LanguageDto>;
    public CountryCodeList: ko.ObservableArray<CountryCodeDto>;
    public FormatsList: ko.ObservableArray<FormatDto>;
    public Entity: any;
    public ChooseCaption = translate("#Core/LBL_CHOOSE");
    public isCompanyPersisted: ko.Computed<boolean>;
    public companyLanguage: string;
    public companyFormat: string;
    public actionResultSub: ko.Subscription;
    public openDialogNotifier = observable(false).extend({notify: 'always'});
    public dialogActionResult: ko.Observable<boolean | null> = observable(null);
    public isRootCompany: boolean;

    private isInitialyActive: boolean;
    private readonly externalSystemIdSubscription: ko.Subscription;
    private readonly companyNameSubscription: ko.Subscription;
    private readonly companyIdSubscription: ko.Subscription;

    private readonly externalSystemIdentifier = "ExternalSystemIdentifier";
    private readonly companyNameIdentifier = "CompanyNameIdentifier";
    private readonly companyIdIdentifier = "CompanyIdIdentifier";

    constructor(entityWithContext: IEntityWithContext, companyContextId: any, tabModel: any) {
        entityWithContext.TabModels.General = this;
        this.ViewModel = DataDefinitionEditor.call(this, entityWithContext, companyContextId, tabModel);
        this.LanguagesList = observableArray([]);
        this.FormatsList = observableArray([]);

        this.CountryCodeList = observableArray(CountryCodes);
        
        this.Entity = entityWithContext.Entity;
        this.isCompanyPersisted = pureComputed(() => this.Entity.Id() !== 0);

        //Updating externalsystemid should update internal identifier ExternalSystemIdentifier
        this.externalSystemIdSubscription = this.Entity.ExternalSystemId.subscribe((newValue: any) => {
            if (this.Entity.CompanyIdentifiers().filter((identifier: any) => identifier.Type().Type() === this.externalSystemIdentifier).length > 0)
                this.Entity.CompanyIdentifiers().filter((identifier: any) => identifier.Type().Type() === this.externalSystemIdentifier)[0].Identifier(newValue);
        });

        //Updating name should update internal identifier CompanyNameIdentifier
        this.companyNameSubscription = this.Entity.Name.subscribe((newValue: any) => {
            if (this.Entity.CompanyIdentifiers().filter((identifier: any) => identifier.Type().Type() === this.companyNameIdentifier).length > 0)
                this.Entity.CompanyIdentifiers().filter((identifier: any) => identifier.Type().Type() === this.companyNameIdentifier)[0].Identifier(newValue);
        });

        //Updating companyid should update internal identifier CompanyIdIdentifier
        this.companyIdSubscription = this.Entity.CompanyId.subscribe((newValue: any) => {
            if (this.Entity.CompanyIdentifiers().filter((identifier: any) => identifier.Type().Type() === this.companyIdIdentifier).length > 0)
                this.Entity.CompanyIdentifiers().filter((identifier: any) => identifier.Type().Type() === this.companyIdIdentifier)[0].Identifier(newValue);
        });

        //we need to store this values because when autocompleters are created
        //and there is no yet available languages list populated
        //first element on list is set, which changes state of entity
        this.companyLanguage = this.Entity.Language();
        this.companyFormat = this.Entity.Format();
        this.isInitialyActive = this.Entity.IsActive();
        this.isRootCompany = this.Entity.Parent() === null && this.Entity.Id() !== 0;
        this.load();

        const setIsPV2Enabled = () => {
            get("applicationData")
                .fail(function (xhr: any) {
                    handleAnyError(xhr);
                })
                .then((response: { accesses: { enabledModules: any[]; }; }) => {
                    if (response) {
                        entityWithContext.Context.set('company-addresses-isPv2Enabled', response.accesses.enabledModules.includes(Module.PV2));
                    }
                });
        };

        setIsPV2Enabled();
    }

    load() {
        when(userEditorHelpers.loadAvailableLanguages())
        .done((languages: LanguageDto[]) => {
            this.LanguagesList(languages);
            this.Entity.Language(this.companyLanguage);
        });

        when(userEditorHelpers.loadAvailableFormats())
        .done((formats: FormatDto[]) => {
            this.FormatsList(formats);
            this.Entity.Format(this.companyFormat);
        });
    }

    wasDeactivated() {
        return this.isInitialyActive && !this.Entity.IsActive();
    }

    openDialogAction() {
        this.openDialogNotifier(true);
    }

    public dispose() {
        this.externalSystemIdSubscription.dispose();
        this.companyNameSubscription.dispose();
        this.companyIdSubscription.dispose();
        if (this.actionResultSub !== undefined) {
            this.actionResultSub.dispose();
        }
    }
}

export default function create(entityWithContext: IEntityWithContext, 
                                companyContextId: number,
                                tabModel: any) {
    return new Company(entityWithContext, companyContextId, tabModel);
}

export type AdministrationCompany = Company;

interface LanguageDto {
    $type: string;
    DisplayName: string;
    Name: string;
}

interface CountryCodeDto {
    Value: string;
    Name: string;
}

interface FormatDto {
    $type: string;
    DisplayName: string;
    Name: string;
}
