/// <amd-module name="Core/Medius.Core.Web/Scripts/components/card/Card"/>
import * as React from 'react';
import { toAction } from 'Core/Medius.Core.Web/Scripts/Medius/lib/path';
import { getApplicationVersion } from 'Core/Medius.Core.Web/Scripts/appConfig';
import Loader from './Loader';

interface ICardPageProps {
    children: React.ReactNode | React.ReactNode[];
    loading?: boolean;
    loaderMessage?: string;
}

export default function CardPage(props: ICardPageProps) {
    return (
        <div className='card-component'>
            <div
                className='card-component__page-container'
                data-testid='branding-background'
                style={{
                    backgroundImage: `url(${toAction(
                        `Background?v=${getApplicationVersion()}`,
                        '~/Branding'
                    )})`,
                }}
            >
                {!props.loading ? (
                    <div className='card-component__page-container__card'>
                        <div className='card-component__page-container__card__logo-container'>
                            <img
                                src={toAction(`LogoLogon?v=${getApplicationVersion()}`, '~/Branding')}
                                data-testid='branding-logo'
                                alt='Medius'
                            />
                        </div>
                        <div>{props.children}</div>
                    </div>
                ) : (
                        <Loader message={props.loaderMessage} />
                    )}
            </div>

            <div className='card-component__powered-by'>
                <img
                    data-testid='poweredBy'
                    src={toAction(`PoweredBy?v=${getApplicationVersion()}`, '~/Branding')}
                    alt=''
                />
            </div>
        </div>
    );
}
