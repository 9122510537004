/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/AuthorizationGroups/authorizationGroupDataHeader"/>
import * as React from "react";
import { Optional } from "./authorizationGroup";

interface IAuthorizationGroupDataHeaderProps {
    authorizationGroupName: Optional<string>;
}

export const AuthorizationGroupDataHeader = (props: IAuthorizationGroupDataHeaderProps) => {
    const { authorizationGroupName } = props;

    return (
        <div className="authorizationGroups__data-header">{authorizationGroupName}</div>
    );

};
