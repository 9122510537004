/// <amd-module name="Core/Medius.Core.Web/Scripts/shared/hooks/useAbortController"/>
import { useEffect, useState } from 'react';

export function useAbortController() {
    const [abortController] = useState(new AbortController());

    useEffect(() => {
        return () => {
            if (!abortController.signal.aborted)
                abortController.abort();
        };
    }, [abortController]);

    return abortController;
}
