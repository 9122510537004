/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/models/mergedConfiguration/mergedEntityConfigurationTab"/>
import { MergedConfigurationTab, EntityWithContext } from "Core/Medius.Core.Web/Scripts/Medius/models/mergedConfiguration/mergedConfigurationTab";
import { unwrap, observable, computed } from "knockout";
import { getFormattedLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import contextFactory = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/context");
import { getTypeName } from "Core/Medius.Core.Web/Scripts/Medius/core/type";
import { each, isNull, isUndefined } from "underscore";
import { ajax } from "Core/Medius.Core.Web/Scripts/Medius/core/rpc";
import { handleAnyError } from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import * as mergedConfigModel from "Core/Medius.Core.Web/Scripts/Medius/models/mergedConfiguration/mergedConfigurationModel";

export = class EntityConfigurationTab extends MergedConfigurationTab {
    RelatedObjects: any;
    ConfiguredEntity: any;
    ConfigurationData = observable<any>(null);
    isDataLoaded = observable(false);
    missingPermission = observable(false);
    missingPermissionMessage: ko.Computed<any>;
    ajaxRequests: any[] = [];
    context: any;

    constructor(entityWithContext: EntityWithContext) {
        super(entityWithContext);
        this.RelatedObjects = unwrap(entityWithContext.RelatedObjects[this.getConfigurationTypeName()]);
        this.ConfiguredEntity = entityWithContext.Entity;


        this.missingPermissionMessage = computed(() => {
            const company = unwrap(this.companyContext);
            const companyName = company && unwrap(company.Name);
            return getFormattedLabelTranslation("#Core/missingPermissionToViewCompany", [companyName]);
        });

        this.companyContext.subscribe(() => {
            this.onChangeCompany();
        });

        this.onChangeCompany();
    }

    clearConfiguration() {
        this.isDataLoaded(false);
        this.ConfigurationData(null);
        this.missingPermission(false);
    }

    createConfigurationData(currentData: any, mergedData: any) {
        const currentEntityConfigurationViewModel = this.getViewModel(currentData.Entity);
        const mergedConfigurationEntity = mergedData.Entity;
        const mergedMetadata = mergedData.ConfigurationDescription.PropertiesDescription;
        const configurationViewModel = mergedConfigModel.create(
            currentEntityConfigurationViewModel,
            mergedConfigurationEntity,
            this.RelatedObjects,
            mergedMetadata,
            this.ConfiguredEntity,
            this.companyContext(),
            this.context);

        return configurationViewModel;
    }

    createNewContext() {
        const company = this.companyContext();
        const companyId = company && unwrap(company.Id);


        this.context = contextFactory();

        this.context.getData().companyId = companyId;
        this.context.set("companyId", companyId);
        this.context.set("administration-context", {
            entityType: this.getConfiguredEntityType()
        });

    }

    getViewModel(data: any) {
        return this.context.create(data);
    }

    dispose() {
        const configurationData = this.ConfigurationData();

        if (configurationData) {
            configurationData.dispose();
        }

        if (this.context) {
            this.context.dispose();
        }

        each(this.ajaxRequests, function (r) {
            r.abort();
        });
    }

    getCompanyContextId() {
        const company = unwrap(this.companyContext);
        return company && unwrap(company.Id);
    }

    getConfiguredEntityId() {
        return unwrap(this.entityWithContext.Entity.Id);
    }

    getConfiguredEntityType() {
        return getTypeName(unwrap(this.entityWithContext.Entity.$type));
    }

    intializeConfigurationProperties(..._args: any) {
    }

    isCompanyContextDefined() {
        const companyContext = unwrap(this.companyContext);
        return isNull(companyContext) === false && isUndefined(companyContext) === false;
    }

    loadConfiguration() {
        const params = {
            contextCompanyId: this.getCompanyContextId(),
            entityId: this.getConfiguredEntityId(),
            entityTypeName: this.getConfiguredEntityType(),
            configurationObjectTypeName: this.getConfigurationTypeName()
        };

        return ajax("MergedConfigurationEntitiesService", "GetOrCreateConfigurationEntityForCompanyWithMetadata", {
            data: JSON.stringify(params)
        });
    }

    loadMergedConfiguration() {
        const params = {
            contextCompanyId: this.getCompanyContextId(),
            entityId: this.getConfiguredEntityId(),
            entityTypeName: this.getConfiguredEntityType(),
            configurationObjectTypeName: this.getConfigurationTypeName()
        };

        return ajax("MergedConfigurationEntitiesService", "GetMergedConfigurationEntityWithMetadataForParent", {
            data: JSON.stringify(params)
        });
    }

    onChangeCompany() {
        this.dispose();
        this.createNewContext();

        if (this.isCompanyContextDefined() && this.canEdit()) {
            this.reloadConfiguration();
        } else {
            this.clearConfiguration();
        }
    }

    reloadConfiguration() {
        const loadConfiguration = this.loadConfiguration();
        const loadMergedConfiguration = this.loadMergedConfiguration();

        this.ajaxRequests = [loadConfiguration, loadMergedConfiguration];

        $.when(loadConfiguration, loadMergedConfiguration).
            then((currentDataResponse, mergedDataResponse) => {
                const configurationViewModel = this.createConfigurationData(currentDataResponse[0], mergedDataResponse[0]);
                this.intializeConfigurationProperties(this.companyContext(), configurationViewModel);
                this.ConfigurationData(configurationViewModel);

                this.isDataLoaded(true);
                this.missingPermission(false);
            }, (error) => {
                if (error.status === 403) {
                    this.missingPermission(true);
                } else {
                    handleAnyError(error);
                }
            });
    }
};