///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/components/copyRequisition/validationResult/validationResultRow" />
import * as React from "react";
import { FlexLayout, Text, IconStatusErrorFill, ContentDivider, IconStatusCheckCircleFill } from "@medius/ui-controls";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { isEmptyString, isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";

type ValidationResultRowProps = {
    validationErrors: string[],
    lineNumber: number,
    partNumber: string,
    supplier: string,
    description: string
};

export const ValidationResultRow = ({ validationErrors, lineNumber, partNumber, supplier, description }: ValidationResultRowProps) => {

    const icon = validationErrors.length > 0
        ? <IconStatusErrorFill size="small" color="error" data-testid="validation-results-modal-row-error-icon" />
        : <IconStatusCheckCircleFill size="small" color="ok" data-testid="validation-results-modal-row-ok-icon" />;

    const text = validationErrors.length > 0
        ? validationErrors.map(err => translate(err)).join(" ")
        : "";

    const partNumberText = isNullOrUndefined(partNumber) || isEmptyString(partNumber) ? translate("#Procurement/noPartNumber") : partNumber;
    const supplierText = isNullOrUndefined(supplier) || isEmptyString(supplier) ? translate("#Procurement/noSupplier") : supplier;
    const descriptionText = isNullOrUndefined(description) || isEmptyString(description) ? translate("#Procurement/noDescription") : description;
    const lineError = `${lineNumber.toString()}: ${descriptionText} | ${partNumberText} | ${supplierText}`;

    return (
        <>
            <FlexLayout direction="column" itemRowSpacing="small" spacerMode="on" spacerSize="small">
                <FlexLayout direction="row" itemColumnSpacing="vsmall" wrapItems={false}>
                    <div className="copy-requisition__row-icon-container">
                        {icon}
                    </div>
                    <Text variant="heading3" maxLines={2}>{lineError}</Text>
                </FlexLayout>
                <FlexLayout direction="row" itemColumnSpacing="vsmall">
                    <div className="copy-requisition__row-icon-container" />
                    <Text variant="body" data-testid="validation-results-modal-row-error-message">{text}</Text>
                </FlexLayout>
            </FlexLayout>
            <ContentDivider spacerMode="on" spacerSize="small" />
        </>
    );
};