/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/resolver/utils" />

import { isObservable } from "knockout";
import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import { isArray, isEmpty, first, rest } from "underscore";

function findInProperies(obj: any, property: any) {
    if (isNullOrUndefined(obj) || isNullOrUndefined(property)) {
        return null;
    }
    
    obj = isObservable(obj) ? obj()[property] : obj[property];
    
    return obj;
}

export function findInTree(obj: any, properties: any): any {

    if (isNullOrUndefined(obj) ||
        !isArray(properties) || isEmpty(properties)) {
        return null;
    }

    const property = first(properties);
    obj = isObservable(obj) ? obj()[property] : obj[property];

    if (properties.length > 1) {
        return findInTree(obj, rest(properties));
    }
    else {
        return obj;
    }
}

export function findPathInTree(obj: any, path: any) {
    if (isNullOrUndefined(obj) ||
        isNullOrUndefined(path)) {
        return null;
    }
    
    const propertyValue = findInTree(obj, path.split("."));
    
    if (!isNullOrUndefined(propertyValue)) {
        return propertyValue;
    }
    
    return findInProperies(obj, path.replace(/[.]/g, "_"));
}

