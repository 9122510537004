/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/communication/json/helpers"/>
import * as serialization from "Core/Medius.Core.Web/Scripts/Medius/lib/serialization/extended";

export  function deserializeResponse(responseData: any, status?: any, xhr?: JQueryXHR) {
    const contentType = xhr.getResponseHeader("Content-Type");

    if (contentType && contentType.indexOf("application/json") === -1)
        return responseData;

    const deserialized = serialization.fromJSON(responseData);
    return [deserialized, status, xhr];
}

export function getDefaultOptions() {
    return {
        contentType: "application/json; charset=utf-8",
        dataType: "text",
        async: true,
        cache: true,
        headers: {
            "X-Json-Preserve-References": true,
            "Accept": "application/json, text/javascript, */*; q=0.01"
        }
    };
}

export const toJSON = serialization.toJSON;
