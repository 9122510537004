///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/administration/bindings/withBulkAdminModule"/>
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as ko from "knockout";
import * as _ from "underscore";

function loadModule(moduleName: string, key: string | number, context: { $data: any; $parent: any; }) {
    let moduleVm;
    const defaultViewModel = context.$data,
        tabModel = context.$parent;
    
    if (!tabModel.modules) {
        tabModel.modules = {};
    }
    
    // load from cache by key
    if (tabModel.modules[key]) {
        moduleVm = tabModel.modules[key];
    }
    // create a new instance and cache it
    else {
        const module = require(moduleName);
        moduleVm = module(defaultViewModel.EntitiesIds, tabModel);

        tabModel.modules[key] = moduleVm;
    }
   
    return moduleVm;
}

const withBulkAdminModule = {
    init: function (element: any, bindingParams: () => { (): any; new(): any; module: any; }, allBindingsAccessor: any, viewModel: any, context: any) {
        const module = bindingParams().module,
            key = context.$parent.tabId || _.uniqueId('module_');

        // load module from cache or initialize a new instance
        const moduleVm = loadModule(module, key, context);
      
        const newContext = context.createChildContext(moduleVm);

        ko.applyBindingsToDescendants(newContext, element);

        return { controlsDescendantBindings: true };
    }
};

export function register() {
    koUtils.registerBinding("withBulkAdminModule", withBulkAdminModule);
}