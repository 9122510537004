/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/grid/dataSource/default"/>

import * as $ from "jquery";
import * as _ from "underscore";
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as ko from "knockout";

type DataQueryType = {
    entities: any;
    sorting: any;
    page: number;
    pageSize: number;
};

class DataSource {
    
    public loadColumns(dataQuery: DataQueryType) {}
    public load(dataQuery: DataQueryType) {}
    public exportData(dataQuery: DataQueryType, columns: any) {}
    public asyncExportData(dataQuery: DataQueryType, columns: any) {}

    public getSortValue<T>(row: T, sortingProperty: keyof T) {
        return row[sortingProperty];
    }

    public sort(dataQuery: DataQueryType, rows: any[]): any[] {
        const sortingProperty = _(_.keys(dataQuery.sorting) || []).first();
        const sortingOrder = dataQuery.sorting[sortingProperty];
    
        if (!sortingProperty) {
            return rows;
        }
    
        const result = _(rows).sortBy((row) => {
            const value = ko.unwrap(this.getSortValue(row, sortingProperty));
            const toFloat = parseFloat(value);
    
            return !isNaN(toFloat) ? toFloat : value;
        });
        return (sortingOrder === "desc") ? result.reverse() : result;
    }

    public page(dataQuery: DataQueryType, rows: any[]): any[] {
        let startIndex = (dataQuery.page - 1) * dataQuery.pageSize;
        let endIndex: number = startIndex + dataQuery.pageSize;
    
        if (startIndex >= rows.length) {
            dataQuery.page = 1;
            startIndex = 0;
            endIndex = dataQuery.pageSize;
        }
        
        return rows.slice(startIndex, endIndex);
    }


    public getTotalRows(dataQuery: DataQueryType) : JQueryDeferred<number> | JQueryPromise<number> | number{
        if (koUtils.unpack(dataQuery.entities)) {
            return $.Deferred<number>().resolve(dataQuery.entities().length);
        }
        return 0;
    }

    public destroy() {}
    
}

export = DataSource;
