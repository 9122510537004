/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/antiForgeryToken"/>
import * as _ from "underscore";

const token: {value?: any} = {};

export function init(inputToken: string) {
    const wrapper = document.createElement('div');
    wrapper.innerHTML = inputToken;
    const input = wrapper.firstElementChild as HTMLInputElement;
    token['value'] = input.value;
}

export function addToHeaders(options?: JQueryAjaxSettings) {
    if (options && (!options.type || isMethodVunerable(options.type))) {
        options.headers = _.extend({ __RequestVerificationToken: get() }, options.headers);
    }
}

export function get() {
    return token['value'];
}

export function getSecureHeaderPart() {
    return { __RequestVerificationToken: get() };
}

function isMethodVunerable(method: string): boolean {
    switch (method) {
        case "POST":
        case "PUT":
        case "DELETE":
            return true;
        default:
            return false;
    }
}
