///<amd-module name="Core/Medius.Core.Web/Scripts/components/topBar/TopBarLeftSide" />
import * as React from "react";
import { VersionMismatchDialogTemplate } from "Core/Medius.Core.Web/Scripts/components/versionMismatchDialog/versionMismatchDialogTemplate";
import * as featureToggle from "Core/Medius.Core.Web/Scripts/Medius/core/featureToggle";
import { getBasePath, toAction } from "Core/Medius.Core.Web/Scripts/Medius/lib/path";
import { DrawerWithStore } from "Core/Medius.Core.Web/Scripts/components/topBar/drawer/drawerWithStore";
import * as appConfig from "Core/Medius.Core.Web/Scripts/appConfig";
import { 
    IconActionHomeFill, 
    IconFinanceChartPiesliceFill,
    IconEditingNotepadFill,
    MenuButton,
    IconActionNotePencilFill,
    IconEditingTrayFill,
} from "@medius/ui-controls";
import { logHomeClicked } from "Core/Medius.Core.Web/Scripts/components/topBar/uxMetrics";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as cookies from "Core/Medius.Core.Web/Scripts/lib/cookie";
import { getNavLinkClassName, isActivePage } from "Core/Medius.Core.Web/Scripts/components/topBar/topBarUtils";
import { useLocation } from "react-router-dom";
import { APANavigationRoutes } from "Core/Medius.Core.Web/Scripts/components/topBar/topBarUtils";

export interface ITopBarLeftSideProps{
    versionMismatchData: {
        isVersionInvalid: boolean;
        isSessionExpired: boolean;
    };
    documentTypes: {
        name: string;
        url: string;
    }[];
    isQaIconVisible: boolean;
}

export const LeftSide = ({ versionMismatchData, documentTypes, isQaIconVisible }: ITopBarLeftSideProps) => {
    const [isLabelHidden, hideLabel] = React.useState(isWindowResized());
    const isHomeEnabled = featureToggle.isHomePageEnabled();
    const homeUrl = `${getBasePath()}#/Home`;
    const inboxUrl = `${getBasePath()}#/Tasks`;
    const dashboardUrl = `${getBasePath()}#/Dashboard`;
    const reportsUrl = `${getBasePath()}#/Reports`;
    const advancedSearchUrl = `${getBasePath()}#/AdvancedSearch`;
    const { pathname } = useLocation();
    
    const homePageClick = () => {
        logHomeClicked("topBar");
        window.location.href=homeUrl;
    };

    const navigateOnDocumentCreate = (item: any) => {
        window.location.href = item.url;
    };

    function isWindowResized(): boolean {
        return window.matchMedia("screen and (max-width: 1400px)").matches;
    }

    React.useEffect(() => {
        window.addEventListener("resize", () => hideLabel(isWindowResized()), false);

        return () => {
            window.removeEventListener("resize", () => hideLabel(isWindowResized()), false);
        };
    }, []);

    return <div className="top-bar__left_side">
            <VersionMismatchDialogTemplate 
                isSessionExpired={versionMismatchData.isSessionExpired} 
                isVersionInvalid={versionMismatchData.isVersionInvalid} 
            />
            <DrawerWithStore />
            <div className="top-bar-header">
                <a href={isHomeEnabled ? homeUrl : inboxUrl} className="top-bar-logo" data-testid="top-bar-logo-link">
                    <img 
                        className="top-bar-logo__image" 
                        data-testid="topbar-logo" 
                        alt="Medius"
                        src={toAction(`LogoHome?v=${appConfig.getApplicationVersion()}`, "~/Branding")}
                    />
                    <div className="top-bar-header-text" data-testid="top-bar-header-text">
                        <span>AP Automation</span>
                    </div>
                </a>
            </div>
            <div className="top-bar-triangle"></div>
            { isQaIconVisible && 
                <div className="top-bar__qa-note" data-testid="top-bar-qa-icon">
                    <img 
                        alt="QA note" 
                        src={`${getBasePath()}Content/images/qa-28.png`}
                        width="22" 
                        height="18" 
                    />
                </div> 
            }
            { isHomeEnabled && <a 
                className={getNavLinkClassName(isActivePage(APANavigationRoutes.homeUrl, pathname))}
                href={homeUrl}
                onClick={homePageClick}
                data-testid="home-link"
                title="Home">
                <div className="top-bar__icon_container">
                    <IconActionHomeFill className="top-bar__icon" size="medium" />
                </div>
                <span 
                    id="top-bar-icon-name" 
                    className="top-bar-icon-name"
                    data-testid="topBarHomeIconText"
                >
                {translate('#Core/topBarHomeIconText')}</span>
            </a>
            }
            <a 
                href={inboxUrl}
                className={getNavLinkClassName(isActivePage(APANavigationRoutes.inboxUrl, pathname))}
                data-testid="inbox-link"
                title="Inbox">
                <div className="top-bar__icon_container">
                    <IconEditingTrayFill className="top-bar__icon" size="medium" />
                </div>
                <span id="top-bar-icon-name" className="top-bar-icon-name">{translate('#Core/topBarInboxIconText')}</span>
            </a>
            <a 
                href={dashboardUrl} 
                className={getNavLinkClassName(isActivePage(APANavigationRoutes.dashboardUrl, pathname))}
                data-testid="dashboard-link"
                title="Dashboard">
                <div className="top-bar__icon_container">
                    <IconFinanceChartPiesliceFill className="top-bar__icon" size="medium" />
                </div>
                <span id="top-bar-icon-name" className="top-bar-icon-name" data-bind="text: dashboardButtonName">
                    {translate('#Core/topBarDashboardIconText')}
                </span>
            </a>
            <a
                href={reportsEnabled() ? reportsUrl : advancedSearchUrl} 
                className={
                    getNavLinkClassName(
                        isActivePage(APANavigationRoutes.reportsUrl, pathname) || 
                        isActivePage(APANavigationRoutes.advancedSearchUrl, pathname)
                    )
                }
                data-testid="advanced-search-link"
                title="Advanced Search">
                <div className="top-bar__icon_container">
                    <IconEditingNotepadFill className="top-bar__icon" size="medium" />
                </div>
                <span id="top-bar-icon-name" className="top-bar-icon-name">
                    {translate('#Core/topBarReportsIconText')}
                </span>
            </a>
            { documentTypes.length > 0 && 
                <MenuButton
                    variant= {isActivePage(APANavigationRoutes.createUrl, pathname) ? "frameHeaderPrimary" : "frameHeaderInline"}
                    items={documentTypes.map(dt => ({...dt, label: dt.name, key: dt.name}))}
                    icon={<>
                        <IconActionNotePencilFill size="medium" />
                    </>}
                    label={translate("#Core/topBarCreateIconText")}
                    onItemSelected={(item) => navigateOnDocumentCreate(item)}
                    position="bottom"
                    hideLabel={isLabelHidden}
                    data-testid="open-document-menu-button"
                />
            }
    </div>;
};

function reportsEnabled() {
    return cookies.getCookie("ReportsDefault")
        || cookies.getCookie("ReportsDefault") === null;
}