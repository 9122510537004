/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/ImportConfiguration/importConfiguration"/>
import * as React from "react";
import { useEffect, useState, useCallback } from "react";
import { SidebarNavigation } from "Core/Medius.Core.Web/Scripts/AdminPages/sidebarNavigation";
import { translate, getFormattedLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { Grid, GridColumn, GridCellProps, GridProps, GridDataStateChangeEvent } from "@progress/kendo-react-grid";
import { Splitter, SplitterOnChangeEvent, SplitterPaneProps } from "@progress/kendo-react-layout";
import { handleError } from "Core/Medius.Core.Web/Scripts/lib/errorHandling/errorHandler";
import { success } from "Core/Medius.Core.Web/Scripts/Medius/core/notification";

import { MaskingKendoReactGridLoadingPanel } from "../../shared/components/loadingPanel";
import { ActionButtons } from "../../components/administration/buttons/actionButtons";

import { useLocalStorage } from "Core/Medius.Core.Web/Scripts/shared/hooks/useLocalStorage";

import { getGridItems, getItemFields, getCompanies, updateConfigurationItem } from "./services";
import {
    ImportConfigurationDto, ImportConfigurationFieldDto, Company,
    IntegrationUpdateConfigurationData, UpdateFieldDto, IntegrationUpdateConfigurationDto
} from "./interfaces";

import { Switch, Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";

import { PromiseBasedCompanyAutocompleterContainer } from "../../components/administration/autocompleters/company/promiseBasedCompanyAutocompleterContainer";

export function ImportConfigurationAdminPage() {
    const [isLoading, setIsLoading] = useState(true);

    const [dataState, setDataState] = useState<GridProps>({
        skip: 0,
        take: 20,
        sort: [],
        filter: {
            logic: "and",
            filters: []
        }
    });

    const [selectedCompany, setSelectedCompany] = useState<Company>(null);

    const defaultPaneSettings: SplitterPaneProps[] = [
        { size: '60%', min: '20px' },
        { min: '20px' }
    ];
    const dataStateChanged = (e: GridDataStateChangeEvent) => {
        setDataState({
            ...e.dataState
        });
    };

    const [panesState, setPanesState] = useLocalStorage("importConfiguration_panes", defaultPaneSettings);

    const [gridItems, setGridItems] = useState({ data: [] as ImportConfigurationDto[], total: 0 });

    const [selectedItem, setSelectedItem] = useState<ImportConfigurationDto>(null);

    const [rootCompany, setRootCompany] = useState<Company>(null);

    const reload = useCallback(() => {
        setIsLoading(true);

        getGridItems(dataState.skip, dataState.take, null, dataState.sort[0]?.field, dataState.sort[0]?.dir)
            .then((dto) => {
                setRootCompany(dto.rootCompany);
                setGridItems({ data: dto.data, total: dto.total });
                setIsLoading(false);
            }, (e) => {
                handleError(e);
                setIsLoading(false);
            });

    }, [dataState.skip, dataState.take, dataState.sort]);

    function saveConfiguration() {
        const fieldData: Array<UpdateFieldDto> = [];

        selectedItem.fields.forEach(element => {
            fieldData.push({
                field: element.field,
                disabled: element.disabled,
                inherited: element.inherited,
            });
        });

        const updateDto: IntegrationUpdateConfigurationDto = {
            companyId: selectedCompany?.id,
            fields: fieldData
        };

        const updateData: IntegrationUpdateConfigurationData = {
            integrationUpdateConfiguration: updateDto,
            importInOrder: selectedItem.importInOrder,
            itemType: selectedItem.itemType,
        };

        updateConfigurationItem(selectedItem.id, updateData).then(() => {
            success(translate("#Core/changesSavedSuccessfully"));
            reload();
        }, (e) => {
            handleError(e);
        });
    }

    function updateCompany(c: Company, item: ImportConfigurationDto) {
        if (c == null) {
            setSelectedCompany(c);
            return;
        }

        getItemFields(item.id, c.id)
            .then((dto: Array<ImportConfigurationFieldDto>) => {
                item.fields = dto;
                setSelectedCompany(c);
                setSelectedItem(item);
            }, (e) => {
                handleError(e);
            });
    }

    useEffect(() => {
        reload();
    }, [reload]);

    return (
        <>
            <SidebarNavigation />
            <div className="importConfiguration">
                <Splitter
                    panes={panesState}
                    onChange={(e: SplitterOnChangeEvent) => { setPanesState(e.newState); }}
                    className="importConfiguration__splitter"
                >
                    <div className="importConfiguration__gridPanel">
                        {isLoading && <MaskingKendoReactGridLoadingPanel gridSelector=".importManagement__queueItemsGrid" />}

                        <h2 className="administration-page-header">{translate("#Core/importConfiguration")}</h2>
                        <Grid
                            className="importConfiguration__itemsGrid"
                            onDataStateChange={dataStateChanged}
                            {...dataState}
                            total={gridItems.total}
                            data={gridItems.data.map(entry => ({
                                ...entry,
                                selected: selectedItem?.id === entry.id
                            }))}
                            pageable
                            sortable
                            onSortChange={({ sort }) => {
                                setDataState((data) => ({ ...data, sort }));
                            }}
                            selectedField="selected"
                            onRowClick={({ dataItem }) => {
                                updateCompany(rootCompany, dataItem);
                            }}
                            reorderable
                            resizable
                            scrollable={"scrollable"}
                        >
                            <GridColumn field="itemType" title={translate("#Core/itemType")} sortable={true} filterable= {false} width="300px" />
                            <GridColumn field="importInOrder" title={translate("#Core/importInOrder")} sortable={true} filterable= {false} width="150px"
                            cell={ImportInOrderCell} />
                            <GridColumn field="isIntegrationUpdateConfigurable" title={translate("#Core/isIntegrationUpdateConfigurable")} sortable={true} filterable= {false} width="200px"
                            cell={IsIntegrationUpdateConfigurableCell} />
                        </Grid>
                    </div>
                    <div className="importConfiguration__right-side">
                        <ActionButtons
                            saveButtonLabel={translate("#Core/save")}
                            onSave={saveConfiguration}
                            saveDisabled={false}
                            saveVisible={selectedItem !== null}
                            onDelete={function noRefCheck() { }}
                            deleteButtonLabel="Delete"
                        />
                        <IntegrationConfigurationEditor
                            selectedItem={selectedItem}
                            setSelectedItem={(a) => { setSelectedItem((x) => ({ ...x, importInOrder: a })); }}
                            setSelectedFields={(flds) => { setSelectedItem((x) => ({ ...x, fields: flds })); }}
                            setCompany={(c) => updateCompany(c, selectedItem)}
                            selectedCompany={selectedCompany}
                        />
                    </div>
                </Splitter>
            </div>
        </>);
}

interface IntegrationConfigurationEditorProps {
    selectedItem: ImportConfigurationDto;
    setSelectedItem: (p: boolean) => void;
    setSelectedFields: (p: Array<ImportConfigurationFieldDto>) => void;
    setCompany: (c: Company) => void;
    selectedCompany: Company;
}

function IntegrationConfigurationEditor({
    selectedItem,
    setSelectedItem,
    setSelectedFields,
    setCompany,
    selectedCompany }: IntegrationConfigurationEditorProps) {

    if (selectedItem == null)
        return (<></>);

    return (
        <div>
            <h3>{selectedItem.itemType}</h3>

            <div className="importConfiguration__configuration-property">
                <div>{translate("#Core/importInOrder")}</div>
                <Switch
                    id="importConfiguration__toggle-importInOrder"
                    offLabel={translate("#Core/no")}
                    onLabel={translate("#Core/yes")}
                    checked={selectedItem?.importInOrder}
                    onChange={({ target }) => {
                        setSelectedItem(target.value);
                    }} />
            </div>

            {selectedItem.fields.length > 0 && <span>
                <h4 className="importConfiguration__right-side__header">{translate("#Core/fields")}</h4>
                <div className="importConfiguration__configuration-property required">
                    <PromiseBasedCompanyAutocompleterContainer
                        onCompanyChange={(e) => 
                        {
                            setCompany(e.target.value);
                        }}
                        selectedItem={selectedCompany}
                        getCompaniesPromise={getCompanies}
                        textFieldSelector="name"
                        disabled={false}
                        defaultLabel={translate("#Core/company")}
                    />
                </div>
                <FieldsEditor fields={selectedItem.fields} setSelectedFields={setSelectedFields}></FieldsEditor>
            </span>
            }
        </div>);
}

interface IntegrationConfigurationFieldEditorProps {
    fields: Array<ImportConfigurationFieldDto>;
    setSelectedFields: (p: Array<ImportConfigurationFieldDto>) => void;
}

function FieldsEditor({ fields, setSelectedFields }: IntegrationConfigurationFieldEditorProps) {
    const listItems = fields.map((field: ImportConfigurationFieldDto, i: number) => 
        <span key={i}>
            <div>
                <h5 className="importConfiguration__configuration-field-header">{field.localizedField}</h5>

                <div className="importConfiguration__configuration-field-property">
                    {field.inherited && <Checkbox
                        disabled={true}
                        label={translate("#Core/disableIntegrationUpdates")}
                        checked={field.inheritedValue}
                     />}
                    {!field.inherited && <Checkbox
                        label={translate("#Core/disableIntegrationUpdates")}
                        checked={field.disabled}
                        disabled={false}
                        onChange={(e: CheckboxChangeEvent) => {
                            field.disabled = e.value;
                            setSelectedFields(fields);
                        }} />}
                </div>

                <div className="importConfiguration__configuration-field-property">
                    <InheritanceField field={field} fields={fields} setSelectedFields={setSelectedFields} ></InheritanceField>
                </div>
            </div>
        </span>
    );

    return (
        <span>{listItems}</span>
    );
}

interface InheritanceFieldProps {
    field: ImportConfigurationFieldDto;
    fields: Array<ImportConfigurationFieldDto>;
    setSelectedFields: (p: Array<ImportConfigurationFieldDto>) => void;
}

function InheritanceField(props: InheritanceFieldProps) {
    const isInherited = props.field.inherited;
    let label = translate("#Core/currentConfigurationProperty");
    if (isInherited) {
        label = getFormattedLabelTranslation("#Core/inheritedConfigurationProperty", [props.field.inheritedFrom]);
    }
    return <div>
        <Checkbox
            label={label}
            checked={props.field.inherited == false}
            disabled={props.field.isForRootCompany}
            onChange={(e: CheckboxChangeEvent) => {
                props.field.inherited = !e.value;
                props.setSelectedFields(props.fields);
            }} />
    </div>;
}


const ImportInOrderCell = ({ dataItem }: GridCellProps) => (
    <td>
        {dataItem.importInOrder ? translate("#Core/yes") : translate("#Core/no")}
</td>
);

const IsIntegrationUpdateConfigurableCell = ({ dataItem }: GridCellProps) => (
    <td>
        {dataItem.isIntegrationUpdateConfigurable ? translate("#Core/yes") : translate("#Core/no")}
</td>
);
