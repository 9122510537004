/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Entities.Role/servicesDataSource"/>


import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import DataSource = require("Core/Medius.Core.Web/Scripts/Medius/components/grid/dataSource/default");
import { Deferred } from "jquery";
export class DataServices extends DataSource{
    EntityCollection: any;
    constructor(entityCollection: any) {
        super();
        this.EntityCollection = entityCollection;
    }

    load(dataQuery: any) {
        const startIndx = (dataQuery.page - 1) * dataQuery.pageSize;
        const endIndx = dataQuery.page * dataQuery.pageSize;
        const entities = this.EntityCollection.Collection().slice(startIndx, endIndx);

        return Deferred().resolve(entities);
    }

    loadColumns(){

        const columns: any[] = [
            {
                ColumnName: getLabelTranslation("#Core/serviceName"),
                ValueSource: "Name",
                ValueSourceType: "System.String",
                Searchable: false,
                Sortable: false,
                Width: 600
            },
            {
                ColumnName: "",
                ValueSource: null,
                Searchable: false,
                Sortable: false,
                Width: 80
            }
        ];
        return Deferred().resolve(columns);
    }

    getTotalRows() {
        return this.EntityCollection.Collection().length;
    }

    destroy() {
        this.EntityCollection = null;
        super.destroy();
    }
}
