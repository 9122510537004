/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/utils" />

import * as logger from "Core/Medius.Core.Web/Scripts/Medius/lib/logger";
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import * as _ from "underscore";
import * as ko from "knockout";

/*
* Creates a standard binding clutter code with given component model factory
* and default binding params.
*/
export function createBinding(modelFactory: any, defaults: any, settings = {} as any) {
    return {
        init: function(element: any, bindingAccessor: any, allBindingsAccessor: any, viewModel: any, bindingContext: any) {

            const continuation = settings.continuation;
            let value = null, args = null;
            
            if (isNullOrUndefined(bindingAccessor().value)) {
                return { controlsDescendantBindings: true };
            }

            const params = _({}).extend(defaults, bindingAccessor());
            const model = modelFactory.create(params, viewModel, bindingContext.components);

            let newContext = bindingContext.createChildContext(model);
            newContext = _(newContext).extend({
                $component: model
            });
            
            ko.utils.domNodeDisposal.addDisposeCallback(element, function() {
                newContext.$component = null;
                newContext.$rawData = null;
                newContext.$data = null;
                model.dispose();
                newContext = null;
            });

            ko.renderTemplate(model.Template, newContext, {}, element, "replaceChildren");

            if (continuation) {
                args = [model, params].concat(Array.prototype.slice.call([element, bindingAccessor, allBindingsAccessor, viewModel, bindingContext]));
                value = continuation.apply(this, args);
            }

            return value || { controlsDescendantBindings: true };
        }
    };
}

/*
* Constructs the name for the binding and registers it.
*/
export function emitBinding(prefix: any, bindingConfiguration: any) {
    const factory = require(bindingConfiguration.factory);

    const continuationModule = bindingConfiguration.continuation;

    const prefixPart = prefix ? `${prefix}-` : "";
    const bindingName = `${prefixPart}${bindingConfiguration.name}`;

    const settings = {
        continuation: continuationModule && require(continuationModule),
        name: bindingName
    };

    const binding = createBinding(factory, bindingConfiguration.defaults, settings);

    koUtils.registerBinding(bindingName, binding);
    logger.info(`Registered binding '${bindingName}' with model '${bindingConfiguration.factory}'`);
}

export function getConfiguration(name: any, params: any) {
    const config = {
        name: name,
        defaults: {}
    };
    return _(config).extend(params);
}

export function registerBindings(configuration: any, utils: any) {
    _(configuration).each(function (cfg, name) {
        const bindingConfig = utils.getConfiguration(name, cfg);
        utils.emitBinding(bindingConfig);
    });
}