/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/image/sendEmail"/>
import {observableArray, observable, pureComputed, unwrap} from "knockout";
import {error, success} from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import {translate, getFormattedLabelTranslation} from "Core/Medius.Core.Web/Scripts/lib/globalization";
import {get} from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rest";
import {ajax} from "Core/Medius.Core.Web/Scripts/Medius/core/rpc";
import {handleAnyError} from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import {each} from "underscore";
import {isEmptyString, isNotAStringOrEmpty} from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import {logImageSendByEmail} from "Core/Medius.Core.Web/Scripts/components/pdfViewer/pdfViewerUxLog";
import * as user from "Core/Medius.Core.Web/Scripts/Medius/lib/utils/user";
import * as $ from "jquery";
import * as _ from "underscore";
import * as sync from "Core/Medius.Core.Web/Scripts/Medius/core/sync";
import { getRegex } from "Core/Medius.Core.Web/Scripts/Medius/core/validation/email";

const emailRegex = getRegex();

class SendEmail {

    public receivers = observableArray<any>([]);
    public subject = observable();
    public message = observable();    
    public userReceiver = observable<any>();
    public emailReceiver = observable<string>();
    public sendButtonDisabled = observable(false);
    public isDialogVisible = observable(false);        
    public avaliableUsers = observable();
    public isLoaded = observable(false);
    public currentImage: any;
    public companyId: number;
    public canAddUsers = observable(false);

    private documentType: string;

    constructor(fileImage: any, companyId: number, documentType?:string) {
        this.currentImage =  observable(fileImage);
        this.companyId = companyId;
        this.showUserAutocompleter(this.companyId);
        this.documentType = documentType;
    }

    public showDialog = () =>  {
        this.isDialogVisible(true);
    };

    public getUsers = () =>  {
        get("invoiceimageusers", this.companyId)
        .then( (users) =>  {			
            this.avaliableUsers(users);	
            this.isLoaded(true);	
        });          
    };

    public showUserAutocompleter = (companyId: number) => {
        if (companyId) {
            this.canAddUsers(true);
            this.getUsers();
        }
    };

    public checkEmails =  (emailArray: _.List<string>) => {
        const validEmails: string[] = [];

        each(emailArray, (address: string) => {
            address = address || "";
            address = address.trim();

            if ((isEmptyString(address) || (!isEmptyString(address) && !emailRegex.test(address)))) {
                const invalidMsg = getFormattedLabelTranslation("#Core/invalidEmail_address", [address]);
                error(invalidMsg);
            } else {
                validEmails.push(address);
            }
        });

        return validEmails;
    };

    public areReceiversDefined = pureComputed( () => this.receivers().length > 0);

    public findReceiverByEmail = (email: string) => {
        return _(this.receivers()).find( (receiver: { email: string })  => receiver.email === email);
    };

    public validateEmail = (email: string) => {

        if (this.findReceiverByEmail(email)) {
            error(translate("#Core/emailAlreadyAdded"));
            return false;
        }

        return this.checkEmails([email]).length === 1;
    };

    public addUser = () =>  {
        const selectedReceiver = this.userReceiver() || {};
        const  userName = unwrap(selectedReceiver.userName);
        const   userEmail = unwrap(selectedReceiver.email);

        if (isEmptyString(userEmail)) {
            error(getFormattedLabelTranslation("#Core/lackOfUserEmail_userName", [userName]));
            return false;
        }

        if (this.validateEmail(userEmail)) {
            this.receivers.push(new Receiver(userName, userEmail));
            this.userReceiver(null);         
        }
        return undefined;
    };

   public addEmail =  () => {
        const email = this.emailReceiver();

        if (this.validateEmail(email)) {
            this.receivers.push(new Receiver(email, email));
            this.emailReceiver(null);
        }
    };

    public removeEmail = (receiver: any) => {
        this.receivers.remove(receiver);
    };
    
    public getReceiversEmails = () =>  {
        return this.receivers().map(receiver => receiver.email);
    };

    public sendEmail = () =>  {
        const userId = user.getEntity().Id;

        this.sendButtonDisabled(true);

        if ( this.receivers().length === 0 || isNotAStringOrEmpty( this.subject()) || isNotAStringOrEmpty( this.message())) {
            error(translate("#Core/fillAllRequiredFields"));
            this.sendButtonDisabled(false);
            return;
        }

        const sendToList = this.checkEmails( this.getReceiversEmails());

        if (sendToList.length === 0) {
            error(translate("#Core/lackOfValidEmails"));
            this.sendButtonDisabled(false);
            return;
        }

        $.when(
            sync.load("Medius.Core.Entities.User", userId)
        ).done( () =>  {
            const emailInfo = {
                Subject:  this.subject(),
                Message:  this.message(),
                DocId:  this.currentImage().docId(),
                DocType:  this.currentImage().docType(),
                Hash:  this.currentImage().hashFile().Hash(),
                Tag:  this.currentImage().hashFile().Type()
            };

            ajax("MediaManager", "SendEmail", {
                data: JSON.stringify({
                    receivers: sendToList,
                    emailInfo: emailInfo
                })
            }).done(() =>  {
                this.isDialogVisible(false);
                success(translate("#Core/emailSentSuccessfully"));
                logImageSendByEmail(this.documentType);
            }).fail( (err: any) => {
                handleAnyError(err);
            }).always(() =>  {
                this.sendButtonDisabled(false);
            });
        });
    };
}

export class Receiver {
    public label: any;
    public email: string;

    constructor(label: any, email: string) {
        this.label = label;
        this.email = email;
    }

} 

export function create(fileImage: any, companyId: number, documentType?:string) {
    return new SendEmail(fileImage, companyId, documentType);
}
