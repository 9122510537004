/// <amd-module name="Core/Medius.Core.Web/Scripts/components/administration/page/page"/>
import * as React from "react";
import { useState, useEffect, useCallback, Dispatch, SetStateAction } from "react";
import { useLocalStorage } from "Core/Medius.Core.Web/Scripts/shared/hooks/useLocalStorage";
import { SidebarNavigation } from "Core/Medius.Core.Web/Scripts/AdminPages/sidebarNavigation";
import { Splitter, SplitterPaneProps } from "@progress/kendo-react-layout";
import { LoadingPanel } from "Core/Medius.Core.Web/Scripts/shared/components/loadingPanel";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { success } from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import { handleAnyError } from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import { debounce } from "underscore";
import { ActionButtons } from "Core/Medius.Core.Web/Scripts/components/administration/buttons/actionButtons";
import { AddButton } from "Core/Medius.Core.Web/Scripts/components/administration/buttons/addButton";
import { Title } from "Core/Medius.Core.Web/Scripts/components/administration/title";
import { AdministrationGrid } from "Core/Medius.Core.Web/Scripts/components/administration/page/grid/grid";
import { AdministrationEntity, IAdministrationEntity } from "./types";

interface AdministrationEntityList<TEntity extends IAdministrationEntity=any> {
    Entities: TEntity[],
    TotalEntities: number
}

interface AdministrationPageProps<TEntity extends IAdministrationEntity=any> {
    entityType: string,
    title: string,
    selectedItemState: [selectedItem: TEntity, setSelectedItem: Dispatch<SetStateAction<TEntity>>],
    loadGridData: (
        page: number,
        limit: number,
        filter: Record<string, unknown>) => JQueryPromise<AdministrationEntityList>,
    getEmpty: (id: number) => TEntity,
    get: (id: number) => JQueryPromise<AdministrationEntity<TEntity>>,
    canUserAdd: () => JQueryPromise<boolean>,
    canUserSave: boolean,
    add: (data: any) => JQueryPromise<any>,
    update: (id: number, data: any) => JQueryPromise<any>,
    remove: (id: number) => JQueryPromise<any>,
    columns: JSX.Element[],
    rightSide: JSX.Element,
    splitterSize?: string
}

export const AdministrationPage = <TEntity extends IAdministrationEntity=any>({
    entityType,
    title,
    selectedItemState: [selectedItem, setSelectedItem],
    loadGridData,
    getEmpty,
    get,
    canUserAdd,
    canUserSave,
    add,
    update,
    remove,
    columns,
    rightSide,
    splitterSize
}: AdministrationPageProps<TEntity>) => {
    const pageSize = 20;
    const newItemId = -1;

    const defaultPaneSettings: SplitterPaneProps[] = [
        { size: splitterSize ?? '60%', min: '20px' },
        { min: '20px' }
    ];

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const debounceSetIsLoading = useCallback(debounce(setIsLoading, 300), []);
    const [panesState, setPanesState] = useLocalStorage(`administration-${entityType}_panes`, defaultPaneSettings);
    const [gridData, setGridData] = useState<TEntity[]>([]);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [canAdd, setCanAdd] = useState<boolean>(false);
    const [canEdit, setCanEdit] = useState<boolean>(false);
    const [canDelete, setCanDelete] = useState<boolean>(false);

    const onError = useCallback((response: JQueryXHR) =>
        handleAnyError(response), []);

    const loadData = useCallback((
        page: number = 1,
        limit: number = pageSize,
        filter: Record<string, unknown> = null) =>
        loadGridData(page, limit, filter)
            .then(data => {
                setGridData(data.Entities);
                setTotalItems(data.TotalEntities);
            })
            .fail(onError)
            
            .always(() => debounceSetIsLoading(false)),
        [debounceSetIsLoading, loadGridData, onError]);

    const addEmptyItem = () => {
        setCanEdit(true);
        let newItem = gridData.find(item => isNew(item));

        if (newItem == null) {
            newItem = getEmpty(newItemId);
            setGridData([...gridData, newItem]);
        }

        setSelectedItem(newItem);
    };

    const getItem = (id: number) => {
        const itemId = gridData.find(item => item.Id == id)?.Id ?? newItemId;
        const newGridData = [...gridData.filter(item => item.Id > -1)];

        debounceSetIsLoading(true);
        return get(id)
            .then(data => {
                const index = gridData.findIndex(item => item.Id == itemId);
                if (index > -1)
                    newGridData[index] = data.Entity;

                setSelectedItem(data.Entity);
                setCanEdit(data.CanUpdate);
                setCanDelete(data.CanDelete);
            })
            .always(() => {
                setGridData(newGridData);
                debounceSetIsLoading(false);
            });
    };

    const addItem = (entityData: AdministrationEntity<TEntity>) =>
        add(entityData)
            .then((data, textStatus, jqXHR) => {
                successMessage("#Core/changesSavedSuccessfully");
                setTotalItems(total => total + 1);
                getItem(getNewItemId(jqXHR));
            })
            .fail(onError);

    const updateItem = (entityData: AdministrationEntity<TEntity>) =>
        update(entityData.Entity.Id, entityData)
            .then(() => {
                successMessage("#Core/changesSavedSuccessfully");
                getItem(selectedItem.Id);
            })
            .fail(onError);

    const removeItem = () => {
        debounceSetIsLoading(true);
        return remove(selectedItem.Id)
            .then(() => {
                successMessage("#Core/entityRemovedSuccessfully");
                setGridData(gridData.filter(item => item.Id !== selectedItem.Id));
                setTotalItems(total => total - 1);
                setSelectedItem(null);
            })
            .fail(onError)
            .always(() => debounceSetIsLoading(false));
    };

    const saveItem = (): JQueryPromise<any> => {
        const data = {
            Entity: {
                $type: `${entityType}, Medius.PurchaseToPay.Common`,
                ...selectedItem,
                Company: selectedItem.Company?.Id > 0
                    ? selectedItem.Company
                    : null
            }
        } as AdministrationEntity<TEntity>;

        debounceSetIsLoading(true);
        if (isNew(selectedItem))
            return addItem(data)
                .always(() => debounceSetIsLoading(false));
        else
            return updateItem(data)
                .always(() => debounceSetIsLoading(false));
    };

    const getNewItemId = (response: JQueryXHR) =>
        parseInt(response.getResponseHeader("content-location").split('/')[1]);

    const isNew = (item: TEntity) =>
        item.Id <= 0;

    const successMessage = (translationKey: string) =>
        success(translate(translationKey));

    useEffect(() => {
        canUserAdd()
            .then(value => setCanAdd(value));

        loadData();
    }, [canUserAdd, loadData]);

    return (<>
        <SidebarNavigation />
        <div className="administration-page">
            <Splitter
                panes={panesState}
                onChange={(e) => { setPanesState(e.newState); }}>
                <div className="administration-page__left-side">
                    {isLoading && <LoadingPanel />}
                    <div className="administration-page__left-side__title-bar">
                        <Title label={title} />
                        <AddButton
                            canAdd={canAdd}
                            label={translate("#Core/add")}
                            onClick={addEmptyItem}
                            disabled={isLoading} />
                    </div>
                    <AdministrationGrid
                        columns={columns}
                        data={gridData}
                        selectedItem={selectedItem}
                        total={totalItems}
                        pageSize={pageSize}
                        onDataReload={loadData}
                        onRowClick={({ dataItem }) => getItem(dataItem.Id)} />
                </div>
                <div className="administration-page__right-side">
                    {isLoading && <LoadingPanel />}
                    {selectedItem &&
                    <div>
                        <ActionButtons
                            saveButtonLabel={translate("#Core/save")}
                            deleteButtonLabel={translate("#Core/delete")}
                            onSave={saveItem}
                            onDelete={removeItem}
                            deleteDisabled={isLoading}
                            saveDisabled={isLoading || !canUserSave}
                            deleteVisible={canDelete && !isNew(selectedItem)}
                            saveVisible={canEdit} />
                        {rightSide}
                    </div>}
                </div>
            </Splitter>
        </div>
    </>);
};
