///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/core/gadget/chart"/>
import * as $ from "jquery";
import * as html from "Core/Medius.Core.Web/Scripts/Medius/core/html";
import * as simplePresenter from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/simplePresenter";
import * as _ from "underscore";

function valueExtractor(data:any) {
    return data.DisplayValue || data;
}

export class Chart {
    previousPoint: null;
    showTooltip: (x: number, y: number, contents: any) => void;
    registerHooverBehaviour: (placeholder: any) => void;
    getDefaultOption: (type: any) => any;
    getDefaultDataSerie: (data: any, type: any, serieName: any) => any;
    init: (placeholder: any, dataSerie: any, option: any, type: any) => void;
    initGraph: (placeholder: any, data: any, type: any, serieName: string, isDate: boolean, options: any) => void;
    initNumberGraph: (placeholder: any, data: any, type: any, serieName: string, options: any) => void;
    initDateGraph: (placeholder: any, data: any, type: any, serieName: string, options: any) => void;
    initStringGraph: (placeholder: any, data: any, type: any, serieName: string, options: any) => void;
    constructor() {
        this.previousPoint = null;

        const showPieTooltip = function (x:number, y:number, contents:any) {
            $('<div id="tooltip">' + contents + "</div>").css({
                position: "absolute",
                display: "none",
                top: y - 30,
                left: x - 10,
                border: "1px solid #CCC",
                borderRadius: "5px",
                padding: "2px",
                'background-color': "#fff",
                opacity: 1
            }).appendTo("body").fadeIn(0);
        };

        const registerPieHooverBehavior = (placeholder:any) => {
            ($("#" + placeholder) as any).bind("plothover", function (event:any, pos:any, item:any) {
                if (item) {
                    $("#tooltip").remove();
                    showPieTooltip(pos.pageX, pos.pageY, item.series.label + ": " +
                                   Math.round(item.series.percent) + "% " +
                                   "(" + simplePresenter.present(item.series.data[0][1]) + ")");
                } else {
                    $("#tooltip").remove();
                }
            });
        };

        this.showTooltip = (x:number, y:number, contents:any) => {
            $('<div id="tooltip">' + contents + "</div>").css({
                position: "absolute",
                display: "none",
                top: y + 5,
                left: x + 5,
                border: "1px solid #fdd",
                padding: "2px",
                'background-color': "#fee",
                opacity: 0.80
            }).appendTo("body").fadeIn(200);
        };

        this.registerHooverBehaviour = (placeholder:any) => {
            ($("#" + placeholder) as any).bind("plothover", function (event:any, pos:any, item:any) {
                if (item) {
                    if (this.previousPoint !== item.dataIndex) {
                        this.previousPoint = item.dataIndex;

                        $("#tooltip").remove();
                        const dataToPresent = item.series.data[item.dataIndex][2];

                        this.showTooltip(item.pageX, item.pageY,
											item.series.label + ": " + simplePresenter.present(dataToPresent));
                    }
                }
                else {
                    $("#tooltip").remove();
                    this.previousPoint = null;
                }
            });
        };

        this.getDefaultOption = function (type:any) {
            const option: any = {};

            option.colors = ["#5da5da", "#4d4d4d", "#faa43a", "#60bd68", "#f17cb0", "#b2912f", "#b276b2", "#decf3f", "#f15854"];

            if (type === "pie") {
                option.series = { pie: { show: true, radius: 9/10} };
                option.series.pie.label = {
                    show: true,
                    radius: 1,
                    formatter: function (label:any, series:any) {
                        return '<div class="chart-pie-label">' +
								Math.round(series.percent) + "%</div>";
                    },
                    background: { color: "#111" },
                    threshold: 0.025
                };

                option.legend = {
                    show: true,
                    labelFormatter: function (label:string, series:any) {
                        return '<div class="chart-legend-label">' +
								label + " " + Math.round(series.percent) + "%</div>";
                    }
                };
            }

            return option;
        };

        this.getDefaultDataSerie = function (data, type, serieName) {
            const dataSerie:any = {};
            dataSerie.data = data;
            dataSerie.label = html.sanitize(serieName);

            if (type === "bars") {
                dataSerie.bars = { show: true, fill: true };
            }
            else {
                dataSerie.lines = { show: true, fill: true };
            }
            return dataSerie;
        };

        this.init = (placeholder:any, dataSerie:any, option:any, type:any) => {
            let i, data;
            if (option.series) {
                data = [];
                for (i = 0; i < dataSerie.data.length; i += 1) {
                    data.push({ label: html.sanitize(dataSerie.data[i][0]),
                        data: valueExtractor(dataSerie.data[i][1])
                    });
                }
                dataSerie = data;
            }
            else {
                for (i = 0; i < dataSerie.data.length; i += 1) {
                    dataSerie.data[i][2] = dataSerie.data[i][1];
                    dataSerie.data[i][1] = valueExtractor(dataSerie.data[i][1]);
                }
                dataSerie = [dataSerie];
                this.registerHooverBehaviour(placeholder);
            }
            
            if (type === "pie") {
                registerPieHooverBehavior(placeholder);
            }
            option.grid = { hoverable: true, clickable: true };
            ($ as any).plot($("#" + placeholder), dataSerie, option);
        };

        this.initGraph = (placeholder:any, data:any, type:any, serieName:string, isDate:boolean, options:any) => {
            if (isDate) {
                this.initDateGraph(placeholder, data, type, serieName, options);
            }
            else if (isNaN(data[0][0])) {
                this.initStringGraph(placeholder, data, type, serieName, options);
            }
            else {
                this.initNumberGraph(placeholder, data, type, serieName, options);
            }
        };

        this.initStringGraph = (placeholder:any, data:any, type:any, serieName:string, options:any) => {
            const option = this.getDefaultOption(type);
            let i;

            _(option).extend(options);
            option.xaxis = {};
            option.xaxis.ticks = [];

            if (type !== "pie") {
                for (i = 0; i < data.length; i += 1) {
                    option.xaxis.ticks.push([i, data[i][0]]);
                    data[i][0] = i;
                }
            }

            const dataSerie = this.getDefaultDataSerie(data, type, serieName);
            this.init(placeholder, dataSerie, option, type);
        };

        this.initNumberGraph = (placeholder:any, data:any, type:any, serieName:string, options:any) => {
            const option = this.getDefaultOption(type);
            _(option).extend(options);
            const dataSerie = this.getDefaultDataSerie(data, type, serieName);
            this.init(placeholder, dataSerie, option, type);
        };

        this.initDateGraph = (placeholder:any, data:any, type:any, serieName:string, options:any) => {
            const option = this.getDefaultOption(type);
            let i;

            _(option).extend(options);
            for (i = 0; i < data.length; i += 1) {
                data[i][0] = new Date(data[i][0]);
            }
            option.xaxis = { mode: "time", timeformat: "%y-%0m-%0d" };


            const dataSerie = this.getDefaultDataSerie(data, type, serieName);

            this.init(placeholder, dataSerie, option, type);
        };
    }
}