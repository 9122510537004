///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/generic/bindings" />

import * as componentUtils from "Core/Medius.Core.Web/Scripts/Medius/components/utils";
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as logger from "Core/Medius.Core.Web/Scripts/Medius/lib/logger";

import * as editFactory from "Core/Medius.Core.Web/Scripts/Medius/components/generic/editor";
import * as displayFactory from "Core/Medius.Core.Web/Scripts/Medius/components/generic/presenter";
import * as mediusComponentFactory from "Core/Medius.Core.Web/Scripts/Medius/components/generic/model";

export function registerBidings() {
    //edit:
    const editbinding = componentUtils.createBinding(editFactory, {}, { name: "edit" });
    koUtils.registerBinding("edit", editbinding);
    logger.info(`Registered binding "edit" with model "Core/Medius.Core.Web/Scripts/Medius/components/generic/editor"`);

    //display:
    const displaybinding = componentUtils.createBinding(displayFactory, {}, { name: "display" });
    koUtils.registerBinding("display", displaybinding);
    logger.info(`Registered binding "display" with model "Core/Medius.Core.Web/Scripts/Medius/components/generic/presenter"`);


    //mediusComponent:
    const mediusComponentbinding = componentUtils.createBinding(mediusComponentFactory, { tpl: "components-generic-component" }, { name: "mediusComponent" });
    koUtils.registerBinding("mediusComponent", mediusComponentbinding);
    logger.info(`Registered binding "mediusComponent" with model "Core/Medius.Core.Web/Scripts/Medius/components/generic/model"`);
}