/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/conditionBound" />

type ResolverConstructorParams = {priority: number, condition: Function, resolve: Function};

class ConditionBoundResolver {
    priority: number;
    condition: Function;
    resolve: Function;
    constructor({priority, condition, resolve}: ResolverConstructorParams) {
        this.priority = priority;
        this.condition = condition;
        this.resolve = resolve;
    }
}

export function create(options: ResolverConstructorParams) {
    return new ConditionBoundResolver(options);
}
export function instanceOf(obj: any) {
    return obj instanceof ConditionBoundResolver;
}
export const priority = {
    lowest: -1000000,
    highest: 1000000
};
