/// <amd-module name="Core/Medius.Core.Web/Scripts/components/sendForReview/sendForReviewAutocompleter"/>
import { useState, useEffect, useCallback } from "react";
import { ComboBox, ComboBoxChangeEvent, ComboBoxFilterChangeEvent, ComboBoxOpenEvent, } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as React from "react";
import useLoading from "Core/Medius.Core.Web/Scripts/shared/hooks/useLoading";
import { get, aborted } from "Core/Medius.Core.Web/Scripts/Medius/core/fetch/rest";
import { handleError } from "Core/Medius.Core.Web/Scripts/lib/errorHandling/errorHandler";
import { useDebounce } from "use-debounce";
import { SendForReviewerRole} from "Core/Medius.Core.Web/Scripts/Medius/apps/task/models/sendForReview";

type InputProps = {
    readonly documentId: number;
    readonly addReviewer: (reviewer: SendForReviewerRole) => void;
};

export interface Role {
    id: number,
    assigneeName: string
}

export const SendForReviewAutoCompleter = ({ documentId, addReviewer }: InputProps) => {
    const [ selectedRole, setSelectedRole ] = useState<SendForReviewerRole>(null);
    const [ loadedRoles, setLoadedRoles ] = useState<Role[]>([]);
    const { loading, setLoading, clearLoading } = useLoading();    
    const [ filter, setFilter] = useState<string>("");
    const [ debouncedFilter ] = useDebounce(filter, 300);
    
    const loadRoles = useCallback(async (filter : string) => {
        try {
            setLoading();
            const result = await get<Role[]>(`sendForReview`,{ documentId, searchText: filter});
            setLoadedRoles(result);
            clearLoading();
        }
        catch (err) {
            clearLoading();
            if (aborted(err)) {
                return;
            }
            handleError(err);
        }
    }, [documentId, setLoading, clearLoading]);

    useEffect(() => {
        if (debouncedFilter.length > 0) {
            loadRoles(debouncedFilter);
        }
    }, [documentId, debouncedFilter, loadRoles]);
    

    const handleOnFilterChange = (event: ComboBoxFilterChangeEvent) => {
        const filter = event.filter.value;
        setFilter(filter);
    };

    const handleOnChange = (event: ComboBoxChangeEvent) => {
        if (!event.value) {
            setSelectedRole(null);
            return;
        }
        setSelectedRole((event.value as Role));
    };

    const handleOnOpen = (event: ComboBoxOpenEvent) => {      
        // handleOnOpen is called before handleOnFilterChange, this avoids loading roles twice when user types the first character
        const nativeEvent = event.nativeEvent as InputEvent;    
        if (loadedRoles.length === 0 && !nativeEvent.data) {
            loadRoles("");
        }
    };

    const itemRender = (li: React.ReactElement<HTMLLIElement>, { dataItem }: { dataItem: Role }) => {
        const itemChildren = <span data-testid="send-for-review-role-text">{dataItem.assigneeName}</span>;
        return React.cloneElement(li, li.props, itemChildren);
    };

    const addReviewerClick = () => {
        addReviewer(selectedRole);
        setSelectedRole(null);
    };
    
    return (
        <>
        <ComboBox
            data-testid="send-for-review-role-autocompleter"
            className="send-for-review-role-autocompleter"
            data={loadedRoles}
            value={selectedRole}
            required={true}
            textField="assigneeName"
            dataItemKey="id"
            onChange={handleOnChange}
            filterable
            onFilterChange={handleOnFilterChange}
            itemRender={itemRender}
            onOpen={handleOnOpen}
            clearButton={false}
            loading={loading}
            popupSettings={{
                className: "send-for-review-role-autocompleter-popup"
            }}
        />
        <Button data-testid="send-for-review-reviewer-add" title={translate("#Core/add")} className="k-primary"
            onClick={()=>{addReviewerClick();}}>{translate("#Core/add")}
        </Button>
        </>
    );
};