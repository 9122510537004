///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/chosenPluginForLabels"/>
import { registerBinding } from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as ko from "knockout";

const chosenPluginForLabels = {        
    init: function (element:any) {
        $(element).chosen();
    },
    update: function (element:any, valueAccessor:any, allBindings:any) {
        const val = valueAccessor();
        const enabled = allBindings().enable();
        const labelChozen = val.value || ko.observable(null);

        if (labelChozen() === null) {
            $(element).val("");
        }

        $(element).prop('disabled', !enabled).trigger("chosen:updated.chosen");
    },
    after: ['translateAttr']
};

export function register() {
    registerBinding("chosenPluginForLabels", chosenPluginForLabels);
}