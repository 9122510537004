/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/configurationProperties/base"/>
import { observable, pureComputed, subscribable } from "knockout";
import { getLabelTranslation, getTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";

export = class BaseProperty {
    disposables: any[] = [];
    Context: any;
    Company: any;
    ConfiguredEntity: any;
    InheritedData: any;
    CurrentValue: any;
    Metadata: any;
    Options: any;
    InheritedValue: any;
    IsOverriden: ko.Observable<any>;
    IsInherited: ko.PureComputed<boolean>;
    IsUnlicensed: ko.Computed<boolean>;
    UnlicensedReason: ko.Computed<any>;
    IsDisabledOverriden: ko.Computed<boolean>;
    IsDisabled: ko.Computed<any>;
    PropertyName: any;
    PropertyLabel: any;
    TranslatedPropertyLabel: any;
    TranslatedDescription: ko.Computed<any>;
    onChangedSubscribable: ko.Subscribable<unknown>;
    changed: () => void;

    constructor(params: any) {
        this.Context = params.Context;
        this.Company = params.Company;
        this.ConfiguredEntity = params.ConfiguredEntity;
        this.InheritedData = params.InheritedData;
        this.CurrentValue = params.CurrentValue;
        this.Metadata = params.Metadata;
        this.Options = params.Options;

        this.InheritedValue = this.wrapInheritedValue(this.createViewModel(this.InheritedData));

        this.IsOverriden = observable(
            this.Metadata.HasInitialValue === false
            || this.isDefinedInCurrentCompany());

        this.IsInherited = pureComputed(() => this.IsOverriden() === false);

        if (this.IsOverriden() && this.isDefinedInCurrentCompany() === false) {
            this.setInheritedValueAsCurrentValue();
        }

        const isUnlicensed = this.Metadata.IsLicensed === false;
        if (isUnlicensed) {
            this.IsUnlicensed = pureComputed(() => true);

            this.UnlicensedReason = pureComputed(() => {
                return getLabelTranslation("#Core/module/thisFeatureIsNotIncludedInYourLicense");
            });
        }

        this.IsDisabledOverriden = pureComputed(() => {
            return this.Metadata.HasInitialValue === false
                || isUnlicensed;
        });

        this.IsDisabled = pureComputed(() => {
            return this.IsInherited()
                || isUnlicensed;
        });

        this.PropertyName = params.PropertyName;
        this.PropertyLabel = params.PropertyLabelKey;
        this.TranslatedPropertyLabel = getTranslation(this.PropertyLabel);
        this.TranslatedDescription = pureComputed(() => {
            return this.IsOverriden()
                ? getLabelTranslation("#Core/currentConfigurationProperty")
                : getLabelTranslation("#Core/inheritedConfigurationProperty", this.Metadata.OriginCompanyName);
        });

        this.onChangedSubscribable = new subscribable();
        this.changed = () => {
            this.onChangedSubscribable.notifySubscribers();
        };

        this.disposables.push(
            this.IsOverriden.subscribe(this.changed));
    }

    public onChanged(callback: any, target: any, event: any) {
        this.disposables.push(
            this.onChangedSubscribable.subscribe(callback, target, event));
    }

    public setInheritedValueAsCurrentValue() {
        const inheritedValue = this.createViewModel(this.InheritedData);
        this.CurrentValue(inheritedValue);
    }

    public resetCurrentValue() {
        const inheritedValue = this.createViewModel(this.InheritedData);
        this.InheritedValue(inheritedValue);
        this.CurrentValue(this.getEmptyValue());
    }

    public createViewModel(data: any) {
        return this.Context.create(data);
    }

    public wrapInheritedValue(data: any) {
        return observable(data);
    }

    public isDefinedInCurrentCompany(): boolean {
        throw new Error("Please define isDefinedInCurrentCompany in " + this.constructor.name + " prototype");
    }

    public getEmptyValue(): any {
        throw new Error("Please define getEmptyValue in " + this.constructor.name + " prototype");
    }

    public dispose() {
        this.disposables.reverse()
            .forEach((it) => {
                if (typeof it.dispose === "function")
                    it.dispose();
                else if (typeof it.destroy === "function")
                    it.destroy();
                else
                    throw new Error("Item is not disposable.");
            });
    }

};
