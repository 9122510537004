///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/widgets/NonOperationalWidget"/>
import { Text, IconToggleHeartbreakFill, Button, IconMapsFlagRegular } from "@medius/ui-controls";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as React from "react";

interface NonOperationalWidgetProps {
    onOpenFeedbackModal: () => void
}

export function NonOperationalWidget({onOpenFeedbackModal}: NonOperationalWidgetProps) {

    return <div className="mediusAgent__nonOperationalWidgetContainer" data-testid="apa-copilot-not-operational">
    <div className="mediusAgent__nonOperationalWidgetContainer__messageContainer">
        <div className="mediusAgent__nonOperationalWidgetContainer__iconContainer">
            <IconToggleHeartbreakFill />
        </div>
        <div className="mediusAgent__nonOperationalWidgetContainer__messageContainer__messageText">
            <Text variant="heading3">{getLabelTranslation("#Core/agentNonOperationalWidgetHeader")}</Text>
            <Text variant="body">{getLabelTranslation("#Core/agentNonOperationalWidgetBody")}</Text>
        </div>
    </div>
    <Button data-testid="apa-copilot-provide-feedback-button" onClick={() => onOpenFeedbackModal()} icon={<IconMapsFlagRegular />} variant="tertiary" height="small" label={getLabelTranslation("#Core/giveUsFeedback")} width="fill"/>
</div>;
}