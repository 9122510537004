/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/administration/customActionRegistry"/>
import { AdminApplication } from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/adminApp";
import { Editor } from "./editor";

type SaveAction = (adminApp: AdminApplication) => void;
type RemoveAction = (adminApp: AdminApplication) => void;
type LoadAction = (editor: Editor, entityId: number) => JQueryPromise<any>;

const customSaveActionRegistry: {[key: string]: SaveAction} = {};
const customRemoveActionRegistry: {[key: string]: RemoveAction} = {};
const customLoadActionRegistry: {[key: string]: LoadAction} = {};

export function registerCustomSaveAction(entityType: string, action: SaveAction ) {
    if (customSaveActionRegistry[entityType]) {
        throw new Error(`There is already registered custom SAVE action for entity ${entityType}`);
    }
    customSaveActionRegistry[entityType] = action;
}

export function registerCustomRemoveAction(entityType: string, action: RemoveAction) {
    if (customRemoveActionRegistry[entityType]) {
        throw new Error(`There is already registered custom REMOVE action for entity ${entityType}`);
    }
    customRemoveActionRegistry[entityType] = action;
}

export function registerCustomLoadAction(entityType: string, action: LoadAction ) {
    if (customLoadActionRegistry[entityType]) {
        throw new Error(`There is already registered custom LOAD action for entity ${entityType}`);
    }
    customLoadActionRegistry[entityType] = action;
}

export function getSaveActionForType(entityType: string): SaveAction | null {
    return customSaveActionRegistry[entityType] ? customSaveActionRegistry[entityType] : null;
}

export function getRemoveActionForType(entityType: string): RemoveAction | null {
    return customRemoveActionRegistry[entityType] ? customRemoveActionRegistry[entityType] : null;
}

export function getLoadActionForType(entityType: string): LoadAction | null {
    return customLoadActionRegistry[entityType] ? customLoadActionRegistry[entityType] : null;
}