/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/PaymentTerm/legacyCompanyViewModel"/>
import { Company } from "./company";
import {observable, observableArray} from "knockout";

export class LegacyCompanyViewModel {

    public $type: string;
    public Id: ko.Observable<number>;
    public Name: ko.Observable<string>;
    public CompanyIdentifiers: ko.ObservableArray<string>;
    public CompanyId: ko.Observable<string>;

    public static create(company: Company): LegacyCompanyViewModel {
        if (!company) {
            return null;
        }

        return {
            $type: "Medius.Core.Entities.Company, Medius.Core.Common",
            Id: observable(company.id),
            Name: observable(company.name),
            CompanyIdentifiers: observableArray([]),
            CompanyId: observable(company.companyId)
        };
    }
}
