/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/AuthorizationGroups/Tabs/companyAutocompleter/promiseBasedCompanyAutocompleterContainer"/>
import * as React from "react";
import { CompanyAutocompleter } from "./companyAutocompleter";
import { debounce } from "underscore";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { useState } from "react";

export interface IPromiseBasedCompanyAutocompleterContainerProps<T> {
    selectedItem: T;
    textFieldSelector: string;
    disabled?: boolean
    onCompanyChange: (company: T) => void;
    getCompaniesPromise: (searchPhrase?: string) => JQueryPromise<T[]>
}

export function PromiseBasedCompanyAutocompleterContainer<T>(props: IPromiseBasedCompanyAutocompleterContainerProps<T>) {
    const { textFieldSelector, onCompanyChange, selectedItem, getCompaniesPromise, disabled = false } = props;

    const [companyDropdownState, setCompanyDropdownState] = useState({
        loadedCompanies: [] as T[],
    });

    const handleCompanyFilterChange = (filter: string) =>
        debouncedQueryCompanies(filter);

    const handleCompanyChange = (company: T) =>
        onCompanyChange(company);

    const queryCompanies = (filter: string = '') =>
        getCompaniesPromise(filter)
            .then((response) => {
                setCompanyDropdownState((x) => ({ ...x, loadedCompanies: response ?? [] }));
            });

    const debouncedQueryCompanies = debounce(queryCompanies, 300);

    return (
        <CompanyAutocompleter
            required={true}
            disabled={disabled}
            label={translate("#Core/company")}
            selectedItem={selectedItem}
            textFieldSelector={textFieldSelector}
            onCompanyChange={handleCompanyChange}
            onFilterChange={handleCompanyFilterChange}
            onDropdownOpen={() => debouncedQueryCompanies()}
            loadedCompanies={companyDropdownState.loadedCompanies}
        />
    );
}
