///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/ReadOnlyRelation/dataSource"/>
import * as $ from "jquery";
import * as queryBuilderFactory from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/ReadOnlyRelation/queryBuilder";
import { DataSearchSource as dataSearchDataSource } from "Core/Medius.Core.Web/Scripts/Medius/components/grid/dataSource/dataSearch";

const queryBuilder = queryBuilderFactory.create();

export class DataSource extends dataSearchDataSource {
    Collection: ko.ObservableArray<any>;
    constructor(collection:ko.ObservableArray<any>) {
        super();
        this.Collection = collection;
    }
    filteredCollection(dataQuery:any, collection:any[]) {
        return queryBuilder.filter(dataQuery, collection);
    }

    load(dataQuery:any) {
        return $.Deferred().resolve(this.filteredCollection(dataQuery, this.Collection()));
    }
    
    getTotalRows(dataQuery:any) {
        return this.filteredCollection(dataQuery, this.Collection()).length;
    }

    destroy() {
        this.Collection = null;
    }
}