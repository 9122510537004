///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/administration/bulk/bulkNotifications"/>
import * as ko from "knockout";
import * as backendErrorHandler from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import * as notification from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as rest from "Core/Medius.Core.Web/Scripts/Medius/core/rest";
import * as _ from "underscore";
import * as sync from "Core/Medius.Core.Web/Scripts/Medius/core/sync";

const docType = "Medius.Core.Entities.AsyncTask.AsyncTaskInformation";

class BulkNotificationModal{
    OngoingUpdates = ko.observableArray();
    isLoadingUpdates = ko.observable(false);

    open() {
        this.refreshTaskStatus();
    };

    checkForErrors(collection: any) {
        const hasErrors = _.any(collection, (c) => {
            return c.ModyficationResult === false;
        });
        return hasErrors;
    };

    updateWrappedData(oldData: { TaskState: (arg0: any) => void; Modifications: (arg0: any) => void; AffectedEntities: (arg0: any) => void; HasErrors: (arg0: boolean) => void; }, ongoingUpdate: { AffectedEntities: any; TaskState: any; Modifications: any; }) {
        const hasErrors = this.checkForErrors(ongoingUpdate.AffectedEntities);

        oldData.TaskState(ongoingUpdate.TaskState);
        oldData.Modifications(ongoingUpdate.Modifications);
        oldData.AffectedEntities(ongoingUpdate.AffectedEntities);
        oldData.HasErrors(hasErrors);
    };

    wrappUpdateItem(bulkInfo:any) {
            const hasErrors = this.checkForErrors(bulkInfo.AffectedEntities);
            const wrappedInfo = ko.observable({
                EntityTypeName: ko.observable(bulkInfo.EntityTypeName),
                isDetailVisible: ko.observable(false),
                TaskState: ko.observable(bulkInfo.TaskState),
                Modifications: ko.observableArray(bulkInfo.Modifications),
                AffectedEntities: ko.observableArray(bulkInfo.AffectedEntities),
                ErrorMessage: ko.observable(bulkInfo.ErrorMessage),
                EntityPresentation: ko.observable(bulkInfo.EntityPresentation),
                Id: ko.observable(bulkInfo.Id),
                HasErrors: ko.observable(hasErrors),
                TaskStateCompletedWithErrors: globalization.getLabelTranslation("#Core/asyncTaskStateCompletedWithErrors")
            });
        return wrappedInfo;
    };

    toggleDetailsUpdateInformation(bulkInfo:any) {
        bulkInfo.isDetailVisible(!bulkInfo.isDetailVisible());
    };

    refreshUpdateInformation(bulkInfo:any) {
        const updateEntityId = bulkInfo.Id();

        sync.load(docType, updateEntityId)
            .done((ongoingUpdate: any) => {
                this.updateWrappedData(bulkInfo, ongoingUpdate);
            })
            .fail((xhr: backendErrorHandler.XHR) => {
                backendErrorHandler.handleAnyError(xhr);
            });
    };

    removeUpdateInformation(bulkInfo:any) {
        const updateEntityId = bulkInfo.Id();

        sync.remove(docType, updateEntityId)
            .done(() => {
               const clearedItems = _.reject(this.OngoingUpdates(), (item:any) => { return item().Id() === updateEntityId; });
               this.OngoingUpdates(clearedItems);
               notification.success(globalization.getLabelTranslation("#Core/ongoingBulkUpdateRemoved"));
           })
            .fail((xhr: backendErrorHandler.XHR) => {
                backendErrorHandler.handleAnyError(xhr);
           });
    };

    refreshTaskStatus()  {
        this.isLoadingUpdates(true);

        rest.get("BulkOperationService", "bulkOperations")
            .done((ongoingUpdates: any) => {
            const items = _.map(ongoingUpdates, (bulkItem) => {
                return this.wrappUpdateItem(bulkItem);
            });
            this.OngoingUpdates(items);
        }).fail((xhr: backendErrorHandler.XHR) => {
            backendErrorHandler.handleAnyError(xhr);
        }).always(() => {
            this.isLoadingUpdates(false);
        });
    };
};

export function create(){
    return new BulkNotificationModal();       
}