/// <amd-module name="Core/Medius.Core.Web/Scripts/components/topBar/drawer/DrawerApp"/>
import * as React from 'react';
import { useState} from "react";
import {applicationIcons} from './icons';

type OnClickHandler = (e:React.MouseEvent<HTMLAnchorElement>, appName: string) => void;

export default function DrawerApp(props: { path: string; LogoUrl: string; name: React.ReactNode, type: number, onClick: OnClickHandler}) {
    const [imgClass, setImageClass] = useState('drawer-app-img');
 
    function handleHoverOff() {
        setImageClass('drawer-app-img');
    }

    function handleHoverOn() {
        setImageClass('drawer-app-img drawer-app-img__hover');
    }

    function handleClick(e: React.MouseEvent<HTMLAnchorElement>) {
        if (props.onClick) {
            props.onClick(e, props.name.toString());
        }
    }

    return (
        <a href={props.path} className='drawer-app-href' onMouseEnter={handleHoverOn} onMouseLeave={handleHoverOff} onClick={handleClick}>
            <div className='drawer-app' data-testid={'drawer-app'} data-app-type={AppType[props.type]}>    
                <div className={imgClass}>{applicationIcons[props.name.toString()]}</div>
                <span className='drawer-app-name' data-testid={'drawer-app-name'}>
                        {props.name}
                </span>
            </div>
        </a>
    );
}

enum AppType {
    Default,
    Suite,
    Integration
}

