/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/kendo/kendoUtils" />
import { addDisposeCallback } from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";

export class KendoUtils {
    public static readonly elementId = "ko-kendo-element";

    public static createComponent<TOptions>($element: JQuery, kendoComponent: string, options: TOptions): JQuery {
        const $kendoDiv = $element.append(`<div id="${KendoUtils.elementId}"></div>`).children().last();
        $kendoDiv.addClass($element.attr("class"));
        return $kendoDiv[kendoComponent](options);
    }

    public static destroyComponent($element: JQuery, kendoComponent: string) {
        const component = KendoUtils.getComponent($element, kendoComponent);
        if (component != null) {
            component.destroy();
            $element.empty();
        }
    }

    public static getComponent($element: JQuery, kendoComponent: string): any {
        const $kendoDiv = $element.find(`#${KendoUtils.elementId}`).first();
        if ($kendoDiv.length > 0) {
            return $kendoDiv.data(kendoComponent);
        }
        return null;
    }

    public static registerDisposeFor($element: JQuery, widgetName: string): void {
        const htmlNode = $element[0];
        addDisposeCallback(
            htmlNode,
            () => KendoUtils.dispose($element, widgetName));
    }

    public static dispose($element: JQuery, widgetName: string): void {
        const widgetInstance = $element.data(widgetName);
        if (widgetInstance) {
            if(widgetInstance.options.dispose) {
                widgetInstance.options.dispose(widgetInstance);
            }
            widgetInstance.destroy();
            $element.empty();
        }
    }

    public static ensureValidElement(widgetName: string, elementTypeName: string, $element: JQuery): void {
        if (!$element.is(elementTypeName))
            throw new Error(`Binding '${widgetName}' can be applied only on a '${elementTypeName}'.`);
    }

    public static loadTemplate(id: string) {
        const template = $(`script[id='${id}']`);
        if (template.length === 0)
            throw new Error(`Could not find template by Id: '${id}'.`);

        return kendo.template(template.html());
    }
}
