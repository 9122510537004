///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/newTooltip/defaultSkin"/>

export const showOn = 'mouseenter';
export const hideOn = 'mouseleave';
export const position = {
    my: 'center bottom',
    at: 'center top',
    collision: 'flipfit flipfit'
};
export const css = {
    color: 'white',
    background: 'black'
};
export const arrowCss = {
    'border-color': 'black'
};