/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/editors/text/model" />

import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import {Basic} from "Core/Medius.Core.Web/Scripts/Medius/components/editors/basic";
import * as helpers from "Core/Medius.Core.Web/Scripts/Medius/components/editors/helpers";

class Text extends Basic {
    Multiline: any;
    MaxLength: any;
    InputType: string;
    constructor(bindingParameters: any, viewmodel: any, context: any) {
        const defaults = {
            template: (bindingParameters.multiline) ? "editors-text-multiline" : "editors-text-default",
            maxLength: (bindingParameters.multiline) ? 449 : 200, //449 due to sending attachment error
            multiline: false,
            align: 'left',
            options: {
                defaultName: "editor-text",
                placeholder: null as any,
                pattern: null as any,
                css: null as any
            }
        };

        const isOverflow = function (value: any) {
            if (isNullOrUndefined(value)) {
                return true;
            }
            return true;
        };

        helpers.mergeDefaults(bindingParameters, defaults);
        super(defaults, isOverflow, context);

        this.Multiline = this.params.multiline;
        this.MaxLength = this.params.maxLength;
        this.InputType = this.params.password ? "password" : "text";
    }
}

export function create(bindingParameters: any, viewmodel: any, context: any) {
    return new Text(bindingParameters, viewmodel, context);
}
