/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/task/models/sendForReview"/>
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { observable, observableArray, computed } from "knockout";
import { MentionEditorFactory } from "Core/Medius.Core.Web/Scripts/components/mention/editor/mentionEditorFactory";
import { Editor } from "Core/Medius.Core.Web/Scripts/components/mention/editor/editor";
import { DisabledMentionsEditor } from "Core/Medius.Core.Web/Scripts/components/mention/editor/disabledMentionsEditor";
import { any, constant, extend } from "underscore";
import { isEmptyString } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import { request } from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rpc";
import { success } from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import { InboxInstance } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/inbox";
import { handleAnyError } from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import { handleTask } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/taskHandler";
import { SendForReviewAutoCompleter } from "Core/Medius.Core.Web/Scripts/components/sendForReview/sendForReviewAutocompleter";
import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";

function errorHandler(error: any) {
    handleAnyError(error);
}

export interface SendForReviewerRole {
    id: number;
    assigneeName: string;
}

class SendForReview {
    public header = translate("#Core/sendForReview");
    public reviewMessage = observable("");
    public reviewers = observableArray<SendForReviewerRole>([]);
    public mentionsEnabled: boolean;
    public mentionEditor: Editor | DisabledMentionsEditor;
    public canAddReviewer: (reviewer: SendForReviewerRole) => boolean;
    public showSendForReview: ko.Computed<boolean>;
    public showWithdrawFromReview: ko.Computed<boolean>;
    public sendForReviewAutocompleterConfig: ko.Computed<any>;
    public addReviewer: (reviewer: SendForReviewerRole) => void;
    public removeReviewer: (reviewer: SendForReviewerRole) => void;
    public canSendForReview: ko.Computed<boolean>;
    public completeReviewAction: () => JQueryPromise<any>;
    public sendReview: () => JQueryPromise<any>;
    public completeReviewTask: () => void;
    public withdrawFromReview: () => void;
    private getMentionOptions: () => any;

    constructor(
        public task: any,
        public customTaskHandlerProvider: any) {
        this.canAddReviewer = (reviewer: SendForReviewerRole) => {
            return !isNullOrUndefined(reviewer) && !any(this.reviewers(), (item: SendForReviewerRole) => item.id === reviewer.id);
        };

        this.getMentionOptions = () => {
            let mentionsEnabled = true;
            let mentionsDisabledDueToTranslationKey = "";

            //if this task is draft or edi
            if (!task.Document().Id() || task.Document().WrappedDocumentType !== undefined) {
                mentionsEnabled = false;
                mentionsDisabledDueToTranslationKey = "#Core/mentionDisabledDuringCreation";
            }

            const placeholder = mentionsEnabled ? translate("#Core/writeACommentMentionsEnabled") : translate("#Core/writeAComment");

            return {
                mentionsEnabled: mentionsEnabled,
                value: this.reviewMessage,
                documentId: this.task.Document().Id(),
                options: {
                    rows: 7,
                    autocompleterDisablementReasonTranslationKey: mentionsDisabledDueToTranslationKey,
                    placeholder: placeholder
                }
            };
        };

        this.mentionEditor = MentionEditorFactory.getInstance().create(this.getMentionOptions());

        this.showSendForReview = computed(() => this.task.InReview() === false);
        this.showWithdrawFromReview = computed(() => !this.showSendForReview());
        this.addReviewer = (reviewer: SendForReviewerRole) => {
            if (!this.canAddReviewer(reviewer)) {
                return;
            }
            this.reviewers.push(reviewer);
        };

        this.removeReviewer = (reviewer: any) => {
            this.reviewers.remove(reviewer);
        };

        this.canSendForReview = computed(() => this.reviewers().length > 0 && this.reviewMessage() && !isEmptyString(this.reviewMessage()));

        this.completeReviewAction = () => {
            const handleAction = this.customTaskHandlerProvider.getHandler("CompleteReviewTask", this.task)
                || constant(request("TaskManager", "CompleteReviewTask", {
                    reviewTaskId: task.Id()
                }));

            return handleAction().done(() => {
                success(translate("#Core/reviewHasBeenCompleted"));
            })
                .fail((data: any) => {
                    errorHandler(data);
                });
        };

        this.sendForReviewAutocompleterConfig = computed(() => {
            return {
                functionComponent: SendForReviewAutoCompleter,
                props: {
                    documentId: this.task.Document().Id(),
                    addReviewer: this.addReviewer
                }
            };
        });

        this.sendReview = () => {
            const reviewData = {
                reviewMessage: this.reviewMessage(),
                reviewersIds: this.reviewers().map(item => item.id)
            };
            const handleAction = this.customTaskHandlerProvider.getHandler("SendForReview", this.task, reviewData)
                || constant(request("TaskManager", "SendForReview", extend(reviewData, { task: task.toDto() })));

            this.task.saveUnsavedComment();

            $('.newPopdown').hide();

            if (typeof handleAction === 'function') {
                return handleAction().done(() => {
                    success(translate("#Core/documentHasBeenSentForReview"));
                    // mark task as 'in review' in inbox
                    InboxInstance.updateCurrentTaskReviewStatus(true);
                    // go to next task in inbox
                    InboxInstance.onTaskHandled(task.Id());
                })
                    .fail((data: any) => {
                        errorHandler(data);
                    });
            }
        };

        this.completeReviewTask = () => {
            this.task.saveUnsavedComment();
            // perform task handling via outbox
            handleTask(task, null, this.completeReviewAction);
        };

        this.withdrawFromReview = () => {
            this.task.saveUnsavedComment();

            const handleAction = this.customTaskHandlerProvider.getHandler("WithdrawFromReview", this.task)
                || constant(request("TaskManager", "WithdrawFromReview", {
                    task: task.toDto()
                }));

            if (typeof handleAction === 'function') {
                return handleAction().done(function () {
                    success(translate("#Core/documentHasBeenWithdrawnFromReview"));
                    // unmark task as 'in review' in inbox
                    InboxInstance.updateCurrentTaskReviewStatus(false);
                    // reload task
                    InboxInstance.reloadCurrentTask();
                })
                    .fail((data: any) => {
                        errorHandler(data);
                    });
            }
        };
    }

    public dispose() {
        this.mentionEditor.dispose();
        this.canSendForReview.dispose();
        this.showSendForReview.dispose();
        this.showWithdrawFromReview.dispose();
        this.sendForReviewAutocompleterConfig.dispose();
        this.task = null;
    }
}

export function create(task: any, customTaskHandlerProvider: any) {
    return new SendForReview(task, customTaskHandlerProvider);
}
