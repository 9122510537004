///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/approvalGroup/dataProvider"/>
import { DataProvider } from "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/default/dataProvider";
import { handleAnyError } from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import * as rpc from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rpc";

export class ApprovalGroupDataProvider extends DataProvider {
    constructor() {
        super();
    }

    callServer(phrase: string, limit: number, company: any) {
        const params = {
            searchText: phrase,
            maxResults: limit,
            companyId: this.getId(company)
        };

        return rpc.request("AuthorizationGroupService", "Autocomplete", params)
            .fail(message => handleAnyError(message));
    }

    search(searchText: string, maxResults: number, options: any) {
        return this.callServer(searchText, maxResults, options.company);
    }
}

export function create() {
    return new ApprovalGroupDataProvider();
}