///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/task/models/taskEventsContext"/>

import { getHandleEventName } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/settings";

class TaskHandlingEvents{
    subscribe: any;
    unsubscribe: any;
    publish: any;

    constructor(events: any){
        this.subscribe = (resultCode: any, callback: any) => {
            const eventName = getHandleEventName(resultCode);
            return events.subscribe(eventName, callback);
        };
    
        this.unsubscribe = (resultCode: any, callback: any) => {
            const eventName = getHandleEventName(resultCode);
            return events.unsubscribe(eventName, callback);
        };
    
        this.publish = (...args: any[]) => {
            const taskResult = args.shift();
    
            const eventName = getHandleEventName(taskResult || "default");
    
            args.unshift(eventName);
    
            return events.publish(events, ...args);
        };
    }

}

export function extendViewmodelContext(vmContext: any){
    if (!vmContext || !vmContext.events) {
        throw new Error("Unable to extend context with taskEvents");
    }
    
    if (vmContext.events.taskHandling) {
        return;
    }

    vmContext.events.taskHandling = new TaskHandlingEvents(vmContext.events);
}