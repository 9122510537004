/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/ResolutionConfiguration/resolutionConfiguration"/>
import * as React from "react";
import { useState, useEffect } from "react";
import { Grid, GridColumn as Column, GridToolbar, GridCellProps } from "@progress/kendo-react-grid";
import { orderBy } from '@progress/kendo-data-query';
import {Button} from "@progress/kendo-react-buttons";
import {translate} from "Core/Medius.Core.Web/Scripts/lib/globalization";
import {get, post} from "Core/Medius.Core.Web/Scripts/Medius/core/fetch/rest";
import { success, error} from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import { handleError } from "Core/Medius.Core.Web/Scripts/lib/errorHandling/errorHandler";
import { newGuid } from "Core/Medius.Core.Web/Scripts/lib/uniqueGuid";
import { DropDownList } from '@progress/kendo-react-dropdowns';
import {SidebarNavigation} from "Core/Medius.Core.Web/Scripts/AdminPages/sidebarNavigation";
import {registerAdministrationEntity} from "Core/Medius.Core.Web/Scripts/AdminPages/administrationServices";
import {useSelector} from "react-redux";
import {RootState} from "Core/Medius.Core.Web/Scripts/shared/store/reduxStore";
import {NoAccess} from "Core/Medius.Core.Web/Scripts/Medius/apps/noAccess/noAccess";

interface ConfigurationDto {
    id: string;
    typeIdentifier: string;
    numberIdentifier: string;
    resolution: number;
}

interface ResolutionConfigurationDto {
    configurations: ConfigurationDto[];
}

interface Event
{
    dataItem: ConfigurationDto;
    nativeEvent: any;
    syntheticEvent: any;
    target: any;
}

export function ResolutionConfiguration() {
    const [gridData, setGridData] = useState<ConfigurationDto[]>([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [gridState, setGridState] = useState({
        sort: [{}] as any,
        filter: {
            logic: "and",
            filters: []
        } as any,
        editID: null as string
    });
    const hasAccess = useSelector((state: RootState) => state.accesses.resolutionConfiguration);

    const [latestIdentifierType, setLatestIdentifierType] = useState("Medius.Core.Entities.AmountConfiguration");

    const getResolutionConfiguration = async () => {
        try {
            const resolutionConfiguration = await get<ResolutionConfigurationDto>("resolutionConfigurationAdministration/resolutionconfigurations");
            setGridData(resolutionConfiguration.configurations.map((item) =>
                ({ ...item, id: newGuid() })));
            setIsLoaded(true);
        } catch (e) {
            handleError(e);
        }
    };

    const rowClick = (event: Event) => {
        setGridState((s) => ({
            ...s,
            editID: event.dataItem.id
        }));
    };

    const closeEdit = (event: any) => {
        if (event.target === event.currentTarget) {
            setGridState((s) => ({
                ...s,
                editID: null
            }));
        }
    };

    const addRecord = () => {
        const newRecord: ConfigurationDto = { id: newGuid(), numberIdentifier: "", resolution: 2, typeIdentifier: latestIdentifierType };
        setGridData([newRecord, ...gridData]);
        setGridState((s) => ({
            ...s,
            editID: newRecord.id
        }));
    };

    const removeRecord = (idtoremove: string) => {
        setGridData(gridData.filter(item => item.id !== idtoremove));
        setGridState((s) => ({
            ...s,
            editID: null
        }));
    };

    const itemChange = (event: any) => {
        const inEditID = event.dataItem.id;
        const data = gridData.map(item =>
            item.id === inEditID ? { ...item, [event.field]: event.value } : item
        );
        setGridData(data);
        setLatestIdentifierType(event.dataItem.typeIdentifier);
    };
    
    useEffect(() => {
        getResolutionConfiguration();
    }, []);

    const validateGridData = () => {
        if (gridData.filter(item => item.numberIdentifier === "" || item.resolution == null).length > 0) {
            error(translate("#Core/resolutionConfigurationAllFieldsRequired"), translate("#Security/internalErrorOccured"));
            return false;
        }
        if (gridData.filter(item => item.resolution < 0 || item.resolution > 14 || !Number.isInteger(item.resolution)).length > 0) {
            error(translate("#Core/resolutionConfigurationResolutionMustBeWithinRange"), translate("#Security/internalErrorOccured"));
            return false;
        }
        if (gridData.map(item => gridData.filter(gd => gd.typeIdentifier === item.typeIdentifier && gd.numberIdentifier.toLowerCase() === item.numberIdentifier.toLowerCase()).length > 1).filter(uq => uq === true).length > 0) {
            error(translate("#Core/resolutionConfigurationContainsDuplicates"), translate("#Security/internalErrorOccured"));
            return false;
        }

        return true;
    };

    async function onSaveResolutionConfiguration() {
        try {
            if (validateGridData()) {
                setIsLoaded(false);
                await post("Backend/Rest/resolutionConfigurationAdministration/resolutionconfigurations", {
                    Configurations: gridData
                });
                success(translate("#Core/changesSavedSuccessfully"));
                
                getResolutionConfiguration();
            }
        } catch (e) {
            handleError(e);
        }
    }

    if(!hasAccess) {
        return(<NoAccess/>);
    }
    return (
        <>
            <SidebarNavigation/>
            { isLoaded && <div className="resolutionConfiguration">
                <h2 className="administration-page-header">{translate("#Core/resolutionConfiguration")}</h2>
                <div className="alert alert-info">
                        <span>{translate("#Core/resolutionConfigurationUpdateDelay")}</span>
                </div>
                <Grid
                    style={{ flexGrow: 1, height: "0" }}
                            data={orderBy(gridData.map((item) =>
                                ({ ...item, inEdit: item.id === gridState.editID })
                            ), gridState.sort)}
                            sortable
                            sort={gridState.sort}

                            onSortChange={(e) => {
                                setGridState((s) => ({
                                    ...s,
                                    sort: e.sort
                                }));
                            }}

                            editField="inEdit"
                            onRowClick={rowClick}
                            onItemChange={itemChange}
                        >
                    <GridToolbar>
                        <div onClick={closeEdit}>
                            <button title={translate("#Core/add")} className="k-button" onClick={addRecord}>{translate("#Core/add")}</button>
                            <button title={translate("#Core/save")} className="k-button k-primary resolutionConfiguration__save-button" onClick={onSaveResolutionConfiguration}>{translate("#Core/save")}</button>
                        </div>
                    </GridToolbar>
                        <Column field="typeIdentifier" cell={DropDownCell} title={translate("#Core/resolutionConfigurationTypeIdentifier")} />
                        <Column field="numberIdentifier" title={translate("#Core/resolutionConfigurationNumberIdentifier")} />
                        <Column field="resolution" title={translate("#Core/resolutionConfigurationResolution")} editor="numeric" format="{0:n}"/>
                            <Column
                            title={translate("#Core/actionText")}
                            cell={(e: { dataItem: { id: string; }; }) => (<ActionCell
                                    isDisabled={false}
                                    onDelete={() => {
                                        removeRecord(e.dataItem.id);
                                    }} />)}
                            sortable={false}
                            filterable={false}
                            width={100}
                        />
                </Grid>
            </div>}
        </>);
}

class DropDownCell extends React.Component<GridCellProps> {

    public localizedData = [
        { text: translate("#Core/resolutionConfigurationAmount"), value: 'Medius.Core.Entities.AmountConfiguration' },
        { text: translate("#Core/resolutionConfigurationCurrencyRate"), value: 'Medius.Core.Entities.CurrencyRateConfiguration' },
        { text: translate("#Core/resolutionConfigurationQuantity"), value: 'Medius.Core.Entities.QuantityConfiguration' },
        { text: translate("#Core/resolutionConfigurationUnitPrice"), value: 'Medius.Core.Entities.UnitPriceConfiguration' }
    ];

    public handleChange(e: any) {
        this.props.onChange({
            dataItem: this.props.dataItem,
            field: this.props.field,
            syntheticEvent: e.syntheticEvent,
            value: e.target.value.value,
            dataIndex: this.props.dataIndex
        });
    }
    public render() {
        const dataValue = this.props.dataItem[this.props.field];
        const selectedDataValue = this.localizedData.find(c => c.value === dataValue);

        if (!this.props.dataItem.inEdit) {
            return (
                <td> {
                    (dataValue === null || dataValue === '') ? '' : selectedDataValue.text}
                </td>
            );
        }

        return (
            <td>
                <DropDownList
                    style={{ width: "250px" }}
                    onChange={this.handleChange.bind(this)}
                    value={selectedDataValue}
                    data={this.localizedData}
                    textField="text"
                />
            </td>
        );
    }
}

export function registerResolutionConfigurationAdminPage() {
    registerAdministrationEntity("ResolutionConfiguration", ResolutionConfiguration);
}

function ActionCell({ onDelete, isDisabled }: {onDelete: () => void; isDisabled: boolean}) {
    return (
        <td className="resolutionConfiguration__action-cell">
            <Button
                onClick={onDelete}
                disabled={isDisabled}
                data-testid="rc-delete-button"
            >{translate("#Core/resolutionConfigurationDelete")}</Button>
        </td>
    );
}
