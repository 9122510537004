///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Entities.User/Reminders/externalReminderGrid"/>
import { ExternalRemindersDataSource } from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Entities.User/Reminders/externalRemindersDataSource";
import * as repository from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Reminders/repository";
import * as _ from "underscore";
import * as ko from "knockout";
import * as reminderConfigurationHandler from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Reminders/reminderConfigurationHandler";
import { EditableGrid } from "Core/Medius.Core.Web/Scripts/Medius/components/grid/editable/grid";
import * as entityBackup from "Core/Medius.Core.Web/Scripts/Medius/lib/entityBackup/entity";

class ExternalRemindersGrid extends EditableGrid {
    reminderConfigurationHandler: any;
    isInherited: boolean;
    constructor(currentReminders: any, inheritedReminders: any, isInherited: boolean, options: any, reminderTypes: any, messageTemplates: any) {
        options = {
            ...options, search: false,
            sort: true,
            paging: true,
            editPageSize: true,
            tplRow: "Core:templates/Administration/User/ReminderRowTpl.html",
            hideSummary: true,
            hideWhen: true,
            tableType: 'External'
        };

        const reminderConfigurationHandlerVar = reminderConfigurationHandler.create(currentReminders, reminderTypes, options);

        const dataSource = new ExternalRemindersDataSource(currentReminders, inheritedReminders, isInherited, reminderTypes, messageTemplates, options.hideSummary, options.hideWhen, reminderConfigurationHandlerVar);

        super(dataSource, options);
        dataSource.grid = this;
        this.reminderConfigurationHandler = reminderConfigurationHandlerVar;
    }

    setInherited(isInherited: boolean) {
        this.isInherited = isInherited;
        this.DataSource.setInherited(isInherited);
        this.refresh();
    }
    addEmptyRow() {
        if (this.isAnyRowInEdit()) {
            return;
        }

        const reminderConfig = this.DataSource.getFirstRowReminderConfiguration() ||
            this.reminderConfigurationHandler.createReminderConfiguration();

        if (reminderConfig === null) {
            return;
        }

        this.isAnyRowInEdit(true);

        const documentType = this.DataSource.getFirstRowDocumentType();
        const messageTemplate = _(this.DataSource.availableMessageTemplates).first();

        const newReminderConfigRow = this.reminderConfigurationHandler.addReminderRow(reminderConfig, documentType, messageTemplate);
        newReminderConfigRow.isEditable = ko.observable(true);
        newReminderConfigRow.justAdded = true;

        this.openPage(1);
    }

    editRow(row: any) {
        super.editRow(row, entityBackup);
    }

    saveRow(row: any) {
        const wasJustAdded = row.justAdded === true;
        super.saveRow(row);
        this.reminderConfigurationHandler.onReminderTypeChanged(row);

        if (wasJustAdded) {
            this.openPage(1);
        }
    }

    removeRow(row: any) {
        row.disposeWrapper();
        this.reminderConfigurationHandler.removeRow(row);
        super.removeRow(row);
    }
}

export function create(currentReminders: any, inheritedReminders: any, isInherited: boolean, options?: any) {
    return $.when(
        repository.loadAvailableExternalReminderTypes(),
        repository.loadAvailableMessageTemplates()
    ).then(function (reminderTypes: any, messageTemplates: any) {
        return new ExternalRemindersGrid(currentReminders, inheritedReminders, isInherited, options, reminderTypes, messageTemplates);
    });
}