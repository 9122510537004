/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/attachments/fileAttachment"/>
import {translate} from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { put } from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rest";
import { handleAnyError, XHR } from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import * as notification from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import {observable} from "knockout";
import { toAction } from "Core/Medius.Core.Web/Scripts/Medius/lib/path";
import { tryRevive } from "Core/Medius.Core.Web/Scripts/Medius/lib/utils/date";

export interface FileAttachmentEntity {
    AllowDelete: boolean;
    AddedDate: string;
    Author: string;
    Hash: string;
    IsCarriedOver: boolean;
    FileName: string;
    Text: string;
    FileSize: number;
    IsMainAttachment: boolean;
    IsExternal: boolean;
    IsSentToSupplier: boolean;
}

export class FileAttachment {
    public FileSize: string;
    public Text: string;
    public FileName: string;
    public FileIcon: string;
    public IsCarriedOver: boolean;
    public IsExternal: ko.Observable<boolean>;
    public Hash: string;
    public DownloadLink: any;
    public Author: string;
    public AddedDate: string;
    public AllowDelete: boolean;
    public IsSentToSupplier: boolean;
    public CurrentAccessText = observable("");

    private isExternalSub: ko.Subscription;

    constructor(data: FileAttachmentEntity) {
        this.AllowDelete = data.AllowDelete;
        this.AddedDate = tryRevive(data.AddedDate);
        this.Author = data.Author;
        this.DownloadLink = this.getDownlaodLink(data);
        this.Hash = data.Hash;
        this.IsCarriedOver = data.IsCarriedOver;
        this.IsExternal = observable(data.IsExternal);
        this.FileIcon = this.getIcon(data, this.IsCarriedOver);
        this.FileName = data.FileName;
        this.Text = data.Text;
        this.IsSentToSupplier = data.IsSentToSupplier;

        this.isExternalSub = this.IsExternal.subscribe((isExternalValue) => {
            this.updateIsExternalValue();
            this.updateCurrentAccessText();
        });

        this.updateCurrentAccessText();

        const nonBreakingSpace = "\xa0";
        this.FileSize = `${(data.FileSize / 1024 / 1024).toFixed(2)}${nonBreakingSpace}MB`;
    }

    public getDownlaodLink({Hash}: any): string {
        return toAction(`DownloadAttachment?metadataHash=${Hash}`, "~/Attachments");
    }

    public getIcon({FileExtension}: any, isCarriedOver: boolean) {
        return isCarriedOver
            ? "carried-over-icon-" + FileExtension.toLowerCase()
            : "icon-file-" + FileExtension.toLowerCase();
    }

    private updateCurrentAccessText() {
        if (this.IsExternal()) {
            this.CurrentAccessText(translate("#Core/external"));
        } else {
            this.CurrentAccessText(translate("#Core/internal"));
        }
    }

    private updateIsExternalValue() {
        put(
                "fileattachments", "", {
                hash: this.Hash,
                isExternal: this.IsExternal()
            }).done(() => { })
            .fail((jqXhr: XHR) => handleAnyError(jqXhr))
            .always(() => {});
    }

    public dispose() {
        this.isExternalSub.dispose();
    }
}

export const supportedExtensions = [
    "pdf",
"tif",
"msg",
"xlsx",
"xls",
"png",
"jpg",
"docx",
"html",
"doc",
"csv",
"htm",
"xlsm",
"xml",
"txt",
"jpeg",
"pptx",
"rtf",
"eml",
"zip",
"xlsb",
"tiff",
"ppt",
"gif",
"xps",
"bmp",
"odt",
"ods",
"docm",
"xsl",
"7z",
"oxps",
"xlt",
"bdoc",
"ppsx",
"pptm",
"log",
"svg",
"dxf",
"eps",
"asice"
];

export function isFileSupported(data: any) {
    let isExtensionSupported = false;
    let isFilenameCorrect = false;
    if (data.files && data.files[0].name) {
        const extension = data.files[0].name.split(".").pop().toLowerCase();
        isExtensionSupported = supportedExtensions.indexOf(extension) !== -1;
        if (!isExtensionSupported) {
            notification.error(translate("#Core/attachmentExtensionErrorMessage"), translate("#Core/attachmentUploadFailureTitle"));
        }

        const rg1 = /^[^\\/:\*\?"<>\|]+$/; // forbidden characters for Windows \ / : * ? " < > |
        const rg2 = /^\./; // filename cannot start with dot (.)
        isFilenameCorrect = rg1.test(data.files[0].name) && !rg2.test(data.files[0].name);
        if (!isFilenameCorrect) {
            notification.error(translate("#Core/attachmentFilenameCharactersErrorMessage"), translate("#Core/attachmentUploadFailureTitle"));
        }
    }

    return isExtensionSupported && isFilenameCorrect;
}
