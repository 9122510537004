///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/newTooltip/model"/>
import * as $ from "jquery";
import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import * as ko from "knockout";
import * as _ from "underscore";

let counter = 0;
let timeout:any;

function fixEpsilon(epsilon:number) {
    if (isNullOrUndefined(epsilon)) {
        epsilon = 0;
    }

    return Math.abs(epsilon);
}

function lessOrEqual(a:number, b:number, epsilon:number) {
    epsilon = fixEpsilon(epsilon);

    if (a < b) {
        return true;
    }

    return Math.abs(a - b) <= epsilon;
}

function greaterOrEqual(a:number, b:number, epsilon:number) {
    epsilon = fixEpsilon(epsilon);

    if (a > b) {
        return true;
    }

    return Math.abs(a - b) <= epsilon;
}

function computePosition(tooltip:Tooltip) {
    return function (tooltipOffset:any) {
        const tooltipElement = tooltip.getTooltipElement(),
            target = tooltip.target,
            targetPosition = target.offset();

        function isTop(tooltipTop:any) {
            const tooltipBottom = tooltipTop + tooltipElement.height();
            return lessOrEqual(tooltipBottom, targetPosition.top, 1);
        }

        function isBottom(tooltipTop:any) {
            const targetBottom = targetPosition.top + target.height();
            return greaterOrEqual(tooltipTop, targetBottom, 1);
        }

        function isLeft(tooltipLeft:any) {
            const tooltipRight = tooltipLeft + tooltipElement.width();
            return lessOrEqual(tooltipRight, targetPosition.left, 1);
        }

        if (isTop(tooltipOffset.top)) {
            tooltipElement.addClass("top");
        }
        else if (isBottom(tooltipOffset.top)) {
            tooltipElement.addClass("bottom");
        }
        else if (isLeft(tooltipOffset.left)) {
            tooltipElement.addClass("left");
        }
        else {
            tooltipElement.addClass("right");
        }

        tooltipElement.css(tooltipOffset);
    };
}

function updatePosition(tooltip:Tooltip) {
    tooltip.tooltipElement.removeClass("top bottom left right");
    if ($(tooltip.target).is(':visible')) {
        tooltip.tooltipElement.position(tooltip.options.position);
    } else {
        tooltip.tooltipElement.position({
            at: "center center",
            my: "center center",
            of: window
        });
    }
}

class Tooltip {
    enabled = true;
    inDom = false;
    target: JQuery;
    tooltipElement:any = null;
    options: any;
    isVisible: ko.Observable<boolean>;
    isBoundClickOutsideEvent = false;
    notifyHideSubscription: any;
    isVisibleSubscription: ko.Subscription;
    tooltipContent: JQuery;
    clickOutsideEvent: (ev: any) => void;

    
    constructor(element:any, options:any) {
        this.target = $(element);
        this.options = options;
        this.isVisible = options.isVisible || ko.observable(false);

        $.extend(this.options.position, {
            of: this.target,
            using: computePosition(this)
        });

        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);

        const showNewPopdown = (event:any) => {
            const closeOtherPopdowns = isNullOrUndefined(this.options.closeOtherPopdowns) ? true : this.options.closeOtherPopdowns;
            if (closeOtherPopdowns) {
                $('.newPopdown').hide();
            }
            this.show(event);
        };

        if (this.options.hidePopupNotifier !== undefined) {
            this.notifyHideSubscription = this.options.hidePopupNotifier.subscribe(() => {
                this.hide();
            });
        }

        if (options.isNewPopdown) {
            this.target.on(this.options.showOn, showNewPopdown);
        } else {
            this.target.on(this.options.showOn, this.show);
        }
        this.target.on(this.options.hideOn, this.hide);
        
        this.isVisibleSubscription = this.isVisible.subscribe(function (newValue) {
            if (newValue === true) {
                this.showWithNoEvent();
            } else if (newValue === false) {
                this.hide();
            }
        }, this);
    }

    setupDom() {
        const tooltipId = 'tooltip-' + counter++;

        const css = this.options.css;
        const arrowCss = this.options.arrowCss;
        const cssClass = css.cssClass;
        const arrow = $('<div/>').addClass("tooltip-arrow");

        this.tooltipContent = $('<div/>').addClass("tooltip-inner");
        
        _(css).each((value, key) => {
            this.tooltipContent.css(key, value);
        });
        _(arrowCss).each(function (value, key) {
            arrow.css(key, value);
        });

        this.tooltipElement = $('<div/>')
            .attr('id', tooltipId)
            .addClass("tooltip in fade")
            .append(this.tooltipContent)
            .append(arrow)
            .hide()
            .data('tooltip', this);
        this.target.attr('tooltip-id', tooltipId);

        if (cssClass) {
            this.tooltipElement.addClass(cssClass);
        }

        if (this.options.hideOn === "mouseleave") {
            this.tooltipElement.bind("mouseenter", function () {
                clearTimeout(timeout);
            });
            this.tooltipElement.bind("mouseleave", $.proxy(this.hide, this));
        }
    }

    getTooltipElement() {
        if (!this.tooltipElement) {
            this.setupDom();
        }
        return this.tooltipElement;
    }

    appendToDom() {
        const hide = () => {
            if(this.hide) {
                this.hide();
            }
        };

        this.inDom = true;
        $(document.body).append(this.getTooltipElement());
        this.options.setContent.call(this);
        $(this.tooltipElement).find('.close-tooltip').on('click', hide);
        
    }

    show(event:any) {
        this.showWithNoEvent();

        const tooltipElement = this.getTooltipElement();

        const clickedOutsideDatePicker = (ev:any) => {
            return ev.originalEvent.composedPath() && 
                ev.originalEvent.composedPath().filter((p:any) => {
                    return p.className !== undefined && p.className.indexOf('bootstrap-datepicker') > -1;
                }).length === 0;
        };

        if (this.options.hideOn === 'clickOutside' && !this.isBoundClickOutsideEvent) {
            this.clickOutsideEvent = function(ev) {
                if (tooltipElement.has(ev.target).length === 0 &&
                    !tooltipElement.is(ev.target) && clickedOutsideDatePicker(ev)) {
                    tooltipElement.hide();
                }
            };
            
            $(document).on('click', this.clickOutsideEvent);
            this.isBoundClickOutsideEvent = true;
        }

        event.preventDefault();
        event.stopPropagation();

        return this;
    }
    
    showWithNoEvent() {
        if (!this.inDom) {
            this.appendToDom();
        }

        if (!this.enabled) {
            return this;
        }
        const tooltipElement = this.getTooltipElement();

        tooltipElement.show();
        this.isVisible(true);

        updatePosition(this);
        

        $(tooltipElement).find(":tabbable").first().focus();

        return this;
    }

    updatePositionIfVisible() {
        if (this.getTooltipElement().is(':visible')) {
            updatePosition(this);
        }
    }

    hide() {
        timeout = setTimeout(() => {
            if (this.tooltipElement) {
                this.tooltipElement.hide();
            }                
        }, 50);
        return this;
    }

    destroy() {
        clearTimeout(timeout);
        this.target.off(this.options.showOn, this.show);
        this.target.off(this.options.hideOn, this.hide);

        if (this.isBoundClickOutsideEvent) {
            $(document).off('click', this.clickOutsideEvent);
            this.clickOutsideEvent = null;
        }

        if (this.notifyHideSubscription) {
            this.notifyHideSubscription.dispose();
            this.notifyHideSubscription = null;
        }

        if (this.isVisibleSubscription) {
            this.isVisibleSubscription.dispose();
            this.isVisibleSubscription = null;
        }

        if (this.tooltipElement) {
            this.tooltipElement.off("mouseenter");
            this.tooltipElement.off("mouseleave");
            $(this.tooltipElement).find('.close-tooltip').off("click");

            this.tooltipElement.each(function() {
                ko.cleanNode(this /* DOM node */);
            });

            this.tooltipElement.remove();
        }

        this.show = null;
        this.hide = null;
        this.options.position.of = null;
        this.options.position.using = null;
        this.options = null;
        this.tooltipContent = null;
        this.tooltipElement = null;
        this.target = null;

        return this;
    }
}

export function create(element:any, options:any) {
    return new Tooltip(element, options);
}
