/// <amd-module name="Core/Medius.Core.Web/Scripts/lib/messageLogging/messageLogger"/>
import { logEvent } from "Core/Medius.Core.Web/Scripts/lib/metricsLogging/metricLogger";
import * as logService from "./logService";
import { Log, LogWithStackTrace, Context } from "./models";
import {getApplicationVersion} from "Core/Medius.Core.Web/Scripts/appConfig";

const cooldownPeriodInMilliseconds = 5000;
const maxBufferSize = 10;
const metricsActionName = "frontendErrors";
const metricsLabel = "count";

let logBuffer: Log[] = [];
let aggregatedLogCount = 0;
let isCooldownPeriod = false;
let cachedContext: Context;

function getContext() {
    if (!cachedContext) {
        cachedContext = {
            url: window.location.href,
            screenSize: {
                width: window.screen.width,
                height: window.screen.height
            },
            platform: (window as any)?.platform?.description ?? "unknown",
            frontendVersion: getApplicationVersion()
        };
    }
    return cachedContext;
}

function sendLogWithStackTrace(log: LogWithStackTrace) {
    try {
        if (log.stackTrace) {
            (window as any).sourceMappedStackTrace.mapStackTrace(log.stackTrace, (mappedStack: any[] | string) => {
                if (mappedStack instanceof Array) {
                    mappedStack = mappedStack.join("\n");
                }
                sendLog({ ...log, stackTrace: mappedStack });
            }, { cacheGlobally: true });
        } else {
            sendLog(log);
        }
    } catch (e) {
        logErrorMessage("stacktrace mapping failed!");
    }
}

function sendLog(log: Log) {
    try {
        if (isCooldownPeriod) {
            if (logBuffer.length < maxBufferSize) {
                logBuffer.push(log);
            }
            aggregatedLogCount++;
            return;
        }

        logService.log([log], getContext());

        logEvent(metricsActionName, metricsLabel, 1);
        isCooldownPeriod = true;

        setTimeout(() => {
            if (logBuffer.length > 0) {
                logService.log(
                    logBuffer,
                    getContext(),
                    aggregatedLogCount,
                );
                logEvent(metricsActionName, metricsLabel, aggregatedLogCount);
            }
            bufferReset();

        }, cooldownPeriodInMilliseconds);
    } catch (e) {
        // Silent catch to avoid infinite loop if error happened during error logging
    }
}

export function logErrorMessage(message: string) {
    sendLog({ type: "error", message });
}

export function logWarnMessage(message: string) {
    sendLog({ type: "warn", message });
}

export function logInfoMessage(message: string) {
    sendLog({ type: "info", message });
}

export function logFromError(error: Error) {
    sendLogWithStackTrace({
        type: "error",
        message: `${error.name}: ${error.message}`,
        stackTrace: error.stack
    });
}

export function startLogging() {
    function logFromOnError(message: string, filename?: string, lineNumber?: number, columnNumber?: number, error?: Error) {
        if (error) {
            sendLogWithStackTrace({
                type: "error",
                message: `${message} ${filename}:${lineNumber}:${columnNumber}\n${error.name}: ${error.message}`,
                stackTrace: error.stack
            });
        } else {
            sendLog({
                type: "error",
                message: `${message} ${filename}:${lineNumber}:${columnNumber}`
            });
        }
    }

    function onUnhandledPromiseRejection(event: any) {
        sendLogWithStackTrace({
            type: "error",
            message: `Unhandled rejection: ${event.reason.message}`,
            stackTrace: event.reason.stack
        });
    }

    window.addEventListener("unhandledrejection", onUnhandledPromiseRejection);
    (window as any).onerror = logFromOnError;
}

export function bufferReset() {
    logBuffer = [];
    isCooldownPeriod = false;
    aggregatedLogCount = 0;
}
