///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/newTooltip/skins"/>
import * as defaultSkin from "Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/newTooltip/defaultSkin";
import * as errorSkin from "Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/newTooltip/errorSkin";
import * as newPopdownSkin from "Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/newTooltip/newPopdownSkin";
import * as whiteSkin from "Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/newTooltip/defaultWhiteSkin";

function createWithDefault() {
    return {
        "base": defaultSkin,
        "error": errorSkin,
        "newPopdown": newPopdownSkin,
        "defaultWhite": whiteSkin
    };
}

let skins:any = createWithDefault();

export function register(name:string, skin:object) {
    if (!name) {
        throw new Error("Skin name is required and must be a string");
    }

    if (!skin) {
        throw new Error("Skin value is required and must be an object");
    }
    skins[name] = skin;
}

export function resolve(name:string) {
    if (skins[name]) {
        return skins[name];
    }
    return skins.base;
}

export function clean() {
    skins = createWithDefault();
}