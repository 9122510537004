///<amd-module name = "Core/Medius.Core.Web/Scripts/components/versionMismatchDialog/versionMismatchDialogTemplate"/>
import { AlertDialog, EmptyState, ImageRocketRocketlaunch, Text } from "@medius/ui-controls";
import * as React from "react";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";

interface VersionMismatchDialogTemplateProps {
    isVersionInvalid: boolean;
    isSessionExpired: boolean;
}

export function VersionMismatchDialogTemplate({isVersionInvalid, isSessionExpired}:VersionMismatchDialogTemplateProps) {
    const [isDialogOpen, setIsDialogOpen] = React.useState(isVersionInvalid && !isSessionExpired);


    React.useEffect(() => { 
        setIsDialogOpen(isVersionInvalid && !isSessionExpired);
    }, [isVersionInvalid, isSessionExpired]);

    return <AlertDialog
        width="small"
        isOpen={isDialogOpen}
        onClose={() => {
            setIsDialogOpen(false);
            window.onbeforeunload = function() {
                return "";
              };

            window.location.reload();

            window.onbeforeunload = function() {

            };
        }}
        okButtonLabel={getLabelTranslation("#Core/refreshNow")}
    >
        <div style={{width:"100%", justifyContent:"center", display:"flex", flexDirection:"column"}}>
            <EmptyState
                explanation={getLabelTranslation("#Core/versionMismatchDialogExplanation")}
                size="large"
                title={getLabelTranslation("#Core/versionMismatchDialogTitle")}
                image={<ImageRocketRocketlaunch style={{width: "200px"}} />}
                contentAlign="center"
            />
            <Text alignment="center" variant="heading3">{getLabelTranslation("#Core/versionMismatchDialogWarning")}</Text>
        </div>
    </AlertDialog>;
}