/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/AuthorizationGroups/Tabs/components/supplierAutocompleter"/>
import { ComboBox, ComboBoxPageChangeEvent } from "@progress/kendo-react-dropdowns";
import { handleAnyError } from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import * as React from "react";
import { useState, useContext } from "react";
import { useDebounce } from "use-debounce";
import { Optional, Supplier } from "../../authorizationGroup";
import { CompanyContext } from "../../contexts/companyContext";
import { getSuppliers } from "../../service";
import { useDidUpdate } from "../hooks/useDidUpdate";

type SupplierAutocompleterProps = {
    readonly supplier: Supplier;
    readonly onChange: (newSupplier: Supplier) => void;
    readonly pageSize: number;
    readonly width: string;
};

type Filter = {
    searchPhrase: Optional<string>,
    skip: number,
    take: number
};

export function SupplierAutocompleter({ supplier, onChange, pageSize, width }: SupplierAutocompleterProps) {
    const companyId = useContext(CompanyContext).companyId;
    const [suppliers, setSuppliers] = useState<Supplier[]>([]);
    const [filter, setFilter] = useState<Filter>({ searchPhrase: undefined, skip: 0, take: pageSize });
    const [total, setTotal] = useState<number>(0);
    const [debouncedFilter] = useDebounce(filter, 300);
    
    useDidUpdate(() => {
        let isSubscribed = true;

        getSuppliers(companyId, filter.skip, filter.take, filter.searchPhrase)
        .then(data => { 
            if(isSubscribed) {
                setSuppliers(data ? data.Suppliers : []);
                setTotal(data ? data.Total : 0);
            }
        })
        .fail(handleAnyError);

        return () => isSubscribed = false;
    }, [companyId, debouncedFilter]);

    const onPageChange = (ev:ComboBoxPageChangeEvent) => { 
        setFilter(f => ({ ...f, skip: ev.page.skip, take: ev.page.take }));
    };

    return (
        <ComboBox
            data-testid="supplier-autocompleter"
            virtual={{
                pageSize: pageSize,
                skip: filter.skip,
                total: total
            }}
            data={suppliers}
            textField="NameWithSupplierIdAndIdentifiers"
            filterable
            required
            onFilterChange={ev => setFilter(f => ({...f, searchPhrase: ev.filter.value, skip: 0}))}
            defaultValue={supplier}
            onOpen={_ => setFilter(f => ({ ...f, searchPhrase: "" }))}
            onChange={ev => onChange(ev.value)}
            onPageChange={onPageChange}
            popupSettings={{ width: width }}
        />
    );
}
