/// <amd-module name="Core/Medius.Core.Web/Scripts/components/topBar/hooks/useClickOutsideMenu"/>
import { useEffect, useRef, MutableRefObject } from "react";

type CallbackWithSideEffects = (e: MouseEvent) => void;
export function useClickOutsideMenu(isMenuOpen: boolean, elRef: MutableRefObject<any>, callback: CallbackWithSideEffects) {
    const callbackRef = useRef<CallbackWithSideEffects>(null);
    (callbackRef as MutableRefObject<CallbackWithSideEffects>).current = callback;

    useEffect(() => {
        const handleClickOutside = (e:MouseEvent) => {
            if (!elRef.current?.contains(e.target) && callbackRef.current) {
                callbackRef.current(e);
            }
        };

        if(isMenuOpen) {
            document.addEventListener("click", handleClickOutside, true );
        }
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, [elRef, isMenuOpen]);
}