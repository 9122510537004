///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/grid/dataQuery/entity"/>
import { DataQuery } from "Core/Medius.Core.Web/Scripts/Medius/components/grid/dataQuery/default";

export class EntityDataQuery extends DataQuery {
    entityType: string;
    companyContextId: null;
    isCompanyRelated: boolean;
    createdDateFilter: null;
    changedDateFilter: null;
    columns: any[];
    mapToDto: any;
    constructor(entityType:string, pageSize:number, initialKeywords:any, initialSorting:any, mapToDto:any) {
        super(pageSize, initialKeywords, initialSorting);
        this.entityType = entityType;
        this.companyContextId = null;
        this.isCompanyRelated = false;
        this.createdDateFilter = null;
        this.changedDateFilter = null;
        this.columns = [];
        this.mapToDto = mapToDto;
        Object.preventExtensions(this);
    }
}