///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/inbox/bulkOperationOnDocumentWithRisksWarning"/>
import * as React from "react";
import { Notification } from "@medius/ui-controls";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { store } from "Core/Medius.Core.Web/Scripts/shared/store/reduxStore";
import { Module } from "Core/Medius.Core.Web/Scripts/Medius/apps/reactSpa/app";

export const BulkOperationOnDocumentWithRisksWarning = () => {
    const isFraudAndRiskProtectionEnabled = store?.getState().accesses.enabledModules.includes(Module.FraudAndRiskProtection);

    return <>
        {
            isFraudAndRiskProtectionEnabled &&
            <Notification 
                feedbackType="warning" 
                text={getLabelTranslation("#Core/noBulkOperationForDocumentsWithRisks")} 
                title=""
                data-testid='fraud-bulkoperation-warning'
            />
        }
    </>;
};