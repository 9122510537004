///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/documentSearch/actionManager"/>
import * as ko from "knockout";
import * as notificationWizard from "Core/Medius.Core.Web/Scripts/Medius/apps/documentSearch/actions/notification/wizard";

class ActionManager {
    notificationWizard = ko.observable(null);

    openNotificationWizard(resultSet: () => { (): any; new(): any; documentIds: { (): any; new(): any; length: number; }; }) {
        
        if (resultSet().documentIds.length === 0) {
            return false;
        }

        const wizard = notificationWizard.create(resultSet, undefined);
        this.notificationWizard(wizard);
    }
    
    destroy() {
    }
}

export function create() {
    return new ActionManager();
}
