/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/dashboard/modals/deleteDashboard"/>
import * as $ from "jquery";

class DeleteDashboard {
    DashboardId: number | null = null;
    deleteConfirm: () => void;

    constructor(framework: any) {
        this.deleteConfirm = () => {
            framework.deleteDashboard();
            $("#modal-dashboard-delete").modal("hide");
        };
    }
}

export function create(frameworkInstance: any) {
    return new DeleteDashboard(frameworkInstance);
}
