/// <amd-module name="Core/Medius.Core.Web/Scripts/components/quickSearch/services/quickSearchService"/>
import { SearchResult, RecentDocumentsSuggestionCollectionDto } from "Core/Medius.Core.Web/Scripts/components/quickSearch/quickSearchDtos";
import * as rest from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rest";

export function search(searchTerm: string): JQueryPromise<SearchResult> {
    return rest.get("quicksearch", `search?searchTerm=${encodeURIComponent(searchTerm)}`);
}

export function recentlyViewed(): JQueryPromise<RecentDocumentsSuggestionCollectionDto> {
    return rest.get("quicksearch", "recentlyViewed");
}

export function markAsRecentlyViewed(entityId: number, entityType: string): JQueryPromise<{}> {
    return rest.post("quicksearch", `recentlyViewed/${entityId}/${encodeURIComponent(entityType)}`);
}
