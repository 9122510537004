/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/grid/dataSource/dataSourceOperations"/>
import * as ko from "knockout";
import * as _ from "underscore";
/*
  This function expects that object to which it will be attached would have getSortValue(rows, sortingProperty) function 
  legacy/backwards compatibility reasons until there is generic DataSource for grids, it is crucial for function to have
  bindable this 
*/
/** Function expects that object to which it will be attached has getSortValue method - more info in module */
export function sortRows<T>(dataQuery: any, rows: T[]): T[] {
    const sortingProperty = _(_.keys(dataQuery.sorting) || []).first();
    const sortingOrder = dataQuery.sorting[sortingProperty];

    if (!sortingProperty) {
        return rows;
    }

    const result = _(rows).sortBy((row) => {
        const value = ko.unwrap(this.getSortValue(row, sortingProperty));
        const toFloat = parseFloat(value);

        return !isNaN(toFloat) ? toFloat : value;
    });
    return (sortingOrder === "desc") ? result.reverse() : result;
}

/** Function mutates state in some cases - page in dataQuery is set to first */
export function pageRows<T>(dataQuery: any, rows: T[]): T[] {
    let startIndex = (dataQuery.page - 1) * dataQuery.pageSize;
    let endIndex: number = startIndex + dataQuery.pageSize;

    if (startIndex >= rows.length) {
        dataQuery.page = 1;
        startIndex = 0;
        endIndex = dataQuery.pageSize;
    }
    
    return rows.slice(startIndex, endIndex);
}
