/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/default/longText/errors/dataObjectAuthorizationResult"/>
import * as typeBoundResolverFactory from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/typeBound";
import { formatValueWithPlaceholders } from "Core/Medius.Core.Web/Scripts/Medius/lib/stringFormat";
import { isNotNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";

export const resolver = typeBoundResolverFactory.create({
    priority: 0,

    type: "Medius.Security.DataObjectAuthorizationResult",

    resolve: function (response: any) {
        const errorElements = [];
        let message = response.Message;
        if (isNotNullOrUndefined(message)) {
            message = formatValueWithPlaceholders(message, [response.MessageParams]);
            errorElements.push(message);
        }

        const objectType = response.ObjectType;
        if (isNotNullOrUndefined(objectType)) {
            errorElements.push(objectType);
        }

        const error = errorElements.join('\n');

        return {
            longText: error
        };
    }
});
