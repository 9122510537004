///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Entities.User/Reminders/bulkRemoveDataSource"/>
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import DataSource = require("Core/Medius.Core.Web/Scripts/Medius/components/grid/dataSource/default");
import * as notification from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import * as repository from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Reminders/repository";
import * as ko from "knockout";
import * as _ from "underscore";

function wrapRow(dataSource:any, row:any) {
    row.DocumentTypeName = row.DocumentTypeName || ko.computed(function () {
        return globalization.getPropertyTranslation("#" + row.EntityFullTypeName());
    });

    row.ReminderTypeName = row.ReminderTypeName || ko.computed(function () {
        const reminderInfo = dataSource.availableReminders.getAvailableReminder(row.ReminderType());
        return (reminderInfo) ? reminderInfo.NameTranslated : null;
    });

    row.ReminderCompatibleTypes = row.ReminderCompatibleTypes || ko.computed(function() {
        return dataSource.availableReminders.getCompatibleDocumentTypes(row.ReminderType());
    });
    
    row.dispose = row.dispose || function () {
        row.DocumentTypeName.dispose();
        row.ReminderTypeName.dispose();
    };

    return row;
}

class BulkRemoveDataSource extends DataSource  {
    collection: any;
    availableReminders: any;
    availableRemindersNamesArray: any;
    availableRemindersArray: null;
    constructor(collection:any, availableReminders:any) {
        super();
        this.collection = collection;
        this.availableReminders = availableReminders;
        this.availableRemindersNamesArray = availableReminders.getAvailableRemindersNamesArray();
    }

    loadColumns() {
        const columns = [
            {
                Sortable: true,
                Searchable: false,
                Resizable: true,
                Stretch: true,
                Name: globalization.getLabelTranslation("#Core/typeOfReminderLabel"),
                ValueSource: "ReminderType",
                Width: 100
            },
            {
                Sortable: true,
                Searchable: false,
                Resizable: true,
                Stretch: true,
                Name: globalization.getLabelTranslation("#Core/documentTypeHeader"),
                ValueSource: "EntityFullTypeName",
                Width: 100
            },
            {
                Sortable: false,
                Searchable: false,
                Resizable: false,
                Stretch: false,
                Width: 90,
                Name: "",
                ValueSource: ""
            }
        ];

        return $.Deferred().resolve(columns);
    }

    load(dataQuery:any) {
        let rows = _.map(this.collection(), (row) => {
            return wrapRow(this, row);
        });
        rows = super.sort(dataQuery, rows);
        rows = super.page(dataQuery, rows);

        return $.Deferred().resolve(rows);
    }
    
    getTotalRows() {              
        return $.Deferred<number>().resolve(this.collection().length);
    }
    
    getNewRow() {
        return wrapRow(this, {
            ReminderType: ko.observable(),
            EntityFullTypeName: ko.observable()
        });
    }

    validateRow(row:any) {
        const duplicate = _(this.collection()).any((item) => {
            return item.ReminderType() === row.ReminderType() &&
                item.EntityFullTypeName() === row.EntityFullTypeName() &&
                item !== row;
        });
        
        if (duplicate) {
            notification.error(`${globalization.getLabelTranslation("#Core/rowAlreadyExists")} (${row.ReminderTypeName()}, ${row.DocumentTypeName()}).`);
            return false;
        }

        return true;
    }
    
    addRow(row:any) {
        this.collection.unshift(row);
    }
    
    removeRow(row:any) {
        this.collection.remove(row);
    }
    
    destroy() {
        this.availableReminders.dispose();
        repository.invalidateReminderCache();

        this.collection = null;
        this.availableReminders = null;
        this.availableRemindersArray = null;
        super.destroy();
    }
}

export function create(collection:any, availableReminders:any) {
    return new BulkRemoveDataSource(collection, availableReminders);
}