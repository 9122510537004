/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/task/models/topBarMessageProviderCollection"/>
import {observableArray} from "knockout";

const messageProviders = observableArray<TopBarMessageProvider>([]);

export function register(messageProvider: TopBarMessageProvider) {
    messageProviders.push(messageProvider);
}

export function getMessages(context: any, documentViewModel: any, documentType: string, task: any): string[] {
    return messageProviders()
        .filter(provider => provider.canHandle(context, documentType, task))
        .map(provider => provider.getMessage(documentViewModel));
}
