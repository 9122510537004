/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/lib/stringFormat"/>
import { chain } from "underscore";

export function formatValueWithPlaceholders(value: string, args: any[]) {
    if (args.length === 0) {
        return value;
    }
    return chain(args)
            .foldl((s, e, i) => {
                const t = "\\{" + i.toString() + "\\}";
                const value = typeof e === 'undefined' || e === null ? "" : e.toString();
                return s.replace(new RegExp(t, "g"), value);
            }, value)
            .value();
}
