///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/workflow/studio/deleteBusinessRuleConfirmationDialog"/>
import * as React from "react";
import { ConfirmDialog } from "@medius/ui-controls";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";

interface DeleteBusinessRuleConfirmationDialogProps {
    IsDialogOpen: boolean,
    OnConfirm: () => void,
    OnCancel: () => void
}

export const DeleteBusinessRuleConfirmationDialog = ({IsDialogOpen, OnConfirm, OnCancel}: DeleteBusinessRuleConfirmationDialogProps) => {
    return <ConfirmDialog
        isOpen = {IsDialogOpen}
        onCancel={OnCancel}
        onConfirm={OnConfirm}
        text={translate("#Core/deleteBusinessRuleConfirmationDialogText")}
        confirmLabel={translate("#Core/removalConfirmation")}
        cancelLabel={translate("#Core/cancel")}
    >
    </ConfirmDialog>;
};