///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/widgets/InvoiceInsightsWidget/WhoIsApprovingThisInvoice"/>
import { CalloutButton, FlexContent, FlexLayout, IconArrowsChevronRightRegular, IconToggleSparkleFill, LoaderBar, Text } from "@medius/ui-controls";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as React from "react";
import { IUserDto } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/agentInterfaces";
import { getCurrent } from "Core/Medius.Core.Web/Scripts/Medius/lib/utils/user";

interface WhoIsApprovingThisInvoiceProps {
    predictedApprovers: string[];
    previousApproversPerStep: IUserDto[][];
}

export function WhoIsApprovingThisInvoice({predictedApprovers, previousApproversPerStep}: WhoIsApprovingThisInvoiceProps) {
    const currentUser = getCurrent();
    const currentUserDto: IUserDto = {
        firstName: currentUser.FirstName,
        lastName: currentUser.LastName,
        userName: currentUser.UserName
    };
    

    return <div className="mediusAgent__widgetCard">
        <Text variant="heading3">
            {getLabelTranslation("#Core/WhoIsApprovingThisInvoice")}
        </Text>
        <FlexContent expand={false}>
            <FlexLayout spacerSize="vsmall" spacerMode="off" itemColumnSpacing="vsmall" verticalAlignment="center" direction="row">
            {predictedApprovers ? 
                <>
                    {previousApproversPerStep.length > 0 &&
                        <PreviousApprovers previousApproversPerStep={previousApproversPerStep}/>
                    }
                    <FlexContent expand={false}>
                        <FlexLayout 
                            spacerSize="vsmall" 
                            spacerMode="on"
                            itemColumnSpacing="vsmall" 
                            verticalAlignment="center" 
                            direction="row"
                        >
                            <Text alignment="center" variant="heading3" data-testid="apa-copilot-approverdisplayname-you">
                                {`${getLabelTranslation("#Core/you")} (${getApproverDisplayName(currentUserDto)})`}
                            </Text>
                        </FlexLayout>
                    </FlexContent>
                    {predictedApprovers.length > 0 &&
                        <PredictedApprovers predictedApprovers={predictedApprovers}/>
                    }
                </>:
                <LoaderBar spacerMode="on" data-testid="copilot-invoiceinsights-predictedApprovers-loaderBar"/>
            }
            </FlexLayout>
        </FlexContent>
    </div>;
}

function PreviousApprovers({previousApproversPerStep}: {previousApproversPerStep: IUserDto[][]}) {
    return previousApproversPerStep.map((usersInStep, index) => (
        <FlexContent key={index} expand={false}>
            <FlexLayout spacerSize="vsmall" spacerMode="on" itemColumnSpacing="vsmall" verticalAlignment="center" direction="row" data-testid="copilot-invoiceInsights-whoElseIsApprovingThisInvoice-list-previousApprovers">
                    <Text variant="body" data-testid={`copilot-invoiceinsights-authorizationHistory-username-display-text-${index}`}>{getApproverDisplayName(usersInStep[0])}</Text>
                {usersInStep.length > 1 && 
                <CalloutButton 
                    label={`+${usersInStep.length-1}`}
                    variant="inline"
                    description={getLabelTranslation("#Core/showMore")}
                    height="small"
                    width="content"
                    calloutProps={{
                        title: getLabelTranslation("#Core/detailsHeader"),
                        renderContent: () => <>
                            {usersInStep.slice(1).map((el, index) => <Text variant="paragraph" key={index}>{getApproverDisplayName(el)}</Text>)}
                        </>,
                        width: "content",
                        showHeader: false
                    }}
                    />}
                <IconArrowsChevronRightRegular style={{paddingLeft:"8px", paddingRight:"8px"}}/>
            </FlexLayout>
        </FlexContent>
    ));
}

function PredictedApprovers({predictedApprovers}: {predictedApprovers: string[]}) {
    return <FlexContent expand={false}>
        <FlexLayout spacerSize="vsmall" spacerMode="on" itemColumnSpacing="vsmall" verticalAlignment="center" direction="row" data-testid="copilot-invoiceInsights-whoElseIsApprovingThisInvoice-list-predictedApprovers">
            <IconArrowsChevronRightRegular style={{paddingLeft:"8px", paddingRight:"8px"}}/>
            <IconToggleSparkleFill style={{paddingLeft:"8px", paddingRight:"8px"}}/>
            <Text spacerMode="off" variant="body" data-testid="copilot-invoiceinsights-predictedApprovers-name-display-text">{predictedApprovers[0]}</Text>
            {predictedApprovers.length > 1 && 
            <CalloutButton 
                data-testid="copilot-invoiceinsights-predictedApprovers-showMorePredictedApprovers-button"
                label={`+${predictedApprovers.length-1}`}
                variant="inline"
                description={getLabelTranslation("#Core/showMore")}
                height="small"
                width="content"
                calloutProps={{
                    title: getLabelTranslation("#Core/detailsHeader"),
                    "data-testid": "copilot-invoiceinsights-predictedApprovers-showMorePredictedApprovers-callout",
                    renderContent: () => <>
                        {predictedApprovers.slice(1).map((el, index) => <Text variant="paragraph" key={index}>{el}</Text>)}
                    </>,
                    width: "content",
                    showHeader: false
                }}
            />}
        </FlexLayout>
    </FlexContent>;
}

const getApproverDisplayName = (user: IUserDto) => {
    if(!user.firstName || !user.lastName) {
        return user.userName;
    }

    return user.firstName + " " + user.lastName[0];
};
