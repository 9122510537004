/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/validation/editors/number/editNumberValidators" />

import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";

class ValidationResult {
    public valid: boolean;
    public validationDetails: string;

    public constructor(valid: boolean, validationDetails: string) {
        this.valid = valid;
        this.validationDetails = validationDetails;
    }

    public static makeValid() {
        return new ValidationResult(true, null);
    }

    public static makeInvalid(validationDetails: string) {
        return new ValidationResult(false, validationDetails);
    }
}

export function PositiveValidator(input: number): ValidationResult
{
    const decimalMaxValue = 99999999999999;

    if (input >= 0 && input < decimalMaxValue)
        return ValidationResult.makeValid();
    else if (input < 0)
        return ValidationResult.makeInvalid(getLabelTranslation("#Core/valueMustBeNonNegative"));
    else
        return ValidationResult.makeInvalid(getLabelTranslation("#Core/valueIsUndefined"));
}

export function NegativeValidator(input: number): ValidationResult
{
    const decimalMinValue = -99999999999999;

    if (input <= 0 && input > decimalMinValue)
        return ValidationResult.makeValid();
    else if (input > 0)
        return ValidationResult.makeInvalid(getLabelTranslation("#Core/valueMustBeNonPositive"));
    else
        return ValidationResult.makeInvalid(getLabelTranslation("#Core/valueIsUndefined"));
}