/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/lib/serialization/extended"/>
import * as _ from "underscore";
import * as dateUtils from "Core/Medius.Core.Web/Scripts/Medius/lib/utils/date";
import * as logger from "Core/Medius.Core.Web/Scripts/Medius/lib/logger";
import * as performance from "Core/Medius.Core.Web/Scripts/Medius/lib/performance";
import * as ko from "knockout";

const ignored = [
    "Perspective",
    "_ctx",
    "ViewData"
];

// required due to the http://json.codeplex.com/workitem/23673
function cloneObject(id: any, data: any) {
    const o: Record<string, unknown> = {
        $id: id
    };
    _(data).chain().keys().without("$id", "$ref")
        .each(function (k: any) {
            o[k] = data[k];
        });
    return o;
}

function getKey(o: any) {
    if (o instanceof Object && typeof o.$type === "string") {
        if (o.Id) {
            return [o.$type, "Id", o.Id].join(":");
        } else if (o.ViewId) {
            return [o.$type, "ViewId", o.ViewId].join(":");
        }
    }
    return null;
}

function serialize(o: any) {
    let reference = 1;
    const map: Record<string, unknown> = {};
    
    function visit(value: any) {
        if (value === null || value === undefined) {
            return undefined;
        }
        
        if (value instanceof Date) {
            return value;
        }
        
        if (value instanceof Array) {
            return  _(value).map(function (item) {
                return visitObject(item);
            });
        }
        
        return visitObject(value);
    }
    
    function visitObject(obj: any) {
        const v = replacer(null, obj);
        
        if (v instanceof Object && !v.$ref) {
            const dest: Record<string, unknown> = {};
        
            _.chain(v)
                .keys()
                .reject(function(key) {
                    return _(ignored).contains(key);
                })
                .each(function(key) { 
                    dest[key] = visit(v[key]);
                });
        
            return dest;
        }

        return v;
    }
    
    function replacer(key: string, value: any) {
        const objKey = getKey(value);

        if (_.contains(ignored, key) || value === null) {
            return undefined;
        } else if (typeof objKey !== "string" || objKey === "") {
            return value;
        } else {
            const currentRef = map[objKey];
            if (typeof currentRef === "string" && currentRef !== "") {
                return { $ref: currentRef };
            }
            const current = (reference++).toString();
            const cloned = cloneObject(current, value);
            map[objKey] = current;
            return cloned;
        }
    }

    const serialized = JSON.stringify(visit(o));
    return serialized;
}

function deserialize(json: any) {
    if (typeof json !== "string") {
        throw new Error("Invalid JSON string");
    }

    const references: Record<string, unknown> = {};

    function get(id: any, data?: any) {
        if(!references[id]) {
            references[id] = {};
        }

        if (data) {
            _(references[id]).extend(data);
        }

        return references[id];
    }

    function reviver(key: string, value: any) {
        if (value && typeof value === "object") {
            if (value.$id) {
                const id = value.$id;
                delete value.$id;
                return get(id, value);
            }

            if (value.$ref) {
                return get(value.$ref);
            }
        } else if (typeof value === "string") {
            return dateUtils.tryRevive(value);
        }
        return value;
    }

    return JSON.parse(json, reviver);
}

function fromJSONInner(json: any) {
    if (!json) {
        return null;
    }

    return deserialize(json);
}


function toJSONInner(viewData: any) {
    if (typeof viewData !== "object" || viewData === null) {
        return JSON.stringify(viewData);
    }
    const data = ko.toJS(viewData);
    return serialize(data);
}

function measure(name: string, f: any) {
    return function (...args: any[]) {
        const s = performance.now();

        const result = f.apply(this, arguments);
        const e = performance.now();
        logger.info(name + ": " + (e - s) + "ms");
        return result;
    };
}

export const toJSON = measure("medius/lib/serialization/extended:toJSON", toJSONInner);
export const fromJSON = measure("medius/lib/serialization.extended:fromJSON", fromJSONInner);