///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/inbox/taskGroup/checkbox" />

import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as _ from "underscore";
import * as ko from "knockout";
import { documentVisibilityStore } from "Core/Medius.Core.Web/Scripts/Medius/core/store/inbox/documentVisibility";

class TaskGroupCheckbox{
    folders: any;
    typeName: any;
    visible = ko.observable();
    dispose: any;
    
    constructor(folders: any, typeName: any){ 
        this.typeName = typeName;

        const updateVisibility = (value: any) => {
            _(this.folders.peek()).each((fldr) => {
                _(fldr.typeGroups.peek()).each((typeGroup) => {
                    if (typeGroup.displayName === this.typeName) {
                        typeGroup.visible(value);
                    }
                });
            });
        };

        this.visible.subscribe((value: any) => {
            documentVisibilityStore.setItem(typeName, value);
            updateVisibility(value);
        });
        this.folders = folders;
        this.dispose = _(koUtils.disposeModel).bind(this);

        documentVisibilityStore.getItem(typeName).done((visible: boolean) => {
            this.visible(visible === false ?
                false :
                true);
        });
    }
}

export function create(folders: any, typeName: any) {
    return new TaskGroupCheckbox(folders, typeName);
}