/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/default/text/entity/roleDto"/>
import {unpack} from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as typeBoundResolverFactory from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/typeBound";

export const resolver = typeBoundResolverFactory.create({
    priority: 0,
    type: "Medius.Core.DTOs.RoleDto",
    resolve: function (data: any) {
        const role = unpack(data),
            name = unpack(role.AssigneeName);
        
        return {
            text: name
        };
    }
});
