/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/lib/events"/>
import * as _ from "underscore";

const INVALID_CALLBACK = "Callback must be a function";

export class EventAggregator {
    private subscriptions: any = {};

    subscribe(eventName: string, callback: any) {
        if (typeof callback !== "function") {
            throw new Error(INVALID_CALLBACK);
        }

        if (!this.subscriptions[eventName]) {
            this.subscriptions[eventName] = [];
        }

        this.subscriptions[eventName].push(callback);

        return {
            dispose: this.unsubscribe.bind(this, eventName, callback)
        };
    }

    unsubscribe(eventName: string, callback: any) {
        if (!this.subscriptions[eventName]) {
            return;
        }

        if (!callback) {
            this.subscriptions[eventName] = [];
            return;
        }

        this.subscriptions[eventName] = _(this.subscriptions[eventName]).reject(function (cb) {
            return cb === callback;
        });
    }

    publish(eventName: string, ...args: any[]) {
        const eventSubscriptions = this.subscriptions[eventName];

        if (!(eventSubscriptions instanceof Array)) {
            return;
        }

        _(eventSubscriptions).each(function (callback) {
            callback.apply(this, args);
        });
    }
    once(eventName: any, callback: any) {
        /*jshint validthis: true */
        const self = this;

        function oneTimeCallback() {
            callback.apply(this, arguments);
            self.unsubscribe(eventName, oneTimeCallback);
        }

        return this.subscribe(eventName, oneTimeCallback);
    }
    on = this.subscribe;
    off = this.unsubscribe;
    trigger = this.publish;

    dispose() {
        this.subscriptions = {};
    }
}

export enum SupplierConversationDemoModalEvents {
    openFull = "openSupplierDemoModalFull",
    openSmall = "openSupplierDemoModalSmall",
    close = "closeSupplierDemoModal",
}
