///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/accounting/codingSuggestions"/>

export const supported = (workflowStep: string, reasonCodeName: string) => {
    if (workflowStep === "DistributeDocument")
        return true;

    if (workflowStep === "ApproveDocument"
        && reasonCodeName !== "AuthorizationLimit"
        && reasonCodeName !== "FourEyesPrincipleNotFullfilled")
        return true;

    return false;
};