/// <amd-module name="Core/Medius.Core.Web/Scripts/bindings/userAutocompleter"/>
import { utils } from "knockout";
import * as $ from "jquery";
import {registerBinding} from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";

const autocompleter = {
    init(element: HTMLElement, valueAccessor: any) {
        $(element).kendoAutoComplete({
            dataSource: valueAccessor().availableOptions,
            dataTextField: "name",
            filter: "contains",
            select(e: any) {
                const dataItem = this.dataItem(e.item.index());
                valueAccessor().onChangeHandler(dataItem);
            }
        });
        
        const currentWidget = $(element).data("kendoAutoComplete");

        $(element).focus(() => {
            currentWidget.search(currentWidget.value());
        });

        utils.domNodeDisposal.addDisposeCallback(element, () => {
            currentWidget.destroy();
        });

    }
};

export function register() {
    registerBinding("user-autocompleter", autocompleter);
}
