/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/lib/utils/breakablePipeFactory"/>
import * as $ from "jquery";

export type PipedDeferred<T> = JQueryDeferred<T> & Partial<{
    abort(): void;
    success(doneCallback: JQueryPromiseCallback<T>): JQueryDeferred<T>;
    error(failCallback: JQueryPromiseCallback<T>): JQueryDeferred<T>;
    complete(alwaysCallback: JQueryPromiseCallback<T>): JQueryDeferred<T>;
}>;

export class BreakablePipe {
    public breakPipe: () => void;
    public getPipedDeferred: () => PipedDeferred<any>;

    constructor(sourceDeferred: JQueryPromise<any>) {
        let pipeIsBroken = false;
        const pipedDeferred: PipedDeferred<any> = $.Deferred();
        pipedDeferred.abort = () => this.breakPipe();
        pipedDeferred.success = pipedDeferred.done;
        pipedDeferred.error = pipedDeferred.fail;
        pipedDeferred.complete = pipedDeferred.always;

        $.when(sourceDeferred).pipe(function () {
            const args = Array.prototype.slice.call(arguments);
            if (!pipeIsBroken) {
                pipedDeferred.resolve.apply(pipedDeferred, args);
            }
        }, function () {
            const args = Array.prototype.slice.call(arguments);
            if (!pipeIsBroken) {
                pipedDeferred.reject.apply(pipedDeferred, args);
            }
        });

        this.breakPipe = () => pipeIsBroken = true;
        this.getPipedDeferred = () => pipedDeferred;
    }
}

export function create(sourceDeferred: JQueryPromise<any>) {
    return new BreakablePipe(sourceDeferred);
}
