/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/workflownames"/>

export const AnalyzeDocument = "AnalyzeDocument";
export const ManualConnectDocument = "ManualConnectDocument";
export const AuthorizeInvoiceAmount = "AuthorizeInvoiceAmount";
export const AuthorizeDeviations = "AuthorizeDeviations";
export const AnalyzeDeviations = "AnalyzeDeviations";
export const ExpenseManualPostControl = "ExpenseManualPostControl";
export const ManualPostControl = "ManualPostControl";
export const EndTask = "EndTask";
export const ManualConnectContractbasedInvoice = "ManualConnectContractbasedInvoice";
export const Create = "Create";
export const Edit = "Edit";
export const Error = "Error";
export const DistributeDocument = "DistributeDocument";
export const HandleMappedDocument = "HandleMappedDocument";
export const ApproveDocument = "ApproveDocument";
export const SplitHeaderTax = "SplitHeaderTax";
export const SplitTax = "SplitTax";
export const Confirm = "Confirm";
export const RiskFactor = "SynchronizeFraudRisk";
export const OrderbasedIntegrationInvalidate = "Cancel post orderbasedinvoice";