/// <amd-module name="Core/Medius.Core.Web/Scripts/customTabs/definitions/contracts/eventActions"/>

function isClickEvent (event: any): boolean {
    return !!(event && event.type == 'click');
}

function isFilterEvent (event: any): boolean {
    return !!(event && event.type == 'input');
}

function isEnterEvent (event: any): boolean {
    return !!(event && event.key === 'Enter');
}

function isArrowEvent (event: any): boolean {
    return !!(event && (event.key === 'ArrowDown' || event.key === 'ArrowUp'));
}

export { isClickEvent, isEnterEvent, isArrowEvent, isFilterEvent };