///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/error"/>
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as ko from "knockout";

function makeValueAccessor(valueAccesor:any) {
    return function () {
        return {
            data: valueAccesor(),
            enabled: valueAccesor().hasError,
            options: {
                showOn: 'focus',
                hideOn: 'blur',
                skin: 'error'
            }
        };
    };
}

const error = {
    init: function (element:any, valueAccessor:any, allBindingsAccessor:any, viewModel:any, bindingContext:any) {
        return ko.bindingHandlers.newTooltip.init(
            element,
            makeValueAccessor(valueAccessor),
            allBindingsAccessor,
            viewModel,
            bindingContext
        );
    },

    update: function (element:any, valueAccessor:any, allBindingsAccessor:any, viewModel:any, bindingContext:any) {
        return ko.bindingHandlers.newTooltip.update(
            element,
            makeValueAccessor(valueAccessor),
            allBindingsAccessor,
            viewModel,
            bindingContext
        );
    }
};

export function register() {
    koUtils.registerBinding("error", error);
}