/// <amd-module name="Core/Medius.Core.Web/Scripts/components/user/userContextState"/>

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserDto } from "Core/Medius.Core.Web/Scripts/Medius/lib/utils/user";

const initialState = {} as UserDto;

const userSlice = createSlice({
    name: "userState",
    initialState,
    reducers: {
        setUser: (state: UserDto, action: PayloadAction<UserDto>) => {
            return action.payload;
        }
    }
});

export const { setUser } = userSlice.actions;
export default userSlice.reducer;