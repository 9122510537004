/// <amd-module name="Core/Medius.Core.Web/Scripts/shared/components/amountPresenter"/>
import * as React from "react";
import { useInternationalization } from "@progress/kendo-react-intl";

export const AmountPresenter = (props: { value: number; format?: string | null; currencyCode?: string | null }) => {
  const internationalization = useInternationalization();
  const { value, format, currencyCode } = props;

  return (
    <span>
      {internationalization.formatNumber(value, format ?? "n2")}
      {currencyCode && ` ${currencyCode}`}
    </span>
  );
};