/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Entities.Role/DataObjectDataProvider"/>
import {DataProvider} from "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/default/dataProvider";
import * as _ from "underscore";
import { Deferred } from "jquery";

class DataObjectDataProvider extends DataProvider {
    results: any;
    fillResults(collection: any) {
        this.results = collection;
    }
    search(phrase: string, maxResults: number) {
        const regex = new RegExp(`${phrase.replace("*", "(.*)").toLowerCase()}`);
        const returnSet =
            _.chain(this.results)
                .filter(function(item) {
                    return regex.test(item.toLowerCase());
                }).first(maxResults).value();

        return Deferred().resolve(returnSet);
    }
}

export function create() {
    return new DataObjectDataProvider();
}