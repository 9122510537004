/// <amd-module name="Core/Medius.Core.Web/Scripts/lib/reactIntegration/reactTaskTabsBinding"/>
import * as ko from "knockout";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { createRoot, Root } from "react-dom/client";
import * as propsValidator from "./propsValidator";
import * as mode from "../development/mode";

/*
 * This is a specialized version of generic react binding, which is used to help implement counters to taskTab
 */

interface FunctionComponentProps {
    tabs: any[],
    activateTab: () => void
}

interface ReactTaskTabsBindingParams {
    reactBinding: ko.Computed<{
        functionComponent?: React.FunctionComponent<FunctionComponentProps>;
        props: FunctionComponentProps
    }>,
    counters: Array<any>
}

const rootList = new Map<any, Root>();
function getRoot(el:any) {
    if(!rootList.has(el)){
        rootList.set(el, createRoot(el));
    }
    return rootList.get(el);
}

export function register() {
    ko.bindingHandlers.reactTaskTabs = {
        init(el: any, valueAccessor: any) {
            const config = ko.unwrap(valueAccessor()) as ReactTaskTabsBindingParams;

            return { controlsDescendantBindings: true };
        },

        update(el: any, valueAccessor: any) {
            const config = ko.unwrap(valueAccessor()) as ReactTaskTabsBindingParams;
            const newTabs = config.reactBinding().props.tabs.map((tab) => {
                const counters = config.counters[tab.tabName];

                const poIndicatorVisible = counters.counterData()?.poCommentsCount() && counters.counterData()?.poCommentsCount() > 0;

                const counter = counters.getCounter()();
                return {tabName:tab.tabName, tabTestId:tab.tabTestId, tabActive:tab.tabActive, counter:counter, poIndicatorVisible:poIndicatorVisible};
            });
 
            const newConfig = {
                functionComponent: config.reactBinding().functionComponent,
                props: {
                    tabs: newTabs,
                    activateTab: config.reactBinding().props.activateTab
                }
            };

            if (!mode.isProduction() && !propsValidator.isValid(newConfig.props)) {
                throw new Error("Passing observables to react binding is not allowed, as it could cause interoperability problems between React and Knockout");
            }

            const RootComponent = newConfig.functionComponent;

            const root = (<RootComponent {...newConfig.props}></RootComponent>);

            el.classList.add("react-root");
            const renderRoot = getRoot(el);

            ko.utils.domNodeDisposal.addDisposeCallback(el, () => {
                renderRoot.unmount();
            });

            renderRoot.render(root);

            return { controlsDescendantBindings: true };
        }
    };
}