/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/workflow/studio/dataObjectViewer"/>
import * as _ from "underscore";
import { request} from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rpc";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import {handleAnyError } from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import * as path from "Core/Medius.Core.Web/Scripts/Medius/lib/path";
import contextFactory = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/context");
import * as workflowDataProvider from "Core/Medius.Core.Web/Scripts/Medius/apps/workflow/studio/workflowDataProvider";
import * as ko from "knockout";
import mapping = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/mapping/collection/instance");

class ViewModel {
    private readonly contextApplicationName: string;
    private readonly contextApplicationVersion: string;
    private readonly contextApplication = ko.observable();
    private readonly context: any = contextFactory();

    public readonly AvailableDataObjects = ko.observableArray([]);
    public readonly CurrentAppConfiguration = ko.observable();
    public readonly AvailableIntegrationDomains = ko.observableArray();
    public readonly DataObject = ko.observable();
    public readonly ShowExport = ko.observable(false);
    public readonly SelectedDataObjectName = ko.observable();
    public readonly ExportType = ko.observable();
    public readonly IntegrationDomain = ko.observable();
    public readonly AvailableXmlTypes = ko.observableArray(["MediaXml", "ServiceXml", "ServiceJson", "IntegrationXml"]);
    public readonly ExportObject = ko.observable();
    public readonly ExportedData = ko.observable("Exported XML");
    public WorkflowDataProvider: any;

    constructor(applicationName: string, applicationVersion: string) {
        this.contextApplicationName = applicationName;
        this.contextApplicationVersion = applicationVersion;
        this.WorkflowDataProvider  = workflowDataProvider.create(this.contextApplicationName, this.contextApplicationVersion, undefined);

        this.initializeAvailableDataObjects();
        this.initializeContextApplication();
        this.initializeAvailableIntegrationDomains();

        this.SelectedDataObjectName.subscribe(() => {
            if (this.SelectedDataObjectName()) {
                this.fetchDataObject(this.SelectedDataObjectName());
            }
        });
    }

    public exportData = () => {
        const bodyData = {
            dataObjectName: this.SelectedDataObjectName(),
            exportFormat: this.ExportType(),
            exportObjectId: this.ExportObject(),
            integrationDomain: this.IntegrationDomain()
        };
        
        request("DataObjectManager", "ExportObject", bodyData).done((data: any) => {
            this.ShowExport(false);
            this.ExportedData(data);
            this.ShowExport(true);
        }).fail((result: any) => {
            handleAnyError(result);
        });
    };

    public closeExportModal = () => {
        this.ShowExport(false);
    };

    public cancelSelectedDataObject = () => {
        this.DataObject(null);
        this.ShowExport(false);
        this.SelectedDataObjectName(null);
    };

    public WorkflowStudioLink = ko.computed(() => {
        return path.toAction("Index", "~/WorkflowStudio") +
            "?ApplicationName=" + this.contextApplicationName +
            "&Version=" + this.contextApplicationVersion;
    });

    private initializeAvailableDataObjects() {
        const bodyData = {
          applicationName: this.contextApplicationName,
          applicationVersion: this.contextApplicationVersion,
        };

        request("DataObjectManager", "GetDataObjectsNamesForApplication", bodyData).done((data: any) => {
            this.AvailableDataObjects(data);
            $(".dataObject-select").chosen();
            $(".dataObject-select").trigger("liszt:updated"); // hm?
        });
    }

    private initializeContextApplication() {
        const bodyData = {
            name: this.contextApplicationName,
            version: this.contextApplicationVersion
        };
        request("ApplicationPackageService", "GetApplicationPackage", bodyData).done((data: any) => 
            this.onGetContextAppSuccess(data));
    }

    private onGetContextAppSuccess(data: any) {
        const application = this.context.create(data);
        this.contextApplication(application);

        const conf = this.combineConfigurationString();
        this.CurrentAppConfiguration(conf);
    }

    private combineConfigurationString() {
        if (this.contextApplication()) {
            const app: any = this.contextApplication();
            const confString = [app.Name(), app.Version(), app.Type()].join("/ ");
            return confString;
        }
        return "";
    }

    private initializeAvailableIntegrationDomains() {
        request("DataObjectManager", "GetIntegrationDomains").done((data: any) => 
            this.AvailableIntegrationDomains(data));
    }

    public fetchDataObject (dataObject: any) {
        const bodyData = {
            dataObjectName: dataObject
        };

        request("DataObjectManager", "GetDataObject", bodyData).done((data: any) => {
            const dataObjectViewModel = this.context.create(data);
            this.DataObject(dataObjectViewModel);
        });
    }
}

export function register() {
    mapping.register("Medius.Core.Entities.DataObjectEditor.DataObject, Medius.Core.Common", function (vm: any, data: any, context: any) {
        vm.changeBaseObject = function () {
            const baseObjectName: string = vm.BaseObjectName();
            if (!_.isNull(baseObjectName) && !_.isUndefined(baseObjectName)) {
                const bodyData = {
                    dataObjectName: vm.BaseObjectName()
                };
                request("DataObjectManager", "GetDataObject", bodyData)
                .done(function(dataObject: any) {
                    const dataObjectViewModel = context.create(dataObject);
                    vm.InheritedFields(dataObjectViewModel.Fields);
                    vm.InheritedFields($.map(data.AllFields, function(field) {
                        return ko.observable(context.create(field));
                    }));
                    vm.IsCompanyRelatedInherited(dataObjectViewModel.IsCompanyRelated());
                });
            } else {
                vm.InheritedFields([]);
                vm.IsCompanyRelatedInherited(false);
            }
        };

        vm.baseObjectSync = ko.computed(function() {
            if (vm.BaseObjectName()) {
                vm.changeBaseObject();
            }
        });
    });

    // enchance the automatically-created view model for field
    mapping.register("Medius.Core.Entities.DataObjectEditor.Field", function (vm: any) {
        vm.selectedFieldType = ko.observable(vm.FieldType());
    });
}

export function create(applicationName: string, applicationVersion: any) {
    return new ViewModel(applicationName, applicationVersion);
}
