///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/sortable"/>
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as $ from "jquery";
import * as _ from "underscore";

const sortable = {
    init: function (element:any, bindingAccessor:any) {
        let $element = $(element),
            onChange = bindingAccessor().onChange;
        
        const options = {
            //when user starts to move the items, marked id of them
            start: function () {
                const baseItems = $element.children().not(".ui-sortable-placeholder");
                _.each(baseItems, function (item:any, index) {
                    item.id = index;
                });
            },
            //when user stops new items order is send to base view model
            stop: function () {
                const newOrder = $element.sortable("toArray");
                onChange(newOrder);
            }
        };
        
        // bind your sortable here
        $element.sortable(options);
        $element.disableSelection();
        
        koUtils.addDisposeCallback(element, function() {
            $element.sortable("destroy");
            onChange = null;
            $element = null;
        });
    }
};

export function register() {
    koUtils.registerBinding("sortable", sortable);
}