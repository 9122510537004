/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/default/link/documentTask"/>
import {unpack} from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as typeBoundResolverFactory from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/typeBound";
import * as path from "Core/Medius.Core.Web/Scripts/Medius/lib/path";

export const resolver =  typeBoundResolverFactory.create({
    priority: 0,
    type: "Medius.Core.Entities.DocumentTask",
    resolve: function (data: any) {
        const entityId = unpack(data.Id);

        return {
            link: path.getBasePath() + ["#DocumentTasks", entityId].join("/")
        };
    }
});
