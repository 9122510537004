///<amd-module name = "Core/Medius.Core.Web/Scripts/Models/Medius.Core.Entities.Role"/>
import * as notification from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as rpc from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rpc";
import * as backendErrorHandler from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import * as _ from "underscore";
import baseEditor = require('Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Data.DataDefinition/Editor');

function hasUsersAttachedCallback(result:any) {
    if (result) {
        const warningText = globalization.getLabelTranslation("#Core/disabledRoleWithUsersWarning");
        const warningTitle = globalization.getLabelTranslation("#Core/warning");
        notification.info(warningText, warningTitle);
    }
}

class RoleEditor {
    isActiveProperty: any;
    ConfigurableProperties: any;
    roleChangeSubscribe: any;
    constructor(entityWithContext:any, companyContextId:any, tabModel:any) {
        _.extend(this, baseEditor(entityWithContext, companyContextId, tabModel));

        this.isActiveProperty = _(this.ConfigurableProperties()).find(function (property) {
            return property.Name === "IsActive";
        });
        this.roleChangeSubscribe = this.isActiveProperty.Value.subscribe(function (newValue:boolean) {
            if (newValue === false) {
                rpc.request('RoleService', 'HasUsersAttached', {
                    roleId: entityWithContext.Entity.Id
                }).done(hasUsersAttachedCallback)
                .fail(function (xhr) {
                    backendErrorHandler.handleAnyError(xhr);
                });
            }
        });
    }
}

export = function(entityWithContext:any, companyContextId:any, tabModel:any) {
    return new RoleEditor(entityWithContext, companyContextId, tabModel);
};