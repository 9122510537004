///<amd-module name="Core/Medius.Core.Web/Scripts/Paging/PagingHelpers"/>
import * as ko from "knockout";
export class PagingHelpers {
    public GetPageItemStartIndex = (actualPageNumber: number, pageSize:number, itemsCount:number) => {
        if(itemsCount < 1) 
            return 0;
        return ((actualPageNumber - 1) * pageSize) + 1;
    };
    
    public GetPageItemLastIndex = (pageItemStartIndex:number, pageSize:number, itemsCount:number) => {
        const lastIndex = pageItemStartIndex + pageSize -1;
        if (lastIndex > itemsCount)
            return itemsCount;
        return lastIndex;
    };

    public GetPageItemsCollection = (newCollection:any, pageItemStartIndex:number, pageItemLastIndex:number) => {
        const items = ko.utils.unwrapObservable(newCollection).slice(pageItemStartIndex - 1, pageItemLastIndex);
        return items;
    };
}