///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/lib/map" />

import * as _ from "underscore";

export function mapFactory(initialData?: any, disposeItems?: any) {
    let data = {
        ...initialData
    };
    
    const mapObject = {
        get: function(key: any) {
            return data[key];
        },
        ensure: function(key: any, initialValue: any) {
            if (!data[key]) {
                data[key] = initialValue;
            }
    
            return data[key];
        },
        set: function(key: any, value: any) {
            data[key] = value;
        },
        dispose: function(forceItemDisposal: any) {
            let k, v;
            if (disposeItems || forceItemDisposal) {
                for (k in data) {
                    v = data[k];
                    if (data.hasOwnProperty(k) && v && v.dispose) {
                        v.dispose(true);
                    }
                }
            }
            data = {};
        },
        getKeys: function() {
            return data.keys;
        },
        hasKey: function(key: any) {
            return data[key] !== undefined;
        }
    };

    return mapObject;
}