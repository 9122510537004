///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/dashboard/deleteGadgetConfirmationDialog"/>
import * as React from "react";
import { ConfirmDialog } from "@medius/ui-controls";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";

interface DeleteGadgetConfirmationDialogProps {
    IsDialogOpen: boolean,
    OnConfirm: () => void,
    OnCancel: () => void
}

export const DeleteGadgetConfirmationDialog = ({IsDialogOpen, OnConfirm, OnCancel}: DeleteGadgetConfirmationDialogProps) => {
    return <ConfirmDialog
        data-testid="gadgetRemovalConfirmationDialog"
        isOpen={IsDialogOpen}
        onCancel={OnCancel}
        onConfirm={OnConfirm}
        text={translate("#Core/deleteGadgetConfirmationDialogText")}
        confirmLabel={translate("#Core/removalConfirmation")}
        cancelLabel={translate("#Core/cancel")}
    >
    </ConfirmDialog>;
};