///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/editor" />

import * as ko from "knockout";
import * as typeahead from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/typeahead";
import * as stateMachine from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/stateMachine";
import * as operatorsProvider from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/provider/operators";
import * as typesProvider from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/provider/types";
import * as queryTypesProvider from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/provider/queryTypes";
import { EmptyQueryState as emptyQueryState } from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/states/states";
import * as queryParser from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/parser/query";
import * as treeParser from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/parser/tree";
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as _ from "underscore";
import * as $ from "jquery";
import { isEmptyString } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
const DOCUMENT = "Medius.Data.Document";

class QueryEditor{
    options: any;
    outTree: any;
    outValid: any;
    outType: any;
    type: ko.Observable;
    availableTypes: any;
    isValid: any;
    stateMachine: any;
    typeahead: any;
    inputQuery: any;
    isLoading: any;
    parsedType: any;
    inputQuerySub: any;
    validSub: any;
    typeSub: any;

    constructor(options: any) {
        const defaults = {
            template: "document-search-query-editor-default",
            typeSelector: true,
            typeSelectorEnabled: true,
            onEnterPressed: (null as any),
            availableTypes: (null as any)
        };
        
        this.options = _.extend({}, defaults, options);
        
        if (!this.options.value) {
            throw new Error("Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/editor: missing value");
        }

        this.outTree = this.options.value;
        this.outValid = this.options.isValid;
        this.outType = this.options.type;
        this.type = ko.observable();
        this.availableTypes = ko.observableArray([]);
        this.isValid = ko.observable(false);
        const initialState = emptyQueryState;
        this.stateMachine = stateMachine.create(this.type(), initialState);
        this.typeahead = typeahead.create(this.stateMachine);
        this.inputQuery = ko.observable("");
        this.isLoading = ko.observable(false);
        this.parsedType = ko.observable();

        const toOutTree = _.debounce(() => {
            this.isValid(this.validate(this.inputQuery()));
            
            const tree = (this.isValid()) ? this.toTree() : null;

            if (this.outTree && ko.isObservable(this.outTree)) {
                this.outTree(tree);
            }
        }, 500);

        this.inputQuerySub = this.inputQuery.subscribe(() => {
            toOutTree();
        });
        
        this.validSub = this.isValid.subscribe((value: any) => {
            if (this.outValid && ko.isObservable(this.outValid)) {
                this.outValid(value);
            }
        });

        this.typeSub = this.type.subscribe((newType: any) => {
            this.stateMachine.entityType = newType;
            
            if (this.outType && ko.isObservable(this.outType)) {
                this.outType(newType);
            }

            toOutTree();
        });
        
        this.type(DOCUMENT);

        this.loadAvailableDocumentTypes();
        
        this.parse(koUtils.unpack(this.outTree));
        this.isValid(this.validateInput());
    }
    
    loadAvailableDocumentTypes() {
        if (!this.options.typeSelector) {
            return;
        }
        
        if (this.options.availableTypes && ko.isObservable(this.options.availableTypes)) {
            this.availableTypes = this.options.availableTypes;
            return;
        }

        $.when(
            queryTypesProvider.load()
        ).pipe((queryTypes: any) => {
            this.availableTypes(queryTypes);
            this.type(DOCUMENT);
        });
    }
    
    validate(query: any) {        
        if (isEmptyString(query)) {
            return true;
        }

        const result = this.stateMachine.validate(query);
        return result.valid;
    }
    
    // to align with validation-group interface
    validateInput() {
        return this.validate(this.inputQuery());
    }

    parse(tree: any) {        
        if (tree === null || tree === undefined) {
            return;
        }
        
        if (typeof tree === "string") {
            tree = isEmptyString(tree) ? { Root: null } : JSON.parse(tree);
            tree.EntityType = tree.EntityType || DOCUMENT;
        }
        
        const query = treeParser.loadSingleQuery(tree);
        const entityType = tree.EntityType;

        this.parsedType(entityType);
        this.type(entityType);
        this.inputQuery(query);
    }
    
    toTree(query?: any) {
        query = query || this.inputQuery();
        const tree = queryParser.parse(this.type(), query);

        return tree;
    }
    
    applyCssToOption(option: any, item: any) {
        option.innerHTML = createIntendation(item.level) + item.label;
    }
    
    dispose() {
    }
    
    destroy() {
        this.inputQuerySub.dispose();
        this.typeSub.dispose();
    }
}

function createIntendation(level: any) {
    let str = "", i;
    for (i = 0; i < level; i++) {
        str += "&nbsp;&nbsp;&nbsp;";
    }
    return str;
}

export function create(options: any){
    operatorsProvider.load();
    typesProvider.load();
    return new QueryEditor(options);
}