///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/grid/bindings"/>
import * as entityGrid from "Core/Medius.Core.Web/Scripts/Medius/components/grid/bindings/entityGrid";
import * as dataGrid from "Core/Medius.Core.Web/Scripts/Medius/components/grid/bindings/dataGrid";
import * as draggableRow from "Core/Medius.Core.Web/Scripts/Medius/components/grid/bindings/draggableRow";
import * as clickOutside from "Core/Medius.Core.Web/Scripts/Medius/components/grid/bindings/clickOutside";
import * as clickableRows from "Core/Medius.Core.Web/Scripts/Medius/components/grid/bindings/clickableRows";
import * as columns from "Core/Medius.Core.Web/Scripts/Medius/components/grid/bindings/columns";
import * as columnSelector from "Core/Medius.Core.Web/Scripts/Medius/components/grid/bindings/columnSelector";
import * as droppableRows from "Core/Medius.Core.Web/Scripts/Medius/components/grid/bindings/droppableRows";
import * as nestedProperties from "Core/Medius.Core.Web/Scripts/Medius/components/grid/bindings/nestedProperties";
import * as search from "Core/Medius.Core.Web/Scripts/Medius/components/grid/bindings/search";

export function registerGridBindings() {
    entityGrid.register();
    dataGrid.register();
    draggableRow.register();
    clickOutside.register();
    clickableRows.register();
    columns.register();
    columnSelector.register();
    droppableRows.register();
    nestedProperties.register();
    search.register();
}