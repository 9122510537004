///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/editors/percentage/defaults"/>
export function get() {
    return {
        template: "edit-percentage-default",
        resolution: 2,
        isInteger: false,
        trimZeros: true,
        notLocalized: false,
        options: {
            defaultName: "editor-percentage"
        }
    };
}