///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/transformers/serialize/viewmodel"/>
import * as ko from "knockout";
import * as defaults from "Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/transformers/create/defaults";
import * as utils from "Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/transformers/create/utils";

export = function viewmodelSerializer(input:any/*, context*/) {
    if (!utils.hasMetadata(input)) {
        return null;
    }

    const data:any = {};

    return {
        ...defaults,
        shouldDescend: true,
        shouldDescendProperty: function(name:string) {
            return utils.hasProperty(input, name);
        },
        mapProperty: function (name:string, value:any) {
            data[name] = ko.utils.unwrapObservable(value);
        },
        finalize: function () {
            return data;
        }
    };
};