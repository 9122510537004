///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Schedulers.MasterDataCleanupJobContext/Editor"/>
import mappings = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/mapping/collection/instance");
import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import * as ko from "knockout";
import * as _ from "underscore";

function viewModelFactory(viewModel:any) {
    viewModel.Template = 'Core:templates/Administration/MasterDataCleanupJobContext/EditorTemplate.html';

    viewModel.SpanDays = ko.computed({
        read: () => {
            if (isNullOrUndefined(viewModel.Span())) {
                return undefined;
            }

            return viewModel.Span().split(".")[0];
        },
        write: (value) => {
            viewModel.Span(value + ".00:00:00");
        },
        owner: viewModel
    });

    viewModel.dispose = () => {
        viewModel.SpanDays.dispose();
        viewModel.SpanDays = null;
    };
}

export function register() {
    mappings.register("Medius.Core.Schedulers.MasterDataCleanupJobContext", _.constant(true), viewModelFactory);
}