///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/presenters/percentage/model"/>
import * as _ from "underscore";
import * as ko from "knockout";
import * as koDisposal from "Core/Medius.Core.Web/Scripts/Medius/knockout/disposal";
import * as helpers from "Core/Medius.Core.Web/Scripts/Medius/components/editors/helpers";
import * as number from "Core/Medius.Core.Web/Scripts/Medius/components/presenters/number/model";
import * as koSync from "Core/Medius.Core.Web/Scripts/Medius/knockout/observables/synchronization";
import * as defaultsHolder from "Core/Medius.Core.Web/Scripts/Medius/components/presenters/percentage/defaults";

class Percentage {
    dispose: () => void;
    format: (value: any) => string;
    constructor(bindingParameters:any = {}) {
        const percent = ko.observable();
        let percentSync: {dispose:() => void};
        let percentageUnwrapped:any;

        const percentageSubscriptionsUpdate = (percentageObject:any) => {
            koDisposal.tryDispose(percentSync);

            if (!percentageObject) {
                return;
            }

            percentSync = koSync.oneWay(percentageObject.Percent, percent, undefined);
        };

        const synchronizePercentage = (percentageObject:any) => {
            if (percentageObject === percentageUnwrapped) {
                return;
            }

            percentageUnwrapped = percentageObject;
            percentageSubscriptionsUpdate(percentageObject);
        };
        
        const defaults:any = defaultsHolder.get();
        helpers.mergeDefaults(bindingParameters, defaults);

        const percentage = defaults.value;
        let percentageSubscription: ko.Subscription;

        if (ko.isObservable(percentage) && !_.isNull(percentage())) {
            defaults.value = percent;
            percentageSubscription = percentage.subscribe(synchronizePercentage);
            synchronizePercentage(percentage());
        }
        else if (!ko.isObservable(percentage)) {
            defaults.value = ko.observable(percentage.Percent);
        }
        else {
            defaults.disabled = true;
        }

        const numberModel = number.create(defaults);
        _(this).extend(numberModel);

        this.dispose = () => {
            koDisposal.tryDispose(percentSync);
            koDisposal.tryDispose(percentageSubscription);
            number.derive.prototype.dispose.call(this);
        };

        this.format = (value:any) => {
            return value ? `${value}%` : '';
        };
    }
}

export function create(bindingParameters:any) {
    return new Percentage(bindingParameters);
}