/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/snapToWindow"/>
/*
THIS BINDING IS DEPRECATED, DO NOT USE IT

As we are now trying to make our design to be at least responsive for desktop application this binding represents old approach and should not be used.
Try to use flexbox instead, with flex-grow: 1 - it would be sufficient for 90% cases and this is the style which we want to follow.
ONLY case when usage of this binding is understandable is trying to integrate with layout which bootstrap is providing - unfortunately with relative
and fixed positioning and fixed pixel heights/widths sometimes the only way to make element to fill all space is to set calculated height in JS
*/

import * as $ from 'jquery';
import * as ko from 'knockout';

const updateHeight = (element: HTMLElement, bottomMargin: number, window: Window) => {
    setTimeout(() => {
        const $element = $(element);
        $element.height($(window).height() - $element.offset().top - bottomMargin);
    }, 0);
};

const snapToWindowBinding = {
    init(element: HTMLElement, valueAccessor: () => any) {
        const bottomMarginParam = ko.isObservable(valueAccessor().bottomMargin) 
            ? valueAccessor().bottomMargin()
            : valueAccessor().bottomMargin;
        
        const bottomMargin: number = bottomMarginParam || 0;
        const updateHeightHandler = () => updateHeight(element, bottomMargin, window);
        $(window).on('resize', updateHeightHandler);

        setTimeout(() => {updateHeight(element, bottomMargin, window);}, 0);

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            $(window).off('resize', updateHeightHandler);
        });
    },
}

export const register = () => {
    ko.bindingHandlers["snapToWindow"] = snapToWindowBinding;
}
