///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/scrollable"/>
import * as $ from "jquery";
import * as _ from "underscore";
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";

const SCROLL_STEP = 100;
export const SCROLLABLE_DOM_KEY = "scrollable";
const CSS_HIDDEN_SCROLL = "empty";
const CSS_VISIBLE_SCROLL = "on";
const CSS_BIGGER_SCROLL = "bigger";
const CSS_SCROLLABLE = "scrollable-ko";
const CSS_NATIVE_SCROLLABLE = "scrollable";
const CSS_SCROLL = "scroll";
const CSS_SCROLL_X = "scroll-x";
const CSS_SCROLL_Y = "scroll-y";
const CSS_HANDLER = "handler";

class ScrollHandler{
    $element: any;
    $content: any;
    enableX: any;
    enableY: any;
    mouseScrollTimestamp: any;
    $scrollX: any;
    $scrollY: any;
    $scrollsContainers: any;
    posContentY: number;
    posContentX: number;
    posScrollY: number;
    posScrollX: number;
    distanceX: number;
    distanceY: number;
    distanceScrollX: number;
    distanceScrollY: number;
    distancePerPxX: number;
    distancePerPxY: number;
    stepScrollY: number;
    sizeScrollX: number;
    sizeScrollY: number;
    constructor($element:any, enableX:any, enableY:any){
        this.$element = $element;
        this.$content = $element.children().eq(0);
        this.enableX = _.isUndefined(enableX) ? true : enableX;
        this.enableY = _.isUndefined(enableY) ? true : enableY;

        this.mouseScrollTimestamp = null;

        this.$scrollX = null;
        this.$scrollY = null;
        this.$scrollsContainers = null;

        this.posContentY = 0;
        this.posContentX = 0;
        this.posScrollY = 0;
        this.posScrollX = 0;

        this.distanceX = 0;
        this.distanceY = 0;
        this.distanceScrollX = 0;
        this.distanceScrollY = 0;
        this.distancePerPxX = 0;
        this.distancePerPxY = 0;

        this.stepScrollY = 0;
        
        this.sizeScrollX = 0;
        this.sizeScrollY = 0;

        this.appendScrolls();
    }

    appendScrolls() {
        if (this.enableX) {
            this.$element.append('<div class="' + CSS_SCROLL + ' ' + CSS_SCROLL_X + '"><div class="' + CSS_HANDLER + '"></div></div>');
            this.$scrollX = this.$element.find("." + CSS_SCROLL_X + " ." + CSS_HANDLER);

            this.$scrollX.draggable({
                addClasses: false,
                containment: "parent",
                axis: "x",
                grid: [1,0],
                stop: this.onDragX.bind(this)
            });
        }
        
        if (this.enableY) {
            this.$element.append('<div class="' + CSS_SCROLL + ' ' + CSS_SCROLL_Y + '"><div class="' + CSS_HANDLER + '"></div></div>');
            this.$scrollY = this.$element.find("." + CSS_SCROLL_Y + " ." + CSS_HANDLER);
            
            this.$scrollY.draggable({
                addClasses: false,
                containment: "parent",
                axis: "y",
                grid: [0,1],
                stop: this.onDragY.bind(this)
            });
        }
        
        if (this.enableX || this.enableY) {
            this.$scrollsContainers = this.$element.find("." + CSS_SCROLL);
            this.$scrollsContainers.mouseover(function () {
                $(this).addClass(CSS_BIGGER_SCROLL);
            });
            this.$scrollsContainers.mouseout(function () {
                $(this).removeClass(CSS_BIGGER_SCROLL);
            });
        }
    }

    calculate() {
        // x
        if (this.enableX) {
            const elementWidth = this.$element.width();
            const contentWidth = this.$content.width();
            this.distanceX = contentWidth - elementWidth;

            this.sizeScrollX = (this.distanceX > 0) ? (elementWidth / contentWidth) * elementWidth : 0;
            
            this.distanceScrollX = (this.distanceX > 0) ? elementWidth - this.sizeScrollX : 0;
            this.distancePerPxX = (this.distanceX > 0) ? this.distanceX / (elementWidth - this.sizeScrollX) : 0;
        }

        // y
        if (this.enableY) {
            const elementHeight = this.$element.height();
            const contentHeight = this.$content.height();
            this.distanceY = contentHeight - elementHeight;

            this.sizeScrollY = (this.distanceY > 0) ? (elementHeight / contentHeight) * elementHeight : 0;
            this.sizeScrollY = (this.distanceY > 0) ? this.sizeScrollY - 10 : this.sizeScrollY;

            this.distanceScrollY = (this.distanceY > 0) ? elementHeight - this.sizeScrollY : 0;
            this.distancePerPxY = (this.distanceY > 0) ? this.distanceY / this.distanceScrollY : 0;
            
            const stepRatioY = SCROLL_STEP / this.distanceY;
            this.stepScrollY = this.distanceScrollY * stepRatioY;
        }

        return true;
    }

    recalculate() {
        this.calculate();
        this.updateHandlersSizes();
    }
    
    updateHandlersSizes() {
        if (this.enableX) {
            this.$scrollX.css("width", this.sizeScrollX + "px");
            
            if (this.sizeScrollX > 0) {
                this.$scrollX.parent().removeClass(CSS_HIDDEN_SCROLL);
            } else {
                this.$scrollX.parent().addClass(CSS_HIDDEN_SCROLL);
            }
        }

        if (this.enableY) {
            this.$scrollY.css("height", this.sizeScrollY + "px");
            
            if (this.sizeScrollY > 0) {
                this.$scrollY.parent().removeClass(CSS_HIDDEN_SCROLL);
            } else {
                this.$scrollY.parent().addClass(CSS_HIDDEN_SCROLL);
            }
        }
    }

    scrollByY(contentPosition:any) {
        this.$content.animate({
            top: "-" + contentPosition + "px"
        }, 300);
    }
        
    scrollByX(contentPosition:any) {
        this.$content.animate({
            left: "-" + contentPosition + "px"
        }, 300);
    }
    
    moveScrollYTo(scrollPosition:any) {
        this.$scrollY.animate({
            top: scrollPosition + "px"
        }, 300);
    }
    
    moveScrollXTo(scrollPosition:any) {
        this.$scrollX.animate({
            left: scrollPosition + "px"
        }, 300);
    }

    calculatePosition(delta:number, step:number, distance:number, position:number) {

        if (delta > 0) {
            position -= step;
        } else {
            position += step;
        }   

        position = (position < 0) ? 0 : position;
        position = (position > distance) ? distance : position;

        return position;
    }
    
    onMouseScroll(e:any, deltaX:any, deltaY:any) {
        if (e.timestamp <= this.mouseScrollTimestamp) {
            return;
        }
        this.mouseScrollTimestamp = e.timestamp;

        if (this.$scrollY) {
            this.posContentY = this.calculatePosition(deltaY, SCROLL_STEP, this.distanceY, this.posContentY);
            this.posScrollY = this.calculatePosition(deltaY, this.stepScrollY, this.distanceScrollY, this.posScrollY);
            
            this.moveScrollYTo(this.posScrollY);
            this.scrollByY(this.posContentY);
        }
    }

    onDragX(e:any, ui:any) {
        if (e.timestamp <= this.mouseScrollTimestamp) {
            return;
        }
        this.mouseScrollTimestamp = e.timestamp;
        
        if (ui.position.left > this.distanceScrollX) {
            this.moveScrollXTo(this.distanceScrollX);
        }
        else if (ui.position.left < 0) {
            this.moveScrollXTo(0);
            this.scrollByX(0);
            return;
        }

        const scrolledDistance = ui.position.left - ui.originalPosition.left;
        const step = this.distancePerPxX * Math.abs(scrolledDistance);
        const deltaX = (scrolledDistance > 0) ? -1 : 1;
        
        this.posContentX = this.calculatePosition(deltaX, step, this.distanceX, this.posContentX);
        this.scrollByX(this.posContentX);
    }
    
    onDragY(e:any, ui:any) {
        if (e.timestamp <= this.mouseScrollTimestamp) {
            return;
        }
        this.mouseScrollTimestamp = e.timestamp;
        
        if (ui.position.top > this.distanceScrollY) {
            this.moveScrollYTo(this.distanceScrollY);
        }
        else if (ui.position.top < 0) {
            this.moveScrollYTo(0);
        }

        const scrolledDistance = ui.position.top - ui.originalPosition.top;
        const step = this.distancePerPxY * Math.abs(scrolledDistance);
        const deltaY = (scrolledDistance > 0) ? -1 : 1;

        this.posContentY = this.calculatePosition(deltaY, step, this.distanceY, this.posContentY);
        this.scrollByY(this.posContentY);
    }

    dispose() {
        if (this.$scrollX && this.$scrollX.is(":data(ui-draggable)")) {
            this.$scrollX.draggable("destroy");
        }
        
        if (this.$scrollY && this.$scrollY.is(":data(ui-draggable)")) {
            this.$scrollY.draggable("destroy");
        }
        
        if (this.$scrollsContainers) {
            this.$scrollsContainers.off("mouseover mouseout");
        }
        
        this.$scrollX = null;
        this.$scrollY = null;
        this.$scrollsContainers = null;
        this.$content = null;
        this.$element = null;
    }
}

function stopWheel(e:any) {
    if (!e) { /* IE7, IE8, Chrome, Safari */
        e = window.event;
    }
    if (e.preventDefault) { /* Chrome, Safari, Firefox */
        e.preventDefault();
    }
    e.returnValue = false; /* IE7, IE8 */
}

const scrollable = {
    init: function (element:any, bindingAccessor:any) {let alternativeElement = bindingAccessor().element;
        const enableX = bindingAccessor().x;
        const enableY = bindingAccessor().y;
        const useNativeScroll = bindingAccessor().native;let $window = $(window);let $element = (alternativeElement) ?
            $(element).parent().find(alternativeElement) :
            $(element);
        let onMouseWheel:any;

        if (useNativeScroll) {
            $element.addClass(CSS_NATIVE_SCROLLABLE);
            return;
        }
        let handler = new ScrollHandler($element, enableX, enableY);
        
        $element.addClass(CSS_SCROLLABLE);let $scrolls = $element.find("." + CSS_SCROLL);
        
        $element.hover(function () {
            $scrolls.addClass(CSS_VISIBLE_SCROLL);

            if (handler.sizeScrollY > 0) {
                const f = _.debounce(handler.onMouseScroll, 50);
                onMouseWheel = function(e:any, delta:any, deltaX:any, deltaY:any) {
                    stopWheel(e);
                    f.call(handler, e, deltaX, deltaY);
                };

                $element.mousewheel(onMouseWheel);
            }

        }, function () {
            $scrolls.removeClass(CSS_VISIBLE_SCROLL);
            $element.unmousewheel();
        });
        
        let recalculateHandler = () => {
            handler.recalculate();
        };
        let resizeHandler = _.debounce(recalculateHandler, 300);
        
        $window.on("shown", recalculateHandler); // bootstrap tabs shown
        $window.resize(resizeHandler);
        
        koUtils.domData.set(element, SCROLLABLE_DOM_KEY, {
            handler: handler
        });

        koUtils.addDisposeCallback(element, function () {
            $window.off("shown", recalculateHandler);
            $window.off("resize", resizeHandler);

            handler.dispose();

            $element
                .remove("." + CSS_SCROLL)
                .removeClass(CSS_SCROLLABLE)
                .unmousewheel(onMouseWheel)
                .off("mouseenter mouseleave hover");

            alternativeElement = null;
            resizeHandler = null;
            handler = null;
            onMouseWheel = null;
            $scrolls = null;
            $window = null;
            $element = null;
            recalculateHandler = null;

            koUtils.domData.set(element, SCROLLABLE_DOM_KEY, {});
        });
    }
};

export function register() {
    koUtils.registerBinding("scrollable", scrollable);
}