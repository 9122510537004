///<amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/User/IdentityProvider/identityProvider"/>
import * as ko from "knockout";
import * as _ from "underscore";
import DataSource from "Core/Medius.Core.Web/Scripts/AdminPages/User/IdentityProvider/identityProviderDataSource";
import {error} from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import * as dataGrid from "Core/Medius.Core.Web/Scripts/Medius/components/grid/default/grid";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as sync from "Core/Medius.Core.Web/Scripts/Medius/core/sync";

class IdentityProvider {
    public entityWithCtx: any;
    public Entity: any;
    public Tab: any;
    public CompanyContextId: any;
    public Collection: any;
    public IdentityProvidersGrid: any;
    public SelectedProvider: ko.Observable<any>;
    public SelectedName: ko.Observable<any>;
    public isAddingPossible: ko.Computed<boolean>;
    public translations: any;

    constructor(entityWithContext: any, companyContextId: any, tabModel: any) {
        const entityModel = entityWithContext.Entity;

        this.entityWithCtx = entityWithContext;
        this.Entity = entityModel;
        this.Tab = tabModel;
        this.CompanyContextId = companyContextId;
        this.Collection = this.resolveCollection(this.Entity, "IdentityProviders");
        this.IdentityProvidersGrid = this.createGrid();
        this.SelectedProvider = ko.observable();
        this.SelectedName = ko.observable();

        this.isAddingPossible = ko.computed(() => {
            return !!this.SelectedProvider() &&
                !!this.SelectedName() &&
                !_.isEmpty(this.SelectedName().trim());
        });

        this.translations = {
            chooseLabel: getLabelTranslation('#Core/chooseProvider'),
            logInProvider: getLabelTranslation('#Core/loginInProvider')
        }
    }

    public addEntry() {
        if (!this.isNew(this.SelectedName())) {
            const errorInfo = getLabelTranslation("#Core/authenticationLoginIsAlreadyUsedError_login", this.SelectedName());
            error(errorInfo);
        }
        else {
            const newUserIdentityProvider = sync.getNew("Medius.Core.Entities.Security.UserIdentityProvider");
            newUserIdentityProvider.NameIdentifier = ko.observable(this.SelectedName());
            newUserIdentityProvider.IdentityProviderName = ko.observable(this.SelectedProvider());

            this.Collection.push(newUserIdentityProvider);
            this.IdentityProvidersGrid.addRow(newUserIdentityProvider);
        }
    }

    public isNew(selectedName: any) {
            const duplicatedName = _.find(this.Collection(), (identityProvider : any) => {
                return identityProvider.NameIdentifier() === selectedName;
            });
        return _.isUndefined(duplicatedName);
    }

    public createGrid() {
        const dataSource = new DataSource(this.Collection);

        const options = {
            tplRow: "Core:templates/Common/RemovableRowTpl.html",
            pageSize: 20,
            onRemoveRow: (row: any) => {
                this.removeProvider(row);
            }
        };

        return dataGrid.create(dataSource, options);
    }

    public resolveCollection(entityModel: any, propertyName: any) {
        const collection = entityModel[propertyName];
        if (!collection) {
            throw new Error(`Property ${propertyName} does not exist"`);
        }
        return collection;
    }

    public removeProvider(provider: any) {
        if (provider.IdentityProviderName().Name() === "Medius Local identity store") {
            const nameIdentifier = provider.NameIdentifier();
            const relatedLocalUsers = this.entityWithCtx.RelatedObjects["Medius.Security.Entities.LocalUser"].RelatedEntities().filter((element: any) => {
                return element.Username() !== nameIdentifier;
            });
            this.entityWithCtx.RelatedObjects["Medius.Security.Entities.LocalUser"].RelatedEntities(relatedLocalUsers);
        }
        this.Collection.remove(provider);
    }

    public dispose() {
        this.SelectedProvider(null);
        this.Entity = null;
        this.Tab = null;
        this.CompanyContextId = null;
        this.IdentityProvidersGrid.destroy();
        this.IdentityProvidersGrid = null;
        this.Collection = null;
    }
}

export default (entityWithContext: any, companyContextId: any, tabModel: any) => {
    return new IdentityProvider(entityWithContext, companyContextId, tabModel);
}