/// <amd-module name="Core/Medius.Core.Web/Scripts/components/controls/multiSelect"/>

import { KendoUtils } from "Core/Medius.Core.Web/Scripts/Medius/kendo/kendoUtils";
import {translate} from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { addDisposeCallback, registerBinding } from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";

const multiSelectBinding = {
    init: (element: HTMLElement, valueAccessor: ko.Observable<MultiSelectConfiguration>) => {
        const $element = $(element);
        const configuration = valueAccessor();

        function getMultiselectElement(): JQuery {
            const multiselect = $element.children(".k-multiselect");
            if (multiselect.length === 0) {
                throw new Error("Could not found Kendo's multiselect.");
            }
            return multiselect;
        }

        function validate(): boolean {
            if (!configuration.required) {
                return true;
            }

            const multiselect = getMultiselectElement();
            const items = configuration.selectedItems();
            if (items.length > 0) {
                multiselect.removeClass("invalid");
                return true;
            } else {
                multiselect.addClass("invalid");
                return false;
            }
        }

        function clear() {
            const multiselect = getMultiselectElement();
            multiselect.removeClass("invalid");
        }

        function createSelectEvent(configuration: MultiSelectConfiguration): (e: kendo.ui.MultiSelectSelectEvent) => void {
            return (e: kendo.ui.MultiSelectSelectEvent) => {
                const item: MultiSelectItem = {
                    displayValue: e.dataItem.displayValue as string,
                    value: e.dataItem.value
                };

                configuration.selectedItems.push(item);
            };
        }

        function createDeselectEvent(configuration: MultiSelectConfiguration): (e: kendo.ui.MultiSelectDeselectEvent) => void {
            return (e: kendo.ui.MultiSelectDeselectEvent) => {
                configuration.selectedItems
                    .remove(item => item.value === e.dataItem.value);
            };
        }

        configuration.validate = () => validate();
        configuration.clear = () => clear();

        const kendoOptions: kendo.ui.MultiSelectOptions = {
            change: () => validate(),
            dataSource: new kendo.data.DataSource(
                {
                    data: configuration.source
                }),
            dataTextField: "displayValue",
            dataValueField: "value",
            deselect: createDeselectEvent(configuration),
            height: 300,
            maxSelectedItems: configuration.maxSelectedItems,
            filter: "contains",
            filtering: function (e) {
                const filter = e.filter;
                if (filter && filter.value) {
                    filter.value = filter.value.trim();
                }
            },
            placeholder: translate(configuration.placeholder),
            select: createSelectEvent(configuration)
        };

        KendoUtils.destroyComponent($element, "kendoMultiSelect");
        KendoUtils.createComponent($element, "kendoMultiSelect", kendoOptions);

        addDisposeCallback(
            element, () => {
                KendoUtils.destroyComponent($element, "kendoMultiSelect");
            });
    },

    update: (element: HTMLElement, valueAccessor: ko.Observable<MultiSelectConfiguration>) => {
        const $element = $(element);
        const configuration = valueAccessor();

        const multiSelect = KendoUtils.getComponent($element, "kendoMultiSelect") as kendo.ui.MultiSelect;
        multiSelect.enable(true);
        multiSelect.value(configuration.selectedItems());
    }
};

registerBinding("multiSelect", multiSelectBinding);
