///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/autocompleter/dataProvider/companyRelated" />
import { unpack } from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import { Provider as BaseProvider } from "Core/Medius.Core.Web/Scripts/Medius/apps/autocompleter/dataProvider/provider";

export class Provider extends BaseProvider {
    companyId: any;
    constructor(context: any, methodName: string, serviceName: string, companyId?: any, buildViewModel?: any){
        super(context, methodName, serviceName, buildViewModel);
        this.companyId = companyId;
    }

    getParameters(phrase: any, limit: any, _extras?: any) {
        const companyId = unpack(this.companyId) || this.context.getData().companyId;
        return {
            searchText: phrase,
            maxResults: limit,
            companyId: companyId || 0
        };
    }
}

export function create(context: any, methodName: any, serviceName: any, companyId?: any, buildViewModel?: any) {
    return new Provider(context, methodName, serviceName, companyId, buildViewModel);
}

export const derive = Provider;