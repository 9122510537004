/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/models/mergedConfiguration/mergedConfigurationTab"/>

import { observable, unwrap } from "knockout";

export type EntityWithContext = {
    Entity: any;
    RelatedObjects?: { [key: string]: any };
};

export class MergedConfigurationTab {
    companyContext: ko.Observable<any>;
    companyFilter: any;

    constructor(public entityWithContext: EntityWithContext) {
        this.companyContext = observable(this.getDefaultCompanyContext());
        this.companyFilter = this.getCompanyFilter();
    }
    
    getDefaultCompanyContext(): any {
        return this.entityWithContext.Entity.Company();
    }

    getCompanyFilter(): any {
        return this.entityWithContext.Entity.Company();
    }

    showCompanyContext() {
        return true;
    }
    
    canEdit() {
        const entity = this.entityWithContext.Entity;
        // can edit only already saved entities.
        return entity && unwrap(entity.Id) > 0;
    }

    getConfigurationTypeName(): string {
        throw new Error("Please define getConfigurationTypeName function in " + this.constructor.name + " prototype");
    }

    getTemplateName() {
        throw new Error("Please define getTemplateName function in " + this.constructor.name + " prototype");
    }
}