/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler"/>
import * as messageLogger from "Core/Medius.Core.Web/Scripts/lib/messageLogging/messageLogger";
import * as mode from "Core/Medius.Core.Web/Scripts/lib/development/mode";
import {translate} from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as notification from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import {logEvent} from "Core/Medius.Core.Web/Scripts/lib/metricsLogging/metricLogger";
import * as consoleLogger from "../../Medius/lib/logger";
import * as simplePresenter from "./metadata/dataTransfer/simplePresenter";
import { tryParseJSON } from "Core/Medius.Core.Web/Scripts/Medius/core/jsonParser";

function getErrorTitle(xhr: XHR): string {
    switch (xhr.status) {
        case 400:
            return translate("#Core/badRequestMessageTitle");
        case 403:
            return translate("#Core/forbiddenRequestMessageTitle");
        case 404:
            return translate("#Core/httpException404");
        default:
            return translate("#Core/serverErrorMessageTitle");
    }
}

function parseResponseAsObject(xhr: XHR): any {
    if (!xhr.responseText) {
        return null;
    }

    return tryParseJSON(xhr.responseText);
}

function getBySimplePresenter(response: any): string | null {
    if (!response.hasOwnProperty("$type")) {
        return null;
    }

    const metadata = simplePresenter
        .presentMetadata(response);

    return metadata
        ? metadata.longText
        : null;
}

function getFromMessageProperty(response: any): string {
    return response.message
        || response.Message;
}

function getSpecificErrorText(xhr: XHR): string | null {
    const response = parseResponseAsObject(xhr);
    if (response == null) {
        return null;
    }

    return getBySimplePresenter(response)
        || getFromMessageProperty(response);
}

function getErrorText(xhr: XHR): string {
    const errorText = getSpecificErrorText(xhr);
    return translate(errorText ? errorText : null)
        || translate("#Core/errorOccurred");
}

function logMissingXhrError() {
    const error = new Error("Unknown error: Argument XHR is required to format it as ErrorMessage.");

    consoleLogger.error(error);
    messageLogger.logFromError(error);
}

export interface XHR {
    readonly responseText: string;
    readonly status: number;
}

export interface ErrorMessage {
    readonly title: string;
    readonly text: string;
}

export function formatAnyError(xhr: XHR): ErrorMessage {
    if (xhr) {
        return {
            title: getErrorTitle(xhr),
            text: getErrorText(xhr)
        };
    }

    logMissingXhrError();
    return {
        title: translate("#Core/errorOccurredUnknown"),
        text: translate("#Core/errorOccurred")
    };
}

export function handleAnyError(xhr: XHR, text: string | null = null, title: string | null = null) {
    if (!mode.isProduction() && (window as any).Response /* Response is undefined in Phantom.js */) {
        if (xhr instanceof Response || xhr && (xhr as any).response && (xhr as any).response instanceof Response) {
            const wrongUsageTitle = "Incorrect error handler";
            const wrongUsageMessage = `ES6 promises should be error handled with 'Core/Medius.Core.Web/Scripts/lib/errorHandling/errorHandler' module`;
            notification.error(wrongUsageMessage, wrongUsageTitle);            
            consoleLogger.error(`[${wrongUsageTitle}] ${wrongUsageMessage}`);
            return;
        }
    }

    if (xhr && xhr.status === 0) {
        notification.error(translate("#Core/networkErrorMessageText"), translate("#Core/networkErrorMessageTitle"));
        return;
    }
    const error = formatAnyError(xhr);
    const errorText = translate(text) || error.text;
    const errorTitle = translate(title) || error.title;

    if (errorTitle === translate("#Core/serverErrorMessageTitle")) {
        logEvent("client-error", "generic-500");
    }

    notification.error(errorText, errorTitle);
}

export function formatValidationError(xhr: XHR) {
    const validationText = parseResponseAsObject(xhr) || { message: getErrorTitle(xhr)};
    return validationText.message || validationText;
}
