/// <amd-module name="Core/Medius.Core.Web/Scripts/lib/collections/splitByKeyChange"/>
/*
 * Returns: It splits passed array in places where key changes and returns array of split parts.
 * Comments: keeps order of elements inside a part (it's stable)
 *
 * Example:
 *   > splitByKeyChange([1, 1, 1, 5, 5, 3, 5, 3, 3, 2, 2], x => x)
 *   [[1, 1, 1], [5, 5], [3], [5], [3, 3], [2, 2]]
 */
export default function splitByKeyChange<T, V>(collection: T[], keySelector: (key: T) => V) {
    if (collection.length === 0) {
        return [];
    }

    const groups: T[][] = [];

    let currentGroup: T[] = [];
    let currentGroupKey = keySelector(collection[0]);

    groups.push(currentGroup);

    for (const item of collection) {
        const currentKey = keySelector(item);
        if (currentKey !== currentGroupKey) {
            currentGroupKey = currentKey;
            groups.push(currentGroup = []);
        }
        currentGroup.push(item);
    }

    return groups;
}
