///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/role/roleWithDummy"/>
import { derive } from "Core/Medius.Core.Web/Scripts/Medius/apps/autocompleter/dataProvider/companyRelated";

class Provider extends derive {
    dummyRoleName: string;
    constructor(context:any, methodName:string, serviceName:string, companyId:any, dummyRoleName:string, buildViewModel:any) {
        super(context, methodName, serviceName, companyId, buildViewModel);
        this.dummyRoleName = dummyRoleName;
    }

    getParameters(phrase: any, limit: any) {
        return {
            searchText: phrase,
            maxResults: limit,
            companyId: this.companyId(),
            dummyRoleName: this.dummyRoleName || null
        };       
    }
}

export function create(context:any, companyId:any, dummyRoleName:string, buildViewModel = true) {
    return new Provider(context, 'GetRealRolesWithDummyRole', 'lightApi/RoleAutocompletersService', companyId, dummyRoleName, buildViewModel);
}