/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/entity/factory" />
import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
const DefaultName = "default";

class AutocompleterFactory {
    Mappings:any = {
        "Medius.Core.Entities.Company": "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/company/factory",
        "Medius.Core.Entities.Role": "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/role/model",
        "Medius.Enterprise.Entities.Authorization.Configuration.AuthorizationGroup": "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/approvalGroup/model"
    };

    constructor(bindingParameters: any, context: any){
        this.Mappings[DefaultName] = "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/entity/model";
        const type = bindingParameters.type || DefaultName;
        const modulePath = this.getMapping(type);
        
        return require(modulePath).create(bindingParameters, context);
    }

    getMapping(type: any) {
        if (!isNullOrUndefined(this.Mappings[type])) {
            return this.Mappings[type];
        }
        return this.Mappings[DefaultName];
    }
}

export function create(bindingParameters: any, context: any){
    return new AutocompleterFactory(bindingParameters, context);
}