///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/models/userSettings/tabs/general" />

import * as sync from "Core/Medius.Core.Web/Scripts/Medius/core/sync";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as notification from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import * as user from "Core/Medius.Core.Web/Scripts/Medius/lib/utils/user";
import * as userEditorHelpers from "Core/Medius.Core.Web/Scripts/Medius/lib/utils/userEditorHelpers";

import contextFactory = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/context");
import userSettings = require("Core/Medius.Core.Web/Scripts/Medius/models/userSettings/userSettings");
const userType = "Medius.Core.Entities.User";

import { create } from "Core/Medius.Core.Web/Scripts/Medius/apps/userSettings/tab";

export function register(){
    create({
        name: globalization.getLabelTranslation("#Core/defaultTabName"),
        template: "userSettings-general-tpl",
        
        viewModel: userSettings,
        originalUserModel: null,

        init: function () {
            const userId = parseInt(user.getEntity().Id, 10);

            $.when(
                userEditorHelpers.loadAvailableFormats(),
                userEditorHelpers.loadAvailableLanguages(),
                userEditorHelpers.loadAvailableTimeZones(),
                this.loadUser(userId)
            ).done((formats, languages, timeZones, userModel) => {
                this.viewModel.LanguagesList(languages);
                this.viewModel.FormatsList(formats);
                this.viewModel.TimeZonesList(timeZones);
                this.viewModel.CurrentUser(userModel);
            });
        },
        
        loadUser: function (userId: any){
            return sync.load(userType, userId)
                .pipe((data: any) => {
                    this.originalUserModel = data;
                    
                    const context = contextFactory();
                    return context.create(data);
                }).fail(() => {
                    notification.error(globalization.getLabelTranslation("#Core/userNotFound"));
                });
        },
        
        onSave: function(modifications: any) {
            const originalModel = this.originalUserModel;
            const updatedModel = this.viewModel.CurrentUser();
            const originalDefaultCompanyId = originalModel?.DefaultCompany ? originalModel.DefaultCompany.Id : null;
            const updatedDefaultCompanyId = updatedModel.DefaultCompany() ? updatedModel.DefaultCompany().Id() : null;
            
            if (updatedModel.FirstName() !== originalModel.FirstName) {
                modifications.push({ Key: "GeneralFirstName", Value: { FirstName: updatedModel.FirstName() } });
            }
            
            if (updatedModel.LastName() !== originalModel.LastName) {
                modifications.push({ Key: "GeneralLastName", Value: { LastName: updatedModel.LastName() } });
            }

            let updatedEmail = updatedModel.Contact().Email() || "";
            updatedEmail = updatedEmail.trim();
            
            if (updatedEmail !== originalModel.Contact.Email) {
                modifications.push({ Key: "GeneralEmail", Value: { Email: updatedEmail } });
            }
            
            if (updatedModel.Language() !== originalModel.Language) {
                modifications.push({ Key: "GeneralLanguage", Value: { Language: updatedModel.Language() } });
            }
            
            if (updatedModel.Format() !== originalModel.Format) {
                modifications.push({ Key: "GeneralFormat", Value: { Format: updatedModel.Format() } });
            }

            if (updatedModel.TimeZone() !== originalModel.TimeZone) {
                modifications.push({ Key: "GeneralTimeZone", Value: { TimeZone: updatedModel.TimeZone() } });
            }

            if (originalDefaultCompanyId !== updatedDefaultCompanyId) {
                modifications.push({ Key: "GeneralDefaultCompany", Value: { CompanyId: updatedDefaultCompanyId } });
            }

            this.originalUserModel = null;
            this.viewModel.reset();
        }
    });
}