/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/inbox/bulk/steps/result"/>
import * as ko from "knockout";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { Step } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/bulk/step";
import { ResultProcessor } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/bulk/steps/resultProcessor";

export class Result extends Step {
    public result: ko.Observable<any>;

    constructor() {
        super(globalization.getLabelTranslation("#Core/result"),
            "Core:Medius/apps/inbox/bulk/templates/result.html");

        this.result = ko.observable();
    }

    public onOpen(modal: any) {
        ResultProcessor.process(modal.data.chosenAction.send(), modal.tasks, this);
    }
}
