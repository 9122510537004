/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/Company/General/onInactivateDialogBinding"/>
import {bindingHandlers, utils} from "knockout";
import * as $ from "jquery";
import {translate} from "Core/Medius.Core.Web/Scripts/lib/globalization";

interface BindingParameters {
    notifyOpen: ko.Observable<boolean>;
    actionResult: ko.Observable<boolean | null>;
}

const binding = {
    init(element: HTMLElement, valueAccessor: ko.Observable<BindingParameters>) {
        const notifyOpenSub = valueAccessor().notifyOpen.subscribe(() => {
            $(element).data('kendoDialog').open();
        });

        const actionResultNotifier = valueAccessor().actionResult;
        
        $(element).kendoDialog({
            visible: false,
            closable: false,
            actions: [
                {
                    text: translate("#Core/cancel"),
                    action() {
                        actionResultNotifier(false);
                        return true;
                    }
                },
                {
                    primary: true,
                    text: translate("#Core/yes"),
                    action() {
                        actionResultNotifier(true);
                        return true;
                    }
                }
            ]
        });

        utils.domNodeDisposal.addDisposeCallback(element, () => {
            notifyOpenSub.dispose();
            $(element).data("kendoDialog").destroy();
        });
    }
}

export function register() {
    bindingHandlers['on-inactivate-dialog'] = binding;
}
