/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/lexeme/helpers"/>

import { isEmptyString, isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import * as _ from "underscore";

export function removeQuotes(lexeme: string) {
    const quotes = ["'", '"'];
    
    if (isNullOrUndefined(lexeme)) {
        return lexeme;
    }
    
    if (_(quotes).contains(_.first(lexeme)) && _(quotes).contains(_.last(lexeme))) {
        lexeme = lexeme.substr(1, lexeme.length - 2);
    }
    
    return lexeme;
}

export function endsWithWhitespace(lexeme: string) {
    if (!lexeme) {
        return false;
    }
    const lastSign = lexeme.substr(lexeme.length - 1, 1);
    return lastSign === " ";
}

export function endsWithDot(lexeme: string) {
    if (!lexeme) {
        return false;
    }
    const lastSign = lexeme.substr(lexeme.length - 1, 1);
    return lastSign === ".";
}

export function removeEndingDot(lexeme: string) {
    if (endsWithDot(lexeme)) {
        return lexeme.substr(0, lexeme.length - 1);
    }
    return lexeme;
}

export function wrapWithQuotes(item: string) {
    if (isEmptyString(item)) {
        return "";
    }

    const splitted = item.split(".");

    if (splitted[0].indexOf(' ') !== -1) {
        splitted[0] = "'" + splitted[0] + "'";
    }

    return _(splitted).reduce(function (a, b) {
        if (b.indexOf(' ') !== -1) {
            b = "'" + b + "'";
        }
        return a + '.' + b;
    });
}

export function validateQuotes(lexeme: string) {
    const quotes = ["'", '"'];
    let isValid = true;
    
    //if not not all apostroph ends query is invalid for sure
    _.each(quotes, function(quote) {
        let matches:any = lexeme.match(new RegExp(quote, "g"));
        if(isNullOrUndefined(matches)) {
            matches = [];
        }
        isValid = isValid && matches.length % 2 === 0;
    });
    
    return isValid;
}