/// <amd-module name="Core/Medius.Core.Web/Scripts/lib/htmlHelper"/>
/**
 * Function with side effect - accessing document state
 */
export function isInputElementActive() {
    const activeElement = document.activeElement;
    const inputs = ["input", "select", "button", "textarea"];
    
    return activeElement && inputs.indexOf(activeElement.tagName.toLowerCase()) !== -1;
}
