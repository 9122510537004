/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/entity/dataProvider" />

import * as _ from "underscore";
import { DataProvider } from "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/default/dataProvider";
import { handleAnyError } from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import { request } from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rpc";
import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";

export class Provider extends DataProvider {
    constructor(){
        super();
    }

    callServer(searchText: any, maxResults: any, entityType: any, company: any, fields: any) {
        let operation = "SearchResultTypeByPhrases";
        const params: any = {
            phrases: searchText.split(' '),
            typeName: entityType,
            detachMode: 'Autocompleter',
            contextCompanyId: this.getId(company),
            limit: maxResults
        };

        if (!isNullOrUndefined(fields) && !_.isEmpty(fields)) {
            operation = "SearchResultByTypeAndProperties";
            params.targetProperties = fields;
        }

        return request("FullTextSearchService", operation, params).fail(function(data: any) {
            handleAnyError(data);
        });
    }

    search(searchText: any, maxResults: any, options: any) {
        return this.callServer(searchText, maxResults, options.entityType, options.company, options.fields);
    }
}

export function create(){
    return new Provider();
}