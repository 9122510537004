///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/core/errors/model"/>
import * as type from "Core/Medius.Core.Web/Scripts/Medius/core/type";
import * as rpc from "Core/Medius.Core.Web/Scripts/Medius/core/rpc";
import * as _ from "underscore";
import * as $ from "jquery";
import * as ko from "knockout";
import * as backendErrorHandler from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";

export class Errors {
    OldErrors: ko.ObservableArray<any>;
    settings: any;
    CurrentErrors: ko.ObservableArray<any>;
    IsLoading: ko.Observable<boolean>;
    errorHandler: (jqXhr: backendErrorHandler.XHR) => void;
    constructor(target:any, options:any) {
        this.settings = $.extend({
            entityViewId: null,
            entityType: null,
            tabId: null
        }, options);

        this.OldErrors = ko.observableArray([]);
        this.CurrentErrors = ko.observableArray([]);
        this.IsLoading = ko.observable(false);

        this.loadErrors();

        this.errorHandler = (jqXhr:backendErrorHandler.XHR) => {
            this.IsLoading(false);
            backendErrorHandler.handleAnyError(jqXhr);
        };
    }

    loadErrors() {
        this.IsLoading(true);
        const params = {
            entityViewId: this.settings.entityViewId,
            entityType: type.getTypeName(this.settings.entityType)
        };

        rpc.ajax("ErrorsManager", "GetErrors", {
            data: JSON.stringify(params),
            context: this
        }).done((errors) => {
            this.CurrentErrors(_.filter(errors, function (error) { return error.IsOpen; }));
            this.OldErrors(_.filter(errors, function (error) { return !error.IsOpen; }));
            this.IsLoading(false);
        }).fail(this.errorHandler);
    }
}