/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/dashboard/framework"/>

import { isEmptyString, isNotNullOrUndefined, isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import { observable, computed, observableArray } from "knockout";
import { dashboards } from "Core/Medius.Core.Web/Scripts/components/staticDashboard/staticDashboardCollection";
import * as _ from "underscore";
import { ajax } from "Core/Medius.Core.Web/Scripts/Medius/core/rpc";
import { unwrap } from "knockout";
import { getRouter } from "Core/Medius.Core.Web/Scripts/Medius/apps/spaManager/routerProvider";
import {applications} from "Core/Medius.Core.Web/Scripts/Medius/apps/spaManager/applications";
import { trigger } from "Core/Medius.Core.Web/Scripts/eventBus";
import { urlHashChanged } from "Core/Medius.Core.Web/Scripts/events/eventNames";
import { success } from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { sanitize } from "Core/Medius.Core.Web/Scripts/Medius/core/html";
import { isEmpty, isNull, isUndefined } from "underscore";
import { handleAnyError } from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import { getTypeName } from "Core/Medius.Core.Web/Scripts/Medius/core/type";
import { toJSON } from "Core/Medius.Core.Web/Scripts/Medius/lib/serialization";
import { presentMetadata } from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/simplePresenter";
import * as modalNewDashboard from "Core/Medius.Core.Web/Scripts/Medius/apps/dashboard/modals/newDashboard";
import * as modalDeleteDashboard from "Core/Medius.Core.Web/Scripts/Medius/apps/dashboard/modals/deleteDashboard";
import * as modalLibrary from "Core/Medius.Core.Web/Scripts/Medius/apps/dashboard/modals/library";
import dashboard from "Core/Medius.Core.Web/Scripts/Medius/apps/dashboard/dashboard";
import { get } from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rest";
class Framework {
    public settings: any;
    public container: JQuery;
    public currentDashboard = observable(null);
    public isOwned: ko.Computed<any>;
    public dashboards = observableArray<any>([]);
    public staticDashboards: any;
    public visibleDashboards: ko.Computed<any[]>;
    public allVisibleDashboards: ko.Computed<any[]>;
    public gadgetRepository = observable<any>({});
    public newDashboardWindow: any;
    public renameDashboardWindow = observable<any>();
    public deleteDashboardWindow: any;
    public gadgetsLibrary: any;
    public TaskContext = observable<any>(null);
    public Context = observable<any>(null);
    public taskContextIdentifier: ko.Computed<any>;
    public setCurrentDashboard: (dashboardToSet: any) => void;
    public preloadDashboards: () => any;
    public goBackTo: () => void;
    public navigateTo: (app: any, route: string) => void;
    public onOpenDashboardWithTaskContext: (taskId: number) => void;
    public onOpenDashboard: (dashboardId: number) => void;
    public navigatedFrom: NavigatedFrom;

    constructor(options: any, spaManager: any) {
        this.settings = {
            containerId: "",
            ...options
        };
        this.container = $("#" + this.settings.containerId);
        this.isOwned = computed(() => !isNullOrUndefined(this.currentDashboard()) && this.currentDashboard().isOwned);
        this.staticDashboards = dashboards;
        this.visibleDashboards = computed(() => {
            const visible: any[] = [];

            this.dashboards().forEach((localDashboard) => {
                if (localDashboard.isVisible()) {
                    visible.push(localDashboard);
                }
            });

            return visible;
        });
        this.allVisibleDashboards = computed(() => [...this.visibleDashboards(), ...this.staticDashboards()]);

        this.newDashboardWindow = modalNewDashboard.create(this);
        this.deleteDashboardWindow = modalDeleteDashboard.create(this);
        this.gadgetsLibrary = modalLibrary.create(this);

        this.taskContextIdentifier = computed({
            read: function() {
                const taskContext = this.TaskContext();
                if (isNotNullOrUndefined(taskContext)) {
                    const taskDescription = unwrap(taskContext.Description);
                    const documentIdentifier = presentMetadata(unwrap(taskContext.Document)).longText;
                    const description = `${taskDescription} - ${documentIdentifier}`;

                    return description;
                }
            },
            deferEvaluation: true,
            owner: this
        });

        this.setCurrentDashboard = (dashboardToSet: any) => {
            if (isNotNullOrUndefined(dashboardToSet)) {
                dashboardToSet.setActive();
                getRouter().navigate("Dashboard/" + dashboardToSet.id);
            }
            this.currentDashboard(dashboardToSet);
        };

        this.preloadDashboards = () => {
            if (this.dashboards().length > 0) {
                return $.Deferred().resolve();
            }

            return $.when(
                this.loadAvailableGadgets(),
                this.loadDashboards()
            );
        };

        function findApp(name: any) {
            return spaManager.Apps.find((spaApp: any) => spaApp.name && spaApp.name === name);
        }

        this.goBackTo = () => {
            if (isNullOrUndefined(this.navigatedFrom)) {
                const inboxApp = findApp(applications.inbox);
                this.navigateTo(inboxApp, "Tasks");
            }
            else {
                const app = findApp(this.navigatedFrom.applicationName);
                const decodedRoute = decodeURIComponent(this.navigatedFrom.fromRoute);
                this.navigatedFrom = null;

                this.navigateTo(app, decodedRoute);
            }
        };

        this.navigateTo = (app: any, route: string) => {
            const dashboardApp = findApp(applications.dashboard);

            const canNavigate = spaManager.ActiveApp === dashboardApp && this.TaskContext() && !spaManager.isTransitionInProgress;
            if (canNavigate) {
                spaManager.switchApp(app);
                getRouter().navigate(route);
                trigger(urlHashChanged);
            }
        };
        
        this.onOpenDashboardWithTaskContext = (taskId: number, fromApp?: string, fromRoute?: string) => {
            $.when(
                this.updateContext(taskId),
                this.preloadDashboards()
            ).done(() => {
                const isNavigetedFromFulfilled = !isEmptyString(fromApp) && !isEmptyString(fromRoute);
                if (isNavigetedFromFulfilled) {
                    this.navigatedFrom = { applicationName: fromApp, fromRoute: fromRoute };
                }

                this.presentDashboardsWithTaskContext();
            });
        };

        this.onOpenDashboard = (dashboardId: number) => {
            $.when(
                this.preloadDashboards()
            ).done(() => {
                this.TaskContext(null);
                this.Context(null);
                this.presentDashboardsNotForActionAndControl(dashboardId);
                this.navigatedFrom = null;
            });
        };
    }

    public saveDashboard(dshbrd: any) {
        const newDashboard = this.createDashboard(this, dshbrd);
        newDashboard.isVisible(true);
        this.dashboards.push(newDashboard);

        // Make newly created dashboard active
        this.showLastDashboard();

        // Display message
        success(getLabelTranslation("#Core/dashboard") + " " +
                getLabelTranslation("#Core/signApostrophe") +
                sanitize(dshbrd.Name) +
                getLabelTranslation("#Core/signApostrophe") + " " +
                getLabelTranslation("#Core/hasBeenCreated"));
    }

    public addDashboard(title: string) {
        let columnsTotal = 3;

        if (isUndefined(title)) {
            title = this.newDashboardWindow.Name();
            columnsTotal = this.newDashboardWindow.ColumnsTotal();
        }

        ajax("CoreGadgetService", "CreateDashboard", {
            data: toJSON({
                title: title,
                columnsTotal: columnsTotal,
                userTask: this.TaskContext()
            })
        }).done((newDshbrd: any) => {
            this.saveDashboard(newDshbrd);
        }).fail((xhr: any) => {
            this.errorHandler(xhr, getLabelTranslation("#Core/dashboardCreationError"));
        });
    }

    public showDashboard(dashboardId: any) {
        if (isEmpty(this.allVisibleDashboards())) {
            this.setCurrentDashboard(null);
            return;
        }
        let tabId = 0;
        let currentDashboard = _.first(this.allVisibleDashboards());
        while (tabId < this.allVisibleDashboards().length && currentDashboard.id !== dashboardId) {
            tabId++;
            currentDashboard = this.allVisibleDashboards()[tabId];
        }
        this.container.find(".nav li:eq("+(tabId)+") a").tab("show");
        this.setCurrentDashboard(currentDashboard);
    }

    public showFirstDashboard() {
        if (isEmpty(this.allVisibleDashboards())) {
            this.setCurrentDashboard(null);
            return;
        }

        this.container.find(".nav li:first a").tab("show");
        this.setCurrentDashboard(_.first(this.allVisibleDashboards()));
    }

    public showLastDashboard() {
        if (isEmpty(this.allVisibleDashboards())) {
            this.setCurrentDashboard(null);
            return;
        }

        if (this.dashboards().length <= 1) {
            this.showFirstDashboard();
        } else {
            this.container.find(".nav li:last a").tab("show");
            this.setCurrentDashboard(_.last(this.visibleDashboards()));
        }
    }

    public createDashboard(framework: any, data: any) {
        return dashboard(framework, data, {
            width: framework.container.data("width")
        });
    }

    public deleteDashboard(dashboardId: any) {
        if (isUndefined(dashboardId)) {
            dashboardId = this.deleteDashboardWindow.Id;
        }

        ajax("CoreGadgetService", "DeleteDashboard", {
            data: toJSON({
                dashboardId: dashboardId
            })
        }).done(() => {

            const dashboardToDelete = this.dashboards().find((item: any) => item.Data.Id === dashboardId);
            if (isNullOrUndefined(dashboardToDelete)) {
                return;
            }

            this.dashboards.remove(dashboardToDelete);
            dashboardToDelete.dispose();

            // Display message
            success(getLabelTranslation("#Core/dashboardDeleted"));

            // Make active one of dashboards that left
            this.showFirstDashboard();
        }).fail((xhr: any) => {
            this.errorHandler(xhr, getLabelTranslation("#Core/dashboardNotDeletedError"));
        });
    }

    public loadDashboards() {
        return get("dashboard/userDashboards").then(
            (dashboards: any) =>
            {
                // Get available width
                this.container.data("width", this.container.find(".nav-tabs").width());

                // Dispose current dashboards
                this.disposeDashboards();

                // Create dashboard instance
                const newDashboards = dashboards.map((serverDashboard: any) => this.createDashboard(this, serverDashboard));
                this.dashboards(newDashboards);
            }, (xhr: any) => {
                this.errorHandler(xhr, "#Core/dashboardGadgetsCannotBeDisplayedError");
            });
    }

    public disposeDashboards() {
        const currentDashboards = this.dashboards();

        currentDashboards.forEach((dshbrd) => {
            dshbrd.dispose();
        });

        this.dashboards([]);
    }

    public stringEndsWith(firstString: string, secondString: string) {
        return firstString.length >= secondString.length &&
               firstString.substr(firstString.length - secondString.length) === secondString;
    }

    public presentDashboardsWithTaskContext() {
        const taskContext = this.TaskContext();

        if (isNullOrUndefined(taskContext)) {
            throw new Error("task context not loaded");
        }

        const taskName = taskContext.ActivityContext.Name;

        this.dashboards().forEach((dashboard) => {
            const documentType = getTypeName(taskContext.Document.$type);
            const matchingContexts = _(dashboard.Contexts).where({
                SupportedDocument: documentType
            });
            let isDashboardAdded = false;

            matchingContexts.forEach((matchingContext) => {
                if (isDashboardAdded) {
                    return;
                }

                const activityDefinition = matchingContext.SupportedActivityDefinition;

                if (activityDefinition === "All") {
                    isDashboardAdded = true;
                    return;
                }

                if (this.stringEndsWith(taskName, activityDefinition)) {
                    isDashboardAdded = true;
                    return;
                }
            });

            dashboard.isVisible(isDashboardAdded);
        });

        this.showFirstDashboard();
    }

    public presentDashboardsNotForActionAndControl(dashboardId: any) {
        this.dashboards().forEach((dashboard: any) => {
            if (dashboard.ActionAndControlOnly) {
                dashboard.isVisible(false);
            } else {
                dashboard.isVisible(true);
            }
        });

        if (dashboardId && isNaN(dashboardId) === false && dashboardId > 0) {
            this.showDashboard(parseInt(dashboardId));
        }
        else if (dashboardId && _(this.staticDashboards()).some((sd) => sd.id === dashboardId)){
            this.showDashboard(dashboardId);
        }
        else {
            this.showFirstDashboard();
        }
    }

    public loadAvailableGadgets() {
        return get("dashboard/availableGadgets").then(
            (gadgets: any) =>
            {
                gadgets.forEach((g: any) => {
                    this.gadgetsLibrary.Gadgets.push(g);
                });
            }, (xhr: any) => {
                this.errorHandler(xhr, "#Core/dashboardGadgetsCannotBeDisplayedError");
            });
    }

    public getTask(taskId: any) {
        if (isNull(taskId) || isUndefined(taskId)) {
            return null;
        }

        return ajax("TaskManager", "GetTask", {
            data: JSON.stringify({
                taskId: taskId
            })
        }).fail((xhr: any) => {
            this.errorHandler(xhr);
        });
    }

    public getContext(ctxType: any, id: any) {
        if (isNull(ctxType) || isUndefined(ctxType) ||
            isNull(id) || isUndefined(id)) {
            return null;
        }

        return ajax("CoreGadgetService", "GetContext", {
            data: JSON.stringify({
                entityId: id,
                entityType: ctxType
            })
        }).fail(this.errorHandler.bind(this));
    }

    public updateContext(taskId: any) {
        if (isNull(taskId) || isUndefined(taskId)) {
            return null;
        }

        return this.getTask(taskId).pipe((task: any) => {
            const docId = task.Document.Id;
            const docType = getTypeName(task.Document.$type);

            this.TaskContext(task);

            return this.getContext(docType, docId);
        }).done((context: any) => {
            this.Context(context);
        }).fail((jqXhr: any) => {
            this.errorHandler(jqXhr);
        });
    }

    public errorHandler(jqXhr: any, exceptionDetail?: any) {
        handleAnyError(jqXhr, null, exceptionDetail);
    }
}

type NavigatedFrom = {
    applicationName: string;
    fromRoute: string;
};

export function create(options: any, spaManager: any) {
    return new Framework(options, spaManager);
}