/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/ImportManagement/importLogs"/>
import * as React from "react";
import { Card, CardTitle, CardBody} from "@progress/kendo-react-layout";
import {DatePresenter} from "Core/Medius.Core.Web/Scripts/shared/components/datePresenter";

interface ImportLogsProps {
    logs: {log: string; createdTimestamp: string}[];
}

const rootCssClassName = "importManagement-importLogs";

function componentClassName(strings: TemplateStringsArray) {
    return `${rootCssClassName}__${strings.toString()}`;
}

export function ImportLogs({logs}: ImportLogsProps) {
    return (<div className={rootCssClassName}>
        {logs.map((item, index) => (
            <Card key={index} type="info" className={componentClassName`card`}>
                <CardBody>
                    <CardTitle className={componentClassName`card-title`}>{item.log}</CardTitle> 
                    <p className={componentClassName`card-footer`}>
                        <DatePresenter date={new Date(item.createdTimestamp)} format="g"></DatePresenter>
                    </p>
                    
                </CardBody>
            </Card>
        ))}
    </div>);
}
