///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/documentSearch/actions/notification/steps/result"/>
import * as _ from "underscore";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { WizardStep } from "Core/Medius.Core.Web/Scripts/Medius/components/wizard/steps/wizardStep";
import * as rest from "Core/Medius.Core.Web/Scripts/Medius/core/rest";
import * as ko from "knockout";

class NotificationResultWizardStep extends WizardStep<Notification> {
    unsuccessEmailNb = ko.observable();
    successEmailNb = ko.observable();
    successEmails = ko.observableArray();
    unsuccessEmails = ko.observableArray();
    isUnsuccessSectionVisible = ko.observable(false);
    isSuccessSectionVisible = ko.observable(false);
    resultInfo = ko.observable();
    finished = ko.observable(false);
    
    constructor() {
        super(globalization.getLabelTranslation("#Core/notificationResultStep"),
        "Core:templates/DocumentSearch/Notification/Result.html");
    }

    onOpen(wizard: any) {
        wizard.isLoading(true);
        
        return rest.post("DataSearchManager", "emailNotification", { data: JSON.stringify({
                Message: wizard.data.message,
                Subject: wizard.data.subject,
                DocumentIds: wizard.documentIds,
                IsUserBased: wizard.isUserBasedDistribution || "false",
                IsRoleBased: wizard.isRoleBasedDistrubution || "false"
            })})
            .done((data: { ResultSet: any; length: number; }) => {
                this.handleResult(data);
            })
            .fail(() => {
                this.resultInfo(globalization.getLabelTranslation("#Core/emailsSentUnsuccessfully"));
            })
            .always(() => {
                wizard.isLoading(false);
                this.finished(true);
            });
    }
    
    toggleSuccessSection() {
        this.isSuccessSectionVisible(!this.isSuccessSectionVisible());
    }

    toggleUnsuccessSection() {
        this.isUnsuccessSectionVisible(!this.isUnsuccessSectionVisible());
    }
    
    handleResult(resultSet: { ResultSet: any; length: number; }) {
        this.successEmailNb(0);
        this.unsuccessEmailNb(0);
        this.successEmails([]);
        this.unsuccessEmails([]);
            _.each(resultSet.ResultSet, (item) => {
            if (item.IsSuccessfull) {
                this.successEmailNb(this.successEmailNb() + 1);
                this.successEmails.push(item);
            } else {
                this.unsuccessEmailNb(this.unsuccessEmailNb() + 1);
                this.unsuccessEmails.push(item);
            }
        });
        
        if (resultSet.length === 0) {
            this.resultInfo(globalization.getLabelTranslation("#Core/notificationNoResultInfo"));
        }
    }
}

export function create() {
    return new NotificationResultWizardStep;
}