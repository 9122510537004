/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/autocompleter/dataProvider/paymentDetails"/>

import * as _ from "underscore";
import {unwrap} from "knockout";
import * as utils from "Core/Medius.Core.Web/Scripts/Medius/mediusUtils";
import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import { PaymentDetailsFormatter } from "Core/Medius.Core.Web/Scripts/Medius/apps/metadata/resolvers/paymentDetails";

class Provider {
    public supplierId: number;
    public results: any;

    constructor(supplierId: number) {
        this.supplierId = supplierId;
        this.results = null;
    }

    public resetSupplier(supplierId: number) {
        this.supplierId = supplierId;
        this.results = null;
    }

    public createPaymentDetailsSearchModel(paymentDetails: any) {
        return {
            Id: paymentDetails.Id,
            Text: PaymentDetailsFormatter.format(paymentDetails)
        };
    }

    public fetchData() {

        const params = {
            supplierId: this.supplierId
        };
        const successCallback = (addresses: any) => {
            this.results = addresses;
        };

        return utils.ajax("GetPaymentDetails", "MasterDataService", params, successCallback);
    }   

    public filter(results: any, filterFunction: any) {
        const selectedItem = results.SelectedItemIndex && results[results.SelectedItemIndex];
        const filteredResults = _.filter(results.Results, filterFunction);
        const index = _.indexOf(filteredResults, selectedItem) || null;

        return {
            Results: filteredResults,
            SelectedItemIndex: index
        };
    }

    public getId(entity: any) {
        entity = unwrap(entity);
        if (!entity) {
            return null;
        }

        return unwrap(entity.Id) || null;
    }

    public exact(...args: any) {
        return this.search(args);
    }

    public createDummy(): any {
        return null;
    }

    public search(phrase: any) {
        let regex: RegExp;
        let results: any;

        const escape = (text: any) => {
            return text.replace(/[-[\]{}()*+?.,\\^$|#]/g, "\\$&");
        };

        const filterResults = () => {
            regex = new RegExp(`${escape(phrase).replace("*", "(.*)").toLowerCase()}`);
            results = _.filter(this.results, (item: any) => {
                return regex.test(this.createPaymentDetailsSearchModel(item).Text.toLowerCase());
            });
            return results;
        };

        if (isNullOrUndefined(this.results) && this.supplierId > 0) {
            // if the available results are not yet available, fetch them first
            return this.fetchData().pipe(filterResults);
        }

        return filterResults();
    }

    public matchItem(phrase: any) {
        return this.search(phrase);
    }
}

export function create(supplierId: number) {
    return new Provider(supplierId);
}

