/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/inbox/bulk/bulkResult"/>
import {translate} from "Core/Medius.Core.Web/Scripts/lib/globalization";
import {OutboxItem} from "Core/Medius.Core.Web/Scripts/components/outbox/outboxItem";

import * as ko from "knockout";
import { formatValueWithPlaceholders } from "../../../lib/stringFormat";

class TaskActionResult {
    public successes: OutboxItem[];
    public failures: OutboxItem[];
    public hasFailures: boolean;
    public hasSuccesses: boolean;
    public successTitle = translate("#Core/successfullyHandledTasks");
    public failTitle = translate("#Core/failedTasks");
    public successesExpanded: ko.Observable<boolean>;
    public failuresExpanded: ko.Observable<boolean>;
    public shouldShowSuccesses: ko.Computed<boolean>;
    public shouldShowFailures: ko.Computed<boolean>;

    public toggleSuccesses: () => void;
    public toggleFailures: () => void;

    constructor(successes: OutboxItem[], failures: OutboxItem[]) {
        this.successes = successes;
        this.hasSuccesses = successes.length > 0;
        this.failures = failures;
        this.hasFailures = failures.length > 0;

        this.successesExpanded = ko.observable(false);
        this.failuresExpanded = ko.observable(this.hasFailures);
        this.shouldShowSuccesses = ko.pureComputed(() => this.hasSuccesses && this.successesExpanded());
        this.shouldShowFailures = ko.pureComputed(() => this.hasFailures && this.failuresExpanded());

        this.toggleSuccesses = () => {
            this.successesExpanded(!this.successesExpanded());
        };

        this.toggleFailures = () => {
            this.failuresExpanded(!this.failuresExpanded());
        };
    }
}

export class BulkResult {
    public createFromActionSuccess: (outboxItems: OutboxItem[], bulkOperationResult: any) => TaskActionResult;
    public createFromActionFailure: (outboxItems: OutboxItem[], actionFailureDetails: any) => TaskActionResult;

    constructor() {
        this.createFromActionSuccess = (outboxItems, bulkOperationResult) => {
            const successes: OutboxItem[] = [],
                  failures: OutboxItem[] = [];

            bulkOperationResult.Results.forEach((result: any) => {
                const item = outboxItems.find((oi) => oi.taskId === result.Details.TaskId);

                if (result.Result === 0) {
                    let messages: string[];

                    if (result.ValidationResults) {
                        messages = result.ValidationResults.ValidationResults.map((validationResult: any) => {
                            const message = validationResult.ValidationMessage.Message;
                            const params = validationResult.ValidationMessage.MessageParams;

                            return formatValueWithPlaceholders(message,[params]);
                        });
                    } else {
                        messages = [result.DetailsMsg];
                    }

                    item.fail(messages);
                    failures.push(item);
                } else {
                    item.done();
                    successes.push(item);
                }
            });

            return new TaskActionResult(successes, failures);
        }

        this.createFromActionFailure = (outboxItems, actionFailureDetails) => {
            outboxItems.forEach((outboxItem) => {
                outboxItem.fail([actionFailureDetails]);
            });

            return new TaskActionResult([], outboxItems);
        }
    }
}
