/// <amd-module name="Core/Medius.Core.Web/Scripts/components/securitySettingsManager/securitySettingsManager"/>
import {observable, applyBindings} from "knockout";
import {error} from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import {translate} from "Core/Medius.Core.Web/Scripts/lib/globalization";
import {ajax} from "Core/Medius.Core.Web/Scripts/Medius/core/rpc";
import {handleAnyError} from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import * as $ from "jquery";
import {getEntity} from "Core/Medius.Core.Web/Scripts/Medius/lib/utils/user";
import {getBasePath} from "Core/Medius.Core.Web/Scripts/Medius/lib/path";

class SettingsManager {
    public userEntity: any;
    public viewModel = {
        currentPassword: observable<string>(),
        newPassword: observable<string>(),
        retypedPassword: observable<string>(),
        userName: observable<string>(),
        currentUserLogin: observable<string>(),
        isMediusFlowUsedForLogin: observable(false),
        savePassword: null as () => void,
        hide: () => { this.modal.modal('hide'); }
    };
    constructor(public targetElement: any, public modal: any) {
        this.userEntity = getEntity();
        this.viewModel.userName(`${this.userEntity.FirstName} ${this.userEntity.LastName} (${this.userEntity.UserName})`);
        this.viewModel.savePassword = () => {

            if (this.viewModel.currentPassword() === "" ||
                this.viewModel.newPassword() === "" ||
                this.viewModel.retypedPassword() === "") {
                error(translate("#Security/passwordsDataIncomplete"));
                return;
            }
            if (this.viewModel.currentPassword() === this.viewModel.newPassword()) {
                error(translate("#Security/passwordsTheSameWhileChanging"));
                return;
            }
            if(!(this.viewModel.newPassword() === this.viewModel.retypedPassword())) {
                error(translate("#Security/newPasswordsNotTheSameWhileChanging"));
                return;
            }
            ajax("LocalUserManager", "ChangePassword", {
                async: true,
                dataType: 'json',
                cache: false,
                type: 'POST',
                contentType: 'application/json; charset=utf-8',
                data: JSON.stringify({
                    oldPassword: this.viewModel.currentPassword(),
                    newPassword: this.viewModel.newPassword()
                })
            }).then(({IsSuccessful, Errors}: {IsSuccessful: boolean; Errors: string[]}) => {
                if(IsSuccessful) {
                    window.location.href = `${getBasePath()}/Account/LogOff`;
                } else {
                    error(Errors.join(', '));
                }
                
            }).fail((xhr: any) => {
                handleAnyError(xhr);
            });
        };
        targetElement.each((i: any, e: any) => {
            applyBindings(this.viewModel, e);
        });
    }
    public open() {
        const mediusFlowIdentityProviderName = "Medius Local identity store";
        const isMediusFlowUsedForLogin = this.userEntity.IdentityProviderName === mediusFlowIdentityProviderName;
        const currentUserLogin = this.userEntity.Login;

        this.viewModel.isMediusFlowUsedForLogin(isMediusFlowUsedForLogin);
        this.viewModel.currentUserLogin(currentUserLogin);
        this.modal.modal('show');
        this.viewModel.currentPassword("");
        this.viewModel.newPassword("");
        this.viewModel.retypedPassword("");
    }
}

let settingsManagerInstance: SettingsManager = null;


function getSettingsManagerInstance() {
    if (!settingsManagerInstance) {
        settingsManagerInstance = new SettingsManager($('div#changePasswordModal'), $('div#changePasswordModal'));
    }
    return settingsManagerInstance;
}

export function open() {
    getSettingsManagerInstance().open();
}

export function register() {
    $('a#change-password').click(() => {
        getSettingsManagerInstance().open();
    });
}
