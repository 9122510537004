/// <amd-module name="Core/Medius.Core.Web/Scripts/components/mention/editor/editorBase"/>
// Mobile version of this file is: mfxi\Mediusflow\Frontend\Web\mobile\src\core\components\comments\newComment\newComment.ts
// Consider changing the other one while doing changes in this one.

import * as ko from "knockout";
import { MentionSeekResult, seekMentions } from "Core/Medius.Core.Web/Scripts/components/mention/editor/mentionEditorParser";
import { IMentionEditorBaseOptions } from "Core/Medius.Core.Web/Scripts/components/mention/editor/IEditorOptions";

const jwerty = require("jwerty");

export abstract class EditorBase
{
    public readonly cursorPosition: ko.Observable<number> = ko.observable<number>(0);
    public readonly value: ko.Observable<string>;
    public readonly rows: number;
    public readonly placeholder: string;
    public readonly autocompleterLettersThreshold: number;
    
    private readonly valueSubscription: ko.Subscription;
    private readonly cursorPositionSubscription: ko.Subscription;

    public readonly suggestionsPresentationModeString: ko.Computed<string>;

    protected latestMentionSeekResult: MentionSeekResult;
    protected readonly suggestionsPresentationMode = ko.observable<SuggestionPresentationMode>(SuggestionPresentationMode.ShowNothing);

    constructor(value: ko.Observable<string>, options: IMentionEditorBaseOptions) {
        this.value = value;
        this.rows = options.rows;
        this.autocompleterLettersThreshold = options.autocompleterLettersThreshold;
        this.placeholder = options.placeholder;

        this.suggestionsPresentationModeString = ko.computed(() => {
            return SuggestionPresentationMode[this.suggestionsPresentationMode()];
        });

        this.valueSubscription = this.value.subscribe(value => {
            this.onTextChanged(value);
        });

        this.cursorPositionSubscription = this.cursorPosition.subscribe(() => {
            this.onTextChanged(this.value());
        });
    }

    public onScroll(_viewModel: EditorBase, _event: JQueryEventObject) {}

    public onKeyDown(viewModel: EditorBase, event: JQueryEventObject) {
        if (this.suggestionsPresentationMode() !== SuggestionPresentationMode.ShowResults) {
            return true;
        }

        if (jwerty.is("↑", event)) {
            return this.onArrowUp();
        }
        if (jwerty.is("↓", event)) {
            return this.onArrowDown();
        }
        if (jwerty.is("↩", event)) {
            return this.onEnter();
        }
        return true;
    }

    public dropDownItemMouseDown(_selectedSuggestion: any) {}

    public onBlur() {
        this.suggestionsPresentationMode(SuggestionPresentationMode.ShowNothing);
    }

    public onFocus() {
        this.onTextChanged(this.value());
    }

    public dispose() {
        this.valueSubscription.dispose();
        this.cursorPositionSubscription.dispose();
        this.suggestionsPresentationModeString.dispose();
    }

    protected abstract onValidMentionFound(searchTerm: string): void;

    protected abstract onMentionNotFound(): void;

    protected abstract onInvalidMentionFound(searchTerm: string): void;

    protected onArrowUp() {
        return true;
    }

    protected onArrowDown() {
        return true;
    }

    protected onEnter() {
        return true;
    }

    private onTextChanged(value: string) {
        this.latestMentionSeekResult = seekMentions(value, this.cursorPosition());

        if (this.latestMentionSeekResult.mentionFound) {
            const searchTerm = this.latestMentionSeekResult.searchTerm;

            if (searchTerm && searchTerm.length >= this.autocompleterLettersThreshold) {
                this.onValidMentionFound(searchTerm);
            } else {
                this.onInvalidMentionFound(searchTerm);
            }
        }
        else {
            this.onMentionNotFound();
        }
    }
}

export enum SuggestionPresentationMode
{
    ShowNothing,
    ShowResults,
    ShowNoResults,
    ShowFetching,
    ShowDisabled,
    ShowHint
}