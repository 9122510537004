/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/inbox/search/customFilterFieldsRegistry"/>
import * as _ from "underscore";

export interface CustomFilterFieldProvider {
    InboxFilterConditionName: string;
    TemplateName: string;
    GetVm: (field: IDocumentField, documentContext: IDocumentContext) => any;
}

export interface IDocumentField {
    Values: Array<any>;
    InboxFilterConditionName: ko.Observable<string>;
    Property: string;
}

export interface IDocumentContext {
    CompanyId: string;
    CompanyName: string;
    DocumentType: string;
}

const providers: Array<CustomFilterFieldProvider> = [];

export function register(provider: CustomFilterFieldProvider) {
    const existingProviderId = _.findIndex(providers, p => p.InboxFilterConditionName === provider.InboxFilterConditionName);

    if (existingProviderId === -1) {
        providers.push(provider);
    }
    else {
        providers[existingProviderId] = provider;
    }
}

export function getProvider(inboxFilterConditionName: string): CustomFilterFieldProvider {
    return _.find(providers, p => p.InboxFilterConditionName === inboxFilterConditionName);
}
