/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Reminders/repository"/>
import * as _ from "underscore";
import * as $ from "jquery";
import * as rpc from "Core/Medius.Core.Web/Scripts/Medius/core/rpc";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as availableRemindersCollection from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Reminders/availableRemindersCollection";

const cache: Record<string, any> = {};

function parseReminderInfo(reminderInfo: any) {
    reminderInfo.NameTranslated = globalization.getLabelTranslation(reminderInfo.Name);
    reminderInfo.ReminderCompatibleTypes = _.chain(reminderInfo.CompatibleDocumentTypes).map(function (type: any) {
        return {
            Name: globalization.getPropertyTranslation("#" + type),
            Value: type
        };
    }).sortBy(function (type: any) {
        return type.Name;
    }).value();
}

function loadAvailableReminderTypesInner(external: any) {
    let cachedValues: any;
    external = !!external;

    if (external) {
        cachedValues = cache.externalReminderTypes;
    }
    else {
        cachedValues = cache.reminderTypes;
    }

    if (cachedValues) {
        return $.Deferred().resolve(cachedValues);
    }

    return $.when(
        rpc.ajax('ReminderDataService', 'GetAvailableReminders', {
            data: JSON.stringify({
                external: external
            })
        })).pipe(function (reminders) {
            const map = _.chain(reminders).each(parseReminderInfo).groupBy(function (reminderInfo) {
                return reminderInfo.ReminderType;
            }).value();

            const availableReminders = availableRemindersCollection.create(map);

            if (external) {
                cache.externalReminderTypes = availableReminders;
            } else {
                cache.reminderTypes = availableReminders;
            }

            return availableReminders;
        });
}

export function loadAvailableReminderTypes() {
    return loadAvailableReminderTypesInner(false);
}

export function loadAvailableExternalReminderTypes() {
    return loadAvailableReminderTypesInner(true);
}

export function loadAvailableMessageTemplates() {
    if (cache.messageTemplates) {
        return $.Deferred().resolve(cache.messageTemplates);
    }

    return rpc.ajax('ReminderDataService', 'GetAvailableMessageTemplates').pipe(function (templates: any) {
        cache.messageTemplates = templates;
        return templates;
    });
}

export function invalidateReminderCache() {
    cache.reminderTypes = undefined;
}

export function invalidateExternalReminderCache() {
    cache.externalReminderTypes = undefined;
}