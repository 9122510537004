/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/models/bulk/handle"/>
import * as ko from "knockout";
import { request } from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rpc";
import { getLabelTranslation, translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { error } from "Core/Medius.Core.Web/Scripts/Medius/core/notification";

class BulkHandle {
    public tasksIds: number[];
    public activityName: string;
    public documentType: string;
    public taskDescription: string;
    public availableTaskResults: ko.ObservableArray<any> = ko.observableArray([]);
    public hasTaskResults: ko.Computed<boolean>;
    public chosenTaskResult = ko.observable<string>(null);
    public isLoading = ko.observable(false);

    constructor(tasksIds: number[], activityName: string, documentType: string, taskDescription: string) {
        this.tasksIds = tasksIds;
        this.activityName = activityName;
        this.documentType = documentType;
        this.taskDescription = taskDescription;
        this.hasTaskResults = ko.pureComputed(() => this.availableTaskResults().length > 0);
        this.loadAvailableTaskResults();
    }

    private loadAvailableTaskResults() {
        this.isLoading(true);
        return request("TaskManager", "GetTaskResults", {
            taskId: this.tasksIds[0]
        }).then((results: any[]) => {

            if (results.length > 0) {
                results.forEach(taskResult => {
                    taskResult.Name = getLabelTranslation(taskResult.Name);
                });
                this.availableTaskResults(results);
            }

        }).always(() => {
            this.selectFirstAvailableTaskResult();
            this.isLoading(false);
        });
    }

    private selectFirstAvailableTaskResult() {
        this.chosenTaskResult(this.hasTaskResults() ? this.availableTaskResults()[0].Code : "");
    }

    public save() {
        if (!this.validate()) { return false; }
        if (this.isLoading()) { return false; }

        const taskResult = this.hasTaskResults() ?
            this.availableTaskResults().find(tr => tr.Code === this.chosenTaskResult()) : null;

        if (taskResult == null) {
            error(translate("#Core/taskNotAvailable"));
            return false;
        }

        this.isLoading(true);
        return request("TaskManager", "BulkCompleteTask", {
            taskIds: this.tasksIds,
            taskResult
        }).always(() => {
            this.isLoading(false);
        });
    }

    private validate() {
        // check if chosenTaskResult() is null or undefined. Empty string is OK.
        if (this.chosenTaskResult() == null) {
            error(translate("#Core/pleaseSelectWorkflowActionToPerformOnSelectedDocuments"));
            return false;
        }
        return true;
    }
}

export const init = (tasksIds: number[], activity: string, documentType: string, taskDescription: string) =>
    new BulkHandle(tasksIds, activity, documentType, taskDescription);
