/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/task/taskHandler"/>
import {ajax} from "Core/Medius.Core.Web/Scripts/Medius/core/rpc";
import * as simplePresenter from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/simplePresenter";
import {when, Deferred} from "jquery";
import * as outbox from "Core/Medius.Core.Web/Scripts/components/outbox/outbox";
import {formatAnyError} from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import {unwrap} from "knockout";
import { startHandleMeasurement, startHandleAsyncMeasurement } from "Core/Medius.Core.Web/Scripts/Medius/performance/loggers/task";
import * as customTaskHandlerProvider from "Core/Medius.Core.Web/Scripts/Medius/core/customTaskHandlers/customTaskHandlersProvider";
import { toJSON } from "Core/Medius.Core.Web/Scripts/Medius/lib/serialization/extended";
import store = require("Core/Medius.Core.Web/Scripts/Medius/core/store/handling");

let inbox: any;


function ajaxSend(task: any, taskResult: any) {
    const data = {
        task: task.toDto(),
        taskResult: taskResult
    };
    const jsonData = toJSON(data);

    return ajax("TaskManager", "CompleteTask", {
        async: true,
        data: jsonData,
        headers: {
            "X-Json-Preserve-References": true
        }
    });
}

export function handleTask(task: any, taskResult: any, sendAction: any) {
    const taskId = task.Id();
    const documentId = simplePresenter.present(task.Document());
    const taskDisposal = task.disposeContext;
    let confirmationResultDeferred: any;

    if (taskResult && unwrap(taskResult.RequiresConfirmation)) {
        confirmationResultDeferred = task.taskConfirmationModalHandler.show();
    } else {
        confirmationResultDeferred = Deferred().resolve(true).promise();
    }

    return when(confirmationResultDeferred).pipe((isConfirmed) => {
        if (!isConfirmed) {
            return when();
        }

        const measurement = startHandleMeasurement(task);
        const measurementAsync = startHandleAsyncMeasurement(task);

        // use custom send action if provided. Use default one otherwise
        const sending = (sendAction && sendAction()) || ajaxSend(task, taskResult);
        const storing = store.setItem(taskId, task);

        // replace disposal with no-op
        task.disposeContext = function () { };
        const outboxItem = outbox.getOutboxInstance().enqueue(taskId, documentId);

        const deferred = when(
            storing,
            sending
        ).done(() => {
            store.removeItem(taskId);
            taskDisposal();
            outboxItem.done();
        }).fail((result) => {
            const message = formatAnyError(result).text;
            const  validationMessages = message.split('\n');
            outboxItem.fail(validationMessages);
        });

        if(!customTaskHandlerProvider.hasCustomizedInboxHandling(taskResult, task)) {
            inbox.onTaskHandled(taskId);
        }
        measurementAsync.stop();

        deferred.always(() => {
            measurement.stop();
        });

        return deferred;
    });
}

export function handleTaskCustomDocument(taskId: number) {
    outbox.getOutboxInstance().enqueue(taskId, null).done();
    inbox.onTaskHandled(taskId);
}

export function handleBulkOpTask(taskId: number, documentId: number) {
    return outbox.getOutboxInstance().enqueue(taskId, documentId);
}

export function registerInbox(i: any) {
    inbox = i;
}
