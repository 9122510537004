/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/metadata/resolvers/paymentDetails"/>

import {unpack} from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as registry from "Core/Medius.Core.Web/Scripts/Medius/apps/metadata/resolvers/registry";
export interface IData {
    Description: ko.Observable<string>;
    PaymentDetailId: ko.Observable<string>;
    Identifiers: ko.ObservableArray<string>;
}

export class PaymentDetailsFormatter {
    public static format(data: IData): string {
        const description: string = unpack(data.Description);
        const paymentDetailId: string | null = unpack(data.PaymentDetailId);
        const identifiers: string[] | null = unpack(data.Identifiers);

        if (isSet(paymentDetailId) && hasAny(identifiers)) {
            return format([paymentDetailId, ...identifiers]);
        } else if (isSet(paymentDetailId)) {
            return format([paymentDetailId]);
        } else if (hasAny(identifiers)) {
            return format(identifiers);
        } else {
            return description;
        }

        function isSet(value: string) {
            return value !== null && value !== undefined && value !== "";
        }

        function hasAny(value: string[]) {
            return value !== null && value !== undefined && value.length > 0;
        }

        function format(details: Array<string>): string {
            return `${description} (${details.join(", ")})`;
        }
    }
}

export function register() {
    registry.register("Medius.Enterprise.Entities.PaymentDetails", (data: any) => ({
        text: PaymentDetailsFormatter.format(data)
    }));
}
