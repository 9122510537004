/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/editors/date/localizer"/>

import { getDateFormat } from "Core/Medius.Core.Web/Scripts/Medius/lib/culture";
import * as date from "Core/Medius.Core.Web/Scripts/Medius/components/localizer/type/date";

export class LocalizerAdapter{
    patternKey: string;

    constructor(patternKey: string) {
        this.patternKey = patternKey;
    }

    fromString = (value: string) => {
        return date.fromString(value, { dateFormat: this.patternKey });
    };

    toString = (value: Date | string) => {
        return date.toString(value, { dateFormat: this.patternKey });
    };

    getDateFormat = () => {
        return getDateFormat(this.patternKey);
    };

    fromStandardString = (str?: string) => {
        /** Converts from string YYYY-MM-DD to Date UTC object */
        if (!str)
            return null;

        const date = str.split("-")
            .map(el =>  parseInt(el, 10));

        if (date.some(el => Number.isNaN(el)))
            return null;

        return new Date(
            Date.UTC(date[0], date[1] - 1, date[2]));
    };

}

export function create(patternKey: any){
    return new LocalizerAdapter(patternKey);
}