///<amd-module name = "Core/Medius.Core.Web/Scripts/components/taskHandling/fraudPopup/InvalidatePopupInconclusiveTemplate"/>
import * as React from "react";
import { ConfirmDialog, Text } from "@medius/ui-controls";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";

interface InvalidatePopupInconclusiveTemplateInterface {
    showPopup: boolean,
    onConfirm: () => void,
    onCancel: () => void,
}


export const InvalidatePopupInconclusiveTemplate = ({showPopup, onConfirm, onCancel}:InvalidatePopupInconclusiveTemplateInterface) => {
    return (<>
        <ConfirmDialog 
            isOpen={showPopup}
            cancelLabel={getLabelTranslation("#Core/cancel")}
            confirmLabel={getLabelTranslation("#Core/invalidateConfirmation")}
            data-testid="invalidatePopupInconclusive"
            onCancel={onCancel}
            onConfirm={onConfirm}>
            <Text data-testid="invalidatePopupInconclusive-title" variant="heading2">{getLabelTranslation("#Core/invalidatePopupInconclusiveTitle")}</Text>
            <Text data-testid="invalidatePopupInconclusive-text" variant="body">{getLabelTranslation("#Core/documentMayHaveUncalculatedRisks")}</Text>  
        </ConfirmDialog>
    </>);
};