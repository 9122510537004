///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/documentSearch/resultGroup/resultGroup"/>
import * as grid from "Core/Medius.Core.Web/Scripts/Medius/components/grid/default/grid";
import * as resultGroupDataSource from "Core/Medius.Core.Web/Scripts/Medius/apps/documentSearch/resultGroup/dataSource";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as rest from "Core/Medius.Core.Web/Scripts/Medius/core/rest";
import * as resultGroupParser from "Core/Medius.Core.Web/Scripts/Medius/apps/documentSearch/resultGroup/parser";
import * as window from "window";
import * as ko from "knockout";
import {getInstance as availableTasksModal} from "Core/Medius.Core.Web/Scripts/components/availableTasksModal/availableTasksModal";
import { isNotNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import * as metadataGenerator from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/generator";

class ResultGroup {
    translatedType: any = null;
    truncatedQuery: any;
    type: any;
    label: any;
    dataSource: any;
    sorting: any = {};
    grid: ko.Observable<any>;
    hasResults: ko.Computed<any>;
    openDefault: (entityId: any) => void;
    constructor(resultDto: { truncatedQuery: any; ForType: any; ColumnDefinitions: any; Entities: any; TotalEntities: any; TypeIsDocument: any; }) {
        this.truncatedQuery = resultDto.truncatedQuery;
        this.type = resultDto.ForType;
        this.label = globalization.getPropertyTranslation("#" + this.type);
        this.dataSource = resultGroupDataSource.create(this, resultDto.ColumnDefinitions, resultDto.Entities, resultDto.TotalEntities);

        
        if (isNotNullOrUndefined(this.truncatedQuery.Query.Order)) {
            this.sorting[this.truncatedQuery.Query.OrderBy] = this.truncatedQuery.Query.Order;
        }

        const gridOptions = {
            sort: true,
            paging: true,
            multiselect: true,
            pageSize: 10,
            editPageSize: true,
            sorting: this.sorting,
            onClickRow: (clickedRow: { Id: any; }) => {
                if (resultDto.TypeIsDocument) {
                    this.openDocument(clickedRow.Id);
                } else {
                    this.openDefault(clickedRow.Id);
                }
            },
            templateEngine: (ko.nativeTemplateEngine as any).instance,
            tplRow: "Core:templates/DocumentSearch/ResultGroup/RowTpl.html",
            tplHeadCell: "Core:templates/DocumentSearch/ResultGroup/HeadCellTpl.html",
            parser: resultGroupParser.create()
        };

        this.grid = ko.observable(grid.create(this.dataSource, gridOptions));

        this.hasResults = ko.computed(() => {
            return this.grid().Rows().length > 0 || this.dataSource.initialRows;
        });

        this.openDefault = (entityId) => {
            const navigationData = {
                $type: this.type,
                Id: entityId
            };

            const generator = metadataGenerator.create(navigationData.$type);
            const metadata = generator.getMetadata(navigationData);

            (window as any).location = metadata.link;
        };
    }

    openDocument = (documentId: any) => {
        const uri = ["TasksAssignments", documentId].join("/");
        this.grid().IsLoading(true);

        rest.get("InboxManager", uri).done((tasks: any[]) => {
            if (tasks.length === 1) {
                const generator = metadataGenerator.create("Medius.Core.Entities.DocumentTask");
                const metadata = generator.getMetadata({ $type: "Medius.Core.Entities.DocumentTask", Id: tasks[0].TaskId });

                (window as any).location = metadata.link;
            } else {
                availableTasksModal().show(tasks, this.openTaskFromAvailable);
            }
        })
        .always(() => {
            this.grid().IsLoading(false);
        });
    };

    openTaskFromAvailable = (taskId: any) => {
        const generator = metadataGenerator.create("Medius.Core.Entities.DocumentTask");
        const metadata = generator.getMetadata({ $type: "Medius.Core.Entities.DocumentTask", Id: taskId });

        (window as any).location = metadata.link;
    };
}
export function create(resultDto:any) {
    return new ResultGroup(resultDto);
}
