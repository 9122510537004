/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/default/text/basicEntity"/>
import { unpack } from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as typeBoundResolverFactory from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/typeBound";
import { getTypeName } from "Core/Medius.Core.Web/Scripts/Medius/core/type";


export const resolver = typeBoundResolverFactory.create({
    priority: typeBoundResolverFactory.priority.lowest + 1,
    type: "Medius.Data.Entity",
    resolve: function (data: any) {
        return { text: `${getTypeName(unpack(data.$type))}:${unpack(data.Id)}` };
    }
});
