/// <amd-module name="Core/Medius.Core.Web/Scripts/components/administrationEditor/editorTabs"/>
import {pureComputed, observableArray, computed} from "knockout";
import EditorTab from "Core/Medius.Core.Web/Scripts/components/administrationEditor/editorTab";
import { getTabsForType } from "Core/Medius.Core.Web/Scripts/AdminPages/tabTemplateProvider";
import { IEntityWithContext } from "../../AdminPages/IEntityWithContext";
import {EditorTabDto} from "./EditorTabDto";
import {request} from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rpc";

export default class EditorTabs {
    public Tabs = observableArray<EditorTab>([]);
    public EntityType: string;
    public EntityWithContext: IEntityWithContext;
    public DefaultViewModel: any;
    public dropdownTabs: ko.Computed<EditorTab[]>;
    public isDropdownTabsVisible: ko.Computed<boolean>;
    public listedTabs: ko.Computed<any>;

    constructor(entityType: string, entityWithContext: IEntityWithContext, companyContextId: number | ko.Observable<number>) {
        this.EntityType = entityType;
        this.EntityWithContext = entityWithContext;
        this.DefaultViewModel = {
            EntityWithContext: entityWithContext,
            CompanyContextId: companyContextId,
            HashFilesEntityModel: entityWithContext.Entity
        };
        this.dropdownTabs = pureComputed(() => this.Tabs().filter(tab => tab.isDropdown()));
        this.isDropdownTabsVisible = pureComputed(() => this.dropdownTabs().length > 0);
        this.listedTabs = computed(() => {
            const listedTabs = this.Tabs()
                .filter(tab => !tab.isDropdown());
            if (listedTabs.length > 0 && !listedTabs.some(tab => tab.IsDefault())) {
                listedTabs[0].IsDefault(true);
            }
            return listedTabs;
        });
    }

    public loadTabs() {
        if (!this.EntityType) {
            return null;
        }
        return request("SettingsManager", "GetTabs", {
            modelType: this.EntityType
        });
    }

    public load() {
        this.loadTabs()
            .done((tabsDto: EditorTabDto[]) => {
                const registeredTabsData = getTabsForType(this.EntityType).map((tabData: any) => tabData.Properties); 
                const tabs = tabsDto
                    .concat(registeredTabsData)
                    .map(tabDto => new EditorTab(tabDto, this.DefaultViewModel))
                    .sort((a,b) => a.Position() - b.Position());
                this.Tabs(tabs);
            });
    }

    public dispose() {
        this.listedTabs.dispose();
        this.Tabs([]);
    }
}
