/// <amd-module name="Core/Medius.Core.Web/Scripts/components/procurement/procurementReducer"/>

import { combineReducers } from "redux";
import { cartSlice } from "Core/Medius.Core.Web/Scripts/components/procurement/cart/cartSlice";
import { currentCompanySlice } from "Core/Medius.Core.Web/Scripts/components/procurement/currentCompany/currentCompanySlice";
import { checkoutModalSlice } from "Core/Medius.Core.Web/Scripts/components/procurement/checkoutModalSlice";
import { validatorConfigurationSlice } from "./validatorConfiguration/validatorConfigurationSlice";
import { resolutionConfigurationSlice } from "./resolutionConfiguration/resolutionConfigurationSlice";
import { editSlice } from "./edit/editSlice";
import { inboxSlice } from "./inboxSlice";
import { userSettingsSlice } from "./userSettingsSlice";

export const procurementReducer = combineReducers({
    currentCompany: currentCompanySlice.reducer,
    cart: cartSlice.reducer,
    checkoutModal: checkoutModalSlice.reducer,
    validatorConfiguration: validatorConfigurationSlice.reducer,
    resolutionConfiguration: resolutionConfigurationSlice.reducer,
    edit: editSlice.reducer,
    inbox: inboxSlice.reducer,
    userSettings: userSettingsSlice.reducer
});

export type ProcurementReducer = ReturnType<typeof procurementReducer>;