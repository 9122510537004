///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/core/cloudNotifications/binding/notificationBinding"/>
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as ko from "knockout";
import { create } from "Core/Medius.Core.Web/Scripts/Medius/core/cloudNotifications/notification";
const notification = create();

const notificationBinding: ko.BindingHandler = {
    init: function (element, bindingAccessor, allBindings, viewModel, bindingContext) {
        ko.renderTemplate("Core:templates/CloudNotification/CloudNotification.html", notification, {}, element, "replaceChildren");
        return { controlsDescendantBindings: true };
    }
};

export function register() {
    koUtils.registerBinding("cloudNotification", notificationBinding);
}