///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Entities.Validation.DocumentImportValidationRuleConfiguration/Editor"/>
import * as rpc from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rpc";
import * as ko from "knockout";

function arrayDiff(array1:any[], array2:any[]) {
    const result = array1.filter((el:any) => { return array2.indexOf(el) < 0; });
    return result;
}

class editor {
    editorViewModel: any;
    constructor(entityWithContext:any) {
        const model = entityWithContext.Entity;
        const documentType = model.DocumentType();

        function loadAvailableBlocks(blocks:any) {
            model.availableBlocks(blocks);
            model.availableBlocksLoaded(true);
        }

        function reloadAvailableBlocks() {
            if (model.DocumentType()) {
                model.availableBlocksLoaded(false);
                rpc.lightApi("DocumentImportValidationService", "GetAvailableValidationBlocks", { documentTypeName: model.DocumentType().Name })
                    .pipe(loadAvailableBlocks);
            }
        }

        function reloadAvailableTypes() {
            rpc.lightApi("DocumentImportValidationService", "GetAvailableDocumentTypes" /*, params*/)
                .pipe(loadAvailableDocumentTypes);
        }

        function removeNamespaceFromTypename(typename:string) {
            return typename.split(".").pop();
        }

        function loadAvailableDocumentTypes(types:any) {
            let newType:any = null;

            for (let i = 0; i < types.length; i++) {
                types[i].shortName = removeNamespaceFromTypename(types[i].Name);
            }

            model.availableDocumentTypes(types);
            if (documentType) {
                newType = types.filter((el:{Name:string}) => { return el.Name === documentType.Name(); })[0];
            }
            model.DocumentType.subscribe(reloadAvailableBlocks);
            model.DocumentType(newType);
        }

        function getBlockById(blockId:any) {
            const matchingBlocks = model.availableBlocks().filter((el:any) => { return el.BlockId === blockId; });
            return matchingBlocks ? matchingBlocks[0] : null;
        }

        function getBlockDescription(blockId:any) {
            const block = getBlockById(blockId);
            return block ? block.Description : "";
        }

        this.editorViewModel = model;
        if (!model.Blocks()) {
            model.Blocks = ko.observableArray([]);
        }
        model.availableBlocks = ko.observableArray([]);
        model.availableDocumentTypes = ko.observableArray([]);
        model.availableBlocksLoaded = ko.observable(false);
        model.unusedBlocks = ko.computed(() => {
            const availableBlockNames = model.availableBlocks().map((el:any) => { return el.BlockId; });
            const usedBlocks = model.Blocks().map((el:any) => { return el.BlockId(); });
            return arrayDiff(availableBlockNames, usedBlocks);
        });
        model.selectedBlock = model.availableBlocks()[0];
        model.addBlock = () => {
            if (model.selectedBlock) {
                const block = getBlockById(model.selectedBlock);
                const observableBlock = { BlockId: ko.observable(block.BlockId), Description: ko.observable(block.Description) };
                model.Blocks.push(observableBlock);
            }
        };
        model.removeBlock = (block:any) => {
            if (block) {
                model.Blocks.remove(block);
            }
        };
        reloadAvailableTypes();
        model.getBlockDescription = getBlockDescription;
        model.isAddingBlocksAllowed = () => {
            return model.Company() && model.DocumentType() && model.availableBlocksLoaded() && model.unusedBlocks().length > 0;
        };
        model.isAnyBlockAdded = ko.computed(() => {
            return model.Blocks() && model.Blocks().length > 0;
        });
    }
}

export = function (entityWithContext:any) {
    return new editor(entityWithContext);
};