///<amd-module name = "Core/Medius.Core.Web/Scripts/lib/cookie"/>

export function setCookie(cname:string, cvalue:string | boolean | number, expiresAt:Date) {
    const expires = "expires="+expiresAt.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getCookie(cname:string) {
    const name = cname + "=";
    const ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
        c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
        const cvalue = c.substring(name.length, c.length);

        if(!isNaN(Number(cvalue))) {
            return Number(cvalue);
        }

        if(cvalue === "true" || cvalue ==="false") {
            return cvalue === "true";
        }

        return cvalue;
    }
  }
  return null;
}

export function deleteCookie(cname:string) {
    setCookie(cname, null, new Date(1970, 1, 1));
}
  