/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/MasterDataReindex/masterDataReindexServices"/>
import {get, post} from "Core/Medius.Core.Web/Scripts/Medius/core/fetch/rest";

interface IMasterDataReindexCompanyDto {
    id: number;
    companyName: string;
    companyId: string;
    externalSystemId: string;
    parentCompanyName: string;
    parentCompanyCompanyId: string;
    isActive: boolean;
    isVirtual: boolean;
}

export interface IReindexHistoryLogDto {
    oldCompanyId: string;
    newCompanyId: string;
    timeStamp: string;
    userFullName: string;
}

export function getStatus(): Promise<{reindexInProgress: boolean; errorMessage: string}> {
    return get("masterDataReindex/status");
}

export function getCompanies(searchPhrase = ""): Promise<{results: IMasterDataReindexCompanyDto[]; total: number}> {
    return get(`masterDataReindex/company?searchPhrase=${searchPhrase}`);
}

export function getReindexHistoryLogs(): Promise<{ results: IReindexHistoryLogDto[]; total: number }> {
    return get(`masterDataReindex/reindexHistoryLog`);
}

export function reindexCompany(companyId: number, newCompanyIdentifier: string, externalSystemIdReplacementApproach: number): Promise<void> {
    return post("Backend/Rest/masterDataReindex/reindexCompany", { companyId, newCompanyIdentifier, externalSystemIdReplacementApproach });
}
