/// <amd-module name="Core/Medius.Core.Web/Scripts/bindings/counter/setup"/>
import {unwrap, observable, computed} from "knockout";
import {registerBinding} from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";

export function register() {
    const counterSetup = {
        init(element: HTMLElement, valueAccessor: ko.Observable<any>, allBindingsAccessor: unknown, viewModel: unknown, bindingContext: any) {
            const data = valueAccessor() || {};
            const name = unwrap(data.name) || "default";
            const count = observable(0);
            const usages = observable(0);

            bindingContext.counters = bindingContext.counters || {};

            if (bindingContext.counters[name]) {
                return;
            }

            bindingContext.counters[name] = {
                aquire() {
                    const currentUsages = usages();
                    usages(currentUsages + 1);
                },
                release() {
                    const currentUsages = usages();
                    usages(currentUsages - 1);
                },
                isUsed: computed(() => usages() > 0),
                getCounter: function () {
                    return count;
                },
                counterTemplate: observable(null),
                counterData: observable(null)
            };
        }
    };

    registerBinding("counterSetup", counterSetup);
}
