/// <amd-module name="Core/Medius.Core.Web/Scripts/shared/store/reduxStore"/>
import { combineReducers, Unsubscribe } from "redux";
import { sidebarGridStateReducer as sidebarGridState } from "Core/Medius.Core.Web/Scripts/components/sidebar/sidebarGridState";
import { accessesReducer as accesses } from "Core/Medius.Core.Web/Scripts/AdminPages/accessState";
import { topBarContextReducer as topBarContext } from "Core/Medius.Core.Web/Scripts/components/topBar/topBarContextState";
import { documentTypesReducer as documentTypes } from "Core/Medius.Core.Web/Scripts/components/topBar/documentTypesState";
import { appsReducer as apps } from "Core/Medius.Core.Web/Scripts/components/topBar/drawer/appState";
import { procurementReducer } from "Core/Medius.Core.Web/Scripts/components/procurement/procurementReducer";
import userReducer from "Core/Medius.Core.Web/Scripts/components/user/userContextState";
import { configureStore } from "@reduxjs/toolkit";

export const rootReducer = combineReducers({
    sidebarGridState,
    accesses,
    documentTypes,
    topBarContext,
    apps,
    procurement: procurementReducer,
    user: userReducer
});

export const store = configureStore(
    {
        reducer: rootReducer
    });

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export function observe<T>(select: (a: RootState) => T, onChange: (a: T) => void): Unsubscribe {
    let currentState: T;

    function handleChange() {
        const nextState = select(store.getState());
        if (nextState !== currentState) {
            currentState = nextState;
            onChange(currentState);
        }
    }

    const unsubscribe = store.subscribe(handleChange);
    handleChange();
    return unsubscribe;
}