///<amd-module name = "Core/Medius.Core.Web/Scripts/Models/Medius.Core.Entities.CustomFields.CustomFieldsConfigurationWithList"/>
import * as ko from "knockout";
import mapping = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/mapping/collection/instance");
import * as customListProviderFactory from "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/customfields/list/dataProvider";

export function register() {
    mapping.register("Medius.Core.Entities.CustomFields.CustomFieldsConfigurationWithList, Medius.Core.Common", function (viewModel:any) {
        viewModel.NumericCustomFields = ko.observableArray([
            viewModel.NumericCustomField1,
            viewModel.NumericCustomField2,
            viewModel.NumericCustomField3,
            viewModel.NumericCustomField4,
            viewModel.NumericCustomField5
        ]);

        viewModel.TextCustomFields = ko.observableArray([
            viewModel.TextCustomField1,
            viewModel.TextCustomField2,
            viewModel.TextCustomField3,
            viewModel.TextCustomField4,
            viewModel.TextCustomField5
        ]);

        viewModel.BooleanCustomFields = ko.observableArray([
            viewModel.BooleanCustomField1,
            viewModel.BooleanCustomField2,
            viewModel.BooleanCustomField3,
            viewModel.BooleanCustomField4,
            viewModel.BooleanCustomField5
        ]);

        viewModel.DateTimeCustomFields = ko.observableArray([
            viewModel.DateTimeCustomField1,
            viewModel.DateTimeCustomField2,
            viewModel.DateTimeCustomField3,
            viewModel.DateTimeCustomField4,
            viewModel.DateTimeCustomField5
        ]);

        viewModel.ListCustomFields = ko.observableArray([
            viewModel.ListCustomField1,
            viewModel.ListCustomField2,
            viewModel.ListCustomField3,
            viewModel.ListCustomField4,
            viewModel.ListCustomField5
        ]);

        viewModel.customListDataProvider = customListProviderFactory.create();
        
        viewModel.dispose = function () {
            viewModel = null;
        };
    });
}