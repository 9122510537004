/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/User/customActions"/>
import { AdminApplication, makeGenericSaveCall } from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/adminApp";
import { registerCustomRemoveAction, registerCustomSaveAction, registerCustomLoadAction } from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/customActionRegistry";
import * as rest from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rest";
import * as backendErrorHandler from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { success, error as notificationError } from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import { keys, without } from "underscore";
import { Editor } from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/editor";
import { IEntityWithContext } from "Core/Medius.Core.Web/Scripts/AdminPages/IEntityWithContext";
import { getBasePath } from "Core/Medius.Core.Web/Scripts/Medius/lib/path";
import EditorTabs from "Core/Medius.Core.Web/Scripts/components/administrationEditor/editorTabs";
import { isCustomAdministrationUserGetEnabled } from "Core/Medius.Core.Web/Scripts/Medius/core/featureToggle";

const userFullName = "Medius.Core.Entities.User";

function onUserRemove(adminApp: AdminApplication) {
    adminApp.IsLoading(true);

    rest.del("users", adminApp.EntityId(), null, null)
        .then(() => {
            if (adminApp.EntityId() > 0) {
                success(getLabelTranslation("#Core/entityRemovedSuccessfully"));
            }
            adminApp.ObjectBrowser.Grid().openPage(1);
            adminApp.Editor().dispose();
            adminApp.Editor(null);
            adminApp.EntityId(null);
        }, error => {
            if (error.responseText.indexOf("localizedMessage") !== -1) {
                notificationError(JSON.parse(error.responseText).map((e: any) => {return e.localizedMessage;}).join('\n'), getLabelTranslation("#Core/badRequestMessageTitle"));
            }
            else{
                backendErrorHandler.handleAnyError(error);
            }           
        }).always(() => { adminApp.IsLoading(false); });
}

function onUserSave(adminApp: AdminApplication) {
    adminApp.IsLoading(true);

    const context = adminApp.Editor().context;
    const user = adminApp.Editor().EntityWithContext.Entity;
    const types = without(keys(adminApp.Editor().EntityWithContext.RelatedObjects), "$type");

    const userDto = {
        $type: "Medius.Enterprise.DTO.UserDto, Medius.Enterprise.Common",
        User:  context.toJS(user),
        RelatedObjects: context.toJS(types.map((key: any) => {
            return {
                $type: "Medius.Enterprise.DTO.RelatedObjectDto, Medius.Enterprise.Common",
                RelatedObject: adminApp.Editor().EntityWithContext.RelatedObjects[key],
                RelatedObjectType: key
            };
        })),
        CanUpdate: adminApp.Editor().EntityWithContext.CanUpdate,
        CanDelete: adminApp.Editor().EntityWithContext.CanDelete,
    };

    if(adminApp.EntityId() > 0) {
        makeGenericSaveCall(adminApp)
        .always(adminApp.IsLoading(false));
    } else {
        rest.put("UserAdministrationServiceRestful", adminApp.EntityId(), userDto)
        .then(() => {
            success(getLabelTranslation("#Core/changesSavedSuccessfully"));
            adminApp.ObjectBrowser.Grid().openPage(1);
            adminApp.Editor().dispose();
            adminApp.Editor(null);
            adminApp.EntityId(null);
        }, error => {
            if (error.responseText.indexOf("localizedMessage") !== -1) {
                notificationError(JSON.parse(error.responseText).map((e: any) => {return e.localizedMessage;}).join('\n'), getLabelTranslation("#Core/badRequestMessageTitle"));
            }
            else{
                backendErrorHandler.handleAnyError(error);
            }           
        }).always(() => { adminApp.IsLoading(false); });   
    }
}

function onUserLoad(editor: Editor) {
    return rest.get("UserAdministrationServiceRestful", editor.AdminApp.EntityId())
    .then((entityWithContext: IEntityWithContext) => {
        editor.EntityWithContext = editor.createEntityWithContext(entityWithContext);
        editor.EntityWithContext.TabModels = {};
        editor.CanUpdate(entityWithContext.CanUpdate);
        editor.CanDelete(entityWithContext.CanDelete);
        editor.EditorTabs = new EditorTabs(userFullName, editor.EntityWithContext, editor.CompanyContextId);
        return editor.EditorTabs.load();
    })
    .fail((obj: any) => {
        if (obj.status === 403) {
            window.location.replace(getBasePath() + "Error/NotFound");
        }
    });
}

export function register() {
    registerCustomRemoveAction(userFullName, onUserRemove);
    registerCustomSaveAction(userFullName, onUserSave);
    if (isCustomAdministrationUserGetEnabled()) registerCustomLoadAction(userFullName, onUserLoad);
}
