///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/presenters/bool/model"/>
import { Basic } from "Core/Medius.Core.Web/Scripts/Medius/components/presenters/basic";
import {getLabelTranslation} from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as logger from "Core/Medius.Core.Web/Scripts/Medius/lib/logger";
import * as defaults from "Core/Medius.Core.Web/Scripts/Medius/components/presenters/bool/defaults";
import * as ko from "knockout";
import * as _ from "underscore";

function wrapTextObservable(model:any, propertyName:string) {
    const propertyValue = model[propertyName];

    switch (typeof propertyValue) {
        case "string":
            return ko.observable(propertyValue);
        case "function":
            if (ko.isObservable(propertyValue)) {
                return propertyValue;
            }
            break;
        default:
            logger.error("Unknown presenter/bool/model.wrapTextObservable model[propertyName] type for propertyName: "
                + propertyName);
            break;
    }
    throw new Error("Value not supported");
}

class Bool extends Basic {
    LocalizedTrue: ko.Computed<any>;
    LocalizedFalse: ko.Computed<any>;
    LocalizedNull: ko.Computed<any>;
    IsTrue: ko.Computed<any>;
    IsFalse: ko.Computed<boolean>;
    ActiveLabel: ko.Computed<any>;
    dispose: () => void;
    OriginalValue: any;
    constructor(binding:any) {
        binding = _({}).extend(defaults, binding);
        super(binding, undefined);

        const trueLabel = wrapTextObservable(binding, "trueLabel");
        const falseLabel = wrapTextObservable(binding, "falseLabel");
        const nullLabel = wrapTextObservable(binding, "nullLabel");

        this.LocalizedTrue = ko.computed(() => {
            const key = trueLabel();
            return getLabelTranslation(key);
        });

        this.LocalizedFalse = ko.computed(() => {
            const key = falseLabel();
            return getLabelTranslation(key);
        });
        
        this.LocalizedNull = ko.computed(() => {
            const key = nullLabel();
            return getLabelTranslation(key);
        });

        this.IsTrue = ko.computed(() => {
            return ko.unwrap(this.OriginalValue);
        });

        this.IsFalse = ko.computed(() => {
            return ko.unwrap(this.OriginalValue) === false;
        });

        this.ActiveLabel = ko.computed(() => {
            if (this.IsTrue()) {
                return this.LocalizedTrue();
            }
            if(this.IsFalse()) {
                return this.LocalizedFalse();
            }
            return this.LocalizedNull();
        });

        this.dispose = () => {
            this.ActiveLabel.dispose();
            this.IsFalse.dispose();
            this.IsTrue.dispose();
            this.LocalizedNull.dispose();
            this.LocalizedFalse.dispose();
            this.LocalizedTrue.dispose();
            super.dispose();
        };
    }
}

export function create(binding:any) {
    return new Bool(binding);
}