///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/task/RiskFactor/RiskFactorComponentDtos/riskFactorsForInvoiceDto"/>

export interface RiskFactorStatusForInvoice{
    isInconclusive: boolean;
    hasActiveRiskFactors: boolean;
    hasAnyRiskFactors: boolean;
    activeRiskFactorsCount: number;
}

export interface RiskFactorsDetailsResponseDTO{
    invoiceDetails: RiskFactorsForInvoice;
    canEdit: boolean; //user can only edit when they have accessKey: ActOnRiskFactors, and are in PostControl WF step
}

export interface RiskFactorsForInvoice{
    importTrackingId: string;
    isInconclusive: boolean;
    riskFactors: RiskFactor[]
}

export interface RiskFactor{
    code: RiskFactorCode;
    occurredAt: string;
    isHandled: boolean;
    additionalProperties: AdditionalProperties;
    handledInfo?: IHandledInfo
}

export interface IHandledInfo {
    handledByUser: {
        globalId?: string;
        userName: string;
        firstName?: string;
        lastName?: string;
    },
    reasonCode: RiskFactorReasonCode;
    handledAt: string;
}

export interface AdditionalProperties{
    newEmailAddress?: string;
    allFieldsHiddenOrEmpty?: string,
    newBankGiroValue?: string,
    newPlusGiroValue?: string,
    newIbanValue?: string,
    newBbanValue?: string
    newOrganizationNumber?: string,
    newVatIdentificationNumber?: string
}

export interface UserHandledRiskFactor{
    code: RiskFactorCode;
    occurredAt: string;
    additionalProperties: AdditionalProperties;
    handledInfo?: {
        reasonCode: string;
    }
}

export enum RiskFactorReasonCode {
    InvoiceDeletedDuplicateInvoice = "InvoiceDeletedDuplicateInvoice",
    InvoiceDeletedFraudAttempt = "InvoiceDeletedFraudAttempt",
    InvoiceDeletedOther = "InvoiceDeletedOther",
    MasterDataNotUpdated = "MasterDataNotUpdated",
    MasterDataUpdated = "MasterDataUpdated",
    MasterDataUpdatedAndConfirmed = "MasterDataUpdatedAndConfirmed",
    NoReason = "NoReason",
    SupplierChanged = "SupplierChanged",
    ValueChanged = "ValueChanged",
    ValueHasBeenConfirmedWithSupplier = "ValueHasBeenConfirmedWithSupplier",
    OriginalInvoiceInvalidatedOrEdited = "OriginalInvoiceInvalidatedOrEdited",
    NewEmailAddressAdded = "NewEmailAddressAdded",
    SupplierInvoicesProcessedUpdated = "SupplierInvoicesProcessedUpdated",
    InvoiceArchived = "InvoiceArchived",
    ValueConfirmed = "ValueConfirmed",
    NewValueAdded = "NewValueAdded"
}

export enum RiskFactorCode {
    FirstTimeInvoice = "FirstTimeInvoice",
    FromEmailAddressChanged = "FromEmailAddressChanged",
    BankAccountValidationMasterData = "BankAccountValidationMasterData",
    OrganizationNumberValidationMasterData = "OrganizationNumberValidationMasterData",
    DuplicatedInvoice = "DuplicatedInvoice",
    NewBankAccountDetected = "NewBankAccountDetected",
    NewOrganizationNumberDetected = "NewOrganizationNumberDetected",
    RandomizedExtraApproval = "RandomizedExtraApproval"
}