///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/models/mergedConfiguration/mergedConfigurationModel"/>
import * as _ from "underscore";
import * as entityObjectFactory from "Core/Medius.Core.Web/Scripts/Medius/components/configurationProperties/entityObject";
import * as baseObjectPropertyFactory from "Core/Medius.Core.Web/Scripts/Medius/components/configurationProperties/baseObject";
import * as simplePropertyFactory from "Core/Medius.Core.Web/Scripts/Medius/components/configurationProperties/simpleObject";
import * as mergedCollectionPropertyFactory from "Core/Medius.Core.Web/Scripts/Medius/components/configurationProperties/mergedCollection";
import * as entityDataProviderFactory from "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/entity/dataProvider";

class ConfigurationDataVm {
    CurrentDataViewModel: any;
    MergedData: any;
    RelatedObjects: any;
    Properties: any[];
    Metadata: any;
    Company: any;
    Context: any;
    ConfiguredEntity: any;
    
    constructor(currentDataViewModel: any, mergedData: any, relatedObjects: any, metadata: any, configuredEntity: any, company: any, context: any){
        this.CurrentDataViewModel = currentDataViewModel;
        this.MergedData = mergedData;
        this.RelatedObjects = relatedObjects;
        this.Properties = [];
        this.Metadata = metadata;
        this.Company = company;
        this.Context = context;
        this.ConfiguredEntity = configuredEntity;
    }

    addEntityProperty(propertyName: string | number, labelKey: any, dataProvider: any) {
        function extend(vm: any) {
            _.extend(vm, {
                dataProvider: (typeof(dataProvider) === "object") ? dataProvider : entityDataProviderFactory.create(),
                typeName: (typeof (dataProvider) === "string") ? dataProvider : ""
            });
        }

        return addProperty(this, propertyName, labelKey, entityObjectFactory.create, extend);
    }

    addMergedCollectionProperty(propertyName: string | number, labelKey: any, dataProvider: any, columns: any) {
        function extend(vm: any) {
            _.extend(vm, {
                dataProvider: dataProvider
            });
        }

        return addProperty(this, propertyName, labelKey, mergedCollectionPropertyFactory.create, extend, columns);
    }

    addSimpleProperty(propertyName: string | number, labelKey: any, options: any) {
        return addProperty(this, propertyName, labelKey, simplePropertyFactory.create, undefined, undefined, options);
    }

    addComponentProperty(propertyName: string | number, labelKey: any, templateName: any) {
        function extend(vm: any) {
            _.extend(vm, {
                BindingTemplate: templateName
            });
        }

        return addProperty(this, propertyName, labelKey, baseObjectPropertyFactory.create, extend);
    }

    addCustomProperty(propertyName: string | number, labelKey: any, factory: { create: (arg0: { CurrentValue: any; InheritedData: any; PropertyName: any; Metadata: any; Context: any; Company: any; ConfiguredEntity: any; PropertyLabelKey: any; Columns: any; Options: any; }) => any; }) {
        return addProperty(this, propertyName, labelKey, factory.create);
    }

    dispose() {
        this.RelatedObjects.ToBeSaved.removeAll();
        this.RelatedObjects.ToBeRemoved.removeAll();

        _.each(this.Properties, (property) => {
            if (property && property.dispose) {
                property.dispose();
            }
        });
    }

    getPropertyViewModel(propertyName: any) {
        return _.find(this.Properties, (p) => {
            return p.PropertyName === propertyName;
        });
    }

    getMetadata(propertyName: string) {
        const metadata = _.find(this.Metadata, (p) => {
            return p.PropertyName === propertyName;
        });

        if (_.isUndefined(metadata)) {
            throw new Error("Cannot find metadata for property: " + propertyName);
        }

        return metadata;
    }

    relatedObjectsSync() {
        const hasOverriden = _(this.Properties).some(function (prop) {
            return prop.IsOverriden() === true;
        });

        this.RelatedObjects.ToBeSaved.removeAll();
        this.RelatedObjects.ToBeRemoved.removeAll();

        if (hasOverriden) {
            this.RelatedObjects.ToBeSaved.push(this.CurrentDataViewModel);
        } else {
            if (this.CurrentDataViewModel.Id() > 0) {
                this.RelatedObjects.ToBeRemoved.push(this.CurrentDataViewModel);
            }
        }
    }
}

function addProperty(self: any, propertyName: string | number, labelKey: any, factory: (arg0: { CurrentValue: any; InheritedData: any; PropertyName: any; Metadata: any; Context: any; Company: any; ConfiguredEntity: any; PropertyLabelKey: any; Columns: any; Options: any; }) => any, extend?: (arg0: any) => void, columns?: any, options?: any) {
    const params = {
            CurrentValue: self.CurrentDataViewModel[propertyName],
            InheritedData: self.MergedData[propertyName],
            PropertyName: propertyName,
            Metadata: self.getMetadata(propertyName),
            Context: self.Context,
            Company: self.Company,
            ConfiguredEntity: self.ConfiguredEntity,
            PropertyLabelKey: labelKey,
            Columns: columns,
            Options: options || {}
        },
        propertyViewModel = factory(params);

    if (extend) {
        extend(propertyViewModel);
    }

    self.Properties.push(propertyViewModel);
    self.relatedObjectsSync();
    propertyViewModel.onChanged(self.relatedObjectsSync, self);

    return propertyViewModel;
}

export function create(currentDataViewModel: any, mergedData: any, relatedObjects: any, metadata: any, configuredEntity: any, company: any, context: any) {
    return new ConfigurationDataVm(currentDataViewModel, mergedData, relatedObjects, metadata, configuredEntity, company, context);
}