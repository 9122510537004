///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/inbox/task/codingSuggestionInvoiceConfidenceCell"/>

import * as React from "react";
import { IconToggleSparkleFill, IconToggleSparkleRegular } from "@medius/ui-controls";
import * as codingSuggestions from "../../accounting/codingSuggestions";

interface Props {
    workflowStep: string;
    reasonCodeName: string;
    codingSuggestionInvoiceConfidence: "High" | "Low";
}

export function CodingSuggestionInvoiceConfidenceCell({
    workflowStep,
    reasonCodeName,
    codingSuggestionInvoiceConfidence
}: Props) {
    if (codingSuggestions.supported(workflowStep, reasonCodeName))
        return ToIcon(codingSuggestionInvoiceConfidence);

    return null;
}

function ToIcon(confidenceLevel: Props["codingSuggestionInvoiceConfidence"]) {
    switch (confidenceLevel) {
        case "High":
            return <IconToggleSparkleFill color="ok" data-testid="high-invoice-confidence-inbox-icon" />;
        case "Low":
            return <IconToggleSparkleRegular color="warning" data-testid="low-invoice-confidence-inbox-icon" />;
        default:
            return null;
    }
}
