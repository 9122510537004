/// <amd-module name="Core/Medius.Core.Web/Scripts/shared/prepayment/types"/>
import { Amount } from "Core/Medius.Core.Web/Scripts/Models/amount";

export enum PaymentStatus {
    Open,
    PartiallyPaid,
    Paid,
    Cancelled,
    Issued,
    Failed,
    Expired,
    Voided
}

export interface ISupplierInvoicePaymentLine {
    readonly PaymentDate: ko.Observable<Date>;
    readonly PaymentAmount: ko.Observable<Amount>;
    readonly PaymentMethod: ko.Observable<string>;
    readonly PaymentAdditionalInformation: ko.Observable<string | null>;
    readonly PaymentIdentifier: ko.Observable<string | null>;
    readonly BatchNumber: ko.Observable<string | null>;
}

export interface ISupplierInvoice {
    readonly PaymentDate: ko.Observable<Date | null>;
    readonly PaymentAmount: ko.Observable<Amount | null>;
    readonly PaymentStatus: ko.Observable<PaymentStatus | null>;
    readonly PaymentLines: ko.ObservableArray<ISupplierInvoicePaymentLine>;
}
