///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Entities.DataSearch.UserDataSearch/userDataSearchTab"/>
import * as sync from "Core/Medius.Core.Web/Scripts/Medius/core/sync";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as notification from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import * as _ from "underscore";
import * as ko from "knockout";
import { isNullOrUndefined, isNotNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";

class UserDataSearchTab {
    UserDataSearches: any;
    User: ko.Observable<any>;
    DataSearch: ko.Observable<any>;
    constructor(entityWithContext:any) {
        const userDataSearches = entityWithContext.RelatedObjects["Medius.Core.Entities.DataSearch.UserDataSearch"].RelatedEntities;

        this.UserDataSearches = userDataSearches;
        this.User = ko.observable();
        this.DataSearch = ko.observable(entityWithContext.Entity);
    }
    removeRow(rowToRemove:any) {
        this.UserDataSearches.remove(rowToRemove);
    }
    addRow() {
        let errorMessage:string;
        if (isNullOrUndefined(this.User())) {
            errorMessage = globalization.getLabelTranslation("#Core/notifyUserMustBeSelected");
            notification.error(errorMessage);
            return;
        }
        const alreadyAddedUserDataSearch = _(this.UserDataSearches()).find((ud) => {
            return ko.utils.unwrapObservable(ud.User().Id) === ko.utils.unwrapObservable(this.User().Id);
        });
        if (isNotNullOrUndefined(alreadyAddedUserDataSearch)) {
            errorMessage = globalization.getLabelTranslation("#Core/notifySelectedUserIsAlreadyAdded");
            notification.error(errorMessage);
            return;
        }

        const userDataSearch = sync.getNew("Medius.Core.Entities.DataSearch.UserDataSearch");
        userDataSearch.User = ko.observable(this.User());
        userDataSearch.SharedDataSearch = this.DataSearch;
        this.UserDataSearches.push(userDataSearch);
    }

    dispose() {
        this.UserDataSearches(null);
        this.User(null);
        this.DataSearch(null);
    }
}

export = function(entityWithContext:any) {
    return new UserDataSearchTab(entityWithContext);
};