/// <amd-module name="Core/Medius.Core.Web/Scripts/components/taskHandling/shared/tabsCollection/tabsCollection"/>
import * as ko from "knockout";
import { FullScreenModeSide } from "Core/Medius.Core.Web/Scripts/components/taskHandling/shared/tabsCollection/fullScreenModeSide";
import { TaskTabsTemplate, TaskTabsProps, TabType } from "Core/Medius.Core.Web/Scripts/components/administrationEditor/TaskTabsTemplate";
import { isNotNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";

export class TaskTab {
    public readonly title: string;
    public readonly isActive = ko.observable(false);
    public readonly content: { counter?: ko.Observable<number> };
    public readonly counter: ko.Computed<number>;
    public readonly fullScreenSide: FullScreenModeSide.Left | FullScreenModeSide.Right;
    public readonly dataQa: string;

    public tabActivationRequested: (TaskTab: any) => void = () => { };

    constructor(title: string, content: any, fullScreenSide = FullScreenModeSide.Right, dataQa?: string) {
        this.title = title;
        this.content = content;
        this.counter = ko.pureComputed(() => this.content && this.content.counter ? this.content.counter() : null);
        this.fullScreenSide = fullScreenSide;
        this.dataQa = dataQa;
    }
}

export class TaskTabCollection {
    public readonly tabs: TaskTab[];
    public readonly activeTab = ko.observable<TaskTab>(null);
    private isFullScreenEnabledSubsciption: ko.Subscription;
    private isFullScreenEnabled: ko.Observable<boolean>;
    TaskTabs: ko.PureComputed<{ functionComponent: ({ tabs, activateTab }: TaskTabsProps) => JSX.Element; props: { tabs: any; activateTab: (tabName: any) => void; }; }>;

    constructor(isFullScreenEnabled: ko.Observable<boolean>, ...tabs: TaskTab[]) {
        this.isFullScreenEnabled = isFullScreenEnabled;

        const tabActivationRequested = (tabToActivate: any) => tabs.forEach(tab => {
            tab.isActive(tab === tabToActivate);
            if (tab.isActive()) {
                this.activeTab(tab);
            }
        });

        tabs.forEach(tab => tab.tabActivationRequested = tabActivationRequested);
        
        this.tabs = tabs;

        tabActivationRequested(this.rightSideTabs()[0]);

        this.isFullScreenEnabledSubsciption = this.isFullScreenEnabled
                                                .subscribe(() => { tabActivationRequested(this.rightSideTabs()[0]); });
        
        this.TaskTabs = ko.pureComputed(() => {
            const tabs:TabType[] = this.rightSideTabs().map((tab) => {
                return {tabName:tab.title, tabTestId:tab.dataQa, tabActive:tab.isActive(), counter:tab.counter(), poIndicatorVisible:false};
            });
        
            return ({
                functionComponent: TaskTabsTemplate,
                props: {
                    tabs: tabs,
                    activateTab: (tabName:string) => {
                        const tabToActivate = ko.utils.arrayFirst(this.rightSideTabs(), (tab) => {
                            return tab.title == tabName;
                        });
                        if(isNotNullOrUndefined(tabToActivate)){
                            tabActivationRequested(tabToActivate);
                        }
                    }
                }
            });
        });
    }

    public leftSideTab = () => this.tabs.find(tab => tab.fullScreenSide === FullScreenModeSide.Left);
    public rightSideTabs = () => this.tabs.filter(tab => !this.isFullScreenEnabled() || tab.fullScreenSide === FullScreenModeSide.Right);

    public dispose = () => {
        this.isFullScreenEnabledSubsciption.dispose();
    };
}