///<amd-module name = "Core/Medius.Core.Web/Scripts/Models/Medius.Core.Entities.Workflow.ReviewTask"/>
import mappings = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/mapping/collection/instance");
import { UserTaskExtender as base } from "Core/Medius.Core.Web/Scripts/Models/userTask";

const reviewTaskTypeName = "Medius.Core.Entities.Workflow.ReviewTask";

function ReviewTaskViewmodel(vm:any , data:any, viewmodelContext:any) {
        
    base(vm, data, viewmodelContext);
    
    vm.IsInReviewStep = true;

    vm.actionsTemplate = "Core:templates/Task/HandleReviewTask.html";
}

export function register() {
    mappings.register(reviewTaskTypeName, {
        construct: ReviewTaskViewmodel
    });
}