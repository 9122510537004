/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/administration/genericObjectBrowser"/>
import { observable, observableArray, pureComputed, unwrap, computed } from "knockout";
import { formatValueWithPlaceholders } from "Core/Medius.Core.Web/Scripts/Medius/lib/stringFormat";
import { getGridForEntity } from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/leftSideGridRegistry";
import {logEvent} from "Core/Medius.Core.Web/Scripts/lib/metricsLogging/metricLogger";
import {error} from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import {translate} from "Core/Medius.Core.Web/Scripts/lib/globalization";
import {ajax} from "Core/Medius.Core.Web/Scripts/Medius/core/rpc";
import {isNullOrUndefined, isEmptyString} from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import * as _ from "underscore";
import * as serialization from "Core/Medius.Core.Web/Scripts/Medius/lib/serialization";
import type = require("Core/Medius.Core.Web/Scripts/Medius/core/type");
import * as bulkAdminNotificationsModal from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/bulk/bulkNotifications";
import bulkAdminDeleteModal = require("Core/Medius.Core.Web/Scripts/Medius/apps/administration/bulk/deleteModal");
import * as entityGrid from "Core/Medius.Core.Web/Scripts/Medius/components/grid/entity/grid";
import * as bulkAdminWizard from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/bulk/wizard";
import { create as createDataSearchQueryBuilder } from "Core/Medius.Core.Web/Scripts/Medius/components/grid/dataSource/dataSearchQueryBuilder";

const keywordsMap: {[key: string]: any} = {
    "Medius.Core.Entities.Role": { "Owner": "is null" },
    "Medius.Core.Entities.RoleCompany": { "Role.Owner": "is null" }
};

function resolveKeywords(entityType: string) {
    return keywordsMap[entityType];
}

export default class GenericObjectBrowser {
    public AvailableDateFilters: ko.ObservableArray<DateFilter>;
    public SelectedCreatedDateFilter = observable();
    public SelectedChangeDateFilter = observable();
    public SelectedCompany = observable(null);
    public IsCompanyRelated = observable(false);
    public Grid = observable(null);
    public isGridAdditionalFilteringVisible = observable(false);
    public isBulkWizardAccessible: ko.Computed<boolean>;
    public hasAnySelections: ko.Computed<boolean>;
    public isBulkWizardVisible = observable(false);
    public isBulkDeleteVisible = observable(false);
    public isBulkNotificationsVisible = observable(false);
    public canUserDelete = observable(false);
    public bulkAdminWizard = observable<any>();
    public bulkAdminDelete = observable<any>();
    public bulkNotificationModel: any = bulkAdminNotificationsModal.create();
    public bulkNotification = observable<any>();
    public isCloneable: ko.Computed<boolean | "">;
    public toggleDeleteModal: () => void;
    public toggleNotificationsModal: () => void;
    public switchModalWindows: () => void;
    public isGridFiltered: ko.Computed<boolean>;
    public isGridShowingAll: ko.Computed<boolean>;

    private labelLastXDays = translate("#Core/lastXdays") || "";
    private SelectedCompanySub: ko.Subscription;
    private SelectedCreatedDateFilterSub: ko.Subscription;
    private SelectedChangeDateFilterSub: ko.Subscription;
    private EntityIdSub: ko.Subscription;
    private EntityNameSub: ko.Subscription;
    private entityId: ko.Computed<any>;
    private entityName: ko.Computed<string>;
    private companyContextId: ko.Observable<number>;
    private canCreate: ko.Observable<boolean>;
    private entityKey: ko.Observable<null>;

    constructor(entityId: ko.Computed<string>, 
                entityName: ko.Computed<string>, 
                entityKey: ko.Observable<any>,
                companyContextId: ko.Observable<number>,
                canCreate: ko.Observable<boolean>) {
        this.entityId = entityId;
        this.entityName = entityName;
        this.entityKey = entityKey;
        this.companyContextId = companyContextId;
        this.canCreate = canCreate;
        this.AvailableDateFilters = observableArray([
            new DateFilter("-0d", translate("#Core/today")),
            new DateFilter("-1d", translate("#Core/yesterday")),
            new DateFilter("-2d", formatValueWithPlaceholders(this.labelLastXDays, [3])),
            new DateFilter("-6d", formatValueWithPlaceholders(this.labelLastXDays, [7]))
        ]);

        this.isBulkWizardAccessible = computed(() => this.entityName() === "Medius.Core.Entities.User");
        this.hasAnySelections = pureComputed(() => {
            if (isNullOrUndefined(this.Grid()) || isNullOrUndefined(this.Grid().multiselection)) {
                return false;
            }
            return this.Grid().multiselection.selectedIds().length > 0;
        });

        this.isGridFiltered = pureComputed(() => {
            if (isNullOrUndefined(this.Grid())
                || isNullOrUndefined(this.Grid().IsDataFiltered)
                || isNullOrUndefined(this.Grid().CreatedDateFilter)
                || isNullOrUndefined(this.Grid().ChangedDateFilter)
                || isNullOrUndefined(this.Grid().CompanyContextId)) {
                return false;
            }
            if (this.hasAnySelections()) {
                return false;
            }
            return this.Grid().IsDataFiltered()
                || !isNullOrUndefined(this.Grid().CreatedDateFilter())
                || !isNullOrUndefined(this.Grid().ChangedDateFilter())
                || !isNullOrUndefined(this.Grid().CompanyContextId());
        });

        this.isGridShowingAll = pureComputed(() => {
            return !this.isGridFiltered() && !this.hasAnySelections();
        });
        
        this.SelectedCompanySub = this.SelectedCompany.subscribe((newValue: any) => {
            logEvent("filter-company", this.entityName().split(".").join("-"));
            this.companyContextId((newValue) ? newValue.Id() : null);
            if(!isNullOrUndefined(this.Grid()) && !isNullOrUndefined(this.Grid().SelectedCompany))
                this.Grid().SelectedCompany(newValue);
        });
        
        this.SelectedCreatedDateFilterSub = this.SelectedCreatedDateFilter.subscribe((newValue: any) => {
            logEvent("filter-created-date", this.entityName().split(".").join("-"));
            if(!isNullOrUndefined(this.Grid()) && !isNullOrUndefined(this.Grid().SelectedCreatedDateFilter))
                this.Grid().SelectedCreatedDateFilter(newValue);
        });

        this.SelectedChangeDateFilterSub = this.SelectedChangeDateFilter.subscribe((newValue: any) => {
            logEvent("filter-change-date", this.entityName().split(".").join("-"));
            if(!isNullOrUndefined(this.Grid()) && !isNullOrUndefined(this.Grid().SelectedChangeDateFilter))
                this.Grid().SelectedChangeDateFilter(newValue);
        });

        this.EntityIdSub = this.entityId.subscribe((idValue: string) => {
            const grid = this.Grid();

            if (!grid) {
                return;
            }

            if (isNullOrUndefined(idValue)) {
                grid.Rows.remove((item: any) => item.Id === 0);
                return;
            }

            const selectedRowId = parseInt(this.entityId(), 10);
            if (selectedRowId >= 0) {
                const row = _(grid.Rows()).find((r: any) => {
                    return unwrap(r.Id) === selectedRowId;
                });

                if (row) {
                    grid.SelectedRow(row);
                }
            }

            if (selectedRowId === 0) {
                const selectedRow = _(grid.Rows()).find((row: any) => row.Id === 0);

                if (isNullOrUndefined(selectedRow)) {
                    grid.addRow({ Id: 0 });
                }
            }
        });
        
        this.EntityNameSub = this.entityName.subscribe((name: string) => {
            if (isNullOrUndefined(name) || name === "") {
                return false;
            }

            this.resolveCompanyRelation(name);
            this.Grid(this.createGrid());
            this.checkDeleteRights(name);
        });
        
        this.isCloneable = pureComputed(() => {
            const typeName = this.entityName();
            if (isNullOrUndefined(typeName) || isEmptyString(typeName)) {
                return "";
            }
            return type.implementsInterface(typeName, "Medius.Data.IClonableEntity");
        });

        this.toggleDeleteModal = () => {
            this.isBulkDeleteVisible(!this.isBulkDeleteVisible());
        };

        this.toggleNotificationsModal = () => {
            this.isBulkNotificationsVisible(!this.isBulkNotificationsVisible());
        };

        this.switchModalWindows = () => {
            this.openBulkNotification();
        };
    }

    public checkDeleteRights(name: string) {
        this.canUserDelete(false);
        
        ajax("SettingsManager", "UserAccessControlForType", {
            data: serialization.toJSON({ typeName: name })
        }).done((incomingList: any) => {
            this.canCreate(incomingList.Create);
            this.canUserDelete(incomingList.Delete);
        }).fail(() => {
            this.canUserDelete(false);
        });
    }

    public openBulkWizard() {
        if (!this.isBulkWizardAccessible()) {
            error(translate("#Core/bulkOperationIsNotAccessible"));
            return;
        }

        const entities = this.Grid().multiselection.selectedIds();

        const wizard = bulkAdminWizard.create(entities, {
            EntityKey: this.entityKey,
            NavigationMethod: this.switchModalWindows,
            EntityFilter: this.getEntityFilter(this.Grid().DataQuery),
            ShouldApplyOnAll: this.isGridShowingAll(),
            CompanyId: this.Grid().CompanyContextId()
        });

        this.bulkAdminWizard(wizard);
    }

    public getEntityFilter(dataQuery: string) {
        const queryBuilder = createDataSearchQueryBuilder(dataQuery)
            .buildColumns()
            .buildSearchQuery()
            .buildCreatedDateFilter()
            .buildChangedDateFilter()
            .buildSortQuery();
        return queryBuilder.getQuery();
    }

    public openBulkNotification() {
        this.isBulkNotificationsVisible(true);
        this.bulkNotification(this.bulkNotificationModel);
        this.bulkNotification().open();
    }

    public openBulkDelete() {
        if (!this.isBulkWizardAccessible()) {
            error(translate("#Core/bulkOperationIsNotAccessible"));
            return;
        }

        const entities = this.Grid().multiselection.selectedIds();

        const deleteModal = bulkAdminDeleteModal(this.isBulkWizardVisible, entities, this.entityName);

        this.bulkAdminDelete(deleteModal);
        this.isBulkDeleteVisible(true);
    }

    public createGrid() {
        this.Grid(null);

        const customGridCreator = getGridForEntity(this.entityName());

        const options = {
            companyContextId: this.companyContextId,
            createdDateFilter: this.SelectedCreatedDateFilter,
            changedDateFilter: this.SelectedChangeDateFilter,
            multiselect: this.isBulkWizardAccessible,
            editPageSize: true,
            onClickRow: (clickedRow: any) => {
                this.entityId(clickedRow.Id);
            },
            initialKeywords: resolveKeywords(this.entityName())
        };

        const grid = customGridCreator ? 
                        customGridCreator(this.entityId) :
                        entityGrid.create(this.entityName(), options);

        const selectedRowId = parseInt(this.entityId(), 10);
        if (selectedRowId >= 0) {
            const row = _(grid.Rows()).find((r: any) => {
                return unwrap(r.Id) === selectedRowId;
            });

            if (row) {
                grid.SelectedRow(row);
            }
        }

        return grid;
    }

    public resolveCompanyRelation(entityName: string) {
        if (!entityName) {
            this.IsCompanyRelated(false);
            return false;
        }
        this.IsCompanyRelated(type.isCompanyRelated(entityName));
        return this.IsCompanyRelated();
    }

    public toggleGridFilter() {
        this.isGridAdditionalFilteringVisible(!this.isGridAdditionalFilteringVisible());
    }

    public dispose() {
        this.SelectedCompanySub.dispose();
        this.EntityIdSub.dispose();
        this.EntityNameSub.dispose();
        this.isBulkWizardAccessible.dispose();
        this.SelectedChangeDateFilterSub.dispose();
        this.SelectedCreatedDateFilterSub.dispose();
    }
}

class DateFilter {
    public value: string;
    public label: string;
    constructor(value: string, label: string) {
        this.value = value;
        this.label = label;
    }
}
