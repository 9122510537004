///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/document/models/HashFiles/hashFiles" />

import * as ko from "knockout";
import * as _ from "underscore";
import * as $ from "jquery";

import * as rpc from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rpc"; 
import * as dataGrid from "Core/Medius.Core.Web/Scripts/Medius/components/grid/default/grid";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { PdfViewer } from "Core/Medius.Core.Web/Scripts/components/pdfViewer/pdfViewer";
import { SendEmailContainer } from "Core/Medius.Core.Web/Scripts/components/sendEmail/sendEmailContainer";
import * as type from "Core/Medius.Core.Web/Scripts/Medius/core/type";

import contextFactory = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/context");
import { error } from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import * as events from "Core/Medius.Core.Web/Scripts/Medius/apps/document/events";
import * as observablesSync from "Core/Medius.Core.Web/Scripts/Medius/knockout/observables/synchronization";
import { postFormData } from "Core/Medius.Core.Web/Scripts/Medius/core/fetch/rest";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { handleAnyError } from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import * as hashFilesDataSource from "Core/Medius.Core.Web/Scripts/Medius/apps/document/models/HashFiles/hashFilesDataSource";

class HashFilesViewModel{
    containerElement: any;
    context: any;

    IsLoading = ko.observable(false);

    hashFilesCollection = ko.observableArray();
    hashFilesSync: any = null;
    hashFilesGrid: dataGrid.Grid;

    refreshGridHandler: ko.Computed<any>;
    pdfViewer: ko.Observable<PdfViewer>;
    sendEmailContainer: ko.Observable<SendEmailContainer>;
    entityImageVisible = ko.observable(false);
    entity: any = null;

    ToggleText: ko.Observable<any>;
    isEditable: ko.Computed<any>; 
    isEditableSubscription: ko.Subscription;
    dataFiles: any[];
    uploadInput: any;

    constructor(entityViewModel: any, containerElement: any){
        this.containerElement = $(containerElement);
        this.context = contextFactory();
        this.hashFilesGrid = this.createHashFilesGrid();
        this.uploadInput = $(containerElement).find("#hashfile-upload")["0"];
        const dropZone = this.containerElement.find("#document-display-container")["0"];
    
        dropZone.addEventListener('dragover', (e: Event) => { e.preventDefault(); });
        dropZone.addEventListener('drop', (e: any) => {
            e.preventDefault();
            this.sendFile(e.dataTransfer.files[0]);
        }); 

        this.refreshGridHandler = ko.computed(() => {
            if (this.hashFilesCollection() && this.hashFilesGrid) {
                this.hashFilesGrid.refresh();
            }
        });
    
        let companyId = null;
        if (entityViewModel && entityViewModel.Company && entityViewModel.Company() && entityViewModel.Company().Id()) {
            companyId = entityViewModel.Company().Id();
        }
        this.pdfViewer = ko.observable(new PdfViewer(undefined, undefined, [], companyId ));
        this.sendEmailContainer = ko.observable(new SendEmailContainer(this.pdfViewer(), companyId));    
        this.ToggleText = ko.observable(globalization.getLabelTranslation("#Core/hideUploadedImages"));
    
        this.isEditable = ko.computed(() => {
            if (entityViewModel.EditMode === undefined) {
                return true;
            }
            return entityViewModel.EditMode();
        });
    
        this.isEditableSubscription = this.isEditable.subscribe((isEditable: any) => {
            this.pdfViewer().isImageSelectorVisible(!isEditable);
        });
    
        this.boundEntity(entityViewModel);
        this.initUpload();
        this.registerEventHandlers();    
    }
    
    getFileType() {
        return "EDIImage";
    }

    sendFile(file: any){
        this.IsLoading(true);

        const formData = new FormData();
        formData.append("entityId", this.entity.Id());
        formData.append("entityType", this.entity.$type());
        formData.append("hashFileType", this.getFileType());
        formData.append("file", file);        

        const path = "Document/AddHashFile";
        postFormData(path, formData)                
        .then(async result => {            
            const hashFileRow = {
                Type: this.getFileType(),
                $type: 'Medius.Data.HashFile, Medius.Common',
                Added: true,
                Hash: result,
                Name: file.name
            };
            this.hashFilesCollection.push(hashFileRow);
            
            if (this.hashFilesCollection().length === 1) {
                this.refreshImages();
            } else {
                this.pdfViewer().addFile(this.context.create(hashFileRow));
            }

            this.entity.ReferencedFilesCount(this.entity.ReferencedFilesCount() + 1);
        })
        .catch(err => {
            // unpack the error message and title from JSON object 
            const errorDto = JSON.parse(err.message); 
            error(translate(errorDto.Message), translate(errorDto.Title));
        })
        .catch(error => handleAnyError(error))
        .finally(() => {
            this.IsLoading(false);
        }); 
    }

    initUpload() {
        const uploadInput = this.containerElement.find("#hashfile-upload")["0"];
        uploadInput.addEventListener('change', () => {
            this.sendFile(uploadInput.files[0]);
        }); 
    }

    rebindImages(hashFiles: any) {
        if (this.hashFilesSync) {
            this.hashFilesSync.dispose();
        }
        this.hashFilesSync = observablesSync.twoWay(hashFiles, this.hashFilesCollection);
        this.refreshImages();
    }

    boundEntity(entity: any) {
        this.entity = entity;
        const isCloned: boolean = this.entity.HashFiles.any((hf: any) => hf.Cloned());

        if (isCloned || this.entity.Id() === 0) {
            this.rebindImages(this.entity.HashFiles);
            return;
        }

        $.when(this.getHashFiles()).done((hashFilesObservable) => {
            this.rebindImages(hashFilesObservable);
        });
    }

    getHashFiles() {
        const entityId = this.entity.Id();
        const entityType = type.getTypeName(this.entity.$type());

        return rpc.request('HashFilesManager', 'GetHashFiles', {
            entityId: entityId,
            entityType: entityType
        }).pipe((files: any) => {
            const hashFiles = _.map(files, (tuple: any) => {
                return _(tuple.Item1).extend({ Name: tuple.Item2, Cloned: () => false });
            });

            this.entity.HashFiles.removeAll();
            this.entity.HashFiles(hashFiles);
            this.entity.ReferencedFilesCount(hashFiles.length);

            return this.entity.HashFiles;
        });
    }

    createHashFilesGrid() {
        const dataSource = hashFilesDataSource.create(this.hashFilesCollection);

        const options = {
            tplHead: "grid-tpl-empty",
            tplRow: "Core:templates/Common/RemovableRowTpl.html",
            tplNoRows: "hashfiles-grid-tpl-no-rows",
            tplFoot: "grid-tpl-empty",
            onRemoveRow: (row: any) => {
                this.removeHashFile(row);
            },
            onClickRow: (row: any) => {
                const index = this.getRowIndex(row);
                this.pdfViewer().setCurrentFileByIndex(index);
            },
            paging: false
        };

        return dataGrid.create(dataSource, options);
    }

    getRowIndex(row: any) {
        return _(this.hashFilesCollection()).indexOf(row);
    }

    refreshImages() {
        const hashFiles = this.hashFilesCollection();
        const currentFileHash = this.pdfViewer().getCurrentFileHash();
        const entityId = this.entity.Id();
        const entityType = this.entity.$type();
        const hashFilesViewModels = _.map(hashFiles, (data: any) => this.context.create(data));

        let companyId = null;
        if (this.entity && this.entity.Company && this.entity.Company() && this.entity.Company().Id()) {
            companyId = this.entity.Company().Id();
        }                

        const newPdfViewer = new PdfViewer(entityId, entityType, hashFilesViewModels, companyId);
        newPdfViewer.isImageSelectorVisible(!this.isEditable());
        this.pdfViewer(newPdfViewer);
        if (currentFileHash) {
            this.pdfViewer().setCurrentFileByHash(currentFileHash);
        }

        this.entityImageVisible(hashFiles.length > 0);
    }

    removeHashFile(row: any) {
        if (row.Added || !row.Cloned()) {
            this.IsLoading(true);
            $.when(this.deleteHashFile(row)).done(() => {
                this.onDeleteHashFileSuccess(row);
            }).always(() => {
                this.IsLoading(false);
            });
        } else {
            this.onDeleteHashFileSuccess(row);
        }
    }

    deleteHashFile(hashFile: any) {
        const deferred = $.Deferred();
        const hashFileDto = {
            $type: 'Medius.Data.HashFile, Medius.Common',
            Hash: hashFile.Hash,
            Type: hashFile.Type
        };
        rpc.request('HashFilesManager', 'DeleteHashFile', {
            entityId: this.entity.Id(),
            entityType: this.entity.$type(),
            hashFile: hashFileDto
        }).done(function () {
            deferred.resolve();
        });

        return deferred.promise();
    }

    deleteAddedHashFiles() {
        const removeDeferreds:any[] = [];
        const deferred = $.Deferred();

        const added = _(this.hashFilesCollection()).filter(elem => !elem.Cloned() );
        _(added).each((hashFile) => {
            removeDeferreds.push(this.deleteHashFile(hashFile));
        });
        $.when.apply(null, removeDeferreds).done(() => {
            deferred.resolve();
        });

        return deferred.promise();
    }

    onDeleteHashFileSuccess(row: any) {
        const fileIndex = this.hashFilesCollection.indexOf(row);
        this.hashFilesCollection.remove(row);

        this.pdfViewer().removeFileByIndex(fileIndex);
        this.entityImageVisible(this.hashFilesCollection().length > 0);

        this.entity.ReferencedFilesCount(this.entity.ReferencedFilesCount() - 1);
    }

    toggleGrid() {
        const grid = this.containerElement.find("div#document-hash-files-grid");
        const gridVisible = grid.is(":visible");

        if (gridVisible) {
            grid.hide();
            this.ToggleText(globalization.getLabelTranslation("#Core/showUploadedImages"));
        } else {
            grid.show();
            this.ToggleText(globalization.getLabelTranslation("#Core/hideUploadedImages"));
        }
    }

    registerEventHandlers() {
        this.containerElement.on(events.cancel, () => {
            $.when(this.deleteAddedHashFiles()).done(() => {
                this.hashFilesCollection([]);
                this.dataFiles = [];
                this.refreshImages();
            });
        });
    }

    dispose() {
        console.warn((this as any).tabShownHandler);
        this.containerElement.off(events.cancel);

        if (this.hashFilesSync) {
            this.hashFilesSync.dispose();
            this.hashFilesSync = null;
        }

        if (this.refreshGridHandler) {
            this.refreshGridHandler.dispose();
        }

        this.hashFilesCollection = null;
        this.dataFiles = [];
        this.hashFilesGrid = null;

        if (this.context) {
            this.context.dispose();
            this.context = null;
        }

        this.entity = null;

        if (this.pdfViewer) {
            this.pdfViewer().dispose();
            this.pdfViewer = null;
        }

        if (this.sendEmailContainer) {
            this.sendEmailContainer().dispose();
            this.sendEmailContainer = null;
        }

        this.containerElement = null;

        if (this.isEditableSubscription) {
            this.isEditableSubscription.dispose();
            this.isEditableSubscription = null;
        }
    }
}

export = function (entityViewModel: any, containerElement: any) {
    return new HashFilesViewModel(entityViewModel, containerElement);
};