///<amd-module name="Core/Medius.Core.Web/Scripts/components/mention/editor/mentionEditorParser"/>

// The same file exists in mobile app: mfxi\Mediusflow\Frontend\Web\mobile\src\core\components\comments\mentionEditorParser.ts
// Consider changing the other one while doing changes in this one.

export class MentionSeekResult {
    constructor(public readonly mentionFound: boolean,
        public readonly searchTerm: string,
        public readonly beginIndex: number,
        public readonly endIndex: number) {
    }
}

class ParseResult {
    constructor(public readonly parsedText: string, public readonly cursorPosition: number) {
    }
}

export function seekMentions(text: string, cursorPosition: number) {
    const stringToFindMentionsIn = text.substring(0, cursorPosition),
        lastMentionIndex = getLastMentionIndex(stringToFindMentionsIn);

    let searchTerm: string,
        endIndex: number,
        beginIndex: number,
        mentionFound: boolean;

    if (lastMentionIndex < 0) {
        beginIndex = -1;
        searchTerm = null;
        endIndex = -1;
        mentionFound = false;
    } else {
        beginIndex = lastMentionIndex;
        searchTerm = text.slice(beginIndex + 1, cursorPosition);
        endIndex = beginIndex + searchTerm.length + 1;
        mentionFound = true;
    }

    return new MentionSeekResult(mentionFound, searchTerm, beginIndex, endIndex);
}

export function parseText(originalText: string, seekResult: MentionSeekResult, identifier: string) {
    let parsedText = originalText;
    let newCursorPosition = seekResult.endIndex;

    if (seekResult.mentionFound) {
        const mentionPlaceholder = buildMentionPlaceholder(identifier);
        parsedText = stringReplaceBetween(parsedText,
            seekResult.beginIndex,
            seekResult.endIndex,
            mentionPlaceholder);

        newCursorPosition = seekResult.beginIndex + mentionPlaceholder.length;
    }

    return new ParseResult(parsedText, newCursorPosition);
}

function buildMentionPlaceholder(identifier: string) {
    return `[~${identifier}]`;
}

function stringReplaceBetween(inputValue: string,
    beginIndex: number,
    endIndex: number,
    replaceValue:
    string) {
    return inputValue.substring(0, beginIndex) + replaceValue + inputValue.substring(endIndex, inputValue.length);
}

function getLastMentionIndex(text: string) {

    // for clarity troubleshooting see disclaimer at the bottom
    const mentionRegex = /(^@|\W@)(?!.*(\[~.*\]|\n))(.*?(?=\W@|$))/;

    let currentMention: RegExpExecArray,
        matchResult: RegExpExecArray,
        currentSubstring = text,
        aggregatedIndex = 0,
        indexShift: number,
        currentMentionActualIndex: number;

    while ((matchResult = mentionRegex.exec(currentSubstring))) {
        // why 1 and 3 -> see disclaimer
        currentMention = matchResult;
        currentMentionActualIndex = matchResult.index + matchResult[1].length - 1 + aggregatedIndex;

        indexShift = matchResult.index + matchResult[1].length + matchResult[3].length;
        aggregatedIndex += indexShift;

        currentSubstring = currentSubstring.substring(indexShift);
    }

    return currentMention ? currentMentionActualIndex : -1;
}
/* Magic mention-finding regex disclaimer

for clarity whitespaces in regex are written here as undersores (' _ ') and non-regex whitespaces are added.

                / ( ^@ | \W @ ) (?! .* ( \[ ~ .* \] | \n ))( .*? (?= \W @ | $ ) )/
                        (1)                    (2)                  (3)

Reges consists of three subsequences.
    1)First one matches strings ONLY IF:
        a ) they begin whole expression and first character is @  - > ( ^@)
        OR -> |
        b) they contain @ character preceeded with not alphanumeric character ( i.e. "this@isnotmention but @thisis and (@thisisalsoamention)" ) -> \W
    2) Then we must exclude mentions followed by valid mention placeholder or a new line. I.e. "@NotMention [~because of this placeholder]". To achieve that
        we exclude (?!) all matches that contain anything (.*) which is then followed by "[~" ( [ must be escaped), then again anything
        (.*) inside, and ] character. Pattern is the same for \n chars. This is second capturing group.
    3) We end our match as soon as we occur another @ preceeded by nonalphanumeric character, or we stumble across the end of string ($). We use not greedy operator .*? to match
       as few characters as possible (otherwise newline or @ characters would fall inside .* match, i.e. from "@mention \n this is not mention"
       we need to match only "mention", not whole string).

Each of these substrings is capturing group. We are interested only in what's inside mention (not the @sign or filtering-out crap) so we need to handle group #2.
*/