/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/dashboard/gadgetDefinitions"/>

const GadgetDefinitions: { [key: string]: any} = {

};

export function getGadgetDefinition(name: string) {
    return GadgetDefinitions[name];
}

export function registerGadgetDefinition(name: string, gadget: any) {
    GadgetDefinitions[name] = gadget;
}