/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/lib/store/async"/>
import { Deferred } from "jquery";
import { uniqueId } from "underscore";
import { ILocalStorage, localStorageInstance } from "Core/Medius.Core.Web/Scripts/Medius/lib/store/local";

function getKey(prefix: string, key: string) {
    return [prefix, key].join(":");
}

class StoreStorage {
    public prefix: string;

    constructor(private store: ILocalStorage, prefix: string) {
        this.prefix = prefix;
    }

    public getItem(key: string) {
        const promise = Deferred();
        try {
            const prefixedKey = getKey(this.prefix, key);
            const data = this.store.getItem(prefixedKey);
            promise.resolve(data);
        } catch (e) {
            promise.reject(e);
        }

        return promise;
    }

    public setItem(key: string, value: any) {
        const promise = Deferred();

        try {
            const prefixedKey = getKey(this.prefix, key);
            this.store.setItem(prefixedKey, value);
            promise.resolve();
        } catch (e) {
            promise.reject(e);
        }

        return promise;
    }

    public removeItem(key: string) {
        const promise = Deferred();

        try {
            const prefixedKey = getKey(this.prefix, key);
            this.store.removeItem(prefixedKey);
            promise.resolve();
        } catch (e) {
            promise.reject(e);
        }

        return promise;
    }

    public hasItem(key: string) {
        const promise = Deferred();
        try {
            const prefixedKey = getKey(this.prefix, key);
            const exists = this.store.hasItem(prefixedKey);
            promise.resolve(exists);
        } catch (e) {
            promise.reject(e);
        }
        return promise;
    }   
}

export function create(backend: ILocalStorage, prefix: string) {
    if (!prefix) {
        prefix = uniqueId("storage");
    }

    return new StoreStorage(backend, prefix);
}

export function createLocalStore(prefix: string) {
    return create(localStorageInstance, prefix);
}

export type IStoreStorage = InstanceType<typeof StoreStorage>;