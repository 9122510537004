///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/transformers/create/utils"/>
import * as ko from "knockout";
import { isArray } from "Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/helpers";
import * as mappingResolver from "Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/mapping/resolver";

const METADATA_PROPERTY_NAME = "__metadata__";

export function finalizeViewmodel(viewmodel:any, input:any, mapping:any, context:any, properties:any[]) {
    properties = properties || [];

    let key = null;

    if (!viewmodel || isArray(viewmodel)) {
        return;
    }

    if (mapping && mapping.key) {
        key = mapping.key(input, context);
    }

    if (key && context.cache) {
        context.cache.set(key, viewmodel);
    }

    viewmodel[METADATA_PROPERTY_NAME] = {
        mapping: mapping,
        key: key,
        properties: properties
    };
}

export function wrap(data:any, parent:any) {
    if (isArray(parent)) {
        return data;
    }
    return isArray(data) ?
        ko.observableArray(data) :
        ko.observable(data);
}

export function getMetadata(vm:any) {
    return vm && vm[METADATA_PROPERTY_NAME] || {};
}

export function getMapping(vm:any, context:any) {
    return getMetadata(vm).mapping || mappingResolver.resolve(vm, context);
}

export function hasMetadata(vm:any) {
    return vm && vm[METADATA_PROPERTY_NAME] !== undefined;
}

export function hasProperty(viewmodel:any, name:string) {
    const metadata = getMetadata(viewmodel);
    const properties = metadata.properties || [];

    for (let i = 0; i < properties.length; i++) {
        if (name === properties[i]) {
            return true;
        }
    }
    return false;
}