/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/helpers/filter"/>

import { isEmptyString } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import * as _ from "underscore";

export function filterOut(lexemes: any[], word: string | number = "") {
    if (isEmptyString(word)) {
        return lexemes;
    }

    word = word.toString().toLowerCase();

    return _(lexemes).filter(function(lexeme) {
        if (isEmptyString(lexeme)) {
            return false;
        }

        lexeme = lexeme.toString().toLowerCase();
        return lexeme.indexOf(word) !== -1;
    });
}