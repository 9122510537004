///<amd-module name = "Core/Medius.Core.Web/Scripts/Models/Medius.Core.ErrorHandling.Error"/>
import mappings = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/mapping/collection/instance");
import * as ko from "knockout";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { formatValueWithPlaceholders } from "../Medius/lib/stringFormat";

export function register() {
    mappings.register("Medius.Core.ErrorHandling.Error", function (vm:any) {
        vm.translateMessage = function (messageLabel:any, arrMessageParams:any) {
            const translatedMsg = globalization.getLabelTranslation(messageLabel);
            if (!arrMessageParams || arrMessageParams.length === 0) {
                return translatedMsg;
            }
            else {
                return formatValueWithPlaceholders(translatedMsg, [vm.translateMessageParams(arrMessageParams)]);
            }
        };

        vm.translateMessageParams = function (arrLabels:Array<any>) {
            const retArr = arrLabels.map((label) => {
                const key = ko.isObservable(label) ? label() : label;
                const translation = globalization.getLabelTranslation(key);
                return translation;
            });

            return retArr;
        };

        vm.TranslatedMessage = ko.computed(function () {
            return vm.translateMessage(vm.Message(), vm.MessageParameters());
        });

        vm.TranslatedMessageHeader = ko.computed(function () {
            if (vm.MessageHeader()) {
                return vm.translateMessage(vm.MessageHeader(), vm.MessageParameters());
            }
            return "";
        });
    });
}