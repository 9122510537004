/// <amd-module name="Core/Medius.Core.Web/Scripts/shared/components/loadingPanel"/>
import * as React from "react";
import { ButtonHTMLAttributes } from "react";
import { createPortal } from "react-dom";

export const LoadingPanel = (props: ButtonHTMLAttributes<HTMLDivElement>) =>
    <div className="k-loading-mask" {...props}>
        <div className="k-loading-image"></div>
        <div className="k-loading-color"></div>
    </div>;

export function MaskingKendoReactGridLoadingPanel({gridSelector}: {gridSelector: string}) {
    const InternalPanel = <LoadingPanel style={{background: "white"}} />;
    
    const gridContent = document && document.querySelector(gridSelector);
    return gridContent ? createPortal(InternalPanel, gridContent) : InternalPanel;
}
