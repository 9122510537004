/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/moment/momentRelative"/>
import * as user from "Core/Medius.Core.Web/Scripts/Medius/lib/utils/user";
import * as culture from "Core/Medius.Core.Web/Scripts/Medius/lib/culture";
import * as moment from "moment";

export function fromNow(date: Date): string | null {
    if (date == null)
        return null;
    const momentInstance = moment(date);

    let userCultureCode = user.getLanguageCode();

    //In Moment.js Norwegian has 'nb' value whereas it is 'no' in medius 
    if (userCultureCode === "no")
        userCultureCode = "nb";
    momentInstance.locale(userCultureCode);
    if (momentInstance.locale() !== userCultureCode) {
        const baseCode = culture.baseCodeFor(userCultureCode);
        momentInstance.locale(baseCode);
        if (momentInstance.locale() !== baseCode)
            momentInstance.locale(culture.getCodeForInvariantCulture());
    }

    return momentInstance.fromNow();
}
