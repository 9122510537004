///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/drag_drop"/>
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as ko from "knockout";
import * as $ from "jquery";

let _hasBeenDropped:any, _dragged:any, _draggedIndex:any;

const drag = {
    init: function (element:any, valueAccessor:any) {
        const dragElement = $(element);

        const dragOptions = {
            helper: function () {
                return dragElement.clone().addClass("ui-dragon");
            },
            revert: true,
            revertDuration: 0,
            start: function () {
                _hasBeenDropped = false;
                _dragged = ko.utils.unwrapObservable(valueAccessor().value);
                if ($.isFunction(valueAccessor().value)) {
                    valueAccessor().value(undefined);
                    dragElement.draggable("option", "revertDuration", 500);
                } else if (valueAccessor().array) {
                    _draggedIndex = valueAccessor().array.indexOf(_dragged);
                    valueAccessor().array.splice(_draggedIndex, 1);
                }
            },
            stop: function () {
                if (!_hasBeenDropped) {
                    if ($.isFunction(valueAccessor().value)) {
                        valueAccessor().value(_dragged);
                    } else if (valueAccessor().array) {
                        valueAccessor().array.splice(_draggedIndex, 0, _dragged);
                    }
                }
            },
            cursor: 'default'
        };
        dragElement.draggable(dragOptions).disableSelection();
    },
    update: function (element:any, valueAccessor:any) {
        const dragElement = $(element);
        const disabled = !!koUtils.unpack(valueAccessor().disabled);

        dragElement.draggable("option", "disabled", disabled);
    }
};

const drop = {
    init: function (element:any, valueAccessor:any) {
        const dropElement = $(element);
        const onDrop = valueAccessor().onDrop;
        const dropOptions = {
                tolerance: 'pointer',
                hoverClass: "drop-highlight",

                drop: function (event:any, ui:any) {
                    if (onDrop) {
                        onDrop();
                    }
                    _hasBeenDropped = true;
                    valueAccessor().value(_dragged);
                    ui.draggable.draggable("option", "revertDuration", 0);
                }
            };
        dropElement.droppable(dropOptions);
    },
    update: function (element:any, valueAccessor:any) {
        const dropElement = $(element);
        const disabled = !!koUtils.unpack(valueAccessor().disabled);
        let dropEnabled = koUtils.unpack(valueAccessor().dropEnabled);

        dropElement.droppable("option", "accept", disabled ? ".nothing" : "*");
        if (dropEnabled === undefined || dropEnabled === null) {
            // Enable by default if option not set
            dropEnabled = true;
        }
        dropElement.droppable("option", "hoverClass", dropEnabled ? "drop-enabled" : "drop-disabled");
    }
};

export function register() {
    koUtils.registerBinding("drag", drag);
    koUtils.registerBinding("drop", drop);
}