/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/lib/utils/defaultCompany"/>
import { getCurrent, setDefaultCompany } from "Core/Medius.Core.Web/Scripts/Medius/lib/utils/user";
import { load } from "Core/Medius.Core.Web/Scripts/Medius/core/sync";

export function loadDefaultCompany() {
    const currentUser = getCurrent();
    if (currentUser && currentUser.DefaultCompanyId > 0) {
        load("Medius.Core.Entities.Company", currentUser.DefaultCompanyId)
            .done(function(data: any) {
                setDefaultCompany(data);
            }).fail(function() {
                throw new Error("Could not get the user default company");
            });
    }
}