/// <amd-module name="Core/Medius.Core.Web/Scripts/Accounting/TaxHandling/taxGroupHandling"/>
import * as ko from "knockout";
import * as defaultDataProviderFactory from "Core/Medius.Core.Web/Scripts/Medius/apps/accounting/autocompleter/dataProvider/taxGroup";
const unpack = require("Core/Medius.Core.Web/Scripts/Medius/knockout/utils").unpack;

export function addTaxGroupHandling(context: any, model: any, dataProviderFactory?: any, companyId?: any) {
    function getCode(modelTaxGroup: any) {
        return ko.pureComputed({
            read: () => {
                const taxGroup = unpack(modelTaxGroup);
                if (taxGroup) {
                    return unpack(taxGroup.Code);
                } else {
                    return null;
                }
            }
        });
    }

    model.TaxIndicator1Code = getCode(model.TaxIndicator1);
    model.TaxIndicator2Code = getCode(model.TaxIndicator2);

    /* TaxGroup autocompleter */
    model.dataProvider = (dataProviderFactory || defaultDataProviderFactory)
        .create(context, companyId, null, model.TaxIndicator1, model.TaxIndicator2);
}
