///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/grid/ui/columnsHandler" />

import * as _ from "underscore";
import * as $ from "jquery";
const $window = $(window);
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { unpack } from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils"; 
import { loadColumnsWidth, saveColumnWidth } from "Core/Medius.Core.Web/Scripts/Medius/components/grid/ui/userConfigRepository";
import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";

const MIN_SCREEN_STANDARD_WIDTH = 767;
const SORT_ORDER_KEY = "sort-order";

class ColumnsHandler {
    id = _.uniqueId("columnsHandler-");
    grid: any;
    $container: any;
    $colgroups: any;
    $headers: Array<any> = [];
    scrollableHandler: any;
    columnWidthStore: any;

    Columns: any[];
    WindowSize = {
        width: null as number | null,
        height: null as number | null
    };

    columnsSub: any;
    TotalRows: any;

    constructor(grid: any, $container: any, scrollableHandler: any){
        this.grid = grid;
        this.$container = $container;
        this.$colgroups = this.$container.find("*[data-grid-id='colgroup']");
        this.scrollableHandler = scrollableHandler;
        this.columnWidthStore = this.grid.options.columnWidthStore;

        this.Columns = unpack(this.grid.AllVisibleColumns);

        this.columnsSub = this.grid.AllVisibleColumns.subscribe((newColumns: any) => {
            _.defer(() => {
                this.destroyResizable();
                this.Columns = newColumns;
                this.setHeaders();
                this.initCalculations();
            });
        });
    }

    init(){
        this.loadColumnsWidthFromStore().always(() => {
            this.updateWindowSize();
            this.setHeaders();
            this.initCalculations();
        });
    }

    setHeaders() {
        this.$headers = this.$container.find("*[data-grid-id='headers'] .column-name");
    }

    initCalculations () {
        this.resetCurrentTableWidth();
        this.fitColumns(true);
        
        this.$container.find(".grid").addClass("layout-fixed"); // tweak for Chrome

        if (this.scrollableHandler) {
            this.scrollableHandler.recalculate();
        }

        _(this.$headers).each((header, i) => {
            const column = this.Columns[i];
            const $header = $(header);
            const $cell = $header.parent();

            let sort: any = null;

            if (this.isSortingOn(column)) {
                sort = () => {
                    const valueSource = (column.OrderBySource) ? column.OrderBySource : column.ValueSource;
                    const oldOrder = this.isSortingAppliedOnColumn(valueSource) ? this.grid.sortingOrder : $cell.data(SORT_ORDER_KEY) || "desc";
                    const newOrder = (oldOrder === "asc") ? "desc" : "asc";

                    $cell.siblings()
                        .removeClass("asc desc")
                        .data(SORT_ORDER_KEY, null);

                    $cell
                        .removeClass("asc desc")
                        .addClass(newOrder)
                        .data(SORT_ORDER_KEY, newOrder);

                    this.grid.sortByColumn(column, newOrder);
                };

                const valueSource = (column.OrderBySource) ? column.OrderBySource : column.ValueSource;
                if (this.isSortingAppliedOnColumn(valueSource)) {

                    $cell.siblings()
                        .removeClass("asc desc")
                        .data(SORT_ORDER_KEY, null);

                    $cell
                        .removeClass("asc desc")
                        .addClass(this.grid.sortingOrder);
                }

                $header.on("click", sort)
                    .attr("title", getLabelTranslation("#Core/clickToSortByColumn"));

                $cell.addClass("sortable");
            }

            if (this.isResizableOn(column)) {
                $cell.resizable({
                    handles: 'e',
                    minWidth: 30,
                    start: function() {
                        $header.off("click", sort);
                    },
                    resize: (e: any, ui: any) => {
                        this.onColumnResize(column, ui.size.width);
                    },
                    stop: (e: any, ui: any) => {
                        if (this.isSortingOn(column)) {
                            _.delay(function() {
                                $header.on("click", sort);
                            }, 30);
                        }
                        this.onResizeStop(e, ui);
                    }
                });
            }
        });
    }

    loadColumnsWidthFromStore() {
        const deferred = $.Deferred();

        if (!this.columnWidthStore) {
            return deferred.resolve();
        }

        return loadColumnsWidth(this.columnWidthStore, this.Columns);
    }

    isResizableOn(column: any) {
        if (!column) {
            return false;
        }
        return unpack(this.grid.options.resizable) && (_.isUndefined(column.Resizable) || column.Resizable === true);
    }

    isSortingAppliedOnColumn(column: any) {
        const convertedColumn = column.split("_").join(".");

        return this.grid.sortingColumn === convertedColumn;
    }

    isSortingOn(column: any) {
        if (!column) {
            return false;
        }
        return this.grid.IsSortingOn() && column.Sortable === true;
    }

    fitColumns(strechColumns: any) {
        this.removeBorderFromLastColumn();
        const containerW = this.getContainerWidth();

        if (strechColumns) {
            this.stretchColumns(containerW);
        }

        this.applyColumnsWidth();
        const currentTableW = this.getCurrentTableWidth();
        this.setTableWidth(currentTableW);

        if (containerW > currentTableW) {
            this.addBorderToLastColumn();
        }
    }

    updateWindowSize () {
        this.WindowSize.width = $window.width();
        this.WindowSize.height = $window.height();
    }

    getDesireTableWidth() {
        let width = 0;

        _(this.Columns).each(function (column) {
            width += column.Width;
        });

        return width;
    }

    getCurrentTableWidth() {
        let width = 0;

        _(this.Columns).each(function (column) {
            width += column.CurrentWidth;
        });

        return width;
    }
    
    resetCurrentTableWidth() {
        _(this.Columns).each(function (column) {
            column.CurrentWidth = 0;
        });
    }

    getContainerWidth() {
        let width = 0;
        let container = this.$container;

        while (width === 0 && !!container) {
            width = container.innerWidth();
            container = container.parent();
        }

        return width;
    }

    calculateWhiteSpace(argContainerW: number) {
        const containerW = argContainerW || this.getContainerWidth();
        const realTableW = this.getDesireTableWidth();
        const whiteSpaceW = containerW - realTableW;
        
        return whiteSpaceW;
    }

    applyColumnsWidth() {
        _(this.Columns).each((column, i) => {
            const width = column.CurrentWidth;

            _(this.$colgroups).each(function (colgroup) {
                $(colgroup).children().eq(i).css("width", width);
            });
        });
    }

    stretchColumns(containerWidth: number) {
        let perColumn = 0;
        let leftSpace = 0;
        let last: any;

        if (!this.Columns) return;

        const stretchableColumns = _(this.Columns).filter(function(col) {
            return isNullOrUndefined(col.Stretch) ? true : col.Stretch;
        });

        const totalColumns = stretchableColumns.length;
        
        const whiteSpace = this.calculateWhiteSpace(containerWidth);

        if (whiteSpace > 0 && totalColumns > 0) {
            perColumn = Math.round(whiteSpace / totalColumns);                
            leftSpace = whiteSpace - (perColumn * totalColumns);
        }

        _(this.Columns).each(function (col) {
            let adjustedWidth = col.Width;

            if (isNullOrUndefined(col.Stretch) || col.Stretch) {
                adjustedWidth += perColumn;
            }
            
            col.CurrentWidth = adjustedWidth;
        });
        
        if (totalColumns > 0) {
            last = _(stretchableColumns).last();
            last.CurrentWidth += leftSpace;
        }
    }

    onColumnResize(column: any, newWidth: any) {
        column.CurrentWidth = parseInt(newWidth, 10);
        saveColumnWidth(this.columnWidthStore, column, column.CurrentWidth);

        this.fitColumns(false);

        _(this.$headers).each(function (header) {
            const $cell = $(header).parent();
            if ($cell.hasClass("ui-resizable")) {
                $cell.removeAttr("style");
            }
        });
        
        if (this.scrollableHandler) {
            this.scrollableHandler.recalculate();
        }
    }

    onWindowResize() {
        if (!this.$container) return;

        if ($window.width() !== this.WindowSize.width || $window.height() !== this.WindowSize.height) {
            this.updateWindowSize();
            this.fitColumns(false);

            if (this.scrollableHandler) {
                this.scrollableHandler.recalculate();
            }
        }
    }

    onResizeStop(e: any, ui: any) {
        const $grid = this.$container.find(".grid");
        const columnShrinked = ui.size.width < ui.originalSize.width;
        const defaultSized = $grid.width() <= this.$container.width();

        if (columnShrinked) {
            if (defaultSized) {
                ui.position.left = -1;

                if (this.scrollableHandler) {
                    this.scrollableHandler.sizeScrollX = 0;
                    this.scrollableHandler.recalculate();
                }
            }
            if (this.scrollableHandler) {
                this.scrollableHandler.onDragX(e, ui);
            }
        }
    }

    setTableWidth(width: number) {
        const $grid = this.$container.find(".grid");
        const $innerContainer = $grid.parent();

        if (this.WindowSize.width > MIN_SCREEN_STANDARD_WIDTH) {
            $grid.css("width", width);
            $innerContainer.css("width", width);
        }
    }

    addBorderToLastColumn() {
        this.$container.addClass("viewport-bigger");
    }

    removeBorderFromLastColumn() {
        this.$container.removeClass("viewport-bigger");
    }

    destroyResizable() {
        _(this.$headers).each(function (header) {
            const $header = $(header);
            const $cell = $header.parent();

            if ($cell.hasClass("ui-resizable")) {
                $cell.resizable('destroy');
                $cell.removeAttr("style");
            }

            $header.off("click");
        });
    }

    dispose() {
        this.columnsSub.dispose();
        this.columnsSub = null;
        this.destroyResizable();

        this.grid = null;
        this.Columns = null;
        this.TotalRows = null;
        this.WindowSize = null;

        this.$colgroups = null;
        this.$headers = null;
        this.$container = null;
        this.scrollableHandler = null;
        this.columnWidthStore = null;
    }
}


export function create(grid: any, $container: any, scrollableHandler: any) {
    return new ColumnsHandler(grid, $container, scrollableHandler);
}