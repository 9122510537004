/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/communication/remediumApi"/>
import {get as platformGet, post as platformPost, put as platformPut, del as platformDel, Data} from "Core/Medius.Core.Web/Scripts/Medius/core/communication/platform";

export async function post<T = any>(url: string, data?: Data) {
    return platformPost(url, data)
        .then(throwResponseError) as Promise<T>;
}

export async function put<T = any>(url: string, data?: Data) {
    return platformPut(url, data)
        .then(throwResponseError) as Promise<T>;
}

export async function del<T = any>(url: string, data?: Data) {
    return platformDel(url, data)
        .then(throwResponseError) as Promise<T>;
}

export async function get<T = any>(url: string, data: Data = {}) {
    return platformGet(url, data)
        .then(throwResponseError) as Promise<T>;
}

async function throwResponseError(response: Response) {
    if (!response.ok) throw response.clone();
    return response;
}
