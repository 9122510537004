///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Entities.CustomFields.CustomFieldsCompanyConfiguration/customFieldsEditorTab"/>
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as sync from "Core/Medius.Core.Web/Scripts/Medius/core/sync";
import * as serialization from "Core/Medius.Core.Web/Scripts/Medius/lib/serialization";
import * as rpc from "Core/Medius.Core.Web/Scripts/Medius/core/rpc";
import * as _ from "underscore";
import * as $ from "jquery";
import * as ko from "knockout";

const ConfigurationType = 'Medius.Core.Entities.CustomFields.CustomFieldsCompanyConfiguration';

class CustomFieldsEditorTab {
    EntityContext: any;
    Context: any;
    MasterDataTypes: ko.ObservableArray<any>;
    SelectedMasterDataType: ko.Observable<any>;
    Configurations: any;
    CustomFieldsCompanyConfiguration: ko.Computed<any>;
    NewCompany: boolean;
    constructor(entityWithContext:any) {
        this.EntityContext = entityWithContext;
        this.Context = entityWithContext.Context;
        this.MasterDataTypes = ko.observableArray([]);
        this.SelectedMasterDataType = ko.observable(null);

        this.Configurations = this.EntityContext.RelatedObjects[ConfigurationType].RelatedEntities;
        this.CustomFieldsCompanyConfiguration = ko.computed(() => {
            const masterDataType = this.SelectedMasterDataType();

            if (masterDataType) {
                const companyConfigs = _(this.Configurations()).filter((config) => {
                    return config.CustomFieldsConfiguration().EntityType() === masterDataType.Type;
                });
                return _(companyConfigs).first();
            }
            return null;
        });

        this.NewCompany = this.EntityContext.Entity.Id() === 0;
        if (!this.NewCompany) {
            this.loadMasterDataTypes();
        }
    }

    removeCompanyConfiguration() {
        this.Configurations.remove(this.CustomFieldsCompanyConfiguration());
    }
    createCompanyConfiguration() {
        sync.create(ConfigurationType).done((configuration) => {
            this.prepareConfigurationViewModel(configuration).done((configVm:any) => {
                this.Configurations.push(configVm);
            });
        });
    }

    prepareCustomFieldsConfigViewModel() {
        const def = $.Deferred();
        rpc.ajax("CustomFieldsManager", "GetNewCustomFieldsConfiguration").done(
            (customFieldsConfig) => {
                const configVm = this.Context.create(customFieldsConfig);
                return def.resolve(configVm);
            });
        return def.promise();
    }

    prepareConfigurationViewModel(configuration:any) {
        const configurationVm = this.Context.create(configuration);
        const typeName = this.SelectedMasterDataType().Type;
        const company = this.EntityContext.Entity;
        const def = $.Deferred();

        $.when(this.prepareCustomFieldsConfigViewModel()).done((customFieldsConfig) => {
            configurationVm.ConfiguredEntity(company);
            configurationVm.Company(company);
            configurationVm.CustomFieldsConfiguration(customFieldsConfig);
            configurationVm.CustomFieldsConfiguration().EntityType(typeName);
            return def.resolve(configurationVm);
        });

        return def.promise();
    }

    loadMasterDataTypes() {
        const entityId = this.EntityContext.Entity.Id();

        rpc.ajax("CustomFieldsManager", "GetAvailableDataTypes", {
            data: serialization.toJSON({
                contextCompanyId: entityId
            })
        }).done((types) => {
            this.MasterDataTypes(types.map((masterType:string) => {
                return {
                    Type: masterType,
                    Name: globalization.getPropertyTranslation(`#${masterType}`)
                };
            }));
        });
    }
}

export = function (entityWithContext:any) {
    return new CustomFieldsEditorTab(entityWithContext);
};