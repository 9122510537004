/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/inbox/task/labelsHandler"/>
import { observable, observableArray, pureComputed } from "knockout";
import * as _ from "underscore";
import * as ko from "knockout";
import { get, put, del } from "Core/Medius.Core.Web/Scripts/Medius/core/fetch/rest";
import * as errorHandler from "Core/Medius.Core.Web/Scripts/lib/errorHandling/errorHandler";
import { ICompanyLabel } from "Core/Medius.Core.Web/Scripts/typings/interfaces/ICompanyLabel";
import {getLabelTextColor} from "Core/Medius.Core.Web/Scripts/lib/labelHelper";
import { LabelSelector } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/task/labelSelector";

interface LabelsDTO {
    labelsAssignedToDocument: number[];
    labelsForDocument: ICompanyLabel[];
}

interface LabelWithTextColor extends ICompanyLabel {
    textColor: string;
}

export class LabelsHandler {
    public availableLabels = observableArray<ICompanyLabel>([]);
    public isLabelDropdownVisible = observable(false);
    public labels = observableArray<ICompanyLabel>([]);
    public toggleLabelDropdown: () => void;
    public hideLabelDropdown: () => void;

    public isAnyLabelAssigned = pureComputed(() => this.labels().length > 0);

    public isAnyLabelsAvailable = pureComputed(() => this.availableLabels().length > 0);
    public labelsLoaded = observable(false);

    public documentId: number;
    public isInFullScreen = ko.observable(false);
    private isClickFromColumn: boolean;

    constructor(documentId: number, assignedLabels: ko.ObservableArray<ICompanyLabel>, isInFullScreen: boolean) {
        this.isInFullScreen(isInFullScreen);
        this.documentId = documentId;

        this.labels = assignedLabels;
        this.availableLabels = observableArray<ICompanyLabel>([]);

        this.toggleLabelDropdown = () => {
            this.labelsLoaded(false);
            this.isLabelDropdownVisible(!this.isLabelDropdownVisible());
            this.isClickFromColumn = true;

            get(`labels/document/${this.documentId.toString()}`)
                .then((labelsDto: LabelsDTO) => {
                    const allLabels = labelsDto.labelsForDocument.map(l => ({
                        ...l,
                        textColor: getLabelTextColor(l.colorCode),
                    }));

                    const assignedLabels = allLabels.filter(label => {
                        if(labelsDto.labelsAssignedToDocument.some(labelId => labelId === label.id)) return true;
                        return false;
                    });
                    
                    this.labels(assignedLabels);
                    this.availableLabels(allLabels);
                }).catch(error => {
                    errorHandler.handleError(error);
                }).finally(() => {
                    this.labelsLoaded(true);
                });
        };

        this.hideLabelDropdown = () => {
            if (!this.isClickFromColumn) {
                this.isLabelDropdownVisible(false);
            } else {
                this.isClickFromColumn = false;
            }
        };
    }

    public dispose() {
        this.isAnyLabelAssigned.dispose();
    }

    public isLabelAlreadyAssigned(label: ICompanyLabel) {
        return (this.labels()).some(assignedLabel => label.id === assignedLabel.id);
    }

    public isIconBlack(label: LabelWithTextColor){
        return label.textColor === "#000000";
    }

    public toggleLabel(label: ICompanyLabel) {
        if (_.isUndefined(label) || _.isNull(label)) {
            return;
        }

        if (!this.isLabelAlreadyAssigned(label)) {
            this.addLabel(label);
        } else {
            this.removeLabel(label);
        }

        this.isLabelDropdownVisible(false);
    }

    public removeLabel(label: ICompanyLabel) {
        del(`Backend/Rest/labels/${label.id}/document/${this.documentId}`)
            .then(() => {
                this.labels.remove(labelToRemove => label.id === labelToRemove.id);
            }).catch(error => {
                errorHandler.handleError(error);
            });
    }

    public addLabel(label: ICompanyLabel) {
        put(`Backend/Rest/labels/${label.id}/document/${this.documentId}`)
            .then(() => {
                this.labels.push(label);
            }).catch(error => {
                errorHandler.handleError(error);
            });
    }

    public LabelsHandler = ko.pureComputed(() => {        
        return ({
            functionComponent: LabelSelector,
            props: {
                documentId: this.documentId
            }
        });
    });
}

export function create(documentId: number, assignedLabels: ko.ObservableArray<ICompanyLabel>, isInFullScreen = false) {
    const handler = new LabelsHandler(documentId, assignedLabels, isInFullScreen);
    return handler;
}