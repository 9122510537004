///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/lexeme/iterator" />

class LexemeIterator{
    lexemes: string[];
    currentIndex = -1;

    constructor(lexemes: string[]) {
        this.lexemes = lexemes;
    }

    current(){
        return this.lexemes[this.currentIndex];
    }
    
    hasNext(){
        const next = this.currentIndex + 1;
        return this.lexemes[next];
    }
    
    next(){
        this.currentIndex++;
        return this.current();
    }
    
    prev(){
        this.currentIndex--;
        return this.current();
    }
    
    reset(){
        this.currentIndex = -1;
    }
    
    getLexemesInBrackets(){
        const resultLexemes = [];
        let openningBracketsCount = 0;
        let lexeme = this.current();
    
        if (lexeme !== '(') {
            throw 'opening bracket not found';
        }
        
        this.prev();
    
        do {
            lexeme = this.next();
            resultLexemes.push(lexeme);
            
            if (lexeme === ')') {
                openningBracketsCount--;
            } else if (lexeme === '(') {
                openningBracketsCount++;
            }
        }
        while (openningBracketsCount > 0 && this.hasNext());
        
        return resultLexemes.slice(1, resultLexemes.length - 1);
    }
}

export function create(lexemes: any) {
    return new LexemeIterator(lexemes);
}
