/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/ImportManagement/importManagement"/>
import * as React from "react";
import { useEffect, useState, useReducer, useCallback } from "react";
import { SidebarNavigation } from "Core/Medius.Core.Web/Scripts/AdminPages/sidebarNavigation";
import { translate, getFormattedLabelTranslation, getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { Grid, GridColumn, GridProps, GridToolbar, GridColumnProps } from "@progress/kendo-react-grid";
import { TabStrip, TabStripTab, Splitter, SplitterPaneProps } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { IconStatusInfoRegular } from '@medius/ui-controls';
import { InstantSearchBox } from "@medius/ui-controls";
import { InfoPanel } from "./infoPanel";
import { ImportLogs } from "./importLogs";
import { getQueueItems, reprocessItem, deleteItem } from "Core/Medius.Core.Web/Scripts/AdminPages/ImportManagement/services";
import { QueueItem, QueueItemState } from "./interfaces";
import { importManagementReducer, selectRow, loadData } from "./importManagamentState";
import { FilterDescriptor } from "@progress/kendo-data-query";
import { Switch } from "@progress/kendo-react-inputs";
import { MaskingKendoReactGridLoadingPanel } from "../../shared/components/loadingPanel";
import { useLocalStorage } from "Core/Medius.Core.Web/Scripts/shared/hooks/useLocalStorage";
import { error } from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import { handleError } from "Core/Medius.Core.Web/Scripts/lib/errorHandling/errorHandler";
import { toUnknownKindIsoString, toUserLocal } from "Core/Medius.Core.Web/Scripts/Medius/lib/utils/date";
import { useDebounce } from "use-debounce";
import { Callout } from "@medius/ui-controls";

export function ImportManagement() {
    const [dataState, setDataState] = useState<GridProps>({
        skip: 0,
        take: 20,
        sort: [],
        filter: {
            logic: "and",
            filters: []
        }
    });
    const defaultPaneSettings: SplitterPaneProps[] = [
        { size: '60%', min: '20px' },
        { min: '20px' }
    ];
    const [panesState, setPanesState] = useLocalStorage("importManagement_panes", defaultPaneSettings);

    const [reducerState, dispatch] = useReducer(importManagementReducer, { data: [] as QueueItem[], total: 0 });
    const selectedItem = reducerState.data.find(x => x.selected);
    const [selectedTab, setSelectedTab] = useState(0);
    const [showSuccesful, setShowSuccesful] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [isSearchErrorsInfoCalloutOpened, setIsSearchErrorsInfoCalloutOpened] = useState(false) ;
    const [errorSearchString, setErrorSearchString] = useState("");

    const isAbleToReprocessSelectedItem = () => {
        return selectedItem &&
            (selectedItem.state == QueueItemState.Error || (selectedItem.state == QueueItemState.Processed && !selectedItem.successfullyProcessed));
    };

    const reprocessQueueItem = () => {
        setIsLoading(true);
        reprocessItem(selectedItem.id)
            .then(() => reload(), () => {
                error(getFormattedLabelTranslation("#Core/reprocessFailureMessage_masterDataType", [selectedItem.itemType]), translate("#Core/reprocessFailureTitle"));
                setIsLoading(false);
            });
    };

    const handleErrorSearch = (searchString: string) => {
        setErrorSearchString(searchString);
    };

    const filters = dataState.filter?.filters as FilterDescriptor[];
    const [itemType] = useDebounce(filters?.find(({ field }) => field === "itemType")?.value, 200);
    const [itemId] = useDebounce(filters?.find(({ field }) => field === "itemId")?.value, 200);
    const [state] = useDebounce(filters?.find(({ field }) => field === "state")?.value, 200);
    const [itemCount] = useDebounce(filters?.find(({ field }) => field === "itemCount")?.value, 200);
    const [itemSaved] = useDebounce(filters?.find(({ field }) => field === "itemSaved")?.value, 200);
    
    const filterCreatedTimestamp = filters?.find(({ field }) => field === "createdTimestamp")?.value as Date;
    const [createdTimestamp] = useDebounce(!!filterCreatedTimestamp ? toUnknownKindIsoString(toUserLocal(filterCreatedTimestamp)) : null, 200);
    
    const [erpSourceId] = useDebounce(filters?.find(({ field }) => field === "erpSourceId")?.value, 200);

    const reload = useCallback(() => {
        if(createdTimestamp && (new Date(createdTimestamp)).getFullYear() < 1800) {
            return;
        }

        setIsLoading(true);
        const keywords = {
            itemType,
            itemId,
            state,
            itemCount,
            itemSaved,
            createdTimestamp,
            erpSourceId
        };

        const onlySuccessful = showSuccesful ? null : false;

        getQueueItems(errorSearchString, dataState.skip, dataState.take, onlySuccessful, dataState.sort[0]?.field, dataState.sort[0]?.dir, keywords)
            .then((dto) => {
                dispatch(loadData(dto));
                setIsLoading(false);
            }, (e) => {
                handleError(e);
                setIsLoading(false);
            });
            
    }, 
    [
        itemType, 
        itemId, 
        state, 
        itemCount, 
        itemSaved, 
        createdTimestamp, 
        erpSourceId, 
        dataState.skip, 
        dataState.take, 
        dataState.sort, 
        showSuccesful,
        errorSearchString
    ]);

    const handleSearchErrorsInfoButtonClick = () => {
        setIsSearchErrorsInfoCalloutOpened(prev => !prev);
    };

    useEffect(() => {
        reload();
    }, [reload]);

    const defaultColumnProps: GridColumnProps[] = [
        { width: "180px", field: "itemType", title: translate("#Core/itemType") },
        { width: "150px", field: "state", title: translate("#Core/queueItemState") },
        { width: "150px", field: "successfullyProcessed", title: translate("#Core/successfulImport"), sortable: false, filterable: false },
        { width: "250px", field: "createdTimestamp", title: translate("#Core/createdTime"), format: "{0:g}", filter: "date" },
        { width: "150px", field: "itemCount", title: translate("#Core/itemCount"), filter: "numeric" },
        { width: "150px", field: "itemSaved", title: translate("#Core/itemSaved"), filter: "numeric" },
        { width: "280px", field: "itemId", title: translate("#Core/queueItemId") },
        { width: "250px", field: "erpSourceId", title: translate("#Enterprise/erpSourceId") }
    ];

    const [columnProps, setColumnProps] = useLocalStorage("importManagement_columns", defaultColumnProps);
    const containerRef = React.useRef<HTMLDivElement>(null);
    const anchorRef = React.useRef<HTMLDivElement>(null);

    const columns = columnProps.map(x => (<GridColumn {...x} key={x.field} />));
    return (
        <>
            <SidebarNavigation />
            <div className="importManagement">
                <Splitter
                    panes={panesState}
                    onChange={(e) => { setPanesState(e.newState); }}
                    className="importManagement__splitter"
                >
                    <div className="importManagement__gridPanel">
                        {isLoading && <MaskingKendoReactGridLoadingPanel gridSelector=".importManagement__queueItemsGrid" />}
                        <h2 className="administration-page-header">{translate("#Core/importQueueManagement")}</h2>
                        <Grid
                            className="importManagement__queueItemsGrid"
                            {...reducerState}
                            {...dataState}
                            onDataStateChange={(e) => {
                                setDataState({ ...e.dataState });
                            }}
                            pageable
                            sortable
                            filterable
                            filterOperators={{
                                "text": [{ text: 'grid.filterContainsOperator', operator: 'contains' }],
                                "numeric": [{ text: 'grid.filterEqOperator', operator: 'eq' }],
                                "date": [{ text: 'grid.filterEqOperator', operator: 'eq' }]
                            }}
                            selectedField="selected"
                            onRowClick={({ dataItem }) => {
                                dispatch(selectRow(dataItem.id));
                            }}
                            onSortChange={({ sort }) => {
                                setDataState((data) => ({ ...data, sort }));
                            }}
                            onFilterChange={({ filter }) => {
                                setDataState((data) => ({ ...data, skip: 0, take: 20, filter }));
                            }}
                            onColumnReorder={({ columns }) => {
                                setColumnProps(columns);
                            }}
                            onColumnResize={(({ columns }) => {
                                setColumnProps(columns);
                            })}
                            reorderable
                            resizable
                        >
                            <GridToolbar>
                                <Button
                                    id="importManagement__refresh-button"
                                    title={translate("#Core/refresh")}
                                    onClick={reload}>{translate("#Core/refresh")}</Button>
                                <Button
                                    id="importManagement__reprocess-button"
                                    themeColor="primary"
                                    disabled={!isAbleToReprocessSelectedItem()}
                                    onClick={reprocessQueueItem}>{translate("#Core/reprocessSelected")}
                                </Button>
                                <Button
                                    className="importManagement__delete-button"
                                    disabled={!selectedItem}
                                    onClick={() => {
                                        deleteItem(selectedItem.id).then(() => {
                                            setIsLoading(true);
                                            setDataState((data) => ({ ...data, skip: 0, take: 20 }));
                                            reload();
                                        });

                                    }}>{translate("#Core/deleteSelected")}
                                </Button>
                                <span className="importManagement__toolbar-label">{translate("#Core/showSuccessful")}</span>
                                <Switch
                                    id="importManagement__toggle-show-successful"
                                    offLabel={translate("#Core/no")}
                                    onLabel={translate("#Core/yes")}
                                    checked={showSuccesful}
                                    onChange={({ target }) => {
                                        setIsLoading(true);
                                        setDataState((data) => ({ ...data, skip: 0, take: 20 }));
                                        setShowSuccesful(target.value);
                                    }} 
                                />
                                <div className="importManagement__toolbar-searchboxWrapper">
                                    <InstantSearchBox
                                        executeSearch={(str) => handleErrorSearch(str)}
                                        label={getLabelTranslation("#Core/searchErrors")}
                                        data-testid="importManagement__errorSearchBar"
                                    />
                                </div>
                                <div ref={containerRef}>
                                    <div ref={anchorRef} onClick={handleSearchErrorsInfoButtonClick}>
                                        <IconStatusInfoRegular size="medium" />
                                    </div>
                                </div>
                                <Callout 
                                    displayTargetRef={anchorRef}
                                    renderContainerRef={containerRef}
                                    onCloseRequested={() => { setIsSearchErrorsInfoCalloutOpened(false); }} 
                                    isOpen={isSearchErrorsInfoCalloutOpened} 
                                    position={"right"} 
                                >
                                    <p style={{margin: "0px"}}>{getLabelTranslation("#Core/importManagementSearchbarInfoText")}</p>
                                </Callout>
                            </GridToolbar>
                            {columns}
                        </Grid>
                    </div>
                    <div className="importManagement__right-side">
                        {selectedItem && <div className="importManagement__infoPanel">
                            {isLoading && <MaskingKendoReactGridLoadingPanel gridSelector=".importManagement__queueItemsGrid" />}
                            <TabStrip
                                selected={selectedTab}
                                onSelect={(e) => { setSelectedTab(e.selected); }}
                                className="importManagement__infoPanel__panels"
                            >
                                <TabStripTab title={translate("#Core/info")} contentClassName="importManagement__infoPanel__panel">
                                    <InfoPanel selectedItem={selectedItem} reload={reload} errorSearchString={errorSearchString} />
                                </TabStripTab>
                                <TabStripTab title={translate("#Core/importLogs")} contentClassName="importManagement__infoPanel__panel">
                                    <ImportLogs logs={selectedItem.logs} />
                                </TabStripTab>
                            </TabStrip>
                        </div>}
                    </div>

                </Splitter>
            </div>
        </>);
}
