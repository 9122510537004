///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Entities.Dashboard.RoleDashboard/roleDashboardTab"/>
import { observable, Observable, unwrap } from "knockout";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { isNotNullOrUndefined, isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import * as _ from "underscore";
import { error } from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import { getNew } from "Core/Medius.Core.Web/Scripts/Medius/core/sync";

class RoleDashboardTab {
    Role = observable();
    RoleDashboards: any;
    Dashboard: Observable<any>;
    constructor(entityWithContext: any) {
        const roleDashboards = entityWithContext.RelatedObjects["Medius.Core.Entities.Dashboard.RoleDashboard"]
        .RelatedEntities;

        // Bindings
        this.RoleDashboards = roleDashboards;
        this.Dashboard = observable(entityWithContext.Entity);
    }

    removeRow(rowToRemove: any) {
        this.RoleDashboards.remove(rowToRemove);
    }

    addRow() {
        if (isNullOrUndefined(this.Role())) {
            const errorMessage = getLabelTranslation("#Core/notifyRoleMustBeSelected");
            error(errorMessage);
            return;
        }

        if (isNotNullOrUndefined(
                _(this.RoleDashboards()).find((rd) => unwrap(rd.Role().Id) === unwrap(this.Role().Id))
            )) {
            const errorMessage = getLabelTranslation("#Core/notifySelectedRoleIsAlreadyAdded");
            error(errorMessage);
            return;
        }

        const roleDashboard = getNew("Medius.Core.Entities.Dashboard.RoleDashboard");
        roleDashboard.Role = observable(this.Role());
        roleDashboard.Dashboard = this.Dashboard;
        this.RoleDashboards.push(roleDashboard);
    }

    dispose() {
        this.RoleDashboards(null);
        this.Role(null);
        this.Dashboard(null);
    }
}

export default function create(entityWithContext: any) {
    return new RoleDashboardTab(entityWithContext);
}