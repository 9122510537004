///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/knockout/observables/synchronization"/>
import * as ko from "knockout";
export function oneWay(source:any, destination:any, toDestination?:any) {
    if (!ko.isObservable(source)) {
        throw new Error("Source must be an observable");
    }

    if (!ko.isWriteableObservable(destination)) {
        throw new Error("Destinantion must be a writeable observable");
    }
        
    if (typeof toDestination !== "function") {
        toDestination = function(value:any) {
            destination(value);
        };
    }

    const v = source();

    let sourceSubscription:ko.Subscription;
    toDestination(v);
        sourceSubscription = source.subscribe(function () {
        const value = source();
        toDestination(value);
    });

    return {
        dispose: function() {
            if (sourceSubscription) {
                sourceSubscription.dispose();
                sourceSubscription = null;
            }
        }
    };
}

export function twoWay(source:any, destination:any, toSource?:any, toDestination?:any) {
    if (!ko.isWriteableObservable(source)) {
        throw new Error("Source must be a writable observable");
    }

    if (!ko.isWriteableObservable(destination)) {
        throw new Error("Destinantion must be a writeable observable");
    }
        
    if (typeof toSource !== "function") {
        toSource = function(value:any) {
            source(value);
        };
    }

    if (typeof toDestination !== "function") {
        toDestination = function(value:any) {
            destination(value);
        };
    }
    
    let isSynchronizing = false;
    const v = source();

    toDestination(v);
    let sourceSubscription = source.subscribe(function () {
        const value = source();
        isSynchronizing = true;
        toDestination(value);
        isSynchronizing = false;
    });
    let destinationSubscription = destination.subscribe(function () {
        if (!isSynchronizing) {
            const value = destination();
            toSource(value);
        }
    });

    return {
        dispose: function() {
            if (destinationSubscription) {
                destinationSubscription.dispose();
                destinationSubscription = null;
            }
            if (sourceSubscription) {
                sourceSubscription.dispose();
                sourceSubscription = null;
            }
        }
    };
};