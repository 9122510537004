///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/grid/bindings/nestedProperties"/>
import * as ko from "knockout";
import * as $ from "jquery";
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as propertiesLoader from "Core/Medius.Core.Web/Scripts/Medius/components/grid/columnSelector/propertiesLoader";

const selectorElementKey = "medius-preloader";

const nestedProperties = {
    init: function (element:any, valueAccessor:any, allBindingsAccessor:any, viewModel:any) {
        const bindingParams = valueAccessor();
        const type = koUtils.unpack(bindingParams.type);
        const absolutePath = koUtils.unpack(bindingParams.absolutePath);
        const entityType = koUtils.unpack(bindingParams.entityType);
        const selectedCollection = bindingParams.selectedCollection;
        const limitCollectionAccess = koUtils.unpack(bindingParams.limitCollectionAccess);
        const current = koUtils.domData.get(element, selectorElementKey) || {};
        let $element = $(element);
        let propertyViewModel = viewModel;
        
        if (current.type) {
            current.disposal();
            ko.utils.domNodeDisposal.removeDisposeCallback(current.disposal, undefined);
        }
        
        if (!type) {
            throw new Error("propertiesLoader: Can't initialize propertiesLoader, no entity type provided.");
        }
        
        const propertiesLoaderInstance = propertiesLoader.create();
        
        $element
            .on("mouseenter", function () {
                if (!propertyViewModel.showProperties()) {
                    propertiesLoaderInstance.loadProperties(type, selectedCollection, limitCollectionAccess, absolutePath, entityType)
                        .done(function(properties:any) {
                            propertyViewModel.propertiesList(properties);
                            propertyViewModel.showProperties(true);
                        });
                }
            }).on("mouseleave", function () {
                propertyViewModel.showProperties(false);
            });
        
        const disposal = function () {
            propertiesLoaderInstance.destroy();
            $element.off("mouseenter mouseleave");
            $element = null;
            propertyViewModel = null;
            koUtils.domData.set(element, selectorElementKey, null);
        };
        koUtils.addDisposeCallback(element, disposal);

        koUtils.domData.set(element, selectorElementKey, {
            disposal: disposal
        });
    },
    update: function (element:any, valueAccessor:any, allBindingsAccessor:any, viewModel:any) {
        const bindingParams = valueAccessor();
        const type = koUtils.unpack(bindingParams.type);
        const current = koUtils.domData.get(element, selectorElementKey) || {};

        if (type && type !== current.type) {
            ko.bindingHandlers.nestedProperties.init(element, valueAccessor, allBindingsAccessor, viewModel, undefined);
        }
    }
};

export function register() {
    koUtils.registerBinding("nestedProperties", nestedProperties);
}