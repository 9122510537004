///<amd-module name = "Core/Medius.Core.Web/Scripts/Models/Medius.Core.Entities.Workflow.TaskResult.CodeResult"/>
import * as ko from "knockout";
import mappings = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/mapping/collection/instance");
import * as customHandlersProvider from "Core/Medius.Core.Web/Scripts/Medius/core/customTaskHandlers/customTaskHandlersProvider";
import {extender} from "Core/Medius.Core.Web/Scripts/Models/Medius.Core.Entities.Workflow.TaskResult.TaskResult";

export function register() {
    mappings.register("Medius.Core.Entities.Workflow.TaskResult.CodeResult", function (vm:any) {
        extender(vm);

        vm.getCustomHandler = function (task:any) {
            return customHandlersProvider.getHandler(vm, task);
        };

        vm.resultName = ko.computed(function () {
            return [vm.resultName(), vm.Code()].join(":");
        });

        vm.getButtonClass = function () {
            switch (vm.Appearance()) {
                case 1:
                    return 'btn-success';

                case 2:
                    return 'btn-info';

                case 3:
                    return 'btn-warning';

                case 4:
                    return 'btn-danger';

                default:
                    return null;
            }
        };
    });
}