/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/inbox/inbox"/>
import { observable, pureComputed, observableArray } from "knockout";
import { create as createSelectedTasksHandler } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/handlers/selectedTasks";
import InboxSearch from "Core/Medius.Core.Web/Scripts/components/inboxSearch/inboxSearch";
import { TaskLoader } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/loaders/task";
import { getRouter } from "Core/Medius.Core.Web/Scripts/Medius/apps/spaManager/routerProvider";
import * as $ from "jquery";
import { getInstance as availableTasksModal } from "Core/Medius.Core.Web/Scripts/components/availableTasksModal/availableTasksModal";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { listenTo } from "Core/Medius.Core.Web/Scripts/eventBus";
import * as EventNames from "Core/Medius.Core.Web/Scripts/events/eventNames";
import { bindShortcutWithLogging } from "Core/Medius.Core.Web/Scripts/lib/shortcutManager";
import { isNullOrUndefined, isNotNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import { indexOf, any, isUndefined, delay, isFunction } from "underscore";
import { getTasksIds, findCurrentTask } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/helpers";
import { get, post } from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rest";
import { error } from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import { releaseLockIntervalForDocument } from "Core/Medius.Core.Web/Scripts/Medius/lockManager";
import * as _ from "underscore";
import { getOutboxInstance } from "Core/Medius.Core.Web/Scripts/components/outbox/outbox";
import { BulkModalFactory } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/bulk/bulkModalFactory";
import { registerInbox } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/taskHandler";
import { lightApi } from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rpc";
import * as folder from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/folder";
import { startInitMeasurement, startReloadMeasurement } from "Core/Medius.Core.Web/Scripts/Medius/performance/loggers/inbox";
import { create as taskGroupCheckbox } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/taskGroup/checkbox";
import * as ko from "knockout";
import { InboxHeaderTemplate } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/InboxHeaderTemplate";
import { store } from "Core/Medius.Core.Web/Scripts/shared/store/reduxStore";
import { LabelSelector } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/task/labelSelector";
import { handleAnyError } from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import { BulkOperationOnDocumentWithRisksWarning } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/bulkOperationOnDocumentWithRisksWarning";

const service = "InboxManager";

export class Inbox {
    public spaApp: any;
    public isLoading = observable(false);
    public isCountLoading = observable(false);
    public isDropdownVisible = observable(false);
    public isFullscreenNavigationVisible = observable(true);
    public isShowingDocument = false;
    public currentDocumentId = observable<number>();
    public currentTaskId = observable<number>();
    public currentTypeGroup = observable<string>(null);

    public openNextTaskEnabled = observable(true);
    public fullscreenEnabled = observable(false);

    public handleOneByOneEnabled = observable(false);
    public currentTemplate = pureComputed(() => this.fullscreenEnabled() ? "Core:templates/fullscreen.html" : "inbox-template-non-fullscreen");
    public folders = observableArray([]);
    public documentTypes = observableArray([]);
    public totalLoadedTasks = observable(0);
    public currentTaskPosition = observable(-1);
    public currentDocumentPosition = observable(-1);
    public selectedTasksHandler = createSelectedTasksHandler(this);
    public selectedTasks: any[] = [];
    public bulkWizard = observable();
    public labelsHandlerComponentConfiguration: ko.Computed;
    public areDocumentsWithRiskFactorsSelected = observable(false);
    public bulkOperationOnDocumentWithRisksWarning = ko.pureComputed(() => {
        return ({
            functionComponent: BulkOperationOnDocumentWithRisksWarning
        });
    });

    public search = new InboxSearch(this);
    public isSearchFilterApplied = observable(false);
    public searchIsFilterAppliedSub = this.search.isFilterApplied.subscribe((isApplied) => {
        this.isSearchFilterApplied(isApplied);
    });
    public taskLoader = new TaskLoader(this.currentTaskId, {
        FullscreenEnabled: this.fullscreenEnabled,
        CurrentDocumentId: this.currentDocumentId
    });

    public turnOffFullscreen = () => {
        this.handleOneByOneEnabled(false);
        this.search.isAdvancedSearchVisible(false);
        this.fullscreenEnabled(false);
        this.selectedTasksHandler.restoreSelection(this.selectedTasks);
    };

    public originPlace = observable(null);
    public navigateBackFromHandleOneByOne = () => {
        if (!this.originPlace()) {
            this.turnOffFullscreen();
        } else {
            getRouter().navigate(this.originPlace(), { trigger: true });
        }
    };
    
    public taskLoaders: any[] = [
        {
            handleTasks: (tasks: any) => {
                const deferred = $.Deferred();
                // only one task available. Open it.
                if (tasks.length === 1) {
                    this.openTask(tasks[0].TaskId, false);
                    deferred.resolve(true);
                } else if (tasks.length > 0) {
                    availableTasksModal().show(tasks, this.openTask.bind(this));
                    deferred.resolve(true);
                } else {
                    deferred.resolve(false);
                }
                return deferred.promise();
            }
        }
    ];

    public inboxDataChangedSub = listenTo(EventNames.documentDataChanged, () => {
        this.refresh();
    });

    public taskHandledSub = listenTo(EventNames.taskHandled, (taskId: number) => {
        this.onTaskHandled(taskId);
    });

    public taskSavedSub = listenTo(EventNames.taskSaved, () => {
        this.reloadCurrentTask();
    });

    public taskReloadRequestedSub = listenTo(EventNames.taskReloadRequested, () => {
        this.reloadCurrentTask();
    });

    public taskSentToReview = listenTo(EventNames.taskSentToReview, (taskId: number) => {
        this.updateCurrentTaskReviewStatus(true);
        this.onTaskHandled(taskId);
    });


    public taskSentToReviewInPV2 = listenTo(EventNames.taskSentToReviewInPV2, () => {
        this.updateCurrentTaskReviewStatus(true);
    });

    public withdrawFromReview = listenTo(EventNames.taskWithdrawnFromReview, () => {
        this.updateCurrentTaskReviewStatus(false);
        this.reloadCurrentTask();
    });

    public withdrawFromReviewInPV2 = listenTo(EventNames.taskWithdrawnFromReviewInPV2, () => {
        this.updateCurrentTaskReviewStatus(false);
    });

    public handleDocumentsOneByOne = listenTo(EventNames.handleDocumentsOneByOneRequested, (payload: any) => {
        this.originPlace(payload.originPlace);
        this.selectedTasksHandler.saveDocuments(payload.documentIds);
        this.openDocumentForBulkHandling(payload.currentId);
    });

    public InboxHeader = ko.pureComputed(() => {
        const foldersData = this.folders().map((folder) => {
            return { id: folder.id, visible: folder.visible(), localizedName: folder.localizedName };
        });

        const documentTypesData = this.documentTypes().map((documentType) => {
            return { visible: documentType.visible(), typeName: documentType.typeName };
        });
        return ({
            functionComponent: InboxHeaderTemplate,
            props: {
                refreshAction: () => { this.refresh(); },
                fullscreenAction: () => { this.useFullScreenMode(); },
                handleOneByOneAction: () => { this.handleOneByOne(); },
                searchAction: (keywords: string) => {
                    this.search.keywords(keywords);
                    this.search.triggerInputSearch();
                },
                filterButtonAction: () => { this.search.toggleAdvancedSearch(); },
                bulkWizardAction: () => { this.openBulkWizard(); },
                hasTasks: this.selectedTasksHandler.hasTasks(),
                toggleDropdownAction: (visibility: boolean) => { this.toggleDropdown(visibility); },
                isDropdownVisible: this.isDropdownVisible(),
                isFilterApplied: this.isSearchFilterApplied(),
                resetFilter: () => { this.search.clear(); },
                savedFilterName: this.search.currentSavedFilter()?.Name,
                dropdownProps: {
                    folders: foldersData,
                    savedFilters: this.search.savedFilters(),
                    documentTypes: documentTypesData,
                    toggleFolder: (id: number) => { this.toggleFolderVisibility(id); },
                    toggleDocumentType: (typeName: string) => { this.toggleDocumentTypeVisibility(typeName); },
                    allFoldersVisible: this.allFoldersVisible(),
                    allDocumentsVisible: this.allDocumentsVisible(),
                    toggleAllFoldersAction: (visibility: boolean) => { this.toggleAllFolders(visibility); },
                    toggleAllDocumentTypesAction: (visibility: boolean) => { this.toggleAllDocumentTypes(visibility); },
                    deleteFilter: (filter: any) => { this.search.removeSavedFilter(filter); },
                    applyFilter: (filter: any) => { this.search.applySavedFilter(filter); }
                }
            }
        });
    });

    public toggleAllFolders(visibility: boolean) {
        this.folders().forEach((folder) => {
            if (folder.visible() !== visibility) {
                folder.visible(visibility);
            }
        });
    }

    public toggleAllDocumentTypes(visibility: boolean) {
        this.documentTypes().forEach((docType) => {
            if (docType.visible() !== visibility) {
                docType.visible(visibility);
            }
        });
    }

    public allDocumentsVisible = ko.pureComputed(() => {
        const foundDocumentType = ko.utils.arrayFirst(this.documentTypes(), (docType) => {
            return docType.visible() == false;
        });
        return isNullOrUndefined(foundDocumentType);
    });

    public allFoldersVisible = ko.pureComputed(() => {
        const foundFolder = ko.utils.arrayFirst(this.folders(), (folder) => {
            return folder.visible() == false;
        });
        return isNullOrUndefined(foundFolder);
    });

    public toggleFolderVisibility(id: number) {
        const foundFolder = ko.utils.arrayFirst(this.folders(), (folder) => {
            return folder.id == id;
        });

        foundFolder.visible(!foundFolder.visible());
    }

    public toggleDocumentTypeVisibility(typeName: string) {
        const foundDocumentType = ko.utils.arrayFirst(this.documentTypes(), (docType) => {
            return docType.typeName == typeName;
        });

        foundDocumentType.visible(!foundDocumentType.visible());
    }

    constructor() {
        $(document).ready(() => {
            bindShortcutWithLogging("ToggleFullscreen", this.toggleFullScreen.bind(this));
            bindShortcutWithLogging("OpenPreviousTask", this.openPreviousTask.bind(this));
            bindShortcutWithLogging("OpenNextTask", this.openNextTask.bind(this));
            bindShortcutWithLogging("RefreshInbox", this.refresh.bind(this));
        });

        registerInbox(this);
    }

    public addTaskLoader(taskLoader: any) {
        this.taskLoaders.push(taskLoader);
    }

    public init() {
        const measurement = startInitMeasurement();

        return $.when(
            this.loadFolders(),
            this.search.loadSavedFilters()
        ).pipe(() => {
            const t: any[] = [];

            // load tasks for all folders
            this.folders().forEach((fldr) => {
                t.push(fldr.init());
                fldr.countTasks();
            });

            const initIsCompleted = $.when.apply($, t);

            // when all folders finished loading tasks, open first of all
            if (isNullOrUndefined(this.currentTaskId()) && this.isShowingDocument === false) {
                initIsCompleted.pipe(() => {
                    if (this.currentTypeGroup()) {
                        this.currentTypeGroup(null);
                        return;
                    }

                    this.openFirstTask();
                });
            }

            return initIsCompleted;
        }).pipe(() => {
            measurement.stop();
        });
    }

    public refresh() {
        const measurement = startReloadMeasurement();

        return $.when(this.loadFolders()).pipe(() => {
            const t: any[] = [];

            // load tasks for all folders
            this.folders().forEach((fldr) => {
                t.push(fldr.init());
                fldr.countTasks();
            });

            return $.when.apply($, t);
        }).pipe(() => {
            measurement.stop();
        });
    }

    public toggleDropdown(visibility: boolean) {
        this.isDropdownVisible(visibility);
    }

    public resetToDefaultState() {
        this.clearTask();
        this.openNextTaskEnabled(true);
        this.isShowingDocument = false;
        this.isFullscreenNavigationVisible(true);
        this.turnOffFullscreen();
        this.selectedTasksHandler.isInDocumentMode(false);
    }

    public resetAndOpenFirstTask() {
        this.resetToDefaultState();
        this.init();
    }

    public resetAndRefresh() {
        this.resetToDefaultState();
        this.refresh();
    }

    public filterTasks() {
        this.folders().forEach(folder => {
            folder.loadTasks();
            folder.countTasks();
        });

        this.isDropdownVisible(false);
    }

    public clearTask() {
        this.currentTaskId(null);
        this.spaApp.onTaskIdChanged();
    }

    public openTask(taskId: number | string, updateUrl: boolean = true) {
        if (taskId === 0) {
            this.labelsHandlerComponentConfiguration = null;
        }
        else {
            this.labelsHandlerComponentConfiguration = ko.computed(() => {
                let localTaskId = taskId;
                if(typeof taskId === "string"){
                    localTaskId = parseInt((localTaskId as any), 10); 
                }
    
                return ({
                    functionComponent: LabelSelector,
                    props: {
                        taskId: localTaskId
                    } 
                });
            });
        }

        this.isShowingDocument = false;

        if (typeof taskId === "string") {
            taskId = parseInt(taskId, 10);
        }
        if (typeof taskId !== "number") {
            throw new Error("Invalid Task Id");
        }

        this.currentTaskId(taskId);
        this.updateCurrentTaskPosition();
        this.search.isAdvancedSearchVisible(false);

        if (updateUrl)
            this.spaApp.onTaskIdChanged();
    }

    public openTypeGroup(updateUrl: boolean = true) {
        let taskId = null;

        for (const folder of this.folders()) {
            for (const typeGroup of folder.typeGroups()) {
                if (typeGroup.fullName === this.currentTypeGroup()) {
                    taskId = typeGroup.tasks()[0]?.TaskId;
                    break;
                }
            }

            if (taskId > 0)
                break;
        }

        if (taskId > 0)
            this.openTask(taskId, updateUrl);
        else
            this.openFirstTask();
    }

    public updateCurrentTaskPosition() {
        const taskId = this.currentTaskId();
        let context: any;

        if (this.selectedTasksHandler.hasTasks()) {
            context = this.selectedTasksHandler.tasks();
        } else {
            context = this.mergeAllTasks();
            this.totalLoadedTasks(context.length);
        }

        const position = indexOf(getTasksIds(context), taskId) + 1;
        this.currentTaskPosition(position);
    }

    public updateCurrentDocumentPosition() {
        this.currentDocumentPosition(this.selectedTasksHandler.documentIds().indexOf(this.currentDocumentId()) + 1);
    }

    public openTaskInFullscreen(taskId: number) {
        // We have to keep this value here and can't do simple if(taskId != this.currentTaskId())
        // later because this.openTask(taskId) changes currentTaskId
        const taskHasChanged = taskId !== this.currentTaskId();

        this.selectedTasksHandler.saveTasks();
        this.selectedTasks = this.selectedTasksHandler.tasks();
        this.selectedTasksHandler.clear();

        this.taskLoader.isInSwitchMode(true);
        this.fullscreenEnabled(true);

        this.openTask(taskId);
        if (!taskHasChanged) {
            this.taskLoader.isInSwitchMode(false);
        }
    }

    public useFullScreenMode() {
        this.fullscreenEnabled(false);
        this.toggleFullScreen();
    }

    public toggleFullScreen() {
        if (this.fullscreenEnabled()) {
            this.turnOffFullscreen();
        } else {
            this.openCurrentTaskInFullscreen();
        }
    }

    public openCurrentTaskInFullscreen() {
        if (isNotNullOrUndefined(this.currentTaskId())) {
            this.openTaskInFullscreen(this.currentTaskId());
        }
    }
    
    public openDocument(documentId: number) {
        const uri = `TasksAssignments/${documentId}`;

        this.isShowingDocument = true;
        this.currentDocumentId(documentId);
        this.isLoading(true);

        get(service, uri).done((tasks: any) => {
            this.currentTaskId(null);
            this.fullscreenEnabled(true);
            this.openNextTaskEnabled(false);
            this.isFullscreenNavigationVisible(false);
            this.tryHandleTasks(tasks, documentId);

        }).fail(() => {
            this.clearTask();
        })
            .always(() => {
                this.isLoading(false);
            });
    }

    public openDocumentForBulkHandling(documentId: number) {
        const uri = ["TasksAssignments", documentId].join("/");

        this.isShowingDocument = true;
        this.currentDocumentId(documentId);
        this.isLoading(true);

        get(service, uri).then((tasks) => {
            getRouter().navigate('Tasks/ShowDocument/' + documentId);
            this.currentTaskId(null);
            this.tryHandleTasks(tasks, documentId);
            this.updateCurrentDocumentPosition();
            this.spaApp.openInboxApp();
            this.fullscreenEnabled(true);
            this.isFullscreenNavigationVisible(true);
            this.handleOneByOneEnabled(true);
        }).fail(() => {
            this.clearTask();
        })
            .always(() => {
                this.isLoading(false);
            });
    }

    public openNextDocument() {
        const documentIds = this.selectedTasksHandler.documentIds();
        const currentIndex = documentIds.indexOf(this.currentDocumentId());
        if (currentIndex === documentIds.length - 1) {
            this.openDocumentForBulkHandling(documentIds[0]);
        } else {
            this.openDocumentForBulkHandling(documentIds[currentIndex + 1]);
        }
    }

    public openPreviousDocument() {
        const documentIds = this.selectedTasksHandler.documentIds();
        const currentIndex = documentIds.indexOf(this.currentDocumentId());
        if (currentIndex === 0) {
            this.openDocumentForBulkHandling(documentIds[documentIds.length - 1]);
        } else {
            this.openDocumentForBulkHandling(documentIds[currentIndex - 1]);
        }
    }

    public tryHandleTasks(tasks: any, documentId: number) {
        const loadersDeferreds = this.taskLoaders.map((taskLoader) => {
            return taskLoader.handleTasks(tasks, documentId);
        });

        $.when.apply(null, loadersDeferreds).done(function () {
            if (!any(arguments)) {
                error(translate("#Core/documentDoesNotExistOrYouHaveInsufficientPermissions"),
                    translate("#Core/documentCannotBeLoaded"));
            }
        });
    }

    public openTaskInDocumentMode(taskId: number) {
        this.isShowingDocument = true;
        this.openNextTaskEnabled(false);
        this.isFullscreenNavigationVisible(false);
        this.openTask(taskId, false);
        this.fullscreenEnabled(true);
    }

    public mergeAllTasks() {
        const allTasks: any[] = [];

        this.folders().forEach((fldr => {
            if (!fldr.visible()) {
                return;
            }

            fldr.typeGroups().forEach((group: any) => {
                if (!group.visible()) {
                    return;
                }

                group.tasks().forEach((task: any) => {
                    if ((task.isCurrent && task.isCurrent()) || (task.visible && task.visible())) {
                        allTasks.push(task);
                    }
                });
            });
        }));

        return allTasks;
    }

    public openFirstTask() {
        this.clearTask();
        this.openNextTask(false);
    }

    public openNextTask(reload: boolean) {
        let index = 0;

        if (!this.openNextTaskEnabled()) {
            this.resetAndRefresh();
            return false;
        }

        reload = (isUndefined(reload)) ? true : reload;

        const allTasks = (this.handleOneByOneEnabled()) ? this.selectedTasksHandler.tasks() : this.mergeAllTasks();
        const currentTask = findCurrentTask(allTasks);

        if (currentTask) {
            index = _(allTasks).indexOf(currentTask) + 1;
            index = (index > allTasks.length - 1) ? 0 : index;
        }

        const task = allTasks[index];

        if (task && task !== currentTask && task.visible()) {
            task.clicked();
            return true;
        } else if (reload) {
            // no tasks in inbox - reload
            // this delay is needed due to some animations and calculations related to displaying inbox, without it, inbox sometimes is not displayed
            delay(() => {
                this.resetAndOpenFirstTask();
            }, 200);
            return false;
        }
    }

    public openPreviousTask() {
        let index = 0;

        const allTasks = (this.handleOneByOneEnabled()) ? this.selectedTasksHandler.tasks() : this.mergeAllTasks();
        const currentTask = findCurrentTask(allTasks);

        if (currentTask) {
            index = _(allTasks).indexOf(currentTask) - 1;
            index = (index < 0) ? allTasks.length - 1 : index;
        }

        const task = allTasks[index];

        if (task && task !== currentTask && task.visible()) {
            task.clicked();
        }
    }

    public onTaskHandled(handledTaskId: number) {
        releaseLockIntervalForDocument();

        const isUserInProcurement = store.getState().topBarContext.context == "Procurement";

        if (isUserInProcurement) {
            window.location.hash = "/procurement/orders";
            return;
        }

        if (this.handleOneByOneEnabled() && this.selectedTasksHandler.isInDocumentMode()) {
            const currentDocumentId = this.currentDocumentId();
            this.openNextDocument();

            this.selectedTasksHandler.removeDocument(currentDocumentId);
            this.updateCurrentDocumentPosition();
        } else if (this.openNextTask(true) && this.handleOneByOneEnabled()) {
            // order and position of conditional statements is forced because this.openNextTask(...) is performin action
            const currentTask = this.selectedTasksHandler.tasks().find((task: any) => {
                return task.id === handledTaskId;
            });

            this.selectedTasksHandler.removeTaskFromSavedTasks(currentTask);
            this.updateCurrentTaskPosition();
        }
    }

    public reloadCurrentTask() {
        const taskId = this.currentTaskId();

        this.clearTask();
        this.openTask(taskId);
    }

    public updateCurrentTaskReviewStatus(isInReview: boolean) {
        const currentTask: any = findCurrentTask(this.mergeAllTasks());
        if (currentTask) {
            currentTask.isInReview(isInReview);
        }
    }

    public setCurrentTaskOnHoldStatus() {
        const currentTask: any = findCurrentTask(this.mergeAllTasks());
        if (currentTask) {
            currentTask.isOnHold(true);
        }
    }

    public loadFolders() {
        this.isLoading(true);

        return lightApi("InboxService", "GetFolders").done((folders: any) => {
            if (folders.length < 1) {
                return;
            }

            const t: any[] = [];

            folders.forEach((f: any) => {
                t.push(folder.create(this, f, this.isSearchFilterApplied));
            });

            this.disposeFolders();
            this.folders(t);

        }).always(() => {
            this.isLoading(false);
        });
    }

    public disposeFolders() {
        this.folders().forEach((fldr) => {
            if (fldr && isFunction(fldr.dispose)) {
                fldr.dispose();
            }
        });
    }

    public generateDocumentTypes() {
        const names: any[] = [];
        const checkboxWrappers: any[] = [];

        this.documentTypes().forEach((docType) => {
            docType.dispose();
        });

        this.folders().forEach((fldr) => {
            fldr.typeGroups().forEach((typeGroup: any) => {
                const name = typeGroup.displayName;

                if (!_(names).contains(name)) {
                    names.push(name);
                    checkboxWrappers.push(taskGroupCheckbox(this.folders, name));
                }
            });
        });

        this.documentTypes(checkboxWrappers);
    }

    public handleOneByOne() {
        this.selectedTasksHandler.saveTasks();

        if (!this.selectedTasksHandler.hasTasks()) {
            return;
        }

        this.clearTask();
        this.fullscreenEnabled(true);
        this.selectedTasksHandler.isInDocumentMode(false);
        this.originPlace(null);
        this.handleOneByOneEnabled(true);
        this.openNextTask(false);
    }

    public openBulkWizard() {
        this.selectedTasksHandler.saveTasks();

        if (!this.selectedTasksHandler.hasTasks()) {
            return;
        }

        interface IIndicator{
            Value: number; 
            Type: string;
        }

        if (this.selectedTasksHandler.tasks().every(t => t.indicators?.some((i: IIndicator) => i.Type == 'RiskStatus' && i.Value > 0))) {
            error(translate("#Core/unableToPerformBulkOperationOnRiskDocuments"));
            return;
        }

        post("bulkInbox/availableDocuments", "", {
            Ids: this.selectedTasksHandler.tasks().map(t => t.DocumentId)
          }).then(
            (availableDocuments: any) =>
            {
                const tasks = this.selectedTasksHandler.tasks().filter(t => availableDocuments.ids.indexOf(t.DocumentId) !== -1);

                if (tasks.length == 0) {
                    return;
                }

                const selectedDocumentsWithRiskFactorsCount = this.selectedTasksHandler
                    .tasks()
                    .filter(t => t.indicators?.some((i: IIndicator) => i.Type == 'RiskStatus' && i.Value > 0))
                    .length;

                const options = {
                    onClose: () => {
                        getOutboxInstance().clearDone();
                        this.resetAndOpenFirstTask();
                    },
                    selectedDocumentsWithRiskFactorsCount: selectedDocumentsWithRiskFactorsCount
                };
        
                const bulkModalContext = { tasks, options };
        
                const wizard = !tasks || tasks.length === 0 ? null : BulkModalFactory.getInstance().create(bulkModalContext);

                this.areDocumentsWithRiskFactorsSelected(selectedDocumentsWithRiskFactorsCount > 0);
                
                this.bulkWizard(wizard);
            }, (xhr: any) => {
                handleAnyError(xhr);
            });
    }

    public registerSpaApp(spaApp: any) {
        this.spaApp = spaApp;
    }
}

export const InboxInstance = new Inbox();