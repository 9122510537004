/// <amd-module name="Core/Medius.Core.Web/Scripts/shared/hooks/useLocalStorage"/>
import { useCallback, useState } from 'react';

export function useLocalStorage<T>(key: string, initialValue: T) {
    const [storedValue, setStoredValue] = useState<T>(() => {
      try {
        const item = window.localStorage.getItem(key);
        return item ? JSON.parse(item) : initialValue;
      } catch (error) {
        console.log(error);
        return initialValue;
      }
    });
  
    const setValue = useCallback((value: T) => {
      try {
        setStoredValue(value);
        window.localStorage.setItem(key, JSON.stringify(value));
      } catch (error) {
        // A more advanced implementation would handle the error case
        console.log(error);
      }
    }, [key]);
  
    return [storedValue, setValue] as const;
  }
