/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/default/text/nameField"/>
import { unpack } from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as conditionBoundResolverFactory from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/conditionBound";


export const resolver = conditionBoundResolverFactory.create({
    priority: conditionBoundResolverFactory.priority.lowest / 2,
    condition: function(data: any) {
        return data && typeof data === "object" && data.Name;
    },
    resolve: function(data: any) {
        return { text: unpack(data.Name) };
    }
});
