/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/task/models/taskGroupResult"/>

import { observable } from "knockout";
import * as $ from "jquery";
class TaskGroupResult {

   private modalSelector: any;
   private modalElement = observable<any>();
   private Task = observable<any>();
   private Group = observable<any>();
   private ViewName = observable<string>();
   private show = observable<boolean>(false);

    handle: () => void;
    copy: () => void;

    constructor(modalElementSelector: any) {
        this.modalSelector = modalElementSelector;

        // to emulate old behavior with dynamical this
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;
        this.handle = function () {
            self.close();
            self.Task().handleTaskHandler(this);
        };

        this.copy = function () {
            self.close();
            self.Task().copy(this);
        };
    }

    public open = (task: any, groupResult: any, viewName: string) => {
        this.show(false);
        this.Task(task);
        this.Group(groupResult);
        this.ViewName(viewName);
        this.show(true);
        this.modalElement($(this.modalSelector));
        this.modalElement().modal('show');
    };

    public close = () => {
        this.modalElement().modal('hide');
    };

    public dispose = () => {
        this.modalSelector = null;
        this.modalElement = null;
        this.Task = null;
        this.Group = null;
    };
}

export function create(modalElement: any) {
    return new TaskGroupResult(modalElement);
}


