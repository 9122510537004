///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/userSettings/app"/>
import * as _ from "underscore";
import * as rest from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rest";
import * as backendErrorHandler from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as notification from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import * as tabManager from "Core/Medius.Core.Web/Scripts/Medius/apps/userSettings/tabManager";
import * as ko from "knockout";
import * as modificationsMapping from "Core/Medius.Core.Web/Scripts/Medius/models/bulk/modificationsMapping";

class App {
    tabs = ko.observableArray([]);

    init() {
        const newTabs = _.map(tabManager.getTabs(), (tab) => {
            tab.init();
            return tab;
        });

        const defaultTab = _.first(newTabs);
        if (defaultTab) {
            defaultTab.isDefault = true;
        }

        this.tabs(newTabs);
    }

    save() {
        const modifications:any[] = [];

        _.each(this.tabs(), (tab) => {
            tab.onSave(modifications);
        });

        if (modifications.length === 0) {
            return;
        }

        $.when(
            modificationsMapping.create().prepareHandlerMapping()
        ).pipe((mapping) => {
            const modificationsMapped = mapping.injectMapping("Medius.Core.Entities.User", modifications);
            return rest.post("UserSettingsService", "Update", modificationsMapped);
        }).done(() => {
            notification.info(globalization.getLabelTranslation("#Core/userSettingsSaved"));
        }).fail((xhr) => {
            backendErrorHandler.handleAnyError(xhr, null, "#Core/userSettingsNotSaved");
        });
    }

    dispose() {
        _(tabManager.getTabs()).each((tab) => {
            tab.dispose();
        });
    }
}

export function getInstance() {
    const app = new App();
    app.init();
    return app;
}