/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/history/history" />

import {observableArray, observable, pureComputed} from "knockout";
import * as EventNames from "Core/Medius.Core.Web/Scripts/events/eventNames";
import * as eventBus from "Core/Medius.Core.Web/Scripts/eventBus";
import * as backendErrorHandler from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import {translate} from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as rpc from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rpc";
import { getTypeName } from "Core/Medius.Core.Web/Scripts/Medius/core/type";
import { historyStore } from "Core/Medius.Core.Web/Scripts/Medius/core/store/inbox/historyCategory";

export class WorkflowHistory {
    public presentMainActionsLabel = translate("#Core/historyCategoryPresentMain");
    public presentDetailsLabel = translate("#Core/historyCategoryPresentDetails");
    public category: string = null;
    public CarriedOverItemsVisible = pureComputed(() => this.CarriedOverHistoryLog().length > 0 && this.PresentCarriedOverItems());
    public PresentCarriedOverItems = observable(true);
    public activeFilter = observable("showMainActions");
    public PresentDetail = false;
    public IsLoading = observable(false);
    public IsCodingHistoryActive: boolean;
    public CarriedOverHistoryLog = observableArray();
    public CurrentHistoryLog = observableArray();
    public filterStoreKey: string;
    public entityViewId: string;
    public entityType: string;
    private reloadHistorySub: Backbone.Events;

    constructor(entityViewId: string, entityType: string) {
        this.entityType = getTypeName(entityType);
        this.entityViewId = entityViewId;
        
        const storeName = "#PresentDetailHistory";
        this.filterStoreKey = `${storeName}-${this.entityType}-Filter`;

        this.IsCodingHistoryActive = entityType === "Medius.ExpenseInvoice.Entities.ExpenseInvoice, Medius.ExpenseInvoice.Common";

        historyStore.getItem(this.filterStoreKey)
            .done((filter: any) => {
                if (filter) {
                    this.activeFilter(filter);
                }
                (this as any)[this.activeFilter()]();
            });

        this.reloadHistorySub = eventBus.listenTo(EventNames.documentHistoryChanged, () => {
            this.loadHistory();
        });
    }

    public clearFilter() {
        this.category = null;
        this.PresentDetail = false;
    }

    public showAll() {
        this.clearFilter();
        this.PresentDetail = true;
        this.loadHistory();
        historyStore.setItem(this.filterStoreKey, "showAll");
    }

    public showMainActions() {
        this.clearFilter();
        this.loadHistory();
        historyStore.setItem(this.filterStoreKey, "showMainActions");
    }

    public showCodingHistory() {
        this.clearFilter();
        this.PresentDetail = true;
        this.category = "Coding";
        this.loadHistory();
        historyStore.setItem(this.filterStoreKey, "showCodingHistory");
    }

    public loadHistory() {
        this.IsLoading(true);
        rpc.request("WorkflowHistoryManager", "GetTaskHistoryForViewId", {
            entityViewId: this.entityViewId,
            entityType: this.entityType,
            presentDetail: this.PresentDetail,
            category: this.category
        })
            .done((historyItems: any) => {
                this.CurrentHistoryLog(historyItems.filter((item: any) => !item.IsCarriedOver));
                this.CarriedOverHistoryLog(historyItems.filter((item: any) => item.IsCarriedOver));
            })
            .fail((jqXhr: any) => backendErrorHandler.handleAnyError(jqXhr))
            .always(() => this.IsLoading(false));
    }

    public dispose() {
        this.reloadHistorySub.stopListening();
        this.CarriedOverItemsVisible.dispose();
    }
}
