/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/default/link/task"/>
import {unpack} from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as typeBoundResolverFactory from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/typeBound";
import * as path from "Core/Medius.Core.Web/Scripts/Medius/lib/path";

export const resolver =  typeBoundResolverFactory.create({
    priority: typeBoundResolverFactory.priority.lowest,
    type: "Medius.Data.Task",
    resolve: function (data: any) {
        const entityId = unpack(data.Id);

        return {
            link: path.getBasePath() + ["#Tasks", entityId].join("/")
        };
    }
});
