///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/grid/bindings/columns"/>
import * as $ from "jquery";
import * as logger from "Core/Medius.Core.Web/Scripts/Medius/lib/logger";
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as columnsHandler from "Core/Medius.Core.Web/Scripts/Medius/components/grid/ui/columnsHandler";
import {SCROLLABLE_DOM_KEY as scrollableKey} from "Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/scrollable";
import * as _ from "underscore";

const elementKey = "grid-columns";
const $window = $(window);

const gridColumns = {
    init: function (element:any, bindingAccessor:any, allAccessor:any, viewModel:any) {
        let $element = $(element);
        const scrollableData = koUtils.domData.get(element, scrollableKey) || {};
            
        let handler = columnsHandler.create(viewModel, $element.parent(), scrollableData.handler);
        handler.init();

        let resizeHandler = _.debounce(function () {
            handler.onWindowResize();
        }, 300);

        let tabShownHandler = function(e:any) {
            const tabId = $(e.target).attr("href");
            
            try {
                const parents = $element.parents(tabId);
                
                if (parents.length > 0) {
                    handler.fitColumns(true);
                }
            } catch (err) {
                logger.warn("medius/components/grid/bindings/columns.tabShownHandler: " + err);
            }
        };

        $window.on("shown", tabShownHandler);
        $window.resize(resizeHandler);

        let dispose = function () {
            $window.off("shown", tabShownHandler);
            $window.off("resize", resizeHandler);
            handler.dispose();
            resizeHandler = null;
            tabShownHandler = null;
            handler = null;
            $element = null;
            koUtils.domData.set(element, elementKey, null);
            $element = null;
            dispose = null;
        };

        koUtils.addDisposeCallback(element, dispose);

        koUtils.domData.set(element, elementKey, {
            handler: handler,
            dispose: dispose
        });
    }
};

export function register() {
    koUtils.registerBinding("grid-columns", gridColumns);
}