/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/demoManager/services"/>
import {ajax} from "Core/Medius.Core.Web/Scripts/Medius/core/rpc";

export function canCopyDatabase() {
    return ajax("DemoResetService", "CanCopyDatabase");
}

export function restoreDatabaseFromCopy() {
    return ajax("DemoResetService", "RestoreFromSnapshot");
}

export function createDatabaseCopy() {
    return ajax("DemoResetService", "CreateSnapshot");
}
