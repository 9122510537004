/* eslint-disable react/no-unknown-property */
///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/Components/AgentFeedbackComponent"/>
import * as React from "react";
import { useState } from "react";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { feedbackOptions } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/AgentConsts";
import {
    CalloutButton,
    Text,
    Button,
    ButtonGroup,
    IconToggleThumbsUpRegular, 
    IconToggleThumbsUpFill, 
    IconToggleThumbsDownRegular, 
    IconToggleThumbsDownFill
} from "@medius/ui-controls";
import { success, error } from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import { sendFeedback } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/services/agentServices";
import { IAgentFeedbackComponentProps } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/agentInterfaces";
import { FeedbackModal } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/Components/FeedbackModal";

export default function AgentFeedbackComponent({ documentId, conversationId, workflowStepName, invoiceType }: IAgentFeedbackComponentProps){
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [wasThumbsUpClicked, setWasThumbsUpClicked] = useState(false);
    const [wasThumbsDownClicked, setWasThumbsDownClicked] = useState(false);

    const handleCloseDialog = () => {
      setIsDialogOpen(false);
    };

    const submitNegativeFeedback = (feedback: string) => {
        if(wasThumbsUpClicked)
            return;
        if(wasThumbsDownClicked){
            error(translate("#Core/agentFeedbackWasAlreadySubmitted"), translate("#Core/agentFeedbackNotSent"));
            return;
        }
        sendFeedback(conversationId, documentId, feedback, workflowStepName, invoiceType).then(() => {
                success(translate("#Core/agentThankYouForLettingUsImproveMediusAgent"), translate("#Core/agentFeedbackSent"));
                setWasThumbsDownClicked(true);
                handleCloseDialog();
            }
        );
    };

    const submitPositiveFeedback = () => {
        if(wasThumbsDownClicked || wasThumbsUpClicked)
            return;
        sendFeedback(conversationId, documentId, "positive", workflowStepName, invoiceType).then(() => {
                success(translate("#Core/agentThankYouForLettingUsImproveMediusAgent"), translate("#Core/agentFeedbackSent"));
                setWasThumbsUpClicked(true);
            }
        );
    };

    return(
        <div 
            close-ai-agent-modal="false" 
            className="mediusAgent__copilot-feedback-button"
            style={{height: "15px"}}
        >
            <div 
                onClick={submitPositiveFeedback} 
                className="mediusAgent__copilot-feedback-button__upvote"
                data-testid="apa-copilot-feedback-message-upvote"
                style={ wasThumbsDownClicked == true ? { opacity: 0, cursor: "default" } : null }
            >
                { wasThumbsUpClicked ? <IconToggleThumbsUpFill color="brand" data-testid="apa-copilot-upvote-fill" /> : <IconToggleThumbsUpRegular color="brand" data-testid="apa-copilot-upvote-regular" /> }
            </div>
            {wasThumbsUpClicked == false && 
                <CalloutButton 
                    height="small"
                    data-testid="apa-copilot-feedback-message-downvote"
                    close-ai-agent-modal="false"
                    icon={wasThumbsDownClicked ? <IconToggleThumbsDownFill /> : <IconToggleThumbsDownRegular />}  
                    label={""}
                    disabled={isDialogOpen}
                    width="content"
                    variant="inline" 
                    calloutProps={{
                        title: "",
                        width: "small",
                        position: "bottom",
                        showHeader: false,
                        showActionsDivider: false,
                        renderContent: () => <div className="mediusAgent__copilot-feedback-negative-modal" data-testid="apa-copilot-feedback-negative-modal">
                            <Text alignment="left" variant="heading3">{translate("#Core/agentGiveUsFeedback")}</Text>
                            <ButtonGroup layout="row" spacerMode="on">
                                <Button 
                                    variant="secondary" 
                                    label={translate("#Core/agentFeedbackDontLikeTheStyle")} 
                                    close-ai-agent-modal="false" 
                                    onClick={() => submitNegativeFeedback(feedbackOptions[0])} 
                                    data-testid="apa-copilot-feedback-negative-modal-agentFeedbackDontLikeTheStyle"
                                />
                                <Button 
                                    variant="secondary" 
                                    label={translate("#Core/agentFeedbackNotFactuallyCorrect")} 
                                    close-ai-agent-modal="false" 
                                    onClick={() => submitNegativeFeedback(feedbackOptions[1])} 
                                    data-testid="apa-copilot-feedback-negative-modal-agentFeedbackNotFactuallyCorrect"
                                />
                                <Button 
                                    variant="secondary" 
                                    label={translate("#Core/agentFeedbackDidntFullyFollowInstructions")} 
                                    close-ai-agent-modal="false" 
                                    onClick={() => submitNegativeFeedback(feedbackOptions[2])} 
                                    data-testid="apa-copilot-feedback-negative-modal-agentFeedbackDidntFullyFollowInstructions"
                                />
                                <Button 
                                    variant="secondary" 
                                    label={translate("#Core/agentFeedbackRefusedWhenItShouldntHave")} 
                                    close-ai-agent-modal="false" 
                                    onClick={() => submitNegativeFeedback(feedbackOptions[3])} 
                                    data-testid="apa-copilot-feedback-negative-modal-agentFeedbackRefusedWhenItShouldntHave"
                                />
                                <Button 
                                    variant="secondary" 
                                    label={translate("#Core/agentFeedbackBeingLazy")} 
                                    close-ai-agent-modal="false" 
                                    onClick={() => submitNegativeFeedback(feedbackOptions[4])} 
                                    data-testid="apa-copilot-feedback-negative-modal-agentFeedbackBeingLazy"
                                />
                                <Button 
                                    variant="secondary" 
                                    label={translate("#Core/agentFeedbackMore")} 
                                    close-ai-agent-modal="false" 
                                    onClick={() => setIsDialogOpen(true)} 
                                    data-testid="agent-feedback-more" 
                                />
                            </ButtonGroup>
                        </div>,
                        renderActions: () => <></>
                    }} 
                />
            }
            <FeedbackModal 
                isOpen={isDialogOpen} 
                onClose={handleCloseDialog} 
                documentId={documentId} 
                conversationId={conversationId} 
                workflowStepName={workflowStepName} 
                invoiceType={invoiceType} 
                onConfirm={() => setWasThumbsDownClicked(true)}
            />
        </div>
    );
}