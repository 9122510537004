///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/editors/email/model"/>
import * as _ from "underscore";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { derive as basic } from "Core/Medius.Core.Web/Scripts/Medius/components/editors/basic";
import defaults = require("Core/Medius.Core.Web/Scripts/Medius/components/editors/email/defaults");
import * as helpers from "Core/Medius.Core.Web/Scripts/Medius/components/editors/helpers";

class Email {
    $input: any;
    constructor(bindingParameters:any, viewmodel:any, context:any, params:any) {
        this.$input = null;
        const config = helpers.mergeDefaults(bindingParameters, defaults.get());
        config.inputValidator = this.runNativeInputValidation.bind(this);

        basic.call(this, config, viewmodel, context, params);
    }

    setInputElement(element:any) {
        this.$input = element;
    }
    
    runNativeInputValidation() {
        const validity = this.$input.validity;

        if (validity) {
            if (validity.valid) {
                return true;
            }

            return {
                valid: false,
                validationDetails: globalization.getLabelTranslation("#Core/defaultValidation_EMAILATTRIBUTE")
            };
        } else {
            return true;
        }
    }
}

_.defaults(Email.prototype, basic.prototype);

export function create(bindingParameters:any, viewmodel:any, context:any, params:any) {
    return new Email(bindingParameters, viewmodel, context, params);
}