///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/newTooltip/errorSkin"/>
export const showOn = 'mouseenter';
export const hideOn = 'mouseleave';
export const position = {
    my: 'center bottom',
    at: 'center top',
    collision: 'flipfit flipfit'
};
export const css = {
    color: 'black',
    background: 'white',
    border: '1px solid red'
};
export const arrowCss = {
    'border-color': 'red'
};