///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/localizer/type/financial" />

import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import * as culture from "Core/Medius.Core.Web/Scripts/Medius/lib/culture";
import * as helpers from "Core/Medius.Core.Web/Scripts/Medius/components/editors/helpers";

function escape(text: string) {
    return text.replace(/[-[\]{}()*+?.,\\^$|#]/g, "\\" + text);
}

function buildPrepareStingInputRegex(positiveSign: any, negativeSign: any) {
    return new RegExp(`^\\s*(${escape(negativeSign)}|${escape(positiveSign)})\\s*`, "g");
}

function buildVerifyStringInputRegex(thousandsSeparator: any, fractionSeparator: any) {
    return new RegExp(`^[${escape(thousandsSeparator)}${escape(fractionSeparator)}0-9]+$`, "g");
}

function addThousandsSeparator(number: any, separator: any) {
    let s = number.split('').reverse().join('');
    const a = [], i = 3;
    do {
        a.push(s.substring(0, i));
    }
    while ((s = s.substring(i, s.length)) !== "");

    number = a.join(separator).split('').reverse().join('');
    return number;
}

function buildZeroFractionValue(precision: any) {
    let fractionValue = "";

    for (let i = 0; i < precision; i++)
        fractionValue += "0";

    return fractionValue;
}

function getFractionValue(value: any, fractionSeparator: any, resolution: any) {
    if (value.indexOf(fractionSeparator) === -1)
        return buildZeroFractionValue(resolution);

    const currentResolution = (value.length - 1) - value.indexOf(fractionSeparator);
    const fractionValue = value.split(fractionSeparator)[1];

    return fractionValue + buildZeroFractionValue(resolution - currentResolution);
}

export function fromString(number: string, options: any) {
    const numberFormat = culture.getNumberFormatDefinition(),
        fractionSeparator = numberFormat["."],
        positiveSign = numberFormat["+"],
        negativeSign = numberFormat["-"],
        decimalResolution = options.decimalResolution;
    let thousandsSeparator = numberFormat[","];

    if (isNullOrUndefined(number))
        return number;

    let sign = "";
    number = number.trim();
    if (number.charAt(0) === negativeSign)
        sign = "-"; // this sign is supposed to be deserialized by the backend

    number = number.replace(buildPrepareStingInputRegex(positiveSign, negativeSign), "");

    // all spaces in input must be replaced with non breaking spaces
    number = number.replace(/\s/g, String.fromCharCode(160));

    // thousadsSeparator cannot be a space, must be a non breaking space instead
    if (thousandsSeparator === " ")
        thousandsSeparator = String.fromCharCode(160);

    if (!buildVerifyStringInputRegex(thousandsSeparator, fractionSeparator).test(number))
        return undefined;

    if (number.split(fractionSeparator).length > 2)
        return undefined;

    if (number.indexOf(fractionSeparator) !== -1 && number.lastIndexOf(thousandsSeparator) > number.indexOf(fractionSeparator))
        return undefined;

    let fixedValue = number.split(fractionSeparator)[0];
    fixedValue = fixedValue.split(thousandsSeparator).join("");
    let fractionValue: string | number = number.split(fractionSeparator)[1];

    if (isNullOrUndefined(fractionValue))
        fractionValue = 0;

    const roundedValue = helpers.roundNumberAsString(`${fixedValue}.${fractionValue}`, decimalResolution);
    return parseFloat(sign + roundedValue);
}

export function toString(value: any, options: any) {
    options = options || {};

    if (isNullOrUndefined(value))
        return value;

    // TODO - in ideal scenario following should never be a number
    if (typeof (value) === "number")
        value = value.toFixed(options.decimalResolution);

    if (options.notLocalized === true)
        return value;

    const numberFormat = culture.getNumberFormatDefinition(),
        thousandsSeparator = numberFormat[","],
        fractionSeparator = numberFormat["."],
        negativeSign = numberFormat["-"];
        
    let sign = "";
    if (value.indexOf("-") === 0) {
        value = value.substring(1);
        sign = negativeSign;
    }

    let fixedValue = value.split(".")[0];
    fixedValue = addThousandsSeparator(fixedValue, thousandsSeparator);

    const fractionValue = getFractionValue(value, ".", options.decimalResolution);
    const result = sign + fixedValue + fractionSeparator + fractionValue;

    return result;
}