/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/html"/>
import {escape} from "underscore";

/** This function should escape at least <, >, &, ', " and / */
export function sanitize(str: string) {
    const forwardSlashHtmlCode = "&#x2F;";
    const partiallyEscaped = escape(str);
    const fullyEscaped = partiallyEscaped.replace(/\//g, forwardSlashHtmlCode);

    return fullyEscaped;
}
