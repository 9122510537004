///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/administration/bindings/withEntityCtxModule"/>
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as _ from "underscore";
import * as ko from "knockout";

function loadModule(moduleName: string, key: string | number, context:any) {
    let moduleVm;

    if (!context.$data.modules) {
        context.$data.modules = {};
    }

    // load from cache by key
    if (context.$data.modules[key]) {
        moduleVm = context.$data.modules[key];
    }
    // create a new instance and cache it
    else {
        const defaultViewModel = context.$data;
        const tabModel = context.$parent;

        const module = require(moduleName);
        moduleVm = module(defaultViewModel.EntityWithContext, defaultViewModel.CompanyContextId, tabModel);

        context.$data.modules[key] = moduleVm;
    }

    return moduleVm;
}

const withEntityCtxModule = {
    init: function (element: Node, bindingParams: () => { (): any; new(): any; module: any; key: string; }, allBindingsAccessor: any, viewModel: any, context: { $data: { modules: { [x: string]: any; }; }; createChildContext: (arg0: any) => any; }) {
        const module = bindingParams().module,
            key = bindingParams().key || _.uniqueId('module_');

        // load module from cache by key or initialize a new instance
        const moduleVm = loadModule(module, key, context);

        if (moduleVm && moduleVm.dispose) {
            ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
                if (moduleVm) {
                    moduleVm.dispose();
                }
                context.$data.modules[key] = null;
            });
        }

        const newContext = context.createChildContext(moduleVm);

        ko.applyBindingsToDescendants(newContext, element);

        return { controlsDescendantBindings: true };
    }
};

export function register(){
    koUtils.registerBinding("withEntityCtxModule", withEntityCtxModule);
}