/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Reminders/availableRemindersCollection"/>
import * as _ from "underscore";

class AvailableRemindersCollection {

    // tsc targeted to ES 5 has issues with the ES 6's Function.prototype.name property.
    public static className = "AvailableRemindersCollection";

    private availableReminders: IReminderDictionary;

    public getAvailableReminder(reminderType?: string , documentType?: string) {
        if (_.isEmpty(this.availableReminders)) {
            return null;
        }
        
        if (!reminderType) {
            const defaultValue = _.values(this.availableReminders);
            
            return defaultValue[0][0];
        }
        
        function filter(x: IReminder) {
            if (!documentType) {
                return true;
            }

            return _.contains(x.CompatibleDocumentTypes, documentType);
        }

        return _.chain(this.availableReminders[reminderType])
                    .filter(filter)
                    .first()
                    .value();
    }

    public getAvailableRemindersNamesArray() {
        return _.chain(this.availableReminders)
                .values()
                .flatten()
                .map(x => ({ ReminderType: x.ReminderType, NameTranslated: x.NameTranslated}))
                .uniq(false, x => x.ReminderType + x.NameTranslated)
                .value();
    }

    public getCompatibleDocumentTypes(reminderType: string) {
        const reminders = this.availableReminders[reminderType] ?? [];

        const value = reminders
            .reduce((acc, curr:IReminder) => acc.concat(curr.ReminderCompatibleTypes), []);

        return value;
    }

    public dispose() {
        this.availableReminders = null;
    }

    constructor(availableReminders: IReminderDictionary) {
        let remindersCount = 0;
        for (const prop in availableReminders) {
            if (availableReminders.hasOwnProperty(prop)) {
                remindersCount++;

                if (!(availableReminders[prop].length > 0)) {
                    throw new Error(`Constructing the instance of ${AvailableRemindersCollection.className} with no remiders for specified reminder type ${prop} is not possible.`);        
                }
            }
        }
        
        if (remindersCount === 0) {
            this.availableReminders = {};
        } else {
            this.availableReminders = availableReminders;
        }
    }
}

interface IReminder {
    ReminderType: string;
    NameTranslated: string;
    CompatibleDocumentTypes: string[];
    ReminderCompatibleTypes: unknown[];
}

interface IReminderDictionary {
    [reminder: string]: IReminder[];
}

export function create(availableReminders: any) {
    return new AvailableRemindersCollection(availableReminders);
}
