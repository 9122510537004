/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/kendo/bindings/kendoSplitter"/>
import * as $ from "jquery";
import * as ko from "knockout"

const kendoSplitterBinding = {
    update(element: HTMLElement, valueAccessor: any) {
        const $element = $(element);
        const options = ko.unwrap(valueAccessor().options);

        if (!$element.is("div"))
            throw new Error("Binding 'kendoSplitter' can be applied only on a DIV.");

        $element.kendoSplitter(options);
    }
}


export const register = () => {
    ko.bindingHandlers["kendoSplitter"] = kendoSplitterBinding;
}