///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Schedulers.ScheduledJobContext/Editor"/>
import mappings = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/mapping/collection/instance");
import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import * as _ from "underscore";

export function viewModelFactory(viewModel:any) {
    if(isNullOrUndefined(viewModel.ConsideredEntities())) {
        viewModel.ConsideredEntitiesList = [];
    } else {
        viewModel.ConsideredEntitiesList = viewModel.ConsideredEntities().split(",");
    }

    viewModel.dispose = () => {
        viewModel.ConsideredEntitiesList = null;
    };
}

export function register() {
    mappings.register("Medius.Schedulers.ScheduledJobContext", _.constant(true), viewModelFactory);
}