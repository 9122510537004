/// <amd-module name="Core/Medius.Core.Web/Scripts/components/sidebar/filters"/>
import * as React from "react";
import { GridFilterCellProps} from "@progress/kendo-react-grid";
import {Input} from "@progress/kendo-react-inputs";
import {ConfigurableTypeDto} from "Core/Medius.Core.Web/Scripts/AdminPages/administrationServices";

export function DisplayNameFilter({value, onChange}: GridFilterCellProps) {
    return (<Input
        data-testid="display-name-filter"
        className="sidebar__grid__input"
        value={value || ""}
        onChange={({target}) => {
            onChange({
                value: target.value ? target.value : "",
                operator: target.value ? "contains" : "",
                syntheticEvent: null as any // required by kendo API for on chage event, however not used here so null value
            });
        }}
        />);
}

export function filterByDisplayName(types: ConfigurableTypeDto[], filterValue: string) {
    return types.filter(t => t.displayName.toLowerCase().includes(filterValue.toLowerCase()) || t.fullName.toLowerCase().includes(filterValue.toLowerCase()));
}
