/// <amd-module name="Core/Medius.Core.Web/Scripts/components/mention/editor/mentionEditorFactory"/>
import * as _ from "underscore";
import { IParams, IEditorOptions } from "Core/Medius.Core.Web/Scripts/components/mention/editor/IEditorOptions";
import { defaults } from "Core/Medius.Core.Web/Scripts/components/mention/editor/defaults";
import { Editor } from "Core/Medius.Core.Web/Scripts/components/mention/editor/editor";
import { DisabledMentionsEditor } from "Core/Medius.Core.Web/Scripts/components/mention/editor/disabledMentionsEditor";
import { UserMentionSuggestionsProvider} from "Core/Medius.Core.Web/Scripts/components/mention/editor/userMentionSuggestionsProvider";

export class MentionEditorFactory
{
    private static instance: MentionEditorFactory;
    private constructor() { }

    public static getInstance() {
        if (!this.instance) {
            this.instance = new MentionEditorFactory();
        }
        return this.instance;
    }

    public create(params: IParams) {
        const optionsMerged = _.extend({}, defaults, params.options);
        
        const editor = params.mentionsEnabled ? this.createWithMentionsEnabled(params.value, optionsMerged, params.documentId)
            : this.createWithMentionsDisabled(params.value, optionsMerged);

        return editor;
    }

    private createWithMentionsEnabled(value: ko.Observable<string>, options: IEditorOptions, documentId: number) {
        if (!documentId)
            throw "documentId param is required";

        options.suggestionsProvider = new UserMentionSuggestionsProvider(documentId);

        return new Editor(value, options);
    }

    private createWithMentionsDisabled(value: ko.Observable<string>, options: IEditorOptions) {
        return new DisabledMentionsEditor(value, options);
    }

}
