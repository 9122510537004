/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/task/models/putOnHold"/>
import {observable, pureComputed, subscribable} from "knockout";
import { MentionEditorFactory } from "Core/Medius.Core.Web/Scripts/components/mention/editor/mentionEditorFactory";
import { Editor } from "Core/Medius.Core.Web/Scripts/components/mention/editor/editor";
import { DisabledMentionsEditor } from "Core/Medius.Core.Web/Scripts/components/mention/editor/disabledMentionsEditor";
import { isUserAuthorized, authorizationKeysConstants } from "Core/Medius.Core.Web/Scripts/Medius/core/authorizationKeys";
import * as backendErrorHandler from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import {translate} from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as notification from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import { InboxInstance as inbox } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/inbox";
import {request} from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rpc";
import {isEmptyString} from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";

export default class PutOnHold {
    private errorHandler: (error: JQueryXHR) => void;

    private task: any;
    public header = translate("#Core/onHold");
    private onHoldReason = observable("");
    private shouldHidePopUp = new subscribable();
    private mentionsEnabled: boolean;
    private putOnHoldOnGoing = observable(false);
    private canPutOnHold: ko.Computed<boolean>;
    private showPutOnHold: ko.Computed<boolean>;

    private mentionEditor: Editor | DisabledMentionsEditor;

    constructor(task: any) {
        this.task = task;
        this.mentionsEnabled = !!this.task.Document().Id();

        this.mentionEditor = MentionEditorFactory.getInstance().create({
            mentionsEnabled: this.mentionsEnabled,
            value: this.onHoldReason,
            documentId: this.task.Document().Id(),
            options: {
                rows: 7,
                placeholder: this.mentionsEnabled ? translate("#Core/writeACommentMentionsEnabled") : translate("#Core/writeAComment")
            }
        });

        this.canPutOnHold = pureComputed(() => this.onHoldReason() && !isEmptyString(this.onHoldReason()) && !this.putOnHoldOnGoing());

        this.showPutOnHold = pureComputed(() => {

            const canUserPutOnHold = isUserAuthorized(authorizationKeysConstants.PutOnHold);
            return (canUserPutOnHold && this.isDocumentOnHold() === false);
        });

        this.errorHandler = (error: JQueryXHR) => {
            backendErrorHandler.handleAnyError(error);
        };
    }

    public dispose() {
        this.mentionEditor.dispose();
        this.canPutOnHold.dispose();
        this.showPutOnHold.dispose();
        this.task = null;
    }

    public isDocumentOnHold() {
        const perspective = this.task.Perspective();
        return (perspective && perspective.IsOnHold());
    }

    public putOnHold() {
        this.putOnHoldOnGoing(true);

        this.shouldHidePopUp.notifySubscribers();

        this.task.saveUnsavedComment();
        return request("DocumentOnHoldService", "PutOnHold", {
                documentId: this.task.Document().Id(),
                onHoldReason: this.onHoldReason()
            })
        .done(() => {
            // mark task as 'on hold' in inbox
            inbox.setCurrentTaskOnHoldStatus();

            notification.success(translate("#Core/documentHasBeenPutOnHold"));
            // go to next task in inbox
            inbox.onTaskHandled(this.task.Id());
        })
        .fail((data: JQueryXHR) => {
            this.errorHandler(data);
        })
        .always(() => {
            this.putOnHoldOnGoing(false);
        });
    }
}
