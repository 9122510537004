/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/image/documentImageProvider"/>
import { observable, computed } from "knockout";
import { createUrl } from "Core/Medius.Core.Web/Scripts/Medius/core/rest";
import {unpack} from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";

class DocumentImageProvider {
    currentFile: any;
    hashFiles: any;
    hashFile: ko.Computed<any>;
    haveFile: ko.Computed<any>;
    getImageUrl: (downloadType?: string, action?: string) => string;

    openUrl = (action?: string) => {
        if (!this.hashFile()) {
            return;
        }

        window.open(this.getImageUrl("application/pdf;base64", action), "_blank", "fullscreen=0, toolbar=0, location=0, menubar=0");
    };

    dispose = () => {
        this.haveFile.dispose();
        this.hashFile.dispose();
    };
    constructor(docId: any, docType: any, hashFiles: any) {
        this.currentFile = observable(1);

        this.hashFiles = unpack(hashFiles);

        this.hashFile = computed(() => this.hashFiles[this.currentFile() - 1]);

        // If any file is currently loaded for document
        this.haveFile = computed(() => this.hashFile());

        // the url to the current image (pdf)
        this.getImageUrl = (downloadType = "attachment", action = "display") => {
            if (this.haveFile()) {
                const hash = unpack(this.hashFile().Hash);
                const tag = unpack(this.hashFile().Type);
                return createUrl("MediaService",`image/${hash}/pdf?docId=${docId}&docType=${docType}&tag=${tag}&download=${downloadType}&action=${action}`);
            }
            else {
                return "";
            }
        };
    }
}

export function create(docId: any, docType: any, hashFiles: any) {
    return new DocumentImageProvider(docId, docType, hashFiles);
}