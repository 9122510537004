///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/document/bindings/showModal"/>
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as ko from "knockout";
import * as $ from "jquery";

const showModal = {
    init: function (element: Node) {
        $(element).modal({ backdrop: 'static', keyboard: true, show: false });

        koUtils.addDisposeCallback(element, () => {
            $(element).modal("destroy");
        });
    },
    update: function (element: string | Element | JQuery, valueAccessor: () => any) {

        const value = valueAccessor();
        if (ko.utils.unwrapObservable(value)) {
            $(element).modal('show');
            $("input", element).focus();
        }
        else {

            $(element).modal('hide');
        }
    }
};

export function register() {
    koUtils.registerBinding("showModal", showModal);
}