///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/presenters/text/model" />

import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as html from "Core/Medius.Core.Web/Scripts/Medius/core/html";
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as _ from "underscore";
import * as ko from "knockout";
import { Basic } from "Core/Medius.Core.Web/Scripts/Medius/components/presenters/basic";
import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";

const newLineRegex = /\r?\n/g;

class TextPresenter extends Basic{
    Multiline: any;
    Output: any;

    constructor(bindingParameters: any = {}){
        let template: string;
        if (bindingParameters.multiline) {
            template = "presenters-text-multiline";
        } else if (bindingParameters.maxlength) {
            template = "presenters-text-maxlength";
        } else {
            template = "presenters-text-default";
        }

        const defaults = {
            template: template,
            multiline: false,
            dataQa: "presenters-text",
            ...bindingParameters
        };

        super(defaults);

        this.Multiline = this.params?.multiline;
        this.Output = ko.computed(() => {
            let value = koUtils.unpack(this.OriginalValue);
            let errormsg: string;
    
            if (isNullOrUndefined(value)) {
                const fallbackValue = koUtils.unpack(bindingParameters.fallbackValue);
                if (!fallbackValue) {
                    return value;
                }
                value = fallbackValue;
            } else if (typeof value !== "string") {
                errormsg = (`Value ${value} should be a string`);
                throw new Error(errormsg);
            }
    
            let output = globalization.getLabelTranslation(value);
            output = globalization.getPropertyTranslation(output);
            output = html.sanitize(output);
    
            if (this.Multiline === true) {
                output = output.replace(newLineRegex, "<br>");
            }
    
            return output;
        });
    }


    dispose() {
        this.Output.dispose();
    }
}

export function create(bindingParameters: any){
    return new TextPresenter(bindingParameters);
}

export function extend(obj: any, bindingParameters: any){
    const textPresenterinstance = new TextPresenter(bindingParameters);
    _.extend(obj, textPresenterinstance);
}