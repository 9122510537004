///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Entities.User/Roles"/>
import * as entityGrid from "Core/Medius.Core.Web/Scripts/Medius/components/grid/entity/grid";
import * as typeResolver from "Core/Medius.Core.Web/Scripts/Medius/components/resolver/type";
import * as sync from "Core/Medius.Core.Web/Scripts/Medius/core/sync";
import * as rpc from "Core/Medius.Core.Web/Scripts/Medius/core/rpc";
import * as serialization from "Core/Medius.Core.Web/Scripts/Medius/lib/serialization";
import * as backendErrorHandler from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import * as ko from "knockout";
import * as _ from "underscore";
import { RolesDataSource } from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Entities.User/rolesDataSource";
import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";

class RolesTab {
    Entity: any;
    Context: any;
    Tab: any;
    CompanyContextId: any;
    Collection: any;
    Grid: any;
    effectiveRoles: ko.ObservableArray<any>;
    selectedCompany: ko.Observable<any>;
    selectedRole: ko.Observable<any>;
    isCompanyNotSelected: ko.Computed<any>;
    canAddRoleCompany: ko.Computed<boolean>;
    addEntry: () => void;
    RolesPreviewContextSubscription: any;
    selectedPreviewCompany: any;
    CollectionHandler: null;
    constructor(entityWithContext:{Entity:any, Context:any}, companyContextId:string, tabModel:any) {
        const entityModel = entityWithContext.Entity;

        if (isNullOrUndefined(entityModel)) {
            throw "medius/viewModels/administration/Medius.Core.Entities.User/Roles: Entity model not defined";
        }

        const propertyName = tabModel.RelatedProperty();
        const elementType = typeResolver.getCollectionPropertyElementType(entityModel.$type(), propertyName);

        this.Entity = entityModel;
        this.Context = entityWithContext.Context;

        this.Tab = tabModel;
        this.CompanyContextId = companyContextId;
        this.Collection = this.resolveCollection(this.Entity, propertyName);
        this.Grid = this.createGrid(elementType, this.Collection, this.CompanyContextId);
        this.effectiveRoles = ko.observableArray([]);
        this.selectedCompany = ko.observable(null);
        this.selectedRole = ko.observable(null);
        this.selectedPreviewCompany = ko.observable(null);
        this.isCompanyNotSelected = ko.computed(() => {
            return isNullOrUndefined(this.selectedCompany());
        });
        this.canAddRoleCompany = ko.computed(() => {
            if (this.isCompanyNotSelected() || isNullOrUndefined(this.selectedRole())) {
                return false;
            }
            return !_(this.Collection()).find((elem) => {
                return elem.Role().Id() === this.selectedRole().Id() && elem.Company().Id() === this.selectedCompany().Id();
            });
        });

        this.addEntry = () => {
            if (!_.isUndefined(this.selectedRole()) && !_.isUndefined(this.selectedCompany())) {
                let newRoleCompany = sync.getNew("Medius.Core.Entities.RoleCompany");
                newRoleCompany = this.Context.create(newRoleCompany);
                newRoleCompany.Role(this.selectedRole());
                newRoleCompany.Company(this.selectedCompany());

                this.Collection.push(newRoleCompany);
                this.Grid.addRow(newRoleCompany);
                this.Grid.refresh();
            }
        };


        this.RolesPreviewContextSubscription =
            this.selectedPreviewCompany.subscribeToChange(this.rolesPreviewContextChanged.bind(this));
    }

    createGrid(type:string, collection:any, companyContextId:string) {
        const options = {
            dataSource: new RolesDataSource(collection),
            companyContextId: companyContextId,
            search: false,
            tplRow: "Core:templates/Administration/User/RolesTpl.html",
            onRemoveRow: (row:any) => {
                collection.remove(row);
            }
        };

        return entityGrid.create(type, options);
    }

    resolveCollection(entityModel:any, propertyName:string) {
        const collection = entityModel[propertyName];

        if (!collection) {
            throw new Error(`Property ${propertyName} does not exist`);
        }

        const personalRolesCollection = _(collection()).reject((role) => {
            return !role.IsOwnPersonalRole();
        });

        const noPersonalRolesCollection = ko.observableArray(ko.utils.arrayFilter(collection(), (role) => {
            return !role.IsOwnPersonalRole();
        }));

        noPersonalRolesCollection.subscribe((latestValue) => {
            const roles = _(personalRolesCollection).union(latestValue);
            this.Entity.Roles(roles);
        });

        return noPersonalRolesCollection;
    }

    rolesPreviewContextChanged(context:any) {
        if (isNullOrUndefined(context)) {
            this.effectiveRoles([]);
            return;
        }

        rpc.ajax('UserService', 'GetEffectiveRoles', {
            data: serialization.toJSON({
                userId: this.Entity.Id,
                contextCompany: context
            })
        }).done((roleCompanies) => {
            this.effectiveRoles(roleCompanies);
        }).fail((xhr) => {
            backendErrorHandler.handleAnyError(xhr);
        });
    }

    dispose() {
        this.Entity = null;
        this.Tab = null;
        this.CompanyContextId = null;
        this.Grid.destroy();
        this.Grid = null;
        this.CollectionHandler = null;
        this.RolesPreviewContextSubscription.dispose();
        this.effectiveRoles = null;
        this.selectedPreviewCompany = null;
        this.selectedCompany = null;
        this.selectedRole = null;
        this.isCompanyNotSelected.dispose();
        this.canAddRoleCompany.dispose();
    }
}

export = function create(entityWithContext:{Entity:any, Context:any}, companyContextId:string, tabModel:any) {
    return new RolesTab(entityWithContext, companyContextId, tabModel);
};