/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/currency/dataProvider"/>
import * as ko from "knockout";
import * as rest from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rest";

export interface CurrencyDto {
    Id: ko.Observable<number>;
    Code: ko.Observable<string>;
}

function search(searchTerm: string, maxResults: number, companyId?: number): JQueryPromise<any> {
    if(companyId)
        return rest.get("real-currencies", `?searchTerm=${encodeURIComponent(searchTerm)}&maxResults=${maxResults}&companyId=${companyId}`);
    else
        return rest.get("real-currencies", `?searchTerm=${encodeURIComponent(searchTerm)}&maxResults=${maxResults}`);
}

interface Company {
    Id: ko.Observable<number>;
}

export default class CurrencyDataProvider {
    companyContext:  ko.Observable<Company>;
    constructor(params: any) {
        this.companyContext = params.companyContext;
    }

    public search(searchTerm: string, maxResults: number) {
        return search(searchTerm, maxResults, this.companyContext?.()?.Id())
            .then(currencyDtos => {
                // We need to wrap results into observables for backward compatibility
                // so view models that use context created currencies will work
                // For example: currency on purchase requisition head
                return currencyDtos.map((currencyDto: any) => ({
                    Id: ko.observable(currencyDto.Id),
                    Code: ko.observable(currencyDto.Code)
                }));
            });
    }

    public exact(searchTerm: string, maxResults: number) {
        return search(searchTerm, maxResults, this.companyContext?.()?.Id());
    }

    public createDummy(): any {
        return null;
    }

}
