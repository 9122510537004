/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/ImportManagement/interfaces"/>
export interface QueueItem {
    id: number;
    itemType: string;
    itemId: string;
    state: QueueItemState;
    itemCount: number;
    itemSaved: number;
    isSuccessful: boolean;
    createdTimestamp: Date;
    selected: boolean;
    details: string;
    processedTime: string;
    importTime: string;
    successfullyProcessed: boolean;
    logs: {
        log: string;
        createdTimestamp: string;
    }[];
}

export enum QueueItemState {
    Ready = "Ready",
    Error = "Error",
    Processing = "Processing",
    Processed = "Processed"
}

export interface MasterDataErrorDto {
    id: number;
    externalSystemId: string;
    serializedMasterData: string;
    integrationErrors: IntegrationErrorDto[];
}

interface IntegrationErrorDto {
    errorMessage: string;
    exception: string;
}

export interface QueueItemsDto {
    queueItems: QueueItemDto[];
    totalCount: number;
}

export interface QueueItemDto {
    id: number;
    itemType: string;
    itemId: string;
    itemCount: number;
    itemSaved: number;
    processedTime: string;
    importTime: string;
    state: QueueItemState;
    details: string;
    createdTimestamp: string;
    workId: string;
    isSuccessful: boolean;
    erpSourceId: string;
    logs: QueueItemLogsDto[];
}

interface QueueItemLogsDto {
    log: string;
    createdTimestamp: string;
}

export interface ErrorItem {
    id: number;
    externalSystemId: string;
    serializedMasterData: string;
    integrationErrors: {
        errorMessage: string;
        exception: string;
    }[];
    selected: boolean;
    expanded: boolean;
}
