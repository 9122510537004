/// <amd-module name="Core/Medius.Core.Web/Scripts/components/staticDashboard/staticDashboardCollection"/>
import * as ko from "knockout";
import * as _ from "underscore";

export const dashboards = ko.observableArray<IStaticDashboard>([]);

export function registerStaticDashboard(dashboard: IStaticDashboard) {
    const alreadyExisting = _.find(dashboards(), d => d.id === dashboard.id);

    if (alreadyExisting)
        throw new Error("Tried to register the same static dashboard twice.");

    dashboard.isAccessible().then(result => {
        if (result) { dashboards.push(dashboard); }
    });
}
export interface IStaticDashboard {
    id: string;
    tabId: string;
    containerId: string;
    hrefContainerId: string;
    isOwned: boolean;
    isVisible: ko.Observable<boolean>;
    isAccessible(): Promise<boolean>;
}
