///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/transformers/update/array"/>
import * as helpers from "Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/helpers";
import * as ko from "knockout";

export = function arrayUpdater(targetAccessor:any) {
    const value: any[] = [];

    return {
        topDown: function (args:any) {
            if (ko.isWriteableObservable(targetAccessor)) {
                return;
            }

            targetAccessor = ko.observableArray();
            args.setTargetData(targetAccessor);
        },
        beforeDescend: function (args:any) {
            // just ignore target value, it will either be fetched from cache or rebuilt
            args.setTargetProperty(undefined, true);
        },
        afterDescend: function (args:any) {
            let currentValue;
            const sourceProperty = args.getSourceProperty();

            switch (typeof sourceProperty) {
                case "number":
                case "string":
                case "boolean":
                    currentValue = sourceProperty;
                    break;

                case "object":
                    if (helpers.isDate(sourceProperty)) {
                        currentValue = sourceProperty;
                        break;
                    }
                    currentValue = ko.utils.unwrapObservable(args.getTargetProperty());
                    break;
            }
            value[args.propertyName] = currentValue || undefined;
        },
        bottomUp: function () {
            targetAccessor(value);
        }
    };
};