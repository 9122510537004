///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/grid/default/defaults"/>
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as ko from "knockout";

export function get() {
    return {
        tplHeader: "grid-tpl-header",
        tplColgroup: "grid-tpl-colgroup",
        tplHead: "grid-tpl-head",
        tplHeadCell: "grid-tpl-head-cell",
        tplRow: "grid-tpl-row",
        tplCell: "grid-tpl-cell",
        tplNoRows: "grid-tpl-no-rows",
        tplFoot: "grid-tpl-foot",
        tplTools: "grid-tpl-tools",
        tplFooter: "grid-tpl-footer",
        template: "grid-tpl-default",
        templateEngine: (ko.nativeTemplateEngine as any).instance,
        defaultColumnWidth: 100,
        pageSize: 25,
        editPageSize: false,
        selectColumns: false,
        exportData: false,
        downloadImagesToPdf: false,
        multiselect: false,
        multiselectInitialPredicate: null as any,
        search: true,
        sort: true,
        paging: true,
        resizable: true,
        draggable: false,
        draggableLabel: function (draggedRows:any) {
            return "Dragged rows: " + draggedRows.length;
        },
        onClickRow: null as any,
        onRemoveRow: null as any,
        dataQuery: null as any,
        parser: null as any,
        availablePageSizes: ["5", "10", "25", "50", "100"],
        columnWidthStore: null as any,
        columnVisibleStore: null as any,
        hideWhenEmpty: false,
        descriptionSingularForm: globalization
            .getLabelTranslation("#Core/gridDataInformationBasicDescriptionItemSingular"),
        descriptionPluralForm: globalization
            .getLabelTranslation("#Core/gridDataInformationBasicDescriptionItemPlural"),
        nativeScroll: true,
        limit: null as any
    };
}