/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/CurrencyRate/General/general"/>
import * as ko from "knockout";

import {IEntityWithContext} from "Core/Medius.Core.Web/Scripts/AdminPages/IEntityWithContext";

class CurrencyRate {
    public BaseCurrency: ko.Computed<any>;
    public Entity: any;

    constructor(entityWithContext: IEntityWithContext) {
        this.Entity = entityWithContext.Entity;
        this.BaseCurrency = ko.pureComputed(() => {
            const company = ko.unwrap(this.Entity.Company);
            const currency = company ? ko.unwrap(company.AccountingCurrency) : null;
            return (this.Entity && company && currency) ? currency.Code() : null; 
        });
    }
}

export default function create(entityWithContext: IEntityWithContext, _companyContextId: number, _tabModel: any) {
    return new CurrencyRate(entityWithContext);
}
