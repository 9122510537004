/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/inbox/taskGroup/gridOperations" />

import * as _ from "underscore";

export function sortByColumn(grid: any, column: any, order: string): false | void {
    if (!column || order === "" || !_.contains(["asc", "desc"], order)) {
        return false;
    }
    
    const columnSortingStore = grid.options.columnSortingStore;
    const folderId = grid.options.folderId;
    const groupName = grid.options.groupFullName;
    
    const newOrderCriteria = [{
        DocumentType: groupName,
        Path: (column.OrderBySource as string).split("_").join("."),
        Type: order,
        FolderId: folderId,
    }];

    columnSortingStore.getItem(folderId)
        .then((orderCriteriaJson: string) => orderCriteriaJson ? JSON.parse(orderCriteriaJson) : [])
        .fail((): any => [])
        .then((orderCriteriaList: any[]) => 
            orderCriteriaList.filter(orderCriteria => orderCriteria.DocumentType !== groupName).concat(newOrderCriteria))
        .then((newOrderCriteriaList: any[]) => {
            columnSortingStore.setItem(`${folderId}`, JSON.stringify(newOrderCriteriaList));
            grid.sortingColumn = column.Key;
            grid.sortingOrder = order;
            const valueSource = (column.OrderBySource) ? column.OrderBySource : column.ValueSource;

            grid.DataQuery.sorting = {[valueSource]: order};

            grid.refresh();
        });
}