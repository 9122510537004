/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/models/options/shortcuts"/>

import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import * as _ from "underscore";
import * as manager from "Core/Medius.Core.Web/Scripts/lib/shortcutManager";
import { applyBindings } from "knockout";

class ShortcutsManager {
    isOpen: boolean;
    modalElement: any;
    groups: { groupName: string; shortcuts: any[]; }[];
    open: () => void;
    close: () => void;
    toggleVisibility: () => void;
    constructor(modalElement: any) {
        const groupedShortcuts = _.groupBy(manager.getAllDefinedShortcuts(), function(g) {
                return g.groupName;
            }),
            shortcutGroups = _(groupedShortcuts).keys().map(function(key) {
                const filteredShortCuts = _.reject(groupedShortcuts[key], function (s) {
                    return s.skipInHelp;
                });
                return {
                    groupName: key,
                    shortcuts: _.map(filteredShortCuts, function (s) {
                        if (isNullOrUndefined(s.managerDescription)) {
                            s.managerDescription = s.description;
                        }
                        return s;
                    })
            };
            });
        
        this.isOpen = false;
        this.modalElement = modalElement;
        this.groups = shortcutGroups;

        this.open = () => {
            this.isOpen = true;
            this.modalElement.modal('show');
        };

        this.close = () => {
            this.isOpen = false;
            this.modalElement.modal('hide');
        };

        this.toggleVisibility = () => {
            this.isOpen ? this.close() : this.open();
        };
    }
}

export function bind(targetElement: any, modalElement: any) {

    const viewModel = new ShortcutsManager(modalElement);
    
    targetElement.each(function (targetElement: any, e: any) {
        applyBindings(viewModel, e);
    });

    return viewModel;
}
