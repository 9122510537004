///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/documentSearch/resultGroup/dataSource"/>
import * as ko from "knockout";
import * as serialization from "Core/Medius.Core.Web/Scripts/Medius/lib/serialization";
import * as rest from "Core/Medius.Core.Web/Scripts/Medius/core/rest";
import DefaultDataSource = require("Core/Medius.Core.Web/Scripts/Medius/components/grid/dataSource/default");
import * as metadataColumns from "Core/Medius.Core.Web/Scripts/Medius/apps/documentSearch/metadataColumns";
import * as _ from "underscore";
import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import * as $ from "jquery";

import * as typeResolver from "Core/Medius.Core.Web/Scripts/Medius/components/resolver/type";
    
function toValueSource(path: string) {
    if (!path) {
        return path;
    }

    return path.replace(/[.]/g, "_");
}
    
function fromValueSource(valueSource: string) {
    if (!valueSource) {
        return valueSource;
    }

    return valueSource.replace(/[_]/g, ".");
}
    
function checkHasCollection(type: any, valueSource: any) {
    return typeResolver.containsCollection(type, valueSource);
}
    
function checkIsIgnored(type: any, valueSource: any) {
    return typeResolver.isIgnored(type, valueSource);
}
    
function checkIsMetaColumn(metaColumns: any, valueSource: any) {
    return _.any(metaColumns, (item) => {
        return item.Key === valueSource;
    });
}

class ResultGroupDataSource extends DefaultDataSource{
    resultGroup: any;
    initialRows: any;
    totalRows: any;
    columns: any;
    initialized = false;
    availableMetadataColumns: ko.Computed<any[]>;
    
    constructor(resultGroup: any, columns: any, initialRows: any, initialTotal: any) {
        super();
            this.resultGroup = resultGroup;
            this.initialRows = initialRows;
            this.totalRows = initialTotal;
            this.columns = columns;
            this.availableMetadataColumns = ko.computed(() => {
                return metadataColumns.availableMetadataColumns();
            });         
    }

    loadColumns() {
        const columns: Array<any> = [],
        type = this.resultGroup.type,
        metaColumns = this.availableMetadataColumns();
        
        let groupColumns = this.columns;

        if (isNullOrUndefined(groupColumns) || groupColumns.length === 0) {
            groupColumns = [
                { Sortable: true, Searchable: false, Name: "ID", ValueSource: "Id" }
            ];
        }
        
        _(groupColumns).each((column) => {
            const hasCollection = checkHasCollection(type, column.ValueSource);

            columns.push({
                Sortable: !(hasCollection ||
                    checkIsMetaColumn(metaColumns, column.ValueSource) ||
                    checkIsIgnored(type, column.ValueSource)),
                Searchable: false,
                Name: column.ColumnName,
                HasCollection: hasCollection,
                ValueSource: toValueSource(column.ValueSource)
            });
        });
       
        return $.Deferred<any[]>().resolve(columns);
    }

    load(dataQuery: { sorting: any; page: number; pageSize: number; }) {
        
        if (!this.initialized) {
            this.initialized = true;
        
            const rows = this.initialRows || [];
            return $.Deferred().resolve(rows);
        }
        
        let query = this.resultGroup.truncatedQuery;
        
        if (dataQuery.sorting) {
            const sortingOrder = _.chain(dataQuery.sorting).values().first().value();
            const orderBy = _.chain(dataQuery.sorting).keys().first().value();
            query.Query.Order = (sortingOrder) ? sortingOrder.toUpperCase() : null;
            query.Query.OrderBy = (orderBy) ? fromValueSource(orderBy) : null;
        }

        query = encodeURIComponent(serialization.toJSON(query));

        return rest.get("DataSearchManager", "searchPaged?query=" + query + "&page=" + dataQuery.page + "&limit=" + dataQuery.pageSize)
            .pipe((nextPage: { Entities: any; }) => {
                return nextPage.Entities || [];
            });
    }
    
    getTotalRows() {
        return $.Deferred<number>().resolve(this.totalRows);
    }

}

export function create(resultGroup: any, columns: any, initialRows: any, initialTotal: any) {
    return new ResultGroupDataSource(resultGroup, columns, initialRows, initialTotal);
}