///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/provider/operators"/>
import * as _ from "underscore";
import * as rest from "Core/Medius.Core.Web/Scripts/Medius/core/rest";
import * as ko from "knockout";
import * as logger from "Core/Medius.Core.Web/Scripts/Medius/lib/logger";
import * as typeResolver from "Core/Medius.Core.Web/Scripts/Medius/components/resolver/type";
import * as lexemeHelper from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/lexeme/helpers";
import { isEmptyString } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";

let operators:any[] = [];
const separators:string[] = [";"];

function isTypeSupported(supportedTypesString:string, type:string) {
    if (isEmptyString(supportedTypesString) || isEmptyString(type)) {
        return false;
    }
    const supportedTypes = supportedTypesString.split(/, /);
        
    return _.any(supportedTypes, function (supportedType) {
        if (isCollectionType(type) && isCollectionType(supportedType)) {
            type = getCollectionElementType(type);
            supportedType = getCollectionElementType(supportedType);
        }
        return typeResolver.isSameClassOrSubclass(type, supportedType);
    });
}
function isCollectionType(type:string) {
    if (isEmptyString(type)) {
        return false;
    }
    return type.indexOf('System.Collections.Generic.IList`1') > -1 || type.indexOf('System.Collections.Generic.IEnumerable`1') > -1;
}

function getCollectionElementType(type:string) {
    if (isEmptyString(type)) {
        return null;
    }
    return (/[a-zA-Z. ]*`1\[([a-zA-Z. ]*)\]/).exec(type)[1];
}
export function load() {
    return rest.get("DataSearchManager", "operators").done(function (result:any) {
        operators = result;
    }).fail(logger.error);
}
export function getOperators() {
    return operators;
}
export function getSeparators() {
    return separators;
}
export function getOperatorName(operatorTranslation:string) {
    const opDefinition = _.find(this.getOperators(), function (op) {
        return op.Translation === operatorTranslation;
    });

    if (_.isUndefined(opDefinition)) {
        return operatorTranslation;
    }

    return opDefinition.Operator;
}
export function getOperatorTranslation(operator:string) {
    const opDefinition = _.find(this.getOperators(), function (op) {
        return op.Operator === operator;
    });

    if (_.isUndefined(opDefinition)) {
        return operator;
    }

    return opDefinition.Translation;
}
export function getLogicalOperators() {
    const logicalOperators =_.chain(this.getOperators())
        .filter(function (opDef) {
            return opDef.OpType === 'Logical';
        }).map(function (opDef) {
            return opDef.Translation;
        }).value();

    return logicalOperators;
}
export function getLogicalOperatorsWithoutNegation() {
    const logicalOperators =_.chain(this.getOperators())
        .filter(function (opDef) {
            return opDef.OpType === 'Logical' && opDef.Operator !== "not";
        }).map(function (opDef) {
            return opDef.Translation;
        }).value();

    return logicalOperators;
}
export function getLogicalConjunctionOperator() {
    const andOperator = _.find(this.getOperators(), function (opDef) {
        return opDef.OpType === "Logical" && opDef.Operator === "and";
    });
            
    if (!andOperator) {
        return null;
    }

    return andOperator.Translation;
}
export function getLogicalNegationOperator() {
    const notOperator = _.find(this.getOperators(), function (opDef) {
        return opDef.OpType === "Logical" && opDef.Operator === "not";
    });

    if (!notOperator) {
        return null;
    }

    return notOperator.Translation;
}
export function getUnaryOperators(entityType:string) {
    const operatorsDefinitions = _.filter(this.getOperators(), function (opDef) {
        return opDef.OpType === 'Unary' && isTypeSupported(opDef.SupportedEntityTypes, entityType);
    });

    return _.map(operatorsDefinitions, function(opDef) {
        return opDef.Translation;
    });
}
export function getBinaryOperators(propertyType:string) {
    const binaryOperators = _.chain(this.getOperators())
        .filter(function(opDef) {
            return opDef.OpType === 'Binary' && isTypeSupported(opDef.SupportedTypes, propertyType);
        }).map(function(opDef) {
            return opDef.Translation;
        }).value();
            
    return _.uniq(binaryOperators);
}
export function getNullaryOperators(propertyType:string) {
    const nullaryOperators = _.chain(this.getOperators())
        .filter(function (opDef) {
            return opDef.OpType === 'Nullary' && isTypeSupported(opDef.SupportedTypes, propertyType);
        }).map(function (opDef) {
            return opDef.Translation;
        }).value();

    return nullaryOperators;
}
export function getBinaryOperatorsWithArity(propertyType:string, arities:any) {
    const binaryOperators = _.chain(this.getOperators())
        .filter(function (opDef) {
            const arityInRange = _.contains(arities, opDef.Arity);
            return opDef.OpType === 'Binary' && isTypeSupported(opDef.SupportedTypes, propertyType) && arityInRange;
        }).map(function (opDef) {
            return {
                Translation: ko.observable(opDef.Translation),
                Arity: ko.observable(opDef.Arity)
            };
        }).value();
            
    return binaryOperators;
}
export function getOrderByOperator() {
    return this.getOperatorTranslation('orderBy');
}
export function isCorrectUnaryOperator(entityType:string, lexeme:string) {
    lexeme = lexemeHelper.removeQuotes(lexeme);
    return _.contains(this.getUnaryOperators(entityType), lexeme);
}
export function getOperatorDataByName(operatorName:string) {
    return _.find(this.getOperators(), function(opDef) {
        return operatorName === opDef.Operator;
    });
}
export function getOperatorDataByTranslation(operatorTranslation:string) {
    operatorTranslation = lexemeHelper.removeQuotes(operatorTranslation);
    return _.find(this.getOperators(), function(opDef) {
        return operatorTranslation === opDef.Translation;
    });
}
export function getOperatorDataByTranslationAndType(operatorTranslation:string, type:string) {
    operatorTranslation = lexemeHelper.removeQuotes(operatorTranslation);
    return _.find(this.getOperators(), function (opDef) {
        return operatorTranslation === opDef.Translation && opDef.SupportedTypes && opDef.SupportedTypes.indexOf(type) !== -1;
    });
}