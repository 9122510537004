/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/sidebarNavigation"/>
import * as React from "react";
import { useState } from "react";
import { Sidebar } from "Core/Medius.Core.Web/Scripts/components/sidebar/sidebar";


export function SidebarNavigation() {
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);
    return (
        <>
            <aside id="administration-sidebar" className="well span5 administration__sidebar" style={{ display: isSidebarVisible ? "block" : "none" }} data-testid="administration-sidebar">
                <Sidebar onBackAction={() => { setIsSidebarVisible(false); }} />
            </aside>
            <button className="btn btn-small"
                onClick={() => { setIsSidebarVisible(true); }}
                style={{ marginLeft: "20px", marginTop: "10px", position: "absolute", zIndex: 1 }}
                data-testid="SidebarNavigation-button"
            >
                <i className="icon-list"></i>
            </button>
        </>
    );
}