/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/kendo/bindings/kendoGridCollapseExpand"/>

import { logScenario } from "Core/Medius.Core.Web/Scripts/lib/metricsLogging/metricLogger";
import { MultiEventHandler } from "Core/Medius.Core.Web/Scripts/Medius/kendo/bindings/multiEventHandler";
import * as $ from "jquery";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";

const cellSelector = "tr[role=row] th.k-group-cell.k-header";
const buttonAttrDataQa = "gridGroupsCollapseExpand";
const buttonSelector = `${cellSelector} a[data-testid='${buttonAttrDataQa}']`;

enum ButtonKind { Collapse, Expand }

interface IButtonState {
    readonly titleKey: string;
    readonly cssClass: string;
    readonly clickHandler: (e: JQueryEventObject) => void;
}

export class KendoGridCollapseExpand {
    public static setupButtons(gridOptions: kendo.ui.GridOptions): void {
        gridOptions.dataBound = MultiEventHandler
            .from(gridOptions.dataBound, KendoGridCollapseExpand.onDataBound);
    }

    private static onDataBound(e: kendo.ui.GridDataBoundEvent): void {
        KendoGridCollapseExpand.removeButtonFrom(e.sender);
        KendoGridCollapseExpand.insertHeaderButton(e.sender);
    }

    private static removeButtonFrom(grid: kendo.ui.Grid) {
        const currentButton = grid.thead.find(buttonSelector);
        if (currentButton.length)
            currentButton.closest("th").html("&nbsp;");
    }

    private static insertHeaderButton(grid: kendo.ui.Grid) {
        const headerCells = grid.thead.find(cellSelector);
        if (!headerCells.length)
            return;

        const button = $("<a></a>")
            .prop("href", "")
            .prop("tabindex", -1)
            .attr("data-testid", buttonAttrDataQa);

        KendoGridCollapseExpand.setButtonState(button, ButtonKind.Collapse, grid);

        const buttonWrapper = $("<div></div>")
            .addClass("collapse-expand")
            .addClass("k-grouping-row")
            .append(button);

        headerCells.first()
            .empty()
            .append(buttonWrapper);
    }

    private static setButtonState(button: JQuery, kind: ButtonKind, grid: kendo.ui.Grid): void {
        const state = KendoGridCollapseExpand.stateFor(kind, grid);

        button.removeClass().addClass("k-font-icon").addClass(state.cssClass);
        button.prop("title", getLabelTranslation(state.titleKey));
        button.one("click", state.clickHandler);
    }

    private static stateFor(kind: ButtonKind, grid: kendo.ui.Grid): IButtonState {
        switch (kind) {
            case ButtonKind.Collapse:
                return {
                    titleKey: "#Core/groups/collapse",
                    cssClass: "k-i-collapse",
                    clickHandler: KendoGridCollapseExpand
                        .createCollapseHandlerFor(grid)
                };

            case ButtonKind.Expand:
                return {
                    titleKey: "#Core/groups/expand",
                    cssClass: "k-i-expand",
                    clickHandler: KendoGridCollapseExpand
                        .createExpandHandlerFor(grid)
                };

            default:
                throw new Error(`Unknown button kind: ${kind}.`);
        }
    }

    private static createCollapseHandlerFor(grid: kendo.ui.Grid): (e: JQueryEventObject) => void {
        return (e: JQueryEventObject) => {
            const timeNow = Date.now();
            e.preventDefault();
            kendo.ui.progress(grid.content, true);
            window.setTimeout(() => {
                grid.tbody.find(".k-grouping-row").each((i, element) => {
                    grid.collapseGroup(element);
                });
                KendoGridCollapseExpand.setButtonState($(e.target), ButtonKind.Expand, grid);
                kendo.ui.progress(grid.content, false);

                const duration = Date.now() - timeNow;
                logScenario("KendoGrid", "All", "Collapse", duration);
            });
        };
    }

    private static createExpandHandlerFor(grid: kendo.ui.Grid): (e: JQueryEventObject) => void {
        return (e: JQueryEventObject) => {
            const timeNow = Date.now();
            e.preventDefault();
            kendo.ui.progress(grid.content, true);
            window.setTimeout(() => {
                grid.tbody.find(".k-grouping-row").each((i, element) => {
                    grid.expandGroup(element);
                });
                KendoGridCollapseExpand.setButtonState($(e.target), ButtonKind.Collapse, grid);
                kendo.ui.progress(grid.content, false);

                const duration = Date.now() - timeNow;
                logScenario("KendoGrid", "All", "Expand", duration);
            });
        };
    }
}