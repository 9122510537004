///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/grid/parser/default" />

import * as _ from "underscore";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as resolverUtils from "Core/Medius.Core.Web/Scripts/Medius/components/resolver/utils";
import * as ko from "knockout";
import { isEmptyString, isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";

export class GridObjectParser {
    parseColumns(columns: any, defaultColumnWidth: any, defaultTplCell: any) {
        if (!_.isArray(columns) || columns.length === 0) {
            columns = [{ Name: "", ValueSource: "", ColumnName: "", CellTemplate: defaultTplCell }];
        }

        const parsedColumns: any[] = [];
        _(columns).each((column) => {
            parsedColumns.push(this.parseColumn(column, defaultColumnWidth, defaultTplCell));
        });

        return parsedColumns;
    }

    parseColumn(column: any, defaultColumnWidth: any, defaultTplCell: any) {
        if (!_.isObject(column)) {
            throw new Error("Column not defined correctly.");
        }

        if (_.isUndefined(column.ValueSource)) {
            throw new Error("Column's ValueSource not defined.");
        }

        column.Name = column.Name || column.ColumnName || "";

        let name = globalization.getLabelTranslation(column.Name);
        name = (name === column.Name) ? globalization.getPropertyTranslation(column.Name) : name;

        const visible = (ko.isObservable(column.Visible)) ? column.Visible : ko.observable(isNullOrUndefined(column.Visible) ? true : column.Visible);

        column.Width = column.Width || defaultColumnWidth;
        column.WidthStorageId = column.WidthStorageId || null;
        column.Resizable = isNullOrUndefined(column.Resizable) ? true : column.Resizable;
        column.Stretch = isNullOrUndefined(column.Stretch) ? true : column.Stretch;
        column.Searchable = isNullOrUndefined(column.Searchable) ? true : column.Searchable;
        column.Sortable = isNullOrUndefined(column.Sortable) ? true : column.Sortable;
        column.Selectable = isNullOrUndefined(column.Selectable) ? true : column.Selectable;
        column.Visible = visible;
        column.VisibleStorageId = column.VisibleStorageId || null;
        column.Name = name || "";
        column.Key = column.Key || column.ValueSource;
        column.ValueSourceType = _.isUndefined(column.ValueSourceType) ? null : column.ValueSourceType;
        column.searchValue = ko.observable();
        column.CellTemplate = column.CellTemplate || defaultTplCell;

        return column;
    }

    parseRows(columns: any, rows: any){
        if (!_.isArray(rows)) {
            throw new Error("'rows' argument is not an array.");
        }

        const parsedRows: any[] = [];
        _(rows).each((row) => {
            parsedRows.push(this.parseRow(columns, row));
        });

        return parsedRows;
    }

    parseRow(columns: any, row: any) {
        if (!_.isObject(row)) {
            throw new Error("Row not defined correctly.");
        }
        
        const columnsValues: any[] = [];
        const columnsByKey: any = {};
        const columnsTpls: any[] = [];

        _(columns).each((column) => {
            const existingValue = row.ColumnsByKey ? row.ColumnsByKey[column.Key] : undefined;
            const value = (existingValue) ? existingValue : this.resolveColumnValue(row, column);

            columnsTpls.push(column.CellTemplate);

            columnsValues.push(value);
            columnsByKey[column.Key] = value;
        });

        row.ColumnsTpls = columnsTpls;
        row.Columns = columnsValues;
        row.ColumnsByKey = columnsByKey;
        row.isEditable = _.isUndefined(row.isEditable) ?  ko.observable(false) : row.isEditable;

        return row;
    }

    resolveColumnValue(row: any, column: any) {
        let value: any;
        try {
            value = (isEmptyString(column.ValueSource)) ? null :
                resolverUtils.findPathInTree(row, column.ValueSource);
            value = (isNullOrUndefined(value)) ? "" : value;
        } catch (e) {
            value = "";
        }

        return value;
    }
    
    getCollectionNames(keys: any, firstRow: any) {
        const reserved = ["Columns"];

        return _.filter(keys,(property: any) => {
            return _.isArray(firstRow[property]) && !_(reserved).contains(property);
        });
    }
    
    dispose() {
    }
}

const instance = new GridObjectParser();

export function create(){
    return instance;
}

export const derive = GridObjectParser;