/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/default/text/entity/role"/>
import {unpack} from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as typeBoundResolverFactory from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/typeBound";
import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";

function getRoleName(role: any) {
    if (isNullOrUndefined(role)) {
        return "";
    }

    const owner = unpack(role.Owner);
    const name = unpack(role.Name);
    
    if (!isNullOrUndefined(owner)) {
        return unpack(owner.FirstName) + " " + unpack(owner.LastName) + " (" + unpack(owner.UserName) + ")";
    }
    return name;
}

export const resolver = typeBoundResolverFactory.create({
    priority: 0,
    type: "Medius.Core.Entities.Role",
    resolve: function (data: any) {
        const role = unpack(data),
            name = getRoleName(role);

        return {
            text: name
        };
    }
});
