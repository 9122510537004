/// <amd-module name= "Core/Medius.Core.Web/Scripts/Medius/apps/task/taskConfirmationModalHandler" />
import { Deferred, when } from "jquery";
import { observable } from "knockout";

class TaskConfirmationModalHandler {
    private result = false;
    private resultPromise = Deferred();
    private isShown = observable(false);

    public show(confirmCallback: any, cancelCallback?: any) {
        this.isShown(true);
        this.result = false;
        this.resultPromise = $.Deferred();

        when(this.resultPromise).pipe((value) => {
            if (value && confirmCallback) confirmCallback();
            if (!value && cancelCallback) cancelCallback();
        });

        return this.resultPromise;
    }

    public confirm() {
        this.result = true;
        this.isShown(false);
    }

    public dismiss() {
        this.result = false;
        this.isShown(false);
    }

    public resolveResult() {
        this.resultPromise.resolve(this.result);
    }
}

export function create() {
    return new TaskConfirmationModalHandler();
}