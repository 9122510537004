/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/DocumentCacheManagement/documentCacheManagement"/>
import * as React from "react";
import { useEffect, useState, useReducer, useCallback } from "react";
import { SidebarNavigation } from "Core/Medius.Core.Web/Scripts/AdminPages/sidebarNavigation";
import { registerAdministrationEntity } from "Core/Medius.Core.Web/Scripts/AdminPages/administrationServices";
import { Grid, GridColumn, GridProps, GridToolbar, GridFilterCellProps } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { DocumentCache } from "./interfaces";
import { documentCacheManagementReducer, loadData } from "./documentCacheManagamentState";
import { FilterDescriptor } from "@progress/kendo-data-query";
import { getDocumentsNotCached, rebuildCache, getRebuildCacheProgress, isUpdatingCacheError, updateCacheError } from "./services";
import { error } from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import { useDebounce } from "use-debounce";
import { DatePresenter } from "Core/Medius.Core.Web/Scripts/shared/components/datePresenter";
import { MaskingKendoReactGridLoadingPanel } from "../../shared/components/loadingPanel";
import { handleError } from "Core/Medius.Core.Web/Scripts/lib/errorHandling/errorHandler";
import { ConfirmDialog, Notification } from "@medius/ui-controls";

function DocumentCacheManagement() {
    const [dataState, setDataState] = useState<GridProps>({
        skip: 0,
        take: 20,
        sort: [],
        filter: {
            logic: "and",
            filters: []
        }
    });

    const [state, dispatch] = useReducer(documentCacheManagementReducer, {data: [] as DocumentCache[], total: 0, lastUpdated: null});
    const [isRebuilding, setIsRebuilding] = useState(false);
    const [currentProgress, setCurrentProgress] = useState("0");
    const [isUpdating, setIsUpdating] = useState(false);
    const [showCacheRebuildModal, setShowCacheRebuildModal] = useState(false);

    const [debouncedParams] = useDebounce({
        filters: dataState.filter?.filters as FilterDescriptor[],
        skip: dataState.skip,
        take: dataState.take,
        sort: dataState.sort
    }, 300);

    const rebuildCacheAction = (isConfirmed: boolean = false) => {
        if(isConfirmed){
            setShowCacheRebuildModal(false);
            startRebuildTimer();
            rebuildCache()
                .then(() => reload(), () => {
                    error(translate("#Core/rebuildCacheFailed"));
                });
        }
        else setShowCacheRebuildModal(true);
    };

    const updateDocumentCacheError = () => {
        setIsUpdating(true);
        updateCacheError().then(() => {
            startUpdateTimer();
        })
        .catch((e) => { 
            handleError(e);
            setIsUpdating(false);
        });
    };

    const startUpdateTimer = useCallback(() => {
        const timer = setInterval(() => isUpdatingCacheError()
        .then((isLoading) => {
            if (!isLoading) {
                clearInterval(timer);
                setIsUpdating(false);
                reload();
            }
        }, () => { })
        .catch((e) => { 
            handleError(e);
            clearInterval(timer);
        })
        .finally(() => {
        })
        , 3000);
    }, []);

    const reload = useCallback(
        () => {
                getDocumentsNotCached(debouncedParams.skip, debouncedParams.take, debouncedParams.sort[0]?.field, debouncedParams.sort[0]?.dir,
                    debouncedParams.filters?.find(({field}) => field === "id")?.value,
                    debouncedParams.filters?.find(({field}) => field === "errorDescription")?.value.value)
                    .then((dto) => {
                        dispatch(loadData(dto));
                    });
                }, [debouncedParams.filters,
                    debouncedParams.skip,
                debouncedParams.take,
                debouncedParams.sort]);

    const startRebuildTimer = useCallback( () => {
        setIsRebuilding(true);
        const timer = setInterval(() => getRebuildCacheProgress().then((dto) =>
        {
            setCurrentProgress(dto.progress.toString());

            if (dto.progress == 0 || dto.progress == 100) {
                setIsRebuilding(false);
                clearInterval(timer);
                reload();
            }
        }, () => {}), 3000);
    }
    , [reload]);

    useEffect(() => {
        getRebuildCacheProgress().then((dto) =>
        {
            setCurrentProgress(dto.progress.toString());

            if (dto.progress > 0) {
                startRebuildTimer();
            }

            reload();
        });

        return () => {
        };
    }, [reload, startRebuildTimer]);

    return (
        <>
            <ConfirmDialog
                width={"medium"}
                confirmLabel={translate("#Core/rebuildCache")}
                isOpen={showCacheRebuildModal}
                onCancel={() => setShowCacheRebuildModal(false)}
                onConfirm={() => rebuildCacheAction(true)}
            >
                <Notification
                    feedbackType="warning"
                    text={translate("#Core/rebuildCacheOutsideWorkingHours")}
                />
            </ConfirmDialog>
            <SidebarNavigation />
            <div className="documentCacheManagement">
                <div className="documentCacheManagement__gridPanel">
                    <h2 className="administration-page-header">{translate("#Core/documentCacheManagement")}</h2>
                    {
                        isRebuilding &&
                        <div>
                            <div className="alert alert-warning">
                                <span>{translate("#Core/rebuildCacheInProgress")}</span>
                            </div>
                            <div className="documentCacheManagement__progressBar">{translate("#Core/rebuildingDocumentCache")} {currentProgress} {translate("#Core/signPercentage")}</div>
                            <div className="documentCacheManagement__progressBar__border">
                                <div className="documentCacheManagement__progressBar__bar" style={{height:'24px', width: currentProgress + '%'}}>
                                </div>
                            </div>
                        </div>
                    }
                    {!isRebuilding && <>
                    <div className="alert alert-warning">
                        <span>{translate("#Core/rebuildCacheOutsideWorkingHours")}</span>
                        {state.lastUpdated && <span> {translate("#Core/lastUpdatedOn")} <DatePresenter date={state.lastUpdated} format="G"></DatePresenter></span>}
                    </div>
                    {isUpdating && <MaskingKendoReactGridLoadingPanel gridSelector=".documentCacheManagement__documentsGrid" />}
                    <Grid
                        className="documentCacheManagement__documentsGrid"
                        {...state}
                        {...dataState}
                        onDataStateChange={(e) => {
                            setDataState({...e.dataState});
                        }}
                        pageable
                        sortable
                        filterable
                        onSortChange={({sort}) => {
                            setDataState((data) => ({...data, sort}));
                        }}
                        onFilterChange={({filter}) => {
                            setDataState((data) => ({...data, skip: 0, take: 20, filter}));
                        }}
                        >
                        <GridToolbar>
                            <Button
                                disabled={isUpdating}
                                id="documentCacheManagement__update-button"
                                title={translate("#Core/fetchDocuments")}
                                onClick={updateDocumentCacheError}>{translate("#Core/fetchDocuments")}</Button>
                            <Button
                                disabled={isUpdating}
                                id="documentCacheManagement__rebuild-button"
                                themeColor="primary"
                                onClick={() => rebuildCacheAction()}
                            >
                                {translate("#Core/rebuildCache")}
                            </Button>
                        </GridToolbar>
                        <GridColumn field="id" title={translate("#Core/id")} />
                        <GridColumn field="documentType" title={translate("#Core/documentType")} filterable={false} sortable={false}/>
                        <GridColumn field="companyName" title={translate("#Core/company")} filterable={false} sortable={false}/>
                        <GridColumn field="documentCreatedTimestamp" title={translate("#Core/createdDate")} format="{0:G}" filterable={false} sortable={false}/>
                        <GridColumn field="errorDescription" title={translate("#Core/errorDescription")} sortable={true} filterCell={ErrorFilterCell}/>
                    </Grid></>
                    }
                </div>
            </div>
        </>
    );
}

function ErrorFilterCell(props: GridFilterCellProps) {
    interface LocalizedData {
        text: string;
        value: string;
    }

    const localizedData: Array<LocalizedData> = [
        { text: translate("#Core/notCachedDocumentsDescription"), value: 'DocumentAggregateConnection' },
        { text: translate("#Core/inaccessibleDocumentsDescription"), value: 'UserDocumentAggregateConnection' },
        { text: translate("#Core/documentsNotAccessibleInAnyInboxDescription"), value: 'InboxDocumentAggregateConnection' },
    ];

    const defaultItem: LocalizedData = { text: translate("#Core/selectErrorDescription"), value: '' };

    const hasValueFunc = (value: LocalizedData) => value && value !== defaultItem;

    const onChange = (event: DropDownListChangeEvent) => {
        const hasValue = hasValueFunc(event.target.value);
        props.onChange({
            value: hasValue ? event.target.value : '',
            operator: hasValue ? 'eq' : '',
            syntheticEvent: event.syntheticEvent
        });
    };

    return (
        <div className="k-filtercell">
                <DropDownList
                    data={localizedData}
                    onChange={onChange}
                    value={props.value || defaultItem}
                    defaultItem={defaultItem}
                    textField="text"
                />
            </div>
        );
}

export function registerDocumentCacheManagementAdminPage() {
    registerAdministrationEntity("DocumentCacheManagement", DocumentCacheManagement);
}
