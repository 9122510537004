///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/role/model"/>
import * as baseModel from "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/default/model";
import * as dataProviderFactory from "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/role/dataProvider";
import * as defaultsFactory from "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/role/defaults";
import * as utils from "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/utils";

export function create(bindingParams:any) {
    const providerOptionsExtension = {
        company: bindingParams.company,
        onlyPersonal: bindingParams.onlyPersonal,
        withPersonal: bindingParams.withPersonal
    },
    optionsExtension = {
        disabled: bindingParams.disabled,
        required: bindingParams.required
    },
    params = utils.createParameters(bindingParams, optionsExtension, providerOptionsExtension, defaultsFactory, dataProviderFactory);

    return baseModel.create(params);
}