/// <amd-module name="Core/Medius.Core.Web/Scripts/bindings/kendoButton"/>
import * as ko from "knockout";
import * as $ from "jquery";

const kendoButtonBinding = {
    init(element: HTMLElement) {
        $(element).kendoButton();

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => 
            $(element).data("kendoButton").destroy()
        );
    }
}

export const register = () => ko.bindingHandlers["kendo-button"] = kendoButtonBinding;
