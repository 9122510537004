///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/lexeme/validator"/>
import * as hintsProvider from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/provider/hints";
import { isEmptyString } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import * as _ from "underscore";
import {CultureLocalizer} from "Core/Medius.Core.Web/Scripts/Medius/components/localizer/cultureLocalizer";

const daysOpRegex = /^[-+][0-9]+[d|D]$/;
const htmlTagRegex = /<[0-9a-z\/]+>/gi;
let localizer:any;

export function validate(lexeme:string, parsingData:any) {
    if (isEmptyString(lexeme) || _([",", ";"]).contains(lexeme) || lexeme.search(htmlTagRegex) > -1) {
        return false;
    }

    return this.validateByExpectedType(lexeme, parsingData);
}
export function validateByExpectedType(lexeme:string, parsingData:any) {
    const expectedValueType = parsingData.propertyType;
    const operator = parsingData.operator.Operator;

    if (expectedValueType === "System.DateTime" && (_(hintsProvider.getValueDateHints(operator)).contains(lexeme) || daysOpRegex.test(lexeme))) {
        return true;
    }

    const lexemeValue = this.getLocalizer()
        .getLocalizedValue(lexeme, expectedValueType);

    return !isEmptyString(lexemeValue);
}

export function getLocalizer() {
    if (!localizer)
        localizer = new CultureLocalizer();

    return localizer;
}