/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/ImportManagement/services"/>
import {post, get, del} from "Core/Medius.Core.Web/Scripts/Medius/core/fetch/rest";
import {MasterDataErrorDto, QueueItemsDto} from "./interfaces";

export function getMasterDataErrors(
    queueItemId: number, 
    skip: number, 
    take: number, 
    externalSystemId: string | null,
    errorSearchString: string
) {
    return get<{masterDataErrors: MasterDataErrorDto[]; total: number}>(`importManagement/errors/${queueItemId}`, {
        skip, take, externalSystemId, errorSearchString
    });
}

export function reprocessPartially(queueItemId: number, masterDataErrorIds: number[]) {
    return post("Backend/Rest/importManagement/reprocessPartially", {
        queueItemId,
        masterDataErrorIds
    });
}

export function getQueueItems(errorSearchString: string, skip: number, take: number, successful: boolean | null, orderBy: string | null, orderByDirection: string | null, keywords = {}) {
    return post<QueueItemsDto>("Backend/Rest/importManagement/search", {
        errorSearchString,
        skip,
        take,
        successful,
        orderBy,
        orderByDirection,
        keywords
    });
}

export function reprocessItem(id: number) {
    return post(`Backend/Rest/importManagement/reprocess/${id}`);
}

export function deleteItem(id: number) {
    return del(`Backend/Rest/importManagement/queueItem/${id}`);
}