///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/resizableAppColumns"/>
import * as resizableStore from "Core/Medius.Core.Web/Scripts/Medius/core/store/resizable";
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import { isNotNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";

const $window = $(window);
const RESIZABLE_CONTAINER_CLASS = "resizable-app-container"; 

function storeWidth(elementId:any, tiedElementId:any, containerId:any, elementWidth:any) {
    const containerWidth = $(containerId).width();
    let elementPercentageWidth = ((100 * elementWidth) / containerWidth).toFixed(2);
    let tiedElementPercentageWidth = (100 - Number(elementPercentageWidth)).toFixed(2);

    elementPercentageWidth = elementPercentageWidth + "%";
    tiedElementPercentageWidth = tiedElementPercentageWidth + "%";

    $(elementId).width(elementPercentageWidth);
    $(tiedElementId).width(tiedElementPercentageWidth);

    resizableStore.setItem(elementId, elementPercentageWidth);
    resizableStore.setItem(tiedElementId, tiedElementPercentageWidth);
}

const resizable = {
    init: function (element:any, bindingAccessor:any) {
        let tiedElemId = bindingAccessor().tiedElem;

        (jQuery.support as any).boxSizing = false;

        const containerId = bindingAccessor().container;
        const minWidthProc = bindingAccessor().minWidthProc;
        const maxWidthProc = bindingAccessor().maxWidthProc;
        const elemId = '#' + $(element).get(0).id;
        const iconHtml = "<span class='ui-resizable-icon'></span>";
        
        $(tiedElemId).addClass('resizable-tied');
    
        resizableStore.getItem(elemId).done(function (width:any) {
            if (isNotNullOrUndefined(width)) {
                $(elemId).width(width);
            }
        });
        
        resizableStore.getItem(tiedElemId).done(function (width:any) {
            if (isNotNullOrUndefined(width)) {
                $(tiedElemId).width(width);
            }
        });

        $(element).resizable({
            handles: 'e',
            start: function() {
                const maxWidth = Math.floor($(containerId).width() * maxWidthProc / 100);
                const minWidth = Math.floor($(containerId).width() * minWidthProc / 100);

                $(tiedElemId).addClass('resizing-in-progress');
                $(element).resizable('option', 'maxWidth', maxWidth);
                $(element).resizable("option", "minWidth", minWidth);
            },
            stop: function(e:any, ui:any) {
                $(tiedElemId).removeClass('resizing-in-progress');
                storeWidth(elemId, tiedElemId, containerId, ui.size.width);
                $(element).height(ui.size.height);
                $window.trigger('resize');
            },
            helper: "ui-resizable-helper"
        }).on('resize', function(e:any) {
            e.stopPropagation();
        }).addClass(RESIZABLE_CONTAINER_CLASS);

        $(".ui-resizable-handle").html(iconHtml);
     
        koUtils.addDisposeCallback(element, function() {
            const $element = $(element);
            $element.resizable("destroy");
            $element.removeClass(RESIZABLE_CONTAINER_CLASS);
            tiedElemId = null;
        });
    }
};

export function register() {
    koUtils.registerBinding("resizableAppColumns", resizable);
}