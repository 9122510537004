/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/sox/soxReport"/>
import { isNotNullOrUndefined, isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import { translate, getFormattedLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import {error} from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import { find } from "underscore";
import * as _ from "underscore";
import { toUserLocal } from "Core/Medius.Core.Web/Scripts/Medius/lib/utils/date";
import * as ko from "knockout";

export class SoxReport {
    public ReportName = ko.observable<string>();
    public TypeName = ko.observable<string>();
    public CurrentAuthor = ko.observable<any>();
    public ChangesAuthors = ko.observableArray([]);
    public AffectedUser = ko.observable<any>();
    public CurrentCompany = ko.observable<any>();
    public ContextCompanies = ko.observableArray<any>();
    public StartDate = ko.observable();
    public EndDate = ko.observable<Date>();
    public SelectedReportType = ko.observable(null);
    public SelectedReportDescription = ko.observable(null);
    public SelectedProperties = ko.observableArray([]);
    public AreCompanyUnrelatedChangesIncluded = ko.observable(true);
    public IsSystemIncluded = ko.observable(false);
    public SoxReportId = ko.observable(null);
    public LimitCollectionAccess = ko.observable(false);
    public IsAffectingUser = ko.observable(false);

    public IsTypeSelected = ko.computed(() => isNotNullOrUndefined(this.SelectedReportType()));

    public TypeStub = this.SelectedReportType.subscribe(() => {
        this.SelectedProperties([]);
    });

    public IsCurrentAuthorSelected = ko.computed(() => isNotNullOrUndefined(this.CurrentAuthor()));
    public IsCurrentCompanySelected = ko.computed(() => isNotNullOrUndefined(this.CurrentCompany()));

    public validate() {
        let message = "",
            isValid = true;

        if (this.ReportName().length > 50) {
            message += " " + translate("#Core/soxValidationNameToLong");
            isValid = false;
        }

        if (this.StartDate() > this.EndDate()) {
            message += " " + translate("#Core/soxValidationStartAfterEnd");
            isValid = false;
        }

        if (!isValid) {
            error(message);
        }
        return isValid;
    }

    public loadData(pickedReport: any) {
        this.SoxReportId(pickedReport.RelatedReportId);
        this.ReportName(pickedReport.Name);
        this.TypeName(pickedReport.TypeName);
        this.StartDate(pickedReport.From);
        this.EndDate(pickedReport.To);
        this.SelectedReportType({ Type: ko.observable(pickedReport.Type), CustomType: ko.observable(pickedReport.CustomType), TypeName: ko.observable(pickedReport.TypeName) });
        this.SelectedReportDescription(pickedReport.Details);
        this.SelectedProperties(pickedReport.Paths);
        this.IsAffectingUser(pickedReport.IsAffectingUser);
    }

    public addAuthor() {
        const selectedAuthor = this.CurrentAuthor();

        const existingAuthor = find(this.ChangesAuthors(), (item) => item === selectedAuthor);

        if (isNullOrUndefined(existingAuthor)) {
            this.ChangesAuthors.push(selectedAuthor);
        } else {
            const message = getFormattedLabelTranslation("#Core/soxReportAuthorAlreadyAdded_userName", selectedAuthor.UserName);
            error(message);
        }
        this.CurrentAuthor(null);
    }

    public addCompany() {
        const selectedCompany = this.CurrentCompany();
        const existingCompany = _(this.ContextCompanies()).find((contextCompany: any) => contextCompany === selectedCompany);

        if (isNullOrUndefined(existingCompany)) {
            this.ContextCompanies.push(selectedCompany);
        } else {
            const companyName = ko.unwrap(selectedCompany.Name);
            const errorMessage = getFormattedLabelTranslation("#Core/soxReportContextCompanyAlreadyAdded_companyName", companyName);
            error(errorMessage);
        }
        this.CurrentCompany(null);
    }

    public removeAuthor(selectedAutor: any) {
        this.ChangesAuthors.remove(selectedAutor);
    }

    public removeCompany(selectedCompany: any) {
        this.ContextCompanies.remove(selectedCompany);
    }

    public getReportData() {
        const paths = _.map(this.SelectedProperties(), (item) => item);
        const endTime = new Date(this.EndDate().getTime());

        //we need to present report till the end of selected day
        endTime.setDate(endTime.getDate() + 1);
        endTime.setMilliseconds(-10);

        return {
            $type: null as string,
            Type: this.SelectedReportType().Type(),
            CustomType: this.SelectedReportType().CustomType(),
            From: toUserLocal(this.StartDate()),
            To: toUserLocal(endTime),
            Paths: paths,
            Name: this.ReportName(),
            RelatedReportId: this.SoxReportId()
        };
    }

    public getSoxReportData() {
        const reportData = this.getReportData();

        reportData.$type = "Medius.Core.DTOs.SoxReportDto";
        return reportData;
    }

    public getSoxReportRequestData() {
        const reportData = this.getReportData();
        const contextCompanyIds = this.ContextCompanies().map((item) => item.Id());
        const userIds = this.ChangesAuthors().map((item) => item.Id());

        reportData.$type = "Medius.Core.DTOs.SoxReportRequest";
        (reportData as any).UserIds = userIds;
        if (!isNullOrUndefined(this.AffectedUser()))
        (reportData as any).AffectedUserId = this.AffectedUser().Id();
        (reportData as any).ContextCompanyIds = contextCompanyIds;
        (reportData as any).IncludeSystemActions = this.IsSystemIncluded();
        (reportData as any).ExcludeActionsWithoutCompanyContext = !this.AreCompanyUnrelatedChangesIncluded();

        return reportData;
    }

    public dispose() {
        this.IsTypeSelected.dispose();
        this.TypeStub.dispose();
        this.IsCurrentAuthorSelected.dispose();
        this.IsCurrentCompanySelected.dispose();
    }
}
