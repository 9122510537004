///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/context/finalize"/>
export = function finalizeContextDecorator(context:any) {
    let finalizing = false;
    let finalizers:any[] = [];

    context.disposal.register(function() {
        finalizers = [];
    });

    context.finalize = {
        add: function addContextFinalizer(finalizeCallback:any) {
            finalizers.push(finalizeCallback);
        },
        remove: function removeContextFinalizer(finalizeCallback:any) {
            for (let i = 0; i < finalizers.length; i++) {
                if (finalizers[i] === finalizeCallback) {
                    finalizers.splice(i, 1);
                }
            }
        },
        run: function runContextFinalizer() {
            if (finalizing) {
                return;
            }

            finalizing = true;
            for (let i = 0; i < finalizers.length; i++) {
                const finalizer = finalizers[i];
                finalizer();
            }
            finalizing = false;
        },
        isFinalizing: function isContextFinalizing() {
            return finalizing;
        }
    };
};
