/// <amd-module name="Core/Medius.Core.Web/Scripts/components/sidebar/sidebarWithProvider"/>
import * as React from "react";
import {Sidebar} from "./sidebar";
import { Provider } from "react-redux";
import {store} from "Core/Medius.Core.Web/Scripts/shared/store/reduxStore";
import {LocalizationProvider} from "@progress/kendo-react-intl";

export function SidebarWithProvider({onBackAction}: { onBackAction: () => void}) {
    return (
        <LocalizationProvider language="current-language">
            <Provider store={store}>
                <Sidebar onBackAction={onBackAction} />
            </Provider>
        </LocalizationProvider> 
    );
}
