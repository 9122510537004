///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Entities.BusinessRule.BusinessRuleCompany/Editor"/>
import DataDefinitionEditor = require("Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Data.DataDefinition/Editor");
import * as rpc from "Core/Medius.Core.Web/Scripts/Medius/core/rpc";
import * as serialization from "Core/Medius.Core.Web/Scripts/Medius/lib/serialization";
import * as ko from "knockout";
import { DataSearchSource as DataSearchDataSource } from "Core/Medius.Core.Web/Scripts/Medius/components/grid/dataSource/dataSearch";


class EditorModel {
    ViewModel: any;
    DataSource: any;
    SpecificProperties: { Name: string; Property?:any }[];
    DefaultEditorProps: ko.ObservableArray<any>;
    AvailablePerspectives: ko.Observable<any>;
    SelectedPerspective: ko.Observable<any>;
    AvailableWorkflows: ko.ObservableArray<any>;
    SelectedWorkflow: ko.Observable<any>;
    AvailableActivities: ko.ObservableArray<any>;
    SelectedActivity: ko.Observable<any>;
    editorVisible: ko.Observable<boolean>;
    activityInputVisible: ko.Computed<boolean>;
    InitData: ko.Computed<void>;
    AvailableActivitiesSynch: ko.Computed<void>;
    AvailableWorkflowsSynch: ko.Computed<void>;
    subscriptions: any[];
    constructor(entitityWithContext:any, companyContextId:number, tabModel:any) {
        this.ViewModel = DataDefinitionEditor.call(this, entitityWithContext, companyContextId, tabModel);
        this.DataSource = new DataSearchDataSource();

        this.SpecificProperties = [{ Name: "PerspectiveType" }, { Name: "ActivityName" }];
        this.DefaultEditorProps = ko.observableArray();

        this.AvailablePerspectives = ko.observable();
        this.SelectedPerspective = ko.observable();

        this.AvailableWorkflows = ko.observableArray();
        this.SelectedWorkflow = ko.observable();

        this.AvailableActivities = ko.observableArray();
        this.SelectedActivity = ko.observable();

        this.editorVisible = ko.observable(false);
        this.activityInputVisible = ko.computed(() => {
            return !this.editorVisible();
        });

        this.InitData = ko.computed(() => {

            rpc.ajax("BusinessRuleEditorManager", "GetAllAvailablePerspectives").done((data) => {
                this.setAvailablePerspectives(data);
                this.setCurrentPerspective();
            });

        });

        this.AvailableActivitiesSynch = ko.computed(() => {
            if (this.SelectedWorkflow()) {
                rpc.ajax("BusinessRuleCompanyEditorManager", "GetAvailableActivityDefinitions", {
                    data: serialization.toJSON({ workflowId: this.SelectedWorkflow().Id })
                }).done((list) => {
                    this.AvailableActivities(list);
                });
            } else {
                this.AvailableActivities(null);
            }

        });

        this.AvailableWorkflowsSynch = ko.computed(() => {
            if (this.editorVisible()) {
                rpc.ajax("BusinessRuleCompanyEditorManager", "GetAvailableWorkflows")
                .done((list) => {
                    this.AvailableWorkflows(list);
                });
            }
        });


        this.subscriptions = [];

        this.subscriptions.push(
        this.SelectedPerspective.subscribe(() => {
            if (this.SelectedPerspective()) {
                this.ViewModel.Entity.PerspectiveType(this.SelectedPerspective().Type);
            }
        }));

        this.subscriptions.push(this.SelectedActivity.subscribe(() => {
            if (this.SelectedActivity()) {
                this.ViewModel.Entity.ActivityName(this.SelectedActivity().Name);
            }
        }));

        this.initProperties();
    }

    initProperties() {
        const temp:any = [];

        this.ViewModel.ConfigurableProperties().forEach((prop:any) => {
            const found = this.SpecificProperties.find(specificProp => specificProp.Name === prop.Name);
            if (!found) {
                temp.push(prop);
            }
            else {
                found.Property = prop;
            }
        });
        this.DefaultEditorProps(temp);
    }

    setCurrentPerspective() {
        const found = this.AvailablePerspectives().find((perspective:any) => perspective.Type === this.ViewModel.Entity.PerspectiveType());
        this.SelectedPerspective(found);
    }

    editActivity() {
        this.editorVisible(true);
    }

    setAvailablePerspectives(list:any[]) {
        const tmp:any = [];
        list.forEach((elem) => {
            tmp.push({
                Name: elem.Name,
                Type: elem.Type
            });
        });
        this.AvailablePerspectives(tmp);
    }

    dispose() {
        this.ViewModel.dispose();

        this.subscriptions.forEach((subscription) => {
            subscription.dispose();
        });

        this.subscriptions = null;
        this.DefaultEditorProps(null);

        this.AvailablePerspectives(null);
        this.SelectedPerspective(null);

        this.AvailableWorkflows(null);
        this.SelectedWorkflow(null);

        this.AvailableActivities(null);
        this.SelectedActivity(null);

        this.editorVisible(null);
    }
}

export = function (entitityWithContext:any, companyContextId:number, tabModel:any) {
    return new EditorModel(entitityWithContext, companyContextId, tabModel);
};