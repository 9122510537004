///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/configurationProperties/overridenCollection"/>
import baseCollectionProperty = require("Core/Medius.Core.Web/Scripts/Medius/components/configurationProperties/baseCollection");

export class OverridenCollectionProperty extends baseCollectionProperty{
    BindingTemplate = "override-collection-property";

    constructor(params: any) {
        super(params);
        if (!params.WithoutCollectionGrid) {
            this.collectionGrid = this.createGrid();
            this.disposables.push(this.collectionGrid);
        }

        this.disposables.push(
            this.IsOverriden
                .subscribe((isOverriden) => {
                    if (isOverriden) {
                        this.setInheritedValueAsCurrentValue();
                    } else {
                        this.resetCurrentValue();
                    }
                    if (this.collectionGrid) {
                        this.collectionGrid.refresh();
                    }
                }));
    }

    getValue() {
        if (this.IsOverriden() === true) {
            return this.getCurrentItemsWithMetadata();
        }

        return this.getInheritedItemsWithMetadata();
    }  
}

export function create(params:any) {
    return new OverridenCollectionProperty(params);
}

export const derive = OverridenCollectionProperty;