/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/default/longText/errors/serviceUnexpectedOperationExceptionDetail"/>
import * as typeBoundResolverFactory from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/typeBound";
import { formatValueWithPlaceholders } from "Core/Medius.Core.Web/Scripts/Medius/lib/stringFormat";

export const resolver = typeBoundResolverFactory.create({
    priority: 0,

    type: "Medius.Exceptions.ServiceUnexpectedOperationExceptionDetail",

    resolve: function (exceptionDetail: any) {
        if (!exceptionDetail.Message) {
            return {};
        }
        
        const message = exceptionDetail.Message;
        const error = formatValueWithPlaceholders(message, [exceptionDetail.MessageParams]);

        return {
            longText: error
        };
    }
});
