/// <amd-module name="Core/Medius.Core.Web/Scripts/lib/countryCodes"/>

export const CountryCodes = [
    { Name: "AD Andorra", Value: "AD" }, 
    { Name: "AE United Arab Emirates (the)", Value: "AE" }, 
    { Name: "AF Afghanistan", Value: "AF" }, 
    { Name: "AG Antigua and Barbuda", Value: "AG" }, 
    { Name: "AI Anguilla", Value: "AI" }, 
    { Name: "AL Albania", Value: "AL" }, 
    { Name: "AM Armenia", Value: "AM" }, 
    { Name: "AO Angola", Value: "AO" }, 
    { Name: "AQ Antarctica", Value: "AQ" }, 
    { Name: "AR Argentina", Value: "AR" }, 
    { Name: "AS American Samoa", Value: "AS" }, 
    { Name: "AT Austria", Value: "AT" }, 
    { Name: "AU Australia", Value: "AU" }, 
    { Name: "AW Aruba", Value: "AW" }, 
    { Name: "AX Åland Islands", Value: "AX" }, 
    { Name: "AZ Azerbaijan", Value: "AZ" }, 
    { Name: "BA Bosnia and Herzegovina", Value: "BA" }, 
    { Name: "BB Barbados", Value: "BB" }, 
    { Name: "BD Bangladesh", Value: "BD" }, 
    { Name: "BE Belgium", Value: "BE" }, 
    { Name: "BF Burkina Faso", Value: "BF" }, 
    { Name: "BG Bulgaria", Value: "BG" }, 
    { Name: "BH Bahrain", Value: "BH" }, 
    { Name: "BI Burundi", Value: "BI" }, 
    { Name: "BJ Benin", Value: "BJ" }, 
    { Name: "BL Saint Barthélemy", Value: "BL" }, 
    { Name: "BM Bermuda", Value: "BM" }, 
    { Name: "BN Brunei Darussalam", Value: "BN" }, 
    { Name: "BO Bolivia (Plurinational State of)", Value: "BO" }, 
    { Name: "BQ Bonaire, Sint Eustatius and Saba", Value: "BQ" }, 
    { Name: "BR Brazil", Value: "BR" }, 
    { Name: "BS Bahamas (the)", Value: "BS" }, 
    { Name: "BT Bhutan", Value: "BT" }, 
    { Name: "BV Bouvet Island", Value: "BV" }, 
    { Name: "BW Botswana", Value: "BW" }, 
    { Name: "BY Belarus", Value: "BY" }, 
    { Name: "BZ Belize", Value: "BZ" }, 
    { Name: "CA Canada", Value: "CA" }, 
    { Name: "CC Cocos (Keeling) Islands (the)", Value: "CC" }, 
    { Name: "CD Congo (the Democratic Republic of the)", Value: "CD" }, 
    { Name: "CF Central African Republic (the)", Value: "CF" }, 
    { Name: "CG Congo (the)", Value: "CG" }, 
    { Name: "CH Switzerland", Value: "CH" }, 
    { Name: "CI Côte d'Ivoire", Value: "CI" }, 
    { Name: "CK Cook Islands (the)", Value: "CK" }, 
    { Name: "CL Chile", Value: "CL" }, 
    { Name: "CM Cameroon", Value: "CM" }, 
    { Name: "CN China", Value: "CN" }, 
    { Name: "CO Colombia", Value: "CO" }, 
    { Name: "CR Costa Rica", Value: "CR" }, 
    { Name: "CU Cuba", Value: "CU" }, 
    { Name: "CV Cabo Verde", Value: "CV" }, 
    { Name: "CW Curaçao", Value: "CW" }, 
    { Name: "CX Christmas Island", Value: "CX" }, 
    { Name: "CY Cyprus", Value: "CY" }, 
    { Name: "CZ Czechia", Value: "CZ" }, 
    { Name: "DE Germany", Value: "DE" }, 
    { Name: "DJ Djibouti", Value: "DJ" }, 
    { Name: "DK Denmark", Value: "DK" }, 
    { Name: "DM Dominica", Value: "DM" }, 
    { Name: "DO Dominican Republic (the)", Value: "DO" }, 
    { Name: "DZ Algeria", Value: "DZ" }, 
    { Name: "EC Ecuador", Value: "EC" }, 
    { Name: "EE Estonia", Value: "EE" }, 
    { Name: "EG Egypt", Value: "EG" }, 
    { Name: "EH Western Sahara", Value: "EH" }, 
    { Name: "ER Eritrea", Value: "ER" }, 
    { Name: "ES Spain", Value: "ES" }, 
    { Name: "ET Ethiopia", Value: "ET" }, 
    { Name: "FI Finland", Value: "FI" }, 
    { Name: "FJ Fiji", Value: "FJ" }, 
    { Name: "FK Falkland Islands (the) [Malvinas]", Value: "FK" }, 
    { Name: "FM Micronesia (Federated States of)", Value: "FM" }, 
    { Name: "FO Faroe Islands (the)", Value: "FO" }, 
    { Name: "FR France", Value: "FR" }, 
    { Name: "GA Gabon", Value: "GA" }, 
    { Name: "GB United Kingdom of Great Britain and Northern Ireland (the)", Value: "GB" }, 
    { Name: "GD Grenada", Value: "GD" }, 
    { Name: "GE Georgia", Value: "GE" }, 
    { Name: "GF French Guiana", Value: "GF" }, 
    { Name: "GG Guernsey", Value: "GG" }, 
    { Name: "GH Ghana", Value: "GH" }, 
    { Name: "GI Gibraltar", Value: "GI" }, 
    { Name: "GL Greenland", Value: "GL" }, 
    { Name: "GM Gambia (the)", Value: "GM" }, 
    { Name: "GN Guinea", Value: "GN" }, 
    { Name: "GP Guadeloupe", Value: "GP" }, 
    { Name: "GQ Equatorial Guinea", Value: "GQ" }, 
    { Name: "GR Greece", Value: "GR" }, 
    { Name: "GS South Georgia and the South Sandwich Islands", Value: "GS" }, 
    { Name: "GT Guatemala", Value: "GT" }, 
    { Name: "GU Guam", Value: "GU" }, 
    { Name: "GW Guinea-Bissau", Value: "GW" }, 
    { Name: "GY Guyana", Value: "GY" }, 
    { Name: "HK Hong Kong", Value: "HK" }, 
    { Name: "HM Heard Island and McDonald Islands", Value: "HM" }, 
    { Name: "HN Honduras", Value: "HN" }, 
    { Name: "HR Croatia", Value: "HR" }, 
    { Name: "HT Haiti", Value: "HT" }, 
    { Name: "HU Hungary", Value: "HU" }, 
    { Name: "ID Indonesia", Value: "ID" }, 
    { Name: "IE Ireland", Value: "IE" }, 
    { Name: "IL Israel", Value: "IL" }, 
    { Name: "IM Isle of Man", Value: "IM" }, 
    { Name: "IN India", Value: "IN" }, 
    { Name: "IO British Indian Ocean Territory (the)", Value: "IO" }, 
    { Name: "IQ Iraq", Value: "IQ" }, 
    { Name: "IR Iran (Islamic Republic of)", Value: "IR" }, 
    { Name: "IS Iceland", Value: "IS" }, 
    { Name: "IT Italy", Value: "IT" }, 
    { Name: "JE Jersey", Value: "JE" }, 
    { Name: "JM Jamaica", Value: "JM" }, 
    { Name: "JO Jordan", Value: "JO" }, 
    { Name: "JP Japan", Value: "JP" }, 
    { Name: "KE Kenya", Value: "KE" }, 
    { Name: "KG Kyrgyzstan", Value: "KG" }, 
    { Name: "KH Cambodia", Value: "KH" }, 
    { Name: "KI Kiribati", Value: "KI" }, 
    { Name: "KM Comoros (the)", Value: "KM" }, 
    { Name: "KN Saint Kitts and Nevis", Value: "KN" }, 
    { Name: "KP Korea (the Democratic People's Republic of)", Value: "KP" }, 
    { Name: "KR Korea (the Republic of)", Value: "KR" }, 
    { Name: "KW Kuwait", Value: "KW" }, 
    { Name: "KY Cayman Islands (the)", Value: "KY" }, 
    { Name: "KZ Kazakhstan", Value: "KZ" }, 
    { Name: "LA Lao People's Democratic Republic (the)", Value: "LA" }, 
    { Name: "LB Lebanon", Value: "LB" }, 
    { Name: "LC Saint Lucia", Value: "LC" }, 
    { Name: "LI Liechtenstein", Value: "LI" }, 
    { Name: "LK Sri Lanka", Value: "LK" }, 
    { Name: "LR Liberia", Value: "LR" }, 
    { Name: "LS Lesotho", Value: "LS" }, 
    { Name: "LT Lithuania", Value: "LT" }, 
    { Name: "LU Luxembourg", Value: "LU" }, 
    { Name: "LV Latvia", Value: "LV" }, 
    { Name: "LY Libya", Value: "LY" }, 
    { Name: "MA Morocco", Value: "MA" }, 
    { Name: "MC Monaco", Value: "MC" }, 
    { Name: "MD Moldova (the Republic of)", Value: "MD" }, 
    { Name: "ME Montenegro", Value: "ME" }, 
    { Name: "MF Saint Martin (French part)", Value: "MF" }, 
    { Name: "MG Madagascar", Value: "MG" }, 
    { Name: "MH Marshall Islands (the)", Value: "MH" }, 
    { Name: "MK North Macedonia", Value: "MK" }, 
    { Name: "ML Mali", Value: "ML" }, 
    { Name: "MM Myanmar", Value: "MM" }, 
    { Name: "MN Mongolia", Value: "MN" }, 
    { Name: "MO Macao", Value: "MO" }, 
    { Name: "MP Northern Mariana Islands (the)", Value: "MP" }, 
    { Name: "MQ Martinique", Value: "MQ" }, 
    { Name: "MR Mauritania", Value: "MR" }, 
    { Name: "MS Montserrat", Value: "MS" }, 
    { Name: "MT Malta", Value: "MT" }, 
    { Name: "MU Mauritius", Value: "MU" }, 
    { Name: "MV Maldives", Value: "MV" }, 
    { Name: "MW Malawi", Value: "MW" }, 
    { Name: "MX Mexico", Value: "MX" }, 
    { Name: "MY Malaysia", Value: "MY" }, 
    { Name: "MZ Mozambique", Value: "MZ" }, 
    { Name: "NA Namibia", Value: "NA" }, 
    { Name: "NC New Caledonia", Value: "NC" }, 
    { Name: "NE Niger (the)", Value: "NE" }, 
    { Name: "NF Norfolk Island", Value: "NF" }, 
    { Name: "NG Nigeria", Value: "NG" }, 
    { Name: "NI Nicaragua", Value: "NI" }, 
    { Name: "NL Netherlands (the)", Value: "NL" }, 
    { Name: "NO Norway", Value: "NO" }, 
    { Name: "NP Nepal", Value: "NP" }, 
    { Name: "NR Nauru", Value: "NR" }, 
    { Name: "NU Niue", Value: "NU" }, 
    { Name: "NZ New Zealand", Value: "NZ" }, 
    { Name: "OM Oman", Value: "OM" }, 
    { Name: "PA Panama", Value: "PA" }, 
    { Name: "PE Peru", Value: "PE" }, 
    { Name: "PF French Polynesia", Value: "PF" }, 
    { Name: "PG Papua New Guinea", Value: "PG" }, 
    { Name: "PH Philippines (the)", Value: "PH" }, 
    { Name: "PK Pakistan", Value: "PK" }, 
    { Name: "PL Poland", Value: "PL" }, 
    { Name: "PM Saint Pierre and Miquelon", Value: "PM" }, 
    { Name: "PN Pitcairn", Value: "PN" }, 
    { Name: "PR Puerto Rico", Value: "PR" }, 
    { Name: "PT Portugal", Value: "PT" }, 
    { Name: "PW Palau", Value: "PW" }, 
    { Name: "PY Paraguay", Value: "PY" }, 
    { Name: "QA Qatar", Value: "QA" }, 
    { Name: "RE Réunion", Value: "RE" }, 
    { Name: "RO Romania", Value: "RO" }, 
    { Name: "RS Serbia", Value: "RS" }, 
    { Name: "RU Russian Federation (the)", Value: "RU" }, 
    { Name: "RW Rwanda", Value: "RW" }, 
    { Name: "SA Saudi Arabia", Value: "SA" }, 
    { Name: "SB Solomon Islands", Value: "SB" }, 
    { Name: "SC Seychelles", Value: "SC" }, 
    { Name: "SD Sudan (the)", Value: "SD" }, 
    { Name: "SE Sweden", Value: "SE" }, 
    { Name: "SG Singapore", Value: "SG" }, 
    { Name: "SH Saint Helena, Ascension and Tristan da Cunha", Value: "SH" }, 
    { Name: "SI Slovenia", Value: "SI" }, 
    { Name: "SJ Svalbard and Jan Mayen", Value: "SJ" }, 
    { Name: "SK Slovakia", Value: "SK" }, 
    { Name: "SL Sierra Leone", Value: "SL" }, 
    { Name: "SM San Marino", Value: "SM" }, 
    { Name: "SN Senegal", Value: "SN" }, 
    { Name: "SO Somalia", Value: "SO" }, 
    { Name: "SR Suriname", Value: "SR" }, 
    { Name: "SS South Sudan", Value: "SS" }, 
    { Name: "ST Sao Tome and Principe", Value: "ST" }, 
    { Name: "SV El Salvador", Value: "SV" }, 
    { Name: "SX Sint Maarten (Dutch part)", Value: "SX" }, 
    { Name: "SY Syrian Arab Republic", Value: "SY" }, 
    { Name: "SZ Eswatini", Value: "SZ" }, 
    { Name: "TC Turks and Caicos Islands (the)", Value: "TC" }, 
    { Name: "TD Chad", Value: "TD" }, 
    { Name: "TF French Southern Territories (the)", Value: "TF" }, 
    { Name: "TG Togo", Value: "TG" }, 
    { Name: "TH Thailand", Value: "TH" }, 
    { Name: "TJ Tajikistan", Value: "TJ" }, 
    { Name: "TK Tokelau", Value: "TK" }, 
    { Name: "TL Timor-Leste", Value: "TL" }, 
    { Name: "TM Turkmenistan", Value: "TM" }, 
    { Name: "TN Tunisia", Value: "TN" }, 
    { Name: "TO Tonga", Value: "TO" }, 
    { Name: "TR Türkiye", Value: "TR" }, 
    { Name: "TT Trinidad and Tobago", Value: "TT" }, 
    { Name: "TV Tuvalu", Value: "TV" }, 
    { Name: "TW Taiwan (Province of China)", Value: "TW" }, 
    { Name: "TZ Tanzania, the United Republic of", Value: "TZ" }, 
    { Name: "UA Ukraine", Value: "UA" }, 
    { Name: "UG Uganda", Value: "UG" }, 
    { Name: "UM United States Minor Outlying Islands (the)", Value: "UM" }, 
    { Name: "US United States of America (the)", Value: "US" }, 
    { Name: "UY Uruguay", Value: "UY" }, 
    { Name: "UZ Uzbekistan", Value: "UZ" }, 
    { Name: "VA Holy See (the)", Value: "VA" }, 
    { Name: "VC Saint Vincent and the Grenadines", Value: "VC" }, 
    { Name: "VE Venezuela (Bolivarian Republic of)", Value: "VE" }, 
    { Name: "VG Virgin Islands (British)", Value: "VG" }, 
    { Name: "VI Virgin Islands (U.S.)", Value: "VI" }, 
    { Name: "VN Viet Nam", Value: "VN" }, 
    { Name: "VU Vanuatu", Value: "VU" }, 
    { Name: "WF Wallis and Futuna", Value: "WF" }, 
    { Name: "WS Samoa", Value: "WS" }, 
    { Name: "YE Yemen", Value: "YE" }, 
    { Name: "YT Mayotte", Value: "YT" }, 
    { Name: "ZA South Africa", Value: "ZA" }, 
    { Name: "ZM Zambia", Value: "ZM" }, 
    { Name: "ZW Zimbabwe", Value: "ZW" }
];