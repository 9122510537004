///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/editors/bindings/clickOutside"/>
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as $ from "jquery";
import * as _ from "underscore";
import * as ko from "knockout";

const $html = $("html");
const keyInitFlag = "clickOutsideFlag";

function getOptionsObject(options:any) {
    switch (typeof options) {
        case "function":
            return {
                handler: options,
                active: ko.observable(true)
            };
        case "object":
            return ({
                ...options,
                active: ko.observable(true)
            });
        default:
            throw new Error("Unsupported options");
    }
}

const clickOutside = {
    init: function (element:any, bindingAccessor:any, allAccessor:any, viewModel:any) {
        const options = getOptionsObject(bindingAccessor());
        const func = _.debounce(options.handler, 100, true).bind(viewModel);

        function checkActivity(active: boolean) {
            if (active) {
                $html.on("click", func);
            } else {
                $html.off("click", func);
            }
        }

        const subscription = options.active.subscribe(function(v:any) {
            return checkActivity(v);
        });
        checkActivity(options.active());
        
        koUtils.addDisposeCallback(element, function () {
            subscription.dispose();
            $html.off("click", func);
        });

        koUtils.domData.set(element, keyInitFlag, true);
    }
};

koUtils.registerBinding("clickOutside", clickOutside);