///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/documentSearch/actions/notification/wizard"/>
import * as _ from "underscore";
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as resultStep from "Core/Medius.Core.Web/Scripts/Medius/apps/documentSearch/actions/notification/steps/result";
import * as formStep from "Core/Medius.Core.Web/Scripts/Medius/apps/documentSearch/actions/notification/steps/form";
import { AbstractWizard } from "Core/Medius.Core.Web/Scripts/Medius/components/wizard/wizard";
import { Observable, observable, computed } from "knockout";

const defaults = {
    template: "Core:Medius/components/wizard/wizard.html",
    onClose: function () {
    },
    onCancel: function () {
    }
};
class NotificationWizard extends AbstractWizard {
    skipNextStep: boolean;
    isCurrentStepFullyCustom: Observable<boolean>;
    isLoading: Observable<boolean>;
    totalStepsCss: string;
    documentIds: any;
    documentType: any;
    documentTypeName: any;
    documentCount: any;
    isUserBasedDistribution = true;
    isRoleBasedDistrubution = true;

    constructor(selectionData: any, options: any) {
        super();
        selectionData = koUtils.unpack(selectionData);

        this.documentIds = selectionData.documentIds;
        this.documentType = selectionData.type;
        this.documentTypeName = globalization.getPropertyTranslation("#" + this.documentType);
        this.documentCount = selectionData.rowsCount;

        const steps = [
            formStep.create(this),
            resultStep.create()
        ];
        this.skipNextStep = false;
        this.options = _.extend({}, defaults, options);
        this.isCurrentStepFullyCustom = observable(false);
        this.isLoading = observable(false);
        this.isVisible = observable(true);
        this.steps = steps;
        this.totalStepsCss = "steps-" + steps.length;
        this.maxStepIndex = steps.length - 1;
        this.currentStepIndex = observable(-1);
        this.currentStep = computed(() => {
            if (this.currentStepIndex() === -1)
                return null;
            return steps[this.currentStepIndex()];
        });

        this.currentStepName = computed(() => {
            return (this.currentStep()) ? this.currentStep().name : null;
        });
        this.data = {};
        this.instanceObservable = null;

        this.isVisibleSub = this.isVisible.subscribe((newValue) => {
            if (newValue === false) {
                this.cancel();
            }
        });

        this.init();
    }
}

export function create(selectionData: any, options: any) {
    return new NotificationWizard(selectionData, options);
}