/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/lib/utils/extract"/>
import * as ko from "knockout";
import { Amount } from "Core/Medius.Core.Web/Scripts/Models/amount";

type TypeCheck = (value: any) => boolean;
type KeyOfType = string | number | symbol;

export type AllowNull = "orNull" | boolean;

/**
 * This utility allows to Extract (and Unwrap from an Observable) value from given Instance.
 * Extracted value is then checked against expected type and nullability in the runtime.
 * In case of type mismatch, unexpected NULL, or missing value altogether, the Error is being thrown.
 *
 * TypeScript compiler will guard valid Names for Field's key - when type of Instance is known,
 * however it will not check if requested type of value is matched to requested field defined type.
 */
export class Extract {
    public static anyFrom<T>(instance: T, field: keyof T, allowNull?: AllowNull): any {
        return Extract.anything(instance, field, allowNull, _ => true);
    }

    public static arrayFrom<T>(instance: T, field: keyof T, allowNull?: AllowNull): any[] {
        return Extract.anything(instance, field, allowNull, x => Array.isArray(x));
    }

    public static booleanFrom<T>(instance: T, field: keyof T, allowNull?: AllowNull): boolean {
        return Extract.anything(instance, field, allowNull, x => typeof x === "boolean");
    }

    public static numberFrom<T>(instance: T, field: keyof T, allowNull?: AllowNull): number {
        return Extract.anything(instance, field, allowNull, x => typeof x === "number");
    }

    public static stringFrom<T>(instance: T, field: keyof T, allowNull?: AllowNull): string {
        return Extract.anything(instance, field, allowNull, x => typeof x === "string");
    }

    public static dateFrom<T>(instance: T, field: keyof T, allowNull?: AllowNull): Date {
        return Extract.anything(instance, field, allowNull, x => x instanceof Date);
    }

    public static amountFrom<T>(instance: T, field: keyof T, allowNull?: AllowNull): Amount {
        return Extract.anything(instance, field, allowNull, x => x instanceof Amount);
    }

    public static entityIdFrom<T>(instance: T, field: keyof T, allowNull?: AllowNull): number {
        const entity = Extract.anything(instance, field, allowNull, _ => true);
        if (allowNull && entity == null)
            return null;

        return Extract.numberFrom(entity, "Id", allowNull);
    }

    private static anything(instance: any, field: KeyOfType, allowNull: AllowNull, hasExpectedType: TypeCheck): any {
        const fieldValue = instance[field];
        if (fieldValue === undefined) {
            throw new Error(`Missing field: '${String(field)}'.`);
        }

        const value = ko.unwrap(fieldValue) as any;
        if (!allowNull && value == null) {
            throw new Error(`Unexpected NULL in field: '${String(field)}'.`);
        }

        if (value != null && !hasExpectedType(value)) {
            throw new Error(`Unexpected type in field: '${String(field)}' => '${value}'.`);
        }

        return value;
    }
}
