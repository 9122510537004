/// <amd-module name="Core/Medius.Core.Web/Scripts/components/procurement/checkoutModalSlice"/>

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "Core/Medius.Core.Web/Scripts/shared/store/reduxStore";

interface CheckoutModal {
    expandedState: boolean
}

const initialState: CheckoutModal = { expandedState: false };

export const checkoutModalSlice = createSlice({
    name: 'checkoutModal',
    initialState,
    reducers: {
        setModalState: (state, action: PayloadAction<boolean>) => {
            state.expandedState = action.payload;
        }
    }
});

export const {setModalState} = checkoutModalSlice.actions;

export function checkoutModalSelector(s: RootState) { return s.procurement.checkoutModal.expandedState; }
