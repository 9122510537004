///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/transformers/serialize/toJS"/>
import * as defaults from "Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/transformers/create/defaults";
import * as utils from "Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/transformers/create/utils";

export = function toJsViewmodelSerializer(input:any, context:any) {
    let data:any = null;
    const mapping = utils.getMapping(input, context);

    if (!mapping) {
        return null;
    }

    if (mapping && mapping.toJS) {
        data = mapping.toJS(input, context);
    }

    if (data === undefined) {
        return null;
    }

    return {
        ...defaults,
        shouldDescend: false,
        finalize: function () {
            return data;
        }  
    };
};