///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/ReadOnlyRelation/queryBuilder"/>
import * as _ from "underscore";
import * as ko from "knockout";
import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";

class QueryBuilder {
    filter(dataQuery:any, collection:any[]) {
        const properties = _.keys(dataQuery.keywords);
        let filteredCollection = collection;

        properties.forEach((property) => {
            const keywords = dataQuery.keywords[property].split(" ");

            if (keywords.length > 0) {
                const lowerCaseKeywords = keywords.map((keyword:string) => {
                    return keyword.toLowerCase();
                });

                filteredCollection = (filteredCollection).filter((item:any) => {
                    const findResult = lowerCaseKeywords.find((keyword:string) => {
                        const propertyValue = ko.utils.unwrapObservable(item[property]).toLowerCase();

                        if (propertyValue.indexOf(keyword) !== -1) {
                            return true;
                        }
                    });

                    return !isNullOrUndefined(findResult);
                });
            }
        });

      return filteredCollection;
  } 
}

export function create() {
    return new QueryBuilder();
}