/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/default/text/entity/currencyRate"/>
import { unpack } from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as typeBoundResolverFactory from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/typeBound";
import * as financialLocalizer from "Core/Medius.Core.Web/Scripts/Medius/components/localizer/type/financial";

export const resolver = typeBoundResolverFactory.create({
    priority: 0,

    type: "Medius.Core.Entities.CurrencyRate",

    resolve: function (currencyRate: any) {
        let displayValue = unpack(currencyRate.DisplayValue);
        const displayResolution = unpack(currencyRate.Resolution);

        displayValue = financialLocalizer.toString(displayValue, {
            decimalResolution: displayResolution
        });

        return { text: displayValue };
    }
});
