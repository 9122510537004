/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/editors/basic" />

import * as _ from "underscore";
import * as $ from "jquery";
import * as ko from "knockout";
import * as logger from "Core/Medius.Core.Web/Scripts/Medius/lib/logger";
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as helpers from "Core/Medius.Core.Web/Scripts/Medius/components/editors/helpers";
import * as ValueOrigin from "Core/Medius.Core.Web/Scripts/Medius/components/editors/valueOrigin";
import * as defaultLocalizer from "Core/Medius.Core.Web/Scripts/Medius/components/editors/localizer";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { isEmptyString } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import { create as createQueue } from "Core/Medius.Core.Web/Scripts/Medius/lib/task/queue";
import { Validator as ImportedValidator } from "Core/Medius.Core.Web/Scripts/Medius/components/editors/validator";

export class Basic {
    params: any;
    Validator: any | ImportedValidator;
    Disabled: any;
    Autofocus: any;
    Template: any;
    Options: any;
    HasFocus: ko.Observable;
    Localizer: any;
    IsRequired: any;
    Validity: any;
    IsValidating: any;
    IsValid: any;
    IsInvalid: any;
    ValidationDetails: any;
    runValidationAsync: any;
    ongoingValidation: any;
    OriginalValue: any;
    PreviousValue: any;
    InputValue = ko.observable();
    LocalizedValue = ko.observable();
    queue: any;

    OriginalValueChanging = ko.observable();
    OriginalValueSubscription: any;
    
    ShowErrorIndicator: ko.Computed;
    triggerValidation: any;
    isQueueFromContext: boolean;

    

    constructor(bindingParameters: any, context: any, params: any = {}) {
        const inputValidator = params?.inputValidator;
        const localizer = params?.localizer; //should be null coming from test
        const validator = params?.validator;

        if (_.isUndefined(bindingParameters) || _.isUndefined(bindingParameters.value) || !ko.isObservable(bindingParameters.value)) {
            throw new Error("Basic editor component - OriginalValue not provided");
        }

        this.params = {
            template: "",
            validator: typeof validator === "function" ? validator : null,
            required: false,
            updateIfInvalid: false,
            validationThrottle: 100,
            validationEvent: "change", // 'change' | 'keyup',
            runValidationAsync: true,
            disabled: false,
            autofocus: false,
            options: {
                defaultName: "editor",
                id: null,
                css: null,
                title: null,
                tooltip: { enabled: false },
                tabindex: 0
            },
            emptyCheck: null
        };

        helpers.mergeDefaults(bindingParameters, this.params);

        if (!this.params.options.id) {
            this.params.options.id = _.uniqueId(`${this.params.options.defaultName}-`);
        }

        if (this.params.options && this.params.options.placeholder) {
            this.params.options.placeholder = getLabelTranslation(this.params.options.placeholder);
        }

        this.Disabled = koUtils.pack(this.params.disabled);
        this.Autofocus = this.params.autofocus;
        this.Template = this.params.template;
        this.Options = this.params.options;
        this.HasFocus = ko.observable(false);

        this.Validator = this.params.validator && typeof this.params.validator === "object"
            ? this.params.validator
            : new ImportedValidator({
                required: this.params.required,
                updateIfInvalid: this.params.updateIfInvalid,
                businessValidator: this.params.validator || validator,
                inputValidator: this.params.inputValidator || inputValidator,
                emptyCheck: this.params.emptyCheck
            });

        this.IsRequired = this.Validator.IsRequired;
        this.Validity = this.Validator.Validity;
        this.IsValidating = this.Validator.IsValidating;
        this.IsValid = this.Validator.IsValid;
        this.IsInvalid = this.Validator.IsInvalid;
        this.ValidationDetails = this.Validator.ValidationDetails;
        this.runValidationAsync = this.params.runValidationAsync;
        this.ongoingValidation = $.when();
        
        this.Localizer = koUtils.pack(
            localizer ? localizer : defaultLocalizer.create()
        );        

        this.OriginalValue = this.params.value;
        this.PreviousValue = ko.observable(this.OriginalValue());

        this.OriginalValueSubscription = this.OriginalValue
            .subscribe((value: any) => {
                this.localize(value, (localized: any) => {
                    this.LocalizedValue(localized);
                    this.validate(value, ValueOrigin.Outside);
                    this.debug(`updated with value: '${value}'`);
                });
            });

        this.localize(this.OriginalValue(), (localized: any) => {
            this.InputValue(localized);
            this.LocalizedValue(localized);
            this.initializeRequiredValidation(localized);
        });

        this.ShowErrorIndicator = ko.computed(() => {
            return this.IsInvalid() && !isEmptyString(this.ValidationDetails());
        });

        this.triggerValidation = this.params.validationThrottle
            ? _.debounce(this.onValidationTriggered.bind(this), this.params.validationThrottle, false)
            : () => { this.onValidationTriggered(); };

        if (context && context.queue) {
            this.isQueueFromContext = true;
            this.queue = context.queue;
        } else {
            this.isQueueFromContext = false;
            this.queue = createQueue({
                name: ["component", this.params.options.id, "queue"].join("_")
            });
        }

        this.debug("initialized");
    }

    debug(message: any){
        logger.debug(`Component ${this.params.options.id}: ${message}`);
    }

    dispose(){
        this.ShowErrorIndicator.dispose();

        // Queue should only be disposed if created by component itself,
        // otherwise it will be disposed by context.
        if (!this.isQueueFromContext) {
            this.queue.dispose();
        }

        this.Validator.dispose();
        this.Validator = null;
        this.IsRequired = null;
        this.Validity = null;
        this.IsValid = null;
        this.IsInvalid = null;
        this.IsValidating = null;
        this.ValidationDetails = null;
        this.ongoingValidation = null;
        this.OriginalValueSubscription.dispose();
        this.OriginalValue = null;
        this.Localizer = null;
        this.queue = null;
        this.Options = null;
        this.params = null;
    }

    runAfterOngoingValidation(validationPromiseSource: any) {
        const next = $.Deferred();

        this.ongoingValidation.always(next.resolve);
        this.ongoingValidation = next
            .pipe(validationPromiseSource);

        return this.ongoingValidation.promise();
    }

    validateInput() {
        return this.runAfterOngoingValidation(() => {
            this.debug("executing input-validation");
            return $.when()
                .pipe(() => {
                    return this.Localizer().fromString(this.InputValue());
                })
                .pipe((localizedValue) => {
                    return this.Validator.validate(localizedValue);
                })
                .pipe(() => {
                    return this.IsValid();
                });
        });
    }

    onValidationTriggered() {
        return this.runAfterOngoingValidation(() => {
            this.debug("executing triggered-Validation");
            return this.validate(this.InputValue(), ValueOrigin.Input);
        });
    }

    validate(value: any, valueOrigin: any) {
        const validationRequest = {
            originalValue: this.OriginalValue(),
            inputValue: this.InputValue(),
            localizedValue: undefined as any,
            triggeringValue: value,
            valueOrigin: valueOrigin,
            date: Date.now()
        };

        if (this.PreviousValue() === value) {
            this.updateInputValue(value);
            return $.when();
        }

        this.debug(`validation will run for raw value: '${value}`);
        this.PreviousValue(value);

        const validationBreak = $.Deferred();
        const validationPromise = validationBreak
            .pipe(() => {
                return ValueOrigin.Input === valueOrigin
                    ? this.Localizer().fromString(value)
                    : value;
            })
            .pipe((localizedValue) => {
                validationRequest.localizedValue = localizedValue;
                if (this.Validator) {
                    return $.when(this.Validator.validate(localizedValue))
                    .pipe((result) => {
                        return this.onValidationCompleted(validationRequest, result);
                    });
                }                    
            });

        if (this.runValidationAsync) {
            // We need to move it to the clean stack frame, otherwise Chrome makes it
            // asynchronous by itself and screws up the `hasFocus` knockout binding.
            _.defer(validationBreak.resolve);
        } else {
            validationBreak.resolve();
        }

        return this.queue.enqueue({
            name: "validation " + this.params.options.id,
            action: () => {
                return validationPromise;
            }
        }).getDeferred();
    }

    initializeRequiredValidation(value: string | any) {
        value = typeof value === "string"
            ? this.Localizer().fromString(value)
            : value;

        if (!this.Validator.runRequiredValidation(value)) {
            this.Validator.setInvalid(null);
        }
    }

    onValidationCompleted(validationRequest: any, result: any) {
        const valueOrigin = validationRequest.valueOrigin;
        let newValue = validationRequest.localizedValue;

        this.debug(`validation of '${newValue}' completed with IsValid: ${this.IsValid}`);

        if (this.IsValid()) {
            if (validationRequest.valueOrigin === ValueOrigin.Input) {
                this.updateOriginalValue(newValue);
            }

            this.updateInputValue(newValue);
        } else if (this.IsInvalid()) {
            newValue = result.value || newValue;

            if (valueOrigin === ValueOrigin.Input && result.updateIfInvalid === true) {
                this.updateOriginalValue(newValue);
            } else if (valueOrigin === ValueOrigin.Outside) {
                this.updateInputValue(newValue);
            }
        }
    }

    updateInputValue(newValue: any) {
        const event = this.params.validationEvent;

        if (event === "change" || event === "keyup" && !this.HasFocus()) {
            this.localize(newValue, this.InputValue);
        }
    }

    updateOriginalValue(newValue: any) {
        newValue = newValue && newValue.trim
            ? newValue.trim()
            : newValue;

        this.OriginalValueChanging({
            oldValue: this.OriginalValue(),
            newValue: newValue
        });

        this.OriginalValue(newValue);
    }

    localize(value: any, target: any) {
        const localized = this.Localizer()
                .toString(koUtils.unpack(value));

        $.when(localized)
            .done(target);
    }



    
}

export function create(bindingParameters: any, context: any, params: any){
    return new Basic(bindingParameters, context, params);
}
export const derive = Basic;

