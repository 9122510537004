///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/accounting/autocompleter/dataProvider/taxGroupDto"/>
import * as base from "Core/Medius.Core.Web/Scripts/Medius/apps/accounting/autocompleter/dataProvider/taxGroup";

class Provider extends base.Provider {
    constructor(context: any, companyId: any, indicatorNumber: number, modelTaxIndicator1Observable: any, modelTaxIndicator2Observable: any){
        super(context, companyId, indicatorNumber, modelTaxIndicator1Observable, modelTaxIndicator2Observable, undefined, "GetTaxGroupsDto");
    }

    createDummy(value:any) {
        return {
            $type: "Medius.Enterprise.DTO.Accounting.TaxGroupDto",
            Id: 0,
            Code: value
        };
    }
}

export function create(context: any, companyId: any, indicatorNumber: number, modelTaxIndicator1Observable: any, modelTaxIndicator2Observable: any) {
    return new Provider(context, companyId, indicatorNumber, modelTaxIndicator1Observable, modelTaxIndicator2Observable);
}