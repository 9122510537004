/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/AuthorizationGroups/Tabs/supplierLimitsTab"/>
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as React from "react";
import { useContext } from "react";
import { SupplierPermission } from "../authorizationGroup";
import { CompanyContext } from "../contexts/companyContext";
import SupplierLimitGrid from "./components/supplierLimitGrid";

type SupplierLimitsTabProps = {
    readonly data: SupplierPermission[];
    readonly onChange: (newPermissions: SupplierPermission[]) => void;
};

export function SupplierLimitsTab({data, onChange}: SupplierLimitsTabProps) {
    const companyContext = useContext(CompanyContext);
    const isCompanySelected = companyContext.companyId > 0;

    return isCompanySelected
        ? <SupplierLimitGrid data={data} onChange={onChange} />
        : <div>{translate("#Enterprise/authorizationGroupsTabsSelectCompany")}</div>;
}
