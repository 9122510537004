///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/grid/bindings/draggableRow"/>
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as ko from "knockout";
import * as $ from "jquery";
import * as _ from "underscore";

const dragRow = {
    init: function (element:any, bindingAccessor:any, allAccessor:any, viewModel:any, bindingContext:any) {
        let $element = $(element);
        let $body = $("body");
        let createLabel = (bindingAccessor().labelCreator);
        let onDragStart = (bindingAccessor().onDragStart);
        const enabled = !!koUtils.unpack(bindingAccessor().enabled);
        if (!enabled) {
            return;
        }
        
        const dragOptions = {
            cursor: "move",
            delay: 200,
            revert: true,
            revertDuration: 0,
            helper: function () {
                const tooltip = $("<div/>").addClass("medius-grid-drag-tooltip");
                $body.append(tooltip);
                return tooltip;
            },
            start: function (event:any, ui:any) {
                const grid = bindingContext.$grid;
                const multiselectEnabled = grid.MultiselectEnabled;
                const selectedRow = grid.SelectedRow();
                const selectedRowId = (selectedRow) ? koUtils.unpack(selectedRow.Id) : null;
                const currentRow = ko.dataFor(this);
                const currentRowId = koUtils.unpack(currentRow.Id);
                let draggedRows = [];

                if (multiselectEnabled) {
                    if (!_(grid.SelectedIds()).contains(currentRowId)) {
                        grid.markRow(currentRowId, true);
                    }

                    if (selectedRowId && !_(grid.SelectedIds()).contains(selectedRowId)) {
                        grid.SelectedRow(null);
                    }
                    
                    if (onDragStart) {
                        onDragStart(grid, currentRow);
                    }
                    
                    draggedRows = grid.getSelectedRows();
                } else if (currentRow) {
                    grid.SelectedRow(currentRow);
                    draggedRows = [currentRow];
                }
                                    
                if (draggedRows.length > 0) {
                    bindingContext.$root.draggedGrid = grid;
                    
                    const label = $("<div/>");
                    label.text(createLabel(draggedRows));
                    ui.helper.html(label);
                }
            }
        };
        
        $element.draggable(dragOptions);
        
        const dispose = function () {
            onDragStart = null;
            createLabel = null;
            $element = null;
            $body = null;
        };

        koUtils.addDisposeCallback(element, dispose);
    }
};

export function register() {
    koUtils.registerBinding("grid-draggableRow", dragRow);
}