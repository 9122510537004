/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/components/default" />
import * as basicFactory from "Core/Medius.Core.Web/Scripts/Medius/components/components/basic";
import * as dasFactory from "Core/Medius.Core.Web/Scripts/Medius/components/components/accessControl/das";

export function create(bindingParameters: any, ...args: any){
    if (bindingParameters && typeof bindingParameters.accessLevel === "function") {
        // delegate to creation of basic model
        return basicFactory.create.apply(this, [bindingParameters, ...args]);
    }
    // delegate to creation of DAS model
    return dasFactory.create.apply(this, [bindingParameters, ...args]);
}