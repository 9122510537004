/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/tabTemplateProvider"/>
const templateRegistry: {[key: string]: any[]} = {};

export const registerTemplates = (type: string, templates: any[]) => {
    if (!templateRegistry[type]) {
        templateRegistry[type] = templates;
    }
    else {
        templateRegistry[type] = templateRegistry[type].concat(templates);
    }
};

export const hasTemplate = (type: string, id: string) => {
    if (!templateRegistry[type]) {
        return false;
    }
    return templateRegistry[type].some(x => x.Properties.Id === id);
};

export const getTemplate = (type: string, id: string) => {
    return templateRegistry[type].filter(template => template.Properties.Id == id)[0].Template;
};

export const getTabsForType = (type: string) => {
    return templateRegistry[type] ? templateRegistry[type] : [];
};
