///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/bindings/positionResults"/>
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as $ from "jquery";

function elementOverflowsRightEdge(element:any) {
    return document.documentElement.clientWidth < element.getBoundingClientRect().right;
}

function elementOverflowsBottom(element:any) {
    return document.documentElement.clientHeight < element.getBoundingClientRect().bottom;
}

const positionResults = {
    update: function (element:any, valueAccessor:any) {
        const $element = $(element);
        const $relativeElement = $element.offsetParent();
        const $autocompleter = $element.prev();
        const isPositionFixed = $element.css('position') === 'fixed';
        const relativeElementWidth = isPositionFixed ? $(window).width() : $relativeElement.innerWidth();
        const relativeElementHeight = isPositionFixed ? $(window).height() : $relativeElement.innerHeight();

        const accessor = valueAccessor();
        let rightAlign = accessor ? accessor.rightAlign : false;
        const disableElementReposition = accessor ? accessor.disableElementReposition : false;
        // setting top temporarily to 0 prevents some potential overflow issues causing offset to be computed incorrectly
        $element.css("top", 0);
        const autocompleterOffset = isPositionFixed ? $autocompleter.offset() : $autocompleter.position();

        let offsetTop = autocompleterOffset.top;
        offsetTop += $autocompleter.height() + 5;

        if (!rightAlign) {
            const offsetLeft = autocompleterOffset.left;
            $element.css("left", Math.floor(offsetLeft));

            rightAlign = elementOverflowsRightEdge(element);
        }

        if (rightAlign) {
            const offsetRight = relativeElementWidth - (autocompleterOffset.left + $autocompleter.width());
            $element.css("right", Math.floor(offsetRight))
                    .css("left", "auto");
        }

        $element.css("top", Math.floor(offsetTop));

        let elementMayOverflowBottom:boolean;
        if (disableElementReposition) {
            const elementOffsetBottom = document.documentElement.clientHeight - $autocompleter.offset().top - $autocompleter.outerHeight();
            elementMayOverflowBottom = elementOffsetBottom < parseInt($element.css('max-height'), 10);
        }

        if (elementMayOverflowBottom || elementOverflowsBottom(element)) {
            const offsetBottom = relativeElementHeight - (autocompleterOffset.top - 5);
            $element.css("top", "auto");
            $element.css("bottom", offsetBottom);
        }
    }
};

koUtils.registerBinding("positionResults", positionResults);