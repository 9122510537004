///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/inbox/search/query" />

export function TaskCondition(property: any = null, from: any = null, to: any = null) {
    return {
        Property: property,
        From: from,
        To: to,
    };
}

export function DocumentCondition(id: any, from: any, to: any, conditionName: any) {
    const DocumentConditionObject = {
        Id: id || null,
        From: typeof from === "boolean"? from : from || null,
        To: typeof to === "boolean" ? to : to || null,
        ValueSource: null as any
    };
        
    if (!!conditionName){
        return {
            ...DocumentConditionObject,
            InboxFilterConditionName: conditionName
        };
    }
    
    return DocumentConditionObject;
}

export function DocumentCriteria(type: any) {
    return {
        Type: type || null,
        Conditions: [] as any[],
        Labels: [] as any[],
        WithLabels: false,
        OnHoldCondition: null as any,
        InReviewCondition: null as any,
        RiskStatusChosen: [] as any[]
    };
}

export function Query() {
    return {
        Name: null as any,
        Documents: [] as any[],
        Task: [] as any[]
    };
}