
///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/mapping/default"/>
import * as ko from "knockout";
import * as helpers from "Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/helpers";

export const construct:any = undefined;
export const prototype:any = undefined;
export const make:any = undefined;
export const initializeContext:any = undefined;

export function shouldRebuildViewmodel(viewmodel:any, data:any, context:any) {
    const metadata = viewmodel && viewmodel.__metadata__;
    const mapping = metadata && metadata.mapping;
    const dataKey = mapping && mapping.key && mapping.key(data, context);
    const vmKey = metadata && metadata.key;

    return !viewmodel || helpers.isArray(viewmodel) || helpers.isArray(data) || dataKey !== vmKey;
}

export function key(data?:any) {
    if (data && data.$type) {
        const type = ko.utils.unwrapObservable(data.$type);
        const id = ko.utils.unwrapObservable(data.Id);
        const viewId = ko.utils.unwrapObservable(data.ViewId);

        return id ?
            [type, id].join(":") :
            viewId ? [type, viewId].join(":") :
                null;
    }
    return null;
}

export function newObject(data:any, prototype: any) {
    if (!data) {
        return null;
    }
    function Viewmodel(data: any){

    }
    Viewmodel.prototype = prototype;

    return new (Viewmodel as any)(data);
}

export function update(vm:any, data:any/*, context*/) {
    if (vm && vm.update) {
        return vm.update(data);
    }
    return true;
}

export function toJS(vm?:any) {
    if (vm && vm.toJS) {
        return vm.toJS();
    }
    return undefined;
}