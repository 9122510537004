///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/documentSearch/tabs"/>
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as eventBus from "Core/Medius.Core.Web/Scripts/eventBus";
import * as EventNames from "Core/Medius.Core.Web/Scripts/events/eventNames";
import * as ko from "knockout";
import * as _ from "underscore";
import * as $ from "jquery";
import * as cookies from "Core/Medius.Core.Web/Scripts/lib/cookie";

const tabs = ko.observableArray([]);

class DocumentSearchTabs {
    register(tabViewModel: any, tabTemplateId: any, tabLabel: string | number, tabTag: any) {
        const tabText = globalization.getLabelTranslation(tabLabel);
        tabs.push({ TabViewModel: tabViewModel, TabTemplateId: tabTemplateId, TabText: tabText, TabTag: tabTag });
    }

    tabs = ko.computed(() => {
        return _(tabs()).reject((tab) => { return ko.unwrap(tab.TabViewModel.isInactive); });
    });

    activeTabIndex = ko.observable(0);

    setActiveTab(tabTag: any) {
        const tabToActivate = _.find(tabs(), (tab) => {
            return tab.TabTag === tabTag;
        });
        if (tabToActivate) {
            this.activeTabIndex(tabs().indexOf(tabToActivate));
        }
    }

    setReportsAsDefault() {
        require("PurchaseToPay/Medius.PurchaseToPay.Web/Scripts/Reporting/uxMetrics")
            .UxMetrics.logTakeToReports();

        cookies.setCookie("ReportsDefault", true, new Date(2040, 12, 31));
        eventBus.trigger(EventNames.reportingToolChanged);
        return true;
    }

    constructor() {
        this.activeTabIndex.extend({ notify: "always" });
    }
}

export = new DocumentSearchTabs();