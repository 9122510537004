///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/company/factory"/>
import * as model from "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/company/model";
import * as defaultsFactory from "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/company/defaults";
import * as dataProviderFactory from "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/company/dataProvider";
import * as utils from "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/utils";

export function create(bindingParams:any) {
    const optionsExtension = {
        autofocus: bindingParams.autofocus,
        disabled: bindingParams.disabled,
        required: bindingParams.required,
        useDefault: bindingParams.useDefault
    },
    providerOptionsExtension = {
        company: bindingParams.company,
        includeVirtual: bindingParams.includeVirtual,
        includeReal: bindingParams.includeReal,
        includeInactive: bindingParams.includeInactive
    },
    params = utils.createParameters(bindingParams, optionsExtension, providerOptionsExtension, defaultsFactory, dataProviderFactory);

    return model.create(params);
}