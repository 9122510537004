/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/spaManager/uiHandler" />
import * as _ from "underscore";

const visibleClassName = "visible";
const $window = $(window);

export class UIHandler {
    public $mainElement;
    public $appsContainer;
    public Apps;
    public ActiveApp: any;

    constructor(manager: any, containerId: string, appsContainerId: string) {
        this.$mainElement = $("#" + containerId);
        this.$appsContainer = $("#" + appsContainerId);
        this.Apps = manager.Apps;
        this.ActiveApp = null;
    }

    public init = () => {
        this.hideAllApps();
        this.fitSpaContainers();
        this.showAllApps();

        $window.resize(() => {
            this.fitSpaContainers();
            this.positionApp(this.ActiveApp);
        });
    };

    public fitSpaContainers= (appToActivate?: any) => {
        const h = this.getViewportHeight();
        const w = this.getViewportWidth();

        this.setElementHeight(this.$mainElement, h);
        this.$appsContainer.css("width", w * this.Apps.length);

        _.each(this.Apps, (app) => {
            this.setAppWidth(app, w);
        });
    };

    public positionApp = (app: any) => {
        this.fitSpaContainers(app); // Fix from MFP-4790, see its description for details
        this.$appsContainer.css("left", this.calculatePosition(app));
    };

    public getViewportHeight = () => {
        const marginBottom = 0;

        const topOffset = this.$mainElement.offset().top;
        const height = $window.height() - topOffset - marginBottom;
        return height;
    };

    public hideAllApps = () => {
        _.each(this.Apps, (app) => {
            this.hideApp(app);
        });
    };

    public showAllApps = () => {
        _.each(this.Apps, (app) => {
            this.showApp(app);
        });
    };

    public calculatePosition = (targetApp: any) => {
        let distance;
        const appIndex = _.indexOf(this.Apps, targetApp);
            
        if (appIndex === -1) {
            return "0px";
        }

        distance = appIndex * this.getViewportWidth();

        if (distance > 0) {
            distance = `-${distance}px`;
        } else {
            distance = "0px";
        }

        return distance;
    };

    public makeTransition = (currentApp: any, targetApp: any) => {
        if (!currentApp || !targetApp) {
            return false;
        }

        this.showApp(targetApp);

        const transition = this.$appsContainer.animate({
            left: this.calculatePosition(targetApp)
        }, 500, () => {
            if (currentApp) {
                this.hideApp(currentApp);
            }
        });

        this.ActiveApp = targetApp;

        return transition;
    };

    public openApp = (targetApp: any) => {
        if (targetApp === this.ActiveApp) {
            return;
        }

        this.hideAllApps();
        this.positionApp(targetApp);
        this.showApp(targetApp);
        this.ActiveApp = targetApp;
    };

    private getViewportWidth() {
        return $window.width();
    }
    
    private setElementHeight($element: any, height: any) {
        $element.css("height", height);
    }

    private setAppWidth(app: any, width: any) {
        $("#" + app.containerId).css("width", width);
    }

    private hideApp(app: any) {
        $("#" + app.containerId).removeClass(visibleClassName);
    }

    private showApp(app: any) {
        $("#" + app.containerId).addClass(visibleClassName);
    }
}