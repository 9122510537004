/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/document/documentManager"/>
import { observable } from "knockout";
import * as $ from "jquery";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as notification from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import { getOutboxInstance } from "Core/Medius.Core.Web/Scripts/components/outbox/outbox";
import { formatAnyError } from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import { trigger } from "Core/Medius.Core.Web/Scripts/eventBus";
import { taskHandled as taskHandledEventName } from "Core/Medius.Core.Web/Scripts/events/eventNames";
import { request } from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rpc";
import { uiDocumentManagerRegistry } from "Core/Medius.Core.Web/Scripts/Medius/apps/document/uiDocumentManager";
import * as registrationEvents from "Core/Medius.Core.Web/Scripts/Medius/apps/document/events";

export class DocumentManagerViewModel {
    private enqueue: any;
    private targetElement: JQuery;
    private isSaving = observable(false);

    public CommentsModel: any;

    constructor(targetElement: HTMLElement, contextResource: any) {
        this.targetElement = $(targetElement);
        this.enqueue = contextResource.enqueue;
    }

    public registerDocument(document: any) {
        if (document.isLoading && document.isLoading()) {
            notification.info(translate("#Core/performingRequestedOperations"));
            return;
        }

        if (document.isValid && document.errorMessage && !document.isValid()) {
            notification.error(document.errorMessage());
            return;
        }

        if (this.isSaving()) {
            this.onRegisterDocumentForbidden();
            return;
        }

        this.isSaving(true);

        const uiDocumentManager = uiDocumentManagerRegistry.get(document.$type());
        const registerDocument = () => {
            uiDocumentManager.registerDocument(document)
                .done((data: any) => {
                    this.onRegisterDocumentSuccess(data);
                }).fail((data: any) => {
                    this.onRegisterDocumentError(data);
                }).always(() => {
                    this.isSaving(false);
                });
        };

        this.enqueue({
            action: registerDocument
        });
    }

    public performDocumentReclassification(document: any, sourceTask: any) {
        if (document.isLoading && document.isLoading()) {
            notification.info(translate("#Core/performingRequestedOperations"));
            return;
        }

        if (document.isValid && document.errorMessage && !document.isValid()) {
            notification.error(document.errorMessage());
            return;
        }

        if (this.isSaving()) {
            this.onRegisterDocumentForbidden();
            return;
        }

        this.isSaving(true);

        const performDocumentReclassification = () => {
            const outboxItem = getOutboxInstance().enqueue(sourceTask.Id(), sourceTask.Document().Id());
            trigger(taskHandledEventName, sourceTask.Id());
            return request("UiDocumentManager", "PerformDocumentReclassification", {
                newDocument: document.toDto(),
                originalTaskId: sourceTask.Id()
            }).done((data: any) => {
                this.onRegisterDocumentSuccess(data);
                outboxItem.done();
            }).fail((data: any) => {
                const message = formatAnyError(data).text;
                const validationMessages = message.split("\n");
                outboxItem.fail(validationMessages);
                this.onRegisterDocumentError(data);
            }).always(() => {
                this.isSaving(false);
            });
        };   

        this.enqueue({
            action: performDocumentReclassification
        });
    }

    public deleteDocumentMetadata(document: any) {
        function deleteDocumentMetadata() {
            return request("UiDocumentManager", "DeleteDocumentMetadata", {
                document: document.toDto()
            });
        }

        return this.enqueue({
            action: deleteDocumentMetadata
        });
    }

    public saveAsDraft(document: any, draftName: any) {
        const saveAsDraft = () => {
            return request("UiDocumentManager", "SaveAsDraftWithTransaction", {
                document: document.toDto(),
                name: draftName
            }).done(() => {
                this.onSaveAsDraftSuccess();
            });
        };

        return this.enqueue({
            action: saveAsDraft
        });
    }

    private saveUnsavedComment() {
        if (this.CommentsModel && this.CommentsModel.newComment()) {
            this.CommentsModel.addComment();
        }
    }

    private onRegisterDocumentError(data: any) {
        if (data.status === 400) {
            this.targetElement.trigger(registrationEvents.validationError, data);
        } else {
            this.targetElement.trigger(registrationEvents.saveError, data);
        }
    }

    private onRegisterDocumentForbidden() {
        this.targetElement.trigger(registrationEvents.saveForbidden);
    }

    private onRegisterDocumentSuccess(data: any) {
        this.targetElement.trigger(registrationEvents.saveSuccess, data);
        this.saveUnsavedComment();
    }

    private onSaveAsDraftSuccess() {
        this.saveUnsavedComment();
    }
}

export function create(targetElement: HTMLElement, contextResource: any) {
    return new DocumentManagerViewModel(targetElement, contextResource);
}
