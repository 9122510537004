/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/ImportManagement/infoPanel"/>
import * as React from "react";
import { MasterDataErrors } from "./masterDataErrors";
import {QueueItem} from "./interfaces";
import {translate} from "Core/Medius.Core.Web/Scripts/lib/globalization";

interface InfoPanelProps {
    selectedItem: QueueItem;
    reload: () => void;
    errorSearchString: string;
}

export function InfoPanel({selectedItem, reload, errorSearchString}: InfoPanelProps) {
    return (
    <div className="importManagement-infoPanel">
        <div>
            <span>{translate("#Core/processedTime")}:</span> <b>{selectedItem.processedTime}</b>
        </div>
        <div>
            <span>{translate("#Core/importTime")}:</span> <b>{selectedItem.importTime}</b>
        </div>
        <div>
            <span>{translate("#Core/details")}:</span> <b>{translate(selectedItem.details)}</b>
        </div>
        <MasterDataErrors 
            queueItemId={selectedItem.id} 
            reloadImportManagement={reload}
            errorSearchString={errorSearchString}
        ></MasterDataErrors>
    </div>);
}
