/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/administration/editor"/>
import { AdminApplication } from "./adminApp";
import {hasCustomRightSide, getCustomRightSide} from "Core/Medius.Core.Web/Scripts/AdminPages/extensionPointsRegistry";
import { IEntityWithContext } from "Core/Medius.Core.Web/Scripts/AdminPages/IEntityWithContext";
import EditorTabs from "Core/Medius.Core.Web/Scripts/components/administrationEditor/editorTabs";
import {without, keys, isEmpty, isArray, omit} from "underscore";
import {handleAnyError} from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import {Deferred, when} from "jquery";
import {translate} from "Core/Medius.Core.Web/Scripts/lib/globalization";
import {error} from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import * as server from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/server";
import { getBasePath } from "Core/Medius.Core.Web/Scripts/Medius/lib/path";
import type = require("Core/Medius.Core.Web/Scripts/Medius/core/type");
import contextFactory = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/context");
import * as ko from "knockout";

export class Editor {
    public EntityWithContext: any = null;
    public CanUpdate = ko.observable(false);
    public CanDelete = ko.observable(false);
    public customRightSide = ko.observable(null);
    public CompanyContextId: ko.Observable<number>;
    public EditorTabs: any = null;
    public context: any = null;
    constructor(public AdminApp: AdminApplication) {
        this.CompanyContextId = this.AdminApp.CompanyContextId;
    }

    public load(entityType: string, entityId: any, baseEntityId: number) {
        let isDeletePossible: boolean;

        if (hasCustomRightSide(entityType)) {
            const rightSide = getCustomRightSide(entityType);
            const id = parseInt(entityId, 10);
            return rightSide.load(id).then(() => {
                this.customRightSide(rightSide);
                this.CanDelete(rightSide.canDelete);
                this.CanUpdate(rightSide.canUpdate);
            });
        } else {
            return this.doLoadEntity(entityType, entityId, baseEntityId)
                .then((entityWithContext: IEntityWithContext) => {
                    isDeletePossible = entityWithContext.CanDelete && entityId !== 0;
                    this.EntityWithContext = this.createEntityWithContext(entityWithContext);
                    this.EntityWithContext.TabModels = {};
                    this.CanUpdate(entityWithContext.CanUpdate);
                    this.CanDelete(isDeletePossible);
                    this.EditorTabs = new EditorTabs(entityType, this.EntityWithContext, this.CompanyContextId);
                    return this.EditorTabs.load();
                })
                .fail((obj: any) => {
                    if (obj.status === 403) {
                        window.location.replace(getBasePath() + "Error/NotFound");
                    }
                });
        }
    }

    public createEntityWithContext(entityWithContext: IEntityWithContext) {
        const entityType = type.getTypeName(entityWithContext.Entity.$type);

        this.context = contextFactory();
        this.context.set("administration-context", {
            entityType: entityType,
            isNewEntity: entityWithContext.Entity.Id === 0
        });

        entityWithContext.Entity = this.context.create(entityWithContext.Entity);
        entityWithContext.Context = this.context;

        if (!isEmpty(entityWithContext.RelatedObjects)) {
            const types = without(keys(entityWithContext.RelatedObjects), "$type");

            types.forEach((oType) => {
                const obj = entityWithContext.RelatedObjects[oType];
                const properties = without(keys(obj), "$type");

                properties.forEach((property) => {
                    if (isArray(obj[property])) {
                        const items = obj[property].map((object: any) => this.context.create(object));
                        obj[property] = ko.observableArray(items);
                    } else {
                        obj[property] = this.context.create(obj[property]);
                    }
                });
            });
        }

        entityWithContext.reload = () => {
            this.AdminApp.reloadForm();
        };

        return entityWithContext;
    }

    public save() {
        if (this.customRightSide()) {
            return this.customRightSide().save()
            .fail((xhr: any) => {
                handleAnyError(xhr);
            });
        }

        const saveHandler = () => {
            if (!this.CanUpdate) {
                error(translate("#Core/DEFAULT_AUTHORIZATION_UPDATEPERMISSIONS"));
                return Deferred().reject();
            }

            return this.doSave()
                .fail((xhr: any) => {
                    handleAnyError(xhr);
                });
        };

        return this.context.enqueue({
            action: saveHandler,
            name: "save"
        }).getDeferred();
    }

    public remove() {
        // delete just added but not saved yet entity
        if (this.AdminApp.EntityId() === 0) {
            return Deferred().resolve();
        }

        // check user permission to delete given entity
        if (!this.CanDelete) {
            error(translate("#Core/DEFAULT_AUTHORIZATION_DELETEPERMISSIONS"));
            return $.Deferred().reject();
        }

        return when(this.doRemove())
            .fail((data) => {
                handleAnyError(data);
            });
    }

    public doLoadEntity(entityType: string, entityId: string | number, baseEntityId: string | number) {
        let url = [entityType, entityId].join("/");

        if (baseEntityId !== 0) {
            url += "?baseId=" + baseEntityId;
        }

        return server.get(url);
    }

    public doSave() {
        let url: string;
        let request: any;
        const entityData = this.context.toJS(omit(this.EntityWithContext, "Context", "TabModels"));

        if (this.AdminApp.EntityId() > 0) {
            url = [this.AdminApp.EntityName(), this.AdminApp.EntityId()].join("/");
            request = server.put(url, entityData);
        } else {
            url = this.AdminApp.EntityName();
            request = server.post(url, entityData);
        }

        return request;
    }

    public doRemove() {
        if (this.AdminApp.EntityId() < 1) {
            return Deferred().reject();
        }

        const url = [this.AdminApp.EntityName(), this.AdminApp.EntityId()].join("/");
        return server.del(url);
    }

    public provideValidationTextObservable(validationGroup: any) {
        const message = translate("#Core/errorsOnTabs");
        const erroneousTabs: any[] = [];
        validationGroup.validationGroups().forEach((childValidationGroup: any) => {
            if (!childValidationGroup.isValid()) {
                erroneousTabs.push(childValidationGroup.getName());
            }
        });
        return ko.observable(message + " " + erroneousTabs.join(", "));
    }

    public dispose() {

        if (this.EditorTabs) {
            this.EditorTabs.dispose();
        }
        this.EditorTabs = null;

        if (this.context) {
            this.context.dispose();
        }
        this.context = null;

        if (this.EntityWithContext) {
            this.EntityWithContext.reload = null;
        }

        this.EntityWithContext = null;
        this.CanUpdate = null;
        this.CanDelete = null;
        this.CompanyContextId = null;
        this.AdminApp = null;
    }
}

export function create(adminApp: AdminApplication) {
    return new Editor(adminApp);
}
