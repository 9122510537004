/// <amd-module name="Core/Medius.Core.Web/Scripts/loginPage/LoginPage"/>
import * as React from "react";
import { useState } from "react";
import {post} from "Core/Medius.Core.Web/Scripts/Medius/core/fetch/rest";
import Card from "Core/Medius.Core.Web/Scripts/components/card/Card";

export default function LoginPage(props: ILoginPageProps) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);

    function handleUserNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        setUsername(event.target.value);
    }

    function handlePasswordChange(event: React.ChangeEvent<HTMLInputElement>) {
        setPassword(event.target.value);
    }

    function submit() {
        const loginStartTime = Date.now();
        const startKey = 'login_start_time';
        localStorage.setItem(startKey, '' + loginStartTime);

        setLoading(true);
        post("/LocalAccount/LogOn", {
            username: username,
            password: password
        }).then((e: {Error: string; Redirect: string}) => {            
            if(e.Error) {
                setLoading(false);
                setError(e.Error);
            }
            else if(e.Redirect){                
                window.location.href = e.Redirect;  
            } else {
                setLoading(false);
            }  
        });
    }

    function onKeyDown (event: React.KeyboardEvent<HTMLDivElement>) {
        if (event.key === 'Enter' || event.key === "NumpadEnter") {
          submit();
        }
    }

    const togglePasswordVisiblity = () => {
        setPasswordShown(currentValue => !currentValue);
    };

    return (
        <Card loading={loading} loaderMessage={"Verifying your credentials..."}>
            <form className="login-page__form" id="login-form" method="post">
                <label className="card-label" htmlFor="username">Username</label>
                <input onKeyDown={onKeyDown} className="card-input" type="text" name="username" id="username" value={username} onChange={handleUserNameChange} autoFocus/>
                
                <div className="login-page__password-label-container">
                    <label className="card-label" htmlFor="password">
                        <span>Password</span>
                    </label>
                    <a className="card-text login-page__forgot-password-text" onClick={props.goToForgotPasswordPage} data-testid="forgot-password-link">Forgot your password?</a>
                </div>
                <div className="login-page__input-wrapper">
                    <input onKeyDown={onKeyDown} className="card-input"  type={passwordShown ? "text" : "password"} name="password" id="password" value={password} onChange={handlePasswordChange} />
                        {passwordShown ? <i className="icon-backward glyphicons glyphicons-eye-close" onClick={togglePasswordVisiblity}></i>
                            : <i className="icon-backward glyphicons glyphicons-eye-open" onClick={togglePasswordVisiblity}></i>}
                </div>
                <button type="button" className="card-button" id="login" onClick={submit}>Log in</button>
                
                { error ? (<div className="login-page__errors" data-testid="login-error">{error}</div>) : null }
                
            </form>
        </Card>
    );
}

interface ILoginPageProps {
    goToForgotPasswordPage: () => void;
}
