///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/accounting/autocompleter/dataProvider/taxGroupForAutocoding"/>
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as base from "Core/Medius.Core.Web/Scripts/Medius/apps/accounting/autocompleter/dataProvider/taxGroup";
import { DimensionOverridenValue } from "Core/Medius.Core.Web/Scripts/AdminPages/AutoCoding/dimensionOverridenValue";

const dummyTaxSaC = {
    tag: "#Enterprise/sameAsCostDimensionTag",
    description: "#Enterprise/sameAsCostDimensionTagDescription",
    overridenValue: DimensionOverridenValue.SaC
};


class Provider extends base.Provider {
    getItems: any;
    createViewModelIfNeeded: any;
    constructor(context: any, companyId: any, indicatorNumber: any) {
        super(context, companyId, indicatorNumber);
    }

    search(phrase: any, limit: any, extras: any) {
        return this.getItems(phrase, limit, extras).pipe((data:any) => {

            if (data.IsMissing) {
                const dummy = this.createDummy(phrase,);
                data.missing = this.createViewModelIfNeeded(dummy);
            }

            data.Results.push(this.createDummy(dummyTaxSaC.tag, dummyTaxSaC.description, dummyTaxSaC.overridenValue));

            return data;
        });
    }

    createDummy(value: string | number, description?: string | number, overridenValue?: any) {
        return {
            $type: "Medius.Enterprise.Entities.Accounting.Tax.TaxGroup, Medius.Enterprise.Common",
            Id: 0,
            Code: globalization.getLabelTranslation(value),
            Description: description !== undefined ? globalization.getLabelTranslation(description) : null,
            OverridenValue: overridenValue
        };
    }
}

export function create(context:any, companyId:any, indicatorNumber:any) {
    return new Provider(context, companyId, indicatorNumber);
}