/// <amd-module name="Core/Medius.Core.Web/Scripts/components/topBar/drawer/appState"/>
import { RootState } from "Core/Medius.Core.Web/Scripts/shared/store/reduxStore";

interface App {
    name: string;
    path: string;
    logoFileName: string;
    type: number;
}

export const AppsLoadedAction = "APPS_LOADED";

export function appsReducer(state: App[] = null, action: { type: string; payload: any }): App[] {
    switch (action.type) {
        case AppsLoadedAction:
            return [...action.payload];
        default:
            return state;
    }
}

export function setApps(apps: App[]) {
    return {
        type: AppsLoadedAction,
        payload: apps
    };
}

export const selectApps = (state: RootState) => state.apps;