///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/transformers/update"/>
import * as ko from "knockout";
import * as helpers from "Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/helpers";
import objectUpdater = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/transformers/update/object");
import arrayUpdater = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/transformers/update/array");

export = function updateTransformerFactory() {

    interface Transformer {
        topDown?: (args: any) => void;
        bottomUp?: (args: any) => void;
        beforeDescend?: (args: any) => void;
        afterDescend?: (args: any) => void;
    }

    let currentUpdater:Transformer = {};

    function updateProperty(newValue:any, accessor:any) {
        if (ko.isWriteableObservable(accessor)) {
            const currentValue = ko.utils.unwrapObservable(accessor);
            if (currentValue !== newValue) {
                accessor(newValue);
            }
        }
    }

    return {
        topDown: function(args:any) {
            const sourceData = args.getSourceData();
            const targetData = args.getTargetData();

            if (!sourceData) {
                // no source data, just write empty value to target accessor
                updateProperty(sourceData, targetData);
                return;
            }

            if (helpers.isArray(sourceData)) {
                currentUpdater = arrayUpdater(targetData);
            } else if (typeof sourceData === "object" && !helpers.isDate(sourceData)) {
                currentUpdater = objectUpdater(sourceData, targetData, args.context);
            }

            if (currentUpdater.topDown) {
                currentUpdater.topDown(args);
            }
        },
        beforeDescend: function(args:any) {
            if (currentUpdater.beforeDescend) {
                currentUpdater.beforeDescend(args);
                return;
            }

            if (args.propertyName === "__metadata__") {
                args.stopPropertyDescend();
            }
        },
        afterDescend: function(args:any) {
            if (currentUpdater.afterDescend) {
                currentUpdater.afterDescend(args);
                return;
            }

            const sourceData = args.getSourceProperty();
            const targetData = args.getTargetProperty();

            switch(typeof sourceData) {
                case "string":
                case "number":
                case "boolean":
                    updateProperty(sourceData, targetData);
                    return;

                case "object":
                    if (helpers.isDate(sourceData)) {
                        updateProperty(sourceData, targetData);
                        return;
                    }
            }
        },
        bottomUp: function(args:any) {
            if (currentUpdater.bottomUp) {
                currentUpdater.bottomUp(args);
            }
        }
    };
};