///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/document/models/HashFiles/withHashFilesModule"/>
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as ko from "knockout";
import * as _ from "underscore";
import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import module = require("Core/Medius.Core.Web/Scripts/Medius/apps/document/models/HashFiles/hashFiles");

function loadModule(key: string | number, context: { modules: { [x: string]: any; }; $data: any; }, element: any, entityModelKey: string | number) {
    let moduleVm, defaultViewModel, containerElement, entityViewModel;

    if (!context.modules) {
        context.modules = {};
    }

    // load from cache by key
    if (context.modules[key]) {
        moduleVm = context.modules[key];
    }
        // create a new instance and cache it
    else {
        defaultViewModel = context.$data;
        containerElement = isNullOrUndefined(defaultViewModel.targetElement) ? element : defaultViewModel.targetElement;

        entityViewModel = !isNullOrUndefined(entityModelKey) ?
            koUtils.unpack(defaultViewModel[entityModelKey]) || koUtils.unpack(defaultViewModel)
            : koUtils.unpack(defaultViewModel);

        moduleVm = module(entityViewModel, containerElement);

        context.modules[key] = moduleVm;
    }

    return moduleVm;
}

const withHashFilesModule = {
    init: function (element: Node, bindingParams: () => { (): any; new(): any; module: any; key: string; entityViewModel: any; }, allBindingsAccessor: any, viewModel: any, context: any) {
        const key = bindingParams().key || _.uniqueId('module_');
        const documentModelKey = bindingParams().entityViewModel;

        // load module from cache by key or initialize a new instance
        const moduleVm = loadModule(key, context, element, documentModelKey);

        if (moduleVm && moduleVm.dispose) {
            koUtils.addDisposeCallback(element, function () {
                if (moduleVm) {
                    moduleVm.dispose();
                }
                context.modules[key] = null;
            });
        }

        const childBindingContext = context.createChildContext(moduleVm);
        ko.applyBindingsToDescendants(childBindingContext, element);

        return { controlsDescendantBindings: true };
    }
};

export function register() {
    koUtils.registerBinding("withHashFilesModule", withHashFilesModule);
}