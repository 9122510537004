/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/session/information"/>
import {isNullOrUndefined} from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import * as cookies from "Core/Medius.Core.Web/Scripts/lib/cookie";

let sessionRemainingTimeCookieInformation : number;
let lastSessionRemainingTimeUpdate : Date;
let sessionCookieLifetime:number;

function getThrowawaySessionRemainingTimeInformation() : number {

    const cookieInformaiton = cookies.getCookie('remainingSessionMilliseconds');

    if (isNullOrUndefined(cookieInformaiton)) {
        return null;
    }

    const remainingSessionMilliseconds = parseInt(cookieInformaiton as string, 10);
    return remainingSessionMilliseconds;
};

export function updateSessionInformation() {
    const newSessionRemainingTime = getThrowawaySessionRemainingTimeInformation();

    sessionRemainingTimeCookieInformation = newSessionRemainingTime;
    lastSessionRemainingTimeUpdate = new Date();
};

export function getSessionRemainingTime() {  
    if (isNullOrUndefined(sessionRemainingTimeCookieInformation)) {
        return undefined;
    }

    const cookieUnawareElapsedTime =
        (new Date() as any) - (lastSessionRemainingTimeUpdate as any);
    const sessionRemainingTime =
        sessionRemainingTimeCookieInformation -
        cookieUnawareElapsedTime;
    return sessionRemainingTime;
};

export function getSessionElapsedTime() {
    const sessionRemainingTime = getSessionRemainingTime();
    const sessionLifetime = getSessionCookieLifetime();

    if (
        isNullOrUndefined(sessionRemainingTime) ||
        isNullOrUndefined(sessionLifetime)
    ) {
        return undefined;
    }

    const sessionElapsedTime = sessionLifetime - sessionRemainingTime;
    return sessionElapsedTime;
};

export function getSessionCookieLifetime() {
    if (!sessionCookieLifetime) {
        throw (
            'Please provide session cookie lifetime information first. ' +
            'Do this in Application.cshtml file in Medius.Core.Web project.'
        );
    }

    return sessionCookieLifetime;
};

export function setSessionCookieLifetime(maximalTime:number) {
    if (sessionCookieLifetime) {
        throw 'function setSessionCookieLifetime can be invoked only once on system startup';
    }

    sessionCookieLifetime = maximalTime;
};
