///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/context/oldData"/>
export = function oldContextDataDecorator(context:any) {
    // TOOD: Remove when dropping backward compatibility
    let oldData = {};
    context.getData = function() {
        return oldData;
    };

    context.disposal.register(function() {
        oldData = {};
    });
};