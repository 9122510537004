/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/document/documentTranslator"/>
import {translate, getFormattedLabelTranslation} from "Core/Medius.Core.Web/Scripts/lib/globalization";

export function translateDocumentSource(enumValue: any) {
    const sourceTranslationKey = "Core/documentSource_enum_";
    return translate(sourceTranslationKey + enumValue);
}

export function translateDocumentFormat(documentFormat: string) {
  if(documentFormat == "Unknown")
    return translate("#Core/unknown");

  if(documentFormat == "Manual")
    return translate("#Core/manual");

  return documentFormat;
}

export function translateDocumentProvider(documentProvider: string) {
  if(documentProvider == "Unknown")
    return translate("#Core/unknown");
   
  return documentProvider;
}

export function translateInvalidatedAndEditedIconTooltipWithId(id: any) {
  // Document in EDI Import step
  if (id == null || id === undefined) {
        return null;
   }

  return getFormattedLabelTranslation("#Core/InvalidatedAndEditedIconTooltip_id", [id]);
}

export function translateInvalidatedAndEditedIconTooltipFromImport() {
    return translate("#Core/InvalidatedAndEditedIconTooltipFromImport");
}
