///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/grid/entity/grid"/>
import * as _ from "underscore";
import * as ko from "knockout";
import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import * as dataGrid from "Core/Medius.Core.Web/Scripts/Medius/components/grid/default/grid";
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as backendErrorHandler from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import * as defaults from "Core/Medius.Core.Web/Scripts/Medius/components/grid/entity/defaults";
import { DataSearchSource as DataSearchDataSource } from "Core/Medius.Core.Web/Scripts/Medius/components/grid/dataSource/dataSearch";
import * as typeResolver from "Core/Medius.Core.Web/Scripts/Medius/components/resolver/type";
import  { EntityDataQuery } from "Core/Medius.Core.Web/Scripts/Medius/components/grid/dataQuery/entity";
import * as helpers from "Core/Medius.Core.Web/Scripts/Medius/components/editors/helpers";

const companyRelatedTypes = [
    "Medius.Core.Entities.User"
];

function isAjaxResult(result:any) {
    return result[2] && result[2].status === 200;
}

class EntityGrid{
    options:any = defaults.get();
    EntityType: any;
    CompanyContextId: any;
    CreatedDateFilter: any;
    SelectedRowId = ko.observable();
    CompanyContextIdSubscription: any;
    CreatedDateFilterSubscription: any;
    ChangedDateFilter: any;
    ChangedDateFilterSubscription: any;
    IsLoading: any;
    DataSource: any;
    DataQuery: any;
    Rows: any;
    CurrentPage: any;
    SelectedRow: any;
    Columns: any;
    MultiselectEnabled: any;
    multiselection: any;
    TotalRows: any;
    NonfilteredTotalRows: any;
    Parser: any;
    selectedRowIdSub: ko.Computed<void>;
    destroyRows: any;
    generatePages: any;
    updateVisibleColumns: any;
    
    constructor(entityType: any, options: any) {
        const Grid = dataGrid.Grid;

        if (isNullOrUndefined(entityType)) {
            throw new Error("EntityGrid: Cannot initialize without provided entityType");
        }

        helpers.mergeDefaults(options, this.options);

        this.options.dataQuery = this.options.dataQuery ||
            new EntityDataQuery(entityType, this.options.pageSize, this.options.initialKeywords, this.options.initialSorting,
                                this.options.mapToDto);
        const dataSource = this.options.dataSource || new DataSearchDataSource();

        Grid.call(this, dataSource, this.options);

        this.EntityType = entityType;
        this.CompanyContextId =
            (this.options.companyContextId) ?
                ((ko.isObservable(this.options.companyContextId)) ?
                    this.options.companyContextId :
                    ko.observable(this.options.companyContextId)) :
                null;

        this.CreatedDateFilter = this.options.createdDateFilter;
        this.CreatedDateFilter = this.options.changedDateFilter;

        this.selectedRowIdSub = ko.computed(() => {
            const id = parseInt(this.SelectedRowId(), 10);
    
            if (id < 0) {
                return;
            }
    
            const row = _(this.Rows()).find((r) => {
                return koUtils.unpack(r.Id) === id;
            });
    
            if (row) {
                this.SelectedRow(row);
            }
        });

        const onFilterChanged = () => {
            this.openPage(1);
        };

        if (this.CompanyContextId) {
            this.CompanyContextIdSubscription = this.CompanyContextId.subscribe(onFilterChanged);
        }

        if (this.CreatedDateFilter) {
            this.CreatedDateFilterSubscription = this.CreatedDateFilter.subscribe(onFilterChanged);
        }

        if (this.ChangedDateFilter) {
            this.ChangedDateFilterSubscription = this.ChangedDateFilter.subscribe(onFilterChanged);
        }
    }

    preload() {
        const interfaces = typeResolver.getInterfaces(this.EntityType);
        
        this.resolveCompanyRelation(interfaces);

        this.IsLoading(true);

        return $.when(this.DataSource.loadColumns(this.DataQuery))
            .done((columns) => {
                this.Columns(this.Parser.parseColumns(columns, this.options.defaultColumnWidth, this.options.tplCell));
                this.updateVisibleColumns();
                this.DataQuery.columns = _(this.Columns()).map((c) => { return c.ValueSource; });
                this.IsLoading(false);
                this.openPage(1);
            })
            .fail((jqXhr) => {
                backendErrorHandler.handleAnyError(jqXhr);
                this.IsLoading(false);
            });
    }

    removeRowById(id:any) {
        if (isNullOrUndefined(id)) {
            return false;
        }

        this.Rows.remove((item:any) => {
            return item.Id === id;
        });

        return true;
    }

    resolveCompanyRelation(entityInterfaces:any) {
        if (_.contains(companyRelatedTypes, this.EntityType) ||
            _.contains(entityInterfaces, "Medius.Core.Entities.ICompanyRelated")) {
            this.DataQuery.isCompanyRelated = true;
        }
    }

    openPage(page:any) {
        this.DataQuery.companyContextId = koUtils.unpack(this.CompanyContextId);
        this.DataQuery.createdDateFilter = koUtils.unpack(this.CreatedDateFilter);
        this.DataQuery.changedDateFilter = koUtils.unpack(this.ChangedDateFilter);

        this.IsLoading(true);
        this.CurrentPage(page);
        this.DataQuery.page = page;

        return $.when(
            this.DataSource.load(this.DataQuery),
            this.DataSource.getTotalRows(this.DataQuery)
        )
        .done((dataResult, totalRowsResult) => {
            let rows, totalRows;

            this.destroyRows();

            if (isAjaxResult(dataResult)) {
                rows = dataResult[0].Entities || dataResult[0];
                totalRows = dataResult[0].TotalEntities;
            } else {
                rows = dataResult;
            }
            if (totalRows === undefined) {
                totalRows = isAjaxResult(totalRowsResult) ? totalRowsResult[0] : totalRowsResult;
            }

            const parsedRows = this.Parser.parseRows(this.Columns(), rows);

            if (this.MultiselectEnabled) {
                this.multiselection.addCheckboxes(parsedRows);
            }
            this.Rows(parsedRows);

            this.TotalRows(totalRows);
            if (isNullOrUndefined(this.NonfilteredTotalRows())) {
                this.NonfilteredTotalRows(this.TotalRows());
            }
            this.generatePages();
            this.IsLoading(false);
        })
        .fail((jqXhr) => {
            backendErrorHandler.handleAnyError(jqXhr);
            this.IsLoading(false);
        });
    }
    
    destroy() {
        if (this.CompanyContextIdSubscription) {
            this.CompanyContextIdSubscription.dispose();
        }
        if (this.CreatedDateFilterSubscription) {
            this.CreatedDateFilterSubscription.dispose();
        }
        if (this.ChangedDateFilterSubscription) {
            this.ChangedDateFilterSubscription.dispose();
        }
        this.selectedRowIdSub.dispose();
        this.CompanyContextId = null;
        dataGrid.Grid.prototype.destroy.call(this);
    }
}

_.defaults(EntityGrid.prototype, dataGrid.Grid.prototype);

export function create(entityType: any, options: any) {
    return new EntityGrid(entityType, options);
}

export const derive = EntityGrid;