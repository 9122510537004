///<amd-module name = "Core/Medius.Core.Web/Scripts/components/taskHandling/fraudPopup/DeletePopupInconclusiveTemplate"/>
import * as React from "react";
import { ConfirmDialog, Text } from "@medius/ui-controls";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";

interface DeletePopupInconclusiveTemplateInterface {
    showPopup: boolean,
    onConfirm: () => void,
    onCancel: () => void,
}


export const DeletePopupInconclusiveTemplate = ({showPopup, onConfirm, onCancel}:DeletePopupInconclusiveTemplateInterface) => {
    return (<>
        <ConfirmDialog 
            isOpen={showPopup}
            cancelLabel={getLabelTranslation("#Core/cancel")}
            confirmLabel={getLabelTranslation("#Core/deleteConfirmation")}
            data-testid="deletePopupInconclusive"
            onCancel={onCancel}
            onConfirm={onConfirm}>
            <Text data-testid="deletePopupInconclusive-title" variant="heading2">{getLabelTranslation("#Core/deletePopupInconclusiveTitle")}</Text>
            <Text data-testid="deletePopupInconclusive-text" variant="body">{getLabelTranslation("#Core/documentMayHaveUncalculatedRisks")}</Text>  
        </ConfirmDialog>
    </>);
};