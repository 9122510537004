/* eslint-disable react/no-unknown-property */
///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/Components/LegalQuestionsContextMenu" />
import { 
    Callout, 
    ContentDivider, 
    IconCommunicationMegaphoneRegular, 
    IconStatusQuestionRegular, 
} from "@medius/ui-controls";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as React from "react";

interface IProps{
    buttonClickCb: (predefinedQuestion: string) => void;
    onOpenFeedbackModal: () => void;
    isFeedbackModalOpen: boolean
}

export const LegalQuestionsContextMenu = ({ buttonClickCb, onOpenFeedbackModal, isFeedbackModalOpen }: IProps) => {
    const [isLegalQuestionsContextMenuOpen, setIsLegalQuestionsContextMenuOpen] = React.useState(false);

    const feedbackContextMenuRef = React.useRef(null);
    const calloutContainerRef = React.useRef(null);

    const handleQuestionClick = (predefinedQuestion: string) => {
        buttonClickCb(predefinedQuestion);
        setIsLegalQuestionsContextMenuOpen(false);
    };

    const closeCallout = () => {
        if(isFeedbackModalOpen)
            return;

        setIsLegalQuestionsContextMenuOpen(false);
    };

    return <>
        <div
            className="mediusAgent__promptInput__legalQuestionsIcon"
            data-testid="agent-legalQuestionsIcon"
            onClick={() => setIsLegalQuestionsContextMenuOpen(true)}
            ref={feedbackContextMenuRef}
        >
            <IconStatusQuestionRegular size="medium" color="brand" />
        </div>
        <Callout 
            displayTargetRef={feedbackContextMenuRef}
            isOpen={isLegalQuestionsContextMenuOpen}
            position="top-start"
            onCloseRequested={closeCallout}
        >
            <div className="mediusAgent__promptInput__calloutInnerContainer" data-testid="agent-legalQuestions-container" ref={calloutContainerRef} close-ai-agent-modal="false" >
                <p onClick={() => handleQuestionClick("#Core/isMyDataPrivate")} close-ai-agent-modal="false">{getLabelTranslation("#Core/isMyDataPrivate")}</p>
                <p onClick={() => handleQuestionClick("#Core/howDoesMediusUseAI")} close-ai-agent-modal="false">{getLabelTranslation("#Core/howDoesMediusUseAI")}</p>
                <p onClick={() => handleQuestionClick("#Core/whyWontMediusAgentRespondToSomeTopics")} close-ai-agent-modal="false">{getLabelTranslation("#Core/whyWontMediusAgentRespondToSomeTopics")}</p>
                <ContentDivider />
                <div 
                    className="mediusAgent__promptInput__mediusAgentFeedbackIconContainer" 
                    close-ai-agent-modal="false"
                    onClick={() => onOpenFeedbackModal()}
                >
                    <IconCommunicationMegaphoneRegular />
                    <p>{getLabelTranslation("#Core/sendMediusAgentFeedback")}</p>
                </div>
            </div>
        </Callout>
    </>;
};