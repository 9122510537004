/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/AuthorizationGroups/gridState"/>
import { translate } from "../../lib/globalization";
import { GridProps } from "@progress/kendo-react-grid";
import { AuthorizationGroupHeader, AuthorizationGroupsHeaders } from "./authorizationGroup";
export interface GridState {
    AuthorizationGroupsHeaders: AuthorizationGroupsHeaders;
    DataState: GridProps;
}

export function GetNewGridRowData() {
    return {
        Id: 0,
        Name: translate('#Enterprise/AuthorizationGroup_newAuthorizationGroup'),
        CompanyName: "",
        CurrencyCode: ""
    } as AuthorizationGroupHeader;
}

export function GetClonedGridRowData() {
    return {
        Id: 0,
        Name: "",
        CompanyName: "",
        CurrencyCode: ""
    } as AuthorizationGroupHeader;
}

export function IsNewRow(g: AuthorizationGroupHeader) {
    return g.Id == 0;
}

export function GetInitialGridState(): GridProps {
    return {
            skip: 0,
            take: 20,
            selectedField: "selected",
            pageable: {
                buttonCount: 5,
                pageSizes: true,
                info: true
            },
            filterable: true,
            filterOperators: {
                "text": [{ text: 'grid.filterContainsOperator', operator: 'contains' }]
            },
            filter: {
                logic: 'and',
                filters: []
            },
            sortable: true,
            sort: []
    };
}
