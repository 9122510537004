/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/lib/performance/output/statsd"/>
import * as md5 from "md5";
import * as browserDetector from "Core/Medius.Core.Web/Scripts/Medius/lib/detector";

class StatsdConfg {
    private static instanceValue: StatsdConfg;
    private sekretKey: string;
    private baseUrl: string;
    private tenantName: string;
    private hostName: string;
    private enabled = false;
    private clientBrowser: any;

    public output(category: any, scenario: any, time: any, data: any) {
        const value = Math.round(time);
        const node = this.createNode(category, scenario, this.clientBrowser, data.scenarioDetails, data.label);
        const token = this.createToken(node, value);
        const url = this.createTimerUrl(node, value, token);

        this.ping(url);
    }

    public event(category: any, scenario: any, scenarioDetails?: any, value?: any) {
        const node = this.createNode(category, scenario, this.clientBrowser, scenarioDetails, null);
        const token = this.createToken(node, typeof value !== "undefined" ? value : 1);
        const url = typeof value !== "undefined"
            ? this.createCounterUrl(node, token, value)
            : this.createEventUrl(node, token);

        this.ping(url);
    }

    public setPingMethod(func: any) {
        this.ping = func;
    }

    public setCreateTokenMethod(func: any) {
        this.createToken = func;
    }

    public initialize(url: any, secret: any, host: any, tenant: any) {
        this.baseUrl = url.replace(/\/$/g, "");
        this.sekretKey = secret;
        this.hostName = host;
        this.tenantName = tenant;
        this.enabled = !!url;
        this.clientBrowser = browserDetector.getBrowserName();
    }

    public createNode(category: any, scenario: any, browser: any, scenarioDetails: any, label: any) {
        let nodeName = `${this.hostName}.${this.tenantName}.${category}.${scenario}`;

        if (scenarioDetails) {
            nodeName = `${nodeName}.${scenarioDetails}`;
        }

        if (label) {
            nodeName = `${nodeName}.${label}`;
        }

        nodeName = `${nodeName}.${browser}`;

        return nodeName.replace(/ /g, "_").toLowerCase();
    }

    public createTimerUrl(node: any, value: any, token: any) {
        return `${this.baseUrl}/statsd/${node}/timer/${value}?token=${token}`;
    }

    public createEventUrl(node: any, token: any) {
        return `${this.baseUrl}/statsd/${node}/increment?token=${token}`;
    }

    public createCounterUrl(node: any, token: any, value: any) {
        return `${this.baseUrl}/statsd/${node}/increment/${value}?token=${token}`;
    }

    private createToken = (node: string, value: any) => md5(node + value + this.sekretKey);

    private ping = (url: string) => {
        if (this.enabled) {
            $.ajax(
                url, {
                    dataType: "jsonp"
                });
        }
    };

    public static get instance() {
        return this.instanceValue || (this.instanceValue = new this());
    }

}

export default function() {
    const instance = StatsdConfg.instance;
    return instance;
}
