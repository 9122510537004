/// <amd-module name="Core/Medius.Core.Web/Scripts/lib/underscoreHelpers"/>
import {isNull, isUndefined, isEmpty, isString} from "underscore";

export function isNullOrUndefined(value: any) {
    return isNull(value) || isUndefined(value);
}

export function isEmptyString(value: any) {
    if (isNullOrUndefined(value)) {
        return true;
    }

    if (typeof value === "string") {
        return value.trim().length === 0;
    }

    return false;
}

export function isNotNullOrUndefined(elem: any) {
    return !isNullOrUndefined(elem);
}

export function isNotAStringOrEmpty(value: any) {
    return  isNullOrUndefined(value) || isEmpty(value) || !isString(value) || value === "";
}
