/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/validation/group/model"/>

import * as ko from 'knockout';
import * as _ from 'underscore';
import * as $ from 'jquery';

class ValidationGroup {
    parent: any;
    attachToParent: any;
    editors: ko.ObservableArray<any> = ko.observableArray([]);
    validationGroups: ko.ObservableArray<any> = ko.observableArray([]);;
    name: any;
    isValid: ko.Computed<boolean>;

    constructor(attachToParent: boolean, parent: any, name: any){
        this.parent = parent || null;
        this.attachToParent = attachToParent || false;
        this.name = name;

        this.isValid = ko.computed(() => {
            const t = _.map(this.editors(), (editor) => {
                if (editor.IsValid) {
                    return editor.IsValid();
                } else if (editor.isValid) {
                    return editor.isValid();
                }
            });

            const s = _.map(this.validationGroups(), (group) => {
                return group.isValid();
            });

            return !_.contains(t.concat(s), false);
        }).extend({ throttle: 100 });
        
        if (this.attachToParent === true && this.parent) {
            this.parent.registerChildValidationGroup(this);
        }
    }

    validate() {
        const validations: any[] = [];

        _(this.editors()).each(editor => {
            validations.push(editor.validateInput());
        });

        _(this.validationGroups()).each(group => {
            validations.push(group.validate());
        });

        return $.when.apply($, validations).pipe( function () {
            const results = _(arguments).chain().values().uniq().value();
            return !_(results).contains(false);
        });
    }

    getName(){
        return this.name;
    }

    dispose() {        
        if (this.attachToParent === true && this.parent) {
            this.parent.unregisterChildValidationGroup(this);
            this.parent = null;
        }

        this.isValid.dispose();
    }

    registerEditorComponent (editor: any) {
        this.editors.push(editor);
    }

    unregisterEditorComponent (editor: any) {
        this.editors.remove(editor);
    }

    registerChildValidationGroup (validationGroup: any) {
        this.validationGroups.push(validationGroup);
    }

    unregisterChildValidationGroup (validationGroup: any) {
        this.validationGroups.remove(validationGroup);
    }
}

export const create = (attachToParent: boolean, parent: any, name: any) => {
    return new ValidationGroup(attachToParent, parent, name);
};
