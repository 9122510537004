/// <amd-module name="Core/Medius.Core.Web/Scripts/lib/validation/validatedValue"/>
import * as ko from "knockout";

export interface Validator {
    validationMessage: string;
    isValid(value: any): boolean;
}

export class ValidatedValue<T> {
    private validators: Validator[];

    // Internal value should be used in validators and in autocomplete control. 
    // It can contain "dummy values" that represent not existing values 
    public internalValue: ko.Observable<T & { isValueMissing?: boolean }> = ko.observable<T>();

    constructor(initialValue?: T & { isValueMissing?: boolean }, ...validators: Validator[]) {
        this.validators = validators;
        this.internalValue(initialValue);   
    }

    public isValid = ko.pureComputed(() => this.validators.every(validator => validator.isValid(this.internalValue())));

    // Value should be used by other view models. It does not contain "dummy values" (they are seen as null values)
    public value: ko.Computed<T> = ko.pureComputed(() => this.hasValue() ? this.internalValue() : null);

    public hasValue = ko.pureComputed(() => this.internalValue() && !this.internalValue().isValueMissing);

    public clearValue = () => this.internalValue(null);

    public errors = ko.pureComputed(() => {
        const validationMessages = this.validators
            .filter(validator => !validator.isValid(this.internalValue()))
            .map(validator => validator.validationMessage);
        return validationMessages;
    });
}
