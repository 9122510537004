/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/knockout/utils"/>

import { isObservable, observableArray, observable, bindingHandlers, utils, computed } from "knockout";
import * as _ from "underscore";

export function unpack<T>(value: ko.Observable<T> | T): T {
    return isObservable(value) ? value() : value;
}

export function unpackAll(value: any) {
    if (typeof value !== "object") {
        return unpack(value);
    }

    if (!value) {
        return value;
    }

    const result: any = {};
    _(value).each((member, key) => {
        result[key] = unpackAll(member);
    });
    return result;
}

export function unpackPath(obj: any, path: string) {
    let result: any = unpack(obj);
    const visited = [];
    const parts = path && path.split(".") || [];

    try {
        for (let i = 0; i < parts.length; i++) {
            const part = parts[i];
            visited.push(part);
            result = unpack(result[part]);
        }
    } catch (e) {
        const visitedPath = visited.join(".");
        const error: any = new Error("Error when accessing path: " + visitedPath);
        error.inner = e;
        error.data = obj;
        error.path = path;
        error.visitedPath = visitedPath;
        throw error;
    }

    return result;
}

export function pack(value: any) {
    if (isObservable(value)) {
        return value;
    }

    return value instanceof Array ?
        observableArray(value) :
        observable(value);
}

export function registerBinding(name: string, binding: ko.BindingHandler) {
    if (bindingHandlers[name]) {
        throw new Error(`Binding ${name} is already registeread`);
    }
    bindingHandlers[name] = binding;
}

export function resolveBinding(name: string) {
    const binding = bindingHandlers[name];
    if (!binding) {
        throw new Error(`Binding '${name}' is not registered`);
    }
    return binding;
}

export const registerEventHandler = utils.registerEventHandler;

export const addDisposeCallback = utils.domNodeDisposal.addDisposeCallback;

export const domData = {
    set(element: Element, key: string, value: any) {
        return utils.domData.set(element, key, value);
    },
    get(element: Element, key: string) {
        return utils.domData.get(element, key);
    }
};

export function disposeModel(viewModel: any) {
    if (!viewModel) {
        return;
    }

    _(viewModel).each((value, key) => {
        if (viewModel.hasOwnProperty(key)) {

            if (value && typeof value.dispose === "function") {
                value.dispose();
                delete viewModel[key];
            } else if (isObservable(value)) {
                delete viewModel[key];
            }
        }
    });
}

export function knockoutNullable<T>(viewModel: T, accessor: (arg0: T) => any) {
    const result = computed(function () {
        const vm = utils.unwrapObservable(viewModel);
        if (vm) {
            return accessor(vm);
        }
        return null;
    });
    return result;
}

export function knockoutValidationResult(errorList: any) {
    const result: any = computed(function () {
        return utils.unwrapObservable(errorList);
    });
    result.hasError = computed(function () {
        return result().length > 0;
    });
    return result;
}    