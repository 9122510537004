///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Entities.MessageTemplate/Editor"/>
import * as ko from "knockout";
import * as remindersRepository from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Reminders/repository";

class MessageTemplateEditorModel {
    Entity: any;
    reminderTypes: ko.ObservableArray<any>;
    constructor(entityWithContext:any) {
        this.Entity = entityWithContext.Entity;
        this.reminderTypes = ko.observableArray([]);

        remindersRepository.loadAvailableReminderTypes().done((reminders) => {
            const remindersArray = reminders.getAvailableRemindersNamesArray();
            this.reminderTypes(remindersArray);
        });
    }
}

export = function(entityWithContext:any) {
    return new MessageTemplateEditorModel(entityWithContext);
};