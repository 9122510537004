/*
* Binding that allows to wrap given collection of elements (list/tabs) into dropdown item "Others"
if the list is longer than given width. Binding accepts the following parameters.
Binding need to be set on container selector <ul>. Parameters:
rightLimit <number> - if the new menu should use not all space for given element (parent.width),
it can be limitted from right side with padding
maxWidth <number> - the menu will be adjust to given width
*/
///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/wrapTaskTabs"/>
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as html from "Core/Medius.Core.Web/Scripts/Medius/core/html";
import * as ko from "knockout";
import * as _ from "underscore";

const wrapKey = "medius-wrapTaskTabs";
const emptyTabWidth = 40;
let rightLimit:number;
let tabsCollection:any;

function registerResizeHandler(element:any, handler:any) {
    const $window = $(window);
    $window.on('resize', handler);
    ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
        $window.off('resize', handler);
    });
}

//it is used to check the length of "Others" tab, as it can be translated and then have different size
const getTextWidth = _.memoize(function(text:any, fontSize:any) {
    const encodedText = html.sanitize(text);

    const font = fontSize || '13px';

    const textObject = $('<div>' + encodedText + '</div>')
        .css({ 'position': 'absolute', 'float': 'left', 'white-space': 'nowrap', 'visibility': 'hidden', 'font-size': font })
        .appendTo($('body'));

    const width = textObject.width();
    textObject.remove();

    //tab view
    return width + (2 * 12) + (2 * 1) + 2; /* padding + border + margin-right*/
});

function fitTaskMenuHandler(element:any) {
    const $element = $(element);
        
    const possibleWidth = $element.parent().width() - rightLimit;

    let currentLinkIndex = 0;
    let currentWidth = 0;
    const linksCount = tabsCollection().length;
    const fontSize = $element.css("font-size");
    const othersLabel = globalization.getLabelTranslation("#Core/others");
    const othersLabelWidth = getTextWidth(othersLabel, fontSize) + emptyTabWidth;

    while (currentLinkIndex < linksCount) {
        const currentLinkWidth = getTextWidth(tabsCollection()[currentLinkIndex].TabName(), fontSize);
        currentWidth += currentLinkWidth;
        tabsCollection()[currentLinkIndex].isDropdown(othersLabelWidth + currentWidth >= possibleWidth);

        currentLinkIndex++;
    }
}

const wrapTaskTabs = {
    init: function (element:any, bindingAccessor:any) {
        const options = bindingAccessor();

        tabsCollection = options.tabsCollection || ko.observableArray([]);
        rightLimit = options.rightLimit || 0;

        const fitHandler = function () {
            fitTaskMenuHandler(element);
        };

        registerResizeHandler(element, fitHandler);
        koUtils.domData.set(element, wrapKey, true);
    },
    update: function (element:any, bindingAccessor:any, ...args:any) {
        const options = bindingAccessor();

        tabsCollection = options.tabsCollection || ko.observableArray([]);
        rightLimit = options.rightLimit || 0;

        fitTaskMenuHandler(element);

        const initialized = koUtils.domData.get(element, wrapKey);
        if (!initialized) {
            wrapTaskTabs.init.apply(this, _.clone([element,bindingAccessor, ...args]));
        }
    }
};

export function register() {
    koUtils.registerBinding("wrapTaskTabs", wrapTaskTabs);
}