/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/accounting/autocompleter/dataProvider/taxGroup"/>
import { unpack } from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import { Provider as BaseProvider} from "Core/Medius.Core.Web/Scripts/Medius/apps/autocompleter/dataProvider/companyRelated";

export class Provider extends BaseProvider {
    public indicatorNumber: number;
    public zeroValuesTaxCodesOnly: any;
    public taxIndicator1Observable: any;
    public taxIndicator2Observable: any;

    public constructor(context: any, companyId: any, indicatorNumber: number, modelTaxIndicator1Observable?: any, modelTaxIndicator2Observable?: any, zeroValuesTaxCodesOnly?: any, methodName = "GetTaxGroups") {
        super(context, methodName, 'TaxService', companyId);
        this.indicatorNumber = indicatorNumber;
        this.zeroValuesTaxCodesOnly = zeroValuesTaxCodesOnly;
        this.taxIndicator1Observable = modelTaxIndicator1Observable;
        this.taxIndicator2Observable = modelTaxIndicator2Observable;
    }

    public getParameters(phrase: any, limit: any, extras: any) {
        const parameters: any = super.getParameters(phrase, limit);
        const indicatorNumber = this.getIndicatorNumber(extras);
        parameters.indicatorNumber = indicatorNumber;
        if (extras.companyId) {
            parameters.companyId = extras.companyId;
        }
        parameters.otherIndicatorId = indicatorNumber === 1 ?
            unwrapTaxIndicatorId(this.taxIndicator2Observable) :
            unwrapTaxIndicatorId(this.taxIndicator1Observable);

        parameters.onlyZeroValuedTaxCodes = this.zeroValuesTaxCodesOnly;
        return parameters;
    }

    public getIndicatorNumber(extras: any) {
        const indicatorNumber = this.indicatorNumber || 1;

        if (extras) {
            return extras.indicatorNumber || indicatorNumber;
        }

        return indicatorNumber;
    }
}

function unwrapTaxIndicatorId(taxIndicatorObservable: any) {
    if (isNullOrUndefined(taxIndicatorObservable) || (!taxIndicatorObservable())) {
        return null;
    }

    const indicatorId = unpack(taxIndicatorObservable().Id);

    return indicatorId !== 0 ? indicatorId : null;
}

export function create(context: any, companyId: any, indicatorNumber: any, modelTaxIndicator1Observable?: any, modelTaxIndicator2Observable?: any, zeroValuesTaxCodesOnly?: any) {
    return new Provider(context, companyId, indicatorNumber, modelTaxIndicator1Observable, modelTaxIndicator2Observable, zeroValuesTaxCodesOnly);
}