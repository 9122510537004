/// <amd-module name="Core/Medius.Core.Web/Scripts/components/mention/editor/defaults"/>
import { IEditorOptions } from "Core/Medius.Core.Web/Scripts/components/mention/editor/IEditorOptions";

export const defaults: IEditorOptions = {
    autocompleterLettersThreshold: 1,
    placeholder: "",
    rows: 1,
    autocompleterRequestDelay: 500,
    autocompleterLimit: 20,
    autocompleterDisablementReasonTranslationKey: "",
    autocompleterDisablementReasonDocumentType: "",
    suggestionsProvider: null
};
