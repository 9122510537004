/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/session/extender"/>
import * as rpc from "Core/Medius.Core.Web/Scripts/Medius/core/rpc";
import * as logger from "Core/Medius.Core.Web/Scripts/Medius/lib/logger";
import * as sessionInformation from "Core/Medius.Core.Web/Scripts/Medius/session/information";
import { isNullOrUndefined, isNotNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import * as ko from "knockout";

export const hasSessionExpired = ko.observable(false);

export class SessionExtender {
    private sessionRefreshRequest: JQueryPromise<void> | null;

    /* Percentage threshold for elapsed time in session when token update is necessary */
    private getSessionUpdateThreshold(): number {
        return 0.2;
    }

    private ping() {
        if (isNotNullOrUndefined(this.sessionRefreshRequest))
            return;

        this.sessionRefreshRequest = rpc.ping("SpaApp/Ping")
            .then(() => { 
                this.sessionRefreshRequest = null;
             });
    }

    public extendSession = () => {
        const sessionElapsedTime: number = sessionInformation.getSessionElapsedTime();
        const sessionCookieLifetime: number = sessionInformation.getSessionCookieLifetime();
        const sessionRemainingTime: number = sessionInformation.getSessionRemainingTime();

        if (isNullOrUndefined(sessionElapsedTime) || isNullOrUndefined(sessionCookieLifetime)) {
            logger.error("[Core/Medius.Core.Web/Scripts/Medius/session/extender] medius/session/extender"
                + " is unable to extend session for user actions which do not require making requests to the backend."
                + " Please fix issues with Core/Medius.Core.Web/Scripts/Medius/session/information module.");
            return;
        }

        const refreshThresholdElapsedTime: number = sessionCookieLifetime * this.getSessionUpdateThreshold();

        if(sessionRemainingTime <= 0)
            hasSessionExpired(true);

        if (sessionElapsedTime >= refreshThresholdElapsedTime)
            this.ping();
    };
}

export function create() {
    return new SessionExtender();
}
