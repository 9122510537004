/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/AuthorizationGroups/grid"/>
import * as React from "react";
import { Grid, GridColumn as Column, GridDataStateChangeEvent, GridFilterCellProps, GridProps, GridRowClickEvent } from "@progress/kendo-react-grid";
import { IsActiveCell, IsActiveFilterCell } from "Core/Medius.Core.Web/Scripts/components/grid/isActiveCell";
import { translate } from "../../lib/globalization";
import { AuthorizationGroupHeader, AuthorizationGroupsHeaders, EntityId, Optional } from "./authorizationGroup";
import { dateFilterCellFactory } from "../../customTabs/definitions/contracts/customFilterCells";

type AuthorizationGroupsGridProps = {
    readonly gridDataState: GridProps;
    readonly gridData: Optional<AuthorizationGroupsHeaders> | null;
    readonly selectedItem: EntityId | null;
    readonly onRowClick: (id:  number) => void;
    readonly onGridDataStateChanged: (dataState: GridProps) => void;
};

export const AuthorizationGroupsGrid = (props: AuthorizationGroupsGridProps) => {
    const { gridDataState, gridData, selectedItem, onRowClick, onGridDataStateChanged } = props;

    const onDataStateChange = (event: GridDataStateChangeEvent) => {
        onGridDataStateChanged({ ...gridDataState, ...event.dataState});
    };

    const onRowClickEvent = (event: GridRowClickEvent) => {
        onRowClick(event.dataItem.Id);
    };

    const selected = (item: AuthorizationGroupHeader) => ({
        ...item,
        selected: item.Id === selectedItem?.Id
    });

    const prepareData = () => 
        gridData?.Data && gridData.Data.map(selected);    

    const dateFilterCell = React.useCallback((props: GridFilterCellProps) => dateFilterCellFactory({ wrappedProps: props, operator: 'eq' }), []);

    return (
        <Grid
            className="authorizationGroups__grid"
            data={prepareData()}
            total={gridData?.Total}
            { ... gridDataState}
            onDataStateChange={onDataStateChange}
            onRowClick={onRowClickEvent}>
            <Column field="Name" title={translate("#Core/name")} className="authorizationGroups__grid__nameRow" />
            <Column field="CompanyName" title={translate("#Core/company")} className="authorizationGroups__grid__companyRow" />
            <Column field="CurrencyCode" title={translate("#Enterprise/currency")} className="authorizationGroups__grid__currencyRow" width="110px" />
            <Column field="IsActive" title={translate("#Core/active")} className="authorizationGroups__grid__isActiveRow" width="110px"
                filter="boolean"
                filterCell={IsActiveFilterCell}
                cell={IsActiveCell} />
            <Column field="ImportedTimestamp" title={translate("#Core/importedTimestamp")} 
                className="authorizationGroups__grid__importedTimestampRow" format="{0:d}" filter="date" filterable={true} filterCell={dateFilterCell}/>
        </Grid >
    );
};
