///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/transformers/serialize/array"/>
import * as defaults from "Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/transformers/serialize/defaults";
import { isArray } from "Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/helpers";

export = function arraySerializer(input:any/*, mapping, context*/) {
    if (!isArray(input)) {
        return null;
    }

    const viewmodel:any = [];

    return {
        ...defaults,
        shouldDescend: true,
        mapProperty: function (name:string, value:any) {
            viewmodel[name] = value;
        },
        finalize: function () {
            return viewmodel;
        }
    };
};