/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/metadata/resolvers/paymentTerm"/>
import * as ko from "knockout";
import { formatItem } from "Core/Medius.Core.Web/Scripts/lib/formatting";
import * as registry from "Core/Medius.Core.Web/Scripts/Medius/apps/metadata/resolvers/registry";

interface IPaymentTerm {
    Name: string;
    PaymentTermId: string;
}

function resolve(paymentTerm: IPaymentTerm) {
    const name = ko.unwrap(paymentTerm.Name);
    const paymentTermId = ko.unwrap(paymentTerm.PaymentTermId);

    return {
        text: formatItem(name, paymentTermId)
    };
}

export default resolve;

export function register() {
    registry.register("Medius.Enterprise.Entities.PaymentTerm", resolve);
}
