/// <amd-module name="Core/Medius.Core.Web/Scripts/lib/reactIntegration/kendoTranslations"/>
// Read more: https://www.telerik.com/kendo-react-ui/components/intl/
// We need to provide object literals with translations that will be later used by LocalizationProvider, see in routingTable.tsx component

import { loadMessages } from "@progress/kendo-react-intl";

// We won't use Kendo mechanism for switching between languages, we have our own, but we need to pass some language name to LocalizationProvider anyway
const dummyLanguageName = "current-language";

export function loadKendoTranslations(translate: (key: string) => string) {
    const translations = {
        "upload": {
            // Default English translations: https://www.telerik.com/kendo-react-ui/components/upload/globalization/
            "select": translate("#Core/kendo/upload/select")
        },
        "grid": {
            // Default English translations: https://www.telerik.com/kendo-react-ui/components/grid/globalization/
            "pagerInfo": translate("#Core/kendo/grid/pagerInfo_from_to_total"),
            "noRecords": translate("#Core/kendo/grid/noRecords"),
            "filterEqOperator": translate("#Core/kendo/grid/filterEqOperator"),
            "pagerFirstPage": translate("#Core/kendo/grid/pagerFirstPage"),
            "pagerPreviousPage": translate("#Core/kendo/grid/pagerPreviousPage"),
            "pagerNextPage": translate("#Core/kendo/grid/pagerNextPage"),
            "pagerLastPage": translate("#Core/kendo/grid/pagerLastPage"),
            "pagerPage": translate("#Core/kendo/grid/pagerPage"),
            "pagerOf": translate("#Core/kendo/grid/pagerOf"),
            "filterContainsOperator": translate("#Core/kendo/grid/filterContainsOperator"),
            "filterBooleanAll": translate("#Core/kendo/grid/filterBooleanAll"),
            "filterIsTrue": translate("#Core/kendo/grid/filterIsTrue"),
            "filterIsFalse": translate("#Core/kendo/grid/filterIsFalse"),
        },
        "datepicker" : {
            "toggleCalendar": translate("#Core/kendo/toggleCalendar")
        },
        "dropdowns": {
            "nodata": translate("#Core/kendo/dropdowns/noData"),
            "clear": translate("#Core/kendo/dropdowns/clear"),
        }
    };

    loadMessages(translations, dummyLanguageName);
}
