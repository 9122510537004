/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/administration/adminApp"/>
import { observable, computed, pureComputed } from "knockout";
import { when } from "jquery";
import { getSaveActionForType, getRemoveActionForType, getLoadActionForType } from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/customActionRegistry";
import GenericObjectBrowser from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/genericObjectBrowser";
import {handleAnyError} from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import {success} from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import {translate} from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { logEvent } from "Core/Medius.Core.Web/Scripts/lib/metricsLogging/metricLogger";
import {SidebarWithProvider} from "Core/Medius.Core.Web/Scripts/components/sidebar/sidebarWithProvider";
import {administrationEntityChanged} from "Core/Medius.Core.Web/Scripts/events/eventNames";
import {trigger} from "Core/Medius.Core.Web/Scripts/eventBus";
import {isEmptyString} from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import {isNull, isUndefined} from "underscore";
import {create as createEditor, Editor} from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/editor";
import {Tabs} from "Core/Medius.Core.Web/Scripts/AdminPages/User/SuperTabs/tabs";
import {isNewUserAdministrationSuperTabsEnabled} from "Core/Medius.Core.Web/Scripts/Medius/core/featureToggle";
import { getMfaAppUrl } from "Core/Medius.Core.Web/Scripts/appConfig";

// as it may be little strange to have defined in core entities from different application it is much more complex than having registering mechanism
const nonDeletableEntities = [
    "Medius.Core.Entities.Company",
    "Medius.Enterprise.Entities.Supplier",
    "Medius.PurchaseToPay.Entities.PurchaseOrder.PurchaseOrder"
];

interface EntityKey {
    Id: number;
    Name: string;
    IsOpenedFromEntityLink: boolean;
}

class AdminApp {
    IsLoading = observable(false);
    IsSidebarVisible = observable(true);
    CompanyContextId = observable<number>(null);
    EntityKey = observable<EntityKey>();
    CanCreate = observable(true);
    EntityName: ko.Computed<string>;
    EntityId: ko.Computed<any>;
    EntityNameKey = pureComputed(() => isEmptyString(this.EntityName()) ? "" : "#" + this.EntityName());
    BaseEntityId = observable(0);
    ObjectBrowser: GenericObjectBrowser;
    Editor = observable<Editor>(null);
    toggleSidebarVisibility: () => void;
    isOpenedFromEntityLink: boolean;
    sidebarRoot: any;
    setActiveTab = (tabName: string) => {
        this.activeTab(tabName);
    };
    userSuperTabs = {
        root: pureComputed(()=>({
            functionComponent: Tabs,
            props: {
                setActiveTab: this.setActiveTab,
                userName: this.Editor().EntityWithContext.Entity?.UserName(),
                firstName: this.Editor().EntityWithContext.Entity?.FirstName(),
                lastName: this.Editor().EntityWithContext.Entity?.LastName(),
                isActive: this.Editor().EntityWithContext.Entity?.IsActive(),
            }
        })),
        mfaUrl: pureComputed(() => {
            const userGlobalId = this.Editor().EntityWithContext.Entity.GlobalId();
            return`${getMfaAppUrl()}/mfa/status/${userGlobalId}`;
        })
    };
    activeTab = observable("AP Automation");
    
    editorTemplate = pureComputed(() => {
        if(!isNewUserAdministrationSuperTabsEnabled()){
            return "Core:templates/Administration/ItemSection.html";
        }
        if(this.EntityName() === "Medius.Core.Entities.User") {
            return "Core:templates/Administration/User/User.html";
        }
        return "Core:templates/Administration/ItemSection.html";
    });
    isDeleteHidden = () => nonDeletableEntities.includes(this.EntityName());
    
    constructor() {
        this.setEntityKey(null, null);

        this.EntityName = computed({
            read: () => this.EntityKey().Name,
            write: value => {
                this.setEntityKey(value, this.EntityId());
            }
        });

        this.EntityId = computed({
            read: () => this.EntityKey().Id,
            write: value => {
                this.setEntityKey(this.EntityName(), value);
            }
        });

        this.toggleSidebarVisibility = () => {
            const currentState = this.IsSidebarVisible();
            this.IsSidebarVisible(!currentState);
        };

        this.sidebarRoot = {
            functionComponent: SidebarWithProvider,
            props: {
                onRowClick: (forType: string) => {
                    this.setEntityKey(forType, null);
                },
                onBackAction: this.toggleSidebarVisibility,
            }
        };

        this.ObjectBrowser = new GenericObjectBrowser(this.EntityId, this.EntityName, this.EntityKey, this.CompanyContextId, this.CanCreate);

        this.EntityKey.subscribe((key: any) => {
            this.IsSidebarVisible(false);

            if (isNull(key.Name) && isNull(key.Id)) {
                this.IsSidebarVisible(true);
            }

            this.loadForm(key.Name, key.Id, key.IsOpenedFromEntityLink);
        });

        this.add = this.add.bind(this);
        this.clone = this.clone.bind(this);
    }

    public init() {}

    public add() {
        this.EntityId(0);
    }

    public clone() {
        this.BaseEntityId(this.EntityId());
        this.EntityId(0);
    }

    public save() {
        const saveAction = getSaveActionForType(this.EntityName());
        if (saveAction) {
            saveAction(this);
        } else {
            this.IsLoading(true);
            makeGenericSaveCall(this)
                .always(() => this.IsLoading(false));
        }
    }

    public remove() {
        const removeAction = getRemoveActionForType(this.EntityName());

        if (removeAction) {
            removeAction(this);
        } else {
            this.IsLoading(true);
            makeGenericRemoveCall(this)
                .always(() => this.IsLoading(false));
        }
    }

    public loadForm(entityType: string, entityId: number, isOpenedFromEntityLink = false) {
        if (this.Editor()) {
            this.Editor().dispose();
            this.Editor(null);
        }

        if (isNull(entityType) || isUndefined(entityType) || isNull(entityId) || isUndefined(entityId)) {
            return when(null);
        }

        this.IsLoading(true);

        const customLoadAction = getLoadActionForType(this.EntityName());

        const editor = createEditor(this);
        let loaded: JQueryPromise<any>;
        if (customLoadAction) {
            loaded = customLoadAction(editor, entityId);
        } else {
            loaded = editor.load(entityType, entityId, this.BaseEntityId());
        }
        
        return when(loaded)
            .then(
                () => {
                    this.Editor(editor);
                    if (isOpenedFromEntityLink) {
                        this.logEntityLinkClicked(entityType, true);
                    }
                },
                xhr => {
                    if (isOpenedFromEntityLink) {
                        this.logEntityLinkClicked(entityType, false);
                    }
                    handleAnyError(xhr);
                })
            .always(() => {
                this.IsLoading(false);
                this.BaseEntityId(0);
            });
    }

    public reloadForm() {
        return this.loadForm(this.EntityName(), this.EntityId());
    }

    public setEntityKey(entityType: string, entityId: number, isOpenedFromEntityLink = false) {
        trigger(administrationEntityChanged, entityType);
        this.EntityKey({
            Id: entityId,
            Name: entityType,
            IsOpenedFromEntityLink: isOpenedFromEntityLink
        });
    }

    private logEntityLinkClicked(entityType: string, withSuccess: boolean) {
        const entityTypeNameParts = entityType.split(".");
        const result = withSuccess ? "_success" : "_failure";
        logEvent("entity-link", entityTypeNameParts[entityTypeNameParts.length - 1] + result);
    }
}

export function extractCreatedId(data: EntityId, xhr: JQueryXHR) {
    if (data && data.id) {
        return data.id;
    }

    if (xhr && xhr.getResponseHeader) {
        const pathParts = xhr.getResponseHeader("Content-Location").split("/");
        const id = parseInt(pathParts[pathParts.length - 1], 10);

        if (!isNaN(id)) {
            return id;
        }
    }
    return 0;
}

export function makeGenericSaveCall(adminApp: AdminApp) {
    const saved = adminApp.Editor().save();
    return when(saved)
        .then(
            (data, textStatus, jqXhr) => {
                success(translate("#Core/changesSavedSuccessfully"));

                if (adminApp.EntityId() > 0) {
                    adminApp.reloadForm();
                } else {
                    const id = extractCreatedId(data, jqXhr);
                    adminApp.EntityId(id);
                }

                adminApp.ObjectBrowser.Grid().refresh();
            });
}

export function makeGenericRemoveCall(adminApp: AdminApp) {
    const removed = adminApp.Editor().remove();

    return when(removed)
        .then(() => {

            if (adminApp.EntityId() > 0) {
                success(translate("#Core/entityRemovedSuccessfully"));
            }

            adminApp.ObjectBrowser.Grid().openPage(1);
            adminApp.Editor().dispose();
            adminApp.Editor(null);
            adminApp.EntityId(null);

        });
}

export function create() {
    return new AdminApp();
}

export type AdminApplication = AdminApp;
