///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/documentSearch/savedQueriesPanel"/>
import * as ko from "knockout";
import * as $ from "jquery";
import * as queryItem from "Core/Medius.Core.Web/Scripts/Medius/apps/documentSearch/queryItem";
import * as rpc from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rpc";
import * as backendErrorHandler from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import * as _ from "underscore";
import { isNotNullOrUndefined } from 'Core/Medius.Core.Web/Scripts/lib/underscoreHelpers';

class SavedQueriesPanel {
    AllQueries = ko.observableArray([]);
    SelectedQuery = ko.observable(null);
    SelectedQueryName = ko.observable(null);
    FavoriteQueries = ko.computed(() => {
        return _.filter(this.AllQueries(), (item) => { return item.Query.isFavorite() && !item.Query.isShared(); });
    });

    SavedQueries = ko.computed(() => {
        return _.filter(this.AllQueries(), (item) => { return !item.Query.isFavorite() && !item.Query.isShared(); });
    });

    SharedQueries = ko.computed(() => {
        return _.filter(this.AllQueries(), (item) => { return item.Query.isShared(); });
    });

    refreshItemsSection() {
        this.AllQueries.notifySubscribers();
    }

    updateQueryItemName(queryItem:any) {
        $.when(queryItem.updateQueryItemName()).done(function () {
            //if name of selected query is changed, reset page header, SelectedQueryName is obserwable
            if (isNotNullOrUndefined(this.SelectedQuery()) && queryItem.Query.Id === this.SelectedQuery().Id) {
                this.SelectedQueryName(queryItem.QueryName());
            }
        });
    }

    getSavedQueries() {
        return rpc.lightApi("SearchQueryService", 'GetSavedQueries').
            done((data) => {
                const t = _(data).map((item) => {
                    return queryItem.create(item);
                });
                this.AllQueries(t);
            }).fail((xhr) => {
                backendErrorHandler.handleAnyError(xhr, null, "#Core/docSidebarGettingSavedFail");
            });
    }

    init() {
        this.getSavedQueries();
    }

    pickSavedQuery(pickedQuery: { Query: any; }) {
        this.SelectedQuery(pickedQuery.Query);
    }

    pickSharedQuery(pickedQuery: { Query: { Id: number; }; }) {
        pickedQuery.Query.Id = 0;
        this.SelectedQuery(pickedQuery.Query);
    }

    updateQueryPanel(newQueryId: any) {
        const newQuery = _.find(this.AllQueries(), (item) => {
            return item.QueryId === newQueryId;
        });

        if (!_.isUndefined(newQuery)) {
            this.SelectedQuery(newQuery.Query);
        }
    }

    removeSavedQuery(pickedQuery: { removeQueryItem: () => any; }) {
        $.when(pickedQuery.removeQueryItem())
            .done(() => {
                this.refreshSavedQueries();
                this.SelectedQuery(null);
            });
    }

    refreshSavedQueries() {
        this.getSavedQueries();
    }
}

export function create() {
    const queriesPanel = new SavedQueriesPanel();
    queriesPanel.getSavedQueries();
    return queriesPanel;
}