///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/validation/button/binding"/>
import * as _ from "underscore";
import * as $ from "jquery";
import * as ko from "knockout";
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as fn from "Core/Medius.Core.Web/Scripts/Medius/lib/functions";

const defaultDelay = 120;
let isInvalid:ko.Computed<boolean>;
let tooltipObservable: ko.Observable<any>;
let isInvalidSubscribtion: ko.Subscription;

function disable(element:Element, value:any) {
    if (value) {
        element.setAttribute("disabled", "disabled");
    } else {
        element.removeAttribute("disabled");
    }
}

function getParams(bindingAccessor:any) {
    const defaults: {
        click: any,
        delay: number,
        disableButton: boolean,
        disableAfterAction: boolean,
        customValidation: any,
        validationTooltipTextObservableProvider: any
    } = {
        click: null,
        delay: defaultDelay,
        disableButton: true,
        disableAfterAction: true,
        customValidation: null,
        validationTooltipTextObservableProvider: null
    };

    return _(defaults).extend(bindingAccessor);
}

function getTooltipValueAccessor(tooltipDataObservable:any) {
    return {
        template: 'editors-tpl-validation-tooltip',
        skin: 'error',
        data: tooltipDataObservable,
        enabled: isInvalid
    };
}

const button: ko.BindingHandler = {
    init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
        let handler: () => any;
        const params = getParams(valueAccessor());
        let action = params.click;
        let validationGroup = bindingContext.validationGroup;

        let customValidationFails = ko.computed(() => {
            if (ko.isObservable(params.customValidation)) {
                return !params.customValidation();
            }

            return false;
        });

        if (!validationGroup) {
            return;
        }

        isInvalid = ko.computed(() => {
            return !validationGroup.isValid() || customValidationFails();
        });

        const validationTooltipTextObservableProvider = params.validationTooltipTextObservableProvider;
        if (_.isFunction(validationTooltipTextObservableProvider)) {
            tooltipObservable = validationTooltipTextObservableProvider(validationGroup);
            ko.bindingHandlers.newTooltip.init.apply(null, [element, _.constant(getTooltipValueAccessor(tooltipObservable)), allBindingsAccessor, viewModel, bindingContext]);
        }

        if (params.disableButton) {
            disable(element, isInvalid.peek());

            isInvalidSubscribtion = isInvalid.subscribe(function (value) {
                disable(element, value);
            });

            handler = () => {
                if (isInvalid.peek()) {
                    return false;
                }

                if (_.isFunction(action)) {
                    disable(element, params.disableAfterAction);
                    action(bindingContext.$data);
                    return false;
                }
            };
        }
        else {
            handler = () => {
                $.when(validationGroup.validate()).always((validationGroupResult) => {
                    const isValid = validationGroupResult && !customValidationFails();

                    if (isValid && _.isFunction(action)) {
                        disable(element, params.disableButton);
                        action(bindingContext.$data);
                    }
                });
            };
        }

        let clickHandler = fn.delayed(handler, params.delay, undefined);
        element.addEventListener("click", clickHandler);

        function dispose() {
            if (isInvalidSubscribtion) {
                isInvalidSubscribtion.dispose();
                isInvalidSubscribtion = null;
            }

            customValidationFails.dispose();
            customValidationFails = null;

            isInvalid.dispose();

            element.removeEventListener("click", clickHandler);

            clickHandler = null;
            handler = null;
            validationGroup = null;
            action = null;
        }

        koUtils.addDisposeCallback(element, dispose);
    },

    update: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
        const params = getParams(valueAccessor()),
            validationTooltipTextObservableProvider = params.validationTooltipTextObservableProvider;
        if (_.isFunction(validationTooltipTextObservableProvider)) {
            ko.bindingHandlers.newTooltip.update.apply(null, [element, _.constant(getTooltipValueAccessor(tooltipObservable)), allBindingsAccessor, viewModel, bindingContext]);
        }
    }
};

export function register() {
    koUtils.registerBinding("validation-button", button);
}