/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/AuthorizationGroups/documentOrder"/>
const documentOrder: {[key: string]: number} = {
    "Medius.Data.Document": 1,
    "Medius.Enterprise.Entities.Invoice": 2,
    "Medius.PurchaseToPay.Entities.SupplierInvoice": 3, 
    "Medius.ExpenseInvoice.Entities.ExpenseInvoice": 4,   
    "Medius.OrderbasedInvoice.Entities.OrderbasedInvoice": 5,    
    "Medius.ContractbasedInvoice.Entities.ContractbasedInvoice": 6,
    "Medius.ExpenseInvoice.Entities.PaymentRequest": 7,
    "Medius.Procurement.Entities.PurchaseRequisition": 8,
    
};

export function getDocumentOrderForType(type: string) {
    // for non standard document types (e.g. from Selenium tests) value which will place document in the end
    return documentOrder[type] ? documentOrder[type] : 9;
}