/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/AuthorizationGroups/Tabs/components/DimensionColumnMenuSort"/>
import * as React from "react";
import { GridColumnMenuItem, GridColumnMenuItemGroup, GridColumnMenuProps } from "@progress/kendo-react-grid";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { SortDescriptor } from "@progress/kendo-data-query";
import { Optional } from "../../authorizationGroup";

const DimensionIdField = "Dimension.Id";
const DimensionNameField = "Dimension.DisplayName";
const ascendingIcon = "k-i-sort-asc-sm";
const descendingIcon = "k-i-sort-desc-sm";
const emptyIcon = "";

type SortDirection = "asc" | "desc";

const getIcon = (dir?: SortDirection) => dir === undefined 
    ? emptyIcon 
    : dir === "asc" ? ascendingIcon : descendingIcon;

const flipSortDirection = (dir?: SortDirection) : SortDirection => dir === undefined
    ? "asc"
    : dir === "asc" ? "desc" : "asc";

const DimensionColumnMenuSort = (props: GridColumnMenuProps) => {
    const dimensionIdDescriptor = props.sort?.find(d => d.field === DimensionIdField);
    const dimensionNameDescriptor = props.sort?.find(d => d.field === DimensionNameField);

    const sortByField = (field: string, descriptor?: SortDescriptor) => (e: any) => {
        if(props.onSortChange) {
            props.onSortChange([{ field: field, dir: flipSortDirection(descriptor?.dir) }],e);
        }
        if(props.onCloseMenu) {
            props.onCloseMenu();
        }
    };

    return (
        <GridColumnMenuItemGroup {...props}>
            <GridColumnMenuItem title={translate("#Enterprise/authorizationGroupsSortByDimensionId")} 
                onClick={sortByField(DimensionIdField, dimensionIdDescriptor)} selected={dimensionIdDescriptor !== undefined} 
                iconClass={getIcon(dimensionIdDescriptor?.dir)} />
            <GridColumnMenuItem title={translate("#Enterprise/authorizationGroupsSortByDimensionName")}
                onClick={sortByField(DimensionNameField, dimensionNameDescriptor)} selected={dimensionNameDescriptor !== undefined}
                iconClass={getIcon(dimensionNameDescriptor?.dir)} />
        </GridColumnMenuItemGroup>
    );
};

export default DimensionColumnMenuSort;
