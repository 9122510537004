/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/communication/json/mediusFlowMigrationRest"/>
import * as _ from "underscore";
import * as rawRest from "Core/Medius.Core.Web/Scripts/Medius/core/mediusFlowMigrationRest";
import {getDefaultOptions, deserializeResponse, toJSON} from "./helpers";
import { pipeApply } from "Core/Medius.Core.Web/Scripts/Medius/lib/deferred";

export function get(service: string, resource?: string | number, data?: any, options?: JQueryAjaxSettings): JQueryXHR {
    const extendedOptions = _(getDefaultOptions()).extend(options, { data });
    const requestResult = rawRest.get(service, resource, extendedOptions)
            .pipe(deserializeResponse);

    return pipeApply(requestResult, { includeFail: true });
}

export function post(service: string, resource: string | number, data?: any, options?: JQueryAjaxSettings): JQueryXHR {
    const extendedOptions = _(getDefaultOptions()).extend(options, { data: toJSON(data) });

    const requestResult = rawRest.post(service, resource, extendedOptions)
        .pipe(deserializeResponse);

    return pipeApply(requestResult, { includeFail: true });
}

export function put(service: string, resource: string | number, data?: any, options?: JQueryAjaxSettings): JQueryXHR {
    const extendedOptions = _(getDefaultOptions()).extend(options, { data: toJSON(data) });

    const requestResult = rawRest.put(service, resource, extendedOptions)
        .pipe(deserializeResponse);

    return pipeApply(requestResult, { includeFail: true });
}

export function del(service: string, resource?: string | number, data?: any, options?: JQueryAjaxSettings): JQueryXHR {
    const extendedOptions = _(getDefaultOptions()).extend(options, { data });

    const requestResult = rawRest.del(service, resource, extendedOptions)
        .pipe(deserializeResponse);

    return pipeApply(requestResult, { includeFail: true });
}
