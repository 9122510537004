/// <amd-module name="Core/Medius.Core.Web/Scripts/Models/Medius.Core.Entities.Integration.EDIDocumentImport"/>
import * as ko from "knockout";
import { getTypeName } from "Core/Medius.Core.Web/Scripts/Medius/core/type";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import repository = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/mapping/collection/instance");

const baseExtension = require("Core/Medius.Core.Web/Scripts/Models/document").DocumentViewmodel;
const type = "Medius.Core.Entities.Integration.EDIDocumentImport";

enum DocumentImportStatus
{
    Unknown = 0,
    Received = 1,
    Registered = 2,
    Error = 3,
    Discarded = 4
}

export const ediDocumentImport = (vm: any, data: any, context: any) => {
    baseExtension(vm, data, context);
    
    vm.entityTypeName = ko.computed(() => {
        if (vm.MappedContent()) {
            return getTypeName(vm.MappedContent().Entity().$type());
        }
        return "";
    });

    vm.isRegistered = ko.observable(data.Status === DocumentImportStatus.Registered);

    if (vm.isRegistered()) {
        vm.documentLink = ko.observable({
            Id: data.WrappedDocumentId,
            DisplayName: getLabelTranslation("#Core/registeredDocumentLinkName"),
            HasAccess: true,
            Type: data.WrappedDocumentType,
            $type: "Medius.Enterprise.DTO.NamedReferenceDto"
        });
    }

    vm.documentView = ko.computed(() =>
        ["Display", vm.entityTypeName(), "Create"].join("/"));
};

export function register() {
    repository.register(type, ediDocumentImport);
}
