/// <amd-module name="Core/Medius.Core.Web/Scripts/components/topBar/drawer/icons/index"/>
import * as React from "react";
import {
    IconAppsAppRsoConnectRegular,
    IconAppsAppManageIntegrationsRegular,
    IconAppsAppOffice365Regular,
    IconAppsAppPayRegular,
    IconAppsAppAnalyticsRegular,
    IconAppsAppApaRegular,
    IconAppsAppConnectRegular,
    IconAppsAppSrmRegular,
    IconAppsAppContractManagementRegular,
    IconAppsAppPdmsRegular,
    IconAppsAppSavingsTrackerRegular,
    IconAppsAppEsourcingRegular,
    IconAppsAppProcurementRegular,
    IconAppsAppSupplierPortalRegular,
    IconAppsAppSupplierManagementRegular 
} from "@medius/ui-controls";

//This part is temporary until we can repoint it to the northstar icon
const SvgImageLogoExpensya = (props: React.SVGProps<SVGSVGElement>) => {
    return (
      <svg fill="none" viewBox="0 0 24 24" {...props}>
            <path
                fill="black"
                d="M20 11.002h1.971c.538 0 1.053-.212 1.433-.592.38-.38.595-.896.596-1.435V1H10.001a1.99 1.99 0 00-1.849 1.232A2.004 2.004 0 0010.002 5H19A.997.997 0 0120 6v5z"
            />
            <path
                fill="black"
                d="M16 17.003h1.971a2.023 2.023 0 002.028-2.028V6.001a1 1 0 01-1 1H6.003a1.991 1.991 0 00-1.845 1.234 2.005 2.005 0 001.078 2.614c.242.1.501.153.763.153h8.997a.996.996 0 01.998 1 1.002 1.002 0 01-.998 1H4v4.001H16z"
            />
            <path
                fill="black"
                d="M16 12.001v8.994a2.011 2.011 0 01-2.009 2.008H0v-7.998a2.01 2.01 0 01.59-1.418A2 2 0 012.005 13H15a.997.997 0 001-1z"
            />
        </svg>
    );
  };

const IconAppsAppExpenseManagementRegular = React.memo(SvgImageLogoExpensya);
export default IconAppsAppExpenseManagementRegular;
IconAppsAppExpenseManagementRegular.displayName = "ImageLogoExpensya";
IconAppsAppExpenseManagementRegular.arguments = { tokenName: "image-logo-expensya" };

export const applicationIcons: { [name: string]: JSX.Element } = {
    'AP Automation': <IconAppsAppApaRegular size="large"/>,
    Connect: <IconAppsAppConnectRegular size="large"/>,
    'Contract Management': <IconAppsAppContractManagementRegular size="large"/>,
    PDMS: <IconAppsAppPdmsRegular size="large"/>,
    Procurement: <IconAppsAppProcurementRegular size="large"/>,
    'Savings Tracker': <IconAppsAppSavingsTrackerRegular size="large"/>,
    Sourcing: <IconAppsAppEsourcingRegular size="large"/>,
    SRM: <IconAppsAppSrmRegular size="large"/>,
    Analytics: <IconAppsAppAnalyticsRegular size="large"/>,
    Pay: <IconAppsAppPayRegular size="large"/>,
    'Supplier Portal': <IconAppsAppSupplierPortalRegular size="large"/>,
    'Supplier Onboarding': <IconAppsAppSupplierManagementRegular size="large"/>,
    'RSO Connect': <IconAppsAppRsoConnectRegular size="large"/>,
    'Manage Your Integration': <IconAppsAppManageIntegrationsRegular size="large"/>,
    'Office365 User Sync': <IconAppsAppOffice365Regular size="large"/>,
    'Expense management': < IconAppsAppExpenseManagementRegular/>
};