///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/provider/queryTypes" />

import * as type from "Core/Medius.Core.Web/Scripts/Medius/core/type";
import * as typesProvider from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/provider/types";
import * as typeResolver from "Core/Medius.Core.Web/Scripts/Medius/components/resolver/type";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as _ from "underscore";

const IDATA_SEARCH_QUERYABLE = "Medius.Data.DataSearch.IDataSearchQueryable";
        
function generateListOfLabels(array: any, types: any) {
    _(types).each(function (t) {
        array.push({
            fullname: t.fullname,
            label: t.label,
            level: t.level
        });
        generateListOfLabels(array, t.deriving);
    });
}

function getList(baseTypes: any) {
    const list: any[] = [];

    _.each(baseTypes, function (baseType) {
        const types = getDerivingTypes(baseType, 1);
        types.unshift(mapType(baseType, 0));
        generateListOfLabels(list, types);
    });

    return list;
}

type MappedType = {
    level: any;
    fullname: any;
    label: string;
    deriving?: any;
};

function mapType(typeName: any, level: any): MappedType{
    return {
        level: level,
        fullname: typeName,
        label: globalization.getPropertyTranslation("#" + typeName)
    };
}

function mapTypeWithDeriving(typeName: any, level: any) {
    const map = mapType(typeName, level);
    map.deriving = getDerivingTypes(typeName, level + 1);
    return map;
}

function getDerivingTypes(baseType: any, level: number = 0) {
    const directlyDerivingTypes = type.getDirectlyDerivingTypes(baseType);

    return _.chain(directlyDerivingTypes)
        .map(function(derivingType) {
            return mapTypeWithDeriving(derivingType, level);
        })
        .sortBy(function(derivingType) {
            return derivingType.label;
        }).value();
}

export function load() {
    return typesProvider.load()
        .pipe(function (typesWithAccess: any) {
            typesWithAccess = _(typesWithAccess).map(function(t) {
                    return t.TypeFullName;
            });
            const queryableTypes = _(typesWithAccess).filter(function(t) {
                return typeResolver.implementsInterface(t, IDATA_SEARCH_QUERYABLE, false);
            });
            let queryTypes = getList(queryableTypes);
            queryTypes = _(queryTypes).filter(function (t) {
                return _(typesWithAccess).contains(t.fullname);
            });

            return queryTypes;
        });
}