/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/inbox/task"/>
import * as ko from "knockout";
import * as outbox from "Core/Medius.Core.Web/Scripts/components/outbox/outbox";
import * as labelsHandlerFactory from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/task/labelsHandlerFactory";
import * as rpc from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rpc";
import * as _ from "underscore";
import * as settingsProvider from "Core/Medius.Core.Web/Scripts/Medius/core/settingsProvider";
import { pureComputed } from "knockout";
import { IndicatorsWrapper } from './indicators/IndicatorsWrapper';
import { OpenInFullscreenButtonTemplate } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/OpenInFullscreenButtonTemplate";
import * as localizer from "Core/Medius.Core.Web/Scripts/Medius/components/editors/financeComponent/localizer";
import { CodingSuggestionInvoiceConfidenceCell } from "./task/codingSuggestionInvoiceConfidenceCell";

import store = require("Core/Medius.Core.Web/Scripts/Medius/core/store/handling");

function formatAmount(amountComponent:any) {
    const value = amountComponent.DisplayValue;
    const currencyCode = amountComponent.CurrencyCode;
    const resolution = settingsProvider.getAmountDisplayResolution(currencyCode);

    const financialLocalizer = localizer.create(
        resolution
    );

    return financialLocalizer.toString(value);
}

class Task {
    public Document: any;
    public inbox: any;
    public taskGroup: any;
    public id: any;
    public TaskId: any;
    public documentId: any;
    public DocumentId: any;
    public LabelsIds: any;
    public labelsHandler: any;
    public indicators: any;
    public Indicators: any;
    public isUnread: ko.Observable<boolean>;
    public isCurrent: ko.Computed<boolean>;
    public isInReview: ko.Observable<boolean>;
    public isOnHold: ko.Observable<boolean>;
    public hasLocalModifications: ko.Observable<boolean>;
    public failed: ko.Computed<boolean>;
    public visible: ko.Computed<boolean>;
    public Activity: any;
    public InboxIndicatorWrapper: any;
    public ReasonCodeName: string;
    public CodingSuggestionInvoiceConfidenceCell: any;

    public OpenInFullscreenButton = {
        functionComponent: OpenInFullscreenButtonTemplate,
        props: {
            openInFullscreenAction: () => {this.openInFullscreen(this);}
        }
    };

    constructor(taskGroup: any, taskDto: any) {
        _.extend(this, taskDto);

        this.Document = this.Document || {};
        
        this.InboxIndicatorWrapper = pureComputed(() => {
            return ({
                functionComponent: IndicatorsWrapper,
                props: { 
                    Indicators: this.Indicators,
                    Activity: this.Activity 
                }
            });
        });

        this.CodingSuggestionInvoiceConfidenceCell = pureComputed(() => {
            return ({
                functionComponent: CodingSuggestionInvoiceConfidenceCell,
                props: {
                    workflowStep: this.Activity,
                    reasonCode: this.ReasonCodeName,
                    codingSuggestionInvoiceConfidence: this.Document.CodingSuggestionConfidence
                }
            });
        });

        const properties = Object.keys(this.Document);

        _(properties)
            .each((property) => {
                const value = this.Document[property];

                if(value && value.$type && value.$type === "Medius.Core.DTOs.AmountDto, Medius.Core.Common")
                    this.Document[property] = formatAmount(value);
        });

        this.inbox = taskGroup.folder.inbox;
        this.taskGroup = taskGroup;
        this.id = this.TaskId;
        this.documentId = this.DocumentId;
        
        this.LabelsIds = this.LabelsIds || [];
        this.labelsHandler = labelsHandlerFactory.createWithAssignedLabelsProvided(this.DocumentId, this.LabelsIds);
        this.indicators = this.Indicators;
        this.isUnread = ko.observable(taskDto.IsUnread);
        this.isCurrent = ko.computed(() => {
            if (this.inbox.currentTaskId() === this.id) {
                return true;
            }
            return false;
        });
        this.isInReview = ko.observable(taskDto.InReview);
        this.isOnHold = ko.observable(taskDto.IsOnHold);

        this.hasLocalModifications = ko.observable(false);

        if (this.inbox.currentTaskId() === this.id) {
            this.markRead();
        }

        store.hasItem(this.id)
            .done((val: any) => {
                this.hasLocalModifications(val);
        });

        this.failed = ko.computed(() => {
            const hasLocal = this.hasLocalModifications();
            const hasFailed = outbox.getOutboxInstance().isFailed(this.id);

            return hasLocal || hasFailed;
        });

        this.visible = ko.computed(() => {
            const tasks = _.flatten([outbox.getOutboxInstance().pending(), outbox.getOutboxInstance().done()]);
            const task = _.find(tasks, t => t.taskId === this.id);
            return task ? false : true;
        });
    }

    public clicked = () => {
        this.markRead();
        this.inbox.openTask(this.id);
    };

    public openInFullscreen = (data:any) => {
        data.inbox.selectedTasksHandler.clear();
        data.inbox.openTaskInFullscreen(data.id);
    };

    public markRead = () => {
        if (!this.isUnread()) {
            return;
        }

        this.isUnread(false);
        
        rpc.lightApi("InboxTaskService", "MarkAsRead", { taskId: this.id });
    };

    public dispose = () => {
        this.labelsHandler.dispose();
        this.failed.dispose();
        this.visible.dispose();
        this.isCurrent.dispose();

        this.inbox = null;
        this.taskGroup = null;
    };
}

export function create(taskGroup:any, taskDto:any){
    return new Task(taskGroup, taskDto);
}
