/// <amd-module name="Core/Medius.Core.Web/Scripts/components/sendEmail/sendEmailContainer"/>

import { create as createSendEmail } from "Core/Medius.Core.Web/Scripts/Medius/core/image/sendEmail";
import { PdfViewer } from "Core/Medius.Core.Web/Scripts/components/pdfViewer/pdfViewer";
import { observable}  from "knockout";
import { observe} from "Core/Medius.Core.Web/Scripts/shared/store/reduxStore";
import { Unsubscribe } from "redux";
import * as ko from "knockout";

export class SendEmailContainer {
    private companyId: number;
    private pdfViewer: PdfViewer;
    private documentType: string;
    public labelsHandlerComponentConfig: ko.Computed;
    public sendEmailForm = observable(null);

    public hasAccessToSendInvoiceAsEmail = observable(false);
    private unsubscribeStoreChange : Unsubscribe;

    constructor(pdfViewer: PdfViewer, companyId: number, documentType?:string) {
        this.companyId = companyId;
        this.pdfViewer = pdfViewer;

        if (documentType) {
            this.documentType = documentType.split(",", 1)[0].trim();
        } else {
            this.documentType = null;
        }

        this.unsubscribeStoreChange = observe((s) => s.accesses, (accesses) => {
            if (accesses) {
                this.hasAccessToSendInvoiceAsEmail(accesses.sendInvoiceAsEmail);
            }
        });
    }

    public showSendAsEmailModal() {
        const emailModel = createSendEmail(this.pdfViewer.currentFile(), this.companyId, this.documentType);
        this.sendEmailForm(emailModel);
        emailModel.showDialog();
    }

    public dispose() {
        if (this.unsubscribeStoreChange) {
            this.unsubscribeStoreChange();
        }
    }
}
