/// <amd-module name="Core/Medius.Core.Web/Scripts/errorPages/UserAccountNotFound"/>
import * as React from "react";
import Card from "Core/Medius.Core.Web/Scripts/components/card/Card";
import {toAction} from "Core/Medius.Core.Web/Scripts/Medius/lib/path";
import {translate} from "Core/Medius.Core.Web/Scripts/lib/globalization";

export default function UserAccountNotFoundPage(props: IUserAccountNotFoundPageProps) {
    return (
        <Card>
            <div className="card-content">
                <div className="user-account-notfound-page">
                    <div className="user-account-notfound-page__message">
                        {translate("#Core/UserAccountNotFoundIdentity")}
                    </div>

                    <div className="user-account-notfound-page__user-not-found-identity">
                        {props.model.NameId}  {props.model.IdentityProvider}
                    </div>

                    <div className="user-account-notfound-page__message">
                        {translate("#Core/couldNotLogIntoMediusFlow")} {props.model.ErrorMessages.map((error, index) => {return <p key={index}>{error}</p>;})}                                                
                    </div>

                    <form action={toAction('SwitchProvider', '~/Account')}>
                        <button className="card-button" type="submit">
                            {translate("#Core/logOut")}
                        </button>
                    </form>
                </div>
            </div>
        </Card>
    );
}

interface IUserAccountNotFoundPageProps {
    model: { 
        NameId: string;
        IdentityProvider: string;
        ErrorMessages: string[];
    };
}
