/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/task/settings"/>

export const context = "task-handling-context";
export const topLevelDocument = "task-handling-top-level-document";
export const taskReopened = "task-handling-task-reopened";
export const taskOpened = "task-handling-task-opened";

export function getEventName(...args: any[]) {
    const names = Array.prototype.slice.call(args);
    return ["Core", "task"].concat(names).join(":");
}

export function getHandleEventName(taskResult: any) {
    const actionCode = typeof taskResult === "object" && taskResult && taskResult.resultName ?
        taskResult.resultName() :
        typeof taskResult === "string" ?
            taskResult :
            "undefined";

    return getEventName("handling", actionCode);
}
