///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/presenters/link/entity/model" />

import { Basic } from "Core/Medius.Core.Web/Scripts/Medius/components/presenters/basic";
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import { get as getDefaults } from "Core/Medius.Core.Web/Scripts/Medius/components/presenters/link/entity/defaults";
import { create as MDGenCreate } from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/generator";
import * as helpers from "Core/Medius.Core.Web/Scripts/Medius/components/editors/helpers";
import * as ko from "knockout";

function wrapObservable(value: any): any {
    return ko.isObservable(value) ? value : ko.observable(value);
}

interface IDefaultParams {
    template: string;
    templateRead: string;
    metadataSource: any;
    forceType: any;
    isClickable: ko.Observable;
}

class EntityLink extends Basic {
    params: IDefaultParams | any;
    Data: ko.Computed;
    OriginalValue: any;
    Template: () => any;

    constructor(bindingParameters: any){
        const params = getDefaults();
        helpers.mergeDefaults(bindingParameters, params);
        params.isClickable = wrapObservable(params.isClickable);
        super(params);

        this.params = (params as any);
        const metadataSource = this.params.metadataSource || MDGenCreate(this.params.forceType);

        this.Data = ko.computed({
            read: () => {
                const v = koUtils.unpack(this.OriginalValue);
                return metadataSource.getMetadata(v);
            },
            owner: self,
            deferEvaluation: true
        });

        this.Template = () => {
            return (this.Data().link && this.params.isClickable()) ? this.params.template : this.params.templateRead;
        };
    }

    dispose(){
        this.Data.dispose();
        super.dispose();
    }
}

export function create(bindingParameters: any){
    return new EntityLink(bindingParameters);
}