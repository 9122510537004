/// <amd-module name="Core/Medius.Core.Web/Scripts/components/mention/editor/disabledMentionsEditor"/>
import { EditorBase, SuggestionPresentationMode } from "Core/Medius.Core.Web/Scripts/components/mention/editor/editorBase";
import { IMentionDisabledEditorOptions } from "Core/Medius.Core.Web/Scripts/components/mention/editor/IEditorOptions";
import {getPropertyTranslation, getFormattedLabelTranslation, getLabelTranslation} from "Core/Medius.Core.Web/Scripts/lib/globalization";

export class DisabledMentionsEditor extends EditorBase {
    public autocompleterDisablementReason: string;

    constructor(value: ko.Observable<string>, options: IMentionDisabledEditorOptions) {
        super(value, options);

        if (options.autocompleterDisablementReasonDocumentType) {
            const translatedType = getPropertyTranslation(options.autocompleterDisablementReasonDocumentType);
            this.autocompleterDisablementReason = getFormattedLabelTranslation(options.autocompleterDisablementReasonTranslationKey,
                [translatedType]);
        } else {
            this.autocompleterDisablementReason = getLabelTranslation(options.autocompleterDisablementReasonTranslationKey);
        }

    }

    protected onValidMentionFound(searchTerm: string) {
        this.suggestionsPresentationMode(SuggestionPresentationMode.ShowDisabled);
    }

    protected onMentionNotFound() {
        this.suggestionsPresentationMode(SuggestionPresentationMode.ShowNothing);
    }

    protected onInvalidMentionFound(searchTerm: string) {
        this.suggestionsPresentationMode(SuggestionPresentationMode.ShowDisabled);       
    }
}
