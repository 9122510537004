/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/Company/customActions"/>
import { AdminApplication, makeGenericSaveCall } from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/adminApp";
import { registerCustomSaveAction } from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/customActionRegistry";
import { AdministrationCompany} from "Core/Medius.Core.Web/Scripts/AdminPages/Company/General/general";
import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { error as notificationError } from "Core/Medius.Core.Web/Scripts/Medius/core/notification";

const companyEntityTypeFullName = "Medius.Core.Entities.Company";

function onCompanySave(adminApp: AdminApplication) {
    const companyGeneralTab = adminApp.Editor().EntityWithContext.TabModels.General as AdministrationCompany;
    
    if (!companyGeneralTab.Entity.IsVirtual() && isNullOrUndefined(companyGeneralTab.Entity.CountryCode())) {
        notificationError(getLabelTranslation("#Core/isNotCorrectCountryCode"));
        return;
    }

    if(companyGeneralTab.wasDeactivated()) {
        companyGeneralTab.openDialogAction();

        if (companyGeneralTab.actionResultSub === undefined) {
            companyGeneralTab.actionResultSub = companyGeneralTab.dialogActionResult.subscribe(
                (accepted: boolean) => {
                    if (accepted) {
                        adminApp.IsLoading(true);
                        makeGenericSaveCall(adminApp)
                            .always(() => { adminApp.IsLoading(false) });
                    }
                });
        }
        return;
    }

    adminApp.IsLoading(true);

    makeGenericSaveCall(adminApp)
        .always(() => { adminApp.IsLoading(false); });

}

export function register() {
    registerCustomSaveAction(companyEntityTypeFullName, onCompanySave);
}
