/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/metadata/resolvers/registry"/>
import * as resolversRegistry from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/collection";
import * as typeBoundResolverFactory from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/typeBound";

export function register(typeName: string, descriptor: any, priority?: number, dontInherit?: boolean) {
    const resolver = typeBoundResolverFactory.create({
        priority: priority || 1,
        type: typeName,
        resolve: descriptor,
        dontInherit: dontInherit
    });

    resolversRegistry.register(resolver);
}