/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/editors/localizer"/>

import * as _ from "underscore";


function toString(value: any) {
        if (_.isUndefined(value) || _.isNull(value))
            return null;
        return value;
    }

function fromString(value: any) {
        if (_.isUndefined(value) || _.isNull(value))
            return null;
        return value;
    }

export function create(){
    return {
        toString, fromString
    };
}