///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/export/async/limits" />
const quickOperationLimit = 1000;
const slowOperationLimit = 100000;

/* 
    How many entities for export is considered a quick operation.
    Such operation does not require progress bars or any other special UX treatment.
    */
export const quickExportLimit = quickOperationLimit; // 1 000

/*
    How many entities for export is considered a slow operation.
    Such operation requires progress bars or some other special UX treatment.
*/
export const slowExportLimit = slowOperationLimit; // 100 000

export function isQuickExportLimitMet(rowsCount: number) {
    return rowsCount <= quickOperationLimit;
}

export function isSlowExportLimitMet(rowsCount: number) {
    return rowsCount <= slowOperationLimit;
}