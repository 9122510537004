/// <amd-module name="Core/Medius.Core.Web/Scripts/customTabs/definitions/contracts/dataLoadingComponentFactory"/>

import * as React from 'react';
import { LoadingPanel } from "Core/Medius.Core.Web/Scripts/shared/components/loadingPanel";
import { translate } from 'Core/Medius.Core.Web/Scripts/lib/globalization';

const dataLoadingComponentFactory = (isDataLoading: boolean) => {
    return (element: React.ReactElement<HTMLDivElement>) => {
        let noDataElement: any = undefined;
        if(isDataLoading) {
            noDataElement = (<LoadingPanel />);
        } else {
            noDataElement =  (
                <div>{translate("#Core/kendo/dropdowns/noData")}</div>
              );
        }

        return React.cloneElement(element, { ...element.props }, noDataElement);
    };
};

export default dataLoadingComponentFactory;