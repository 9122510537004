/// <amd-module name="Core/Medius.Core.Web/Scripts/components/procurement/edit/editSlice"/>
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "Core/Medius.Core.Web/Scripts/shared/store/reduxStore";
import { CartDetails, CartLine } from "../cart/cart";
import { hasAddressNoChanges, compareCartLine, compareId } from "./hasUnsavedChangesHelpers";
import { isEqual } from "underscore";

type StartEditingPayload = {
    readonly detailsSnapshot: CartDetails;
    readonly cartLinesSnapshot: CartLine[];
    readonly taskId: string;
    readonly requisitionNumber: string;
    readonly locked: boolean;
    readonly lockedUserName?: string;
};

type UpdateSnapshotsPayload = {
    readonly detailsSnapshot: CartDetails;
    readonly cartLinesSnapshot: CartLine[];
};

type NavigateToParams = {
    readonly navigateToUrl: string | null;
    readonly navigateToAppName?: string | null;
    readonly type: "Hash" | "Url" | null;
}
    | { type: "GoBack" };

type EditState = {
    readonly isEditing: boolean;
    readonly taskId: string | null;
    readonly requisitionNumber: string | null;
    readonly showUserConfirmationDialog: boolean;
    readonly navigation: NavigateToParams;
    readonly detailsSnapshot: CartDetails | null;
    readonly cartLinesSnapshot: CartLine[] | null;
    readonly locked: boolean;
    readonly lockedUserName: string;
};

const initialNavigation: NavigateToParams = {
    navigateToUrl: null,
    navigateToAppName: null,
    type: null
};

const initialState: EditState = {
    isEditing: false,
    taskId: null,
    requisitionNumber: null,
    showUserConfirmationDialog: false,
    navigation: initialNavigation,
    detailsSnapshot: null,
    cartLinesSnapshot: null,
    locked: false,
    lockedUserName: null
};

const editSlice = createSlice({
    name: "edit",
    initialState,
    reducers: {
        startEditing: (state, action: PayloadAction<StartEditingPayload>) => {
            if (state.isEditing) return state;

            state.isEditing = true;
            state.navigation = initialNavigation;
            state.cartLinesSnapshot = action.payload.cartLinesSnapshot;
            state.detailsSnapshot = action.payload.detailsSnapshot;
            state.taskId = action.payload.taskId;
            state.requisitionNumber = action.payload.requisitionNumber;
            state.locked = action.payload.locked;
            state.lockedUserName = action.payload.lockedUserName;
        },
        updateSnapshots: (state, action: PayloadAction<UpdateSnapshotsPayload>) => {
            if (!state.isEditing) return;

            state.cartLinesSnapshot = action.payload.cartLinesSnapshot;
            state.detailsSnapshot = action.payload.detailsSnapshot;
        },
        stopEditing: () => {
            return initialState;
        },
        showUserConfirmationDialog: (state) => {
            state.showUserConfirmationDialog = true;
        },
        hideUserConfirmationDialog: (state) => {
            state.showUserConfirmationDialog = false;
            state.navigation = initialNavigation;
        },
        setNavigateToParams: (state, action: PayloadAction<NavigateToParams>) => {
            state.navigation = action.payload;
        }
    }
});

const hasUnsavedChanges = (details: CartDetails, detailsSnapshot: CartDetails, cartLines: CartLine[], cartLinesSnapshot: CartLine[]) => {
    const detailsNoChanges =
        detailsSnapshot === null ||
        (compareId(details.buyerRoleId, detailsSnapshot.buyerRoleId) &&
            hasAddressNoChanges(details.deliveryAddress, detailsSnapshot.deliveryAddress) &&
            details.deliveryDate === detailsSnapshot.deliveryDate &&
            details.entityViewId === detailsSnapshot.entityViewId &&
            compareId(details.goodsReceiptByRoleId, detailsSnapshot.goodsReceiptByRoleId) &&
            hasAddressNoChanges(details.invoiceAddress, detailsSnapshot.invoiceAddress) &&
            compareId(details.orderTypeId, detailsSnapshot.orderTypeId) &&
            isEqual(details.ourReferenceRole, detailsSnapshot.ourReferenceRole) &&
            details.shippingMarks === detailsSnapshot.shippingMarks
        );

    const cartLinesNoChanges =
        cartLinesSnapshot === null ||
        (cartLines.length === cartLinesSnapshot.length &&
            cartLines.every((line, idx) => compareCartLine(line, cartLinesSnapshot[idx])));

    return !detailsNoChanges || !cartLinesNoChanges;
};

const isEditingRequisitionSelector = (s: RootState) => s.procurement.edit.isEditing;
const editingRequisitionTaskIdSelector = (s: RootState) => s.procurement.edit.taskId;
const editRequisitionReqNumberSelector = (s: RootState) => s.procurement.edit.requisitionNumber;
const hasUnsavedChangesWhileEditingSelector = (s: RootState) => s.procurement.edit.isEditing
    && hasUnsavedChanges(
        s.procurement.cart.details,
        s.procurement.edit.detailsSnapshot,
        s.procurement.cart.cartLines,
        s.procurement.edit.cartLinesSnapshot
    );

const shouldGetUserConfirmationSelector = (s: RootState) =>
    s.procurement.edit.isEditing &&
    s.procurement.edit.showUserConfirmationDialog &&
    hasUnsavedChanges(
        s.procurement.cart.details,
        s.procurement.edit.detailsSnapshot,
        s.procurement.cart.cartLines,
        s.procurement.edit.cartLinesSnapshot);

const navigateToParamsSelector = (s: RootState) => s.procurement.edit.navigation;

const isRequisitionLockedInEditSelector = (s: RootState) => s.procurement.edit.isEditing && s.procurement.edit.locked;
const lockedByUserSelector = (s: RootState) => s.procurement.edit.lockedUserName;

const editReducerActions = editSlice.actions;
export {
    hasUnsavedChanges,
    editSlice,
    editReducerActions,
    hasUnsavedChangesWhileEditingSelector,
    navigateToParamsSelector,
    shouldGetUserConfirmationSelector,
    isEditingRequisitionSelector,
    editingRequisitionTaskIdSelector,
    editRequisitionReqNumberSelector,
    isRequisitionLockedInEditSelector,
    lockedByUserSelector
};