/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/dashboard/modals/newDashboard"/>
import { isEmptyString } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import { observable } from "knockout";
import * as $ from "jquery";

const defaultColumnsTotal = "3";

class NewDashboard {
    Name = observable("");
    ColumnsTotal = observable(defaultColumnsTotal);
    reset = () => {
        this.Name("");
        this.ColumnsTotal(defaultColumnsTotal);
    };
    create: () => void;

    constructor(framework: any) {
        this.create = () => {
            if (!isEmptyString(this.Name())) {
                framework.addDashboard();
                this.reset();
                $("#modal-dashboard-new").modal("hide");
            }
        };
    }
}

export function create(frameworkInstance: any) {
    return new NewDashboard(frameworkInstance);
}