/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/AuthorizationGroups/Tabs/hooks/useDidUpdate"/>
import { useRef, useEffect } from "react";

export function useDidUpdate(callback: () => any, conditions?: React.DependencyList): void {
    const hasMountedRef = useRef(false);
    useEffect(() => {
      if (hasMountedRef.current) {
        callback();
      } else{
        hasMountedRef.current = true;
      }
    }, conditions);
  }
