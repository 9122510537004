/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/lib/ajax"/>

import * as _ from "underscore";
import * as $ from "jquery";
import * as appConfig from "Core/Medius.Core.Web/Scripts/appConfig";
import * as antiForgeryToken from "Core/Medius.Core.Web/Scripts/Medius/core/antiForgeryToken";
import { assertNoISOFormatDates } from "Core/Medius.Core.Web/Scripts/lib/development/noISODatesValidator";
import { toAction } from "Core/Medius.Core.Web/Scripts/Medius/lib/path";
import { warn, error } from "Core/Medius.Core.Web/Scripts/Medius/lib/logger";
import { getCurrent, getLanguageCode } from "Core/Medius.Core.Web/Scripts/Medius/lib/utils/user";
import { fromJSON } from "Core/Medius.Core.Web/Scripts/Medius/lib/serialization/extended";
import * as sessionInformation from "Core/Medius.Core.Web/Scripts/Medius/session/information";
import { BreakablePipe, PipedDeferred, create as createBreakablePipe } from "Core/Medius.Core.Web/Scripts/Medius/lib/utils/breakablePipeFactory";
import { validateVersionFromHeaderJquery } from "Core/Medius.Core.Web/Scripts/lib/validation/validators/versionValidator";

const aborted = "aborted:";

let shouldBreakAllPipes = false;
let pendingAjaxPipes: { [key: string]: BreakablePipe } = {};

function breakAllPipes() {
    _(pendingAjaxPipes).each(pending =>
        pending.breakPipe());

    shouldBreakAllPipes = true;
}

/**
* Performs ajax call with default handlers.
*
* This is a wrapper over {@link jQuery.ajax} function. It adds some
* default logging handlers that print the ajax request details on
* the console.
*
* @param    uri     uri to which request should be performed
* @param    options options passed to jQuery.ajax function
* @param    signal  optional signal from an AbortController
* @returns  {PipedDeferred} piped wrapper over {@link JQueryDeferred<T>}
*
* @example
* var uri = "http://example.org/myController/myAction";
* ajax(uri, {
*   cache:          false,
*   dataType:       'json',
*   contentType:    'application/json; charset=utf-8'
* }).done(alert("Request to {0} was successful.".format(uri))
*   .fail(alert("Problem while requesting {0}.".format(uri))
*/
export function ajax(uri: string, options?: JQueryAjaxSettings, signal?: AbortSignal): JQueryDeferred<any> {
    let stillPending = true;
    const ajaxId = _.uniqueId("ajax_id_");

    const defaultSettings: JQueryAjaxSettings = {
        dataType: "json",
        contentType: "application/json; charset=utf-8",
        beforeSend(request: JQueryXHR, settings: JQueryAjaxSettings) {
            const logWarn = () =>
                warn("Could not add Http Cache Context headers to request with method"
                    + ` - '${settings?.type}' and uri - '${uri}'`);

            const currentUser = getCurrent();
            if (!currentUser) {
                logWarn();
                return;
            }

            let appsHash: string;
            try {
                appsHash = appConfig.getInstalledAppsHash();
            } catch (ex) {
                logWarn();
                return;
            }

            const userContext = `Language: ${getLanguageCode()}, Id: ${currentUser.Id}`;
            request.setRequestHeader("X-User-Context", userContext);
            request.setRequestHeader("X-Installed-Applications", appsHash);

            if (signal) {
                signal.addEventListener("abort", function () {
                    const breakablePipe = pendingAjaxPipes[ajaxId];
                    request.abort(aborted + this.reason);
                    breakablePipe?.breakPipe();
                });
            }
        },
        converters: {
            // Note: Following converter is used e.g. to convert dates from the server, returned
            //       in the "/Date(253370764800000)/" format. Removing line below causes (e.g.)
            //       bad rendering of dates in admin pages - check Start Date for currency rates.
            'text json': fromJSON
        }
    };

    options = _.extend(defaultSettings, options);
    antiForgeryToken.addToHeaders(options);
    const requestDate = new Date();

    const sourceDeferred = $.ajax(uri, options)
        .done((data: any, textStatus: string, jqXHR: JQueryXHR) => {
            validateVersionFromHeaderJquery(jqXHR, uri, requestDate);
        })
        .fail((xhr: JQueryXHR, textStatus: string, errorThrown) => {
            if (textStatus?.startsWith(aborted))
                return;

            error(`${options?.type || "GET"} request to ${uri} failed with error: ${errorThrown}`);

            if (xhr.status === 401) {
                const frm = (window.frames as any).logoutFrm;
                breakAllPipes();
                if (frm)
                    frm.location = toAction("Index", "~/TokenRenewal");
            }

            if (xhr.status === 400 && xhr.statusText === "AntiForgery validation failed") {
                breakAllPipes();
                location.reload();
            }
        })
        .always(_(sessionInformation.updateSessionInformation).bind(sessionInformation) as any)
        .always(() => {
            stillPending = false;
            delete pendingAjaxPipes[ajaxId];
        });

    sourceDeferred.then(response => {
        assertNoISOFormatDates(response);
    });

    const breakablePipe = createBreakablePipe(sourceDeferred);

    if (stillPending)
        pendingAjaxPipes[ajaxId] = breakablePipe;

    const pipedDeferred: PipedDeferred<any> =
        // Node: This is unnecessarily "typed". This is "the only" use
        //       of `PipedDeferred` to silence linter, as it does not
        //       look apparently into the documentation comment above.
        breakablePipe.getPipedDeferred();

    if (shouldBreakAllPipes)
        breakablePipe.breakPipe();

    // Note: Let's not spread our old "contraption-of-pipes" over our codebase more.
    //       The "true" returned object is an instance of `PipedDeferred<any>`, but
    //       as mentioned in documentation comment above, this is just an extension
    //       over the more familiar type `JQueryDeferred<any>`, returned here:
    return pipedDeferred as JQueryDeferred<any>;
}

export function restore() {
    shouldBreakAllPipes = false;
    pendingAjaxPipes = {};
}
