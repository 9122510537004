///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/resolver/component"/>

import * as _ from "underscore";

const map = {
    "text": [
        "System.String",
        "System.Enum"
    ],
    "bool": [
        "System.Boolean"
    ],
    "bool-nullable": [
        "System.Nullable<System.Boolean>"
    ],
    "timespan": [
        "System.TimeSpan"
    ],
    "amount": [
        "Medius.Core.Entities.Amount",
        "Medius.Core.DTOs.AmountDto"
    ],
    "quantity": [
        "Medius.Core.Entities.Quantity",
        "Medius.Core.DTOs.QuantityDto"
    ],
    "percentage": [
        "Medius.Core.Entities.Percentage",
        "Medius.Core.DTOs.PercentageDto"
    ],
    "unitprice": [
        "Medius.Core.DTOs.UnitPriceDto"
    ],
    "currencyRate": [
        "Medius.Core.DTOs.CurrencyRateDto"
    ],
    "number": [
        "System.Int16",
        "System.Int32",
        "System.Int64",
        "System.UInt16",
        "System.UInt32",
        "System.UInt64",
        "System.Decimal",
        "System.Double",
        "System.Single"
    ],
    "date": [
        "System.DateTime"
    ]
};

const mappedDTO = {}; //key, value pairs are reversed

const keys = _.keys(map);
_(keys).each(function (key) {
    _((map as any)[key]).each(function (type) {
        register(type, key);
    });
});


export function register(key: string, value: string){
    (mappedDTO as any)[key] = value;
}

export function resolve(key: string) {
    return mappedDTO[key as keyof typeof mappedDTO] || "autocompleter-entity";
}