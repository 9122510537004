///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/administration/bulk/wizard"/>
import * as _ from "underscore";
import * as confirmStep from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/bulk/steps/confirm";
import * as editStep from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/bulk/steps/edit";
import * as resultStep from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/bulk/steps/result";
import { AbstractWizard } from "Core/Medius.Core.Web/Scripts/Medius/components/wizard/wizard";
import { Observable, Computed, Subscription, observable, computed } from "knockout";

const defaults = {
    template: "Core:Medius/components/wizard/wizard.html",
    onClose: function () {
    },
    onCancel: function () {
    }
};

class BulkAdminWizard extends AbstractWizard {
    entitiesIds: any;
    entityType: any;
    entityId: any;
    modificationsContainer: any[];
    navigationMethod: any;
    entityFilter: any;
    shouldApplyOnAll: any;
    companyId: any;
    DefaultViewModel: { EntityType: any; EntitiesIds: any; ModificationsContainer: any[]; NavigationMethod: any; EntityFilter: any; ShouldApplyOnAll: any; CompanyId: any; };
    skipNextStep: boolean;
    options: any;
    isCurrentStepFullyCustom: any;
    isLoading: any;
    isVisible: Observable<boolean>;
    steps: any[];
    totalStepsCss: string;
    maxStepIndex: number;
    currentStepIndex: Observable<number>;
    currentStep: Computed<any>;
    currentStepName: Computed<any>;
    data: any;
    instanceObservable: any;
    isVisibleSub: Subscription;

    constructor(entities: any, options: { EntityKey: () => any; NavigationMethod: any; EntityFilter: any; ShouldApplyOnAll: any; CompanyId: any; }) {
        super();
        const entityKey = options.EntityKey(),
            navigationMethod = options.NavigationMethod,
            entityFilter = options.EntityFilter,
            shouldApplyOnAll = options.ShouldApplyOnAll,
            companyId = options.CompanyId;

        this.entitiesIds = entities;
        this.entityType = entityKey.Name;
        this.entityId = entityKey.Id;
        this.modificationsContainer = [];
        this.navigationMethod = navigationMethod;
        this.entityFilter = entityFilter;
        this.shouldApplyOnAll = shouldApplyOnAll;
        this.companyId = companyId;

        this.DefaultViewModel = {
            EntityType: this.entityType,
            EntitiesIds: this.entitiesIds,
            ModificationsContainer: this.modificationsContainer,
            NavigationMethod: this.navigationMethod,
            EntityFilter: this.entityFilter,
            ShouldApplyOnAll: this.shouldApplyOnAll,
            CompanyId: this.companyId
        };

        const steps = [
            editStep.create(this),
            confirmStep.create(this),
            resultStep.create()
        ];
        this.skipNextStep = false;
        this.options = _.extend({}, defaults, options);
        this.isCurrentStepFullyCustom = observable(false);
        this.isLoading = observable(false);
        this.isVisible = observable(true);
        this.steps = steps;
        this.totalStepsCss = "steps-" + steps.length;
        this.maxStepIndex = steps.length - 1;
        this.currentStepIndex = observable(-1);
        this.currentStep = computed(() => {
            if (this.currentStepIndex() === -1)
                return null;
            return steps[this.currentStepIndex()];
        });

        this.currentStepName = computed(() => {
            return (this.currentStep()) ? this.currentStep().name : null;
        });
        this.data = {};
        this.instanceObservable = null;

        this.isVisibleSub = this.isVisible.subscribe((newValue) => {
            if (newValue === false) {
                this.cancel();
            }
        });

        this.init();
    }
}

export function create(entities: any, options: { EntityKey: () => any; NavigationMethod: any; EntityFilter: any; ShouldApplyOnAll: any; CompanyId: any; }) {
    return new BulkAdminWizard(entities, options);
}