/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/default/text/entity/company"/>
import {unpack} from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as typeBoundResolverFactory from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/typeBound";
import * as _ from "underscore";
import { isEmptyString } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";

export const resolver = typeBoundResolverFactory.create({
    priority: 0,

    type: "Medius.Core.Entities.Company",

    resolve: function(data: any) {
        const name = unpack(data.Name),
            companyId = unpack(data.CompanyId),
            identifierValues = _(data.CompanyIdentifiers).map(function (companyIdentifier) {
                return companyIdentifier.Identifier;
            });
        identifierValues.unshift(companyId);
        const secondPart = identifierValues.join(', ');

        return {
            text: !isEmptyString(secondPart) ? `${name} (${secondPart})` : name
    };
    }
});
