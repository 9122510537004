/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/grid/default/ColumnPickerTemplate"/>
import * as React from "react";
import { Button, Callout, ContentArea, Text, Switch, CheckboxGroup, ContentDivider, ButtonGroup, Checkbox, IconDesignSquareSplitHorizontalFill, IconArrowsChevronDownRegular } from "@medius/ui-controls";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization"; 

interface ColumnPickerProps {
    toggleColumnSelector: (visibility:boolean) => void;
    columns: Array<{name:string, selectable:boolean, visible:boolean}>,
    toggleAllColumns: (switchState:boolean) => void,
    toggleColumn: (columnName:string) => void,
    allColumnsVisible: boolean
}

export function ColumnPickerTemplate({toggleColumnSelector, columns, toggleAllColumns, toggleColumn, allColumnsVisible}:ColumnPickerProps) {
    const pickerButtonRef = React.useRef<HTMLButtonElement>(null);
    const [pickerVisibleState, setPickerVisibleState] = React.useState(false);
    return (<>
        <div className="columnPickerButtonContainer">
            <Button 
                data-testid="btn-toggle-column-selector"
                hideLabel
                ref = {pickerButtonRef}
                icon={<><IconDesignSquareSplitHorizontalFill /><IconArrowsChevronDownRegular/></>}
                label={getLabelTranslation("#Core/columnConfigBtn")}
                variant="secondary"
                onClick={() => {
                  const newState = !pickerVisibleState;
                  toggleColumnSelector(newState);
                  setPickerVisibleState(newState);
                }}
                height="small"/>
        </div>
        <Callout
            className="columnPickerDropdownContainer"
            displayTargetRef = {pickerButtonRef}
            isOpen = {pickerVisibleState}
            position="bottom-end"
            onCloseRequested = {() => {
              toggleColumnSelector(false);
              setPickerVisibleState(false);
            }}>
            <ContentArea spacerMode="on">
              <Text variant="heading3">{getLabelTranslation("#Core/selectColumns")}</Text>
            </ContentArea>
            <ContentArea spacerMode="on">
                <Switch
                  width="fill"
                  leftLabel={getLabelTranslation("#Core/allColumns")}
                  value={allColumnsVisible}
                  onChange={(e) => toggleAllColumns(e.target.value)}/>
              </ContentArea>
            <ContentArea spacerMode="on">
             <div className="columnPickerDropdownContainer__columnChecklist">
                 <CheckboxGroup data-testid="columns-container" layout="column">
                   {columns.map((column, key) => {
                      if(column.selectable) {
                        return <Checkbox data-testid={`select-column-${column.name}`} key={key} checked={column.visible} label={column.name} onChange={() => {toggleColumn(column.name);}}/>;
                      }
                    })}
                 </CheckboxGroup>
              </div>
              <ContentDivider />
            </ContentArea>
            <ContentArea addBottomSpacer>
              <ButtonGroup width="fill" alignment="right">
                <Button label={getLabelTranslation("#Core/clear")} variant="inline" height="small" onClick={() => toggleAllColumns(false)}/>
              </ButtonGroup>
            </ContentArea>
        </Callout>
        </>
    );
}