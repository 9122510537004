///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/context/disposal"/>
const CONTEXT_DISPOSALS = "viewmodel-context-disposals";

export = function contextDisposalDecorator(context:any) {
    const disposals = context.ensure(CONTEXT_DISPOSALS, []);

    context.disposal = {
        register: function registerContextDisposal(disposeCallback:any) {
            context.get(CONTEXT_DISPOSALS).push(disposeCallback);
        },
        run: function runContextDisposal() {
            let disposal:any;
            for (let i  = disposals.length - 1; i >= 0; i--) {
                disposal = disposals[i];
                disposal();
            }

            context.set(CONTEXT_DISPOSALS, []);
        }
    };
};