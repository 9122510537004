/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/inbox/handlers/selectedTasks"/>
import { observable, observableArray, pureComputed } from "knockout";
import * as _ from "underscore";

class SelectedTasks {
    public inbox: any;
    public tasks: ko.ObservableArray<any> = observableArray([]);
    public total = observable(0);
    public isInDocumentMode = observable(false);
    public documentIds: ko.ObservableArray<number> = observableArray([]);
    public totalDocuments = pureComputed(() => this.documentIds().length);
    public koSubs: any[] = [];
    public hasTasks = pureComputed(() => this.total() > 0);

    constructor(inbox: any) {
        this.inbox = inbox;
    }

    public init() {
        this.disposeSubs();

        const calculateTotal = () => {
            this.total(this.inbox.fullscreenEnabled() ? totalSaved() : totalSelected());
        };

        const totalSaved = pureComputed(() => this.tasks().length);

        const totalSelected = pureComputed<number>(() => {
            return this.inbox.folders()
                .map((fldr: any) => fldr.typeGroups()) // map
                .reduce((acc: any[], typeGroups: any[]) => acc.concat(typeGroups), []) // flatten
                .map((group: any) => group.grid())
                .filter((grid: any) => !!grid)
                .reduce((acc: number, grid: any) => acc + grid.TotalSelectedRows(), 0);
        });

        this.koSubs.push(totalSaved.subscribe(calculateTotal));
        this.koSubs.push(totalSelected.subscribe(calculateTotal));
    }

    public restoreSelection(selectedTasks: any) {
        const selected: any[] = [];

        this.inbox.folders()
            .map((fldr: any) => fldr.typeGroups()) // map
            .reduce((acc: any[], typeGroups: any[]) => acc.concat(typeGroups), []) // flatten
            .forEach((group: any) => {
                const grid = group.grid();
                const rows = grid ? grid.Rows() : [];

                if (rows.length === 0) {
                    return;
                }

                rows.forEach((row: any) => {
                    if (_(selectedTasks).contains(row)) {
                        grid.markRow(row.id, true);
                        selected.push(row);
                    }
                });
            });

        this.tasks(selected);
    }

    public saveTasks() {
        const selected: any[] = [];

        this.inbox.folders()
            .map((fldr: any) => fldr.typeGroups()) // map
            .reduce((acc: any[], typeGroups: any[]) => acc.concat(typeGroups), []) // flatten
            .forEach((group: any) => {
                const grid = group.grid();
                const ids = grid ? grid.SelectedIds() : [];

                if (ids.length === 0) {
                    return;
                }

                group.visibleTasks().forEach((task: any) => {
                    if (_(ids).contains(task.id) && task.visible()) {
                        selected.push(task);
                    }
                });
            });

        this.tasks(selected);
    }

    public saveDocuments(documentIds: number[]) {
        this.isInDocumentMode(true);
        this.documentIds(documentIds);
    }

    public removeTaskFromSavedTasks(task: any) {
        this.tasks.remove(task);
    }

    public removeDocument(documentId: number) {
        this.documentIds.remove(documentId);
    }

    public clear() {
        this.tasks([]);
    }

    public dispose() {
        this.disposeSubs();
        this.hasTasks.dispose();
    }

    public disposeSubs() {
        this.koSubs.forEach((sub: ko.Subscription) => {
            sub.dispose();
        });
        this.koSubs = [];
    }
}

export function create(inbox: any) {
    const handler = new SelectedTasks(inbox);
    handler.init();
    return handler;
}
