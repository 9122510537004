/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/knockout/extenders/numeric/integer"/>

import * as ko from "knockout";

function throwInvalidValueException(value: any) {
    throw "Observable was set with - '" + value + "' " +
        "but accepts numbers or stringified integers only.";
}

export function addIntegerKnockoutExtender() {
    ko.extenders.integer = function (target) {
        const result = ko.computed({
            read: target,
            write: function (newValue) {
                let value = newValue;
    
                if (typeof newValue === "number") {
                    value = Math.round(newValue);
                } else if (typeof newValue === "string") {
                    value = parseInt(newValue, 10);
                    if (isNaN(value)) {
                        throwInvalidValueException(newValue);
                    }
                } else {
                    throwInvalidValueException(newValue);
                }
                
                target(value);
            } 
        });
    
        return result;
    };
}

