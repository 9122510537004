/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/simplePresenter"/>

import {isNullOrUndefined} from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import {unwrap} from "knockout";
import * as metadataGeneratorFactory from "./generator";

function invokePresenter(objects: any[], present: (obj: any) => any) {
    let objectPresentation;
    if (objects.length === 1) {
        if (objects[0] instanceof Array) {
            objectPresentation = present(objects[0]);
            return objectPresentation;
        } else {
            objectPresentation = present(objects);
            return objectPresentation[0];
        }
    } else {
        objectPresentation = present(objects);
        return objectPresentation;
    }
}

function innerPresentMetadata(objects: any[]) {
    const metadataPresentation = objects.map((object) => {
        if (isNullOrUndefined(object) || isNullOrUndefined(object.$type)) {
            return object;
        } else {
            const objectType = unwrap(object.$type).split(", ")[0];
            const metadataGenerator = metadataGeneratorFactory.create(objectType);
            return metadataGenerator.getMetadata(object);
        }
    });

    return metadataPresentation;
}

function innerPresentObjects(objects: any[]) {
    const metadataPresentation = innerPresentMetadata(objects);
    const objectPresentations = metadataPresentation.map((presentation) => {
        if (presentation && presentation.hasOwnProperty("text")) {
            return presentation.text;
        } else {
            return presentation;
        }
    });

    return objectPresentations;
}


export function present(...args: any[]) {
    return invokePresenter(args, innerPresentObjects);
}

export function presentMetadata(...args: any[]) {
    return invokePresenter(args, innerPresentMetadata);
}
