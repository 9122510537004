///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/components/bindings"/>
import * as componentUtils from "Core/Medius.Core.Web/Scripts/Medius/components/utils";
import * as accessUtils from "Core/Medius.Core.Web/Scripts/Medius/components/components/utils";

const configuration = {
    "amount": {
        defaults: {
            template: "components-finance-component-default"
        }
    },
    "unitprice": {
        defaults: {
            template: "components-unitprice-default"
        }
    },
    "quantity": {
        defaults: {
            template: "components-quantity-default"
        }
    },
    "percentage": {
        defaults: {
            template: "components-percentage-default"
        }
    },
    "bool": {
        defaults: {
            template: "components-bool-default"
        }
    },
    "date": {
        defaults: {
            template: "components-date-default"
        }
    },
    "timespan": {
        defaults: {
            template: "components-timespan-default"
        }
    },
    "number": {
        defaults: {
            template: "components-number-default"
        }
    },
    "text": {
        defaults: {
            template: "components-text-default"
        }
    },
    "entity": {
        defaults: {
            template: "components-entity-default"
        }
    }
};

export function register(){
    componentUtils.registerBindings(configuration, accessUtils);
}