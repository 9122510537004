/// <amd-module name="Core/Medius.Core.Web/Scripts/components/invoice/reasonCodes/service"/>
import * as rest from "Core/Medius.Core.Web/Scripts/Medius/core/fetch/rest";
import { ReasonCode, AddedReasonCode, AddedReasonCodesState } from "./types";

function forDocument(viewId: string) {
    return `document/${viewId}/reason-code`;
}

function withCompany(resource: string, companyId?: number) {
    return companyId > 0
        ? `${resource}?companyId=${companyId}`
        : resource;
}

export function getAvailable(companyId: number) {
    return rest.get<ReasonCode[]>(`document/${companyId}/reason-codes`);
}

export function getFor(viewId: string, companyId?: number, abortSignal?: AbortSignal) {
    let url = forDocument(viewId);
    url = withCompany(url, companyId);

    const data = { signal: abortSignal };
    return rest.get<AddedReasonCodesState>(url, data);
}

export function addTo(viewId: string, reasonCodeId: number, companyId?: number, abortSignal?: AbortSignal) {
    let url = forDocument(viewId);
    url = withCompany(url, companyId);

    return rest.post<AddedReasonCode>(`Backend/Rest/${url}`, {
        reasonCodeId: reasonCodeId,
        signal: abortSignal
    });
}

export function removeFrom(viewId: string, addedReasonCodeId: number, companyId?: number) {
    let url = forDocument(viewId);
    url = `${url}/${addedReasonCodeId}`;
    url = withCompany(url, companyId);

    return rest.del<void>(`Backend/Rest/${url}`);
}
