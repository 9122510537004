/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/PaymentTerm/backendController"/>
import * as rest from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rest";
import { CustomFields } from "./customFields";
import { Company } from "./company";

export interface PaymentTermGeneralRequest {
    id: number;
    paymentTermId: string;
    name: string;
    description: string;
    externalSystemId: string;
    company_id: number;
    active: boolean;
    lines: PaymentTermLineDto[];
}

export interface PaymentTermRequest extends PaymentTermGeneralRequest {
    customFields: CustomFields;
}

export interface PaymentTermResponse {
    id: number;
    paymentTermId: string;
    name: string;
    description: string;
    externalSystemId: string;
    company: Company;
    active: boolean;
    startDate: PaymentTermStartDateDto;
    lines: PaymentTermLineDto[];
    erpSourceId: string;
    importedTimestamp: Date | null;
    customFields: CustomFields;
}

export interface PaymentTermStartDateDto {
    type: string;
    fixedDay: number;
    numberOfMonths: number;
}

export interface PaymentTermLineDto {
    numberOfDays: number;
    numberOfMonths: number;
    fixedDay: number;
    endOfMonth: boolean;
    cashDiscount: number;
}

const baseUrl = "payment-term";

export class BackendController {
    public static get(id: number): JQueryPromise<PaymentTermResponse> {
        return id === 0
            ? $.when(BackendController.defaults())
            : rest.get(baseUrl, id);
    }

    public static save(paymentTerm: PaymentTermRequest): JQueryPromise<EntityId> {
        return paymentTerm.id === 0
            ? rest.post(baseUrl, "", paymentTerm)
            : rest.put(baseUrl, paymentTerm.id, paymentTerm);
    }

    public static defaults(): PaymentTermResponse {
        const defaultTermLine: PaymentTermLineDto = {
            numberOfDays: 0,
            numberOfMonths: 0,
            fixedDay: null,
            endOfMonth: false,
            cashDiscount: 0
        };

        return {
            id: 0,
            paymentTermId: "",
            name: "",
            description: "",
            externalSystemId: "",
            company: null,
            active: true,
            startDate: {} as PaymentTermStartDateDto,
            lines: [defaultTermLine],
            erpSourceId: "",
            importedTimestamp: null,
            customFields: {} as CustomFields
        };
    }
}
