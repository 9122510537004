/// <amd-module name="Core/Medius.Core.Web/Scripts/components/mention/editor/bindings/cursorPosition"/>
// The same file exists in mobile app: mfxi\Mediusflow\Frontend\Web\mobile\src\core\ui\customBindings\cursorPosition.ts
// Consider changing the other one while doing changes in this one.

import * as ko from "knockout";
import * as $ from "jquery";
import { addDisposeCallback, registerBinding } from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";

const cursorPosition = {
    init(element: HTMLTextAreaElement, valueAccessor: any) {
        const value = valueAccessor() as ko.Observable<number>;

        let recentCursorPosition = element.selectionEnd;

        function tryUpdateCursorPosition() {
            if (recentCursorPosition !== element.selectionEnd) {
                recentCursorPosition = element.selectionEnd;
                value(element.selectionEnd);
            }
        }

        $(element)
            .on("keyup", tryUpdateCursorPosition)
            .on("mouseup", tryUpdateCursorPosition);

        addDisposeCallback(element, () => {
            $(element)
                .off("keyup", tryUpdateCursorPosition)
                .off("mouseup", tryUpdateCursorPosition);
        });
    },

    update(element: HTMLTextAreaElement, valueAccessor: any) {
        const value = ko.unwrap(valueAccessor());

        if (element.selectionStart !== element.selectionEnd) return;

        // This is done because Safari forces element to gain focus when selection is modified.
        // Then for ExpenseInvoice that has comments in Coding and Comments tabs the latter always stole focus from the first one.
        if (!$(element).is(":focus")) return;

        element.selectionStart = value;
        element.selectionEnd = value;
    }
};

registerBinding("cursorPosition", cursorPosition);
