///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/utils"/>
import * as _ from "underscore";

function mergeFromLeftToRight(...args:any[]) {
    const result:any = {};

    args.forEach(options => {
        for (const name in options) {
            if (Object.prototype.hasOwnProperty.call(options, name)) {
                //we need to skip options with undefined value, e.g. when options.optionName = undefined;
                //property is defined but should be skiped
                if(_.isUndefined(options[name])) {
                    continue;
                }

                result[name] = options[name];
            }
        }
    });

    return result;
}

export function createParameters(bindingParameters:any, optionsExtension:any, providerOptionsExtensions:any, defaultsFactory:any, providerFactory:any) {
    const defaults = defaultsFactory.create();
    const options = mergeFromLeftToRight(defaults.options, optionsExtension, bindingParameters.options);
    const dataProviderOptions = mergeFromLeftToRight(defaults.dataProviderOptions, providerOptionsExtensions, bindingParameters.dataProviderOptions);

    const params:any = mergeFromLeftToRight(defaults, {
        value: bindingParameters.value,
        hasFocus: bindingParameters.hasFocus,
        context: bindingParameters.context,
        resultsTemplate: bindingParameters.resultsTemplate,
        resultItemTemplate: bindingParameters.resultItemTemplate,
        template: bindingParameters.template,
        dataProvider: bindingParameters.dataProvider || providerFactory.create(),
        options: options,
        dataProviderOptions: dataProviderOptions,
        onSelectedHandler: bindingParameters.onSelectedHandler
    });
        
    return params;
}