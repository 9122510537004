/// <amd-module name="Core/Medius.Core.Web/Scripts/components/topBar/drawer/drawerWithStore"/>
import * as React from "react";
import Drawer from "./Drawer";
import { Provider } from "react-redux";
import { store } from "Core/Medius.Core.Web/Scripts/shared/store/reduxStore";
import { LocalizationProvider } from "@progress/kendo-react-intl";

export function DrawerWithStore() {
    return (
        <LocalizationProvider language="current-language">
            <Provider store={store}>
                <Drawer />
            </Provider>
        </LocalizationProvider>
    );
}