/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/AuthorizationGroups/Tabs/dimensionLimitTab"/>
import { SortDescriptor } from "@progress/kendo-data-query";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as React from "react";
import { useContext } from "react";
import { DimensionValuePermission } from "../authorizationGroup";
import { CompanyContext } from "../contexts/companyContext";
import DimensionLimitGrid from "./components/dimensionLimitGrid";

type DimensionLimitsTabProps = {
    readonly data: DimensionValuePermission[];
    readonly onChange: (newPermissions: DimensionValuePermission[]) => void;
    readonly sort: SortDescriptor[];
    readonly onSortingChange: (newSorting: SortDescriptor[]) => void;
};

export function DimensionLimitsTab({ data, onChange, sort: sort, onSortingChange }: DimensionLimitsTabProps) {
    const companyContext = useContext(CompanyContext);
    const isCompanySelected = companyContext.companyId > 0;

    return isCompanySelected
        ? <DimensionLimitGrid dimensionLimits={data}
            dimensions={companyContext.dimensions}
            onChange={onChange} sort={sort}
            onSortingChange={onSortingChange} />
        : <div>{translate("#Enterprise/authorizationGroupsTabsSelectCompany")}</div>;
}
