///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/bindings/componentsContext"/>
import { BindingHandler, observable, applyBindingsToDescendants } from "knockout";
import { addDisposeCallback, registerBinding } from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as _ from "underscore";

const binding: BindingHandler = {
    init: function(element, bindingAccessor, allBindingsAccessor, viewModel, bindingContext) {
        let viewModelContext = bindingAccessor();

        if (!viewModelContext) {
            throw new Error("Context has not been provided");
        }

        let newKnockoutContext = _(bindingContext).extend({
            components: {
                context: viewModelContext,
                queue: viewModelContext.queue,
                isReadOnlyView: (bindingContext && bindingContext.$isReadOnlyView) ? bindingContext.$isReadOnlyView : observable(false)
            }
        });

        addDisposeCallback(element, function() {
            newKnockoutContext.components = {
                context: null,
                queue: null,
                isReadOnlyView: null
            };
            newKnockoutContext = null;
            viewModelContext = null;
        });

        applyBindingsToDescendants(newKnockoutContext, element);

        return { controlsDescendantBindings: true };
    }
};

export function registerComponentsContextBinding() {
    registerBinding("componentContext", binding);
}

