/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/MasterDataReindex/masterDataReindex"/>
import * as React from "react";
import { useState, useEffect } from "react";
import { registerAdministrationEntity } from "Core/Medius.Core.Web/Scripts/AdminPages/administrationServices";
import { ComboBox, ComboBoxChangeEvent, ComboBoxFilterChangeEvent } from "@progress/kendo-react-dropdowns";
import { SidebarNavigation } from "Core/Medius.Core.Web/Scripts/AdminPages/sidebarNavigation";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { Button } from "@progress/kendo-react-buttons";
import { Input, RadioButton } from "@progress/kendo-react-inputs";
import { getStatus, getCompanies, reindexCompany, getReindexHistoryLogs, IReindexHistoryLogDto } from "./masterDataReindexServices";
import { Card, CardTitle, CardBody, Splitter, SplitterPaneProps } from "@progress/kendo-react-layout";
import { DatePresenter } from "Core/Medius.Core.Web/Scripts/shared/components/datePresenter";
import {isNullOrUndefined} from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import {NoAccess} from "Core/Medius.Core.Web/Scripts/Medius/apps/noAccess/noAccess";
import {RootState} from "Core/Medius.Core.Web/Scripts/shared/store/reduxStore";
import {useSelector} from "react-redux";
import { LocalizationProvider } from "@progress/kendo-react-intl";
import { formatValueWithPlaceholders } from "../../Medius/lib/stringFormat";

interface Company {
    id: number;
    companyName: string;
    companyId: string;
    externalSystemId: string;
    parentCompanyName: string;
    parentCompanyCompanyId: string;
    isActive: boolean;
    isVirtual: boolean;
}

function MasterDataReindexPage({ children }: {children: React.ReactNode}) {
    const hasAccess = useSelector((state: RootState) => state.accesses.masterDataReindex);

    if(!hasAccess) {
        return(<NoAccess/>);
    }
    return (
        <>
            <SidebarNavigation />
            <div className="master-data-reindex">
                {children}
            </div>
        </>);
}

export function MasterDataReindex() {
    const [state, setState] = useState({
        data: [] as Company[],
        value: null as Company,
        filter: ''
    });

    const [historyLogs, setHistoryLogs] = useState({
        data: [] as IReindexHistoryLogDto[]
    });

    const [isLoading, setIsLoading] = useState(true);
    const [inProgress, setInProgress] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [reindexApproach, setReindexApproach] = useState("0");


    const [newCompanyId, setNewCompanyId] = useState("");

    const [panes, setPanes] = useState<SplitterPaneProps[]>([{ size: '50%' }, { scrollable: false }]);

    useEffect(() => {
        getStatus()
            .then(({ reindexInProgress, errorMessage }) => {
                setInProgress(reindexInProgress);
                setErrorMessage(errorMessage);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    useEffect(() => {
        getReindexHistoryLogs()
            .then((reindexHistoryLogs) => {
                setHistoryLogs((x) => ({ ...x, data: reindexHistoryLogs.results, total: reindexHistoryLogs.total }));
            });
    }, []);

    useEffect(() => {
        requestData(state.filter);
    }, [state.filter]);

    const requestData = (searchPhrase: string) => {
        getCompanies(searchPhrase)
            .then((response) => {
                setState((x) => ({ ...x, data: response.results, total: response.total }));
            });
    };

    const onFilterChange = (event: ComboBoxFilterChangeEvent) => {
        const filter = event.filter.value;
        setState(x => ({ ...x, filter }));
    };

    const onChange = (event: ComboBoxChangeEvent) => {
        const value = event.target.value;
        setState(x => ({
            ...x, value
        }));
        setNewCompanyId('');
    };

    const onReindex = () => {
        setInProgress(true);
        reindexCompany(state.value.id, newCompanyId, Number(reindexApproach));
    };

    const changeReindexApproach = React.useCallback(
        (e: any) => {
            setReindexApproach(e.value);
        },
        [setReindexApproach]
    );

    if (isLoading) {
        return (
            <MasterDataReindexPage>
                <h2 className="administration-page-header">{translate("#Core/masterDataReindex")}</h2>
                <div>{translate("#Core/loading")}</div>
            </MasterDataReindexPage>);
    }

    if (inProgress) {
        return (
            <MasterDataReindexPage>
                <h2 className="administration-page-header">{translate("#Core/masterDataReindex")}</h2>
                <div>{translate("#Core/reindexInProgress")}</div>
            </MasterDataReindexPage>);
    }
    
    return (
        <MasterDataReindexPage>
            <h2 className="administration-page-header">{translate("#Core/masterDataReindex")}</h2>
            
            <Splitter
                style={{ border: 0, flexGrow: 1, height: 0 }}
                panes={panes}
                onChange={(e) => { setPanes(e.newState); }}>
                <div className="master-data-reindex__left-side">
                    {DisplayError(errorMessage)}
                    {ReindexInformation()}
                    <h4 className="master-data-reindex__header">{translate("#Core/companySettings")}</h4>
                    <div className="master-data-reindex__company-value">
                        <div className="master-data-reindex__company-value__label">{translate("#Core/company")}</div>
                        <LocalizationProvider language="current-language">
                            <ComboBox
                                data={state.data}
                                value={state.value}
                                textField="companyName"
                                onChange={onChange}
                                filterable
                                onFilterChange={onFilterChange}
                                style={{ width: "300px", marginBottom: "10px" }}
                                placeholder={translate("#Core/chooseCompany")}
                                itemRender={(li: any) => {
                                    const itemChildren = <span style={{ wordBreak: "break-word" }}>{li.props.children}</span>;
                                    return React.cloneElement(li, li.props, itemChildren);
                                }}
                            />
                        </LocalizationProvider>
                    </div>

                    {state.value && <>
                        <div className="master-data-reindex__company-value">
                            <div className="master-data-reindex__company-value__label">{translate("#Core/companyId")}</div>
                            <div>{state.value.companyId}</div>
                        </div>
                        <div className="master-data-reindex__company-value">
                            <div className="master-data-reindex__company-value__label">{translate("#Core/externalSystemId")}</div>
                            <div>{state.value.externalSystemId}</div>
                        </div>
                        <div className="master-data-reindex__company-value">
                            <div className="master-data-reindex__company-value__label">{translate("#Core/parentCompanyName")}</div>
                            <div>{state.value.parentCompanyName}</div>
                        </div>
                        <div className="master-data-reindex__company-value">
                            <div className="master-data-reindex__company-value__label">{translate("#Core/companyIsVirtual")}</div>
                            <div>{state.value.isVirtual ? translate("#Core/yes") : translate("#Core/no")}</div>
                        </div>
                        <div className="master-data-reindex__company-value">
                            <div className="master-data-reindex__company-value__label">{translate("#Core/active")}</div>
                            <div>{state.value.isActive ? translate("#Core/yes") : translate("#Core/no")}</div>
                        </div>
                    </>}


                    {state.value && <div className="master-data-reindex__reindex">
                        <h4 className="master-data-reindex__header">{translate("#Core/reindexMasterData")}</h4>
                        <div className="master-data-reindex__reindex__row">
                            <div className="master-data-reindex__reindex__row__label">{translate("#Core/newCompanyId")}</div>
                            <Input value={newCompanyId} onChange={(e) => { setNewCompanyId(e.target.value as string); }} placeholder={translate("#Core/companyId")}></Input>
                        </div>
                        <div className="master-data-reindex__reindex__row">
                            <div className="master-data-reindex__reindex__row__label">{translate("#Core/replaceWithinBrackets")}</div>
                            <RadioButton name="reindexApproach" value="0" checked={reindexApproach === '0'} onChange={changeReindexApproach} />
                        </div>
                        <div className="master-data-reindex__reindex__row">
                            <div className="master-data-reindex__reindex__row__label">{translate("#Core/replaceFromLeft")}</div>
                            <RadioButton  name="reindexApproach" value="1" checked={reindexApproach === '1'} onChange={changeReindexApproach} />
                        </div>
                        <div className="master-data-reindex__reindex__row">
                            <Button themeColor="primary" onClick={onReindex} disabled={!newCompanyId}>{translate("#Core/reindex")}</Button>
                        </div>
                    </div>}
                </div>
                <div className="master-data-reindex__right-side">
                    <h4 className="master-data-reindex__header">{translate("#Core/reindexHistory")}</h4>
                    {ReindexHistoryLogs(historyLogs.data)}
                </div>
            </Splitter>
        </MasterDataReindexPage>

    );
}

const rootCssClassName = "master-data-reindex-importLogs";
function componentClassName(strings: TemplateStringsArray) {
    return `${rootCssClassName}__${strings.toString()}`;
}
function ReindexHistoryLogs(logs: IReindexHistoryLogDto[]) {
    return (
    <div className={rootCssClassName}>
        {logs.map((item, index) => (
            <Card key={index} type="info" className={componentClassName`card`}>
                <CardBody>
                    <CardTitle className={componentClassName`card-title`}>{formatValueWithPlaceholders(translate("#Core/oldCompanyIdWasChangedToNewCompanyIdByUser_oldCompanyId_newCompanyId_user"),[item.oldCompanyId, item.newCompanyId, item.userFullName])}</CardTitle>
                    <p className={componentClassName`card-footer`}>
                        <DatePresenter date={new Date(item.timeStamp)} format="g"></DatePresenter>
                    </p>
                </CardBody>
            </Card>
        ))}
    </div>);
}

function DisplayError(errorMessage: string) {
    if (isNullOrUndefined(errorMessage))
        return;

    return (
    <div>
        <Card type="error">
            <CardBody>
                <CardTitle>{translate("#Core/thereWasAnErrorDuringLatestReindex")}</CardTitle>
                <p>
                    {errorMessage}
                </p>
            </CardBody>
        </Card>
    </div>);
}

function ReindexInformation() {
    return (
<div>
<Card type="warning">
    <CardBody>
        <p>{translate("#Core/rememberToUpdateDownstreamSystems")}</p>
        <p>{translate("#Core/avoidReindexingWhileSystemUsed")}</p>
    </CardBody>
</Card>    
<p />
<Card type="info">
<CardBody>   
<p>{translate("#Core/externalSystemIdTwoApproaches")}</p>
<ul>
    <li>{translate("#Core/replaceWithinBracketsDescription")}</li>
    <li>{translate("#Core/replaceFromLeftDescription")}</li>
</ul>
<p>{translate("#Core/reindexExample")}</p>
<p>{translate("#Core/reindexIdentifiersWillBeUpdated")}</p>
</CardBody>
</Card>
<p />
</div>);
}

export function registerMasterDataReindexAdminPage() {
    registerAdministrationEntity("MasterDataReindex", MasterDataReindex);
}
