/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/kendo/bindings/kendoListBox"/>

import * as ko from "knockout";
import * as $ from "jquery";
import { KendoUtils } from "Core/Medius.Core.Web/Scripts/Medius/kendo/kendoUtils";
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";

class KendoListBoxBinding {
    public static registerKnockoutBindings(): void {
        koUtils.registerBinding("kendoListBox", {
            init(element: HTMLElement) {
                const $element = $(element);
                koUtils.addDisposeCallback(element, () => {
                    KendoUtils.destroyComponent($element, "kendoListBox");
                });
            },
            update(element: HTMLElement, valueAccessor: any) {
                const params = valueAccessor();
                const $element = $(element);
                const data = ko.unwrap(params.data);

                const options = {
                    dataSource: data,
                    dataTextField: 'displayValue',
                    dataValueFiled: 'value',
                    change: (e: any) => {
                        const listBox = e.sender as kendo.ui.ListBox;
                        params.value(data[listBox.select().index()]);
                    }
                };
                KendoListBoxBinding.create($element, options);
            }
        });
    }

    private static create($element: JQuery, kendoOptions: kendo.ui.ListBoxOptions) {
        KendoUtils.destroyComponent($element, "kendoListBox");
        const $listBox = KendoUtils.createComponent($element, "kendoListBox", kendoOptions);
        const listBox = $listBox.data("kendoListBox");
        listBox.select(listBox.items().first());
    }
}

KendoListBoxBinding.registerKnockoutBindings();
export = null;
