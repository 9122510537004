/// <amd-module name="Core/Medius.Core.Web/Scripts/lib/validation/validators/versionValidator"/>
import * as config from "Core/Medius.Core.Web/Scripts/appConfig";
import { trackEvent } from "Core/Medius.Core.Web/Scripts/appInsights";
import { isVersioningMonitoringEnabled } from "Core/Medius.Core.Web/Scripts/Medius/core/featureToggle";
import * as ko from "knockout";

export const invalidVersion = ko.observable(false);

export function validateVersionFromHeaderJquery(jqXHR: JQueryXHR, uri: string, requestDate:Date): void {
    if (!isVersioningMonitoringEnabled())
        return;

    if (invalidVersion())
        return;

    const responseDateHeader: string | null = jqXHR.getResponseHeader("Date");
    const xApiVersion: string | null = jqXHR.getResponseHeader('x-api-version');
    
    validateVersion(xApiVersion, uri, requestDate, responseDateHeader);
}

export function validateVersionFromHeaderFetch(headers: Headers, uri: string, requestDate: Date): void {
    if (!isVersioningMonitoringEnabled())
        return;

    if (invalidVersion())
        return;
    
    const responseDateHeader: string | null = headers.get("Date");
    const xApiVersion: string | null = headers.get('x-api-version');


    validateVersion(xApiVersion, uri, requestDate, responseDateHeader);
}

function validateVersion(xApiVersion: string | null, uri: string, requestDate: Date, responseDateHeader: string | null) {
    if(!responseDateHeader) {
        return;
    }
    
    const responseDate = new Date(responseDateHeader);

    const timeDifference = Math.ceil((responseDate.getTime() - requestDate.getTime()) / 1000);
    if(timeDifference < 0) {
        return;
    }
    
    const apaVersion = getDisplayVersion(config.getApplicationVersion());
    
    if (xApiVersion !== null) {
        const apaVersionParts = apaVersion.split('.');
        const xApiVersionParts = xApiVersion.split('.');
        const apaMajorVersion = apaVersionParts[0];
        const apaMinorVersion = apaVersionParts[1];
        const xApiMajorVersion = xApiVersionParts[0];
        const xApiMinorVersion = xApiVersionParts[1];
        //We ignore the patch version in the comparison
        if (apaMajorVersion !== xApiMajorVersion || apaMinorVersion !== xApiMinorVersion) {
            invalidVersion(true);

            trackEvent({
                name: "[VersionValidator] Invalid APA version",
                properties: {
                    apaVersion: apaVersion,
                    apiVersion: xApiVersion,
                    detectUri: uri
                }
            });
        }
    } else {
        trackEvent({
            name: "[VersionValidator] Version header not found",
            properties: {
                detectUri: uri,
                apaVersion: apaVersion
            }
        });
    }
}

function getDisplayVersion(versionString: string): string {
    const parts = versionString.split('.');
    if (parts.length > 1) {
      return parts.slice(1).join('.');
    } else {
      return versionString;
    }
  }