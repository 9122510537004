/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/inbox/bulk/steps/resultProcessor" />

import { BulkResult } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/bulk/bulkResult";
import { NoModificationError } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/bulk/noModification";
import { handleBulkOpTask } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/taskHandler";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";


export class ResultProcessor {
    private static bulkResult = new BulkResult();

    public static process(bulkOperation: any, tasks: Array<any>, step: any) {
        const outboxItems = this.enqueueInOutbox(tasks);

        return bulkOperation.then((result: any) => {
            return this.success(outboxItems, result);
        }, (err: any, status: any, xhr: any) => {
            this.failure(err, status, xhr, outboxItems);
        }).always((result: any) => {
            return step.result(result);
        });
    }

    private static enqueueInOutbox(tasks: Array<any>): Array<any> {
        return tasks.map(task => handleBulkOpTask(task.TaskId, task.DocumentIdentifier))
                    .sort((next, prev) => (next.taskId - prev.taskId));
    }

    private static success(outboxItems: Array<any>, result: any) {
        return this.bulkResult.createFromActionSuccess(outboxItems, result);
    }

    private static failure(err: any, status: any, xhr: any, outboxItems: Array<any>) {
        let message;

        if (err instanceof NoModificationError) {
            message = translate("#Core/noModificationHasBeedPerformed");
        } else if (xhr && xhr.statusText) {
            message = xhr.statusText;
        } else {
            message = translate("#Core/unableToHandleThisTask");
        }

        return this.bulkResult.createFromActionFailure(outboxItems, message);
    }
}
