///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/performance"/>
import * as utils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as logger from "Core/Medius.Core.Web/Scripts/Medius/lib/logger";

const performance = {
    init: function () {
            
    },

    update: function (element:any, valueAccessor:any, allBindingsAccessor:any, viewModel:any, bindingContext:any) {
        try {
            const timerName = valueAccessor().timerName;
            const timer = require(timerName);
            timer.handle(bindingContext, valueAccessor().context, valueAccessor());
        } catch(exception) {
            logger.error("Binding based performance measurement faild", exception);
        }
    }
};

export function register() {
    utils.registerBinding("performance", performance);
}