/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/grid/default/multiselection"/>
import * as _ from "underscore";
import * as ko from "knockout";
import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";

function getRowId(row: any) {
    const rowId = row.Id || row.id;
    return ko.utils.unwrapObservable(rowId);
}

function isSelectable(rowId: any) {
    return !isNullOrUndefined(rowId);
}

export class Multiselection {
    selectedIds: ko.ObservableArray<any>;
    selectedRows: ko.ObservableArray<any>;
    selectedRowsByIds: any;
    subscriptions: any[];
    isInitialSelected: any;
    toggleRow: (id: any) => void;
    SelectAll: ko.Computed<boolean>;
    toggleAll: () => void;
    constructor(grid: any, isInitialSelectedPredicate?: any) {
        this.selectedIds = ko.observableArray([]);
        this.selectedRows = ko.observableArray([]);

        this.selectedRowsByIds = {};
        this.subscriptions = [];

        this.isInitialSelected = isInitialSelectedPredicate
            || function () { return false; };

            this.toggleRow = (id) => {
            const observable = this.getRowSelectedObservable(id);

            if (observable) {
                observable(!observable());
            }
        };

        this.SelectAll = ko.computed({
            read: () => {
                let allSelected = true;
                const rows = _(grid.Rows()).filter((row) => {
                        return isSelectable(getRowId(row));
                    });

                if (rows.length === 0) {
                    return false;
                }

                _(rows).each((row) => {
                    const observable = this.getRowSelectedObservable(getRowId(row)),
                        value = ko.isObservable(observable) ? observable() : false;

                    allSelected = allSelected && value;
                });

                return allSelected;
            },
            write: (value) => {
                _(grid.Rows()).each((row) => {
                    this.markRow(getRowId(row), value);
                });
            }
        });

        this.toggleAll = () => {
            this.SelectAll(!this.SelectAll());
        };
    }

    getRowSelectedObservable(id: any) {
        const rowId = ko.utils.unwrapObservable(id),
            observable = this.selectedRowsByIds[rowId];

        return ko.isObservable(observable) ? observable : undefined;
    }

    markRow(id: any, isSelected: any) {
        const observable = this.getRowSelectedObservable(id);

        if (observable) {
            observable(isSelected);
        }
    }

    addCheckbox(id: any, row: any) {
        const observable = this.getRowSelectedObservable(id);

        if (observable) {
            return;
        }

        const initialValue = !!(this.isInitialSelected(row));
        this.selectedRowsByIds[id] = ko.observable(initialValue);

        if (initialValue) {
            this.selectedIds.push(id);
            this.selectedRows.push(row);
        }

        const subscription = this.selectedRowsByIds[id]
            .subscribe((isSelected: any) => {
                if (isSelected) {
                    this.selectedIds.push(id);
                    this.selectedRows.push(row);
                } else {
                    this.selectedIds.remove(id);
                    this.selectedRows.remove(row);
                }
            });
            this.subscriptions.push(subscription);
    }

    addCheckboxes(rows: any) {
        _(rows).each((row) => {
            const rowId = getRowId(row);
            if (isSelectable(rowId)) {
                this.addCheckbox(rowId, row);
            }
        });
    }

    destroy() {
        this.SelectAll.dispose();

        this.subscriptions.forEach((subscription) => {
            subscription.dispose();
        });

        this.subscriptions = [];
        this.selectedIds([]);
        this.selectedRows([]);
        this.selectedRowsByIds = {};
    }
}
