///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/workflow/management/description"/>
import * as ko from "knockout";
import * as rpc from "Core/Medius.Core.Web/Scripts/Medius/core/rpc";
import * as notification from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import * as serialization from "Core/Medius.Core.Web/Scripts/Medius/lib/serialization";
import * as backendErrorHandler from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import instance = require("Core/Medius.Core.Web/Scripts/Medius/apps/workflow/management/instance");

function successHandle(text: string) {
    return () => notification.success(text);
}

function errorHandle(xhr: backendErrorHandler.XHR) {
    backendErrorHandler.handleAnyError(xhr);
}

function buttonHandle(
    inst: {
        disableButtons: (arg0: boolean) => void;
        Grid: { openPage: (arg0: number) => void; };
    },
    refresh: boolean) {
    return () => {
        inst.disableButtons(false);
        if (refresh)
            inst.Grid.openPage(1);
    };
}

const selectedRow = (element: any) => ({
    guid: element.Instance.Grid.SelectedRow().Guid
});


class WorkflowDescription {
    Instance = instance(this);
    IsGraphVisible = ko.observable(false);

    // viewModel fields
    workflowInfo = ko.observableArray([]);
    messages = ko.observableArray([]);

    //------------- workflow control

    Cancel = (element: any) => {
        element.Instance.disableButtons(true);

        return rpc.ajax("WorkflowDescriptionService", "Cancel",
            { data: serialization.toJSON(selectedRow(element)) })
            .done(successHandle("Cancel succeeded"))
            .fail(errorHandle)
            .always(buttonHandle(element.Instance, true));
    };

    Terminate = (element: any) => {
        element.Instance.disableButtons(true);

        return rpc.ajax("WorkflowDescriptionService", "Terminate",
            { data: serialization.toJSON(selectedRow(element)) })
            .done(successHandle("Terminate succeeded"))
            .fail(errorHandle)
            .always(buttonHandle(element.Instance, true));
    };

    Run = (element: any) => {
        element.Instance.disableButtons(true);

        return rpc.ajax("WorkflowDescriptionService", "Run",
            { data: serialization.toJSON(selectedRow(element)) })
            .done(successHandle("Ping succeeded"))
            .fail(errorHandle)
            .always(buttonHandle(element.Instance, false));
    };

    Delete = (element: any) => {
        element.Instance.disableButtons(true);

        return rpc.ajax("WorkflowDescriptionService", "Delete",
            { data: serialization.toJSON(selectedRow(element)) })
            .done(successHandle("Delete succeeded"))
            .fail(errorHandle)
            .always(buttonHandle(element.Instance, false));
    };

    //end------------- workflow control
}

export = function () {
    return new WorkflowDescription();
};
