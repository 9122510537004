/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Entities.DataAccessSchemaActivity/dataAccessSchemaActivityTabCreator"/>
import { ObservableArray, Observable, observableArray, observable, unwrap } from "knockout";
import * as rpc from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rpc";
import * as _ from "underscore";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { error } from "Core/Medius.Core.Web/Scripts/Medius/core/notification";

// ReSharper disable InconsistentNaming
class DataAccessSchemaActivityTab {
    ActivityDefinitionRows: any;
    AllActivityDefinitions: ObservableArray<any>;
    SelectedActivityDefinition: Observable<any>;
    constructor(entityWithContext: any) {
        // ReSharper restore InconsistentNaming
        const activityDefinitions = entityWithContext.RelatedObjects["Medius.Data.WorkflowMetadata.ActivityDefinition"]
            .RelatedEntities;

        // Bindings
        this.ActivityDefinitionRows = activityDefinitions;
        this.AllActivityDefinitions = observableArray([]);
        this.SelectedActivityDefinition = observable();
        // Actions
        rpc.request("WorkflowDefinitionDataService", "GetUserActivityDefinitions")
            .done((activityDefinitionCollection) => {
                this.AllActivityDefinitions(activityDefinitionCollection);
            });
    }

    removeRow(activityDefinition: any) {
        this.ActivityDefinitionRows.remove(activityDefinition);
    }
    addRow() {
        const activityDefinition = this.SelectedActivityDefinition();

        const existingActivityRow = _.find(this.ActivityDefinitionRows(), (observableAdr) => {
            const activityDefinitionRow = unwrap(observableAdr);
            const workflowDefinition = unwrap(activityDefinitionRow.Workflow);

            const isActivityIdSimilar = unwrap(activityDefinitionRow.Id) === activityDefinition.Id;
            const isWorkflowIdSimilar = unwrap(workflowDefinition.Id) === activityDefinition.Workflow.Id;

            return isActivityIdSimilar && isWorkflowIdSimilar;
        });
        if (!(_.isNull(existingActivityRow) || _.isUndefined(existingActivityRow))) {
            const selectedActivityErrorMessage = getLabelTranslation("#Core/selectedActivityAlreadyAdded");
            error(selectedActivityErrorMessage);
            return;
        }

        this.ActivityDefinitionRows.push(activityDefinition);
    }
    unwrap(object: any) {
        return unwrap(object);
    }

    createActivityDescription(observableActivityDefinition: any) {
        const activityNameTranslation = getLabelTranslation("#Core/activityName"),
            workflowNameTranslation = getLabelTranslation("#Core/workflowName");

        const activityDefinition = unwrap(observableActivityDefinition);
        const workflowDefinition = unwrap(activityDefinition.Workflow);

        const activityDescription = activityNameTranslation + ": " + unwrap(activityDefinition.Name) + ", " +
            workflowNameTranslation + ": " + unwrap(workflowDefinition.Name);

        return activityDescription;
    }

    // Interface for Administration Pages API
    dispose() {
        this.ActivityDefinitionRows(null);
        this.AllActivityDefinitions(null);
        this.SelectedActivityDefinition(null);
    }
}

export default function (entityWithContext: any) {
    return new DataAccessSchemaActivityTab(entityWithContext);
}