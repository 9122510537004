///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/documentSearch/resultGroup/parser"/>
import * as metadataColumns from "Core/Medius.Core.Web/Scripts/Medius/apps/documentSearch/metadataColumns";
import * as labelsRepository from "Core/Medius.Core.Web/Scripts/Medius/core/labels/labelsRepository";
import * as _ from "underscore";
import {GridObjectParser} from "Core/Medius.Core.Web/Scripts/Medius/components/grid/parser/default";

class ResultGroupParser extends GridObjectParser {
    constructor() {
        super();
    }

    getCollectionNames(keys: any, firstRow: any) {
        return _.filter(keys, (property) => {
                const isMetadataColumn = _.find(metadataColumns.availableMetadataColumns(), (metadataColumn) => {
                return metadataColumn.Key === property;
            });

            return (isMetadataColumn) ? false : _.isArray(firstRow[property]);
        });
    }

    resolveColumnValue(row: { _meta_labels: any; }, column: { Key: string; }) {

        if (column.Key !== "_meta_labels") {
            return super.resolveColumnValue(row, column);
        }

        const labelIds = row._meta_labels;
        const labels = labelsRepository.getLabelsByIds(labelIds);
        return labels;
    }
}

const instance = new ResultGroupParser();

export function create() {
    return instance;
}