/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/DocumentCacheManagement/services"/>
import {post, get} from "Core/Medius.Core.Web/Scripts/Medius/core/fetch/rest";
import {DocumentCachesDto} from "./interfaces";


export function getDocumentsNotCached(skip: number, take: number, orderBy: string = null, orderByDirection: string = null, filterId: number = null, filterErrorDescription: string = null ) {
    return get<DocumentCachesDto>("documentCacheManagement/search", {
        skip,
        take,
        orderBy,
        orderByDirection,
        filterId,
        filterErrorDescription
    });
}

export function rebuildDocument(documentId: number) {
    return post(`Backend/Rest/documentCacheManagement/rebuildDocument/${documentId}`);
}

export function rebuildCache() {
    return post(`Backend/Rest/documentCacheManagement/rebuildCache`);
}

export function getRebuildCacheProgress() {
    return get<{progress: number}>(`documentCacheManagement/getProgress`);
}

export function isUpdatingCacheError(): Promise<boolean> {
    return get(`documentCacheManagement/isUpdatingCacheError`);
}

export function updateCacheError() {
    return post(`Backend/Rest/documentCacheManagement/updateCacheError`);
}