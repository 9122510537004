///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Schedulers.ScheduledJobConfiguration/Editor" />
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as type from "Core/Medius.Core.Web/Scripts/Medius/core/type";
import * as rpc from "Core/Medius.Core.Web/Scripts/Medius/core/rpc";
import * as serialization from "Core/Medius.Core.Web/Scripts/Medius/lib/serialization";
import { handleAnyError } from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import * as notification from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import * as ko from "knockout";
import * as moment from "moment";

class TimeWrapper {
    Date: any;
    TimeValue: any;
    Time: ko.Computed;
    DateSubscription: any;
    DateChanged: boolean;

    constructor(dateProperty: any) {
        this.Date = dateProperty;
        this.TimeValue = this.Date();

        this.Time = ko.computed({
            read: () => {
                if (this.Date() === null) {
                    return null;
                }
                return moment(this.Date()).format("hh:mm a");
            },
            write: (value: any) => {
                const time = moment(value, "hh:mm a");

                if (!time.isValid()) {
                    return;
                }

                this.TimeValue = time.toDate();
                this.setHours();
            },
            owner: this
        });

        this.DateSubscription = this.Date.subscribe(() => {
            this.DateChanged = true;
            if (this.TimeValue) {
                this.setHours();
            }
        });
    }

    setHours() {
        if (this.Date() === null) {
            return;
        }

        const hours = this.TimeValue.getHours();
        const minutes = this.TimeValue.getMinutes();

        this.Date().setHours(hours, minutes);
    }

    dispose() {
        this.DateSubscription.dispose();
        this.DateSubscription = null;
    }
}

class JobConfigViewModel {
    Entity: any;
    Tab: any;
    CompanyContextId: any;
    EntityType: any;

    JobStatus: any;
    StartTime: ko.Observable;
    EndTime: ko.Observable;

    IsCron = ko.observable(true);
    CronStatement = ko.observable(false);

    LastRunDate = ko.observable(false);
    NextRunDate = ko.observable(false);

    WasSuccessful = ko.observable(null);
    ExceptionMessage = ko.observable(null);

    CanRunJob: ko.Observable;
    ContextTemplate: string;

    constructor(entityWithContext: any, companyContextId: any, tabModel: any) {
        this.Entity = entityWithContext.Entity;
        this.Tab = tabModel;
        this.CompanyContextId = companyContextId;
        this.EntityType = type.getTypeName(this.Entity.$type());

        this.JobStatus = new Date().setHours(0, 0, 0, 0) <= this.Entity.EndDate()
            ? getLabelTranslation("#Core/jobIsScheduled")
            : getLabelTranslation("#Core/jobIsNotScheduled");

        this.StartTime = ko.observable(new TimeWrapper(this.Entity.StartDate));
        this.EndTime = ko.observable(new TimeWrapper(this.Entity.EndDate));

        this.IsCron = ko.observable(true);
        this.CronStatement = ko.observable(false);

        this.LastRunDate = ko.observable(false);
        this.NextRunDate = ko.observable(false);

        this.WasSuccessful = ko.observable(null);
        this.ExceptionMessage = ko.observable(null);

        this.CanRunJob = ko.observable(entityWithContext.CanUpdate);

        this.getDates();

        this.ContextTemplate = (this.Entity.Context() && this.Entity.Context().Template)
            ? this.Entity.Context().Template
            : "default-scheduled-job-context-template";
    }

    getDates() {
        rpc.ajax("ScheduledJobManager", "GetRunDateById", {
            data: serialization.toJSON({ jobConfigurationId: this.Entity.Id })
        }).done((dto: any) => {
            if (dto) {
                if (dto.LastRun != null) {
                    this.LastRunDate(dto.LastRun);
                }
                if (dto.NextRun != null) {
                    this.NextRunDate(dto.NextRun);
                }
                this.WasSuccessful(dto.WasSuccessful);
                this.ExceptionMessage(dto.ExceptionMessage);
            }
        }).fail(function (error) {
            handleAnyError(error);
        });
    }

    runJob() {
        rpc.ajax("ScheduledJobManager", "RunJobAsyncById", {
            data: serialization.toJSON({ jobConfigurationId: this.Entity.Id })
        }).done((started: boolean) => {
            if (started) {
                notification.success(getLabelTranslation("#Core/jobHasSuccessfullyStarted"));
            } else {
                notification.info(getLabelTranslation("#Core/jobHasBeenRunning"));
            }
        }).fail((error) => {
            handleAnyError(error);
        });
    }

    repeatSpanValidator(value: any) {
        const reg = new RegExp(/^(\d+\.)?(0\d|1\d|2[0-3])(:[0-5]\d){2,}$/);

        if (reg.test(value)) {
            return true;
        }
        return {
            valid: false,
            validationDetails: "Incorrect format. Value must match d.HH:mm:ss"
        };
    }

    dispose() {
        this.Entity = null;
        this.Tab = null;
        this.CompanyContextId = null;
        this.JobStatus = null;
        this.IsCron = null;
        this.CronStatement = null;
        this.StartTime().dispose();
        this.EndTime().dispose();
        this.StartTime = null;
        this.EndTime = null;
        this.LastRunDate = null;
        this.NextRunDate = null;
        this.WasSuccessful = null;
        this.ExceptionMessage = null;
        this.CanRunJob = null;
    }
}

export = function (entityWithContext: any, companyContextId: any, tabModel: any) {
    return new JobConfigViewModel(entityWithContext, companyContextId, tabModel);
};
