/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/typeBound" />
import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import * as type from "Core/Medius.Core.Web/Scripts/Medius/core/type";
import * as _ from "underscore";
import {unpack} from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";

class TypeBoundResolver {
    condition: (data: any, typeOverride: any) => boolean;
    resolve: any;
    constructor(options: any = {}) {
        if (typeof options.priority !== "number") {
            throw new Error("TypeBoundResolver requires priority number parameter");
        }

        if (typeof options.type !== "string") {
            throw new Error("TypeBoundResolver requires type string parameter");
        }

        if (typeof options.resolve !== "function") {
            throw new Error("TypeBoundResolver requires resolve function parameter");
        }

        const appliedType = type.getTypeName(options.type);

        _(this).extend(options);
        
        function conditionNonInherited(shortType: any) {
            return shortType === appliedType;
        }

        function conditionInherited(shortType: any) {
            const applicableTypes = type.getHierarchy(shortType).map(function (t: any) {
                return type.getTypeName(t);
            });

            return _(applicableTypes).any(function(t) {
                return t === appliedType;
            });
        }

        this.condition = function (data, typeOverride) {
            if (!isNullOrUndefined(data)) {
                if (this.isLongTermArchived === true && (isNullOrUndefined(data.isLongTermArchived) || data.isLongTermArchived === false)) {
                    return false;
                }
                if (this.isLongTermArchived === false && data.isLongTermArchived === true) {
                    return false;
                }
            }

            const dataType = typeOverride || data && unpack(data.$type),
                shortType = type.getTypeName(dataType);

            return options.dontInherit ? conditionNonInherited(shortType) : conditionInherited(shortType);
        };
    }
}
export function create(options: any) {
    return new TypeBoundResolver(options);
}

export function instanceOf(obj: any) {
    return obj instanceof TypeBoundResolver;
}

export const priority = {
    lowest: -1000000,
    highest: 1000000
};
