/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/default/longText/errors/dataValidationResultCollection"/>
import * as typeBoundResolverFactory from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/typeBound";
import { formatValueWithPlaceholders } from "Core/Medius.Core.Web/Scripts/Medius/lib/stringFormat";
import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import * as _ from "underscore";

export const resolver = typeBoundResolverFactory.create({
    priority: 0,

    type: "Medius.Validation.DataValidationResultCollection",

    resolve: function (validationCollection: any) {
        if (!validationCollection.ValidationResults) {
            return {};
        }

        const error = _(validationCollection.ValidationResults).map(function (m) {
            const message = m.ValidationMessage.Message;
            if (isNullOrUndefined(message)) {
                return "";
            }

            const messageParameters = m.ValidationMessage.MessageParams;
            const errorMessage = formatValueWithPlaceholders(message, [...messageParameters]);

            return errorMessage;
        }).join("\n");
        
        return {
            longText: error
        };
    }
});
