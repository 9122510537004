/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/company/model" />
import {AutocompleterViewModel as BaseViewModel} from "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/default/model";
import * as userUtils from "Core/Medius.Core.Web/Scripts/Medius/lib/utils/user";

class AutocompleterViewModel extends BaseViewModel {
    constructor(params: any) {
        super(params);
        if (params.options.useDefault && !this.value()) {
            this.setValue(userUtils.getDefaultCompany(), this.context);
        }
    }
}

export function create(params: any) {
    return new AutocompleterViewModel(params);
}
