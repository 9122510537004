///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Entities.Integration.IntegrationMessageSettingsConfiguration/Config"/>
import baseFactory = require("Core/Medius.Core.Web/Scripts/Medius/models/mergedConfiguration/mergedEntityConfigurationTab");
import * as roleDataProviderFactory from "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/role/dataProvider";

class ConfigTab extends baseFactory {
    constructor(entityWithContext:any) {
        super(entityWithContext);
    }
    getConfigurationTypeName() {
        return "Medius.Core.Entities.Integration.IntegrationMessageSettingsConfiguration";
    }

    getTemplateName () {
        return "Core:templates/Administration/EntityConfiguration/IntegrationMessageSettingsConfiguration.html";
    }

    getDefaultCompanyContext():any {
        return null;
    }

    getCompanyFilter():any {
        return null;
    }
        
    intializeConfigurationProperties(company:any, configurationData:any) {
        const roleDataProvider = roleDataProviderFactory.create();
            
        configurationData.addSimpleProperty('IsActive', this.getPropertyLabel("IsActive"));
        configurationData.addSimpleProperty('WaitForResponse', this.getPropertyLabel("WaitForResponse"));
        configurationData.addSimpleProperty('CorrelationKeys', this.getPropertyLabel("CorrelationKeys"));
            
        configurationData.addEntityProperty('IntegrationRole', this.getPropertyLabel("IntegrationRole"), roleDataProvider);
    }
        
    getPropertyLabel(propertyName:string) {
        return this.getConfigurationTypeName() + "/" + propertyName;
    }
}

export = function(entityWithContext:any) {
    return new ConfigTab(entityWithContext);
};