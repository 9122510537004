/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/localizer/defaults"/>

import * as integerLocalizer from "Core/Medius.Core.Web/Scripts/Medius/components/localizer/type/integer";
import * as numericLocalizer from "Core/Medius.Core.Web/Scripts/Medius/components/localizer/type/numeric";
import * as dateLocalizer from "Core/Medius.Core.Web/Scripts/Medius/components/localizer/type/date";
import * as _ from "underscore";

const dateTypes = [
    "System.DateTime"
];
const integerTypes = [
    "System.Int16",
    "System.Int32",
    "System.Int64",
    "System.UInt16",
    "System.UInt32",
    "System.UInt64"
];
const numericTypes = [
    "System.Decimal",
    "System.Double",
    "System.Single"
];

export function register(localizer: any) {
    function applier(localizationMethod: any) {
        return (type: any) => {
            localizer.register(type, localizationMethod);
        };
    }

    _(dateTypes).each(applier(dateLocalizer));
    _(integerTypes).each(applier(integerLocalizer));
    _(numericTypes).each(applier(numericLocalizer));
}