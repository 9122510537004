/// <amd-module name="Core/Medius.Core.Web/Scripts/components/links/linkSelector/services/linkService"/>
import { SearchResult, RecentDocumentsSuggestionCollectionDto } from "Core/Medius.Core.Web/Scripts/components/links/linkSelector/linkSelectorDtos";
import * as rest from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rest";

export function search(searchTerm: string): JQueryPromise<SearchResult> {
    return rest.get("entities-to-link", `search?searchTerm=${encodeURIComponent(searchTerm)}`);
}

export function recentlyViewed(): JQueryPromise<RecentDocumentsSuggestionCollectionDto> {
    return rest.get("entities-to-link", "recentlyViewed");
}
