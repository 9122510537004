/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/lib/performance/output/console"/>

import * as logger from "Core/Medius.Core.Web/Scripts/Medius/lib/logger";

export function output (category: string, name: string, measure: any, data?: any) {
    data = data || {};
    
    if (data.scenarioDetails) {
        name = name + " - " + data.scenarioDetails;
    }

    let out: string;
    if (data.label) {
        out = `Performance | ${category} | ${name} | ${data.label} | ${measure} ms`;
    } else {
        out = `Performance | ${category} | ${name} | ${measure} ms`;
    }
    logger.log(out);
}

export function event(category: string, action: string, label: string, value?: string) {
    let out: string;
    if (value !== null) {
        out = `Event | ${category} | ${action} | ${label} | ${value} `;
    } else {
        out = `Event | ${category} | ${action} | ${label} `;
    }
    logger.log(out);
}