/// <amd-module name="Core/Medius.Core.Web/Scripts/components/taskHandling/blockingOperationHandler"/>
import { info } from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import {translate} from "Core/Medius.Core.Web/Scripts/lib/globalization";

const validationOngoingMessage = '#Enterprise/validationOfCodingIsOngoing';
const validationOngoingTitle = '#Enterprise/pleaseWaitAMomentAndTryAgain';

// DEPENDENCY CORE -> ENTERPRISE
// This is temporary workaround for document steps that are using generic save/task handle from UserTask
// Gradually it should go to action-based handlers (like expense: authorizeStep.ts and expenseBlockingOperationHandler.ts)
export function showWarningIfAnyRequestOngoing(task: any) {
    if (task && task.Document() && task.Document().Accounting &&
        task.Document().Accounting().IsRequestOngoing && task.Document().Accounting().IsRequestOngoing()) {
        
        info(translate(validationOngoingMessage), translate(validationOngoingTitle));
        
        return true;
    }
    
    return false;
}
