/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/wizard/bindings/wizard"/>
import * as ko from "knockout";
import { addDisposeCallback, registerBinding, unpack } from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";

const wizard: ko.BindingHandler = {
    init: function (element, bindingAccessor, allBindingsAccessor, viewModel, bindingContext) {
        const instanceObservable = bindingAccessor();
        const instance = unpack(bindingAccessor());
        
        if (!instance) {
            throw new Error("medius/components/wizard/bindings/wizard: undefined wizard");
        }

        instance.instanceObservable = ko.isObservable(instanceObservable) ? instanceObservable : null;

        const newContext: any = bindingContext.createChildContext(instance);
        newContext.extend({
            $wizardElement: element
        });
        ko.renderTemplate(instance.options.template, newContext, {}, element, "replaceChildren");

        const dispose = function() {
            instance.dispose();
            newContext.$wizardElement = null;
        };
        addDisposeCallback(element, dispose);

        return { controlsDescendantBindings: true };
    }
};

export function registerWizardBinding() {
    registerBinding("wizard", wizard);
}
