///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/lib/entityBackup/backup"/>

import * as _ from "underscore";
import * as ko from "knockout";
import * as serialization from "Core/Medius.Core.Web/Scripts/Medius/lib/serialization";

class ObjectBackup {
    revert: () => void;
    constructor(obj: any, propertiesToSkip: any) {
        let cached: any;
        function cache(o: any) {
            let properties = _(o).keys();
            properties = _(properties).reject(function (property) {
                return _(propertiesToSkip).contains(property);
            });
            
            cached = (serialization.toJS as any)(obj, {
                'include': properties
            });
        }

        function revert(o: any, cachedObj: any) {
            const properties = _(o).keys();

            _(properties).each(function (propertyName) {
                let valueToRestore: any;

                if (ko.isWriteableObservable(o[propertyName]) && propertiesToSkip.indexOf(propertyName) === -1) {
                    valueToRestore = cachedObj[propertyName];

                    if (valueToRestore != null && typeof valueToRestore === "object") {
                        revert(o[propertyName](), valueToRestore);
                    } else {
                        o[propertyName](valueToRestore);
                    }
                }
            });
        }

        this.revert = function () {
            revert(obj, cached);
        };

        cache(obj);
    }
}

export function create(obj: any, propertiesToSkip: any[] = []) {
    return new ObjectBackup(obj, propertiesToSkip);
}