///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/inbox/task/labelSelectorServices" />
import { get, put, del } from "Core/Medius.Core.Web/Scripts/Medius/core/fetch/rest";
import { LabelsForDocumentResponse } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/task/labelSelector";

export const getLabelsAndAssignedLabels = (taskId: number) => {
    return get<LabelsForDocumentResponse>(`labels/task/${taskId}`);
};

export const assignLabel = (documentId: number, labelId: number) => {
    return put(`Backend/Rest/labels/${labelId}/document/${documentId}`);
};

export const unassignLabel = (documentId: number, labelId: number) => {
    return del(`Backend/Rest/labels/${labelId}/document/${documentId}`);
};