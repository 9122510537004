///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/autocompleter/dataProvider/provider" />

import * as utils from "Core/Medius.Core.Web/Scripts/Medius/mediusUtils";    
import { DataProvider as baseProvider } from "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/default/dataProvider";
import * as ko from "knockout";
import * as _ from "underscore";

export class Provider extends baseProvider {
    context: any;
    methodName: any;
    serviceName: any;
    buildViewModel: any;
    isRequestOngoing = ko.observable(false);

    constructor(context: any, methodName: any, serviceName: any, buildViewModel: any) {
        super();
        this.context = context;
        this.methodName = methodName;
        this.serviceName = serviceName;
        this.buildViewModel = buildViewModel || false;
    }

    getItems(phrase: any, limit: any, extras: any) {
        this.isRequestOngoing(true);
        const params = this.getParameters(phrase, limit, extras);
        return utils.ajax(this.methodName, this.serviceName, params).always(() => {
            this.isRequestOngoing(false);
        });
    }
    
    createViewModelIfNeeded(data: any) {
        if (!this.buildViewModel){
            return data;
        }
    
        return this.context.create(data);
    }
    
    search(phrase: any, limit: any, extras: any) {
        return this.getItems(phrase, limit, extras).pipe((data: any) => {
    
            if (data.IsMissing) {
                const dummy = this.createDummy(phrase);
                data.missing = this.createViewModelIfNeeded(dummy);
            }
    
            data.Results = _.map(data.Results, (item: any) => {
                return this.createViewModelIfNeeded(item);
            }, this);
    
            return data;
        });
    }
    
    createDummy(arg?: any): any {
        return null;
    }

    getParameters(phrase?: any, limit?: any, extras?: any){}
}

export const derive = Provider;