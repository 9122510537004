/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/LoginOption/customActions"/>
import { AdminApplication } from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/adminApp";
import { registerCustomLoadAction, registerCustomSaveAction } from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/customActionRegistry";
import * as backendErrorHandler from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import { when, Deferred } from "jquery";
import { success, error } from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { without, keys } from "underscore";
import { IEntityWithContext } from "../IEntityWithContext";
import EditorTabs from "../../components/administrationEditor/editorTabs";
import { getBasePath } from "../../Medius/lib/path";
import { Editor } from "../../Medius/apps/administration/editor";
import * as rest from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rest";

const identityProviderFullName = "Medius.Core.Entities.Security.IdentityProvider";

function onIdentityProviderSave(adminApp: AdminApplication) {
    adminApp.IsLoading(true);

    makeSaveCall(adminApp)
        .always(() => { adminApp.IsLoading(false); });
}

function makeSaveCall(adminApp: AdminApplication) {
    function save() {
        const identityProvider = adminApp.Editor().EntityWithContext.Entity;
        const saveHandler = () => {
            if (!adminApp.Editor().CanUpdate) {
                error(translate("#Core/DEFAULT_AUTHORIZATION_UPDATEPERMISSIONS"));
                return Deferred().reject();
            }
            function doSave() {
                const context = adminApp.Editor().context;
                const types = without(keys(adminApp.Editor().EntityWithContext.RelatedObjects), "$type");
                const identityProviderDto = {
                    $type: "Medius.Core.Platform.InfrastructureLayer.WebServices.IdentityProviderDto, Medius.Core.Platform",
                    Entity: context.toJS(identityProvider),
                    RelatedObjects: context.toJS(types.map((key: any) => {
                        return {
                            $type: "Medius.Enterprise.DTO.RelatedObjectDto, Medius.Enterprise.Common",
                            RelatedObject: adminApp.Editor().EntityWithContext.RelatedObjects[key],
                            RelatedObjectType: key
                        };
                    })),
                    CanUpdate: adminApp.Editor().EntityWithContext.CanUpdate,
                    CanDelete: adminApp.Editor().EntityWithContext.CanDelete
                };
                
                const restCall = adminApp.Editor().AdminApp.EntityId() > 0 ? rest.put : rest.post;

                return restCall("identityProviderAdministration", "", identityProviderDto);
            }

            return doSave();
        };

        return adminApp.Editor().context.enqueue({
            action: saveHandler,
            name: "save"
        }).getDeferred();
    }
    const saved = save();
    return when(saved)
        .then((data: number) => {
            success(translate("#Core/changesSavedSuccessfully"));
            if (adminApp.EntityId() > 0) {
                adminApp.reloadForm();
            } else {
                adminApp.EntityId(data);
            }

            adminApp.ObjectBrowser.Grid().refresh();
        }, error => {
            backendErrorHandler.handleAnyError(error);
        }).always(() => { adminApp.IsLoading(false); });
}

function makeLoadCall(editor: Editor) {
    return rest.get("identityProviderAdministration", editor.AdminApp.EntityId())
    .then((entityWithContext: IEntityWithContext) => {
        const isDeletePossible = entityWithContext.CanDelete;
        entityWithContext.$type = "Medius.Core.Entities.Security.IdentityProvider, Medius.Core.Common";
        editor.EntityWithContext = editor.createEntityWithContext(entityWithContext);
        editor.EntityWithContext.TabModels = {};
        editor.CanUpdate(entityWithContext.CanUpdate);
        editor.CanDelete(isDeletePossible);
        editor.EditorTabs = new EditorTabs(identityProviderFullName, editor.EntityWithContext, editor.CompanyContextId);
        return editor.EditorTabs.load();
    })
    .fail((obj: any) => {
        if (obj.status === 403) {
            window.location.replace(getBasePath() + "Error/NotFound");
        }
    });
}

export function register() {
    registerCustomSaveAction(identityProviderFullName, onIdentityProviderSave);
    registerCustomLoadAction(identityProviderFullName, makeLoadCall);
}
