///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/task/RiskFactor/riskFactorIconComponentServices"/>
import { get, post } from "Core/Medius.Core.Web/Scripts/Medius/core/fetch/rest";
import { 
    RiskFactorCode, 
    RiskFactorReasonCode, 
    RiskFactorsDetailsResponseDTO, 
    RiskFactorStatusForInvoice, 
} from "Core/Medius.Core.Web/Scripts/Medius/apps/task/RiskFactor/RiskFactorComponentDtos/riskFactorsForInvoiceDto";

export function getRiskFactors(taskId: number){
    return get<RiskFactorsDetailsResponseDTO>(`riskFactors/invoice/${taskId}`);
}

export function getRiskFactorStatus(taskId: number){
    return get<RiskFactorStatusForInvoice>(`riskFactors/invoice/status/${taskId}`);
}

export const sendOneHandlingReasonForTask = (
    taskId: number,
    data: {
        handlingReason: RiskFactorReasonCode,
        riskFactorCode: RiskFactorCode,
    }
) => post(`Backend/Rest/riskFactors/invoice/${taskId}`, [data]);