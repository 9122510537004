/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/lib/performance/output/all"/>

import * as consoleOutput from "Core/Medius.Core.Web/Scripts/Medius/lib/performance/output/console";
import * as statsd from "Core/Medius.Core.Web/Scripts/Medius/lib/performance/output/statsd";

const statsdOutput = statsd.default();

export function output(category: any, scenario: any, time: any, data: any) {
    consoleOutput.output(category, scenario, time, data);
    statsdOutput.output(category, scenario, time, data);
    
}

export function event(category: any, scenario: any, scenarioDetails: any, value: any) {
    consoleOutput.event(category, scenario, scenarioDetails, value);
    statsdOutput.event(category, scenario, scenarioDetails, value);
}
