/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/models/bulk/modificationsMapping"/>

import * as rest from "Core/Medius.Core.Web/Scripts/Medius/core/rest";
import * as _ from "underscore";
import { Deferred } from "jquery";

export class ModificationsMapping{

    handlersMapping: any;

    prepareHandlerMapping = () => {
        if (this.handlersMapping) {
            return this;
        } else {
            const dfd = Deferred();  
            rest.get("BulkOperationService", "handlerMapping/admin")
                .pipe((response: Response) => {
                    this.handlersMapping = response;
                    return response;
                }).done(() => {
                    dfd.resolve(this);
                });
            return dfd.promise();
        }
    };

    injectMapping = (entityType: string, modificationsContainer: any) => {
        const injectedContainer = _(modificationsContainer).map((modification) => {
            return {
                Key: modification.Key,
                Value: {
                    $type: this.handlersMapping[entityType][modification.Key].$type,
                    Value: modification.Value
                }
            };
        });
        return injectedContainer;
    };
}

export function create() {
    return new ModificationsMapping;
}
