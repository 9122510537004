/// <amd-module name="Core/Medius.Core.Web/Scripts/components/links/linkSelector/suggestion/suggestion"/>
import * as ko from "knockout";
import { SuggestionDto } from "Core/Medius.Core.Web/Scripts/components/links/linkSelector/linkSelectorDtos";
type SuggestionSelectedHandler = (suggestion: Suggestion) => void;

export default class Suggestion {
    public readonly entityId: number;
    public readonly entityViewId: string;
    public readonly entityType: string;
    public isSelected: ko.Observable<boolean> = ko.observable(false);
    public templateName: string;
    public searchTerm: string;

    constructor(suggestionDto: SuggestionDto) {
        this.entityId = suggestionDto.entityId;
        this.entityViewId = suggestionDto.entityViewId;
        this.entityType = suggestionDto.entityType;
    }

    public onSuggestionSelected: SuggestionSelectedHandler = () => {};

    public suggestionSelected() {
        this.onSuggestionSelected(this);
    }
}
