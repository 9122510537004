///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/core/comments/register/knockout"/>
import * as ko from "knockout";
import * as _ from "underscore";
import * as $ from "jquery";
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import { Comments } from "Core/Medius.Core.Web/Scripts/Medius/core/comments/model";

const commentsStorage:any = {};

const binding = {
    init: function(element:any, bindingAccessor:any, allAccessor:any, viewModel:any, bindingContext:any) {
        let viewId:string | null = ko.utils.unwrapObservable(bindingAccessor().viewId) || null;
        let type: string | null = ko.utils.unwrapObservable(bindingAccessor().type) || null;
        let comments:any, isWrapped:boolean;

        function setWrappedEntity() {
            if (type === "Medius.Core.Entities.DocumentRegistration.Draft, Medius.Core.Common") {
                type = viewModel.EntityViewModel().$type();
                viewId = viewModel.EntityViewModel().ViewId();
                isWrapped = true;
            }

            if (type === "Medius.Core.Entities.Integration.EDIDocumentImport, Medius.Core.Common" &&
                !isNullOrUndefined(ko.unwrap(viewModel.MappedContent))) {
                type = viewModel.MappedContent().Entity().$type();
                viewId = viewModel.MappedContent().Entity().ViewId();
                isWrapped = true;
            }
        }

        $(element).on('keydown', function (e) {
            const pageUpKeyCode = 33;
            const pageDownKeyCode = 34;

            if (e.keyCode === pageUpKeyCode || e.keyCode === pageDownKeyCode) {
                e.stopPropagation();
                e.preventDefault();
            }
        });

        setWrappedEntity();

        if (_.isUndefined(commentsStorage[viewId]) || commentsStorage[viewId].counter === 0) {

            comments = new Comments(viewId, type, viewModel.Id, viewModel.isLongTermArchived, isWrapped);
            commentsStorage[viewId] = {
                vm: comments,
                counter: bindingContext.$task ? 1 : 0
            };
        } else {
            commentsStorage[viewId].counter++;
        }
       
        const newContext = bindingContext.createChildContext(commentsStorage[viewId].vm);
        ko.applyBindingsToDescendants(newContext, element);

        function passReferenceToCommentsModel() {
            const commentsModel = commentsStorage[viewId].vm;
            if (bindingContext.$task) {
                bindingContext.$task.CommentsModel = commentsModel;
            } else if (bindingContext.$root.SetCommentsModel) {
                commentsModel.allowRemoveComment(true);
                bindingContext.$root.SetCommentsModel(commentsModel);
            }
        }

        function disposeReferencedCommentsModel() {
            if (bindingContext.$task && bindingContext.$task.CommentsModel) {
                bindingContext.$task.CommentsModel = null;
            } else if (bindingContext.$root.DisposeCommentsModel) {
                bindingContext.$root.DisposeCommentsModel();
            }
        }

        koUtils.addDisposeCallback(element, function () {
            if (_.isUndefined(commentsStorage[viewId])) {
                return;
            }
            commentsStorage[viewId].counter--;
            if (commentsStorage[viewId].counter === 0) {
                commentsStorage[viewId].vm.dispose();
                disposeReferencedCommentsModel();
                delete commentsStorage[viewId];
            }
            
            if (comments) {
                comments.dispose();
            }

            $(element).off('keydown');
        });

        passReferenceToCommentsModel();

        return { controlsDescendantBindings: true };
    }
};

export function register() {
    koUtils.registerBinding("comments", binding);
}