/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/inbox/bulk/actions/update"/>
import * as ko from "knockout";
import { ajax } from "Core/Medius.Core.Web/Scripts/Medius/lib/ajax";
import { Action } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/bulk/actions/action";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";

export class Update extends Action {
    constructor(tasks: any) {
        super(tasks);
        this.id = "update";
        this.label = globalization.getLabelTranslation("#Core/bulkUpdateAction");
        this.isAvailable = ko.observable(false);
        this.isReady(false);

        ajax("BulkTemplate/ExistsUpdateView", { data: { type: this.documentType, activityName: this.activity } })
            .then((response: any) => {
                this.isAvailable(!!response);
                this.viewPath = response;
                this.isReady(true);
            });
    }
}
