///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/agentInterfaces"/>

export enum Role {
    assistant = "assistant",
    user = "user",
    system = "system"
}

export interface IMessage{
    content: string;
    role: Role;
    supplierWidgetDto?: ISupplierWidgetDto | null;
    sources: ISourceUrl[];
    invoiceContextDto?: IInvoiceContextDto | null;
    conversationId: string;
    documentId: number;
    date: string;
    isError?: boolean;
}

export interface IAgentIconComponentProps{
    documentId: number;
    taskName: string;
    isOperational: boolean;
    workflowStepName : string;
    invoiceType: string;
    getDelegationMessage: () => string;
    getEscalationMessage: () => string;
}

export interface ISourceUrl {
    url: string;
    label: string;
}

export interface IAgentFeedbackComponentProps{
    documentId: number;
    conversationId: string;
    workflowStepName : string;
    invoiceType: string;
}

export interface ISupplierWidgetDto {
    name: string;
    responsibleName: string | null;
    address: string | null;
    contact: IContact;
    nonPoInvoicesProcessedLastMonthCount: number;
    poInvoicesProcessedLastMonthCount: number;
    nonPoInvoicesProcessedThisMonthCount: number;
    poInvoicesProcessedThisMonthCount: number;
    nonPoInvoicesProcessedIn12MonthsCountAverage: number;
    poInvoicesProcessedIn12MonthsCountAverage: number;
    paymentTerm: string;
    dueDate: string;
    cashDiscount1: CashDiscount;
    cashDiscount2: CashDiscount;
}

export interface IInvoiceContextDto {
    documentTypeDescription: string | null;
    invoiceNumber: string | null;
    totalAmount: IMoney;
    invoiceDate: string;
    paymentTerm: string;
    dueDate: string;
    cashDiscount1: CashDiscount;
    cashDiscount2: CashDiscount;
    currentStep: string | null;
    supplierName: string;
    authorizationHistoryUsersPerStep: IUserDto[][];
    predictedNextApprovers: string[];
    amountToApprove: IMoney;
    latestComments: ICommentDto[];
    previousInvoices: IPreviousInvoiceDto[];
    allPreviousInvoicesCount: number;
    invoiceCodingLines: IInvoiceCodingLineDto[];
    invoiceDeviations: IInvoiceDeviationDto[];
    isInvoiceAssignedToUser: boolean;
    documentId: number;
}

export interface IInvoiceDeviationDto {
    amount: IMoney;
    type: string | null;
    approvalStatus: InvoiceLineApprovalStatus;
}

export interface IInvoiceCodingLineDto {
    amount: IMoney;
    codingDimensions: ICodingDimensions;
    approvalStatus: InvoiceLineApprovalStatus;
}

export interface ICodingDimensions {
    Dimension1 : IDimension;
    Dimension2 : IDimension;
    Dimension3 : IDimension;
    Dimension4 : IDimension;
    Dimension5 : IDimension;
    Dimension6 : IDimension;
    Dimension7 : IDimension;
    Dimension8 : IDimension;
    Dimension9 : IDimension;
    Dimension10 : IDimension;
    Dimension11 : IDimension;
    Dimension12 : IDimension;
    FreeTextDimension1 : string | null;
    FreeTextDimension2 : string | null;
    FreeTextDimension3 : string | null;
    FreeTextDimension4 : string | null;
    FreeTextDimension5 : string | null;
}

export interface IDimension {
    value: string | null;
    description: string | null;
}

export interface IContact {
    telephone: string | null;
    fax: string | null;
    homepage: string | null;
    email: string | null;
}

export interface IUserDto {
    userName: string;
    firstName: string;
    lastName: string;
}

export interface IMoney {
    value: number;
    currency: string;
}

export interface IPreviousInvoiceDto {
    id: number;
    invoiceNumber: string;
    invoiceDate: string;
    totalAmount: IMoney;
    approvalStatus: InvoiceApprovalStatus;
    hasUserAccess: boolean;
}

export interface ICommentDto {
    value: string;
    createdDate: string;
    createdBy: IUserDto;
}

export interface IEntityLogDto{
    message: string;
    messagePropertiesJson: string;
    topic: string;
    topicPropertiesJson: string;
    date: string
}

export interface CashDiscount {
    date: string | null;
    discountPercent: number | null;
}

export enum InvoiceLineApprovalStatus {
    Approved = "approved",
    Rejected = "rejected",
    NoActionTaken = "noActionTaken"
}

export enum InvoiceApprovalStatus {
    Approved = "approved",
    Rejected = "rejected",
    PartiallyApproved = "partiallyApproved",
    Invalidated = "invalidated"
}

export interface IPredefinedQuestion {
    categorizationId: number | null,
    value: string
}

export type StreamCallScenario = "freetext" | "predefined";

export interface IStreamCallOriginDetails {
    origin: StreamCallScenario,
    freetextArguments: {
        prompt: string, 
        conversationId: string, 
        oldMessages: IMessage[]
    } | null,
    predefinedArguments: { 
        predefinedQuestion: string 
    } | null
}