/// <amd-module name="Core/Medius.Core.Web/Scripts/Models/amount"/>
import * as ko from "knockout";
import mappings = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/mapping/collection/instance");

interface AmountDto {
    $type?: string;
    CurrencyCode: string;
    DisplayValue: string | number;
}

export class Amount {
    public static readonly amountType = "Medius.Core.DTOs.AmountDto, Medius.Core.Common";

    public $type: string;
    public readonly CurrencyCode: ko.Observable<string>;
    public readonly DisplayValue: ko.Observable<number>;

    constructor(data: AmountDto) {
        this.CurrencyCode = ko.observable(data.CurrencyCode);
        const displayValueNumber = (typeof data.DisplayValue === "string") ? parseFloat(data.DisplayValue) : data.DisplayValue; 
        this.DisplayValue = ko.observable(displayValueNumber);
    }

    public toJS(): AmountDto {
        const value = this.DisplayValue();

        return {
            $type: this.$type,
            CurrencyCode: this.CurrencyCode(),
            DisplayValue: value?.toString() ?? "0",
        };
    }

    public update(data: AmountDto) {
        if (this.CurrencyCode() !== data.CurrencyCode) {
            this.CurrencyCode(data.CurrencyCode);
        }

        const displayValueNumber = (typeof data.DisplayValue === "string") ? parseFloat(data.DisplayValue) : data.DisplayValue;

        if (this.DisplayValue() !== displayValueNumber) {
            this.DisplayValue(displayValueNumber);
        }
    }
}

Amount.prototype.$type = Amount.amountType;

export function create(data: AmountDto) {
    return new Amount(data);
}

export function createFromValues(displayValue: number, currencyCode: string) {
    return create({ DisplayValue: displayValue, CurrencyCode: currencyCode });
}

export function zero(currencyCode: string) {
    return new Amount({
        CurrencyCode: currencyCode,
        DisplayValue: "0"
    });
}

export function instanceOf(vm: any) {
    return vm instanceof Amount;
}

export function register() {
    mappings.register(Amount.amountType, { make: create }, undefined);
}
