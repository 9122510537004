/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/comments/commentsService"/>
import * as rest from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rest";

const commentsResource = "comments";

interface CommentDto {
    id: number;
    tokenizedText: Token[];
    author: string;
    createdDate: Date;
    hash: string;
    isCarriedOver: boolean;
}

interface Token {
    tokenType: string;
    tokenValue: TokenValue;
}

interface TokenValue {
    title: string;
    description: string;
    value: string;
}

export function get(entityViewId: string, entityType: string): JQueryPromise<CommentDto[]> {
    return rest.get(commentsResource, "", {
        entityViewId,
        entityType
    }, {cache: false});
}

export function post(comment: string, entityViewId: string, entityType: string): JQueryPromise<CommentDto> {
    return rest.post(commentsResource, "", {
        comment,
        entityViewId,
        entityType
    });
}

export function remove(hash: string) {
    return rest.del(commentsResource, hash);
}
