/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/lib/utils/user"/>
import * as _ from "underscore";
import { log } from "Core/Medius.Core.Web/Scripts/Medius/lib/logger";
import { store } from "Core/Medius.Core.Web/Scripts/shared/store/reduxStore";
import { setUser } from "Core/Medius.Core.Web/Scripts/components/user/userContextState";
import { getCanduToken } from "Core/Medius.Core.Web/Scripts/appConfig";
import { SupplierConversationDemoModalEvents } from "../events";

export interface UserDto {
    Id: number;
    PersonalRoleId: number;
    UserName: string;
    FirstName: string;
    LastName: string;
    Format: string;
    Language: string;
    Login?: string;
    DefaultCompanyId?: number;
    IdentityProviderName?: string;
    ViewId: string;
}

export function getInitials(userDto: UserDto) {
    function initial(str: string | undefined) {
        const s = str?.trim();
        return s
            ? s.substr(0, 1).toUpperCase()
            : "";
    }

    const firstNameInitial = initial(userDto?.FirstName);
    const lastNameInitial = initial(userDto?.LastName);
    return `${firstNameInitial}${lastNameInitial}`;
}

export function getFullName(userDto: UserDto) {
    return `${userDto?.FirstName} ${userDto?.LastName} (${userDto?.UserName})`.trim();
}

// TODO: The rest of the file should be removed once we get rid of this Singleton instance of User (except call to setUser(user))

let currentUser: UserDto | null = null;
let defaultCompany: any = null;

function clone(user: UserDto) {
    const jsonified = JSON.stringify(user);
    return JSON.parse(jsonified);
}

export function getDefaultCompany() {
    return defaultCompany;
}

export function setDefaultCompany(company: any) {
    defaultCompany = company;
}

export function register(user: UserDto) {
    currentUser = user;
    store.dispatch(setUser(user));

    if (currentUser) {
        log(`Current user: ${user.UserName} (${user.Id})`);
    }
}

export function registerCandu(csp: any) {
    const canduToken = getCanduToken();
    if (canduToken) {
        const userId = (currentUser as any).GlobalId as string;
        // push traits
        fetch(`remedium-api/growth/v1/users/${userId}/traits/push-candu`,  { method: "POST" });

        // then launch candu
        (function (d, params) {
            const script = d.createElement('script');
            script.setAttribute('src', 'https://cdn.candu.ai/sdk/latest/candu.umd.js?token=' + canduToken);
            script.setAttribute('nonce', csp);
            script.onload = function () {
                (window as any).Candu.init(params);
            };
            d.head.appendChild(script);
        })(document, {
            userId,
            clientToken: canduToken,
            callbacks: {
                refreshMembership: function (): void {
                    (window as any).Candu?.refreshMembership();
                },
                launchCopilot: function (): void {
                    const copilotIconButton : HTMLElement = document.querySelector('[data-testid="apaAgentIconButton"]');
                    if (copilotIconButton) {
                        copilotIconButton.click();
                    }
                },
                openSupplierDemoModalFull: function (): void {
                    window.dispatchEvent(new CustomEvent(SupplierConversationDemoModalEvents.openFull));
                },
                openSupplierDemoModalSmall: function (): void {
                    window.dispatchEvent(new CustomEvent(SupplierConversationDemoModalEvents.openSmall));
                },
                closeSupplierDemoModal: function (): void {
                    window.dispatchEvent(new CustomEvent(SupplierConversationDemoModalEvents.close));
                }
            }
        });
    }
}

export function registerBeamer(csp: string) {
    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.src = 'https://app.getbeamer.com/js/beamer-embed.js';
    script.setAttribute('nonce', csp);
    script.defer = true;
    script.onload = async function () {
        try {
            const userId = (currentUser as any).GlobalId as string;

            const response = await fetch(`remedium-api/growth/v1/users/${userId}/traits`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const userTraits = await response.json();

            const beamerConfig = {
                product_id: 'ldmHrElp67573',
                button: false,
                user_id: userId,
                user_firstname: userTraits.firstName,
                user_lastname: userTraits.lastName,
                user_email: userTraits.email,
                filter: userTraits.enabledModules.join(';'),
                language: userTraits.language,
                username: userTraits.userName,
                type: userTraits.type,
                isActive: userTraits.isActive,
                isAdminUser: userTraits.isAdminUser,
                isApClerk: userTraits.isApClerk,
                isSuperUser: userTraits.isSuperUser,
                tenantId: userTraits.tenantId
        };

            (window as any).beamer_config = beamerConfig;
        } catch (error) {
            console.error('Failed to configure Beamer:', error);
        }
    };

    document.head.appendChild(script);
}

export function reset() {
    currentUser = null;
    defaultCompany = null;
}

export function getCurrent() {
    // Clone user to avoid it being a global object and prone for bugs where accidental change is done
    return currentUser ? clone(currentUser) : null;
}

export function getCurrentRole() {
    if (!currentUser) { return null; }

    return {
        Id: currentUser.PersonalRoleId,
        $type: "Medius.Core.Entities.Role, Medius.Core.Common"
    };
}

export function getLanguageCode() {
    if (currentUser) {
        return currentUser.Language != "en-legacy"
            ? currentUser.Language
            : "en-US";
    }

    return null;
}

export function getCultureCode() {
    return currentUser ? currentUser.Format : null;
}

export function getEntity() {
    if (!currentUser) {
        throw new Error("User is not logged into system");
    }

    return _({}).extend(currentUser, {
        $type: "Medius.Core.Entities.User, Medius.Core.Common"
    });
}

export function getInitialsOld() {
    function initial(str: string | undefined) {
        const s = str?.trim();
        return s
            ? s.substr(0, 1).toUpperCase()
            : "";
    }

    const firstNameInitial = initial(currentUser?.FirstName);
    const lastNameInitial = initial(currentUser?.LastName);
    return `${firstNameInitial}${lastNameInitial}`;
}

export function getDisplayName() {
    return currentUser?.FirstName?.trim()
        || currentUser?.UserName
        || "";
}

export function getFullNameOld() {
    return `${currentUser?.FirstName} ${currentUser?.LastName} (${currentUser?.UserName})`.trim();
}

export function getFirstName() {
    return `${currentUser?.FirstName}`;
}

export function getPersonalRoleDTO() {
    return currentUser
        ? {
            $type: "Medius.Core.DTOs.RoleDto, Medius.Core.Common",
            Id: currentUser.PersonalRoleId,
            AssigneeName: getFullNameOld(),
            OwnerId: currentUser.Id
        } : null;
}

export function getId() {
    return currentUser?.Id;
}

export function getViewId() {
    return currentUser?.ViewId;
}
