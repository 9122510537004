/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/wizard/steps/wizardStep"/>

import { IWizardStep } from "Core/Medius.Core.Web/Scripts/Medius/components/wizard/steps/IWizardStep";
import { TypedWizard } from "Core/Medius.Core.Web/Scripts/Medius/components/wizard/typedWizard";
import { Observable, observable } from "knockout";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";

export class WizardStep<TData> implements IWizardStep<TData> {
    public name: string;
    public template: string;
    public buttonNextLabel: string;
    public isButtonYesVisible: Observable<boolean>;
    public isNextStepEnable: Observable<boolean>;
    public readonly isFullyCustom = observable(false);
    public isNewSupplierAutocompleterEnabled: Observable<boolean>;

    public constructor(name: string, template: string) {
        this.name = name;
        this.template = template;
        this.buttonNextLabel = getLabelTranslation("#Core/next");
        this.isButtonYesVisible = observable(false);
        this.isNextStepEnable = observable(true);
        this.isNewSupplierAutocompleterEnabled = observable(false);
    }

    public onOpen(wizard: TypedWizard<TData>) {

    }

    public onOpenBack(wizard: TypedWizard<TData>) {

    }

    public validate(wizard: TypedWizard<TData>): boolean {
        return true;
    }

    public beforeNext(wizard: TypedWizard<TData>) {
        
    }

    public beforePrev(wizard: TypedWizard<TData>) {

    }

    public dispose(wizard: TypedWizard<TData>) {

    }
}
