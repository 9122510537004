///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/editors/financeComponent/model"/>
import * as ko from "knockout";
import * as _ from "underscore";
import * as helpers from "Core/Medius.Core.Web/Scripts/Medius/components/editors/helpers";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as settingsProvider from "Core/Medius.Core.Web/Scripts/Medius/core/settingsProvider";
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as serialization from "Core/Medius.Core.Web/Scripts/Medius/lib/serialization";
import * as number from "Core/Medius.Core.Web/Scripts/Medius/components/editors/number/model";
import * as localizer from "Core/Medius.Core.Web/Scripts/Medius/components/editors/financeComponent/localizer";
import * as koDisposal from "Core/Medius.Core.Web/Scripts/Medius/knockout/disposal";
import * as koSync from "Core/Medius.Core.Web/Scripts/Medius/knockout/observables/synchronization";

export class FinanceComponentField {
    CurrencyCode: ko.Observable<any>;
    updateResolution: any;
    ShowCurrency: boolean;
    EnableCurrency: boolean;
    AvaliableCurrencies: any[];
    toggleCurrency: (currencyInput: any) => void;
    dispose: () => void;
    constructor(bindingParameters:any, viewmodel:any, context:any) {
        const tplName = "edit-finance-component-default";
        const displayValue = ko.observable();
        let financeComponentDisplayValueSub:any;
        let displayValueSync:any;
        let financeCurrencySub:any;
        let currencyCodeSync:any;
        let financeComponentUnwrapped:any;
        let financeComponentSubscription:any;
        let financialLocalizer:any;


        const financeComponentSubscriptionsUpdate = (financeComponentObject:any) => {
            displayValueSubscriptionUpdate(financeComponentObject);
            currencySubscriptionUpdate(financeComponentObject);
        };

        const displayValueSubscriptionUpdate = (financeComponentObject:any) => {
            koDisposal.tryDispose(financeComponentDisplayValueSub);
            koDisposal.tryDispose(displayValueSync);

            displayValueSync = koSync.twoWay(financeComponentObject.DisplayValue, displayValue);
            financeComponentDisplayValueSub = financeComponentObject
                && financeComponentObject.DisplayValue.subscribe(() => {
                    financeComponent.notifySubscribers(financeComponentObject);
                });
        };

        const currencySubscriptionUpdate = (financeComponentObject:any) => {
            koDisposal.tryDispose(financeCurrencySub);
            koDisposal.tryDispose(currencyCodeSync);

            currencyCodeSync = koSync.twoWay(financeComponentObject.CurrencyCode, this.CurrencyCode);
            financeCurrencySub = financeComponentObject
                && financeComponentObject.CurrencyCode.subscribe(() => {
                    this.updateResolution();
                    financeComponent.notifySubscribers(financeComponentObject);
                });

            this.updateResolution();
        };

        const synchronizeFinanceComponent = (financeComponentObject:any) => {
            if (financeComponentObject === financeComponentUnwrapped) {
                return;
            }

            financeComponentUnwrapped = financeComponentObject;
            financeComponentSubscriptionsUpdate(financeComponentObject);
        };
        const defaults:any = {
            template: tplName,
            align: 'right',
            maxValue: null,
            minValue: null,
            isInteger: false,
            trimZeros: false,
            roundDecimal: 'midpoint',
            notLocalized: false,
            resolution: ko.observable(3),
            showCurrency: false,
            enableCurrency: true,
            options: {
                defaultName: "editor-finance-component",
                tooltip: {
                    enabled: false
                }
            }
        };

        defaults.template = bindingParameters.showCurrency ? "edit-finance-component-with-currency" : "edit-finance-component-default";

        helpers.mergeDefaults(bindingParameters, defaults);

        const financeComponent = defaults.value;

        this.ShowCurrency = defaults.showCurrency;
        this.EnableCurrency = defaults.enableCurrency;
        this.CurrencyCode = ko.observable();

        this.updateResolution = () => {
            const currencyCode = koUtils.unpack(this.CurrencyCode),
                resolution = this.getResolution(currencyCode);
            defaults.resolution(resolution);
        };

        if (ko.isObservable(financeComponent) && !_.isNull(financeComponent())) {
            financeComponentSubscription = financeComponent.subscribe(synchronizeFinanceComponent);
            synchronizeFinanceComponent(financeComponent());
        }
        else {
            defaults.disabled = true;
        }

        defaults.value = displayValue;

        this.AvaliableCurrencies = settingsProvider.getAvaliableCurrencies();
        this.toggleCurrency = (currencyInput) => {
            const selectedCurrency = serialization.fromJS(currencyInput);
            this.CurrencyCode(koUtils.unpack(selectedCurrency.Code));
        };

        let validator = (nmbr:number|string) => {
            if (defaults.disabled || (!defaults.required && _.isNull(nmbr))) {
                return { valid: true };
            }
            
            if (defaults.required && _.isNull(nmbr)) {
                const details:any = this.getValidationErrorMsgKey();
                return {
                    valid: false,
                    validationDetails: globalization.getLabelTranslation(details)
                };
            }
            else {
                if (typeof (nmbr) === "string") {
                    nmbr = parseFloat(nmbr);
                }
                return helpers.isValueInRange(
                    nmbr,
                    koUtils.unpack(defaults.minValue),
                    koUtils.unpack(defaults.maxValue)
                );
            }
        };
        defaults.validator = validator;

        financialLocalizer = ko.computed(() => {
            const resolution = koUtils.unpack(defaults.resolution);
            return localizer.create(
                resolution,
                defaults.trimZeros,
                defaults.roundDecimal,
                defaults.notLocalized
            );
        });

        let numberModel = number.create(defaults, viewmodel, context, {
            localizer: financialLocalizer
        });
        _(this).extend(numberModel);

        this.dispose = function () {
            koDisposal.tryDispose(displayValueSync);
            koDisposal.tryDispose(currencyCodeSync);
            koDisposal.tryDispose(financeComponentSubscription);
            koDisposal.tryDispose(financeComponentDisplayValueSub);
            koDisposal.tryDispose(financeCurrencySub);
            koDisposal.tryDispose(financialLocalizer);
            numberModel.dispose();
            financialLocalizer = null;
            validator = null;
            numberModel = null;
        };
    }
    getResolution(currencyCode:any) {
        throw new Error("Function getResolution is not implemented in " + this.constructor.name);
    }

    getValidationErrorMsgKey() {
        throw new Error("Function getValidationErrorMsg is not implemented in " + this.constructor.name);
    }
}