/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/editors/utils"/>
import * as componentUtils from "Core/Medius.Core.Web/Scripts/Medius/components/utils";
import * as _ from "underscore";

export function getConfiguration(name: any, params: any): any {    
    const configuration = _({
        factory: resolveModuleName(params.module || name),
        continuation: "Core/Medius.Core.Web/Scripts/Medius/components/editors/bindingContinuation"
    }).extend(params);
    return componentUtils.getConfiguration(name, configuration);
}

function resolveModuleName(name: string) {
    switch (name) {
        case "amount":
            return "Core/Medius.Core.Web/Scripts/Medius/components/editors/amount/model";
        case "unitprice":
            return "Core/Medius.Core.Web/Scripts/Medius/components/editors/unitprice/model";
        case "quantity":
            return "Core/Medius.Core.Web/Scripts/Medius/components/editors/quantity/model";
        case "percentage":
            return "Core/Medius.Core.Web/Scripts/Medius/components/editors/percentage/model";
        case "timespan":
            return "Core/Medius.Core.Web/Scripts/Medius/components/editors/timespan/model";
        case "bool":
            return "Core/Medius.Core.Web/Scripts/Medius/components/editors/bool/model";
        case "date":
            return "Core/Medius.Core.Web/Scripts/Medius/components/editors/date/model";
        case "number":
            return "Core/Medius.Core.Web/Scripts/Medius/components/editors/number/model";
        case "text":
            return "Core/Medius.Core.Web/Scripts/Medius/components/editors/text/model";
        case "textTranslation":
            return "Core/Medius.Core.Web/Scripts/Medius/components/editors/textTranslation/model";
        case "email":
            return "Core/Medius.Core.Web/Scripts/Medius/components/editors/email/model";
        case "autocompleter":
            return "medius/components/editors/autocompleter/model";
        case "autocompleter-entity":
            return "medius/components/editors/autocompleter/entity/model";
        case "autocompleter-customfieldvalue":
            return "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/customfields/listValue/factory";
        case "autocompleter/dataObjectType":
            return "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/dataObjectType/model";
        case "autocompleter/company/factory":
            return "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/company/factory";
        case "autocompleter/role":
            return "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/role/model";
        case "autocompleter/redistributionItem":
            return "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/redistributionItem/model";
        case "autocompleter/approvalGroup":
            return "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/approvalGroup/model";
        case "dimension":
            return "medius/components/editors/dimension/model";
        case "preferred-payment":
            return "PurchaseToPay/Medius.PurchaseToPay.Web/Scripts/Medius/components/editors/preferredPayment/model";    
        default:
            throw new Error(`Unexpected module name: ${name}`);
    }
}

export const emitBinding = componentUtils.emitBinding.bind(componentUtils, "edit");
