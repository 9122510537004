/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/kendo/culture"/>
import {log, warn} from "Core/Medius.Core.Web/Scripts/Medius/lib/logger";
import {getCultureCode} from "Core/Medius.Core.Web/Scripts/Medius/lib/utils/user";
import * as culture from "Core/Medius.Core.Web/Scripts/Medius/lib/culture";

const userCultureCode = getCultureCode();
if (kendo.cultures[userCultureCode]) {
    kendo.culture(userCultureCode);
    log(`Kendo UI - culture set to: '${userCultureCode}'.`);
} else {
    const userBaseCode = culture.baseCodeFor(userCultureCode);
    const availableCulture = kendo.cultures[userBaseCode]
        || kendo.cultures[culture.getCodeForInvariantCulture()];

    kendo.culture(availableCulture.name);
    warn(`Kendo UI - there is no culture: '${userCultureCode}'. A '${availableCulture.name}' will be used.`);
}

