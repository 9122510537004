/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/presenters/unitprice/model"/>
import * as settingsProvider from "Core/Medius.Core.Web/Scripts/Medius/core/settingsProvider";
import { FinanceComponent } from "Core/Medius.Core.Web/Scripts/Medius/components/presenters/financeComponent/model";

type UnitPriceParams = {
    value: ko.Observable<number>;
};

class UnitPrice extends FinanceComponent{
    create: () => any;
    constructor(bindingParameters: UnitPriceParams){
        super(bindingParameters);
    }

    getResolution(currencyCode: string) {
        return settingsProvider.getUnitPriceDisplayResolution(currencyCode);
    }
}

export function create (bindingParameters: UnitPriceParams) {
    return new UnitPrice(bindingParameters).create();
}