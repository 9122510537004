/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/AutoCoding/dimensionWithOverridenValues"/>
import { DimensionOverridenValue } from "Core/Medius.Core.Web/Scripts/AdminPages/AutoCoding/dimensionOverridenValue";
import * as ko from "knockout";

export default class DimensionWithOverridenValues {

    public getDimensionOverridenValue: (dimensions: any) => DimensionOverridenValue;
    public getDimensions: (dimensions: any) => any;

    private readonly getDimension: (dimensions: any) => any;

    constructor(context: any) {

        this.getDimensionOverridenValue = (dimension: any) => {
            if (dimension !== null && dimension.Id() === 0) {
                const overridenValue = ko.unwrap(dimension.OverridenValue);
                if (overridenValue !== undefined) {
                    return overridenValue;
                }
            }

            return DimensionOverridenValue.Configured;
        };

        this.getDimension = (dimension: any) => {
            if (dimension !== null && dimension.Id() === 0) {
                const overridenValue = ko.unwrap(dimension.OverridenValue);
                if (overridenValue === DimensionOverridenValue.ERP
                    || overridenValue === DimensionOverridenValue.SaC
                    || overridenValue === DimensionOverridenValue.SaD) {
                    return null;
                }
            }
            return context.toJS(dimension);
        };
        
        this.getDimensions = (dimensions: any) => ({
            $type: "Medius.Enterprise.Entities.Accounting.Dimensions.CodingDimensions, Medius.Enterprise.Common",
            Dimension1: this.getDimension(dimensions.Dimension1()),
            Dimension2: this.getDimension(dimensions.Dimension2()),
            Dimension3: this.getDimension(dimensions.Dimension3()),
            Dimension4: this.getDimension(dimensions.Dimension4()),
            Dimension5: this.getDimension(dimensions.Dimension5()),
            Dimension6: this.getDimension(dimensions.Dimension6()),
            Dimension7: this.getDimension(dimensions.Dimension7()),
            Dimension8: this.getDimension(dimensions.Dimension8()),
            Dimension9: this.getDimension(dimensions.Dimension9()),
            Dimension10: this.getDimension(dimensions.Dimension10()),
            Dimension11: this.getDimension(dimensions.Dimension11()),
            Dimension12: this.getDimension(dimensions.Dimension12()),
            FreeText1: context.toJS(dimensions.FreeText1()),
            FreeText2: context.toJS(dimensions.FreeText2()),
            FreeText3: context.toJS(dimensions.FreeText3()),
            FreeText4: context.toJS(dimensions.FreeText4()),
            FreeText5: context.toJS(dimensions.FreeText5()),
            InternalIdentifier: "1",
            Perspective: null
        });
    }
}
