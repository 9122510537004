///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/dataSearch/model"/>
import * as rest from "Core/Medius.Core.Web/Scripts/Medius/core/rest";
import * as restJson from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rest";
import * as serialization from "Core/Medius.Core.Web/Scripts/Medius/lib/serialization";
import * as logger from "Core/Medius.Core.Web/Scripts/Medius/lib/logger";
import * as notification from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as ko from "knockout";
import * as backendErrorHandler from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import * as _ from "underscore";
import { isNotNullOrUndefined, isEmptyString } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import * as resultGroup from "Core/Medius.Core.Web/Scripts/Medius/apps/documentSearch/resultGroup/resultGroup";

function serializeAndEncodeJSON(obj: any) {
    const stringifiedTree = serialization.toJSON(obj);
    return encodeURIComponent(stringifiedTree);
}

function failHandler(fail: backendErrorHandler.XHR, translationKey: string) {
    backendErrorHandler.handleAnyError(fail, null, translationKey);
}

class Model {
    ColumnConfiguration = ko.observableArray();
    FinalQueryObject = ko.observable(null);
    savedQueries = ko.observableArray();
    selectedQuery = ko.observable(null);
    typedReceiver = ko.observable(null);
    isLoading = ko.observable(false);
    isQueryFinished = ko.observable(false);
    resultGroup = ko.observable();

    parseSearchResult(result: { truncatedQuery: any; Entities: string | any[]; ColumnDefinitions: any; }, finalQueryObject: { Query: any; Columns: string; }) {
        result.truncatedQuery = finalQueryObject;

        if (result.Entities.length === 0) {
            return null;
        }

        //this should be exposed by component API
        //[0].columns will be changed when component will return singe collection
        this.ColumnConfiguration(_.map(result.ColumnDefinitions, (item) => {
            return item.ValueSource;
        }));

        return resultGroup.create(result);
    }

    prepareSavedQueryObject(queryTree: any) {
        let columnConfig = "";

        if (isNotNullOrUndefined(this.ColumnConfiguration())) {
            columnConfig = this.ColumnConfiguration().join("|");
        }

        const savedQueryObject = {
            Query: queryTree,
            Columns: columnConfig
        };

        logger.log(savedQueryObject);

        this.FinalQueryObject(savedQueryObject);
        return savedQueryObject;
    }

    fetchResults(queryTree: any, additionalQueryString: any) {
        this.isLoading(true);

        const finalQueryObject = this.prepareSavedQueryObject(queryTree);
        const encodedTree = serializeAndEncodeJSON(finalQueryObject);
        this.resultGroup(null);

        return rest.get("DataSearchManager", "searchPaged?query=" + encodedTree + "&page=1&limit=10")
            .always(() => {
                this.isLoading(false);
            })
            .done((result: { truncatedQuery: any; Entities: string | any[]; ColumnDefinitions: any; }) => {
                this.resultGroup(this.parseSearchResult(result, finalQueryObject));
            })
            .fail((fail: backendErrorHandler.XHR) => {
                failHandler(fail, "#Core/resultsFetchedUnsuccessfully");
            });
    }

    saveQueryChanges(queryTree: any) {
        const currentId = _.isNull(this.selectedQuery()) ? null : this.selectedQuery().Id,
            name = _.isNull(this.selectedQuery()) ? null : this.selectedQuery().Name;

        return this.saveQuery(currentId, queryTree, name).done((result) => {

            if (!this.selectedQuery().Id) {
                this.selectedQuery().Id = result;
            }
        });
    }

    saveAsNewQuery(queryTree: any, queryName: any) {
        return this.saveQuery(null, queryTree, queryName);
    }

    saveQuery(id: string, queryTree: any, queryName: any) {
        if (isEmptyString(queryName)) {
            notification.error("s! the query or name is empty");
            return null;
        }

        const finalQueryObject = this.prepareSavedQueryObject(queryTree);
        const serializedTree = serialization.toJSON(finalQueryObject);
        const dto = { Name: queryName, Query: serializedTree };

        if (id) {
            return restJson.put("DataSearchManager", "saveQuery/" + id, dto);
        } else {
            return restJson.post("DataSearchManager", "saveQuery", dto);
        }
    }

    shareSavedQuery() {
        const savedQuery = this.selectedQuery(),
            receiver = this.typedReceiver();

        if (savedQuery && receiver) {
            rest.post("DataSearchManager", 'shareSavedQuery?queryId=' + savedQuery.Id + '&userId=' + receiver.Id._latestValue, undefined)
                .done(() => {
                    notification.success(globalization.getLabelTranslation("#Core/filterSharedSuccessfully"));
                })
                .fail((fail: backendErrorHandler.XHR) => {
                    failHandler(fail, "#Core/filterSharedUnsuccessfully");
                });
        }
    }

    deleteSavedQuery(query: { Id: any; }) {
        const queryId = query.Id;

        if (query) {
            if (this.selectedQuery() && this.selectedQuery().Id === queryId) {
                this.selectedQuery(null);
            }

            rest.del("DataSearchManager", 'deleteSavedQuery?id=' + queryId, undefined).fail((fail: backendErrorHandler.XHR) => {
                failHandler(fail, "#Core/filterDeletedUnsuccessfully");
            });
        }
    }

    refreshSavedQueries() {
        this.selectedQuery(null);
    }

    removeUnsavedQuery() {
        const withoutUnsaved = _(this.savedQueries()).filter((item:any) => {
            return item.Id !== -1;
        });

        this.savedQueries(withoutUnsaved);
    }
}

export function create() {
    return new Model();
}