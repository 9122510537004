/// <amd-module name="Core/Medius.Core.Web/Scripts/components/links/linksWithSections"/>

import * as React from 'react';
import { Button, Card, StringMap, Notification } from '@medius/ui-controls';
import { LinksModal } from 'Core/Medius.Core.Web/Scripts/components/links/linksModal/linksModal';
import { useEffect, useState } from 'react';
import { handleError } from 'Core/Medius.Core.Web/Scripts/lib/errorHandling/errorHandler';
import { get, del } from "Core/Medius.Core.Web/Scripts/Medius/core/fetch/rest";
import { LinksWithCategoriesViewModel, LinksWithCategoriesDto } from 'Core/Medius.Core.Web/Scripts/components/links/linkViewModel';
import { ReferencedDocuments } from 'Core/Medius.Core.Web/Scripts/components/links/referencedDocuments';
import { DuplicatedDocuments } from 'Core/Medius.Core.Web/Scripts/components/links/duplicatedDocuments';
import { GenericLinks } from 'Core/Medius.Core.Web/Scripts/components/links/genericLinks';
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { LinkViewModelBase } from 'Core/Medius.Core.Web/Scripts/components/links/linkViewModel';
import { LocalizationContext, IconStatusInfoRegular } from '@medius/ui-controls';
import { getCultureCode } from "Core/Medius.Core.Web/Scripts/Medius/lib/utils/user";
import { getCurrentCulture } from "Core/Medius.Core.Web/Scripts/lib/reactIntegration/kendoIntl";
import { mapFromNorthstarToApa } from "Core/Medius.Core.Web/Scripts/lib/northstarLocalization";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";

interface LinksWithSectionsProps {
    entityViewId: string;
    entityType: string;
    onLinksCountChanged: (value: number) => void;
}

export const LinksWithSections = (props: LinksWithSectionsProps) => {
    const [links, setLinks] = useState<LinksWithCategoriesViewModel>();
    const [modalVisible, setModalVisible] = useState(false);

    const removeLink = (entityLinkToRemove: LinkViewModelBase) => {
        del("Backend/Rest/links", entityLinkToRemove.RawId )
        .then(() => { 
            load();
        })
        .catch(reason => handleError(reason));
    };
    
    const load = () => {
        get("links", {
            entityViewId: props.entityViewId,
            entityType:  props.entityType
        }).then((results: LinksWithCategoriesDto) => {
                const linksWithCategoriesViewModel = new LinksWithCategoriesViewModel(results);
                props.onLinksCountChanged(linksWithCategoriesViewModel.linksCnt);
                setLinks(linksWithCategoriesViewModel);
            }
        )
        .catch(reason => {
            handleError(reason);
        });
    };

    useEffect(() => {
        load();
    }, []);

    const onModalCloseHandler = () => {
        load();
        setModalVisible(false);
    };

   return (
    <div>
        <LocalizationContext.Provider value={{
            translate: (key: string, _, defaultValue?: string, args?: StringMap) => mapFromNorthstarToApa(key, defaultValue, args),
            getLocale: () => getCultureCode()
        }} >
            <IntlProvider locale={getCurrentCulture()}>
                <LocalizationProvider language="current-language">
                        <Button data-testid='linksWithSections-addLinksButton' variant="secondary" label={"+ "+translate("#Core/addLink")} onClick={() => { setModalVisible(true); }}/>
                    {(!links || links.linksCnt === 0) ? (<NoLinksSpecified />) : (<div className="linksWithSections_mainContainer">
                            <LinkSpecificSections onItemDelete={(item) => removeLink(item)} links={links} isHistorical={false} />
                            {links.isThereAnyHistoricalLink ? 
                            (
                                <div className='linksWithSections_historicalContainer'>
                                    <Notification data-testid="historical-section-notification" feedbackType="info" title={translate('#PurchaseToPay/linksBelowWereAddedToPreviousVersionOfDocument')} text=""/>
                                    <Card data-testid="historical-grid-sections" title={translate('#PurchaseToPay/historicalLinks')}>
                                        <LinkSpecificSections onItemDelete={(item) => removeLink(item)} links={links} isHistorical={true} />
                                    </Card>
                                </div>
                            )
                            : <></>}
                        </div>)}
                        <LinksModal sourceEntityType={props.entityType} sourceEntityViewId={props.entityViewId} modalVisible={modalVisible} onModalClose={onModalCloseHandler} />
                </LocalizationProvider>
            </IntlProvider>
        </LocalizationContext.Provider>
    </div>);
};

const NoLinksSpecified = () => {
    return (
        <div data-testid="linksWithSections-no-links" className="noLinksSpecified__container">
            <div className='noLinksSpecified__headerContainer'>
                <IconStatusInfoRegular size="custom" color="container" className="noLinksSpecified__iconInfo"/>
                <span data-testid="linksWithSections-no-links-header" className="noLinksSpecified__header">{translate('#PurchaseToPay/noLinkedDocuments')}</span>
            </div>
            <div data-testid="linksWithSections-no-links-mainText" className="noLinksSpecified__mainText">{translate('#PurchaseToPay/noExistingDocumentHasBeenLinkedToCurrentOne')}</div>
        </div>
    );
};

interface LinkSpecificSectionsProps {
    links: LinksWithCategoriesViewModel
    isHistorical: boolean;
    onItemDelete?: (item: LinkViewModelBase) => void;
}

const LinkSpecificSections = (props: LinkSpecificSectionsProps) => {
    const referencedDocuments = props.links.getReferencedDocumentsLinks(props.isHistorical);
    const duplicateLinks = props.links.getDuplicatedLinks(props.isHistorical);
    const customLinks = props.links.getCustomLinks(props.isHistorical);
    const reclasiffied = props.links.getReclasiffiedLinks(props.isHistorical);
    return (<>
        {duplicateLinks.length === 0 ?
            <></>
            : <DuplicatedDocuments isHistorical={props.isHistorical} links={duplicateLinks}/>}
        {referencedDocuments.length === 0 ?
            <></>
            : <ReferencedDocuments onItemDelete={props.onItemDelete} isHistorical={props.isHistorical} links={referencedDocuments}/>}
        {customLinks.length === 0 ?
            <></>
            : <GenericLinks onItemDelete={props.onItemDelete} isHistorical={props.isHistorical} header={translate('#PurchaseToPay/otherLinks')} links={customLinks}/>}
        {reclasiffied.length === 0 ?
            <></>
            : <GenericLinks onItemDelete={props.onItemDelete}  isHistorical={props.isHistorical} header={translate('#PurchaseToPay/reclassify')} links={reclasiffied}/>}
    </>);
};