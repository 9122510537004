/// <amd-module name="Core/Medius.Core.Web/Scripts/lib/messageLogging/logService"/>
import * as rest from "Core/Medius.Core.Web/Scripts/Medius/core/communication/remediumApi";
import { Log, Context } from "./models";
import { getBasePath } from "../../Medius/lib/path";

export function log(logs: Log[], context: Context, totalLogCount = logs.length): Promise<{}> {    
    return rest.post(`${getBasePath()}remedium-api/frontend-log/logs`, {
        logs,
        context,
        totalLogCount
    });
}
