///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/core/cloudNotifications/notification"/>
import {get} from "Core/Medius.Core.Web/Scripts/Medius/core/communication/remediumApi";
import * as path from "Core/Medius.Core.Web/Scripts/Medius/lib/path";
import * as ko from "knockout";

class cloudNotification {
    isLocalStorageAvailable: () => boolean;
    hasAnyNotification: ko.Observable<boolean>;
    notificationText: ko.Observable<any>;
    notificationId: ko.Observable<any>;
    getNotificationKeyPrefix: () => string;
    getNotificationKey: () => string;
    isNotificationRelatedKey: (key: string) => boolean;
    clearOldNotifications: () => void;
    hideNotification: () => void;
    notificationHiddenByUser: () => boolean;
    showNotification: ko.Computed<boolean>;
    showNotificationSub: any;
    init: () => void;
    constructor() {
        this.isLocalStorageAvailable = function() {
            const test = 'test';
            try {
                localStorage.setItem(test, test);
                localStorage.removeItem(test);
                return true;
            } catch(e) {
                return false;
            }
        };
        
        this.hasAnyNotification = ko.observable(false);
        this.notificationText = ko.observable();
        this.notificationId = ko.observable();


        this.getNotificationKeyPrefix = function() {
            return "HiddenNotification_" + path.getTenantId();
        };

        this.getNotificationKey = () => {
            return this.getNotificationKeyPrefix() + "_" + this.notificationId();
        };

        this.isNotificationRelatedKey = (key:string) => {
            return key != null && key.indexOf(this.getNotificationKeyPrefix()) === 0;
        };

        this.clearOldNotifications = () => {
            const noOfDays = 90; //all notification settings older than 3 months should be wiped out
            for (let i = 0, len = localStorage.length; i < len; ++i) {
                const key = localStorage.key(i);
                if (this.isNotificationRelatedKey(key)) {
                    const date = new Date(parseInt(localStorage.getItem(key)));
                    const diff = new Date().getTime() - (date as any);
                    const maxAllowedDiff = noOfDays * 24 * 3600 * 1000; //86400000 is number of miliseconds per day
                    if (diff > maxAllowedDiff) {
                        localStorage.removeItem(key);
                    }
                }
            }
        };
        
        this.hideNotification = () => {
            if (!this.isLocalStorageAvailable) {
                return;
            }
            localStorage.setItem(this.getNotificationKey(), new Date().getTime().toString());
            document.getElementById("medius-app-container").classList.remove("notification-visible-modifier");
        };

        this.notificationHiddenByUser = () => {
            return this.isLocalStorageAvailable && !!localStorage.getItem(this.getNotificationKey());
        };

        this.showNotification = ko.computed(() => {
            return this.hasAnyNotification() && !this.notificationHiddenByUser();
        });

        this.showNotificationSub = this.showNotification.subscribe((show:boolean) => {
            if(show) {
                document.getElementById("medius-app-container").classList.add("notification-visible-modifier");
            } else {
                document.getElementById("medius-app-container").classList.remove("notification-visible-modifier");
            }
        });

        this.init = async () => {
            this.clearOldNotifications();
            const result = await get("remedium-api/CloudNotificationService/GetNotification");
            const notification = await result.json();
            if (notification && notification.id !== 0) {
                this.notificationText(notification.text);
                this.notificationId(notification.id);
                this.hasAnyNotification(true);
            } else {
                this.hasAnyNotification(false);
            }
        };
    }
}

const instance = new cloudNotification();

export function create() {
    return instance;
}