///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/grid/parser/repeat" />
import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import * as _ from "underscore";
import * as defaultParser from "Core/Medius.Core.Web/Scripts/Medius/components/grid/parser/default";


const instance = defaultParser.create();
class RepeatableParser extends defaultParser.derive{
    constructor(){
        super();
    }

    parseRows(columns: any, rows: any): any {
        if (!_.isArray(rows)) {
            throw new Error("No rows provided.");
        }

        rows = this.handleRepeatableColumnValues(rows);
        return instance.parseRows(columns, rows); //instability
    }

    handleRepeatableColumnValues(rows: any): any {
        if (isNullOrUndefined(rows) || rows.length === 0) {
            return [];
        }

        const firstRow = _.first(rows);
        const keys = _.keys(firstRow);
        const collectionNames = this.getCollectionNames(keys, firstRow);
        const totalCollectionNames = collectionNames.length;

        // no collections
        if (totalCollectionNames === 0) {
            return rows;
        }

        const t = _.map(rows, (row) => {
            const lines = [];

            const totalRowLines = _.max(_.map(collectionNames, (collectionName) => {
                const collection = row[collectionName] || [];
                return collection.length;
            }));

            const copy = _.clone(row);
            _.each(collectionNames, (collectionName) => {
                copy[collectionName] = null;
            });

            if (totalRowLines === 0) {
                return copy;
            }

            for (let i = 0; i < totalRowLines; i++) {
                const newRow = _.clone(copy);

                for (let j = 0; j < totalCollectionNames; j++) {
                    const collectionProperty = collectionNames[j];
                    newRow[collectionProperty] = row[collectionProperty][i] || null;
                }

                lines.push(newRow);
            }

            return lines;
        });

        return _.flatten(t);
    }
}

const nativeClassInstance = new RepeatableParser();

export function create(){
    return nativeClassInstance;
}

export const derive = RepeatableParser;