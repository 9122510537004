///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/transformers/create"/>
import * as helpers from "Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/helpers";
import * as mappingResolver from "Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/mapping/resolver";
import * as utils from "Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/transformers/create/utils";
import arrayBuilder = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/transformers/create/arrayBuilder");
import cacheBuilder = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/transformers/create/cacheBuilder");
import makeBuilder = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/transformers/create/makeBuilder");
import objectBuilder = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/transformers/create/objectBuilder");

function createViewmodelTransformer() {
    let viewmodelBuilder:any;

    function getMapping(input:any, context:any) {
        if (!input || typeof input !== "object") {
            return undefined;
        }
        return mappingResolver.resolve(input, context);
    }

    return {
        topDown: function(args:any) {
            const input = args.data;
            const context = args.context;
            const mapping = getMapping(input, context) || {};

            viewmodelBuilder = cacheBuilder(input, mapping, context) ||
                arrayBuilder(input) ||
                makeBuilder(input, mapping, context) ||
                objectBuilder(input, mapping, context);

            if (!viewmodelBuilder.shouldDescend) {
                args.stopDescend();
            }
        },
        afterDescend: function (args:any) {
            viewmodelBuilder.mapProperty(args.propertyName, args.propertyValue);
        },
        bottomUp: function(args:any) {
            viewmodelBuilder.construct();
            const value = utils.wrap(viewmodelBuilder.finalize(), args.parent);
            args.data = value;
        }
    };
}

function wrapTransformer() {
    return {
        topDown: function(args:any) {
            const value = utils.wrap(args.data, args.parent);
            args.data = value;
            args.stopDescend();
        }
    };
}

interface Transformer {
    topDown?: (args: any) => void;
    bottomUp?: (args: any) => void;
    beforeDescend?: (args: any) => void;
    afterDescend?: (args: any) => void;
}

export = function createTransformerFactory() {
    const create = createViewmodelTransformer();
    const wrap = wrapTransformer();

    function getTransformer(args:any):Transformer {
        const data = args.data;

        return (data && typeof data === "object" && !helpers.isDate(data)) ?
            create :
            wrap;
    }

    function invokeCurrentTransformer(method:keyof Transformer, args:any) {
        const currentTransformer = getTransformer(args);

        if (currentTransformer[method]) {
            currentTransformer[method](args);
        }
    }

    return {
        topDown: function(args:any) {
            invokeCurrentTransformer("topDown", args);
        },
        bottomUp: function(args:any) {
            invokeCurrentTransformer("bottomUp", args);
        },
        beforeDescend: function(args:any) {
            invokeCurrentTransformer("beforeDescend", args);
        },
        afterDescend: function(args:any) {
            invokeCurrentTransformer("afterDescend", args);
        }
    };

};