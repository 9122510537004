/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/AuthorizationGroups/Tabs/components/exceptionsPopupCell"/>
import { Grid, GridCellProps, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { useCallback, useRef, useState } from "react";
import * as React from "react";
import { DimensionValueExceptionPermission, DimensionValueExpression } from "../../authorizationGroup";
import { ButtonCell, InputCell } from "./gridCells";
import { Popup } from "@progress/kendo-react-popup";
import { useClickOutside } from "../hooks/useClickOutside";
import { Badge } from "@progress/kendo-react-indicators";
import { SvgIcon } from "@progress/kendo-react-common";
import { plusIcon } from "@progress/kendo-svg-icons";

type ChangeEvent<T> = (x: T) => void;
const Value = "Value";
const Description = "Description";
enum ActionType { Add, Edit, Delete }
type Property = "Value" | "Description";

type DispatchAction =
    { type: ActionType.Add } | { type: ActionType.Delete; index: number } |
    {
        type: ActionType.Edit
        property: Property
        index: number
        newValue: any
    };

const map = (props: GridCellProps) => {
    return {
        createCell: (cell: ((x: DimensionValueExceptionPermission, idx: number) => any)) =>
            cell(props.dataItem as DimensionValueExceptionPermission, props.dataIndex)
    };
};

const exceptionsGridReducer = (state: DimensionValueExceptionPermission[], action: DispatchAction, onChange: ChangeEvent<DimensionValueExceptionPermission[]>) => {
    switch (action.type) {
        case ActionType.Edit:
            onChange(state.map((x, index) => index === action.index ? { ...x, [action.property]: action.newValue } : x));
            break;
        case ActionType.Add:
            onChange(state.concat({
                Id: 0,
                Value: { Id: 0, Filter: "*" },
                Description: { Id: 0, Filter: "*" }
            } as DimensionValueExceptionPermission));
            break;
        case ActionType.Delete:
            onChange(state.filter((_, idx) => idx !== action.index));
            break;
    }
};

export const ExceptionsPopupCell = (exceptions: DimensionValueExceptionPermission[],
    onChange: ChangeEvent<DimensionValueExceptionPermission[]>) => {

    const buttonEl = useRef<HTMLButtonElement>(null);
    const popupEl = useRef<HTMLDivElement>(null);
    const anchor = useRef<HTMLSpanElement>(null);
    const [anchorClassName, setAnchorClassName] = useState("");
    const [showPopup, setShowPopup] = useState<boolean>(false);

    const refData = useRef(exceptions);
    refData.current = exceptions;

    const dispatch = (action: DispatchAction): void => {
        exceptionsGridReducer(refData.current, action, onChange);
    };


    const passEditToDispatch =
        (property: Property, index: number) =>
            (newValue: any) =>
                dispatch({
                    type: ActionType.Edit, property, index,
                    newValue: { Id: 0, Filter: newValue } as DimensionValueExpression
                });

    const onClickOutside = (e: any) => {
        if (!buttonEl?.current?.contains(e.target)) {
            setShowPopup(false);
        }
    };

    useClickOutside(popupEl, onClickOutside);

    const ValueCell = useCallback((props: GridCellProps) => map(props).createCell((ex, idx) =>
        InputCell(ex.Value.Filter, passEditToDispatch(Value, idx), "exception-value")), []);
    const DescriptionCell = useCallback((props: GridCellProps) => map(props).createCell((ex, idx) =>
        InputCell(ex.Description.Filter, passEditToDispatch(Description, idx), "exception-description")), []);
    const DeleteItemCell = useCallback(({ dataIndex }: GridCellProps) =>
        ButtonCell(() => dispatch({ type: ActionType.Delete, index: dataIndex }), "delete-exception"), []);


    const onOpenPopupButtonClick = () => {
        setAnchorClassName("popupAnchor");
        setShowPopup(previousState => !previousState);
    };

    const onPopupClose = () => {
        setAnchorClassName("");
    };

    const hasExceptions = refData.current.length > 0;
    const buttonIcon = hasExceptions ? "k-i-edit" : "k-i-plus";
    return (
        <td data-testid="exceptions-popup" data-testid-item-count={refData.current.length} className="gridContainer__grid__centerCell">
            <button ref={buttonEl} className="k-button k-button-icon openPopupButton" onClick={onOpenPopupButtonClick}
                data-testid="exceptions-popup-open-button" >
                <span className={`k-font-icon ${buttonIcon}`} />
                <span ref={anchor} className={anchorClassName} />
                {hasExceptions &&
                    <Badge position="edge" themeColor="info" size="small" className="exceptionsBadge">{refData.current.length}</Badge>}
            </button>
            <Popup anchor={anchor.current} show={showPopup} popupClass="popupContent" onClose={onPopupClose}
                anchorAlign={{ horizontal: "left", vertical: "bottom" }} collision={{ vertical: "flip", horizontal: "fit" }}
                popupAlign={{ horizontal: "right", vertical: "top" }}>
                <div ref={popupEl} data-testid="exceptions-grid">
                    <Grid data={refData.current} className="gridContainer__grid__exceptionsGrid">
                        <GridToolbar>
                            <button className="k-button" onClick={() => dispatch({ type: ActionType.Add })} data-testid="add-exception-button">
                                <SvgIcon icon={plusIcon}/>{translate("#Enterprise/LBL_ADD")}
                            </button>
                        </GridToolbar>
                        <GridColumn field={Value} title={translate("#Enterprise/LBL_VALUE")} cell={ValueCell} width="220px" />
                        <GridColumn field={Description} title={translate("#Enterprise/description")} cell={DescriptionCell} width="220px" />
                        <GridColumn cell={DeleteItemCell} width="60px" />
                    </Grid>
                </div>
            </Popup>
        </td >);
};
