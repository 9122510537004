///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/parser/tree" />

import { isNotNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import * as operatorsProvider from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/provider/operators";
import * as translationsProvider from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/provider/translations";
import * as _ from "underscore";

export function loadSingleQuery(query: any) {
    let loaded = loadStatement(query.EntityType, query.Root);
    const hasOrderClause = _(query).has("OrderBy");

    if (hasOrderClause) {
        loaded += ' ' + loadOrderClause(query);
    }

    return loaded;
}

export function loadStatement(entityType: any, statement: any = {}) {
    const operatorTranslation = operatorsProvider.getOperatorTranslation(statement.Operator);
    const isLogicalOperator = _(operatorsProvider.getLogicalOperators()).contains(operatorTranslation);

    if (isLogicalOperator) {
        return loadLogicalStatement(entityType, statement);
    }

    const operator = operatorsProvider.getOperatorTranslation(statement.Operator); 
    const values = loadValues(statement.Values);
    let result: any[];

    if (statement.Property) {
        const property = translationsProvider.getTranslation(entityType, statement.Property); 
        result = [property, operator, values]; 
    } else {
        result = [operator, values];
    }

    return result.join(' ').trim();
}

export function loadLogicalStatement(entityType: any, statement: any) {
    const operator = operatorsProvider.getOperatorTranslation(statement.Operator);
    const leftStatement: any = loadStatement(entityType, statement.Values[0]);
    const rightStatement: any = loadStatement(entityType, statement.Values[1]);

    if (rightStatement) { // binary operator
        return leftStatement + ' ' + operator + ' ' + rightStatement;
    } else { // unary operator
        return operator + ' ' + leftStatement;
    }
}

export function loadOrderClause(query: any) {
    const property = translationsProvider.getTranslation(query.EntityType, query.OrderBy);
    const operator = operatorsProvider.getOrderByOperator();
    const order = translationsProvider.resolveSortingOrderTranslation(query.Order);

    return operator + ' ' + property + ' ' + order;
}

export function loadValues(values: any) {
    values = _(values).map(transformSingleValue);

    if (values.length > 1) {
        return '(' + _(values).reduce(function(a, b) {
            return a + ', ' + b;
        }) + ')';
    }

    return values[0];
}

export function transformSingleValue(value: any) {
    if (isNotNullOrUndefined(value) && (_(value).contains(' ') || !(value.trim()))) {
        return "'" + value + "'";
    }

    return value;
}