///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/grid/entity/defaults"/>

export function get():{pageSize:number, companyContextId:any, createdDateFilter:any, changedDateFilter:any, exportData:boolean, downloadImagesToPdf:boolean, mapToDto:boolean}{
    return {
        pageSize: 25,
        companyContextId: null,
        createdDateFilter: null,
        changedDateFilter: null,
        exportData: true,
        downloadImagesToPdf: false,
        mapToDto: false
    };
};
