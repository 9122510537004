///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Data.XslTemplate/Editor"/>

class XslTemplateEditor {
    entity: any;
    constructor(entityWithContext:any) {
        this.entity = entityWithContext.Entity;
    }
}

export = function(entityWithContext:any) {
    return new XslTemplateEditor(entityWithContext);
};