///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/documentSearch/columnConfiguration"/>
import * as ko from "knockout";
import * as rest from "Core/Medius.Core.Web/Scripts/Medius/core/rest";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as notification from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import * as backendErrorHandler from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import * as _ from "underscore";
import * as metadataColumns from "Core/Medius.Core.Web/Scripts/Medius/apps/documentSearch/metadataColumns";
import { getTranslationPath } from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/provider/translations";
import * as typeResolver from "Core/Medius.Core.Web/Scripts/Medius/components/resolver/type";
import { getPropertyType } from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/helpers/propertyType";

class ColumnConfiguration {
    IsColumnConfigChanged = ko.observable(false);
    DocumentType = ko.observable(null);
    DocumentTypeName: ko.Computed<any>;
    SortedColumnIds = ko.observableArray([]);
    DisplayedColumns = ko.observableArray([]);
    ColumnsOnEnter = ko.observableArray([]);
    availableMetadataColumns: ko.Computed<any>;
    isLoading = ko.observable(false);
    selectedCollection = ko.observable(null);
    displayedColumnsTranslations: ko.Computed<{ translation: any; isCollection: any; }[]>;
    onDisplayedColumnsChanged: ko.Subscription;

    constructor() {
        this.DocumentTypeName = ko.computed(() => {
            return globalization.getPropertyTranslation("#" + this.DocumentType());
        });
        
        this.availableMetadataColumns = ko.computed(() => {
            return metadataColumns.getMetadataColumnsForType(this.DocumentType());
        });

        this.displayedColumnsTranslations = ko.computed(() => {
            return _.map(this.DisplayedColumns(), (property) => {
                const documentType = this.DocumentType(),
                    hasCollection = typeResolver.containsCollection(documentType, property);
                let translationPath = getTranslationPath(documentType, property);
                if (canBeMetadataColumn(translationPath)) {
                    const metadataColumn = _.find(this.availableMetadataColumns.peek(),
                        (item) => {
                            return item.Key === property;
                        }
                    );
                    if (metadataColumn) {
                        translationPath = [metadataColumn.Translation];
                    }
                }

                return {
                    translation: formatTranslation(translationPath),
                    isCollection: hasCollection
                };
            });
        });

        this.onDisplayedColumnsChanged = this.DisplayedColumns.subscribe(() => {
            if (!this.selectedCollection()) {
                return;
            }

            const columnWithCollectionExists = _.any(this.DisplayedColumns(), (column) => {
                return column.indexOf(this.selectedCollection()) > -1;
            });

            if (!columnWithCollectionExists) {
                this.selectedCollection(null);
            }
        });

        //remove, orderChanged should be called on ColumnConfig object (not on single column)
        this.orderChanged = this.orderChanged.bind(this);
        this.pickColumn = this.pickColumn.bind(this);
    }

    pickColumn(property: string) {
        const entityType = this.DocumentType(),
            pathParts = property.split(".");
        let collectionPath: string = null;

        _.each(pathParts, (part, i) => {
            if (collectionPath) {
                return;
            }

            const testPath = _(pathParts).first(i + 1).join(".");
            const isCollection = typeResolver.isCollection(getPropertyType(entityType, testPath));

            if (isCollection) {
                collectionPath = testPath;
            }
        });

        if (this.selectedCollection() && collectionPath && this.selectedCollection() !== collectionPath) {
            notification.error(globalization.getLabelTranslation("#Core/columnConfigModalPresentingMoreThanOneCollectionAtOnceNotSupported"));
            return;
        }

        if (!this.selectedCollection() && collectionPath) {
            this.selectedCollection(collectionPath);
        }

        this.addColumn(property);
    }

    addColumn(newColumn: string) {
        if (_.contains(this.DisplayedColumns(), newColumn)) {
            notification.info(globalization.getLabelTranslation("#Core/columnConfigModalDuplicate"));
            return;
        }

        this.DisplayedColumns.push(newColumn);
        this.IsColumnConfigChanged(true);
    }

    remove(column: any) {
        if (this.DisplayedColumns().length === 1) {
            notification.error(globalization.getLabelTranslation("#Core/columnConfigModalRemoveLastInfo"));
            return;
        }

        const newColumns = _.reject(this.DisplayedColumns(), (displayedColumn) => {
            return column === displayedColumn;
        });

        this.DisplayedColumns(newColumns);
        this.IsColumnConfigChanged(true);
    }

    cancelColumnConfig() {
        this.DisplayedColumns(this.ColumnsOnEnter());
        this.IsColumnConfigChanged(false);
    }

    orderChanged(newOrder: any[]) {
        const tempCollection = [];
        const displayedColumns = this.DisplayedColumns(),
            itemsLength = displayedColumns.length;

        for (let i = 0; i < itemsLength; i++) {
            tempCollection.push(displayedColumns[newOrder[i]]);
        }

        this.DisplayedColumns(tempCollection);
        this.IsColumnConfigChanged(true);
    }

    reset() {
        this.isLoading(true);

        rest.get("DataSearchManager", 'columns?typeName=' + this.DocumentType())
            .done((columns: any) => {
                this.clearColumnConfig();

                const temp = _.map(columns, (item) => {
                    return item.ValueSource;
                });
                this.DisplayedColumns(temp);
                this.IsColumnConfigChanged(true);
            })
            .fail((xhr: backendErrorHandler.XHR) => {
                backendErrorHandler.handleAnyError(xhr, null, "#Core/columnConfigModalResetFailed");
            })
            .always(() => {
                this.isLoading(false);
            });
    }

    init(documentType: any, savedColumnConfig: any) {
        this.IsColumnConfigChanged(false);
        this.DocumentType(documentType);
        this.DisplayedColumns(savedColumnConfig);
        this.ColumnsOnEnter(savedColumnConfig);
    }

    destroy() {
        this.onDisplayedColumnsChanged.dispose();
    }

    pickMetadataColumn(key: any) {
        this.addColumn(key);
    }

    clearColumnConfig() {
        this.DisplayedColumns([]);
    }
}

function formatTranslation(translationPath: any) {
    if (!translationPath) {
        return '';
    }
    const formatted = _.map(translationPath,
        function (element) {
            return "'" + element + "'";
        }
    );
    return formatted.join(".");
}

function canBeMetadataColumn(translationPath: string | any[]) {
    return (
        _.isArray(translationPath)
        && translationPath.length === 1
        && translationPath[0] === ""
    );
}

export function create() {
    return new ColumnConfiguration();
}