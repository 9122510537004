/// <amd-module name="Core/Medius.Core.Web/Scripts/shared/hooks/useLoading"/>

import { useCallback, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

export default function useLoading(defaultValue?: boolean) {
    const [value, setValue] = useState<boolean>(defaultValue ?? false);
    const wait = 300;

    const debounceSetLoading = useDebouncedCallback(
        (newValue: boolean) => {
            setValue(newValue);
        }, wait);

    const setLoading = useCallback(() =>
        debounceSetLoading(true),
        [debounceSetLoading]);

    const clearLoading = useCallback(() => {
            debounceSetLoading.cancel();
            setValue(false);
        }, [debounceSetLoading]);

    return {
        loading: value,
        setLoading,
        clearLoading
    };
}