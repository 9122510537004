///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/inbox/task/labelSelector" />

import * as React from "react";
import { DropDownList, DropDownListChangeEvent, ListItemProps } from "@progress/kendo-react-dropdowns";
import { handleError } from "Core/Medius.Core.Web/Scripts/lib/errorHandling/errorHandler";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { getLabelTextColor } from "Core/Medius.Core.Web/Scripts/lib/labelHelper";
import { useState, useEffect } from "react";
import { unassignLabel, assignLabel, getLabelsAndAssignedLabels } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/task/labelSelectorServices";

export interface LabelsForDocumentResponse {
    labelsAssignedToDocument: number[];
    labelsForDocument: SingleLabel[];
    documentId: number;
}
export interface SingleLabel {
    colorCode: string;
    companyId: number;
    description: string;
    id: number;
    isActive: boolean;
    longDescription: string;
}

interface IProps {
    taskId: number;
}

export const LabelSelector = ({ taskId }: IProps) => {
    const [currentLabels, setCurrentLabels] = useState<SingleLabel[]>([]);
    const [labelList, setLabelList] = useState<SingleLabel[]>([]);
    const [documentId, setDocumentId] = useState<number>();
    const [hideHandler, setHideHandler] = useState(true);

    useEffect(() => {
        getLabelsAndAssignedLabels(taskId).then(resp => {
            const assignedLabelsWithDetails = resp.labelsForDocument.filter(label => {
                return resp.labelsAssignedToDocument.some(labelId => labelId === label.id);
            });
            
            if(resp.labelsForDocument.length !== 0){
                setHideHandler(false);
            }

            setDocumentId(resp.documentId);
            setLabelList(resp.labelsForDocument);
            setCurrentLabels(assignedLabelsWithDetails);
        }).catch(err => handleError(err));
    }, [taskId]);

    const handleLabelSelect = (ev: DropDownListChangeEvent) => {
        let newCurrentLabels = [...currentLabels];
        const wasFoundAtIndex = currentLabels.findIndex(label => label === ev.target.value);
        if(wasFoundAtIndex !== -1){
            newCurrentLabels.splice(wasFoundAtIndex, 1);         
            unassignLabel(documentId, ev.target.value.id).then(()=>{
                setCurrentLabels(newCurrentLabels);
            }).catch(err => handleError(err));
        }
        else {
            newCurrentLabels = [...newCurrentLabels, ev.target.value];
            assignLabel(documentId, ev.target.value.id).then(()=>{
                setCurrentLabels(newCurrentLabels);
            }).catch(err => handleError(err));           
        }
    };

    if(taskId === -1) return <></>;

    const customCell = (
        li: React.ReactElement<HTMLLIElement>,
        itemProps: ListItemProps
    ) => {
        let classNames = "icon-ok generalLabelSelector__tickIconWhite";
        if(getLabelTextColor(itemProps.dataItem.colorCode) === "#000000"){
            classNames = "icon-ok";
        }
        const isInList = currentLabels.findIndex(label => label === itemProps.dataItem);

        return React.cloneElement(li, li.props, (
            <div className="generalLabelSelector__customCellContainer">
                <div className="generalLabelSelector__labelColorSquare" style={{backgroundColor: itemProps.dataItem.colorCode}}>
                    { (isInList !== -1) ? <i className={classNames}></i> : null }
                </div>
                <span data-testid="labelInDropdownList">{itemProps.dataItem.description}</span>
            </div>
        ));
    };

    return (
        <>
        {
            hideHandler ? <></> : (
            <div className="generalLabelSelector">
                <div className="generalLabelSelector__dropDownListContainer" data-testid="inbox-fullscreen-labels-dropdown">
                    <DropDownList 
                        data={labelList}
                        itemRender={customCell}
                        onChange={handleLabelSelect}
                        value={translate("#Core/addRemoveLabels")}
                    />
                </div>
                <div className="generalLabelSelector__currentLabelListContainer" data-testid="inbox-fullscreen-labels-list">
                    {currentLabels.map((el, key) => (
                        <div key={key} className="generalLabelSelector__labelInList" style={{backgroundColor: el.colorCode}} data-testid="labelInList">
                            <span style={{color: getLabelTextColor(el.colorCode)}}>{el.description}</span>
                        </div>
                    ))}
                </div>
            </div>
            )
        }
        </>
    );
};    