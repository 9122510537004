/// <amd-module name="Core/Medius.Core.Web/Scripts/components/outbox/outbox"/>
import * as $ from "jquery";
import * as window from "window";
import { OutboxItem } from "Core/Medius.Core.Web/Scripts/components/outbox/outboxItem";
import {translate} from "Core/Medius.Core.Web/Scripts/lib/globalization";
import {pureComputed, observableArray} from "knockout";
import * as metadataGeneratorFactory from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/generator";

function propertyFilter(propertyAccessor: any, propertyValue: any) {
    return function(item: any) {
        const prop = item[propertyAccessor];
        if (typeof prop === "function") {
            return prop() === propertyValue;
        }
        return prop === propertyValue;
    };
}

let outboxInstance: Outbox = null;

export class Outbox {
    public started = "outbox.handling.started";
    public inbox: any;
    private metadataGenerator: any = metadataGeneratorFactory.create();

    /* All tasks in current outbox */
    public items = observableArray<OutboxItem>([]);
   
    public pending = pureComputed(() => this.items().filter(propertyFilter("isPending", true)));
    public done = pureComputed(() => this.items().filter(propertyFilter("isDone", true)));
    public failed = pureComputed(() => this.items().filter(propertyFilter("isFailed", true)));
    public unhandled = pureComputed(() => this.items().filter(propertyFilter("isDone", false)));
    public hasFailed = pureComputed(() => this.failed().length > 0);
    public isBadgeVisible = pureComputed(() => this.hasFailed() && this.pending().length === 0);
    public failedNumber = pureComputed(() => this.failed().length > 10 ? "10+" : this.failed().length );

    public outboxHeader = pureComputed(() => (this.pending().length > 0) ?
        translate("#Core/outbox") : translate("#Core/validationErrors"));

    constructor() {
        $(window).bind("beforeunload", (e: any) => {
            if (this.unhandled().length > 0) {
                const message = translate("#Core/youHaveUnhandledDocumentsInYourOutbox");
                e.returnValue = message;
                return message;
            }
            return undefined;
        });
    }

    public enqueue(taskId: number, documentId: number | string) {
        this.removeExisting(taskId);
        const newItem = new OutboxItem(taskId, documentId, this.metadataGenerator);
        this.items.push(newItem);      
        return newItem;
    }

    public removeExisting(taskId: number) {
        this.items()
            .filter(item => item.taskId === taskId)
            .forEach(item => {
                item.dispose();
                this.items.remove(item);
            });
    }

    public clearDone() {
        this.items(this.items().filter(propertyFilter("isDone", false)));
    }

    public isInProgessOrDone(taskId: number) {
        return this.items().some(outboxItem => {
            if (outboxItem.taskId === taskId) {
                return outboxItem.isPending() || outboxItem.isDone();
            } else {
                return false;
            }
        });
    }

    public has(taskId: number) {
        function hasTask(item: any) {
            return taskId === item.taskId;
        }
        return this.pending().some(hasTask) || this.failed().some(hasTask);
    }

    public isFailed(taskId: number) {
        return this.failed().some(function(i) {
            return i.taskId === taskId;
        });
    }

    public getErrorsForTask(taskId: any) {
        const itemForTask = this.items().find(item => item.taskId === taskId);

        if (!itemForTask) {
            return [];
        }

        return itemForTask.validationMessages();
    }

    public registerInbox(inbox: any) {
        this.inbox = inbox;
    }
}

export function getOutboxInstance() {
    if (!outboxInstance) {
        outboxInstance = new Outbox();
    }
    return outboxInstance;
}
