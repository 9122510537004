///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/localizer/type/integer"/>

import {Globalize} from "Core/Medius.Core.Web/Scripts/Medius/lib/globalize";
import * as $ from "jquery";
import {isNaN} from "underscore";
import * as culture from "Core/Medius.Core.Web/Scripts/Medius/lib/culture";
import * as helpers from "Core/Medius.Core.Web/Scripts/Medius/components/editors/helpers";
import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";

export function toString(value: any) {
    const cultureCode = culture.getCode();
    let number = Globalize.parseFloat($.trim(value), cultureCode);

    number = helpers.decimalAdjust("midpoint", number, 0);
    if (isNullOrUndefined(number) || isNaN(number))
        return null;

    return Globalize.format(number, "n0", cultureCode);
}