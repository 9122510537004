/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/metadata/entity/templates/label"/>
import {unpack} from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as type from "Core/Medius.Core.Web/Scripts/Medius/core/type";
import * as typeResolver from "Core/Medius.Core.Web/Scripts/Medius/components/resolver/type";
import * as _ from "underscore";

const labelAttribute = "Medius.IndexSearch.FieldAttribute";

export const labelTemplates = {
    "Medius.Data.Entity": function (entity: any) {
        const entityType = unpack(entity.$type),
            properties = _.without(_.keys(type.describe(entityType).Properties), "$type"),
            labelProperties = _.filter(properties, function (property) {
                const attributes = typeResolver.getPropertyAttributes(entityType, property);
                if (_.some(attributes, function (attribute) { return attribute.Type.FullName === labelAttribute; })) {
                    return true;
                }
                else {
                    return false;
                }
            });
            if (labelProperties.length > 0) {
                return _.map(labelProperties, function(labelProperty) {
                    return unpack(entity[labelProperty]);
                }).join(", ");
            } else {
                return `${unpack(entity.$type)}:${unpack(entity.Id)}`;
            }
    },
    "Medius.Core.Entities.User": function (entity: any) {
        return unpack(entity.UserName);
    },
    "Medius.Core.Entities.Company": function (entity: any) {
        return unpack(entity.Name);
    },
    "Medius.Core.Entities.Role": function (entity: any) {
        return unpack(entity.Name);
    },
    "Medius.Core.Entities.RoleCompany": function (entity: any) {
        return unpack(unpack(entity.Role).Name) + " (" + unpack(unpack(entity.Company).Name) + ")";
    },
    "Medius.Core.Entities.Currency": function (entity: any) {
        return unpack(entity.Code);
    },
    "Medius.Core.Entities.Unit": function (entity: any) {
        return unpack(entity.Description);
    },
    "Medius.Data.XslTemplate": function(entity: any) {
        return unpack(entity.TemplateName);
    },
    "Medius.Core.Entities.Security.IdentityProviderName": function (entity: any) {
        return unpack(entity.Name);
    }
};
