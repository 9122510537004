/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/lib/blob" />

export function dataURItoBlob(dataUri: string) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataUri.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataUri.split(',')[1]);
    else
        byteString = unescape(dataUri.split(',')[1]);

    // separate out the mime component 
    const mimeString = dataUri.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
}

export function saveOrOpenDataUri(dataUri: string, fileName: string) {
    const localBlob = this.dataURItoBlob(dataUri);
    const blobObject = new Blob([localBlob]);
    (window.navigator as any).msSaveOrOpenBlob(blobObject, fileName);
}

export function open(dataUri: string, fileName: string) {
    if ((window.navigator as any).msSaveOrOpenBlob !== undefined) {
        saveOrOpenDataUri(dataUri, fileName);
    } else {
        // Opening data based urls in Chrome is not longer possible (https://stackoverflow.com/a/45495974/543205) directly.
        // There's a workaround that uses full screen embeeded iframe to display this document instead.
        // Cross-border compatibile method of displaying full screen iframe borrowed from https://stackoverflow.com/a/14738668/543205

        const iframeStyle =
            "position:fixed; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden; z-index:999999;";

        const newWindow = window.open();
        newWindow.document.write(
            '<iframe src="' + dataUri
            + '" style="' + iframeStyle
            + '" frameborder="0" allowfullscreen></iframe>');
    }
}