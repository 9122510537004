/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/spaManager/router" />
import * as _ from "underscore";
import * as Backbone from "backbone";

const Router = Backbone.Router.extend({

    initialize: function (spaManager: any) {
        _(spaManager.Apps).each((app) => {
            _(app.routes).each((route) => {
                this.route(route.path, route.path, function () {
                    if (_.isFunction(route.action)) {
                        route.action.apply(app, arguments);
                    }
                });
            });
        });
    },

    updateUrl: function (elements: any, trigger: any, replace: any) {
        const url = _.isArray(elements)
            ? elements.join("/")
            : elements;


        this.navigate(url, {
            trigger: trigger || false,
            replace: false || replace
        });

        return true;
    },

    navigateTo: function (elements: any) {
        this.updateUrl(elements, true);
    },

    navigateToInNewTab: function (route: any) {
        window.open(window.location.href.split("#")[0] + route, '_blank');
    },

    openLinkInNewTab: function (link: any) {
        window.open(link, '_blank');
    },
});

export function create(spaManager: any) {
    return new Router(spaManager);
}
