/// <amd-module name="Core/Medius.Core.Web/Scripts/components/administration/grid/isActiveColumn"/>
import * as React from "react";
import { ChangeEvent } from "react";
import { GridColumn, GridFilterCellProps } from "@progress/kendo-react-grid";
import { BoolToggle, BoolToggleElement } from "Core/Medius.Core.Web/Scripts/shared/components/boolToggle";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";

export const isActiveColumn = (field: string ) =>
    <GridColumn field={field} title={translate("#Core/active")}
        filter="boolean"
        filterCell={IsActiveFilterCell}
        cell={IsActiveCell} />;

const IsActiveCell = ({ dataItem }: { dataItem: { IsActive: boolean } }) =>
    <td>
        {dataItem.IsActive ? translate("#Core/yes") : translate("#Core/no")}
    </td>;

const IsActiveFilterCell = ({ onChange }: GridFilterCellProps) => {
    const onToggle = (syntheticEvent: ChangeEvent<BoolToggleElement>) => {
        const value = syntheticEvent.target.value;
        onChange({
            value,
            operator: value ? 'eq' : null,
            syntheticEvent
        });
    };

    return <BoolToggle
        onToggle={onToggle}
        trueLabel={translate("#Core/yes")}
        falseLabel={translate("#Core/no")} />;
};
