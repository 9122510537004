/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/passwordExpiringNotification"/>
import * as _ from "underscore";
import { info } from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import {getBasePath} from "Core/Medius.Core.Web/Scripts/Medius/lib/path";
import * as cookies from "Core/Medius.Core.Web/Scripts/lib/cookie";
import { formatValueWithPlaceholders } from "./lib/stringFormat";

export function notifyAboutExpiringPassword() {
    const basePath = getBasePath().replace("/", "");
    const cookieName = basePath + "LifetimePolicy";
    const cookie = cookies.getCookie(cookieName);
    if (cookie) {
        info(formatValueWithPlaceholders(translate("#Security/passwordWillExpireWithinDays"), [cookie]));
        cookies.deleteCookie(cookieName);
    }
}
