/// <amd-module name="Core/Medius.Core.Web/Scripts/Models/Percentage/percentage"/>
import * as ko from "knockout";

export default class Percentage {
    public Percent: ko.Observable<number|null>;

    constructor(data?: any) {
        this.Percent = ko.observable(data?.Percent);
    }

    public static createNew() {
        const data = {
            Percent: 0
        };
        return data;
    }

    public toJS() {
        return {
            Percent: this.Percent()
        };
    }
}
