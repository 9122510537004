/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/default/text/entity/validationContextConfiguration"/>
import { unpack } from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as typeBoundResolverFactory from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/resolvers/typeBound";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { getTypeName } from "Core/Medius.Core.Web/Scripts/Medius/core/type";

export const resolver = typeBoundResolverFactory.create({
    priority: 0,
    type: "Medius.Validation.ValidationContextConfiguration",
    resolve: function (data: any) {
        const code = data.Code || data.Id,
            taskName = data.TaskName || null,
            taskGroup = data.TaskGroup ? data.TaskGroup.Description : null,
            description = [taskName, taskGroup, code].join(" "),
            type = getTypeName(unpack(data.$type)),
            typeName = globalization.getPropertyTranslation(type),
            result = `${typeName}:${description}`;

        return { text: result };
    }
});
