/// <amd-module name="Core/Medius.Core.Web/Scripts/components/procurement/validatorConfiguration/validatorConfigurationSlice"/>

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "Core/Medius.Core.Web/Scripts/shared/store/reduxStore";
import { ValidatorConfiguration } from "./validatorConfiguration";

const initialState: ValidatorConfiguration = {
    activeValidators: []
};

export const validatorConfigurationSlice = createSlice({
  name: 'validatorConfiguraton',
  initialState,
  reducers: {
    set: (state, action: PayloadAction<string[]>) => {
      state.activeValidators = action.payload;
    }, 
  }
});

export const { set } = validatorConfigurationSlice.actions;

export function validatorConfigurationSelector(s: RootState) { return s.procurement.validatorConfiguration.activeValidators; }