/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/demoManager/demoManager"/>
import * as React from "react";
import {useEffect, useState} from "react";
import {canCopyDatabase, createDatabaseCopy, restoreDatabaseFromCopy} from "./services";
import {translate} from "Core/Medius.Core.Web/Scripts/lib/globalization";
import {error} from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import {isNullOrUndefined} from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";

function handleError(jqXhr: JQueryXHR) {
    if (jqXhr.status >= 500) {
        error(translate("#Core/demoManagerErrorMessage"));
        return;
    }

    const defaultMessage = translate("#Core/errorOccurred");
    const errorMessage = JSON.parse(jqXhr.responseText);
    let message: string;
    try {
        message = errorMessage.ValidationResults[0].ValidationMessage.Message;
    } catch (exception) {
        message = defaultMessage;
    }
    if (isNullOrUndefined(message)) {
        message = defaultMessage;
    }

    error(message);
}

export function DemoManager() {
    const [operationResult, setOperationResult] = useState("");
    const [canCopy, setCanCopy] = useState(false);

    useEffect(() => {
        let isCancelled = false;
        canCopyDatabase().then((result: boolean) => {
            if (!isCancelled) {
                setCanCopy(result);
            }
        }, (jqXHR: any) => {
            if (!isCancelled) {
                handleError(jqXHR);
            }
        });
        return () => { isCancelled = true; };
    }, []);

    function createCopy() {
        createDatabaseCopy().then(() => {
            setOperationResult(translate("#Core/demoManagerCreateCopySuccessMessage"));
        }, handleError);
    }

    function restoreDatabase() {
        const successMessage = translate("#Core/demoManagerRestoreDatabaseSuccessMessage");
        restoreDatabaseFromCopy().then(() => {
            setOperationResult(successMessage);
        }, (jqXhr: any) => {
            if (jqXhr.status !== 503) { // If restoration request succeed tenant is shut down and connection can be lost
                handleError(jqXhr);
            } else {
                setOperationResult(successMessage);
            }
        });
    }

    return (
    <React.StrictMode>
        <h3>{translate("#Core/demoReset")}</h3>
        <button disabled={!canCopy} onClick={createCopy}>{translate("#Core/createCopy")}</button>
        <button onClick={restoreDatabase}>{translate("#Core/restoreFromCopy")}</button>
        <div>{operationResult}</div>
    </React.StrictMode>
    );
}
