/// <amd-module name="Core/Medius.Core.Web/Scripts/components/administration/page/grid/grid"/>
import * as React from "react";
import { useState, useCallback } from "react";
import { Grid, GridColumn, GridProps } from "@progress/kendo-react-grid";
import { CompositeFilterDescriptor, orderBy } from '@progress/kendo-data-query';
import { debounce } from "underscore";
import { IAdministrationEntity } from "../types";

type AdministrationGridProps = {
    readonly columns: JSX.Element[],
    readonly data: IAdministrationEntity[];
    readonly selectedItem: IAdministrationEntity;
    readonly total: number;
    readonly pageSize: number;
    readonly onRowClick: ({ dataItem }: { dataItem: { Id : number}}) => void;
    readonly onDataReload: (page?: number, take?: number, filter?: Record<string, unknown>) => JQueryPromise<void>;
};

export const AdministrationGrid = ({
    columns,
    data,
    selectedItem,
    total,
    pageSize,
    onRowClick,
    onDataReload
}: AdministrationGridProps) => {

    const defaultGridSettings: GridProps = {
        selectedField: "selected",
        pageable: {
            buttonCount: 5,
            pageSizes: true
        },
        skip: 0,
        take: pageSize,
        pageSize,
        filterable: true,
        filterOperators: {
            "text": [{ text: 'grid.filterContainsOperator', operator: 'contains' }]
        },
        filter: {
            logic: 'and',
            filters: []
        },
        sortable: true,
        sort: []
    };

    const [dataState, setDataState] = useState<GridProps>(defaultGridSettings);
    const [filterKeywords, setFilterKeywords] = useState<Record<string, unknown>>(null);
    const debounceOnDataReload = useCallback(debounce(onDataReload, 300), []);

    const filterData = (filter: CompositeFilterDescriptor) => {
        const filters: any[] = filter?.filters ?? [];
        const newFilterKeywords = {} as any;

        for (const columnFilter of filters) {
            if (!newFilterKeywords.hasOwnProperty(String(columnFilter.field)))
                newFilterKeywords[String(columnFilter.field)] = columnFilter.value;
        }

        setDataState({ ...dataState, filter });
        setFilterKeywords(newFilterKeywords);

        return debounceOnDataReload(
            (dataState.skip / dataState.take) + 1,
            dataState.take,
            newFilterKeywords);
    };

    const changePage = ({ skip, take }: any) =>
        onDataReload((skip / take) + 1, take, filterKeywords)
            .then(() => setDataState({ ...dataState, take, skip }));

    const addGridColumns = (item: { Id: number }) => ({
        ...item,
        selected: item.Id === selectedItem?.Id
    });

    const prepareGridData = () =>
        orderBy(
            data.map(addGridColumns),
            dataState.sort);

    return (
        <Grid
            className="administration__grid"
            data={prepareGridData()}
            {...dataState}
            onDataStateChange={({ dataState }) => setDataState({ ...dataState })}
            onSortChange={({ sort }) => setDataState({ ...dataState, sort })}
            onFilterChange={({ filter }) => filterData(filter)}
            onPageChange={({ page }) => changePage(page)}
            onRowClick={onRowClick}
            total={total}>
            {columns.map((column, k) => 
                <GridColumn key={k} {...column.props} />)}
        </Grid>);
};
