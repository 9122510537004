define("medius/knockout/templateEngines/underscore", function() {
    "use strict";

    var ko = this.globals.ko,
        _ = require("underscore");
    
    ko.underscoreTemplateEngine = function () {
    };
    ko.underscoreTemplateEngine.prototype = new ko.templateEngine();
    ko.underscoreTemplateEngine.prototype.constructor = ko.underscoreTemplateEngine;

    ko.underscoreTemplateEngine.prototype.renderTemplateSource = function (templateSource, bindingContext, options) {
        var precompiled, renderedMarkup;

        // Precompile and cache the templates for efficiency
        precompiled = templateSource.data('precompiled');
        if (!precompiled) {
            precompiled = _.template("<% with($data) { %> " + templateSource.text() + " <% } %>");
            templateSource.data('precompiled', precompiled);
        }
        // Run the template and parse its output into an array of DOM elements
        renderedMarkup = precompiled(bindingContext).replace(/\s+/g, " ");
        
        return ko.utils.parseHtmlFragment(renderedMarkup);
    };
    ko.underscoreTemplateEngine.prototype.createJavaScriptEvaluatorBlock = function (script) {
        return "<%= " + script + " %>";
    };

    ko.underscoreTemplateEngine.instance = new ko.underscoreTemplateEngine();

    ko.exportSymbol('underscoreTemplateEngine', ko.underscoreTemplateEngine);
});