/*
 * Binding that allows to execute viewmodel action when key is pressed. Binding accepts
 * the following parameters:
 *   - key: <number> | <Array:number> - specifies the keys on which action have to
 *                                      to be executed.
 *   - action: <function>             - specifies the action that have to be executed. The
 *                                      action must be a function. Function call will be bound
 *                                      with element on which the binding is defined and the
 *                                      single parameter will be passed, which is a current
 *                                      context $data value
 */
///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/knockout/bindings/ui/onKey"/>
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as ko from "knockout";
import * as _ from "underscore";

const keyDownDataKey = "medius-keyDownHandler";


function getWatchedKeys(key:any) {
    if (typeof key === 'number') {
        return [key];
    }

    if (key instanceof Array) {
        return key;
    }
    throw new Error("Keys must be either an key code or array of key codes");
}

function getCallback(element:any, options:any, context:any, params:any) {
    const watchedKeys = getWatchedKeys(options.key);
    return function (e:any) {
        const matches = _(watchedKeys).any(function (k) {
            return k === e.which;
        });

        if (matches) {
            options.action.apply(context, params);
        }
    };
}

function attachNew(element:any, handler:any) {
    let $element = $(element);
    $element.on('keydown', handler);
    ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
        $element.off('keydown', handler);
        $element = null;
    });
    koUtils.domData.set(element, keyDownDataKey, handler);
}

function detachOld(element:any, previousHandler:any) {
    if (previousHandler) {
        $(element).off('keydown', previousHandler);
    }
}

const onKey = {
    init: function (element:any, bindingAccessor:any, allAccessor:any, viewModel:any, context:any) {
        const options = bindingAccessor();

        if (!options.key) {
            throw new Error("Keys are not defined");
        }

        if (!options.action || typeof options.action !== "function") {
            throw new Error("Action must be a function");
        }
        const handler = getCallback(element, options, element, [context.$data]);
        attachNew(element, handler);
    },
    update: (element:any, ...args:any[]) => {
        const previousHandler = koUtils.domData.get(element, keyDownDataKey);
        detachOld(element, previousHandler);

        onKey.init.apply(this, [element, ...args]);
    }
};

export function register() {
    koUtils.registerBinding("onKey", onKey);
}