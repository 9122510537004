/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/attachments/shared/attachmentsShared" />

import { handleAnyError, XHR } from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import { toAction } from "Core/Medius.Core.Web/Scripts/Medius/lib/path";
import { postFormData } from "Core/Medius.Core.Web/Scripts/Medius/core/fetch/rest";
import { getTypeName } from "Core/Medius.Core.Web/Scripts/Medius/core/type";
import {  translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { ajax } from "Core/Medius.Core.Web/Scripts/Medius/lib/ajax";
import { request } from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rpc";
import * as notification from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import { FileAttachment, FileAttachmentEntity } from "Core/Medius.Core.Web/Scripts/Medius/core/attachments/fileAttachment";
import { formatValueWithPlaceholders } from "Core/Medius.Core.Web/Scripts/Medius/lib/stringFormat";

function addAttachmentRequest(fileDescription: string, entityViewId: string, entityType: string, mainAttachmentMode: boolean, file: any):
    Promise<FileAttachmentEntity[]> {
    const result = new Promise<FileAttachmentEntity[]>((resolve, reject) => {
        const path = "Attachments/UploadFile";
        const formData = new FormData();
        formData.append("description", fileDescription || "");
        formData.append("entityViewId", entityViewId);
        formData.append("entityType", getTypeName(entityType));
        formData.append("isMainAttachment", mainAttachmentMode ? "true" : "false");
        formData.append("isExternal", "false");
        formData.append("file", file);
        
        postFormData(path, formData)        
        .then(result => {
            const attachments: FileAttachmentEntity[] = result;
            resolve(attachments);  
        })
        .catch(error => {
            const errorDto = JSON.parse(error.message); 
            notification.error(translate(errorDto.Message), translate(errorDto.Title));
            reject(error);
        })
        .catch(error => {
            handleAnyError(error);
            reject(error);
        });
    });

    return result;
}

function getAttachments(params : {entityViewId: string, entityType: string}, mainAttachmentMode: boolean): Promise<{attachments: FileAttachment[], carriedOverAttachments: FileAttachment[]}> {
    const result = new Promise<{attachments: FileAttachment[], carriedOverAttachments: FileAttachment[]}>((resolve, reject) => {
        request("AttachmentsManager", "GetAttachments", params)
        .done((inputattachments: FileAttachmentEntity[]) => {
            const filteredAttachments = inputattachments.filter(
                attachment => !attachment.IsCarriedOver &&
                (mainAttachmentMode ? attachment.IsMainAttachment : !attachment.IsMainAttachment));

            const attachments = filteredAttachments.map(attachment => new FileAttachment(attachment))
                .sort((a, b) => a.AddedDate < b.AddedDate ? 1 : -1);

            const carriedOverAttachments = inputattachments.filter(attachment => attachment.IsCarriedOver)
                .map(attachment => new FileAttachment(attachment))
                .sort((a, b) => a.AddedDate < b.AddedDate ? 1 : -1);

            resolve({attachments, carriedOverAttachments});
        })
        .fail((jqXhr: XHR) => {
            handleAnyError(jqXhr);
            reject();
        });
    });
    return result;
}

function deleteAttachment(attachmentHash: string): Promise<void> {
    const result = new Promise<void>((resolve, reject) => {
        request("AttachmentsManager", "DeleteAttachment", { hash: attachmentHash })
            .done(() => resolve())
            .fail((jqXhr: XHR) =>  {
                handleAnyError(jqXhr);
                reject();
            });
    });

    return result;
}

function deleteAttachmentAddedByOther(attachmentHash: string) {
    const result = new Promise<void>((resolve, reject) => {
        request("AttachmentsManager", "DeleteDocumentAttachmentAddedByOthers", { hash: attachmentHash })
            .done(() => resolve())
            .fail((jqXhr: XHR) => {
                handleAnyError(jqXhr);
                reject();
            });
    });

    return result;
    
}

function getAttachmentMaxSize(): Promise<number> {
    const result = new Promise<number>((resolve) => {
        ajax(toAction("GetAttachmentMaxSize", "~/Attachments"), undefined)
        .done((maxSize: any) => resolve(maxSize));
    });

    return result;

}

function showMaxFileSizeExceededError(maxFileSize: any) {
    const message = maxFileSize
        ? formatValueWithPlaceholders(translate("#Core/attachmentCouldntBeAdded_maxFileSize"), [maxFileSize / (1024 * 1024)])
        : translate("#Core/attachmentCouldntBeAdded");

    notification.error(message, translate("#Core/attachmentUploadFailureTitle"));
}

export {addAttachmentRequest, getAttachments, deleteAttachment, getAttachmentMaxSize, showMaxFileSizeExceededError, deleteAttachmentAddedByOther};