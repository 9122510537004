///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/lib/url"/>

//http://stackoverflow.com/questions/901115/how-can-i-get-query-string-values-in-javascript?answertab=votes#tab-top
export default function getParameterByName(name: string) {
    const url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}