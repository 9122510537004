/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/kendo/bindings/multiEventHandler"/>

export interface IEventHandler<T> {
    (event: T): void;
}

export class MultiEventHandler {
    public static from<T>(originHandler: IEventHandler<T>, nextHandler: IEventHandler<T>): IEventHandler<T> {
        if (!nextHandler)
            return originHandler;

        if (!originHandler)
            return nextHandler;

        return (e: T) => {
            originHandler(e);

            if (!MultiEventHandler.prevented(e))
                nextHandler(e);
        };
    }

    private static prevented(event: any): boolean {
        if (event.defaultPrevented)
            return true;

        if (typeof event.isDefaultPrevented === "function")
            return event.isDefaultPrevented();

        return false;
    }
}

