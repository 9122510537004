/// <amd-module name="Core/Medius.Core.Web/Scripts/components/topBar/topBarContainerViewModel"/>
import { observe } from "Core/Medius.Core.Web/Scripts/shared/store/reduxStore";
import { get } from "Core/Medius.Core.Web/Scripts/components/customTopBarProvider/customTopBarProvider";
import { TopBar } from "Core/Medius.Core.Web/Scripts/components/topBar/topBar";
import { observable } from "knockout";
import { Unsubscribe } from "redux";
import * as ko from "knockout";

class TopBarInfo {
    public topBarTemplate:string;
    public topBarViewModel: any;

    constructor (pTemplate:string, pViewModel:any) {
        this.topBarTemplate = pTemplate;
        this.topBarViewModel = pViewModel;
    }
}


export class TopBarContainerViewModel {
    public topBarInfo: ko.Observable<TopBarInfo>;
    public unsubscribe: Unsubscribe;

    public dispose() { this.unsubscribe(); }
}

export function register() {
    const vm = new TopBarContainerViewModel();

    vm.topBarInfo = observable(new TopBarInfo("Core:components/topBar/topBar.html", new TopBar()));    

    ko.applyBindings(vm, document.getElementById('top-bar'));

    this.unsubscribe = observe((state) => state.topBarContext, ({ context }) => {
        if (context) {
            const topBarModel = get(context.toString());

            if (topBarModel == null) {
                const topBar = "Core:components/topBar/topBar.html";

                if (vm.topBarInfo().topBarTemplate === topBar)
                    return;

                vm.topBarInfo(new TopBarInfo(topBar, new TopBar()));
            }
            else {
                if (vm.topBarInfo().topBarTemplate === topBarModel.templateName)
                    return;

                vm.topBarInfo(new TopBarInfo(topBarModel.templateName, topBarModel.provider()));
            }
        }
    });

    return vm;
}
