///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/core/sync"/>
import * as logger from "Core/Medius.Core.Web/Scripts/Medius/lib/logger";
import * as serialization from "Core/Medius.Core.Web/Scripts/Medius/lib/serialization";
import * as rest from "Core/Medius.Core.Web/Scripts/Medius/core/rest";
import * as guid from "Core/Medius.Core.Web/Scripts/lib/uniqueGuid";
import * as _ from "underscore";

const resolveNew = _.memoize((type:any) => {
    let result: any = null;
    create(type, { async: false, cache: true })
        .done((model: any) => { result = model; });
    return result;
});

const _defaultAjaxParams = {
    cache: true,
    dataType: 'json',
    contentType: 'application/json; charset=utf-8',
    async: true
};

const _mappings = {
    load: {
        path: (params: { type: any; id: any; }) => {
            return `${params.type??""}/${params.id??""}`;
        },
        method: "GET"
    },
    loadList: {
        path: (params: { type: any; companyId: any; }) => {
            return `${params.type??""}?companyId=${params.companyId??""}`;
        }, 
        method: "GET"
    },
    loadListFilter: {
        path: (params: { type: any; companyId: any; filterResultByColumns: any; }) => {
            return `${params.type??""}?companyId=${params.companyId??""}&filterResultByColumns=${params.filterResultByColumns??""}`;
        },
        method: "GET"
    },
    loadListPaged: {
        path: (params: { type: any; companyId: any; page: any; pageSize: any; }) => {
            return `${params.type??""}?companyId=${params.companyId??""}&page=${params.page??""}&pageSize=${params.pageSize??""}`;
        },
        method: "GET"
    },
    loadListPagedFilter: {
        path: (params: { type: any; companyId: any; page: any; pageSize: any; filterResultByColumns: any; }) => {
            return `${params.type??""}?companyId=${params.companyId??""}&page=${params.page??""}&pageSize=${params.pageSize??""}&filterResultByColumns=${params.filterResultByColumns??""}`;
        },
        method: "GET"
    },
    create: {
        path: (params: { type: any; }) => {
            return `${params.type??""}`;
        },
        method: "POST"
    },
    update: {
        path: (params: { type: any; id: any; }) => {
            return `${params.type??""}/${params.id??""}`;
        },
        method: "PUT"
    },
    remove: {
        path: (params: { type: any; id: any; }) => {
            return `${params.type??""}/${params.id??""}`;
        },
        method: "DELETE"
    }
};

const _performAjax = (type: keyof typeof _mappings, params: any, options: any) => {
    const mapping = _mappings[type];

    if (!mapping) {
        logger.warn(`No mapping for method ${type}. The call will be ignored.`);
        return;
    }
    options = _.extend(
        { type: mapping.method },
        _defaultAjaxParams || {},
        options || {}
    );

        const uri = mapping.path(params);
        return rest.ajax("Synchronization", uri, options);
};

const _pagedMappingFor = (mappingName: keyof typeof _mappings): keyof typeof _mappings => {
    switch (mappingName) {
        case 'loadList':
            return "loadListPaged";
        default:
            return mappingName;
    }
};

const _selectUpdateMapping = (id: string|number) => {
    if (typeof id === 'undefined') {
        return 'create';
    }
    return id === 0 ? 'create' : 'update';
};

const _filterMappingFor = (mappingName: keyof typeof _mappings): keyof typeof _mappings=> {
    switch(mappingName) {
        case "loadList":
            return "loadListFilter";
        case "loadListPaged":
            return "loadListPagedFilter";
        default:
            return mappingName;
    }
};

export const create = (type: any, options?: any) => {
    return load(type, 0, null, null, options, undefined).pipe((data: { ViewId: string; }) => {
        if (data && data.ViewId) {
            data.ViewId = guid.newGuid();
        }
        return data;
    });
};

export const load = (type: any, q?: number, pageSize?: any, page?: number, options?: any, filterResultByColumns?: any) => {
    let mapping: keyof typeof _mappings = 'load';
    const params: {type:any, companyId:any} = { type: type, companyId: null };

    if (typeof q === 'number') {
        mapping = 'load';
        _.extend(params, { id: q });
    } else if (typeof q === 'undefined' || !q) {
        mapping = 'loadList';
    }

    if (pageSize) {
        page = page || 1;
        mapping = _pagedMappingFor(mapping);
        _.extend(params, { page: page, pageSize: pageSize });
    }
    
    if (filterResultByColumns) {
        mapping = _filterMappingFor(mapping);
        _.extend(params, { filterResultByColumns: filterResultByColumns });
    }

    return _performAjax(mapping, params, options);
};

export const loadWithCompanyContext = (type: any, companyId: any, pageSize: any, page: number, options: any, filterResultByColumns: any) => {
    let mapping : keyof typeof _mappings = 'loadList';
    const params = { type: type, companyId: companyId };

    if (pageSize) {
        page = page || 1;
        mapping = _pagedMappingFor(mapping);
        _.extend(params, { page: page, pageSize: pageSize });
    }
    
    if (filterResultByColumns) {
        mapping = _filterMappingFor(mapping);
        _.extend(params, { filterResultByColumns: filterResultByColumns });
    }

    return _performAjax(mapping, params, options);
};

export const save = (type: any, model: any, id?: string | number, options?: any) => {
    model.$type = model.$type || type;
    const mappingName = _selectUpdateMapping(id);
    options = _.extend(options || {}, {
        data: serialization.toJSON(model)
    });
    return _performAjax(mappingName, { type: type, id: id }, options);
}; 

export const remove = (type: any, data: { Id: any; }, options?: any) => {
    let id;
    if (typeof data === 'number') {
        id = data;
    } else {
        id = data.Id;
    }
    return _performAjax('remove', { type: type, id: id }, options);
};

export const getNew = (type: any) => {
    const fromCache = resolveNew(type);
    const newObject = _.extend({}, fromCache);
    newObject.ViewId = guid.newGuid();

    return newObject;
};

export const count = (type: any, companyId: any) => {
    companyId = parseInt(companyId, 10);
    companyId = companyId || "";
    const uri = `count?type=${type}&companyId=${companyId}`;
    return rest.ajax("Synchronization", uri, undefined);
};

export const getNewGuid = () => {
    return guid.newGuid();
};