///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Entities.CustomFields.ListCustomField/Values"/>
import * as ko from "knockout";
import * as sync from "Core/Medius.Core.Web/Scripts/Medius/core/sync";
import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";

class ValueTab {
    Entity: any;
    Context: any;
    IsNewEntity: boolean;
    addLine: () => void;
    hasValues: ko.Computed<boolean>;
    hasNoValues: ko.Computed<boolean>;
    removeLine: (values: any, value: any) => void;
    dispose: () => void;
    constructor(entityWithContext:any) {
        const entityModel = entityWithContext.Entity;

        if (isNullOrUndefined(entityModel)) {
            throw "medius/viewModels/administration/Medius.Core.Entities.CustomFields.ListCustomField/Values: Entity model not defined";
        }

        this.Entity = entityModel;

        this.Context = entityWithContext.Context;

        this.IsNewEntity = this.Entity.Id() === 0;

        this.addLine = () => {
            let newCustomFieldValue = sync.getNew("Medius.Core.Entities.CustomFields.ListCustomFieldValue");
            newCustomFieldValue = this.Context.create(newCustomFieldValue);
            this.Entity.Values.push(newCustomFieldValue);

        };

        this.hasValues = ko.computed(() => {
            return this.Entity.Values().length !== 0;
        });

        this.hasNoValues = ko.computed(() => {
            return this.Entity.Values().length === 0;
        });

        this.removeLine = (values:any, value:any) => {
            this.Entity.Values.remove(value);
        };

        this.dispose = () => {
            this.hasValues.dispose();
            this.hasNoValues.dispose();
        };
    }
}

export = function (entityWithContext:any) {
    return new ValueTab(entityWithContext);
};