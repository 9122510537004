///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/presenters/date/model"/>
import { Basic } from "Core/Medius.Core.Web/Scripts/Medius/components/presenters/basic";
import * as localizer from "Core/Medius.Core.Web/Scripts/Medius/components/editors/date/localizer";
import * as _ from "underscore";

class DatePresenter extends Basic {
    LocalizedValue: any;

    constructor(bindingParameters: any) {
        const defaults = {
            format: "d",
            template: "presenters-date-default",
            ...bindingParameters
        };

        const dateLocalizer = localizer.create(defaults.format);
        super(defaults, dateLocalizer);
    }
}

export function create(bindingParameters:any) {
    return new DatePresenter(bindingParameters);
}

export function extend(obj:any, bindingParameters:any) {
    _.extend(obj, create(bindingParameters));
}