/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/dashboard/dashboard"/>

import { isNotNullOrUndefined, isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import * as user from "Core/Medius.Core.Web/Scripts/Medius/lib/utils/user";
import * as modalRenameDashboard from "Core/Medius.Core.Web/Scripts/Medius/apps/dashboard/modals/renameDashboard";
import { handleAnyError } from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import * as _ from "underscore";
import { get, put } from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rest";
import * as gadget from "Core/Medius.Core.Web/Scripts/Medius/apps/dashboard/gadget";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { error, success } from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import { ajax } from "Core/Medius.Core.Web/Scripts/Medius/core/rpc";
import { toJSON } from "Core/Medius.Core.Web/Scripts/Medius/lib/serialization";
import { observableArray, observable } from "knockout";
import * as $ from "jquery";

type DashboardData = {
    Id: any;
    ActionAndControlOnly: any;
    Contexts: any;
    Name: any;
    IsShared: any;
    Owner: {
        Id: any;
    };
    ColumnsTotal: any;
};

class DashboardColumn {
    Width: string;
    Gadgets = observableArray([]);
    constructor(width: string | number) {
        this.Width = width + "px";
    }
}

class Dashboard {
    settings: any;
    isVisible = observable(false);
    id: any;
    ActionAndControlOnly: any;
    Contexts: any;
    name: ko.Observable<any>;
    IsShared: any;
    isOwned: boolean;
    IsLoaded = observable(false);
    ColumnsNumber: any;
    Data: DashboardData;
    columns = observableArray([]);
    containerId: string;
    tabId: string;
    hrefContainerId: string;
    Framework: any;
    template = "Core:Medius/apps/dashboard/templates/DefaultDashboardTemplate.html";
    deleteDashboard: () => void;
    renameDashboard: () => void;
    addGadget: () => void;
    constructor(framework: any, data: DashboardData, options: any) {
        if (_.isNull(framework)) {
            throw new Error("Dashboard init error - Framework is null");
        }

        this.settings = _.extend({
            width: 1000
        }, options);

        this.id = data.Id;
        this.ActionAndControlOnly = data.ActionAndControlOnly;
        this.Contexts = data.Contexts;
        this.name = observable((!data.Name) ? "" : data.Name);
        this.IsShared = (!data.IsShared) ? false : data.IsShared;
        this.isOwned = isNotNullOrUndefined(data.Owner) && user.getEntity().Id === data.Owner.Id;
        this.ColumnsNumber = data.ColumnsTotal;
        this.Data = data;
        this.containerId = "dashboard-" + this.Data.Id;
        this.tabId = this.containerId + "-tab";
        this.hrefContainerId = "#" + this.containerId;
        this.Framework = framework;

        // Initialize dashboard
        this.init();

        this.deleteDashboard = () => {
            this.Framework.deleteDashboardWindow.Id = this.Data.Id;
            $("#modal-dashboard-delete").modal("show");
        };

        this.renameDashboard = () => {
            this.Framework.renameDashboardWindow(modalRenameDashboard.create(this));
            $("#modal-dashboard-rename").modal("show");
        };

        this.addGadget = () => {
            this.Framework.gadgetsLibrary.Dashboard = this;
            this.Framework.gadgetsLibrary.Name(this.name());
            $("#modal-dashboard-library").modal("show");
        };
    }

    init() {
        const columns = [];

        // Calculate column width
        const columnWidth = (this.settings.width / this.ColumnsNumber) - 40;

        // Create columns
        for (let i = 0; i < this.ColumnsNumber; i = i + 1) {
            columns.push(new DashboardColumn(columnWidth));
        }

        this.columns(columns);
    }

    errorHandler(jqXhr: any, errorSummary: string) {
        handleAnyError(jqXhr, null, errorSummary);
    }

    setActive() {
        this.loadStoredGadgets();
    }

    /**
    * Loads gadgets installed on this dashboard
    * @param self
    * @ignore
    */
    loadStoredGadgets() {

        if (this.IsLoaded()) {
            return;
        }
        this.IsLoaded(true);

        get("dashboard/dashboardGadgets", this.Data.Id).then(
            (dashboardGadgets: any) =>
            {
                const gadgets = this.initializeGadgets(dashboardGadgets);
                this.loadGadgetsTemplates(gadgets);
            }, (xhr: any) => {
                this.errorHandler(xhr, "#Core/dashboardGadgetsCannotBeDisplayedError");
            });
    }

    initializeGadgets(dashboardGadgets: any) {
        const availableGadgets = this.Framework.gadgetsLibrary.Gadgets();

        const gadgets = _.chain(dashboardGadgets)
            .map((gadgetDashboard) => {
                return gadget.create(this, gadgetDashboard, {
                    title: gadgetDashboard.Gadget.Title,
                    settings: gadgetDashboard.Settings,
                    available: availableGadgets.some((g: { Id: any; }) => g.Id === gadgetDashboard.Gadget.Id)
                });
            })
            .each((gd) => {
                this.positionGadgetOnDashboard(gd);
            })
            .value();

        return gadgets;
    }

    positionGadgetOnDashboard(gd: { Position: any; }) {
        const position = gd.Position;

        if (isNullOrUndefined(this.columns()[position])) {
            error(getLabelTranslation("#Core/errorCouldNotDisplayGadget"));
            return;
        }

        // Add gadget to proper column
        this.columns()[position].Gadgets.push(gd);
    }

    loadGadgetsTemplates(gadgets: any) {
                const reqs = _.chain(gadgets)
            .filter((g) => {
                 return g.IsAvailable;
            })
            .map((g) => {
                return g.FileContent;
            })
            .uniq()
            .map((filePath) => {
                return this.renderGadgetsWithTemplate(gadgets, filePath);
            })
            .value();

        return $.when(reqs);
    }

    renderGadgetsWithTemplate(gadgets: any, filePath: any) {
        _(gadgets).each((gd) => {
            if (gd.FileContent === filePath) {
                gd.render();
            }
        });

        return $.when();
    }

    /**
    * Renames dashboard
    * @param newTitle
    * @ignore
    */
    rename(newTitle: string) {
        put("dashboard/update", "", {
            Id: this.Data.Id,
            Name: newTitle
        })
        .then(() => {
            this.name(newTitle);
            this.Data.Name = newTitle;
            success(getLabelTranslation("#Core/dashboardsNameChanged"));
        }, (xhr: any) => {
            this.errorHandler(xhr, "#Core/dashboardsNameNotChangedError");
        });
    }

    /**
    * Installs new instance of chosen gadget on dashboard
    * @param gadgetLibrary
    * @ignore
    */
    installGadget(gadgetLibrary: { Id: any; }) {

        if (_.isUndefined(gadgetLibrary.Id) || _.isNull(gadgetLibrary.Id)) {
            return;
        }

        // Register this gadget in database in order to get the gadget instance ID
        ajax("CoreGadgetService", "InstallGadget", {
            data: toJSON({ gadgetId: gadgetLibrary.Id, dashboardId: this.Data.Id })
        }).pipe((gadgetDashboard: any) => {
            return this.initializeGadgets([gadgetDashboard]);
        }).pipe((gadgets: any) => {
            return this.loadGadgetsTemplates(gadgets);
        }).fail((xhr: any) => {
            this.errorHandler(xhr, getLabelTranslation("#Core/gadgetNotInstalledError"));
        });
    }

    /**
    * Invoked when user has removed out a gadget from the dashboard
    * @param gadget
    * @ignore
    */
    closeGadget(gadgetInstance: { GadgetDashboard: { Id: string; }; disposeGadget: () => void; }) {

        // Delete instance from persistance layer
        ajax("CoreGadgetService", "DeleteGadget", {
            data: toJSON({ gadgetId: gadgetInstance.GadgetDashboard.Id })
        }).pipe(() => {
            // Delete gadget from column
            _(this.columns()).each((column) => {
                column.Gadgets.remove(gadgetInstance);
            });

            $("#" + this.containerId + " #gadget-" + gadgetInstance.GadgetDashboard.Id).remove();
            gadgetInstance.disposeGadget();

            return this.saveLayout();
        }).done(() => {
            success(getLabelTranslation("#Core/gadgetRemoved"));
        }).fail((xhr: any) => {
            this.errorHandler(xhr, getLabelTranslation("#Core/gadgetNotDeletedError"));
        });
    }

    dispose() {

        _(this.columns()).each((columnInstance) => {
            _(columnInstance.Gadgets()).each((gadgetInstance) => {
                gadgetInstance.disposeGadget();
            });
        });
    }

    /**
    * Saves gadgets position
    * Invokes when user has moved a gadget
    * @param self
    * @ignore
    */
    saveLayout() {
        const columns: any[] = [],
            gadgetsPositionInfo: { Id: any; ColumnIndex: number; PositionInColumn: any; }[] = [];

        _.each($("#" + this.containerId).find(".column"), (column) => {
            columns.push($(column).sortable("toArray"));
        });

        _.each(columns, (column, i) => {
            let positionInColumn: number;

            if (column.length > 0) {
                positionInColumn = 0;

                _.each(column, (gadgetId: string) => {
                    gadgetsPositionInfo.push({
                        Id: gadgetId.replace("gadget-", ""),
                        ColumnIndex: i,
                        PositionInColumn: positionInColumn
                    });
                    positionInColumn++;
                });
            }
        });

        return ajax("CoreGadgetService", "SaveDashboardLayout", {
            data: toJSON({ gadgets: gadgetsPositionInfo })
        }).fail((xhr: any) => {
            this.errorHandler(xhr, getLabelTranslation("#Core/dashboardLayoutNotSavedError"));
        });
    }
}

export default function create(framework: any, data: DashboardData, options: any) {
    return new Dashboard(framework, data, options);
}