///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/workflow/studio/businessRuleEditor"/>
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as notification from "Core/Medius.Core.Web/Scripts/Medius/core/notification";
import * as rpc from "Core/Medius.Core.Web/Scripts/Medius/core/rpc";
import * as path from "Core/Medius.Core.Web/Scripts/Medius/lib/path";
import * as serialization from "Core/Medius.Core.Web/Scripts/Medius/lib/serialization";
import * as backendErrorHandler from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import * as ko from "knockout";
import * as $ from "jquery";
import contextFactory = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/context");
import * as _ from "underscore";
import * as sync from "Core/Medius.Core.Web/Scripts/Medius/core/sync";
import { formatValueWithPlaceholders } from "../../../lib/stringFormat";
import { DeleteBusinessRuleConfirmationDialog } from "Core/Medius.Core.Web/Scripts/Medius/apps/workflow/studio/deleteBusinessRuleConfirmationDialog";

class ViewModel {
    context: any;
    Application: ko.Observable<any>;
    Version: ko.Observable<any>;
    contextApplication = ko.observable();
    CurrentAppConfiguration = ko.observable();

    AvailableWorkflows = ko.observableArray();
    AvailableActivities = ko.observableArray();
    AvailableCompanies = ko.observableArray();
    AvailablePerspectives = ko.observable();

    SelectedBusinessRule = ko.observable();
    EditedBusinessRule = ko.observable();
    BusinessRuleCompany = ko.observableArray();

    InitialCompany = ko.observable();
    InitialWorkflow = ko.observable();
    InitialActivity = ko.observable();
    InitialIsActive = ko.observable(true);
    IncludeInitialConfiguration = ko.observable(true);
    IsNewBusinessRule = ko.observable(false);
    IsLoading = ko.observable(false);

    SelectedPerspective = ko.observable();
    ContextAppBusinessRules = ko.observableArray();
    TemplateAppBusinessRules = ko.observableArray();

    Result = ko.observable([]);
    IsValid = ko.observable();
    HideValidationMessages: () => void;
    CombineConfigurationString: () => string | false;
    onGetContextAppSuccess: (data: any) => void;
    initializeContextApplication: () => void;
    InitialSynch: ko.Computed<void>;
    IsAppOnlineDev: ko.Computed<boolean>;
    IsBusinessRuleEditable: ko.Computed<boolean>;
    LoadBusinessRulesForSelectedPerspective: (ruleNameToSelect: any) => void;
    BusinessRuleChanged: any;
    AvailableBusinessRulesSynch: ko.Computed<void>;
    AvailableActivitiesSynch: ko.Computed<void>;
    ShowOkMessage: (messageTag: any) => void;
    ShowErrorMessage: (jsonError: any) => void;
    Compile: () => void;
    BusinessRuleSaved: () => void;
    Save: () => void;
    Create: (override: any) => void;
    IsOwnBusinessRule: ko.Computed<boolean>;
    IsRemovingPossibleToolTip = ko.observable();
    IsRemovingPossible: ko.Computed<any>;
    IsCreatingPossible: ko.Computed<any>;
    IsOverridingPossible: ko.Computed<boolean>;
    IsEditorActive: ko.Computed<any>;
    Delete: () => void;
    WorkflowStudioLink: ko.Computed<string>;
    OpenDeleteConfirmationPopup: () => void;
    IsPopupOpened: ko.Observable<boolean>;
    ConfirmationPopupTemplate: ko.Computed<any>;

    constructor(applicationName: any, version: any, editor: { setOption: (arg0: string, arg1: boolean) => void; setValue: (arg0: string) => void; getValue: () => any; }) {
        this.context = contextFactory();
        this.Application = ko.observable(applicationName);
        this.Version = ko.observable(version);

        this.HideValidationMessages = () => {
            this.Result([]);
            this.IsValid(null);
        };

        this.CombineConfigurationString = () => {
            if (this.contextApplication()) {
                const app = this.contextApplication(),
                    confString = [app.Name(), app.Version(), app.Type()].join("/ ");
                return confString;
            }
            return false;
        };

        this.onGetContextAppSuccess = (data) => {
            const application = this.context.create(data);

            this.contextApplication(application);

            const conf = this.CombineConfigurationString();
            this.CurrentAppConfiguration(conf);
        };

        this.initializeContextApplication = () => {
            rpc.ajax("ApplicationPackageService", "GetApplicationPackage", {
                data: serialization.toJSON({ name: this.Application, version: this.Version })
            }).done((data: any) => {
                this.onGetContextAppSuccess(data);
            });
        };

        this.InitialSynch = ko.computed(() => {

            rpc.ajax("BusinessRuleEditorManager", "GetAvailablePerspectives", {
                data: serialization.toJSON({ applicationName: this.Application, version: this.Version })
            }).done((data: any) => {
                this.AvailablePerspectives(data);
            });

            sync.load("Medius.Data.WorkflowMetadata.WorkflowDefinition")
                .done((list: any[]) => {
                    this.AvailableWorkflows(list);
                });

            sync.load("Medius.Core.Entities.Company")
                .done((list: any[]) => {
                    this.AvailableCompanies(list);
                });
        });

        this.IsAppOnlineDev = ko.computed(() => {
            const isOnlineDev =  this.SelectedPerspective() &&
                this.SelectedPerspective().IsAPIBased && this.contextApplication().IsCloudCustomization();
            editor.setOption("readOnly", !(isOnlineDev));
            return isOnlineDev;
        });


        this.IsBusinessRuleEditable = ko.computed(() => !!this.EditedBusinessRule());

        this.LoadBusinessRulesForSelectedPerspective = (ruleNameToSelect) => {
            rpc.ajax("BusinessRuleEditorManager", "GetBusinessRules", {
                data: serialization.toJSON({
                    perspective: this.SelectedPerspective().Type,
                    applicationName: this.Application,
                    version: this.Version
                })
            }).done((data: unknown[]) => {
                this.ContextAppBusinessRules([]);
                this.TemplateAppBusinessRules([]);
                ko.utils.arrayForEach(data, (item: any) => {
                    if (item.ApplicationName === this.Application() && item.ApplicationVersion === this.Version() && this.IsAppOnlineDev()) {
                        this.ContextAppBusinessRules.push(serialization.fromJS(item));
                    } else {
                        this.TemplateAppBusinessRules.push(serialization.fromJS(item));
                    }
                });
                // select the initial rule after loading

                if (this.ContextAppBusinessRules().length > 0) {
                    if (ruleNameToSelect) {
                        const ruleToSelect = $.grep(this.ContextAppBusinessRules(), (e: any) => {
                            return e.Name() === ruleNameToSelect;
                        })[0];
                        this.SelectedBusinessRule(ruleToSelect);
                    } else {
                        this.SelectedBusinessRule(this.ContextAppBusinessRules()[0]);
                    }
                } else if (this.TemplateAppBusinessRules().length > 0) {
                    this.SelectedBusinessRule(this.TemplateAppBusinessRules()[0]);
                } else {
                    this.SelectedBusinessRule(null);
                }
                this.BusinessRuleChanged();
            });
        };

        this.AvailableBusinessRulesSynch = ko.computed(() => {
            if (this.SelectedPerspective()) {
                this.LoadBusinessRulesForSelectedPerspective(null);
            } else {
                this.ContextAppBusinessRules([]);
                this.TemplateAppBusinessRules([]);
                this.EditedBusinessRule(null);
            }
        });

        this.AvailableActivitiesSynch = ko.computed(() => {
            if (this.InitialWorkflow()) {
                rpc.ajax("WorkflowDefinitionDataService", "GetActivityDefinitions", {
                    data: serialization.toJSON({ workflowId: this.InitialWorkflow().Id })
                }).done((list: unknown[]) => {
                    this.AvailableActivities(list);
                }).fail((xhr: backendErrorHandler.XHR) => {
                    backendErrorHandler.handleAnyError(xhr);
                });
            } else {
                this.AvailableActivities(null);
            }
        });

        this.BusinessRuleChanged = () => {
            if (this.SelectedBusinessRule()) {
                // copy the selected rule to the edited observable
                this.EditedBusinessRule(serialization.fromJS(ko.toJS(this.SelectedBusinessRule())));

                editor.setValue(this.EditedBusinessRule().Content());

                rpc.ajax("BusinessRuleEditorManager", "GetBusinessRuleCompanies", {
                    data: serialization.toJSON({
                        name: this.SelectedBusinessRule().Name(),
                        perspectiveType: this.SelectedBusinessRule().Perspective()
                    })
                }).done((list: unknown[]) => {
                    this.BusinessRuleCompany(list);
                }).fail((xhr: backendErrorHandler.XHR) => {
                    backendErrorHandler.handleAnyError(xhr);
                });
            } else {
                this.EditedBusinessRule(null);
                editor.setValue("");
            }
            this.IsNewBusinessRule(false);
            this.HideValidationMessages();
        };


        this.ShowOkMessage = (messageTag) => {
            this.IsValid(true);
            notification.success(globalization.getLabelTranslation(messageTag));
        };

        this.ShowErrorMessage = (jsonError) => {
            const results = JSON.parse(jsonError.responseText);
            _(results.ValidationResults).each((validationResult) => {
                const validationMessage = validationResult.ValidationMessage;
                const formattedMessage = formatValueWithPlaceholders(validationMessage.Message, [validationMessage.MessageParams]);
                validationMessage.Message = formattedMessage;
            });
            this.Result(results.ValidationResults);
            this.IsValid(false);
        };

        this.Compile = () => {
            this.EditedBusinessRule().Content(editor.getValue());

            rpc.ajax("BusinessRuleEditorManager", "CompileBusinessRule", {
                data: serialization.toJSON({ businessRule: this.EditedBusinessRule() })
            }).done(() => {
                this.ShowOkMessage("#Core/businessRuleCompiledOK");
            }).fail((error: any) => {
                this.ShowErrorMessage(error);
            });
        };

        this.BusinessRuleSaved = () => {
            this.ShowOkMessage("#Core/businessRuleSavedOK");
            this.LoadBusinessRulesForSelectedPerspective(this.EditedBusinessRule().Name());
        };

        this.Save = () => {
            if (this.IsLoading()) {
                return;
            }
            this.IsLoading(true);
            if (!this.EditedBusinessRule().EntityType()) {
                notification.error(
                    globalization.getLabelTranslation("#Core/dataObjectTypeIsRequiredInBusinessRule"));
            } else {
                this.EditedBusinessRule().Content(editor.getValue());

                rpc.ajax("BusinessRuleEditorManager", "SaveBusinessRule", {
                    data: serialization.toJSON({ businessRule: this.EditedBusinessRule() })
                }).done(() => {

                    // if the initial configuration needs to be saved as well
                    if (this.IsNewBusinessRule() && this.IncludeInitialConfiguration()) {
                        const config = sync.getNew("Medius.Core.Entities.BusinessRule.BusinessRuleCompany");
                        config.Company = this.InitialCompany();
                        config.PerspectiveType = this.EditedBusinessRule().Perspective();
                        config.Priority = 1;
                        config.Name = this.EditedBusinessRule().Name();
                        config.Active = this.InitialIsActive();
                        config.ActivityName = this.InitialActivity() ? this.InitialActivity().Name : null;
                        sync.save("Medius.Core.Entities.BusinessRule.BusinessRuleCompany", config)
                            .done(() => {
                                this.BusinessRuleSaved();
                            });
                    } else {
                        this.BusinessRuleSaved();
                    }

                }).fail((error: backendErrorHandler.XHR) => {
                    if (error.responseText !== "") {
                        this.ShowErrorMessage(error);
                    } else {
                        backendErrorHandler.handleAnyError(error);
                    }
                }).always(() => {
                    this.IsLoading(false);
                });
            }
        };


        this.Create = (override) => {
            rpc.ajax("BusinessRuleEditorManager", "GetNewBusinessRule").done((data: { ApplicationName: any; ApplicationVersion: any; Perspective: any; Content: string; Name: any; EntityType: any; }) => {
                data.ApplicationName = this.Application();
                data.ApplicationVersion = this.Version();
                data.Perspective = this.SelectedPerspective().Type;
                data.Content = "";

                if (override === true) {
                    data.Name = this.SelectedBusinessRule().Name();
                    data.Content = this.SelectedBusinessRule().Content();
                    data.EntityType = this.SelectedBusinessRule().EntityType();
                    this.InitialWorkflow(null);
                    this.InitialIsActive(true);
                    this.IncludeInitialConfiguration(true);
                    this.IsNewBusinessRule(false);
                } else {
                    data.Content = "";
                    this.IsNewBusinessRule(true);
                }
                this.HideValidationMessages();
                this.EditedBusinessRule(serialization.fromJS(data));
                editor.setValue(data.Content);
            });
        };


        this.IsOwnBusinessRule = ko.computed(() => {
            if (this.SelectedBusinessRule()) {
                return $.inArray(this.SelectedBusinessRule(), this.ContextAppBusinessRules()) >= 0;
            } else {
                return false;
            }
        });

        this.IsRemovingPossible = ko.computed(() => {
            this.IsRemovingPossibleToolTip(this.SelectedBusinessRule() && this.IsAppOnlineDev() && this.IsOwnBusinessRule());
            return this.IsRemovingPossibleToolTip();
        });

        this.IsCreatingPossible = ko.computed(() => {
            // new rule can be created when the app is online,dev and the perspective was selected
            return this.SelectedPerspective() && this.IsAppOnlineDev();
        });

        this.IsOverridingPossible = ko.computed(() => {
            // rule can be overriden when the app is online,dev; the perspective was selected and selected rule is one of the template ones
            return this.SelectedPerspective() && this.SelectedBusinessRule() && this.IsAppOnlineDev() && !this.IsOwnBusinessRule();
        });

        this.IsEditorActive = ko.computed(() => {
            const isActive = this.EditedBusinessRule() && this.IsAppOnlineDev() && (this.IsBusinessRuleEditable() || this.IsOwnBusinessRule());
            editor.setOption("readOnly", !(isActive));
            return isActive;
        });

        this.OpenDeleteConfirmationPopup = () => {
            this.IsPopupOpened(true);
        };

        this.Delete = () => {
            if (this.SelectedBusinessRule()) {
                rpc.ajax("BusinessRuleEditorManager", "DeleteBusinessRule", {
                    data: serialization.toJSON({ businessRule: this.SelectedBusinessRule() })
                }).done(() => {
                    this.ShowOkMessage("#Core/businessRuleDeletedOK");
                    this.LoadBusinessRulesForSelectedPerspective(null);
                }).fail((error: any) => {
                    this.ShowErrorMessage(error);
                });
            }
        };

        this.WorkflowStudioLink = ko.computed(() => {
            return path.toAction("Index", "~/WorkflowStudio") + "?ApplicationName=" + this.Application() + "&Version=" + this.Version();
        });

        this.initializeContextApplication();

        this.SelectedBusinessRule.subscribe(() => this.BusinessRuleChanged());

        this.IsPopupOpened = ko.observable(false);
        this.ConfirmationPopupTemplate =  ko.computed(() => {
            return ({
                functionComponent: DeleteBusinessRuleConfirmationDialog,
                props: {
                    IsDialogOpen: this.IsPopupOpened(),
                    OnConfirm: () => {
                        this.IsPopupOpened(false);
                        this.Delete();
                    },
                    OnCancel: () => {
                        this.IsPopupOpened(false);
                    }
                }
            });
        });
    }
}

export function create(applicationName: any, version: any, editor: { setOption: (arg0: string, arg1: boolean) => void; setValue: (arg0: string) => void; getValue: () => any; }) {
    return new ViewModel(applicationName, version, editor);
}

export function register() {
    ko.bindingHandlers.option = {
        update: function (element, valueAccessor) {
            const value = ko.utils.unwrapObservable(valueAccessor());
            ko.selectExtensions.writeValue(element, value);
        }
    };
}

