/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Entities.Api.ClientApplication/Editor" />
import * as ko from "knockout";
import {Flow, ClientApplication} from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Entities.Api.ClientApplication/ClientApplicationViewModel";

class Editor {

    public readonly entity: ClientApplication;
    public readonly isInCreateMode: ko.Observable<boolean>;
    public readonly isRedirectUriApplicable: ko.Computed<boolean>;
    public readonly isClientSecretApplicable: ko.Computed<boolean>;
    public readonly flows: ko.ObservableArray<FlowOption>;
    public readonly isPostLogoutRedirectUriAssigned: ko.Observable<boolean>;
    public readonly isFrontChannelLogoutUriAssigned: ko.Observable<boolean>;

    constructor(entityWithContext: any) {
        this.entity = entityWithContext.Entity;

        this.isInCreateMode = ko.observable(this.entity.isNew());

        this.isRedirectUriApplicable = ko.computed(() => {
            return this.entity.flow() === Flow.ImplicitFlow || this.entity.flow() === Flow.AuthorizationCodeFlow;
        });

        this.isClientSecretApplicable = ko.computed(() => this.entity.flow() !== Flow.ImplicitFlow);

        this.flows = ko.observableArray([
            { value: Flow.PasswordFlow, text: Flow[Flow.PasswordFlow] },
            { value: Flow.AuthorizationCodeFlow, text: Flow[Flow.AuthorizationCodeFlow] },
            { value: Flow.ImplicitFlow, text: Flow[Flow.ImplicitFlow] },
            { value: Flow.ClientCredentialsFlow, text: Flow[Flow.ClientCredentialsFlow] }
        ]);

        this.isPostLogoutRedirectUriAssigned = ko.observable(this.entity.isPostLogoutRedirectUriAssigned());

        this.isFrontChannelLogoutUriAssigned = ko.observable(this.entity.isFrontChannelLogoutUriAssigned());
    }

    public dispose() {
        this.isRedirectUriApplicable.dispose();
        this.isClientSecretApplicable.dispose();
    }

}

interface FlowOption {
    value: Flow;
    text: string;
}

export default function editorFunc(entityWithContext: any) {
    return new Editor(entityWithContext);
}
