///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Reminders/reminderConfigurationHandler"/>
import * as sync from "Core/Medius.Core.Web/Scripts/Medius/core/sync";
import contextFactory = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/context");
import { isEmptyString, isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import * as ko from "knockout";

function resetAsNew(entity:{Id:ko.Observable<number>, EntityVersion:ko.Observable<number>, ViewId:ko.Observable<string>, CreatedTimestamp:ko.Observable<any>}) {
    entity.Id(0);
    entity.EntityVersion(0);
    entity.ViewId(sync.getNewGuid());
    entity.CreatedTimestamp(null);
}

class ReminderConfigurationHandler {
    options: any;
    reminders: any;
    availableReminders: any;
    context: any;
    constructor(reminderConfigurations:any, availableReminders:any, options:any) {
        options = {
            ...options,
            tableType: options.tableType ?? ''
        };

        this.options = options;
        this.reminders = reminderConfigurations;
        this.availableReminders = availableReminders;
        this.context = contextFactory();
    }
    addReminderRow(reminderConfig:any, documentType:string, messageTemplate:any) {
        const defaultReminderConfiguration = this.availableReminders.getAvailableReminder();
        const reminderDetailsType = defaultReminderConfiguration.ReminderDetailsType;
        const newReminderSendInSummary = defaultReminderConfiguration.SendInSummaryByDefault;
        const newReminderDetails = (!isEmptyString(reminderDetailsType)) ? this.context.create(sync.getNew(reminderDetailsType)) : null;

        if (defaultReminderConfiguration.CompatibleDocumentTypes.some((x:string) => x === documentType)) {
            documentType = defaultReminderConfiguration.ReminderCompatibleTypes[0].Name;
        }

        let newReminderConfigRow = sync.getNew("Medius.Core.Entities." + this.options.tableType + "ReminderConfigurationRow");
        newReminderConfigRow = this.context.create(newReminderConfigRow);
        newReminderConfigRow.Enabled(true);
        newReminderConfigRow.EntityFullTypeName(documentType);
        newReminderConfigRow.Template(messageTemplate);
        newReminderConfigRow.Details(newReminderDetails);
        newReminderConfigRow.SendSummary(newReminderSendInSummary);
        
        reminderConfig.Rows.unshift(newReminderConfigRow);

        return newReminderConfigRow;
    }
    
    findReminderConfig(reminderType:string) {
        return this.reminders().find((reminder: {ReminderType:ko.Observable<string>}) => {
            return reminder.ReminderType() === reminderType;
        });
    }
    
    moveReminder(reminderRow:any) {
        const newReminderType = reminderRow.ReminderType();
        const newReminderDocumentType = reminderRow.EntityFullTypeName().Value;
        const currentReminderConfiguration = this.findReminderConfig(reminderRow.CurrentReminderType);
        let newReminderConfiguration = this.findReminderConfig(newReminderType);

        if (currentReminderConfiguration === newReminderConfiguration) {
            return;
        }

        reminderRow.ReminderType(newReminderType);
        reminderRow.CurrentReminderType = newReminderType;
        resetAsNew(reminderRow);

        if (!newReminderConfiguration) {
            const reminderInfo = this.availableReminders.getAvailableReminder(newReminderType, newReminderDocumentType);
            newReminderConfiguration = this.createReminderConfiguration(reminderInfo);
        }

        currentReminderConfiguration.Rows.remove(reminderRow);
        newReminderConfiguration.Rows.push(reminderRow);

        this.cleanReminderConfigs();
    }
    
    createReminderConfiguration(reminderInfo:any) {
        reminderInfo = reminderInfo || this.availableReminders.getAvailableReminder();

        if (reminderInfo === null) {
            return null;
        }

        let reminderConfig = sync.getNew("Medius.Core.Entities." + this.options.tableType + "ReminderConfiguration");
        reminderConfig = this.context.create(reminderConfig);
        reminderConfig.ReminderType(reminderInfo.ReminderType);

        this.reminders.push(reminderConfig);

        return reminderConfig;
    }
    
    removeRow(reminderRow:any) {
        if (!reminderRow) {
            return;
        }

        this.reminders().forEach((reminderConfig:any) => {
            if (reminderConfig.Rows().includes(reminderRow)) {
                reminderConfig.Rows.remove(reminderRow);
                return;
            }
        });

        this.cleanReminderConfigs();
    }
    
    cleanReminderConfigs() {
        const emptyConfigurations = this.reminders().filter((reminder:{Rows:ko.ObservableArray<any>}) => {
            return reminder.Rows().length === 0;
        });

        if (emptyConfigurations.length > 0) {
            this.reminders.removeAll(emptyConfigurations);
        }
    }
    
    onReminderTypeChanged(reminderConfigurationRow:any) {
        if (reminderConfigurationRow.ReminderType() !== reminderConfigurationRow.CurrentReminderType) {
            this.moveReminder(reminderConfigurationRow);
        }
    }
    
    getTemplateName(row:any) {
        if (isNullOrUndefined(row.Template)) {
            return '';
        }
        
        if (isNullOrUndefined(row.Template().Name)) {
            return '';
        }
            
        return ko.utils.unwrapObservable(row.Template().Name);
    }
    
    isUnique(row:any) {
        const reminderConfig = this.findReminderConfig(row.ReminderType());

        if (!reminderConfig) {
            return true;
        }

        if (this.options.tableType === 'External') {
            return true;
        }

        const reminderConfigRow = reminderConfig.Rows().find((rr: {EntityFullTypeName:ko.Observable<string>}) => {
            return rr.EntityFullTypeName() === row.EntityFullTypeName() && rr !== row;
        });
        
        if (reminderConfigRow) {
            return false;
        }

        return true;
    }
}

export function create(reminderConfigurations:any, availableReminders:any, options:any) {
    return new ReminderConfigurationHandler(reminderConfigurations, availableReminders, options);
}