///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/configurationProperties/mergedCollection"/>
import base = require("Core/Medius.Core.Web/Scripts/Medius/components/configurationProperties/baseCollection");
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as _ from "underscore";
import * as ko from "knockout";

export class MergedCollectionProperty extends base {
    Columns: any;
    BindingTemplate: string;
    SelectedItem: ko.Observable<any>;

    constructor(params: any) {
        super(params);
        
        this.Columns = params.Columns;
        if (!params.WithoutCollectionGrid) {
            this.collectionGrid = this.createGrid();
            this.disposables.push(this.collectionGrid);
        }
        
        this.BindingTemplate = "configuration-simple-collection-property";
        this.SelectedItem = ko.observable(null);
        
        this.disposables.push(
           this.CurrentValue
               .subscribe(() => {
                   const isOverriden = this.CurrentValue().length !== 0;
                   this.IsOverriden(isOverriden);
               }, this));


        //in case of mergedCollection we don't want to disable control when data is inherited.
        //(see base.js IsDisabled property implementation)
        //that's why we override IsDisabled property here to take into consideration licensing only
        this.IsDisabled = this.IsUnlicensed;
    }
    
    addItem() {
        const item = this.SelectedItem();
        super.addItem(item);
        this.SelectedItem(null);
    }

    getValue() {
        const currentItems = this.getCurrentItemsWithMetadata(),
            inheritedItems = this.getInheritedItemsWithMetadata();

        return _.union(currentItems, inheritedItems);
    }

    getDefaultColumns(): any {
        return [
            {
                ColumnName: globalization.getLabelTranslation("#Core/name"),
                ValueSource: "Data.Name",
                ValueSourceType: "System.String",
                Searchable: false,
                Sortable: false,
                Width: 200
            },
            {
                ColumnName: globalization.getLabelTranslation("#Core/inheritedFrom"),
                ValueSource: "Metadata.OriginCompanyName",
                ValueSourceType: "System.String",
                Searchable: false,
                Sortable: false,
                Width: 200
            },
            {
                ColumnName: "",
                ValueSource: null,
                Searchable: false,
                Sortable: false,
                Width: 100
            }
        ];
    }

    getColumns() {
        if (_.isUndefined(this.Columns)) {
            return this.getDefaultColumns();
        }

        return this.Columns;
    }

    getRowTemplate() {
        return "config-collection-property-row-tpl";
    }
}

export function create(params: any) {
    return new MergedCollectionProperty(params);
}
export const derive = MergedCollectionProperty;