/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Entities.DataAccessSchemaProperty/dataAccessSchemaPropertyTabCreator"/>
import { ObservableArray, Observable, Computed, observableArray, observable, computed, unwrap } from "knockout";
import * as _ from "underscore";
import { getFormattedLabelTranslation, getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { cache } from "Core/Medius.Core.Web/Scripts/Medius/core/type";
import { getNew } from "Core/Medius.Core.Web/Scripts/Medius/core/sync";
import { error } from "Core/Medius.Core.Web/Scripts/Medius/core/notification";

const newCustomPropertyNameRegExp = /^[a-zA-Z_][a-zA-Z0-9_]*$/,
    mandatoryDataAccessSchemaPropertyType = "Medius.Core.Entities.MandatoryDataAccessSchemaProperty",
    customDataAccessSchemaPropertyType = "Medius.Core.Entities.CustomDataAccessSchemaProperty",
    accessSchema = {
        Hide: "Hide",
        Display: "Display",
        Edit: "Edit"
    };

// ReSharper disable InconsistentNaming
class DataAccessSchemaPropertiesTab {
    AccessSchemas: ObservableArray<string>;
    ErrorMessage: Observable<any>;
    CustomPropertyName: Observable<any>;
    PropertyRows: Computed<any[]>;
    CustomPropertyRows: Computed<any>;
    DataTypeHandler: any;
    addCustomRow: () => void;
    dispose: () => void;
    removeCustomRow: (customRow: any) => void;
    constructor(entityWithContext: any) {
        // ReSharper restore InconsistentNaming

        // Private Member Variables
        const context = entityWithContext.Context;

        const createNewDataAccessSchemaProperty = (propertyName: any, isPropertyCustom: any) => {
            let newDataAccessSchemaProperty: any;

            if (this.isNullOrUndefined(isPropertyCustom)) {
                isPropertyCustom = false;
            }

            if (isPropertyCustom) {
                newDataAccessSchemaProperty = getNew(customDataAccessSchemaPropertyType);
            } else {
                newDataAccessSchemaProperty = getNew(mandatoryDataAccessSchemaPropertyType);
            }

            newDataAccessSchemaProperty.PropertyName = propertyName;
            newDataAccessSchemaProperty.AccessSchema = accessSchema.Edit;
            const dataAccessSchemaPropertyVm = context.create(newDataAccessSchemaProperty);

            return dataAccessSchemaPropertyVm;
        };

        // Bindings
        this.AccessSchemas = observableArray([accessSchema.Hide, accessSchema.Display, accessSchema.Edit]);
        this.ErrorMessage = observable();
        this.CustomPropertyName = observable();

        // Computed Bindings
        this.PropertyRows = computed(() => {
            const allProperties = entityWithContext.Entity.PropertiesAccessSchema(),
            mandatoryProperites = this.filterByEntityType(allProperties, mandatoryDataAccessSchemaPropertyType);
            return mandatoryProperites;
        });
        this.CustomPropertyRows = computed(() => {
            const allProperties = entityWithContext.Entity.PropertiesAccessSchema(),
                customProperites = this.filterByEntityType(allProperties, customDataAccessSchemaPropertyType);
            return customProperites;
        });

        // Behavior
        this.DataTypeHandler = entityWithContext.Entity.DataType.subscribe((newDataType: any) => {
            entityWithContext.Entity.PropertiesAccessSchema.removeAll();
            if (_.isUndefined(cache[newDataType])) {
                const incorrectDataTypeErrorMessage = getFormattedLabelTranslation("#Core/typeDoesNotExist" , [newDataType]);
                this.ErrorMessage(incorrectDataTypeErrorMessage);
                return;
            }

            this.ErrorMessage(null);

            const entityTypeProperties = cache["Medius.Data.Entity"].Properties;
            const newTypeProperties = cache[newDataType].Properties;
            const newTypePropertyNames = _.keys(newTypeProperties);

            _(newTypePropertyNames).each((propertyName) => {
                if (propertyName === "$type" ||
                    !_(newTypeProperties).has(propertyName) ||
                    !_.isUndefined(entityTypeProperties[propertyName])) {
                    return;
                }
                const mandatoryDataAccessSchemaPropertyVm = createNewDataAccessSchemaProperty(propertyName, false);
                entityWithContext.Entity.PropertiesAccessSchema.push(mandatoryDataAccessSchemaPropertyVm);
            });
        });

        // Interface for Administration Pages API
        this.addCustomRow = () => {
            const newCustomPropertyName = this.CustomPropertyName();

            if (this.isNullOrUndefined(newCustomPropertyName)) {
                const errorMessage = getLabelTranslation("#Core/notifyCustomPropertyNameCannotBeEmpty");
                error(errorMessage);
                return;
            }
            if (!newCustomPropertyNameRegExp.test(newCustomPropertyName)) {
                const errorMessage = getLabelTranslation("#Core/notifyCustomPropertyInvalidName");
                error(errorMessage);
                return;
            }
            if (this.isPropertyInCollection(this.CustomPropertyRows(), newCustomPropertyName)) {
                const errorMessage = getFormattedLabelTranslation("#Core/notifyCustomPropertyAlreadyAdded_propertyName", [newCustomPropertyName]);
                error(errorMessage);
                return;
            }
            if (this.isPropertyInCollection(this.PropertyRows(), newCustomPropertyName)) {
                const errorMessage = getFormattedLabelTranslation(
                    "#Core/notifyMandatoryPropertyAlreadyAdded_propertyName", [newCustomPropertyName]);
                error(errorMessage);
                return;
            }

            const customDataAccessSchemaPropertyVm = createNewDataAccessSchemaProperty(newCustomPropertyName, true);
            entityWithContext.Entity.PropertiesAccessSchema.push(customDataAccessSchemaPropertyVm);
        };

        this.dispose = () => {
            this.AccessSchemas(null);
            this.ErrorMessage(null);
            this.CustomPropertyName(null);
            this.DataTypeHandler.dispose();
            this.PropertyRows.dispose();
            this.CustomPropertyRows.dispose();
            if (!(_.isNull(context) || _.isUndefined(context))) {
                context.dispose();
            }
        };

        this.removeCustomRow = (customRow) => {
            entityWithContext.Entity.PropertiesAccessSchema.remove(customRow);
        };
    }

    isNullOrUndefined(object: any) {
        return _.isNull(object) || _.isUndefined(object);
    }

    filterByEntityType(collection: any, entityType: string) {
        const filteredCollection: any[] = [];

        _(collection).each((element) => {
            const elementType = unwrap(element.$type);
            if (elementType.indexOf(entityType) === 0) {
                filteredCollection.push(element);
            }
        });

        return filteredCollection;
    }

    isPropertyInCollection(dataAccessSchemaPropertyCollection: any, propertyName: any) {
        const existingDataAccessSchemaProperty = _(dataAccessSchemaPropertyCollection).find((property) => {
            return property.PropertyName() === propertyName;
        });
        return !this.isNullOrUndefined(existingDataAccessSchemaProperty);
    }
}

export default function create(entityWithContext: any) {
    return new DataAccessSchemaPropertiesTab(entityWithContext);
}
