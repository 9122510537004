/// <amd-module name = "Core/Medius.Core.Web/Scripts/Medius/core/settingsProvider"/>
import * as rest from "Core/Medius.Core.Web/Scripts/Medius/core/rest";
import * as _ from "underscore";
    let currencies: any[],
        units: any[],
        defaultUnit: any,
        resolutions: { [x: string]: any; };
    const defaultResolution = 2,
        amountTypeName = "Medius.Core.Entities.Amount",
        quantityTypeName = "Medius.Core.Entities.Quantity",
        unitPriceTypeName = "Medius.Core.Entities.UnitPrice",
        currencyRateTypeName = "Medius.Core.Entities.CurrencyRate";

function fetchData() {
    return rest.get("GlobalSettingsService", "get", { async: false }).done((response:any) => {
        currencies = response.Currencies;
        units = response.Units;
        defaultUnit = response.DefaultUnit;
        resolutions = response.Resolutions;
    }).fail(function() {
        throw new Error("Cannot load settings.");
    });
}

function getDisplayResolution(identfier:any, typeName:any) {
    const mapping = resolutions[typeName],
        resolution = mapping && mapping[identfier];

    if (_.isUndefined(resolution)) {
        return defaultResolution;
    }
    return resolution;
}

export function dispose() {
    currencies = [];
    units = [];
    resolutions = {};
}

export function init() {
    fetchData();
}

export function getAvaliableCurrencies() {
    return currencies;
}

export function getAvaliableUnits() {
    return units;
}

export function getDefaultUnit() {
    return defaultUnit;
}

export function getAmountDisplayResolution(currencyCode: any) {
    return getDisplayResolution(currencyCode, amountTypeName);
}

export function getUnitPriceDisplayResolution(currencyCode: any) {
    return getDisplayResolution(currencyCode, unitPriceTypeName);
}

export function getCurrencyRateDisplayResolution(currencyCode: any) {
    return getDisplayResolution(currencyCode, currencyRateTypeName);
}

export function getQuantityDisplayResolution(unitDescription: any) {
    return getDisplayResolution(unitDescription, quantityTypeName);
}

export function getCurrency(currencyCode: any) {
    const currency = _(getAvaliableCurrencies()).find(function(currencyObject: { Code: any; }) {
        return currencyObject.Code === currencyCode;
    });
    return currency;
}