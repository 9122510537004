/// <amd-module name="Core/Medius.Core.Web/Scripts/loginPage/LoggedOff"/>
import * as React from "react";
import Card from "Core/Medius.Core.Web/Scripts/components/card/Card";
import {translate} from "Core/Medius.Core.Web/Scripts/lib/globalization";

export default function LoggedOffPage({ model }: ILoggedOffPageProps) {
    const { LogInAgainUrl, State, FrontChannelLogoutUris } = model;

    return (
        <Card>
            <div className="card-content">
                <div id="logged-out-info" className="card-message">
                    {translate("#Core/goodByeMessage")}
                </div>
                        
                <form action={LogInAgainUrl}>
                    {State && (<input type="hidden" name="state" value={State}></input>)}
                    <button className="card-button" type="submit">{translate("#Core/logInAgain")}</button>
                </form>

                {
                    FrontChannelLogoutUris.map((uri: string) => (
                        <iframe key={uri} src={uri} style={{visibility: "hidden", display: "none"}}></iframe>)
                    )
                }
            </div>
        </Card>
    );
}

interface ILoggedOffPageProps {
    model: {
        LogInAgainUrl: string;
        FrontChannelLogoutUris: string[];
        State: string;
    };
}
