/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/kendo/bindings/kendoTimePicker" />

import { KendoUtils } from "Core/Medius.Core.Web/Scripts/Medius/kendo/kendoUtils";
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";

interface IKendoTimePickerOptions {

    value: ko.Observable<Date>;
    validator: ko.Observable<KendoValidator>;
}

class KendoTimePicker {
    private static readonly widgetName = "kendoTimePicker";

    public static registerKnockoutBinding(): void {
        koUtils.registerBinding(
            KendoTimePicker.widgetName, {
                init(element: Element, valueAccessor: any) {
                    const $element = $(element);
                    
                    KendoUtils.ensureValidElement(KendoTimePicker.widgetName, "input", $element);
                    KendoTimePicker.create($element, valueAccessor());
                    KendoUtils.registerDisposeFor($element, KendoTimePicker.widgetName);
                },
                update(element: Element, valueAccessor: any) {
                    const options = valueAccessor() as IKendoTimePickerOptions;
                    const $element = $(element);
                    const selectedValue = options.value();
                    const validator = options.validator();

                    KendoTimePicker.validate($element, selectedValue, validator);
                    $element.data(KendoTimePicker.widgetName)
                        .value(selectedValue);
                }
            });
    }

    private static create($element: JQuery, params: IKendoTimePickerOptions) {
        if (params.validator)
            params.validator({ validate: () => KendoTimePicker.validate($element, params.value(), params.validator()) });

        const options: kendo.ui.TimePickerOptions = {
            change: (e: kendo.ui.TimePickerChangeEvent) => {
                const selectedValue = e.sender.value();
                KendoTimePicker.validate($element, selectedValue, params.validator());
                params.value(selectedValue);
            },
            interval: 60
        };

        KendoUtils.dispose($element, KendoTimePicker.widgetName);
        $element.kendoTimePicker(options);
    }

    private static validate(element: JQuery, value: Date, validator: KendoValidator): boolean {
        if (!validator)
            return true;

        const timePicker = KendoTimePicker.getTimePickerElement(element);
        if (value) {
            timePicker.removeClass("invalid");
            return true;
        }
        else {
            timePicker.addClass("invalid");
            return false;
        }
    }

    private static getTimePickerElement(element: JQuery): JQuery {
        const timePicker = element.parent();
        if (timePicker.length === 0)
            throw new Error("Could not find Kendo time picker.");
        return timePicker;
    }
}

export function register() {
    KendoTimePicker.registerKnockoutBinding();
}
