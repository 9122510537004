/// <amd-module name="Core/Medius.Core.Web/Scripts/components/slidingModal/useClosableElement"/>
import * as React from "react";
import { useEffect, useCallback } from "react";

export const useClosableElement = ({
    contentRef,
    onClose,
    show = true,
}: {
    contentRef: React.RefObject<HTMLElement>;
    onClose: (event?: MouseEvent | KeyboardEvent) => void;
    show?: boolean;
}) => {
    const handleOutsideClick = useCallback(
        (e: MouseEvent) => {
            if (!contentRef?.current || !show) {
                return;
            }

            if (contentRef.current.contains(e.target as Node | null)) {
                return;
            }

            onClose(e);
        },
        [contentRef, onClose, show]
    );

    const handleEscapeKeydown = useCallback(
        (e: KeyboardEvent) => {
            if (!show) {
                return;
            }

            if (e.key === 'Escape') {
                onClose(e);
            }
        },
        [onClose, show]
    );

    useEffect(() => {
        window.addEventListener('mousedown', handleOutsideClick);
        window.addEventListener('keydown', handleEscapeKeydown);

        return () => {
            window.removeEventListener('mousedown', handleOutsideClick);
            window.removeEventListener('keydown', handleEscapeKeydown);
        };
    }, [handleEscapeKeydown, handleOutsideClick]);
};