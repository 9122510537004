/// <amd-module name="Core/Medius.Core.Web/Scripts/components/invoice/reasonCodes/viewModel"/>
import { ReactBindingParams } from "Core/Medius.Core.Web/Scripts/lib/reactIntegration/reactBindingParams";
import { ReasonCode } from "./types";
import { ReasonCodesSection, ReasonCodesSectionProps } from "./components/section";
import * as reasonCodesService from "./service";
import * as ko from "knockout";
type Entity = { readonly Id: ko.Observable<number> };
type DocumentCompany = ko.Observable<Entity | null>;

export class ReasonCodes {
    private readonly availableReasonCodes: ko.Observable<ReasonCode[]>;
    private readonly companySubscription: ko.Subscription;

    public readonly sectionBindingParams = ko.observable<ReactBindingParams<ReasonCodesSectionProps>>();

    constructor(documentViewId: ko.Observable<string>, company: DocumentCompany) {
        const viewId = documentViewId();
        const companyId = company()?.Id();

        this.availableReasonCodes = ko.observable([]);
        this.companySubscription = company.subscribe(
            v => this.onCompanyChanged(v));

        this.fetchAvailableReasonCodes(viewId, companyId);
    }

    public visible() {
        return this.availableReasonCodes().length > 0;
    }

    public dispose() {
        this.companySubscription.dispose();
    }

    private onCompanyChanged(newCompany: Entity) {
        this.availableReasonCodes([]);

        const documentCompanyId = newCompany?.Id();
        const { documentViewId } =
            this.sectionBindingParams().props;

        this.fetchAvailableReasonCodes(documentViewId, documentCompanyId);
    }

    private fetchAvailableReasonCodes(viewId: string, companyId: number){
        if (companyId > 0)
            reasonCodesService.getAvailable(companyId)
                .then(available => {
                    this.availableReasonCodes(available);
                    this.sectionBindingParams({
                        functionComponent: ReasonCodesSection,
                        props: {
                            documentViewId: viewId,
                            documentCompanyId: companyId,
                            availableReasonCodes: available
                        }
                    });
                });
        else
            this.sectionBindingParams({
                functionComponent: ReasonCodesSection,
                props: {
                    documentViewId: viewId,
                    documentCompanyId: null,
                    availableReasonCodes: []
                }
            });
    }
}
