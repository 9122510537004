///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Schedulers.IntegrationMessageCleanupJobContext/Editor"/>
import mappings = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/mapping/collection/instance");
import { viewModelFactory as base } from "Core/Medius.Core.Web/Scripts/Medius/apps/administration/models/Medius.Core.Schedulers.CleanupJobContext/Editor";
import * as sync from "Core/Medius.Core.Web/Scripts/Medius/core/sync";
import * as ko from "knockout";
import * as _ from "underscore";
import { isNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";

export function viewModelFactory(viewModel:any) {
    base(viewModel);

    viewModel.Template = 'Core:templates/Administration/IntegrationMessageCleanupJobContext/EditorTemplate.html';

    viewModel.FinalCleanupSpanDays = ko.computed({
        read: () => {
            if (isNullOrUndefined(viewModel.FinalCleanupSpan())) {
                return undefined;
            }

            return viewModel.FinalCleanupSpan().split(".")[0];
        },
        write: (value) => {
            viewModel.FinalCleanupSpan(value + ".00:00:00");
        },
        owner: viewModel
    });

    viewModel.NotCorrelatedInboundMessageMaxAgeInDays = ko.computed({
        read: () => {
            if (isNullOrUndefined(viewModel.NotCorrelatedInboundMessageMaxAge())) {
                return "";
            }

            return viewModel.NotCorrelatedInboundMessageMaxAge().split(".")[0];
        },
        write: (value) => {
            viewModel.NotCorrelatedInboundMessageMaxAge(value + ".00:00:00");
        },
        owner: viewModel
    });

    viewModel.ExcludedTagsList = ko.computed({
        read: () => {
            if (viewModel.ExcludedMessageTags())
                return viewModel.ExcludedMessageTags().split(',');
            return [];
        },
        write: (value) => {
            viewModel.ExcludedMessageTags(value.join(','));
        },
        owner: viewModel
    });

    viewModel.SelectedTag = ko.observable();

    viewModel.AllTags = ko.observable();

    viewModel.AvailableTags = ko.computed(() => {
        return _.difference(viewModel.AllTags(), viewModel.ExcludedTagsList());
    });

    viewModel.NoTagSelected = ko.computed(() => {
        return !viewModel.SelectedTag();
    });

    viewModel.addTag = () => {
        viewModel.ExcludedTagsList(viewModel.ExcludedTagsList().concat(viewModel.SelectedTag()));
    };

    viewModel.removeTag = (tag:any) => {
        viewModel.ExcludedTagsList(_.without(viewModel.ExcludedTagsList(), tag));
    };

    viewModel.loadAvailableTags = () => {
        sync.load("Medius.Core.Entities.Integration.IntegrationMessageSettings").done((data) => {
            const tags = _.uniq(_.map(data, (setting) => { return setting.MessageTag; }));
            viewModel.AllTags(tags);
        });
    };

    viewModel.dispose = () => {
        viewModel.FinalCleanupSpanDays.dispose();
        viewModel.FinalCleanupSpanDays = null;
        viewModel.ExcludedTagsList.dispose();
        viewModel.ExcludedTagsList = null;
        viewModel.AvailableTags.dispose();
        viewModel.AvailableTags = null;
        viewModel.NoTagSelected.dispose();
        viewModel.NoTagSelected = null;
    };

    viewModel.loadAvailableTags();
}

export function register() {
    mappings.register("Medius.Core.Schedulers.IntegrationMessageCleanupJobContext", _.constant(true), viewModelFactory);
}