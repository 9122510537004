///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/role/defaults"/>
import * as baseDefaultsFactory from "Core/Medius.Core.Web/Scripts/Medius/components/editors/autocompleter/default/defaults";

export function create() {
    const baseDefaults = baseDefaultsFactory.create();

    return {
        ...baseDefaults,
        dataProviderOptions: {
            onlyPersonal: false,
            withPersonal: false,
            preselectFirstValue: true,
            onlyActive: true,
            onlyDistributionAllowed: false
        }
    };
}