/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/helpers/propertyType"/>

import { isEmptyString } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import * as typeResolver from "Core/Medius.Core.Web/Scripts/Medius/components/resolver/type";
import * as _ from "underscore";

export function getPropertyTypeForHints(type: string, property: string) {
    const propertyType = getPropertyType(type, property);
    
    if (typeResolver.isCollection(propertyType)) {
        return propertyType
            .replace("System.Collections.Generic.IList`1[", "")
            .replace("System.Collections.Generic.IEnumerable`1[", "")
            .replace("]", "");
    }

    return propertyType;
}

export function getPropertyType(type: string, property: string | string[]): any {
    if (isEmptyString(property) || isEmptyString(type)) {
        return null;
    }
   
    const path = (_.isArray(property)) ? property : property.split(".");
    let propertyType = typeResolver.getPropertyType(type, path[0]);
    
    if (!propertyType) {
        return null;
    }
    
    if (path.length > 1) {
        
        if (typeResolver.isCollection(propertyType)) {
            propertyType = typeResolver.getCollectionPropertyElementType(type, path[0]);
        }

        return getPropertyType(propertyType, _.rest(path, 1));
    }

    return propertyType;
}