///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/transformers/update/object"/>
import * as mappingResolver from "Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/mapping/resolver";
import * as utils from "Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/transformers/update/utils";
import * as ko from "knockout";

export = function objectUpdater(sourceData:any, targetAccessor:any, context:any) {
    let unwrappedTarget = ko.utils.unwrapObservable(targetAccessor);
    let mapping: any;
    let key:any;

    function bottomUp() {
        if (ko.isWriteableObservable(targetAccessor)) {
            const currentValue = ko.utils.unwrapObservable(targetAccessor);
            if (currentValue !== unwrappedTarget) {
                targetAccessor(unwrappedTarget);
            }
        }
    }

    if (!unwrappedTarget) {
        mapping = mappingResolver.resolve(sourceData, context);
        if (mapping && mapping.key) {
            key = mapping.key(sourceData, context);
            if (key) {
                unwrappedTarget = context.cache.get(key);
            }
        }
    }

    let metadata = utils.getMetadata(unwrappedTarget);
    mapping = metadata.mapping;

    if (mapping && mapping.shouldRebuildViewmodel(unwrappedTarget, sourceData, context)) {
        unwrappedTarget = null;
    }

    if (!unwrappedTarget) {
        // last chance, rebuild the object
        unwrappedTarget = context.create(sourceData);
    }

    metadata = utils.getMetadata(unwrappedTarget);
    mapping = metadata.mapping;

    if (mapping && mapping.update) {
        const runDefaultUpdate = mapping.update(unwrappedTarget, sourceData, context);
        if (!runDefaultUpdate) {
            return {
                topDown: function (args:any) {
                    args.setTargetData(unwrappedTarget);
                    args.stopDescend();
                },
                bottomUp: bottomUp
            };
        }
    }

    return {
        topDown: function (args:any) {
            args.setTargetData(unwrappedTarget);
        },
        beforeDescend: function (args:any) {
            if (args.propertyName === "__metadata__" || !utils.hasProperty(unwrappedTarget, args.propertyName)) {
                args.stopPropertyDescend();
            }
        },
        bottomUp: bottomUp
    };
};