/// <amd-module name="Core/Medius.Core.Web/Scripts/AdminPages/User/General/general"/>
import * as ko from "knockout";

import {IEntityWithContext} from "Core/Medius.Core.Web/Scripts/AdminPages/IEntityWithContext";
import * as userEditorHelpers from "Core/Medius.Core.Web/Scripts/Medius/lib/utils/userEditorHelpers";
import { Provider } from "Core/Medius.Core.Web/Scripts/Medius/lib/utils/DefaultCompanyDataProvider";

class User {
    public readonly TimeZonesList: ko.ObservableArray<any>;
    public readonly FormatsList: ko.ObservableArray<any>;
    public readonly LanguagesList: ko.ObservableArray<any>;
    public readonly Entity: any;
    public readonly DefaultCompanyDataProvider: any;
    public readonly userLanguage: string;
    public readonly userFormat: string;
    public readonly userTimeZone: string;
    public readonly isEntityNew: ko.Computed<boolean>;

    constructor(entityWithContext: IEntityWithContext) {
        const entityModel = entityWithContext.Entity;

        this.TimeZonesList = ko.observableArray([]);
        this.FormatsList = ko.observableArray([]);
        this.LanguagesList = ko.observableArray([]);

        this.Entity = entityModel;
        this.DefaultCompanyDataProvider = new Provider();

        //we need to store this values because when autocompleters are created
        //and there is no yet available languages list populated
        //first element on list is set, which changes state of entity
        this.userLanguage = this.Entity.Language();
        this.userFormat = this.Entity.Format();
        this.userTimeZone = this.Entity.TimeZone();

        this.isEntityNew = ko.pureComputed(() => this.Entity.Id() === 0);

        this.load();
    }

    private load() {
        userEditorHelpers.loadAvailableLanguages()
            .then((languages: any) => {
                this.LanguagesList(languages);
                this.Entity.Language(this.userLanguage);
            });

        userEditorHelpers.loadAvailableFormats()
            .then((formats: any) => {
                this.FormatsList(formats);
                this.Entity.Format(this.userFormat);
            });

        userEditorHelpers.loadAvailableTimeZones()
            .then((timeZones: any) => {
                this.TimeZonesList(timeZones);
                this.Entity.TimeZone(this.userTimeZone);
            });

        if (!this.isEntityNew()) {
            this.DefaultCompanyDataProvider.getResults(this.Entity.Id);
        }
    }
}

export default function create(entityWithContext: IEntityWithContext, companyContextId: number, tabModel: any) {
    return new User(entityWithContext);
}
