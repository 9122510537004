/// <amd-module name="Core/Medius.Core.Web/Scripts/lib/fn"/>

import * as _ from "underscore";

export const not = (fn: any) => {
    return (...predicates: any) => {
        return !fn.apply(this, predicates);
    };
};

export const any = (...predicates: any) => {
    return function(...args: any[]) {
        return _(predicates)
            .chain()
            .map(function (predicate: any) { return predicate.apply(null, args); })
            .any()
            .value();
    };
};