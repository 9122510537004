///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/grid/bindings/dataGrid"/>
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as grid from "Core/Medius.Core.Web/Scripts/Medius/components/grid/default/grid";
import * as ko from "knockout";

const gridElementKey = "medius-grid";

function parseBinding(bindingAccessor:any) {
    const binding = bindingAccessor();

    return {
        providedGrid: koUtils.unpack(binding),
        dataSource: koUtils.unpack(binding.dataSource) || null,
        options: koUtils.unpack(binding.options) || {}
    };
}

const dataGrid = {
    init: function (element:any, bindingAccessor:any, allBindingsAccessor:any, viewModel:any, bindingContext:any) {
        const binding = parseBinding(bindingAccessor);
        const dataSource = binding.dataSource;
        const providedGrid = binding.providedGrid;
        const options = binding.options;
        const current = koUtils.domData.get(element, gridElementKey) || {};

        if (current.grid) {
            current.disposal();
            ko.utils.domNodeDisposal.removeDisposeCallback(current.disposal, undefined);
        }

        let instance = (dataSource) ? grid.create(dataSource, options) : providedGrid;
        let newContext = bindingContext.createChildContext(instance);
        newContext.$grid = instance;
        newContext.$viewModel = viewModel;
        
        instance.preload().done(function () {
            ko.renderTemplate(instance.options.template, newContext, {}, element, "replaceChildren");
        });
       
        let disposal = function () {
            koUtils.domData.set(element, gridElementKey, null);
            instance.destroy();
            instance = null;
            bindingContext.$root.draggedGrid = null;
            newContext.$grid = null;
            newContext.$viewModel = null;
            newContext = null;
            disposal = null;
        };
        ko.utils.domNodeDisposal.addDisposeCallback(element, disposal);

        koUtils.domData.set(element, gridElementKey, {
            grid: instance,
            disposal: disposal
        });

        return { controlsDescendantBindings: true };
    }
};

export function register() {
    koUtils.registerBinding("dataGrid", dataGrid);
}