///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/widgets/SupplierWidget"/>
import * as React from "react";
import { ISupplierWidgetDto } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/agentInterfaces";
import { 
    IconActionHomeFill, 
    IconCommunicationChatsFill, 
    IconTimeClockClockwiseRegular, 
    IconCommerceHandCoinsFill, 
    Text, 
    FlexContent, 
    IconCommercePiggyBankFill, 
    IconUserUserFill 
} from "@medius/ui-controls";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as user from "Core/Medius.Core.Web/Scripts/Medius/lib/utils/user";
import { useInternationalization } from "@progress/kendo-react-intl";
import { getUserTimeProvider } from "Core/Medius.Core.Web/Scripts/lib/date/date";

export const SupplierWidget = ({supplierWidgetDto}: {supplierWidgetDto: ISupplierWidgetDto}) => {
    const timeProvider = getUserTimeProvider();
    const internationalization = useInternationalization();

    const getLastMonthString = () => {
        const lastMonthDate = new Date();
        lastMonthDate.setDate(0);
        const dateString = lastMonthDate.toLocaleString(user.getLanguageCode(), { month: 'short' });
        return dateString.charAt(0).toUpperCase() + dateString.substring(1);
    };
    
    const getCurrentMonthString = () => {
        const lastMonthDate = new Date();
        lastMonthDate.setDate(1);
        const dateString = lastMonthDate.toLocaleString(user.getLanguageCode(), { month: 'short' });
        return dateString.charAt(0).toUpperCase() + dateString.substring(1);
    };

    return <div data-testid="apa-copilot-supplier-widget">
        <Text variant="paragraph">{getLabelTranslation("#PurchaseToPay/apaAgentSupplierWidgetText")}</Text>
        <div className="mediusAgent__widgetCard">
            <Text variant="small-impact">{supplierWidgetDto.name}</Text>
        </div>
        {supplierWidgetDto.address &&
        <div className="mediusAgent__widgetCard" data-testid="apa-copilot-supplieraddress">
            <div className="mediusAgent__widgetCard__iconAndContentContainer">
                <div className="mediusAgent__widgetCard__iconAndContentContainer__icon"><IconActionHomeFill color="brand" /></div>
                <FlexContent>
                    <Text variant="heading3">{getLabelTranslation("#Core/address")}</Text>
                    <Text spacerMode="off" variant="body">{supplierWidgetDto.address}</Text>
                </FlexContent>
            </div>
        </div>
        }
        {(supplierWidgetDto.contact.email || supplierWidgetDto.contact.fax || supplierWidgetDto.contact.homepage || supplierWidgetDto.contact.telephone) && 
        <div className="mediusAgent__widgetCard" data-testid="apa-copilot-contactinformation">
            <div className="mediusAgent__widgetCard__iconAndContentContainer">
                <div className="mediusAgent__widgetCard__iconAndContentContainer__icon"><IconCommunicationChatsFill color="brand"/></div>
                <FlexContent>
                    <Text variant="heading3">{getLabelTranslation("#PurchaseToPay/contactInformation")}</Text>
                    {supplierWidgetDto.contact.telephone && <Text spacerMode="off" variant="body">{supplierWidgetDto.contact.telephone}</Text>}
                    {supplierWidgetDto.contact.email && <Text spacerMode="off" variant="body">{supplierWidgetDto.contact.email}</Text>}
                </FlexContent>
            </div>
        </div>
        }
        <div className="mediusAgent__widgetCard">
            <div className="mediusAgent__widgetCard__iconAndContentContainer">
                <div className="mediusAgent__widgetCard__iconAndContentContainer__icon"><IconCommerceHandCoinsFill color="brand"/></div>
                <FlexContent>
                    <Text variant="heading3">{getLabelTranslation("#Enterprise/paymentTerm")}</Text>
                    <Text spacerMode="off" variant="body">
                        <div dangerouslySetInnerHTML={{__html: getLabelTranslation("#PurchaseToPay/apaAgentSupplierWidgetPaymentTermCardText", `<b>${supplierWidgetDto.paymentTerm}</b>`, `<b>${internationalization.formatDate(timeProvider.toUserTimeZoneFromBrowserDate(new Date(supplierWidgetDto.dueDate)), "d")}</b>`)}}/>
                    </Text>
                </FlexContent>
            </div>
        </div>
        {(supplierWidgetDto.cashDiscount1.discountPercent || supplierWidgetDto.cashDiscount2.discountPercent) &&
        <div className="mediusAgent__widgetCard" data-testid="apa-copilot-discountPercent">
            <div className="mediusAgent__widgetCard__iconAndContentContainer">
                <div className="mediusAgent__widgetCard__iconAndContentContainer__icon"><IconCommercePiggyBankFill color="brand"/></div>
                <FlexContent>
                    <Text variant="heading3">{getLabelTranslation("#Core/indicatorHasCashDiscountOpportunity")}</Text>
                    {supplierWidgetDto.cashDiscount1.discountPercent && supplierWidgetDto.cashDiscount1.date &&
                        <Text spacerMode="off" variant="body">
                            <div dangerouslySetInnerHTML={{__html: getLabelTranslation("#PurchaseToPay/apaAgentSupplierWidgetCashDiscountOpportunityCardText", `<b>${supplierWidgetDto.cashDiscount1.discountPercent}</b>`, `<b>${internationalization.formatDate(timeProvider.toUserTimeZoneFromBrowserDate(new Date(supplierWidgetDto.cashDiscount1.date)), "d")}</b>`)}}/>
                        </Text>
                    }
                    {supplierWidgetDto.cashDiscount2.discountPercent && supplierWidgetDto.cashDiscount2.date &&
                        <Text spacerMode="off" variant="body">
                            <div dangerouslySetInnerHTML={{__html: getLabelTranslation("#PurchaseToPay/apaAgentSupplierWidgetCashDiscountOpportunityCardText", `<b>${supplierWidgetDto.cashDiscount2.discountPercent}</b>`, `<b>${internationalization.formatDate(timeProvider.toUserTimeZoneFromBrowserDate(new Date(supplierWidgetDto.cashDiscount2.date)), "d")}</b>`)}}/>
                        </Text>
                    }
                </FlexContent>
            </div>
        </div>
        }
        {supplierWidgetDto.responsibleName &&
        <div className="mediusAgent__widgetCard" data-testid="apa-copilot-responsiblename">
            <div className="mediusAgent__widgetCard__iconAndContentContainer">
                <div className="mediusAgent__widgetCard__iconAndContentContainer__icon"><IconUserUserFill color="brand"/></div>
                <FlexContent>
                    <Text variant="heading3">{getLabelTranslation("#Enterprise/supplierResponsible")}</Text>
                    <Text spacerMode="off" variant="body">{supplierWidgetDto.responsibleName}</Text>
                </FlexContent>
            </div>
        </div>
        }
        <div className="mediusAgent__widgetCard">
            <div className="mediusAgent__widgetCard__iconAndContentContainer">
                <div className="mediusAgent__widgetCard__iconAndContentContainer__icon"><IconTimeClockClockwiseRegular color="brand"/></div>
                <FlexContent>
                    <Text variant="heading3">{getLabelTranslation("#PurchaseToPay/recentlyProcessedDocuments")}</Text>
                    <table className="mediusAgent__processedInvoicesCountTable">
                        <thead>
                            <tr>
                                <th>{getLabelTranslation("#Core/documentType")}</th>
                                <th>{getLastMonthString()}</th>
                                <th>{getCurrentMonthString()}</th>
                                <th>{getLabelTranslation("#PurchaseToPay/average12MonthsShort")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{getLabelTranslation("#ExpenseInvoice/expenseInvoice")}</td>
                                <td>{supplierWidgetDto.nonPoInvoicesProcessedLastMonthCount}</td>
                                <td>{supplierWidgetDto.nonPoInvoicesProcessedThisMonthCount}</td>
                                <td>{supplierWidgetDto.nonPoInvoicesProcessedIn12MonthsCountAverage}</td>
                            </tr>
                            <tr>
                                <td>{getLabelTranslation("#OrderbasedInvoice/orderbasedInvoice")}</td>
                                <td>{supplierWidgetDto.poInvoicesProcessedLastMonthCount}</td>
                                <td>{supplierWidgetDto.poInvoicesProcessedThisMonthCount}</td>
                                <td>{supplierWidgetDto.poInvoicesProcessedIn12MonthsCountAverage}</td>
                            </tr>
                        </tbody>
                    </table>
                </FlexContent>
            </div>
        </div>
    </div>;
};