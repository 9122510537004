/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/inbox/bulk/bulkModalFactory"/>
import { IBulkModalContext } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/bulk/iBulkModalContext";

export class BulkModalFactory {
    private wizardCreators: BulkModalCreator[] = [];
    private defaultWizardCreator: BulkModalCreator;
    private static instance: BulkModalFactory;

    protected constructor() { }

    public static getInstance() {
        if (this.instance == null) { this.instance = new BulkModalFactory(); }
        return this.instance;
    }

    public register(constructor: { new(tasks: any, options: any, selectedDocumentsWithRiskFactorsCount: number): any }, condition: (context: IBulkModalContext) => boolean) {
        if (!condition && !this.defaultWizardCreator) {
            this.defaultWizardCreator = new BulkModalCreator(constructor, null);
            return;
        }
        this.wizardCreators.push(new BulkModalCreator(constructor, condition));
    }

    public registerDefault(constructor: { new(tasks: any, options: any): any }) {
        this.register(constructor, null);
    }

    public create(context: IBulkModalContext) {
        const availableWizardCreators: BulkModalCreator[] = this.wizardCreators
            .filter(wizardCreator => wizardCreator.satisfies(context));

        if (availableWizardCreators.length > 1) {
            throw new Error("There are multiple wizards found!");
        }

        if (availableWizardCreators.length === 0 && !this.defaultWizardCreator) {
            throw new Error("Cannot find wizard satisfying condition with given context.");
        }

        const wizardCreator = availableWizardCreators.length ? availableWizardCreators[0] : this.defaultWizardCreator;

        return wizardCreator.create(context);
    }
}

class BulkModalCreator {
    private ctor: { new(tasks: any, options: any, selectedDocumentsWithRiskFactorsCount: number): any };
    private condition: (context: IBulkModalContext) => boolean;

    public constructor(ctor: { new(task: any, options: any, selectedDocumentsWithRiskFactorsCount: number): any }, condition: (context: IBulkModalContext) => boolean) {
        this.ctor = ctor;
        this.condition = condition;
    }

    public create(context: IBulkModalContext) {
        return new this.ctor(context.tasks, context.options, context.options?.selectedDocumentsWithRiskFactorsCount);
    }

    public satisfies(context: IBulkModalContext) {
        return this.condition(context);
    }
}
