///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/provider/hints"/>

import * as $ from "jquery";
import * as _ from "underscore";
import { isNullOrUndefined, isEmptyString } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import * as propertyHelper from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/helpers/property";
import * as propertyTypeHelper from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/helpers/propertyType";
import * as lexemeHelper from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/lexeme/helpers";
import * as translationsProvider from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/provider/translations";
import * as timeOperatorsProvider from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/provider/timeOperators";
import * as rpc from "Core/Medius.Core.Web/Scripts/Medius/core/rpc";
import * as rest from "Core/Medius.Core.Web/Scripts/Medius/core/rest";
import * as html from "Core/Medius.Core.Web/Scripts/Medius/core/html";
import * as serialization from "Core/Medius.Core.Web/Scripts/Medius/lib/serialization";
import * as operatorsProvider from "Core/Medius.Core.Web/Scripts/Medius/components/documentSearch/query/provider/operators";

const debounceWaitMilliseconds = 500;
const recentBackendDebounced =
{
    promise: null as JQueryDeferred<any>,
    aborter: null as AbortController,
    trigger: null as number
};

function cancelRecentBackendRequest() {
    const reason = "debounced";

    clearTimeout(recentBackendDebounced.trigger);
    recentBackendDebounced.aborter?.abort(reason);

    const promise = recentBackendDebounced.promise;
    if (promise?.state() === "pending")
        promise.reject(reason);

    recentBackendDebounced.trigger = null;
    recentBackendDebounced.aborter = null;
    recentBackendDebounced.promise = null;
}

function backendDebounced<TData, TResult>(
    action: (requestData: TData, signal: AbortSignal)
        => JQueryPromise<TResult>) {

    return function (requestData: TData) {
        const promise = $.Deferred<TData>();
        const aborter = new AbortController();
        const trigger = setTimeout(() => {
            promise.resolve(requestData);
        }, debounceWaitMilliseconds) as any;

        recentBackendDebounced.promise = promise;
        recentBackendDebounced.aborter = aborter;
        recentBackendDebounced.trigger = trigger;

        return promise.then(data =>
            action(data, aborter.signal));
    };
}

function getFieldHint(field: any, collision = false) {
    if (isNullOrUndefined(field.translation))
        return $('<span>', { 'class': 'styled-hint', 'text': field.property })[0].outerHTML;

    if (collision)
        return field.translation + " " + $('<span>', { 'class': 'styled-hint', 'text': field.property })[0].outerHTML;

    return field.translation;
}

function filterOut(word: string, fields: any) {
    word = word.toLowerCase();

    return _.filter(fields, function (field) {
        let translation = field.translation;
        let property = field.property;
        let translationIncludesWord = false;
        let propertyIncludesWord = false;

        if (translation) {
            translation = translation.toLowerCase();
            translationIncludesWord = translation.includes(word);
        }

        if (property) {
            property = property.toLowerCase();
            propertyIncludesWord = property.includes(word);
        }

        return translationIncludesWord || propertyIncludesWord;
    });
}

function filterUnique(word: string, suggestions: any) {
    if (!suggestions)
        return [];

    let t = _.chain(suggestions)
        .map(item => html.sanitize(item));

    if (!isEmptyString(word)) {
        word = word || "";
        word = word.trim().toLowerCase();

        t = t.filter(function (item) {
            const itemLower = (item.toLowerCase) ? item.toLowerCase() : item;
            return _.isString(item) && itemLower.indexOf(word) !== -1;
        });
    }

    return t.unique().value();
}

function getTranslatedProperties(type: string) {
    return translationsProvider.getTranslatedProperties(type, true);
}

export function getHints(type: any, lexeme: string) {
    let fields = this.getFields(type, lexeme);
    fields = this.prepareFieldHints(fields);
    const operators = this.getOperators(type, lexeme);

    return [].concat(operators, fields);
}

export function getOperators(type: any, lexeme: string) {
    const operators = operatorsProvider.getUnaryOperators(type);
    operators.push(operatorsProvider.getLogicalNegationOperator());

    if (isEmptyString(lexeme))
        return operators;

    return _(operators)
        .filter(op => op.includes(lexeme));
}

export function getOrderByHints(type: any, lexeme: string) {
    let fields = this.getFields(type, lexeme);
    const isNested = lexemeHelper.endsWithDot(lexeme);
    lexeme = lexemeHelper.removeEndingDot(lexeme);

    fields = _.filter(fields, function (field) {
        const path = (isNested)
            ? [lexeme, field.property].join(".")
            : field.property;

        return propertyHelper.isCorrectOrderingProperty(type, path);
    });

    const hints = this.prepareFieldHints(fields);

    return hints;
}

export function getFields(type: string, lexeme: string) {
    let property: string, propertyType: string;
    if (isNullOrUndefined(lexeme))
        return getTranslatedProperties(type);

    // Sender.
    if (lexemeHelper.endsWithDot(lexeme)) {
        lexeme = lexemeHelper.removeEndingDot(lexeme);
        property = translationsProvider.resolvePropertyFromTranslation(type, lexeme);
        propertyType = propertyTypeHelper.getPropertyTypeForHints(type, property);
        return getTranslatedProperties(propertyType);
    }

    // Sender.'First Name'
    property = translationsProvider.resolvePropertyFromTranslation(type, lexeme);
    propertyType = propertyTypeHelper.getPropertyTypeForHints(type, property);

    if (propertyType)
        return [];

    // Sender.Con
    const parts = lexeme.split(".");
    let fields: any;
    if (parts.length > 1) {
        lexeme = _.initial(parts).join(".");
        property = translationsProvider.resolvePropertyFromTranslation(type, lexeme);
        propertyType = propertyTypeHelper.getPropertyTypeForHints(type, property);
        fields = getTranslatedProperties(propertyType);
        return filterOut(_.last(parts), fields);
    }

    // Send
    fields = getTranslatedProperties(type);
    return filterOut(lexeme, fields);
}

export function getValueDateHints(operator: any) {
    return timeOperatorsProvider.getDateTimeValues(operator);
}

const debouncedFullTextSearchService = backendDebounced((params: any, signal) =>
    rpc.ajax("FullTextSearchService", "SearchResultByTypeAndProperties",
        { data: serialization.toJSON(params) }, signal));

export function getValueHints(type: string, operator: any, property: string, propertyType: string, lexeme: string) {
    cancelRecentBackendRequest();

    if (propertyType === "System.DateTime")
        return this.getValueDateHints(operator);

    const path = _.initial(property.split(".")).join(".");
    const suggestType = propertyTypeHelper.getPropertyTypeForHints(type, path);
    const targetProperty = _.last(property.split("."));

    if (!suggestType)
        return [];

    const requestParams = {
        phrases: [lexeme],
        typeName: suggestType,
        detachMode: 'Autocompleter',
        targetProperties: [targetProperty]
    };

    return debouncedFullTextSearchService(requestParams)
        .then(function (suggestions: any) {
            if (!suggestions)
                return [];

            const targetValues = _(suggestions.Results)
                .map(item => item[targetProperty]);

            return filterUnique(lexeme, targetValues);
        });
}

const debouncedDataSearchManager = backendDebounced((resource: string, signal) =>
    rest.get("DataSearchManager", resource, null, signal));

export function getCustomSuggestions(operator: any, lexeme: string) {
    cancelRecentBackendRequest();

    let url = ["operatorSuggestions", operatorsProvider.getOperatorName(operator)].join("/");
    if (!isEmptyString(lexeme))
        url += "?phrase=" + lexeme;

    return debouncedDataSearchManager(url)
        .then((suggestions: any) => filterUnique(lexeme, suggestions));
}

export function prepareFieldHints(fields: any) {
    return _.map(fields, function (field) {
        const translationDuplicates = _(fields).where({ translation: field.translation });
        const collision = translationDuplicates.length > 1;

        return getFieldHint(field, collision);
    });
}
