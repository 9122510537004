/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/core/comments/comments"/>
import * as ko from "knockout";
import { MentionEditorFactory } from "Core/Medius.Core.Web/Scripts/components/mention/editor/mentionEditorFactory";
import { Editor } from "Core/Medius.Core.Web/Scripts/components/mention/editor/editor";
import { DisabledMentionsEditor } from "Core/Medius.Core.Web/Scripts/components/mention/editor/disabledMentionsEditor";
import { Comment, CommentDto } from "Core/Medius.Core.Web/Scripts/Medius/core/comment/comment";
import { translate, getFormattedLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import * as commentService from "Core/Medius.Core.Web/Scripts/Medius/core/comments/commentsService";
import * as backendErrorHandler from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import { observable } from "knockout";

export class Comments {
    private readonly documentId: number;
    private readonly entityType: string;
    private readonly entityViewId: string;
    private allowRemoveComment = ko.observable(false);
    private defaultNumberOfComments = 3;
    private defaultNumberOfMentionsRows = 4;

    public mentionEditor: Editor | DisabledMentionsEditor;
    public comments: ko.ObservableArray<Comment>;
    public counter: ko.Computed<number>;
    public newComment: ko.Observable<string>;
    public showAll = ko.observable(false);
    public isLoading = ko.observable(false);
    public commentsLoaded = observable(false);

    public canSend = ko.pureComputed(() => this.isCanSendEnabled());
    public visibleComments = ko.pureComputed(() => this.showVisibleComments());
    public isShowMoreLinkVisible = ko.pureComputed(() => !this.showAll() && this.totalHiddenComments() > 0);
    public showMoreLabel = ko.pureComputed(() => getFormattedLabelTranslation("#Core/showMore_number", [`${this.totalHiddenComments()}`]));
    public headerComments = ko.pureComputed(() => getFormattedLabelTranslation("#Core/comments_total", [`${this.counter()}`]));

    constructor(viewId: string, documentType: string, documentId: number) {
        this.entityViewId = viewId;
        this.entityType = documentType;
        this.documentId = documentId;
        
        this.comments = ko.observableArray<Comment>([]);
        this.newComment = ko.observable("");
        this.counter = ko.pureComputed(() => this.comments().length);
        this.mentionEditor = MentionEditorFactory.getInstance().create(this.getMentionsOptions());
        
        this.loadComments();
    }

    public addComment() {
        this.isLoading(true);

        commentService.post(this.newComment().trim(), this.entityViewId, this.entityType)
                        .then(savedComment => { 
                            const newComment = new Comment(savedComment, this.allowRemoveComment, this.deleteComment);
                            this.comments.unshift(newComment);
                            this.newComment("");
                        })
                        .fail(jqXhr => { backendErrorHandler.handleAnyError(jqXhr); })
                        .always(() => { 
                            this.isLoading(false);
                        });
    }

    public showMoreComments = () => {
        this.showAll(true);
    };

    private isCanSendEnabled() {
        return this.newComment().trim().length > 0;
    }

    private showVisibleComments() {
        return this.showAll() ? this.comments() : this.comments().slice(0, this.defaultNumberOfComments);
    }

    private totalHiddenComments = () => {
        const result = this.counter() - this.defaultNumberOfComments;
        return Math.max(0, result);
    };

    private get = (comments: CommentDto[]) => {
        return comments.map(comment => new Comment(comment, this.allowRemoveComment, this.deleteComment))
            .sort((a: any, b: any)  => (a.createdDate - b.createdDate < 0) ? 1 : -1);
    };

    private loadComments() {
        this.isLoading(true);

        commentService.get(this.entityViewId, this.entityType)
                        .then((comments) => { 
                            const commentsModels = this.get(comments);
                            this.comments(commentsModels);
                            this.markFirstCarriedOverComment(commentsModels);
                        })
                        .fail(jqXhr => { backendErrorHandler.handleAnyError(jqXhr); })
                        .always(() => { 
                            this.isLoading(false);
                            this.commentsLoaded(true);
                        });
    } 

    private deleteComment(hash: string) {
        this.comments.remove(comment => comment.hash === hash);
        commentService.remove(hash).fail(jqXhr => { backendErrorHandler.handleAnyError(jqXhr); });
    }

    private markFirstCarriedOverComment(comments: Comment[]) {
        let wasFirstMarked = false;
        comments.forEach(comment => {
            if (!wasFirstMarked && comment.isCarriedOver) {
                comment.isFirstCarriedOver = true;
                wasFirstMarked = true;
            }
        });
    }

    private getMentionsOptions() {
        let mentionsEnabled = true;
        let mentionsDisableDueToTranlsationKey = "";

        if (this.documentId === 0) {
            mentionsDisableDueToTranlsationKey = "#Core/mentionDisabledDuringCreation";
            mentionsEnabled = false;
        }


        return {
            mentionsEnabled,
            value: this.newComment,
            documentId: this.documentId,
            options: {
                rows: this.defaultNumberOfMentionsRows,
                placeholder: mentionsEnabled ? translate("#Core/writeACommentMentionsEnabled") : translate("#Core/writeAComment"),
                autocompleterDisablementReasonTranslationKey: mentionsDisableDueToTranlsationKey,
                autocompleterDisablementReasonDocumentType: ""
            }
        };
    }

    public dispose() {
        this.mentionEditor.dispose();
        this.isShowMoreLinkVisible.dispose();
        this.showMoreLabel.dispose();
        this.visibleComments.dispose();
        this.canSend.dispose();
        this.isShowMoreLinkVisible.dispose();
    }
}
