/// <amd-module name="Core/Medius.Core.Web/Scripts/components/notificationCenter/notificationCenter"/>
import * as appConfig from "Core/Medius.Core.Web/Scripts/appConfig";
import * as rest from "Core/Medius.Core.Web/Scripts/Medius/core/fetch/rest";
import * as backendErrorHandler from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import { getFormattedLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { observable, pureComputed, observableArray } from "knockout";
import * as moment from "Core/Medius.Core.Web/Scripts/Medius/moment/momentRelative";

interface KendoListViewOptions extends kendo.ui.ListViewOptions{
    itemTemplateId?: string;
}
export class NotificationCenter {
    public readonly isPopUpVisible = observable(false);
    public readonly listViewOptions: ko.Observable<KendoListViewOptions>;
    public readonly notifications = observableArray<Notification>();

    constructor() {
        this.listViewOptions = observable(this.createListViewOptions());
    }

    public notificationCenterImg = `Content/images/bell.png`;

    public togglePopUp(): void {
        this.isPopUpVisible(!this.isPopUpVisible());
    }

    public hide = () => this.isPopUpVisible(false);

    public isUnreadBadgeVisible = pureComputed(() => this.unreadNotificationsNumber() > 0);

    public unreadBadgeText = pureComputed(() => {
        const maxUnread = 10;
        if (this.unreadNotificationsNumber() > maxUnread) {
            return `${maxUnread}+`;
        }

        return this.unreadNotificationsNumber().toString();
    });

    private unreadNotificationsNumber = pureComputed(() =>
        this.notifications().filter(n => !n.isRead()).length
    );

    public markAllAsRead(): void {
        rest.put("Backend/Rest/notifications/read/release")
            .then(() => this.notifications().forEach(n => n.isRead(true)))
            .catch(error => backendErrorHandler.handleAnyError(error));
    }

    public noNotifications(): boolean {
        return this.notifications().length === 0;
    }

    private createListViewOptions(): KendoListViewOptions {
        const dataSourceOptions: kendo.data.DataSourceOptions = {
            transport: {
                read: (options:any): Promise<void> => {
                    return rest.get("notifications")
                        .then((data: INotificationDto[]) => {
                            this.notifications(data.map(dto => new Notification(dto)));
                            options.success(this.notifications());
                        })
                        .catch(() => {
                            options.success([]);
                        });
                }
            }
        };

        return {
            itemTemplateId: "Core:components/notificationCenter/template.html",
            dataSource: kendo.data.DataSource.create(dataSourceOptions)
        };
    }
}

export class Notification {
    public id: number;
    public isRead: ko.Observable<boolean>;
    public link: string;
    public text: string;
    public relativeTime: any;

    constructor(data: INotificationDto) {
        this.id = data.id;
        this.isRead = observable(data.isRead);
        this.link = `${appConfig.getReleaseNotesUrl()}/${data.content.version}/`;
        this.text = getFormattedLabelTranslation("#Core/notificationCenter/mediusflowWasUpgradedTo_version", [data.content.version]);
        this.relativeTime = moment.fromNow(new Date(data.createdOn));
    }

    public markAsRead = () => {
        if (this.isRead()) {
            return true;
        }

        rest.put(`Backend/Rest/notifications/${this.id}/read`)
            .then(() => {
                this.isRead(true);
            });
        return true;
    };
}

interface INotificationDto {
    id: number;
    content: any;
    createdOn: Date;
    isRead: boolean;
}

export function getNotificationCenterInstance() {
    return new NotificationCenter();
}
