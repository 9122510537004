/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/document/editor"/>
import * as $ from "jquery";
import * as rpc from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rpc";
import * as _ from "underscore";
import * as taskHandlingContext from "Core/Medius.Core.Web/Scripts/Medius/apps/task/models/taskHandlingContext";
import * as registrationEvents from "Core/Medius.Core.Web/Scripts/Medius/apps/document/events";

import * as documentContext from "Core/Medius.Core.Web/Scripts/Medius/apps/task/models/documentContext";

class Editor {
    public context: any = null;
    public documentViewModel: any = null;
    public targetElement: JQuery;
    public contextResource: any;
    public returnUrl: any;

    constructor(targetElement: any, contextResource: any) {
        this.targetElement = $(targetElement);
        this.contextResource = contextResource;
    }

    public initWithExistingDocument(document: any, type: any, returnUrl: any) {
        this.returnUrl = returnUrl;

        rpc.request('UiDocumentManager', 'GetReclassificationDraft', {
            documentId: document,
            targetTypeName: type,
            manual: true
        }).done((data) => {
            this.onGetDocumentSuccess(data);
        }).fail((error) => {
            this.onGetDocumentError(error);
        });
    }

    public createNewDocument(documentType: any) {
        if (this.documentViewModel && _.isFunction(this.documentViewModel.dispose)) {
            this.documentViewModel.dispose();
        }

        return rpc.request('UiDocumentManager', 'GetNewDocument', {
            documentType: documentType
        }).done((data) => {
            this.onGetDocumentSuccess(data);
        }).fail((data) => {
            this.onGetDocumentError(data);
        });
    }

    public onGetDocumentSuccess(data: any) {
        const context = this.contextResource.newContext();

        taskHandlingContext.extendViewmodelContextFromCreateDocument(context, data.$type);
        documentContext.extendViewmodelContextFromCreateDocument(context, data.$type);

        const documentViewModel = context.create(data);
        if(!documentViewModel.toDto)
            documentViewModel.toDto = context.toJS.bind(context, documentViewModel);

        this.context = context;
        this.documentViewModel = documentViewModel;
        // document was fetched properly
        this.targetElement.trigger(registrationEvents.modelBound, documentViewModel);
    }

    public onGetDocumentError(error: any) {
        this.targetElement.trigger(registrationEvents.modelLoadError, error);
    }
}

export function create(targetElement: any, contextResource: any) {
    return new Editor(targetElement, contextResource);
}
