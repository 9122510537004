/// <amd-module name="Core/Medius.Core.Web/Scripts/loginPage/HomeRealmDiscovery"/>
import * as React from "react";
import {useState} from "react";
import Card from "Core/Medius.Core.Web/Scripts/components/card/Card";
import {translate} from "Core/Medius.Core.Web/Scripts/lib/globalization";
import {toAction} from "Core/Medius.Core.Web/Scripts/Medius/lib/path";

export default function HomeRealmDiscoveryPage(props: IHomeRealmDiscoveryPageProps) {
    return (
        <Card>
            <div className="home-realm-discovery-page card-content">
            {
                props.model.Error 
                ? (
                    <Error/>
                )
                :  (
                    <ProviderSelection identityProviders={props.model.IdentityProviders}/>
                )
            }
            </div>
        </Card>
    );
}

function Error() {
    return (
        <div className="card-message">
            {translate("#Core/couldNotAccessMediusflowServer")}
        </div>
    );
}

function ProviderSelection(props: IProviderSelection) {
    const [alwaysLogInThisWay, setAlwaysLogInThisWay] = useState(false);

    function selectProvider(id: number) {
        window.location.href = toAction(`IdentityProviderSelect?identityProviderId=${id}&isDefault=${alwaysLogInThisWay}`, '~/Account');
    }

    function handleAlwaysLogInThisWay() {
        setAlwaysLogInThisWay(!alwaysLogInThisWay);
    }

    return (
        <>
            <div className="home-realm-discovery-page__question">{translate("#Core/userIdentificationQuestion")}</div>
            <div className="home-realm-discovery-page__button-selection">
            {
                props.identityProviders.map((provider) => 
                    (
                        <button key={provider.Id} onClick={() => selectProvider(provider.Id)} className="card-button card-button">{provider.IdentityProviderName}</button>
                    )
                )
            }
            </div>

            <div className="home-realm-discovery-page__checkbox-container">
                <label className="home-realm-discovery-page__checkbox-container__label">
                    {translate("#Core/alwaysLogInThisWay")}
                    <input type="checkbox" checked={alwaysLogInThisWay} onChange={handleAlwaysLogInThisWay} style={{margin: "5px"}}/>
                    <span className="home-realm-discovery-page__checkbox-container__checkmark"></span>
                </label>
            </div>
        </>
    );
}

interface IHomeRealmDiscoveryPageProps {
    model: {
        Error: boolean;
        IdentityProviders: {Id: number; IdentityProviderName: string }[];
    };
}

interface IProviderSelection {
    identityProviders: {Id: number; IdentityProviderName: string }[];
}
