/// <amd-module name="Core/Medius.Core.Web/Scripts/Models/Medius.Core.Entities.CustomFields.CustomFieldsWithListContainer"/>
import * as ko from "knockout";
import repository = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/mapping/collection/instance");

const typeName = "Medius.Core.DTOs.CustomFieldsWithListContainerDto, Medius.Core.Common";

class CustomFieldWithConfigurationViewModel {
    public CustomField: any;
    public Configuration: any;
    public Type:string;
    public isActive: () => boolean;
    public dispose: () => void;

    constructor(CustomField: any, Configuration: any, Type:string) {
        this.CustomField = ko.isObservable(CustomField) ? CustomField : ko.observable(CustomField);
        this.Configuration = ko.isObservable(Configuration) ? Configuration : ko.observable(Configuration);
        this.Type = Type;

        this.isActive = () => {
            const config = ko.unwrap(this.Configuration);

            if (!config) {
                return false;
            }
            return ko.unwrap(config.IsActive);
        };

        this.dispose = () => {
            this.CustomField = null;
            this.Configuration = null;
        };
    }
}

function disposeCollection(collection:any) {
    collection.forEach(tryDispose);
}

function tryDispose(item:any) {
    if (item && item.dispose) {
        item.dispose();
    }
}

function getCustomField(container:any, index:number) {
    const customFieldContainer = ko.unwrap(container);

    if (!customFieldContainer || !customFieldContainer[index]) {
        return null;
    }

    return ko.unwrap(customFieldContainer[index].CustomField);
}

export class CustomFieldsWithListContainer {
    public Perspective: ko.Observable<any>;
    public ActiveCustomFields: ko.ObservableArray<CustomFieldWithConfigurationViewModel>;
    public AreAnyFieldsActive: ko.Computed<boolean>;

    public NumericCustomField1WithConfiguration: CustomFieldWithConfigurationViewModel;
    public NumericCustomField2WithConfiguration: CustomFieldWithConfigurationViewModel;
    public NumericCustomField3WithConfiguration: CustomFieldWithConfigurationViewModel;
    public NumericCustomField4WithConfiguration: CustomFieldWithConfigurationViewModel;
    public NumericCustomField5WithConfiguration: CustomFieldWithConfigurationViewModel;
    public NumericCustomFieldsWithConfiguration:ko.Computed<CustomFieldWithConfigurationViewModel[]>;
    public ActiveNumericCustomFieldsWithConfiguration: () => any[];
    public AreAnyNumericFieldsActive: ko.Computed<boolean>;

    public TextCustomField1WithConfiguration: CustomFieldWithConfigurationViewModel;
    public TextCustomField2WithConfiguration: CustomFieldWithConfigurationViewModel;
    public TextCustomField3WithConfiguration: CustomFieldWithConfigurationViewModel;
    public TextCustomField4WithConfiguration: CustomFieldWithConfigurationViewModel;
    public TextCustomField5WithConfiguration: CustomFieldWithConfigurationViewModel;
    public TextCustomFieldsWithConfiguration:ko.Computed<CustomFieldWithConfigurationViewModel[]>;
    public ActiveTextCustomFieldsWithConfiguration: () => any[];
    public AreAnyTextFieldsActive: ko.Computed<boolean>;

    public BooleanCustomField1WithConfiguration: CustomFieldWithConfigurationViewModel;
    public BooleanCustomField2WithConfiguration: CustomFieldWithConfigurationViewModel;
    public BooleanCustomField3WithConfiguration: CustomFieldWithConfigurationViewModel;
    public BooleanCustomField4WithConfiguration: CustomFieldWithConfigurationViewModel;
    public BooleanCustomField5WithConfiguration: CustomFieldWithConfigurationViewModel;
    public BooleanCustomFieldsWithConfiguration:ko.Computed<CustomFieldWithConfigurationViewModel[]>;
    public ActiveBooleanCustomFieldsWithConfiguration: () => any[];
    public AreAnyBooleanFieldsActive: ko.Computed<boolean>;

    public DateTimeCustomField1WithConfiguration: CustomFieldWithConfigurationViewModel;
    public DateTimeCustomField2WithConfiguration: CustomFieldWithConfigurationViewModel;
    public DateTimeCustomField3WithConfiguration: CustomFieldWithConfigurationViewModel;
    public DateTimeCustomField4WithConfiguration: CustomFieldWithConfigurationViewModel;
    public DateTimeCustomField5WithConfiguration: CustomFieldWithConfigurationViewModel;
    public DatetimeCustomFieldsWithConfiguration:ko.Computed<CustomFieldWithConfigurationViewModel[]>;
    public ActiveDatetimeCustomFieldsWithConfiguration: () => any[];
    public AreAnyDatetimeFieldsActive: ko.Computed<boolean>;

    public ListCustomField1WithConfiguration: CustomFieldWithConfigurationViewModel;
    public ListCustomField2WithConfiguration: CustomFieldWithConfigurationViewModel;
    public ListCustomField3WithConfiguration: CustomFieldWithConfigurationViewModel;
    public ListCustomField4WithConfiguration: CustomFieldWithConfigurationViewModel;
    public ListCustomField5WithConfiguration: CustomFieldWithConfigurationViewModel;
    public ListCustomFieldsWithConfiguration:ko.Computed<CustomFieldWithConfigurationViewModel[]>;
    public ActiveListCustomFieldsWithConfiguration: () => any[];
    public AreAnyListFieldsActive: ko.Computed<boolean>;

    public ActiveCustomFieldsSync: ko.Computed<void>;
    public perspectiveSub: any;
    public dispose: () => void;
    public toJS: () => any;


    constructor(data:any) {
        this.Perspective = ko.isObservable(data.Perspective) ? data.Perspective : ko.observable(data.Perspective);
        if (this.Perspective()) {
            this.Perspective().Configuration = ko.isObservable(this.Perspective().Configuration)
            ? this.Perspective().Configuration
            : ko.observable(this.Perspective().Configuration);
        }

        this.ActiveCustomFields = ko.observableArray([]);

        this.AreAnyFieldsActive = ko.pureComputed(() => !this.Perspective() ? false : this.Perspective().AreAnyFieldsActive);

        this.NumericCustomFieldsWithConfiguration = ko.computed(() => {
            if (!this.Perspective()) {
                return null;
            }

            tryDispose(this.NumericCustomField1WithConfiguration);
            tryDispose(this.NumericCustomField2WithConfiguration);
            tryDispose(this.NumericCustomField3WithConfiguration);
            tryDispose(this.NumericCustomField4WithConfiguration);
            tryDispose(this.NumericCustomField5WithConfiguration);

            this.NumericCustomField1WithConfiguration = new CustomFieldWithConfigurationViewModel(data.NumericCustomField1, this.Perspective().Configuration().NumericCustomField1, 'n');
            this.NumericCustomField2WithConfiguration = new CustomFieldWithConfigurationViewModel(data.NumericCustomField2, this.Perspective().Configuration().NumericCustomField2, 'n');
            this.NumericCustomField3WithConfiguration = new CustomFieldWithConfigurationViewModel(data.NumericCustomField3, this.Perspective().Configuration().NumericCustomField3, 'n');
            this.NumericCustomField4WithConfiguration = new CustomFieldWithConfigurationViewModel(data.NumericCustomField4, this.Perspective().Configuration().NumericCustomField4, 'n');
            this.NumericCustomField5WithConfiguration = new CustomFieldWithConfigurationViewModel(data.NumericCustomField5, this.Perspective().Configuration().NumericCustomField5, 'n');

            return [
                this.NumericCustomField1WithConfiguration,
                this.NumericCustomField2WithConfiguration,
                this.NumericCustomField3WithConfiguration,
                this.NumericCustomField4WithConfiguration,
                this.NumericCustomField5WithConfiguration
            ];
        });

        this.ActiveNumericCustomFieldsWithConfiguration = () => {
            return this.NumericCustomFieldsWithConfiguration().filter(
                function (item:any) {
                    return item.isActive();
                });
        };
    
        this.AreAnyNumericFieldsActive = ko.pureComputed(() => {
            return this.ActiveNumericCustomFieldsWithConfiguration().length > 0;
        });


        this.TextCustomFieldsWithConfiguration = ko.computed(() => {
            if (!this.Perspective()) {
                return null;
            }

            tryDispose(this.TextCustomField1WithConfiguration);
            tryDispose(this.TextCustomField2WithConfiguration);
            tryDispose(this.TextCustomField3WithConfiguration);
            tryDispose(this.TextCustomField4WithConfiguration);
            tryDispose(this.TextCustomField5WithConfiguration);

            this.TextCustomField1WithConfiguration = new CustomFieldWithConfigurationViewModel(data.TextCustomField1, this.Perspective().Configuration().TextCustomField1, 't');
            this.TextCustomField2WithConfiguration = new CustomFieldWithConfigurationViewModel(data.TextCustomField2, this.Perspective().Configuration().TextCustomField2, 't');
            this.TextCustomField3WithConfiguration = new CustomFieldWithConfigurationViewModel(data.TextCustomField3, this.Perspective().Configuration().TextCustomField3, 't');
            this.TextCustomField4WithConfiguration = new CustomFieldWithConfigurationViewModel(data.TextCustomField4, this.Perspective().Configuration().TextCustomField4, 't');
            this.TextCustomField5WithConfiguration = new CustomFieldWithConfigurationViewModel(data.TextCustomField5, this.Perspective().Configuration().TextCustomField5, 't');

            return [
                this.TextCustomField1WithConfiguration,
                this.TextCustomField2WithConfiguration,
                this.TextCustomField3WithConfiguration,
                this.TextCustomField4WithConfiguration,
                this.TextCustomField5WithConfiguration
            ];
        });

        this.ActiveTextCustomFieldsWithConfiguration = () => {
            return this.TextCustomFieldsWithConfiguration().filter(
                function (item:any) {
                    return item.isActive();
                });
        };

        this.AreAnyTextFieldsActive = ko.pureComputed(() => {
            return this.ActiveTextCustomFieldsWithConfiguration().length > 0;
        });

        this.BooleanCustomFieldsWithConfiguration = ko.computed(() => {
            if (!this.Perspective()) {
                return null;
            }

            tryDispose(this.BooleanCustomField1WithConfiguration);
            tryDispose(this.BooleanCustomField2WithConfiguration);
            tryDispose(this.BooleanCustomField3WithConfiguration);
            tryDispose(this.BooleanCustomField4WithConfiguration);
            tryDispose(this.BooleanCustomField5WithConfiguration);

            this.BooleanCustomField1WithConfiguration = new CustomFieldWithConfigurationViewModel(data.BooleanCustomField1, this.Perspective().Configuration().BooleanCustomField1, 'b');
            this.BooleanCustomField2WithConfiguration = new CustomFieldWithConfigurationViewModel(data.BooleanCustomField2, this.Perspective().Configuration().BooleanCustomField2, 'b');
            this.BooleanCustomField3WithConfiguration = new CustomFieldWithConfigurationViewModel(data.BooleanCustomField3, this.Perspective().Configuration().BooleanCustomField3, 'b');
            this.BooleanCustomField4WithConfiguration = new CustomFieldWithConfigurationViewModel(data.BooleanCustomField4, this.Perspective().Configuration().BooleanCustomField4, 'b');
            this.BooleanCustomField5WithConfiguration = new CustomFieldWithConfigurationViewModel(data.BooleanCustomField5, this.Perspective().Configuration().BooleanCustomField5, 'b');

            return [
                this.BooleanCustomField1WithConfiguration,
                this.BooleanCustomField2WithConfiguration,
                this.BooleanCustomField3WithConfiguration,
                this.BooleanCustomField4WithConfiguration,
                this.BooleanCustomField5WithConfiguration
            ];
        });

        this.ActiveBooleanCustomFieldsWithConfiguration = () => {
            return this.BooleanCustomFieldsWithConfiguration().filter(
                function (item:any) {
                    return item.isActive();
                });
        };

        this.AreAnyBooleanFieldsActive = ko.pureComputed(() => {
            return this.ActiveBooleanCustomFieldsWithConfiguration().length > 0;
        });

        this.DatetimeCustomFieldsWithConfiguration = ko.computed(() => {
            if (!this.Perspective()) {
                return null;
            }

            tryDispose(this.DateTimeCustomField1WithConfiguration);
            tryDispose(this.DateTimeCustomField2WithConfiguration);
            tryDispose(this.DateTimeCustomField3WithConfiguration);
            tryDispose(this.DateTimeCustomField4WithConfiguration);
            tryDispose(this.DateTimeCustomField5WithConfiguration);

            this.DateTimeCustomField1WithConfiguration = new CustomFieldWithConfigurationViewModel(data.DateTimeCustomField1, this.Perspective().Configuration().DateTimeCustomField1, 'd');
            this.DateTimeCustomField2WithConfiguration = new CustomFieldWithConfigurationViewModel(data.DateTimeCustomField2, this.Perspective().Configuration().DateTimeCustomField2, 'd');
            this.DateTimeCustomField3WithConfiguration = new CustomFieldWithConfigurationViewModel(data.DateTimeCustomField3, this.Perspective().Configuration().DateTimeCustomField3, 'd');
            this.DateTimeCustomField4WithConfiguration = new CustomFieldWithConfigurationViewModel(data.DateTimeCustomField4, this.Perspective().Configuration().DateTimeCustomField4, 'd');
            this.DateTimeCustomField5WithConfiguration = new CustomFieldWithConfigurationViewModel(data.DateTimeCustomField5, this.Perspective().Configuration().DateTimeCustomField5, 'd');

            return [
                this.DateTimeCustomField1WithConfiguration,
                this.DateTimeCustomField2WithConfiguration,
                this.DateTimeCustomField3WithConfiguration,
                this.DateTimeCustomField4WithConfiguration,
                this.DateTimeCustomField5WithConfiguration
            ];
        });

        this.ActiveDatetimeCustomFieldsWithConfiguration = () => {
            return this.DatetimeCustomFieldsWithConfiguration().filter(
                function (item:any) {
                    return item.isActive();
                });
        };

        this.AreAnyDatetimeFieldsActive = ko.pureComputed(() =>{
            return this.ActiveDatetimeCustomFieldsWithConfiguration().length > 0;
        });

        this.ListCustomFieldsWithConfiguration = ko.computed(() => {
            if (!this.Perspective()) {
                return null;
            }

            tryDispose(this.ListCustomField1WithConfiguration);
            tryDispose(this.ListCustomField2WithConfiguration);
            tryDispose(this.ListCustomField3WithConfiguration);
            tryDispose(this.ListCustomField4WithConfiguration);
            tryDispose(this.ListCustomField5WithConfiguration);

            this.ListCustomField1WithConfiguration = new CustomFieldWithConfigurationViewModel(data.ListCustomField1, this.Perspective().Configuration().ListCustomField1, 'l');
            this.ListCustomField2WithConfiguration = new CustomFieldWithConfigurationViewModel(data.ListCustomField2, this.Perspective().Configuration().ListCustomField2, 'l');
            this.ListCustomField3WithConfiguration = new CustomFieldWithConfigurationViewModel(data.ListCustomField3, this.Perspective().Configuration().ListCustomField3, 'l');
            this.ListCustomField4WithConfiguration = new CustomFieldWithConfigurationViewModel(data.ListCustomField4, this.Perspective().Configuration().ListCustomField4, 'l');
            this.ListCustomField5WithConfiguration = new CustomFieldWithConfigurationViewModel(data.ListCustomField5, this.Perspective().Configuration().ListCustomField5, 'l');

            return [
                this.ListCustomField1WithConfiguration,
                this.ListCustomField2WithConfiguration,
                this.ListCustomField3WithConfiguration,
                this.ListCustomField4WithConfiguration,
                this.ListCustomField5WithConfiguration
            ];
        });

        this.ActiveListCustomFieldsWithConfiguration = () => {
            return this.ListCustomFieldsWithConfiguration().filter(
                function (item:any) {
                    return item.isActive();
                });
        };

        this.AreAnyListFieldsActive = ko.pureComputed(() => {
            return this.ActiveListCustomFieldsWithConfiguration().length > 0;
        });

        this.ActiveCustomFieldsSync = ko.computed(() => {
            this.ActiveCustomFields.removeAll();

            if (!this.Perspective() || !this.TextCustomFieldsWithConfiguration() || !this.NumericCustomFieldsWithConfiguration() ||
                !this.BooleanCustomFieldsWithConfiguration() || !this.DatetimeCustomFieldsWithConfiguration() || !this.ListCustomFieldsWithConfiguration()) {
                return;
            }

            ko.utils.arrayPushAll(this.ActiveCustomFields(), this.ActiveNumericCustomFieldsWithConfiguration());
            ko.utils.arrayPushAll(this.ActiveCustomFields(), this.ActiveTextCustomFieldsWithConfiguration());
            ko.utils.arrayPushAll(this.ActiveCustomFields(), this.ActiveBooleanCustomFieldsWithConfiguration());
            ko.utils.arrayPushAll(this.ActiveCustomFields(), this.ActiveDatetimeCustomFieldsWithConfiguration());
            ko.utils.arrayPushAll(this.ActiveCustomFields(), this.ActiveListCustomFieldsWithConfiguration());
            this.ActiveCustomFields.valueHasMutated();
        });

        this.perspectiveSub = this.Perspective.subscribe(() => {
            this.ActiveCustomFieldsSync();
        });

        this.dispose = () => {
            this.perspectiveSub.dispose();
            this.ActiveCustomFieldsSync.dispose();
            this.DatetimeCustomFieldsWithConfiguration.dispose();
            this.BooleanCustomFieldsWithConfiguration.dispose();
            this.TextCustomFieldsWithConfiguration.dispose();
            this.NumericCustomFieldsWithConfiguration.dispose();
            this.ListCustomFieldsWithConfiguration.dispose();
            disposeCollection(this.ActiveCustomFields.peek());
            this.AreAnyFieldsActive.dispose();
        };

        this.toJS = () => {
            return this.Perspective() ? {
                $type: typeName,
                TextCustomField1: getCustomField(this.TextCustomFieldsWithConfiguration, 0),
                TextCustomField2: getCustomField(this.TextCustomFieldsWithConfiguration, 1),
                TextCustomField3: getCustomField(this.TextCustomFieldsWithConfiguration, 2),
                TextCustomField4: getCustomField(this.TextCustomFieldsWithConfiguration, 3),
                TextCustomField5: getCustomField(this.TextCustomFieldsWithConfiguration, 4),
                NumericCustomField1: getCustomField(this.NumericCustomFieldsWithConfiguration, 0),
                NumericCustomField2: getCustomField(this.NumericCustomFieldsWithConfiguration, 1),
                NumericCustomField3: getCustomField(this.NumericCustomFieldsWithConfiguration, 2),
                NumericCustomField4: getCustomField(this.NumericCustomFieldsWithConfiguration, 3),
                NumericCustomField5: getCustomField(this.NumericCustomFieldsWithConfiguration, 4),
                BooleanCustomField1: getCustomField(this.BooleanCustomFieldsWithConfiguration, 0),
                BooleanCustomField2: getCustomField(this.BooleanCustomFieldsWithConfiguration, 1),
                BooleanCustomField3: getCustomField(this.BooleanCustomFieldsWithConfiguration, 2),
                BooleanCustomField4: getCustomField(this.BooleanCustomFieldsWithConfiguration, 3),
                BooleanCustomField5: getCustomField(this.BooleanCustomFieldsWithConfiguration, 4),
                DateTimeCustomField1: getCustomField(this.DatetimeCustomFieldsWithConfiguration, 0),
                DateTimeCustomField2: getCustomField(this.DatetimeCustomFieldsWithConfiguration, 1),
                DateTimeCustomField3: getCustomField(this.DatetimeCustomFieldsWithConfiguration, 2),
                DateTimeCustomField4: getCustomField(this.DatetimeCustomFieldsWithConfiguration, 3),
                DateTimeCustomField5: getCustomField(this.DatetimeCustomFieldsWithConfiguration, 4),
                ListCustomField1: getCustomField(this.ListCustomFieldsWithConfiguration, 0),
                ListCustomField2: getCustomField(this.ListCustomFieldsWithConfiguration, 1),
                ListCustomField3: getCustomField(this.ListCustomFieldsWithConfiguration, 2),
                ListCustomField4: getCustomField(this.ListCustomFieldsWithConfiguration, 3),
                ListCustomField5: getCustomField(this.ListCustomFieldsWithConfiguration, 4)
            } : null;
        };
    }
}

export function registerMapping() {
    repository.register(typeName, function() {return true;}, {
        make: function (data:any, context:any) {
            return new CustomFieldsWithListContainer(data);
        },
        toJS: function(viewModel:any) {
            if(viewModel instanceof CustomFieldsWithListContainer) return viewModel.toJS();
        }
    });
}