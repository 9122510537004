/// <amd-module name="Core/Medius.Core.Web/Scripts/lib/northstarLocalization"/>
import { translate } from "./globalization";
import { StringMap } from '@medius/ui-controls';

const mappings = [
    { nsKey: "action.ok", apaKey: "#Procurement/ok" },
    { nsKey: "action.cancel", apaKey: "#Procurement/cancel" },
    { nsKey: "action.close", apaKey: "#Procurement/close" },
    { nsKey: "action.back", apaKey: "#Procurement/back" },
    { nsKey: "action.search", apaKey: "#Procurement/search" },
    { nsKey: "action.clear", apaKey: "#Procurement/clear" },
    { nsKey: "action.delete", apaKey: "#Procurement/delete" },
    { nsKey: "action.edit", apaKey: "#Procurement/edit" },
    { nsKey: "action.add", apaKey: "#Procurement/add" },
    { nsKey: "action.save", apaKey: "#Procurement/save" },
    { nsKey: "action.removeItem", apaKey: "#Procurement/removeItem" },
    { nsKey: "action.retry", apaKey: "#Procurement/retry" },
    { nsKey: "action.reset", apaKey: "#Procurement/reset" },
    { nsKey: "frame.navMenuDescription", apaKey: "#Procurement/applicationNavigationMenu" },
    { nsKey: "frame.toggleMenuExpand", apaKey: "#Procurement/toggleMenuExpansion" },
    { nsKey: "frame.toggleMenuPin", apaKey: "#Procurement/toggleMenuPinned" },
    { nsKey: "frame.homeLinkDescription", apaKey: "#Procurement/homeLinkDescription" },
    { nsKey: "frame.applicationLoadError", apaKey: "#Procurement/applicationLoadError" },
    { nsKey: "frame.applicationSwitch", apaKey: "#Procurement/applicationSwitch" },
    { nsKey: "frame.companyLogoLabel", apaKey: "#Procurement/companyLogoLabel" },
    { nsKey: "frame.showUserMenu", apaKey: "#Procurement/showUserMenu" },
    { nsKey: "frame.logoutAction", apaKey: "#Procurement/logoutAction" },
    { nsKey: "dialog.alertTitle", apaKey: "#Procurement/alertTitle" },
    { nsKey: "dialog.confirmTitle", apaKey: "#Procurement/confirmTitle" },
    { nsKey: "dialog.resetSelection", apaKey: "#Procurement/resetSelection" },
    { nsKey: "dialog.selectAllVisible", apaKey: "#Procurement/selectAllVisible" },
    { nsKey: "dialog.noItemSelectedError", apaKey: "#Procurement/noItemSelectedError" },
    { nsKey: "behaviour.expand", apaKey: "#Procurement/expand" },
    { nsKey: "behaviour.collapse", apaKey: "#Procurement/collapse" },
    { nsKey: "behaviour.scrollLeft", apaKey: "#Procurement/scrollLeft" },
    { nsKey: "behaviour.scrollRight", apaKey: "#Procurement/scrollRight" },
    { nsKey: "datetime.today", apaKey: "#Procurement/today" },
    { nsKey: "datetime.dateFormatText", apaKey: "#Procurement/dateFormat" },
    { nsKey: "datetime.day", apaKey: "#Procurement/day" },
    { nsKey: "datetime.month", apaKey: "#Procurement/month" },
    { nsKey: "datetime.year", apaKey: "#Procurement/year" },
    { nsKey: "progress.pending", apaKey: "#Procurement/pending" },
    { nsKey: "progress.inProgress", apaKey: "#Procurement/inProgress" },
    { nsKey: "progress.complete", apaKey: "#Procurement/complete" },
    { nsKey: "progress.error", apaKey: "#Procurement/error" },
    { nsKey: "data.loading", apaKey: "#Procurement/loading" },
    { nsKey: "data.noItems", apaKey: "#Procurement/noItems" },
    { nsKey: "data.noMoreItems", apaKey: "#Procurement/noMoreItems" },
    { nsKey: "data.noMatchingItems", apaKey: "#Procurement/noMatchingItems" },
    { nsKey: "data.loadError", apaKey: "#Procurement/loadError" },
    { nsKey: "data.retryLoad", apaKey: "#Procurement/retryLoad" },
    { nsKey: "data.loadMore", apaKey: "#Procurement/loadMore" },
    { nsKey: "data.allSelected", apaKey: "#Procurement/allSelected" },
    { nsKey: "fileUploader.addFile", apaKey: "#Procurement/addFile" },
    { nsKey: "fileUploader.dropZone", apaKey: "#Procurement/dropZone" },
    { nsKey: "fileUploader.fileTooLarge", apaKey: "#Procurement/fileTooLarge" },
    { nsKey: "fileUploader.fileInvalidType", apaKey: "#Procurement/fileInvalidType" },
    { nsKey: "fileUploader.tooManyFiles", apaKey: "#Procurement/tooManyFiles" },
    { nsKey: "fileUploader.uploadDisabled", apaKey: "#Procurement/uploadDisabled" },
    { nsKey: "fileUploader.uploadProgress", apaKey: "#Procurement/uploadProgress" },
    { nsKey: "form.fieldRequiredError", apaKey: "#Procurement/fieldRequiredError" },
    { nsKey: "form.fieldMaxLengthError", apaKey: "#Procurement/fieldMaxLengthError" },
    { nsKey: "form.fieldMinLengthError", apaKey: "#Procurement/fieldMinLengthError" },
    { nsKey: "comments.addFieldName", apaKey: "#Procurement/commentsAddFieldName" },
    { nsKey: "friendlyDate.timestampNow", apaKey: "#Procurement/timestampNow" },
    { nsKey: "friendlyDate.timestampLastHour", apaKey: "#Procurement/timestampLastHour" },
    { nsKey: "friendlyDate.timestampToday", apaKey: "#Procurement/timestampToday" },
    { nsKey: "friendlyDate.timestampYesterday", apaKey: "#Procurement/timestampYesterday" },
    { nsKey: "grid.loadErrorMessage", apaKey: "#Procurement/grid/loadErrorMessage" },
    { nsKey: "grid.loadRetryMessage", apaKey: "#Procurement/grid/loadRetryMessage" },
    { nsKey: "grid.noResultsTitle", apaKey: "#Procurement/grid/noResultsMessage" },
    { nsKey: "grid.noResultsExplanation", apaKey: "#Procurement/grid/noResultsExplanation" },
    { nsKey: "grid.columnResizeLabel", apaKey: "#Procurement/grid/columnResizeLabel" },
    { nsKey: "grid.columnResizeDescription", apaKey: "#Procurement/grid/columnResizeDescription" },
    { nsKey: "grid.columnResizeResetDescription", apaKey: "#Procurement/grid/columnResizeResetDescription" },
    { nsKey: "grid.changeSort", apaKey: "#Procurement/grid/changeSort" },
    { nsKey: "grid.sortBy", apaKey: "#Procurement/grid/sortBy" },
    { nsKey: "grid.pageSelectAll", apaKey: "#Procurement/grid/pageSelectAll" },
    { nsKey: "grid.rowSelect", apaKey: "#Procurement/grid/rowSelect" },
    { nsKey: "grid.selectColumnLabel", apaKey: "#Procurement/grid/selectColumnLabel" },
    { nsKey: "paging.pageMenu", apaKey: "#Procurement/paging/pageMenu" },
    { nsKey: "paging.pageSize", apaKey: "#Procurement/paging/pageSize" },
    { nsKey: "paging.currentPage", apaKey: "#Procurement/paging/currentPage" },
    { nsKey: "paging.gotoPage", apaKey: "#Procurement/paging/gotoPage" },
    { nsKey: "paging.nextPage", apaKey: "#Procurement/paging/nextPage" },
    { nsKey: "paging.previousPage", apaKey: "#Procurement/paging/previousPage" },
    { nsKey: "paging.totalItems", apaKey: "#Procurement/paging/totalItems" }
];

export const mapFromNorthstarToApa = (key: string, defaultValue?: string, args?: StringMap): string => {
    const apaKey = mappings.find(m => m.nsKey === key)?.apaKey;
    let translation = (apaKey) ? translate(apaKey) : key ?? defaultValue;
    if (args) {
        Object.keys(args).forEach((x: string) => {
            translation = translation.replace('{{'+x+'}}', args[x]);
        });
    }

    return translation;
};