/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/performance/loggers/base"/>
import { logScenario } from "Core/Medius.Core.Web/Scripts/lib/metricsLogging/metricLogger";
import * as timing from "Core/Medius.Core.Web/Scripts/Medius/lib/performance";

export function start(scenario?: string, scenarioDetails?: string, label?: string) {
    return {
        startTime: timing.now(),
        stop: function () {
            log({
                scenario: scenario,
                scenarioDetails: scenarioDetails,
                label: label,
                time: this.getElapsedTime()
            });
        },
        getElapsedTime: function() {
            return timing.now() - this.startTime;
        }
    };
}

export function log(data: any) {
    logScenario(data.scenario, data.scenarioDetails, data.label, data.time);
}