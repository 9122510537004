///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/components/configurationProperties/entityObject"/>
import * as _ from "underscore";
import * as base from "Core/Medius.Core.Web/Scripts/Medius/components/configurationProperties/baseObject";

class EntityProperty extends base.derive{
    BindingTemplate: string;
    constructor(params: any){
        super(params);
        this.BindingTemplate = "configuration-entity-property";
    }
}

export function create(params:any) {
    return new EntityProperty(params);
}
